import { useAppDispatch } from "@/hook";
import useReminder from "@/hook/useReminder";
import { useEffect, useMemo, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { DataBaseAccountItemType, DataType } from "../type";
import debounce from "lodash/debounce";
import { FleetTooltip } from "@/components";
import React from "react";
import { CommonRoleWrap, FleetToast } from "@/common";
import { ColumnsType } from "antd/es/table";
import {
  deleteAccountAction,
  getAccountGroupAction,
} from "@/action/dataBase/account";
import { AccountFilterProps } from "../components/filter/type";
import { Popover } from "@/_components";

const useIndex = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { reminder } = useReminder();
  const cacheSource = useRef<{ [index: number]: DataBaseAccountItemType[] }>(
    {}
  );
  const [selectedRowKeys, setSelectedRowKeys] = useState<number[]>([]);
  const record = useRef<DataBaseAccountItemType>(null);
  const [search, setSearch] = useState("");
  const [loading, setLoading] = useState(false);
  const [hide, setHide] = useState(true);
  const [editType, setEditType] = useState<"add" | "edit">("add");

  const [company, setCompany] = useState<{ id: number; name: string }>({
    id: null,
    name: "",
  });

  const handleCompanySelect: AccountFilterProps["onCompanySelect"] = (val) => {
    setCompany((prev) => ({ ...val }));
  };

  const [pager, setPager] = useState<{
    total: number;
    pageNum: number;
    pageSize: number;
  }>({ total: 0, pageNum: 1, pageSize: 20 });

  const dataSource = useMemo(() => {
    return cacheSource.current[pager.pageNum];
  }, [pager.pageNum, pager.total]);

  const handleEdit = (
    type: "add" | "edit",
    item: DataBaseAccountItemType | null
  ) => {
    record.current = item;
    setEditType(type);
    setHide(false);
  };

  const opereteColumns: ColumnsType<DataType> = useMemo(() => {
    return [
      {
        dataIndex: "option",
        width: 120,
        title: "Action",
        key: "option",
        align: "center",
        fixed: "right",
        render: (value, record, index) => {
          return (
            <div className="td-operate">
              <CommonRoleWrap roleStr="account:list:edit">
                <span onClick={() => handleEdit("edit", record)}>Edit</span>
              </CommonRoleWrap>
              <CommonRoleWrap roleStr="account:list:delete">
                <Popover
                  // iconProps={{
                  //   type: "FleetEslistDeleteIcon",
                  //   outerLayerClassname: "operate-icon"
                  // }}
                  render={(setIsOpen) => {
                    return (
                      <FleetToast
                        label="Are you sure delete this item?"
                        onCancel={() => {
                          setIsOpen(false);
                        }}
                        onConfirm={() => {
                          setIsOpen(false);
                          deleteAccount("single", record);
                        }}
                      />
                    );
                  }}
                >
                  <span
                    className="font_family fleet-delete"
                    style={{ color: "red" }}
                  ></span>
                </Popover>
              </CommonRoleWrap>
            </div>
          );
        },
      },
    ];
  }, [record]);

  const onSelectChange = (newSelectedRowKeys: number[]) => {
    setSelectedRowKeys(newSelectedRowKeys);
  };

  const getAccountGroupFront = () => {
    setLoading(true);
  };

  const getAccountGroupSuccess = (response) => {
    let total = 0;
    switch (true) {
      case !Array.isArray(response?.data):
        // setDataSource(response?.data?.items);
        cacheSource.current[1] = response?.data?.items;
        total = response?.data?.totalCount;
        break;
      default:
        // setDataSource([]);
        cacheSource.current = {};
        break;
    }
    setPager((prev) => ({ ...prev, total, pageNum: 1 }));
    setLoading(false);
  };

  const getAccountGroupFailed = (error) => {
    reminder(
      "error",
      error?.msg ? error?.msg + ": " + error?.data : error?.data
    );
    setLoading(false);
  };

  const getAccountGroup = (searchVal?: string) => {
    getAccountGroupAction(
      {
        pageNum: 1,
        pageSize: pager.pageSize,
        keyword: searchVal ?? search,
        companyId: company.id,
      },
      getAccountGroupFront,
      getAccountGroupSuccess,
      getAccountGroupFailed,
      dispatch,
      navigate
    );
  };

  const deleteAccountSuccess = (response) => {
    reminder("success", response?.msg);
    getAccountGroup();
  };

  const deleteAccount = (
    type: "single" | "all",
    record?: DataBaseAccountItemType
  ) => {
    if (type === "all" && selectedRowKeys?.length === 0) return;
    deleteAccountAction(
      type === "single" ? [record?.id] : selectedRowKeys,
      getAccountGroupFront,
      deleteAccountSuccess,
      getAccountGroupFailed,
      dispatch,
      navigate
    );
  };

  const handleReset = () => {
    setHide(true);
    setSelectedRowKeys([]);
    getAccountGroup();
  };

  const onPageChange = (page: number, size: number) => {
    if (dataSource?.length >= pager.total) return;
    // if (page * size <= dataSource?.length) return;
    if (cacheSource.current[page]) {
      setPager((prev) => ({ ...prev, pageNum: page }));
      return;
    }
    getAccountGroupAction(
      {
        pageNum: 1,
        pageSize: pager.pageSize,
        keyword: search,
        companyId: company.id,
      },
      getAccountGroupFront,
      (response) => {
        if (!Array.isArray(response?.data)) {
          cacheSource.current[page] = response?.data?.items;
        } else {
          cacheSource.current[page] = [];
        }
        setPager((prev) => ({ ...prev, pageNum: page }));
        setLoading(false);
      },
      getAccountGroupFailed,
      dispatch,
      navigate
    );
  };

  const onSearch = useMemo(() => {
    const loadOptions = (val: string) => {
      cacheSource.current = {};
      setSearch(val);
      getAccountGroup(val);
    };
    return debounce(loadOptions, 500);
  }, []);

  useEffect(() => {
    getAccountGroup();
  }, [company]);

  return {
    hide,
    search,
    record,
    selectedRowKeys,
    pager,
    dataSource,
    loading,
    onSearch,
    opereteColumns,
    editType,
    company,
    handleCompanySelect,
    setHide,
    onPageChange,
    onSelectChange,
    handleReset,
    deleteAccount,
    handleEdit,
  };
};

export default useIndex;
