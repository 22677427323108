import React from "react";
import { type PageRouterProps } from "@/router";
import MovemoentList from "./list";
import MovementDetail from "./details";

const VoyageManageMovementRouter: PageRouterProps[] = [
  {
    // index: true,
    path: "movement/list",
    // key: "/layout/voyagemanage",
    key: "/layout/voyagemanage/movement/list",
    element: <MovemoentList />,
    label: "Movement"
  },
  {
    path: "movement/details",
    key: "/layout/voyagemanage/movement/details",
    element: <MovementDetail />,
    label: "Movement",
    notTab: true
  },
  // {
  //   key: "/layout/voyagemanage/movement/details",
  //   element: <MovementDetail />,
  //   path:"details",
  //   label: "Movement Details"
  // }
];

export default VoyageManageMovementRouter;