import { MutableRefObject } from "react";
import jsPDF from 'jspdf';
import html2canvas from 'html2canvas';

export const convertToDms = (latitude: number, longitude: number): string => {
  if (!latitude && !longitude) {
    return "00°00.000,00°00.000"
  }
  // 纬度部分处理
  const latDegree: number = Math.floor(Math.abs(latitude));
  const latMinute: number = Math.floor((Math.abs(latitude) - latDegree) * 60);
  const latSecond: string = ((Math.abs(latitude) - latDegree) * 3600 - latMinute * 60).toFixed(3);
  const latDirection: string = latitude >= 0 ? "N" : "S";

  // 经度部分处理
  const longDegree: number = Math.floor(Math.abs(longitude));
  const longMinute: number = Math.floor((Math.abs(longitude) - longDegree) * 60);
  const longSecond: string = ((Math.abs(longitude) - longDegree) * 3600 - longMinute * 60).toFixed(3);
  const longDirection: string = longitude >= 0 ? "E" : "W";

  // 拼接返回结果
  return (
    latDegree + "°" + latMinute.toFixed(3) + "'" + latDirection + ", " +
    longDegree + "°" + longMinute.toFixed(3) + "'" + longDirection
  );
}

export const downloadPdf = (canvasRef: MutableRefObject<HTMLCanvasElement>,fileName:string) => {
  // 将 Canvas 转换为 PDF
  html2canvas(canvasRef.current, { scale: window.devicePixelRatio * 2 }).then((canvasImage) => {
    console.log("canvasImage", canvasImage)
    // 创建一个新的 PDF 文档
    let leftHeight = canvasImage.height

    const a4Width = 200
    // const a4Height = 277 // A4大小，210mm x 297mm，四边各保留10mm的边距，显示区域190x277

    const pdf = new jsPDF('p', 'mm', 'a4') // A4纸，纵向
    // 将图像添加到 PDF 中
    pdf.addImage(canvasImage, 'JPEG', 0, 0, a4Width, a4Width / canvasImage.width * leftHeight)

    // 保存或下载 PDF 文件
    pdf.save(fileName + '.pdf');
  });
}