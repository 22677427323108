import React, { FC } from "react";
import classnames from "classnames";
import style from "./index.module.less";
import { MovementSummaryProps } from "./type";
import { useTranslation } from "react-i18next";
import { formatThousandthNumber } from "@/tools/amount";
import { Popover } from "@/_components";

const MovementSummary: FC<MovementSummaryProps> = ({ item, voyageType }) => {
	const { t } = useTranslation();
	return (
		<div
			className={classnames(style["movement-summary"], {
				[style["movement-summary-tctc"]]: voyageType === "TCTC"
			})}
		>
			<div className="movement-details--header">
				<span className="fleet-title">Summary</span>
			</div>
			<ul className="summary-contianer">
				{Object?.entries(item)?.map(([dataIndex, value], index) => {
					return (
						<li key={index}>
							<span className="summary-label">
								{t(`voyageManagement.movement.${dataIndex}`, {
									defaultValue: t(`common.${dataIndex}`)
								})}
							</span>
							<Popover
								placement="top"
								trigger="hover"
								content={
									<span
										className={classnames("summary-value", {
											highest: dataIndex === "disputeCharge"
										})}
									>
										{formatThousandthNumber(value)}
									</span>
								}
							>
								<span
									className={classnames("summary-value", {
										highest: dataIndex === "disputeCharge"
									})}
								>
									{formatThousandthNumber(value)}
								</span>
							</Popover>
						</li>
					);
				})}
			</ul>
		</div>
	);
};

export default MovementSummary;
