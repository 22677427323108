import { MutableRefObject, useRef, useState } from "react";
import useEstimation from "./useEstimation";
import useReminder from "@/hook/useReminder";
import {
	useEstCargoIndex,
	useEstPortIndex,
	useEstVesselIndex
} from "@/pages/estimation/business/details/components";
import { EstVesselParticularProps } from "@/pages/estimation/business/details/components/vesselParticular/type";
import useOtherIndex from "@/pages/estimation/business/details/hooks/useOtherIndex";
import {
	EstCompareItemType,
	EstListCompareCacheType
} from "@/pages/estimation/business/list/module";

export type IdentifierMapType = {
	vesselTrack: number;
	portGather: number;
	vesselPosition: number;
	portTrack: number;
	vesselHisTrack: number;
	trackPoints: number;
};

export type shippingMapType = {
	portTrack: any;
	portGather: any;
	vesselTrack: any;
	vesselPosition: AisVesselProps;
	vesselHisTrack: {
		[portCode: string | number]: { index: number[]; path: any[] };
	};
	trackPoints: any;
};

export type AisVesselProps = {
	lat: number | undefined;
	lon: number | undefined;
	heading: number | undefined;
};

export type IdentifierMapChangeEvent = (
	key: keyof IdentifierMapType,
	value: number | ((preVal: number) => number)
) => void;

export type IdentifierMapCommitEvent = (item: Partial<IdentifierMapType>) => void;

export type ShippingMapCommitEvent = (ships: Partial<shippingMapType>) => void;

export type ShippingVesselHisChangeEvent = (
	portCode: string | number,
	item: { index: number[]; path: any[] }
) => void;

const useGraphMap = () => {
	const [identifierMap, setGraphMap] = useState<IdentifierMapType>({
		vesselTrack: -1,
		portGather: -1,
		vesselPosition: -1,
		portTrack: -1,
		vesselHisTrack: -1,
		trackPoints: -1
	});

	const shippingMap = useRef<shippingMapType>({
		portTrack: undefined,
		portGather: undefined,
		vesselTrack: undefined,
		vesselPosition: {
			lat: undefined,
			lon: undefined,
			heading: undefined
		},
		vesselHisTrack: {},
		trackPoints: undefined
		// vesselHisTrack:undefined
	});

	const change = (key: keyof IdentifierMapType, value: number | ((preVal: number) => number)) => {
		if (typeof value === "function") {
			setGraphMap((prev) => {
				return {
					...prev,
					[key]: value?.(identifierMap[key])
				};
			});
			return;
		}
		setGraphMap((prev) => {
			return {
				...prev,
				[key]: value
			};
		});
	};

	const commitVesselHis: ShippingVesselHisChangeEvent = (portCode, item) => {
		commitShip({
			vesselHisTrack: {
				...shippingMap.current.vesselHisTrack,
				[portCode]: item
			}
		});
	};

	const commitShip = (ships: Partial<shippingMapType>) => {
		shippingMap.current = {
			...shippingMap.current,
			...ships
		};
	};

	const commitIdentifier = (ships: Partial<IdentifierMapType>) => {
		setGraphMap((prev) => {
			return {
				...prev,
				...ships
			};
		});
	};

	const reset = () => {
		setGraphMap({
			vesselTrack: -1,
			portGather: -1,
			vesselPosition: -1,
			portTrack: -1,
			vesselHisTrack: -1,
			trackPoints: -1
		});
		shippingMap.current = {
			portTrack: undefined,
			portGather: undefined,
			vesselTrack: undefined,
			vesselPosition: {
				lat: undefined,
				lon: undefined,
				heading: undefined
			},
			vesselHisTrack: {},
			trackPoints: undefined
		};
	};

	return {
		identifierMap,
		shippingMap,
		commitVesselHis,
		commitShip,
		change,
		reset,
		commitIdentifier
	};
};

const useEsDetail = (detailsId: number, cache?: MutableRefObject<EstListCompareCacheType>) => {
	const [loading, setLoading] = useState(false);
	const { reminder } = useReminder();

	const {
		identifierMap,
		shippingMap,
		commitShip: handleShipCommit,
		change: handleGraphMapChange,
		commitVesselHis: handleVesselHisCommit,
		reset: handleGraphMapReset,
		commitIdentifier: handleIdentifierCommit
	} = useGraphMap();

	const {
		dataSource: vesselItem,
		commit: handleVesselItemCommit,
		change: handleVesselItemChange,
		init: handleVesselItemInit,
		check: handleVesselItemCheck
	} = useEstVesselIndex(handleShipCommit, handleGraphMapChange);

	const {
		dataSource: cargoItem,
		change: handleCargoItemChange,
		commit: handleCargoItemCommit,
		init: handleCargoItemInit,
		check: handleCargoItemCheck
	} = useEstCargoIndex();

	const {
		dataSource: otherItem,
		change: handleOtherItemChange,
		commit: handleOtherItemCommit,
		init: handleOtherItemInit
	} = useOtherIndex();

	const {
		loading: queryLoading,
		dataSource: portList,
		delPortIds,
		portSeq,
		portCategory,
		check: handlePortItemCheck,
		commit: handlePortItemCommit,
		change: handlePortItemChange,
		init: handlePortItemInit,
		add: handlePortItemAdd,
		handlePortItemDelete,
		handlePortRotationConfirm,
		handleChangeCalcDistance,
		handleCalcDeliveryDistance,
		handleBatchCommit: handlePortBatchCommit,
		handleBatchFoConsumCommit
	} = useEstPortIndex(
		{
			deliveryPortCode: vesselItem?.deliveryPortCode,
			ballastSpeed: vesselItem?.ballastSpeed,
			ladenSpeed: vesselItem?.ladenSpeed
		},
		identifierMap,
		handleGraphMapChange,
		handleShipCommit
	);

	// const {
	//   portGroup,
	//   ballastPortList,
	//   portSeq,
	//   ladenPortList,
	//   savePortGroup,
	//   addPort,
	//   rmPort,
	//   check: handlePortGroupCheck,
	//   change: handlePortGroupChange,
	//   changeUnit: handleUnitChange,
	//   commit: handlePortGroupCommit,
	//   portCommit: handlePortCommit,
	//   changeQuantity: handleChangeQuantity,
	//   getPortDistance,
	//   getPorts,
	//   init: handlePortGroupInit
	// } = usePortGroup(
	//   vesselItem.deliveryPortCode,
	//   identifierMap,
	//   handleGraphMapChange,
	//   handleShipCommit
	// );

	// const {
	//   cargoForm,
	//   change: handleCargoChange,
	//   commit: handleCargoCommit,
	//   check: handleCargoCheck,
	// } = useCargoForm(handleUnitChange, handleChangeQuantity);

	const { calculate, quicklockForm } = useEstimation(portList, cargoItem, vesselItem, otherItem);

	const init = (item: EstCompareItemType) => {
		const { vesselParticular, cargoParticularList, portRotationList, ...rest } = item;
		handleVesselItemInit(vesselParticular);
		handleCargoItemInit(cargoParticularList?.[0]);
		handlePortItemInit(portRotationList);
		handleOtherItemInit(rest);
		portRotationList?.length >= 1 &&
			handleCalcDeliveryDistance(vesselParticular?.deliveryPortCode, portRotationList);
	};

	const handleDeliverySelect: EstVesselParticularProps["onDeliveryPortSelect"] = (item) => {
		handleVesselItemCommit({
			deliveryPortCode: item?.portCode,
			deliveryPortId: item?.id,
			deliveryPortName: item?.portName
		});
		debugger;
		if (item?.lon && item?.lat) {
			handleOtherItemChange("deliveryPort", [item?.lon, item?.lat]);
		} else {
			handleOtherItemChange("deliveryPort", []);
		}
		if (portList?.length >= 1) {
			handleChangeCalcDistance?.(0, item?.portCode, portList);
		}
	};

	const check = () => {
		const { checked: vesselChecked, checkKey: vesselCheckKey } = handleVesselItemCheck();
		if (!vesselChecked) {
			reminder("error", `Field ${vesselCheckKey} is required`);
			return false;
		}

		const { checked: cargoChecked, checkKey: cargoCheckKey } = handleCargoItemCheck();
		if (!cargoChecked) {
			reminder("error", `Field ${cargoCheckKey} is required`);
			return false;
		}

		const { checked: portChecked, checkKey: portCheckKey } = handlePortItemCheck();
		if (portList?.length <= 0) {
			reminder("error", "No loading or unloading port selected");
			return false;
		}
		if (!portChecked) {
			reminder("error", `Field ${portCheckKey} is required`);
			return false;
		}

		return true;
	};

	if (cache) {
		cache.current[detailsId] = {
			cargoParticularList: cargoItem,
			portRotationList: portList,
			vesselParticular: vesselItem,
			calculate,
			otherForm: otherItem,
			quicklockForm,
			id: detailsId,
			delPortIds: delPortIds,
			check: check
		};
	}

	return {
		queryLoading,
		portList,
		delPortIds,
		handlePortItemCommit,
		handlePortItemChange,
		handlePortRotationConfirm,
		handlePortItemAdd,
		handlePortItemDelete,
		handlePortBatchCommit,
		loading,
		portSeq,
		portCategory,
		calculate: {
			...calculate,
			id: detailsId
		},
		quicklockForm: quicklockForm,
		init,
		setLoading,
		handleDeliverySelect,
		check,
		shippingMap,
		identifierMap,
		handleShipCommit,
		handleGraphMapChange,
		handleVesselHisCommit,
		handleIdentifierCommit,
		vesselItem,
		handleVesselItemChange,
		handleVesselItemCommit,
		cargoItem,
		handleCargoItemChange,
		handleCargoItemCommit,
		otherItem,
		handleOtherItemChange,
		handleOtherItemCommit,
		handleBatchFoConsumCommit
	};
};

export default useEsDetail;
