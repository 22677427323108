import { downloadRoutePlanAction } from "@/action/monitoring/routePlan"
import { RoutePlanItemType } from "../../../type"
import { useState } from "react"
import { useAppDispatch } from "@/hook"
import { useNavigate } from "react-router-dom"
import useReminder from "@/hook/useReminder"

const useIndex = (currentRecord: RoutePlanItemType, onClose: () => void) => {
  const dispatch = useAppDispatch()
  const navigate = useNavigate()
  const { reminder } = useReminder();
  const [activeKey, setActiveKey] = useState<"A" | "B">("A")
  const [loading, setLoading] = useState(false)


  const handleChange = (val: "A" | "B") => {
    if (activeKey === val) return
    setActiveKey(val)
  }

  const downloadRoutePlanFront = () => {
    setLoading(true)
  }

  const downloadRoutePlanSuccess = (response) => {
    if (response instanceof Blob) {
      reminder("error", "There are no downloadable files for the current routing plan!");
      setLoading(false)
      return
    }
    setLoading(false)
    onClose?.()
  }

  const downloadRoutePlanFailed = (error) => {
    setLoading(false)
    reminder("error", error?.msg ? (error?.msg + ": " + error?.data) : error?.data)
  }

  const downloadRoutePlan = () => {
    downloadRoutePlanAction(
      { routePlanUuid: currentRecord.id, type: activeKey },
      downloadRoutePlanFront,
      downloadRoutePlanSuccess,
      downloadRoutePlanFailed,
      dispatch,
      navigate
    )
  }

  return {
    loading,
    activeKey,
    handleChange,
    downloadRoutePlan
  }
}

export default useIndex