import React, { FC } from "react";
import style from "./index.module.less";
import { InvoicesVoyageItemProps } from "./type";
import classnames from "classnames";
import dayjs from "dayjs";

const InvoicesVoyageItem: FC<InvoicesVoyageItemProps> = ({ item, activeItem, onSelect }) => {
	return (
		<div
			onClick={() => {
				console.log("item", item);
				onSelect?.(item);
			}}
			className={classnames(style["voygae-item"], {
				active: item?.id === activeItem?.id
			})}
		>
			{item?.voyageType && (
				<span className={classnames("voyageItem-voyageType", item?.voyageType)}>
					{item?.voyageType}
				</span>
			)}
			<div className="voyageItem-card header">
				<span>{item?.vesselName}</span>
				<span className={item?.status}>{item?.status}</span>
			</div>
			<div className="voyageItem-card">
				<span>Voyage No. {item?.voyageNo}</span>
				<span>PIC: {item?.pic}</span>
			</div>
			<div className="voyageItem-card">
				<span>Inovices:{item?.invTotal || 0}</span>
				<span>Last Update: {item?.lut ? dayjs(item?.lut)?.format("YYYY/MM/DD HH:mm") : ""}</span>
			</div>
		</div>
	);
};

export default InvoicesVoyageItem