import { type ParameterType } from "@/api";
import { loginOutAction } from "@/store/userSlice";
import {
  getMovementPortListApi,
  getMovementPortLogApi,
  saveMovementPortLogApi,
  analysisMovementPortLogApi,
  saveMovementPortLogParams,
  updateToMovementApi,
  updateToMovementParams,
  uploadMovementPortLogParams
} from "@/api/voyageManage/movement/portLog";

export const getMovementPortLogAction: ParameterType<number> = (
  id,
  frontCallback,
  successCallback,
  errorCallback,
  dispatch,
  navigate
) => {
  frontCallback?.();
  getMovementPortLogApi(id)
    .then((response) => {
      successCallback?.(response);
    })
    .catch((err) => {
      errorCallback?.(err);
      if (err?.loginStatus === "token faild") {
        // dispatch?.(loginOut({}));
        // navigate?.('/')
        dispatch(loginOutAction(() => navigate?.("/login")));
      }
    });
};

export const saveMovementPortLogAction: ParameterType<
  saveMovementPortLogParams
> = (
  params,
  frontCallback,
  successCallback,
  errorCallback,
  dispatch,
  navigate
) => {
  frontCallback?.();
  saveMovementPortLogApi(params)
    .then((response) => {
      successCallback?.(response);
    })
    .catch((err) => {
      errorCallback?.(err);
      if (err?.loginStatus === "token faild") {
        // dispatch?.(loginOut({}));
        // navigate?.('/')
        dispatch(loginOutAction(() => navigate?.("/login")));
      }
    });
};

export const getMovementPortListAction: ParameterType<number> = (
  id,
  frontCallback,
  successCallback,
  errorCallback,
  dispatch,
  navigate
) => {
  frontCallback?.();
  getMovementPortListApi(id)
    .then((response) => {
      successCallback?.(response);
    })
    .catch((err) => {
      errorCallback?.(err);
      if (err?.loginStatus === "token faild") {
        // dispatch?.(loginOut({}));
        // navigate?.('/')
        dispatch(loginOutAction(() => navigate?.("/login")));
      }
    });
};

export const analysisMovementPortLogAction: ParameterType<uploadMovementPortLogParams> = (
  params,
  frontCallback,
  successCallback,
  errorCallback,
  dispatch,
  navigate
) => {
  frontCallback?.();
  analysisMovementPortLogApi(params)
    .then((response) => {
      successCallback?.(response);
    })
    .catch((err) => {
      errorCallback?.(err);
      if (err?.loginStatus === "token faild") {
        // dispatch?.(loginOut({}));
        // navigate?.('/')
        dispatch(loginOutAction(() => navigate?.("/login")));
      }
    });
};

export const updateToMovementAction: ParameterType<updateToMovementParams> = (
  data,
  frontCallback,
  successCallback,
  errorCallback,
  dispatch,
  navigate
) => {
  frontCallback?.();
  updateToMovementApi(data)
    .then((response) => {
      successCallback?.(response);
    })
    .catch((err) => {
      errorCallback?.(err);
      if (err?.loginStatus === "token faild") {
        // dispatch?.(loginOut({}));
        // navigate?.('/')
        dispatch(loginOutAction(() => navigate?.("/login")));
      }
    });
};
