import { RequestApiMapType } from "@/api";
import { MovementTctcApiType, MovementTctcParamsType, movementTctcApiMap } from "./tctc";
import { offHireApiMap, MovementOffHireApiType, MovementOffHireParamsType } from "./offHire";
import {
	otherExpensesApiMap,
	MovementOtherExpensesApiType,
	MovementOtherExpensesParamsType
} from "./otherExpenses";
import {
	MovementOtherIncomeApiType,
	MovementOtherIncomeParamsType,
	otherIncomeApiMap
} from "./otherIncome";
import {
	MovementDisputeChargeApiType,
	MovementDisputeChargeParamsType,
	disputeChargeApiMap
} from "./disputeCharge";
import {
	bunkerPlanApiMap,
	MovementBunkerCostApiType,
	MovementBunkerCostParamsType
} from "./bunkerPlan";

import {
	movementPortExpensesApiMap,
	MovementPortExpensesApiType,
	MovementPortExpensesParamsType
} from "./portExpense";

export {
	getMovementGroupApi,
	deleteMovementApi,
	getMovementApi,
	getMovementStowagePlanApi,
	saveMovementStowagePlanApi,
	getMovementLaytimeGroupApi,
	getMovementLaytimeApi,
	saveMovementLaytimeGroupApi,
	deleteMovementLaytimeApi,
	saveMovementDetailApi,
	editMovementVoyageStatusApi,
	getMovementFilePathApi,
	setMovementMainPlanApi,
	updateMovementPlanApi,
	getMovementPlanDetailsApi,
	getMovementTctcApi
} from "./api";

export {
	getMovementGroupParams,
	getMovementParams,
	updateMovementTaskParams,
	getMovementStowagePlanParams,
	saveMovementStowagePlanParams,
	getMovementLaytimeParams,
	getMovementLaytimeGroupParams,
	saveMovementLaytimeGroupParams,
	deleteMovementLaytimeParams,
	saveMovementDetailParams,
	editMovementVoyageStatusParams,
	deleteMovementParams,
	getMovementFilePathParams,
	setMovementMainPlanParams,
	updateMovementPlanPayload,
	getMovementPlanDetailsParmas,
	getMovementTctcParams
} from "./type";

export {
	getMovementOtherIncomeApi,
	saveMovementOtherIncomeApi,
	getMovementOtherIncomeParams,
	saveMovementOtherIncomeParams
} from "./otherIncome";

export { downloadLaytimeAttachApi, downloadLaytimeAttachParams } from "./layTime";

export {
	downloadMovementPnlApi,
	initMovementDetailsApi,
	downloadMovementPnlParams,
	initMovementDetailsParams
} from "./pnl";

export {
	getMovementTaskListParams,
	getMovementVoyageTaskParams,
	getMovementTaskCommentListParams,
	saveMovementTaskCommentParams,
	deleteMovementTaskCommentParams,
	updateMovementTaskItemParams,
	saveMovementTaskConfigParams,
	updateMovementTaskItemApi,
	getMovementTaskListApi,
	getMovementVoyageTaskApi,
	getMovemenTaskCommentListApi,
	saveMovementTaskCommentApi,
	deleteMovementTaskCommentApi,
	saveMovementTaskConfigApi
} from "./task";

export type MovementApiType =
	| MovementTctcApiType
	| MovementOffHireApiType
	| MovementOtherExpensesApiType
	| MovementOtherIncomeApiType
	| MovementDisputeChargeApiType
	| MovementBunkerCostApiType
	| MovementPortExpensesApiType;

export type MovementParamsType =
	| MovementTctcParamsType
	| MovementOffHireParamsType
	| MovementOtherExpensesParamsType
	| MovementOtherIncomeParamsType
	| MovementDisputeChargeParamsType
	| MovementBunkerCostParamsType
	| MovementPortExpensesParamsType;

export const movementApiMap: RequestApiMapType<MovementApiType, MovementParamsType> = {
	...movementTctcApiMap,
	...offHireApiMap,
	...otherExpensesApiMap,
	...otherIncomeApiMap,
	...disputeChargeApiMap,
	...bunkerPlanApiMap,
	...movementPortExpensesApiMap
};
