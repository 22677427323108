import React, { useEffect, useState } from "react";
import { Table } from 'antd'
import type { ColumnsType } from 'antd/es/table';
import { FleetLoading } from "@/components";
import classnames from "classnames";
import useReminder from "@/hook/useReminder";
import { DetailDrawerItem } from '@/pages/monitoring/components/DetailDrawer'
import { getCPTermsAction } from '@/action/monitoring/vessel'
import style from './index.module.less'

export type CPTermProps = {
  className?: string,
  data: DetailDrawerItem,
}
interface DataType {
  id: number,
  cpTermsName: number,
  cpSpeed: string,
  cpFo: number,
  cpDoGo: number,
  voyageNo: number,
}

const CPTerm: React.FC<CPTermProps> = ({ data, className }) => {
  const columns: ColumnsType<DataType> = [
    {
      title: 'CP',
      dataIndex: 'cpTermsName',
      ellipsis: true,
    }, {
      title: 'Speed(kts)',
      dataIndex: 'cpSpeed',
      ellipsis: true,
    }, {
      title: 'FO(mt)',
      dataIndex: 'cpFo',
      ellipsis: true,
    }, {
      title: 'DO/GO(mt)',
      dataIndex: 'cpDoGo',
      ellipsis: true,
    }
  ]
  const [tableData, setTableData] = useState([])
  const [loading, setLoading] = useState<boolean>(false)
  const { reminder } = useReminder();

  useEffect(() => {
    loadCPTerms()
  }, [data?.voyageNo])

  const loadCPTerms = async () => {
    try {
      setLoading(true)
      const res = await getCPTermsAction(data?.voyageNo)
      setLoading(false)
      const list = res?.data || []
      setTableData(list)
    } catch (error) {
      setTableData([])
      reminder("error", error?.data)
    }
  }

  return <div className={classnames([style.cpTerm, style[className]])}>
    <FleetLoading loading={loading} />
    <Table className={style.table} rowKey="id" columns={columns} dataSource={tableData} pagination={false} scroll={{ y: 132 }} />
  </div >
}

export default CPTerm
