import { AreaSelectItemType, AreaSelectProps } from "../type";
import useCommonIndex from "./useCommon";
import {
	getAreaInfoListAction,
	getCountryListAction,
	getPortCodeListAction
} from "@/action/monitoring/area";
const useIndex = (
	activeItems: Partial<AreaSelectItemType> | Partial<AreaSelectItemType>[],
	showKey: keyof AreaSelectItemType,
	onFinish: (newItem: AreaSelectItemType, operateType: "add" | "del") => void,
	primaryKey: keyof AreaSelectItemType = "id",
	disabled: boolean,
	type: AreaSelectProps["type"],
	params: AreaSelectProps["params"]
) => {
	const {
		loading,
		open,
		list,
		searchValue,
		selectValue,
		page,
		handleSearch,
		focusPopup,
		closePopup,
		loadMore,
		handleItemSelect,
		tagRender
	} = useCommonIndex<AreaSelectItemType, keyof AreaSelectItemType>(
		activeItems,
		showKey,
		onFinish,
		type === "areaName"
			? getAreaInfoListAction
			: type === "countryName"
				? getCountryListAction
				: getPortCodeListAction,
		primaryKey,
		disabled,
		params
	);

	return {
		loading,
		open,
		list,
		searchValue,
		selectValue,
		page,
		handleSearch,
		focusPopup,
		closePopup,
		loadMore,
		handleItemSelect,
		tagRender
	};
};

export default useIndex;
