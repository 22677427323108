import React, { FC } from "react";
import style from "./index.module.less";
import { RouteCommonVesselCardProps } from "./type";
import { RouteCommonVesselCardItem } from "./components";
import classNames from "classnames";

const RouteCommonVesselCard: FC<RouteCommonVesselCardProps> = ({
	activeAisItem,
	activeLegItem,
	activePortItem,
	activeAreaItem,
	onSelect,
	onClose,
	onTrackRefresh,
	onAisListReset
}) => {
	return (
		<div className={classNames(style["routeCommon-vesselCard"], "fleet-nonScrolBar")}>
			{activeLegItem && (
				<RouteCommonVesselCardItem
					mode="leg"
					activeItem={activeLegItem}
					onSelect={onSelect}
					onClose={onClose}
					onTrackRefresh={onTrackRefresh}
				/>
			)}
			{activePortItem && (
				<RouteCommonVesselCardItem
					mode="port"
					activeItem={activePortItem}
					onSelect={onSelect}
					onClose={onClose}
					onTrackRefresh={onTrackRefresh}
				/>
			)}
			{activeAreaItem && (
				<RouteCommonVesselCardItem
					mode="area"
					activeItem={activeAreaItem}
					onSelect={onSelect}
					onClose={onClose}
					onTrackRefresh={onTrackRefresh}
				/>
			)}
			{activeAisItem && (
				<RouteCommonVesselCardItem
					mode="ais"
					activeItem={activeAisItem}
					onSelect={onSelect}
					onClose={onClose}
					onTrackRefresh={onTrackRefresh}
					onAisListReset={onAisListReset}
				/>
			)}
		</div>
	);
};

export default RouteCommonVesselCard;
