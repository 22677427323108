import React from "react";
import style from "./index.module.less";
import { MovementLaytimeSubTotalProps } from "./type";
import { FleetVerifyInput } from "@/components";

const LaytimeSubTotal: React.FC<MovementLaytimeSubTotalProps> = ({
  item,
  onChange
}) => {
  return (
		<div className={style["movement-subTotal"]}>
			<div className="subTotal-header">
				<span className="fleet-title">Laytime Subtotal</span>
			</div>
			<div className="subTotal-container">
				<FleetVerifyInput
					disabled
					label="Vessel Name"
					value={item?.vesselName ?? ""}
					onChange={(val: string) => {
						onChange?.(item?.id, "vesselName", val);
					}}
				/>
				<FleetVerifyInput
					disabled
					label="Cargo Name"
					value={item?.cargoName ?? ""}
					onChange={(val: string) => {
						onChange?.(item?.id, "cargoName", val);
					}}
				/>
				<FleetVerifyInput
					label="Cargo Quantity(MT)"
					type="number"
					disabled={!item}
					value={item?.cargoQuantity ?? ""}
					onChange={(val: number) => {
						onChange?.(item?.id, "cargoQuantity", val);
					}}
				/>
				<FleetVerifyInput
					label="L/D Rate(MT/Day)"
					type="number"
					// placeholder="MT/Day"
					disabled={!item}
					value={item?.loadingDischargeRate ?? ""}
					onChange={(val: number) => {
						onChange?.(item?.id, "loadingDischargeRate", val);
					}}
				/>
				<FleetVerifyInput
					label="DEM Rate(USD/Day)"
					type="number"
					// placeholder="MT/Day"
					disabled={!item}
					value={item?.demRate ?? ""}
					onChange={(val: number) => {
						onChange?.(item?.id, "demRate", val);
					}}
				/>
				<FleetVerifyInput
					label="DES Rate(USD/Day)"
					type="number"
					// placeholder="MT/Day"
					disabled={!item}
					value={item?.desRate ?? ""}
					onChange={(val: number) => {
						onChange?.(item?.id, "desRate", val);
					}}
				/>
			</div>
		</div>
	);
}

export default LaytimeSubTotal