import { InvFreightDescInitItemType, InvFreightDescProps } from "../type";
import { useCargoDetailsIndex, useSettlementIndex } from "../components";
import { useOtherExpensesIndex } from "../../../../../common";

const useIndex = (otherItem: InvFreightDescProps["otherItem"]) => {
	const {
		dataSource: cargoItems,
		delIds: delCargoIds,
		summary: ttlSummary,
		change: handleCargoItemChange,
		add: handleCargoDetailAdd,
		remove: handleCargoDetailDelete,
		init: handleCargoDetailInit,
		check: handleCargoDetailCheck
	} = useCargoDetailsIndex({ addCargo: otherItem?.addCargo });

	const {
		summary: otherExpensesSummary,
		dataSource: otherExpenses,
		delIds: otherExpenseDelIds,
		change: handleOtherExpensesChange,
		add: handleOtherExpensesAdd,
		remove: handleOtherExpensesDelete,
		init: handleOtherExpensesInit
	} = useOtherExpensesIndex(otherItem);

	const {
		loadDataSource,
		dischargeDataSource,
		delPortIds,
		handlePortItemChange,
		handlePortItemCommit,
		handlePortAdd,
		handlePortRemove,
		handlePortInit,
		settlement: settleItems,
		summary,
		handleSettlementCommit,
		handleSettlementInit,
		handleSettlementCheck
	} = useSettlementIndex({
		addDesDem: otherItem?.addDesDem,
		addCargo: otherItem?.addCargo,
		addFreightIncome: otherItem?.addFreightIncome,
		ttlSummary
	});

	const check = () => {
		const { checkKey, checked } = handleCargoDetailCheck();
		if (!checked) return { checkKey, checked };

		return handleSettlementCheck();
	};

	const init = (type: "init" | "details", item: Partial<InvFreightDescInitItemType>) => {
		handleCargoDetailInit(type, item?.cargoDetailList);
		handlePortInit(type, item?.loadPortDemDes, item?.dischargeDemDes);
		handleSettlementInit(item);
		handleOtherExpensesInit(type, item?.otherFieldList);
	};

	return {
		cargoItems,
		delCargoIds,
		handleCargoItemChange,
		handleCargoDetailAdd,
		handleCargoDetailDelete,
		loadDataSource,
		dischargeDataSource,
		delPortIds,
		handlePortItemChange,
		handlePortItemCommit,
		handlePortAdd,
		handlePortRemove,
		settleItems,
		summary,
		otherExpensesSummary,
		handleSettlementCommit,
		check,
		init,
		otherExpenses,
		otherExpenseDelIds,
		handleOtherExpensesChange,
		handleOtherExpensesAdd,
		handleOtherExpensesDelete
	};
};

export default useIndex;
