import { ExcludeKeys, ExtendsKeys, GroupOptionItemType, GroupOptionsType } from "@/types";
import { ColumnsType } from "antd/es/table/interface";
import { HeadFixtueKeys, HeadFixtueLabelMap } from ".";
import { Key } from "react";
import { dateFormat } from "@/tools/date";
import React from "react";

/**
 * 表格列筛选分类名
 */
export type HFCategoryKeys =
  "Basic" |
  "HireAndFees" |
  "Duration" |
  "Payment Terms" |
  "Setup";


/**
 * 表格列
 */

export type HFGroupColumnKeys = ExtendsKeys<HeadFixtueKeys,
  "ownerName" |
  "vesselName" |
  "cpDate" |
  "tradingArea" |
  "layCanFrom" |
  "layCanTo" |
  "deliveryPortCode" |
  "deliveryPortName" |
  "deliveryDate" |
  "estRedeliveryDate" |
  "redeliveryPortCode" |
  "redeliveryPortName" |
  "cpNo" |
  "charteredBy" |
  "dailyHire" |
  "commission" |
  "brokerage" |
  "brokerName" |
  "ilohc" |
  "cve" |
  // "paymentTerm" |
  // "paymentRate" |
  "paymentStartDay" |
  "maxDuration" |
  "minDuration" |
  "createdBy" |
  "operatedBy" |
  "updatedDate" |
  "status" |
  "id" |
  "voyageId" |
  "option"
>;

export type DataType = {
  key: Key;
} & Record<HFGroupColumnKeys, any>;

export const columns: ColumnsType<DataType> = [
  {
    dataIndex: "ownerName",
    "width": 150,
    "title": "Owner Name",
    key: "ownerName",
    "align": "center",
    "fixed": "left"
  },
  {
    dataIndex: "vesselName",
    "width": 150,
    "title": "Vessel Name",
    key: "vesselName",
    "align": "center",
    "fixed": "left"
  },
  {
    dataIndex: "cpDate",
    "width": 100,
    "title": "CP Date",
    key: "cpDate",
    "align": "center",
    "fixed": "left"
  },
  {
    dataIndex: "tradingArea",
    "width": 150,
    "title": "Trading Area",
    key: "tradingArea",
    "align": "center"
  },
  {
    dataIndex: "layCanFrom",
    "width": 150,
    "title": "Laycan From",
    key: "layCanFrom",
    "align": "center",
    render:(value,record) => {
      return <span>{dateFormat("YY/mm/dd HH:MM",value)}</span>
    }
  },
  {
    dataIndex: "layCanTo",
    "width": 150,
    "title": "Laycan To",
    key: "layCanTo",
    "align": "center",
    render:(value,record) => {
      return <span>{dateFormat("YY/mm/dd HH:MM",value)}</span>
    }
  },
  {
    dataIndex: "deliveryPortName",
    "width": 150,
    "title": "Delievry Port",
    key: "deliveryPortName",
    "align": "center"
  },
  {
    dataIndex: "deliveryDate",
    "width": 150,
    "title": "Delivery Date",
    key: "deliveryDate",
    "align": "center",
    render:(value,record) => {
      return <span>{dateFormat("YY/mm/dd HH:MM",value)}</span>
    }
  },
  {
    dataIndex: "redeliveryPortName",
    "width": 180,
    "title": "Est. Redelivery Port",
    key: "redeliveryPortName",
    "align": "center"
  },
  {
    dataIndex: "estRedeliveryDate",
    "width": 180,
    "title": "Est. Redelivery Date",
    key: "estRedeliveryDate",
    "align": "center",
    render:(value,record) => {
      return <span>{dateFormat("YY/mm/dd HH:MM",value)}</span>
    }
  },
  {
    dataIndex: "cpNo",
    "width": 100,
    "title": "CP No.",
    key: "cpNo",
    "align": "center"
  },
  {
    dataIndex: "dailyHire",
    "width": 100,
    "title": "Daily Hire",
    key: "dailyHire",
    "align": "center"
  },
  {
    dataIndex: "brokerName",
    "width": 150,
    "title": "Broker Name",
    key: "brokerName",
    "align": "center"
  },
  {
    dataIndex: "brokerage",
    "width": 100,
    "title": "Brokerage",
    key: "brokerage",
    "align": "center"
  },
  {
    dataIndex: "commission",
    "width": 150,
    "title": "Add. Comm.",
    key: "commission",
    "align": "center"
  },
  {
    dataIndex: "ilohc",
    "width": 100,
    "title": "ILOHC",
    key: "ilohc",
    "align": "center"
  },
  {
    dataIndex: "cve",
    "width": 100,
    "title": "CVE",
    key: "cve",
    "align": "center"
  },
  // {
  //   dataIndex: "paymentTerm",
  //   "width": 150,
  //   "title": "Payment Term",
  //   key: "paymentTerm",
  //   "align": "center"
  // },
  {
    dataIndex: "paymentStartDay",
    "width": 150,
    "title": "Payment Timing",
    key: "paymentStartDay",
    "align": "center"
  },
  // {
  //   dataIndex: "paymentRate",
  //   "width": 150,
  //   "title": "Payment Method",
  //   key: "paymentRate",
  //   "align": "center"
  // },
  {
    dataIndex: "minDuration",
    "width": 150,
    "title": "Min. Duration",
    key: "minDuration",
    "align": "center"
  },
  {
    dataIndex: "maxDuration",
    "width": 150,
    "title": "Max. Duration",
    key: "maxDuration",
    "align": "center"
  },
  {
    dataIndex: "createdBy",
    "width": 150,
    "title": "Created By",
    key: "createdBy",
    "align": "center"
  },
  {
    dataIndex: "charteredBy",
    "width": 150,
    "title": "Chartered By",
    key: "charteredBy",
    "align": "center"
  },
  {
    dataIndex: "operatedBy",
    "width": 150,
    "title": "Operated By",
    key: "operatedBy",
    "align": "center"
  },
  {
    dataIndex: "updatedDate",
    "width": 150,
    "title": "Updated Date",
    key: "updatedDate",
    "align": "center"
  },
  {
    dataIndex: "status",
    "width": 100,
    "title": "Status",
    key: "status",
    "align": "center"
  }
];

/**
 * 列筛选分类
 */

export type HFGroupOptionItemKeys = ExcludeKeys<
  HFGroupColumnKeys,
  "deliveryPortCode" |
  "redeliveryPortCode" |
  "id"
>;

export type HFGroupOptionItemType = GroupOptionItemType<ExcludeKeys<
  HFGroupColumnKeys,
  "deliveryPortCode" |
  "redeliveryPortCode"
>>;

export type HFGroupOptionsType = GroupOptionsType<HFCategoryKeys, ExcludeKeys<
  HFGroupColumnKeys,
  "deliveryPortCode" |
  "redeliveryPortCode"
>>;


export const HFGroupOptions: HFGroupOptionsType[] = [
  {
    key: "Basic",
    basicDisabled: true,
    list: [
      {
        "value": "ownerName",
        "label": HeadFixtueLabelMap["ownerName"],
        disabled: true
      },
      {
        "value": "vesselName",
        "label": HeadFixtueLabelMap["vesselName"],
        disabled: true
      },
      {
        "value": "cpDate",
        "label": HeadFixtueLabelMap["cpDate"],
        disabled: true
      },
      {
        "value": "layCanFrom",
        "label": HeadFixtueLabelMap["layCanFrom"],
        disabled: true
      },
      {
        "value": "layCanTo",
        "label": HeadFixtueLabelMap["layCanTo"],
        disabled: true
      },
      {
        "value": "deliveryPortName",
        "label": HeadFixtueLabelMap["deliveryPortName"],
        disabled: true
      },
      {
        "value": "deliveryDate",
        "label": HeadFixtueLabelMap["deliveryDate"],
        disabled: true
      },
      {
        "value": "estRedeliveryDate",
        "label": HeadFixtueLabelMap["estRedeliveryDate"],
        disabled: true
      },
      {
        "value": "redeliveryPortName",
        "label": HeadFixtueLabelMap["redeliveryPortName"],
        disabled: true
      },
      {
        "value": "cpNo",
        "label": HeadFixtueLabelMap["cpNo"],
        disabled: true
      }
    ]
  },
  {
    key: "HireAndFees",
    basicDisabled: false,
    list: [
      {
        "value": "dailyHire",
        "label": HeadFixtueLabelMap["dailyHire"]
      },
      {
        "value": "commission",
        "label": HeadFixtueLabelMap["commission"]
      },
      {
        "value": "brokerage",
        "label": HeadFixtueLabelMap["brokerage"]
      },
      {
        "value": "ilohc",
        "label": HeadFixtueLabelMap["ilohc"]
      },
      {
        "value": "cve",
        "label": HeadFixtueLabelMap["cve"]
      },
    ]
  },
  {
    key: "Payment Terms",
    basicDisabled: false,
    list: [
      // {
      //   "value": "paymentTerm",
      //   "label": HeadFixtueLabelMap["paymentTerm"]
      // },
      // {
      //   "value": "paymentRate",
      //   "label": HeadFixtueLabelMap["paymentRate"]
      // },
      {
        "value": "paymentStartDay",
        "label": HeadFixtueLabelMap["paymentStartDay"]
      }
    ]
  },
  {
    key: "Duration",
    basicDisabled: false,
    list: [
      {
        "value": "minDuration",
        "label": HeadFixtueLabelMap["minDuration"]
      },
      {
        "value": "maxDuration",
        "label": HeadFixtueLabelMap["maxDuration"]
      }
    ]
  },
  {
    key: "Setup",
    basicDisabled: true,
    list: [
      {
        "value": "createdBy",
        "label": HeadFixtueLabelMap["createdBy"]
      },
      {
        "value": "charteredBy",
        "label": HeadFixtueLabelMap["charteredBy"]
      },
      {
        "value": "operatedBy",
        "label": HeadFixtueLabelMap["operatedBy"]
      },
      {
        "value": "updatedDate",
        "label": HeadFixtueLabelMap["updatedDate"]
      },
      {
        "value": "status",
        "label": HeadFixtueLabelMap["status"],
        disabled: true
      }
    ]
  }
];

/**
 * 分类筛选
 */

export type HFCategoryGroupType = Record<HFCategoryKeys, HFGroupColumnKeys[]>;

export const initHFCategoryGroup: HFCategoryGroupType = {
  "Basic": ["ownerName", "vesselName", "cpDate", "tradingArea", "layCanFrom", "layCanTo", "deliveryPortName", "deliveryDate", "redeliveryPortName", "estRedeliveryDate", "cpNo"],
  "HireAndFees": [],
  "Duration": [],
  "Payment Terms": [],
  "Setup": ["status"]
}