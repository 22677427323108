import { useRouteCommonContext } from "@/pages/routeCommon/store";
import { useEffect, useState } from "react";
import { RouteCommonRoutePlanDetailsProps, RoutePlanWayPointItemType } from "../type";
import { useAppDispatch } from "@/hook";
import { useNavigate } from "react-router-dom";
import useReminder from "@/hook/useReminder";
import { onRequest } from "@/action";

const useIndex = () => {
	const dispatch = useAppDispatch();
	const navigate = useNavigate();
	const { reminder } = useReminder();
	const { activeDetailsOption, onDetailsOptionCommit } = useRouteCommonContext();

	const [loading, setLoading] = useState(false);
	const [dataSource, setDataSource] = useState<RoutePlanWayPointItemType[]>([]);

	const getRoutePlanWayPointFront = () => {
		setLoading(true);
	};

	const getRoutePlanWayPointSuccess = (response) => {
		setLoading(false);
		setDataSource((prev) => {
			return response?.data ?? [];
		});
	};

	const getRoutePlanWayPointFailed = (error) => {
		setLoading(false);
		reminder("error", error?.msg ? error?.msg + ": " + error?.data : error?.data);
	};

	const getRoutePlanWayPoint = () => {
		onRequest(
			"getRouteCommonRoutePlanDetailsApi",
			{ id: activeDetailsOption?.activeRoutePlan?.id },
			getRoutePlanWayPointFront,
			getRoutePlanWayPointSuccess,
			getRoutePlanWayPointFailed,
			dispatch,
			navigate
		);
	};

	useEffect(() => {
		if (activeDetailsOption?.activeTab === "routePlans" && !!activeDetailsOption?.activeRoutePlan) {
			getRoutePlanWayPoint();
		}
	}, [activeDetailsOption]);

	return {
		activeDetailsOption,
		loading,
		dataSource,
		onDetailsOptionCommit
	};
};

export default useIndex;
