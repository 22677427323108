import { useCallback, useMemo, useRef, useState } from "react";
import { DetailDelaysTableDisabledType } from "../type";
import useReminder from "@/hook/useReminder";
import dayjs from "dayjs";
const useIndex = (addRow, deleteRow,detailDelaysData) => {
  const [detailDelaysTableDisabled, setDetailDelaysTableDisabled] =
    useState<DetailDelaysTableDisabledType>([]);
  const [first, setFirst] = useState(true);
  const onMouse = (index, prop, type) => {
    if (type == "enter") {
      const newDisabled = JSON.parse(JSON.stringify(detailDelaysTableDisabled));
      newDisabled.splice(index, 1, {
        ...detailDelaysTableDisabled[index],
        [prop]: false,
      });
      setDetailDelaysTableDisabled(newDisabled);
    } else {
      const newDisabled = JSON.parse(JSON.stringify(detailDelaysTableDisabled));
      newDisabled.splice(index, 1, {
        ...detailDelaysTableDisabled[index],
        [prop]: true,
      });
      setDetailDelaysTableDisabled(newDisabled);
    }
  };
  const handleAdd = () => {
    const newDisabled = JSON.parse(JSON.stringify(detailDelaysTableDisabled));
    newDisabled.push({ date: true, remark: true });
    setDetailDelaysTableDisabled(newDisabled);
    addRow({
      sno: detailDelaysData.length+1,
      fromTime: dayjs().valueOf(),
      toTime: dayjs().valueOf(),
      remarks: "",
    });
  };
  const handleDelete = (index) => {
    const newDisabled = JSON.parse(JSON.stringify(detailDelaysTableDisabled));
    newDisabled.splice(index, 1);
    setDetailDelaysTableDisabled(newDisabled);
    deleteRow(index);
  };
  const cellValue = (row, prop) => {
    if (["fromTime", "toTime"].includes(prop)) {
      return row[prop] ? dayjs(row[prop]).format("YYYY-MM-DD HH:mm") : null;
    } else {
      return row[prop] || null;
    }
  };
  return {
    detailDelaysTableDisabled,
    onMouse,
    handleAdd,
    handleDelete,
    cellValue,
    first,
    setFirst,
    setDetailDelaysTableDisabled,
  };
};
export default useIndex;
