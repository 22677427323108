import { useCommonIndex } from "@/hook"
import { useInvFieldNames } from "@/pages/voyagemanage/business/invoices/module"
import { checkKeys, initialStatementBasicsItem } from "../source"

const useIndex = () => {
  const {
    delIds,
    dataSource: fieldItems,
    change: handleBasicsFieldItemChange,
    remove: handleBasicsFieldDelete,
    init: handleBasicsFieldInit,
    add: handleBasicsFieldAdd
  } = useInvFieldNames()

  const {
    dataSource,
    change,
    commit,
    init,
    check
  } = useCommonIndex(initialStatementBasicsItem, checkKeys)

  return {
    fieldItems,
    handleBasicsFieldItemChange,
    handleBasicsFieldDelete,
    handleBasicsFieldInit,
    handleBasicsFieldAdd,
    dataSource,
    change,
    commit,
    init,
    check
  }
}

export default useIndex