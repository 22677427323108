import { CommonUserItemType } from "@/common/Select";
import { ExcludeKeys, ExtendsKeys } from "@/types";
import { HeadFixtueKeys } from "@/types/voyageManage/headFixtureList";

export type HfBasicKeys = ExtendsKeys<HeadFixtueKeys,
	"cpNo" | "ownerName" | "vesselName" | "cpDate" |
	"vesselNameCn" | "vesselNameEn" | "charteredBy" |
	"tradingArea" | "layCanFrom" | "layCanTo" | "deliveryPortCode" |
	"deliveryPortName" | "deliveryDate" | "minDuration" | "maxDuration" |
	"estRedeliveryDate" | "redeliveryPortCode" | "redeliveryPortName" |
	"mmsi" | "bale" | "grain" | "imo" | "dwt" | "charateList" | "id"
>;

export type HfBasicNumKeys = ExtendsKeys<HfBasicKeys,
	"cpNo" | "minDuration" | "maxDuration" |
	"bale" | "dwt" | "imo" | "grain" | "mmsi" | "layCanFrom" | "layCanTo" | "estRedeliveryDate" | "deliveryDate"
>;

export type HfBasicDateKeys = ExtendsKeys<HfBasicKeys,
	"layCanFrom" | "layCanTo"
>;

export type HfBasicEnumKeys = ExtendsKeys<HfBasicKeys,
	"charteredBy" | "charateList" | "id"
>;

export type HfBasicStrKeys = ExcludeKeys<HfBasicKeys, HfBasicNumKeys | HfBasicEnumKeys>;

export type HfBasicFormType = Record<HfBasicNumKeys, number | "">
	&
	Record<HfBasicStrKeys, string>
	// &
	// Record<HfBasicDateKeys, Dayjs | undefined>
	&
	{
		[K in ExcludeKeys<HfBasicEnumKeys,"id">]: K extends "charteredBy" ? string[] : (
			K extends "charateList" ? CommonUserItemType[] : never
		);
	} & {
		id?: number;
	}

export type HfBasicFormKeys = HfBasicStrKeys | HfBasicNumKeys | HfBasicEnumKeys;

export type HfBasicFormValues = HfBasicFormType[HfBasicFormKeys];

/**
 * hooks type
 */

export type HfBasicFormChangeEvent = <K extends HfBasicFormKeys>(key: K, value: HfBasicFormType[K]) => void;

export type HfBasicFormCommitEvent = (item: Partial<HfBasicFormType>) => void;

/**
 * hfbasicprops type
 */

export type HfBasicFormProps = {
	item: HfBasicFormType;
	onChange: HfBasicFormChangeEvent;
	onCommit: HfBasicFormCommitEvent;
	type: "edit" | "newAdd" | "preOperation";
}


/**
 * init data
 */
export const initialBasicForm: HfBasicFormType = {
	cpNo: "",
	minDuration: "",
	maxDuration: "",
	bale: "",
	dwt: "",
	imo: "",
	grain: "",
	mmsi: "",
	charteredBy: [],
	layCanFrom: "",
	layCanTo: "",
	ownerName: "",
	vesselName: "",
	cpDate: "",
	vesselNameCn: "",
	vesselNameEn: "",
	tradingArea: "",
	deliveryPortCode: "",
	deliveryPortName: "",
	deliveryDate: "",
	estRedeliveryDate: "",
	redeliveryPortCode: "",
	redeliveryPortName: "",
	charateList:[]
}

export const checkBasicFormKeys: Partial<HfBasicFormKeys>[] = [
	"ownerName", "vesselName", "cpDate",
	"deliveryPortCode", "deliveryPortName"
	// "layCanFrom", "layCanTo"
]