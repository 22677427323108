import { useAppDispatch } from "@/hook";
import useReminder from "@/hook/useReminder";
import { useMemo, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { RouteCommonAisItemType } from "../..";
import { getAisGroupAction } from "@/action/monitoring/aisGroup";
import debounce from "lodash/debounce";
import { RouteAisFilterProps } from "../type";

const useIndex = (
	activeItem: RouteAisFilterProps["activeItem"],
	onSelect: RouteAisFilterProps["onSelect"]
) => {
	const dispatch = useAppDispatch();
	const navigate = useNavigate();
	const { reminder } = useReminder();

	const [open, setOpen] = useState(false);
	const [keyword, setKeyword] = useState<string>("");
	const [loading, setLoading] = useState<boolean>(false);
	const pager = useRef<{
		total: number;
		pageNum: number;
		pageSize: number;
	}>({
		total: 0,
		pageNum: 1,
		pageSize: 20
	});

	const [dataSource, setDataSource] = useState<RouteCommonAisItemType[]>([]);

	const selectValue = useMemo(() => {
		return [activeItem?.currentState?.vesselName];
	}, [dataSource, activeItem]);

	const getAisGroupFront = () => {
		setLoading(true);
	};
	const getAisGroupFailed = (error) => {
		setLoading(false);
		reminder("error", error?.msg ? error?.msg + ": " + error?.data : error?.data);
	};

	const getAisGroupSuccess = (response) => {
		setLoading(false);
		if ((pager.current.pageNum + 1) * pager.current.pageSize < response?.data?.totalCount) {
			setDataSource((prev) => [...prev, ...response?.data?.items]);
		} else {
			setDataSource(response?.data?.items);
		}
		pager.current = {
			...pager.current,
			total: response?.data?.totalCount,
			pageNum: pager.current.pageNum + 1
		};
	};

	const getAisGroup = (searchVal?: string) => {
		if (dataSource?.length !== 0 && dataSource?.length >= pager?.current.total) return;
		getAisGroupAction(
			{
				keyword: searchVal ?? keyword,
				pageNum: pager.current.pageNum,
				pageSize: pager.current.pageSize
			},
			getAisGroupFront,
			getAisGroupSuccess,
			getAisGroupFailed,
			dispatch,
			navigate
		);
	};

	const handleSearch = useMemo(() => {
		const loadOptions = (val: string) => {
			pager.current.pageNum = 1;
			pager.current = {
				...pager.current,
				pageNum: 1,
				total: 0
			};
			setDataSource([]);
			setKeyword(val);
			getAisGroup(val);
		};
		return debounce(loadOptions, 800);
	}, []);

	const handleClosePopup = (open: boolean) => {
		setOpen(open);
	};

	const handleItemSelect = (item: RouteCommonAisItemType) => {
		setOpen(false);
		if (!item?.currentState) {
			reminder("info", "There is currently no current location information for this ship");
			return;
		}
		if (activeItem?.currentState?.imo === item?.currentState?.imo) return;
		onSelect?.(item);
	};

	return {
		open,
		keyword,
		loading,
		dataSource,
		selectValue,
		handleClosePopup,
		handleSearch,
		getAisGroup,
		handleItemSelect
	};
};

export default useIndex;
