import useReminder from "@/hook/useReminder";
import { ExcludeKeys } from "@/types";
import { VoyageType } from "@/types/common";
import { useEffect, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";

const useIndex = () => {
	const navigate = useNavigate();
	const { reminder } = useReminder();
	const [searchParams] = useSearchParams();
	const voyageId = searchParams?.get("id"),
		voyageType = searchParams?.get("voyageType") as ExcludeKeys<VoyageType, "VCVC">;

	const [loading, setLoading] = useState(false);

	useEffect(() => {
		if (!voyageType) {
			reminder("warning", "The voyage type is incorrect, please select a new voyage!");
			setTimeout(() => {
				navigate("/layout/voyagemanage");
			}, 1000);
			return;
		}
	}, []);

	return {
		voyageId,
		voyageType,
		loading,
		setLoading
	};
};

export default useIndex;
