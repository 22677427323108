import React, { useEffect, useMemo, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import debounce from "lodash/debounce";
import { CommonRoleWrap, FleetToast } from "@/common";
import { FleetTooltip } from "@/components";
import { useAppDispatch } from "@/hook";
import useReminder from "@/hook/useReminder";
import { DatabaseTaskItemType } from "@/featrue/database/task/edit/type";
import { deleteTaskAction, getTaskGroupAction } from "@/action/dataBase/task";
import { columns as list_columns } from "@/pages/database/business/task/type";
import { Popover } from "@/_components";

const useGroup = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { reminder } = useReminder();
  const record = useRef<DatabaseTaskItemType>(null);
  const cacheSource = useRef<{ [index: number]: any[] }>({});
  const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([]);
  const [loading, setLoading] = useState(false);
  const [isRefresh, setIsRefresh] = useState(false);
  const [pager, setPager] = useState<{
    total: number;
    pageNum: number;
    pageSize: number;
  }>({ total: 0, pageNum: 0, pageSize: 20 });

  const [search, setSearch] = useState("");
  const [hide, setHide] = useState(true);
  const [type, setType] = useState<"add" | "edit">("add");

  const dataSource = useMemo(() => {
    return cacheSource.current[pager.pageNum];
  }, [pager.pageNum, isRefresh]);

  const delTaskMultiple = (
    type: "multiple" | "single" = "multiple",
    id?: string
  ) => {
    deleteTaskAction(
      type === "multiple" ? selectedRowKeys : [id],
      getTaskGroupFront,
      delTaskResultSuccess,
      getTaskGroupError,
      dispatch,
      navigate
    );
  };

  const delTaskResultSuccess = (response) => {
    reminder("success", response?.msg);
    setSelectedRowKeys([]);
    getTaskGroup();
  };

  const getTaskGroupError = (error) => {
    reminder(
      "error",
      error?.msg ? error?.msg + ": " + error?.data : error?.data
    );
    setLoading(false);
  };

  const columns = useMemo(() => {
    const _columns = [...list_columns];
    _columns.push({
      dataIndex: "option",
      width: 85,
      title: "Action",
      key: "option",
      align: "center",
      fixed: "right",
      render: (value, record, index) => {
        return (
          <div className="td-operate">
            <CommonRoleWrap roleStr="task:list:delete">
              <Popover
                // iconProps={{
                //   type: "FleetEslistDeleteIcon",
                //   outerLayerClassname: "operate-icon"
                // }}
                render={(setIsOpen) => {
                  return (
                    <FleetToast
                      label="Are you sure delete this item?"
                      onCancel={() => {
                        setIsOpen(false);
                      }}
                      onConfirm={() => {
                        console.log("single", record?.id);
                        delTaskMultiple("single", record?.id);
                        setIsOpen(false);
                      }}
                    />
                  );
                }}
              >
                <span
                  className="font_family fleet-delete"
                  style={{ color: "red" }}
                ></span>
              </Popover>
            </CommonRoleWrap>
          </div>
        );
      },
    });
    return _columns;
  }, [list_columns]);

  const onSelectChange = (newSelectedRowKeys: React.Key[]) => {
    console.log("newSelectedRowKeys", newSelectedRowKeys);
    setSelectedRowKeys(newSelectedRowKeys);
  };

  const getTaskGroupFront = () => {
    setLoading(true);
  };

  const getTaskSuccess = (response) => {
    let total = 0;
    switch (true) {
      case !Array.isArray(response?.data):
        // setDataSource(response?.data?.items);
        cacheSource.current[1] = response?.data?.items;
        total = response?.data?.totalCount;
        break;
      default:
        // setDataSource([]);
        cacheSource.current = {};
        break;
    }
    setPager((prev) => ({ ...prev, total, pageNum: 1 }));
    setIsRefresh((prev) => !prev);
    setLoading(false);
  };

  console.log("cacheSource", cacheSource, dataSource);

  const getTaskError = (error) => {
    reminder(
      "error",
      error?.msg ? error?.msg + ": " + error?.data : error?.data
    );
    setLoading(false);
  };

  const getTaskGroup = (searchVal?: string) => {
    cacheSource.current = {};
    getTaskGroupAction(
      {
        pageNum: 1,
        pageSize: pager.pageSize,
        keyword: searchVal ?? search,
      },
      getTaskGroupFront,
      getTaskSuccess,
      getTaskError,
      dispatch,
      navigate
    );
  };

  const onPageChange = (page: number, size: number) => {
    if (dataSource?.length >= pager.total) return;
    // if (page * size <= dataSource?.length) return;
    if (cacheSource.current[page]) {
      setPager((prev) => ({ ...prev, pageNum: page }));
      return;
    }
    getTaskGroupAction(
      {
        pageNum: page,
        pageSize: pager.pageSize,
        keyword: search,
      },
      getTaskGroupFront,
      (response) => {
        if (!Array.isArray(response?.data)) {
          cacheSource.current[page] = response?.data?.items;
        } else {
          cacheSource.current[page] = [];
        }
        setPager((prev) => ({ ...prev, pageNum: page }));
        setLoading(false);
      },
      getTaskError,
      dispatch,
      navigate
    );
  };

  const onReset = () => {
    setHide(true);
    setSelectedRowKeys([]);
    getTaskGroup();
  };

  const onSearch = useMemo(() => {
    const loadOptions = (val: string) => {
      cacheSource.current = {};
      setSearch(val);
      getTaskGroup(val);
    };
    return debounce(loadOptions, 500);
  }, []);

  const handleOpen = (type: "add" | "edit") => {
    setType(type);
    setHide(false);
  };

  const onRow = (item) => {
    return {
      onDoubleClick: () => {
        record.current = item;
        handleOpen("edit");
        // navigate(`/layout/voyagemanage/movement/details?id=${record?.id}`)
      },
    };
  };

  useEffect(() => {
    getTaskGroup();
  }, []);

  return {
    hide,
    setHide,
    type,
    setType,
    search,
    record,
    selectedRowKeys,
    pager,
    columns,
    onReset,
    dataSource,
    loading,
    navigate,
    onPageChange,
    onSelectChange,
    handleOpen,
    onSearch,
    onDeleteItems: delTaskMultiple,
    onRow,
  };
};

export default useGroup;
