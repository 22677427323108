import React, { useMemo } from "react"
import { MovementCargoActualItemType } from "../components/charterBase/components/cargo/type";
import { MovementVesselBasicsType } from "../components/vesselBasics/type"
import { MovementTcvcOtherItemType } from "../type";
import useEstimation from "./useEstimation";
import { downloadMovementPnlParams } from "@/api/voyageManage/movement";
import { MovementPortActualItemType } from "../components";
import { MovementFinancialDataItemType, initialFinacialItem } from "../../../common";

const usePnl = <T>(
	estCalculate: any,
	vesselItem: MovementVesselBasicsType,
	cargoItem: MovementCargoActualItemType,
	portGroup: MovementPortActualItemType[],
	otherItem: MovementTcvcOtherItemType
) => {
	const {
		actualCalauate,
		laytime,
		otherExpenses,
		disputeCharges,
		portExpenses,
		hireExpense,
		freightExpense,
		portCalcutor
	} = useEstimation(vesselItem, cargoItem, portGroup, otherItem);

	const totalRevenues = useMemo<MovementFinancialDataItemType>(() => {
		const diffVal = actualCalauate?.freightIncome - estCalculate?.freightIncome;
		return initialFinacialItem({
			desc: "Total Revenues",
			plan: estCalculate?.totalRevenue,
			actual: actualCalauate?.totalRevenue,
			diffActual: actualCalauate?.totalRevenue
				? (actualCalauate?.totalRevenue - estCalculate?.totalRevenue) / actualCalauate?.totalRevenue
				: 0,
			children: [
				initialFinacialItem({
					desc: "Freight Income",
					actual: actualCalauate?.freightIncome,
					plan: estCalculate?.freightIncome,
					diffActual: actualCalauate?.freightIncome ? diffVal / actualCalauate?.freightIncome : 0,
					posted: freightExpense?.total,
					diffPosted: freightExpense?.total
						? (actualCalauate?.freightIncome - freightExpense?.total) / freightExpense?.total
						: 0
				}),
				initialFinacialItem({
					desc: "Balance of Freight",
					actual: actualCalauate?.freightIncome - freightExpense?.total
				}),
				...laytime?.calcuate,
				initialFinacialItem({
					desc: "Others Income",
					actual: +otherItem?.otherIncome
				})
			]
		});
	}, [
		actualCalauate?.totalRevenue,
		laytime?.calcuate,
		otherItem?.otherIncome,
		estCalculate?.totalRevenue,
		estCalculate?.freightIncome,
		freightExpense?.total
	]);

	const totalOtherExpenses = useMemo(() => {
		return initialFinacialItem({
			desc: "Other Expenses",
			plan: estCalculate?.otherExpense,
			actual: otherExpenses?.total,
			children: otherExpenses?.calcuate
		});
	}, [actualCalauate?.otherExpense, estCalculate?.otherExpense, otherExpenses]);

	const totalPortExpense = useMemo(() => {
		const diffVal = actualCalauate?.portExpense - estCalculate?.portExpense;
		return initialFinacialItem({
			desc: "Port Disbursement",
			plan: estCalculate?.portExpense,
			actual: portExpenses?.total,
			diffActual: actualCalauate?.portExpense ? diffVal / actualCalauate?.portExpense : 0,
			children: portExpenses?.calcuate
		});
	}, [actualCalauate?.portExpense, estCalculate?.portExpense]);

	const totalHireExpense = useMemo(() => {
		const actualHireCost = actualCalauate?.hireCost - +otherExpenses?.ownersCount;
		const diffVal = actualHireCost - estCalculate?.hireCost;
		return initialFinacialItem({
			desc: "Hire Cost",
			plan: estCalculate?.hireCost,
			actual: actualHireCost,
			diffActual: actualHireCost ? diffVal / actualHireCost : 0,
			posted: hireExpense?.total,
			diffPosted: hireExpense?.total
				? (actualCalauate?.hireCost - hireExpense?.total) / hireExpense?.total
				: 0,
			children: [
				...hireExpense?.calcuate,
				initialFinacialItem({
					desc: "Owr's Account",
					actual: otherExpenses?.ownersCount
				})
			]
		});
	}, [
		actualCalauate?.hireCost,
		estCalculate?.hireCost,
		estCalculate?.portExpense,
		otherExpenses?.ownersCount,
		hireExpense
	]);

	const totalCommission = useMemo(() => {
		return initialFinacialItem({
			desc: "Commission",
			actual:
				actualCalauate?.commission +
				actualCalauate?.brokerage -
				actualCalauate?.freightAddCommission -
				actualCalauate?.freightBrokerage,
			children: [
				initialFinacialItem({
					desc: "Chrt's Brokerage",
					actual: actualCalauate?.freightBrokerage,
					plan: estCalculate?.freightBrokerage,
					diffActual: actualCalauate?.freightBrokerage
						? (actualCalauate?.freightBrokerage - estCalculate?.freightBrokerage) /
							actualCalauate?.freightBrokerage
						: 0
				}),
				initialFinacialItem({
					desc: "Chit's Addcom.",
					actual: actualCalauate?.freightAddCommission,
					plan: estCalculate?.freightAddCommission,
					diffActual: actualCalauate?.freightAddCommission
						? (actualCalauate?.freightAddCommission - estCalculate?.freightAddCommission) /
							actualCalauate?.freightAddCommission
						: 0
				}),
				initialFinacialItem({
					desc: "Owr's Brokerage",
					actual: actualCalauate?.brokerage,
					plan: estCalculate?.brokerage,
					diffActual: actualCalauate?.brokerage
						? (actualCalauate?.brokerage - estCalculate?.brokerage) / actualCalauate?.brokerage
						: 0
				}),
				initialFinacialItem({
					desc: "Owr's Addcom.",
					actual: actualCalauate?.commission,
					plan: estCalculate?.commission,
					diffActual: actualCalauate?.commission
						? (actualCalauate?.commission - estCalculate?.commission) / actualCalauate?.commission
						: 0
				})
			]
		});
	}, [
		actualCalauate?.brokerage,
		actualCalauate?.commission,
		actualCalauate?.freightAddCommission,
		actualCalauate?.freightBrokerage,
		estCalculate?.brokerage,
		estCalculate?.commission,
		estCalculate?.freightAddCommission,
		estCalculate?.freightBrokerage
	]);

	const totalBunkerCost = useMemo(() => {
		return (
			otherItem?.bunkerPlan?.hsfoTotalPrice +
			otherItem?.bunkerPlan?.lsfoTotalPrice +
			otherItem?.bunkerPlan?.mdoTotalPrice +
			otherItem?.bunkerPlan?.mgoTotalPrice +
			+otherItem?.bunkerPlan?.scrubberTotalPrice
		);
	}, [otherItem?.bunkerPlan]);

	const bunkerCost = useMemo(() => {
		return initialFinacialItem({
			desc: "Bunker Cost",
			actual: totalBunkerCost,
			plan: estCalculate?.bunkerCost,
			children: [
				initialFinacialItem({
					desc: "HSFO Cost",
					actual: otherItem?.bunkerPlan?.hsfoTotalPrice + +otherItem?.bunkerPlan?.scrubberTotalPrice
				}),
				initialFinacialItem({
					desc: "LSFO Cost",
					actual: otherItem?.bunkerPlan?.lsfoTotalPrice,
					plan: estCalculate?.ifoCost
				}),
				initialFinacialItem({
					desc: "MDO Cost",
					actual: otherItem?.bunkerPlan?.mdoTotalPrice,
					plan: estCalculate?.mdoCost
				}),
				initialFinacialItem({
					desc: "MGO Cost",
					actual: otherItem?.bunkerPlan?.mgoTotalPrice
				})
			]
		});
	}, [
		totalBunkerCost,
		otherItem?.bunkerPlan,
		estCalculate?.bunkerCost,
		estCalculate?.mdoCost,
		estCalculate?.ifoCost
	]);

	const totalBunkerage = useMemo(() => {
		return initialFinacialItem({
			desc: "Bunkerage",
			actual: totalBunkerCost + otherItem?.bunkerPlan?.totalBunkerPlanExpense,
			children: [
				bunkerCost,
				initialFinacialItem({
					desc: "Bunker Port Charge",
					actual: otherItem?.bunkerPlan?.totalBunkerPlanExpense,
					plan: estCalculate?.bunkerExpenses
				})
			]
		});
	}, [
		otherItem?.bunkerPlan?.totalBunkerPlanExpense,
		estCalculate?.bunkerExpenses,
		totalBunkerCost,
		bunkerCost
	]);

	const totalCost = useMemo(() => {
		const diffVal = actualCalauate?.totalCost - estCalculate?.totalCost;
		return initialFinacialItem({
			desc: "Total Cost",
			actual: actualCalauate?.totalCost,
			plan: estCalculate?.totalCost,
			diffActual: actualCalauate?.totalCost ? diffVal / actualCalauate?.totalCost : 0,
			children: [
				totalHireExpense,
				totalBunkerage,
				totalPortExpense,
				totalCommission,
				totalOtherExpenses
			]
		});
	}, [
		actualCalauate?.totalCost,
		totalOtherExpenses,
		,
		estCalculate?.totalCost,
		totalHireExpense,
		totalBunkerage,
		totalPortExpense,
		totalCommission
	]);

	const totalDisputeCharge = useMemo(() => {
		return initialFinacialItem({
			desc: "Dispute Charge",
			actual: disputeCharges?.total,
			children: disputeCharges?.calcuate
		});
	}, [disputeCharges]);

	const handleCombineParams = (
		item: Omit<
			downloadMovementPnlParams,
			"freights" | "hires" | "disputeCharges" | "totalPnl" | "actualDays"
		>
	): downloadMovementPnlParams => {
		return {
			...item,
			freights: {
				balanceOfFreightAmount: actualCalauate?.freightIncome - freightExpense?.total,
				oceanFreightTime: otherItem?.freightList?.at(-1)?.settledTime,
				oceanFreightAmount: actualCalauate?.freightIncome,
				laytime: laytime?.calcuate?.map((item) => ({ desc: item?.desc, actual: item?.actual })),
				otherIncome: +otherItem?.otherIncome,
				total: actualCalauate?.totalRevenue
			},
			hires: {
				hireList: hireExpense?.calcuate?.map((item) => ({
					desc: item?.desc,
					actual: item?.actual,
					time: item?.time
				})),
				bunkerage: {
					bunkerCost: totalBunkerCost + otherItem?.bunkerPlan?.totalBunkerPlanExpense,
					bunkerPortCharge: otherItem?.bunkerPlan?.totalBunkerPlanExpense,
					bunkerCostTime:
						otherItem?.bunkerPlan?.status === "Settled" ? otherItem?.bunkerPlan?.settledTime : null,
					bunkerPortTime:
						portGroup?.findLast((portItem) => portItem?.status === "Settled")?.settledTime ?? null
				},
				portExpenses: portExpenses?.calcuate?.map((item) => ({
					desc: item?.desc,
					actual: item?.actual,
					portAgentName: item?.portAgentName,
					time: item?.time
				})),
				commission: {
					chrtsCommission: actualCalauate?.freightAddCommission,
					chrtsBrokerage: actualCalauate?.freightBrokerage,
					ownrsBrokerage: actualCalauate?.brokerage,
					ownrsCommission: actualCalauate?.commission
				},
				otherExpenses: otherExpenses?.calcuate?.map((item) => ({
					desc: item?.desc,
					actual: item?.actual,
					time: item?.time
				})),
				total: actualCalauate?.totalCost
			},
			disputeCharges: disputeCharges?.calcuate?.map((item) => ({
				desc: item?.desc,
				actual: item?.actual
			})),
			totalPnl: actualCalauate?.totalRevenue - actualCalauate?.totalCost,
			actualDays:
				portCalcutor?.ballastPortTotalDays +
				portCalcutor?.ladenPortTotalDays +
				portCalcutor?.ballastSeaDays +
				portCalcutor?.ladenSeaDays
		};
	};

	return {
		actualCalauate,
		totalRevenues,
		totalCost,
		totalDisputeCharge,
		handleCombineParams
	};
};

export default usePnl