import { ParameterType } from "@/api";
import { loginOutAction } from "@/store/userSlice";

import {
  getAisGroupApi,
  getAisStarGroupApi,
  addAisStarApi,
  getAisCurrentStateApi,
  getMonitoringAisDataApi,
  getMonitoringThreeAisDataApi,
  getAisCurrentForImoApi,
  getMonitoringAisDataForImoApi
} from "@/api/monitoring/aisGroup"

import {
  getAisGroupParams,
  addAisStarParams,
  getAisCurrentStateParams,
  getMonitoringAisDataParams,
  getMonitoringThreeAisDataParams,
  getAisCurrentForImoParams,
  getMonitoringAisDataForImoParams
} from "@/api/monitoring/aisGroup/type"

export const getAisGroupAction: ParameterType<getAisGroupParams> = (
  params,
  frontCallback,
  successCallback,
  errorCallback,
  dispatch,
  navigate
) => {
  frontCallback?.();
  getAisGroupApi(params).then(response => {
    successCallback?.(response)
  }).catch(err => {
    errorCallback?.(err);
    if (err?.loginStatus === 'token faild') {
      dispatch(loginOutAction(() => navigate?.("/login")))
    }
  })
}

export const getAisStarGroupAction: ParameterType<null> = (
  params,
  frontCallback,
  successCallback,
  errorCallback,
  dispatch,
  navigate
) => {
  frontCallback?.();
  getAisStarGroupApi().then(response => {
    successCallback?.(response)
  }).catch(err => {
    errorCallback?.(err);
    if (err?.loginStatus === 'token faild') {
      dispatch(loginOutAction(() => navigate?.("/login")))
    }
  })
}

export const addAisStarAction: ParameterType<addAisStarParams> = (
  params,
  frontCallback,
  successCallback,
  errorCallback,
  dispatch,
  navigate
) => {
  frontCallback?.();
  addAisStarApi(params).then(response => {
    successCallback?.(response)
  }).catch(err => {
    errorCallback?.(err);
    if (err?.loginStatus === 'token faild') {
      dispatch(loginOutAction(() => navigate?.("/login")))
    }
  })
}

export const getAisCurrentStateAction: ParameterType<getAisCurrentStateParams> = (
  params,
  frontCallback,
  successCallback,
  errorCallback,
  dispatch,
  navigate
) => {
  frontCallback?.();
  getAisCurrentStateApi(params).then(response => {
    successCallback?.(response)
  }).catch(err => {
    errorCallback?.(err);
    if (err?.loginStatus === 'token faild') {
      dispatch(loginOutAction(() => navigate?.("/login")))
    }
  })
}

export const getMonitoringAisDataAction: ParameterType<getMonitoringAisDataParams> = (
  params,
  frontCallback,
  successCallback,
  errorCallback,
  dispatch,
  navigate
) => {
  frontCallback?.();
  getMonitoringAisDataApi(params).then(response => {
    successCallback?.(response)
  }).catch(err => {
    errorCallback?.(err);
    if (err?.loginStatus === 'token faild') {
      dispatch(loginOutAction(() => navigate?.("/login")))
    }
  })
}

export const getMonitoringThreeAisDataAction: ParameterType<getMonitoringThreeAisDataParams> = (
  params,
  frontCallback,
  successCallback,
  errorCallback,
  dispatch,
  navigate
) => {
  frontCallback?.();
  getMonitoringThreeAisDataApi(params).then(response => {
    successCallback?.(response)
  }).catch(err => {
    errorCallback?.(err);
    if (err?.loginStatus === 'token faild') {
      dispatch(loginOutAction(() => navigate?.("/login")))
    }
  })
}

export const getAisCurrentForImoAction: ParameterType<getAisCurrentForImoParams> = (
  params,
  frontCallback,
  successCallback,
  errorCallback,
  dispatch,
  navigate
) => {
  frontCallback?.();
  getAisCurrentForImoApi(params).then(response => {
    successCallback?.(response)
  }).catch(err => {
    errorCallback?.(err);
    if (err?.loginStatus === 'token faild') {
      dispatch(loginOutAction(() => navigate?.("/login")))
    }
  })
}

export const getMonitoringAisDataForImoAction: ParameterType<getMonitoringAisDataForImoParams> = (
  params,
  frontCallback,
  successCallback,
  errorCallback,
  dispatch,
  navigate
) => {
  frontCallback?.();
  getMonitoringAisDataForImoApi(params).then(response => {
    successCallback?.(response)
  }).catch(err => {
    errorCallback?.(err);
    if (err?.loginStatus === 'token faild') {
      dispatch(loginOutAction(() => navigate?.("/login")))
    }
  })
}