import { type ApiParameter } from "@/api";
import { appVersion } from "@/api/constant";
import request from "@/service";
import { PageParams } from "../estimation";

const enum ApiMap {
	addTaskParameter,
	updateTaskParameter,
	getTaskGroupParameter,
	getTaskParameter,
	deleteTaskParameter,
	batchDeleteTaskParameter
}

type ApiUrl = keyof typeof ApiMap;

const taskApi: ApiParameter<ApiUrl> = {
	getTaskGroupParameter: {
		method: "get",
		url: appVersion + "/stageTask/list"
	},
	getTaskParameter: {
		method: "get",
		url: appVersion + "/stageTask"
	},
	deleteTaskParameter: {
		method: "delete",
		url: appVersion + "/stageTask"
	},
	addTaskParameter: {
		method: "post",
		url: appVersion + "/stageTask"
	},
	updateTaskParameter: {
		method: "put",
		url: appVersion + "/stageTask"
	},
	batchDeleteTaskParameter: {
		method: "delete",
		url: appVersion + "/stageTask/batch"
	}
};

export type getTaskGroupParams = {
	keyword: string;
} & PageParams;

export type getTaskParams = {
	id: string | number;
};

export type deleteTaskParams = (string | number)[];

export type addTaskParams = {
	id?: string | number;
	stage: "Delivery" | "Arrived LP" | "Arrived DP" | "Departed LP" | "Departed DP" | "Redelivery";
	title: string;
	contents: string;
};

export type updateTaskParams = addTaskParams;

export const getTaskGroupApi = (params: getTaskGroupParams) => {
	return request.request({
		...taskApi.getTaskGroupParameter,
		params
	});
};

export const getTaskApi = (params: getTaskParams) => {
	return request.request({
		...taskApi.getTaskParameter,
		url: `${taskApi.getTaskParameter.url}/${params?.id}`
	});
};

export const deleteTaskApi = (params: deleteTaskParams) => {
	return request.request({
		...taskApi.batchDeleteTaskParameter,
		data: params
	});
};

export const updateTaskApi = (params: updateTaskParams) => {
	return request.request({
		...taskApi.updateTaskParameter,
		data: params
	});
};

export const addTaskApi = (params: addTaskParams) => {
	return request.request({
		...taskApi.addTaskParameter,
		data: params
	});
};
