import { ExtendsKeys } from "@/types";

export enum HeadFixtueLabelMap {
  ownerName = "Owner Name",
  vesselName = "Vessel Name",
  vesselNameCn = "Vessel Name Cn",
  vesselNameEn = "Vessel Name En",
  dwt = "DWT",
  bale = "BALE",
  mmsi = "MMSI",
  imo = "IMO",
  grain = "Grain",
  cpDate = "CP Date",
  layCan = "Laycan From/To",
  tradingArea = "Trading Area",
  layCanFrom = "Laycan From",
  layCanTo = "Laycan To",
  deliveryPort = "Delivery Port",
  deliveryPortCode = "Delievry Port Code",
  deliveryPortName = "Delievry Port Name",
  deliveryDate = "Delivery Date",
  estRedeliveryDate = "Est. Redel Date",
  redeliveryPort = "Est. Redel Port",
  redeliveryPortCode = "Est. Redel Port Code",
  redeliveryPortName = "Est. Redel Name",
  cpNo = "CP No.",
  charteredBy = "Chartered By",
  dailyHire = "Daily Hire",
  commission = "Add. Comm.",
  brokerage = "Brokerage",
  brokerId = "Broker Id",
  brokerName = "Broker Name",
  ilohc = "ILOHC",
  cve = "C/V/E (PMPR)",
  paymentTerm = "Payment Term (Day/In Addvance)",
  paymentRate = "Payment Type",
  paymentStartDay = "First Payment Start",
  maxDuration = "Max. Duration Day",
  minDuration = "Min. Duration Day",
  createdBy = "Created By",
  operatedBy = "Operated By",
  updatedDate = "Updated Date",
  status = "Status",
  id = "id",
  voyageId = "voyageId",
  option = "Option",
  bunkerClause = "Bunker Clause",
  hsFoQuantity = "HSFO",
  lsFoQuantity = "LSFO",
  mdoQuantity = "MDO",
  mgoQuantity = "MGO",
  bodQuantity = "BOD Quantity",
  borAbout = "BOR About",
  borPrice = "BOR Price",
  bodPrice = "BOD Price",
  hsFoPrice = "HSFO Price",
  lsFoPrice= "LSFO Price",
  mdoPrice = "MDO Price",
  mgoPrice =  "MGO Price",
  speed = "Speed",
  hsFo = "HSFO",
  lsFo = "LSFO",
  mdo = "MDO",
  mgo = "MGO",
  remarks = "Remarks",
  ballast = "Ballast",
  laden = "Laden",
  work = "Work",
  idle = "Idle",
  goodWeatherDef = "Good Weather",
  performanceClause = "Performance Clause",
  charateList = "Chartered List"
}

export type HeadFixtueKeys = keyof typeof HeadFixtueLabelMap;
// export type HeadFixtueListType = {
//   /**
//     * 船东
//   */
//   ownerName: string;
//   /**
//    * 船舶名
//   */
//   vesselName: string;
//   /**
//    * 合同签订日期
//    */
//   cpDate: string;

//   /**
//  * 贸易区域
//  */
//   tradingArea: string;
//   /**
//    * 船舶合同号码
//    */
//   layCanFrom: string;
//   layCanTo: string;
//   /**
//  * 交船港口编码
//  */
//   deliveryPortCode: string;
//   /**
//    * 交船港口名称
//    */
//   deliveryPortName: string;
//   deliveryDate: string;
//   /**
//  * 最长租期
//  */
//   maxDuration: number;
//   /**
//  * 最短租期
//  */
//   minDuration: number;
//   estRedeliveryDate: string;
//   /**
//  * 还船港口编码
//  */
//   redeliveryPortCode: string;
//   /**
//    * 还船港口名称
//    */
//   redeliveryPortName: string;
//   /**
//  * 租船负责人
//  */
//   charteredBy: string;
//   /**
//  * 日租金
//  */
//   dailyHire: number;
//   /**
//  * 租金折扣
//  */
//   commission: number;
//   /**
//  * 中介费率
//  */
//   brokerage: number;
//   /**
//    * 中介id
//    */
//   brokerId: number;
//   /**
//    * 中介名
//    */
//   brokerName: string;
//   /**
//    * 洗舱费
//    */
//   ilohc: number;
//   /**
//  * 船员生活费（月）
//  */
//   cve: number;
//   /**
// * 装货完成多少天内支付运费
// */
//   paymentTerm: number;
//   /**
//    * 租船负责人
//    */
//   charteredName: string;
//   /**
//    * 船舶合同号码
//    */
//   cpNo: string;
//   /**
//    * 好天气条款
//    */
//   goodWeatherDef: string;
//   /**
//    * id
//    */
//   operatedBy: number;
//   /**
//    * 操作负责人
//    */
//   operatedName: string;
//   /**
//    * 付款比例
//    */
//   paymentRate: number;
//   /**
//    * 首付日期
//    */
//   paymentStartDay: string;
//   /**
//    * 状态
//    */
//   status: string;
//   /**
//    * 航次ID
//    */
//   voyageId: number;
// }

// export type HeadFixtueListKeys = keyof HeadFixtueListType;