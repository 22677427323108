import { IconMap } from "@/components/Icon/icons";
import {
	findFeatureInLayer,
	hideFeature,
	loadImage,
	toggleFeature,
	toggleLayerVisibility
} from "@/featrue/estimation/details/routeGraph/tool";
import { convertToDms } from "@/tools/graph";
import mapboxgl, { AnySourceImpl } from "mapbox-gl";
import { MutableRefObject, useEffect, useRef } from "react";
import { IdentifierMapType, shippingMapType } from "./useDetails";
import { formatThousandthNumber } from "@/tools/amount";
import { dateFormat, dateToUtcString } from "@/tools/date";
import { uuid } from "@/tools";
// import turf from "turf"

// console.log(turf.featureEach)

// mapboxgl.accessToken = "pk.eyJ1Ijoidm95YWdlY2VudHVyeTIwMTgiLCJhIjoiY2xmOTM0NDJyMXV1MjQwb2NrNzFvZWJ3dSJ9.zyBT0Gd6HYyeywftD_XKtg";

type GraphGeoJson =
	| GeoJSON.Feature<GeoJSON.Geometry>
	| GeoJSON.FeatureCollection<GeoJSON.Geometry>
	| String;

const useVesselSource = () => {
	// 船历史轨迹/箭头指向 source
	const vesselTrackSource = useRef({
		type: "FeatureCollection",
		features: []
	});

	// 船图标source
	const vesselIconSource = useRef({
		type: "FeatureCollection",
		features: [
			{
				type: "Feature",
				geometry: {
					type: "Point",
					coordinates: []
				},
				properties: {
					bearing: 0
				}
			}
		]
	});

	// 船历史轨迹 source
	const vesselHisTrackSource = useRef({
		type: "FeatureCollection",
		features: []
	});

	// 历史轨迹起始点终止点 source
	const startEndPointSource = useRef({
		type: "FeatureCollection",
		features: [
			{
				type: "Feature",
				properties: {
					typeColor: "transparent",
					index: "start-outer",
					type: "outer"
				},
				geometry: {
					type: "Point",
					coordinates: []
				}
			},
			{
				type: "Feature",
				properties: {
					typeColor: "rgba(3, 240, 254, 0.60)",
					index: "start-inline",
					type: "inline"
				},
				geometry: {
					type: "Point",
					coordinates: []
				}
			},
			{
				type: "Feature",
				properties: {
					typeColor: "transparent",
					type: "outer",
					index: "end-outer"
				},
				geometry: {
					type: "Point",
					coordinates: []
				}
			},
			{
				type: "Feature",
				properties: {
					typeColor: "rgba(255, 134, 50, 0.60)",
					type: "inline",
					index: "end-inline"
				},
				geometry: {
					type: "Point",
					coordinates: []
				}
			}
		]
	});

	const vesselTrackPointsSource = useRef({
		type: "FeatureCollection",
		features: []
		// generateId: true
	});

	const vesselTrackPointLabelSource = useRef({
		type: "FeatureCollection",
		features: []
	});

	const vesselTrackPointsLineSource = useRef({
		type: "FeatureCollection",
		features: [
			{
				type: "Feature",
				geometry: {
					type: "LineString",
					coordinates: []
				},
				properties: {}
			}
		]
	});

	return {
		vesselTrackSource,
		vesselIconSource,
		vesselHisTrackSource,
		startEndPointSource,
		vesselTrackPointsSource,
		vesselTrackPointLabelSource,
		vesselTrackPointsLineSource
	};
};

const useLoadVesselImage = (mapboxgl: MutableRefObject<mapboxgl.Map>) => {
	loadImage(mapboxgl, IconMap.EstimationVesselArrowIcon, "vessel-arrow-icon");
	loadImage(mapboxgl, IconMap.EstimationVesselIcon, "vessel-icon");
	loadImage(mapboxgl, IconMap.EstimationVesselPointIcon, "vessel-point-icon");
	loadImage(mapboxgl, IconMap.EstimationVesselBgIcon, "vessel-bg-icon");
};

const useVesselGlLayer = () => {
	const addVesselLayer = (mapboxgl: MutableRefObject<mapboxgl.Map>, before?: string) => {
		mapboxgl.current.addLayer(
			{
				id: "vesselTrackLayer",
				type: "line",
				source: "vesselTrackSource",
				layout: {
					"line-join": "round",
					"line-cap": "round"
				},
				paint: {
					"line-width": 1.34,
					// "line-color": "rgba(0, 240, 255, 1)",
					"line-color": "rgba(53,86,145,0.75)"
					// 'line-gradient': [
					//   'interpolate',
					//   ['linear'],
					//   ['line-progress'],
					//   0.25, "rgba(0, 240, 255, 1)",
					//   0.5, "rgba(255, 233, 38, 1)",
					//   0.75, "rgba(255, 134, 50, 1)",
					//   1, "rgba(255, 134, 50, 1)"
					// ]
				}
			},
			before
		);
	};

	const addVesselIconLayer = (mapboxgl: MutableRefObject<mapboxgl.Map>, before?: string) => {
		mapboxgl.current.addLayer(
			{
				id: "vesselIconLayer",
				type: "symbol",
				source: "vesselIconSource",
				layout: {
					// 'symbol-placement': 'line',
					// 'symbol-spacing': 100, // 图标间隔，默认为250
					"icon-image": "vessel-icon", //箭头图标
					"icon-size": 0.7,
					"icon-rotate": ["get", "bearing"]
					// "icon-rotation-alignment": "map",
					// "icon-allow-overlap": true,
					// "icon-ignore-placement": true,
					// 'icon-rotate': -90
				}
			},
			before
		);
	};

	const addVesselTrackArrowLayer = (mapboxgl: MutableRefObject<mapboxgl.Map>, before?: string) => {
		mapboxgl.current.addLayer(
			{
				id: "vesselTrackArrowLayer",
				type: "symbol",
				source: "vesselTrackSource",
				layout: {
					"symbol-placement": "line",
					"symbol-spacing": 100, // 图标间隔，默认为250
					"icon-image": "vessel-arrow-icon", //箭头图标
					"icon-size": 0.7,
					"icon-rotate": -90
				}
			},
			before
		);
	};

	const addVesselHisTrackLayer = (mapboxgl: MutableRefObject<mapboxgl.Map>, before?: string) => {
		mapboxgl.current.addLayer(
			{
				id: "vesselHisTrackLayer",
				type: "line",
				source: "vesselHisTrackSource",
				layout: {
					"line-join": "round",
					"line-cap": "round"
				},
				paint: {
					"line-width": 2.5,
					"line-opacity": 0.7,
					"line-color": ["get", "color"]
				}
			},
			before
		);
	};

	const addStartEndPointLayer = (mapboxgl: MutableRefObject<mapboxgl.Map>, before?: string) => {
		mapboxgl.current.addLayer(
			{
				id: "startEndPointLayer",
				source: "startEndPointSource",
				type: "circle",
				paint: {
					// "circle-radius": [
					//   "match",
					//   ["get", "type"],
					//   "inline", 3,
					//   "outer", 6,
					//   5
					// ],
					"circle-radius": 4,
					"circle-color": "rgba(53,86,145,0.75)"
					// "circle-color": [
					//   "match",
					//   ["get", "index"],
					//   "start-outer", "transparent",
					//   "start-inline", "rgba(3, 240, 254, 0.60)",
					//   "end-outer", "transparent",
					//   "end-inline", "rgba(255, 134, 50, 0.60)",
					//   "#cccccc"],
					//    "circle-stroke-width": [
					//   "match",
					//   ["get", "type"],
					//   "outer", 2,
					//   "inline", 0,
					//   0],
					// //设置边线颜色
					// "circle-stroke-color": [
					//   "match",
					//   ["get", "index"],
					//   "start-outer", "rgba(3, 240, 254, 0.60)",
					//   "start-inline", "transparent",
					//   "end-outer", "rgba(255, 134, 50, 0.60)",
					//   "end-inline", "transparent",
					//   "#cccccc"]
				}
			},
			before
		);
	};

	const addVesselTrackPointLineLayer = (
		mapboxgl: MutableRefObject<mapboxgl.Map>,
		before?: string
	) => {
		mapboxgl.current.addLayer(
			{
				id: "vesselTrackPointLineLayer",
				source: "vesselTrackPointsLineSource",
				type: "line",
				layout: {
					"line-join": "round",
					"line-cap": "round"
				},
				paint: {
					"line-color": "transparent",
					"line-width": 2
				}
			},
			before
		);
	};

	const addVesselTrackPointsLayer = (mapboxgl: MutableRefObject<mapboxgl.Map>, before?: string) => {
		mapboxgl.current.addLayer(
			{
				id: "vesselTrackPointsLayer",
				source: "vesselTrackPointsSource",
				type: "symbol",
				layout: {
					// 'symbol-placement': 'line',
					"symbol-spacing": 100, // 图标间隔，默认为250
					"icon-image": "vessel-point-icon", //
					"icon-size": 1
					// 'icon-rotate': -90
				}
			},
			before
		);
	};

	const addVesselTrackPointLabelLayer = (
		mapboxgl: MutableRefObject<mapboxgl.Map>,
		before?: string
	) => {
		mapboxgl.current.addLayer(
			{
				id: "vesselTrackPointLabelLayer",
				type: "symbol",
				source: "vesselTrackPointLabelSource",
				layout: {
					// 'symbol-placement': 'line',
					"symbol-spacing": 100, // 图标间隔，默认为250
					"icon-image": "vessel-bg-icon", //箭头图标
					"icon-size": 1,
					"text-field": ["concat", ["get", "time"], "Z ", ["get", "speed"], " knots"],
					"icon-offset": [100, 0],
					"text-offset": [10, 0],
					"text-max-width": 1000, // 设置一个足够大的宽度，确保文本不会换行
					// 文字大小
					"text-size": 10
				}
			},
			before
		);
	};

	// const { mapContainer } = useGraph(
	//   portSeq,
	//   shippingMap,
	//   identifierMap,
	//   deliveryPort
	//   )

	return {
		addVesselLayer,
		addVesselIconLayer,
		addVesselTrackArrowLayer,
		addVesselHisTrackLayer,
		addStartEndPointLayer,
		addVesselTrackPointsLayer,
		addVesselTrackPointLabelLayer,
		addVesselTrackPointLineLayer
	};
};

const usePortSource = () => {
	const portSource = useRef({
		type: "FeatureCollection",
		features: []
	});

	const portTrackSource = useRef({
		type: "FeatureCollection",
		features: []
	});

	const portTrackArrowSource = useRef({
		type: "FeatureCollection",
		features: []
	});

	return {
		portSource,
		portTrackSource,
		portTrackArrowSource
	};
};

const useLoadPortImage = (mapboxgl: MutableRefObject<mapboxgl.Map>) => {
	loadImage(mapboxgl, IconMap.FleetGraphLineIcon, "port-track-icon");
};

const usePortGlLayer = () => {
	const addPortLayer = (mapboxgl: MutableRefObject<mapboxgl.Map>, before?: string) => {
		mapboxgl.current.addLayer(
			{
				type: "circle",
				id: "portLayer",
				source: "portSource",
				paint: {
					"circle-radius": 10,
					"circle-color": [
						"match",
						["get", "type"],
						"start",
						"#355691",
						"ballast",
						"#FFAD32",
						"laden",
						"#A2A6AD",
						"#355691"
					],
					"circle-stroke-width": 1,
					//设置边线颜色
					"circle-stroke-color": "#FFF"
				}
			},
			before
		);

		mapboxgl.current.addLayer({
			id: "portIndexLayer",
			type: "symbol",
			source: "portSource",
			layout: {
				"text-field": "{index}",
				// 'text-font':['Microsoft YaHei'],
				"text-size": 15
			},
			paint: {
				// 文字颜色
				"text-color": "#fff"
			}
		});
	};

	const addPortTrackLayer = (mapboxgl: MutableRefObject<mapboxgl.Map>, before?: string) => {
		mapboxgl.current.addLayer(
			{
				type: "line",
				id: "portTrackLayer",
				source: "portTrackSource",
				layout: {
					"line-join": "round",
					"line-cap": "round"
				},
				paint: {
					"line-width": 3.5,
					"line-opacity": 1,
					"line-color": "#6181CB"
				}
			},
			before
		);
	};

	const addPortTrackArrowLayer = (mapboxgl: MutableRefObject<mapboxgl.Map>, before?: string) => {
		mapboxgl.current.addLayer(
			{
				id: "portTrackArrowLayer",
				type: "symbol",
				source: "portTrackSource",
				layout: {
					"symbol-placement": "line",
					"symbol-spacing": 20, // 图标间隔，默认为250
					"icon-image": "port-track-icon", //箭头图标
					"icon-size": 1
				}
			},
			before
		);
	};

	return {
		addPortLayer,
		addPortTrackArrowLayer,
		addPortTrackLayer
	};
};

const useGraph = (
	portSeq: ("laden" | "ballast")[],
	shippingMap?: MutableRefObject<shippingMapType>,
	identifierMap?: IdentifierMapType,
	deliveryPort?: number[]
) => {
	const mapContainer = useRef<HTMLDivElement>(null);
	const mapbox = useRef(null);
	const coordinatesController = useRef<HTMLDivElement>(null);
	const switchController = useRef<HTMLDivElement>(null);
	const loading = useRef(false);
	const renderQueue = useRef<(() => void)[]>([]);
	const trackLabelState = useRef<
		{ id: string; visibility: "visible" | "none"; [propName: string]: any }[]
	>([]);

	const {
		vesselTrackSource,
		vesselIconSource,
		vesselHisTrackSource,
		startEndPointSource,
		vesselTrackPointsSource,
		vesselTrackPointLabelSource,
		vesselTrackPointsLineSource
	} = useVesselSource();

	const { portSource, portTrackArrowSource, portTrackSource } = usePortSource();

	const {
		addVesselLayer,
		addVesselIconLayer,
		addVesselTrackArrowLayer,
		addVesselHisTrackLayer,
		addStartEndPointLayer,
		addVesselTrackPointsLayer,
		addVesselTrackPointLabelLayer,
		addVesselTrackPointLineLayer
	} = useVesselGlLayer();

	const { addPortLayer, addPortTrackArrowLayer, addPortTrackLayer } = usePortGlLayer();

	const setVesselRelation = (visible: "visible" | "none") => {
		toggleLayerVisibility(mapbox, "vesselTrackLayer", visible);
		// toggleLayerVisibility(mapbox, 'vesselIconLayer', visible);
		toggleLayerVisibility(mapbox, "vesselTrackArrowLayer", visible);
		toggleLayerVisibility(mapbox, "startEndPointLayer", visible);
		toggleLayerVisibility(mapbox, "vesselTrackPointLineLayer", visible);
		toggleLayerVisibility(mapbox, "vesselTrackPointsLayer", visible);
		toggleLayerVisibility(mapbox, "vesselTrackPointLabelLayer", visible);
	};

	const setPortRelation = (visible: "visible" | "none") => {
		// toggleLayerVisibility(mapbox, 'portLayer', visible);
		// toggleLayerVisibility(mapbox, 'portIndexLayer', visible);
		toggleLayerVisibility(mapbox, "portTrackLayer", visible);
		toggleLayerVisibility(mapbox, "portTrackArrowLayer", visible);
	};

	const setHistoryRelation = (visible: "visible" | "none") => {
		toggleLayerVisibility(mapbox, "vesselHisTrackLayer", visible);
	};

	console.log("mapboxgl", mapboxgl);

	const mapboxglInit = () => {
		mapbox.current = new mapboxgl.Map({
			container: mapContainer.current,
			// style: "mapbox://styles/mapbox/streets-v11",
			style: "mapbox://styles/voyagecentury2018/cln1634sd003g01rc1alr5qmp",
			accessToken:
				"pk.eyJ1Ijoidm95YWdlY2VudHVyeTIwMTgiLCJhIjoiY2xmOTM0NDJyMXV1MjQwb2NrNzFvZWJ3dSJ9.zyBT0Gd6HYyeywftD_XKtg",
			center: { lng: 174.7265625, lat: 29.305561325527698 },
			zoom: 1.2
		});

		// 创建鼠标经纬度控件
		const coordinatesControl = {
			onAdd: function (map) {
				coordinatesController.current = document.createElement("div");
				coordinatesController.current.className = "coordinates-container";
				map.on("mousemove", this._updateCoordinates);
				return coordinatesController.current;
			},
			onRemove: function (map) {
				map.off("mousemove", this._updateCoordinates);
				coordinatesController.current.parentNode.removeChild(coordinatesController.current);
				this._map = undefined;
			},
			_updateCoordinates: function (e) {
				if (!mapbox.current) return;
				const lngLat = e.lngLat;
				coordinatesController.current.textContent = `Lat/Lng: ${convertToDms(lngLat.lat, lngLat.lng)}`;
			}
		};

		// 创建图层渲染控件
		const swithControl = {
			activeIndex: ["shortest", "vessel", "History"],
			onAdd: function (map) {
				switchController.current = document.createElement("div");
				switchController.current.className = "switch-container";
				for (let i = 0; i < this.activeIndex.length; i++) {
					const switchItem = document.createElement("div");
					switchItem.id = `switch-${this.activeIndex[i]}`;
					switchItem.addEventListener("click", this._onSelect);
					switchItem.className = "active " + this.activeIndex[i];
					switchItem.innerText = this.activeIndex[i];
					// let firstChild = document.createElement('span'), secondChild = document.createElement('span');
					// firstChild.innerText = this.activeIndex[i];
					// secondChild.addEventListener("click", this._onSelect)
					// secondChild.className = "active " + this.activeIndex[i]
					// switchItem.appendChild(firstChild)
					// switchItem.appendChild(secondChild)
					switchController.current.appendChild(switchItem);
				}
				return switchController.current;
			},
			onRemove: function (map) {
				switchController.current?.children[1]?.removeEventListener("click", this._onSelect);
				switchController.current.parentNode.removeChild(switchController.current);
				this._map = undefined;
			},
			_onSelect: function (e) {
				e.stopPropagation();
				if (!mapbox.current || !loading.current) return;
				if (e.target.className?.includes("active")) {
					e.target.className = e.target.className?.replace("active", "");
					switch (true) {
						case e.target.className.includes("shortest"):
							setPortRelation("none");
							break;
						case e.target.className.includes("vessel"):
							setVesselRelation("none");
							break;
						case e.target.className.includes("History"):
							setHistoryRelation("none");
							break;
						default:
							break;
					}
				} else {
					e.target.className += " active";
					switch (true) {
						case e.target.className.includes("shortest"):
							setPortRelation("visible");
							break;
						case e.target.className.includes("vessel"):
							setVesselRelation("visible");
							break;
						case e.target.className.includes("History"):
							setHistoryRelation("visible");
							break;
						default:
							break;
					}
				}
			}
		};

		mapbox.current.addControl(coordinatesControl);
		mapbox.current.addControl(swithControl);

		useLoadVesselImage(mapbox);
		useLoadPortImage(mapbox);

		mapbox.current.on("style.load", () => {
			// useVesselGlSource(mapbox);
			mapbox.current.addSource("vesselTrackSource", {
				type: "geojson",
				lineMetrics: true,
				//@ts-ignore
				data: vesselTrackSource.current
			});
			mapbox.current.addSource("vesselIconSource", {
				type: "geojson",
				//@ts-ignore
				data: vesselIconSource.current
			});
			mapbox.current.addSource("startEndPointSource", {
				type: "geojson",
				//@ts-ignore
				data: startEndPointSource.current
			});

			mapbox.current.addSource("vesselHisTrackSource", {
				type: "geojson",
				//@ts-ignore
				data: vesselHisTrackSource.current
			});

			mapbox.current.addSource("vesselTrackPointsSource", {
				type: "geojson",
				//@ts-ignore
				data: vesselTrackPointsSource.current
				// id:"vesselTrackPointsSource",
				// generateId: true
			});

			mapbox.current.addSource("vesselTrackPointLabelSource", {
				type: "geojson",
				//@ts-ignore
				data: vesselTrackPointLabelSource.current
			});

			mapbox.current.addSource("vesselTrackPointsLineSource", {
				type: "geojson",
				//@ts-ignore
				data: vesselTrackPointsLineSource.current
			});

			mapbox.current.addSource("portSource", {
				type: "geojson",
				//@ts-ignore
				data: portSource.current
			});

			mapbox.current.addSource("portTrackArrowSource", {
				type: "geojson",
				//@ts-ignore
				data: portTrackArrowSource.current
			});

			mapbox.current.addSource("portTrackSource", {
				type: "geojson",
				//@ts-ignore
				data: portTrackSource.current
			});

			addPortTrackLayer(mapbox);
			addPortTrackArrowLayer(mapbox);
			addVesselHisTrackLayer(mapbox);
			addPortLayer(mapbox);

			addVesselLayer(mapbox);
			addVesselTrackArrowLayer(mapbox);
			addVesselTrackPointLineLayer(mapbox);
			addVesselTrackPointsLayer(mapbox);
			addVesselTrackPointLabelLayer(mapbox);
			addStartEndPointLayer(mapbox);
			addVesselIconLayer(mapbox);
		});

		mapbox.current.on("load", () => {
			loading.current = true;
			while (renderQueue?.current?.length > 0) {
				const callback = renderQueue?.current?.shift();
				callback?.();
			}
		});
		// 监听点击事件
		// mapbox.current.on('click', 'vesselTrackPointsLayer', function (e) {
		//   // 在这里编写点击事件的处理逻辑
		//   // const bounds = mapbox.current.getBounds();
		//   // const features = mapbox.current.queryRenderedFeatures(bounds, { layers: ['vesselTrackPointsLayer'] });
		//   const clickFeatures = mapbox.current.queryRenderedFeatures(e.point, { layers: ['vesselTrackPointsLayer'] });
		//   // const featureId = clickFeatures?.[0]?.properties?.id;
		//   console.log('Layer clicked:', e.featrues, clickFeatures);
		//   const featureIndex = trackLabelState.current?.findIndex(item => item.id === clickFeatures?.[0]?.properties?.id)
		//   const featureState = trackLabelState.current?.[featureIndex];
		//   if (!featureState) return
		//   if (featureState.visibility === "visible") {
		//     vesselTrackPointLabelSource.current.features.splice(featureIndex, 1);
		//     mapbox?.current?.getSource("vesselTrackPointLabelSource")?.setData(vesselTrackPointLabelSource.current);
		//     featureState.visibility = "none"
		//   } else {
		//     console.log("vesselTrackPointsSource.current.features[featureIndex]",vesselTrackPointsSource.current.features[featureIndex])
		//     vesselTrackPointLabelSource.current.features = [...vesselTrackPointsSource.current.features];
		//     console.log("vesselTrackPointLabelSource",vesselTrackPointLabelSource,vesselTrackPointsSource.current.features[featureIndex])
		//     mapbox?.current?.getSource("vesselTrackPointLabelSource")?.setData(vesselTrackPointLabelSource.current);
		//     featureState.visibility = "visible"
		//   }
		//   console.log("vesselTrackPointLabelSource",vesselTrackPointLabelSource)
		//   //   if (clickFeatures?.[0]?.properties?.id)
		//   //     toggleFeature(mapbox, "vesselTrackPointLabelSource", "vesselTrackPointLabelLayer", clickFeatures?.[0]?.id)
		//   //   // const targetFeature = findFeatureInLayer(mapbox,"vesselTrackPointLabelLayer",featureId)
		//   //   // hideFeature(mapbox,"vesselTrackPointLabelLayer",targetFeature)
		// });
	};

	const drawVesselTrack = () => {
		let coordinates = shippingMap?.current?.vesselTrack?.geometry?.coordinates?.flat();
		if (coordinates?.length > 0) {
			startEndPointSource.current.features[0].geometry.coordinates = coordinates?.[0];
			startEndPointSource.current.features[1].geometry.coordinates =
				coordinates?.[coordinates?.length - 1];
			// ponitSource.current.data.features[2].geometry.coordinates = coordinates?.[coordinates?.length - 1];
			// ponitSource.current.data.features[3].geometry.coordinates = coordinates?.[coordinates?.length - 1];
			mapbox.current.flyTo(
				{
					center: startEndPointSource.current.features[1].geometry.coordinates,
					zoom: 2,
					duration: 1200
				},
				{
					moveend: "FLY_END"
				}
			);
			vesselTrackSource.current = shippingMap?.current?.vesselTrack;
			mapbox.current.getSource("startEndPointSource").setData(startEndPointSource.current);
			mapbox.current.getSource("vesselTrackSource").setData(vesselTrackSource.current);
			// mapbox.current.getSource("vesselArrowSource").setData(vesselTrackSource.current);
		} else {
			startEndPointSource.current.features[0].geometry.coordinates = [];
			startEndPointSource.current.features[1].geometry.coordinates = [];
			vesselTrackSource.current = {
				type: "FeatureCollection",
				features: [
					{
						type: "Feature",
						geometry: {
							type: "LineString",
							coordinates: []
						}
					}
				]
			};
			mapbox.current.getSource("startEndPointSource").setData(startEndPointSource.current);
			mapbox.current.getSource("vesselTrackSource").setData(vesselTrackSource.current);
			// mapbox.current.getSource("vesselArrowSource").setData(vesselTrackSource.current);
		}
	};

	const drwaVesselPosition = () => {
		const { vesselPosition } = shippingMap?.current;
		if (vesselPosition?.lat && vesselPosition?.lon) {
			// 212.76 + +vesselPosition?.hdg;
			vesselIconSource.current.features[0].geometry.coordinates = [
				vesselPosition?.lon,
				vesselPosition?.lat
			];
			vesselIconSource.current.features[0].properties.bearing = +vesselPosition?.heading;
			mapbox.current.flyTo(
				{
					center: vesselIconSource.current.features[0].geometry.coordinates,
					zoom: 2,
					duration: 1500
				},
				{
					moveend: "FLY_END"
				}
			);
		} else {
			vesselIconSource.current.features[0].geometry.coordinates = [];
			vesselIconSource.current.features[0].properties.bearing = 0;
		}

		mapbox.current?.getSource("vesselIconSource")?.setData(vesselIconSource.current);
	};

	const drawTrackPoints = () => {
		let direction = true;
		const coordinates: [number, number][] = [];
		const features = [];
		shippingMap?.current?.trackPoints?.map((item, idx) => {
			coordinates.push([item.lon, item.lat]);
			const id = uuid();
			features.push({
				type: "Feature",
				id: idx,
				geometry: {
					type: "Point",
					coordinates: [item.lon, item.lat]
				},
				// visibility: 'none',
				properties: {
					speed: formatThousandthNumber(item?.speed),
					time: dateToUtcString(item?.time),
					longitude: item?.lon,
					latitude: item?.lat,
					direction: direction ? -100 : 100,
					textDirection: direction ? -10 : 10,
					hidden: false,
					id
				}
			});
			trackLabelState.current.push({
				id: id,
				visibility: "visible"
			});
			direction = !direction;
		});
		// vesselTrackPointsSource.current.features[0].geometry.coordinates = coordinates;
		vesselTrackPointsLineSource.current.features[0].geometry.coordinates = coordinates;
		// vesselTrackPointLabelSource.current.features = features;
		vesselTrackPointLabelSource.current.features = features;
		vesselTrackPointsSource.current.features = features;
		mapbox?.current?.getSource("vesselTrackPointsSource")?.setData(vesselTrackPointsSource.current);
		mapbox?.current
			?.getSource("vesselTrackPointsLineSource")
			?.setData(vesselTrackPointsLineSource.current);
		mapbox?.current
			?.getSource("vesselTrackPointLabelSource")
			?.setData(vesselTrackPointLabelSource.current);
	};

	const drawVesselHis = () => {
		const features = [];
		Object.values(shippingMap?.current?.vesselHisTrack)?.map(({ index, path }, idx) => {
			features.push(...path);
		});
		vesselHisTrackSource.current.features = features;
		mapbox.current?.getSource("vesselHisTrackSource")?.setData(vesselHisTrackSource.current);
	};

	const drawPortTrack = () => {
		portTrackSource.current.features = [];
		console.log("portTrackSource", portTrackSource, shippingMap.current.portTrack);
		portTrackSource.current.features.push(shippingMap?.current?.portTrack);
		mapbox.current?.getSource("portTrackSource")?.setData(portTrackSource.current);
	};

	const drawPonit = () => {
		const features = [];
		shippingMap?.current?.portGather?.features?.map((item, idx, arr) => {
			features.push({
				type: "Feature",
				properties: {
					type: idx === 0 ? "start" : portSeq[idx - 1],
					index: idx === 0 ? "B" : idx
				},
				geometry: {
					type: "Point",
					coordinates: item?.geometry?.coordinates
				}
			});
		});
		portSource.current.features = features;
		debugger;
		mapbox.current?.getSource("portSource")?.setData(portSource.current);
		if (!features || features?.length <= 0) {
			return;
		}
		mapbox.current.flyTo(
			{
				center:
					portSource.current.features[portSource.current.features?.length - 1].geometry.coordinates,
				zoom: 2,
				duration: 1200
			},
			{
				moveend: "FLY_END"
			}
		);
	};

	// const drawDeliveryPort = () => {
	//   mapbox.current.flyTo(
	//     {
	//       center: deliveryPort,
	//       zoom: 2,
	//       duration: 1200
	//     },
	//     {
	//       moveend: "FLY_END"
	//     }
	//   );
	//   mapbox.current.getSource("anchorIconSource").setData({
	//     "type": "FeatureCollection",
	//     "features": [{
	//       "type": "Feature",
	//       "geometry": {
	//         "type": "Point",
	//         // 图片在地图中的坐标
	//         "coordinates": deliveryPort
	//       },
	//       "properties": {
	//         "bearing": 0
	//       }
	//     }]
	//   })
	// }

	useEffect(() => {
		if (!mapbox.current) {
			mapboxglInit();
		}
		return () => {
			mapbox.current?.remove();
			loading.current = false;
		};
	}, []);

	useEffect(() => {
		if (!mapbox.current) {
			return;
		}
		if (identifierMap?.vesselTrack === -1) return;
		if (!loading.current) {
			renderQueue.current.push(drawVesselTrack);
			return;
		}
		drawVesselTrack();
	}, [identifierMap?.vesselTrack]);

	useEffect(() => {
		if (!mapbox.current) {
			return;
		}

		if (identifierMap?.vesselPosition === -1) return;
		if (!loading.current) {
			renderQueue.current.push(drwaVesselPosition);
			return;
		}
		drwaVesselPosition();
	}, [identifierMap?.vesselPosition]);

	console.log("identifierMap", identifierMap, shippingMap);

	useEffect(() => {
		if (!mapbox.current) {
			return;
		}

		if (identifierMap?.trackPoints === -1) return;

		if (!loading.current) {
			renderQueue.current.push(drawTrackPoints);
			return;
		}

		drawTrackPoints();
	}, [identifierMap?.trackPoints]);

	useEffect(() => {
		if (!mapbox.current) {
			return;
		}

		if (identifierMap?.vesselHisTrack === -1) return;

		drawVesselHis();
	}, [identifierMap?.vesselHisTrack]);

	useEffect(() => {
		if (!mapbox.current) {
			return;
		}
		if (identifierMap?.portTrack === -1) return;
		if (!loading.current) {
			renderQueue.current.push(drawPortTrack);
			return;
		}

		drawPortTrack();
	}, [identifierMap?.portTrack]);

	useEffect(() => {
		if (!mapbox.current) {
			return;
		}
		if (identifierMap?.portGather === -1) return;
		if (!loading.current) {
			renderQueue.current.push(drawPonit);
			return;
		}
		drawPonit();
	}, [identifierMap?.portGather]);

	return {
		mapContainer
	};
};

export default useGraph;
