import React, { FC, useEffect, useState } from "react";
import style from "./index.module.less";
import { EstimationVesselArrivalProps } from "./type";
import { useAppDispatch } from "@/hook";
import { useNavigate } from "react-router-dom";
import useReminder from "@/hook/useReminder";
import { vesselArrivalOptions } from "./source";
import { useTranslation } from "react-i18next";
import { CommonVesselArchivalType } from "@/common/VesselArchives/type";
import { queryVesselArchiveForImoAction } from "@/action/dataBase/vesselArchives";

const EstimationVesselArrival: FC<EstimationVesselArrivalProps> = ({
  aisData,
  imo
}) => {
  const dispatch = useAppDispatch()
  const navigate = useNavigate()
  const { reminder } = useReminder()
  const { t } = useTranslation()
  const [dataSource, setDataSource] = useState<Partial<CommonVesselArchivalType>>()

  const getVesselArchivesSuccess = (response) => {
    setDataSource(response?.data)
  }

  const getVesselArchivesFailed = (error) => {
    reminder("error", error?.msg ? (error?.msg + ": " + error?.data) : error?.data);
  }

  const getVesselArchives = () => {
    queryVesselArchiveForImoAction(
      { imo: imo },
      null,
      getVesselArchivesSuccess,
      getVesselArchivesFailed,
      dispatch,
      navigate
    )
  }

  useEffect(() => {
    if (!imo) return
    getVesselArchives()
  }, [imo])
  return <div className={style["estimation-vesselArrival"]}>
    <span className="details-title">Vessel Particular</span>
    <div className="vesselArrival-container">
      {
        vesselArrivalOptions?.map((arrival, arrivalIndex) => {
          return <div key={arrivalIndex} className="vesselArrival">
            {
              arrival?.map((item, index) => {
                return <div key={item?.dataIndex}>
                  <span>{t(`vesselArrival.${item?.dataIndex}`)}</span>
                  {item?.renderValue ? item?.renderValue(item?.dataIndex, dataSource, aisData) : <span>{dataSource?.[item?.dataIndex]}</span>}
                </div>
              })
            }
          </div>
        })
      }
    </div>
  </div>
}

export default EstimationVesselArrival