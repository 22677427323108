import React, { FC } from "react";
import style from "./index.module.less";
import { CommonUserPopupProps } from "./type";
import { FleetLoading } from "@/components";
import commonStyle from "../../../../index.module.less"
import classnames from "classnames";

const CommonUserPopup: FC<CommonUserPopupProps> = ({
  list,
  loading,
  onSelect,
  loadMore
}) => {
  const handleSelect = (e: React.MouseEvent<HTMLUListElement, MouseEvent>, item: CommonUserPopupProps["list"][number]) => {
    e?.stopPropagation()
    onSelect?.(item)
  }
  return <div className={classnames(commonStyle["common-select--Popup"], style["common-userSelect--Popup"])}>
    <FleetLoading loading={loading} />
    <ul className="select--popup--header">
      <span>Name</span>
      {/* <span>Company Name</span> */}
    </ul>
    <div className="select--popup--container fleet-nonScrolBar">
      <div className="select--popup--scrollArea">
        {
          list?.map((item, index) => {
            return <ul key={index} className="select--popup--item" onClick={(e) => {
              handleSelect?.(e, item)
            }}>
              <li>{item?.nickName}</li>
              {/* <li>{item?.companyName}</li> */}
            </ul>
          })
        }
      </div>
    </div>
    <div className="select--popup--loadMore" onClick={loadMore}>
      <span>Load More</span>
      <span className="font_family fleet-back"></span>
    </div>
  </div>
}

export default CommonUserPopup