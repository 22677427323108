
import { getStowageAction, updateStowageAction } from "@/action/estimation/stowage";
import { useAppDispatch } from "@/hook";
import useReminder from "@/hook/useReminder";
import {
  StowageFormType,
  StowagePortInfoFormType,
  StowagePortInfoKeys,
  StowageVesselFormType,
  StowageVesselKeys
} from "@/types/estimation/details/index";
import { useState, useMemo, useRef } from "react";
import { useNavigate } from "react-router-dom";
import { StowagePlanProps } from "@/featrue/estimation/details/stowagePlan";

export type InitiPortInfoVosType = {
  ballast: StowagePortInfoFormType[],
  laden: StowagePortInfoFormType[]
}

const useStowagePlan = (
  source: StowagePlanProps["source"],
  onClose?: () => void,
) => {
  const { reminder } = useReminder();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const portInfoVos = useRef<StowagePortInfoFormType[]>([]);
  const [active, setActive] = useState<"dwt" | "grain" | "bale">("dwt")

  const [stowagePlan, setStowagePlan] = useState<StowageFormType>({
    firstPortName: "",
    vesselVo: {
      bale: "",
      ballastWater: "",
      brobDo: "",
      brobFo: "",
      constant: "",
      dwt: "",
      freshWater: "",
      grain: "",
      id: "",
      loadedOthers: "",
      resultId: source?.resultId,
      sswDraft: "",
      stowageFactor: "",
      tonnageType: "TPC",
      tonnageValue: "",
      tropical: ""
    },
    portInfoVos: []
  });


  const stowageGroup = useMemo(() => {
    let maxQuantity = 0;
    let prevDwt = 0;
    let adjustedDwt = 0;
    const {
      portInfoVos,
      vesselVo
    } = stowagePlan;
    return portInfoVos?.map((item, idx) => {
      switch (active) {
        case "bale":
          adjustedDwt = +vesselVo.bale / (+vesselVo?.stowageFactor || 1);
          break;
        case "grain":
          adjustedDwt = +vesselVo.grain / (+vesselVo?.stowageFactor || 1);
          break
        case "dwt":
          adjustedDwt = (idx === 0 ? +vesselVo?.dwt : prevDwt) - (+vesselVo?.sswDraft - +item?.draftLimitation * (+item?.density / 1.025)) * 100 * +vesselVo?.tonnageValue;
      }


      if (idx === 0) {
        maxQuantity = adjustedDwt - (+vesselVo.constant
          + +vesselVo?.ballastWater + + vesselVo?.freshWater + +vesselVo?.brobFo + +vesselVo?.brobDo + +vesselVo?.loadedOthers
        ) + +vesselVo?.tonnageValue + (
            +item.portDo + +item?.portFo + +item?.seaFuelDo + +item?.seaFuelFo
          ) - (+item?.bunkerDo + + item?.bunkerFo)
      } else {
        maxQuantity = adjustedDwt + (
          +item.portDo + +item?.portFo + +item?.seaFuelDo + +item?.seaFuelFo
        ) - (+item?.bunkerDo + + item?.bunkerFo)
      }

      prevDwt = maxQuantity;



      return {
        adjustedDwt,
        maxQuantity
      }
    })
  }, [stowagePlan, active])

  const portInfoVosInit = (initPortInfoVos: {
    ballast: StowagePortInfoFormType[],
    laden: StowagePortInfoFormType[],
    firstPortName: string,
    vesselVo: StowageVesselFormType
  }) => {
    const {
      startVoyTime,
      ballastSpeed,
      ballastPortList,
      ladenSpeed,
      ladenPortList,
      seaFuelBallast,
      resultId,
      seaFuelLaden,
      seaFuelMdo,
      portFuelIdle,
      portFuelMdo,
      portFuelWork
    } = source;
    let group: StowagePortInfoFormType[] = [];

    for (let i = 0; i < ballastPortList?.length; i++) {
      let distance = ballastPortList[i].distance;
      let seaIfo = 0;
      let seaMdo = 0;
      let portIfo = 0;
      let portMdo = 0;
      let timeConsue = +distance / +ballastSpeed /24;

      seaIfo = +seaFuelBallast * timeConsue + +seaFuelLaden * timeConsue;
      seaMdo = +seaFuelMdo * timeConsue;
      portIfo = +portFuelIdle * +ballastPortList[i].idleDays + +portFuelWork * +ballastPortList[i]?.workDays;
      portMdo = +portFuelMdo * (+ballastPortList[i]?.idleDays + +ballastPortList[i]?.workDays);
      let groupItem = {
        id: ballastPortList[i]?.id,
        resultId: resultId,
        seaFuelFo: +seaIfo.toFixed(2),
        seaFuelDo: +seaMdo.toFixed(2),
        portFo: +portIfo.toFixed(2),
        portDo: +portMdo.toFixed(2),
        bunkerDo: initPortInfoVos?.ballast?.[i]?.bunkerDo,
        bunkerFo: initPortInfoVos?.ballast?.[i]?.bunkerFo,
        adjustedDwt: initPortInfoVos?.ballast?.[i]?.adjustedDwt,
        draftLimitation: initPortInfoVos?.ballast?.[i]?.draftLimitation,
        density: initPortInfoVos?.ballast?.[i]?.density || 1.025,
        portName: initPortInfoVos?.ballast?.[i]?.portName,
        purpose: initPortInfoVos?.ballast?.[i]?.purpose,
      }
      group.push({ ...groupItem });

      portInfoVos.current.push({ ...groupItem })
    }
    for (let i = 0; i < ladenPortList?.length; i++) {
      let distance = ladenPortList[i].distance;
      let timeConsue = +distance / +ladenSpeed / 24;
      let seaIfo = +seaFuelBallast * timeConsue + +seaFuelLaden * timeConsue;
      let seaMdo = +seaFuelMdo * timeConsue;
      let portIfo = +portFuelIdle * +ladenPortList[i].idleDays + +portFuelWork * +ladenPortList[i]?.workDays;
      let portMdo = +portFuelMdo * (+ladenPortList[i]?.idleDays + +ladenPortList[i]?.workDays);
      let groupItem = {
        id: ladenPortList[i]?.id,
        resultId: resultId,
        seaFuelFo: +seaIfo.toFixed(3),
        seaFuelDo: +seaMdo.toFixed(3),
        portFo: +portIfo.toFixed(3),
        portDo: +portMdo.toFixed(3),
        bunkerDo: initPortInfoVos?.laden?.[i]?.bunkerDo,
        bunkerFo: initPortInfoVos?.laden?.[i]?.bunkerFo,
        adjustedDwt: initPortInfoVos?.laden?.[i]?.adjustedDwt,
        draftLimitation: initPortInfoVos?.laden?.[i]?.draftLimitation,
        density: initPortInfoVos?.laden?.[i]?.density || 1.025,
        portName: initPortInfoVos?.laden?.[i]?.portName,
        purpose: initPortInfoVos?.laden?.[i]?.purpose,
      }
      if (i === 0) {
        group.push({ ...groupItem });
      }
      portInfoVos.current.push({ ...groupItem })
    }

    setStowagePlan({
      firstPortName: initPortInfoVos.firstPortName,
      vesselVo: initPortInfoVos.vesselVo,
      portInfoVos: group
    })
  }

  const init = (item: StowageFormType) => {
    let ladenPorts = item.portInfoVos?.filter(item => ["DC", "DB", "DT"].includes(item.purpose));
    let ballastPorts = item.portInfoVos?.filter(item => ["LD", "LB", "LT"].includes(item.purpose));
    portInfoVosInit({
      firstPortName: item.firstPortName,
      vesselVo: item.vesselVo,
      ballast: ballastPorts,
      laden: ladenPorts
    })
  }

  const vesselChange = (key: StowageVesselKeys, value: string | number) => {
    if (key === "tonnageType") {
      let tonnageValue = stowagePlan?.vesselVo["tonnageValue"]
      switch (value) {
        case "TPC":
          tonnageValue = +tonnageValue / 0.39370;
          break;
        case "TPI":
          tonnageValue = +tonnageValue * 0.39370;
        default:
          break;
      }
      setStowagePlan({
        ...stowagePlan,
        vesselVo: {
          ...stowagePlan.vesselVo,
          tonnageValue: tonnageValue,
          tonnageType: value as "TPC" | "TPI"
        }
      })
      return;
    }
    setStowagePlan({
      ...stowagePlan,
      vesselVo: {
        ...stowagePlan.vesselVo,
        [key]: value
      }
    })
  }

  const portChange = (id: string | number, key: Exclude<StowagePortInfoKeys, "id">, value: string | number) => {
    const portIndex = stowagePlan.portInfoVos?.findIndex(item => item.id == id);
    const portItem = stowagePlan.portInfoVos?.[portIndex];
    // @ts-ignore
    portItem[key] = value;
    stowagePlan.portInfoVos.splice(portIndex, 1, portItem)
    setStowagePlan(prev => {
      return {
        ...prev,
        portInfoVos: [...stowagePlan.portInfoVos]
      }
    })
  }

  const getStowageFront = () => {
    portInfoVos.current = [];
    setLoading(true)
  }

  const getStowageSuccess = (response) => {
    setLoading(false);
    init(response?.data)
    console.log('stowage', response)
  }

  const getStowageError = (error) => {
    reminder("error", error?.msg ? (error?.msg + ": " + error?.data) : error?.data)
    setLoading(false);

  }

  const getStowage = () => {
    getStowageAction(
      { resultId: source?.resultId },
      getStowageFront,
      getStowageSuccess,
      getStowageError,
      dispatch,
      navigate
    )
  }

  const updateStowageSuccess = (response) => {
    setLoading(false)
    reminder("success", response?.msg);
    onClose?.()
  }

  const updateStowageError = (error) => {
    setLoading(false)
    reminder("error", error?.msg ? (error?.msg + ": " + error?.data) : error?.data);
  }

  const updateStowage = (stowageGroup: {
    adjustedDwt: number,
    maxQuantity: number
  }[]) => {
    const portInfoVos = stowagePlan.portInfoVos.map((item, idx) => {
      const { id, ...rest } = item;
      if (/[0-9a-f]{8}(-[0-9a-f]{4}){3}-[0-9a-f]{12}/.test((id).toString())) {
        return {
          ...rest,
          adjustedDwt: +stowageGroup[idx].adjustedDwt.toFixed(3),
          maxQuantity: +stowageGroup[idx].maxQuantity.toFixed(3)
        }
      }
      return {
        ...item,
        adjustedDwt: +stowageGroup[idx].adjustedDwt.toFixed(3),
        maxQuantity: +stowageGroup[idx].maxQuantity.toFixed(3)
      }
    })
    updateStowageAction(
      {
        firstPortName: stowagePlan.firstPortName,
        vesselVo: stowagePlan.vesselVo,
        portInfoVos: portInfoVos
      },
      getStowageFront,
      updateStowageSuccess,
      updateStowageError,
      dispatch,
      navigate
    )
  }

  return {
    stowagePlan,
    portChange,
    getStowage,
    updateStowage,
    stowageGroup,
    active,
    setActive,
    loading,
    vesselChange
  }

}

export default useStowagePlan;