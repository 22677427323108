import React, { FC } from "react";
import style from "./index.module.less";
import { MonitoringAreaVesselListProps } from "./type";
import classnames from "classnames";
import { AreaListFilter, AreaListTable } from "./components/index";
import useIndex from "./hooks/useIndex";

const MonitoringAreaVesselList: FC<MonitoringAreaVesselListProps> = ({
	areaItems,
	activeIndex,
	currentTab,
	onAisItemSelect,
	onOpenPortCall,
	onAreaPortVesselLoad
}) => {
	const {
		filter,
		loading,
		tableData,
		selectedRows,
		handleCommit,
		handleDownLoad,
		handleRowClick,
		handleRowSelect,
		handleTimeRange,
		handleSelectAll
	} = useIndex(
		activeIndex,
		currentTab,
		areaItems,
		onAisItemSelect,
		onOpenPortCall,
		onAreaPortVesselLoad
	);
	return (
		<div
			className={classnames(style["areaVessel-list"], {
				[style["areaVessel-list--active"]]:
					activeIndex === "areaVesslDetails" && currentTab === "area"
			})}
		>
			<div className="areaVessel-fliter">
				<AreaListFilter
					activeIndex={activeIndex}
					filter={filter}
					selectedRows={selectedRows}
					onCommit={handleCommit}
					onDownLoad={handleDownLoad}
					onTimeRange={handleTimeRange}
				></AreaListFilter>
				<AreaListTable
					loading={loading}
					tableData={tableData}
					onClick={handleRowClick}
					selectedRows={selectedRows}
					onSelect={handleRowSelect}
					onSelectAll={handleSelectAll}
				></AreaListTable>
			</div>
		</div>
	);
};

export default MonitoringAreaVesselList;
