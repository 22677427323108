import { FleetOptionType } from "@/types/advancedType";
import { AparListFilterItemType } from "./type";
import { ExtendsKeys } from "@/types";

export const settleOptions: FleetOptionType<
	AparListFilterItemType["status"],
	AparListFilterItemType["status"]
>[] = [
	{ label: "Settled", value: "Settled" },
	{ label: "Unsettled", value: "Unsettled" }
];

export const invStatusOptions: FleetOptionType<
	AparListFilterItemType["type"],
	AparListFilterItemType["type"]
>[] = [
	{ label: "AP", value: "AP" },
	{ label: "AR", value: "AR" }
];

export const voyageTypeOptions: FleetOptionType<
	ExtendsKeys<AparListFilterItemType["voyageType"], "TCTC" | "TCVC">,
	ExtendsKeys<AparListFilterItemType["voyageType"], "TCTC" | "TCVC">
>[] = [
	{
		label: "TCTC",
		value: "TCTC"
	},
	{
		label: "TCVC",
		value: "TCVC"
	}
];
