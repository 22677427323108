import React, { FC } from "react";
import style from "./index.module.less";
import { RouteCommonDetailsTabsProps } from "./type";
import { tabOptions } from "./source";
import classnames from "classnames";

const RouteCommonDetailsTabs: FC<RouteCommonDetailsTabsProps> = ({ activeTab, onSelect }) => {
	return (
		<ul
			className={classnames(style["routeCommon-details-tabs"], {
				[style["routeCommon-details-tabs--empty"]]: !tabOptions?.length
			})}
		>
			{tabOptions?.map((item) => {
				return (
					<li
						key={item?.value}
						className={classnames("details-tabItem", {
							active: item?.value === activeTab
						})}
						onClick={() => {
							onSelect?.(item?.value);
						}}
					>
						{item?.label}
					</li>
				);
			})}
		</ul>
	);
};

export default RouteCommonDetailsTabs;
