import { type ApiParameter } from "@/api";
import request from '@/service';
import {
  getVoyageAttachAndContactParams,
  updatePreopRemarkParams,
  saveVoyageContactParams,
  uploadVoyageAttachParams
} from "./type";
import { appVersion } from "@/api/constant";

const enum ApiMap {
  getVoyageAttachAndContactParameter,
  updatePreopRemarkParameter,
  saveVoyageContactParameter,
  uploadVoyageAttachParameter
}

type ApiUrl = keyof typeof ApiMap;

const attachAndContactApi: ApiParameter<ApiUrl> = {
  getVoyageAttachAndContactParameter: {
    method: "get",
    url: appVersion + "/pre/voyage/attach-contact"
  },
  updatePreopRemarkParameter: {
    method: "put",
    url: appVersion + "/pre/voyage/highlighted-point"
  },
  saveVoyageContactParameter: {
    method: "post",
    url: appVersion + "/vm/contact"
  },
  uploadVoyageAttachParameter: {
    method: "post",
    url: appVersion + "/pre/voyage/attach"
  }
}

export const getVoyageAttachAndContactApi = (params: getVoyageAttachAndContactParams) => {
  return request.request({
    ...attachAndContactApi.getVoyageAttachAndContactParameter,
    url: `${attachAndContactApi.getVoyageAttachAndContactParameter.url}/${params?.voyageId}`
  })
}

export const updatePreopRemarkApi = (params: updatePreopRemarkParams) => {
  return request.request({
    ...attachAndContactApi.updatePreopRemarkParameter,
    data: params
  })
}


export const saveVoyageContactApi = (params: saveVoyageContactParams) => {
  return request.request({
    ...attachAndContactApi.saveVoyageContactParameter,
    data: params
  })
}


export const uploadVoyageAttachApi = (params: uploadVoyageAttachParams) => {
  const { voyageId, files, type } = params
  return request.request({
    ...attachAndContactApi.uploadVoyageAttachParameter,
    url: `${attachAndContactApi.uploadVoyageAttachParameter.url}/${voyageId}`,
    params: { type },
    data: files
  })
}