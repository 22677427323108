import { FleetInput, FleetSelect } from "@/components"
import { MovementCommonTableColumnsType } from "../../../charterBase/common/table/type"
import { MovementBunkerPortItemType, BunkerPortItemChangeEvent, BunkerPortItemCommitEvent } from "./type"
import React from "react";
import { CommonCompanySelect } from "@/common/Select"
import { Icon } from "@/_components";

export const columns: MovementCommonTableColumnsType<
	MovementBunkerPortItemType,
	keyof MovementBunkerPortItemType,
	{
		onChange: BunkerPortItemChangeEvent;
		onCommit: BunkerPortItemCommitEvent;
		onOpen: () => void;
	}
>[] = [
	{
		label: "Status",
		dataIndex: "id",
		width: 128,
		fixed: "left",
		render: (value, record, index, actions) => {
			return (
				<FleetSelect
					className="td-select"
					value={["bunker"]}
					disabled
					options={[
						{
							label: "Bunker",
							value: "bunker"
						}
					]}
				/>
			);
		}
	},
	{
		label: "Port Name",
		dataIndex: "portName",
		width: 126,
		render: (value, record, index, actions) => {
			return <FleetInput className="td-input" value={value} disabled />;
		}
	},
	{
		label: "Bunker Port Expense",
		dataIndex: "portExpense",
		width: 150,
		render: (value, record, index, actions) => {
			return (
				<FleetInput
					type="number"
					className="td-input"
					value={value}
					onChange={(val: number) => {
						actions?.onChange?.(index, "portExpense", val);
					}}
				/>
			);
		}
	},
	{
		label: "Agent",
		dataIndex: "portAgentId",
		width: 293,
		render: (value, record, index, actions) => {
			return (
				<div className="flex items-center justify-between">
					<CommonCompanySelect
						activeItems={{
							id: record?.portAgentId,
							companyName: record?.portAgentName
						}}
						showKey="companyName"
						onFinish={(newItem, type) => {
							actions?.onCommit?.(index, {
								portAgentId: newItem?.id ?? null,
								portAgentName: newItem?.companyName ?? ""
							});
						}}
						className="w-[calc(100%-36px)]"
					/>
					<Icon
						type="fleet-plus"
						className="cursor-pointer opacity-40"
						size={32}
						onClick={actions?.onOpen}
					/>
				</div>
			);
		}
	}
];