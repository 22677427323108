import React, { FC } from "react";
import classnames from "classnames";
import style from "./index.module.less";
import { MovementVoyageRemarksProps } from "./type";
import { FleetTextarea } from "@/components";

const MovementVoyageRemarks: FC<MovementVoyageRemarksProps> = ({ item, voyageType, onChange }) => {
	return (
		<div
			className={classnames(style["movement-voyageRemarks"], {
				[style["movement-voyageRemarks-tctc"]]: voyageType === "TCTC"
			})}
		>
			<div className="movement-details--header">
				<span className="fleet-title">Voyage Remarks</span>
			</div>
			<div className="voyageRemarks-container">
				<div>
					<span>Handover Highlighted Points</span>
					<FleetTextarea
						className="voyageRemarks-textarea"
						placeholder="Please input remakes"
						value={item?.highlightedPoint}
						onChange={(val: string) => {
							onChange?.("highlightedPoint", val);
						}}
					/>
				</div>
				<div>
					<span>Operation Remarks</span>
					<FleetTextarea
						className="voyageRemarks-textarea"
						placeholder="Please input remakes"
						value={item?.voyageRemark}
						onChange={(val: string) => {
							onChange?.("voyageRemark", val);
						}}
					/>
				</div>
			</div>
		</div>
	);
};

export default MovementVoyageRemarks