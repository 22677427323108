import React, { FC } from "react";
import style from "./index.module.less";
import classnames from "classnames";
import { MovementTemporaryTasksItemType, MovementTemporaryTasksProps } from "./type";
import { MovementTaskPriority, MovementTaskPriorityLabelMap } from "./source";
import dayjs from "dayjs";
import { useNavigate } from "react-router-dom";

const MovementTemporaryTasks: FC<MovementTemporaryTasksProps> = ({
	items,
	currentVoyageId,
	currentVoyageType
}) => {
	const navigate = useNavigate();

	const handleJumpTask = (
		event: React.MouseEvent<HTMLDivElement, globalThis.MouseEvent>,
		item: MovementTemporaryTasksItemType
	) => {
		event?.stopPropagation();
		navigate(
			`/layout/voyagemanage/movement/details?id=${currentVoyageId}&task_open=true&stage=${item?.stage}&port_rotation_id=${item?.portRotationId ?? ""}&task_id=${item?.id}&voyageType=${currentVoyageType}`
		);
	};

	return (
		<div className={classnames(style["movement-temporaryTasks"], "fleet-nonScrolBar")}>
			<div className="temporaryTasks-scrollArea">
				{items?.map((item, index) => {
					return (
						<div
							className={classnames("temporaryTasks-item", {
								"last-first": item?.alarmType === 1,
								"last-second": item?.alarmType === 2,
								"last-thiry": item?.alarmType === 3
							})}
							key={index}
							onClick={(event) => {
								handleJumpTask(event, item);
							}}
						>
							<div className="temporaryTasks-item--container">
								<span className="temporaryTasks-item--stage">
									{item?.stage}
									{item?.portName && `(${item?.portName})`}
								</span>
								<div className="temporaryTasks-item--info">
									<span
										className={classnames(
											"temporaryTasks-item--priority",
											MovementTaskPriorityLabelMap[item?.priority]
										)}
									>
										{MovementTaskPriority[item?.priority]}
									</span>
									<span className="temporaryTasks-item--title">{item?.title}</span>
								</div>
								<div className="temporaryTasks-item--footer">
									<span>{dayjs(item?.deadLine)?.format("YYYY/MM/DD HH:mm")}</span>
									<div className={classnames("temporaryTasks-item--deadline")}>
										<span className="font_family fleet-Calender"></span>
										<span>{item?.alarmType} 天</span>
									</div>
								</div>
							</div>
						</div>
					);
				})}
			</div>
		</div>
	);
};

export default MovementTemporaryTasks;
