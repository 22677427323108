import { type ApiParameter } from "@/api";
import { appVersion } from "@/api/constant";
import request from "@/service";
import {
	getSelectedPortListParams,
	getPortVesselListParams,
	savePortListSearchParams,
	getCountryCodeListParams,
	getAreaCodeListParams,
	downLoadPortVesselParams
} from "./type";

const enum ApiMap {
	getSelectedPortListParameter,
	getPortVesselListParameter,
	getPortVesselSummaryParameter,
	getCountryCodeListParameter,
	getAreaCodeListParameter,
	getPortVesselSearchDataParameter,
	savePortVesselSearchDataParameter,
	downloadPortVesselParameter
}

type ApiUrl = keyof typeof ApiMap;

const monitoringPortListApi: ApiParameter<ApiUrl> = {
	getSelectedPortListParameter: {
		method: "get",
		url: appVersion + `/ibiz-port`
	},
	getPortVesselListParameter: {
		method: "get",
		url: appVersion + `/moni/ship/port/list`
	},
	getPortVesselSearchDataParameter: {
		method: "get",
		url: appVersion + `/moni/ship/port/argument`
	},
	savePortVesselSearchDataParameter: {
		method: "post",
		url: appVersion + `/moni/ship/port/argument`
	},
	getPortVesselSummaryParameter: {
		method: "get",
		url: appVersion + `/moni/ship/port/statistics/list`
	},
	getCountryCodeListParameter: {
		method: "get",
		url: appVersion + `/ibiz-country`
	},
	getAreaCodeListParameter: {
		method: "get",
		url: appVersion + `/moni/area/info/list`
	},
	downloadPortVesselParameter: {
		method: "post",
		url: appVersion + "/moni/ship/port/download/vessel"
	}
};

export const getSelectedPortListApi = (params: getSelectedPortListParams) => {
	return request.request({
		...monitoringPortListApi.getSelectedPortListParameter,
		params
	});
};

export const getPortVesselListApi = (params: getPortVesselListParams) => {
	return request.request({
		...monitoringPortListApi.getPortVesselListParameter,
		params
	});
};

export const getPortVesselSearchDataApi = () => {
	return request.request({
		...monitoringPortListApi.getPortVesselSearchDataParameter
	});
};

export const savePortVesselSearchDataApi = (data: savePortListSearchParams) => {
	return request.request({
		...monitoringPortListApi.savePortVesselSearchDataParameter,
		data
	});
};

export const getPortVesselSummaryApi = (params: getPortVesselListParams) => {
	return request.request({
		...monitoringPortListApi.getPortVesselSummaryParameter,
		params
	});
};

export const getCountryCodeListApi = (params: getCountryCodeListParams) => {
	return request.request({
		...monitoringPortListApi.getCountryCodeListParameter,
		params
	});
};

export const getAreaCodeListApi = (params: getAreaCodeListParams) => {
	return request.request({
		...monitoringPortListApi.getAreaCodeListParameter,
		params
	});
};

export const downloadPortVesselApi = (data: downLoadPortVesselParams[]) => {
	return request.request({
		...monitoringPortListApi.downloadPortVesselParameter,
		data,
		responseType: "blob"
	});
};
