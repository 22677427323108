import React from "react";
import style from "./index.module.less";
import { EsMultipleRouteProps, columns } from "./type";
import { FleetIcon, FleetLoading } from "@/components";
import MovementTable from "@/featrue/voyagemanage/movement/common/table";

const MultipleRoute: React.FC<EsMultipleRouteProps> = ({
  show,
  checkbox,
  onClose,
  loading,
  items,
  actions,
  onConfirm
}) => {
  return <div className={style["esDetails-mulRoute"]} style={{
    visibility:show ? "unset" : "hidden",
    zIndex:show ? 2 : 1
  }}>
    <FleetLoading loading={loading} />
    <div className="mulRoute-header">
      <div className="header-select">
        <FleetIcon 
          type="FleetBPointerIcon"
          onClick={onClose}
        />
        <span>Route</span>
        <span>Secondary</span>
      </div>
      {/* <span onClick={onConfirm} className="header-confirm">Confirm</span> */}
    </div>
    <MovementTable 
      columns={columns}
      className="mulRoute-container"
      dataSource={items}
      actions={actions}
      checkbox={checkbox}
    />
  </div>
}

export default MultipleRoute