import React, { useMemo } from "react";
import style from "./index.module.less";
import HeaderMenu from "./menu";
import HeaderFilter from "./filter";
import { useAppSelector } from "@/hook";
import { FleetImage } from "@/components";
import FleetLogo from "static/icons/fleet_logo.svg";

const LayoutHeader: React.FC<{}> = (props) => {
	const { userInfo } = useAppSelector((state) => state.UserStore);
	return (
		<div className={style["fleet-layout-header"]}>
			<div className="layout-header-left">
				<FleetImage src={FleetLogo} className="layout-header-logo" />
				<HeaderMenu items={userInfo?.menus} />
			</div>
			<HeaderFilter />
		</div>
	);
};

export default LayoutHeader;
