import React, { FC } from "react";
import classnames from "classnames";
import commonStyle from "@/common/Select/index.module.less";
import style from "./index.module.less";
import { RoutePortPopupProps } from "./type";
import { FleetLoading } from "@/components";

const RoutePortPopup: FC<RoutePortPopupProps> = ({ list, loading, onSelect, loadMore }) => {
	const handleSelect = (
		e: React.MouseEvent<HTMLUListElement, MouseEvent>,
		item: RoutePortPopupProps["list"][number]
	) => {
		e?.stopPropagation();
		onSelect?.(item);
	};
	console.log(list);
	return (
		<div
			className={classnames(commonStyle["common-select--Popup"], style["route-portSelect--Popup"])}
		>
			<FleetLoading loading={loading} />
			<ul className="select--popup--header">
				<li>Port Name</li>
				<li>Port Code</li>
				<li>Area</li>
			</ul>
			<div className="select--popup--container fleet-nonScrolBar">
				<div className="select--popup--scrollArea">
					{list?.map((item, index) => {
						return (
							<ul
								key={index}
								className="select--popup--item"
								onClick={(e) => {
									handleSelect?.(e, item);
								}}
							>
								<li>{item?.portName}</li>
								<li>{item?.unlCode}</li>
								<li>{item?.countryName}</li>
							</ul>
						);
					})}
				</div>
			</div>
			<div className="select--popup--loadMore" onClick={loadMore}>
				<span>Load More</span>
				<span className="font_family fleet-back"></span>
			</div>
		</div>
	);
};

export default RoutePortPopup;
