import { type ApiParameter } from "@/api";
import { appVersion } from "@/api/constant";
import request from "@/service";
import { getVesselArchivesParams } from "./type";

const enum ApiMap {
	getVesselArchivesParameter
}

type ApiUrl = keyof typeof ApiMap;

const aisDataApi: ApiParameter<ApiUrl> = {
	getVesselArchivesParameter: {
		method: "get",
		url: appVersion + "/ais/cmes/basic"
	}
};

export const getVesselArchivesApi = (params: getVesselArchivesParams) => {
	return request.request({
		...aisDataApi.getVesselArchivesParameter,
		url: `${aisDataApi?.getVesselArchivesParameter?.url}/${params?.imo}`
	});
};
