import { useState } from "react";
import {lanOrLonInputPropsType} from '../type'
const useIndex = (onChange:lanOrLonInputPropsType['onCommit']) => {
  const [minLat, setMinLat] = useState<number>(0);
  const [secLat, setSecLat] = useState<number>(0);
  const [minLon, setMinLon] = useState<number>(0);
  const [secLon, setSecLon] = useState<number>(0);
  const [directionLat, setDirectionLat] = useState<string>();
  const [directionLon, setDirectionLon] = useState<string>();
  const [openLatLon, setOpenLatLon] = useState<boolean>(false);
  const confirmLatLon = () => {
    const lat = directionLat=='S'
      ? -(minLat + secLat / 60).toFixed(2)
      : +(minLat + secLat / 60).toFixed(2);
    const lon = directionLat=='W'
      ? -(minLon + secLon / 60).toFixed(2)
      : +(minLon + secLon / 60).toFixed(2);
    onChange({lat,lon})
    setOpenLatLon(false);
  };
  const northSouth = [
    { value: "N", label: "N" },
    { value: "S", label: "S" },
  ];
  const eastWest = [
    { value: "E", label: "E" },
    { value: "W", label: "W" },
  ];
  return {
    minLat,
    secLat,
    minLon,
    secLon,
    directionLat,
    directionLon,
    openLatLon,
    northSouth,
    eastWest,
    setMinLat,
    setSecLat,
    setMinLon,
    setSecLon,
    setDirectionLat,
    setDirectionLon,
    setOpenLatLon,
    confirmLatLon,
  };
};
export default useIndex;
