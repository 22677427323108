import React, { FC, useMemo } from "react";
import style from "./index.module.less";
import { DailyReportCategoryType, DailyReportItemType, RouteCommonDailyReportProps } from "./type";
import { cn } from "@/tools";
import useIndex from "./hooks/useIndex";
import { FleetLoading } from "@/components";
import { Button, Icon, Popover, Table } from "@/_components";
import { categoryOptions, columns as list_columns } from "./source";
import { CommonColumnCategory } from "@/common";
import { ColumnType } from "antd/es/table";

const RouteCommonDailyReport: FC<RouteCommonDailyReportProps> = ({}) => {
	const {
		activeDetailsOption,
		loading,
		dataSource,
		checkedGroup,
		handleRowSelect,
		handleCheckedGroupReset,
		handleCheckedGroupSelect,
		handleCheckedGroupSelectAll
	} = useIndex();

	const columns = useMemo(() => {
		const keys = Object.values(checkedGroup)?.reduce((prev, curr) => {
			return prev.concat(curr);
		}, []);
		return list_columns?.filter((column: ColumnType<DailyReportItemType>) =>
			keys?.includes(column?.dataIndex as keyof DailyReportItemType)
		);
	}, [checkedGroup]);

	return (
		<div
			className={cn(
				style["routeCommon-dailyReport"],
				"w-full h-full relative hidden gap-[20px] pt-[12px] pr-[12px] pb-[8px] pl-[25px] flex-col",
				{
					flex:
						activeDetailsOption.activeTab === "dailyReport" &&
						!activeDetailsOption?.isPortCallVisible &&
						!activeDetailsOption?.activeRoutePlan
				}
			)}
		>
			<FleetLoading loading={loading} />
			<div className="w-full flex items-center gap-[20px]">
				<div className="flex flex-row gap-[12px]">
					<span className="text-[#333] leading-[22px] font-medium text-[16px]">Report List</span>
					<span className="text-[14px] text-[#48484899] font-medium leading-[22px]">
						Total:{""}
						<span className="text-[#333]">{dataSource?.length}</span>
					</span>
				</div>
				<Popover
					trigger="click"
					content={
						<CommonColumnCategory<DailyReportCategoryType, keyof DailyReportItemType>
							options={categoryOptions}
							selectRows={checkedGroup}
							onSelect={handleCheckedGroupSelect}
							onSelectAll={handleCheckedGroupSelectAll}
							onReset={handleCheckedGroupReset}
						/>
					}
					children={
						<Button size="small" type="secondary" prefixIcon={<Icon type="fleet-filter1" />}>
							Filter
						</Button>
					}
				/>
			</div>
			<Table<DailyReportItemType>
				primaryKey="reportDateTime"
				dataSource={dataSource}
				pagination={false}
				columns={columns}
				className="dailyReport-list w-full h-[calc(100%-52px)]"
				rowSelection={{
					type: "radio",
					selectedRowKeys: activeDetailsOption?.selectDailyReports?.map(
						(item) => item?.reportDateTime
					),
					onChange: handleRowSelect,
					hideSelectAll: true
				}}
			/>
		</div>
	);
};

export default RouteCommonDailyReport;
