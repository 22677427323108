import React from "react";
import style from "./index.module.less";
import { FleetPrompt } from "@/common";
import { FleetIcon, FleetLoading } from "@/components";
import { MovementLaytimeProps } from "./type";
import { LaytimeCalculator, LaytimeTabs, LaytimeSubTotal } from "./components";
import useIndex from "./hooks/useIndex";
import { Button } from "@/_components";

const MovementLaytime: React.FC<MovementLaytimeProps> = ({
	hide,
	onClose,
	onConfirm,
	voyageId
}) => {
	const {
		loading,
		activeIndex,
		activePort,
		laytimeDataSource,
		rangeSource,
		exceptDate,
		portDataSource,
		norRangeSource,
		handleNorRangeSourceChange,
		handleExpectDateCommit,
		handleExpectDateChange,
		handleExpectDateCrud,
		handleIndexChange,
		handleSubtotalChange,
		handleRangeChange: handleRangeDateChange,
		saveMovementLaytimeGroup,
		handleLaytimeChange,
		downloadLaytimeAttach,
		laytimeRate
	} = useIndex(voyageId, hide, onClose, onConfirm);

	return (
		<FleetPrompt hide={hide} mode="fixed">
			<div className={style["movement-layTime"]}>
				<FleetLoading loading={loading} />
				<div className="layTime-header">
					<span className="fleet-title">Laytime Subtotal</span>
					<FleetIcon type="FleetCloseIcon" outerLayerClassname="header-icon" onClick={onClose} />
				</div>
				<div className="layTime-container">
					<LaytimeTabs
						activeIndex={activeIndex}
						items={portDataSource}
						onChange={handleIndexChange}
					/>
					<div className="layTime-rightArea">
						<div className="rightArea">
							<LaytimeSubTotal item={activePort} onChange={handleSubtotalChange} />
							<LaytimeCalculator
								activeIndex={activeIndex}
								onRangeDateChange={handleRangeDateChange}
								onChange={handleLaytimeChange}
								rangeDate={rangeSource}
								norDate={norRangeSource}
								onNorDateChange={handleNorRangeSourceChange}
								summary={laytimeRate}
								items={laytimeDataSource}
								exceptDate={exceptDate}
								onExpectDateCommit={handleExpectDateCommit}
								onExpectDateChange={handleExpectDateChange}
								onExpectDateCrud={handleExpectDateCrud}
							/>
						</div>
					</div>
				</div>
				<div className="layTime-footer">
					<Button
						size="small"
						type="primary"
						prefixIcon="fleet-download"
						onClick={downloadLaytimeAttach}
					>
						Preview & Download
					</Button>
					<Button size="small" onClick={saveMovementLaytimeGroup}>
						Confirm
					</Button>
				</div>
			</div>
		</FleetPrompt>
	);
};

export default MovementLaytime;
