import { type ApiParameter } from "..";
import { appVersion } from "@/api/constant";

import request from "@/service";

const enum ApiMap {
	deleteRoutePlanParameter,
	getRoutePlanWayPointParemeter,
	getRoutePlanLinesParameter,
	downloadRoutePlanParameter,
	uploadRoutePlanParameter
}

type ApiUrl = keyof typeof ApiMap;

const routePlanApi: ApiParameter<ApiUrl> = {
	deleteRoutePlanParameter: {
		method: "delete",
		url: appVersion + "/moni/route-plan"
	},
	getRoutePlanWayPointParemeter: {
		method: "get",
		url: appVersion + "/moni/route-plan/detail"
	},
	getRoutePlanLinesParameter: {
		method: "get",
		url: appVersion + "/moni/route-plan/lines"
	},
	downloadRoutePlanParameter: {
		method: "get",
		url: appVersion + "/moni/route-plan/export"
	},
	uploadRoutePlanParameter: {
		method: "post",
		url: appVersion + "/moni/route-plan/custome"
	}
};

export type deleteRoutePlanParams = {
	id: string;
};

export type getRoutePlanWayPointParams = {
	id: number;
};

export type getRoutePlanLinesParams = {
	uuid: string;
};

export type downloadRoutePlanParams = {
	type: "A" | "B";
	routePlanUuid: string;
};

export type uploadRoutePlanParams = {
	planType: string;
	etd: number;
	voyageNo: string;
	legType: string;
	file: FormData;
};

export const deleteRoutePlanApi = (params: deleteRoutePlanParams) => {
	return request.request({
		...routePlanApi.deleteRoutePlanParameter,
		url: `${routePlanApi.deleteRoutePlanParameter.url}/${params?.id}`
	});
};

export const getRoutePlanWayPointApi = (params: getRoutePlanWayPointParams) => {
	return request.request({
		...routePlanApi.getRoutePlanWayPointParemeter,
		url: `${routePlanApi.getRoutePlanWayPointParemeter.url}/${params?.id}`
	});
};

export const getRoutePlanLinesApi = (params: getRoutePlanLinesParams) => {
	return request.request({
		...routePlanApi.getRoutePlanLinesParameter,
		params
	});
};

export const downloadRoutePlanApi = (params: downloadRoutePlanParams) => {
	return request.request({
		...routePlanApi.downloadRoutePlanParameter,
		url: `${routePlanApi?.downloadRoutePlanParameter?.url}/${params?.type}`,
		params: {
			routePlanUuid: params.routePlanUuid
		},
		responseType: "blob"
	});
};

export const uploadRoutePlanApi = (params: uploadRoutePlanParams) => {
	const { file, ...rest } = params;
	return request.request({
		...routePlanApi.uploadRoutePlanParameter,
		params: rest,
		data: file
	});
};
