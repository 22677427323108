import React, { FC } from "react";
import { RouteLegItemProps } from "./type";
import style from "./index.module.less";
import classnames from "classnames";
import { Icon, Popover } from "@/_components";

const RouteLegItem: FC<RouteLegItemProps> = ({ item, activeItem, onSelect }) => {
	return (
		<div
			id={item?.voyageNo}
			className={classnames([
				style["route-legItem"],
				{
					[style["route-legItem--active"]]:
						activeItem?.voyageNo === item.voyageNo &&
						activeItem?.vesselStatus === item?.vesselStatus
				}
			])}
			onClick={() => onSelect?.(item)}
		>
			<div className="legItem-header">
				<span className="legItem-vessel">{item?.vesselName}</span>
				<span className={classnames("legItem-status", item?.status)}>{item.status}</span>
			</div>
			<div className="legItem-container">
				<div className="legItem-info">
					<Icon type="fleet-user" className="legItem-avatar" size={12} />
					<Popover content={`PIC: ${item.charaByName}`} trigger="hover">
						<div className="legItem-pic">
							<span>PIC: </span>
							<span>{item.charaByName}</span>
						</div>
					</Popover>
				</div>
				<div className="legItem-info legItem-voyage">
					<Popover content={`Voy. NO. : ${item.voyageNo}`} trigger="hover">
						<div className="legItem-pic">
							<span>Voy. NO.: </span>
							<span>{item.voyageNo}</span>
						</div>
					</Popover>
					<span className={classnames("legItem-vesselStatus", item?.vesselStatus?.toLowerCase())}>
						{item?.vesselStatus?.toLowerCase()}
					</span>
				</div>
			</div>
		</div>
	);
};

export default RouteLegItem;
