import { useState } from "react";

const useArrival = () => {
  const [arrivalHide, setArrivalHide] = useState(true);
  const [aisChartHide, setAisChartHide] = useState(true);

  const [dataSource, setDataSource] = useState<{
    aisType: "ais" | "vm" | "area" | "port";
    arrivalType: "ais" | "vm" | "area" | "port";
  }>({
    aisType: null,
    arrivalType: null
  });

  // 1. 第一次点击 aisData，2. 点击相同的 aisData 3. 点击不同的 aisData

  const handleOpen = (type: "aisData" | "arrival", vesselType: "ais" | "vm" | "area" | "port") => {
    switch (true) {
      case type === "aisData" && !dataSource?.aisType:
        setDataSource((prev) => ({ ...prev, aisType: vesselType }));
        setAisChartHide(false);
        break;
      case type === "aisData" && vesselType === dataSource.aisType:
        setDataSource((prev) => ({ ...prev, aisType: null }));
        setAisChartHide(true);
        break;
      case type === "aisData" && vesselType !== dataSource.aisType:
        setDataSource((prev) => ({ ...prev, aisType: vesselType }));
        break;
      case type === "arrival" && !dataSource?.arrivalType:
        setDataSource((prev) => ({ ...prev, arrivalType: vesselType }));
        setArrivalHide(false);
        break;
      case type === "arrival" && vesselType === dataSource.arrivalType:
        setDataSource((prev) => ({ ...prev, arrivalType: null }));
        setArrivalHide(true);
        break;
      case type === "arrival" && vesselType !== dataSource.arrivalType:
        setDataSource((prev) => ({ ...prev, arrivalType: vesselType }));
        break;
      default:
        break;
    }
  };

  const handlClose = (type: "aisData" | "arrival") => {
    const params = { ...dataSource };
    switch (true) {
      case type === "aisData":
        params.aisType = null;
        setAisChartHide(true);
      case type === "arrival":
        params.arrivalType = null;
        setArrivalHide(true);
        break;
      default:
        break;
    }
    setDataSource(params);
  };

  return {
    arrivalHide,
    aisChartHide,
    dataSource,
    handleOpen,
    handlClose
  };
};

export default useArrival;
