import { type ApiParameter } from "@/api";
import { appVersion } from "@/api/constant";
import request from "@/service";
import {
	getCompanyDetailParams,
	getCompanyGroupParmas,
	deleteComponyParams,
	updateCompanyPayload
} from "./type";

const enum ApiMap {
	getCompanyDetailParameter,
	getCompanyGroupParameter,
	deleteComponyParameter,
	updateCompanyParameter
}

type ApiUrl = keyof typeof ApiMap;

const companyApi: ApiParameter<ApiUrl> = {
	getCompanyGroupParameter: {
		method: "get",
		url: appVersion + "/company/list"
	},
	getCompanyDetailParameter: {
		method: "get",
		url: appVersion + "/company"
	},
	deleteComponyParameter: {
		method: "delete",
		url: appVersion + "/company/batch"
	},
	updateCompanyParameter: {
		method: "post",
		url: appVersion + "/company"
	}
};

export const getCompanyGroupApi = (params: getCompanyGroupParmas) => {
	return request.request({
		...companyApi.getCompanyGroupParameter,
		params
	});
};

export const getCompanyDetailsApi = (params: getCompanyDetailParams) => {
	return request.request({
		...companyApi.getCompanyDetailParameter,
		url: `${companyApi.getCompanyDetailParameter.url}/${params?.id}`
	});
};

export const deleteCompanyApi = (params: deleteComponyParams) => {
	return request.request({
		...companyApi.deleteComponyParameter,
		data: params
	});
};

export const updateCompanyApi = (payload: updateCompanyPayload) => {
	return request.request({
		...companyApi.updateCompanyParameter,
		method: payload.method,
		data: payload.params
	});
};
