import { ArrivalVesselSubTypeMap } from "@/common/Select";
import { FleetOptionType } from "@/types/advancedType";
import {
	AreaVesselFilterHeadingMap,
	AreaVesselFilterItemType,
	AreaVesselFilterPurposeMap
} from "./type";
import { CommonInitItemEvent } from "@/types/event";

export const vesselSubTypeOptions: FleetOptionType<
	keyof typeof ArrivalVesselSubTypeMap,
	ArrivalVesselSubTypeMap
>[] = [
	{ label: "VLOC", value: ArrivalVesselSubTypeMap.VLOC },
	{ label: "Capesize", value: ArrivalVesselSubTypeMap.Capesize },
	{ label: "Post-Panamax", value: ArrivalVesselSubTypeMap["Post-Panamax"] },
	{ label: "Panamax", value: ArrivalVesselSubTypeMap.Panamax },
	{ label: "Supramax", value: ArrivalVesselSubTypeMap.Supramax },
	{ label: "Handymax", value: ArrivalVesselSubTypeMap.Handymax },
	{ label: "Handysize", value: ArrivalVesselSubTypeMap.Handysize },
	{ label: "Small Bulk", value: ArrivalVesselSubTypeMap["Small Bulk"] }
];

export const headingTypeOption: FleetOptionType<
	keyof typeof AreaVesselFilterHeadingMap,
	AreaVesselFilterHeadingMap
>[] = [
	{ label: "Southeast", value: AreaVesselFilterHeadingMap.Southeast },
	{ label: "Southwest", value: AreaVesselFilterHeadingMap.Southwest },
	{ label: "Northeast", value: AreaVesselFilterHeadingMap.Northeast },
	{ label: "Northwest", value: AreaVesselFilterHeadingMap.Northwest }
];

export const purposeOption: FleetOptionType<
	keyof typeof AreaVesselFilterPurposeMap,
	AreaVesselFilterPurposeMap
>[] = [
	{ label: "Start", value: AreaVesselFilterPurposeMap.Start },
	{ label: "Load", value: AreaVesselFilterPurposeMap.Load },
	{ label: "Bunker", value: AreaVesselFilterPurposeMap.Bunker },
	{ label: "Discharge", value: AreaVesselFilterPurposeMap.Discharge },
	{ label: "Repair", value: AreaVesselFilterPurposeMap.Repair },
	{ label: "Other", value: AreaVesselFilterPurposeMap.Other }
];

export const initialFilterItem: CommonInitItemEvent<AreaVesselFilterItemType> = (item) => {
	return {
		ageMax: item?.ageMax ?? "",
		aisSpeedMin: item?.aisSpeedMin ?? "",
		aisSpeedMax: item?.aisSpeedMax ?? "",
		dwtMin: item?.dwtMin ?? "",
		dwtMax: item?.dwtMax ?? "",
		vesselTypeSub: item?.vesselTypeSub ?? [],
		headingList: item?.headingList ?? [],
		lastPurpose: item?.lastPurpose ?? [],
		lastPortCountry: item?.lastPortCountry ?? [],
		desPortList: item?.desPortList ?? [],
		desPortCountry: item?.desPortCountry ?? [],
		areaList: item?.areaList ?? [],
		startAreaList: item?.startAreaList ?? []
	};
};
