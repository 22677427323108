import mapboxgl from "mapbox-gl";
import { MutableRefObject } from "react";
import {
	vesselTrackArrowLayers,
	vesselTrackInfoLayers,
	vesselTrackLayers,
	vesselTrackPointerLayers,
	vesselTrackPointerSource,
	vesselTrackSource
} from "../source";
import { addSourceToMap } from "../utils";

const useVesselTrack = (mapboxGl: MutableRefObject<mapboxgl.Map>) => {
	const loadTrackSource = () => {
		for (let [vesselTag, sourceName] of Object.entries(vesselTrackSource)) {
			addSourceToMap(mapboxGl.current, {
				sourceName: sourceName,
				source: {
					type: "geojson",
					data: {
						type: "FeatureCollection",
						features: []
					}
				}
			});
			addSourceToMap(mapboxGl.current, {
				sourceName: vesselTrackPointerSource[vesselTag],
				source: {
					type: "geojson",
					data: {
						type: "FeatureCollection",
						features: []
					}
				}
			});
		}
	};
	const loadTrackLayers = () => {
		for (let [vesselTag, layerName] of Object.entries(vesselTrackLayers)) {
			mapboxGl.current.addLayer({
				id: layerName,
				type: "line",
				source: vesselTrackSource[vesselTag],
				layout: {
					"line-join": "round",
					"line-cap": "round"
				},
				paint: {
					"line-color": "#6077A4",
					"line-width": 2
				}
			});
			mapboxGl.current.addLayer({
				id: vesselTrackPointerLayers[vesselTag],
				source: vesselTrackPointerSource[vesselTag],
				type: "symbol",
				layout: {
					// 'symbol-placement': 'line',
					"symbol-spacing": 100, // 图标间隔，默认为250
					// 'icon-image': 'vessel-point-icon', //
					"icon-image": "vesselPointerIcon",
					"icon-size": 1
					// 'icon-rotate': -90
				}
			});
			mapboxGl.current.addLayer({
				id: vesselTrackArrowLayers[vesselTag],
				type: "symbol",
				source: vesselTrackSource[vesselTag],
				layout: {
					"symbol-placement": "line",
					"symbol-spacing": 50, // 图标间隔，默认为250
					"icon-image": "vesselTrackArrowIcon", //箭头图标
					"icon-size": 1,
					"icon-rotate": -90
				}
			});

			mapboxGl.current.addLayer({
				id: vesselTrackInfoLayers[vesselTag],
				type: "symbol",
				source: vesselTrackPointerSource[vesselTag],
				layout: {
					// 'symbol-placement': 'line',
					"symbol-spacing": 100, // 图标间隔，默认为250
					"icon-image": "vesselInfoIcon", //箭头图标
					"icon-size": 1,
					"text-field": ["concat", ["get", "time"], "LT ", ["get", "speed"], " knots"],
					"icon-offset": [100, 0],
					"text-offset": [10, 0],
					"text-max-width": 1000, // 设置一个足够大的宽度，确保文本不会换行
					// 文字大小
					"text-size": 10
				}
			});
		}
	};
	return {
		loadTrackSource,
		loadTrackLayers
	};
};

export default useVesselTrack;
