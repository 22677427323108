import { useTranslation } from "react-i18next";
import useReminder from "@/hook/useReminder";
import { useNavigate } from "react-router-dom";
import { useAppDispatch } from "@/hook";
import { useMemo, useRef, useState } from "react";
import {
  VoyageManageContactOptionChangeEvent,
  VoyageManageContactOptionType
} from "@/pages/voyagemanage/business/preOp&movement/type";
import { initialContactOption } from "@/pages/voyagemanage/business/preOp&movement/source";
import { CommonContactItemType } from "@/common/Select";
import { addContactAction } from "@/action/dataBase/contact";
import {
  useBasicInfo,
  useBunkerClause,
  useHeadFixture,
  usePerformance
} from "@/pages/voyagemanage/business/preOp&movement/components";
import useOtherIndex from "./useOtherIndex";
import { HandoverTctcFormItemType, VoyageManageTctcHandoverFormIdKeys } from "../type";
import { tctcHandoverIdMap } from "../source";
import { getPreopTctcHandoverFormAction } from "@/action/voyageManage/preOperation";

const useIndex = (voyageId: string) => {
	const dispatch = useAppDispatch();
	const navigate = useNavigate();
	const { reminder } = useReminder();
	const { t } = useTranslation();

	const [loading, setLoading] = useState(false);

	const containerRef = useRef<HTMLDivElement>(null);

	const [activeIndex, setActiveIndex] = useState<VoyageManageTctcHandoverFormIdKeys>(
		"handoverForm-tctc--basicInfo"
	);

	const currentIndex = useMemo(() => {
		return tctcHandoverIdMap?.findIndex((item) => item?.value === activeIndex);
	}, [activeIndex]);

	const [contactOption, setContactOption] =
		useState<VoyageManageContactOptionType>(initialContactOption());

	const handleContactChange: VoyageManageContactOptionChangeEvent = (item) => {
		setContactOption((prev) => ({ ...prev, ...item }));
	};

	const {
		dataSource: otherItem,
		change: handleOtherChange,
		commit: handleOtherCommit,
		init: handleOtherInit,
		check: handleOtherCheck
	} = useOtherIndex();

	const {
		dataSource: basicInfo,
		change: handleBasicInfoChange,
		commit: handleBasicInfoCommit,
		init: handleBasicInfoInit,
		check: handleBasicInfoCheck
	} = useBasicInfo();

	const {
		dataSource: rentHeadFixture,
		change: handleRentHeadFixtureChange,
		commit: handleRentHeadFixtureCommit,
		init: handleRentHeadFixtureInit,
		check: handleRentHeadFixtureCheck
	} = useHeadFixture();

	const {
		dataSourse: rentBunkerClause,
		change: handleRentBunkerClauseChange,
		commit: handleRentBunkerClauseCommit,
		init: handleRentBunkerClauseInit
	} = useBunkerClause();

	const {
		dataSourse: rentPerformance,
		change: handleRentPerformanceChange,
		commit: handleRentPerformanceCommit,
		init: handleRentPerformanceInit
	} = usePerformance();

	const {
		dataSource: reletHeadFixture,
		change: handleReletHeadFixtureChange,
		commit: handleReletHeadFixtureCommit,
		init: handleReletHeadFixtureInit,
		check: handleReletHeadFixtureCheck
	} = useHeadFixture();

	const {
		dataSourse: reletBunkerClause,
		change: handleReletBunkerClauseChange,
		commit: handleReletBunkerClauseCommit,
		init: handleReletBunkerClauseInit
	} = useBunkerClause();

	const {
		dataSourse: reletPerformance,
		change: handleReletPerformanceChange,
		commit: handleReletPerformanceCommit,
		init: handleReletPerformanceInit
	} = usePerformance();

	const check = () => {
		for (let callback of [
			handleBasicInfoCheck,
			handleRentHeadFixtureCheck,
			handleReletHeadFixtureCheck,
			handleOtherCheck
		]) {
			const { checkKey, checked } = callback();
			if (!checked) {
				reminder(
					"error",
					`Field ${t(`voyageManagement.preOperation.${checkKey}`, {
						defaultValue: t(`common.${checkKey}`)
					})} is required`
				);
				return false;
			}
		}
		return true;
	};

	const init = (item: HandoverTctcFormItemType) => {
		const { relet, rent, ...rest } = item;
		const {
			performanceList: reletPerformanceList,
			performanceClause: reletPerformanceClause,
			goodWeatherDef: reletGoodWeatherDef,
			...restRelet
		} = relet;
		const {
			performanceList: rentPerformanceList,
			performanceClause: rentPerformanceClause,
			goodWeatherDef: rentGoodWeatherDef,
			...restRent
		} = rent;
		handleBasicInfoInit(rest);
		handleOtherInit(rest);
		handleRentHeadFixtureInit(restRent);
		handleRentBunkerClauseInit(restRent);
		handleRentPerformanceInit({
			...rentPerformanceList,
			performanceClause: rentPerformanceClause,
			goodWeatherDef: rentGoodWeatherDef
		});
		handleReletHeadFixtureInit(restRelet);
		handleReletBunkerClauseInit(restRelet);
		handleReletPerformanceInit({
			...reletPerformanceList,
			performanceClause: reletPerformanceClause,
			goodWeatherDef: reletGoodWeatherDef
		});
	};

	const handleContactAddFront = () => {
		handleContactChange({ loading: true });
	};

	const handleContactAddSuccess = (response) => {
		handleContactChange({ loading: false, hide: true, type: null });
		reminder("success", response?.msg);
	};

	const handleContactAddFailed = (error) => {
		handleContactChange({ loading: true });
		reminder("error", error?.msg ? error?.msg + ": " + error?.data : error?.data);
	};

	const getPreopHandoverFormFront = () => {
		setLoading(true);
	};

	const getPreopHandoverFormSuccess = (response) => {
		setLoading(false);
		init(response?.data);
	};

	const getPreopHandoverFormFailed = (error) => {
		setLoading(false);
		reminder("error", error?.msg ? error?.msg + ": " + error?.data : error?.data);
	};

	const handleContactAdd = (item: CommonContactItemType) => {
		addContactAction(
			item,
			handleContactAddFront,
			handleContactAddSuccess,
			handleContactAddFailed,
			dispatch,
			navigate
		);
	};

	const getPreopHandoverForm = () => {
		if (!voyageId) {
			init({
				relet: {},
				rent: {}
			});
			return;
		}
		getPreopTctcHandoverFormAction(
			{ voyageId },
			getPreopHandoverFormFront,
			getPreopHandoverFormSuccess,
			getPreopHandoverFormFailed,
			dispatch,
			navigate
		);
	};

	const handleCombine = (): HandoverTctcFormItemType => {
		console.log("ssss", {
			basicInfo,
			otherItem
		});
		const { performanceClause, goodWeatherDef, ...restRentPerformance } = rentPerformance;
		const {
			performanceClause: reletPerformanceClause,
			goodWeatherDef: reletGoodWeatherDef,
			...restReletPerformance
		} = reletPerformance;
		return {
			...basicInfo,
			...otherItem,
			rent: {
				...rentHeadFixture,
				...rentBunkerClause,
				performanceList: restRentPerformance,
				goodWeatherDef: goodWeatherDef,
				performanceClause: performanceClause
			},
			relet: {
				...reletHeadFixture,
				...reletBunkerClause,
				performanceList: restReletPerformance,
				performanceClause: reletPerformanceClause,
				goodWeatherDef: reletGoodWeatherDef
			}
		};
	};

	const handleScrollIntoView = (
		event: React.MouseEvent<HTMLLIElement, MouseEvent>,
		id: VoyageManageTctcHandoverFormIdKeys,
		index: number
	) => {
		const currentElement = containerRef.current?.querySelector(`#${id}`);
		currentElement?.scrollIntoView({
			behavior: "smooth",
			block: "start"
		});
		setActiveIndex(id);
	};

	return {
		loading,
		basicInfo,
		handleBasicInfoChange,
		handleBasicInfoCommit,
		rentHeadFixture,
		handleRentHeadFixtureChange,
		handleRentHeadFixtureCommit,
		rentBunkerClause,
		handleRentBunkerClauseChange,
		handleRentBunkerClauseCommit,
		rentPerformance,
		handleRentPerformanceChange,
		handleRentPerformanceCommit,
		reletHeadFixture,
		handleReletHeadFixtureChange,
		handleReletHeadFixtureCommit,
		reletBunkerClause,
		handleReletBunkerClauseChange,
		handleReletBunkerClauseCommit,
		reletPerformance,
		handleReletPerformanceChange,
		handleReletPerformanceCommit,
		otherItem,
		handleOtherChange,
		handleOtherCommit,
		activeIndex,
		containerRef,
		currentIndex,
		handleScrollIntoView,
		// handleScroll,
		handleCombine,
		handleReset: getPreopHandoverForm,
		handleCheck: check,
		contactOption,
		handleContactChange,
		handleContactAdd
	};
};

export default useIndex;
