import React, { FC } from "react";
import style from "./index.module.less";
import { RouteCommonGroupSelectProps } from "./type";
import useIndex from "./hooks/useIndex";
import { Button, Popover } from "@/_components";
import { FleetSelect } from "@/components";
import { CommonSelectList } from "@/common/Select";
import { RouteCommonGroupAdd } from "@/pages/routeCommon/common";

const RouteCommonGroupSelect: FC<RouteCommonGroupSelectProps> = ({
	onConfirm,
	open,
	onOpenChange,
	children
}) => {
	const {
		activeItem,
		loading,
		open: selectOpen,
		list,
		searchValue,
		selectValue,
		page,
		handleSearch,
		focusPopup,
		closePopup,
		loadMore,
		handleItemSelect,
		tagRender,
		handleConfirm,
		handleGroupAdd
	} = useIndex(onConfirm);
	return (
		<Popover
			placement="right-start"
			trigger="click"
			open={open}
			className={style["routeCommon-groupSelect--popover"]}
			onOpenChange={onOpenChange}
			render={(setIsOpen) => {
				return (
					<div className={style["routeCommon-groupSelect"]}>
						<span className="groupSelect-header">Select a Group</span>
						<div className="groupSelect-container">
							<FleetSelect
								value={selectValue}
								showSearch
								popupMatchSelectWidth={false}
								onDropdownVisibleChange={closePopup}
								open={selectOpen}
								onSearch={handleSearch}
								onFocus={() => focusPopup()}
								className="groupSelect-select"
								tagRender={tagRender}
								dropdownRender={() => (
									<CommonSelectList
										showKey="groupName"
										loadMore={loadMore}
										list={list}
										loading={loading}
										onSelect={handleItemSelect}
									/>
								)}
							/>
							<RouteCommonGroupAdd mode="newly" onConfirm={handleGroupAdd}>
								<Button type="secondary" prefixIcon="fleet-plus">
									Add Groups
								</Button>
							</RouteCommonGroupAdd>
						</div>
						<div className="groupSelect-footer">
							<Button
								size="minuscule"
								type="secondary"
								onClick={() => {
									onOpenChange?.(false);
								}}
							>
								Cancel
							</Button>
							<Button size="minuscule" onClick={handleConfirm} disabled={!activeItem?.id}>
								Confirm
							</Button>
						</div>
					</div>
				);
			}}
		>
			{children}
		</Popover>
	);
};

export default RouteCommonGroupSelect;
