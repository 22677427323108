export {
  default as InvFreightCargoDetails
} from "./index"

export {
  default as useCargoDetailsIndex
} from "./hooks/useIndex"

export {
  InvFreightCargoDetailsProps
} from "./type"