import { FleetOptionType } from "@/types/advancedType";
import { HandoverFormTctcOtherItemType, VoyageManageTctcHandoverFormIdKeys } from "./type";
import { CommonInitItemEvent } from "@/types/event";


export const tctcHandoverIdMap: FleetOptionType<
  "Basic Information" |
  "Rent" |
  "Bunker & Vessel Performance" |
  "Relet",
  VoyageManageTctcHandoverFormIdKeys
>[] = [
    { label: "Basic Information", value: "handoverForm-tctc--basicInfo" },
    { label: "Rent", value: "handoverForm-tctc-rent" },
    { label: "Bunker & Vessel Performance", value: "handoverForm-tctc-rent--bunkerAndperformance" },
    { label: "Relet", value: "handoverForm-tctc-relet" },
    { label: "Bunker & Vessel Performance", value: "handoverForm-tctc-relet--bunkerAndperformance" }
  ]

export const initialHandoverFormOtherItem: CommonInitItemEvent<HandoverFormTctcOtherItemType> = (item) => {
  return {
    id: item?.id ?? null,
    estVcResultId: item?.estVcResultId ?? null,
    status: item?.status ?? "",
    presentVesselMovement: item?.presentVesselMovement ?? "",
    presentPortAgentInfo: item?.presentPortAgentInfo ?? "",
    shipContactDetail: item?.shipContactDetail ?? "",
    voyageNo: item?.voyageNo ?? ""
  }
}