import React from "react";
import style from "./index.module.less";
import { CommonTemplatePopupProps } from "./type";
import { FleetLoading } from "@/components";
import classnames from "classnames";

const TemplatePopup: React.FC<CommonTemplatePopupProps> = ({
	items,
	loading,
	activeItem,
	onDelete,
	onLoadMore,
	onSelect
}) => {
	return (
		<div className={style["common-template-popup"]}>
			<FleetLoading loading={loading} />
			<div className="template-popup fleet-nonScrolBar">
				<ul className="template-popup-scrollArea">
					{items?.map((item, index) => {
						return (
							<li
								key={index}
								onClick={() => onSelect?.(item)}
								className={classnames({
									active: activeItem?.id === item?.id
								})}
							>
								<span className="label">{item?.templateName}</span>
								{!item?.sys && (
									<span
										className="font_family fleet-close"
										onClick={(e) => {
											e?.stopPropagation();
											onDelete?.(item);
										}}
									></span>
								)}
							</li>
						);
					})}
				</ul>
			</div>
			<span
				className="template-popup-loadMore"
				onClick={(e) => {
					e?.stopPropagation();
					onLoadMore?.();
				}}
			>
				Load More
			</span>
		</div>
	);
};

export default TemplatePopup;
