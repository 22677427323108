import { EstVesselParticularType } from "./type";

export const initialVesselParticular = (
	item?: EstVesselParticularType
): EstVesselParticularType => {
	return {
		id: item?.id ?? null,
		resultId: item?.resultId ?? null,
		vesselName: item?.vesselName ?? "",
		deliveryPortId: item?.deliveryPortId ?? null,
		deliveryPortCode: item?.deliveryPortCode ?? "",
		deliveryPortName: item?.deliveryPortName ?? "",
		ballastSpeed: item?.ballastSpeed ?? "",
		ladenSpeed: item?.ladenSpeed ?? "",
		seaFuelBallast: item?.seaFuelBallast ?? "",
		seaFuelLaden: item?.seaFuelLaden ?? "",
		seaFuelMdo: item?.seaFuelMdo ?? "",
		portFuelWork: item?.portFuelWork ?? "",
		portFuelIdle: item?.portFuelIdle ?? "",
		portIdleMdo: item?.portIdleMdo ?? "",
		portWorkMdo: item?.portIdleMdo ?? "",
		dailyHire: item?.dailyHire ?? "",
		commission: item?.commission ?? "",
		brokerage: item?.brokerage ?? "",
		bunkerPriceIfo: item?.bunkerPriceIfo ?? "",
		bunkerPriceMdo: item?.bunkerPriceMdo ?? "",
		otherExpenses: item?.otherExpenses ?? "",
		dwt: item?.dwt ?? null,
		bale: item?.bale ?? null,
		grain: item?.grain ?? null,
		mmsi: item?.mmsi ?? null,
		imo: item?.imo ?? null,
		ownerName: item?.ownerName ?? "",
		templateId: item?.templateId ?? null,
		templateName: item?.templateName ?? "",
		tonnageType: item?.tonnageType ?? "",
		tonnageValue: item?.tonnageValue ?? null,
		portFuelMdo: item?.portFuelMdo ?? ""
	};
};

export const checkKeys: (keyof EstVesselParticularType)[] = [
	"deliveryPortName",
	"deliveryPortCode",
	"seaFuelBallast",
	"seaFuelLaden",
	"seaFuelMdo",
	"portFuelWork",
	"portFuelIdle",
	"portWorkMdo",
	"portIdleMdo",
	"dailyHire",
	"bunkerPriceIfo",
	"bunkerPriceMdo"
];
