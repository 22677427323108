import React, { FC } from "react";
import classnames from "classnames";
import style from "./index.module.less";
import { InvoicesViewsCommonHeaderProps } from "./type";
import dayjs from "dayjs";

const InvoicesViewsCommonHeader: FC<InvoicesViewsCommonHeaderProps> = ({
  title,
  type,
  item
}) => {
  return <div className={classnames(style["invoices-views-commonHeader"], {
    [style["invoices-views-commonHeader--desc"]]: type === "desc"
  })}>
    <div className="commonHeader-header">
      <ul className="header-container">
        <li className="header-title">{title}</li>
        {item?.status === 'Settled' && <li className="header-tags">{item?.status}</li>}
      </ul>
      {type === 'base' && item?.updateTime && <span className="header-updateDate">Update time: {dayjs(item?.updateTime)?.format("MM/DD/YY HH:mm")}</span>}
    </div>
    <span className="commonHeader-container">{type === "base" ? `INV. No. ${item?.invNo}` : `Voyage No. ${item?.voyageNo}`}</span>
  </div>
}

export default InvoicesViewsCommonHeader