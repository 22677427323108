import React from "react";
import style from "./index.module.less";
import {
  FleetButton,
  FleetRangePicker,
  FleetInput,
  FleetTooltip,
} from "@/components";
import { CommonRoleWrap, FleetCategory, FleetToast } from "@/common";
import { EsGroupOptions } from "@/types/estimation";
import { EstListFilterProps } from "./type";
import { Button, Popover } from "@/_components";

const Filter: React.FC<EstListFilterProps> = ({
  checkGroup,
  onReset,
  onChange,
  onOpenNewAdd,
  onOpenCompare,
  onSearch,
  timeRange,
  onTimeRangeChange,
  onDeleteItems,
  selectedRowKeys,
}) => {
  return (
    <div className={style["estimation-list-filter"]}>
      <div className="filter">
        <div className="filter-item">
          <span className="label">Search</span>
          <FleetInput
            value={undefined}
            onChange={onSearch}
            prefix="LayoutSearchIcon"
          />
        </div>
        <div className="filter-item">
          <span className="label">Cargo Laycan</span>
          <FleetRangePicker
            className="date-input"
            allowClear
            value={timeRange}
            onChange={onTimeRangeChange}
          />
        </div>
        <Popover
          buttonProps={{
            children: "Filter",
            size: "small",
          }}
          className="filter-btn"
          content={
            <div className={style["filter-content"]}>
              <FleetCategory
                onReset={onReset}
                options={EsGroupOptions}
                checkGroup={checkGroup}
                onChange={onChange}
                className={style["estimation-category"]}
              />
            </div>
          }
        ></Popover>
          <Button
            disabled={selectedRowKeys?.length < 2}
            className="filter-btn"
            onClick={onOpenCompare}
            size="small"
            prefixIcon="fleet-comparison"
          >
            Voy Comparison
          </Button>
      </div>
      <div className="filter-toolbar">
        <CommonRoleWrap roleStr="est:list:batchDelete">
          <Popover
            buttonProps={{
              children: "Delete",
              prefixIcon: "fleet-delete",
              disabled: selectedRowKeys.length === 0,
            }}
            disabled={selectedRowKeys.length === 0}
            render={(setIsOpen) => {
              return (
                <FleetToast
                  label="Are you sure delete this item?"
                  onCancel={() => {
                    setIsOpen(false);
                  }}
                  onConfirm={() => {
                    onDeleteItems?.("multiple");
                    setIsOpen(false);
                  }}
                />
              );
            }}
          ></Popover>
        </CommonRoleWrap>
        <CommonRoleWrap roleStr="est:list:add">
          <Button onClick={onOpenNewAdd} size="small" prefixIcon="fleet-add">
            New Estimation
          </Button>
        </CommonRoleWrap>
      </div>
    </div>
  );
};

export default Filter;
