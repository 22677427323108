import React, { FC, useEffect, useMemo } from "react";
import classnames from "classnames";
import style from "./index.module.less";
import { PortListCurrentProps } from "./type";
import { VesselItem } from "../index";
const PortListCurrent: FC<PortListCurrentProps> = ({ portCode, dataSource }) => {
	// const total = useMemo(() => {
	// 	if (dataSource?.length == 1 && dataSource[0].vesselTypeSub == -1) {
	// 		return dataSource[0]?.totalCount ?? 0;
	// 	} else return dataSource?.filter((item) => item.vesselTypeSub != -1)?.reduce((acc, cur) => acc + (cur?.totalCount || 0), 0);
	// }, [dataSource]);
	// const berth = useMemo(() => {
	// 	if (dataSource?.length == 1 && dataSource[0].vesselTypeSub == -1) {
	// 		return dataSource[0]?.berth ?? 0;
	// 	} else return dataSource?.filter((item) => item.vesselTypeSub != -1)?.reduce((acc, cur) => acc + (cur?.berth || 0), 0);
	// }, [dataSource]);
	// const mooring = useMemo(() => {
	// 	if (dataSource?.length == 1 && dataSource[0].vesselTypeSub == -1) {
	// 		return dataSource[0]?.mooring ?? 0;
	// 	} else return dataSource?.filter((item) => item.vesselTypeSub != -1)?.reduce((acc, cur) => acc + (cur?.mooring || 0), 0);
	// }, [dataSource]);
	return (
		<div className={classnames(style["port-list-current"])}>
			<div className="portListCurrent-list">
				{dataSource?.length == 1 && dataSource[0].vesselTypeSub == -1 ? (
					<></>
				) : (
					dataSource
						?.filter((item) => item.vesselTypeSub != -1)
						?.map((item) => {
							return <VesselItem item={item} />;
						})
				)}
			</div>
			{/* <VesselItem
				item={{ otherName: "Total", mooring: mooring, berth: berth, totalCount: total }}
			/> */}
		</div>
	);
};

export default PortListCurrent;
