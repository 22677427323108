import React, { useEffect, useMemo, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import debounce from "lodash/debounce";
import { CommonRoleWrap, FleetToast } from "@/common";
import { FleetTooltip } from "@/components";
import { useAppDispatch } from "@/hook";
import useReminder from "@/hook/useReminder";
import {
  DatabasePortItemType,
  columns as list_columns,
} from "@/pages/database/business/port/type";
import { getPortGroupAction, deletePortAction } from "@/action/dataBase/port";
import { DatabasePortFilterProps } from "@/featrue/database/port/filter/type";
import { Popover } from "@/_components";

const useGroup = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { reminder } = useReminder();
  const cacheSource = useRef<{ [index: number]: DatabasePortItemType[] }>({});
  const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([]);
  const record = useRef<DatabasePortItemType>(null);
  const [ids, setIds] = useState<string>("");
  const [search, setSearch] = useState("");
  const [loading, setLoading] = useState(false);
  const [pager, setPager] = useState<{
    total: number;
    pageNum: number;
    pageSize: number;
  }>({ total: 0, pageNum: 0, pageSize: 20 });

  const [filter, setFilter] = useState<
    Pick<DatabasePortFilterProps["item"], "country" | "portType">
  >({
    country: "",
    portType: "",
  });

  const [hideMap, setHide] = useState({
    add: true,
    edit: true,
  });

  const dataSource = useMemo(() => {
    return cacheSource.current[pager.pageNum];
  }, [pager.pageNum, pager.total]);

  const delPortMultiple = (type: "multiple" | "single", id?: string) => {
    deletePortAction(
      { id: type === "multiple" ? selectedRowKeys.join(",") : id },
      getPortGroupFront,
      delPortResultSuccess,
      getPortGroupError,
      dispatch,
      navigate
    );
  };

  const delPortResultSuccess = (response) => {
    reminder("success", response?.msg);
    setIds("");
    setSelectedRowKeys([]);
    getPortGroup();
  };

  const getPortGroupError = (error) => {
    reminder(
      "error",
      error?.msg ? error?.msg + ": " + error?.data : error?.data
    );
    setLoading(false);
  };

  const columns = useMemo(() => {
    const _columns = [...list_columns];
    _columns.push({
      dataIndex: "option",
      width: 85,
      title: "Action",
      key: "option",
      align: "center",
      fixed: "right",
      render: (value, record, index) => {
        return (
          <div className="td-operate">
            <CommonRoleWrap roleStr="port:list:delete">
              <Popover
                // iconProps={{
                //   type: "FleetEslistDeleteIcon",
                //   outerLayerClassname: "operate-icon"
                // }}
                render={(setIsOpen) => {
                  return (
                    <FleetToast
                      label="Are you sure delete this item?"
                      onCancel={() => {
                        setIsOpen(false);
                      }}
                      onConfirm={() => {
                        delPortMultiple("single", record?.id);
                        setIsOpen(false);
                      }}
                    />
                  );
                }}
              >
                <span
                  className="font_family fleet-delete"
                  style={{ color: "red" }}
                ></span>
              </Popover>
            </CommonRoleWrap>
          </div>
        );
      },
    });
    return _columns;
  }, []);

  const onHideChange = (key: "add" | "edit", value: boolean) => {
    setHide((prev) => ({ ...prev, [key]: value }));
  };

  const onSelectChange = (newSelectedRowKeys: React.Key[]) => {
    setSelectedRowKeys(newSelectedRowKeys);
    setIds(newSelectedRowKeys?.join(","));
  };

  const getPortGroupFront = () => {
    setLoading(true);
  };

  const getPortSuccess = (response) => {
    let total = 0;
    switch (true) {
      case !Array.isArray(response?.data):
        // setDataSource(response?.data?.items);
        cacheSource.current[1] = response?.data?.items;
        total = response?.data?.totalCount;
        break;
      default:
        // setDataSource([]);
        cacheSource.current = {};
        break;
    }
    setPager((prev) => ({ ...prev, total, pageNum: 1 }));
    setLoading(false);
  };

  const getPortError = (error) => {
    reminder(
      "error",
      error?.msg ? error?.msg + ": " + error?.data : error?.data
    );
    setLoading(false);
  };

  const getPortGroup = (searchVal?: string) => {
    cacheSource.current = {};
    getPortGroupAction(
      {
        ...filter,
        pageNum: 1,
        pageSize: pager.pageSize,
        keyword: searchVal ?? search,
      },
      getPortGroupFront,
      getPortSuccess,
      getPortError,
      dispatch,
      navigate
    );
  };

  const onReset = () => {
    setHide({
      edit: true,
      add: true,
    });
    setIds("");
    setSelectedRowKeys([]);
    getPortGroup();
  };

  const onPageChange = (page: number, size: number) => {
    if (dataSource?.length >= pager.total) return;
    // if (page * size <= dataSource?.length) return;
    if (cacheSource.current[page]) {
      setPager((prev) => ({ ...prev, pageNum: page }));
      return;
    }
    getPortGroupAction(
      {
        ...filter,
        pageNum: page,
        pageSize: pager.pageSize,
        keyword: search,
      },
      getPortGroupFront,
      (response) => {
        if (!Array.isArray(response?.data)) {
          cacheSource.current[page] = response?.data?.items;
        } else {
          cacheSource.current[page] = [];
        }
        setPager((prev) => ({ ...prev, pageNum: page }));
        setLoading(false);
      },
      getPortError,
      dispatch,
      navigate
    );
  };

  const onFilterChange: DatabasePortFilterProps["onChange"] = (key, value) => {
    setFilter((prev) => ({ ...prev, [key]: value }));
  };

  const onSearch = useMemo(() => {
    const loadOptions = (val: string) => {
      cacheSource.current = {};
      setSearch(val);
      getPortGroup(val);
    };
    return debounce(loadOptions, 500);
  }, []);

  const onRow = (item) => {
    return {
      onDoubleClick: () => {
        record.current = item;
        onHideChange("edit", false);
      },
    };
  };

  useEffect(() => {
    getPortGroup();
  }, [filter]);

  return {
    search,
    record,
    selectedRowKeys,
    pager,
    columns,
    dataSource,
    loading,
    hideMap,
    filter,
    onHideChange,
    navigate,
    onPageChange,
    onSelectChange,
    onSearch,
    onDeleteItems: delPortMultiple,
    onRow,
    onFilterChange,
    onReset,
  };
};

export default useGroup;
