import { MutableRefObject } from "react";
import { RouteCommonMapRefType } from "../type";
import { GeoJSONSource } from "mapbox-gl";

const routePlanSource = {
	"route-plan-line-source": "route-plan-line-layer"
};

const useRoutePlan = (mapboxGl: MutableRefObject<mapboxgl.Map>) => {
	const loadSource = () => {
		mapboxGl?.current?.addSource("route-plan-line-source", {
			type: "geojson",
			data: {
				type: "FeatureCollection",
				features: []
			}
		});
	};

	const loadLayers = () => {
		mapboxGl?.current?.addLayer({
			id: routePlanSource["route-plan-line-source"],
			source: "route-plan-line-source",
			type: "line",
			layout: {
				"line-join": "round",
				"line-cap": "round"
			},
			paint: {
				"line-color": ["get", "type"],
				// "line-color": ["case", ["==", ["get", "type"], "primary_route"], "#365691", "#6081cb99"],
				// "line-dasharray": ["case", ["==", ["get", "type"], "primary_route"], [10, 15], undefined],
				"line-width": 2
			}
		});
	};

	const loadRoutePlan: RouteCommonMapRefType["onRoutePlanLoad"] = (items) => {
		const features: GeoJSON.Feature<
			GeoJSON.MultiLineString,
			{ type?: "primary_route" | "other" }
		>[] = [];
		for (let line of items?.values()) {
			features?.push(line);
		}
		console.log("features", features, items);
		const currentSource = mapboxGl?.current?.getSource("route-plan-line-source") as GeoJSONSource;
		currentSource?.setData({
			type: "FeatureCollection",
			features: features
		});
	};

	return {
		loadSource,
		loadLayers,
		loadRoutePlan
	};
};

export default useRoutePlan;
