import React from "react";
import classnames from "classnames";
import style from "./index.module.less";
import { HfBunkerClauseFormProps } from "./type";
import { FleetInput, FleetSelect, FleetTextarea } from "@/components";
import { HeadFixtueLabelMap } from "@/types/voyageManage/headFixtureList";

const HfBunkerClause: React.FC<HfBunkerClauseFormProps> = ({
  item,
  onChange,
  onCommit
}) => {
  console.log("borprice",item?.borPrice)
  return <div className={classnames(style["headFixture-bunkerClause"], "headFixture-form")}>
    <span className="vm-common-title">Bunker Clause</span>
    <div className="bunkerClause-bod">
      <div className="bob-common bod-header">
        <span className="bob-item"></span>
        <span className="bob-item">{HeadFixtueLabelMap["hsFoQuantity"]}</span>
        <span className="bob-item">{HeadFixtueLabelMap["lsFoQuantity"]}</span>
        <span className="bob-item">{HeadFixtueLabelMap["mdoQuantity"]}</span>
        <span className="bob-item">{HeadFixtueLabelMap["mgoQuantity"]}</span>
        <span className="bob-item">{HeadFixtueLabelMap["borAbout"]}</span>
        <span className="bob-item">{HeadFixtueLabelMap["borPrice"]}</span>
      </div>
      <div className="bob-common bod-content">
        <span className="bob-item">{HeadFixtueLabelMap["bodQuantity"]}</span>
        <div className="bob-item">
          <FleetInput
            value={item?.hsFoQuantity}
            onChange={(val: number) => {
              onChange?.("hsFoQuantity", val)
            }}
          />
        </div>
        <div className="bob-item">
          <FleetInput
            value={item?.lsFoQuantity}
            onChange={(val: number) => {
              onChange?.("lsFoQuantity", val)
            }}
          />
        </div>
        <div className="bob-item">
          <FleetInput
            value={item?.mdoQuantity}
            onChange={(val: number) => {
              onChange?.("mdoQuantity", val)
            }}
          />
        </div>
        <div className="bob-item">
          <FleetInput
            value={item?.mgoQuantity}
            onChange={(val: number) => {
              onChange?.("mgoQuantity", val)
            }}
          />
        </div>
        <div className="bob-item">
          <FleetInput
            value={item?.borAbout}
            onChange={(val: number) => {
              onChange?.("borAbout", val)
            }}
          />
        </div>
        <span className="bob-item"></span>
      </div>
      <div className="bob-common bod-content">
        <span className="bob-item">{HeadFixtueLabelMap["bodPrice"]}</span>
        <div className="bob-item">
          <FleetInput
            value={item?.hsFoPrice}
            onChange={(val: number) => {
              onChange?.("hsFoPrice", val)
            }}
          />
        </div>
        <div className="bob-item">
          <FleetInput
            value={item?.lsFoPrice}
            onChange={(val: number) => {
              onChange?.("lsFoPrice", val)
            }}
          />
        </div>
        <div className="bob-item">
          <FleetInput
            value={item?.mdoPrice}
            onChange={(val: number) => {
              onChange?.("mdoPrice", val)
            }}
          />
        </div>
        <div className="bob-item">
          <FleetInput
            value={item?.mgoPrice}
            onChange={(val: number) => {
              onChange?.("mgoPrice", val)
            }}
          />
        </div>
        <span className="bob-item">

        </span>
        <div className="bob-item">
          <FleetSelect 
            value={[item?.borPrice]}
            options={[{
              label:"Same",
              value:"same"
            },{
              label:"Different",
              value:"different"
            }]}
            defaultValue="same"
            placeholder="Same/Different"
            onChange={(val:string) => {
              onChange?.("borPrice", val)
            }}
          />
          {/* <FleetInput
            value={item?.borPrice}
            onChange={(val: string) => {
              onChange?.("borPrice", val)
            }}
          /> */}
        </div>
      </div>
    </div>
    <FleetTextarea
      className="bunkerClause-textarea"
      value={item?.bunkerClause}
      onChange={(val:string) => {
        onChange?.("bunkerClause",val)
      }}
      onBlur={(e) => {
        let val = e.target.value;
        let bunkerForm: Partial<HfBunkerClauseFormProps["item"]> = {};
        let moReg = /\w+(MGO|MDO) AB(T|OUT) (\d+) MT(.*)USD (\d+) (PMT|MT)/gi;
        let foReg = /\w+(FO) AB(T|OUT) (\d+) MT(.*)USD (\d+) (PMT|MT)/gi;

        let foStrList = val.match(foReg) ?? [];
        let moStrList = val.match(moReg) ?? [];
        
        let quantityReg = /AB[T|OUT] (\d+) MT/i;
        let priceReg = /USD (\d+) P{0,1}MT/i;

        for (let item of foStrList) {
          let title = item.match(/^\w+FO/i);
          let quantity = item.match(quantityReg);
          let price = item.match(priceReg);
          switch (true) {
            case title?.[0]?.toLocaleLowerCase() === "lsfo":
              bunkerForm["lsFoPrice"] = (+price?.[1] ?? "");
              bunkerForm["lsFoQuantity"] = (+quantity?.[1] ?? "");
              break;
            case title?.[0]?.toLocaleLowerCase() === "hsfo":
              bunkerForm["hsFoPrice"] = (+price?.[1] ?? "");
              bunkerForm["hsFoQuantity"] = (+quantity?.[1] ?? "");
              break;
            default:
              break
          }
        }

        for (let item of moStrList) {
          let title = item.match(/^\w+[MGO|MDO]/i);
          let quantity = item.match(quantityReg);
          let price = item.match(priceReg);
          switch (true) {
            case title?.[0]?.toLocaleLowerCase()?.includes("mdo"):
              bunkerForm["mdoPrice"] = (+price?.[1] ?? "");
              bunkerForm["mdoQuantity"] = (+quantity?.[1] ?? "");
              break;
            case title?.[0]?.toLocaleLowerCase()?.includes("mgo"):
              bunkerForm["mgoPrice"] = (+price?.[1] ?? "");
              bunkerForm["mgoQuantity"] = (+quantity?.[1] ?? "");
              break;
            default:
              break
          }
        }
        if(Object.keys(bunkerForm)?.length === 0) {
          return;
        };
        onCommit?.(bunkerForm)
      }}
      placeholder=""
      // placeholder={`BUNKER CLAUSE:\nBUNKERS ON DELIVERY ABOUT:\nLSFO ABT 600 MT @ USD 340 PMT\nLSMGO ABT 70 MT @ USD 350 PMT\nBUNKERS ON REDELIVERY ABT AS ON DELIVERY AND SAME PRICES BENDS`}
    />
    <span className="vm-common-title">Good Weather Definition</span>
    <FleetTextarea
      value={item?.goodWeatherDef}
      onChange={(val: string) => {
        onChange?.("goodWeatherDef", val);
      }}
      className="bunkerClause-textarea bunkerClause-goodWeather"
      placeholder=""
      // placeholder={`SPEED/CONS DESCRIBED FROM SEABUOYTO SEABUOY\nTHE ABOVE SPD/CONS IS BASIS GOOD WEATHER CONDITION, CLEAN\nUNDERWATER HULL, CLEAN FUEL, NO ADVERSE CURRENT, NO NEGATIVE\nINFLUENCE OF SWELLS AND NOT EXCEEDINGG BEAUFORT SCALE FORCE 4 AND\nDOUGLAS SEA STATE 3 WITH COMBINED WAVE AND SWELL HEIGHTS NOT TO\nEXCEED 1.25 M.\nALL CONSUMPTION FIGURES ARE CALCULATTED ON A PER DAY BASIS\nCONSIDERING A 24 HOUR DAY.`}
    />
  </div>
}

export default HfBunkerClause
