import React, { Fragment, forwardRef, useRef } from "react";
import { VoyageManageHandoverFormProps, VoyageManageHandoverFormRefType } from "./type";
import { VoyageManageTctcHandoverForm, VoyageManageTcvcHandoverForm } from "./components";

const VoyageManageHandoverForm = forwardRef<
	VoyageManageHandoverFormRefType,
	VoyageManageHandoverFormProps
>(({ className, voyageId, voyageType, type }, ref) => {
	return (
		<Fragment>
			{voyageType === "TCTC" ? (
				<VoyageManageTctcHandoverForm
					voyageId={voyageId}
					className={className}
					type={type}
					voyageType={voyageType}
					ref={ref}
				/>
			) : (
				<VoyageManageTcvcHandoverForm
					voyageId={voyageId}
					className={className}
					type={type}
					voyageType={voyageType}
					ref={ref}
				/>
			)}
		</Fragment>
	);
});

export default VoyageManageHandoverForm;
