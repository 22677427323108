import { type ParameterType } from "@/api";
import { loginOut, loginOutAction } from "@/store/userSlice";

import {
	queryContactParams,
	queryUserGroupApi,
	getContactGroupApi,
	getContactParams,
	deleteContactApi,
	queryContactGroupApi,
	addContactApi,
	addContactParams,
	editContactApi,
	editContactParams,
	getContactApi,
	queryUserRoleGroupApi,
	queryUserRoleGroupParams
} from "@/api/dataBase/contact";

export const queryContactGroupAction: ParameterType<queryContactParams> = (
	params,
	frontCallback,
	successCallback,
	errorCallback,
	dispatch,
	navigate
) => {
	frontCallback?.();
	queryContactGroupApi(params)
		.then((response) => {
			successCallback?.(response);
		})
		.catch((err) => {
			errorCallback?.(err);
			if (err?.loginStatus === "token faild") {
				// dispatch?.(loginOut({}));
				// navigate?.('/')
				dispatch(loginOutAction(() => navigate?.("/login")));
			}
		});
};

export const getContactGroupAction: ParameterType<queryContactParams> = (
	params,
	frontCallback,
	successCallback,
	errorCallback,
	dispatch,
	navigate
) => {
	frontCallback?.();
	getContactGroupApi(params)
		.then((response) => {
			successCallback?.(response);
		})
		.catch((err) => {
			errorCallback?.(err);
			if (err?.loginStatus === "token faild") {
				// dispatch?.(loginOut({}));
				// navigate?.('/')
				dispatch(loginOutAction(() => navigate?.("/login")));
			}
		});
};

export const queryUserRoleGroupAction: ParameterType<queryUserRoleGroupParams> = (
	params,
	frontCallback,
	successCallback,
	errorCallback,
	dispatch,
	navigate
) => {
	console.log("params", params);
	frontCallback?.();
	queryUserRoleGroupApi(params)
		.then((response) => {
			successCallback?.(response);
		})
		.catch((err) => {
			errorCallback?.(err);
			if (err?.loginStatus === "token faild") {
				// dispatch?.(loginOut({}));
				// navigate?.('/')
				dispatch(loginOutAction(() => navigate?.("/login")));
			}
		});
};

export const queryUserGroupAction: ParameterType<queryContactParams> = (
	params,
	frontCallback,
	successCallback,
	errorCallback,
	dispatch,
	navigate
) => {
	frontCallback?.();
	queryUserGroupApi(params)
		.then((response) => {
			successCallback?.(response);
		})
		.catch((err) => {
			errorCallback?.(err);
			if (err?.loginStatus === "token faild") {
				// dispatch?.(loginOut({}));
				// navigate?.('/')
				dispatch(loginOutAction(() => navigate?.("/login")));
			}
		});
};

export const deleteContactAction: ParameterType<getContactParams> = (
	params,
	frontCallback,
	successCallback,
	errorCallback,
	dispatch,
	navigate
) => {
	frontCallback?.();
	deleteContactApi(params)
		.then((response) => {
			successCallback?.(response);
		})
		.catch((err) => {
			errorCallback?.(err);
			if (err?.loginStatus === "token faild") {
				// dispatch?.(loginOut({}));
				// navigate?.('/')
				dispatch(loginOutAction(() => navigate?.("/login")));
			}
		});
};

export const addContactAction: ParameterType<addContactParams> = (
	params,
	frontCallback,
	successCallback,
	errorCallback,
	dispatch,
	navigate
) => {
	frontCallback?.();
	addContactApi(params)
		.then((response) => {
			successCallback?.(response);
		})
		.catch((err) => {
			errorCallback?.(err);
			if (err?.loginStatus === "token faild") {
				// dispatch?.(loginOut({}));
				// navigate?.('/')
				dispatch(loginOutAction(() => navigate?.("/login")));
			}
		});
};

export const editContactAction: ParameterType<editContactParams> = (
	params,
	frontCallback,
	successCallback,
	errorCallback,
	dispatch,
	navigate
) => {
	frontCallback?.();
	editContactApi(params)
		.then((response) => {
			successCallback?.(response);
		})
		.catch((err) => {
			errorCallback?.(err);
			if (err?.loginStatus === "token faild") {
				// dispatch?.(loginOut({}));
				// navigate?.('/')
				dispatch(loginOutAction(() => navigate?.("/login")));
			}
		});
};

export const getContactAction: ParameterType<getContactParams> = (
	params,
	frontCallback,
	successCallback,
	errorCallback,
	dispatch,
	navigate
) => {
	frontCallback?.();
	getContactApi(params)
		.then((response) => {
			successCallback?.(response);
		})
		.catch((err) => {
			errorCallback?.(err);
			if (err?.loginStatus === "token faild") {
				// dispatch?.(loginOut({}));
				// navigate?.('/')
				dispatch(loginOutAction(() => navigate?.("/login")));
			}
		});
};
