import React, { FC } from "react";
import style from "./index.module.less";
import { RoutePortListProps } from "./type";
import classnames from "classnames";
import useIndex from "./hooks/useIndex";
import {
	RouteCommonPortTabbar,
	RoutePortCurrent,
	RoutePortOtherIntervals,
	RoutePortSelect
} from "./components";
import { FleetLoading } from "@/components";

const RoutePortList: FC<RoutePortListProps> = ({ activeTab, onPortVesselsReset }) => {
	const {
		dataSource,
		loading,
		expectRange,
		historyRange,
		activePortItem,
		handleTabChange,
		handlePortCommit,
		handleRangeCommit
	} = useIndex(activeTab, onPortVesselsReset);
	return (
		<div
			className={classnames("routeCommon-list-common", style["route-portList"], {
				[style["route-portList--active"]]: activeTab === "port"
			})}
		>
			<RoutePortSelect
				activeItems={activePortItem}
				onFinish={(newItem, type) => {
					handlePortCommit?.({
						portName: newItem?.portName ?? "",
						portCode: newItem?.portCode ?? ""
					});
				}}
				showKey="portName"
				primaryKey="portCode"
				placeholder="Search Port"
			/>
			<div className="routeCommon-list-container portList-wrapper">
				<FleetLoading loading={loading} />
				<RouteCommonPortTabbar activeTab={activePortItem.type} onSelect={handleTabChange} />
				{activePortItem.type === 2 ? (
					<RoutePortCurrent items={dataSource} />
				) : (
					<RoutePortOtherIntervals
						mode={activePortItem.type}
						item={activePortItem.type === 1 ? expectRange : historyRange}
						onCommit={handleRangeCommit}
					/>
				)}
			</div>
		</div>
	);
};

export default RoutePortList;
