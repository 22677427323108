import { useState } from "react"
import { HandoverFormFixtureNotesChangeEvent, HandoverFormFixtureNotesCommitEvent, HandoverFormFixtureNotesType } from "../type"
import { initialFixtureNotes, checkKeys } from "../source";

const useIndex = () => {
  const [dataSource, setDataSource] = useState<HandoverFormFixtureNotesType>({ ...initialFixtureNotes() });

  const change: HandoverFormFixtureNotesChangeEvent = (key, value) => {
    setDataSource(prev => ({ ...prev, [key]: value }))
  }

  const commit: HandoverFormFixtureNotesCommitEvent = (item) => {
    setDataSource(prev => ({ ...prev, ...item }))
  }

  const init: HandoverFormFixtureNotesCommitEvent = (item) => {
    commit(initialFixtureNotes(item))
  }

  const check = () => {
    let checkKey = "";
    let checked = true;
    for (let key of checkKeys) {
      if (dataSource[key] !== 0 && !dataSource[key]) {
        checkKey = key;
        checked = false;
        break
      }
    }

    return {
      checkKey,
      checked
    }
  }

  return {
    dataSource,
    change,
    commit,
    check,
    init
  }
}

export default useIndex