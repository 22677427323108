import { onRequest } from "@/action";
import { useAppDispatch } from "@/hook";
import useReminder from "@/hook/useReminder";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import {
	PortVesselListProps,
	initialFilterItem,
	RouteCommonPortVesselFilterItemType,
	RouteCommonPortVesselFilterProps,
	useFilterIndex
} from "../components";
import dayjs from "dayjs";
import { useRouteCommonContext } from "@/pages/routeCommon/store";
import { RouteCommonPortVesselProps, RouteCommonPortVesselRefType } from "../type";

const useIndex = (onVesselLoad: RouteCommonPortVesselProps["onVesselLoad"]) => {
	const dispatch = useAppDispatch();
	const navigate = useNavigate();
	const { reminder } = useReminder();

	const { activeDetailsOption, activePortItem, onPortItemCommit } = useRouteCommonContext();
	const [loading, setLoading] = useState(false);
	const [selectRows, setSelectRows] = useState<PortVesselListProps["selectRows"]>([]);
	const [dataSource, setDataSource] = useState<PortVesselListProps["items"]>([]);

	const { dataSource: filters, commit, init: handleFilterInit } = useFilterIndex();

	const [timeRange, setTimeRange] = useState<RouteCommonPortVesselFilterProps["timeRange"]>({
		startDate: dayjs().format("YYYY-MM-DD"),
		endDate: dayjs().format("YYYY-MM-DD")
	});

	const handleTimeRangeChange: RouteCommonPortVesselFilterProps["onTimeRangeCommit"] = (item) => {
		setTimeRange(item);
	};

	const handleRowSelect: PortVesselListProps["onSelect"] = (vals) => {
		setSelectRows(vals);
	};

	const handleCommit: RouteCommonPortVesselFilterProps["onCommit"] = (item) => {
		updatePortArguments(item);
	};

	const getPortArgumentsFront = () => {
		setLoading(true);
	};

	const getPortArgumentsSuccess = (response) => {
		setLoading(false);
		handleFilterInit(response?.data);
		getPortVesselList(initialFilterItem(response?.data));
	};

	const getPortArgumentsFaild = (error) => {
		console.log("error", error);
		setLoading(false);
		reminder("error", error?.msg ? error?.msg + ": " + error?.data : error?.data);
	};

	const getPortArguments = () => {
		onRequest(
			"getRouteCommonPortVesselArgumentsApi",
			null,
			getPortArgumentsFront,
			getPortArgumentsSuccess,
			getPortArgumentsFaild,
			dispatch,
			navigate
		);
	};

	const updatePortArguments = (params: Partial<RouteCommonPortVesselFilterItemType>) => {
		onRequest(
			"updateRouteCommonPortVesselArgumentsApi",
			{ ...filters, ...params },
			getPortArgumentsFront,
			(response) => {
				commit(params);
				getPortVesselList({ ...filters, ...params });
				setLoading(false);
			},
			getPortArgumentsFaild,
			dispatch,
			navigate
		);
	};

	const getPortVesselListSuccess = (response) => {
		setLoading(false);
		setDataSource(response?.data);
		setSelectRows([]);
		onVesselLoad?.("port", response?.data);
	};

	const downloadPortVesselListSuccess = (response) => {
		reminder("success", "Download Successfully!");
		setLoading(false);
	};

	const getPortVesselList = (params: RouteCommonPortVesselFilterItemType = filters) => {
		if (dataSource?.length <= 0 && !activePortItem?.portCode) return;
		onRequest(
			"getRouteCommonPortVesselListApi",
			{
				...params,
				startDate: activePortItem.startDate,
				endDate: activePortItem.endDate,
				filterEndDate: timeRange.endDate,
				filterStartDate: timeRange.startDate,
				type: activePortItem.type,
				portCode: activePortItem.portCode
			},
			getPortArgumentsFront,
			getPortVesselListSuccess,
			getPortArgumentsFaild,
			dispatch,
			navigate
		);
	};

	const handleReset: RouteCommonPortVesselRefType["onPortVesselReset"] = (hasPort) => {
		if (!hasPort || !activePortItem.portCode) {
			if (dataSource?.length > 0) {
				setDataSource([]);
				setSelectRows([]);
				onVesselLoad?.("port", []);
			}
			return;
		}
		onRequest(
			"getRouteCommonPortVesselListApi",
			{
				...filters,
				startDate: activePortItem.startDate,
				endDate: activePortItem.endDate,
				filterEndDate: timeRange.endDate,
				filterStartDate: timeRange.startDate,
				type: activePortItem.type,
				portCode: activePortItem.portCode
			},
			getPortArgumentsFront,
			getPortVesselListSuccess,
			getPortArgumentsFaild,
			dispatch,
			navigate
		);
	};

	const handleDownload = () => {
		debugger;
		if (selectRows?.length <= 0) return;
		console.log("selectRows", selectRows);
		const selectItems = dataSource?.filter((selectItem) => selectRows?.includes(selectItem?.imo));
		onRequest(
			"downloadRouteCommonPortVesselListApi",
			selectItems,
			getPortArgumentsFront,
			downloadPortVesselListSuccess,
			getPortArgumentsFaild,
			dispatch,
			navigate
		);
	};

	useEffect(() => {
		if (activeDetailsOption?.activeTab !== "portVessel") return;
		getPortArguments();
	}, [activeDetailsOption]);

	console.log("setSelectRows", selectRows);

	return {
		loading,
		filters,
		handleCommit,
		timeRange,
		handleTimeRangeChange,
		dataSource,
		handleReset,
		selectRows,
		handleRowSelect,
		handleDownload,
		activeDetailsOption
	};
};

export default useIndex;
