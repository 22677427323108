import { type ApiParameter } from ".";
import { appVersion } from "@/api/constant";

import request from "@/service";
import { CargoAndPortItemType } from "@/pages/estimation/business/list/components/newEst/components/cargo&port/type";
import { VesselParticularItemType } from "@/pages/estimation/business/list/components/newEst/components/vesselParticular/type";
import {
	EstVesselParticularType,
	EstCargoItemType,
	EstOtherItemType
} from "@/pages/estimation/module";
import { EstPortRotationItemType } from "@/pages/estimation/components";
import { EstProfitItemType } from "@/pages/estimation/business/details/type";

const enum ApiMap {
	getEsCompareParameter,
	queryPortGroupParameter,
	queryVesselGroupParameter,
	rewriteCargoParameter,
	addEstimationParamater,
	saveEsCompareParameter,
	delEsResultParameter,
	getEsGroupParameter,
	delEsPortParameter,
	rewriteVesselParameter
}

type ApiUrl = keyof typeof ApiMap;

const esApi: ApiParameter<ApiUrl> = {
	getEsCompareParameter: {
		method: "get",
		url: appVersion + "/est/vc/comparison"
	},
	queryPortGroupParameter: {
		method: "get",
		url: appVersion + "/port/search"
	},
	queryVesselGroupParameter: {
		method: "get",
		url: appVersion + "/vessel/list"
	},
	rewriteCargoParameter: {
		method: "post",
		url: "/gpt/cargo"
	},
	rewriteVesselParameter: {
		method: "post",
		url: "/gpt/vessel"
	},
	addEstimationParamater: {
		method: "post",
		url: appVersion + "/est/vc/result"
	},
	saveEsCompareParameter: {
		method: "put",
		url: appVersion + "/est/vc"
	},
	delEsResultParameter: {
		method: "delete",
		url: appVersion + "/est/vc"
	},
	getEsGroupParameter: {
		method: "get",
		url: appVersion + "/est/vc/query"
	},
	delEsPortParameter: {
		method: "delete",
		url: appVersion + "/est/vc/port"
	}
};

export type PageParams = {
	pageSize: number;
	pageNum: number;
};

export type getEsCompareParams = {
	ids?: number;
	isReserveDeliveryPort?: boolean;
};

export type queryPortGroupParams = {
	keyword: string;
} & PageParams;

export type rewriteParams = {
	text: string;
};

export type EsCompareResult = {
	vesselParticular: EstVesselParticularType;
	cargoParticularList: EstCargoItemType[];
	portRotationList: EstPortRotationItemType[];
	delPortIds: number[];
} & EstOtherItemType &
	EstProfitItemType & {
		ecaDays?: number;
		ecaBallastDays?: number;
		ecaLadenDays?: number;
		seaDays?: number;
		seaBallastDays?: number;
		seaLadenDays?: number;
		portDays?: number;
		portWorkDays?: number;
		portIdleDays?: number;
	};

export type saveEsCompareParams = {
	delIds?: string[];
	resultList?: EsCompareResult[];
};

export type addEstimationParams = {
	status: "DFT" | "EST" | "PVY";
	cargoPortFoList: CargoAndPortItemType[];
	vesselFoList: VesselParticularItemType[];
};

export type delEsResultParams = {
	resultId?: React.Key[] | string;
};

export type getEsGroupParams = {
	keyword: string;
	layCanFrom: string;
	layCanTo: string;
} & PageParams;

export type delEsPortParams = {
	portId?: string | number;
};

export const getEsCompareApi = (params: getEsCompareParams) => {
	return request.request({
		...esApi.getEsCompareParameter,
		params
	});
};

export const queryPortGroupApi = (params: queryPortGroupParams) => {
	return request.request({
		...esApi.queryPortGroupParameter,
		params
	});
};

export const rewriteCargoApi = (params: rewriteParams) => {
	return request.request({
		...esApi.rewriteCargoParameter,
		data: params
	});
};

export const rewriteVesselApi = (params: rewriteParams) => {
	return request.request({
		...esApi.rewriteVesselParameter,
		data: params
	});
};

export const queryVesselGroupApi = (params: queryPortGroupParams) => {
	return request.request({
		...esApi.queryVesselGroupParameter,
		params
	});
};

export const addEstimationApi = (params: addEstimationParams) => {
	return request.request({
		...esApi.addEstimationParamater,
		data: params
	});
};

export const saveEsCompareApi = (params: saveEsCompareParams) => {
	return request.request({
		...esApi.saveEsCompareParameter,
		data: params
	});
};

export const delEsResultApi = (params: delEsResultParams) => {
	return request.request({
		...esApi.delEsResultParameter,
		url: `${esApi.delEsResultParameter.url}/${params.resultId}`
	});
};

export const delEsMultipleApi = (params: delEsResultParams) => {
	return request.request({
		...esApi.delEsResultParameter,
		params: {
			resultId: params.resultId
		}
	});
};

export const getEsGroupApi = (params: getEsGroupParams) => {
	return request.request({
		...esApi.getEsGroupParameter,
		params
	});
};

export const delEsPortApi = (params: delEsPortParams) => {
	return request.request({
		...esApi.delEsPortParameter,
		url: `${esApi.delEsPortParameter.url}/${params?.portId}`
	});
};
