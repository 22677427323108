import React, { FC } from "react";
import { VesselParticularItemProps } from "./type";
import { FleetFormItem, FleetVerifyInput } from "@/components";
import { useTranslation } from "react-i18next";
import { EstTemplate } from "@/pages/estimation/components";
import { CommonPortSelect, CommonVesselSelect } from "@/common";

const VesselParticularItem: FC<VesselParticularItemProps> = ({
	item,
	currentIndex,
	onChange,
	onCommit,
	onCrud,
	onTemplateSave
}) => {
	const { t } = useTranslation();
	return (
		<div className="estimation-newly--item scroll-allowed">
			<div className="newly-item--header">
				<span className="header-title ">Vessel Particular</span>
				<div className="header-actions">
					<span
						className="font_family fleet-add-square"
						onClick={() => onCrud?.("vessel/add")}
					></span>
					<span
						className="font_family fleet-copy"
						onClick={() => onCrud?.("vessel/parse", currentIndex)}
					></span>
					<span
						className="font_family fleet-delete"
						onClick={() => onCrud?.("vessel/delete", currentIndex)}
					></span>
				</div>
			</div>
			<div className="newly-item--container">
				<div className="newly-item--scrollArea">
					<EstTemplate
						activeItem={{
							id: item?.templateId,
							templateName: item?.templateName
						}}
						label="Template"
						className="newly-item--formItem ninetyPercent"
						onCommit={(item) => onCommit?.(currentIndex, item)}
						onTemplateSave={(templateName) => onTemplateSave?.(currentIndex, templateName)}
					/>
					<div className="newly-item--formItem full">
						<FleetFormItem value={item?.vesselName} label={t(`common.vesselName`)}>
							<CommonVesselSelect
								activeItems={{
									vesselName: item?.vesselName,
									mmsi: item?.mmsi as number
								}}
								primaryKey="mmsi"
								showKey="vesselName"
								onFinish={(newItem, type) => {
									onCommit?.(currentIndex, {
										dwt: newItem?.dwt ?? "",
										bale: newItem?.baleCapacity ?? "",
										grain: newItem?.grainCapacity ?? "",
										imo: newItem?.imo ?? null,
										mmsi: newItem?.mmsi ?? null,
										vesselName: newItem?.vesselName ?? ""
									});
								}}
							/>
						</FleetFormItem>
					</div>
					<div className="newly-item--formItem bisector">
						<FleetVerifyInput
							value={item?.ownerName}
							onChange={(val: string) => {
								onChange?.(currentIndex, "ownerName", val);
							}}
							label={t(`common.ownerName`)}
						/>
						<FleetVerifyInput
							needVerity
							type="number"
							value={item?.dwt}
							onChange={(val: number) => {
								onChange?.(currentIndex, "dwt", val);
							}}
							label={t(`common.dwt`)}
						/>
					</div>
					<div className="newly-item--formItem bisector">
						<FleetVerifyInput
							type="number"
							value={item?.grain}
							onChange={(val: number) => {
								onChange?.(currentIndex, "grain", val);
							}}
							label={t(`common.grain`)}
						/>
						<FleetVerifyInput
							type="number"
							value={item?.bale}
							onChange={(val: number) => {
								onChange?.(currentIndex, "bale", val);
							}}
							label={t(`common.bale`)}
						/>
					</div>
					<div className="newly-item--formItem full">
						<FleetFormItem needVerity label={t(`common.delievryPort`)}>
							<CommonPortSelect
								primaryKey="portCode"
								showKey="portName"
								activeItems={{
									portName: item?.deliveryPortName,
									portCode: item?.deliveryPortCode
								}}
								onFinish={(newItem, type) => {
									onCommit?.(currentIndex, {
										deliveryPortCode: newItem?.portCode ?? "",
										deliveryPortName: newItem?.portName ?? ""
									});
								}}
							/>
						</FleetFormItem>
					</div>
					<div className="newly-item--formItem bisector">
						<FleetVerifyInput
							type="number"
							needVerity
							placeholder="Ballast"
							value={item?.ballastSpeed}
							onChange={(val: number) => {
								onChange?.(currentIndex, "ballastSpeed", val);
							}}
							label={t(`common.ballastSpeed`)}
						/>
						<FleetVerifyInput
							type="number"
							needVerity
							placeholder="Laden"
							value={item?.ladenSpeed}
							label={t(`common.ladenSpeed`)}
							onChange={(val: number) => {
								onChange?.(currentIndex, "ladenSpeed", val);
							}}
						/>
					</div>
					<div className="newly-item--formItem trisection">
						<FleetVerifyInput
							type="number"
							value={item?.seaFuelBallast}
							onChange={(val: number) => {
								onChange?.(currentIndex, "seaFuelBallast", val);
							}}
							label={t(`common.seaFuelBallast`)}
							needVerity
							placeholder="Ballast"
						/>
						<FleetVerifyInput
							type="number"
							needVerity
							value={item?.seaFuelLaden}
							placeholder="Laden"
							label={t(`common.seaFuelLaden`)}
							onChange={(val: number) => {
								onChange?.(currentIndex, "seaFuelLaden", val);
							}}
						/>
						<FleetVerifyInput
							value={item?.seaFuelMdo}
							type="number"
							needVerity
							placeholder="MDO"
							label={t(`common.seaFuelMdo`)}
							onChange={(val: number) => {
								onChange?.(currentIndex, "seaFuelMdo", val);
							}}
						/>
					</div>
					<div className="newly-item--formItem trisection">
						<FleetVerifyInput
							type="number"
							needVerity
							placeholder="Work"
							value={item?.portFuelWork}
							onChange={(val: number) => {
								onChange?.(currentIndex, "portFuelWork", val);
							}}
							label={t(`common.portFuelWork`)}
						/>
						<FleetVerifyInput
							type="number"
							placeholder="Idle"
							needVerity
							value={item?.portFuelIdle}
							label={t(`common.portFuelIdle`)}
							onChange={(val: number) => {
								onChange?.(currentIndex, "portFuelIdle", val);
							}}
						/>
						<FleetVerifyInput
							type="number"
							needVerity
							placeholder="MDO"
							label={t(`common.portFuelMdo`)}
							value={item?.portFuelMdo}
							onChange={(val: number) => {
								onCommit?.(currentIndex, {
									portFuelMdo: val,
									portIdleMdo: val,
									portWorkMdo: val
								});
							}}
						/>
					</div>
					<div className="newly-item--formItem bisector">
						<FleetVerifyInput
							// needVerity={true}
							needVerity
							type="number"
							value={item?.bunkerPriceIfo}
							onChange={(val: number) => {
								onChange?.(currentIndex, "bunkerPriceIfo", val);
							}}
							label={t("common.bunkerPriceIfo")}
						/>
						<FleetVerifyInput
							// needVerity={true}
							needVerity
							type="number"
							value={item?.bunkerPriceMdo}
							onChange={(val: number) => {
								onChange?.(currentIndex, "bunkerPriceMdo", val);
							}}
							label={t("common.bunkerPriceMdo")}
						/>
					</div>
					<div className="newly-item--formItem bisector">
						<FleetVerifyInput
							needVerity={true}
							type="number"
							value={item?.dailyHire}
							onChange={(val: number) => {
								onChange?.(currentIndex, "dailyHire", val);
							}}
							label={t(`common.dailyHire`)}
						/>
						<FleetVerifyInput
							// needVerity={true}
							type="number"
							value={item?.commission}
							onChange={(val: number) => {
								onChange?.(currentIndex, "commission", val);
							}}
							label={t(`common.commission`)}
						/>
					</div>
					<div className="newly-item--formItem bisector">
						<FleetVerifyInput
							type="number"
							value={item?.brokerage}
							onChange={(val: number) => {
								onChange?.(currentIndex, "brokerage", val);
							}}
							label={t(`common.brokerage`)}
						/>
						<FleetVerifyInput
							type="number"
							value={item?.otherExpenses}
							onChange={(val: number) => {
								onChange?.(currentIndex, "otherExpenses", val);
							}}
							label={t(`common.otherExpenses`)}
						/>
					</div>
				</div>
			</div>
		</div>
	);
};

export default VesselParticularItem;
