import { useMemo } from "react";
import { MovementTctcVesselBasicsItemType } from "../components";
import { MovementTctcOtherItemType } from "../type";
import { MovementFinancialDataItemType, initialFinacialItem } from "../../../common";

const useEstimation = (
	rentVesselItem: MovementTctcVesselBasicsItemType,
	reletVesselItem: MovementTctcVesselBasicsItemType,
	otherItem: MovementTctcOtherItemType
) => {
	const rentOffHire = useMemo(() => {
		return otherItem?.rentOffHire?.reduce((prev, curr) => {
			return prev + +curr?.duration;
		}, 0);
	}, [otherItem?.rentOffHire]);

	const otherExpenses = useMemo(() => {
		return otherItem?.otherExpense?.reduce(
			(prev, curr) => {
				prev.total += +curr?.netExpense;
				prev.ownersCount += +curr?.ownerAccount;
				prev.calcuate?.push(
					initialFinacialItem({
						desc: curr?.itemName,
						actual: +curr?.netExpense,
						time: curr?.status === "Settled" ? curr?.settledTime : null
					})
				);
				return prev;
			},
			{
				total: 0,
				ownersCount: 0,
				calcuate: [] as MovementFinancialDataItemType[]
			}
		);
	}, [otherItem?.otherExpense]);

	const rentHireExpense = useMemo(() => {
		return otherItem?.rentHireList?.reduce(
			(prev, curr, index, arr) => {
				prev.total += curr?.status === "Settled" ? curr?.amount : 0;
				prev?.calcuate?.push(
					initialFinacialItem({
						desc: index === arr?.length - 1 ? `Final Hire` : `${curr?.period}st Hire`,
						actual: curr?.amount,
						payerName: curr?.payerName ?? "",
						time: curr?.status === "Settled" ? curr?.settledTime : null,
						posted: curr?.status === "Settled" ? curr?.amount : null
					})
				);
				return prev;
			},
			{
				total: 0,
				calcuate: [] as MovementFinancialDataItemType[]
			}
		);
	}, [otherItem?.rentHireList]);

	const reletHireExpense = useMemo(() => {
		return otherItem?.reletHireList?.reduce(
			(prev, curr, index, arr) => {
				prev.total += curr?.status === "Settled" ? curr?.amount : 0;
				prev?.calcuate?.push(
					initialFinacialItem({
						desc: index === arr?.length - 1 ? `Final Hire` : `${curr?.period}st Hire`,
						actual: curr?.amount,
						payerName: curr?.payerName ?? "",
						time: curr?.status === "Settled" ? curr?.settledTime : null,
						posted: curr?.status === "Settled" ? curr?.amount : null
					})
				);
				return prev;
			},
			{
				total: 0,
				calcuate: [] as MovementFinancialDataItemType[]
			}
		);
	}, [otherItem?.reletHireList]);

	const disputeCharges = useMemo(() => {
		return otherItem?.disputeChargeList?.reduce(
			(prev, curr) => {
				prev.total += +curr?.finalAmount;
				prev.calcuate?.push(
					initialFinacialItem({
						desc: curr?.itemName,
						actual: +curr?.finalAmount
					})
				);
				return prev;
			},
			{
				total: 0,
				calcuate: [] as MovementFinancialDataItemType[]
			}
		);
	}, [otherItem?.disputeChargeList]);

	const reletOffHire = useMemo(() => {
		return otherItem?.reletOffHire?.reduce((prev, curr) => {
			return prev + +curr?.duration;
		}, 0);
	}, [otherItem?.reletOffHire]);

	const rentIfoCost = useMemo(() => {
		return (
			otherItem?.rentBunkerPlan?.hsfoTotalPrice +
			otherItem?.rentBunkerPlan?.lsfoTotalPrice +
			+otherItem?.rentBunkerPlan?.scrubberTotalPrice
		);
	}, [otherItem?.rentBunkerPlan]);

	const rentMdoCost = useMemo(() => {
		return otherItem?.rentBunkerPlan?.mdoTotalPrice + otherItem?.rentBunkerPlan?.mgoTotalPrice;
	}, [otherItem?.rentBunkerPlan]);

	const rentTotalBunkerCost = useMemo(() => {
		return rentIfoCost + rentMdoCost;
	}, [rentIfoCost, rentMdoCost]);

	const rentOperationCost = useMemo(() => {
		return rentTotalBunkerCost + otherExpenses?.total;
	}, [rentTotalBunkerCost, otherExpenses]);

	const rentHireCost = useMemo(() => {
		const totalDays =
			((rentVesselItem?.estRedeliveryDate ?? 0) - (rentVesselItem?.deliveryTime ?? 0)) /
			(1000 * 60 * 60 * 24);
		return +rentVesselItem.dailyHire * totalDays;
		// return +vesselItem.dailyHire * (portCalcutor.ballastSeaDays + portCalcutor.ladenSeaDays + portCalcutor.ballastPortTotalDays + portCalcutor.ladenPortTotalDays)
	}, [rentVesselItem.dailyHire, rentVesselItem?.estRedeliveryDate, rentVesselItem?.deliveryTime]);

	const rentCommission = useMemo(() => {
		return (rentHireCost * +rentVesselItem.commission) / 100;
	}, [rentHireCost, rentVesselItem.commission]);

	const rentBrokerage = useMemo(() => {
		return (rentHireCost * +rentVesselItem.brokerage) / 100;
	}, [rentHireCost, rentVesselItem.brokerage]);

	const rentTotalHireCost = useMemo(() => {
		return rentHireCost - (rentCommission + rentBrokerage);
	}, [rentHireCost, rentBrokerage, rentCommission]);

	const reletTotalBunkerCost = useMemo(() => {
		return (
			otherItem?.reletBunkerPlan?.hsfoTotalPrice +
			otherItem?.reletBunkerPlan?.lsfoTotalPrice +
			+otherItem?.reletBunkerPlan?.scrubberTotalPrice +
			otherItem?.reletBunkerPlan?.mdoTotalPrice +
			+otherItem?.reletBunkerPlan?.mgoTotalPrice
		);
	}, [otherItem?.reletBunkerPlan]);

	const reletHireCost = useMemo(() => {
		const totalDays =
			((reletVesselItem?.estRedeliveryDate ?? 0) - (reletVesselItem?.deliveryTime ?? 0)) /
			(1000 * 60 * 60 * 24);
		return +reletVesselItem.dailyHire * totalDays;
		// return +vesselItem.dailyHire * (portCalcutor.ballastSeaDays + portCalcutor.ladenSeaDays + portCalcutor.ballastPortTotalDays + portCalcutor.ladenPortTotalDays)
	}, [
		reletVesselItem.dailyHire,
		reletVesselItem?.estRedeliveryDate,
		reletVesselItem?.deliveryTime
	]);

	const reletCommission = useMemo(() => {
		return (reletHireCost * +reletVesselItem.commission) / 100;
	}, [reletHireCost, reletVesselItem.commission]);

	const reletBrokerage = useMemo(() => {
		return (reletHireCost * +reletVesselItem.brokerage) / 100;
	}, [reletHireCost, reletVesselItem.brokerage]);

	const reletTotalHireCost = useMemo(() => {
		return reletHireCost - (reletCommission + reletBrokerage);
	}, [reletHireCost, reletBrokerage, reletCommission]);

	const revenues = useMemo(() => {
		return reletTotalHireCost + otherItem?.otherIncome + reletTotalBunkerCost;
	}, [reletTotalHireCost, otherItem?.otherIncome]);

	const totalCost = useMemo(() => {
		return rentOperationCost + rentTotalHireCost;
	}, [rentOperationCost, rentTotalHireCost]);

	const profit = useMemo(() => {
		return revenues - totalCost;
	}, [revenues, totalCost]);

	const tce = useMemo(() => {
		const totalDays =
			((reletVesselItem?.estRedeliveryDate ?? 0) - (reletVesselItem?.deliveryTime ?? 0)) /
			(1000 * 60 * 60 * 24);
		return totalDays ? (revenues - rentOperationCost) / totalDays : 0;
	}, [revenues, rentOperationCost]);

	return {
		totalProfit: profit,
		tce,
		totalRevenue: revenues,
		totalCost,
		rentTotalHireCost,
		reletTotalHireCost,
		otherExpenses,
		rentCommission,
		rentBrokerage,
		reletCommission,
		reletBrokerage,
		rentHireCost,
		reletHireCost,
		rentHireExpense,
		rentTotalBunkerCost,
		reletTotalBunkerCost,
		reletHireExpense,
		disputeCharges
	};
};

export default useEstimation;
