import React, { useEffect } from "react";
import { delEsMultipleAction, getEsGroupAction } from "@/action/estimation";
import { useCheckGroup, columns as list_columns } from "@/types/estimation";
import { type Dayjs } from "dayjs";
import { useMemo, useState } from "react";
import { FleetButton } from "@/components";
import { CommonRoleWrap, FleetToast } from "@/common";
import useCommonGroup from "@/hook/common/useGroup";
import classnames from "classnames";
import { Popover } from "@/_components";

const useGroup = () => {
	const [layCan, setLayCan] = useState<[Dayjs | undefined, Dayjs | undefined]>([
		undefined,
		undefined
	]);
	const { checkGroup, reset: handleReset, change: handleChange } = useCheckGroup();

	const {
		loading,
		dataSource,
		pager,
		selectedRowKeys,
		setSelectedRowKeys,
		search,
		dispatch,
		navigate,
		reminder,
		getGroupError,
		getGroupFront,
		getGroup,
		onPageChange,
		onSearch
	} = useCommonGroup(getEsGroupAction, delEsMultipleAction, {
		layCanFrom: layCan?.[0]?.format("YYYY-MM-DD"),
		layCanTo: layCan?.[1]?.format("YYYY-MM-DD")
	});

	const delEsResultSuccess = (response) => {
		reminder("success", response?.msg);
		setSelectedRowKeys([]);
		getGroup();
	};

	const delEsResult = (type: "multiple" | "single", resultId?: string) => {
		delEsMultipleAction(
			{ resultId: type === "single" ? resultId : selectedRowKeys.join(",") },
			getGroupFront,
			delEsResultSuccess,
			getGroupError,
			dispatch,
			navigate
		);
	};

	const columns = useMemo(() => {
		let column_keys = [];
		Object.values(checkGroup).forEach((item) => {
			column_keys.push(...item);
		});
		let _columns = list_columns.filter((column) => column_keys.includes(column.key));

		_columns.push({
			dataIndex: "option",
			width: 141,
			title: "Option",
			key: "option",
			align: "center",
			fixed: "right",
			render: (value, record, index) => {
				return (
					<div className="td-operate">
						<CommonRoleWrap roleStr="est:list:sendtoVM">
							<FleetButton disabled type="text" className="operate-btn">
								Send to VM
							</FleetButton>
						</CommonRoleWrap>
						{/* <span className="operate-btn" > Send to VM </span> */}
						<CommonRoleWrap roleStr="est:list:delete">
							<Popover
								disabled={record?.status === "PVY"}
								render={(setIsOpen) => {
									return (
										<FleetToast
											label="Are you sure delete this item?"
											onCancel={() => {
												setIsOpen(false);
											}}
											onConfirm={() => {
												if (record?.status === "PVY") return;
												delEsResult("single", record?.id);
												setIsOpen(false);
											}}
										/>
									);
								}}
							>
								<span
									className={classnames("font_family fleet-delete operate-icon", {
										"fleet-disabled": record?.status === "PVY"
									})}
								></span>
							</Popover>
						</CommonRoleWrap>
					</div>
				);
			}
		});
		return _columns;
	}, [checkGroup]);

	const onSelectChange = (newSelectedRowKeys: React.Key[]) => {
		setSelectedRowKeys(newSelectedRowKeys);
	};

	const onRow = (record) => {
		return {
			onDoubleClick: () => {
				navigate(`/layout/estimation/details?id=${record?.id}`);
			}
		};
	};

	useEffect(() => {
		getGroup();
	}, [layCan]);

	return {
		search,
		checkGroup,
		layCan,
		selectedRowKeys,
		pager,
		columns,
		dataSource,
		loading,
		onLayCanChange: setLayCan,
		setSelectedRowKeys,
		handleReset,
		handleChange,
		onPageChange,
		onSelectChange,
		onDeleteItems: delEsResult,
		onReset: getGroup,
		onRow,
		onSearch
	};
};

export default useGroup;
