import React, { FC } from "react";
import style from "./index.module.less";
import classnames from "classnames";
import { BunkerClauseProps, MovementBunkerClauseType } from "./type";
import { FleetFormItem, FleetSelect, FleetVerifyInput } from "@/components";
import { bunkerBorPriceOptions, bunkerScrubberOptions } from "./source";

const BunkerClause: FC<BunkerClauseProps> = ({
	item,
	onOtherItemChange,
	otherItem,
	onChange,
	onCommit,
	onBunkerBorPriceCommit
}) => {
	return (
		<div className={classnames("bunkerPlan-commom", style["bunkerPlan-clause"])}>
			<span className="bunkerPlan-header--title">C/P Bunker Clause</span>
			<div className="bunkerPlan-clause--container">
				<FleetVerifyInput
					label="BOR About %"
					value={item?.borAbout}
					min={0}
					max={100}
					onChange={(val: number) => {
						onChange?.("borAbout", val);
					}}
				/>
				<FleetFormItem label="Redelivery Price">
					<FleetSelect
						value={[item?.borPrice]}
						options={bunkerBorPriceOptions}
						onChange={(val: MovementBunkerClauseType["borPrice"]) => {
							onChange?.("borPrice", val);
							val === "same" && onBunkerBorPriceCommit?.();
						}}
					/>
				</FleetFormItem>
				<FleetFormItem label="Scrubber Benefit">
					<FleetSelect
						value={[otherItem?.scrubberBenefit]}
						options={bunkerScrubberOptions}
						onChange={(val: BunkerClauseProps["otherItem"]["scrubberBenefit"]) => {
							onOtherItemChange?.("scrubberBenefit", val);
						}}
					/>
				</FleetFormItem>
				<FleetVerifyInput
					label="Scrubber Price"
					value={otherItem?.scrubberPrice}
					disabled={otherItem?.scrubberBenefit === 0}
					onChange={(val: number) => {
						onOtherItemChange?.("scrubberPrice", val);
					}}
				/>
				<FleetVerifyInput
					label="Scrubber Cost"
					value={
						(+otherItem?.bodQuantity +
							+otherItem?.bunkerSummaryQuantity -
							+otherItem?.borQuantity) *
						+otherItem?.scrubberPrice
					}
					disabled
				/>
			</div>
		</div>
	);
};

export default BunkerClause;
