import { IconProps } from "@/_components/Icon/interface";
import { RouteVoyageTabType } from "@/pages/routeCommon/type";
import { CommonOptionType } from "@/types/advancedType";
import { InitialUppercase } from "@/types/string";

export const vesselList: CommonOptionType<
	InitialUppercase<RouteVoyageTabType>,
	RouteVoyageTabType,
	{ icon: IconProps["type"]; color: string }
>[] = [
	{ label: "Area", value: "area", icon: "fleet-area", color: "#FD881C" },
	{ label: "Port", value: "port", icon: "fleet-port1", color: "#F5CDCD" },
	{ label: "Ais", value: "ais", icon: "fleet-ais", color: "#5E89F7" },
	{ label: "Leg", value: "leg", icon: "fleet-leg", color: "#1E1E1E" }
];
