import useReminder from "@/hook/useReminder";
import { transform2DM } from "@/tools";
import { useEffect, useRef, useState } from "react";
import { RouteCommonMapProps, RouteCommonMapRefType } from "../type";
import { createRangingControll } from "../tools";
import {
	RouteCommonVesselLoadEvent,
	RouteCommonVesselTrackLoadEvent
} from "@/pages/routeCommon/type";
import mapboxgl, { GeoJSONSource } from "mapbox-gl";
import {
	currentVesselSource,
	initialVesselGroupData,
	routeCommonVesselIconMap,
	vesselGroupLayers,
	vesselGroupSource,
	vesselIconNames,
	vesselTrackPointerSource,
	vesselTrackSource
} from "../source";
import { loadImage } from "../utils";
import { useRouteCommonContext } from "@/pages/routeCommon/store";
import useVesselEvent from "./useVesselEvent";
import useVesselTrack from "./useVesselTrack";
import { formatThousandthNumber } from "@/tools/amount";
import useCreateVessel from "./useCreateVessel";
import {
	RouteCommonRangingControllTabType,
	RouteCommonToolbarMeteoMap,
	RouteCommonToolbarMeteoSwitchMap
} from "../../toolbar";
import useDiscrete from "./useDiscrete";
import useVesselGroup from "./useVesselGroup";
import { convertToDms } from "@/tools/graph";
import useAisLayer, { aisSourceMap } from "./useAisLayer";
import { RouteCommonAisItemType } from "../../list";
import dayjs from "dayjs";
import useHifleet from "./useHifleet";
import useRoutePlan from "./useRoutePlan";
const L = window?.L;

const useIndex = (onIsAisCollectGet: RouteCommonMapProps["onIsAisCollectGet"]) => {
	const { reminder } = useReminder();
	const [meteoCore, setMeteoCore] = useState(null);
	const [isLoaded, setIsLoaded] = useState<boolean>(false);
	const renderQueue = useRef<(() => void)[]>([]);
	const mapboxGl = useRef<mapboxgl.Map>(null);
	const vesselInfoPopup = useRef<mapboxgl.Popup>(null);
	const rangingControll = useRef(null);
	const leafletLayerGroup = useRef(new L.LayerGroup());

	const { activeLegVessel, activeAisVessel, activeAreaVessel, activePortVessel } =
		useRouteCommonContext();

	const { onVesselGroupListen, onVesselGroupSelectListen, onVesselGroupSelectRemove } =
		useVesselEvent(mapboxGl);

	const { loadTrackSource, loadTrackLayers } = useVesselTrack(mapboxGl);

	const { loadCurrentVesselSource, loadCurrentVesselLayers } = useCreateVessel(mapboxGl);

	const {
		loadLayers: loadHifleetLayers,
		loadSource: loadHifleetSource,
		enableLayers: handleEnableHifleetLayer
	} = useHifleet(mapboxGl);

	const {
		loadLayers: loadAisInfoLayers,
		loadSource: loadAisInfoSource,
		handleAisInfoLayerLoad
	} = useAisLayer(mapboxGl);

	const { discreteLayers, loadDiscreteLayers, onDiscreteListen, handlePointControlSelect } =
		useDiscrete(mapboxGl);

	const { loadSource: loadVesselGroupSource, loadLayers: loadVesselGroupLayers } =
		useVesselGroup(mapboxGl);

	const {
		loadSource: loadRoutePlanSource,
		loadLayers: loadRoutePlanLayers,
		loadRoutePlan
	} = useRoutePlan(mapboxGl);

	const handleLoadImages = () => {
		for (let imageName of vesselIconNames) {
			loadImage(mapboxGl.current, {
				imageName,
				imagePath: routeCommonVesselIconMap[imageName]
			});
		}
	};

	const handleRangingSelect = (type: RouteCommonRangingControllTabType) => {
		const isCircle = type === "circle";
		switch (true) {
			case !type:
			case rangingControll?.current?.options.greatCircle === isCircle:
				rangingControll.current.options.greatCircle = null;
				rangingControll.current._toggleMeasure?.(false);
				break;
			default:
				rangingControll.current.options.greatCircle = isCircle;
				!rangingControll.current._measuring && rangingControll.current._toggleMeasure?.();
				break;
		}
	};

	const handleControllChange: RouteCommonMapRefType["controllChange"] = (layerIds, isShow) => {
		for (let layerId of layerIds) {
			mapboxGl.current?.setLayoutProperty(layerId, "visibility", isShow ? "visible" : "none");
		}
	};

	const handleControllHidden: RouteCommonMapRefType["onControllHidden"] = (layerIds) => {
		for (let layerId of layerIds) {
			mapboxGl.current?.setLayoutProperty(layerId, "visibility", "none");
		}
	};

	const handleMeteoHidden: RouteCommonMapRefType["onMeteoHidden"] = (items) => {
		for (let item of items) {
			handleMeteoChange(item, RouteCommonToolbarMeteoSwitchMap.Off);
		}
	};

	const handleMeteoChange: RouteCommonMapRefType["meteoChange"] = (key, switchType) => {
		const currentMeteoType = RouteCommonToolbarMeteoMap[key];
		let meteoTypeForControl: "isolines" | "isobands" | "particlesAnim" | "hurricanes" | "discrete" =
				null,
			switchTypeForControl: RouteCommonToolbarMeteoSwitchMap | RouteCommonToolbarMeteoMap =
				switchType === RouteCommonToolbarMeteoSwitchMap.On
					? currentMeteoType
					: RouteCommonToolbarMeteoSwitchMap.Off;
		switch (currentMeteoType) {
			case RouteCommonToolbarMeteoMap.Enc:
				handleEnableHifleetLayer(
					switchType === RouteCommonToolbarMeteoSwitchMap.On ? "visible" : "none"
				);
				return;
			case RouteCommonToolbarMeteoMap.Wave:
			case RouteCommonToolbarMeteoMap.Swell:
				meteoTypeForControl = "isobands";
				// switchTypeForControl =
				// 	switchType === RouteCommonToolbarMeteoSwitchMap.On
				// 		? currentMeteoType
				// 		: RouteCommonToolbarMeteoSwitchMap.Off;
				break;
			case RouteCommonToolbarMeteoMap.Pressure:
				meteoTypeForControl = "isolines";
				// switchTypeForControl =
				// 	switchType === RouteCommonToolbarMeteoSwitchMap.On
				// 		? currentMeteoType
				// 		: RouteCommonToolbarMeteoSwitchMap.Off;
				break;
			case RouteCommonToolbarMeteoMap.Current:
				meteoTypeForControl = "particlesAnim";
				switchTypeForControl = switchType;
				RouteCommonToolbarMeteoSwitchMap.On === switchType &&
					meteoCore.store.set("overlay", "wind");
				break;
			case RouteCommonToolbarMeteoMap.Typhoon:
				if (meteoCore.map.hurricanesLayers) {
					switchType === RouteCommonToolbarMeteoSwitchMap.On
						? meteoCore.map.hurricanesLayers.forEach((item) => item.addTo(meteoCore.map))
						: meteoCore.map.hurricanesLayers.forEach((item) => item.removeFrom(meteoCore.map));
					meteoTypeForControl = null;
				} else {
					meteoTypeForControl = "hurricanes";
					switchTypeForControl = switchType;
				}
				break;
			case RouteCommonToolbarMeteoMap.Wind:
				meteoTypeForControl = "discrete";
				switchTypeForControl = switchType;
				break;
			default:
				break;
		}
		if (meteoTypeForControl) {
			meteoCore.store.set(meteoTypeForControl, switchTypeForControl);
		}
	};

	const handleMeteoTimeChange: RouteCommonMapRefType["meteoTimeChange"] = (value) => {
		meteoCore.store.set("timestamp", value);
	};

	const getMeteoCalendar = () => {
		console.log("getMeteoCalendar", meteoCore.store.get("calendar"));
		return meteoCore.store.get("calendar");
	};

	const handleVesselLoad: RouteCommonVesselLoadEvent = (type, items) => {
		const geoJson: GeoJSON.FeatureCollection<GeoJSON.Point> = {
			features: items?.map((item) => initialVesselGroupData(item)),
			type: "FeatureCollection"
		};
		console.log("geoJson", {
			geoJson,
			items,
			type
		});
		if (!isLoaded) {
			renderQueue.current?.push(() => {
				const currentSource = mapboxGl.current.getSource(vesselGroupSource[type]) as GeoJSONSource;
				currentSource.setData(geoJson);
				if (type === "ais") {
					handleAisInfoLayerLoad(items as unknown as RouteCommonAisItemType[]);
				}
			});
			return;
		}
		console.log("geoJson", {
			geoJson,
			items,
			type
		});
		const currentSource = mapboxGl.current.getSource(vesselGroupSource[type]) as GeoJSONSource;
		currentSource.setData(geoJson);
		if (type === "ais") {
			handleAisInfoLayerLoad(items as unknown as RouteCommonAisItemType[]);
		}
	};

	const handleVesselVisible: RouteCommonMapRefType["onVesselListVisible"] = (type, visibility) => {
		mapboxGl?.current?.setLayoutProperty(vesselGroupLayers[type], "visibility", visibility);
	};

	const handleVesselGroupVisible = (groups: string[]) => {
		// const prev = [...hideGroups];
		// const index = hideGroups?.findIndex((item) => item === groupId);
		// if (index === -1) {
		// 	prev?.push(groupId);
		// } else {
		// 	prev?.splice(index, 1);
		// }
		// setHideGroups([...prev]);
		mapboxGl?.current?.setFilter(vesselGroupLayers.ais, [
			"any",
			...groups?.map((id) => ["in", ["get", "groupId"], id])
		]);
		mapboxGl?.current?.setFilter(aisSourceMap["ais-info-source"], [
			"any",
			...groups?.map((id) => ["in", ["get", "groupId"], id])
		]);
		// mapboxGl.current.setLayoutProperty(vesselGroupLayers.ais, "visibility", [
		// 	"case",
		// 	["==", ["get", "groupId"], ],
		// 	"visible",
		// 	"none"
		// ]);
	};

	const handleVesselTrackLoad: RouteCommonVesselTrackLoadEvent = (type, item, source) => {
		console.log("handleVesselTrackLoad", type, item);
		let direction = true;
		const trackLines: [number, number][] = [],
			trackPoints: GeoJSON.Feature<
				GeoJSON.Point,
				{
					speed: string;
					time: string;
					longitude: number;
					latitude: number;
					direction: number;
					textDirection: number;
				}
			>[] = [];
		let aisLines: GeoJSON.Feature<GeoJSON.MultiLineString | GeoJSON.LineString, {}>[] = [];

		if (item?.route && item?.route?.trackPoints?.length > 0) {
			for (let trackPoint of item?.route?.trackPoints) {
				trackLines.push([trackPoint.lon, trackPoint.lat]);
				trackPoints.push({
					type: "Feature",
					geometry: {
						type: "Point",
						coordinates: [trackPoint.lon, trackPoint.lat]
					},
					// visibility: 'none',
					properties: {
						speed: formatThousandthNumber(trackPoint?.speed),
						time: dayjs(trackPoint?.time)?.format("YYYY-MM-DD HH:mm:ss"),
						longitude: trackPoint?.lon,
						latitude: trackPoint?.lat,
						direction: direction ? -100 : 100,
						textDirection: direction ? -10 : 10
					}
				});
				direction = !direction;
			}
		}

		if (item?.route?.ais && item?.route?.ais?.features?.length > 0) {
			aisLines = item?.route?.ais?.features;
		}

		console.log("trackLines", {
			item,
			trackLines,
			trackPoints,
			activeAisVessel
		});
		const currentTrackSource = mapboxGl.current.getSource(vesselTrackSource[type]) as GeoJSONSource;
		const currentTrackInfoSource = mapboxGl.current.getSource(
			vesselTrackPointerSource[type]
		) as GeoJSONSource;
		const currentVesselDataSource = mapboxGl.current.getSource(
			currentVesselSource[type]
		) as GeoJSONSource;
		// {
		// 	type: "FeatureCollection",
		// 	features: [
		// 		{
		// 			type: "Feature",
		// 			geometry: {
		// 				type: "LineString",
		// 				coordinates: trackLines
		// 			},
		// 			properties: {}
		// 		}
		// 	]
		// }
		currentTrackSource.setData({
			type: "FeatureCollection",
			features: aisLines
		});
		currentTrackInfoSource?.setData({
			type: "FeatureCollection",
			features: trackPoints
		});
		if (item?.current) {
			console.log("fdddd", activeAisVessel, {
				...item?.current,
				color: activeAisVessel?.color ?? "Purple"
			});
			currentVesselDataSource?.setData({
				type: "FeatureCollection",
				features: [
					{
						type: "Feature",
						geometry: {
							type: "Point",
							coordinates: [item?.current?.lon, item?.current?.lat]
						},
						properties: { ...item?.current, color: activeAisVessel?.color ?? "Purple" }
					}
				]
			});
			console.log("source", mapboxGl?.current?.getLayer("ais-currentVessel-layer"));
			// if (type === "ais" && !onIsAisCollectGet?.({ currentState: item?.current })) {
			if (type === "ais" && source === "collect") {
				const bounds = L.geoJson({
					type: "FeatureCollection",
					features: [
						{
							type: "Feature",
							geometry: {
								type: "Point",
								coordinates: [item?.current?.lon, item?.current?.lat]
							}
						}
					]
				}).getBounds();
				meteoCore.map.fitBounds(bounds, {
					maxZoom: 5
				});
				// mapboxGl?.current?.flyTo(
				// 	{
				// 		duration: 300,
				// 		center: [item?.current?.lon, item?.current?.lat]
				// 	},
				// 	{
				// 		moveend: "FLY_END"
				// 	}
				// );
			}
		} else {
			currentVesselDataSource?.setData({
				type: "FeatureCollection",
				features: []
			});
		}
	};

	const handleFlyTo: RouteCommonMapRefType["onFlyTo"] = (item) => {
		const bounds = L.geoJson({
			type: "FeatureCollection",
			features: [
				{
					type: "Feature",
					geometry: {
						type: "Point",
						coordinates: [item?.lon, item?.lat]
					}
				}
			]
		}).getBounds();
		meteoCore.map.fitBounds(bounds, {
			maxZoom: 5
		});
	};

	useEffect(() => {
		console.log("reload");
		delete require.cache[require.resolve("../lib/libBoot")];
		require("../lib/libBoot");
		debugger;
		window.meteoApi(
			{
				verbose: false,
				lat: 32.99,
				lon: 105.78,
				zoom: 3
			},
			(core) => {
				console.log("core", core);
				setMeteoCore(core);
				core.store.set("product", "ecmwf");
				const mapboxMap = L.mapboxGL({
					accessToken:
						"pk.eyJ1IjoibGl0dGxlZml2ZTE5OTUiLCJhIjoiY2w1OWRtdTB3MmJqbTNjcXFpcWE4dDIxdSJ9.3ZH5BCZI085b4B9XVflZpg",
					style: "mapbox://styles/littlefive1995/clpgntuil00h201p97zby73r2",
					padding: 0,
					dragPan: true,
					pane: "mapboxgl-tile-pane",
					paneZindex: 399,
					mouseEvent: true
				}).addTo(core.map);
				leafletLayerGroup?.current?.addTo(core.map);
				discreteLayers?.current?.addTo(core.map);
				const mapboxGlMap = mapboxMap.getMapboxMap();
				mapboxGlMap.on("load", () => {
					// 单独设置symbol-spacing属性
					mapboxGlMap.setLayoutProperty("ports", "icon-padding", 48);
					mapboxGlMap.setLayoutProperty("ports", "text-size", 10);
					mapboxGlMap.on("click", "ports", (e) => {
						const properties = e.features[0].properties;
						const portInfoPopup = L.popup({
							closeButton: true,
							offset: [0, 0],
							autoClose: true,
							closeOnClick: false
						});
						portInfoPopup
							.setContent(
								'<div class="polyline-measure-tooltip leaflet-zoom-animated" tabindex="0" role="button" style="box-shadow: none;margin-left: 4px; margin-top: 4px; width: 12px; height: 12px; z-index: 210; padding: 0;padding-right: 20px;background: none">' +
									'<div style="display: flex; column-gap: 10px"><div class="polyline-measure-tooltip-label" style="color: #C8C8C8;font-size: 12px;font-weight: 400">Position:</div>' +
									'<div class="polyline-measure-tooltip-position" style="color: #fff;font-size: 12px;font-weight: 400">' +
									`${transform2DM(properties.x)}${properties.x >= 0 ? "E" : "W"} ${transform2DM(properties.y)}${properties.y >= 0 ? "S" : "N"}` +
									"</div></div>" +
									'<div style="display: flex; column-gap: 10px"><div class="polyline-measure-tooltip-label" style="color: #C8C8C8;font-size: 12px;font-weight: 400">Country/Area:</div>' +
									"<div>" +
									'<span class="polyline-measure-tooltip-position" style="color: #fff;font-size: 12px;font-weight: 400">' +
									properties.country +
									"</span>" +
									"</div></div>" +
									'<div style="display: flex; column-gap: 10px"><div class="polyline-measure-tooltip-label" style="color: #C8C8C8;font-size: 12px;font-weight: 400">Port Code:</div>' +
									"<div>" +
									'<span class="polyline-measure-tooltip-position" style="color: #fff;font-size: 12px;font-weight: 400">' +
									properties.port_code +
									"</span>" +
									"</div></div>" +
									'<div style="display: flex; column-gap: 10px"><div class="polyline-measure-tooltip-label" style="color: #C8C8C8;font-size: 12px;font-weight: 400">Port Name:</div>' +
									"<div>" +
									'<span class="polyline-measure-tooltip-position" style="color: #fff;font-size: 12px;font-weight: 400">' +
									properties.port_name +
									"</span>" +
									"</div></div>" +
									"</div>"
							)
							.setLatLng(e.lngLat)
							.openOn(leafletLayerGroup?.current);
					});
					handleLoadImages();
					loadTrackSource();
					loadVesselGroupSource();
					loadCurrentVesselSource();
					loadAisInfoSource();
					loadHifleetSource();
					loadRoutePlanSource();
					loadHifleetLayers();
					loadTrackLayers();
					loadVesselGroupLayers();
					loadAisInfoLayers();
					loadCurrentVesselLayers();
					onVesselGroupListen();
					loadDiscreteLayers();
					loadRoutePlanLayers();
					// console.log("images", mapboxGl?.current?());
					// mapboxGl?.current?.addControl(coordinatesControll, "bottom-left");
					mapboxGlMap.on("click", onDiscreteListen);
					setIsLoaded(true);
				});
				L.control
					.mousePosition({
						position: "bottomleft",
						emptyString: "没有经纬度数据",
						lngFirst: true,
						numDigits: 6,
						prefix: "经度:",
						separator: " | ",
						suffix: " 纬度:",
						showDecimal: true,
						showDMS: false,
						wrapLng: true,
						wrapLat: false,
						lngFormatter: function (lng) {
							return lng.toFixed(6);
						},
						latFormatter: function (lat) {
							return lat.toFixed(6);
						},
						formatter: function (lng: number, lat: number) {
							const rTextareaWrap = document.getElementById("routeCommon-details"),
								rect = rTextareaWrap?.getBoundingClientRect();
							// console.log("rTextareaWrap", rect);
							return `<div class="routeCommon-lnglat-control" style="bottom: ${rect.height + 50}px">Lat/Lng: ${convertToDms(lat, lng)}</div>`;
						}
					})
					.addTo(core.map);
				mapboxGl.current = mapboxGlMap;
				core.map.on("zoom", () => {
					const zoom = core.map.getZoom();
					const iconPadding = Math.min(Math.max(0, 48 - (zoom - 2) * 12), 48);
					mapboxGlMap.setLayoutProperty("ports", "icon-padding", iconPadding);
				});
				core.map.createPane("measure");
				core.map.getPane("measure").style.zIndex = 9999;
				core.map.createPane("measure-line");
				core.map.getPane("measure-line").style.zIndex = 9998;
				rangingControll.current = createRangingControll(L, core.map);
				core.store.on("hurricanes", (data) => {
					if (data === "no") {
						reminder("success", "No active typhoon");
					}
				});
			}
		);
	}, []);

	useEffect(() => {
		if (!isLoaded) return;
		while (renderQueue?.current?.length > 0) {
			const callback = renderQueue?.current?.shift();
			callback?.();
		}
	}, [isLoaded]);

	useEffect(() => {
		if (!isLoaded) return;
		onVesselGroupSelectListen();
		return () => {
			onVesselGroupSelectRemove();
		};
	}, [activeLegVessel, activeAisVessel, activeAreaVessel, activePortVessel, isLoaded]);
	return {
		handleControllChange,
		handleMeteoChange,
		getMeteoCalendar,
		handleMeteoTimeChange,
		handleRangingSelect,
		handleVesselLoad,
		handleVesselTrackLoad,
		handlePointControlSelect,
		handleVesselVisible,
		handleVesselGroupVisible,
		handleFlyTo,
		handleControllHidden,
		handleMeteoHidden,
		loadRoutePlan
	};
};

export default useIndex;
