import React, { Fragment, forwardRef, useRef } from "react";
import { VoyageManageVoyageRemarkProps, VoyageManageVoyageRemarkCommonRefType } from "./type";
import { VoyageManageTctcVoyageRemark, VoyageManageTcvcVoyageRemark } from "./components";

const VoyageManageVoyageRemark = forwardRef<
	VoyageManageVoyageRemarkCommonRefType,
	VoyageManageVoyageRemarkProps
>(({ voyageId, type, voyageType }, ref) => {
	return (
		<Fragment>
			{voyageType === "TCTC" ? (
				<VoyageManageTctcVoyageRemark
					type={type}
					voyageId={voyageId}
					voyageType={voyageType}
					ref={ref}
				/>
			) : (
				<VoyageManageTcvcVoyageRemark
					type={type}
					voyageId={voyageId}
					voyageType={voyageType}
					ref={ref}
				/>
			)}
		</Fragment>
	);
});

export default VoyageManageVoyageRemark;
