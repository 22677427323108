import { useRef, useState } from "react";
import {
  CBPortRotationFormType,
  CBPortRotationFormChangeEvent,
  CBPortRotationFormCommitEvent,
  CBPortRotationFormInitEvent,
  PortPurposeType,
  CBPortRotationFormAddEvent,
  CBPortRotationFormDelEvent,
  checkPortRotationFormKeys
} from "@/featrue/voyagemanage/cargoBook/portRotation/type";
import { ExtendsKeys } from "@/types";
import { uuid } from "@/tools";

const initPortRotation = (purpose: ExtendsKeys<PortPurposeType, "DC" | "LD">): CBPortRotationFormType => {
  return {
    purpose: purpose,
    portCode: "",
    portName: "",
    loadingDischargeRate: "",
    lptt: "",
    demRate: "",
    desRate: "",
    cargoQuantity: "",
    cargoUnit: "MT",
    portId: uuid()
  }
}

const usePortRotation = () => {
  const [portRotation, setPortRotation] = useState<CBPortRotationFormType[]>([
    {
      purpose: "LD",
      portCode: "",
      portName: "",
      loadingDischargeRate: "",
      lptt: "",
      demRate: "",
      desRate: "",
      cargoQuantity: "",
      cargoUnit: "MT",
      portId: uuid()
    },
    {
      purpose: "DC",
      portCode: "",
      portName: "",
      loadingDischargeRate: "",
      lptt: "",
      demRate: "",
      desRate: "",
      cargoQuantity: "",
      cargoUnit: "MT",
      portId: uuid()
    },
  ]);

  const delIds = useRef<(string | number)[]>([]);

  const change: CBPortRotationFormChangeEvent = (id, key, value) => {
    setPortRotation(prev => {
      const index = prev.findIndex(item => item.portId === id)
      const portItem = prev[index];

      portItem[key] = value;
      prev.splice(index, 1, portItem);
      return [...prev]
    })
  }

  const commit: CBPortRotationFormCommitEvent = (id, item) => {
    setPortRotation(prev => {
      const index = prev.findIndex(item => item.portId === id)
      const portItem = prev[index];
      prev.splice(index, 1, {
        ...portItem,
        ...item
      });
      return [...prev]
    })
  }

  const add: CBPortRotationFormAddEvent = (purpose,portId) => {
    const portItem = initPortRotation(purpose)
    setPortRotation(prev => {
      const index = prev.findIndex(item => item.portId === portId);
      prev.splice(index + 1,0,portItem);
      return [...prev]
    })
  }

  const del:CBPortRotationFormDelEvent = (id) => {
    setPortRotation(prev => {
      const index = prev.findIndex(item => item.portId === id);
      const item = prev[index];
      item?.id && (delIds.current.push(item?.id))
      prev.splice(index,1);
      return [...prev]
    })
  }

  const clear = () => {
    setPortRotation([initPortRotation("LD"),initPortRotation("DC")])
  }

  const init: CBPortRotationFormInitEvent = (items) => {
    const newItems = items?.map(item => {
      return {...item,portId:uuid()}
    })
    setPortRotation(items?.length === 0 ?[initPortRotation("LD"),initPortRotation("DC")] : newItems)
  }

  const check = () => {
    let checkKey = "";
    let checked = true;
    for(let portItem of portRotation) {
      for(let item of checkPortRotationFormKeys) {
        if(portItem[item] !== 0 && !portItem[item]) {
          checkKey = item;
          checked = false;
          break;
        }
      }

      if(!checked) {
        break;
      }
    }

    return {
      checked,
      checkKey
    }
  }

  return {
    portRotation,
    change,
    check,
    commit,
    add,
    del,
    clear,
    delIds,
    init
  }
}

export default usePortRotation