import { FleetNumType } from "@/types/common";

export type getSelectedPortListParams = {
	portName: string;
	pageNum: number;
	pageSize: number;
};

export type getPortVesselListParams = {
	type: 1 | 2 | 3;
	startDate: string;
	endDate: string;
	portCode: string;
	keyword?: string;
	vesselTypeSub?: FleetNumType;
	aisStatus?: FleetNumType;
	startAreaCode?: FleetNumType;
	lastPortCountry?: string;
	filterStartDate?: string;
	filterEndDate?: string;
};

export type savePortListSearchParams = {
	id?: string;
	userId?: string;
	updateUser?: string;
	vesselTypeSub?: FleetNumType;
	aisStatus?: FleetNumType;
	startAreaCode?: FleetNumType;
	lastPortCountry?: string;
};

export type getCountryCodeListParams = {
	countryName?: string;
	keyword?: string;
	pageNum?: number;
	pageSize?: number;
};

export type getAreaCodeListParams = {
	keyword?: string;
	areaName?: string;
};

export { PortAreaListItemType as downLoadPortVesselParams } from "@/pages/monitoring/components/DetailDrawer/components/portAreaList/components/table/type";
