import React, { forwardRef, useImperativeHandle } from "react";
import style from "./index.module.less";
import { RouteAisListProps, RouteAisListRefType } from "./type";
import classnames from "classnames";
import { FleetLoading } from "@/components";
import useIndex from "./hooks/useIndex";
import { RouteAisFilter, RouteCommonAisGroupItem } from "./components";
import { Icon } from "@/_components";
import { RouteCommonGroupAdd } from "@/pages/routeCommon/common";
import { useRouteCommonContext } from "@/pages/routeCommon/store";

const RouteAisList = forwardRef<RouteAisListRefType, RouteAisListProps>(
	({ activeTab, onSelect, onVesselLoad }, ref) => {
		const {
			expandRows,
			activeAisVessel,
			dataSource,
			totalCount,
			loading,
			hideGroups,
			handleGroupHide,
			handleReset,
			handleGetFollow,
			handleSelect,
			handleGroupEdit,
			handleGroupDelete,
			handleCollection,
			handleDragStart,
			handleDragEnter,
			handleDragEnd,
			handleDragLeave,
			handleDrop,
			handleExpandChange
		} = useIndex(activeTab, onSelect, onVesselLoad);
		const { onVesselGroupHide } = useRouteCommonContext();

		const handleShow = (value: boolean) => {
			if (dataSource?.length === 0) return;
			onVesselGroupHide(
				value ? dataSource?.map((item) => item?.groupId) : [],
				value ? [] : dataSource?.map((item) => item?.groupId)
			);
		};

		useImperativeHandle(ref, () => {
			return {
				onAisListReset: handleReset,
				onGetFollow: handleGetFollow
			};
		});

		return (
			<div
				className={classnames("routeCommon-list-common", style["route-aisList"], {
					[style["route-aisList--active"]]: activeTab === "ais"
				})}
			>
				<RouteAisFilter
					activeItem={activeAisVessel}
					onSelect={onSelect}
					className="routeCommon-list-header"
				/>
				<div className="routeCommon-list-container aisList-wrapper">
					<FleetLoading loading={loading} />
					<span className="aisList-wrapper-title">My collection（{totalCount}）</span>
					<div className="aisList-wrapper-header">
						<RouteCommonGroupAdd
							children={
								<div className="aisList-wrapper-addGroup">
									<Icon type="fleet-add1" className="addGroup-icon" />
									<span>Add Groups</span>
								</div>
							}
							mode="newly"
							onConfirm={handleGroupEdit}
						/>
						<div className="aisList-wrapper-actions">
							{["left", "right"]?.map((item) => {
								const isActive =
									item === "left"
										? hideGroups?.length < dataSource?.length
										: hideGroups?.length === dataSource?.length;
								return (
									<div
										className={classnames(item, {
											active: isActive
										})}
										onClick={() => {
											handleShow(item === "left");
										}}
									>
										<Icon
											size={16}
											type={item === "left" ? "fleet-legend_show" : "fleet-legend_hide"}
											color={isActive ? "#ffffff" : "#757575"}
										/>
									</div>
								);
							})}
						</div>
					</div>
					<div className="aisList-wrapper-container">
						<div
							className="aisList-wrapper-scrollArea"
							onDrop={handleDrop}
							onDragStart={handleDragStart}
							onDragEnter={handleDragEnter}
							onDragEnd={handleDragEnd}
							onDragLeave={handleDragLeave}
							onDragOver={(event) => {
								event?.preventDefault();
							}}
						>
							{dataSource?.map((item, index) => {
								return (
									<RouteCommonAisGroupItem
										key={index}
										item={item}
										activeItem={activeAisVessel}
										onSelect={handleSelect}
										onEdit={handleGroupEdit}
										onDelete={handleGroupDelete}
										onCollection={handleCollection}
										expandRows={expandRows}
										onRowExpand={handleExpandChange}
										hideGroups={hideGroups}
										onGroupHide={handleGroupHide}
									/>
								);
							})}
						</div>
					</div>
				</div>
			</div>
		);
	}
);

export default RouteAisList;
