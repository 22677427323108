import { FC } from "react";
import { RouteCommonRoutePlanProps, RoutePlanItemType } from "./type";
import { cn } from "@/tools";
import React from "react";
import style from "./index.module.less";
import useIndex from "./hooks/useIndex";
import { Button, Icon, Popover, Table } from "@/_components";
import { columns } from "./source";
import { CommonRoleWrap, FleetToast } from "@/common";
import { FleetLoading } from "@/components";
import { RoutePlanDownload, RoutePlanUpload } from "./components";

const RouteCommonRoutePlan: FC<RouteCommonRoutePlanProps> = ({ onRoutePlanLoad }) => {
	const {
		activeLegVessel,
		activeDetailsOption,
		dataSource,
		loading,
		selectRows,
		downloadRef,
		uploadRef,
		onDetailsOptionCommit,
		handleRowSelect,
		deleteRoutePlan,
		getRoutePlan
	} = useIndex(onRoutePlanLoad);
	return (
		<div
			className={cn(
				style["routeCommon-routePlan"],
				"w-full h-full relative hidden gap-[20px] pt-[12px] pr-[12px] pb-[8px] pl-[25px] flex-col",
				{
					flex:
						activeDetailsOption.activeTab === "routePlans" &&
						!activeDetailsOption?.isPortCallVisible &&
						!activeDetailsOption?.activeRoutePlan
				}
			)}
		>
			<FleetLoading loading={loading} />
			<div className="w-full flex items-center justify-between">
				<div className="flex flex-row gap-[20px]">
					<span className="text-[#333] leading-[22px] font-medium text-[16px]">Plan List</span>
					<span className="text-[14px] text-[#48484899] font-medium leading-[22px]">
						Total:{""}
						<span className="text-[#333]">{dataSource?.length}</span>
					</span>
					<span className="text-[14px] text-[#48484899] font-medium leading-[22px]">
						Selected:{""}
						<span className="text-[#333]">{selectRows?.length}</span>
					</span>
				</div>
				<CommonRoleWrap roleStr="monitoring:detail:upload">
					<Button
						size="small"
						type="secondary"
						disabled={!activeLegVessel || !activeLegVessel?.voyageNo}
						onClick={() => {
							uploadRef?.current?.openModal();
						}}
					>
						Upload
					</Button>
				</CommonRoleWrap>
			</div>
			<Table<RoutePlanItemType>
				primaryKey="id"
				dataSource={dataSource}
				pagination={false}
				rowSelection={{
					selectedRowKeys: selectRows?.map((row) => row?.id),
					onChange: handleRowSelect,
					columnWidth: 32
				}}
				columns={[
					...columns,
					{
						title: "Action",
						dataIndex: "action",
						fixed: "right",
						width: 130,
						render: (value, record, index) => {
							return (
								<div className="gap-[16px] flex flex-row items-center">
									<span
										className="text-[#446399] text-[12px] font-normal cursor-pointer leading-[22px]"
										onClick={() => {
											onDetailsOptionCommit?.({ activeRoutePlan: record });
										}}
									>
										Detail
									</span>
									<Icon
										type="fleet-download"
										size={16}
										color="#355691"
										className="cursor-pointer leading-4"
										onClick={() => {
											downloadRef?.current?.openModal(record);
										}}
									/>
									<Popover
										trigger="click"
										children={
											<Icon
												type="fleet-delete"
												size={16}
												color="#F15D5D"
												className="cursor-pointer leading-4"
											/>
										}
										render={(setIsOpen) => {
											return (
												<FleetToast
													label="That file will be deleted. Are you sure?"
													onCancel={() => {
														setIsOpen(false);
													}}
													onConfirm={() => {
														setIsOpen(false);
														deleteRoutePlan(record);
													}}
												/>
											);
										}}
									/>
								</div>
							);
						}
					}
				]}
				className="routePlan-list w-full h-[calc(100%-52px)]"
			/>
			<RoutePlanDownload ref={downloadRef} />
			<RoutePlanUpload ref={uploadRef} onReset={getRoutePlan} />
		</div>
	);
};

export default RouteCommonRoutePlan;
