import { type ApiParameter } from "@/api";
import request from "@/service";

const enum ApiMap {
	getPortDistanceParameter
}

type ApiUrl = keyof typeof ApiMap;

const cargoPortApi: ApiParameter<ApiUrl> = {
	getPortDistanceParameter: {
		method: "get",
		url: "/gateway/distbl/"
	}
};

export type getPortDistanceParams = {
	ports: string;
};

export const getPortDistanceApi = (params: getPortDistanceParams) => {
	return request.request({
		...cargoPortApi.getPortDistanceParameter,
		url: `${cargoPortApi.getPortDistanceParameter.url}?ports=${params?.ports}`
	});
};
