import { CommonInitItemEvent } from "@/types/event";
import {
	AreaListFilterDataType,
	AreaListFilterItemKeys,
	AreaListFilterCommitEvent,
	AreaListFilterChangeEvent
} from "./type";
import { FleetOptionType } from "@/types/advancedType";
import React, { MutableRefObject } from "react";
import { FleetInput, FleetSelect } from "@/components";
import AreaSelect from "./components/areaSelect";
export const initFilterData: CommonInitItemEvent<AreaListFilterDataType> = (
	item: AreaListFilterDataType
) => {
	return {
		id: item?.id ?? null,
		userId: item?.userId ?? null,
		vesselTypeSub: item?.vesselTypeSub ?? null,
		ageMin: item?.ageMin ?? "",
		ageMax: item?.ageMax ?? "",
		status: item?.status ?? null,
		aisStatus: item?.aisStatus ?? null,
		desPortCode: item?.desPortCode ?? null,
		aisSpeedMin: item?.aisSpeedMin ?? "",
		aisSpeedMax: item?.aisSpeedMax ?? "",
		areaCode: item?.areaCode ?? null,
		portCode: item?.portCode ?? null,
		lastPurpose: item?.lastPurpose ?? null,
		startAreaCode: item?.startAreaCode ?? "",
		shipStatus: item?.shipStatus ?? null,
		lastPortCountry: item?.lastPortCountry ?? null,
		desPortName: item?.desPortName ?? null,
		desPortCountry: item?.desPortCountry ?? null,
		areaName: item?.areaName ?? "",
		portName: item?.portName ?? "",
		startAreaName: item?.startAreaName ?? "",
		dwtMin: item?.dwtMin ?? "",
		dwtMax: item?.dwtMax ?? "",
		headingList: item?.headingList ?? null
	};
};

const statusOption: FleetOptionType<
	| "Orderbook"
	| "In Service"
	| "Repair"
	| "Storage"
	| "Laid Up"
	| "Abnormal"
	| "FPSO"
	| "Decommisioned",
	1 | 2 | 3 | 4 | 5 | 6 | 7 | 8
>[] = [
	{ label: "Orderbook", value: 1 },
	{ label: "In Service", value: 2 },
	{ label: "Repair", value: 3 },
	{ label: "Storage", value: 4 },
	{ label: "Laid Up", value: 5 },
	{ label: "Abnormal", value: 6 },
	{ label: "FPSO", value: 7 },
	{ label: "Decommisioned", value: 8 }
];
const aisStatusOption: FleetOptionType<
	"Running" | "Mooring" | "Disable" | "Draft-Limits" | "At Berth" | "Stranded" | "Unknown",
	1 | 2 | 4 | 5 | 6 | 7 | 15
>[] = [
	{ label: "Running", value: 1 },
	{ label: "Mooring", value: 2 },
	{ label: "Disable", value: 4 },
	{ label: "Draft-Limits", value: 5 },
	{ label: "At Berth", value: 6 },
	{ label: "Stranded", value: 7 },
	{ label: "Unknown", value: 15 }
];
export const purposeOption: FleetOptionType<
	"Start" | "Load" | "Bunker" | "Discharge" | "Repair" | "Other",
	1 | 2 | 3 | 4 | 5 | 6
>[] = [
	{ label: "Start", value: 1 },
	{ label: "Load", value: 2 },
	{ label: "Bunker", value: 3 },
	{ label: "Discharge", value: 4 },
	{ label: "Repair", value: 5 },
	{ label: "Other", value: 6 }
];
export const shipStatusOption: FleetOptionType<
	| "Unknown"
	| "(Sea) Laden"
	| "(Sea) Ballast"
	| "(Port) Loading"
	| "(Port) Discharging"
	| "(Port) Waiting Loading"
	| "(Port) Waiting Discharging"
	| "Repair",
	0 | 1 | 2 | 3 | 4 | 5 | 6 | 7
>[] = [
	{ label: "Unknown", value: 0 },
	{ label: "(Sea) Laden", value: 1 },
	{ label: "(Sea) Ballast", value: 2 },
	{ label: "(Port) Loading", value: 3 },
	{ label: "(Port) Discharging", value: 4 },
	{ label: "(Port) Waiting Loading", value: 5 },
	{ label: "(Port) Waiting Discharging", value: 6 },
	{ label: "Repair", value: 7 }
];
export const vesselTypeSubOption: FleetOptionType<
	| "VLOC"
	| "Capesize"
	| "Post-Panamax"
	| "Panamax"
	| "Supramax"
	| "Handymax"
	| "Handysize"
	| "Small Bulk",
	21 | 22 | 23 | 24 | 25 | 26 | 27 | 28
>[] = [
	{ label: "VLOC", value: 21 },
	{ label: "Capesize", value: 22 },
	{ label: "Post-Panamax", value: 23 },
	{ label: "Panamax", value: 24 },
	{ label: "Supramax", value: 25 },
	{ label: "Handymax", value: 26 },
	{ label: "Handysize", value: 27 },
	{ label: "Small Bulk", value: 28 }
];
export const headingTypeOption: FleetOptionType<
	"Northeast" | "Northwest" | "Southeast" | "Southwest",
	"1" | "2" | "3" | "4"
>[] = [
	{ label: "Northeast", value: "1" },
	{ label: "Northwest", value: "2" },
	{ label: "Southeast", value: "3" },
	{ label: "Southwest", value: "4" }
];

export const options: (FleetOptionType<
	| "Last Area Name"
	| "Age Max"
	| "Vessel Type"
	| "AIS Status"
	| "Destination Port"
	| "Destination Country"
	| "Selected Area Name"
	| "Current Port Name"
	| "Last Purpose"
	| "Last Port Country"
	| "AIS Speed Max"
	| "Min DWT"
	| "Max DWT"
	| "Heading",
	AreaListFilterItemKeys
> & {
	render: (
		key: AreaListFilterItemKeys,
		record: AreaListFilterDataType,
		actions: {
			onCommit: AreaListFilterCommitEvent;
			onChange: AreaListFilterChangeEvent;
		},
		ref?: MutableRefObject<HTMLInputElement>
	) => React.ReactNode;
})[] = [
	{
		label: "Last Purpose",
		value: "lastPurpose",
		render: (key, record, actions) => {
			return (
				<FleetSelect
					options={purposeOption}
					value={record?.[key]}
					className="filterHeader-select"
					allowClear
					onChange={(val: number) => {
						actions?.onCommit?.({ lastPurpose: val });
					}}
				/>
			);
		}
	},
	{
		label: "Last Port Country",
		value: "lastPortCountry",
		render: (key, record, actions) => {
			return (
				<AreaSelect
					type="countryName"
					showKey="countryName"
					primaryKey="countryName"
					className="filterHeader-select"
					activeItems={{
						countryName: record?.lastPortCountry
					}}
					onClear={() => {
						actions?.onCommit?.({
							lastPortCountry: null
						});
					}}
					onFinish={(newItem, type) => {
						actions?.onCommit?.({
							lastPortCountry: newItem?.countryName ?? ""
						});
					}}
				/>
			);
		}
	},
	{
		label: "Last Area Name",
		value: "startAreaCode",
		render: (key, record, actions) => {
			return (
				<AreaSelect
					type="areaName"
					showKey="areaName"
					primaryKey="areaCode"
					className="filterHeader-select"
					activeItems={{
						areaName: record?.startAreaName,
						areaCode: record?.startAreaCode
					}}
					onClear={() => {
						actions?.onCommit?.({
							startAreaName: "",
							startAreaCode: null
						});
					}}
					onFinish={(newItem, type) => {
						actions?.onCommit?.({
							startAreaName: newItem?.areaName ?? "",
							startAreaCode: newItem?.areaCode ?? null
						});
					}}
				/>
			);
		}
	},
	{
		label: "Vessel Type",
		value: "vesselTypeSub",
		render: (key, record, actions) => {
			return (
				<FleetSelect
					value={record?.[key]}
					options={vesselTypeSubOption}
					className="filterHeader-select"
					allowClear
					onChange={(val: number) => {
						actions?.onCommit?.({ vesselTypeSub: val });
					}}
				/>
			);
		}
	},
	{
		label: "Selected Area Name",
		value: "areaCode",
		render: (key, record, actions) => {
			return (
				<AreaSelect
					type="areaName"
					showKey="areaName"
					primaryKey="areaCode"
					className="filterHeader-select"
					activeItems={{
						areaName: record?.areaName,
						areaCode: record?.areaCode
					}}
					onClear={() => {
						actions?.onCommit?.({
							areaName: "",
							areaCode: null
						});
					}}
					onFinish={(newItem, type) => {
						actions?.onCommit?.({
							areaName: newItem?.areaName ?? "",
							areaCode: newItem?.areaCode ?? null
						});
					}}
				/>
			);
		}
	},
	// {
	// 	label: "AgeMin",
	// 	value: "ageMin",
	// 	render: (key, record, actions) => {
	// 		return (
	// 			<FleetInput
	// 				value={record?.[key]}
	// 				className="filterHeader-select"
	// 				onChange={(val: number) => {
	// 					actions?.onCommit?.({ ageMin: val });
	// 				}}
	// 			/>
	// 		);
	// 	}
	// },
	{
		label: "Age Max",
		value: "ageMax",
		render: (key, record, actions, ref) => {
			return (
				<FleetInput
					value={undefined}
					inputRef={ref}
					className="filterHeader-select"
					onChange={(val: number) => {
						actions?.onChange?.("ageMax", val);
					}}
				/>
			);
		}
	},
	{
		label: "AIS Speed Max",
		value: "aisSpeedMax",
		render: (key, record, actions, ref) => {
			return (
				<FleetInput
					value={undefined}
					inputRef={ref}
					className="filterHeader-select"
					onChange={(val: number) => {
						actions?.onChange?.("aisSpeedMax", val);
					}}
				/>
			);
		}
	},
	{
		label: "Min DWT",
		value: "dwtMin",
		render: (key, record, actions, ref) => {
			return (
				<FleetInput
					value={undefined}
					inputRef={ref}
					className="filterHeader-select"
					onChange={(val: number) => {
						actions?.onChange?.("dwtMin", val);
					}}
				/>
			);
		}
	},
	{
		label: "Max DWT",
		value: "dwtMax",
		render: (key, record, actions, ref) => {
			return (
				<FleetInput
					value={undefined}
					inputRef={ref}
					className="filterHeader-select"
					onChange={(val: number) => {
						actions?.onChange?.("dwtMax", val);
					}}
				/>
			);
		}
	},

	// {
	// 	label: "Status",
	// 	value: "status",
	// 	render: (key, record, actions) => {
	// 		return (
	// 			<FleetSelect
	// 				value={record?.[key]}
	// 				options={statusOption}
	// 				allowClear
	// 				className="filterHeader-select"
	// 				onChange={(val: number) => {
	// 					actions?.onCommit?.({ status: val });
	// 				}}
	// 			/>
	// 		);
	// 	}
	// },
	{
		label: "Destination Port",
		value: "desPortCode",
		render: (key, record, actions) => {
			return (
				<AreaSelect
					type="portName"
					showKey="portName"
					primaryKey="portCode"
					className="filterHeader-select"
					activeItems={{
						portName: record?.desPortName,
						portCode: record?.desPortCode
					}}
					onClear={() => {
						actions?.onCommit?.({
							desPortName: "",
							desPortCode: null
						});
					}}
					onFinish={(newItem, type) => {
						actions?.onCommit?.({
							desPortName: newItem?.portName ?? "",
							desPortCode: newItem?.portCode ?? null
						});
					}}
				/>
			);
		}
	},
	{
		label: "Destination Country",
		value: "desPortCountry",
		render: (key, record, actions) => {
			return (
				<AreaSelect
					type="countryName"
					showKey="countryName"
					primaryKey="countryName"
					className="filterHeader-select"
					activeItems={{
						countryName: record?.desPortCountry
					}}
					onClear={() => {
						actions?.onCommit?.({
							desPortCountry: null
						});
					}}
					onFinish={(newItem, type) => {
						actions?.onCommit?.({
							desPortCountry: newItem?.countryName ?? ""
						});
					}}
				/>
			);
		}
	},
	{
		label: "Heading",
		value: "headingList",
		render: (key, record, actions) => {
			return (
				<FleetSelect
					options={headingTypeOption}
					value={record?.["headingList"]?.split(",")}
					className="filterHeader-select"
					allowClear
					mode={"multiple"}
					onChange={(val: string) => {
						actions?.onCommit?.({ headingList: val.length === 0 ? null : val.join(",") });
					}}
				/>
			);
		}
	}
	// {
	// 	label: "Ship Status",
	// 	value: "shipStatus",
	// 	render: (key, record, actions) => {
	// 		return (
	// 			<FleetSelect
	// 				options={shipStatusOption}
	// 				value={record?.[key]}
	// 				className="filterHeader-select"
	// 				allowClear
	// 				onChange={(val: number) => {
	// 					actions?.onCommit?.({ shipStatus: val });
	// 				}}
	// 			/>
	// 		);
	// 	}
	// }
];
