import React from "react";
import style from "./index.module.less";
import classnames from "classnames";
import { InvStatementDescProps } from "./type";
import {
  InvStatementHire,
  InvStatementBunkerPrice,
  InvStatementFixedExpenses
} from "./components";
import { InvCommonOtherExpenses, InvRemitAmount, InvoicesViewsCommonHeader } from "../../../../common";

const InvStatementDesc: React.FC<InvStatementDescProps> = ({
	item,
	summary,
	otherItem,
	onOtherItemChange,
	hireItem,
	onHireCommit,
	onOffHireItemCommit,
	onOffHireItemAdd,
	onOffHireItemDelete,
	bunkerPriceItem,
	onBunkerPriceCommit,
	fixedExpensesItem,
	onFixedExpensesChange,
	onFixedExpensesCommit,
	otherExpenses,
	onOtherExpensesChange,
	onOtherExpensesFieldOpen,
	onOtherExpensesDelete
}) => {
	return (
		<div className={classnames("invoicesDetail-item", style["statement-desciption"])}>
			<InvoicesViewsCommonHeader
				type="desc"
				title="Description"
				item={{
					voyageNo: item?.voyageNo
				}}
			/>
			<InvStatementHire
				item={hireItem}
				onCommit={onHireCommit}
				onOffHireItemAdd={onOffHireItemAdd}
				onOffHireItemCommit={onOffHireItemCommit}
				onOffHireItemDelete={onOffHireItemDelete}
				otherItem={{
					addHire: otherItem?.addHire
				}}
				onOtherItemChange={onOtherItemChange}
				fixedExpenses={fixedExpensesItem}
				onFixedExpensesChange={onFixedExpensesChange}
			/>
			<InvStatementBunkerPrice
				item={bunkerPriceItem}
				onCommit={onBunkerPriceCommit}
				otherItem={{
					addBunker: otherItem?.addBunker
				}}
				onOtherItemChange={onOtherItemChange}
			/>
			<InvStatementFixedExpenses
				item={fixedExpensesItem}
				onChange={onFixedExpensesChange}
				onCommit={onFixedExpensesCommit}
				otherItem={{
					addFixed: otherItem?.addFixed,
					ttlOnHireDay: hireItem?.ttlOnHireDay
				}}
				onOtherItemChange={onOtherItemChange}
			/>
			<InvCommonOtherExpenses
				items={otherExpenses}
				onChange={onOtherExpensesChange}
				onFieldOpen={onOtherExpensesFieldOpen}
				onDelete={onOtherExpensesDelete}
				otherItem={{
					addOther: otherItem?.addOther
				}}
				onOtherItemChange={onOtherItemChange}
			/>
			<InvRemitAmount remitAmount={summary?.plus - summary?.less} />
		</div>
	);
};

export default InvStatementDesc