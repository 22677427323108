import { CommonInitItemEvent } from "@/types/event";
import { MovementPlanItemType } from "./type";

export const initialPlanItem: CommonInitItemEvent<MovementPlanItemType> = (item) => {
  return {
    id: item?.id ?? null,
    estResultId: item?.estResultId ?? null,
    vmId: item?.vmId ?? null,
    main: item?.main ?? false,
    planName: item?.planName ?? ""
  }
}