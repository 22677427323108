import React, { FC } from "react";
import style from "./index.module.less";
import { CommonVesselPopupProps } from "./type";
import { FleetLoading } from "@/components";
import { formatThousandthNumber } from "@/tools/amount";
import { ArrivalVesselTypeMap } from "../../source";

const CommonVesselPopup: FC<CommonVesselPopupProps> = ({ list, loading, onSelect, loadMore }) => {
	const handleSelect = (
		e: React.MouseEvent<HTMLUListElement, MouseEvent>,
		item: CommonVesselPopupProps["list"][number]
	) => {
		e?.stopPropagation();
		onSelect?.(item);
	};
	return (
		<div className={style["common-vesselSelect--Popup"]}>
			<FleetLoading loading={loading} />
			<ul className="vesselSelect--popup--header">
				<span>Vessel Name</span>
				<span>Type</span>
				<span>IMO</span>
				<span>DWT</span>
				<span>MMSI</span>
			</ul>
			<div className="vesselSelect--popup--container fleet-nonScrolBar">
				<div className="vessel--popup--scrollArea">
					{list?.map((item, index) => {
						return (
							<ul
								key={index}
								className="vessel--popup--item"
								onClick={(e) => {
									handleSelect?.(e, item);
								}}
							>
								<li>{item?.vesselName}</li>
								<li>{ArrivalVesselTypeMap[item?.vesselType]}</li>
								<li>{item?.imo}</li>
								<li>{formatThousandthNumber(item?.dwt, 0, 0)}</li>
								<li>{item?.mmsi}</li>
							</ul>
						);
					})}
				</div>
			</div>
			<div className="vesselSelect--popup--loadMore" onClick={loadMore}>
				<span>Load More</span>
				<span className="font_family fleet-back"></span>
			</div>
		</div>
	);
};

export default CommonVesselPopup;
