import { FleetOptionType } from "@/types/advancedType";
import { MovementTaskItemType } from "./type";
import { CommonInitItemEvent } from "@/types/event";

export const levelOptions: FleetOptionType<
  "M" | "H" | "L",
  MovementTaskItemType["priority"]
>[] = [
    { label: "L", value: 1 },
    { label: "M", value: 2 },
    { label: "H", value: 3 },
  ];

export enum LevelMapEnum {
  low = 1,
  medium,
  high
}

export const initialMovementTaskItem: CommonInitItemEvent<MovementTaskItemType> = (item) => {
  return {
    id: item?.id ?? null,
    stage: item?.stage ?? "Delivery",
    title: item?.title ?? "",
    contents: item?.contents ?? "",
    deadLine: item?.deadLine ?? null,
    voyageId: item?.voyageId ?? null,
    status: item?.status ?? 0,
    portRotationId: item?.portRotationId ?? null,
    priority: item?.priority ?? 1,
    alarmHours: item?.alarmHours ?? "",
    dialog: item?.dialog ?? null,
    sortNum: item?.sortNum ?? "",
    isWarning: item?.isWarning ?? 0,
    updateUSer: item?.updateUSer ?? null,
    updateUSerName: item?.updateUSerName ?? "",
    totalCount: item?.totalCount ?? 0
  }
}