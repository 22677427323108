import React from "react";
import { HfHireAndFeesFormProps, HfHireAndFeesFormChangeEvent, HfHireAndFeesFormCommitEvent } from "./type";
import { FleetFormItem, FleetInput, FleetVerifyInput } from "@/components";
import { HeadFixtueLabelMap } from "@/types/voyageManage/headFixtureList";
import NoSelect from "@/common/NoSelect";

const HfHireAndFeesForm: React.FC<HfHireAndFeesFormProps> = ({
  item,
  onChange,
  onCommit
}) => {
  return <div className="headFixture-form">
    <span className="vm-common-title">Hire & Fees</span>
    <div className="form-container">
      <FleetVerifyInput
        label={HeadFixtueLabelMap["dailyHire"]}
        needVerity
        type="number"
        value={item?.dailyHire}
        onChange={(val: number) => {
          onChange?.("dailyHire", val)
        }}
      />
      <FleetVerifyInput
        label={HeadFixtueLabelMap["commission"]}
        type="number"
        value={item?.commission}
        onChange={(val: number) => {
          onChange?.("commission", val)
        }}
      />
      <FleetFormItem
        label={HeadFixtueLabelMap["brokerName"]}
      >
        <NoSelect
          initItem={[{
            value: (item?.brokerId) as string,
            label: item?.brokerName
          }]}
          label="companyName"
          type="company"
          onFinish={(noItem, newNoItem, type) => {
            console.log("noItem",noItem)
            onCommit?.({
              brokerId: noItem?.value,
              brokerName: noItem?.label
            })
          }}
        />
      </FleetFormItem>

      <FleetVerifyInput
        label={HeadFixtueLabelMap["brokerage"]}
        type="number"
        value={item?.brokerage}
        onChange={(val: number) => {
          onChange?.("brokerage", val)
        }}
      />
      <FleetVerifyInput
        label={HeadFixtueLabelMap["ilohc"]}
        type="number"
        value={item?.ilohc}
        onChange={(val: number) => {
          onChange?.("ilohc", val)
        }}
      />
      <FleetVerifyInput
        label={HeadFixtueLabelMap["cve"]}
        type="number"
        value={item?.cve}
        onChange={(val: number) => {
          onChange?.("cve", val)
        }}
      />
    </div>
  </div>
}

export default HfHireAndFeesForm;