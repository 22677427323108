import React, { useState, useRef } from "react";
import classnames from "classnames";
import { Button, Row, Col } from 'antd';
import type { ButtonProps } from 'antd/es/button';
import { BoxPlotOutlined } from '@ant-design/icons'
import style from './index.module.less'

export type LegendControlProps = {
  className?: string,
  visible: boolean,
  onChange: (visible: boolean) => void
}

interface Position {
  x: number,
  y: number
}

const LegendControl: React.FC<LegendControlProps> = ({ className, visible, onChange }) => {

  const windPositions = useRef<Position[]>([]).current
  const waves = useRef([
    { color: 'rgb(148, 231, 238)', label: '2' },
    { color: 'rgb(127, 255, 226)', label: '3' },
    { color: 'rgb(165, 255, 174)', label: '4' },
    { color: 'rgb(206, 255, 133)', label: '5' },
    { color: 'rgb(255, 255, 127)', label: '6' },
    { color: 'rgb(255, 227, 127)', label: '7' },
    { color: 'rgb(255, 183, 127)', label: '8' },
    { color: 'rgb(255, 127, 127)', label: '9' },
    { color: 'rgb(252, 127, 172)', label: '10' },
    { color: 'rgb(212, 127, 212)', label: '11' },
    { color: 'rgb(253, 130, 232)', label: '>11' },
  ]).current
  const typhoons = useRef([
    { label: '0 ~32' },
    { label: '32 ~43' },
    { label: '43 ~50' },
    { label: '50 ~58' },
    { label: '58 ~70' },
    { label: '> 70' },
  ]).current

  const intWindPositions = () => {
    windPositions.length = 0
    const size = 33
    for (let i = 0; i < 4; i++) {
      const col = i === 3 ? 3 : 6
      for (let j = 0; j < col; j++) {
        windPositions.push({ x: -1 * j * size, y: -1 * i * size })
      }
    }
  }

  const ms2mts = (value: number): number => {
    return value * 1.943844
  }

  const content = () => {
    intWindPositions()
    const size: number = 9
    const rows: number = Math.ceil(windPositions.length / size)
    return <div style={{ width: 280, zoom: 0.8 }}>
      <Row className={style.legendTableHeader} gutter={[24, 24]}>
        {/*<Col span={12}>Wind</Col>*/}
        <Col span={7}>Wave/Swell</Col>
        <Col span={6}>Current</Col>
        <Col span={11}>Typhoon</Col>
      </Row>
      <Row gutter={[24, 24]} justify="center" style={{ 'flex-wrap': 'nowrap' }}>
        {/*{Array(rows).fill('').map((_, row: number) => {*/}
        {/*  const colLength = row === rows - 1 ? windPositions.length % size : size*/}
        {/*  return <Col span={4} key={row}>*/}
        {/*    {*/}
        {/*      Array(colLength).fill('').map((_, col: number) => {*/}
        {/*        const position: Position = windPositions[row * size + col]*/}
        {/*        return <Row align="middle" key={col}>*/}
        {/*          <Col span={10}>*/}
        {/*            <span className={style.windIcon} style={{ backgroundPositionX: position.x, backgroundPositionY: position.y }}></span>*/}
        {/*          </Col>*/}
        {/*          <Col span={14}>{`${ms2mts((row * size + col) * 2.5).toFixed(0)}~${(ms2mts((row * size + col + 1) * 2.5) - 1).toFixed(0)}`}(kts)</Col>*/}
        {/*        </Row>*/}
        {/*      })*/}
        {/*    }*/}
        {/*  </Col >*/}
        {/*})*/}
        {/*}*/}
        <Col style={{ display: 'flex', 'flex-direction': 'column', 'justify-content': 'space-between' }} span={7}>
          {waves.map((t: any) => <Row align="middle">
            <Col span={10} >
              <span className={style.tag} style={{ backgroundColor: t.color }} />
            </Col>
            <Col style={{ paddingLeft: '12px' }} span={14}>{t.label}</Col>
          </Row>
          )}
        </Col>
        <Col className={style.leftBorder} span={6}>
          <div className={style.linear}>
            <span style={{ height: '14.285714285714286%' }}>kt</span>
            <span style={{ height: '14.285714285714286%' }}>0</span>
            <span style={{ height: '14.285714285714286%' }}>0.4</span>
            <span style={{ height: '14.285714285714286%' }}>0.8</span>
            <span style={{ height: '14.285714285714286%' }}>1.6</span>
            <span style={{ height: '14.285714285714286%' }}>2.0</span>
            <span style={{ height: '14.285714285714286%' }}>3.2</span>
          </div>
        </Col>
        <Col className={style.leftBorder} span={11}>
          <Row align="middle"><Col className={style.typhoonSubTitle} span={24}>Wind Speed(kts)</Col></Row>
          {typhoons.map((item, index) => {
            const logo = index === 0 ? <div className={style.dot} /> : <img src={require(`static/image/cat${index}.png`)} />
            return <Row align="middle">
              <Col span={7}>{logo}</Col>
              <Col span={17}>{item.label}</Col>
            </Row>
          }
          )}
          <Row align="middle">
            <Col className={style.typhoonSubTitle} span={24}>Type</Col>
            <Col span={8}><span className={style.tag} style={{ backgroundColor: 'red' }} /></Col>
            <Col span={16}>Forcast</Col>
            <Col span={8}><span className={style.tag} style={{ backgroundColor: 'white' }} /></Col>
            <Col span={16}>Realtime</Col>
            <Col span={8}><span className={style.tag} style={{ backgroundColor: 'gray' }} /></Col>
            <Col span={16}>History</Col>
          </Row>
        </Col>
      </Row >
    </div>
  }
  const buttonProps: ButtonProps = {
    className: style.button,
    icon: <BoxPlotOutlined />
  }
  if (visible) {
    buttonProps.type = 'primary'
  }
  return <div className={classnames([style.legendControl, style[className]])}>
    <Button onClick={() => onChange(!visible)} {...buttonProps}>Legend</Button>
  </div >
}

export default LegendControl
