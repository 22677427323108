import React, { useEffect, useRef, useState } from "react";
import style from "./index.module.less";
import classnames from "classnames";
import { MovementCommonTableProps } from "./type";
import { formatThousandthNumber } from "@/tools/amount";
import { isNumber } from "lodash";

const MovementTable: React.FC<MovementCommonTableProps> = ({
	sortOption,
	columns,
	dataSource,
	otherSource,
	checkbox,
	className,
	bordered,
	actions,
	summary
}) => {
	const columnZindex = useRef<{
		[dataIndex: string]: {
			fixedWidth: number;
			zIndex: number;
		};
	}>({});

	const tableRef = useRef<HTMLTableElement>(null);
	const containerRef = useRef<HTMLDivElement>(null);

	const currentFixedRef = useRef<{
		left: number;
		right: number;
	}>({ left: 0, right: 0 });

	const [resetWidth, setResetWidth] = useState(0);
	const initialTableWidth = useRef(0);

	const resize: React.ReactEventHandler<HTMLDivElement> = (event) => {
		console.log("event", event);
	};

	// 创建 ResizeObserver 实例
	const resizeObserver = new ResizeObserver((entries) => {
		const currentObserverTarget = entries?.find(
			(entrie) => entrie?.target === containerRef?.current
		);
		if (!currentObserverTarget) return;
		console.log("entries", entries);
		const { width, height } = currentObserverTarget.contentRect;
		console.log("containerRef", `宽度: ${width}px, 高度: ${height}px`);
		if (!!width && !!height) {
			const tableClientWidth = tableRef?.current?.clientWidth,
				containerClientWidth = containerRef?.current?.clientWidth;
			console.log("tableClientWidth", {
				initialTableWidth: initialTableWidth.current,
				tableClientWidth: tableClientWidth,
				columnTotal: columns?.reduce((prev, curr) => prev + curr?.width, 0),
				containerClientWidth: containerClientWidth,
				resetWidth: resetWidth,
				width: width,
				rest_width: width - (initialTableWidth.current || tableClientWidth)
			});
			if (initialTableWidth.current >= containerClientWidth && resetWidth === 0) return;
			if (initialTableWidth.current >= containerClientWidth && resetWidth !== 0) {
				setResetWidth(0);
				return;
			}
			const rest_width = width - (initialTableWidth.current || tableClientWidth);
			setResetWidth(rest_width / columns?.length > 0 ? rest_width / columns?.length : 0);
			!initialTableWidth.current && (initialTableWidth.current = tableClientWidth);
		}
	});

	useEffect(() => {
		const leftColumns = columns?.filter((column) => column?.fixed === "left"),
			rightColumns = columns?.filter((column) => column?.fixed === "right");
		leftColumns?.forEach((item, index) => {
			columnZindex.current[item?.dataIndex] = {
				fixedWidth: currentFixedRef.current.left,
				zIndex: columns?.length - index
			};
			currentFixedRef.current.left = currentFixedRef.current.left + item?.width;
		});
		rightColumns?.reverse()?.forEach((item, index) => {
			columnZindex.current[item?.dataIndex] = {
				fixedWidth: currentFixedRef.current.right,
				zIndex: columns?.length - index
			};
			currentFixedRef.current.right = currentFixedRef.current.right + item?.width;
		});
		resizeObserver.observe(containerRef?.current);
		return () => {
			containerRef?.current && resizeObserver?.unobserve(containerRef?.current);
		};
	}, []);

	return (
		<div
			className={classnames(
				style["movement-table"],
				{
					[style["movement-table--bordered"]]: bordered,
					[style["movement-table--summary"]]: summary
				},
				className
			)}
			ref={containerRef}
		>
			<table className="table-layout" ref={tableRef}>
				<colgroup>
					{columns?.map((item, idx) => {
						return (
							<col
								style={{
									width: item?.width + resetWidth
								}}
								key={idx}
							/>
						);
					})}
				</colgroup>
				<thead className="table-thead">
					<tr>
						{columns?.map((item, idx) => {
							return (
								<th
									key={idx}
									className={classnames({
										"need-verity": item?.needVerity,
										sorted: item?.sorted
									})}
									style={{
										[item?.fixed]: columnZindex?.current?.[item?.dataIndex]?.fixedWidth,
										zIndex: (columnZindex?.current?.[item?.dataIndex]?.zIndex || 0) + 1
									}}
								>
									{item?.sorted ? (
										<div className="sorted-container">
											<span>{item?.label}</span>
											<svg
												className="font_family"
												aria-hidden="true"
												onClick={() =>
													actions?.onSort?.({
														value: item?.dataIndex,
														type: "desc"
													})
												}
											>
												<use
													xlinkHref={classnames({
														"#fleet-sorter": sortOption?.value !== item?.dataIndex,
														"#fleet-sorter-up":
															sortOption?.value === item?.dataIndex && sortOption?.type === "asc",
														"#fleet-sorter-down":
															sortOption?.value === item?.dataIndex && sortOption?.type === "desc"
													})}
												></use>
											</svg>
											{/* <span
												className={classnames("font_family", {
													"fleet-sorter": !sortOption?.value || !sortOption?.type,
													"fleet-sorter-up": sortOption?.value && sortOption?.type === "asc",
													"fleet-sorter-down": sortOption?.value && sortOption?.type === "desc"
												})}
												onClick={() =>
													actions?.onSort?.({
														value: item?.dataIndex,
														type: null
													})
												}
											></span> */}
										</div>
									) : (
										<span>{item?.label}</span>
									)}
									{/* {item?.renderCheckbox ? item?.renderCheckbox(checkbox?.length !== 0 && checkbox?.length - 1 === dataSource?.length - 1, null, actions, true) : item?.label} */}
								</th>
							);
						})}
					</tr>
				</thead>
				<tbody className="table-tbody">
					{dataSource?.map((item, idx) => {
						return (
							<tr key={idx}>
								{columns?.map((colItem, colIndex) => {
									return (
										<td
											key={colIndex}
											className={colItem?.className}
											style={{
												position: colItem?.fixed ? "sticky" : "relative",
												[colItem?.fixed]: columnZindex?.current?.[colItem?.dataIndex]?.fixedWidth,
												zIndex: columnZindex?.current?.[colItem?.dataIndex]?.zIndex
											}}
										>
											{colItem?.render
												? colItem?.render(
														item?.[colItem?.dataIndex],
														item,
														idx,
														actions,
														otherSource
													)
												: item?.[colItem?.dataIndex] ?? "-"}
											{/* {colItem?.renderCheckbox ? colItem?.renderCheckbox(checkbox?.includes(item?.id), item, actions) : (colItem?.render ? colItem?.render(item?.[colItem?.dataIndex], item, idx, actions, dataSource?.[idx - 1]) : (item?.[colItem?.dataIndex] ?? "-"))} */}
										</td>
									);
								})}
							</tr>
						);
					})}
					{summary && <tr className="tbody-blank"></tr>}
				</tbody>
				{summary && (
					<tfoot
						className="table-tfoot"
						style={{
							zIndex: dataSource?.length + 1
						}}
					>
						<tr className="summary">
							{columns?.map((column, idx) => {
								return (
									<td
										key={idx}
										style={{
											position: "sticky",
											textAlign: "left",
											bottom: "0",
											[column?.fixed]: columnZindex?.current?.[column?.dataIndex]?.fixedWidth,
											zIndex: columnZindex?.current?.[column?.dataIndex]?.zIndex + 1
										}}
									>
										{isNumber(summary?.[column?.dataIndex])
											? formatThousandthNumber(summary?.[column?.dataIndex] as number, 3, 3)
											: summary?.[column?.dataIndex]}
									</td>
								);
							})}
						</tr>
					</tfoot>
				)}
			</table>
		</div>
	);
};

export default MovementTable;
