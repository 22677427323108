import { IconKeys } from "@/components/Icon/icons";

export enum LabelMap {
  ownerName = "Owner Name",
  vesselName = "Vessel Name",
  delievryPort = "Delievry Port",
  dailyHire = "Daily Hire",
  chartererName = "Charterer Name",
  cargoName = "Cargo Name",
  cargoQuantity = "Cargo Quantity",
  cargoUnit = "Cargo Unit",
  layCanFrom = "Cargo Laycan From",
  layCanTo = "Cargo Laycan To",
  loadPort = "Load Port",
  dischargePort = "Discharge Port",
  freightType = "Freight Type",
  freightRate = "Freight Rate",
  commission = "Add.Comm.",
  brokerage = "Brokerage",
  loadRate = "Load Rate",
  dischargeRate = "Discharge Rate",
  tT = "TT",
  revenue = "Revenue",
  totalCost = "Total Cost",
  bunkerCost = "Bunker Cost",
  hireCost = "Hire Cost",
  profitAndLoss = "Profit & Loss",
  status = "Status",
  passTo = "Pass To",
  saveasDraft = "Save as Draft",
  option = "Option",
  loadPortExpense = "Port Expenses",
  disPortExpenses = "Port Expenses",
  dischargeWorkDays = "Port days",
  loadPortDays = "Port days",
  grain = "Grain",
  bale = "Bale",
  dwt = "DWT",
  speed = "Speed",
  seaFuel = "Sea Fuel",
  portFuel = "Port Fuel",
  bunkerPriceIfo = "IFO Price",
  bunkerPriceMdo = "MDO Price",
  otherExpenses = "Other Expenses",
  mollo = "Mollo",
  ballastSpeed = "Ballast Speed",
  ladenSpeed = "Laden Speed",
  seaFuelBallast = "Ballast Fuel",
  seaFuelLaden = "Laden Fuel",
  seaFuelMdo = "MDO",
  portFuelWork = "Port Work",
  portFuelIdle = "Port Idle",
  portFuelMdo = "MDO",
  ttlDistanceBallast = "Ballast ttlDis",
  ttlDistanceLaden = "Laden ttlDis",
  ttlDistanceBallastEca = "Ballast eca ttlDis",
  ttlDistanceLadenEca = "Laden eca ttlDis",

  ttlDistance = "Total Distance",
  seaDays = "Sea Days",
  ecaDays = "Inside ECA Days",
  totalIfo = "Total IFO Con.",
  portDays = "Port Days",
  portCost = "Port Cost",
  revenues = "Revenues",
  operationCost = "Operation Cost",
  profit = "Profit",

  ifo = "IFO",
  mdo = "MDO",
  ballastIfo = "Ballast IFO",
  ladenIfo = "Laden IFO",
  ballast = "Ballast",
  laden = "Laden",
  ballastDays = "Ballast Days",
  ladenDays = "Laden Days",
  tce = "TCE",

  totalBallastPortDays = "DP TTL Port Day",
  totalLadenPortDays = "LP TTL Port Day",
  ladenPortCost = "LP Cost",
  ballastPortCost = "DP Cost",

  portName = "Port Name",
  portWorkDay = "Work Day",
  portIdleDay = "Idle Days",
  portExpenses = "Port Expenses",
  totalPortDays = "Total Port Days",
  tpc = "TPC",
  tpi = "TPI",
  sswDraft = "SSW Draft",
  stowageFactor = "Stowage Factor",
  constant = "Constant",
  ballastWater = "Ballast Water",
  freshWater = "Fresh Water",
  brobFo = "BROB FO",
  brobDo = "BROB DO",
  others = "Others",
  tropical = "Tropical",
  seaFo = "Sea FO",
  seaDo = "Sea DO",
  portFO = "Port FO",
  portDO = "Port DO",
  bunkerFO = "Bunker FO",
  bunkerDO = "Bunker DO",
  density = "Density",
  draftLimitation = "Draft Limitation",
  adjustedDwt = "Adjusted DWT",
  totalRevenues = "Total Revenues",
  ifoExpense = "IFO Expense",
  mdoExpense = "MDO Expense",
  TotalSeaDays = "Total Sea Days",
  ifoConsumption = "IFO Consumption",
  mdoConsumption = "MDO Consumption",
  freightAddCom = "Freight Add Com.",
  totalHireCost = "Total Hire Cost",
  revenuesBrokerage = "Brokerage",
  totalHireCostBrokerage = "Brokerage",
  freightIncome = "Freight Income",
  ifoCost = "IFO Cost",
  modCost = "MDO Cost",
  ladenPortWorkDays = "Laden Port Work Days",
  ladenPortIdleDays = "Laden Port Idle Days",
  loadPortCode = "loadPortCode",
  loadPortName = "loadPortName",
  loadPurpose = "loadPurpose",
  dischargePortCode = "dischargePortCode",
  dischargePortName = "dischargePortName",
  dischargePurpose = "dischargePurpose",
  deliveryPortCode = "deliveryPortCode",
  deliveryPortName = "deliveryPortName",
  deliveryPortId = "deliveryPortId",
  mmsi = "mmsi",
  id = "ID",
  cargoQuantityDiscrepancy = "cargoQuantityDiscrepancy",
  marginValue = "Margin Value",
  ecaDistanceAdjustFactor = "ecaDistanceAdjustFactor",
  vesselNameCn = "vesselNameCn",
  vesselNameEn = "vesselNameEn",
  totalRevenue = "totalRevenue",

  customType = "customType",
  originId = "originId",

  imo = "IMO",
  portCode = "Port Code",
  loadingDischargeRate = "loadingDischargeRate",
  workDays = "Work Days",
  idleDays = "Idle Days",
  portExpense = "Port Expenses",
  totalDays = "Total Port Days",
  distance = "Distance",
  ecaDistance = "Inside ECA Dis.",
  area = "Area",
  purpose = "Purpose",
  timeZone = "TimeZone",
  vesselTypeLv1 = "Vessel TypeLv1",
  owner = "OwnerName",
  tonnageType = "Tonnage Type",
  tonnageValue = "Tonnage Value"
}

export type LabelKeys = keyof typeof LabelMap;

export type ExtendsKeys<Label, K extends Label> = Extract<Label, K>;

export type ExcludeKeys<Label, K extends Label> = Exclude<Label, K>;

export type FleetEnumMapType = {
  cargoUnit: "MT" | "CBM";
  freightType: "FR" | "LP";
  portPurpose: "DC" | "DB" | "DT" | "RD" | "DL" | "LD" | "LB" | "LT";
  loadPurpose: ExtendsKeys<FleetEnumMapType["portPurpose"], "LB" | "LD" | "LT">;
  dischargePurpose: ExtendsKeys<FleetEnumMapType["portPurpose"], "DC" | "DB" | "DT">;
}

export enum FleetPortPurposeEnum {
  DL = "delivery",
  RD = "redelivery",
  LD = "load",
  LB = "load bunker",
  LT = "load transit",
  DC = "discharge",
  DB = "discharge bunker",
  DT = "discharge transit"
}

export type FleetFormCommitEvent<T> = (item: Partial<T>) => void;


export type GroupOptionItemType<T> = {
  value: T;
  label: string;
  icon?:IconKeys;
  disabled?: boolean;
}

export type GroupOptionsType<T, U> = {
  key: T;
  defaultValue?: string[];
  basicDisabled?: boolean;
  list: GroupOptionItemType<U>[]
}

export type ChangeEvent = <K extends keyof F, F>(key: K, value: F[K]) => void