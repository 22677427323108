import { ApiParameter } from "@/api";
import { appVersion } from "@/api/constant";
import request from "@/service";
import {
	getAreaInfoListParams,
	updateAreaSelectListParams,
	saveShipArgumentParams,
	getPortCodeListParams,
	getCountryListParams,
	getShipTableListParams,
	downLoadTableListApiParams
} from "./type";

const enum ApiMap {
	getAreaInfoListParameter,
	getAreaInfoSelectListParameter,
	updateAreaSelectListParameter,
	clearAreaSelectListParameter,
	getShipArgumentParameter,
	saveShipArgumentParameter,
	getPortCodeListParameter,
	getCountryListParameter,
	getShipTableListParameter,
	downLoadTableListApiParameter
}

type ApiUrl = keyof typeof ApiMap;

const aisApi: ApiParameter<ApiUrl> = {
	getAreaInfoListParameter: {
		method: "get",
		url: appVersion + "/moni/area/info/list"
	},
	getAreaInfoSelectListParameter: {
		method: "get",
		url: appVersion + "/moni/area/info/select/list"
	},
	updateAreaSelectListParameter: {
		method: "put",
		url: appVersion + "/moni/area/info"
	},
	clearAreaSelectListParameter: {
		method: "post",
		url: appVersion + "/moni/area/info"
	},
	getShipArgumentParameter: {
		method: "get",
		url: appVersion + "/moni/ship/area/argument"
	},
	saveShipArgumentParameter: {
		method: "post",
		url: appVersion + "/moni/ship/area/argument"
	},
	getPortCodeListParameter: {
		method: "get",
		url: appVersion + "/ibiz-port"
	},
	getCountryListParameter: {
		method: "get",
		url: appVersion + "/ibiz-country"
	},
	getShipTableListParameter: {
		method: "get",
		url: appVersion + "/moni/ship/area/list"
	},
	downLoadTableListApiParameter: {
		method: "post",
		url: appVersion + "/moni/ship/area/download/vessel"
	}
};

export const getAreaInfoListApi = (params: getAreaInfoListParams) => {
	return request.request({
		...aisApi.getAreaInfoListParameter,
		params
	});
};

export const getAreaInfoSelectListApi = () => {
	return request.request({
		...aisApi.getAreaInfoSelectListParameter
	});
};

export const updateAreaSelectListApi = (params: updateAreaSelectListParams) => {
	return request.request({
		...aisApi.updateAreaSelectListParameter,
		data: params
	});
};

export const clearAreaSelectListApi = (params: number[]) => {
	return request.request({
		...aisApi.clearAreaSelectListParameter,
		data: params
	});
};

//table
export const getShipArgumentApi = () => {
	return request.request({
		...aisApi.getShipArgumentParameter
	});
};

export const saveShipArgumentApi = (params: saveShipArgumentParams) => {
	return request.request({
		...aisApi.saveShipArgumentParameter,
		data: params
	});
};

export const getPortCodeListApi = (params: getPortCodeListParams) => {
	return request.request({
		...aisApi.getPortCodeListParameter,
		params
	});
};

export const getCountryListApi = (params: getCountryListParams) => {
	return request.request({
		...aisApi.getCountryListParameter,
		params
	});
};

export const getShipTableListApi = (params: getShipTableListParams) => {
	return request.request({
		...aisApi.getShipTableListParameter,
		params
	});
};

export const downLoadTableListApi = (params: downLoadTableListApiParams[]) => {
	return request.request({
		...aisApi.downLoadTableListApiParameter,
		data: params,
		responseType: "blob"
	});
};
