import React, { FC } from "react";
import style from "./index.module.less";
import { FleetPrompt } from "@/common";
import { EstimationNewlyProps } from "./type";
import { FleetButton, FleetLoading } from "@/components";
import useIndex from "./hooks/useIndex";
import { CargoAndPortItem, VesselParticularItem } from "./components";
import { Button } from "@/_components";

const EstimationNewly: FC<EstimationNewlyProps> = ({
  hide,
  onClose,
  onSave
}) => {
  const {
    loading,
    cargoItems,
    vesselItems,
    handleNewlyCrud,
    handleCargoItemChange,
    handleCargoItemCommit,
    handleVesselItemChange,
    handleVesselItemCommit,
    handleSaveTemplate,
    handleSave,
    handleClose
  } = useIndex(onClose, onSave)
  return <FleetPrompt hide={hide} mode="fixed">
    <div className={style["estimation-newly"]}>
      <FleetLoading loading={loading} />
      <div className="prompt-header--container">
        <span className="header-title">New Estimation</span>
        <span className="font_family fleet-close header-close" onClick={handleClose}></span>
      </div>
      <div className="estimation-newly--container">
        {
          cargoItems?.map((cargoItem, cargoIndex) => {
            return <CargoAndPortItem
              key={cargoIndex}
              item={cargoItem}
              currentIndex={cargoIndex}
              onChange={handleCargoItemChange}
              onCommit={handleCargoItemCommit}
              onCrud={handleNewlyCrud}
            />
          })
        }
        <span className="estimation-newly--lines"></span>
        {
          vesselItems?.map((vesselItem, vesselIndex) => {
            return <VesselParticularItem
              key={vesselIndex}
              item={vesselItem}
              currentIndex={vesselIndex}
              onChange={handleVesselItemChange}
              onCommit={handleVesselItemCommit}
              onCrud={handleNewlyCrud}
              onTemplateSave={handleSaveTemplate}
            />
          })
        }
      </div>
      <div className="prompt-footer--container">
        <Button
          type="secondary"
          size="small"
          onClick={handleClose}
        >Cancel</Button>
        <Button
          type="primary"
          size="small"
          disabled={loading}
          onClick={handleSave}
        >Estimation</Button>
      </div>
    </div>
  </FleetPrompt>
}

export default EstimationNewly