import { CommonInitItemEvent } from "@/types/event"
import { InvStatementOtherItemType } from "./type"

export const initialInvStatementOtherItem: CommonInitItemEvent<InvStatementOtherItemType> = (item) => {
  return {
    addOther: item?.addOther ?? false,
    addBunker: item?.addBunker ?? false,
    addFixed: item?.addFixed ?? false,
    addHire: item?.addHire ?? false,
    id: item?.id ?? null,
    pastList: item?.pastList ?? [],
    status: item?.status ?? "Unsettled",
    settledTime: item?.settledTime ?? null,
    total: item?.total ?? 0,
    voyageId: item?.voyageId ?? null,
    companyName: item?.companyName ?? "",
    updateTime: item?.updateTime ?? null
  }
}