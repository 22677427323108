import { useCommonIndex } from "@/hook"
import { checkKeys, initialStatementCounterPartyItem } from "../source"
import { useInvFieldNames } from "@/pages/voyagemanage/business/invoices/module"

const useIndex = () => {
  const {
    delIds,
    dataSource: fieldItems,
    change: handleCounterPartyFieldItemChange,
    remove: handleCounterPartyFieldFieldDelete,
    init: handleCounterPartyFieldFieldInit,
    add: handleCounterPartyFieldFieldAdd
  } = useInvFieldNames()

  const {
    dataSource,
    change,
    commit,
    init,
    check
  } = useCommonIndex(initialStatementCounterPartyItem, checkKeys)

  return {
    dataSource,
    change,
    commit,
    init,
    check,
    fieldItems,
    handleCounterPartyFieldItemChange,
    handleCounterPartyFieldFieldDelete,
    handleCounterPartyFieldFieldInit,
    handleCounterPartyFieldFieldAdd
  }
}

export default useIndex