import {
  delHeadFixtureAction,
  getHeadFixtureGroupAction,
} from "@/action/voyageManage/headFixture";
import { CommonRoleWrap, FleetToast } from "@/common";
import { FleetButton, FleetTooltip } from "@/components";
import { useAppDispatch } from "@/hook";
import useCheckGroup from "@/hook/common/useCheckGroup";
import useReminder from "@/hook/useReminder";
import {
  HFCategoryGroupType,
  HFCategoryKeys,
  HFGroupOptionItemKeys,
  initHFCategoryGroup,
  columns as list_columns,
} from "@/types/voyageManage/headFixtureList/list";
import { Dayjs } from "dayjs";
import React, { useEffect, useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";
import debounce from "lodash/debounce";
import { Popover } from "@/_components";
import classnames from "classnames";

const useGroup = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { reminder } = useReminder();
  const [hide, setHide] = useState(true);
  const [editHide, setEditHide] = useState(true);
  const [recordId, setRecordId] = useState(null);
  const [dataSource, setDataSource] = useState([]);
  const [total, setToal] = useState<number>(0);
  const [selectedRowKeys, setSelectedRowKeys] = useState<number[]>([]);
  const [ids, setIds] = useState<string>("");
  const [pageNum, setPageNum] = useState<number>(1);
  const [pageSize, setPageSize] = useState<number>(20);
  const [search, setSearch] = useState("");
  const [loading, setLoading] = useState(false);
  const [layCan, setLayCan] = useState<[Dayjs | undefined, Dayjs | undefined]>([
    undefined,
    undefined,
  ]);
  const {
    checkGroup,
    reset: handleReset,
    change: handleChange,
  } = useCheckGroup<HFCategoryGroupType, HFCategoryKeys, HFGroupOptionItemKeys>(
    initHFCategoryGroup
  );

  const delHFMultiple = (type: "single" | "all", id?: number) => {
    delHeadFixtureAction(
      type === "single" ? [id] : selectedRowKeys,
      getHFGroupFront,
      delHfResultSuccess,
      getHfGroupError,
      dispatch,
      navigate
    );
  };

  const delHfResultSuccess = (response) => {
    reminder("success", response?.msg);
    setIds("");
    setSelectedRowKeys([]);
    getHFGroup();
  };

  const getHfGroupError = (error) => {
    reminder(
      "error",
      error?.msg ? error?.msg + ": " + error?.data : error?.data
    );
    setLoading(false);
  };

  const columns = useMemo(() => {
    let column_keys = [];
    Object.values(checkGroup).forEach((item) => {
      column_keys.push(...item);
    });
    let _columns = list_columns.filter((column) =>
      column_keys.includes(column.key)
    );

    _columns.push({
      dataIndex: "option",
      width: 85,
      title: "Option",
      key: "option",
      align: "center",
      fixed: "right",
      render: (value, record, index) => {
        return (
          <div className="td-operate">
            <CommonRoleWrap roleStr="hf:list:delete">
              <Popover
                disabled={!!record?.voyageId}
                // iconProps={{
                //   type: "FleetEslistDeleteIcon",
                //   outerLayerClassname: "operate-icon",
                // }}
                render={(setIsOpen) => {
                  return (
                    <FleetToast
                      label="Are you sure delete this item?"
                      onCancel={() => {
                        setIsOpen(false);
                      }}
                      onConfirm={() => {
                        delHFMultiple("single", record?.id);
                        setIsOpen(false);
                      }}
                    />
                  );
                }}
              >
                <span className={classnames("font_family fleet-delete", {
                  'fleet-disabled': !!record?.voyageId
                })} style={{ color: 'red' }}></span>
              </Popover>
            </CommonRoleWrap>
          </div>
        );
      },
    });
    return _columns;
  }, [checkGroup]);

  const onSelectChange = (newSelectedRowKeys: number[]) => {
    setSelectedRowKeys(newSelectedRowKeys);
    setIds(newSelectedRowKeys?.join(","));
  };

  const getHFGroupFront = () => {
    setLoading(true);
  };

  const getHFSuccess = (response) => {
    if (!Array.isArray(response?.data)) {
      setDataSource(response?.data?.items);
      setToal(response?.data?.totalCount);
    } else {
      setDataSource([]);
      setToal(0);
    }
    setPageNum(1);
    setLoading(false);
  };

  const onPageChangeSuccess = (response) => {
    if (!Array.isArray(response?.data)) {
      setDataSource((val) => [...val, ...response?.data?.items]);
    }
    setLoading(false);
  };

  const getHFError = (error) => {
    reminder(
      "error",
      error?.msg ? error?.msg + ": " + error?.data : error?.data
    );
    setLoading(false);
  };

  const getHFGroup = (searchVal?: string) => {
    getHeadFixtureGroupAction(
      {
        pageNum: 1,
        pageSize,
        keyword: searchVal ?? search,
        layCanFrom: layCan?.[0]?.valueOf(),
        layCanTo: layCan?.[1]?.valueOf(),
      },
      getHFGroupFront,
      getHFSuccess,
      getHFError,
      dispatch,
      navigate
    );
  };

  const onPageChange = (page: number, size: number) => {
    setPageNum(page);
    if (dataSource?.length >= total) return;
    if (page * size <= dataSource?.length) return;
    getHeadFixtureGroupAction(
      {
        pageNum: page,
        pageSize,
        keyword: search,
        layCanFrom: layCan?.[0]?.valueOf(),
        layCanTo: layCan?.[1]?.valueOf(),
      },
      getHFGroupFront,
      onPageChangeSuccess,
      getHFError,
      dispatch,
      navigate
    );
  };

  const onSearch = useMemo(() => {
    const loadOptions = (val: string) => {
      setDataSource([]);
      getHFGroup(val);
    };
    return debounce(loadOptions, 500);
  }, [search, layCan]);

  const onRow = (record) => {
    return {
      onDoubleClick: () => {
        setRecordId(record?.id);
        setEditHide(false);
      },
    };
  };

  useEffect(() => {
    getHFGroup();
  }, [search, layCan]);

  return {
    search,
    checkGroup,
    layCan,
    ids,
    selectedRowKeys,
    pageNum,
    pageSize,
    columns,
    dataSource,
    loading,
    total,
    hide,
    recordId,
    editHide,
    setHide,
    setEditHide,
    onLayCanChange: setLayCan,
    handleReset,
    handleChange,
    onPageChange,
    onSelectChange,
    onKeywordChange: setSearch,
    onIdsChange: setIds,
    onSearch,
    onDeleteItems: delHFMultiple,
    onReset: getHFGroup,
    onRow,
  };
};

export default useGroup;
