import { CommonInitItemEvent } from "@/types/event";
import { ShiftingReportPortItem, TableCellType } from "./type";
import { DatePicker, Space } from "antd";
const { RangePicker } = DatePicker;
import React from "react";
import dayjs from "dayjs";
import { FleetInput } from "@/components";
export type TableRowType = {
  rowIndex: number;
  row: TableCellType[];
};
export const shiftingReportConfig: TableRowType[] = [
  {
    rowIndex: 0,
    row: [
      { colSpan: 2, rowSpan: 1, label: "SHIFTING TIME(UTC)" },
      { colSpan: 1, rowSpan: 1, label: "CONSUMED IFO" },
      { colSpan: 1, rowSpan: 1, label: "CONSUMED MDO" },
      { colSpan: 1, rowSpan: 1, label: "ROB IFO" },
      { colSpan: 1, rowSpan: 1, label: "ROB MDO" },
    ],
  },
  {
    rowIndex: 1,
    row: [
      {
        colSpan: 2,
        rowSpan: 1,
        prop: "shiftingTimeFrom",
        render: (value, index, record, actions) => {
          return (
            <RangePicker
              value={
                record.shiftingTimeFrom && record.shiftingTimeTo
                  ? [
                      dayjs(record.shiftingTimeFrom),
                      dayjs(record.shiftingTimeTo),
                    ]
                  : null
              }
              showTime={{ format: "HH:mm" }}
              format="YYYY-MM-DD HH:mm"
              onChange={(value, dateString) => {
                actions?.onCommit(index, {
                  shiftingTimeFrom: dayjs(value[0]).valueOf(),
                  shiftingTimeTo: dayjs(value[1]).valueOf(),
                });
              }}
            />
          );
        },
      },
      {
        colSpan: 1,
        rowSpan: 1,
        prop: "consumedIfo",
        render: (value, index, record, actions) => {
          return (
            <FleetInput
              value={value}
              type={"number"}
              onChange={(val) =>
                actions?.onCommit(index, { consumedIfo: +val })
              }
            ></FleetInput>
          );
        },
      },
      {
        colSpan: 1,
        rowSpan: 1,
        prop: "consumedMdo",
        render: (value, index, record, actions) => {
          return (
            <FleetInput
              value={value}
              type={"number"}
              onChange={(val) =>
                actions?.onCommit(index, { consumedMdo: +val })
              }
            ></FleetInput>
          );
        },
      },
      {
        colSpan: 1,
        rowSpan: 1,
        prop: "brobIfo",
        render: (value, index, record, actions) => {
          return (
            <FleetInput
              value={value}
              type={"number"}
              onChange={(val) => actions?.onCommit(index, { brobIfo: +val })}
            ></FleetInput>
          );
        },
      },
      {
        colSpan: 1,
        rowSpan: 1,
        prop: "brobMdo",
        render: (value, index, record, actions) => {
          return (
            <FleetInput
              value={value}
              type={"number"}
              onChange={(val) => actions?.onCommit(index, { brobMdo: +val })}
            ></FleetInput>
          );
        },
      },
    ],
  },
  {
    rowIndex: 2,
    row: [
      { colSpan: 1, rowSpan: 1, label: "DRAFT(F)" },
      { colSpan: 1, rowSpan: 1, label: "DRAFT(M)" },
      { colSpan: 1, rowSpan: 1, label: "DRAFT(A)" },
      { colSpan: 1, rowSpan: 1, label: "NO. OF TUG(S) USED" },
      { colSpan: 2, rowSpan: 1, label: "WEATHER" },
    ],
  },
  {
    rowIndex: 3,
    row: [
      {
        colSpan: 1,
        rowSpan: 1,
        prop: "driftF",
        render: (value, index, record, actions) => {
          return (
            <FleetInput
              value={value}
              type={"number"}
              onChange={(val) => actions?.onCommit(index, { driftF: +val })}
            ></FleetInput>
          );
        },
      },
      {
        colSpan: 1,
        rowSpan: 1,
        prop: "driftM",
        render: (value, index, record, actions) => {
          return (
            <FleetInput
              value={value}
              type={"number"}
              onChange={(val) => actions?.onCommit(index, { driftM: +val })}
            ></FleetInput>
          );
        },
      },
      {
        colSpan: 1,
        rowSpan: 1,
        prop: "driftA",
        render: (value, index, record, actions) => {
          return (
            <FleetInput
              value={value}
              type={"number"}
              onChange={(val) => actions?.onCommit(index, { driftA: +val })}
            ></FleetInput>
          );
        },
      },
      {
        colSpan: 1,
        rowSpan: 1,
        prop: "noOfTugUsed",
        render: (value, index, record, actions) => {
          return (
            <FleetInput
              value={value}
              type={"number"}
              onChange={(val) =>
                actions?.onCommit(index, { noOfTugUsed: +val })
              }
            ></FleetInput>
          );
        },
      },
      {
        colSpan: 2,
        rowSpan: 1,
        prop: "weather",
        render: (value, index, record, actions) => {
          return (
            <FleetInput
              value={value}
              type={"text"}
              onChange={(val) => actions?.onCommit(index, { weather: val })}
            ></FleetInput>
          );
        },
      },
    ],
  },
];

export const newShiftingReportRow: CommonInitItemEvent<
  ShiftingReportPortItem
> = (item) => {
  return {
    voyageId: item?.voyageId ?? null,
    driftF: item?.driftF ?? "",
    driftM: item?.driftM ?? "",
    driftA: item?.driftA ?? "",
    brobIfo: item?.brobIfo ?? "",
    brobMdo: item?.brobMdo ?? "",
    consumedIfo: item?.consumedIfo ?? "",
    consumedMdo: item?.consumedMdo ?? "",
    noOfTugUsed: item?.noOfTugUsed ?? "",
    weather: item?.weather ?? "",
    remark: item?.remark ?? "",
    portName: item?.portName ?? "",
    portCode: item?.portCode ?? "",
    shiftingTimeFrom: item?.shiftingTimeFrom ?? null,
    shiftingTimeTo: item?.shiftingTimeTo ?? null,
  };
};
