import React, { useEffect, useState } from "react";
import { Button, Space, Divider, Card, Row, Col } from 'antd'
import { DownloadOutlined, UnorderedListOutlined, InfoCircleOutlined } from '@ant-design/icons'
import { FleetLoading } from "@/components";
import classnames from "classnames";
import useReminder from "@/hook/useReminder";
import { DetailDrawerItem } from '../../'
import { getPerformanceAction, getCPTermsAction } from '@/action/monitoring/vessel'
import { FleetIcon } from "@/components";
import CPTerm from "../Performance/components/CPTerm";
import Segment from "../Performance/components/Segment";
import style from './index.module.less'
import { MonitoringDetailMoreTabType } from "../../type";

export type PerformanceProps = {
  className?: string,
  data: DetailDrawerItem,
  mapRef?: React.MutableRefObject<any>
  activeIndex: MonitoringDetailMoreTabType
}

const Performance: React.FC<PerformanceProps> = ({ data, className, mapRef }) => {

  const [loading, setLoading] = useState<boolean>(false)
  const { reminder } = useReminder();
  const [segmentVisible, setSegmentVisible] = useState<boolean>(false)

  useEffect(() => {
    // data?.voyageNo && loadData()
  }, [data?.voyageNo])

  const loadData = () => {
    const { id, vesselStatus, actualCommenceDate, voyageNo, startPortCode, endPortCode } = data
    getPerformanceAction(
      { voyageId: id, legType: vesselStatus?.[0], voyageNo, startPortCode, endPortCode },
      () => { setLoading(true) },
      ({ data }) => {
        setLoading(false)
      },
      (error) => {
        setLoading(false)
        reminder("error", error?.data)
      }
    )
  }

  return <div className={classnames([style.performance, style[className]])}>
    <FleetLoading loading={loading} />
    <div className={style.toolbar}>
      <Space>
        <span>Departure : <span className={style.black}>{ data?.startPortName }</span></span>
        <Divider type="vertical" />
        <span>Arrival : <span className={style.black}>{ data?.endPortName }</span></span>
        <Divider type="vertical" />
        <span>ETA : <span className={style.black}>2023-08-13 00:00</span></span>
        <Divider type="vertical" />
        <span>Created Time : <span className={style.black}>2023-08-13 00:00</span></span>
      </Space>
      <Button type="primary" icon={<DownloadOutlined />}>Download</Button>
    </div>
    <div className={style.container}>
      <div className={style.item}>
        <div className={style.itemHeader}>
          <Space><div className={style.icon}><FleetIcon type="MonitoringPerformanceCP" /></div><span>CP Terms</span></Space>
          <Space>
            <Button disabled={!data?.voyageNo} icon={<UnorderedListOutlined />} onClick={()=>setSegmentVisible(true)}>Segment</Button>
            <Button icon={<UnorderedListOutlined />}>Simulation</Button>
          </Space>
        </div>
        <CPTerm data={data} />
        {/* <Card style={{ padding: '6px 24px' }} >
          <Row gutter={[8, 24]}>
            <Col span={8} className={style.gray}>Full (Speed/IFO/MDO) :</Col>
            <Col span={8} className={style.gray}>ECO (Speed/IFO/MDO) :</Col>
            <Col span={8} className={style.gray}>BF Scale :</Col>
            <Col span={8} className={style.black}>12.23 kts</Col>
            <Col span={8} className={style.black}>12.23 kts</Col>
            <Col span={8} className={style.black}>12.23 kts</Col>
          </Row>
          <Row gutter={[8, 24]} style={{ marginTop: '24px', marginBottom: '24px' }}>
            <Col span={8} className={style.gray}>Sig Wave(m) :</Col>
            <Col span={8} className={style.gray}>No Adverse Current :</Col>
            <Col span={8} className={style.gray}>Consecutive Hours :</Col>
            <Col span={8} className={style.black}>12.23 kts</Col>
            <Col span={8} className={style.black}>12.23 kts</Col>
            <Col span={8} className={style.black}>12.23 kts</Col>
          </Row>
          <Row gutter={[8, 24]} style={{ marginTop: '24px', marginBottom: '24px' }}>
            <Col span={8} className={style.gray}>Favorable Current :</Col>
            <Col span={8} className={style.gray}>Extrapolation :</Col>
            <Col span={8} className={style.gray}>About Spd :</Col>
            <Col span={8} className={style.black}>12.23 kts</Col>
            <Col span={8} className={style.black}>12.23 kts</Col>
            <Col span={8} className={style.black}>12.23 kts</Col>
          </Row>
          <Row gutter={[8, 24]}>
            <Col span={8} className={style.gray}>About-IFO :</Col>
            <Col span={8} className={style.gray}>About-MDO :</Col>
            <Col span={8} className={style.gray}>WOG :</Col>
            <Col span={8} className={style.black}>12.23 kts</Col>
            <Col span={8} className={style.black}>12.23 kts</Col>
            <Col span={8} className={style.black}>12.23 kts</Col>
          </Row>
        </Card> */}
      </div>
      <Divider type="vertical" style={{ height: '100%', margin: '0 24px' }} />
      <div className={style.item}>
        <Row gutter={[8, 8]}>
          <Col span={8}>
            <Card>
              <Row gutter={[8, 8]} className={style.info}>
                <Col span={24}><Space>Time Lost/Gain :<InfoCircleOutlined /></Space></Col>
                <Col span={24} className={style.font24}>12,34</Col>
                <Col span={24} >
                  <Space>
                    <div className={style.icon}><FleetIcon type="MonitoringClock" /></div>
                    Time Gain
                  </Space>
                </Col>
              </Row>
            </Card>
          </Col>
          <Col span={8}>
            <Card>
              <Row gutter={[8, 8]}>
                <Col span={24} className={style.info}><Space>IFO Over/Save :<InfoCircleOutlined /></Space></Col>
                <Col span={24} className={style.font24}>12,34</Col>
                <Col span={24} className={style.info}>
                  <Space>
                    <div className={style.icon}><FleetIcon type="MonitoringWater" /></div>
                    IFO Over
                  </Space>
                </Col>
              </Row>
            </Card>
          </Col>
          <Col span={8}>
            <Card>
              <Row gutter={[8, 8]}>
                <Col span={24} className={style.info}><Space>MDO Over/Save :<InfoCircleOutlined /></Space></Col>
                <Col span={24} className={style.font24}>12,34</Col>
                <Col span={24} className={style.info}>
                  <Space>
                    <div className={style.icon}><FleetIcon type="MonitoringWater" /></div>IFO Over
                  </Space>
                </Col>
              </Row>
            </Card>
          </Col>
          <Col span={8}>
            <Card>
              <Row gutter={[8, 8]}>
                <Col span={24} className={style.info}><Space>GW Distance (nm) :<InfoCircleOutlined /></Space></Col>
                <Col span={24} className={style.font24}>12,34</Col>
                <Col span={24} className={style.info}>Overall Distance (nm) : 10,839</Col>
              </Row>
            </Card>
          </Col>
          <Col span={8}>
            <Card>
              <Row gutter={[8, 8]}>
                <Col span={24} className={style.info}><Space>GW Time (hrs) :<InfoCircleOutlined /></Space></Col>
                <Col span={24} className={style.font24}>12,34</Col>
                <Col span={24} className={style.info}>Overall Time (hrs) : 10,839</Col>
              </Row>
            </Card>
          </Col>
          <Col span={8}>
            <Card>
              <Row gutter={[8, 8]}>
                <Col span={24} className={style.info}><Space>GW Cons. IFO (nm) :<InfoCircleOutlined /></Space></Col>
                <Col span={24} className={style.font24}>12,34</Col>
                <Col span={24} className={style.info}>Overall Cons. IFO (nm) : 10,839</Col>
              </Row>
            </Card>
          </Col>
          <Col span={8}>
            <Card>
              <Row gutter={[8, 8]}>
                <Col span={24} className={style.info}><Space>GW Average Speed (kts) :<InfoCircleOutlined /></Space></Col>
                <Col span={24} className={style.font24}>12,34</Col>
                <Col span={24} className={style.info}>Overall Average Speed (kts) : 10,839</Col>
              </Row>
            </Card>
          </Col>
          <Col span={8}>
            <Card>
              <Row gutter={[8, 8]}>
                <Col span={24} className={style.info}><Space>GW Cons. MDO (nm) :<InfoCircleOutlined /></Space></Col>
                <Col span={24} className={style.font24}>12,34</Col>
                <Col span={24} className={style.info}>Overall Cons. MDO (nm) : 10,839</Col>
              </Row>
            </Card>
          </Col>
          <Col span={8}>
            <Card>
              <Row gutter={[8, 8]}>
                <Col span={24} className={style.info}><Space>GW Performance Speed (kts) :<InfoCircleOutlined /></Space></Col>
                <Col span={24} className={style.font24}>12,34</Col>
                <Col span={24} className={style.info}>Good Weather CF (kts) : 10,839</Col>
              </Row>
            </Card>
          </Col>
        </Row>
      </div>
    </div>
    <Segment data={data} isModalOpen={segmentVisible} onCancel={() => setSegmentVisible(false)} />
  </div >
}

export default Performance
