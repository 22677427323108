import React, { FC } from "react";
import style from "./index.module.less";
import { MovementTaskItemProps } from "./type";
import { FleetCustomRadio } from "@/components/Radio";
import { LevelMapEnum, levelOptions } from "./source";
import classnames from "classnames";
import { FleetDatePicker } from "@/components";
import dayjs from "dayjs";

const MovementTaskItem: FC<MovementTaskItemProps> = ({
  isSelected,
  item,
  onChange,
  onSelect,
  onOpenDialog
}) => {
  return <div className={classnames(style["movement-taskDetails-taskItem"], {
    active: isSelected
  })} onClick={() => {
    onSelect?.(item)
  }}>
    <div className="taskItem-header">
      <FleetCustomRadio
        label={item?.title}
        value={!!item?.status}
        onChange={(checked) => {
          onChange?.(item?.id, "status", +checked as 0 | 1)
        }}
        className="taskItem-select"
      />
      {
        item?.dialog && <div className="taskItem-dialog--open" onClick={(event) => {
          event?.stopPropagation();
          onOpenDialog?.(item?.dialog, false)
        }}>
          <span className="font_family fleet-shortcut"></span>
        </div>
      }
    </div>
    <span className="taskItem-content">
      {item?.contents}
    </span>
    <div className="taskItem-level">
      <span className="taskItem-level--label">Risk level</span>
      <ul className="taskItem-level--tabs">
        {
          levelOptions?.map((levelItem, levelIndex) => {
            return <li
              key={levelIndex}
              className={classnames({
                [LevelMapEnum[item?.priority]]: levelItem.value === item?.priority
              })}
              onClick={(e) => {
                e?.stopPropagation()
                onChange?.(item?.id, "priority", levelItem?.value)
              }}
            >{levelItem?.label}</li>
          })
        }
      </ul>
    </div>
    <div className="taskItem-footer">
      <div className="taskItem-footer--deadLine" onClick={(e) => {
        e?.stopPropagation()
      }}>
        <span className="font_family fleet-Calender"></span>
        <FleetDatePicker
          value={item?.deadLine ? dayjs(item?.deadLine) : null}
          className="deadLine--dateSelect"
          format={(value) => value?.format("YYYY-MM-DD HH:mm")}
          onChange={(value, timeStramp) => {
            onChange?.(item?.id, "deadLine", timeStramp?.startOf("minutes")?.valueOf())
          }}
          showTime={{
            format: "HH:mm"
          }}
          showHour
          showMinute
        />
        <span className="font_family fleet-bell"></span>
      </div>
      <div className="taskItem-footer--comment">
        <span className="font_family fleet-message"></span>
        <span>{item?.totalCount}</span>
      </div>
    </div>
  </div>
}

export default MovementTaskItem