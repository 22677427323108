import { useAppDispatch } from "@/hook";
import useReminder from "@/hook/useReminder";
import { useEffect, useMemo, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import debounce from "lodash/debounce";
import { FleetTooltip } from "@/components";
import React from "react";
import { FleetToast } from "@/common";
import { ColumnsType } from "antd/es/table";
import { CommonVesselArchivalType } from "@/common/VesselArchives/type";
import {
	getVesselArchiveGroupAction,
	deleteVesselArchiveAction
} from "@/action/dataBase/vesselArchives";
import { DataType } from "../type";

const useIndex = () => {
	const dispatch = useAppDispatch();
	const navigate = useNavigate();
	const { reminder } = useReminder();
	const cacheSource = useRef<{ [index: number]: CommonVesselArchivalType[] }>({});
	const [selectedRowKeys, setSelectedRowKeys] = useState<number[]>([]);
	const record = useRef<CommonVesselArchivalType>(null);
	const [search, setSearch] = useState("");
	const [loading, setLoading] = useState(false);
	const [hide, setHide] = useState(true);
	const [editType, setEditType] = useState<"add" | "edit">("add");

	const [pager, setPager] = useState<{
		total: number;
		pageNum: number;
		pageSize: number;
	}>({ total: 0, pageNum: 1, pageSize: 20 });

	const dataSource = useMemo(() => {
		return cacheSource.current[pager.pageNum];
	}, [pager.pageNum, pager.total]);

	const handleEdit = (type: "add" | "edit", item: CommonVesselArchivalType | null) => {
		if (type === "edit") return;
		record.current = item;
		setEditType(type);
		setHide(false);
	};

	const opereteColumns: ColumnsType<DataType> = useMemo(() => {
		return [
			{
				dataIndex: "option",
				width: 120,
				title: "Action",
				key: "option",
				align: "center",
				fixed: "right",
				render: (value, record, index) => {
					return (
						<div className="td-operate">
							<span className="fleet-disabled" onClick={() => handleEdit("edit", record)}>
								Edit
							</span>
							<FleetTooltip
								iconProps={{
									type: "FleetEslistDeleteIcon",
									outerLayerClassname: "operate-icon"
								}}
								render={(setIsOpen) => {
									return (
										<FleetToast
											label="Are you sure delete this item?"
											onCancel={() => {
												setIsOpen(false);
											}}
											onConfirm={() => {
												setIsOpen(false);
												deleteVesselArchive("single", record);
											}}
										/>
									);
								}}
							></FleetTooltip>
						</div>
					);
				}
			}
		];
	}, [record]);

	const onSelectChange = (newSelectedRowKeys: number[]) => {
		setSelectedRowKeys(newSelectedRowKeys);
	};

	const getVesselArchiveGroupActionFront = () => {
		setLoading(true);
	};

	const getVesselArchiveGroupActionSuccess = (response) => {
		let total = 0;
		switch (true) {
			case !Array.isArray(response?.data):
				// setDataSource(response?.data?.items);
				cacheSource.current[1] = response?.data?.items;
				total = response?.data?.totalCount;
				break;
			default:
				// setDataSource([]);
				cacheSource.current = {};
				break;
		}
		setPager((prev) => ({ ...prev, total, pageNum: 1 }));
		setLoading(false);
	};

	const getVesselArchiveGroupActionFailed = (error) => {
		reminder("error", error?.msg ? error?.msg + ": " + error?.data : error?.data);
		setLoading(false);
	};

	const getVesselArchiveGroup = (searchVal?: string) => {
		getVesselArchiveGroupAction(
			{
				pageNum: 1,
				pageSize: pager.pageSize,
				keyword: searchVal ?? search
			},
			getVesselArchiveGroupActionFront,
			getVesselArchiveGroupActionSuccess,
			getVesselArchiveGroupActionFailed,
			dispatch,
			navigate
		);
	};

	const deleteVesselArchiveSuccess = (response) => {
		reminder("success", response?.msg);
		getVesselArchiveGroup();
	};

	const deleteVesselArchive = (type: "single" | "all", record?: CommonVesselArchivalType) => {
		if (type === "all" && selectedRowKeys?.length === 0) return;
		deleteVesselArchiveAction(
			type === "single" ? [record?.id] : selectedRowKeys,
			getVesselArchiveGroupActionFront,
			deleteVesselArchiveSuccess,
			getVesselArchiveGroupActionFailed,
			dispatch,
			navigate
		);
	};

	const handleReset = () => {
		setHide(true);
		setSelectedRowKeys([]);
		getVesselArchiveGroup();
	};

	const onPageChange = (page: number, size: number) => {
		if (dataSource?.length >= pager.total) return;
		// if (page * size <= dataSource?.length) return;
		if (cacheSource.current[page]) {
			setPager((prev) => ({ ...prev, pageNum: page }));
			return;
		}
		getVesselArchiveGroupAction(
			{
				pageNum: page,
				pageSize: pager.pageSize,
				keyword: search
			},
			getVesselArchiveGroupActionFront,
			(response) => {
				if (!Array.isArray(response?.data)) {
					cacheSource.current[page] = response?.data?.items;
				} else {
					cacheSource.current[page] = [];
				}
				setPager((prev) => ({ ...prev, pageNum: page }));
				setLoading(false);
			},
			getVesselArchiveGroupActionFailed,
			dispatch,
			navigate
		);
	};

	const onSearch = useMemo(() => {
		const loadOptions = (val: string) => {
			cacheSource.current = {};
			setSearch(val);
			getVesselArchiveGroup(val);
		};
		return debounce(loadOptions, 500);
	}, []);

	useEffect(() => {
		getVesselArchiveGroup();
	}, []);

	return {
		hide,
		search,
		record,
		selectedRowKeys,
		pager,
		dataSource,
		loading,
		onSearch,
		opereteColumns,
		editType,
		setHide,
		onPageChange,
		onSelectChange,
		handleReset,
		deleteVesselArchive,
		handleEdit
	};
};

export default useIndex;
