import { PortDaNewlyFormType } from "./type";

export const initialNewlyForm: PortDaNewlyFormType = {
  portName: "",
  portCode: "",
  portId: null,
  portCountry: "",
  agentId: null,
  agentName: "",
  advancePayment: "",
  totalExpense: "",
  vesselId: null,
  vesselName: "",
  cargoName: "",
  cargoQuantity: "",
  cargoUnit: "MT",
  portArrival: null
}

export const checkPdaKeys: (keyof PortDaNewlyFormType)[] = [
  "portName",
  "totalExpense"
]