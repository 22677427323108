import { useState } from "react";
import { changePasswordParams } from "@/api/personalCenter/type";
import useReminder from "@/hook/useReminder";
import { useAppDispatch } from "@/hook";
import { useNavigate } from "react-router-dom";
import { changePasswordAction } from "@/action/personalCenter/personalCenter";
import { passwordList } from "../source";

const useIndex = () => {
  const navigate = useNavigate();
  const { reminder } = useReminder();
  const dispatch = useAppDispatch();
  const [passwordForm, setForm] = useState<
    changePasswordParams & { confirmPassword: string }
  >({
    oldPassword: "",
    newPassword: "",
    confirmPassword: "",
  });
  const changeForm = (prop, val) => {
    setForm({ ...passwordForm, [prop]: val });
  };
  const changePassword = () => {
    let text = "";
    for (let item of passwordList) {
      if (!Boolean(passwordForm[item.prop])) {
        text = `${item.En} is Empty!`;
        break;
      }
    }
    if (text != "") {
      reminder("error", text);
      return;
    }
    if (passwordForm.newPassword != passwordForm.confirmPassword) {
      reminder("error", "Failed to confirm password!");
      return;
    }
    changePasswordAction(
      {
        newPassword: passwordForm.newPassword,
        oldPassword: passwordForm.oldPassword,
      },
      () => {},
      changePasswordSuccess,
      changePasswordError,
      dispatch,
      navigate
    );
  };
  const changePasswordError = (error) => {
    reminder(
      "error",
      error?.msg ? error?.msg + ": " + error?.data : error?.data
    );
  };
  const changePasswordSuccess = () => {
    reminder("success", "Save Success");
  };
  return {
    passwordForm,
    changeForm,
    changePassword,
  };
};
export default useIndex;
