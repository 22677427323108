import React, { forwardRef, useImperativeHandle } from "react";
import style from "./index.module.less";
import { MonitoringRoutePlanDownloadProps } from "./type";
import { RoutePlanModal } from "../modal";
import { FleetCustomRadio } from "@/components/Radio";
import useIndex from "./hooks/useIndex";
import { FleetLoading } from "@/components";
import { RoutePlanModalRefType } from "../modal/type";
import { RoutePlanItemType } from "../../type";

const RoutePlanDownload = forwardRef<
	RoutePlanModalRefType<RoutePlanItemType>,
	MonitoringRoutePlanDownloadProps
>(({}, ref) => {
	const { hide, loading, activeKey, handleChange, downloadRoutePlan, closeModal, openModal } =
		useIndex();

	useImperativeHandle(ref, () => {
		return {
			closeModal,
			openModal
		};
	});
	return (
		<RoutePlanModal
			title="Download"
			hide={hide}
			onClose={closeModal}
			className="w-[449px]"
			onConfirm={downloadRoutePlan}
		>
			<FleetLoading loading={loading} />
			<div className={style["routePlan-download"]}>
				<div className="download-container">
					<FleetCustomRadio
						value={activeKey === "A"}
						onChange={(val: boolean) => {
							handleChange("A");
						}}
						className="download-radio"
					/>
					<div className="download-item">
						<span className="linear-measure">01°15.854'N</span>
						<span className="linear-measure">103°57.854'E</span>
					</div>
				</div>
				<div className="download-container">
					<FleetCustomRadio
						value={activeKey === "B"}
						onChange={(val: boolean) => {
							handleChange("B");
						}}
						className="download-radio"
					/>
					<div className="download-item">
						<span>01</span>
						<span>15.864</span>
						<span>N</span>
						<span>103</span>
						<span>57.854</span>
						<span>E</span>
					</div>
				</div>
			</div>
		</RoutePlanModal>
	);
});

export default RoutePlanDownload;
