import { FleetOptionType } from "@/types/advancedType";
import { MovementVoyageStatusType } from "./type";

export const voyageStatusOptions: FleetOptionType<
	MovementVoyageStatusType,
	MovementVoyageStatusType
>[] = [
	{
		label: "Commencing",
		value: "Commencing"
	},
	{
		label: "Completed",
		value: "Completed"
	},
	{
		label: "Closed",
		value: "Closed"
	}
];
