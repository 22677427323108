import React, { useRef } from "react";
import { useMonitoringContext } from '@/pages/monitoring/MonitoringContext'
import classnames from "classnames";
import { Row, Col } from 'antd';
import style from './index.module.less'

export type LegendProps = {
  className?: string
}

interface Position {
  x: number,
  y: number
}

const Legend: React.FC<LegendProps> = ({ className }) => {
  const { meteoStatus } = useMonitoringContext()
  const windPositions = useRef<Position[]>([]).current
  const waves = useRef([
    { color: 'rgb(148, 231, 238)', label: '2' },
    { color: 'rgb(127, 255, 226)', label: '3' },
    { color: 'rgb(165, 255, 174)', label: '4' },
    { color: 'rgb(206, 255, 133)', label: '5' },
    { color: 'rgb(255, 255, 127)', label: '6' },
    { color: 'rgb(255, 227, 127)', label: '7' },
    { color: 'rgb(255, 183, 127)', label: '8' },
    { color: 'rgb(255, 127, 127)', label: '9' },
    { color: 'rgb(252, 127, 172)', label: '10' },
    { color: 'rgb(212, 127, 212)', label: '11' },
    { color: 'rgb(253, 130, 232)', label: '>11' },
    // { color: '#4DC8F5', label: '2~3m' },
    // { color: '#80d778', label: '3~4m' },
    // { color: '#ff0', label: '4~5m' },
    // { color: '#ffd500', label: '5~6m' },
    // { color: '#fa0', label: '6~7m' },
    // { color: '#ff7f00', label: '7~8m' },
    // { color: '#f50', label: '8~9m' },
    // { color: '#ff1e00', label: '9~10m' },
    // { color: '#f5375a', label: '10~11m' },
    // { color: '#c7062a', label: '11~12m' },
    // { color: '#9d0320', label: '12~13m' },
    // { color: '#750317', label: '>13m' }
  ]).current
  const typhoons = useRef([
    { label: '<32' },
    { label: '43' },
    { label: '50' },
    { label: '58' },
    { label: '70' },
    { label: '>70' },
  ]).current

  const intWindPositions = () => {
    windPositions.length = 0
    const size = 33
    for (let i = 0; i < 4; i++) {
      const col = i === 3 ? 3 : 6
      for (let j = 0; j < col; j++) {
        windPositions.push({ x: -1 * j * size, y: -1 * i * size })
      }
    }
  }

  const ms2mts = (value: number): number => {
    return value * 1.943844
  }

  const content = () => {
    intWindPositions()
    const size: number = 9
    const rows: number = Math.ceil(windPositions.length / size)
    return <div style={{ zoom: 0.8 }}>
      {/*<Row className={style.legendTableHeader} gutter={[24, 24]}>*/}
      {/*  /!*<Col span={12}>Wind</Col>*!/*/}
      {/*  <Col span={7}>Wave/Swell</Col>*/}
      {/*  <Col span={6}>Current</Col>*/}
      {/*  <Col span={11}>Typhoon</Col>*/}
      {/*</Row>*/}
      <Col gutter={[24, 24]} justify="center" style={{ 'display': 'flex', 'flexDirection': 'column', 'rowGap': '10px' }}>
        {/*{Array(rows).fill('').map((_, row: number) => {*/}
        {/*  const colLength = row === rows - 1 ? windPositions.length % size : size*/}
        {/*  return <Col span={4} key={row}>*/}
        {/*    {*/}
        {/*      Array(colLength).fill('').map((_, col: number) => {*/}
        {/*        const position: Position = windPositions[row * size + col]*/}
        {/*        return <Row align="middle" key={col}>*/}
        {/*          <Col span={10}>*/}
        {/*            <span className={style.windIcon} style={{ backgroundPositionX: position.x, backgroundPositionY: position.y }}></span>*/}
        {/*          </Col>*/}
        {/*          <Col span={14}>{`${ms2mts((row * size + col) * 2.5).toFixed(0)}~${(ms2mts((row * size + col + 1) * 2.5) - 1).toFixed(0)}`}(kts)</Col>*/}
        {/*        </Row>*/}
        {/*      })*/}
        {/*    }*/}
        {/*  </Col >*/}
        {/*})*/}
        {/*}*/}
        <Row span={24}>
          {(meteoStatus['Swell'] || meteoStatus['Wave']) && <div style={{ display: 'flex', 'flexDirection': 'row', 'flexwrap': 'nowrap', 'align-items': 'center' }}>
            <span className={style.legendTitle}>Swell / Sig.Wave:</span>
            {
              waves.map((t: any, index: number) => <Row align="middle" key={index}>
                <span className={style.tag} style={{ backgroundColor: t.color }}>{t.label}</span>
                {/*<Col style={{ paddingLeft: '12px' }} span={14}>{t.label}</Col>*/}
              </Row>
              )
            }
          </div>
          }
        </Row>
        <Row span={24}>
          {meteoStatus['Current'] && <div style={{ 'display': 'flex', 'align-items': 'center' }}>
            <span className={style.legendTitle}>Current:</span>
            <div className={style.linear}>
              <span style={{ width: '14.285714285714286%' }}>kt</span>
              <span style={{ width: '14.285714285714286%' }}>0</span>
              <span style={{ width: '14.285714285714286%' }}>0.4</span>
              <span style={{ width: '14.285714285714286%' }}>0.8</span>
              <span style={{ width: '14.285714285714286%' }}>1.6</span>
              <span style={{ width: '14.285714285714286%' }}>2.0</span>
              <span style={{ width: '14.285714285714286%' }}>3.2</span>
            </div>
          </div>
          }
        </Row>
        <Row span={24}>
          {meteoStatus['Typhoon'] && <div style={{ 'display': 'flex', 'flexDirection': 'row', 'flexWrap': 'nowrap', 'columnGap': '10px', 'alignItems': 'center' }}>
            <span className={style.legendTitle}>Typhoon(kts):</span>
            {
              typhoons.map((item, index) => {
                const logo = index === 0 ? <div className={style.dot} /> : <img src={require(`static/image/cat${index}.png`)} />
                return <Row align="middle" key={index}>
                  <div style={{ 'display': 'flex', 'alignItems': 'center' }}>{logo}{item.label}</div>
                </Row>
              })
            }
          </div>
          }
        </Row>
      </Col >
    </div>
  }
  return <div className={classnames([style.legend, style[className]])}>
    {content()}
  </div >
}

export default Legend
