import { CommonInitItemEvent } from "@/types/event";
import { InvStatementFixedExpensesType } from "./type";
import { FleetOptionType } from "@/types/advancedType";

export const initialStatementFixedExpensesItem: CommonInitItemEvent<InvStatementFixedExpensesType> = (item) => {
  return {
    cve: item?.cve ?? "",
    ilohc: item?.ilohc ?? "",
    cvePeriodDay: item?.cvePeriodDay ?? 1,
    cvePeriodValue: item?.cvePeriodValue ?? ""
  }
}

export const cveCycleOptions: FleetOptionType<
  "M" | "Y",
  InvStatementFixedExpensesType['cvePeriodDay']
>[] = [
    { label: "M", value: 1 },
    { label: "Y", value: 2 }
  ]