import React from "react";
import classnames from "classnames";
import style from "./index.module.less";
import { PreOperationDetails, PreOperationVoyageList } from "./components";
import useIndex from "./hooks/useIndex";

const VoyagemanagePreoperation: React.FC<{}> = (props) => {
	const {
		loading,
		dataSource,
		activeIndex,
		activeItem,
		handleVoyageSelect,
		handleSearch,
		handleVoyageAdd,
		handleAdd,
		handleVoyageUpdate,
		activeVoyageType,
		handleVoyageDelete
	} = useIndex();

	return (
		<div className={classnames(style["voyagemanage-preOperation"], "voyagemanage-common")}>
			<PreOperationVoyageList
				loading={loading}
				items={dataSource}
				activeIndex={activeIndex}
				onAdd={handleVoyageAdd}
				onSearch={handleSearch}
				onSelect={handleVoyageSelect}
				onDelete={handleVoyageDelete}
			/>
			<PreOperationDetails
				activeItem={activeItem}
				onAdd={handleAdd}
				onUpdate={handleVoyageUpdate}
				voyageType={activeItem?.voyageType ?? activeVoyageType}
			/>
		</div>
	);
};

export default VoyagemanagePreoperation;
