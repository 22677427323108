import React, { FC } from "react";
import style from "./index.module.less";
import { RoutePortCurrentProps } from "./type";
import { RoutePortCurrentItem } from "./components";
import classnames from "classnames";

const RoutePortCurrent: FC<RoutePortCurrentProps> = ({ items }) => {
	return (
		<div className={classnames(style["route-portCurrent"], "fleet-nonScrolBar")}>
			<div className="portCurrent-container">
				{items?.map((item, index) => {
					return <RoutePortCurrentItem key={index} item={item} />;
				})}
				{items?.length <= 0 && (
					<span className="portCurrent-empty">Please select a port first</span>
				)}
			</div>
		</div>
	);
};

export default RoutePortCurrent;
