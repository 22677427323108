import React from "react";
import style from "./index.module.less";
import { MovementOffHireItemType, MovementOffHireProps } from "./type";
import { FleetPrompt } from "@/common";
import { FleetDatePicker, FleetIcon, FleetInput, FleetLoading } from "@/components";
import dayjs, { Dayjs } from "dayjs";
import { Button } from "@/_components";
import useIndex from "./hooks/useIndex";
import FleetTabs from "@/components/Tabs";
import { tctcTabs } from "./source";

const MovementOffHire: React.FC<MovementOffHireProps> = ({
	hide,
	onClose,
	onConfirm,
	voyageId,
	voyageType
}) => {
	const {
		dataSource,
		loading,
		summary,
		activeTctcType,
		handleTabSelect,
		saveMevementOffHire,
		commit,
		change,
		add,
		remove
	} = useIndex(voyageId, voyageType, hide, onConfirm, onClose);

	return (
		<FleetPrompt hide={hide} mode="fixed">
			<div className={style["movement-offHire"]}>
				<FleetLoading loading={loading} />
				<div className="offHire-header">
					<span className="fleet-title">Off Hire Calculator</span>
					<FleetIcon type="FleetCloseIcon" outerLayerClassname="header-icon" onClick={onClose} />
				</div>
				<FleetTabs
					list={tctcTabs}
					onClick={handleTabSelect}
					value={activeTctcType}
					className="offHire-tabs"
				/>
				<div className="offHire-container">
					<div className="offHireItem-common offHireItem-header">
						<span className="offHireItem">From (GMT)</span>
						<span className="offHireItem">To (GMT)</span>
						<span className="offHireItem">Duration (Days)</span>
						<span className="offHireItem">FO (MT)</span>
						<span className="offHireItem">DO (MT)</span>
						<span className="offHireItem">Remarkls</span>
						<span className="offHireItem"></span>
					</div>
					<div className="offHireItem-scrollArea fleet-nonScrolBar">
						{dataSource?.map((item, idx) => {
							return (
								<div key={idx} className="offHireItem-common offHireItem-container">
									<div className="offHireItem">
										<FleetDatePicker
											showHour
											showTime
											allowClear
											format="YYYY-MM-DD HH:mm"
											showMinute
											showSecond={false}
											value={item?.startDatetime ? dayjs(item?.startDatetime) : null}
											onChange={(val, timeStramp) => {
												const params: Partial<MovementOffHireItemType> = {
													startDatetime: timeStramp?.valueOf()
												};
												if (item?.endDatetime) {
													params.duration = +(
														dayjs(item?.endDatetime)?.diff(timeStramp) /
														(1000 * 60 * 60 * 24)
													)?.toFixed(2);
												}
												//  else if (item?.duration) {
												// 	params.endDatetime = dayjs(timeStramp)
												// 		?.add(item?.duration, "day")
												// 		?.valueOf();
												// }
												commit(idx, params);
											}}
											// disabledDate={(current) => {
											// 	return (
											// 		(current && item?.endDatetime && current.isAfter(item?.endDatetime)) ||
											// 		current.isSame(item?.endDatetime)
											// 	);
											// }}
										/>
									</div>
									<div className="offHireItem">
										<FleetDatePicker
											showHour
											showTime
											allowClear
											format="YYYY-MM-DD HH:mm"
											// disabled={!item?.startDatetime}
											showMinute
											showSecond={false}
											value={item?.endDatetime ? dayjs(item?.endDatetime) : null}
											onChange={(val, timeStramp) => {
												const params: Partial<MovementOffHireItemType> = {
													endDatetime: timeStramp?.valueOf()
												};
												// if (!item?.startDatetime) return;
												if (item?.startDatetime) {
													params.duration = +(
														timeStramp?.diff(item?.startDatetime) /
														(1000 * 60 * 60 * 24)
													)?.toFixed(2);
												}
												commit(idx, params);
											}}
										/>
									</div>
									<div className="offHireItem">
										<FleetInput
											type="number"
											value={item?.duration}
											// disabled={!item?.startDatetime}
											onChange={(val: number) => {
												// if (!item?.startDatetime) return;
												const params: Partial<MovementOffHireItemType> = {
													duration: val,
													startDatetime: null,
													endDatetime: null
													// endDatetime: dayjs(item?.startDatetime)?.add(val, "day")?.valueOf()
												};
												commit(idx, params);
											}}
										/>
									</div>
									<div className="offHireItem">
										<FleetInput
											type="number"
											value={item?.offHireFo}
											onChange={(val: number) => {
												change(idx, "offHireFo", val);
											}}
										/>
									</div>
									<div className="offHireItem">
										<FleetInput
											type="number"
											value={item?.offHireDo}
											onChange={(val: number) => {
												change(idx, "offHireDo", val);
											}}
										/>
									</div>
									<div className="offHireItem">
										<FleetInput
											value={item?.remark}
											onChange={(val: string) => {
												change(idx, "remark", val);
											}}
										/>
									</div>
									<div className="offHireItem">
										<FleetIcon
											type="FleetPortAddIcon"
											outerLayerClassname="offHireItem-icon"
											onClick={() => add(idx)}
										/>
										<FleetIcon
											type="FleetPortDelIcon"
											outerLayerClassname="offHireItem-icon"
											onClick={() => remove(idx)}
										/>
									</div>
								</div>
							);
						})}
					</div>
				</div>
				<div className="offHire-count">
					<span>Total</span>
					<div>
						<span>{summary?.duration ?? 0}</span>
						<span>{summary?.offHireFo ?? 0}</span>
						<span>{summary?.offHireDo ?? 0}</span>
					</div>
				</div>
				<div className="offHire-footer">
					<Button type="secondary" size="small" onClick={onClose}>
						Cancel
					</Button>
					<Button size="small" onClick={saveMevementOffHire}>
						Confirm
					</Button>
				</div>
			</div>
		</FleetPrompt>
	);
};

export default MovementOffHire;
