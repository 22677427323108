import { FleetSelect } from "@/components";
import React, { FC } from "react";
import useIndex from "./hooks/useIndex";
import { CommonCompanySelectProps } from "./type";
import { CommonCompanyPopup } from "./components";

const CommonCompanySelect: FC<CommonCompanySelectProps> = ({
  activeItems,
  onFinish,
  disabled,
  className,
  showCount,
  placeholder,
  primaryKey = "id",
  showKey
}) => {
  const {
    loading,
    list,
    open,
    loadMore,
    closePopup,
    focusPopup,
    searchValue,
    tagRender,
    handleSearch,
    handleItemSelect,
    selectValue,
    handleClear
  } = useIndex(
    activeItems,
    showKey,
    onFinish,
    primaryKey,
    disabled
  );

  return <FleetSelect
    value={selectValue}
    showSearch
    allowClear
    placeholder={placeholder}
    showCount={showCount}
    popupMatchSelectWidth={false}
    onClear={handleClear}
    className={className}
    onDropdownVisibleChange={closePopup}
    disabled={disabled}
    mode={Array?.isArray(activeItems) ? "multiple" : undefined}
    open={open}
    onSearch={handleSearch}
    onFocus={() => focusPopup()}
    tagRender={tagRender}
    dropdownRender={() => <CommonCompanyPopup
      loadMore={loadMore}
      list={list}
      loading={loading}
      onSelect={handleItemSelect}
    />}
  />
}

export default CommonCompanySelect;

