import { CommonInitItemEvent } from "@/types/event";
import { CommonNewlyCompanyItemType } from "./type";

export const initialCompanyItem: CommonInitItemEvent<CommonNewlyCompanyItemType> = (item) => {
	return {
		id: item?.id ?? null,
		companyAddress: item?.companyAddress ?? "",
		companyName: item?.companyName ?? "",
		companyWebsite: item?.companyWebsite ?? ""
	};
};

export const checkKeys: (keyof CommonNewlyCompanyItemType)[] = ["companyName"];
