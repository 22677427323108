import { useEffect, useMemo, useState } from "react";
import { MovementTaskListProps, initialMovementTaskItem } from "../components";
import { useAppDispatch } from "@/hook";
import { useNavigate, useSearchParams } from "react-router-dom";
import useReminder from "@/hook/useReminder";
import {
	getMovementVoyageTaskAction,
	saveMovementTaskConfigAction
} from "@/action/voyageManage/movement";
import { MovementTaskStageItemChangeEvent, MovementTaskStageItemType } from "../type";
import { initialMovementTaskStageItem } from "../source";
import { VoyageType } from "@/types/common";

const useIndex = (voyageId: string, refresh: boolean, voyageType: VoyageType) => {
	const [searchParams] = useSearchParams();
	const dispatch = useAppDispatch();
	const navigate = useNavigate();
	const { reminder } = useReminder();

	const [loading, setLoading] = useState(false);

	const currentStage = searchParams?.get(
		"stage"
	) as MovementTaskListProps["items"][number]["stage"];
	const currentPortRotationId = searchParams?.get("port_rotation_id") as unknown as number;

	const [open, setOpen] = useState(!!searchParams?.get("task_open") || false);
	const [activeItem, setActiveItem] = useState<MovementTaskListProps["items"][number]>(
		voyageType === "TCTC"
			? currentStage
				? initialMovementTaskItem({ stage: currentStage })
				: null
			: currentStage && currentPortRotationId
				? initialMovementTaskItem({ stage: currentStage, portRotationId: currentPortRotationId })
				: null
	);
	const [dataSource, setDataSource] = useState<MovementTaskListProps["items"]>([]);

	const [activeStageItem, setActiveStageItem] = useState<MovementTaskStageItemType>(
		initialMovementTaskStageItem()
	);

	const handleStageSelect: MovementTaskStageItemChangeEvent = (item) => {
		saveMovementTaskConfig(item);
	};

	const handleTaskSelect: MovementTaskListProps["onSelect"] = (item) => {
		if (item?.stage === activeItem?.stage && item?.portRotationId === activeItem?.portRotationId) {
			setActiveItem(null);
			setOpen(false);
			return;
		}
		setActiveItem(item);
		setOpen(true);
	};

	const handleTaskClose = () => {
		setOpen(false);
		setActiveItem(null);
	};

	const saveMovementTaskConfigFront = () => {
		setLoading(true);
	};

	const saveMovementTaskConfigFailed = (error) => {
		setLoading(false);
		reminder("error", error?.msg ? error?.msg + ": " + error?.data : error?.data);
	};

	const saveMovementTaskConfig: MovementTaskStageItemChangeEvent = (item) => {
		saveMovementTaskConfigAction(
			{
				...activeStageItem,
				...item
			},
			saveMovementTaskConfigFront,
			(response) => {
				setActiveStageItem((prev) => ({ ...prev, ...item }));
				setLoading(false);
			},
			saveMovementTaskConfigFailed,
			dispatch,
			navigate
		);
	};

	const getMovementVoyageTaskSuccess = ({ data }) => {
		if (data) {
			const { dataList, ...rest } = data;
			setDataSource(dataList ?? []);
			setActiveStageItem(initialMovementTaskStageItem(rest));
		}
	};

	const getMovementVoyageTaskFailed = (error) => {
		reminder("error", error?.msg ? error?.msg + ": " + error?.data : error?.data);
	};

	const getMovementVoyageTask = () => {
		getMovementVoyageTaskAction(
			{ voyageId },
			null,
			getMovementVoyageTaskSuccess,
			getMovementVoyageTaskFailed,
			dispatch,
			navigate
		);
	};

	useEffect(() => {
		getMovementVoyageTask();
	}, [refresh]);

	return {
		loading,
		open,
		setOpen,
		activeItem,
		activeStageItem,
		dataSource,
		handleTaskSelect,
		handleTaskClose,
		handleStageSelect
	};
};

export default useIndex;
