import React, { useEffect, useMemo } from "react";
import style from "./index.module.less";
import { StowagePlanFormPropsType } from "./type";
import {} from "@/common";
import { FleetInput } from "@/components";
import useIndex from "./hook/useIndex";
import { stowagePlanTableConfig } from "./source";

const StowagePlan: React.FC<StowagePlanFormPropsType> = ({
  stowagePlanData,
  onChange,
}) => {
  const {
    stowagePlanTableDisabled,
    onMouse,
    cellValue,
    first,
    setStowagePlanTableDisabled,
    setFirst,
  } = useIndex(onChange);

  const stowagePlanTableConfigNew = useMemo(() => {
    if (stowagePlanData.length == 0) return stowagePlanTableConfig;
    const plans = Object.keys(stowagePlanData[0]) || [];
    const config = JSON.parse(JSON.stringify(stowagePlanTableConfig)) || [];
    plans.map((item) => {
      if (item.indexOf("-") != -1)
        config.splice(2, 0, {
          colSpan: 1,
          rowSpan: 1,
          label: item,
          prop: item,
        });
    });
    return config;
  }, [stowagePlanData]);

  const totalSum = useMemo(() => {
    const sums = {};
    stowagePlanTableConfigNew?.forEach((item) => {   
      // console.log(item.prop)   
      if (["stowagePlan"].includes(item.prop)) {
        sums[item.prop] = stowagePlanData
          .reduce((acc, ele) => acc + Number(ele[item.prop]), 0)
          ?.toFixed(2)
          .replace(/(\d)(?=(\d{3})+\.)/g, "$1,");
      }
      if (item.prop == "ttl") {
        let ttlSum = 0
        stowagePlanData?.map((ele) => {
          let ttl = 0;
          for (const [k, v] of Object.entries(ele)) {
            if (k.indexOf("-") != -1) ttl += Number(v);
          }
          ttlSum +=ttl
        });
        sums['ttl'] = ttlSum?.toFixed(2)
        .replace(/(\d)(?=(\d{3})+\.)/g, "$1,");
      }
      if (item.prop == "cgo") {
        let cgoSum = 0
        stowagePlanData?.map((ele) => {
          let ttl = 0;
          for (const [k, v] of Object.entries(ele)) {
            if (k.indexOf("-") != -1) ttl += Number(v);
          }
          cgoSum +=(ele.stowagePlan - ttl)
        });
        sums['cgo'] = cgoSum?.toFixed(2)
        .replace(/(\d)(?=(\d{3})+\.)/g, "$1,");
      }
      if(item.prop.indexOf("-") != -1){
        let daykeys = []
        for (const [k, v] of Object.entries(stowagePlanData[0])) {
          if (k.indexOf("-") != -1) daykeys.push(k)
        }
        daykeys.forEach(ele=>{
          sums[ele] = stowagePlanData
          .reduce((acc, eleItem) => acc + Number(eleItem[ele]), 0)
          ?.toFixed(2).replace(/(\d)(?=(\d{3})+\.)/g, "$1,");
        })
      }
    });
    return sums;
  }, [stowagePlanData]);

  useEffect(() => {
    if (stowagePlanData.length != 0 && first) {
      const rows = [];
      stowagePlanData.map((item, index) => {
        rows.push({});
        for (let [k, v] of Object.entries(item)) {
          if (k.indexOf("-") != -1 || k == "stowagePlan") {
            rows[index][k] = true;
          }
        }
      });
      setFirst(false);
      setStowagePlanTableDisabled(rows);
    }
  }, [stowagePlanTableConfigNew]);
  return (
    <div className={style["portReport-stowagePlan"]}>
      <div className="port-report-title">Stowage Plan</div>
      <div className="stowage-plan-table">
        <table cellSpacing={0}>
          <thead className="stowage-plan-table-thead">
            <tr>
              {stowagePlanTableConfigNew.map((item, index) => {
                return (
                  <th className="stowage-plan-table-header" key={index}>
                    {item.label}
                  </th>
                );
              })}
            </tr>
          </thead>
          <tbody className="stowage-plan-table-tbody">
            {stowagePlanData.map((row, index) => {
              return (
                <tr key={index}>
                  {stowagePlanTableConfigNew.map((item, idx) => {
                    return (
                      <td key={idx}>
                        <div
                          onMouseEnter={() =>
                            onMouse(index, item.prop, "enter")
                          }
                          onMouseLeave={() =>
                            onMouse(index, item.prop, "leave")
                          }
                        >
                          {!stowagePlanTableDisabled?.[index]?.[item.prop] &&
                          !["warehouse", "ttl", "cgo"].includes(item.prop) ? (
                            <FleetInput
                              value={stowagePlanData?.[index]?.[item.prop]}
                              onChange={(val) => {

                                onChange(val, index, item.prop);
                              }}
                              type="number"
                            ></FleetInput>
                          ) : (
                            cellValue(
                              stowagePlanData?.[index],
                              item.prop,
                              index
                            ) || "--"
                          )}
                        </div>
                      </td>
                    );
                  })}
                </tr>
              );
            })}
            <tr></tr>
          </tbody>
          <tfoot className="stowage-plan-table-tfoot">
            <tr>
              {stowagePlanTableConfigNew.map((item, index) => {
                if (index == 0) {
                  return <td key={index}>Total</td>;
                } else
                  return (
                    <td key={index} colSpan={item.colSpan}>
                      {totalSum?.[item.prop] || ""}
                    </td>
                  );
              })}
            </tr>
          </tfoot>
        </table>
      </div>
    </div>
  );
};

export default StowagePlan;
