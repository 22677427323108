import { useEffect, useState } from "react";
import useOtherIndex from "./useOtherIndex";
import { useBunkerClauseIndex, useBunkerCoseIndex } from "../components";
import { useAppDispatch } from "@/hook";
import { useNavigate } from "react-router-dom";
import useReminder from "@/hook/useReminder";
import { MovementTctcBunkerPlanProps, MovementTctcBunkerPlanTabType } from "../type";
import { onRequest } from "@/action";
import { BunkerPlanRentMap } from "../source";

const useIndex = (
	voyageId: MovementTctcBunkerPlanProps["voyageId"],
	hide: boolean,
	onConfirm: MovementTctcBunkerPlanProps["onConfirm"],
	onClose?: () => void
) => {
	const dispatch = useAppDispatch();
	const navigate = useNavigate();
	const { reminder } = useReminder();

	const [loading, setLoading] = useState(false);

	const [activeTctcType, setActiveTctcType] =
		useState<MovementTctcBunkerPlanTabType>("rentBunkerPlan");

	const {
		dataSource: bunkerPlan,
		change: handleBunkerPlanChange,
		init: handleBunkerPlanInit
	} = useOtherIndex();

	const {
		dataSource: bunkerClause,
		change: handleBunkerClauseChange,
		commit: handleBunkerClauseCommit,
		init: handleBunkerClauseInit
	} = useBunkerClauseIndex();

	const {
		summary: bunkerCostSummary,
		hsfoDataSource,
		dataSource: bunkerCost,
		change: handleBunkerCostChange,
		commit: handleBunkerCostCommit,
		init: handleBunkerCostInit,
		handleBorPriceCommit,
		handleCalcTotalCost
	} = useBunkerCoseIndex({
		borAbout: bunkerClause?.borAbout,
		borPrice: bunkerClause?.borPrice,
		scrubberBenefit: bunkerPlan?.scrubberBenefit,
		scrubberPrice: bunkerPlan?.scrubberPrice
	});

	const handleTabSelect = (value: MovementTctcBunkerPlanTabType) => {
		if (activeTctcType === value) return;
		const totalCost = handleCalcTotalCost();
		onRequest(
			"saveMovementBunkerCostApi",
			{
				type: BunkerPlanRentMap[activeTctcType],
				bunkerPlan: {
					...bunkerPlan,
					...totalCost
				},
				bunkerCostDetails: bunkerClause,
				dataList: bunkerCost,
				bunkerPortCost: []
			},
			getMovementBunkerCostFront,
			(response) => {
				setLoading(false);
				onConfirm?.(value, { ...bunkerPlan, ...totalCost });
				setActiveTctcType(value);
			},
			getMovementBunkerCostFailed,
			dispatch,
			navigate
		);
	};

	const getMovementBunkerCostFront = () => {
		setLoading(true);
	};

	const getMovementBunkerCostSuccess = ({ data }) => {
		setLoading(false);
		handleBunkerClauseInit(data?.bunkerCostDetails);
		handleBunkerPlanInit(data?.bunkerPlan);
		handleBunkerCostInit(data?.dataList);
	};

	const getMovementBunkerCostFailed = (error) => {
		setLoading(false);
		reminder("error", error?.msg ? error?.msg + ": " + error?.data : error?.data);
	};

	const getMovementBunkerCost = () => {
		onRequest(
			"getMovementBunkerCostApi",
			{ voyageId, type: BunkerPlanRentMap[activeTctcType] },
			getMovementBunkerCostFront,
			getMovementBunkerCostSuccess,
			getMovementBunkerCostFailed,
			dispatch,
			navigate
		);
	};

	const saveMovementBunkerCost = () => {
		const totalCost = handleCalcTotalCost();
		onRequest(
			"saveMovementBunkerCostApi",
			{
				type: BunkerPlanRentMap[activeTctcType],
				bunkerPlan: {
					...bunkerPlan,
					...totalCost
				},
				bunkerCostDetails: bunkerClause,
				dataList: bunkerCost,
				bunkerPortCost: []
			},
			getMovementBunkerCostFront,
			(response) => {
				setLoading(false);
				onConfirm?.(activeTctcType, { ...bunkerPlan, ...totalCost });
				onClose?.();
			},
			getMovementBunkerCostFailed,
			dispatch,
			navigate
		);
	};

	useEffect(() => {
		if (hide) return;
		if (!voyageId) return;
		getMovementBunkerCost();
	}, [voyageId, hide, activeTctcType]);

	return {
		loading,
		hsfoDataSource,
		bunkerClause,
		handleBunkerClauseChange,
		handleBunkerClauseCommit,
		bunkerPlan,
		handleBunkerPlanChange,
		bunkerCost,
		bunkerCostSummary,
		handleBunkerCostChange,
		handleBunkerCostCommit,
		handleBorPriceCommit,
		saveMovementBunkerCost,
		activeTctcType,
		handleTabSelect
	};
};

export default useIndex;
