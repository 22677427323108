import { useCallback, useEffect, useMemo, useState } from "react";
import { updateFreightSenParams } from "@/api/estimation/freightSensitivity";
import { getFreightSenAction, updateFreightSenAction } from "@/action/estimation/freightSensitivity";
import { useAppDispatch } from "@/hook";
import { useNavigate } from "react-router-dom";
import useReminder from "@/hook/useReminder";

export type FreightSenProps = Pick<updateFreightSenParams, Exclude<keyof updateFreightSenParams, "id">>;

export type FreightSenChangeEvent = (key: Exclude<keyof updateFreightSenParams, "id">, value: number) => void;

export type FreightSenOriginSource = {
  freightRate: number | ""
  cargoQuantity: number | ""
  // freightAddCommission: number | ""
  // freightBrokerage: number | ""
  commission: number | ""
  brokerage: number | ""
  operationCost: number | ""
  totalHireCost: number | ""
  totalPortDays: number | ""
  totalSeaDays: number | ""
}

export const useCountRate = (
  freightSen: FreightSenProps,
  originSource: FreightSenOriginSource
) => {
  const {
    frtMidValue,
    tceMidValue,
    frtStep,
    tceStep
  } = freightSen;

  const getTceResult = useCallback((frtMidValue: number, originSource: FreightSenOriginSource) => {
    const freightIncome = +frtMidValue * +originSource?.cargoQuantity;
    return ((freightIncome - freightIncome * +originSource?.commission / 100 - freightIncome * +originSource?.brokerage / 100) - +originSource?.operationCost) / ((+originSource?.totalSeaDays + +originSource?.totalPortDays) || 1)
  }, [frtMidValue, frtStep, originSource]);

  const getFrtResult = useCallback((tceMidValue: number, originSource: FreightSenOriginSource) => {
    const freightIncome = +originSource?.freightRate * +originSource?.cargoQuantity;
    return (+tceMidValue * (+originSource?.totalSeaDays + +originSource?.totalPortDays) + freightIncome * +originSource?.commission / 100 + freightIncome * +originSource?.brokerage / 100 + +originSource?.operationCost) / (+originSource?.cargoQuantity || 1)
  }, [tceMidValue, tceStep, originSource])

  const tceResulGroup = useMemo(() => {
    let frtSValue = +frtMidValue - +frtStep * 5;
    let frtEValue = +frtMidValue + +frtStep * 5;
    console.log("tceSValue", frtSValue, frtEValue, tceStep, frtStep)
    const result = [];
    if (frtStep == 0) return result
    for (let i = frtSValue; i <= frtEValue; i += +frtStep) {
      result.push({
        rate: i,
        res: +getTceResult(i, originSource).toFixed(3)
      })
    }
    return result
  }, [frtStep, frtMidValue])

  const frtResulGroup = useMemo(() => {
    let tceSValue = +tceMidValue - +tceStep * 5;
    let tceEValue = +tceMidValue + +tceStep * 5;
    console.log("tceSValue", tceSValue, tceEValue, tceStep, frtStep)
    const result = [];
    if (tceStep == 0) return result
    for (let i = tceSValue; i <= tceEValue; i += +tceStep) {
      console.log('i', i, tceEValue)
      result.push({
        rate: i,
        res: +getFrtResult(i, originSource).toFixed(3)
      })
    }
    return result
  }, [tceStep, tceMidValue])

  console.log("frtResulGroup", { frtResulGroup, tceResulGroup, tceMidValue, tceStep })

  // let tceSValue = tceMidValue - tceStep * 5;
  // let tceEValue = tceMidValue + tceStep * 5;
  // console.log("tceSValue",tceSValue,tceEValue,tceStep)
  // const result = [];
  // for (let i = tceSValue; i <= tceEValue; i +=tceStep) {
  //   console.log('i', i, tceEValue)
  //   result.push({
  //     rate: i,
  //     res: +getFrtResult(i, originSource).toFixed(3)
  //   })
  // }
  // console.log("restceStep",result)

  return {
    tceResulGroup,
    frtResulGroup
  }
}

const useFreighSensitivity = (
  id: string | number,
  hide: boolean,
  midValueGroup: {
    freightRate: number | "",
    tce: number
  },
  onConfirm?: (data: FreightSenProps) => void
) => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { reminder } = useReminder();

  const [loading, setLoading] = useState(false);
  const [freightSen, setFreightSen] = useState<FreightSenProps>({
    frtMidValue: +midValueGroup?.freightRate,
    frtStep: 0,
    tceMidValue: +midValueGroup?.tce,
    tceStep: 0
  })

  const change: FreightSenChangeEvent = (key, value) => {
    setFreightSen(prev => {
      return {
        ...prev,
        [key]: value
      }
    })
  }

  const getFreightSenFront = () => {
    setLoading(true);
  }

  const getFreightSenSuccess = (response) => {
    setLoading(false);
    setFreightSen(prev => {
      return {
        ...prev,
        // frtMidValue:response?.data?.frtMidValue,
        frtStep: response?.data?.frtStep || 0.5,
        // tceMidValue:response?.data?.tceMidValue,
        tceStep: response?.data?.tceStep || 100,
        frtMidValue: response?.data?.frtMidValue || +midValueGroup?.freightRate,
        tceMidValue: response?.data?.tceMidValue || +midValueGroup?.tce,
      }
    })
  }

  const getFreightSenError = (error) => {
    setLoading(false);
    reminder("error", error?.msg ? (error?.msg + ": " + error?.data) : error?.data)
  }

  const getFreightSen = () => {
    getFreightSenAction(
      { resultId: id },
      getFreightSenFront,
      getFreightSenSuccess,
      getFreightSenError,
      dispatch,
      navigate
    )
  }

  const updateFreightSenFront = () => {
    setLoading(true);
  }

  const updateFreightSenSuccess = (response) => {
    setLoading(false);
    onConfirm?.(freightSen);
    reminder("success", response?.msg)
  }

  const updateFreightSenError = (error) => {
    setLoading(false);
    reminder("error", error?.msg ? (error?.msg + ": " + error?.data) : error?.data)
  }

  const updateFreightSen = () => {
    if (!id) return;
    updateFreightSenAction(
      { id: id, ...freightSen },
      updateFreightSenFront,
      updateFreightSenSuccess,
      updateFreightSenError,
      dispatch,
      navigate
    )
  }

  useEffect(() => {
    if (hide) return;
    if (!id) return;
    getFreightSen()
  }, [id, hide])


  return {
    freightSen,
    loading,
    change,
    updateFreightSen
  }
}

export default useFreighSensitivity