import { useState } from "react";
import { PortListTabType } from "../components/tab/type";
import { VesselItemType } from "../components/vesselItem/type";
import { getPortVesselSummaryAction } from "@/action/monitoring/portList";
import useReminder from "@/hook/useReminder";
import { useAppDispatch } from "@/hook";
import { useNavigate } from "react-router-dom";
import dayjs from "dayjs";
import { useMonitoringContext } from "@/pages/monitoring/MonitoringContext";
import { MonitoringPortListProps } from "../type";

const useIndex = (
	onOpenDetailTab: MonitoringPortListProps["onOpenDetailTab"],
	onChangeSearchParams: MonitoringPortListProps["onChangeSearchParams"],
	searchParams: MonitoringPortListProps["searchParams"]
) => {
	const { reminder } = useReminder();
	const dispatch = useAppDispatch();
	const navigate = useNavigate();
	const [loading, setLoading] = useState<boolean>(false);
	const [vesselData, setVesselData] = useState<VesselItemType[]>([]);
	const portListActionFront = () => {
		setLoading(true);
	};
	const portListApiError = (error) => {
		setLoading(false);
		reminder("error", error?.msg ? error?.msg + ": " + error?.data : error?.data);
	};
	const handleChangeTab = (val: 1 | 2 | 3) => {
		onChangeSearchParams?.({ termType: val });
	};
	const getPortVesselListSuccess = (res) => {
		setLoading(false);
		setVesselData(res?.data || []);
		onOpenDetailTab?.("portAreaDetails");
	};
	const getPortVesselList = () => {
		if (
			(searchParams?.termType == 3 && !searchParams?.dateRangeHistory?.startDate) ||
			(searchParams?.termType == 1 && !searchParams?.dateRangeExpert?.startDate) ||
			!searchParams?.currentPort?.portCode
		) {
			setVesselData([]);
			return;
		}
		let startDate = dayjs().format("YYYY-MM-DD");
		let endDate = dayjs().format("YYYY-MM-DD");
		if (searchParams?.termType == 3) {
			if (!searchParams?.dateRangeHistory?.startDate) return;
			startDate = searchParams?.dateRangeHistory?.startDate;
			endDate = searchParams?.dateRangeHistory?.endDate;
		}
		if (searchParams?.termType == 1) {
			if (!searchParams?.dateRangeExpert?.startDate) return;
			startDate = searchParams?.dateRangeExpert?.startDate;
			endDate = searchParams?.dateRangeExpert?.endDate;
		}
		getPortVesselSummaryAction(
			{
				type: searchParams?.termType,
				startDate,
				endDate,
				portCode: searchParams?.currentPort?.portCode
			},
			portListActionFront,
			getPortVesselListSuccess,
			portListApiError,
			dispatch,
			navigate
		);
	};
	return {
		vesselData,
		getPortVesselList,
		handleChangeTab,
		loading
	};
};

export default useIndex;
