import React, { FC, Fragment } from "react";
import { InvFreightPortItemType, InvFreightPortProps } from "./type";
import { InvoiceAddTo } from "@/pages/voyagemanage/business/invoices/module";
import { FleetFormItem, FleetSelect, FleetVerifyInput } from "@/components";
import { CommonPortSelect } from "@/common";
import { rateTypeOptions } from "./source";

const InvFreightPort: FC<InvFreightPortProps> = ({
	loadItems,
	dischargeItems,
	onChange,
	onCommit,
	otherItem,
	onOtherItemChange
}) => {
	return (
		<Fragment>
			<InvoiceAddTo type="addDesDem" value={otherItem?.addDesDem} onChange={onOtherItemChange}>
				<span className="invoicesForm-subheading">DES/DEM</span>
			</InvoiceAddTo>
			{loadItems?.map((loadItem, loadIndex) => {
				return (
					<div className="invoicesForm-container freightInv-desContainer" key={loadIndex}>
						<FleetFormItem label="Load Port" needVerity>
							<CommonPortSelect
								primaryKey="portName"
								showKey="portName"
								activeItems={{
									portName: loadItem?.portName
								}}
								onFinish={(newItem, type) => {
									if (newItem?.portName === loadItem?.portName) return;
									onChange?.("load", loadIndex, "portName", newItem?.portName);
								}}
							/>
						</FleetFormItem>
						<FleetFormItem label="Type" needVerity>
							<FleetSelect
								value={[loadItem?.rateType]}
								options={rateTypeOptions}
								onChange={(val: InvFreightPortItemType["rateType"]) => {
									const params: Partial<Pick<InvFreightPortProps["loadItems"][number], "rate" | "rateType">> = {
										rateType: val
									};
									if (val === "NOT") {
										params.rate = 0;
									}
									onCommit?.("load", loadIndex, params);
								}}
							/>
						</FleetFormItem>
						<FleetVerifyInput
							label="Amount"
							needVerity
							value={loadItem?.rate}
							type="number"
							onChange={(val: number) => {
								onChange?.("load", loadIndex, "rate", val);
							}}
						/>
					</div>
				);
			})}
			{dischargeItems?.map((dischargeItem, dischargeIndex) => {
				return (
					<div className="invoicesForm-container freightInv-desContainer" key={dischargeIndex}>
						<FleetFormItem label="Discharge Port" needVerity>
							<CommonPortSelect
								primaryKey="portName"
								showKey="portName"
								activeItems={{
									portName: dischargeItem?.portName
								}}
								onFinish={(newItem, type) => {
									if (newItem?.portName === dischargeItem?.portName) return;
									onChange?.("discharge", dischargeIndex, "portName", newItem?.portName);
								}}
							/>
						</FleetFormItem>
						<FleetFormItem label="Type" needVerity>
							<FleetSelect
								value={[dischargeItem?.rateType]}
								options={rateTypeOptions}
								onChange={(val: InvFreightPortItemType["rateType"]) => {
									const params: Partial<Pick<InvFreightPortProps["loadItems"][number], "rate" | "rateType">> = {
										rateType: val
									};
									if (val === "NOT") {
										params.rate = 0;
									}
									onCommit?.("discharge", dischargeIndex, params);
								}}
							/>
						</FleetFormItem>
						<FleetVerifyInput
							label="Amount"
							needVerity
							value={dischargeItem?.rate}
							type="number"
							onChange={(val: number) => {
								onChange?.("discharge", dischargeIndex, "rate", val);
							}}
						/>
					</div>
				);
			})}
		</Fragment>
	);
};

export default InvFreightPort;
