import React, { FC } from "react";
import style from "./index.module.less";
import { InvFreightProps } from "./type";
import { InvoicesViewsContainer } from "../../common";
import useIndex from "./hooks/useIndex";
import { InvFreightBase, InvFreightDesc, InvFreightTemplate } from "./components";
import { CommonFieldAdd } from "@/common";

const InvFreight: FC<InvFreightProps> = ({
	activeInvoice,
	newly,
	activeVoyageItem,
	onInvoiceAdd,
	onInvoiceUpdate
}) => {
	const {
		loading,
		activeTab,
		activeField,
		canvasRef,
		handleTabSelect,
		handleDownPdf,
		handleSave,
		dialogItem,
		handleDialogChange,
		otherItem,
		handleOtherItemChange,
		cargoItems,
		handleCargoItemChange,
		handleCargoDetailAdd,
		handleCargoDetailDelete,
		loadDataSource,
		dischargeDataSource,
		handlePortItemChange,
		handlePortItemCommit,
		handlePortAdd,
		handlePortRemove,
		summary,
		settleItems,
		handleSettlementCommit,
		baseItem,
		handleBaseItemChange,
		handleBaseItemCommit,
		beneficiaryInfoFieldItems,
		handleBeneficiaryInfoFieldItemChange,
		counterPartyFieldItems,
		handleCounterPartyFieldItemChange,
		otherExpenses,
		handleOtherExpensesChange,
		handleOtherExpensesDelete,
		handleFieldOpen,
		handleFieldAdd
	} = useIndex(activeVoyageItem, activeInvoice, newly, onInvoiceUpdate, onInvoiceAdd);

	return (
		<InvoicesViewsContainer
			loading={loading}
			activeTab={activeTab}
			activeVoyageItem={activeVoyageItem}
			activeInvType="freightInv"
			activeInvoice={activeInvoice}
			onTabSelect={handleTabSelect}
			onDownloadPdf={handleDownPdf}
			onSave={handleSave}
			period={baseItem?.period}
			total={otherItem?.total}
			formChildren={
				<div className={style["invoices-freight"]}>
					<InvFreightBase
						item={baseItem}
						onChange={handleBaseItemChange}
						onCommit={handleBaseItemCommit}
						beneficiaryFieldList={beneficiaryInfoFieldItems}
						onBeneficiaryFieldChange={handleBeneficiaryInfoFieldItemChange}
						counterFieldList={counterPartyFieldItems}
						onCounterFieldChange={handleCounterPartyFieldItemChange}
						otherItem={otherItem}
						onFieldOpen={handleFieldOpen}
					/>
					<InvFreightDesc
						summary={summary}
						settleItem={settleItems}
						onSettlementCommit={handleSettlementCommit}
						otherItem={otherItem}
						onOtherItemChange={handleOtherItemChange}
						cargoItems={cargoItems}
						onCargoDetailsAdd={handleCargoDetailAdd}
						onCargoDetailsDelete={handleCargoDetailDelete}
						onCargoItemChange={handleCargoItemChange}
						loadPortItems={loadDataSource}
						dischargePortItems={dischargeDataSource}
						onPortDetailsAdd={handlePortAdd}
						onPortDetailsDelete={handlePortRemove}
						onPortItemChange={handlePortItemChange}
						onPortItemCommit={handlePortItemCommit}
						otherExpenses={otherExpenses}
						onOtherExpensesChange={handleOtherExpensesChange}
						onOtherExpensesDelete={handleOtherExpensesDelete}
						onFieldOpen={handleFieldOpen}
					/>
					<CommonFieldAdd
						hide={dialogItem?.field}
						mode="fixed"
						valueProp={{
							label: "Value",
							type: activeField.current === "otherItems" ? "number" : "text"
						}}
						onClose={() => handleDialogChange("field", true)}
						onAdd={handleFieldAdd}
					/>
				</div>
			}
			previewChildren={
				<InvFreightTemplate
					canvasRef={canvasRef}
					activeTab={activeTab}
					dataSource={{
						baseItem,
						descItem: settleItems,
						otherItem,
						counterPartyFieldItems: counterPartyFieldItems,
						beneficiaryFieldItems: beneficiaryInfoFieldItems,
						otherExpenses,
						summary,
						cargoItems,
						dischargeDemDes: dischargeDataSource,
						loadPortDemDes: loadDataSource
					}}
				/>
			}
		/>
	);
};

export default InvFreight;
