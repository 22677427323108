import React, { FC } from "react";
import style from "./index.module.less";
import charterBaseStyle from "../index.module.less"
import { MovementCharterBasePerformanceProps } from "./type";
import classnames from "classnames";
import { columns } from "./source";
import MovementTable from "../../common/table";

const MovementCharterBasePerformance: FC<MovementCharterBasePerformanceProps> = ({
  dataSource,
  otherItem,
  onChange,
  onEstBatchCommit,
  onEstConsumCommit
}) => {
  return <div className={classnames(charterBaseStyle["movement-charterBase--container"], style["movement-performance"])}>
    <div className="movement-details--header">
      <span className="fleet-title">Vessel Performance</span>
    </div>
    <MovementTable
      dataSource={[dataSource]}
      columns={columns}
      className="movement-charterBase--table performance-table"
      otherSource={otherItem}
      actions={{
        onChange: onChange,
        onBatchCommit: onEstBatchCommit,
        onConsumCommit: onEstConsumCommit
      }}
    />
  </div>
}

export default MovementCharterBasePerformance