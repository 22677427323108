import { ParameterType } from "@/api";
import {
	getPortCallListApi,
	downloadPortCallApi,
	getPortCallListParams,
	downPortCallListParams
} from "@/api/monitoring/portCallList";
import { loginOutAction } from "@/store/userSlice";

export const getPortCallListAction: ParameterType<getPortCallListParams> = (
	params,
	frontCallback,
	successCallback,
	errorCallback,
	dispatch,
	navigate
) => {
	frontCallback?.();
	getPortCallListApi(params)
		.then((response) => {
			successCallback?.(response);
		})
		.catch((err) => {
			errorCallback?.(err);
			if (err?.loginStatus === "token faild") {
				dispatch(loginOutAction(() => navigate?.("/login")));
			}
		});
};

export const downPortCallAction: ParameterType<downPortCallListParams[]> = (
	params,
	frontCallback,
	successCallback,
	errorCallback,
	dispatch,
	navigate
) => {
	frontCallback?.();
	downloadPortCallApi(params)
		.then((response) => {
			successCallback?.(response);
		})
		.catch((err) => {
			errorCallback?.(err);
			if (err?.loginStatus === "token faild") {
				dispatch(loginOutAction(() => navigate?.("/login")));
			}
		});
};
