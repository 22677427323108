import { HandoverFormBasicInfoItemType } from "./type";

export const initialBasicInfo = (item?: Partial<HandoverFormBasicInfoItemType>): HandoverFormBasicInfoItemType => {
  return {
    vesselName: item?.vesselName ?? "",
    dwt: item?.dwt ?? null,
    bale: item?.bale ?? null,
    grain: item?.grain ?? null,
    mmsi: item?.mmsi ?? null,
    imo: item?.imo ?? null,
    operatedBy: item?.operatedBy ?? null,
    operatedName: item?.operatedName ?? "",
    charteredBy: item?.charteredBy ?? [],
    charateList: item?.charateList ?? [],
    operateList: item?.operateList ?? []
  }
}

export const checkKeys: (keyof HandoverFormBasicInfoItemType)[] = [
  "vesselName",
  "operatedBy"
]