import { ExcludeKeys, ExtendsKeys } from "@/types";
import { PortLabelMap, PortRotationKeys } from "@/types/voyageManage/cargoBook/index";
import { Dayjs } from "dayjs";
import { CargoUnitType } from "../cargoInfo/type";

export type PortPurposeType = ["DC", "DB", "DT", "LD", "LB", "LT"][number]

export type CBPortRotationKeys = ExtendsKeys<PortRotationKeys,
  "loadingDischargeRate" | "portName" | "portCode" |
  "cargoQuantity" | "cargoUnit" | "lptt" |
  "demRate" | "desRate" | "purpose" | "portId" | "id"
>;

export type CBPortRotationNumKeys = ExtendsKeys<CBPortRotationKeys,
  "loadingDischargeRate" | "lptt" | "cargoQuantity" | "demRate" | "desRate"
>;

export type CBPortRotationEnumKeys = ExtendsKeys<CBPortRotationKeys, "cargoUnit" | "purpose">;

export type CBPortRotationIdKeys = ExtendsKeys<CBPortRotationKeys,"id">;

export type CBPortRotationStrKeys = ExcludeKeys<CBPortRotationKeys, CBPortRotationNumKeys | CBPortRotationEnumKeys | CBPortRotationIdKeys>;

export type CBPortRotationFormKeys =
  CBPortRotationNumKeys |
  CBPortRotationEnumKeys |
  CBPortRotationIdKeys |
  CBPortRotationStrKeys;

export type CBPortRotationFormType = Record<CBPortRotationNumKeys, number | ""> &
  Record<CBPortRotationStrKeys, string> &
  {
    [K in CBPortRotationEnumKeys]: K extends "cargoUnit" ? CargoUnitType : (
      K extends "purpose" ? PortPurposeType : never
    );
  } & Partial<Record<CBPortRotationIdKeys,string | number>>;

/**
 * hooks type
 */

export type CBPortRotationFormChangeEvent = <K extends CBPortRotationFormKeys>(id: string, key: K, value: CBPortRotationFormType[K]) => void;

export type CBPortRotationFormCommitEvent = (id: string, item: Partial<CBPortRotationFormType>) => void;

export type CBPortRotationFormInitEvent = (items: CBPortRotationFormType[]) => void;

export type CBPortRotationFormAddEvent = (purpose: ExtendsKeys<PortPurposeType, "DC" | "LD">,portId:string) => void;

export type CBPortRotationFormDelEvent = (id: string) => void;

/**
 * hfbasicprops type
 */

export type CBPortRotationFormProps = {
  items: CBPortRotationFormType[];
  onChange: CBPortRotationFormChangeEvent;
  onCommit: CBPortRotationFormCommitEvent;
  onAdd: CBPortRotationFormAddEvent;
  onDelete: CBPortRotationFormDelEvent;
}

export const checkPortRotationFormKeys: Partial<CBPortRotationFormKeys>[] = [
  "portCode", "portName", "cargoQuantity","demRate","desRate","loadingDischargeRate"
]