import { useContext, useEffect, useMemo, useRef, useState } from "react";
import { matchPortType, calcSeaValues } from "@/tools/port";
import { EstDetailsContext } from "@/pages/estimation/business/details/store";
import useCalcDistance from "@/pages/estimation/hooks/useCalcDistance";
import { EstPortRotationProps } from "../type";
import { commonItemsCheck } from "@/tools/check";
import useReminder from "@/hook/useReminder";
import { useTranslation } from "react-i18next";
import { EstPortRotationItemProps, checkKeys, initialEstPortItem } from "../components";

const useIndex = (
  hide: boolean,
  portType: EstPortRotationProps["portType"],
  vesselSource: EstPortRotationProps["vesselSource"],
  items: EstPortRotationProps["items"],
  onConfirm: EstPortRotationProps["onConfirm"],
  onClose: () => void
) => {
  const { t } = useTranslation()
  const { reminder } = useReminder()
  const {
    identifierMap,
    onIdentifierChange,
    onShippingCommit
  } = useContext(EstDetailsContext)

  const temporaryShip = useRef<{
    portGather: any
    portTrack: any
  }>({
    portGather: undefined,
    portTrack: undefined
  })

  const deleteIds = useRef<number[]>([])

  const [dataSource, setDataSource] = useState<EstPortRotationItemProps["item"][]>([]);

  const currentQuantity = useMemo(() => {
    return dataSource?.reduce((prev, curr) => {
      return prev + (matchPortType(curr?.purpose) === portType ? +curr?.cargoQuantity : 0)
    }, 0)
  }, [portType, dataSource])

  const change: EstPortRotationItemProps["onChange"] = (currentIndex, key, value) => {
    setDataSource(prev => {
      const currentItem = prev[currentIndex];
      prev.splice(currentIndex, 1, {
        ...currentItem,
        [key]: value
      });
      return [...prev];
    })
  }

  const commit: EstPortRotationItemProps["onCommit"] = (currentIndex, item) => {
    setDataSource(prev => {
      const currentItem = prev[currentIndex];
      prev.splice(currentIndex, 1, {
        ...currentItem,
        ...item
      })
      return [...prev]
    })
  }

  const handleUpdatePort = (
    needCommit: boolean,
    options: {
      distance: any,
      path: any,
      portGather: any,
      ecaDistance: any,
      indexList: number[]
    }) => {
    const {
      distance,
      path,
      portGather,
      ecaDistance,
      indexList
    } = options;
    for (let arrIndex of indexList) {
      let seaParams: Partial<Pick<EstPortRotationProps["items"][number], "distance" | "seaDays">> = {}
      let ecaParams: Partial<Pick<EstPortRotationProps["items"][number], "ecaDistance" | "insideEcaDays">> = {}
      switch (true) {
        case !!(ecaDistance[arrIndex] || ecaDistance[arrIndex] === 0):
          const currentVal = +(ecaDistance?.[arrIndex])?.toFixed(3)
          ecaParams = calcSeaValues(dataSource?.[arrIndex], vesselSource, "ecaDistance", currentVal)
        case !!(distance[arrIndex] || distance[arrIndex] === 0):
          const currentEcaVal = +(distance[arrIndex] - ecaDistance?.[arrIndex])?.toFixed(3)
          seaParams = calcSeaValues(dataSource?.[arrIndex], vesselSource, "distance", currentEcaVal)
          break
        default:
          break
      }
      commit(arrIndex, {
        ...seaParams,
        ...ecaParams
      })
    }
    temporaryShip.current = {
      portGather: portGather,
      portTrack: path
    }
  }

  const { loading, refresh, handleChangeCalcDistance, handleDeleteCalcDistance, setRefResh } = useCalcDistance(handleUpdatePort)

  const handlePortCommit: EstPortRotationItemProps["onPortCommit"] = (currentIndex, item) => {
    const newItems = [...dataSource];
    newItems?.splice(currentIndex, 1, {
      ...newItems[currentIndex],
      ...item
    })
    handleChangeCalcDistance(currentIndex, vesselSource?.deliveryPortCode, newItems, () => {
      commit(currentIndex, item)
    })
  }

  const check = () => {
    const currentItems = dataSource?.filter(item => matchPortType(item?.purpose) === portType);
    return commonItemsCheck(currentItems, checkKeys)
  }

  const add = () => {
    const lastIndex = dataSource?.findLastIndex(item => matchPortType(item?.purpose) === portType);
    setDataSource(prev => {
      prev?.splice(lastIndex + 1, 0, initialEstPortItem({ purpose: portType === "ballast" ? "LD" : "DC" }))
      return [...prev]
    })
  }

  const remove: EstPortRotationItemProps["onRemove"] = (currentIndex) => {
    setDataSource(prev => {
      prev.splice(currentIndex, 1)
      return [...prev]
    })
  }

  const handlePortItemDelete: EstPortRotationItemProps["onRemove"] = (currentIndex) => {
    const newItems = [...dataSource];
    const currentItem = newItems?.[currentIndex];
    currentItem?.id && (deleteIds?.current?.push(currentItem?.id))
    handleDeleteCalcDistance(currentIndex, vesselSource?.deliveryPortCode, newItems, () => {
      remove(currentIndex)
    })
  }

  const handleConfirm = () => {
    const { checkKey, checked } = check();
    if (!checked) {
      reminder("error", `Field ${t(`estimation.${checkKey}`, {
        defaultValue: t(`common.${checkKey}`)
      })} is required`);
      return
    }
    onConfirm?.(dataSource, deleteIds.current)
    if (refresh !== -1) {
      const {
        portGather,
        portTrack
      } = temporaryShip?.current
      onShippingCommit({
        portGather: portGather,
        portTrack: portTrack
      });
      if (!portGather || portGather?.features?.length === 0) {
        onIdentifierChange("portGather", 0);
      } else {
        onIdentifierChange("portGather", identifierMap?.portGather === -1 ? 1 : identifierMap?.portGather + 1);
      }
      if (!portTrack || portTrack?.geometry?.coordinates?.length === 0) {
        onIdentifierChange("portTrack", 0);
      } else {
        onIdentifierChange("portTrack", identifierMap?.portTrack === -1 ? 1 : identifierMap?.portTrack + 1);
      }
    }
    onClose?.()
  }

  useEffect(() => {
    if (hide) {
      setRefResh(-1)
      return
    }
    const newItems = [...items]
    setDataSource(newItems)
    deleteIds.current = []
  }, [hide, items])

  return {
    loading,
    dataSource,
    currentQuantity,
    change,
    commit,
    remove,
    add,
    handlePortCommit,
    handleConfirm,
    handlePortItemDelete
  }
}

export default useIndex;