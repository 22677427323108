import React, { useEffect, useRef, useState } from "react";
import OperationBar from "./components/OperationBar";
import VoyageList from "./components/VoyageList";
import MonitoringDetails from "./components/monitoringDetails";
import Legend from "./components/Legend";
import DetailDrawer from "./components/DetailDrawer";
import DailyReportDrawer from "./components/DailyReportDrawer";
import Map from "@/pages/wmap/index";
import { VesselInfo, VesselStateInfo, DailyRepotyDataType, AISStateInfo } from "./components/index";
import { getVesselDetailAction } from "@/action/monitoring/vessel";
import useReminder from "@/hook/useReminder";
import dayjs, { ManipulateType } from "dayjs";
import classnames from "classnames";
import style from "./index.module.less";
import { ConfigProvider } from "antd";
import MonitoringProvider from "./MonitoringContext";
import useIndex from "./hooks/useIndex";
import {
	getMonitoringAisDataForImoAction,
	getMonitoringThreeAisDataAction
} from "@/action/monitoring/aisGroup";
import { MonitoringAisDetailSelectTimeKeys } from "./components/monitoringDetails/type";
import { initialVesselPoint } from "./tools";
import { MeteoType, SwitchType } from "../wmap/type";
import { useAppDispatch } from "@/hook";
import { useNavigate } from "react-router-dom";
import MonitoringAisData from "./components/aisData";
import { CommonVesselArrival } from "@/common";
import { ArrivalShipStatusMap } from "../estimation/business/details/components/vesselArrival/source";
import { AreaListItemType } from "./components/DetailDrawer/components/areaVesselList/components/table/type";
import { PortAreaListItemType } from "./components/DetailDrawer/components/portAreaList/components/table/type";

const Monitoring: React.FC<{}> = () => {
	const dispatch = useAppDispatch();
	const navigate = useNavigate();
	const [timerId, setTimerId] = useState(null);
	const [collapsed, setCollapsed] = useState<boolean>(false);
	const [detailDrawerVisible, setDetailDrawerVisible] = useState<boolean>(false);
	const [legendVisible, setLegendVisible] = useState<boolean>(true);
	const { reminder } = useReminder();
	const [dailyReportSelectRow, setDailyReportSelectRow] = useState<DailyRepotyDataType[]>([]);

	const {
		actionRef,
		voyageListActionRef,
		aisDetailSource,
		currentAisTime,
		aisDetailLoading,
		abortController,
		currentVessel,
		vesselDetailSource,
		currentAis,
		currentVoyageTime,
		arrivalHide,
		aisChartHide,
		currentRecord,
		setCurrentVoyageTime,
		setCurrentAis,
		setVesselDetailSource,
		setCurrentVessel,
		handleAisItemSelect,
		handleDetailClose,
		handleAddAisStar,
		handleAisStarItemSelect,
		handleAisDetailSelect,
		handlClose,
		handleOpen,
		activeTab,
		handleTabSelect,
		areaItems,
		handleAreaSelect,
		handleAreaDelete,
		handleAreaReset,
		areaDetailLoading,
		areaDetailSource,
		currentArea,
		currentAreaTime,
		portDetailLoading,
		portDetailSource,
		currentPort,
		currentPortTime,
		setCurrentArea,
		setCurrentPort,
		monitoringDetailTab,
		setMonitoringDetailTab,
		portVesselParams,
		handleChangePortVesselParams
	} = useIndex();

	const theme = useRef({
		token: {
			colorPrimary: "#355691",
			colorLink: "#355691"
		},
		components: {
			Table: {
				rowSelectedBg: "#ECEEF4"
			}
		}
	}).current;

	const token = {
		legendVisible,
		dailyReportSelectRow,
		abortController: abortController.current,
		currentVessel,
		currentAis,
		setLegendVisible,
		setDailyReportSelectRow,
		setCurrentVessel,
		setCurrentAis,
		currentArea,
		setCurrentArea,
		currentPort,
		setCurrentPort
	};

	const handlePlay = (starTime, callback, hourInterval) => {
		const meteoCalendar = actionRef?.current?.getMeteoCalendar();

		// const starTime = dayjs(meteoCalendar.start).add(-8, 'h')
		const endTime = dayjs(meteoCalendar.end).add(-8, "h");
		if (timerId) {
			clearInterval(timerId);
			setTimerId(null);
		}

		let playTime = starTime;
		const id = setInterval(() => {
			const newTime = dayjs(playTime).add(hourInterval, "h");
			if (newTime.isBefore(endTime)) {
				playTime = newTime;
			} else {
				playTime = starTime;
			}
			typeof callback === "function" && callback(playTime);
			actionRef.current?.setMeteoTime(playTime.add(8, "h").valueOf());
		}, 1 * 1000);
		setTimerId(id);
	};
	const handlePause = () => {
		if (timerId) {
			clearInterval(timerId);
			setTimerId(null);
		}
	};
	const handleSetNow = (val: number) => {
		actionRef.current?.setMeteoTime(val);
	};
	const handleChangeMeteo = (meteoType: MeteoType, switchType: SwitchType) => {
		actionRef.current?.meteoLayerControl(meteoType, switchType);
	};
	const changeVoyageLayerAvtive = (layerIds: Array<string>, active: boolean) => {
		actionRef.current?.voyageLayerControl(layerIds, active);
	};
	const handleVesselItemChange = (
		type: "init" | "timeSelect",
		item: VesselInfo,
		selectTime: MonitoringAisDetailSelectTimeKeys,
		isLocation: boolean,
		isScorllTo?: boolean
	) => {
		abortController?.current?.abort();
		// actionRef.current?.clearRoutePlan()
		// actionRef.current?.clearDailyReport()
		switch (true) {
			case type === "init" && !item:
			case type === "init" &&
				item.voyageNo === currentVessel?.voyageNo &&
				item?.vesselStatus === currentVessel?.vesselStatus:
				setCurrentVessel(null);
				setVesselDetailSource(null);
				handlClose("aisData");
				actionRef.current?.clearVesselAndTrackLayers("vm");
				// actionRef.current?.clearRoutePlan()
				// actionRef.current?.clearDailyReport()
				return;
			case type === "timeSelect":
				setCurrentVoyageTime(selectTime);
				// handlClose("vm")
				// handleArrivalSelect({ imo: vesselDetailSource?.imo, imo: vesselDetailSource?.imo, currentTime: selectTime })
				break;
			default:
				break;
		}
		abortController.current = new AbortController();
		if (type === "init") {
			setCurrentVessel(item);
			// handleArrivalSelect({ imo: item?.imo, imo: item?.imo, currentTime: selectTime })
		}
		isScorllTo &&
			voyageListActionRef?.current?.vesselListRef?.current?.scrollIntoItem?.(item.voyageNo);
		getVesselDetailAction(
			{ voyageNo: item.voyageNo, type: item.vesselStatus },
			() => {},
			({ data }) => {
				setVesselDetailSource({
					...data,
					startArrivedTime:
						data.startArrivedTime && dayjs(data.startArrivedTime).format("YYYY-MM-DD HH:mm"),
					endArrivedTime:
						data.endArrivedTime && dayjs(data.endArrivedTime).format("YYYY-MM-DD HH:mm"),
					lastTime: data.lastTime && dayjs(data.lastTime).format("YYYY-MM-DD HH:mm"),
					etaStd: data.etaStd && dayjs(data.etaStd).format("YYYY-MM-DD HH:mm"),
					vesselName: item.vesselName,
					voyageNo: item.voyageNo,
					id: item.id,
					vesselStatus: item.vesselStatus,
					actualCommenceDate: item.actualCommenceDate
				});
				setDetailDrawerVisible(true);
				showVesselTrackOnMap(type === "init" ? item : currentVessel, data, isLocation, selectTime);
			},
			(error) => {
				reminder("error", error?.msg ? error?.msg + ": " + error?.data : error?.data);
				showVesselTrackOnMap(type === "init" ? item : currentVessel, null, isLocation, selectTime);
			},
			dispatch,
			navigate
		);
	};
	const showVesselTrackOnMap = (
		item,
		detail,
		isLocation,
		selectTime: MonitoringAisDetailSelectTimeKeys
	) => {
		if (detail?.imo) {
			const [count, rule] = selectTime?.split("/") as [number, ManipulateType];
			if (selectTime === "3/day") {
				getMonitoringThreeAisDataAction(
					{ imo: detail?.imo },
					null,
					(response) => {
						const { data } = response;
						if (!data?.current && !data?.route) return;
						const params = { ...data?.route, properties: data?.current };
						actionRef.current?.loadVesselTrack("vm", params, isLocation);
					},
					(error) => {
						reminder("error", error?.msg ? error?.msg + ": " + error?.data : error?.data);
					},
					dispatch,
					navigate
				);
				return;
			}
			getMonitoringAisDataForImoAction(
				{
					imo: detail?.imo,
					endDate: dayjs()?.format("YYYY-MM-DD HH:mm:ss"),
					startDate: dayjs()?.subtract(count, rule)?.format("YYYY-MM-DD HH:mm:ss")
				},
				null,
				({ data }) => {
					data = { ...data?.route, properties: data?.current };
					actionRef.current?.loadVesselTrack("vm", data, isLocation);
				},
				(error) => {
					reminder("error", error?.msg ? error?.msg + ": " + error?.data : error?.data);
				},
				dispatch,
				navigate
				// { signal: abortController?.current?.signal }
			);
		}
	};
	const handleVesselsChange = (vessels: VesselStateInfo[]) => {
		console.log("handleVesselsChange", vessels);
		actionRef.current?.refreshVessels(vessels);
	};

	const handleAisVesselLoad = (vessels: AISStateInfo[]) => {
		const data = vessels?.map((vessel) => {
			return initialVesselPoint(
				vessel?.id,
				vessel?.imo,
				ArrivalShipStatusMap[vessel?.currentState?.shipStatus],
				vessel?.vesselName ?? vessel?.vesselNameEn,
				"",
				{
					cog: vessel?.currentState?.heading,
					lat: vessel?.currentState?.lat,
					lng: vessel?.currentState?.lon
				}
			);
		});
		actionRef.current?.refreshAisVessels(data);
	};

	const handleAreaPortVesselLoad = (vessels: AreaListItemType[]) => {
		console.log("test", vessels);
		const data = vessels?.map((vessel) => {
			return initialVesselPoint(
				vessel?.id,
				vessel?.imo,
				ArrivalShipStatusMap[vessel?.shipStatus],
				vessel?.vesselName,
				"",
				{
					cog: vessel?.heading,
					lat: vessel?.aisLat,
					lng: vessel?.aisLon
				}
			);
		});
		actionRef.current?.refreshAreaVessels(data);
		// actionRef.current?.refreshPortVessels(data);
	};
	const handlePortVesselLoad = (vessels: PortAreaListItemType[]) => {
		const data = vessels
			?.filter((item) => item.aisLat && item.aisLon)
			?.map((vessel) => {
				return initialVesselPoint(vessel?.imo, vessel?.imo, undefined, vessel?.vesselName, "", {
					cog: vessel?.heading,
					lat: vessel?.aisLat,
					lng: vessel?.aisLon
				});
			});

		actionRef.current?.refreshPortVessel(data);
	};
	const handleDailyReportClose = () => {
		actionRef.current?.handleDailyReportIconChange(null);
	};

	useEffect(() => {
		return () => {
			abortController?.current?.abort();
			if (timerId) clearInterval(timerId);
		};
	}, []);

	const getMeteoCalendar = () => {
		return actionRef?.current?.getMeteoCalendar();
	};

	return (
		<div className={style.fleetMonitoring}>
			<MonitoringProvider value={token}>
				<ConfigProvider theme={theme}>
					<div className={classnames([style.sider, collapsed && style.collapsed])}>
						<VoyageList
							ref={voyageListActionRef}
							handleChangeVessels={handleVesselsChange}
							onVesselItemClick={handleVesselItemChange}
							onAisItemSelect={handleAisItemSelect}
							onAisVesselLoad={handleAisVesselLoad}
							onAisStarItemSelect={handleAisStarItemSelect}
							activeTab={activeTab}
							onTabSelect={handleTabSelect}
							areaItems={areaItems}
							portVesselParams={portVesselParams}
							onChangePortVesselParams={handleChangePortVesselParams}
							onOpenDetailTab={setMonitoringDetailTab}
							onAreaDelete={handleAreaDelete}
							onAreaReset={handleAreaReset}
							onAreaSelect={handleAreaSelect}
						/>
					</div>
					<div className={style.content}>
						<OperationBar
							onChangeVoyageLayerAvtive={changeVoyageLayerAvtive}
							getMeteoCalendar={getMeteoCalendar}
							collapsed={collapsed}
							onCollopsed={() => setCollapsed(!collapsed)}
							onPlay={handlePlay}
							onPause={handlePause}
							onSetNow={handleSetNow}
							onChangeMeteo={handleChangeMeteo}
						/>
						<div className={style.mapContainer}>
							<Map
								onVesselItemClick={handleVesselItemChange}
								onAisVesselItemClick={(type, item) => {
									if (type === "ais") {
										handleAisStarItemSelect(item);
										return;
									}
									handleAisItemSelect(type, item);
								}}
								onAisDetailItemChange={handleDetailClose}
								ref={actionRef}
							/>
							<div className={style.detail}>
								<div className={style["detail-cards"]}>
									{vesselDetailSource && (
										<MonitoringDetails
											type="vm"
											data={vesselDetailSource as any}
											onAisCancel={handleDetailClose}
											activeTime={currentVoyageTime}
											onVesselArrivalOpen={handleOpen}
											onAisItemSelect={(type, item, selectTime) =>
												handleVesselItemChange?.("timeSelect", item, selectTime, true, false)
											}
										/>
									)}
									{aisDetailSource && (
										<MonitoringDetails
											type="ais"
											data={aisDetailSource as any}
											activeTime={currentAisTime}
											onAisItemSelect={handleAisDetailSelect}
											loading={aisDetailLoading}
											onAisCancel={handleDetailClose}
											onAisStarAdd={handleAddAisStar}
											onVesselArrivalOpen={handleOpen}
										/>
									)}
									{areaDetailSource && (
										<MonitoringDetails
											type="area"
											data={areaDetailSource as any}
											activeTime={currentAreaTime}
											onAisItemSelect={handleAisDetailSelect}
											loading={areaDetailLoading}
											onAisCancel={handleDetailClose}
											onVesselArrivalOpen={handleOpen}
										/>
									)}
									{portDetailSource && (
										<MonitoringDetails
											type="port"
											data={portDetailSource as any}
											activeTime={currentPortTime}
											onAisItemSelect={handleAisDetailSelect}
											loading={portDetailLoading}
											onAisCancel={handleDetailClose}
											onVesselArrivalOpen={handleOpen}
										/>
									)}
								</div>
								<MonitoringAisData
									mmsi={currentRecord?.aisData?.mmsi}
									imo={currentRecord?.aisData?.imo}
									hide={aisChartHide}
									selectTime={currentRecord?.currentTime}
									onClose={handlClose}
								/>
							</div>
							<div id="legend" className={style.legend}>
								{legendVisible && <Legend />}
							</div>
							<DetailDrawer
								mapRef={actionRef}
								currentVessel={vesselDetailSource}
								open={detailDrawerVisible}
								onCollopsed={() => setDetailDrawerVisible(!detailDrawerVisible)}
								currentTab={activeTab}
								activeTab={monitoringDetailTab}
								onChangeActiveTab={setMonitoringDetailTab}
								areaItems={areaItems}
								onAisItemSelect={handleAisItemSelect}
								onPortVesselLoad={handlePortVesselLoad}
								portVesselParams={portVesselParams}
								onAreaPortVesselLoad={handleAreaPortVesselLoad}
							/>
						</div>
					</div>
					<div
						className={classnames([
							style.right,
							dailyReportSelectRow.length === 0 && style.collapsed
						])}
					>
						<DailyReportDrawer onClose={handleDailyReportClose} />
					</div>
					<CommonVesselArrival
						hide={arrivalHide}
						type="details"
						imo={currentRecord?.arrivalData?.imo}
						onClose={() => {
							handlClose("arrival");
						}}
					/>
				</ConfigProvider>
			</MonitoringProvider>
		</div>
	);
};

export default Monitoring;
