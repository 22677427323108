import React from "react";
import { formatThousandthNumber } from "@/tools/amount";
import { VesselArrivalItemType } from "./components/item/type";
import { ArrivalEcoEngineMap, ArrivalEnergySavingTypeMap, ArrivalGearMap, ArrivalSoxScrubberStatusMap, ArrivalStatusMap, ArrivalTradeTypeMap, ArrivalVesselSubTypeMap, ArrivalVesselTypeMap } from "@/pages/estimation/business/details/components/vesselArrival/source";

export const columns: VesselArrivalItemType[][] = [
  [
    {
      dataIndex: "vesselName", required: true, renderValue: (key, record) => {
        return record?.[key] ? <span>{record?.[key]}</span> : null
      },
      render: (key, label, record) => {
        return record?.[key] ? <div key={key}>
          <span>{key ? label : ""}</span>
          <span>{record?.[key]}</span>
        </div> : null
      }
    },
    { dataIndex: "vesselType", renderValue: (key, record) => <span>{ArrivalVesselTypeMap[record?.[key]]}</span> },
    { dataIndex: "vesselTypeSub", renderValue: (key, record) => <span>{ArrivalVesselSubTypeMap[record?.[key]]}</span> },
    { dataIndex: "imo", required: true },
    { dataIndex: "mmsi", required: true },
    { dataIndex: "callSign" },
    { dataIndex: "flagCountryName" },
    { dataIndex: "speedMax" },
    { dataIndex: "seviceSpeed" },
    // { dataIndex: "builderCountry" },
    // { dataIndex: "builder" },
    { dataIndex: "classification" },
    { dataIndex: "buildYear" },
    { dataIndex: "buildMonth" },
    { dataIndex: "status", renderValue: (key, record) => <span>{ArrivalStatusMap[record?.[key]]}</span> },
    { dataIndex: "tradeType", renderValue: (key, record) => <span>{ArrivalTradeTypeMap[record?.[key]]}</span> },
    { dataIndex: "cnCode" },
    { dataIndex: "vesselNameCn" },
    { dataIndex: "ventureType" },
    { dataIndex: "exName" },
    { dataIndex: "pniClub" }
  ],
  [
    // { dataIndex: "ownerCode" },
    // { dataIndex: "operatorCode" },
    // { dataIndex: "managerCode" },
    { dataIndex: "companyName" },
    // { dataIndex: "operatorCountry" },
    // { dataIndex: "managerCountry" },
    // { dataIndex: "countryName" },
    { dataIndex: "countryNameCn" },
    { dataIndex: "countryName" },
    { dataIndex: "companyNameCn" },
    { dataIndex: "" },
    { dataIndex: "" },
    { dataIndex: "operatorCompanyName" },
    { dataIndex: "operatorCountryNameCn" },
    { dataIndex: "operatorCountryName" },
    { dataIndex: "operatorCompanyNameCn" },
    { dataIndex: "" },
    { dataIndex: "" },
    { dataIndex: "builderCompanyName" },
    { dataIndex: "builderCompanyNameCn" },
    { dataIndex: "builderCountryName" },
    { dataIndex: "builderCountryNameCn" },
    { dataIndex: "" },
    { dataIndex: "" },
    { dataIndex: "managerCompanyName" },
    { dataIndex: "managerCompanyNameCn" },
    { dataIndex: "managerCountryName" },
    { dataIndex: "managerCountryNameCn" },
    { dataIndex: "" },
    { dataIndex: "" },
  ],
  [
    { dataIndex: "dwt", required: true, renderValue: (key, record) => <span>{formatThousandthNumber(record?.[key])}</span> },
    { dataIndex: "loa", renderValue: (key, record) => <span>{formatThousandthNumber(record?.[key])}</span> },
    { dataIndex: "bm", renderValue: (key, record) => <span>{formatThousandthNumber(record?.[key])}</span> },
    { dataIndex: "draft", renderValue: (key, record) => <span>{formatThousandthNumber(record?.[key])}</span> },
    { dataIndex: "grossTonnage", renderValue: (key, record) => <span>{formatThousandthNumber(record?.[key])}</span> },
    { dataIndex: "netTonnage", renderValue: (key, record) => <span>{formatThousandthNumber(record?.[key])}</span> },
    { dataIndex: "depth", renderValue: (key, record) => <span>{formatThousandthNumber(record?.[key])}</span> },
    { dataIndex: "tpc", renderValue: (key, record) => <span>{formatThousandthNumber(record?.[key])}</span> },
    { dataIndex: "displacement", renderValue: (key, record) => <span>{formatThousandthNumber(record?.[key])}</span> },
    { dataIndex: "soxScrubberStatus", renderValue: (key, record) => <span>{ArrivalSoxScrubberStatusMap[record?.[key]]}</span> },
    { dataIndex: "capacity", renderValue: (key, record) => <span>{formatThousandthNumber(record?.[key])}</span> },
    { dataIndex: "lngContainmentType" }, ,
    { dataIndex: "baleCapacity", renderValue: (key, record) => <span>{formatThousandthNumber(record?.[key])}</span> },
    { dataIndex: "grainCapacity", renderValue: (key, record) => <span>{formatThousandthNumber(record?.[key])}</span> },
    { dataIndex: "holdsTotalNo" },
    { dataIndex: "hatchesTotalNo" },
    { dataIndex: "grabsNo" },
  ],
  [
    { dataIndex: "ecoEngine", renderValue: (key, record) => <span>{ArrivalEcoEngineMap[record?.[key]]}</span> },
    { dataIndex: "energySavingType", renderValue: (key, record) => <span>{ArrivalEnergySavingTypeMap[record?.[key]]}</span> },
    { dataIndex: "lngCarrierEngineType" },
    { dataIndex: "lngContainmentModel" },
    { dataIndex: "auxiliaryEngineNumber" },
    { dataIndex: "mainEngineNumber" },
    { dataIndex: "mainEngineModel" },
    { dataIndex: "engineDesigner" },
    { dataIndex: "engineBuilder" },
    { dataIndex: "powerType" },
    { dataIndex: "mainEngineFuelType" },
    { dataIndex: "mainEngineRpm" },
    { dataIndex: "mainEngineMcrRpm" },
    { dataIndex: "gear", renderValue: (key, record) => <span>{ArrivalGearMap[record?.[key]]}</span> },
    { dataIndex: "gearSummary" },
    { dataIndex: "power" },
    { dataIndex: "minPitch" },
    { dataIndex: "epl" }
  ]
]


export enum VesselArrivalTitleMap {
  "Basic Informaiton",
  "OWNERSHIP",
  "HULL & Dimensions",
  "Machinery"
}