import { ColumnsType } from "antd/es/table";
import React from "react";
import { RouteCommonPortVesselItemType } from "./type";
import i18n from "@/locale";
import dayjs from "dayjs";
import { formatThousandthNumber } from "@/tools/amount";
import {
	ArrivalAisStatusMap,
	ArrivalVesselSubTypeMap,
	ArrivalVesselTypeMap
} from "@/common/Select";

export const columns: ColumnsType<RouteCommonPortVesselItemType> = [
	{
		dataIndex: "vesselName",
		key: "vesselName",
		title: i18n.t("common.vesselName"),
		width: 180,
		fixed: "left"
	},
	{
		dataIndex: "imo",
		key: "imo",
		title: i18n.t("common.imo"),
		width: 120
	},
	{
		dataIndex: "vesselType",
		key: "vesselType",
		title: i18n.t("common.vesselType"),
		width: 150,
		render: (value, record, index) => {
			return <span>{ArrivalVesselTypeMap[record?.vesselType]}</span>;
		}
	},
	{
		dataIndex: "vesselTypeSub",
		key: "vesselTypeSub",
		title: i18n.t("common.vesselTypeSub"),
		width: 150,
		render: (value, record, index) => {
			return <span>{ArrivalVesselSubTypeMap[record?.vesselTypeSub]}</span>;
		}
	},
	{
		title: i18n.t("common.dwt"),
		dataIndex: "dwt",
		width: 100,
		render: (value) => {
			return <span>{formatThousandthNumber(Number(value), 0, 0)}</span>;
		}
	},
	{
		title: "AIS Status",
		dataIndex: "aisStatus",
		width: 130,
		render: (value, record, index) => {
			return <span>{ArrivalAisStatusMap[value]}</span>;
		}
	},
	{
		title: "ATA/ETA (UTC)",
		dataIndex: "ata",
		width: 159,
		render(value, record, index) {
			return <span>{value ? dayjs(value)?.format("YYYY-MM-DD HH:mm") : "-"}</span>;
		}
	},
	{
		title: "Destination Port ETA",
		dataIndex: "desPortEta",
		width: 180,
		render(value, record, index) {
			return <span>{value ? dayjs(value)?.format("YYYY-MM-DD hh:mm") : "-"}</span>;
		}
	},
	{
		title: "Port Name",
		dataIndex: "portName",
		width: 130
	},
	{
		title: "Area Name",
		dataIndex: "areaName",
		width: 130
	},
	{
		title: "Country Name",
		dataIndex: "countryName",
		width: 130
	},
	{
		title: "Last Port Name",
		dataIndex: "lastPortName",
		width: 150
	},
	{
		title: "Last Country Name",
		dataIndex: "lastCountryName",
		width: 150,
		ellipsis: true
	},
	{
		title: "Last Area Name",
		dataIndex: "startAreaName",
		width: 150
	}
];
