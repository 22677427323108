import React, { FC } from "react";
import style from "./index.module.less";
import classnames from "classnames";
import { RouteCommonAisItemProps, RouteCommonAisItemToolType } from "./type";
import { Icon, Popover } from "@/_components";
import { toolOptions } from "./source";
import { formatThousandthNumber } from "@/tools/amount";
import { FleetToast } from "@/common";

const RouteCommonAisItem: FC<RouteCommonAisItemProps> = ({
	item,
	activeItem,
	onSelect,
	onCollection
}) => {
	const handleCollection = (type: RouteCommonAisItemToolType) => {
		switch (true) {
			case type === "delete":
				onCollection?.(item);
				break;
			default:
				break;
		}
	};

	return (
		<div
			id={`routeCommon-aisItem--${item?.imo}`}
			className={classnames(style["routeCommon-aisItem"], {
				[style["routeCommon-aisItem--active"]]: activeItem?.imo === item?.imo
			})}
			onClick={() => {
				onSelect?.(activeItem?.imo === item?.imo ? null : item);
			}}
			draggable
		>
			<Icon type="fleet-drag" className="aisItem-moveIcon" />
			<div className="aisItem-container">
				<div className="aisItem-operator">
					<span>{item?.currentState?.vesselName}</span>
					<ul
						className="aisItem-tools"
						onClick={(event) => {
							event?.stopPropagation();
						}}
					>
						{toolOptions?.map((toolItem, index) => {
							if (toolItem?.value === "warning" && !item?.isWarning) return null;
							if (toolItem?.value === "delete") {
								return (
									<Popover
										children={
											<Icon
												type={toolItem?.icon}
												key={toolItem?.value}
												className={toolItem?.value}
												color="#757575"
												onClick={(event) => {
													event?.preventDefault?.();
												}}
											/>
										}
										render={(setIsOpen) => {
											return (
												<FleetToast
													label="Are you sure delete this item?"
													onCancel={() => {
														setIsOpen(false);
													}}
													onConfirm={() => {
														handleCollection?.(toolItem?.value);
														setIsOpen(false);
													}}
												/>
											);
										}}
									/>
								);
							}
							return (
								<Icon
									type={toolItem?.icon}
									key={toolItem?.value}
									className={toolItem?.value}
									color="#757575"
									onClick={(event) => {
										event?.stopPropagation?.();
										handleCollection?.(toolItem?.value);
									}}
								/>
							);
						})}
					</ul>
				</div>
				<div className="aisItem-otherInfo">
					<span>IMO: {item?.imo}</span>
					<span>DWT: {formatThousandthNumber(item?.currentState?.dwt, 0, 0)}</span>
				</div>
			</div>
		</div>
	);
};

export default RouteCommonAisItem;
