import { MovementFinancialDataItemType } from "./type";
import { CommonInitItemEvent } from "@/types/event";

export const initialFinacialItem: CommonInitItemEvent<MovementFinancialDataItemType> = (item) => {
	return {
		desc: item?.desc ?? "",
		plan: item?.plan ?? null,
		actual: item?.actual ?? null,
		time: item?.time ?? null,
		posted: item?.posted ?? null,
		diffActual: item?.diffActual ?? null,
		diffPosted: item?.diffPosted ?? null,
		children: item?.children ?? [],
		payerName: item?.payerName ?? ""
	};
};