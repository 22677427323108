import { useMemo, useRef, useState } from "react";
import { calcSeaValues, isBallast, isLaden, matchPortType } from "@/tools/port";
import {
	IdentifierMapChangeEvent,
	IdentifierMapType,
	ShippingMapCommitEvent
} from "@/hook/estimation/details/useDetails";
import useCalcDistance from "@/pages/estimation/hooks/useCalcDistance";
import { commonItemsCheck } from "@/tools/check";
import { EstVesselParticularType } from "@/pages/estimation/module";
import { EstCargoAndPortProps } from "../../../type";
import {
	EstPortRotationItemProps,
	EstPortRotationItemType,
	EstPortRotationProps,
	calculateConsumValues,
	checkKeys,
	initialEstPortItem
} from "@/pages/estimation/components";
import { getCurrentIndex } from "@/pages/estimation/tools";
import { EstPortBatchCommitEvent, EstPortConsumCommitEvent } from "../type";

const useIndex = (
	vesselSource: Pick<EstVesselParticularType, "deliveryPortCode" | "ballastSpeed" | "ladenSpeed">,
	identifierMap: IdentifierMapType,
	onIdentifierChange: IdentifierMapChangeEvent,
	onShippingCommit: ShippingMapCommitEvent
) => {
	const delPortIds = useRef<number[]>([]);
	const [dataSource, setDataSource] = useState<EstPortRotationProps["items"]>([]);

	const portCategory = useMemo(() => {
		const ballastPortList = dataSource?.filter((portItem) => isBallast(portItem?.purpose));
		const ladenPortList = dataSource?.filter((portItem) => isLaden(portItem?.purpose));

		return {
			ballastPortList,
			ladenPortList
		};
	}, [dataSource]);

	const portSeq = useMemo(() => {
		let result = [];
		dataSource?.forEach((item) => {
			switch (true) {
				case isBallast(item?.purpose):
					result.push("ballast");
					break;
				case isLaden(item?.purpose):
					result.push("laden");
					break;
				default:
					break;
			}
		});
		return result;
	}, [dataSource]);

	const commit: EstPortRotationItemProps["onCommit"] = (currentIndex, item) => {
		setDataSource((prev) => {
			const currentItem = prev?.[currentIndex];
			prev?.splice(currentIndex, 1, {
				...currentItem,
				...item
			});
			return [...prev];
		});
	};

	const handleBatchCommit: EstPortBatchCommitEvent = (type, vesselItem) => {
		for (let currentIndex = 0; currentIndex < dataSource?.length; currentIndex++) {
			const currentItem = dataSource?.[currentIndex];
			if (matchPortType(currentItem?.purpose) !== type) continue;
			const seaParams = calcSeaValues(
				currentItem,
				vesselItem,
				"distance",
				currentItem?.distance
			);
			const ecaParams = calcSeaValues(
				currentItem,
				vesselItem,
				"ecaDistance",
				currentItem?.ecaDistance
			);
			commit(currentIndex, {
				...seaParams,
				...ecaParams
			});
		}
	};

	const handleBatchFoConsumCommit: EstPortConsumCommitEvent = (vesselItem) => {
		setDataSource(prev => {
			for (let i = 0; i <= prev?.length - 1; i++) {
				prev?.splice(i, 1, calculateConsumValues(prev?.[i], vesselItem))
			}
			return [...prev]
		})
	}

	const add: EstCargoAndPortProps["onPortItemAdd"] = (type, newItem) => {
		const newPortItem = initialEstPortItem(newItem);
		const lastIndex = getCurrentIndex(dataSource, type);
		const newItems = [...dataSource];
		newItems?.splice(lastIndex + 1, 0, newPortItem);
		handleChangeCalcDistance(lastIndex + 1, vesselSource?.deliveryPortCode, newItems, () => {
			setDataSource((prev) => {
				prev?.splice(lastIndex + 1, 0, { ...newPortItem });
				return [...prev];
			});
		});
	};

	const change: EstPortRotationItemProps["onChange"] = (currentIndex, key, value) => {
		setDataSource((prev) => {
			const currentItem = prev?.[currentIndex];
			prev?.splice(currentIndex, 1, {
				...currentItem,
				[key]: value
			});
			return [...prev];
		});
	};

	const remove: EstPortRotationItemProps["onRemove"] = (currentIndex) => {
		setDataSource((prev) => {
			prev.splice(currentIndex, 1);
			return [...prev];
		});
	};

	const init = (items: EstPortRotationItemType[] = []) => {
		setDataSource(items);
	};

	const check = () => {
		return commonItemsCheck(dataSource, checkKeys);
	};

	const handleUpdatePort = (
		needCommit: boolean,
		options: {
			distance: any;
			path: any;
			portGather: any;
			ecaDistance: any;
			indexList: number[];
		}
	) => {
		const { portGather, path, distance, ecaDistance, indexList } = options;
		if (!needCommit) {
			onShippingCommit?.({
				portGather: portGather,
				portTrack: path
			});
			if (!portGather || portGather?.features?.length === 0) {
				onIdentifierChange("portGather", 0);
			} else {
				onIdentifierChange(
					"portGather",
					identifierMap?.portGather === -1 ? 1 : identifierMap?.portGather + 1
				);
			}
			if (!path || path?.geometry?.coordinates?.length === 0) {
				onIdentifierChange("portTrack", 0);
			} else {
				onIdentifierChange(
					"portTrack",
					identifierMap?.portTrack === -1 ? 1 : identifierMap?.portTrack + 1
				);
			}
			return;
		}
		if (refresh !== -1) {
			for (let arrIndex of indexList) {
				let seaParams: Partial<
					Pick<EstPortRotationProps["items"][number], "distance" | "seaDays">
				> = {};
				let ecaParams: Partial<
					Pick<EstPortRotationProps["items"][number], "ecaDistance" | "insideEcaDays">
				> = {};
				switch (true) {
					case !!(ecaDistance[arrIndex] || ecaDistance[arrIndex] === 0):
						const currentVal = +ecaDistance?.[arrIndex]?.toFixed(3);
						ecaParams = calcSeaValues(
							dataSource?.[arrIndex],
							vesselSource,
							"ecaDistance",
							currentVal
						);
					case !!(distance[arrIndex] || distance[arrIndex] === 0):
						const currentEcaVal = +(distance[arrIndex] - ecaDistance?.[arrIndex])?.toFixed(3);
						seaParams = calcSeaValues(
							dataSource?.[arrIndex],
							vesselSource,
							"distance",
							currentEcaVal
						);
						break;
					default:
						break;
				}
				commit(arrIndex, {
					...seaParams,
					...ecaParams
				});
			}

			onShippingCommit?.({
				portGather: portGather,
				portTrack: path
			});
			if (!portGather || portGather?.features?.length === 0) {
				onIdentifierChange("portGather", 0);
			} else {
				onIdentifierChange(
					"portGather",
					identifierMap?.portGather === -1 ? 1 : identifierMap?.portGather + 1
				);
			}
			if (!path || path?.geometry?.coordinates?.length === 0) {
				onIdentifierChange("portTrack", 0);
			} else {
				onIdentifierChange(
					"portTrack",
					identifierMap?.portTrack === -1 ? 1 : identifierMap?.portTrack + 1
				);
			}
		}
	};

	const {
		loading,
		refresh,
		handleChangeCalcDistance,
		handleDeleteCalcDistance,
		handleCalcDeliveryDistance
	} = useCalcDistance(handleUpdatePort);

	const handlePortItemDelete: EstPortRotationItemProps["onRemove"] = (currentIndex) => {
		const newItems = [...dataSource];
		const currentItem = newItems?.[currentIndex];
		currentItem?.id && delPortIds?.current?.push(currentItem?.id);
		handleDeleteCalcDistance(currentIndex, vesselSource?.deliveryPortCode, newItems, () => {
			remove(currentIndex);
		});
	};

	const handlePortRotationConfirm: EstPortRotationProps["onConfirm"] = (items, deleteIds) => {
		init(items);
		delPortIds.current.push(...deleteIds);
	};

	return {
		loading,
		delPortIds,
		dataSource,
		portSeq,
		portCategory,
		change,
		check,
		commit,
		init,
		add,
		handlePortRotationConfirm,
		handleChangeCalcDistance,
		handleCalcDeliveryDistance,
		handlePortItemDelete,
		handleBatchCommit,
		handleBatchFoConsumCommit
	};
};

export default useIndex;
