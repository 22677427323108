import React from "react";
import classnames from "classnames";
import style from "./index.module.less";
import { CBBasicFormProps, CBBasicFormType } from "./type";
import { FleetDatePicker, FleetFormItem, FleetRangePicker, FleetVerifyInput } from "@/components";
import { CargoBookLabelMap } from "@/types/voyageManage/cargoBook";
import dayjs, { Dayjs } from "dayjs";
import NoSelect from "@/common/NoSelect";

const CBBasicForm: React.FC<CBBasicFormProps> = ({
  item,
  type,
  onChange,
  onCommit,
  onPaymentTermsChange,
  otherItem
}) => {
  return <div className={classnames("headFixture-form", style["cargoBook-basic"])}>
    <span className="vm-common-title">Basic</span>
    <div className="form-container">
      {
        type === "edit" && <FleetVerifyInput
          needVerity
          disabled
          label={CargoBookLabelMap["fixtureNo"]}
          value={item?.fixtureNo}
          onChange={(val: string) => {
            onChange?.("fixtureNo", val)
          }}
        />
      }
      <FleetFormItem
        label={CargoBookLabelMap["chartererName"]}
        needVerity
      >
        <NoSelect
          initItem={[{
            value: (item?.chartererId) as string,
            label: item?.chartererName
          }]}
          label="companyName"
          type="company"
          onFinish={(noItem, newNoItem, type) => {
            onCommit?.({
              chartererId: noItem?.value,
              chartererName: noItem?.label
            })
          }}
        />
      </FleetFormItem>
      <FleetFormItem
        label={CargoBookLabelMap["fixtureDate"]}
        needVerity
      >
        <FleetDatePicker
          allowClear
          value={item?.fixtureDate ? dayjs(item?.fixtureDate) : null}
          onChange={(val: string) => {
            onChange?.("fixtureDate", val)
          }}
        // disabledDate={(current) => {
        //   let prevDate = Date.now() - 24 * 60 * 60 * 1000;
        //   return current && current < dayjs(prevDate).endOf("day")
        // }}
        />
      </FleetFormItem>
    </div>
    <div className="form-container cargoBook-form-container">
      <FleetFormItem
        label={CargoBookLabelMap["layCan"]}
        containerClassname="cargoBook-basic-laycan"
      >
        <FleetRangePicker
          allowClear
          showHour={false}
          showMinute={false}
          showTime={false}
          value={[item?.layCanFrom ? dayjs(item?.layCanFrom) : null, item?.layCanTo ? dayjs(item?.layCanTo) : null]}
          onChange={(vals: [Dayjs, Dayjs]) => {
            const ranges: Pick<CBBasicFormType, "layCanFrom" | "layCanTo"> = {
              layCanFrom: null,
              layCanTo: null
            }
            if (vals) {
              ranges.layCanFrom = vals?.[0]?.startOf("day")?.valueOf();
              ranges.layCanTo = vals?.[1]?.startOf("day")?.valueOf();
            }
            onCommit?.({ ...ranges })
            const paymentStartDay = dayjs(vals?.[1]?.valueOf() + +otherItem?.paymentTerm * 24 * 60 * 60 * 1000)?.format("YYYY-MM-DD")
            onPaymentTermsChange("paymentStartDay", vals ? paymentStartDay : "")
          }}
        // disabledDate={(current) => {
        //   let prevDate = Date.now() - 24 * 60 * 60 * 1000;
        //   return current && current < dayjs(prevDate).endOf("day")
        // }}
        />
      </FleetFormItem>
    </div>
  </div>
}

export default CBBasicForm;