import { ExtendsKeys, ExcludeKeys } from "@/types";
import { MovementLaytimePortFormKeys, MovementLaytimePortFormType } from "../tabs/type";

type SubTotalKeys = MovementLaytimePortFormKeys;

export type MovementLaytimeSubTotalFormType = Pick<MovementLaytimePortFormType, SubTotalKeys>;

export enum PurposeMap {
   DL = "delivery",
   RD = "redelivery",
   LD = "load",
   LB = "load bunker",
   LT = "load transit",
   DC = "discharge",
   DB = "discharge bunker",
   DT = "discharge transit"
}


export type MovementLaytimeSubTotalProps = {
  item:MovementLaytimeSubTotalFormType;
  onChange:MovementLaytimeSubTotalFormChangeEvent;
}


export type MovementLaytimeSubTotalFormChangeEvent = <K extends SubTotalKeys>(id:string | number,key:K,value:MovementLaytimeSubTotalFormType[K]) => void;

export type MovementLaytimeSubTotalFormCommitEvent = (item:Partial<MovementLaytimeSubTotalFormType>) => void;

