import { useMemo } from "react";
import { MovementCargoActualItemType } from "../components/charterBase/components/cargo/type"
import { MovementPortActualItemType } from "../components/charterBase/components/portRotation/type";
import { MovementTcvcOtherItemType } from "../type";
import { MovementVesselBasicsType } from "../components/vesselBasics/type";
import { getPortType } from "../tools";
import { MovementFinancialDataItemType, initialFinacialItem } from "../../../common";

const useEstimation = (
	vesselItem: MovementVesselBasicsType,
	cargoItem: MovementCargoActualItemType,
	portGroup: MovementPortActualItemType[],
	otherItem: MovementTcvcOtherItemType
) => {
	const portCalcutor = useMemo(() => {
		return portGroup?.reduce(
			(prev, curr) => {
				const portType = getPortType(curr?.purpose);
				switch (portType) {
					case "ballast":
						return {
							...prev,
							ballastPortWorkDays: prev?.ballastPortWorkDays + +curr?.workDays,
							ballastPortIdleDays: prev?.ballastPortIdleDays + +curr?.idleDays,
							ballastPortTotalDays: prev?.ballastPortTotalDays + +curr?.totalDays,
							ballastSeaDays: prev?.ballastSeaDays + +curr?.seaDay
						};
					case "laden":
						return {
							...prev,
							ladenPortWorkDays: prev?.ladenPortWorkDays + +curr?.workDays,
							ladenPortIdleDays: prev?.ladenPortIdleDays + +curr?.idleDays,
							ladenPortTotalDays: prev?.ladenPortTotalDays + +curr?.totalDays,
							ladenSeaDays: prev?.ladenSeaDays + +curr?.seaDay
						};
					default:
						return prev;
				}
			},
			{
				ballastPortWorkDays: 0,
				ladenPortWorkDays: 0,
				ballastPortIdleDays: 0,
				ladenPortIdleDays: 0,
				ballastPortTotalDays: 0,
				ladenPortTotalDays: 0,
				ballastSeaDays: 0,
				ladenSeaDays: 0
			}
		);
	}, [portGroup]);
	/** 运费率： 包干 / 每公吨 * 货数 */
	const freightIncome = useMemo(() => {
		if (cargoItem?.freightType === "LP") {
			return +cargoItem?.freightRate;
		}
		return +cargoItem?.freightRate * +cargoItem?.cargoQuantity;
	}, [cargoItem?.freightType, cargoItem?.freightRate, cargoItem?.cargoQuantity]);

	/** 折扣费用 = 运费 * 折扣率 */
	const freightAddCom = useMemo(() => {
		return (+cargoItem?.commission * +freightIncome) / 100;
	}, [cargoItem?.commission, freightIncome]);

	/** 中介费用 = 运费 * 中介费率 */
	const revenuesBrokerage = useMemo(() => {
		return (+cargoItem?.brokerage * +freightIncome) / 100;
	}, [cargoItem?.brokerage, freightIncome]);

	const laytime = useMemo(() => {
		return otherItem?.layTimeSubtotal?.reduce(
			(prev, curr) => {
				const portType = getPortType(curr?.purpose) === "ballast" ? "LP" : "DP";
				const desc = !curr?.demAmount ? `DES` : `DEM`;
				console.log("prev", prev, curr);
				prev.calcuate?.push({
					desc: `${desc} ${portType}_${curr?.portName}`,
					plan: null,
					actual: !curr?.demAmount ? curr?.desAmount : curr?.demAmount,
					diffActual: null,
					diffPosted: null,
					posted: null
				});
				prev.total += !curr?.demAmount ? -curr?.desAmount : curr?.demAmount;
				prev.demAmout += curr?.demAmount;
				prev.desAmount += curr?.desAmount;
				return prev;
			},
			{
				total: 0,
				calcuate: [] as MovementFinancialDataItemType[],
				desAmount: 0,
				demAmout: 0
			}
		);
	}, [otherItem?.layTimeSubtotal]);

	/**收入 */
	const revenues = useMemo(() => {
		return +freightIncome + +laytime?.total + +otherItem?.otherIncome;
	}, [freightIncome, laytime?.total, otherItem?.otherIncome]);

	const totalOtherExpense = useMemo(() => {
		return otherItem?.otherExpense?.reduce((prev, curr) => prev + +curr?.netExpense, 0);
	}, [otherItem?.otherExpense]);

	const totalPortExpense = useMemo(() => {
		return otherItem?.portExpenseList?.reduce(
			(prev, curr) => prev + +curr?.prePortDisb + +curr?.settlement,
			0
		);
	}, [otherItem?.portExpenseList]);

	const totalDisputeCharge = useMemo(() => {
		return otherItem?.disputeChargeList?.reduce((prev, curr) => prev + +curr?.finalAmount, 0);
	}, [otherItem?.disputeChargeList]);

	const offHire = useMemo(() => {
		return otherItem?.offHire?.reduce((prev, curr) => {
			return prev + +curr?.duration;
		}, 0);
	}, [otherItem?.offHire]);

	const otherExpenses = useMemo(() => {
		return otherItem?.otherExpense?.reduce(
			(prev, curr) => {
				prev.total += +curr?.netExpense;
				prev.ownersCount += +curr?.ownerAccount;
				prev.calcuate?.push(
					initialFinacialItem({
						desc: curr?.itemName,
						actual: +curr?.netExpense,
						time: curr?.status === "Settled" ? curr?.settledTime : null
					})
				);
				return prev;
			},
			{
				total: 0,
				ownersCount: 0,
				calcuate: [] as MovementFinancialDataItemType[]
			}
		);
	}, [otherItem?.otherExpense]);

	const disputeCharges = useMemo(() => {
		return otherItem?.disputeChargeList?.reduce(
			(prev, curr) => {
				prev.total += +curr?.finalAmount;
				prev.calcuate?.push(
					initialFinacialItem({
						desc: curr?.itemName,
						actual: +curr?.finalAmount
					})
				);
				return prev;
			},
			{
				total: 0,
				calcuate: [] as MovementFinancialDataItemType[]
			}
		);
	}, [otherItem?.disputeChargeList]);

	const portExpenses = useMemo(() => {
		return otherItem?.portExpenseList?.reduce(
			(prev, curr) => {
				const portType = getPortType(curr?.purpose) === "ballast" ? "L/P" : "D/P";
				prev.total += +curr?.prePortDisb + +curr?.settlement;
				prev.calcuate?.push(
					initialFinacialItem({
						desc: `(Pre-${portType}) Disb_${curr?.portName}`,
						actual: +curr?.prePortDisb,
						portAgentName: curr?.portAgentName,
						time: curr?.status === "Settled" ? curr?.settledTime : null
					}),
					initialFinacialItem({
						desc: `(${portType}) Settlement_${curr?.portName}`,
						actual: +curr?.settlement,
						portAgentName: curr?.portAgentName,
						time: curr?.status === "Settled" ? curr?.settledTime : null
					})
				);
				return prev;
			},
			{
				total: 0,
				calcuate: [] as MovementFinancialDataItemType[]
			}
		);
	}, [otherItem?.portExpenseList]);

	const bunkerage = useMemo(() => {
		return otherItem?.portExpenseList?.reduce(
			(prev, curr) => {
				const portType = getPortType(curr?.purpose) === "ballast" ? "L/P" : "D/P";
				prev.total += +curr?.prePortDisb + +curr?.settlement;
				prev.calcuate?.push(
					initialFinacialItem({
						desc: `(Pre-${portType}) Disb_${curr?.portName}`,
						actual: +curr?.prePortDisb
					}),
					initialFinacialItem({
						desc: `(${portType}) Settlement_${curr?.portName}`,
						actual: +curr?.settlement
					})
				);
				return prev;
			},
			{
				total: 0,
				calcuate: [] as MovementFinancialDataItemType[]
			}
		);
	}, [otherItem?.portExpenseList]);

	console.log("otherItem.portExpenses", portExpenses, otherItem?.portExpenseList);

	const hireExpense = useMemo(() => {
		return otherItem?.hireList?.reduce(
			(prev, curr, index, arr) => {
				prev.total += curr?.status === "Settled" ? curr?.amount : 0;
				prev?.calcuate?.push(
					initialFinacialItem({
						desc: index === arr?.length - 1 ? `Final Hire` : `${curr?.period}st Hire`,
						actual: curr?.amount,
						time: curr?.status === "Settled" ? curr?.settledTime : null,
						posted: curr?.status === "Settled" ? curr?.amount : null
					})
				);
				return prev;
			},
			{
				total: 0,
				calcuate: [] as MovementFinancialDataItemType[]
			}
		);
	}, [otherItem?.hireList]);

	const freightExpense = useMemo(() => {
		return otherItem?.freightList?.reduce(
			(prev, curr, index, arr) => {
				prev.total += curr?.status === "Settled" ? curr?.amount : 0;
				prev?.calcuate?.push(
					initialFinacialItem({
						desc: index === arr?.length - 1 ? `Final Hire` : `${curr?.period}st Hire`,
						actual: curr?.amount,
						posted: curr?.status === "Settled" ? curr?.amount : null
					})
				);
				return prev;
			},
			{
				total: 0,
				calcuate: [] as MovementFinancialDataItemType[]
			}
		);
	}, [otherItem?.freightList]);

	const ifoCost = useMemo(() => {
		return (
			otherItem?.bunkerPlan?.hsfoTotalPrice +
			otherItem?.bunkerPlan?.lsfoTotalPrice +
			+otherItem?.bunkerPlan?.scrubberTotalPrice
		);
	}, [otherItem?.bunkerPlan]);

	const mdoCost = useMemo(() => {
		return otherItem?.bunkerPlan?.mdoTotalPrice + otherItem?.bunkerPlan?.mgoTotalPrice;
	}, [otherItem?.bunkerPlan]);

	const totalBunkerCost = useMemo(() => {
		return ifoCost + mdoCost;
	}, [ifoCost, mdoCost]);

	const operationCost = useMemo(() => {
		return ifoCost + mdoCost + otherExpenses?.total + totalPortExpense;
	}, [ifoCost, mdoCost, totalOtherExpense, otherExpenses]);

	const hireCost = useMemo(() => {
		const totalDays =
			((vesselItem?.estRedeliveryDate ?? 0) - (vesselItem?.deliveryTime ?? 0)) /
			(1000 * 60 * 60 * 24);
		return +vesselItem.dailyHire * totalDays;
		// return +vesselItem.dailyHire * (portCalcutor.ballastSeaDays + portCalcutor.ladenSeaDays + portCalcutor.ballastPortTotalDays + portCalcutor.ladenPortTotalDays)
	}, [vesselItem.dailyHire, vesselItem?.estRedeliveryDate, vesselItem?.deliveryTime]);

	const addCom = useMemo(() => {
		return (hireCost * +vesselItem.commission) / 100;
	}, [hireCost, vesselItem.commission]);

	const totalHireCostBrokerage = useMemo(() => {
		return (hireCost * +vesselItem.brokerage) / 100;
	}, [hireCost, vesselItem.brokerage]);

	const totalHireCost = useMemo(() => {
		return hireCost - (addCom + totalHireCostBrokerage);
	}, [hireCost, addCom, totalHireCostBrokerage]);

	console.log("totalHireCost", {
		hireCost,
		addCom,
		totalHireCostBrokerage,
		totalHireCost,
		operationCost
	});

	const totalCost = useMemo(() => {
		return operationCost + totalHireCost - freightAddCom - revenuesBrokerage;
	}, [operationCost, totalHireCost, freightAddCom, revenuesBrokerage]);

	/** profit: 利润 = 收入 - 成本 */
	const profit = useMemo(() => {
		return revenues - totalCost - laytime?.total;
	}, [revenues, totalCost, laytime?.total]);

	const tce = useMemo(() => {
		const totalDays =
			portCalcutor.ballastSeaDays +
			portCalcutor.ladenSeaDays +
			portCalcutor.ballastPortTotalDays +
			portCalcutor.ladenPortTotalDays;
		return totalDays ? (revenues - operationCost) / totalDays : 0;
	}, [revenues, operationCost, portCalcutor]);

	return {
		actualCalauate: {
			totalProfit: +profit.toFixed(3),
			tce: +tce.toFixed(3),
			totalRevenue: +revenues.toFixed(3),
			totalHireCost: +totalHireCost.toFixed(3),
			freightIncome: +freightIncome.toFixed(3),
			freightAddCommission: +freightAddCom.toFixed(3),
			freightBrokerage: +revenuesBrokerage.toFixed(3),
			operationCost: +operationCost.toFixed(3),
			ifoCost: +ifoCost.toFixed(3),
			mdoCost: +mdoCost.toFixed(3),
			portExpense: +totalPortExpense?.toFixed(3),
			otherExpense: +otherExpenses?.total?.toFixed(3),
			totalCost: +totalCost.toFixed(3),
			commission: +addCom.toFixed(3),
			bunkerCost: +totalBunkerCost.toFixed(3),
			brokerage: +totalHireCostBrokerage.toFixed(3),
			hireCost: +hireCost.toFixed(3),
			totalDisputeCharge: +totalDisputeCharge?.toFixed(3),
			totalSeaDays: portCalcutor?.ballastSeaDays + portCalcutor?.ladenSeaDays,
			desAmount: +laytime?.demAmout.toFixed(3),
			demAmount: +laytime?.desAmount.toFixed(3),
			offHire
		},
		portCalcutor,
		otherExpenses,
		laytime,
		disputeCharges,
		portExpenses,
		hireExpense,
		freightExpense
	};
};

export default useEstimation