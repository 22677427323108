import React, { useMemo } from "react";
import FleetTabs from "@/components/Tabs";
import VoyageManageRouter from "../../router"
import style from "./index.module.less";
import { useAppSelector } from "@/hook";

const VoyagemanageHeader: React.FC<{

}> = (props) => {
  const { firstSelected } = useAppSelector(state => state.GlobalStore);
  const { userInfo } = useAppSelector(state => state.UserStore)

  const tabs = useMemo(() => {
    const children = userInfo?.menus?.find(menu => menu.key === "/layout/voyagemanage")?.children
    return children?.filter(item => {
      return !item?.hidden
    })?.map(item => {
      return {
        ...item,
        label: item?.meta?.title
      }
    })
  }, [userInfo])

  return <div className={style["voyagemanage-header"]}>
    {/* @ts-ignore */}
    <FleetTabs className="voyagemanage-header-tabs" list={tabs} value={firstSelected === "/layout/voyagemanage/movement/details" ? "/layout/voyagemanage" : firstSelected} />
    <span className="voyagemanage-header-line"></span>
  </div>
}

export default VoyagemanageHeader