import React, { FC } from "react";
import classnames from "classnames";
import style from "./index.module.less";
import { PortAreaListFilterProps } from "./type";
import { FleetFormItem, FleetInput, FleetRangePicker, FleetSelect } from "@/components";
import dayjs, { Dayjs } from "dayjs";
import { Button } from "@/_components";
import { aisStatusOptions, vesselTypeSubOption } from "./source";
import { AreaCountrySelect } from "../index";
import AreaSelect from "../../../areaVesselList/components/filter/components/areaSelect";
import { CountrySelect } from "../countrySelect/module";
const PortAreaListFilter: FC<PortAreaListFilterProps> = ({
	searchParam,
	onCommit,
	onDownLoad,
	disabled
}) => {
	return (
		<div className={classnames(style["portArea-list-filter"])}>
			<div className="portAreaListFilter-header">
				<FleetFormItem label="Keyword">
					<FleetInput
						value={searchParam?.keyword}
						prefix="LayoutSearchIcon"
						disabled={disabled}
						onChange={(val: string) => {
							onCommit?.({ keyword: val });
						}}
					/>
				</FleetFormItem>
				<FleetFormItem label="Period">
					<FleetRangePicker
						allowClear
						value={[
							searchParam?.filterStartDate ? dayjs(searchParam.filterStartDate) : null,
							searchParam?.filterEndDate ? dayjs(searchParam.filterEndDate) : null
						]}
						onChange={(vals: [Dayjs, Dayjs]) => {
							onCommit?.({
								filterStartDate: vals?.[0].format("YYYY-MM-DD") ?? null,
								filterEndDate: vals?.[1].format("YYYY-MM-DD") ?? null
							});
						}}
						disabled={disabled}
						showHour={false}
						showMinute={false}
						showTime={false}
					/>
				</FleetFormItem>
				<FleetFormItem label="AIS Status">
					<FleetSelect
						value={searchParam?.aisStatus}
						options={aisStatusOptions}
						className="filterHeader-select"
						onChange={(val: number) => {
							onCommit?.({ aisStatus: val });
						}}
						disabled={disabled}
						allowClear
						onClear={() => {
							onCommit?.({ aisStatus: null });
						}}
					/>
				</FleetFormItem>
				<FleetFormItem label="Vessel Type">
					<FleetSelect
						value={searchParam?.vesselTypeSub}
						options={vesselTypeSubOption}
						className="filterHeader-select"
						allowClear
						disabled={disabled}
						onClear={() => {
							onCommit?.({ vesselTypeSub: null });
						}}
						onChange={(val: number) => {
							onCommit?.({ vesselTypeSub: val });
						}}
					/>
				</FleetFormItem>
				<FleetFormItem label="Last Area Name">
					<AreaSelect
						type="areaName"
						showKey="areaName"
						primaryKey="areaCode"
						disabled={disabled}
						className="filterHeader-select"
						activeItems={{
							areaName: searchParam?.startAreaName,
							areaCode: searchParam?.startAreaCode
						}}
						onFinish={(newItem, type) => {
							onCommit?.({
								startAreaName: newItem?.areaName ?? "",
								startAreaCode: newItem?.areaCode ?? null
							});
						}}
					/>
				</FleetFormItem>
				<FleetFormItem label="Last Port Country">
					<CountrySelect
						type="countryName"
						showKey="countryName"
						disabled={disabled}
						primaryKey="countryCode"
						className="filterHeader-select"
						activeItems={{
							countryName: searchParam?.lastPortCountry
						}}
						onFinish={(newItem, type) => {
							onCommit?.({
								lastPortCountry: newItem?.countryName ?? ""
							});
						}}
					/>
				</FleetFormItem>
			</div>
			<Button type="secondary" size="small" onClick={onDownLoad} prefixIcon="fleet-download">
				Download
			</Button>
		</div>
	);
};

export default PortAreaListFilter;
