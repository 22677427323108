import React, { FC } from "react";
import style from "./index.module.less";
import { MovementTaskCommentProps } from "./type";
import classnaems from "classnames"
import useIndex from "./hooks/useIndex";
import { Button } from "@/_components";
import { FleetLoading, FleetTextarea } from "@/components";
import { MovementTaskCommentItem } from "./components";

const MovementTaskComment: FC<MovementTaskCommentProps> = ({
  currentTaskItem,
  onTaskItemChange
}) => {
  const {
    loading,
    dataSource,
    content,
    setContent,
    saveMovementTaskComment,
    deleteMovementTaskComment
  } = useIndex(currentTaskItem, onTaskItemChange)
  return <div className={style["movement-taskDetails-comment"]}>
    <FleetLoading loading={loading} />
    <div className="comment-header">
      <div className="comment-header--remark">Remark</div>
    </div>
    <div className="comment-container">
      <span className="comment-container--taskTitle">{currentTaskItem?.title}</span>
      <div className="comment-container--commentlist">
        <div className="commentlist-scrollArea">
          {
            dataSource?.map((item, index) => {
              return <MovementTaskCommentItem
                key={index}
                item={item}
                onDelete={deleteMovementTaskComment}
              />
            })
          }
        </div>
      </div>
      <FleetTextarea
        className="comment-container--send"
        placeholder=""
        allowClear={false}
        value={content}
        disabled={!currentTaskItem}
        onChange={setContent}
        renderSuffixIcon={() => {
          return <div className={classnaems("comment-send--btn textarea-suffixIcon", {
            disabled: !currentTaskItem || !content
          })}
            onClick={saveMovementTaskComment}
          >
            <span className="font_family fleet-send"></span>
          </div>
        }}
      />
    </div>
  </div>
}

export default MovementTaskComment