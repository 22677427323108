import { addPortAction, editPortAction, getPortAction } from "@/action/dataBase/port";
import {
	DatabasePortAddFormChangeEvent,
	DatabasePortAddFormCommitEvent,
	DatabasePortAddFormInitEvent,
	DatabasePortAddFormType,
	checkPortKeys,
	initialDatabasePortAddForm,
	initialPortItem
} from "@/featrue/database/port/add/type";
import { useAppDispatch } from "@/hook";
import useReminder from "@/hook/useReminder";
import { useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

const useAddPort = (onReset?: () => void, portId?: string | number) => {
	const dispatch = useAppDispatch();
	const navigate = useNavigate();
	const { t } = useTranslation();
	const { reminder } = useReminder();
	const [open, setOpen] = useState(false);
	const openStatus = useRef<boolean>(false);
	const [loading, setLoading] = useState(false);
	const [position, setPosition] = useState("");
	const [dataSource, setDataSource] = useState<DatabasePortAddFormType>(initialPortItem());

	const change: DatabasePortAddFormChangeEvent = (key, value) => {
		setDataSource((prev) => {
			return {
				...prev,
				[key]: value
			};
		});
	};

	const commit: DatabasePortAddFormCommitEvent = (item) => {
		setDataSource((prev) => ({ ...prev, ...item }));
	};

	const init: DatabasePortAddFormInitEvent = (item) => {
		setDataSource(item);
	};

	const check = () => {
		let checkKeys = "";
		let checked = true;
		for (let key of checkPortKeys) {
			if (dataSource[key] !== 0 && !dataSource[key]) {
				checkKeys = key;
				checked = false;
				break;
			}
		}

		return {
			checkKeys,
			checked
		};
	};

	const latitudeClose = (portCode: string) => {
		const [lon, lat] = portCode?.split(",");
		commit({
			lon: +lon,
			lat: +lat
		});
		setPosition(portCode);
		setOpen(false);
	};

	const addPortFront = () => {
		setLoading(true);
	};

	const addPortSuccess = (response) => {
		setLoading(false);
		reminder("success", response?.msg);
		onReset?.();
	};

	const addPortError = (error) => {
		reminder("error", error?.msg ? error?.msg + ": " + error?.data : error?.data);
		setLoading(false);
	};

	const getPortSuccess = (response) => {
		commit({
			...response?.data
		});
		setPosition(`${response?.data?.lon}, ${response?.data?.lat}`);
		setLoading(false);
	};

	const addPort = () => {
		const { checkKeys, checked } = check();
		if (!checked) {
			reminder("error", `Field ${t(`dataBase.port.${checkKeys}`)} is required`);
			return;
		}
		addPortAction(
			{ ...dataSource },
			addPortFront,
			addPortSuccess,
			addPortError,
			dispatch,
			navigate
		);
	};

	const editPort = () => {
		const { checkKeys, checked } = check();
		if (!checked) {
			reminder("error", `Field ${t(`dataBase.port.${checkKeys}`)} is required`);
			return;
		}
		editPortAction(
			{ ...dataSource, id: portId },
			addPortFront,
			addPortSuccess,
			addPortError,
			dispatch,
			navigate
		);
	};

	const getPort = () => {
		getPortAction(
			{
				id: portId
			},
			addPortFront,
			getPortSuccess,
			addPortError,
			dispatch,
			navigate
		);
	};

	const clear = () => {
		setPosition("");
		setDataSource(initialPortItem());
	};

	return {
		dataSource,
		open,
		openStatus,
		position,
		loading,
		setOpen,
		change,
		commit,
		getPort,
		addPort,
		editPort,
		latitudeClose,
		init,
		clear
	};
};

export default useAddPort;
