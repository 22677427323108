import React, { FC } from "react";
import style from "./index.module.less";
import { InvStatementFixedExpensesProps, InvStatementFixedExpensesType } from "./type";
import classnames from "classnames";
import { FleetVerifyInput } from "@/components";
import { InvoiceAddTo } from "@/pages/voyagemanage/business/invoices/module";
import { FleetTabbar } from "@/components/Tabs";
import { calcCveValues } from "./tool";

const InvStatementFixedExpenses: FC<InvStatementFixedExpensesProps> = ({
	item,
	otherItem,
	onOtherItemChange,
	onChange,
	onCommit
}) => {
	return (
		<div className={classnames("invoicesDetail-form", style["invStatement-fixedExpenses"])}>
			<div className="invoicesForm-header">
				<InvoiceAddTo
					type="addFixed"
					value={otherItem?.addFixed}
					onChange={(key, val) => {
						onOtherItemChange?.("addFixed", val);
					}}
				>
					<span>Fixed Expenses</span>
				</InvoiceAddTo>
				<span></span>
			</div>
			<div className="invoicesForm-container">
				<FleetVerifyInput
					value={item?.cve}
					type="number"
					label="C/V/E"
					onChange={(val: number) => {
						onChange?.("cve", val);
					}}
				/>
				<FleetVerifyInput
					value={item?.ilohc}
					type="number"
					label="ILOHC"
					onChange={(val: number) => {
						onChange?.("ilohc", val);
					}}
				/>
				<div className="fixedExpenses-cveCycle">
					<FleetVerifyInput
						value={item?.cvePeriodValue}
						type="number"
						label="C/V/E RATE"
						onChange={(val: number) => {
							onCommit?.({
								cvePeriodValue: val,
								cve: calcCveValues(
									{ ...item, cvePeriodValue: val },
									{ ttlOnHireDay: otherItem?.ttlOnHireDay }
								)
							});
						}}
					/>
					<FleetTabbar
						value={item?.cvePeriodDay === 1 ? "M" : "Y"}
						list={["M", "Y"]}
						onChange={(val: "M" | "Y") => {
							const value = val === "M" ? 1 : 2;
							onCommit?.({
								cvePeriodDay: value,
								cve: calcCveValues(
									{ ...item, cvePeriodDay: value },
									{ ttlOnHireDay: otherItem?.ttlOnHireDay }
								)
							});
						}}
					/>
				</div>
			</div>
		</div>
	);
};

export default InvStatementFixedExpenses;
