import React, { useState } from "react";
import style from "./index.module.less";
import { EstTemplateProps } from "./type";
import { FleetFormItem, FleetTooltip, FleetVerifyInput } from "@/components";
import { CommonTempSelect } from "@/common";
import { Button } from "@/_components";

const EstTemplate: React.FC<EstTemplateProps> = ({
  className,
  onCommit,
  onTemplateSave,
  activeItem,
  label
}) => {
  const [templateName, setTemplateName] = useState("");
  return <div className={className}>
    <FleetFormItem
      label={label}
    >
      <CommonTempSelect
        activeItem={{
          id: activeItem?.id,
          templateName: activeItem?.templateName
        }}
        onFinish={(newItem, type) => {
          if (type === "del") {
            onCommit?.({
              templateId: null,
              templateName: "",
            })
            return
          }
          console.log("newItem", newItem)
          onCommit?.({
            templateId: newItem.id,
            templateName: newItem.templateName,
            dwt: newItem?.dwt,
            ballastSpeed: newItem?.ballastSpeed,
            ladenSpeed: newItem?.ladenSpeed,
            seaFuelBallast: newItem?.seaFuelBallast,
            seaFuelLaden: newItem?.seaFuelLaden,
            seaFuelMdo: newItem?.seaFuelMdo,
            tonnageType: newItem?.tonnageType,
            portFuelIdle: newItem?.portFuelIdle,
            portFuelMdo: newItem?.portFuelMdo,
            portFuelWork: newItem?.portFuelWork,
            tonnageValue: newItem?.tonnageValue
          })
        }}
      />
    </FleetFormItem>
    <FleetTooltip
      trigger="click"
      outerLayer={<Button type="icon" size="small"><span className="font_family fleet-save"></span></Button>}
      render={(setIsOpen) => {
        return <div className={style["estimation-template"]}>
          <FleetVerifyInput
            label="Please name the template"
            needVerity
            value={templateName}
            onChange={(value: string) => {
              setTemplateName(value)
            }}
          />
          <div className="template-actions">
            <Button size="minuscule" type="secondary" onClick={() => {
              setIsOpen(false);
              setTemplateName("");
            }}>Cancel</Button>
            <Button size="minuscule" disabled={!templateName} onClick={() => {
              setIsOpen(false);
              onTemplateSave?.(templateName);
              setTemplateName("");
            }}>Confirm</Button>
          </div>
        </div>
      }}
    />
  </div>
}

export default EstTemplate