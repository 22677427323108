import React, { FC } from "react";
import style from "./index.module.less";
import classnames from "classnames";
import { Collapse } from "antd";
import useIndex from "./hooks/useIndex";
import { RouteCommonVesselCardItemProps } from "./type";
import {
	RouteCommonSegment,
	RouteCommonVesselCardHeader,
	RouteCommonVesselCardOperator
} from "./components";
import { FleetLoading } from "@/components";

const RouteCommonVesselCardItem: FC<RouteCommonVesselCardItemProps> = ({
	mode,
	activeItem,
	onClose,
	onSelect,
	onTrackRefresh,
	onAisListReset
}) => {
	const {
		loading,
		dataSource,
		activeTime,
		handleTimeSelect,
		handleToolbarOpen,
		handlePortCallOpen,
		handleSaveCollection,
		rangeOption,
		handleRangeCommit
	} = useIndex(mode, activeItem, onSelect, onTrackRefresh, onAisListReset);
	return (
		<Collapse
			className={classnames(style["routeCommon-vesselCard-item"], mode)}
			defaultActiveKey={[mode]}
			items={[
				{
					key: mode,
					label: <RouteCommonVesselCardHeader mode={mode} item={dataSource} onClose={onClose} />,
					children: (
						<div className="aisDetails-container">
							<FleetLoading loading={loading} />
							{
								<RouteCommonSegment
									item={{
										lat: dataSource?.lat,
										lon: dataSource?.lon,
										desAisPort: dataSource?.desAisPort,
										startPortName: dataSource?.startPortName,
										endPortName: dataSource?.endPortName
									}}
								/>
							}
							<RouteCommonVesselCardOperator
								item={dataSource}
								activeTime={activeTime}
								onSelect={handleTimeSelect}
								onOpen={handleToolbarOpen}
								onPortCallOpen={handlePortCallOpen}
								mode={mode}
								onSave={handleSaveCollection}
								rangeOption={rangeOption}
								onRangeCommit={handleRangeCommit}
							/>
						</div>
					)
				}
			]}
		/>
	);
};

export default RouteCommonVesselCardItem;
