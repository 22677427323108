import { createContext } from "react";
import { EstIdentifierMapChangeEvent, EstIdentifierMapCommitEvent, EstIdentifierMapType, EstShippingMapCommitEvent } from "../type";

interface EstDetailsProviderType {
  identifierMap?: EstIdentifierMapType
  onIdentifierChange?: EstIdentifierMapChangeEvent
  onIdentifierCommit?: EstIdentifierMapCommitEvent
  onShippingCommit?: EstShippingMapCommitEvent
}

export const initialEstDetailsProvider: EstDetailsProviderType = {

}

export const EstDetailsContext = createContext<EstDetailsProviderType>(initialEstDetailsProvider);

