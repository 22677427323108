import { useMemo } from "react";
import { checkKeys, initialStatementBunkerPriceItem } from "../source";
import { InvStatementBunkerPriceProps } from "../type";
import { useCommonIndex } from "@/hook";

const useIndex = (otherItem: Pick<InvStatementBunkerPriceProps["otherItem"], "addBunker">) => {
	const { dataSource, commit, init, check } = useCommonIndex<InvStatementBunkerPriceProps["item"]>(
		initialStatementBunkerPriceItem,
		checkKeys
	);

	const summary = useMemo(() => {
		if (!otherItem?.addBunker)
			return {
				less: 0,
				plus: 0
			};
		return {
			plus: dataSource?.bodFoTotalValue + dataSource?.bodMdoTotalValue,
			less:
				dataSource?.borMdoTotalValue +
				dataSource?.borFoTotalValue +
				dataSource?.bohFoTotalValue +
				dataSource?.bohMdoTotalValue
		};
	}, [dataSource, otherItem?.addBunker]);

	return {
		summary,
		dataSource,
		commit,
		init,
		check
	};
};

export default useIndex;
