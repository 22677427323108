import { ApiParameter, RequestApiMapType } from "@/api";
import request from "@/service";

import { RouteCommonLegListParamsType, getRouteCommonLegListParams } from "./type";
import { appVersion } from "@/api/constant";

const enum ApiMap {
	getRouteCommonLegListParameter
}

export type ApiUrl = keyof typeof ApiMap;

const LegApi: ApiParameter<ApiUrl> = {
	getRouteCommonLegListParameter: {
		method: "get",
		url: appVersion + "/moni/voy/list2"
	}
};

export const getRouteCommonLegListApi = (params: getRouteCommonLegListParams) => {
	return request.request({
		...LegApi.getRouteCommonLegListParameter,
		params
	});
};

export const LegApiMap: RequestApiMapType<ApiUrl, RouteCommonLegListParamsType> = {
	getRouteCommonLegListApi
};
