import { useState } from "react"
import { MonitoringAisDetailSelectTimeKeys } from "../type";

const useIndex = <T, U>() => {
  const [loading, setLoading] = useState(false);
  const [activeItem, setActiveItem] = useState<T>(null)
  const [activeDetailItem, setActiveDetailItem] = useState<U>(null);
  const [activeDate, setActiveDate] = useState<MonitoringAisDetailSelectTimeKeys>("7/day");

  return {
    loading,
    activeItem,
    activeDetailItem,
    activeDate,
    setLoading,
    setActiveItem,
    setActiveDetailItem,
    setActiveDate
  }

}

export default useIndex