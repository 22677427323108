import { queryUserGroupAction, queryUserRoleGroupAction } from "@/action/dataBase/contact";
import useCommonIndex from "../../../hooks/useIndex";
import { CommonUserItemType, CommonUserSelectProps } from "../type";
const useIndex = (
	activeItems: Partial<CommonUserItemType> | Partial<CommonUserItemType>[],
	showKey: keyof CommonUserItemType,
	onFinish: (newItem: CommonUserItemType, operateType: "add" | "del") => void,
	primaryKey: keyof CommonUserItemType = "userId",
	disabled: boolean,
	params?: CommonUserSelectProps["roleParams"]
) => {
	const {
		loading,
		open,
		list,
		searchValue,
		selectValue,
		page,
		handleSearch,
		focusPopup,
		closePopup,
		loadMore,
		handleItemSelect,
		tagRender
	} = useCommonIndex<CommonUserItemType, keyof CommonUserItemType>(
		activeItems,
		showKey,
		onFinish,
		!!params ? queryUserRoleGroupAction : queryUserGroupAction,
		primaryKey,
		disabled,
		params
	);

	return {
		loading,
		open,
		list,
		searchValue,
		selectValue,
		page,
		handleSearch,
		focusPopup,
		closePopup,
		loadMore,
		handleItemSelect,
		tagRender
	};
};

export default useIndex;
