import React, { useEffect, useState } from "react";
import { PromptChildrenProps } from "@/common/Prompt";
import EditContract from "../../common/editContract";
import CBBasicForm from "../basic";
import CBPaymentTermsForm from "../freightPayterm";
import CBCargoInfoForm from "../cargoInfo";
import CBPortRotation from "../portRotation";
import useAddCargoBook from "@/hook/voyageManage/cargoBook/useAddCargoBook";
import { useAppDispatch } from "@/hook";
import { useNavigate } from "react-router-dom";
import useReminder from "@/hook/useReminder";
import { editCargoBookParams } from "@/api/voyageManage/cargoBook";
import { editCargoBookAction, getCargoBookAction } from "@/action/voyageManage/cargoBook";


export type EditCargoBookProps = {
  recordId?: number;
  onReset?: () => void
} & PromptChildrenProps;

const EditCargoBook: React.FC<EditCargoBookProps> = ({
  hide,
  onClose,
  recordId,
  onReset
}) => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { reminder } = useReminder()
  const {
    loading,
    basicForm,
    payTermForm,
    cargoInfoForm,
    portRotation,
    setLoading,
    handleBasicFormChange,
    handlePayTermFormChange,
    handleCargoInfoFormhange,
    handlePortFormChange,
    handleBasicFormCommit,
    handlePayTermFormCommit,
    handleCargoInfoFormCommit,
    handlePortCommitForm,
    handlePortAdd,
    handlePortInit,
    handlePortDel,
    delIds,
    check,
    clear
  } = useAddCargoBook();
  const [voyageId, setVoyageId] = useState<string | number>("");
  const initCargoBook = (data: Omit<editCargoBookParams, "id">) => {
    console.log("fixtureNo", data?.fixtureNo)
    handleBasicFormCommit({
      fixtureNo: data?.fixtureNo ?? "",
      chartererName: data?.chartererName ?? "",
      chartererId: data?.chartererId ?? "",
      fixtureDate: data?.fixtureDate ?? "",
      layCanFrom: data?.layCanFrom ?? undefined,
      layCanTo: data?.layCanTo ?? undefined
    });

    handlePayTermFormCommit({
      paymentRate: data?.paymentRate ?? "",
      paymentTerm: data?.paymentTerm ?? "",
      paymentStartDay: data?.paymentStartDay ?? ""
    });

    handleCargoInfoFormCommit({
      cargoName: data?.cargoName ?? "",
      cargoQuantity: data?.cargoQuantity ?? "",
      cargoUnit: data?.cargoUnit ?? "MT",
      freightRate: data?.freightRate ?? "",
      freightType: data?.freightType ?? "FR",
      commission: data?.commission ?? "",
      brokerage: data?.brokerage ?? "",
      brokerId: data?.brokerId ?? "",
      brokerName: data?.brokerName ?? ""
    });

    handlePortInit(data?.portRotationList);

    setVoyageId((data?.voyageId ?? ""))
  }

  const editCargoBookFront = () => {
    setLoading(true);
  }

  const getCargoBookFront = () => {
    clear();
    setLoading(true);
  }

  const editCargoBookSuccess = (response) => {
    reminder("success", response?.data);
    setLoading(false);
    onClose?.();
    onReset?.();
  }

  const editCargoBookError = (error) => {
    reminder("error", error?.msg ? (error?.msg + ": " + error?.data) : error?.data);
    setLoading(false);
  }

  const getCargoBook = () => {
    getCargoBookAction(
      {
        id: recordId
      },
      getCargoBookFront,
      getCargoBookSuccess,
      editCargoBookError,
      dispatch,
      navigate
    )
  }

  const getCargoBookSuccess = (response) => {
    initCargoBook(response?.data)
    reminder("success", response?.msg);
    setLoading(false);
  }

  const editCargoBook = () => {
    if (!check()) return;
    editCargoBookAction(
      {
        ...basicForm,
        ...payTermForm,
        ...cargoInfoForm,
        ...payTermForm,
        id: recordId,
        voyageId:voyageId,
        portRotationList: portRotation,
        delIds:delIds?.current
      },
      editCargoBookFront,
      editCargoBookSuccess,
      editCargoBookError,
      dispatch,
      navigate
    )
  }

  useEffect(() => {
    if (hide) return;
    getCargoBook()
  }, [hide, recordId])


  return <EditContract
    hide={hide}
    onClose={onClose}
    label="Cargo Book"
    loading={loading}
    onSave={editCargoBook}
  >
    <CBBasicForm
      type="edit"
      item={basicForm}
      onChange={handleBasicFormChange}
      onCommit={handleBasicFormCommit}
      onPaymentTermsChange={handlePayTermFormChange}
      otherItem={payTermForm}
    />
    <CBPaymentTermsForm
      item={payTermForm}
      otherItem={basicForm}
      onChange={handlePayTermFormChange}
      onCommit={handlePayTermFormCommit}
    />
    <CBCargoInfoForm
      item={cargoInfoForm}
      onChange={handleCargoInfoFormhange}
      onCommit={handleCargoInfoFormCommit}
    />
    <CBPortRotation
      items={portRotation}
      onChange={handlePortFormChange}
      onCommit={handlePortCommitForm}
      onAdd={handlePortAdd}
      onDelete={handlePortDel}
    />
  </EditContract>
}

export default EditCargoBook