import { ColumnsType } from "antd/es/table";
import { RouteCommonPortCallItemType } from "./type";
import dayjs from "dayjs";
import React from "react";
import { ArrivalPurposeMap } from "@/common/Select";

export const columns: ColumnsType<RouteCommonPortCallItemType> = [
	{
		title: "Vessel Name",
		dataIndex: "vesselName",
		key: "vesselName",
		width: 150,
		fixed: "left"
	},
	{
		title: "IMO",
		dataIndex: "imo",
		key: "imo",
		width: 120,
		fixed: "left"
	},
	{
		title: "ATA(UTC)",
		dataIndex: "ata",
		key: "ata",
		width: 200,
		render(value, record, index) {
			return <span>{dayjs(value)?.format("YYYY-MM-DD HH:mm")}</span>;
		}
	},
	{
		title: "Purpose",
		dataIndex: "purpose",
		key: "purpose",
		width: 150,
		render(value, record, index) {
			return <span>{value ? ArrivalPurposeMap[value] : "-"}</span>;
		}
	},
	{
		title: "Port Name",
		key: "portName",
		dataIndex: "portName",
		width: 200
	},
	{
		title: "Port Country",
		key: "countryName",
		dataIndex: "countryName",
		width: 200
	},
	{
		title: "ATD(UTC)",
		key: "atd",
		dataIndex: "atd",
		width: 200,
		render(value, record, index) {
			return <span>{dayjs(value)?.format("YYYY-MM-DD HH:mm")}</span>;
		}
	},
	{
		title: "Portstay (Hours)",
		key: "portStayHour",
		dataIndex: "portStayHour",
		width: 120
	},
	{
		title: "Portstay (Days)",
		key: "portStayDay",
		dataIndex: "portStayDay",
		width: 120
	}
];
