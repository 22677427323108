import React, { FC } from "react";
import style from "./index.module.less";
import { RoutePortFilterProps } from "./type";
import { RoutePortSelect } from "./components";

const RoutePortFilter: FC<RoutePortFilterProps> = ({ portItem, onCommit }) => {
	return (
		<div className={style["route-portFilter"]}>
			<span className="portFilter-label">Find a Port</span>
			<RoutePortSelect
				activeItems={portItem}
				onFinish={(newItem, type) => {
					onCommit?.({
						portName: newItem?.portName ?? "",
						portCode: newItem?.portCode ?? ""
					});
				}}
				showKey="portName"
				primaryKey="portCode"
			/>
		</div>
	);
};

export default RoutePortFilter;
