import { type ParameterType } from "@/api";
import { loginOutAction } from "@/store/userSlice";

import {
	getVoyageGroupApi,
	getVoyageDetailApi,
	addVoyageApi,
	editVoyageApi,
	toMovementApi,
	getVoyageAttachApi,
	deleteVoyageAttactApi,
	uploadVoyageAttachApi,
	saveVoyageRemarkApi,
	queryCargoBookGroupApi,
	queryHeadFixtureGroupApi,
	getVoyageContactGroupApi,
	getVoyageContactDetailApi,
	deleteVoyageContactApi,
	addVoyageContactApi,
	queryVoyageContactSelectApi,
	getVoyageDetailParams,
	getVoyageGroupParams,
	addVoyageParams,
	toMovementParams,
	getVoyageAttachParams,
	deleteVoyageAttactParams,
	uploadVoyageAttachParams,
	saveVoyageRemarkParams,
	queryCargoBookGroupParams,
	queryHeadFixtureGroupParams,
	getVoyageContactGroupParams,
	getVoyageContactParams,
	deleteVoyageContactParams,
	addVoyageContactParams,
	getPreopTcvcHandoverFormApi,
	savePreopTcvcHandoverFormApi,
	getPreopTctcHandoverFormApi,
	savePreopTctcHandoverFormApi,
	getPreopTcvcHandoverFormParams,
	savePreopTcvcHandoverFormParams,
	getPreopTctcHandoverFormParams,
	savePreopTctcHandoverFormParams,
	getVoyageAttachAndContactApi,
	getVoyageAttachAndContactParams,
	saveVoyageContactApi,
	saveVoyageContactParams,
	updatePreopRemarkApi,
	updatePreopRemarkParams,
	uploadVmVoyageAttachApi,
	uploadVmVoyageAttachParams,
	deleteVoyageApi,
	deleteVoyageParams
} from "@/api/voyageManage/preOperation";

export const getVoyageGroupAction: ParameterType<getVoyageGroupParams> = (
	params,
	frontCallback,
	successCallback,
	errorCallback,
	dispatch,
	navigate
) => {
	frontCallback?.();
	getVoyageGroupApi(params)
		.then((response) => {
			successCallback?.(response);
		})
		.catch((err) => {
			errorCallback?.(err);
			if (err?.loginStatus === "token faild") {
				// dispatch?.(loginOut({}));
				// navigate?.('/')
				dispatch(loginOutAction(() => navigate?.("/login")));
			}
		});
};

export const getVoyageDetailAction: ParameterType<getVoyageDetailParams> = (
	params,
	frontCallback,
	successCallback,
	errorCallback,
	dispatch,
	navigate
) => {
	frontCallback?.();
	getVoyageDetailApi(params)
		.then((response) => {
			successCallback?.(response);
		})
		.catch((err) => {
			errorCallback?.(err);
			if (err?.loginStatus === "token faild") {
				// dispatch?.(loginOut({}));
				// navigate?.('/')
				dispatch(loginOutAction(() => navigate?.("/login")));
			}
		});
};

export const addVoyageAction: ParameterType<addVoyageParams> = (
	params,
	frontCallback,
	successCallback,
	errorCallback,
	dispatch,
	navigate
) => {
	frontCallback?.();
	addVoyageApi(params)
		.then((response) => {
			successCallback?.(response);
		})
		.catch((err) => {
			errorCallback?.(err);
			if (err?.loginStatus === "token faild") {
				// dispatch?.(loginOut({}));
				// navigate?.('/')
				dispatch(loginOutAction(() => navigate?.("/login")));
			}
		});
};

export const editVoyageAction: ParameterType<addVoyageParams> = (
	params,
	frontCallback,
	successCallback,
	errorCallback,
	dispatch,
	navigate
) => {
	frontCallback?.();
	editVoyageApi(params)
		.then((response) => {
			successCallback?.(response);
		})
		.catch((err) => {
			errorCallback?.(err);
			if (err?.loginStatus === "token faild") {
				// dispatch?.(loginOut({}));
				// navigate?.('/')
				dispatch(loginOutAction(() => navigate?.("/login")));
			}
		});
};

export const toMovementAction: ParameterType<toMovementParams> = (
	params,
	frontCallback,
	successCallback,
	errorCallback,
	dispatch,
	navigate
) => {
	frontCallback?.();
	toMovementApi(params)
		.then((response) => {
			successCallback?.(response);
		})
		.catch((err) => {
			errorCallback?.(err);
			if (err?.loginStatus === "token faild") {
				// dispatch?.(loginOut({}));
				// navigate?.('/')
				dispatch(loginOutAction(() => navigate?.("/login")));
			}
		});
};

export const getVoyageAttachAction: ParameterType<getVoyageAttachParams> = (
	params,
	frontCallback,
	successCallback,
	errorCallback,
	dispatch,
	navigate
) => {
	frontCallback?.();
	getVoyageAttachApi(params)
		.then((response) => {
			successCallback?.(response);
		})
		.catch((err) => {
			errorCallback?.(err);
			if (err?.loginStatus === "token faild") {
				// dispatch?.(loginOut({}));
				// navigate?.('/')
				dispatch(loginOutAction(() => navigate?.("/login")));
			}
		});
};

export const deleteVoyageAttactAction: ParameterType<deleteVoyageAttactParams> = (
	params,
	frontCallback,
	successCallback,
	errorCallback,
	dispatch,
	navigate
) => {
	frontCallback?.();
	deleteVoyageAttactApi(params)
		.then((response) => {
			successCallback?.(response);
		})
		.catch((err) => {
			errorCallback?.(err);
			if (err?.loginStatus === "token faild") {
				// dispatch?.(loginOut({}));
				// navigate?.('/')
				dispatch(loginOutAction(() => navigate?.("/login")));
			}
		});
};

export const uploadVoyageAttachAction: ParameterType<uploadVoyageAttachParams> = (
	params,
	frontCallback,
	successCallback,
	errorCallback,
	dispatch,
	navigate
) => {
	frontCallback?.();
	uploadVoyageAttachApi(params)
		.then((response) => {
			successCallback?.(response);
		})
		.catch((err) => {
			errorCallback?.(err);
			if (err?.loginStatus === "token faild") {
				// dispatch?.(loginOut({}));
				// navigate?.('/')
				dispatch(loginOutAction(() => navigate?.("/login")));
			}
		});
};

export const saveVoyageRemarkAction: ParameterType<saveVoyageRemarkParams> = (
	params,
	frontCallback,
	successCallback,
	errorCallback,
	dispatch,
	navigate
) => {
	frontCallback?.();
	saveVoyageRemarkApi(params)
		.then((response) => {
			successCallback?.(response);
		})
		.catch((err) => {
			errorCallback?.(err);
			if (err?.loginStatus === "token faild") {
				// dispatch?.(loginOut({}));
				// navigate?.('/')
				dispatch(loginOutAction(() => navigate?.("/login")));
			}
		});
};

export const queryCargoBookGroupAction: ParameterType<queryCargoBookGroupParams> = (
	params,
	frontCallback,
	successCallback,
	errorCallback,
	dispatch,
	navigate
) => {
	frontCallback?.();
	queryCargoBookGroupApi(params)
		.then((response) => {
			successCallback?.(response);
		})
		.catch((err) => {
			errorCallback?.(err);
			if (err?.loginStatus === "token faild") {
				// dispatch?.(loginOut({}));
				// navigate?.('/')
				dispatch(loginOutAction(() => navigate?.("/login")));
			}
		});
};

export const queryHeadFixtureGroupAction: ParameterType<queryHeadFixtureGroupParams> = (
	params,
	frontCallback,
	successCallback,
	errorCallback,
	dispatch,
	navigate
) => {
	frontCallback?.();
	queryHeadFixtureGroupApi(params)
		.then((response) => {
			successCallback?.(response);
		})
		.catch((err) => {
			errorCallback?.(err);
			if (err?.loginStatus === "token faild") {
				// dispatch?.(loginOut({}));
				// navigate?.('/')
				dispatch(loginOutAction(() => navigate?.("/login")));
			}
		});
};
export const getVoyageContactGroupAction: ParameterType<getVoyageContactGroupParams> = (
	params,
	frontCallback,
	successCallback,
	errorCallback,
	dispatch,
	navigate
) => {
	frontCallback?.();
	getVoyageContactGroupApi(params)
		.then((response) => {
			successCallback?.(response);
		})
		.catch((err) => {
			errorCallback?.(err);
			if (err?.loginStatus === "token faild") {
				// dispatch?.(loginOut({}));
				// navigate?.('/')
				dispatch(loginOutAction(() => navigate?.("/login")));
			}
		});
};

export const getVoyageContactDetailAction: ParameterType<getVoyageContactParams> = (
	params,
	frontCallback,
	successCallback,
	errorCallback,
	dispatch,
	navigate
) => {
	frontCallback?.();
	getVoyageContactDetailApi(params)
		.then((response) => {
			successCallback?.(response);
		})
		.catch((err) => {
			errorCallback?.(err);
			if (err?.loginStatus === "token faild") {
				// dispatch?.(loginOut({}));
				// navigate?.('/')
				dispatch(loginOutAction(() => navigate?.("/login")));
			}
		});
};

export const deleteVoyageContactAction: ParameterType<deleteVoyageContactParams> = (
	params,
	frontCallback,
	successCallback,
	errorCallback,
	dispatch,
	navigate
) => {
	frontCallback?.();
	deleteVoyageContactApi(params)
		.then((response) => {
			successCallback?.(response);
		})
		.catch((err) => {
			errorCallback?.(err);
			if (err?.loginStatus === "token faild") {
				// dispatch?.(loginOut({}));
				// navigate?.('/')
				dispatch(loginOutAction(() => navigate?.("/login")));
			}
		});
};

export const addVoyageContactAction: ParameterType<addVoyageContactParams> = (
	params,
	frontCallback,
	successCallback,
	errorCallback,
	dispatch,
	navigate
) => {
	frontCallback?.();
	addVoyageContactApi(params)
		.then((response) => {
			successCallback?.(response);
		})
		.catch((err) => {
			errorCallback?.(err);
			if (err?.loginStatus === "token faild") {
				// dispatch?.(loginOut({}));
				// navigate?.('/')
				dispatch(loginOutAction(() => navigate?.("/login")));
			}
		});
};

export const queryVoyageContactSelectAction: ParameterType<null> = (
	params,
	frontCallback,
	successCallback,
	errorCallback,
	dispatch,
	navigate
) => {
	frontCallback?.();
	queryVoyageContactSelectApi()
		.then((response) => {
			successCallback?.(response);
		})
		.catch((err) => {
			errorCallback?.(err);
			if (err?.loginStatus === "token faild") {
				// dispatch?.(loginOut({}));
				// navigate?.('/')
				dispatch(loginOutAction(() => navigate?.("/login")));
			}
		});
};

export const getPreopTcvcHandoverFormAction: ParameterType<getPreopTcvcHandoverFormParams> = (
	params,
	frontCallback,
	successCallback,
	errorCallback,
	dispatch,
	navigate
) => {
	frontCallback?.();
	getPreopTcvcHandoverFormApi(params)
		.then((response) => {
			successCallback?.(response);
		})
		.catch((err) => {
			errorCallback?.(err);
			if (err?.loginStatus === "token faild") {
				// dispatch?.(loginOut({}));
				// navigate?.('/')
				dispatch(loginOutAction(() => navigate?.("/login")));
			}
		});
};

export const savePreopTcvcHandoverFormAction: ParameterType<savePreopTcvcHandoverFormParams> = (
	params,
	frontCallback,
	successCallback,
	errorCallback,
	dispatch,
	navigate
) => {
	frontCallback?.();
	savePreopTcvcHandoverFormApi(params)
		.then((response) => {
			successCallback?.(response);
		})
		.catch((err) => {
			errorCallback?.(err);
			if (err?.loginStatus === "token faild") {
				// dispatch?.(loginOut({}));
				// navigate?.('/')
				dispatch(loginOutAction(() => navigate?.("/login")));
			}
		});
};

export const getPreopTctcHandoverFormAction: ParameterType<getPreopTctcHandoverFormParams> = (
	params,
	frontCallback,
	successCallback,
	errorCallback,
	dispatch,
	navigate
) => {
	frontCallback?.();
	getPreopTctcHandoverFormApi(params)
		.then((response) => {
			successCallback?.(response);
		})
		.catch((err) => {
			errorCallback?.(err);
			if (err?.loginStatus === "token faild") {
				// dispatch?.(loginOut({}));
				// navigate?.('/')
				dispatch(loginOutAction(() => navigate?.("/login")));
			}
		});
};

export const savePreopTctcHandoverFormAction: ParameterType<savePreopTctcHandoverFormParams> = (
	params,
	frontCallback,
	successCallback,
	errorCallback,
	dispatch,
	navigate
) => {
	frontCallback?.();
	savePreopTctcHandoverFormApi(params)
		.then((response) => {
			successCallback?.(response);
		})
		.catch((err) => {
			errorCallback?.(err);
			if (err?.loginStatus === "token faild") {
				// dispatch?.(loginOut({}));
				// navigate?.('/')
				dispatch(loginOutAction(() => navigate?.("/login")));
			}
		});
};

export const getVoyageAttachAndContactAction: ParameterType<getVoyageAttachAndContactParams> = (
	params,
	frontCallback,
	successCallback,
	errorCallback,
	dispatch,
	navigate
) => {
	frontCallback?.();
	getVoyageAttachAndContactApi(params)
		.then((response) => {
			successCallback?.(response);
		})
		.catch((err) => {
			errorCallback?.(err);
			if (err?.loginStatus === "token faild") {
				// dispatch?.(loginOut({}));
				// navigate?.('/')
				dispatch(loginOutAction(() => navigate?.("/login")));
			}
		});
};

export const saveVoyageContactAction: ParameterType<saveVoyageContactParams> = (
	params,
	frontCallback,
	successCallback,
	errorCallback,
	dispatch,
	navigate
) => {
	frontCallback?.();
	saveVoyageContactApi(params)
		.then((response) => {
			successCallback?.(response);
		})
		.catch((err) => {
			errorCallback?.(err);
			if (err?.loginStatus === "token faild") {
				// dispatch?.(loginOut({}));
				// navigate?.('/')
				dispatch(loginOutAction(() => navigate?.("/login")));
			}
		});
};

export const updatePreopRemarkAction: ParameterType<updatePreopRemarkParams> = (
	params,
	frontCallback,
	successCallback,
	errorCallback,
	dispatch,
	navigate
) => {
	frontCallback?.();
	updatePreopRemarkApi(params)
		.then((response) => {
			successCallback?.(response);
		})
		.catch((err) => {
			errorCallback?.(err);
			if (err?.loginStatus === "token faild") {
				// dispatch?.(loginOut({}));
				// navigate?.('/')
				dispatch(loginOutAction(() => navigate?.("/login")));
			}
		});
};

export const uploadVmVoyageAttachAction: ParameterType<uploadVmVoyageAttachParams> = (
	params,
	frontCallback,
	successCallback,
	errorCallback,
	dispatch,
	navigate
) => {
	frontCallback?.();
	uploadVmVoyageAttachApi(params)
		.then((response) => {
			successCallback?.(response);
		})
		.catch((err) => {
			errorCallback?.(err);
			if (err?.loginStatus === "token faild") {
				// dispatch?.(loginOut({}));
				// navigate?.('/')
				dispatch(loginOutAction(() => navigate?.("/login")));
			}
		});
};

export const deleteVoyageAction: ParameterType<deleteVoyageParams> = (
	params,
	frontCallback,
	successCallback,
	errorCallback,
	dispatch,
	navigate
) => {
	frontCallback?.();
	deleteVoyageApi(params)
		.then((response) => {
			successCallback?.(response);
		})
		.catch((err) => {
			errorCallback?.(err);
			if (err?.loginStatus === "token faild") {
				// dispatch?.(loginOut({}));
				// navigate?.('/')
				dispatch(loginOutAction(() => navigate?.("/login")));
			}
		});
};
