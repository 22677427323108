import { useCommonIndex } from "@/hook";
import { InvFreightOtherItemType } from "../../../type";
import { checkKeys, initialFreightBaseItem } from "../source";
import { useInvFieldNames } from "@/pages/voyagemanage/business/invoices/module";
import { InvFreightBaseInitItemType } from "../type";

const useIndex = (otherItem: Pick<InvFreightOtherItemType, "addOther">) => {
	const {
		dataSource,
		change,
		commit,
		init: handleBaseItemInit,
		check
	} = useCommonIndex(initialFreightBaseItem, checkKeys);

	const {
		delIds: beneficiaryInfoDelIds,
		dataSource: beneficiaryInfoFieldItems,
		change: handleBeneficiaryInfoFieldItemChange,
		remove: handleBeneficiaryInfoFieldDelete,
		init: handleBeneficiaryInfoFieldInit,
		add: handleBeneficiaryInfoFieldAdd
	} = useInvFieldNames();

	const {
		delIds: counterPartyDelIds,
		dataSource: counterPartyFieldItems,
		change: handleCounterPartyFieldItemChange,
		remove: handleCounterPartyFieldFieldDelete,
		init: handleCounterPartyFieldFieldInit,
		add: handleCounterPartyFieldFieldAdd
	} = useInvFieldNames();

	const init = (type: "init" | "details", item: Partial<InvFreightBaseInitItemType>) => {
		handleBaseItemInit(item);
		handleBeneficiaryInfoFieldInit(type, item?.beneficiaryFieldList);
		handleCounterPartyFieldFieldInit(type, item?.counterFieldList);
	};

	return {
		baseItem: dataSource,
		handleBaseItemChange: change,
		handleBaseItemCommit: commit,
		handleBaseInit: init,
		handleBaseCheck: check,
		beneficiaryInfoDelIds,
		beneficiaryInfoFieldItems,
		handleBeneficiaryInfoFieldItemChange,
		handleBeneficiaryInfoFieldDelete,
		handleBeneficiaryInfoFieldAdd,
		counterPartyDelIds,
		counterPartyFieldItems,
		handleCounterPartyFieldItemChange,
		handleCounterPartyFieldFieldDelete,
		handleCounterPartyFieldFieldAdd
	};
};

export default useIndex;
