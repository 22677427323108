import { useLocation, useNavigate, useSearchParams } from "react-router-dom";

const useIndex = () => {
	const [searchParams] = useSearchParams();
	const { search } = useLocation();
	const navigate = useNavigate();

	const voyageNo = new URLSearchParams(search)?.get?.("voyageNo");
	const id = searchParams.get("id"),
		voyageType = searchParams?.get("voyageType");

	const handleJumpMovement = () => {
		navigate(`/layout/voyagemanage/movement/details?id=${id}&voyageType=${voyageType}`);
	};

	return {
		voyageNo,
		handleJumpMovement
	};
};

export default useIndex;
