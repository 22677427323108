import { useMemo, useRef, useState } from "react";
import {
  useBasicInfo,
  useBunkerClause,
  useFixtureNotes,
  useHeadFixture,
  usePerformance,
  usePortDetails
} from "@/pages/voyagemanage/business/preOp&movement/components";

import { tcvcHandoverIdMap } from "../source";
import { HandoverTcvcFormItemType, VoyageManageTcvcHandoverFormIdKeys } from "../type";
import { getPreopTcvcHandoverFormAction } from "@/action/voyageManage/preOperation";
import { addContactAction } from "@/action/dataBase/contact";
import { CommonContactItemType } from "@/common/Select";
import useOtherIndex from "./useOtherIndex";
import { VoyageManageContactOptionChangeEvent, VoyageManageContactOptionType } from "@/pages/voyagemanage/business/preOp&movement/type";
import { useTranslation } from "react-i18next";
import useReminder from "@/hook/useReminder";
import { useNavigate } from "react-router-dom";
import { useAppDispatch } from "@/hook";
import { initialContactOption } from "@/pages/voyagemanage/business/preOp&movement/source";

const useIndex = (voyageId: string) => {
	const dispatch = useAppDispatch();
	const navigate = useNavigate();
	const { reminder } = useReminder();
	const { t } = useTranslation();
	const [loading, setLoading] = useState(false);

	const containerRef = useRef<HTMLDivElement>(null);

	const [contactOption, setContactOption] =
		useState<VoyageManageContactOptionType>(initialContactOption());

	const handleContactChange: VoyageManageContactOptionChangeEvent = (item) => {
		setContactOption((prev) => ({ ...prev, ...item }));
	};

	const {
		dataSource: otherItem,
		change: handleOtherChange,
		commit: handleOtherCommit,
		init: handleOtherInit,
		check: handleOtherCheck
	} = useOtherIndex();

	const handleContactAddFront = () => {
		handleContactChange({ loading: true });
	};

	const handleContactAddSuccess = (response) => {
		handleContactChange({ loading: false, hide: true, type: null });
		reminder("success", response?.msg);
	};

	const handleContactAddFailed = (error) => {
		handleContactChange({ loading: true });
		reminder("error", error?.msg ? error?.msg + ": " + error?.data : error?.data);
	};

	const handleContactAdd = (item: CommonContactItemType) => {
		addContactAction(
			item,
			handleContactAddFront,
			handleContactAddSuccess,
			handleContactAddFailed,
			dispatch,
			navigate
		);
	};

	const {
		dataSource: basicInfo,
		change: handleBasicInfoChange,
		commit: handleBasicInfoCommit,
		init: handleBasicInfoInit,
		check: handleBasicInfoCheck
	} = useBasicInfo();

	const {
		dataSource: headFixture,
		change: handleHeadFixtureChange,
		commit: handleHeadFixtureCommit,
		init: handleHeadFixtureInit,
		check: handleHeadFixtureCheck
	} = useHeadFixture();

	const {
		dataSourse: bunkerClause,
		change: handleBunkerClauseChange,
		commit: handleBunkerClauseCommit,
		init: handleBunkerClauseInit
	} = useBunkerClause();

	const {
		dataSourse: performance,
		change: handlePerformanceChange,
		commit: handlePerformanceCommit,
		init: handlePerformanceInit
	} = usePerformance();

	const {
		dataSource: fixtureNotes,
		change: handleFixtureNotesChange,
		commit: handleFixtureNotesCommit,
		init: handleFixtureNotesInit,
		check: handleFixtureNotesCheck
	} = useFixtureNotes();

	const {
		delIds,
		dataSource: portDetails,
		change: handlePortDetailChange,
		commit: handlePortDetailCommit,
		dhdCommit: handlePortDndCommit,
		add: handlePortDetailAdd,
		remove: handlePortDetailDelete,
		init: handlePortDetailInit,
		check: handlePortDetailCheck,
		move: handlePortDetailMove
	} = usePortDetails();

	const init = (item: HandoverTcvcFormItemType) => {
		const { cargoBook, headFixture, ...rest } = item;
		const { performanceList, performanceClause, goodWeatherDef, ...restHeadFixture } = headFixture;
		const { portRotationList, ...restCargoBook } = cargoBook;
		handleOtherInit(rest);
		handlePortDetailInit(portRotationList);
		handleBasicInfoInit(restHeadFixture);
		handleHeadFixtureInit(restHeadFixture);
		handleBunkerClauseInit(restHeadFixture);
		handlePerformanceInit({ ...performanceList, performanceClause, goodWeatherDef });
		handleFixtureNotesInit(restCargoBook);
	};

	const check = () => {
		for (let callback of [
			handleBasicInfoCheck,
			handleHeadFixtureCheck,
			handleFixtureNotesCheck,
			handleOtherCheck,
			handlePortDetailCheck
		]) {
			const { checkKey, checked } = callback();
			if (!checked) {
				reminder(
					"error",
					`Field ${t(`voyageManagement.preOperation.${checkKey}`, {
						defaultValue: t(`common.${checkKey}`)
					})} is required`
				);
				return false;
			}
		}
		if (portDetails?.length <= 0) {
			reminder("error", "At least one loading or unloading port is required");
			return false;
		}
		return true;
	};

	const getPreopHandoverFormFront = () => {
		setLoading(true);
	};

	const getPreopHandoverFormSuccess = (response) => {
		setLoading(false);
		init(response?.data);
	};

	const getPreopHandoverFormFailed = (error) => {
		setLoading(false);
		reminder("error", error?.msg ? error?.msg + ": " + error?.data : error?.data);
	};

	const getPreopHandoverForm = () => {
		if (!voyageId) {
			init({
				headFixture: {},
				cargoBook: {}
			});
			return;
		}
		getPreopTcvcHandoverFormAction(
			{ voyageId },
			getPreopHandoverFormFront,
			getPreopHandoverFormSuccess,
			getPreopHandoverFormFailed,
			dispatch,
			navigate
		);
	};

	const handleCombine = (): HandoverTcvcFormItemType => {
		const { performanceClause, goodWeatherDef, ...rest } = performance;
		return {
			...otherItem,
			cargoBook: {
				...fixtureNotes,
				portRotationList: portDetails,
				delIds: delIds?.current
			},
			headFixture: {
				...basicInfo,
				...headFixture,
				...bunkerClause,
				performanceList: rest,
				performanceClause,
				goodWeatherDef
			}
		};
	};

	const [activeIndex, setActiveIndex] =
		useState<VoyageManageTcvcHandoverFormIdKeys>("handoverForm-basicInfo");

	const currentIndex = useMemo(() => {
		return tcvcHandoverIdMap?.findIndex((item) => item?.value === activeIndex);
	}, [activeIndex]);

	const handleScrollIntoView = (
		event: React.MouseEvent<HTMLLIElement, MouseEvent>,
		id: VoyageManageTcvcHandoverFormIdKeys,
		index: number
	) => {
		const currentElement = containerRef.current?.querySelector(`#${id}`);
		console.log("currentElement", currentElement, event);
		currentElement?.scrollIntoView({
			behavior: "smooth",
			block: "start"
		});
		setActiveIndex(id);
	};

	// const handleScroll = useMemo(() => {
	//   const loadOptions = (event) => {
	//     console.log("evnet", event)

	//     const scrollTop = event?.target?.scrollTop + event?.target?.getClientRects?.()[0]?.top

	//     const children = containerRef.current?.children
	//     for (let i = 0; i < children?.length; i++) {
	//       const child = children[i];
	//       const { top, bottom } = child.getBoundingClientRect();

	//       if (scrollTop >= top && scrollTop <= bottom) {
	//         setActiveIndex(child.id as VoyageManageHandoverFormIdKeys);
	//         break;
	//       }
	//     }
	//   }
	//   return debounce(loadOptions, 800);
	// }, [activeIndex])

	return {
		loading,
		basicInfo,
		handleBasicInfoChange,
		handleBasicInfoCommit,
		headFixture,
		handleHeadFixtureChange,
		handleHeadFixtureCommit,
		bunkerClause,
		handleBunkerClauseChange,
		handleBunkerClauseCommit,
		performance,
		handlePerformanceChange,
		handlePerformanceCommit,
		fixtureNotes,
		handleFixtureNotesChange,
		handleFixtureNotesCommit,
		portDetails,
		handlePortDetailChange,
		handlePortDetailCommit,
		handlePortDetailAdd,
		handlePortDetailDelete,
		handlePortDetailMove,
		handlePortDndCommit,
		otherItem,
		handleOtherChange,
		handleOtherCommit,
		init,
		activeIndex,
		containerRef,
		currentIndex,
		handleScrollIntoView,
		// handleScroll,
		handleCombine,
		handleReset: getPreopHandoverForm,
		handleCheck: check,
		contactOption,
		handleContactChange,
		handleContactAdd
	};
};

export default useIndex;
