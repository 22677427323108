import { FleetOptionType } from "@/types/advancedType";
import {
	CommonOtherExpensesItemChangeEvnet,
	CommonOtherExpensesItemCommitEvnet,
	CommonOtherExpensesItemDeleteEvnet,
	CommonOtherExpensesItemType
} from "./type";
import { FleetInput, FleetTooltip } from "@/components";
import React from "react";
import { CommonContactSelect, FleetToast } from "@/common";
import { MovementCommonTableColumnsType } from "@/pages/voyagemanage/business/movement/details/components/charterBase/common/table/type";
import { CommonInitItemEvent } from "@/types/event";
import { Icon } from "@/_components";

export const initialOtherExpensesItem: CommonInitItemEvent<CommonOtherExpensesItemType> = (
	item
) => {
	return {
		voyageId: item?.voyageId ?? null,
		itemName: item?.itemName ?? "",
		itemValue: item?.itemValue ?? "",
		remark: item?.remark ?? "",
		burdenType: item?.burdenType ?? 0,
		burdenValue: item?.burdenValue ?? "",
		ownerAccount: item?.ownerAccount ?? "",
		netExpense: item?.netExpense ?? "",
		agentId: item?.agentId ?? null,
		agentName: item?.agentName ?? "",
		settledTime: item?.settledTime ?? null
	};
};

export const burdenOptions: FleetOptionType<
	"%" | "$",
	CommonOtherExpensesItemType["burdenType"]
>[] = [
	{ label: "%", value: 0 },
	{ label: "$", value: 1 }
];

export const columns: MovementCommonTableColumnsType<
	CommonOtherExpensesItemType,
	keyof CommonOtherExpensesItemType,
	{
		onChange: CommonOtherExpensesItemChangeEvnet;
		onCommit: CommonOtherExpensesItemCommitEvnet;
		onDelete: CommonOtherExpensesItemDeleteEvnet;
		onOpen: () => void;
	}
>[] = [
	{
		label: "Item Name",
		dataIndex: "itemName",
		width: 150,
		fixed: "left",
		render: (value, record, index, actions) => {
			return (
				<FleetInput
					className="td-input"
					value={value}
					onChange={(val: string) => {
						actions?.onChange?.(index, "itemName", val);
					}}
				/>
			);
		}
	},
	{
		label: "Total Expense",
		dataIndex: "itemValue",
		width: 125,
		fixed: "left",
		render: (value, record, index, actions) => {
			return (
				<FleetInput
					className="td-input"
					value={value}
					type="number"
					onChange={(val: number) => {
						const ownerAccount =
							record?.burdenType === 0
								? (+record?.burdenValue * val) / 100
								: val - +record?.burdenValue;
						const netExpense = val - ownerAccount;
						actions?.onCommit?.(index, {
							itemValue: val,
							ownerAccount,
							netExpense
						});
					}}
				/>
			);
		}
	},
	// {
	//   label: "Burden on Owner ?",
	//   dataIndex: "burdenValue",
	//   width: 196,
	//   render: (value, record, index, actions) => {
	//     return <div className="otherExpenses-burden--container">
	//       <FleetInput
	//         className="td-input"
	//         value={value}
	//         type="number"
	//         min={record?.burdenType === 0 ? 0 : undefined}
	//         max={record?.burdenType === 0 ? 100 : undefined}
	//         onChange={(val: number) => {
	//           const ownerAccount = record?.burdenType === 0 ? (+record?.itemValue * val / 100) : (+record?.itemValue - val)
	//           const netExpense = +record?.itemValue - ownerAccount
	//           actions?.onCommit?.(index, {
	//             burdenValue: val,
	//             ownerAccount,
	//             netExpense
	//           })
	//         }}
	//       />
	//       <FleetSelect
	//         value={[record?.burdenType]}
	//         popupMatchSelectWidth={false}
	//         className="td-select"
	//         options={burdenOptions}
	//         onChange={(val: CommonOtherExpensesItemType["burdenType"]) => {
	//           // actions?.onChange(index, "burdenType", val)
	//           debugger
	//           const ownerAccount = val === 0 ? (+record?.itemValue * +record?.burdenValue / 100) : (+record?.itemValue - +record?.burdenValue)
	//           const netExpense = +record?.itemValue - ownerAccount
	//           actions?.onCommit?.(index, {
	//             burdenType: val,
	//             ownerAccount,
	//             netExpense
	//           })
	//         }}
	//       />
	//     </div>
	//   }
	// },
	// {
	//   label: "Owner’s Account",
	//   dataIndex: "ownerAccount",
	//   width: 125,
	//   render: (value, record, index, actions) => {
	//     return <FleetInput
	//       className="td-input"
	//       value={value}
	//       disabled
	//       type="number"
	//     />
	//   }
	// },
	// {
	//   label: "Net Expense",
	//   dataIndex: "netExpense",
	//   width: 125,
	//   render: (value, record, index, actions) => {
	//     return <FleetInput
	//       className="td-input"
	//       value={value}
	//       type="number"
	//       disabled
	//     />
	//   }
	// },
	{
		label: "Agent",
		dataIndex: "agentId",
		width: 200,
		render: (value, record, index, actions) => {
			return (
				<CommonContactSelect
					showKey="companyName"
					primaryKey="id"
					activeItems={{
						id: record?.agentId,
						companyName: record?.agentName
					}}
					onFinish={(newNoItem, type) => {
						actions?.onCommit?.(index, {
							agentId: newNoItem?.id ?? null,
							agentName: newNoItem?.companyName ?? ""
						});
					}}
					contactActions={{
						onOpen: actions?.onOpen
					}}
				/>
			);
		}
	},
	{
		label: "Remarks",
		dataIndex: "remark",
		width: 300,
		render: (value, record, index, actions) => {
			return (
				<FleetInput
					className="td-input"
					value={value}
					onChange={(val: string) => {
						actions?.onChange?.(index, "remark", val);
					}}
				/>
			);
		}
	},
	{
		label: "Action",
		dataIndex: "action",
		fixed: "right",
		width: 60,
		render: (value, record, index, actions) => {
			return (
				<div className="td-actions">
					<FleetTooltip
						outerLayer={<span className="font_family fleet-delete"></span>}
						render={(setIsOpen) => {
							return (
								<FleetToast
									label="Are you sure delete this item?"
									onCancel={() => {
										setIsOpen(false);
									}}
									onConfirm={() => {
										actions?.onDelete?.(index);
										setIsOpen(false);
									}}
								/>
							);
						}}
					/>
				</div>
			);
		}
	}
];
