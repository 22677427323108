import React, { FC } from "react";
import style from "./index.module.less";
import { BunkerClauseProps, BunkerClauseType } from "./type";
import { columns, options } from "./source";
import { FleetInput, FleetSelect, FleetTextarea } from "@/components";
import { handlerBunkerClause } from "./tools";

const HandoverFormBunkerClause: FC<BunkerClauseProps> = ({
  item,
  onChange,
  onCommit
}) => {
  return <div className={style["handoverForm-bunkerClause"]}>
    <span className="handoverForm--common--header secondary">Bunker Clause</span>
    <div className="handoverForm-table bunker-clause">
      <ul className="table-common thead">
        {
          columns?.map((column, index) => {
            return <li
              key={index}
            >{column}</li>
          })
        }
      </ul>
      <ul className="table-common tbody">
        <li>BOD Quantity</li>
        <li>
          <FleetInput
            type="number"
            value={item?.hsFoQuantity}
            onChange={(val: number) => {
              onChange?.("hsFoQuantity", val)
            }}
          />
        </li>
        <li>
          <FleetInput
            type="number"
            value={item?.lsFoQuantity}
            onChange={(val: number) => {
              onChange?.("lsFoQuantity", val)
            }}
          />
        </li>
        <li>
          <FleetInput
            type="number"
            value={item?.mdoQuantity}
            onChange={(val: number) => {
              onChange?.("mdoQuantity", val)
            }}
          />
        </li>
        <li>
          <FleetInput
            type="number"
            value={item?.mgoQuantity}
            onChange={(val: number) => {
              onChange?.("mgoQuantity", val)
            }}
          />
        </li>
        <li>
          <FleetInput
            type="number"
            value={item?.borAbout}
            onChange={(val: number) => {
              onChange?.("borAbout", val)
            }}
          />
        </li>
        <li></li>
      </ul>
      <ul className="table-common tbody">
        <span>BOD Price</span>
        <li>
          <FleetInput
            type="number"
            value={item?.hsFoPrice}
            onChange={(val: number) => {
              onChange?.("hsFoPrice", val)
            }}
          />
        </li>
        <li>
          <FleetInput
            type="number"
            value={item?.lsFoPrice}
            onChange={(val: number) => {
              onChange?.("lsFoPrice", val)
            }}
          />
        </li>
        <li>
          <FleetInput
            type="number"
            value={item?.mdoPrice}
            onChange={(val: number) => {
              onChange?.("mdoPrice", val)
            }}
          />
        </li>
        <li>
          <FleetInput
            type="number"
            value={item?.mgoPrice}
            onChange={(val: number) => {
              onChange?.("mgoPrice", val)
            }}
          />
        </li>
        <li></li>
        <li>
          <FleetSelect
            value={[item?.borPrice]}
            options={options}
            defaultValue="same"
            placeholder="Same/Different"
            onChange={(val: BunkerClauseType["borPrice"]) => {
              onChange?.("borPrice", val)
            }}
          />
        </li>
      </ul>
    </div>
    <FleetTextarea
      className="bunkerClause-textarea"
      value={item?.bunkerClause}
      onChange={(val: string) => {
        onChange?.("bunkerClause", val)
      }}
      onBlur={(e) => {
        let val = e.target.value;
        let bunkerForm: Partial<BunkerClauseType> = handlerBunkerClause(item, val);
        if (Object.keys(bunkerForm)?.length === 0) {
          return;
        };
        onCommit?.(bunkerForm)
      }}
      placeholder=""
    // placeholder={`BUNKER CLAUSE:\nBUNKERS ON DELIVERY ABOUT:\nLSFO ABT 600 MT @ USD 340 PMT\nLSMGO ABT 70 MT @ USD 350 PMT\nBUNKERS ON REDELIVERY ABT AS ON DELIVERY AND SAME PRICES BENDS`}
    />
  </div>
}

export default HandoverFormBunkerClause