import React from "react";
import classnames from "classnames";
import style from "./index.module.less";
import { HfBasicFormProps, HfBasicFormType } from "./type";
import { FleetDatePicker, FleetFormItem, FleetRangePicker, FleetVerifyInput } from "@/components";
import { HeadFixtueLabelMap } from "@/types/voyageManage/headFixtureList";
import { CommonPortSelect, CommonUserSelect, CommonVesselSelect } from "@/common";
import dayjs, { Dayjs } from "dayjs";

const HfBasicForm: React.FC<HfBasicFormProps> = ({
	item,
	type,
	onChange,
	onCommit,
}) => {
	return <div className={classnames(style["headFixture-basic"], "headFixture-form")}>
		<span className="vm-common-title">Basic</span>
		<div className="form-container">
			{
				type === "edit" && <FleetVerifyInput
					needVerity
					disabled
					label={HeadFixtueLabelMap["cpNo"]}
					value={item?.cpNo}
					onChange={(val: number) => {
						onChange?.("cpNo", val)
					}}
				/>
			}
			<FleetVerifyInput
				label={HeadFixtueLabelMap["ownerName"]}
				needVerity
				value={item?.ownerName}
				onChange={(val: string) => {
					onChange?.("ownerName", val)
				}}
			/>
			<FleetFormItem
				label={HeadFixtueLabelMap["vesselName"]}
				needVerity
			>
				<CommonVesselSelect
					activeItems={{
						vesselName: item?.vesselName,
						mmsi: item?.mmsi as number
					}}
					primaryKey="mmsi"
					showKey="vesselName"
					onFinish={(newItem, type) => {
						onCommit?.({
							dwt: newItem?.dwt ?? "",
							bale: newItem?.baleCapacity ?? "",
							grain: newItem?.grainCapacity ?? "",
							imo: newItem?.imo ?? null,
							mmsi: newItem?.mmsi ?? null,
							vesselName: newItem?.vesselName,
							vesselNameCn: newItem?.vesselNameCn
						})
					}}
				/>
			</FleetFormItem>
			<FleetFormItem
				label={HeadFixtueLabelMap["cpDate"]}
				needVerity
			>
				<FleetDatePicker
					value={item?.cpDate ? dayjs(item?.cpDate) : null}
					onChange={(val: string) => {
						onChange?.("cpDate", val)
					}}
					allowClear
				// disabledDate={(current) => {
				// 	let prevDate = Date.now() - 24 * 60 * 60 * 1000;
				// 	return current && current < dayjs(prevDate).endOf("day")
				// }}
				/>
			</FleetFormItem>
			<FleetVerifyInput
				label={HeadFixtueLabelMap["tradingArea"]}
				value={item?.tradingArea}
				onChange={(val: string) => {
					onChange?.("tradingArea", val)
				}}
			/>
			<FleetFormItem
				label={HeadFixtueLabelMap["deliveryPort"]}
				needVerity
				value={item?.deliveryPortCode}
			>
				<CommonPortSelect
					activeItems={{
						// id: item.deliveryPortId,
						portCode: item?.deliveryPortCode,
						portName: item?.deliveryPortName
					}}
					showKey="portName"
					primaryKey="portCode"
					onFinish={(newPortItemm, operateType) => {
						onCommit?.({
							// "deliveryPortId", item.id,
							deliveryPortCode: newPortItemm?.portCode ?? "",
							deliveryPortName: newPortItemm?.portName ?? ""
						})
					}} />
			</FleetFormItem>
			<FleetFormItem
				label={HeadFixtueLabelMap["deliveryDate"]}
			>
				<FleetDatePicker
					value={item?.deliveryDate ? dayjs(item?.deliveryDate) : null}
					onChange={(val: string, timeStamp) => {
						const deliveryDate = timeStamp?.startOf("day")?.valueOf();
						const basicItem: Partial<HfBasicFormProps["item"]> = {};
						basicItem.deliveryDate = deliveryDate;
						basicItem.estRedeliveryDate = (((+item.minDuration || 0) * 24 * 60 * 60 * 1000) + deliveryDate);
						onCommit?.(basicItem)
					}}
					allowClear
					showTime={false}
				// disabledDate={(current) => {
				// 	let prevDate = Date.now() - 24 * 60 * 60 * 1000;
				// 	return current && current < dayjs(prevDate).endOf("day")
				// }}
				/>
			</FleetFormItem>
			{
				type !== "edit" && <div></div>
			}
			<div></div>
			<FleetVerifyInput
				label={HeadFixtueLabelMap["minDuration"]}
				type="number"
				value={item?.minDuration}
				onChange={(val: number) => {
					const basicItem: Partial<HfBasicFormProps["item"]> = {};
					basicItem.minDuration = val;
					if (item?.deliveryDate) {
						basicItem.estRedeliveryDate = +item?.deliveryDate + val * 24 * 60 * 60 * 1000;
					}
					onCommit?.(basicItem)
					// onChange?.("minDuration", val)
				}}
			/>
			<FleetVerifyInput
				label={HeadFixtueLabelMap["maxDuration"]}
				type="number"
				value={item?.maxDuration}
				onChange={(val: number) => {
					onChange?.("maxDuration", val)
				}}
			/>
			<FleetFormItem
				label={HeadFixtueLabelMap["estRedeliveryDate"]}
			>
				<FleetDatePicker
					value={item?.estRedeliveryDate ? dayjs(item?.estRedeliveryDate) : null}
					onChange={(val: string, timeStamp) => {
						onChange?.("estRedeliveryDate", timeStamp?.startOf("day")?.valueOf())
					}}
					allowClear
					showTime={false}
				// showHour
				// showMinute
				// showTime={{
				// 	format: "HH:mm"
				// }}
				// disabledDate={(current) => {
				// 	let prevDate = Date.now() - 24 * 60 * 60 * 1000;
				// 	return current && current < dayjs(prevDate).endOf("day")
				// }}
				/>
			</FleetFormItem>
			<FleetFormItem
				label={HeadFixtueLabelMap["redeliveryPort"]}
			>
				<CommonPortSelect
					activeItems={{
						// id: item.deliveryPortId,
						portCode: item?.redeliveryPortCode,
						portName: item?.redeliveryPortName
					}}
					showKey="portName"
					primaryKey="portCode"
					onFinish={(newPortItemm, operateType) => {
						onCommit?.({
							// "deliveryPortId", item.id,
							redeliveryPortCode: newPortItemm?.portCode ?? "",
							redeliveryPortName: newPortItemm?.portName ?? ""
						})
					}} />
			</FleetFormItem>
		</div>
		<div className="basic-form">
			<FleetFormItem
				label={HeadFixtueLabelMap["layCan"]}
			>
				<FleetRangePicker
					allowClear
					value={[item?.layCanFrom ? dayjs(item?.layCanFrom) : null, item?.layCanTo ? dayjs(item?.layCanTo) : null]}
					onChange={(vals: [Dayjs, Dayjs]) => {
						const ranges: Pick<HfBasicFormType, "layCanFrom" | "layCanTo"> = {
							layCanFrom: null,
							layCanTo: null
						}
						if (vals) {
							ranges.layCanFrom = vals?.[0]?.startOf("day")?.valueOf();
							ranges.layCanTo = vals?.[1]?.startOf("day")?.valueOf();
						}
						onCommit?.({ ...ranges })
					}}
					showHour={false}
					showMinute={false}
					showTime={false}
				/>
			</FleetFormItem>
			<FleetFormItem
				label={HeadFixtueLabelMap["charteredBy"]}

			>
				<CommonUserSelect
					showCount={4}
					activeItems={item?.charateList}
					showKey="nickName"
					onFinish={(newItem, type) => {
						console.log("newitem", newItem)
						switch (true) {
							case type === "add":
								onCommit?.({
									charateList: [...item?.charateList, newItem],
									charteredBy: [...item?.charteredBy, newItem?.userId]
								});
								break
							case type === "del":
								const charateIndex = item?.charateList?.findIndex(charateItem => charateItem?.userId === newItem?.userId);
								const charateList = item?.charateList;
								charateList?.splice(charateIndex, 1);
								onCommit?.({
									charateList: [...charateList],
									charteredBy: charateList?.map(charateItem => charateItem?.userId)
								})
								break
							default:
								break
						}
					}}
				/>
			</FleetFormItem>
		</div>
	</div>
}

export default HfBasicForm