import { ApiParameter, RequestApiMapType } from "@/api";
import request from "@/service";

import {
	RouteCommonPortVesselParamsType,
	getRouteCommonPortVesselArgumentsParams,
	updateRouteCommonPortVesselArgumentsParams,
	getRouteCommonPortVesselListParams,
	downloadRouteCommonPortVesselListParams
} from "./type";
import { appVersion } from "@/api/constant";

const enum ApiMap {
	getRouteCommonPortVesselArgumentsParameter,
	updateRouteCommonPortVesselArgumentsParameter,
	getRouteCommonPortVesselListParameter,
	downloadRouteCommonPortVesselListParameter
}

export type ApiUrl = keyof typeof ApiMap;

const portVesselApi: ApiParameter<ApiUrl> = {
	getRouteCommonPortVesselArgumentsParameter: {
		method: "get",
		url: appVersion + "/moni/ship/port/argument/latest"
	},
	updateRouteCommonPortVesselArgumentsParameter: {
		method: "post",
		url: appVersion + "/moni/ship/port/argument/latest"
	},
	getRouteCommonPortVesselListParameter: {
		method: "post",
		url: appVersion + "/moni/ship/port/list/latest"
	},
	downloadRouteCommonPortVesselListParameter: {
		method: "post",
		url: appVersion + "/moni/ship/port/download/vessel"
	}
};

export const getRouteCommonPortVesselArgumentsApi = (
	params: getRouteCommonPortVesselArgumentsParams
) => {
	return request.request({
		...portVesselApi.getRouteCommonPortVesselArgumentsParameter
	});
};

export const updateRouteCommonPortVesselArgumentsApi = (
	params: updateRouteCommonPortVesselArgumentsParams
) => {
	return request.request({
		...portVesselApi.updateRouteCommonPortVesselArgumentsParameter,
		data: params
	});
};

export const getRouteCommonPortVesselListApi = (params: getRouteCommonPortVesselListParams) => {
	return request.request({
		...portVesselApi.getRouteCommonPortVesselListParameter,
		data: params
	});
};

export const downloadRouteCommonPortVesselListApi = (
	params: downloadRouteCommonPortVesselListParams
) => {
	return request.request({
		...portVesselApi.downloadRouteCommonPortVesselListParameter,
		data: params,
		responseType: "blob"
	});
};

export const portVesselApiMap: RequestApiMapType<ApiUrl, RouteCommonPortVesselParamsType> = {
	getRouteCommonPortVesselArgumentsApi,
	updateRouteCommonPortVesselArgumentsApi,
	getRouteCommonPortVesselListApi,
	downloadRouteCommonPortVesselListApi
};
