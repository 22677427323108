import React, { FC } from "react";
import classnames from "classnames";
import style from "./index.module.less";
import { InvStatementBasicsProps } from "./type";
import { FleetFormItem, FleetVerifyInput } from "@/components";
import { CommonCompanySelect } from "@/common/Select";
import { Button } from "@/_components";

const InvStatementBasics: FC<InvStatementBasicsProps> = ({
	activeInvType,
	item,
	onCommit,
	fieldItems,
	onFieldChange,
	onFieldDelete,
	onFieldOpen
}) => {
	return (
		<div className={classnames("invoicesDetail-form", style["invStatement-basics"])}>
			<div className="invoicesForm-header">
				<span>Basics</span>
				<span>Recipient information</span>
			</div>
			<div className="invoicesForm-container">
				<FleetFormItem
					label={activeInvType === "relet" ? "Company/charterer" : "Company/owner"}
					needVerity
				>
					<CommonCompanySelect
						showKey="companyName"
						activeItems={{
							id: item?.ownerId,
							companyName: item?.ownerName
						}}
						onFinish={(newItem, type) => {
							onCommit?.({
								ownerId: newItem?.id ?? null,
								ownerName: newItem?.companyName ?? "",
								address: newItem?.companyAddress ?? "",
								tel: newItem?.tel ?? "",
								email: newItem?.email ?? ""
							});
						}}
					/>
				</FleetFormItem>
				<FleetVerifyInput
					label="Address"
					value={item?.address}
					onChange={(val: string) => {
						onCommit?.({
							address: val
						});
					}}
				/>
				<FleetVerifyInput
					value={item?.tel}
					onChange={(val: string) => {
						onCommit?.({
							tel: val
						});
					}}
					label="Tel"
				/>
				<FleetVerifyInput
					value={item?.email}
					onChange={(val: string) => {
						onCommit?.({
							email: val
						});
					}}
					label="E-Mail"
				/>
				{fieldItems?.map((item, index) => {
					return (
						<div className="statement-fields" key={index}>
							<FleetVerifyInput
								value={item?.fieldVal}
								label={item?.fieldName}
								onChange={(val: string) => {
									onFieldChange?.(index, "fieldVal", val);
								}}
							/>
							<span className="font_family fleet-minus"></span>
						</div>
					);
				})}
				<Button
					size="small"
					type="secondary"
					prefixIcon="fleet-plus"
					// onClick={() => onFieldOpen?.("basic")}
					disabled
				>
					Add field
				</Button>
			</div>
		</div>
	);
};

export default InvStatementBasics