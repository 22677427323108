import { type ApiParameter } from "@/api";
import { appVersion } from "@/api/constant";
import request from "@/service";
import {
	getPortDaDetailsParams,
	getPortDaGroupParams,
	deletePortDaParmas,
	updatePortDaPayload
} from "./type";

const enum ApiMap {
	getPortDaDetailsParameter,
	getPortDaGroupParameter,
	deletePortDaParameter,
	updatePortDaParameter
}

type ApiUrl = keyof typeof ApiMap;

const portDaApi: ApiParameter<ApiUrl> = {
	getPortDaGroupParameter: {
		method: "get",
		url: appVersion + "/portda/list"
	},
	getPortDaDetailsParameter: {
		method: "get",
		url: appVersion + "/portda"
	},
	deletePortDaParameter: {
		method: "delete",
		url: appVersion + "/portda/batch"
	},
	updatePortDaParameter: {
		method: "post",
		url: appVersion + "/portda"
	}
};

export const getPortDaGroupApi = (params: getPortDaGroupParams) => {
	return request.request({
		...portDaApi.getPortDaGroupParameter,
		params
	});
};

export const getPortDaDetailsApi = (params: getPortDaDetailsParams) => {
	return request.request({
		...portDaApi.getPortDaDetailsParameter,
		url: `${portDaApi.getPortDaDetailsParameter.url}/${params?.id}`
	});
};

export const deletePortDaApi = (params: deletePortDaParmas) => {
	return request.request({
		...portDaApi.deletePortDaParameter,
		data: params
	});
};

export const updatePortDaApi = (payload: updatePortDaPayload) => {
	return request.request({
		...portDaApi.updatePortDaParameter,
		method: payload.method,
		data: payload.params
	});
};
