import { FleetOptionType } from "@/types/advancedType";
import { RouteVoyageTabType } from "../../type";

export const voyageTabOptions: FleetOptionType<
	"VESSEL" | "PORT" | "Wx Routing" | "AREA",
	RouteVoyageTabType
>[] = [
	{ label: "Wx Routing", value: "leg" },
	{ label: "AREA", value: "area" },
	{ label: "PORT", value: "port" },
	{ label: "VESSEL", value: "ais" }
];
