import React, { FC } from "react";
import style from "./index.module.less";
import { RoutePortOtherIntervalsProps } from "./type";
import { FleetRangePicker } from "@/components";
import dayjs from "dayjs";
import { disableDate } from "./source";

const RoutePortOtherIntervals: FC<RoutePortOtherIntervalsProps> = ({ item, onCommit, mode }) => {
	return (
		<div className={style["route-portOtherIntervals"]}>
			<span className="portOtherIntervals-tip">
				{mode === 1
					? "*Next 90 days historical data is available"
					: "*Last 90 days historical data is available"}
			</span>
			<FleetRangePicker
				allowClear
				value={[
					item?.startDate ? dayjs(item?.startDate) : null,
					item?.endDate ? dayjs(item?.endDate) : null
				]}
				showTime={false}
				onChange={(vals) => {
					onCommit?.({
						startDate: vals?.[0]?.format("YYYY-MM-DD"),
						endDate: vals?.[1].format("YYYY-MM-DD")
					});
				}}
				disabledDate={(currentDate) => {
					return disableDate?.(mode, currentDate);
				}}
			/>
		</div>
	);
};

export default RoutePortOtherIntervals;
