import React, { useState } from "react";
import { FleetPrompt } from "@/common";
import { PromptChildrenProps } from "@/common/Prompt";
import style from "./index.module.less";
import { FleetButton, FleetIcon, FleetRadio, FleetVerifyInput } from "@/components";
import { LabelMap } from "@/types";

import {
  useSpdAllocation,
  SaOriginalFormType,
  SpdAllocationOriginalKeys,
  SaOriginalFormChangeEvent,
  useEstimation
} from "@/types/estimation/details/spdAllocation";
import { toThousands } from "@/tools";
import { FleetCustomRadio } from "@/components/Radio";
import useReminder from "@/hook/useReminder";
import { CargoFormType, PortRotationFormType } from "@/types/estimation/details/index";
import { EstCargoItemType, EstPortRotationItemType, EstVesselParticularType } from "@/pages/estimation/module";

const SpdAllocationItem: React.FC<{
  activeKey?: string,
  onActiveKeyChange?: (id: string) => void,
  form: SaOriginalFormType,
  index?: number,
  origin?: boolean,
  onRemove?: (id: string | number) => void,
  onChange?: SaOriginalFormChangeEvent,
  onOriginChange?: (key: Exclude<SpdAllocationOriginalKeys, "id">, val: string | number) => void,
  cargoParticular: EstCargoItemType,
  vesselParticular: Pick<
    EstVesselParticularType,
    "otherExpenses" |
    "brokerage" |
    "commission" |
    "dailyHire" |
    "portFuelWork" |
    "portFuelIdle" |
    "portFuelMdo"
  >,
  ladenPortList: PortRotationFormType[],
  ballastPortList: PortRotationFormType[],
}> = ({
  form,
  origin,
  activeKey,
  onActiveKeyChange,
  onChange,
  onOriginChange,
  index,
  cargoParticular,
  vesselParticular,
  ladenPortList,
  ballastPortList,
  onRemove
}) => {
    const {
      totalProfit,
      tce,
      totalRevenue,
      totalCost,
      ifoCost,
      mdoCost,
      portExpense,
      hireCost,
      totalHireCost,
      operationCost,
      commission,
      brokerage
    } = useEstimation(
      cargoParticular,
      { ...vesselParticular, ...form },
      ladenPortList,
      ballastPortList);

    const handleChange: SaOriginalFormChangeEvent = (id, key, val) => {
      if (origin) {
        onOriginChange?.(key, val);
        return;
      }
      onChange?.(id, key, val)
    }

    return <div className={style["saOrigin"]}>
      <div className="saOrigin-form">
        <div className="saOrigin-label">
          {
            origin ? <span className="origin__label">Orignal</span> : <>
              <div className="plan__label">
                <span>{`Plan${index + 1}`}</span>
                <FleetCustomRadio
                  value={activeKey === form.id}
                  onChange={(checked) => {
                    onActiveKeyChange?.(form.id)
                  }}
                />
              </div>
              <FleetIcon
                type="FleetCloseIcon"
                outerLayerClassname="saOrigin-label__close"
                onClick={() => onRemove?.(form?.id)}
              />
            </>
          }
        </div>
        <div className="saOrigin-item">
          <FleetVerifyInput
            label={LabelMap["ballastSpeed"]}
            value={form.ballastSpeed}
            disabled={origin}
            onChange={(val: string) => {
              handleChange(form.id, "ballastSpeed", val)
            }}
            containerClassname="saOrigin-formItem-1"
          />
          <FleetVerifyInput
            label={LabelMap["ladenSpeed"]}
            value={form.ladenSpeed}
            disabled={origin}
            onChange={(val: string) => {
              handleChange(form.id, "ladenSpeed", val)
            }}
            containerClassname="saOrigin-formItem-1"
          />
        </div>
        <div className="saOrigin-item">
          <FleetVerifyInput
            label={LabelMap["seaFuelBallast"]}
            value={form.seaFuelBallast}
            disabled={origin}
            onChange={(val: string) => {
              handleChange(form.id, "seaFuelBallast", val)
            }}
            containerClassname="saOrigin-formItem-2"
          />
          <FleetVerifyInput
            label={LabelMap["seaFuelLaden"]}
            value={form.seaFuelLaden}
            disabled={origin}
            onChange={(val: string) => {
              handleChange(form.id, "seaFuelLaden", val)
            }}
            containerClassname="saOrigin-formItem-2"
          />
          <FleetVerifyInput
            label={LabelMap["seaFuelMdo"]}
            value={form.seaFuelMdo}
            disabled={origin}
            onChange={(val: string) => {
              handleChange(form.id, "seaFuelMdo", val)
            }}
            containerClassname="saOrigin-formItem-2"
          />
        </div>
        <div className="saOrigin-item">
          <FleetVerifyInput
            label={LabelMap["bunkerPriceIfo"]}
            value={form.bunkerPriceIfo}
            disabled={origin}
            onChange={(val: string) => {
              handleChange(form.id, "bunkerPriceIfo", val)
            }}
            containerClassname="saOrigin-formItem-1"
          />
          <FleetVerifyInput
            label={LabelMap["bunkerPriceMdo"]}
            value={form.bunkerPriceMdo}
            disabled={origin}
            onChange={(val: string) => {
              handleChange(form.id, "bunkerPriceMdo", val)
            }}
            containerClassname="saOrigin-formItem-1"
          />
        </div>
      </div>
      <div className="saOrigin-pl">
        <div className="saOrigin-label">
          <span className={origin ? "origin__label" : ""}>{origin ? "Orignal Est. P&L" : `Plan${index + 1} Est. P&L`}</span>
        </div>
        <div className="pl-item">
          <span className="pl-label__unit">Profit</span>
          <div className="pl-value__unit">
            <span>{toThousands(totalProfit)}</span>
            <span>&nbsp;USD</span>
          </div>
        </div>
        <div className="pl-item">
          <span className="pl-label__unit">TCE</span>
          <div className="pl-value__unit">
            <span>{toThousands(tce)}</span>
            <span>&nbsp;USD</span>
          </div>
        </div>
        <div className="pl-item">
          <span className="pl-label__unit">Revenues</span>
          <div className="pl-value__unit">
            <span>{toThousands(totalRevenue)}</span>
            <span>&nbsp;USD</span>
          </div>
        </div>
        <div className="pl-item">
          <span className="pl-label__unit">Total Cost</span>
          <div className="pl-value__unit">
            <span>{toThousands(totalCost)}</span>
            <span>&nbsp;USD</span>
          </div>
        </div>
        <div className="pl-item pl-item__expense">
          <span className="pl-label">Operation Cost</span>
          <span className="pl-value">{toThousands(operationCost)} USD</span>
        </div>
        <div className="pl-item pl-item__expense">
          <span className="pl-label">IFO Cost</span>
          <span className="pl-value">{toThousands(ifoCost)} USD</span>
        </div>
        <div className="pl-item pl-item__expense">
          <span className="pl-label">MDO Cost</span>
          <span className="pl-value">{toThousands(mdoCost)} USD</span>
        </div>
        <div className="pl-item pl-item__expense">
          <span className="pl-label">Port Expenses</span>
          <span className="pl-value">{toThousands(portExpense)} USD</span>
        </div>
        <div className="pl-item">
          <span className="pl-label__unit">Total Hire Cost</span>
          <div className="pl-value__unit">
            <span>{toThousands(totalHireCost)}</span>
            <span>&nbsp;USD</span>
          </div>
        </div>
        <div className="pl-item pl-item__expense">
          <span className="pl-label">Hire Cost</span>
          <span className="pl-value">{toThousands(hireCost)} USD</span>
        </div>
        <div className="pl-item pl-item__expense">
          <span className="pl-label">Add.Commssion</span>
          <span className="pl-value">{toThousands(commission)} USD</span>
        </div>
        <div className="pl-item pl-item__expense">
          <span className="pl-label">Brokerage</span>
          <span className="pl-value">{toThousands(brokerage)} USD</span>
        </div>
      </div>
    </div>

  }

export type SpdAllocationProps = {
  cargoItem: EstCargoItemType,
  vesselItem: Pick<
    EstVesselParticularType,
    "otherExpenses" |
    "brokerage" |
    "commission" |
    "dailyHire" |
    "portFuelWork" |
    "portFuelIdle" |
    "portFuelMdo"
  >,
  onRewrite?: (
    item: Record<Exclude<SpdAllocationOriginalKeys, "id">, number>
  ) => void,
  onChangeOriginForm?: (key: Exclude<SpdAllocationOriginalKeys, "id">, val: string) => void,
  ladenPortList: EstPortRotationItemType[],
  ballastPortList: EstPortRotationItemType[],
  originForm: Record<Exclude<SpdAllocationOriginalKeys, "id">, number | "">
} & PromptChildrenProps;

const SpdAllocation: React.FC<SpdAllocationProps> = ({
  hide,
  onClose,
  onRewrite,
  cargoItem,
  onChangeOriginForm,
  vesselItem,
  ladenPortList,
  ballastPortList,
  originForm
}) => {
  const [fillHide, setHide] = useState(true);
  const { reminder } = useReminder();
  const {
    activeKey,
    saOriginalFormGroup,
    change: handleChangeOriginForm,
    clear: handeOriginFormClear,
    remove: handleRmOriginForm,
    add: handleAddOriginForm,
    changeActiveKey: handleChangeActiveKey
  } = useSpdAllocation(originForm);

  const handleRewrite = () => {
    if (!activeKey) {
      reminder("info", "Please Select at least one");
      return;
    }
    const saOriginalFormItem = saOriginalFormGroup?.find(item => item.id == activeKey);
    const { id, ...rest } = saOriginalFormItem;
    onRewrite?.(rest);
    handeOriginFormClear?.();
  }

  const handleClose = () => {
    onClose?.();
    console.log("onclose", onClose)
    handeOriginFormClear?.();
  }

  return (
		<FleetPrompt onClose={handleClose} hide={hide} mode="fixed">
			<div className={style["esDetails-saOrigin"]}>
				<div className="prompt-header saOrigin-header">
					<div className="header-container">
						<span className="header-title">SPD.Allocation</span>
					</div>
					<FleetIcon
						type="FleetCloseIcon"
						outerLayerClassname="loadRotation-close"
						onClick={handleClose}
					/>
				</div>
				<div className="saOrigin-container">
					<SpdAllocationItem
						origin={true}
						form={originForm}
						onOriginChange={onChangeOriginForm}
						ballastPortList={ballastPortList}
						ladenPortList={ladenPortList}
						cargoParticular={cargoItem}
						vesselParticular={vesselItem}
					/>
					{saOriginalFormGroup.map((item: SaOriginalFormType, idx: number) => {
						return (
							<SpdAllocationItem
								key={idx}
								form={item}
								index={idx}
								ballastPortList={ballastPortList}
								ladenPortList={ladenPortList}
								cargoParticular={cargoItem}
								vesselParticular={vesselItem}
								onChange={handleChangeOriginForm}
								onRemove={handleRmOriginForm}
								activeKey={activeKey}
								onActiveKeyChange={handleChangeActiveKey}
							/>
						);
					})}
					<div className="saOrigin-add">
						<FleetIcon
							type="FleetAddIcon"
							outerLayerClassname="add-container"
							onClick={handleAddOriginForm}
						/>
					</div>
				</div>
				<div className="saOrigin-footer">
					<FleetButton type="secondary" onClick={handleClose}>
						Cancel
					</FleetButton>
					<FleetButton onClick={handleRewrite} disabled={!activeKey}>
						Confirm
					</FleetButton>
				</div>
			</div>
		</FleetPrompt>
	);
}

export default SpdAllocation