import { type ApiParameter } from "@/api";
import { appVersion } from "@/api/constant";
import request from "@/service";

const enum ApiMap {
	getPerformanceModelParameter,
	updatePerformanceParameter
}

type ApiUrl = keyof typeof ApiMap;

const performanceApi: ApiParameter<ApiUrl> = {
	getPerformanceModelParameter: {
		method: "get",
		url: appVersion + "/performance/getModel"
	},
	updatePerformanceParameter: {
		method: "post",
		url: appVersion + "/performance"
	}
};

export type getPerformanceModelParams = {
	resultId: string | number;
	lb: "L" | "B";
	mmsi: string | number;
};

export type updatePerformanceParams = {
	id: string | number;
	/**
	 * 满载CP经济速度
	 */
	cpIfoBallastEco?: number;
	/**
	 * 满载CP全速度
	 */
	cpIfoBallastFull?: number;
	/**
	 * 满载CP经济油耗
	 */
	cpIfoLadenEco?: number;
	/**
	 * 满载CP全油耗
	 */
	cpIfoLadenFull?: number;
	/**
	 * 空载CP经济速度
	 */
	cpSpeedBallastEco?: number;
	/**
	 * 空载CP全速度
	 */
	cpSpeedBallastFull?: number;
	/**
	 * 空载CP经济油耗
	 */
	cpSpeedLadenEco?: number;
	/**
	 * 空载CP全油耗
	 */
	cpSpeedLadenFull?: number;
};

export const getPerformanceModelApi = (params: getPerformanceModelParams) => {
	return request.request({
		...performanceApi.getPerformanceModelParameter,
		params
	});
};

export const updatePerformanceApi = (params: updatePerformanceParams) => {
	return request.request({
		...performanceApi.updatePerformanceParameter,
		data: params
	});
};
