import { Checkbox } from "antd";
import { SeaTableHeadersGroupType, InitGroupType } from "./type";
import { TableHeaderCellType, MovementCommonTableColumnsType,MovementCommonTableDataType } from "./type";

type TableHeaderRowType = {
  rowIndex: number;
  row: TableHeaderCellType[];
};
//实际运用在表头的格式
export const SeaReportTableConfig: TableHeaderRowType[] = [
  {
    rowIndex: 0,
    row: [
      { rowSpan: 3, colSpan: 1, label: " ", prop: "check" },
      { rowSpan: 3, colSpan: 1, label: "Alarm", prop: "alarm" },
      { rowSpan: 3, colSpan: 1, label: "Type", prop: "type" },
      { rowSpan: 3, colSpan: 1, label: "Date/Time(UTC)", prop: "reportDateTime" },
      { rowSpan: 3, colSpan: 1, label: "Date/Time(raw)", prop: "reportDateStr" },
      { rowSpan: 3, colSpan: 1, label: "POSN(Lat)", prop: "positionLat" },
      { rowSpan: 3, colSpan: 1, label: "POSN(Long)", prop: "positionLon" },
      { rowSpan: 3, colSpan: 1, label: "POSN(raw)", prop: "positionStr" },
      {
        rowSpan: 3,
        colSpan: 1,
        label: "True Course at Noon",
        prop: "course",
      },
      {
        rowSpan: 3,
        colSpan: 1,
        label: "Time Of Next Port",
        prop: "timeOfNextPort",
      },
      { rowSpan: 2, colSpan: 2, label: "Sea Time", prop: "seaTime" },
      { rowSpan: 3, colSpan: 1, label: "Sea distance", prop: "seaDistance" },
      { rowSpan: 3, colSpan: 1, label: "DTG", prop: "dtg" },
      {
        rowSpan: 3,
        colSpan: 1,
        label: "Daily avg speed",
        prop: "dailyAvgSpeed",
      },
      { rowSpan: 2, colSpan: 2, label: "Wind", prop: "wind" },
      { rowSpan: 2, colSpan: 2, label: "Sea", prop: "sea" },
      { rowSpan: 3, colSpan: 1, label: "M/E R.p.m.", prop: "meRpm" },
      { rowSpan: 3, colSpan: 1, label: "T/C R.p.m.", prop: "tcRpm" },
      { rowSpan: 3, colSpan: 1, label: "M/E OUTPUT (%MCR)", prop: "meOutput" },
      { rowSpan: 3, colSpan: 1, label: "mePower", prop: "mePower" },
      { rowSpan: 3, colSpan: 1, label: "meLoadSlr", prop: "meLoadSlr" },
      { rowSpan: 3, colSpan: 1, label: "Slip(%)", prop: "slip" },
      {
        rowSpan: 1,
        colSpan: 8,
        label: "Daily Bunker Consumption",
        prop: "bunkerConsumption",
      },
      { rowSpan: 2, colSpan: 4, label: "Bunker ROB", prop: "rob" },
      {
        rowSpan: 3,
        colSpan: 1,
        label: "M/E REVOLUTION COUNTER READING AT NOON",
        prop: "meRevolutionCounter",
      },
      {
        rowSpan: 3,
        colSpan: 1,
        label: "F.O FLOW METER READING AT NOON",
        prop: "foFlowMeter",
      },
      { rowSpan: 3, colSpan: 1, label: "Remarks", prop: "remarks" },
      { rowSpan: 3, colSpan: 1, label: "Update State", prop: "status" },
      { rowSpan: 3, colSpan: 1, label: "Action", prop: "action" },
    ],
  },
  {
    rowIndex: 1,
    row: [
      { rowSpan: 1, colSpan: 4, label: "Main Engine", prop: "mainEngine" },
      { rowSpan: 1, colSpan: 4, label: "Auxiliary Engine", prop: "auxEngine" },
    ],
  },
  {
    rowIndex: 2,
    row: [
      { rowSpan: 1, colSpan: 1, label: "Steaming", prop: "seaTimeSteam" },
      { rowSpan: 1, colSpan: 1, label: "Stoppage", prop: "seaTimeStop" },
      { rowSpan: 1, colSpan: 1, label: "Direction", prop: "windDir" },
      { rowSpan: 1, colSpan: 1, label: "BF", prop: "windBF" },
      { rowSpan: 1, colSpan: 1, label: "Direction", prop: "seaDir" },
      { rowSpan: 1, colSpan: 1, label: "DSS", prop: "seaDSS" },
      { rowSpan: 1, colSpan: 1, label: "HSFO", prop: "meHfoSlr" },
      { rowSpan: 1, colSpan: 1, label: "LSFO", prop: "meLsfoSlr" },
      { rowSpan: 1, colSpan: 1, label: "MDO", prop: "meMdoSlr" },
      { rowSpan: 1, colSpan: 1, label: "MGO", prop: "meMgoSlr" },
      { rowSpan: 1, colSpan: 1, label: "HSFO", prop: "auxHfoSlr" },
      { rowSpan: 1, colSpan: 1, label: "LSFO", prop: "auxLsfoSlr" },
      { rowSpan: 1, colSpan: 1, label: "MDO", prop: "auxMdoSlr" },
      { rowSpan: 1, colSpan: 1, label: "MGO", prop: "auxMgoSlr" },
      { rowSpan: 1, colSpan: 1, label: "HSFO", prop: "brobHfo" },
      { rowSpan: 1, colSpan: 1, label: "LSFO", prop: "brobLsfo" },
      { rowSpan: 1, colSpan: 1, label: "MDO", prop: "brobMdo" },
      { rowSpan: 1, colSpan: 1, label: "MGO", prop: "brobMgo" },
    ],
  },
];
//所有列prop
export const SeaReportTableHeaders:MovementCommonTableColumnsType<MovementCommonTableDataType, keyof MovementCommonTableDataType>[] = [
  {
    dataIndex:'check',
  },
  {
    dataIndex:'alarm',
  },
  {
    dataIndex:'type',
  },
  {
    dataIndex:'reportDateTime',
  },
  {
    dataIndex:'reportDateStr',
  },
  {
    dataIndex:'positionLat',
  },
  {
    dataIndex:'positionLon',
  },
  {
    dataIndex:'positionStr',
  },
  {
    dataIndex:'course',
  },
  {
    dataIndex:'timeOfNextPort',
  },
  {
    dataIndex:'seaTimeSteam',
  },
  {
    dataIndex:'seaTimeStop',
  },
  {
    dataIndex:'seaDistance',
  },
  {
    dataIndex:'dtg',
  },
  {
    dataIndex:'dailyAvgSpeed',
  },
  {
    dataIndex:'windDir',
  },
  {
    dataIndex:'windBF',
  },
  {
    dataIndex:'seaDir',
  },
  {
    dataIndex:'seaDSS',
  },
  {
    dataIndex:'meRpm',
  },
  {
    dataIndex:'tcRpm',
  },
  {
    dataIndex:'meOutput',
  },
  {
    dataIndex:'mePower',
  },
  {
    dataIndex:'meLoadSlr',
  },
  {
    dataIndex:'slip',
  },
  {
    dataIndex:'meHfoSlr',
  },
  {
    dataIndex:'meLsfoSlr',
  },
  {
    dataIndex:'meMdoSlr',
  },
  {
    dataIndex:'meMgoSlr',
  },
  {
    dataIndex:'auxHfoSlr',
  },
  {
    dataIndex:'auxLsfoSlr',
  },
  {
    dataIndex:'auxMdoSlr',
  },
  {
    dataIndex:'auxMgoSlr',
  },
  {
    dataIndex:'brobHfo',
  },
  {
    dataIndex:'brobLsfo',
  },
  {
    dataIndex:'brobMdo',
  },
  {
    dataIndex:'brobMgo',
  },
  {
    dataIndex:'meRevolutionCounter',
  },
  {
    dataIndex:'foFlowMeter',
  },
  {
    dataIndex:'remarks',
  },
  {
    dataIndex:'status',
  },
  {
    dataIndex:'action',
  }
];
//带有分级的表头：filter计算用
export const SeaReportTableHeaderGroup = {
  seaTime: ["seaTimeSteam", "seaTimeStop"],
  wind: ["windDir", "windBF"],
  sea: ["seaDir", "seaDSS"],
  rob: ["brobHfo", "brobLsfo", "brobMdo", "brobMgo"],
  auxEngine: ["auxHfoSlr", "auxLsfoSlr", "auxMdoSlr", "auxMgoSlr"],
  mainEngine: ["meHfoSlr", "meLsfoSlr", "meMdoSlr", "meMgoSlr"],
  bunkerConsumption: [
    "auxHfoSlr",
    "auxLsfoSlr",
    "auxMdoSlr",
    "auxMgoSlr",
    "meHfoSlr",
    "meLsfoSlr",
    "meMdoSlr",
    "meMgoSlr",
  ],
};
// 实际在过滤弹窗内显示的文字格式
export const seaTableHeadersGroup: SeaTableHeadersGroupType[] = [
  {
    key: "General",
    basicDisabled: false,
    list: [
      { label: "Type", value: "type", disabled: true },
      { label: "Date/Time", value: "reportDateTime", disabled: true },
      { label: "Date/Time(raw)", value: "reportDateStr", disabled: false },
      { label: "POSN(Lat)", value: "positionLat", disabled: false },
      { label: "POSN(Long)", value: "positionLon", disabled: false },
      { label: "POSN(raw)", value: "positionStr", disabled: false },
      { label: "Remarks", value: "remarks", disabled: false },
      { label: "Update State", value: "status", disabled: false },
    ],
  },
  {
    key: "Navigation",
    basicDisabled: false,
    list: [
      { label: "True Course at Noon", value: "course", disabled: false },
      { label: "Time Of Next Port", value: "timeOfNextPort", disabled: false },
      { label: "Steaming", value: "seaTimeSteam", disabled: false },
      { label: "Stoppage", value: "seaTimeStop", disabled: false },
      { label: "Sea distance", value: "seaDistance", disabled: false },
      { label: "DTG", value: "dtg", disabled: false },
    ],
  },
  {
    key: "Bunker Monitoring(MT)",
    basicDisabled: false,
    list: [
      { label: "BROB MDO(MT)", value: "brobMdo", disabled: false },
      { label: "BROB LSFO(MT)", value: "brobLsfo", disabled: false },
      { label: "BROB HSFO(MT)", value: "brobHfo", disabled: false },
      { label: "BROB MGO(MT)", value: "brobMgo", disabled: false },
      { label: "ME HSFO CONS(MT)", value: "meHfoSlr", disabled: false },
      { label: "ME LSFO CONS(MT)", value: "meLsfoSlr", disabled: false },
      { label: "ME MDO CONS(MT)", value: "meMdoSlr", disabled: false },
      { label: "ME MGO CONS(MT)", value: "meMgoSlr", disabled: false },
      { label: "AUX HSFO CONS(MT)", value: "auxHfoSlr", disabled: false },
      { label: "AUX LSFO CONS(MT)", value: "auxLsfoSlr", disabled: false },
      { label: "AUX MDO CONS(MT)", value: "auxMdoSlr", disabled: false },
      { label: "AUX MGO CONS(MT)", value: "auxMgoSlr", disabled: false },
    ],
  },  
  {
    key: "Weather Condition",
    basicDisabled: false,
    list: [
      { label: "Wind BF", value: "windBF", disabled: false },
      { label: "Wind Direction", value: "windDir", disabled: false },
      { label: "Sea DSS", value: "seaDSS", disabled: false },
      { label: "Sea Direction", value: "seaDir", disabled: false },
    ],
  },
  {
    key: "Brime Mover",
    basicDisabled: false,
    list: [
      {
        label: "Daily avg speed",
        value: "dailyAvgSpeed",
        disabled: false,
      },
      { label: "M/E R.p.m.", value: "meRpm", disabled: false },
      { label: "T/C R.p.m.", value: "tcRpm", disabled: false },
      { label: "M/E OUTPUT (%MCR)", value: "meOutput", disabled: false },
      { label: "MePower", value: "mePower", disabled: false },
      { label: "MeLoadSLR", value: "meLoadSlr", disabled: false },
      { label: "Slip(%)", value: "slip", disabled: false },
      {
        label: "M/E REVOLUTION COUNTER READING AT NOON",
        value: "meRevolutionCounter",
        disabled: false,
      },
      {
        label: "F.O FLOW METER READING AT NOON",
        value: "foFlowMeter",
        disabled: false,
      },
    ],
  },
];
//在过滤弹窗内使用的prop格式
export const initGroup: InitGroupType = {
  General: ["type", "reportDateTime", "positionLat", "positionLon", "remarks", "status"],
  Navigation: [
    "course",
    "timeOfNextPort",
    "seaTimeSteam",
    "seaTimeStop",
    "seaDistance",
    "dtg",
  ],
  "Bunker Monitoring(MT)": [
    "brobMdo",
    "brobLsfo",
    "brobHfo",
    "brobMgo",
    "meHfoSlr",
    "meLsfoSlr",
    "meMdoSlr",
    "meMgoSlr",
    "auxHfoSlr",
    "auxLsfoSlr",
    "auxMdoSlr",
    "auxMgoSlr",
  ],
  "Brime Mover": [
    "dailyAvgSpeed",
    "meRpm",
    "tcRpm",
    "meOutput",
    "mePower",
    "meLoadSlr",
    "slip",
    "meRevolutionCounter",
    "foFlowMeter",
  ],
  "Weather Condition": ["windBF", "windDir", "seaDSS", "seaDir"],
};
// 需要汇总的prop
export const sumProps = [
  "seaTimeSteam",
  "seaTimeStop",
  "seaDistance",
  "meHfoSlr",
  "meLsfoSlr",
  "meMdoSlr",
  "meMgoSlr",
  "auxHfoSlr",
  "auxLsfoSlr",
  "auxMdoSlr",
  "auxMgoSlr",
];
export const typeOptions = [
  { value: 'COSP', label: "COSP" },
  { value: 'Noon Report', label: "Noon Report" },
  { value: 'EOSP', label: "EOSP" },
];
