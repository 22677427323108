import { type ParameterType } from "@/api";
import { loginOutAction } from "@/store/userSlice";
import {
  getDepartureReportApi,
  saveDepartureReportApi,
  deleteDepartureReportApi,
  saveDepartureReportParams,
  getArrivalReportApi,
  saveArrivalReportApi,
  deleteArrivalReportApi,
  saveArrivalReportParams,
  getShiftingReportApi,
  saveShiftingReportApi,
  deleteShiftingReportApi,
  saveShiftingReportParams,
  getOtherReportParams,
} from "@/api/voyageManage/movement/otherReport/index";

export const getDepartureReportAction: ParameterType<getOtherReportParams> = (
  params,
  frontCallback,
  successCallback,
  errorCallback,
  dispatch,
  navigate
) => {
  frontCallback?.();
  getDepartureReportApi(params)
    .then((response) => {
      successCallback?.(response);
    })
    .catch((err) => {
      errorCallback?.(err);
      if (err?.loginStatus === "token faild") {
        // dispatch?.(loginOut({}));
        // navigate?.('/')
        dispatch(loginOutAction(() => navigate?.("/login")));
      }
    });
};

export const saveDepartureReportAction: ParameterType<
  saveDepartureReportParams
> = (
  params,
  frontCallback,
  successCallback,
  errorCallback,
  dispatch,
  navigate
) => {
  frontCallback?.();
  saveDepartureReportApi(params)
    .then((response) => {
      successCallback?.(response);
    })
    .catch((err) => {
      errorCallback?.(err);
      if (err?.loginStatus === "token faild") {
        // dispatch?.(loginOut({}));
        // navigate?.('/')
        dispatch(loginOutAction(() => navigate?.("/login")));
      }
    });
};

export const deleteDepartureReportAction: ParameterType<number[]> = (
  data,
  frontCallback,
  successCallback,
  errorCallback,
  dispatch,
  navigate
) => {
  frontCallback?.();
  deleteDepartureReportApi(data)
    .then((response) => {
      successCallback?.(response);
    })
    .catch((err) => {
      errorCallback?.(err);
      if (err?.loginStatus === "token faild") {
        // dispatch?.(loginOut({}));
        // navigate?.('/')
        dispatch(loginOutAction(() => navigate?.("/login")));
      }
    });
};

export const getArrivalReportAction: ParameterType<getOtherReportParams> = (
  params,
  frontCallback,
  successCallback,
  errorCallback,
  dispatch,
  navigate
) => {
  frontCallback?.();
  getArrivalReportApi(params)
    .then((response) => {
      successCallback?.(response);
    })
    .catch((err) => {
      errorCallback?.(err);
      if (err?.loginStatus === "token faild") {
        // dispatch?.(loginOut({}));
        // navigate?.('/')
        dispatch(loginOutAction(() => navigate?.("/login")));
      }
    });
};

export const saveArrivalReportAction: ParameterType<saveArrivalReportParams> = (
  params,
  frontCallback,
  successCallback,
  errorCallback,
  dispatch,
  navigate
) => {
  frontCallback?.();
  saveArrivalReportApi(params)
    .then((response) => {
      successCallback?.(response);
    })
    .catch((err) => {
      errorCallback?.(err);
      if (err?.loginStatus === "token faild") {
        // dispatch?.(loginOut({}));
        // navigate?.('/')
        dispatch(loginOutAction(() => navigate?.("/login")));
      }
    });
};

export const deleteArrivalReportAction: ParameterType<number[]> = (
  data,
  frontCallback,
  successCallback,
  errorCallback,
  dispatch,
  navigate
) => {
  frontCallback?.();
  deleteArrivalReportApi(data)
    .then((response) => {
      successCallback?.(response);
    })
    .catch((err) => {
      errorCallback?.(err);
      if (err?.loginStatus === "token faild") {
        // dispatch?.(loginOut({}));
        // navigate?.('/')
        dispatch(loginOutAction(() => navigate?.("/login")));
      }
    });
};

export const getShiftingReportAction: ParameterType<getOtherReportParams> = (
  params,
  frontCallback,
  successCallback,
  errorCallback,
  dispatch,
  navigate
) => {
  frontCallback?.();
  getShiftingReportApi(params)
    .then((response) => {
      successCallback?.(response);
    })
    .catch((err) => {
      errorCallback?.(err);
      if (err?.loginStatus === "token faild") {
        // dispatch?.(loginOut({}));
        // navigate?.('/')
        dispatch(loginOutAction(() => navigate?.("/login")));
      }
    });
};

export const saveShiftingReportAction: ParameterType<
  saveShiftingReportParams
> = (
  params,
  frontCallback,
  successCallback,
  errorCallback,
  dispatch,
  navigate
) => {
  frontCallback?.();
  saveShiftingReportApi(params)
    .then((response) => {
      successCallback?.(response);
    })
    .catch((err) => {
      errorCallback?.(err);
      if (err?.loginStatus === "token faild") {
        // dispatch?.(loginOut({}));
        // navigate?.('/')
        dispatch(loginOutAction(() => navigate?.("/login")));
      }
    });
};

export const deleteShiftingReportAction: ParameterType<number[]> = (
  data,
  frontCallback,
  successCallback,
  errorCallback,
  dispatch,
  navigate
) => {
  frontCallback?.();
  deleteShiftingReportApi(data)
    .then((response) => {
      successCallback?.(response);
    })
    .catch((err) => {
      errorCallback?.(err);
      if (err?.loginStatus === "token faild") {
        // dispatch?.(loginOut({}));
        // navigate?.('/')
        dispatch(loginOutAction(() => navigate?.("/login")));
      }
    });
};
