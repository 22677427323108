import React from "react";
import style from "./index.module.less";
import { Outlet, useNavigate } from "react-router-dom";
import DatabaseHeader from "@/featrue/database/layout/header";

const FleetDatabase: React.FC<{

}> = (props) => {
  const navigate = useNavigate()
  return <div className={style["fleet-database"]}>
    <DatabaseHeader />
    <Outlet />
  </div>
}

export default FleetDatabase