export const initialVesselPoint = (
	id: number,
	imo: number,
	type: string,
	vesselName: string,
	voyageNo: string,
	featrues: {
		cog: number;
		lat: number;
		lng: number;
	}
) => {
	return {
		feature: {
			type: "Feature",
			properties: {
				cog: featrues?.cog,
				vesselName: vesselName,
				voyageNo: voyageNo,
				imo: imo,
				_latlng: {
					lat: featrues?.lat,
					lng: featrues?.lng
				},
				type: "vesselIcon",
				vesselStatus: type,
				id: id
			},
			geometry: {
				type: "Point",
				coordinates: [featrues?.lng, featrues?.lat]
			}
		},
		id: id,
		imo: imo,
		type: type,
		vesselName: vesselName,
		voyageNo: voyageNo
	};
};
