import { CommonInitItemEvent } from "@/types/event";
import { InvStatementCounterPartyType } from "./type";

export const initialStatementCounterPartyItem: CommonInitItemEvent<InvStatementCounterPartyType> = (
	item
) => {
	return {
		beneficiaryName: item?.beneficiaryName ?? "",
		beneficiaryAddress: item?.beneficiaryAddress ?? "",
		accountNo: item?.accountNo ?? "",
		swiftCode: item?.swiftCode ?? "",
		bankCode: item?.bankCode ?? "",
		beneficiaryBank: item?.beneficiaryBank ?? ""
	};
};

export const checkKeys: (keyof InvStatementCounterPartyType)[] = [
	"beneficiaryName",
	"beneficiaryAddress",
	"accountNo",
	"swiftCode",
	"bankCode",
	"beneficiaryBank"
];
