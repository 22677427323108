import React, { useEffect, useMemo, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import debounce from "lodash/debounce";
import { CommonRoleWrap, FleetToast } from "@/common";
import { FleetTooltip } from "@/components";
import { useAppDispatch } from "@/hook";
import useReminder from "@/hook/useReminder";
import { DatabaseContactType, columns as list_columns } from "@/pages/database/business/contact/type";
import {
	getContactGroupAction,
	deleteContactAction,
	addContactAction,
	editContactAction
} from "@/action/dataBase/contact";
import { DatabaseContactFilterChangeEvent, DatabaseContactFilterProps } from "@/featrue/database/contact/filter/type";
import { Popover } from "@/_components";

const useGroup = () => {
	const dispatch = useAppDispatch();
	const navigate = useNavigate();
	const { reminder } = useReminder();

	const cacheSource = useRef<{ [index: number]: DatabaseContactType[] }>({});
	const record = useRef<DatabaseContactType>(null);
	const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([]);

	const [ids, setIds] = useState<string>("");
	const [search, setSearch] = useState("");
	const [loading, setLoading] = useState(false);
	const [addLoading, setAddLoading] = useState(false);
	const [pager, setPager] = useState<{
		total: number;
		pageNum: number;
		pageSize: number;
		refresh: boolean;
	}>({ total: 0, pageNum: 0, pageSize: 20, refresh: false });
	const [filter, setFilter] = useState<Pick<DatabaseContactFilterProps["item"], "businessType" | "companyName">>({
		companyName: "",
		businessType: ""
	});

	const [hideMap, setHide] = useState({
		add: true,
		edit: true
	});

	const onHideChange = (key: "add" | "edit", value: boolean) => {
		setHide((prev) => ({ ...prev, [key]: value }));
	};

	const dataSource = useMemo(() => {
		return cacheSource.current[pager.pageNum];
	}, [pager.pageNum, pager.total, pager.refresh]);

	const delContactMultiple = (type: "multiple" | "single", id?: string) => {
		deleteContactAction(
			{ id: type === "multiple" ? selectedRowKeys.join(",") : id },
			getContactGroupFront,
			delContactResultSuccess,
			getContactGroupError,
			dispatch,
			navigate
		);
	};

	const delContactResultSuccess = (response) => {
		reminder("success", response?.msg);
		setSelectedRowKeys([]);
		getContactGroup();
	};

	const getContactGroupError = (error) => {
		reminder("error", error?.msg ? error?.msg + ": " + error?.data : error?.data);
		setLoading(false);
	};

	const columns = useMemo(() => {
		const _columns = [...list_columns];
		_columns.push({
			dataIndex: "option",
			width: 85,
			title: "Action",
			key: "option",
			align: "center",
			fixed: "right",
			render: (value, record, index) => {
				return (
					<div className="td-operate">
						<CommonRoleWrap roleStr="contact:list:delete">
							<Popover
								// iconProps={{
								//   type: "FleetEslistDeleteIcon",
								//   outerLayerClassname: "operate-icon"
								// }}
								render={(setIsOpen) => {
									return (
										<FleetToast
											label="Are you sure delete this item?"
											onCancel={() => {
												setIsOpen(false);
											}}
											onConfirm={() => {
												delContactMultiple("single", record?.id);
												setIsOpen(false);
											}}
										/>
									);
								}}
							>
								<span className="font_family fleet-delete" style={{ color: "red" }}></span>
							</Popover>
						</CommonRoleWrap>
					</div>
				);
			}
		});
		return _columns;
	}, [list_columns]);

	const onReset = () => {
		setHide({
			edit: true,
			add: true
		});
		setIds("");
		setSelectedRowKeys([]);
		getContactGroup();
	};

	const onSelectChange = (newSelectedRowKeys: React.Key[]) => {
		setSelectedRowKeys(newSelectedRowKeys);
	};

	const getContactGroupFront = () => {
		setLoading(true);
	};

	const getContactSuccess = (response) => {
		let total = 0;
		switch (true) {
			case !Array.isArray(response?.data):
				// setDataSource(response?.data?.items);
				cacheSource.current[1] = response?.data?.items;
				total = response?.data?.totalCount;
				break;
			default:
				// setDataSource([]);
				cacheSource.current = {};
				break;
		}
		setPager((prev) => ({ ...prev, total, refresh: !prev.refresh, pageNum: 1 }));
		setLoading(false);
	};

	const getContactError = (error) => {
		reminder("error", error?.msg ? error?.msg + ": " + error?.data : error?.data);
		setLoading(false);
	};

	const getContactGroup = (searchVal?: string) => {
		cacheSource.current = {};
		getContactGroupAction(
			{
				pageNum: 1,
				pageSize: pager.pageSize,
				keyword: searchVal ?? search,
				companyName: filter?.companyName,
				businessType: filter?.businessType
			},
			getContactGroupFront,
			getContactSuccess,
			getContactError,
			dispatch,
			navigate
		);
	};

	const addContactFront = () => {
		setAddLoading(true);
	};

	const addContactSuccess = (response) => {
		setAddLoading(false);
		setIds("");
		setSelectedRowKeys([]);
		reminder("success", response?.msg);
		onHideChange("add", true);
		getContactGroup();
	};

	const addContactError = (error) => {
		reminder("error", error?.msg ? error?.msg + ": " + error?.data : error?.data);
		setAddLoading(false);
	};

	const addContact = (item) => {
		addContactAction(
			{ ...item },
			addContactFront,
			addContactSuccess,
			addContactError,
			dispatch,
			navigate
		);
	};

	const editContact = (item) => {
		editContactAction(
			{ ...item },
			addContactFront,
			addContactSuccess,
			addContactError,
			dispatch,
			navigate
		);
	};

	const onPageChange = (page: number, size: number) => {
		if (dataSource?.length >= pager.total) return;
		// if (page * size <= dataSource?.length) return;
		if (cacheSource.current[page]) {
			setPager((prev) => ({ ...prev, pageNum: page }));
			return;
		}
		getContactGroupAction(
			{
				...filter,
				pageNum: page,
				pageSize: pager.pageSize,
				keyword: search
			},
			getContactGroupFront,
			(response) => {
				if (!Array.isArray(response?.data)) {
					cacheSource.current[page] = response?.data?.items;
				} else {
					cacheSource.current[page] = [];
				}
				setPager((prev) => ({ ...prev, pageNum: page }));
				setLoading(false);
			},
			getContactError,
			dispatch,
			navigate
		);
	};

	const onSearch = useMemo(() => {
		const loadOptions = (val: string) => {
			cacheSource.current = {};
			setSearch(val);
			getContactGroup(val);
		};
		return debounce(loadOptions, 500);
	}, []);

	const onFilterChange: DatabaseContactFilterChangeEvent = (key, value) => {
		setFilter((prev) => {
			return {
				...prev,
				[key]: value
			};
		});
	};

	const onRow = (contactItem) => {
		return {
			onDoubleClick: () => {
				onHideChange("edit", false);
				record.current = contactItem;
				// navigate(`/layout/voyagemanage/movement/details?id=${record?.id}`)
			}
		};
	};

	const handleConfirm = () => {
		onHideChange("edit", true);
		getContactGroup();
	};

	useEffect(() => {
		getContactGroup();
	}, [filter]);

	return {
		search,
		filter,
		ids,
		selectedRowKeys,
		pager,
		columns,
		dataSource,
		loading,
		hideMap,
		addLoading,
		setAddLoading,
		navigate,
		setHide,
		onPageChange,
		onSelectChange,
		onIdsChange: setIds,
		onSearch,
		onDeleteItems: delContactMultiple,
		onFilterChange,
		onRow,
		onHideChange,
		onAdd: addContact,
		onEdit: editContact,
		record,
		handleConfirm
	};
};

export default useGroup;
