import { downloadMovementPnlAction, editMovementVoyageStatusAction, getMovementAction, initMovementDetailsAction, saveMovementDetailAction } from "@/action/voyageManage/movement";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import useReminder from "@/hook/useReminder";
import {
	useVesselBasicIndex,
	usePortIndex,
	useCargoInfoIndex,
	useCargoGroupIndex,
	useEstIndex,
	MovementBunkerPlanProps,
	MovementLaytimeProps
} from "../components";
import useOtherIndex from "./useOtherIndex";
import usePnl from "./usePnl";
import useDialogIndex from "./useDialog";
import { MovementDetailsReportCommitEvent, MovementTcvcOtherChangeEvent } from "../type";
import { useSettingsIndex } from "../../../common";
import { VoyageStatusType } from "../../../type";
const useIndex = (voyageId: string, onLoadingChange: (value: boolean) => void) => {
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const { reminder } = useReminder();

	const [refresh, setRefresh] = useState(false);

	const { dataSource: dialogGroup, change: handleDialogChange } = useDialogIndex();

	const {
		dataSource: otherForm,
		init: handleOtherFormInit,
		change: handleOtherFormChange,
		commit: handleOtherFormCommit
	} = useOtherIndex();

	const {
		dataSource: vesselBasicForm,
		commit: handleVesselBasicCommit,
		change: handleVesselBasicChange,
		init: handleVesselBasicInit
	} = useVesselBasicIndex();

	const {
		dataSource: cargoInfoForm,
		commit: handleCargoInfoCommit,
		change: handleCargoInfoChange,
		init: handleCargoInfoInit
	} = useCargoInfoIndex();

	const {
		dataSource: settingsForm,
		commit: handleSettingsCommit,
		change: handleSettingsChange,
		init: handleSettingsInit
	} = useSettingsIndex();

	const {
		dataSource: cargoGroup,
		change: handleCargoChange,
		commit: handleCargoCommit,
		init: handleCargoaInit
	} = useCargoGroupIndex();

	const {
		editStatus,
		dataSource: portGroup,
		delIds,
		ladenPortList,
		ballastPortList,
		init: handleActualPortGroupInit,
		change: handleActualPortGroupChange,
		commit: handleActualPortGroupCommit,
		add: handleActualPortGroupAdd,
		remove: handleActualPortGroupDelete,
		move: handleActualPortItemMove,
		handleStartEndPortCommit,
		handleActualPortExpeseUpdate
	} = usePortIndex();

	const {
		queryLoading: estQueryLoading,
		loading: estLoading,
		vesselItem: estVesselItem,
		cargoItem: estCargoItems,
		calculate: estCalculate,
		quicklockForm: estQuicklockForm,
		handleCargoItemChange: handleEstCargoChange,
		portList: estPortItems,
		handlePortItemCommit: handleEstPortItemCommit,
		handlePortItemChange: handleEstPortItemChange,
		handlePortItemAdd: handleEstPortItemAdd,
		handlePortItemDelete: handleEstPortItemDelete,
		handleCargoItemCommit: handleEstCargoCommit,
		handleEstPortCommit,
		handleBatchCommit: handleEstBatchCommit,
		handleVesselItemChange: handleEstVesselParChange,
		setMovementMainPlan,
		otherItem,
		handleOtherItemChange,
		activePlanIndex,
		activePlanItem,
		activePlanType,
		planItems,
		handleEstPlanItemSelect,
		handlePlanAdd,
		handleEstPlanInit,
		handlePlanSave,
		handleEstInit,
		handleBatchFoConsumCommit
	} = useEstIndex(voyageId);

	const { actualCalauate, totalRevenues, totalCost, totalDisputeCharge, handleCombineParams } =
		usePnl(estCalculate, vesselBasicForm, cargoGroup?.[0], portGroup, otherForm);

	const getMovement = () => {
		getMovementAction(
			{
				id: voyageId
			},
			getMovementFront,
			getMovementSuccess,
			getMovementError,
			dispatch,
			navigate
		);
	};

	const getMovementFront = () => {
		onLoadingChange?.(true);
	};

	const getMovementSuccess = (response) => {
		const { vesselBasics, cargoInfo, setting, portRation, cargo, planList, ...restDataSource } =
			response?.data;
		handleVesselBasicInit(vesselBasics);
		handleCargoInfoInit(cargoInfo);
		handleSettingsInit(setting);
		handleActualPortGroupInit(portRation);
		handleCargoaInit([cargo]);
		handleOtherFormInit(restDataSource);
		handleEstPlanInit(planList ?? []);
		handleEstInit(restDataSource?.mainPlan ?? {});
		onLoadingChange?.(false);
		if (!restDataSource?.moveActualBalanceId) {
			initMovementDetailsAction(
				{
					...actualCalauate,
					netVoyageDays: actualCalauate?.totalSeaDays,
					totalOffHireDays: actualCalauate?.offHire,
					id: restDataSource?.moveActualBalanceId,
					voyageId
				},
				null,
				null,
				getMovementError,
				dispatch,
				navigate
			);
		}
	};

	const getMovementError = (error) => {
		onLoadingChange?.(false);
		reminder("error", error?.msg ? error?.msg + ": " + error?.data : error?.data);
	};

	const saveMovementDetailSuccess = (response) => {
		reminder("success", response?.msg);
		getMovement();
		setRefresh(!refresh);
	};

	const saveMovementDetail = () => {
		saveMovementDetailAction(
			{
				...otherForm,
				id: voyageId,
				vesselBasics: vesselBasicForm,
				cargo: cargoGroup?.[0],
				setting: settingsForm,
				cargoInfo: cargoInfoForm,
				portRation: portGroup,
				stat: {
					...actualCalauate,
					netVoyageDays: actualCalauate?.totalSeaDays,
					totalOffHireDays: actualCalauate?.offHire
				},
				seaDays: actualCalauate?.totalSeaDays,
				portDays: estCalculate?.totalPortDay,
				totalDistance: estCalculate?.totalDistance,
				ecaDistance: estCalculate?.ecaDistance,
				portIdleDays: estCalculate?.totalPortIdleDays,
				portWorkDays: estCalculate?.totalPortWorkDays,
				ecaDays: estCalculate?.totalEcaDays,
				delIds: delIds?.current
			},
			getMovementFront,
			saveMovementDetailSuccess,
			getMovementError,
			dispatch,
			navigate
		);
	};

	const editMovementVoyageStatus = (status: VoyageStatusType) => {
		editMovementVoyageStatusAction(
			{
				id: voyageId,
				status
			},
			getMovementFront,
			(response) => {
				handleOtherFormChange("status", status);
				onLoadingChange?.(false);
				reminder("success", response?.msg);
			},
			getMovementError,
			dispatch,
			navigate
		);
	};

	const handlePnlDownloadSuccess = (response) => {
		reminder("success", response?.msg);
	};

	const handlePnlDownload = () => {
		const params = handleCombineParams({
			voyageId,
			voyageNo: otherForm?.voyageNo,
			vesselName: vesselBasicForm?.vesselName,
			cargoName: cargoGroup?.[0]?.cargoName,
			cargoQuantity: +cargoGroup?.[0]?.cargoQuantity,
			charterName: cargoInfoForm?.chartererName,
			ownerName: vesselBasicForm?.ownerName,
			loadingPort: ballastPortList?.map((item) => item?.portName),
			dischargePort: ladenPortList?.map((item) => item?.portName),
			cargoUnit: cargoGroup?.[0]?.cargoUnit,
			mainPlanDays: estQuicklockForm?.totalSeaDays + estQuicklockForm?.totalPortDays,
			mainPlanPnl: estCalculate?.totalProfit
		});
		downloadMovementPnlAction(
			params,
			null,
			handlePnlDownloadSuccess,
			getMovementError,
			dispatch,
			navigate
		);
	};

	const handleStatusChange = (val: VoyageStatusType) => {
		// if (otherForm?.status === "Closed") {
		//   reminder("error", "Voyage was closed");
		//   return
		// }
		editMovementVoyageStatus(val);
	};

	const handleOtherLaytimeChange: MovementLaytimeProps["onConfirm"] = (item) => {
		const layTimeSubtotal = otherForm?.layTimeSubtotal;
		const index = layTimeSubtotal?.findIndex((laytimeItem) => laytimeItem?.id === item?.id);
		layTimeSubtotal?.splice(index, 1, item);
		handleOtherFormChange("layTimeSubtotal", [...layTimeSubtotal]);
	};

	const handleSeaReportsChange: MovementDetailsReportCommitEvent = (type, items) => {
		handleActualPortGroupInit(items);
		handleDialogChange(type, true);
	};

	const handleBunkerCostConfirm: MovementBunkerPlanProps["onConfirm"] = (
		portExpenseItems,
		portItems,
		bunkerPlan
	) => {
		handleOtherFormChange("bunkerPlan", { ...otherForm.bunkerPlan, ...bunkerPlan });
		handleStartEndPortCommit(portItems);
		debugger;
		handleActualPortExpeseUpdate(portExpenseItems);
	};

	const handlePortExpensesConfirm: MovementTcvcOtherChangeEvent = (key, value) => {
		handleOtherFormChange("portExpenseList", value);
		handleActualPortExpeseUpdate(value);
	};

	useEffect(() => {
		getMovement();
	}, [voyageId]);

	return {
		vesselBasicForm,
		otherForm,
		dialogGroup,
		handleDialogChange,
		portGroup,
		cargoGroup,
		handleCargoCommit,
		handleVesselBasicCommit,
		handleVesselBasicChange,
		cargoInfoForm,
		handleCargoInfoCommit,
		handleCargoInfoChange,
		settingsForm,
		handleSettingsCommit,
		handleSettingsChange,
		handleOtherLaytimeChange,
		handleActualPortGroupInit,
		handleActualPortGroupChange,
		handleActualPortGroupCommit,
		handleActualPortGroupAdd,
		handleActualPortGroupDelete,
		handleActualPortItemMove,
		ladenPortList,
		ballastPortList,
		saveMovementDetail,
		handleOtherFormChange,
		handleOtherFormCommit,
		handleCargoChange,
		handleStatusChange,
		editMovementVoyageStatus,
		actualCalauate,
		totalRevenues,
		totalCost,
		totalDisputeCharge,
		estLoading,
		estVesselItem,
		estPortItems,
		estCargoItems,
		estCalculate,
		handleEstCargoChange,
		handleEstCargoCommit,
		handleEstVesselParChange,
		setMovementMainPlan,
		handleSeaReportsChange,
		handleEstPortItemCommit,
		handleEstPortItemChange,
		handleEstPortItemAdd,
		handleEstPortItemDelete,
		handleEstPortCommit,
		handleEstBatchCommit,
		otherItem,
		handleOtherItemChange,
		handlePnlDownload,
		activePlanIndex,
		activePlanItem,
		activePlanType,
		planItems,
		handleEstPlanItemSelect,
		handlePlanAdd,
		handlePlanSave,
		estQueryLoading,
		refresh,
		handleBunkerCostConfirm,
		handleBatchFoConsumCommit,
		handlePortExpensesConfirm
	};
};

export default useIndex;