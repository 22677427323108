import React, { FC } from "react";
import style from "./index.module.less";
import { MovementHandoverProps } from "./type";
import { FleetPrompt } from "@/common";
import { VoyageManageHandoverForm } from "@/pages/voyagemanage/business/preOp&movement";
import { FleetLoading } from "@/components";
import useIndex from "./hooks/useIndex";
import { Button } from "@/_components";

const MovementHandover: FC<MovementHandoverProps> = ({ hide, onClose, voyageId, voyageType }) => {
	const { loading, handoverFormRef, savePreopHandoverForm } = useIndex(
		voyageId,
		voyageType,
		hide,
		onClose
	);

	return (
		<FleetPrompt hide={hide} mode="fixed">
			<div className={style["movement-handover"]}>
				<FleetLoading loading={loading} />
				<div className="prompt-header--container">
					<span className="header-title">Handover Form</span>
					<span className="font_family fleet-close header-close" onClick={onClose}></span>
				</div>
				<VoyageManageHandoverForm
					voyageId={voyageId}
					ref={handoverFormRef}
					type="movement"
					voyageType={voyageType}
				/>
				<div className="prompt-footer--container">
					<Button size="small" type="secondary" onClick={onClose}>
						Cancel
					</Button>
					<Button size="small" onClick={savePreopHandoverForm}>
						Confirm
					</Button>
				</div>
			</div>
		</FleetPrompt>
	);
};

export default MovementHandover;
