import { CommonInitItemEvent } from "@/types/event";
import { MovementTctcVesselBasicsItemType } from "./type";

export const initialDataSource: CommonInitItemEvent<MovementTctcVesselBasicsItemType> = (item) => {
	return {
		ownerName: item?.ownerName ?? "",
		id: item?.id ?? null,
		vesselName: item?.vesselName ?? "",
		commission: item?.commission ?? "",
		dailyHire: item?.dailyHire ?? "",
		brokerage: item?.brokerage ?? "",
		paymentStartDay: item?.paymentStartDay ?? "",
		estRedeliveryDate: item?.estRedeliveryDate ?? null,
		brokerName: item?.brokerName ?? "",
		brokerId: item?.brokerId ?? null,
		deliveryTime: item?.deliveryTime ?? null,
		redeliveryPortCode: item?.redeliveryPortCode ?? "",
		redeliveryPortName: item?.redeliveryPortName ?? "",
		deliveryPortCode: item?.deliveryPortCode ?? "",
		deliveryPortName: item?.deliveryPortName ?? "",
		cpNo: item?.cpNo ?? "",
		paymentTerm: item?.paymentTerm ?? null,
		mmsi: item?.mmsi ?? "",
		lastUpdate: item?.lastUpdate ?? null,
		cpDate: item?.cpDate ?? ""
	};
};
