import { ExtendsKeys } from "@/types";
import { VesselInfo } from "../monitoring/components";
import { MonitoringVoyageListProps } from "../monitoring/components/VoyageList/type";
import {
	MonitoringAisDetailSelectTimeKeys,
	MonitoringAisDetailsProps
} from "../monitoring/components/monitoringDetails/type";

export enum MeteoType {
	Wind = "wind",
	Wave = "wave",
	Swell = "swell1",
	Current = "current",
	Typhoon = "typhoon",
	Pressure = "pressure"
}

export enum SwitchType {
	On = "on",
	Off = "off"
}

export type MonitoringMapProps = {
	onVesselItemClick: (
		type: "init" | "timeSelect",
		item: VesselInfo,
		selectTime: MonitoringAisDetailSelectTimeKeys,
		isLocation: boolean,
		isScorllTo?: boolean
	) => void;
	onAisVesselItemClick: (
		type: "ais" | "port" | "area",
		item: Partial<Parameters<MonitoringVoyageListProps["onAisItemSelect"]>[1]>
	) => void;
	onAisDetailItemChange: MonitoringAisDetailsProps["onAisCancel"];
};

export type MonitoringMaRefType = {
	loadVesselTrack: (
		type: "ais" | "vm",
		tracks: {
			properties: any;
			ais: any;
			latestPosition: any;
			trackPoints: any;
		}
	) => void;
};

export type MonitoringMapVesselPropertiesType = {
	cog: number;
	vesselName: string;
	voyageNo: string;
	imo: number;
	_latlng: { lat: number; lng: number };
	type: "vmVesselIconChecked" | "vesselIcon";
	vesselStatus: "Ballast" | "Laden";
};

export type MonitoringMapVesselItemType = {
	id?: number;
	imo: number;
	type: "Ballast" | "Laden";
	vesselName: string;
	voyageNo: string;
	feature: GeoJSON.Feature<GeoJSON.Point, MonitoringMapVesselPropertiesType>;
};

const a: GeoJSON.Feature<
	GeoJSON.Geometry,
	{
		cog: number;
		vesselName: string;
		voyageNo: string;
		imo: number;
		_latlng: { lat: number; lng: number };
		type: string;
		vesselStatus: "Ballast" | "Laden";
	}
> = {
	type: "Feature",
	properties: {
		cog: 266.8,
		vesselName: "NAN XIN 2",
		voyageNo: "",
		imo: 412419520,
		_latlng: {
			lat: 28.072688,
			lng: 121.401775
		},
		type: "vesselIcon",
		vesselStatus: "Ballast"
	},
	geometry: {
		type: "Point",
		coordinates: [121.401775, 28.072688]
	}
};
