import { FleetOptionType } from "@/types/advancedType";
import { InvoicesVoyageFilterStatusType } from "./type";

export const statusOptions: FleetOptionType<Capitalize<InvoicesVoyageFilterStatusType>, InvoicesVoyageFilterStatusType>[] = [
  {
    value: "all",
    label: "All"
  },
  {
    value: "Commencing",
    label: "Commencing"
  },
  {
    value: "Completed",
    label: "Completed"
  },
  {
    value: "Closed",
    label: "Closed"
  }
]