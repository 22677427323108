import { FleetOptionType } from "@/types/advancedType";
import { RouteLegItemType } from "../item/module";

export const statuOptions: FleetOptionType<
	RouteLegItemType["status"],
	RouteLegItemType["status"]
>[] = [
	{ label: "Preparation", value: "Preparation" },
	{ label: "Departed", value: "Departed" },
	{ label: "Arrived", value: "Arrived" },
	{ label: "Completed", value: "Completed" },
	{ label: "Closed", value: "Closed" }
];
