import React, { FC } from "react";
import classnames from "classnames";
import style from "./index.module.less";
import { AparTagProps } from "./type";

const AparTag: FC<AparTagProps> = ({
  type
}) => {
  return <span className={classnames(style["apar-tag"], style[`apar-tag--${type}`])}>{type}</span>
}

export default AparTag