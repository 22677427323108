import { useAppDispatch } from "@/hook"
import useReminder from "@/hook/useReminder"
import { useEffect, useState } from "react"
import { useNavigate } from "react-router-dom"
import { CommonVesselArchivalType } from "../type"
import { queryVesselArchiveForImoAction } from "@/action/dataBase/vesselArchives"

const useIndex = (imo: string, hide: boolean) => {
	const dispatch = useAppDispatch();
	const navigate = useNavigate();
	const { reminder } = useReminder();

	const [loading, setLoading] = useState(false);

	const [dataSource, setDataSource] = useState<Partial<CommonVesselArchivalType>>();

	const getVesselArchivesFront = () => {
		setLoading(true);
	};

	const getVesselArchivesSuccess = (response) => {
		setDataSource(response?.data);
		setLoading(false);
	};

	const getVesselArchivesFailed = (error) => {
		setLoading(false);
		reminder("error", error?.msg ? error?.msg + ": " + error?.data : error?.data);
	};

	const getVesselArchives = () => {
		queryVesselArchiveForImoAction(
			{ imo: imo },
			getVesselArchivesFront,
			getVesselArchivesSuccess,
			getVesselArchivesFailed,
			dispatch,
			navigate
		);
	};

	useEffect(() => {
		if (hide) return;
		if (!imo) return;
		getVesselArchives();
	}, [imo, hide]);

	return {
		loading,
		dataSource
	};
};

export default useIndex