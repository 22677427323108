import React, { FC } from "react";
import style from "./index.module.less";
import { RouteAreaListProps } from "./type";
import { FleetInput, FleetLoading } from "@/components";
import { RouteAreaPreset, RouteAreaSelectList } from "./componnets";
import useIndex from "./hooks/useIndex";
import classnames from "classnames";

const RouteAreaList: FC<RouteAreaListProps> = ({ activeTab, onReset }) => {
	const { loading, dataSource, selectRows, handleSearch, handleDelete, handlePresetSelect } =
		useIndex(activeTab, onReset);
	return (
		<div
			className={classnames("routeCommon-list-common", style["route-areaList"], {
				[style["route-areaList--active"]]: activeTab === "area"
			})}
		>
			<FleetInput
				value={undefined}
				prefix="LayoutSearchIcon"
				placeholder="Search Area"
				onChange={handleSearch}
				className="routeCommon-list-header"
			/>
			<div className="routeCommon-list-container areaList-wrapper">
				<FleetLoading loading={loading} />
				<RouteAreaPreset items={dataSource} onSelect={handlePresetSelect} />
				<RouteAreaSelectList
					items={selectRows}
					onDelele={handleDelete}
					onSelect={handlePresetSelect}
				/>
			</div>
		</div>
	);
};

export default RouteAreaList;
