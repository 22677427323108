import React, { useEffect, useState, useRef, forwardRef, useImperativeHandle } from "react";
import {
	List,
	Col,
	Row,
	Input,
	Button,
	Divider,
	Popover,
	Checkbox,
	Skeleton,
	AutoComplete
} from "antd";
import { SearchOutlined, FilterOutlined } from "@ant-design/icons";
import InfiniteScroll from "react-infinite-scroll-component";
import { useLocation } from "react-router-dom";
import VesselItem from "../VesselItem";
import { VesselInfo } from "@/pages/monitoring/components";
import { getVesselListAction } from "@/action/monitoring/vessel";
import { FleetLoading } from "@/components";
import useReminder from "@/hook/useReminder";
import { debounce } from "lodash";
import { cacheStorage } from "@/storage";
import { StorageKeyMap } from "@/storage/types";
import classnames from "classnames";
import style from "./index.module.less";
import { useMonitoringContext } from "@/pages/monitoring/MonitoringContext";
import { MonitorVoyageGroupProps } from "./type";

const options = [
	{ label: "Preparation", value: "Preparation" },
	{ label: "Departed", value: "Departed" },
	{ label: "Arrived", value: "Arrived" },
	{ label: "Completed", value: "Completed" },
	{ label: "Closed", value: "Closed" }
];

// 默认值，选中除Closed外的其他项
const defalutOptions = options.filter((t) => t.value !== "Closed").map((t) => t.value);

const VesselList = forwardRef<unknown, MonitorVoyageGroupProps>(
	({ activeTab, onItemClick, handleChangeVessels }, ref) => {
		const [keyword, setKeyword] = useState<string>("");
		const [status, setStatus] = useState<any>(defalutOptions);
		const [allList, setAllList] = useState<VesselInfo[]>([]);
		const [list, setList] = useState<VesselInfo[]>([]);
		const [filterVisible, SetFilterVisible] = useState<boolean>(false);
		const [loading, setLoading] = useState<boolean>(false);
		const [page, setPage] = useState<number>(1);
		const [historyKeys, setHistoryKeys] = useState<string[]>(
			cacheStorage.get(StorageKeyMap.vm_search_keys) || []
		);
		const { reminder } = useReminder();
		const pageSize: number = 20;
		const { search } = useLocation();
		const voyageNo = new URLSearchParams(search)?.get?.("voyageNo");
		const isDefault = useRef(voyageNo ? true : false);
		const { currentVessel } = useMonitoringContext();
		const scorllTimeout = useRef<NodeJS.Timeout>(null);

		useEffect(() => {
			if (activeTab !== "vm") return;
			handleSearch();
			return () => {
				queryDebounce.cancel();
				loadMoreDataDebounce.cancel();
				scorllTimeout?.current && clearTimeout(scorllTimeout.current);
			};
		}, [activeTab]);

		useEffect(() => {
			if (isDefault.current && allList.length > 0) {
				isDefault.current = false;
				const item = allList.find?.((t: VesselInfo) => t.voyageNo === voyageNo);
				item && onItemClick?.(item, true);
			}
		}, [allList, isDefault]);

		const handleItemClick = (item: VesselInfo) => {
			console.log("handleItemClick", item);
			onItemClick?.(item, false);
		};

		const handleSearch = () => {
			SetFilterVisible(false);
			query(keyword);
		};

		const handleInputChange = (keyword: string) => {
			setKeyword(keyword);
			queryDebounce(keyword);
		};

		const queryDebounce = useRef(debounce((keyword) => query(keyword), 500)).current;
		const query = (keyword: string = "") => {
			changeKeysHistory(keyword);
			getVesselListAction(
				{ keyword, statusList: status.join(",") },
				() => {
					setLoading(true);
				},
				({ data }) => {
					setLoading(false);
					setPage(1);
					setAllList(data?.voyageList || []);
					data?.currentVoList?.forEach((currentItem) => {
						const targets = data?.voyageList.filter(
							(voyageItem) => currentItem.voyageNo === voyageItem.voyageNo
						);
						console.log("targets", targets);
						targets.forEach((voyageItem) => {
							voyageItem.cog = currentItem?.feature?.properties?.cog;
						});

						let target;
						if (targets.length > 0) {
							target = targets.sort(
								(a: VesselInfo, b: VesselInfo) => b.actualCommenceDate - a.actualCommenceDate
							)[0];
							currentItem.imo = target.imo;
							currentItem.id = target.id;
							currentItem.type = target.vesselStatus;
						}
					});
					handleChangeVessels?.(data?.currentVoList || []);
					loadMoreData(data?.voyageList || [], 1);
				},
				(error) => {
					setLoading(false);
					reminder("error", error?.msg ? error?.msg + ": " + error?.data : error?.data);
				}
			);
		};

		const loadMoreDataDebounce = useRef(
			debounce((data: any[], page: number) => loadMoreData(data, page), 500)
		).current;
		const loadMoreData = (data: any[], page: number) => {
			const length = page * pageSize;
			const newLength = length > data.length ? data.length : length;
			const newList = data.slice(0, newLength);
			setList(newList);
			setPage(page + 1);
		};

		const changeKeysHistory = (newkey: string) => {
			if (newkey.trim()) {
				historyKeys.unshift(newkey);
				const newHistoryKeys = historyKeys.length > 20 ? historyKeys.slice(0, 20) : historyKeys;
				setHistoryKeys(newHistoryKeys);
				cacheStorage.set(StorageKeyMap.vm_search_keys, newHistoryKeys);
			}
		};

		const scrollIntoItem = (id: string, allListParam: VesselInfo[] = allList) => {
			const itemIndex = allListParam.findIndex((t: VesselInfo) => t.voyageNo === id);
			if (itemIndex > list.length) {
				const page = Math.ceil(itemIndex / pageSize);
				loadMoreData(allListParam, page);
			}
			if (itemIndex !== -1) {
				scorllTimeout?.current && clearTimeout(scorllTimeout.current);
				scorllTimeout.current = setTimeout(() => {
					const itemDom = document.getElementById(id);
					itemDom?.scrollIntoView({ behavior: "smooth", block: "start" });
				}, 200);
			}
		};

		useImperativeHandle(ref, () => ({
			scrollIntoItem
		}));

		return (
			<div
				className={classnames(style.vesselList, {
					[style["active"]]: activeTab === "vm"
				})}
			>
				<FleetLoading loading={loading} />
				<div className={style.total}>Vessels({allList.length})</div>
				<Row gutter={8} wrap={false} style={{ marginBottom: 8 }}>
					<Col style={{ paddingRight: "0px" }} flex="auto">
						<AutoComplete
							getPopupContainer={(triggerNode: any) => triggerNode.parentNode}
							popupClassName={style.popup}
							options={(keyword ? [] : historyKeys).map((t: string, index: number) => {
								return { key: index, label: t, value: t };
							})}
							style={{ width: "100%" }}
							onSelect={(item: string) => handleInputChange(item)}
						>
							<Input
								placeholder="Search"
								value={keyword}
								onChange={(e) => handleInputChange(e.target.value)}
								prefix={<SearchOutlined />}
							/>
						</AutoComplete>
					</Col>
					<Col flex="40px">
						<Popover
							placement="bottomLeft"
							trigger="click"
							title={null}
							open={filterVisible}
							onOpenChange={(val) => SetFilterVisible(val)}
							content={
								<div style={{ width: "140px" }}>
									<Checkbox.Group
										style={{ display: "grid" }}
										options={options}
										value={status}
										onChange={(val) => setStatus(val)}
									/>
									<Divider style={{ margin: "8px 0" }} />
									<div style={{ display: "flex", justifyContent: "space-between" }}>
										<Button onClick={() => setStatus([])}>Reset</Button>
										<Button type="primary" onClick={handleSearch}>
											OK
										</Button>
									</div>
								</div>
							}
						>
							<Button type="primary" icon={<FilterOutlined />} />
						</Popover>
					</Col>
				</Row>
				{/* <Divider style={{ margin: '8px 0' }} /> */}
				<div id="scrollableDiv" className={style.list}>
					<InfiniteScroll
						dataLength={list.length}
						next={() => loadMoreDataDebounce(allList, page)}
						hasMore={list.length !== allList.length}
						loader={<Skeleton paragraph={{ rows: 1 }} active />}
						endMessage={<Divider plain>Nothing more</Divider>}
						scrollableTarget="scrollableDiv"
					>
						{list.length > 0 && (
							<List
								dataSource={list}
								renderItem={(item, index) => (
									<VesselItem
										id={item.voyageNo}
										key={index}
										currentVessel={currentVessel}
										data={item}
										onItemClick={handleItemClick}
									/>
								)}
							/>
						)}
					</InfiniteScroll>
				</div>
			</div>
		);
	}
);

export default VesselList;
