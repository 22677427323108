import { type PageParams } from "@/api/estimation";
import React, { useState, useRef, useCallback, useMemo } from "react";
import style from "@/components/Select/index.module.less";
import { FleetIcon } from "@/components";
import { useNavigate } from "react-router-dom";
import { useAppDispatch } from "@/hook";
import debounce from 'lodash/debounce';
import { AccountItemType } from "@/common/AccountSelect/type";
import useReminder from "../useReminder";
import { getAccountGroupAction } from "@/action/voyageManage/invoices";


const useSelect = <T extends Partial<AccountItemType>>(
  multiple?: boolean,
  onFinish?: (item: T | T[] | null, newItem: T, type: "add" | "del") => void,
  label?: keyof AccountItemType,
  accountItems?: T | T[]
) => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { reminder } = useReminder();
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(true);
  const [list, setList] = useState<T[]>([]);
  const [searchValue, setSearchVal] = useState("");
  const page = useRef<PageParams>({
    pageNum: 1,
    pageSize: 10
  });

  const selectValue = useMemo(() => {
    if (Array.isArray(accountItems)) {
      return accountItems?.map(item => item?.id)
    }

    return [label ? accountItems?.[label] : accountItems?.beneficiaryName]
  }, [accountItems, multiple])

  const closePopup = (open: boolean) => {
    if (!open) {
      // setSearchVal("");
      page.current.pageNum = 1;
    }
    setOpen(open);
  }

  const focusPopup = (loadMore?: boolean, searchVal?: string) => {
    getAccountGroupAction(
      {
        companyName:searchVal || ""
      },
      queryPortGroupFront,
      loadMore ? loadMoreSuccess : queryPortGroupSuccess,
      queryPortGroupError,
      dispatch,
      navigate
    )
  }

  const itemSelect = (item: T) => {
    if (Array.isArray(accountItems)) {
      const index = accountItems?.findIndex((selectItem: T) => selectItem?.id === item.id);
      if (index === -1) {
        onFinish?.([...accountItems, item], item, "add");
      } else {
        if (accountItems?.length <= 1) {
          reminder("info", "Please reserve at least one port");
          return
        }
        onFinish?.(accountItems, item, "del");
      }
    } else {
      const isExist = accountItems?.id === item.id;
      onFinish?.(isExist ? null : item, item, isExist ? "del" : "add");
      setOpen(false);
    }
  }

  const queryPortGroupFront = () => {
    setLoading(true);
    // setOpen(true)
  }

  const queryPortGroupSuccess = (response) => {
    console.log('reponse', response)
    setLoading(false);
    setList(response?.data);
  }

  const queryPortGroupError = (error) => {
    setLoading(false)
  }

  const loadMoreSuccess = (response) => {
    const data = response?.data ?? [];
    console.log('new data', [...list, ...data])
    setList(prev => [...prev, ...data]);
    setLoading(false);
  }


  const loadMore = () => {
    page.current.pageNum += 1;
    focusPopup(true)
  };



  const search = useMemo(() => {

    const loadOptions = (val: string) => {
      page.current.pageNum = 1;
      setList([]);
      focusPopup(false, val);
    }
    return debounce(loadOptions, 800)
  }, [focusPopup])


  const tagRender = (props) => {
    const tagItem = (accountItems as T[]).find(item => item.id == props.value)
    return <div className={style["fleet-select-tag"]}>
      <span>{label ? tagItem[label] : tagItem?.beneficiaryName}</span>
      <FleetIcon
        type="FleetCloseIcon"
        outerLayerClassname="rm-icon"
        onClick={() => {
          itemSelect(tagItem)
        }}
      />
    </div>
  }

  return {
    loading,
    list,
    open,
    selectValue,
    loadMore,
    closePopup,
    focusPopup,
    tagRender,
    itemSelect,
    search,
  }
}

export default useSelect;