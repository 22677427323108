import React from "react";
import style from "./index.module.less";
import useIndex from "./hook/useIndex";
import { FleetFormItem, FleetInput,FleetButton } from "@/components";

const Password: React.FC<{}> = () => {
  const { passwordForm, changeForm,changePassword } = useIndex();
  return (
    <div className={style["fleet-password"]}>
      <FleetFormItem
        containerClassname="password-item"
        label="Old Password"
        type="row"
        needVerity
      >
        <FleetInput
          className="password-item-inner"
          value={passwordForm?.oldPassword}
          onChange={(v) => changeForm('oldPassword', v)}
        />
      </FleetFormItem>
      <FleetFormItem
        containerClassname="password-item"
        label="New Password"
        type="row"
        needVerity
      >
        <FleetInput
          className="password-item-inner"
          value={passwordForm?.newPassword}
          onChange={(v) => changeForm('newPassword', v)}
        />
      </FleetFormItem>
      <FleetFormItem
        containerClassname="password-item"
        label="Confirm Password"
        type="row"
        needVerity
      >
        <FleetInput
          className="password-item-inner"
          value={passwordForm?.confirmPassword}
          onChange={(v) => changeForm('confirmPassword', v)}
        />
      </FleetFormItem>
      <FleetFormItem
        containerClassname="password-item"
        label=""
        type="row"
      >
        <div className="password-item-inner">
          <FleetButton type="primary" onClick={changePassword}>
            Save
          </FleetButton>
        </div>
      </FleetFormItem>
      
    </div>
  );
};

export default Password;
