import { type ApiParameter } from "@/api";
import { appVersion } from "@/api/constant";
import request from "@/service";
import {
	deleteVesselArchiveParams,
	getVesselArchiveGroupParams,
	queryVesselArchiveForImoParams,
	queryVesselArchiveForMmsiParams,
	queryVesselArchiveGroupParams,
	updateVesselArchivePaload
} from "./type";

const enum ApiMap {
	updateVesselArchiveParameter,
	deleteVesselArchiveParameter,
	queryVesselArchiveForImoParameter,
	queryVesselArchiveForMmsiParameter,
	getVesselArchiveGroupParameter,
	queryVesselArchiveGroupParameter
}

type ApiUrl = keyof typeof ApiMap;

const vesselArchivesApi: ApiParameter<ApiUrl> = {
	updateVesselArchiveParameter: {
		method: "post",
		url: appVersion + "/vessel/base"
	},
	deleteVesselArchiveParameter: {
		method: "delete",
		url: appVersion + "/vessel/base/batch"
	},
	queryVesselArchiveForImoParameter: {
		method: "get",
		url: appVersion + "/vessel/base"
	},
	queryVesselArchiveForMmsiParameter: {
		method: "get",
		url: appVersion + "/vessel/base/mmsi"
	},
	getVesselArchiveGroupParameter: {
		method: "get",
		url: appVersion + "/vessel/base/list"
	},
	queryVesselArchiveGroupParameter: {
		method: "get",
		url: appVersion + "/vessel/base/search"
	}
};

export const updateVesselArchiveApi = (params: updateVesselArchivePaload) => {
	return request.request({
		...vesselArchivesApi.updateVesselArchiveParameter,
		method: params?.method,
		data: params?.params
	});
};

export const deleteVesselArchiveApi = (params: deleteVesselArchiveParams) => {
	return request.request({
		...vesselArchivesApi.deleteVesselArchiveParameter,
		data: params
	});
};

export const queryVesselArchiveForImoApi = (params: queryVesselArchiveForImoParams) => {
	return request.request({
		...vesselArchivesApi.queryVesselArchiveForImoParameter,
		url: `${vesselArchivesApi.queryVesselArchiveForImoParameter.url}/${params?.imo}`
	});
};

export const queryVesselArchiveForMmsiApi = (params: queryVesselArchiveForMmsiParams) => {
	return request.request({
		...vesselArchivesApi.queryVesselArchiveForMmsiParameter,
		params
	});
};

export const getVesselArchiveGroupApi = (params: getVesselArchiveGroupParams) => {
	return request.request({
		...vesselArchivesApi.getVesselArchiveGroupParameter,
		params
	});
};

export const queryVesselArchiveGroupApi = (params: queryVesselArchiveGroupParams) => {
	return request.request({
		...vesselArchivesApi.queryVesselArchiveGroupParameter,
		params
	});
};
