export {
  getMovementOtherIncomeParams,
  saveMovementOtherIncomeParams
} from "./type"

export {
  getMovementOtherIncomeApi,
  saveMovementOtherIncomeApi
} from "./api"

export { otherIncomeApiMap, ApiUrl as MovementOtherIncomeApiType } from "./api";
export { MovementOtherIncomeParamsType } from "./type";