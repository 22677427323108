import React from "react";
import style from "./index.module.less";
import { DatabaseTaskFilterProps } from "./type";
import { FleetInput } from "@/components";

const TaskFilter: React.FC<DatabaseTaskFilterProps> = ({
  onSearch
}) => {
  return <div className={style["task-filter"]}>
    <span className="filter-label">Filter</span>
    <div className="filter-container">
      <FleetInput
        value={undefined}
        prefix="LayoutSearchIcon"
        placeholder="Please enter"
        onChange={onSearch}
      />
    </div>
  </div>
}

export default TaskFilter