import React from "react";
import classnames from "classnames";
import style from "./index.module.less";
import { FleetDatePicker, FleetFormItem, FleetVerifyInput } from "@/components";
import dayjs from "dayjs";
import { InvStatementBaseProps } from "./type";
import { InvStatementBasics, InvStatementCounterParty } from "./components";
import { InvoicesViewsCommonHeader } from "../../../../common";

const InvStatementBase: React.FC<InvStatementBaseProps> = ({
	activeInvType,
	item,
	onChange,
	onCommit,
	otherItem,
	onFieldOpen,
	basicsFieldList,
	onBasicsFieldItemChange,
	onBasicsFieldDelete,
	counterFieldList,
	onCounterPartyFieldItemChange,
	onCounterPartyFieldDelete
}) => {
	return (
		<div className={classnames("invoicesDetail-item", style["statement-base"])}>
			<InvoicesViewsCommonHeader
				type="base"
				item={{
					status: otherItem?.status,
					updateTime: otherItem?.updateTime,
					invNo: item?.invNo
				}}
				title={activeInvType === "relet" ? "Hire Invoices" : "Statement of Hire"}
			/>
			<div className="invoices-base--header">
				<FleetVerifyInput
					value={item?.invNo}
					onChange={(val: string) => {
						onChange?.("invNo", val);
					}}
					label="INV. No."
					needVerity
				/>
				<FleetFormItem label="Invoice Date" needVerity>
					<FleetDatePicker
						value={item?.invDate ? dayjs(item?.invDate) : null}
						onChange={(val, timeStamp) => {
							onChange?.("invDate", val);
						}}
						allowClear
					/>
				</FleetFormItem>
			</div>
			<InvStatementBasics
				activeInvType={activeInvType}
				item={{
					ownerName: item?.ownerName,
					ownerId: item?.ownerId,
					address: item?.address,
					tel: item?.tel,
					email: item?.email
				}}
				onCommit={onCommit}
				fieldItems={basicsFieldList}
				onFieldChange={onBasicsFieldItemChange}
			/>
			<InvStatementCounterParty
				item={{
					beneficiaryName: item?.beneficiaryName,
					beneficiaryAddress: item?.beneficiaryAddress,
					accountNo: item?.accountNo,
					swiftCode: item?.swiftCode,
					bankCode: item?.bankCode,
					beneficiaryBank: item?.beneficiaryBank
				}}
				onCommit={onCommit}
				fieldItems={counterFieldList}
			/>
		</div>
	);
};

export default InvStatementBase;
