import React, { Fragment } from "react";
import classnames from "classnames";
import commonNewlyStyle from "../../index.module.less";
import style from "./index.module.less";
import { CommonNewlyCompany, FleetPrompt } from "@/common";
import {
	FleetCascader,
	FleetFormItem,
	FleetInput,
	FleetLoading,
	FleetTextarea,
	FleetVerifyInput
} from "@/components";
import { CommonNewlyContactProps } from "./type";
import { useTranslation } from "react-i18next";
import useIndex from "./hooks/useIndex";
import { CommonCompanySelect, CommonContactItemType } from "@/common/Select";
import { businessOption } from "./source";
import { Button } from "@/_components";

const CommonNewlyContact: React.FC<CommonNewlyContactProps> = ({
	hide,
	onClose,
	loading,
	setLoading,
	onConfirm,
	contanctParams
}) => {
	const { t } = useTranslation();
	const {
		dataSource,
		change,
		handleClose,
		handleConfirm,
		companyHide,
		setCompanyHide,
		handleCompanyClose
	} = useIndex(hide, contanctParams, setLoading, onConfirm, onClose);

	return (
		<Fragment>
			<FleetPrompt mode="fixed" onClose={handleClose} hide={hide}>
				<div
					className={classnames(commonNewlyStyle["common-newly"], style["common-newly--contact"])}
				>
					<FleetLoading loading={loading} />
					<div className="newly-header">
						<span className="fleet-title">Add New Contacts</span>
						<span className="font_family fleet-close" onClick={onClose}></span>
					</div>
					<div className="newly-container">
						<div className="contacts-detail">
							<FleetVerifyInput
								value={dataSource?.name}
								onChange={(val: string) => {
									change("name", val);
								}}
								label="Contact Name"
								needVerity
							/>
							<FleetFormItem label="Company Name" needVerity>
								<div className="contacts-conpmay--container">
									<CommonCompanySelect
										activeItems={{
											companyName: dataSource?.companyName
										}}
										primaryKey="companyName"
										showKey="companyName"
										onFinish={(newCompanyItem, type) => {
											change("companyName", newCompanyItem?.companyName ?? "");
										}}
									/>
									<span
										className="font_family fleet-plus"
										onClick={() => setCompanyHide(false)}
									></span>
								</div>
							</FleetFormItem>
							<FleetFormItem label="Business Type">
								<FleetCascader
									value={[dataSource?.businessType]}
									dropdownMatchSelectWidth
									onClear={() => {
										change("businessType", null);
									}}
									disabled={!!contanctParams}
									className="cascader-container"
									onChange={(val: [CommonContactItemType["businessType"]]) => {
										change("businessType", val?.[0]);
									}}
									options={businessOption}
								/>
							</FleetFormItem>
							<FleetVerifyInput
								value={dataSource?.mobilePhone1}
								onChange={(val: string) => {
									change("mobilePhone1", val);
								}}
								label="MOB"
							/>
							<FleetVerifyInput
								value={dataSource?.mobilePhone2}
								onChange={(val: string) => {
									change("mobilePhone2", val);
								}}
								label="MOB2"
								// needVerity
							/>
							<FleetVerifyInput
								value={dataSource?.landline1}
								onChange={(val: string) => {
									change("landline1", val);
								}}
								label="TEL"
							/>
							<FleetVerifyInput
								value={dataSource?.landline2}
								onChange={(val: string) => {
									change("landline2", val);
								}}
								label="TEL2"
								// needVerity
							/>
							<FleetVerifyInput
								value={dataSource?.faxNumber}
								onChange={(val: string) => {
									change("faxNumber", val);
								}}
								label="FAX"
								// needVerity
							/>
							<FleetVerifyInput
								value={dataSource?.email}
								onChange={(val: string) => {
									change("email", val);
								}}
								label="Email"
								needVerity
							/>
						</div>
						<FleetTextarea
							value={dataSource?.remark}
							onChange={(val: string) => {
								change("remark", val);
							}}
							label="Remakes"
							className="contacts-remarks"
							placeholder="Please input the remarks"
						/>
					</div>
					<div className="newly-footer">
						<Button size="small" onClick={handleClose} type="secondary">
							Cancel
						</Button>
						<Button size="small" onClick={handleConfirm} disabled={loading}>
							Confirm
						</Button>
					</div>
				</div>
			</FleetPrompt>
			<CommonNewlyCompany
				type="add"
				hide={companyHide}
				onClose={handleCompanyClose}
				onConfirm={handleCompanyClose}
			/>
		</Fragment>
	);
};

export default CommonNewlyContact;
