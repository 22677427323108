import useReminder from "@/hook/useReminder";
import { useState } from "react";

const useIndex = (onChange) => {
  const [min, setMin] = useState<number>(0);
  const [sec, setSec] = useState<number>(0);
  const [direction, setDirection] = useState<string>();
  const [openLanOrLon, setOpenLanOrLon] = useState<boolean>(false);
  const { reminder } = useReminder();
  const confirmLatOrLon = (type: "Latitude" | "Longitude") => {
    const result = ['S','W'].includes(direction)? -(min + sec/60).toFixed(2):+(min + sec/60).toFixed(2);
    if(!min && !sec){
      onChange(0)
    }else{
      onChange(result);
    }
    setOpenLanOrLon(false);
  };
  const northSouth = [
    { value: "N", label: "N" },
    { value: "S", label: "S" },
  ];
  const eastWest = [
    { value: "E", label: "E" },
    { value: "W", label: "W" },
  ];
  return {
    min,
    sec,
    openLanOrLon,
    northSouth,
    eastWest,
    setMin,
    setSec,
    setOpenLanOrLon,
    confirmLatOrLon,
    direction,
    setDirection
  };
};
export default useIndex;
