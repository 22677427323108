import { useEffect, useMemo, useRef, useState } from "react";
import {
	MovementPortExpensesItemType,
	MovementPortExpensesPortItemChangeEvent,
	MovementPortExpensesPortItemCommitEvent,
	MovementPortExpensesProps
} from "../type";
import { useAppDispatch } from "@/hook";
import { useNavigate } from "react-router-dom";
import useReminder from "@/hook/useReminder";
import { initialPortExpenses } from "../source";
import { onRequest } from "@/action";

const useIndex = (
	hide: boolean,
	voyageId: MovementPortExpensesProps["voyageId"],
	onConfirm: MovementPortExpensesProps["onConfirm"],
	onClose?: () => void
) => {
	const dispatch = useAppDispatch();
	const navigate = useNavigate();
	const { reminder } = useReminder();
	const [loading, setLoading] = useState(false);
	const delIds = useRef<number[]>([]);
	const [dataSource, setDataSource] = useState<MovementPortExpensesItemType>({
		...initialPortExpenses
	});

	const summary = useMemo(() => {
		return dataSource?.portExpenseVoList?.reduce(
			(prev, curr) => {
				return {
					purpose: "Total",
					cargoQuantity: +prev.cargoQuantity + +curr.cargoQuantity,
					prePortDisb: +prev.prePortDisb + +curr.prePortDisb,
					settlement: +prev.settlement + +curr.settlement,
					portExpense: +prev?.portExpense + +curr?.portExpense
				};
			},
			{
				purpose: "",
				cargoQuantity: 0,
				prePortDisb: 0,
				settlement: 0,
				portExpense: 0
			}
		);
	}, [dataSource]);

	const change: MovementPortExpensesPortItemChangeEvent = (currentIndex, key, value) => {
		setDataSource((prev) => {
			const currentItem = prev?.portExpenseVoList?.[currentIndex];
			prev?.portExpenseVoList?.splice(currentIndex, 1, {
				...currentItem,
				[key]: value
			});
			return { ...prev };
		});
	};

	const commit: MovementPortExpensesPortItemCommitEvent = (currentIndex, item) => {
		setDataSource((prev) => {
			const currentItem = prev?.portExpenseVoList?.[currentIndex];
			prev?.portExpenseVoList?.splice(currentIndex, 1, {
				...currentItem,
				...item
			});
			return { ...prev };
		});
	};

	const getMovementPortExpensesFront = () => {
		setLoading(true);
	};

	const getMovementPortExpensesSuccess = (response) => {
		setLoading(false);
		setDataSource(response?.data);
	};

	const getMovementPortExpensesFailed = (error) => {
		setLoading(false);
		reminder("error", error?.msg ? error?.msg + ": " + error?.data : error?.data);
	};

	const saveMovementPortExpensesSuccess = (response) => {
		setLoading(false);
		onConfirm?.("portExpenseList", dataSource?.portExpenseVoList);
		onClose?.();
	};

	const getMovementPortExpenses = () => {
		onRequest(
			"getMovementPortExpensesApi",
			{ id: voyageId },
			getMovementPortExpensesFront,
			getMovementPortExpensesSuccess,
			getMovementPortExpensesFailed,
			dispatch,
			navigate
		);
	};

	const saveMovementPortExpenses = () => {
		onRequest(
			"saveMovementPortExpensesApi",
			dataSource,
			getMovementPortExpensesFront,
			saveMovementPortExpensesSuccess,
			getMovementPortExpensesFailed,
			dispatch,
			navigate
		);
	};

	useEffect(() => {
		if (hide) {
			delIds.current = [];
			return;
		}
		getMovementPortExpenses();
	}, [hide]);

	return {
		loading,
		dataSource,
		summary,
		change,
		commit,
		saveMovementPortExpenses
	};
};

export default useIndex;