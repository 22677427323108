import React, { useMemo, useRef } from "react";
import style from "./index.module.less";
import { FleetFormItem, FleetIcon, FleetInput } from "@/components";
import { PerformanceData, PerformanceProps, performanceChangeEvent } from "@/hook/estimation/details/usePerformance";
import usePlotting from "@/hook/estimation/details/usePlotting";
import { formatThousandthNumber } from "@/tools/amount";

export const options = {
  layout: {
    root: '#container',
    m: 40
  },
  /**
   * 注意！这里分段间有重复点20一定要相等，用于衔接曲线
   */
  data: [],
  axisX: {
    data: [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30],
    format(param: string | number) {
      return param + 'w'
    },
    top: 4,
  },
  axisY: {
    data: [0, 20, 40, 60, 80],
    format(param: string | number) {
      return param + '人'
    },
    right: 10,
  },
  series: [
    {
      rgba: [[55, 162, 255], [116, 21, 219]],
      hoverRgba: [[55, 162, 255], [116, 21, 219]],
      lineColor: 'blue'
    },
    {
      rgba: [[255, 0, 135], [135, 0, 157]],
      hoverRgba: [[255, 0, 135], [135, 0, 157]],
      lineColor: 'purple'
    },
    {
      rgba: [[255, 190, 0], [224, 62, 76]],
      hoverRgba: [[255, 190, 0], [224, 62, 76]],
      lineColor: 'orange'
    }
  ]
}

export type PlottingProps = {
  type: "eco" | "full";
  source?: Partial<PerformanceData>;
  activeKey?: "L" | "B";
  performance?: Partial<{
    cpEcoIfo: number;
    cpEcoSpeed: number;
    cpFullIfo: number;
    cpFullSpeed: number;
  }>;
  onPmChange?: performanceChangeEvent
}

const Plotting: React.FC<PlottingProps> = ({
  type,
  source,
  activeKey,
  performance,
  onPmChange
}) => {
  const layoutRef = useRef<HTMLDivElement>(null);
  const dot = useMemo(() => {
    return {
      cpIfo: type === "eco" ? performance?.cpEcoIfo : performance?.cpFullIfo,
      cpSpeed: type === "eco" ? performance?.cpEcoSpeed : performance?.cpFullSpeed
    }
  }, [performance]);
  const plottingSource = useMemo(() => {
    return {
      foList: type === "eco" ? source?.ecoFoList : source?.fullFoList,
      speedList: type === "eco" ? source?.ecoSpeedList : source?.fullSpeedList,
      maxFo: type === "eco" ? source?.maxEcoFo : source?.maxFullFo,
      maxSpeed: type === "eco" ? source?.maxEcoSpeed : source?.maxFullSpeed,
    }
  }, [type, source]);

  const average = useMemo(() => {
    let foList = type === "eco" ? source?.ecoFoList : source?.fullFoList;
    let speedList = type === "eco" ? source?.ecoSpeedList : source?.fullSpeedList;
    
    let sumFo = foList.reduce((prev, curr) => prev + curr, 0);
    let sumSpeed = speedList.reduce((prev, curr) => prev + curr, 0);
    return {
      fo: foList.length === 0 ? 0 : sumFo / foList.length,
      speed: speedList.length === 0 ? 0 : sumSpeed / speedList.length
    }

  }, [source, type])

  const { clear } = usePlotting(
    options,
    layoutRef?.current,
    plottingSource,
    dot,
    activeKey,
  );

  return <div className={style["performance-plotting"]}>
    <div className="plotting-header">
      <span className="header-title">{type === "eco" ? "ECO Speed" : "Full Speed"}</span>
      <div className="header-form">
        <div className="form-descriptor">
          <span className="descriptor-text">SPD</span>
          <span className="descriptor-text">{formatThousandthNumber(average?.speed)}</span>
        </div>
        <div className="form-descriptor">
          <span className="descriptor-text">FO</span>
          <span className="descriptor-text">{formatThousandthNumber(average?.fo)}</span>
        </div>
        <div className="form-descriptor">
          <span className="descriptor-prefix descriptor-prefix-1"></span>
          <span className="descriptor-text">CP Speed</span>
        </div>
        <div className="form-descriptor">
          <span className="descriptor-prefix descriptor-prefix-2"></span>
          <span className="descriptor-text">Speed</span>
        </div>
        <div className="form-descriptor">
          <FleetIcon
            type="FleetPmIfoIcon"
            outerLayerClassname="descriptor-prefix descriptor-prefix-icon"
          />
          <span className="descriptor-text">IFO</span>
        </div>
        <div className="form-descriptor">
          <FleetIcon
            type="FleetPmMdoIcon"
            outerLayerClassname="descriptor-prefix descriptor-prefix-icon"
          />
          <span className="descriptor-text">Model IFO</span>
        </div>
        <FleetFormItem
          label="CP IFO"
          type="row"
          containerClassname="form-search"
        >
          <FleetInput
            value={type === "eco" ? performance?.cpEcoIfo : performance?.cpFullIfo}
            placeholder="MT"
            type="number"
            className="form-input"
            onChange={(val: number) => {
              switch (type) {
                case "eco":
                  if (activeKey === "B") {
                    onPmChange?.("cpIfoBallastEco", val);
                  } else {
                    onPmChange?.("cpIfoLadenEco", val);
                  }
                  break;
                case "full":
                  if (activeKey === "B") {
                    onPmChange?.("cpIfoBallastFull", val);
                  } else {
                    onPmChange?.("cpIfoLadenFull", val);
                  }
                  break;
                default:
                  break;
              }
            }}
          />
        </FleetFormItem>
        <FleetFormItem
          label="CP Speed"
          type="row"
          containerClassname="form-search"
        >
          <FleetInput
            value={type === "eco" ? performance?.cpEcoSpeed : performance?.cpFullSpeed}
            type="number"
            onChange={(val: number) => {
              switch (type) {
                case "eco":
                  if (activeKey === "B") {
                    onPmChange?.("cpSpeedBallastEco", val);
                  } else {
                    onPmChange?.("cpSpeedLadenEco", val);
                  }
                  break;
                case "full":
                  if (activeKey === "B") {
                    onPmChange?.("cpSpeedBallastFull", val);
                  } else {
                    onPmChange?.("cpSpeedLadenFull", val);
                  }
                  break;
                default:
                  break;
              }
            }}
            placeholder="Knot"
            className="form-input"
          />
        </FleetFormItem>
      </div>
    </div>
    <div className="plotting" ref={layoutRef}></div>
  </div>
}

export default Plotting