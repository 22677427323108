import { useAppDispatch } from "@/hook";
import {
	InvFreightFieldOpenEvent,
	InvFreightFieldType,
	InvFreightInitItemType,
	InvFreightProps,
	InvStatementFirstInitItemType
} from "../type";
import { useNavigate } from "react-router-dom";
import useReminder from "@/hook/useReminder";
import { useEffect, useMemo, useRef, useState } from "react";
import { InvoicesViewsContainerProps } from "../../../common";
import { downloadPdf } from "@/tools/graph";
import useOtherIndex from "./useOtherIndex";
import { useFreightBaseIndex, useFreightDescIndex } from "../components";
import { CommonFieldAddProps } from "@/common";
import {
	getFreightInvoicesAction,
	getInitialFreightInvoicesAction,
	getLastFreightInvoicesAction,
	saveFreightInvoicesAction,
	updateFreightInvoicesAction
} from "@/action/voyageManage/invoices";
import { initialInvFreightCargoItem } from "../components/descrition/components/cargoDetails/source";
import { useTranslation } from "react-i18next";

const useIndex = (
	activeVoyageItem: InvFreightProps["activeVoyageItem"],
	activeInvoice: InvFreightProps["activeInvoice"],
	newly: InvFreightProps["newly"],
	onInvoiceUpdate: InvFreightProps["onInvoiceUpdate"],
	onInvoiceAdd: InvFreightProps["onInvoiceAdd"]
) => {
	const dispatch = useAppDispatch();
	const navigate = useNavigate();
	const { reminder } = useReminder();

	const { t } = useTranslation();

	const [loading, setLoading] = useState(false);
	const canvasRef = useRef<HTMLCanvasElement>(null);
	const [activeTab, setActiveTab] = useState<InvoicesViewsContainerProps["activeTab"]>("form");
	const activeField = useRef<InvFreightFieldType>("basics");

	const handleTabSelect: InvoicesViewsContainerProps["onTabSelect"] = (val) => {
		if (activeTab === val) return;
		setActiveTab(val);
	};

	const {
		dataSource: otherItem,
		change: handleOtherItemChange,
		init: handleOtherInit,
		dialogItem,
		handleDialogChange
	} = useOtherIndex();

	const {
		baseItem,
		handleBaseItemChange,
		handleBaseItemCommit,
		handleBaseInit,
		handleBaseCheck,
		beneficiaryInfoDelIds,
		beneficiaryInfoFieldItems,
		handleBeneficiaryInfoFieldItemChange,
		handleBeneficiaryInfoFieldDelete,
		handleBeneficiaryInfoFieldAdd,
		counterPartyDelIds,
		counterPartyFieldItems,
		handleCounterPartyFieldItemChange,
		handleCounterPartyFieldFieldDelete,
		handleCounterPartyFieldFieldAdd
	} = useFreightBaseIndex({ addOther: otherItem?.addOther });

	const {
		cargoItems,
		delCargoIds,
		handleCargoItemChange,
		handleCargoDetailAdd,
		handleCargoDetailDelete,
		loadDataSource,
		dischargeDataSource,
		delPortIds,
		handlePortItemChange,
		handlePortItemCommit,
		handlePortAdd,
		handlePortRemove,
		settleItems,
		summary: descSummary,
		handleSettlementCommit,
		check: handleDescCheck,
		init: handleDescInit,
		otherExpenses,
		otherExpenseDelIds,
		otherExpensesSummary,
		handleOtherExpensesChange,
		handleOtherExpensesAdd,
		handleOtherExpensesDelete
	} = useFreightDescIndex(otherItem);

	const init = (type: "init" | "details", item: Partial<InvFreightInitItemType>) => {
		const { basic, description, id, ...restItem } = item;
		const restParams = type === "init" ? restItem : { id, ...restItem };
		handleBaseInit(type, basic);
		handleDescInit(type, description);
		handleOtherInit(restParams);
	};

	const handleFirstInit = (item: InvStatementFirstInitItemType) => {
		handleBaseInit("init", item);
		handleDescInit("init", {
			...item,
			cargoDetailList: [
				initialInvFreightCargoItem("init", {
					cargoName: item?.cargeName,
					cargoQuantity: item?.cargoQuantity,
					cargoUnit: item?.cargoUnit
				})
			]
		});
		handleOtherInit(item);
	};

	const check = () => {
		const { checkKey: baseCheckKey, checked: baseChecked } = handleBaseCheck();
		if (!baseChecked) {
			reminder("error", `Field ${baseCheckKey} is required`);
			return false;
		}

		const { checkKey: descCheckKey, checked: descChecked } = handleDescCheck();
		if (!descChecked) {
			reminder(
				"error",
				`Field ${t(`voyageManagement.invoices.${descCheckKey}`, {
					defaultValue: t(`common.${descCheckKey}`)
				})} is required`
			);
			return false;
		}

		return true;
	};

	const summary = useMemo(() => {
		return descSummary + otherExpensesSummary?.plus - otherExpensesSummary?.less;
	}, [descSummary, otherExpensesSummary]);

	const handleFieldOpen: InvFreightFieldOpenEvent = (type) => {
		activeField.current = type;
		handleDialogChange("field", false);
	};

	const handleFieldAdd: CommonFieldAddProps["onAdd"] = (item) => {
		switch (true) {
			case activeField.current === "basics":
				handleCounterPartyFieldFieldAdd({ ...item, type: "" });
				break;
			case activeField.current === "counterParty":
				handleBeneficiaryInfoFieldAdd({ ...item, type: "" });
				break;
			case activeField.current === "otherItems":
				handleOtherExpensesAdd({ ...item, type: "" });
				break;
			default:
				break;
		}
		handleDialogChange("field", true);
	};

	const handleSaveFront = () => {
		setLoading(true);
	};

	const handleSaveError = (error) => {
		setLoading(false);
		reminder("error", error?.msg ? error?.msg + ": " + error?.data : error?.data);
	};

	const handleSaveSuccess = ({ data, msg }) => {
		onInvoiceUpdate?.({ id: data?.id, invNo: data?.invNo });
		reminder("success", msg);
		setLoading(false);
		getFreightInvoicesAction(
			{ invoiceId: data?.id },
			handleSaveFront,
			getFreightDetailSuccess,
			handleSaveError,
			dispatch,
			navigate
		);
	};

	const getInitialFreightInvoicesSuccess = ({ data }) => {
		handleFirstInit(data);
		onInvoiceAdd?.();
		setActiveTab("form");
		setLoading(false);
	};

	const getFreightDetailSuccess = ({ data }) => {
		init("details", data);
		setActiveTab("form");
		setLoading(false);
	};

	const getLastFreightInvSuccess = ({ data }) => {
		init("init", data);
		setActiveTab("form");
		setLoading(false);
		onInvoiceAdd?.();
	};

	const getNewlyFreightInvoices = () => {
		switch (newly.type) {
			case "lastDetail":
			case "last":
				getLastFreightInvoicesAction(
					{ voyageId: activeVoyageItem?.id },
					handleSaveFront,
					newly.type === "last" ? getLastFreightInvSuccess : getFreightDetailSuccess,
					handleSaveError,
					dispatch,
					navigate
				);
				break;
			case "first":
				getInitialFreightInvoicesAction(
					{ voyageId: activeVoyageItem?.id },
					handleSaveFront,
					getInitialFreightInvoicesSuccess,
					handleSaveError,
					dispatch,
					navigate
				);
				break;
			case "detail":
				getFreightInvoicesAction(
					{ invoiceId: activeInvoice?.id },
					handleSaveFront,
					getFreightDetailSuccess,
					handleSaveError,
					dispatch,
					navigate
				);
				break;
			default:
				break;
		}
	};

	const handleDownPdf = () => {
		downloadPdf(canvasRef, "freight_inv");
	};

	const handleSave = () => {
		if (!check()) return;
		console.log("loadDataSource", loadDataSource, dischargeDataSource);
		let params = {
			...otherItem,
			voyageId: activeVoyageItem?.id,
			basic: {
				...baseItem,
				counterFieldList: counterPartyFieldItems,
				beneficiaryFieldList: beneficiaryInfoFieldItems
			},
			description: {
				...settleItems,
				otherFieldList: otherExpenses,
				loadPortDemDes: loadDataSource,
				dischargeDemDes: dischargeDataSource,
				loadPortList: loadDataSource?.map((item) => item?.portName),
				dischargePortList: dischargeDataSource?.map((item) => item?.portName),
				cargoDetailList: cargoItems
			}
		};

		if (activeInvoice?.id) {
			updateFreightInvoicesAction(
				{
					...params,
					id: activeInvoice?.id,
					delFeildIdList: [
						...beneficiaryInfoDelIds?.current,
						...counterPartyDelIds?.current,
						...otherExpenseDelIds?.current
					],
					delCargoIdList: delCargoIds.current,
					delDemDesIdList: delPortIds.current
				},
				handleSaveFront,
				handleSaveSuccess,
				handleSaveError,
				dispatch,
				navigate
			);
			return;
		}

		saveFreightInvoicesAction(
			{
				...params
			},
			handleSaveFront,
			handleSaveSuccess,
			handleSaveError,
			dispatch,
			navigate
		);
	};

	useEffect(() => {
		if (newly.status === -1) return;
		getNewlyFreightInvoices();
	}, [newly]);

	return {
		loading,
		canvasRef,
		activeField,
		activeTab,
		handleTabSelect,
		handleDownPdf,
		handleSave,
		otherItem,
		handleOtherItemChange,
		dialogItem,
		handleDialogChange,
		cargoItems,
		delCargoIds,
		handleCargoItemChange,
		handleCargoDetailAdd,
		handleCargoDetailDelete,
		loadDataSource,
		dischargeDataSource,
		delPortIds,
		handlePortItemChange,
		handlePortItemCommit,
		handlePortAdd,
		handlePortRemove,
		summary,
		settleItems,
		handleSettlementCommit,
		baseItem,
		handleBaseItemChange,
		handleBaseItemCommit,
		beneficiaryInfoFieldItems,
		handleBeneficiaryInfoFieldItemChange,
		counterPartyFieldItems,
		handleCounterPartyFieldItemChange,
		otherExpenses,
		handleOtherExpensesChange,
		handleOtherExpensesDelete,
		handleFieldOpen,
		handleFieldAdd
	};
};

export default useIndex;
