import React, { FC } from "react";
import style from "./index.module.less";
import { MovementCargoInfoProps } from "./type";
import { FleetDatePicker, FleetFormItem, FleetInput } from "@/components";
import dayjs from "dayjs";
import { useTranslation } from "react-i18next";
import { CommonContactSelect } from "@/common";

const MovementCargoInfo: FC<MovementCargoInfoProps> = ({
  item,
  onChange,
  onCommit
}) => {
  const { t } = useTranslation()
  return <div className={style["movement-cargoInfo"]}>
    <div className="movement-details--header">
      <span className="fleet-title">Cargo Info</span>
    </div>
    <FleetFormItem
      containerClassname="cargoInfo-item"
      needVerity
      type="row"
      label={t("voyageManagement.movement.chartererName")}
    >
      <CommonContactSelect
        activeItems={{
          companyName: item?.chartererName,
          id: item?.chartererId
        }}
        showKey="companyName"
        onFinish={(newItem, type) => {
          onCommit?.({
            chartererName: newItem?.companyName,
            chartererId: newItem?.id
          })
        }}
      contactParmas={{ businessType: "Charterer" }}
      />
    </FleetFormItem>
    <FleetFormItem
      containerClassname="cargoInfo-item"
      type="row"
      label={t("voyageManagement.common.paymentTerm")}
    >
      <FleetInput
        value={item?.paymentTerm}
        type="number"
        onChange={(val: number) => {
          onChange?.("paymentTerm", val)
        }}
      />
    </FleetFormItem>
    <FleetFormItem
      containerClassname="cargoInfo-item"
      type="row"
      label={t("voyageManagement.common.paymentStartDay")}
    >
      <FleetDatePicker
        value={item?.paymentStartDay ? dayjs(item?.paymentStartDay) : null}
        onChange={(val: string) => {
          onChange?.("paymentStartDay", val)
        }}
      />
    </FleetFormItem>
  </div>
}

export default MovementCargoInfo;