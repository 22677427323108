import React, { FC, memo } from "react";
import style from "./index.module.less";
import { wavesOptions } from "./source";
import classnames from "classnames";

const LegendWaves: FC<{}> = (props) => {
	return (
		<div className={classnames("routeCommon-legend-item", style["legend-waves"])}>
			<span className="routeCommon-legend-label">Swell / Sig.Wave:</span>
			<ul className="legend-waves-container">
				{wavesOptions?.map((item) => {
					return (
						<li
							key={item?.label}
							style={{
								background: item?.value
							}}
						>
							{item?.label}
						</li>
					);
				})}
			</ul>
		</div>
	);
};

export default memo(LegendWaves);
