import React from "react";
import style from "./index.module.less";
import { DatabaseContactFilterProps } from "./type";
import { FleetCascader, FleetFormItem, FleetInput, FleetVerifyInput } from "@/components";
import NoSelect, { NoSelectOption } from "@/common/NoSelect";
import { contactBusinessOption } from "@/common/Select/components/Contact/source";

const ContactFilter: React.FC<DatabaseContactFilterProps> = ({
  item,
  onChange,
  onSearch
}) => {
  return <div className={style["contact-filter"]}>
    <FleetVerifyInput
      value={undefined}
      prefix="LayoutSearchIcon"
      placeholder="Please enter"
      onChange={onSearch}
      label="Filter"
      containerClassname="first-filter"
    />
    <FleetFormItem
      label="Role"
    >
      <FleetCascader
        value={[item?.businessType]}
        onClear={() => {
          onChange?.("businessType", "");
        }}
        placeholder="Role"
        className="cascader-container other-filter"
        onChange={(val: string[]) => {
          onChange?.("businessType", val?.[0])
        }} options={contactBusinessOption}
      />
    </FleetFormItem>
    <FleetFormItem
      label="Company"
      containerClassname="other-filter"
    >
      <NoSelect
        type="company"
        label="companyName"
        className="other-filter"
        allowClear
        onClear={() => {
          onChange?.("companyName", "")
        }}
        onFinish={(item, newItem, type) => {
          onChange?.("companyName", (item as NoSelectOption)?.value)
        }}
      />
    </FleetFormItem>
    {/* <FleetVerifyInput
      value={undefined}
      prefix="LayoutSearchIcon"
      placeholder="Please enter"
      onChange={onSearch}
      label="Filter"
    />
    <FleetVerifyInput
      value={undefined}
      prefix="LayoutSearchIcon"
      placeholder="Please enter"
      onChange={onSearch}
      label="Filter"
    /> */}
    {/* <span className="filter-label">Filter</span>
    <div className="filter-container">
      <FleetInput
        value={undefined}
        prefix="LayoutSearchIcon"
        placeholder="Please enter"
        onChange={onSearch}
      />
      <FleetCascader
        value={[item?.businessType]}
        onClear={() => {
          onChange?.("businessType", "");
        }}
        placeholder="Role"
        className="cascader-container"
        onChange={(val: string[]) => {
          onChange?.("businessType", val?.[0])
        }} options={companyOption}
      />
      <NoSelect
        type="company"
        label="companyName"
        allowClear
        onClear={() => {
          onChange?.("companyName", "")
        }}
        onFinish={(item, newItem, type) => {
          onChange?.("companyName", (item as NoSelectOption)?.value)
        }}
      />
    </div> */}
  </div>
}

export default ContactFilter