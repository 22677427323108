import { useState } from "react"
import {
  HandoverFormBasicInfoItemType,
  HandoverFormBasicInfoChangeEvent,
  HandoverFormBasicInfoCommitEvent
} from "../type"
import { initialBasicInfo,checkKeys } from "../source";

const useIndex = () => {
  const [dataSource, setDataSource] = useState<HandoverFormBasicInfoItemType>({ ...initialBasicInfo() });

  const change: HandoverFormBasicInfoChangeEvent = (key, value) => {
    setDataSource(prev => ({ ...prev, [key]: value }))
  }

  const commit: HandoverFormBasicInfoCommitEvent = (item) => {
    debugger
    setDataSource(prev => ({ ...prev, ...item }))
  }

  const init: HandoverFormBasicInfoCommitEvent = (item) => {
    commit(initialBasicInfo(item))
  }

  const check = () => {
    let checkKey = "";
    let checked = true;
    for (let key of checkKeys) {
      if (dataSource[key] !== 0 && !dataSource[key]) {
        checkKey = key;
        checked = false;
        break
      }
    }

    return {
      checkKey,
      checked
    }
  }

  return {
    dataSource,
    change,
    commit,
    init,
    check
  }
}

export default useIndex