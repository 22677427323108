import React, { FC, useMemo } from "react";
import style from "./index.module.less";
import { RouteListTabbarProps } from "./type";
import { tabOptions } from "./source";
import classnames from "classnames";

const RouteListTabbar: FC<RouteListTabbarProps> = ({ mode, activeTab, onSelect }) => {
	const options = useMemo(() => {
		const filterTab = mode === "monitoring" ? "area" : "leg";
		return tabOptions?.filter((optionItem) => optionItem?.value !== filterTab);
	}, [mode]);

	const handleSelect: RouteListTabbarProps["onSelect"] = (selectVal) => {
		if (activeTab === selectVal) return;
		onSelect?.(selectVal);
	};

	return (
		<ul className={style["routeCommon-list-tabbar"]}>
			{options?.map((tabItem) => {
				return (
					<li
						className={classnames({
							active: activeTab === tabItem?.value
						})}
						key={tabItem?.value}
						onClick={() => handleSelect?.(tabItem?.value)}
					>
						{tabItem?.label}
					</li>
				);
			})}
		</ul>
	);
};

export default RouteListTabbar;
