import { useState } from "react";
import { MovementVesselBasicsProps } from "../type";
import { initialDataSource } from "../source";

const useIndex = () => {
  const [dataSource, setDataSource] = useState<MovementVesselBasicsProps["item"]>(initialDataSource());

  const change: MovementVesselBasicsProps["onChange"] = (key, value) => {
    setDataSource(prev => {
      return {
        ...prev,
        [key]: value
      }
    })
  }

  const commit: MovementVesselBasicsProps["onCommit"] = (item) => {
    setDataSource(prev => {
      return {
        ...prev,
        ...item
      }
    })
  }

  const init: MovementVesselBasicsProps["onCommit"] = (item) => {
    commit(initialDataSource(item))
  }

  return {
    dataSource,
    change,
    commit,
    init
  }
}

export default useIndex