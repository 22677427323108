import { useCommonIndex } from "@/hook"
import { useState } from "react"
import { InvFreightOtherItemType, InvFreightDialogType, InvFreightDialogChangeEvent } from "../type"
import { initialInvFreightOtherItem } from "../source"

const useOtherIndex = () => {
  const {
    dataSource,
    change,
    init
  } = useCommonIndex<InvFreightOtherItemType>(initialInvFreightOtherItem)

  const [dialogItem, setDialogItem] = useState<InvFreightDialogType>({
    history: true,
    field: true
  })

  const handleDialogChange: InvFreightDialogChangeEvent = (key, value) => {
    setDialogItem(prev => ({ ...prev, [key]: value }))
  }

  return {
    dataSource,
    change,
    init,
    dialogItem,
    handleDialogChange
  }
}

export default useOtherIndex