import React from "react";
import style from "./index.module.less";
import { PerfReportVoyageList } from "./business";

const PerfReport: React.FC<{}> = (props) => {
  return <div className={style["fleet-perfreport"]}>
    <div className="perfreport-warpper">
      <PerfReportVoyageList />
    </div>
  </div>
}

export default PerfReport