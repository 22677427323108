import { ApiParameter, RequestApiMapType } from "@/api";
import request from "@/service";

import {
	getRouteCommonAisCollectionParams,
	updateRouteCommonAisGroupParams,
	getRouteCommonAisGroupParams,
	deleteRouteCommonAisGroupParams,
	updateRouteCommonAisCollectionParams,
	cancleRouteCommonAisCollectionParams,
	RouteCommonAisParamsType
} from "./type";
import { appVersion } from "@/api/constant";

const enum ApiMap {
	getRouteCommonAisCollectionParameter,
	updateRouteCommonAisGroupParameter,
	getRouteCommonAisGroupParameter,
	deleteRouteCommonAisGroupParameter,
	updateRouteCommonAisCollectionParameter,
	cancleRouteCommonAisCollectionParameter
}

export type ApiUrl = keyof typeof ApiMap;

const aisApi: ApiParameter<ApiUrl> = {
	getRouteCommonAisCollectionParameter: {
		method: "get",
		url: appVersion + "/vessel-collect/data"
	},
	updateRouteCommonAisGroupParameter: {
		method: "post",
		url: appVersion + "/vessel-collect/group"
	},
	getRouteCommonAisGroupParameter: {
		method: "get",
		url: appVersion + "/vessel-collect/group/list"
	},
	deleteRouteCommonAisGroupParameter: {
		method: "delete",
		url: appVersion + "/vessel-collect/group"
	},
	updateRouteCommonAisCollectionParameter: {
		method: "post",
		url: appVersion + "/vessel-collect/collect"
	},
	cancleRouteCommonAisCollectionParameter: {
		method: "delete",
		url: appVersion + "/vessel-collect/collect"
	}
};

export const getRouteCommonAisCollectionApi = (params: getRouteCommonAisCollectionParams) => {
	return request.request({
		...aisApi.getRouteCommonAisCollectionParameter
	});
};

export const updateRouteCommonAisGroupApi = (params: updateRouteCommonAisGroupParams) => {
	return request.request({
		...aisApi.updateRouteCommonAisGroupParameter,
		data: params
	});
};

export const getRouteCommonAisGroupApi = (params: getRouteCommonAisGroupParams) => {
	return request.request({
		...aisApi.getRouteCommonAisGroupParameter
	});
};

export const deleteRouteCommonAisGroupApi = (params: deleteRouteCommonAisGroupParams) => {
	return request.request({
		...aisApi.deleteRouteCommonAisGroupParameter,
		url: `${aisApi.deleteRouteCommonAisGroupParameter.url}/${params?.groupId}`
	});
};

export const updateRouteCommonAisCollectionApi = (params: updateRouteCommonAisCollectionParams) => {
	return request.request({
		...aisApi.updateRouteCommonAisCollectionParameter,
		data: params
	});
};

export const cancleRouteCommonAisCollectionApi = (params: cancleRouteCommonAisCollectionParams) => {
	return request.request({
		...aisApi.cancleRouteCommonAisCollectionParameter,
		url: `${aisApi?.cancleRouteCommonAisCollectionParameter?.url}/${params?.imo}`
	});
};

export const aisApiMap: RequestApiMapType<ApiUrl, RouteCommonAisParamsType> = {
	getRouteCommonAisCollectionApi,
	updateRouteCommonAisGroupApi,
	getRouteCommonAisGroupApi,
	deleteRouteCommonAisGroupApi,
	updateRouteCommonAisCollectionApi,
	cancleRouteCommonAisCollectionApi
};
