import React, { FC } from "react";
import style from "./index.module.less";
import { MovementTaskCommentItemProps } from "./type";
import dayjs from "dayjs";
import { Popover } from "@/_components";
import { FleetToast } from "@/common";

const MovementTaskCommentItem: FC<MovementTaskCommentItemProps> = ({
  item,
  onDelete
}) => {
  return <div className={style["movement-commentItem"]}>
    <div className="commentItem-header">
      <div className="commentItem-creater">
        <span>{item?.createUserName}</span>
        <span>{dayjs(item?.createTime)?.format("MM月DD日 HH:mm")}</span>
      </div>
      <div className="commentItem-toolbar">
        <Popover
          children={<span className="font_family fleet-delete toolbar-delete"></span>}
          render={(setIsOpen) => {
            return <FleetToast
              label="Are you sure delete this item?"
              onCancel={() => {
                setIsOpen(false)
              }}
              onConfirm={() => {
                onDelete?.(item?.id)
                setIsOpen(false)
              }}
            />
          }}
        />
      </div>
    </div>
    <span className="commentItem-container">{item?.content}</span>
  </div>
}

export default MovementTaskCommentItem