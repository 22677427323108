import React from "react";
import classnames from "classnames";
import style from "./index.module.less";
import { CommonRoleWrap, FleetPrompt } from "@/common";
import { DatabaseCommonEditProps } from "./type";
import { FleetButton, FleetIcon, FleetLoading } from "@/components";
import { Button } from "@/_components";

const DatabaseCommonEdit: React.FC<DatabaseCommonEditProps> = ({
  hide,
  onClose,
  onEdit,
  children,
  label,
  className,
  loading,
  title,
  sectionName,
}) => {
  console.log(sectionName, "--------------");
  return (
    <FleetPrompt hide={hide} drawer>
      <div className={classnames(style["database-commonEdit"], className)}>
        <FleetLoading loading={loading} />
        <div className="commonEdit-header">
          <div>
            <span>{title}</span>
            <span>{label}</span>
          </div>
          <div>
            <FleetIcon
              type="FleetCloseIcon"
              onClick={onClose}
              outerLayerClassname="header-close"
            />
            <CommonRoleWrap roleStr={`${sectionName}:list:save`}>
              <Button
                onClick={onEdit}
                size="small"
                type="secondary"
                prefixIcon="fleet-save"
              >
                Save
              </Button>
            </CommonRoleWrap>
          </div>
        </div>
        <div className="commonEdit-container fleet-nonScrolBar">
          <div className="commonEdit-scrollArea">{children}</div>
        </div>
      </div>
    </FleetPrompt>
  );
};

export default DatabaseCommonEdit;
