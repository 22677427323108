import { FleetAisData } from "@/common";
import React, { FC, useEffect } from "react";
import { RouteCommonAisLineProps } from "./type";
import style from "./index.module.less";
import useIndex from "./hooks/useIndex";
import classnames from "classnames";
import { FleetFormItem, FleetInput } from "@/components";

const RouteCommonAisLine: FC<RouteCommonAisLineProps> = ({ imo, rangeOption, hide, onClose }) => {
	const { loading, dataSource, handleChange } = useIndex(hide, imo, rangeOption);

	useEffect(() => {
		console.log("imo changes!");
	}, [imo]);

	useEffect(() => {
		console.log("rangeOption changes!");
	}, [rangeOption]);

	return (
		<div
			className={classnames(style["monitoring-aisData"], {
				[style["monitoring-aisData--active"]]: hide
			})}
		>
			<div className="monitoring-aisData--header">
				<div className="header--container">
					<span className="fleet-header">AIS Data</span>
					<FleetFormItem label="CP Speed" type="row" containerClassname="aisData-search">
						<FleetInput
							value={dataSource?.speed}
							type="number"
							onChange={(val: number) => {
								handleChange?.("speed", val);
							}}
							className="aisData-input"
						/>
					</FleetFormItem>
				</div>
				<span className="font_family fleet-close header-close" onClick={onClose}></span>
			</div>
			<FleetAisData
				type="monitoring"
				loading={loading}
				dataSource={dataSource}
				onChange={handleChange}
			/>
		</div>
	);
};

export default RouteCommonAisLine;
