import { Dayjs } from "dayjs";
import { useRef, useState } from "react";
import { openDownloadDialog } from "@/tools";
import { VmRemarkUploadFileType } from "@/featrue/voyagemanage/preOperation/voyage/voyageRemarks/type";
import { RcFile } from "antd/es/upload";
import { useAppDispatch } from "@/hook";
import { useNavigate } from "react-router-dom";
import useReminder from "@/hook/useReminder";
import { uploadRoutePlanAction } from "@/action/monitoring/routePlan";
import { useRouteCommonContext } from "@/pages/routeCommon/store";
import { RoutePlanModalRefType } from "../../modal";

const useIndex = (onReset: () => void) => {
	const dispatch = useAppDispatch();
	const navigate = useNavigate();
	const { reminder } = useReminder();
	const [hide, setHide] = useState(true);
	const [loading, setLoading] = useState(false);
	const { activeLegVessel } = useRouteCommonContext();
	const [activeDownload, setActiveDownload] = useState<"a" | "b">("a");
	const [planType, setPlanType] = useState("Master");
	const [planTime, setPlanTime] = useState<Dayjs | null>(null);

	const uploadStatus = useRef<"uploading" | "static">("static");
	const [filelist, setFilelist] = useState<VmRemarkUploadFileType[]>([]);

	const handleChange = (type: "a" | "b") => {
		if (type === activeDownload) return;
		setActiveDownload(type);
	};

	const handleDownloadTemplate = async () => {
		setLoading(true);
		await openDownloadDialog(
			activeDownload === "a"
				? "https://voy-public-resource.s3.ap-east-1.amazonaws.com/waypoint-temp/NavigationWaypointTemplate1.xlsx"
				: "https://voy-public-resource.s3.ap-east-1.amazonaws.com/waypoint-temp/NavigationWaypointTemplate2.xlsx",
			""
		);
		setLoading(false);
	};

	const handleUpload = (file: RcFile) => {
		if (filelist?.length > 0) return;
		console.log("file", file);
		setFilelist([file]);
	};

	const handleFileDelete = (id: string | number) => {
		console.log("uid", id, filelist);
		setFilelist((prev) => {
			const index = filelist.findIndex((item) => item.uid === id);
			if (index !== -1) {
				prev.splice(index, 1);
			}
			return [...prev];
		});
	};

	const uploadRoutePlanFront = () => {
		setLoading(true);
	};

	const uploadRoutePlanSuccess = (response) => {
		setLoading(false);
		reminder("success", response?.msg);
		closeModal();
		onReset?.();
	};

	const uploadRoutePlanFailed = (error) => {
		reminder("error", error?.msg ? error?.msg + ": " + error?.data : error?.data);
		setLoading(false);
	};

	const uploadRoutePlan = () => {
		const formData = new FormData();
		formData.append("file", filelist?.[0] as unknown as File);
		uploadRoutePlanAction(
			{
				file: formData,
				planType,
				voyageNo: activeLegVessel?.voyageNo,
				legType: activeLegVessel?.vesselStatus,
				etd: planTime?.valueOf()
			},
			uploadRoutePlanFront,
			uploadRoutePlanSuccess,
			uploadRoutePlanFailed,
			dispatch,
			navigate
		);
	};

	const handleConfirm = () => {
		if (filelist?.length === 0) {
			reminder("warning", "Please upload a file!");
			return;
		}
		uploadRoutePlan();
	};

	const openModal: RoutePlanModalRefType<null>["openModal"] = (item) => {
		setHide(false);
	};

	const closeModal = () => {
		setFilelist([]);
		setActiveDownload("a");
		setPlanTime(null);
		setPlanType("Master");
		setHide(true);
	};

	return {
		hide,
		loading,
		activeDownload,
		planType,
		planTime,
		uploadStatus,
		filelist,
		setPlanTime,
		setPlanType,
		handleChange,
		handleUpload,
		handleFileDelete,
		handleDownloadTemplate,
		handleConfirm,
		openModal,
		closeModal
	};
};

export default useIndex;
