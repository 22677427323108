import { useState } from "react"
import { MovementCargoProps } from "../type"

const useIndex = () => {
  const [dataSource, setDataSource] = useState<MovementCargoProps["actualItems"]>([]);

  const change: MovementCargoProps["onActualChange"] = (currentIndex, key, value) => {
    setDataSource(prev => {
      const currentItem = prev?.[currentIndex];
      prev?.splice(currentIndex, 1, {
        ...currentItem,
        [key]: value
      })
      return [...prev]
    })
  }

  const commit: MovementCargoProps["onActualCommit"] = (currentIndex, item) => {
    setDataSource(prev => {
      const currentItem = prev?.[currentIndex];
      prev?.splice(currentIndex, 1, {
        ...currentItem,
        ...item
      })
      return [...prev]
    })
  }

  const init = (items: MovementCargoProps["actualItems"]) => {
    setDataSource(items ?? [])
  }

  return {
    dataSource,
    change,
    commit,
    init
  }
}

export default useIndex