import React, { FC } from "react";
import classnames from "classnames";
import style from "./index.module.less";
import { PreOperationVoyageItemProps } from "./type";
import { formatThousandthNumber } from "@/tools/amount";
import dayjs from "dayjs";
import { Icon, Popover } from "@/_components";
import { FleetToast } from "@/common";
import { useAppSelector } from "@/hook";

const PreOperationVoyageItem: FC<PreOperationVoyageItemProps> = ({
	item,
	activeIndex,
	currentIndex,
	onSelect,
	onDelete
}) => {
	const { userInfo } = useAppSelector((selector) => selector.UserStore);
	return (
		<div
			className={classnames(style["preOperation-voyageItem"], {
				[style["preOperation-voyageItem--active"]]: activeIndex === item?.id
			})}
			onClick={() => onSelect?.(currentIndex, item)}
		>
			<div className="voyageItem-container voyageItem--userInfo">
				<div className="user-info">
					<span className="font_family fleet-uselaber"></span>
					<span>{item?.createUserName}</span>
				</div>
				{/* <span>{dayjs(item?.createTime)?.format("YYYY/MM/DD hh:mm")}</span> */}
				<div
					className="voyageItem-operator"
					onClick={(event) => {
						event?.stopPropagation();
					}}
				>
					{item?.voyageType && (
						<span className={classnames("voyageItem-voyageType", item?.voyageType)}>
							{item?.voyageType}
						</span>
					)}
					<Popover
						trigger="click"
						disabled={userInfo?.userId != item?.createUser}
						// isStopPropagation
						render={(setIsOpen) => {
							return (
								<FleetToast
									label="That item will be deleted. Are you sure?"
									onCancel={() => {
										setIsOpen(false);
									}}
									onConfirm={() => {
										setIsOpen(false);
										onDelete?.(currentIndex);
									}}
								/>
							);
						}}
						children={
							<Icon
								type="fleet-delete"
								className={classnames("operator-delete", {
									"fleet-disabled": userInfo?.userId != item?.createUser
								})}
							/>
						}
					/>
				</div>
			</div>
			<div className="voyageItem-container">
				<span>Create Time</span>
				<span>{dayjs(item?.createTime)?.format("YYYY/MM/DD hh:mm")}</span>
			</div>
			<div className="voyageItem-container">
				<span>{item?.ownerName}</span>
				<span>{item?.charaByName}</span>
			</div>
			<div className="voyageItem-container voyageItem--voyageInfo">
				<span>{item?.vesselName}</span>
				<span>{item?.cargoName}</span>
				<span>{`${formatThousandthNumber(+item?.cargoQuantity)} ${item?.cargoUnit ?? ""}`} </span>
			</div>
		</div>
	);
};

export default PreOperationVoyageItem;
