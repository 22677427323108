import {
  type ParameterType
} from "@/api";
import { loginOutAction } from "@/store/userSlice";

import {
  deleteVesselArchiveParams,
  getVesselArchiveGroupParams,
  queryVesselArchiveForImoParams,
  queryVesselArchiveForMmsiParams,
  queryVesselArchiveGroupParams,
  updateVesselArchivePaload,
  deleteVesselArchiveApi,
  getVesselArchiveGroupApi,
  queryVesselArchiveForImoApi,
  queryVesselArchiveForMmsiApi,
  queryVesselArchiveGroupApi,
  updateVesselArchiveApi
} from "@/api/dataBase/vesselArchives";

export const updateVesselArchiveAction: ParameterType<updateVesselArchivePaload> = (
  params,
  frontCallback,
  successCallback,
  errorCallback,
  dispatch,
  navigate
) => {
  frontCallback?.();
  updateVesselArchiveApi(params).then(response => {
    successCallback?.(response)
  }).catch(err => {
    errorCallback?.(err);
    if (err?.loginStatus === 'token faild') {
      dispatch(loginOutAction(() => navigate?.("/login")))
    }
  })
}

export const deleteVesselArchiveAction: ParameterType<deleteVesselArchiveParams> = (
  params,
  frontCallback,
  successCallback,
  errorCallback,
  dispatch,
  navigate
) => {
  frontCallback?.();
  deleteVesselArchiveApi(params).then(response => {
    successCallback?.(response)
  }).catch(err => {
    errorCallback?.(err);
    if (err?.loginStatus === 'token faild') {
      dispatch(loginOutAction(() => navigate?.("/login")))
    }
  })
}

export const queryVesselArchiveForImoAction: ParameterType<queryVesselArchiveForImoParams> = (
  params,
  frontCallback,
  successCallback,
  errorCallback,
  dispatch,
  navigate
) => {
  frontCallback?.();
  queryVesselArchiveForImoApi(params).then(response => {
    successCallback?.(response)
  }).catch(err => {
    errorCallback?.(err);
    if (err?.loginStatus === 'token faild') {
      dispatch(loginOutAction(() => navigate?.("/login")))
    }
  })
}

export const queryVesselArchiveForMmsiAction: ParameterType<queryVesselArchiveForMmsiParams> = (
  params,
  frontCallback,
  successCallback,
  errorCallback,
  dispatch,
  navigate
) => {
  frontCallback?.();
  queryVesselArchiveForMmsiApi(params).then(response => {
    successCallback?.(response)
  }).catch(err => {
    errorCallback?.(err);
    if (err?.loginStatus === 'token faild') {
      dispatch(loginOutAction(() => navigate?.("/login")))
    }
  })
}

export const getVesselArchiveGroupAction: ParameterType<getVesselArchiveGroupParams> = (
  params,
  frontCallback,
  successCallback,
  errorCallback,
  dispatch,
  navigate
) => {
  frontCallback?.();
  getVesselArchiveGroupApi(params).then(response => {
    successCallback?.(response)
  }).catch(err => {
    errorCallback?.(err);
    if (err?.loginStatus === 'token faild') {
      dispatch(loginOutAction(() => navigate?.("/login")))
    }
  })
}

export const queryVesselArchiveGroupAction: ParameterType<queryVesselArchiveGroupParams> = (
  params,
  frontCallback,
  successCallback,
  errorCallback,
  dispatch,
  navigate
) => {
  frontCallback?.();
  queryVesselArchiveGroupApi(params).then(response => {
    successCallback?.(response)
  }).catch(err => {
    errorCallback?.(err);
    if (err?.loginStatus === 'token faild') {
      dispatch(loginOutAction(() => navigate?.("/login")))
    }
  })
}