import { useState } from "react";
import { vesselIconMap } from "../source";
import MonitoringTrackPointIcon from "static/icons/monitoring_track_point.svg";
import MonitoringVmIconHover from "static/icons/monitoring_vm_icon_hover.svg";
import { getTrackPoints } from "../utils/tool";
import useReminder from "@/hook/useReminder";
import { MonitoringMapVesselItemType, MonitoringMapVesselPropertiesType } from "../type";
import { getVesselIcon } from "../tools";

const L = window?.L;

const getRepeatLng = (e) => {
	e.latlng.lng = e.latlng.lng - Math.floor(e.latlng.lng / 180) * 180;

	let positionLng = e.latlng.lng;
	if (e.o_latlng.lng < 0 && e.o_latlng.lng > -180) {
		positionLng = e.latlng.lng - 180;
	} else if (e.o_latlng.lng < -180 && e.o_latlng.lng > -360) {
		positionLng = e.latlng.lng - 360;
	} else if (e.o_latlng.lng > 180 && e.o_latlng.lng < 360) {
		positionLng = e.latlng.lng + 180;
	} else if (e.o_latlng.lng < -360 && e.o_latlng.lng > -540) {
		positionLng = e.latlng.lng - 540;
	} else if (e.o_latlng.lng > 360 && e.o_latlng.lng < 540) {
		positionLng = e.latlng.lng + 360;
	} else if (e.o_latlng.lng > 540 && e.o_latlng.lng < 720) {
		positionLng = e.latlng.lng + 540;
	} else if (e.o_latlng.lng < -540 && e.o_latlng.lng > -720) {
		positionLng = e.latlng.lng - 720;
	} else if (e.o_latlng.lng < -720) {
		positionLng = e.latlng.lng - 720;
	} else if (e.o_latlng.lng > 720) {
		positionLng = e.latlng.lng + 720;
	}

	return positionLng;
};

const useCreateVessels = (
	layersType: "ais" | "vm" | "area" | "port",
	trackLayers: any,
	meteoCore: any,
	onTrackLayersAdd: (trackPoints: any[], rest: [string, string, string]) => void
) => {
	const { reminder } = useReminder();
	const [dataSource, setDataSource] = useState<any[]>([]);
	const [vectorLayer, setVectorLayer] = useState(null);
	const [currentVessel, setCurrentVessel] = useState(null);

	const createVmVesselsLayer = (
		geometrys: GeoJSON.FeatureCollection<GeoJSON.Point, MonitoringMapVesselPropertiesType>,
		layers
	) => {
		console.log("GridLayer", L, window.L);
		const vectorLayer = L.GridLayer.vectorGrid
			.slicer(geometrys, {
				rendererFactory: L.svg.tile,
				pane: "featuresPane",
				style: {
					overflow: "visible",
					pointerEvents: "none"
				},
				vectorTileLayerStyles: {
					sliced: function (properties: MonitoringMapVesselPropertiesType, zoom) {
						console.log("vectorTileLayerStyles", properties, geometrys);
						switch (properties.type) {
							case "vesselIcon":
								return {
									icon: L.icon({
										iconUrl: getVesselIcon(layersType, ""),
										iconSize: [39, 39],
										rotationAngle: properties.cog,
										className: "vmVesselIcon",
										overlap: false
									})
								};
							case "vmVesselIconChecked":
								return {
									icon: L.icon({
										iconUrl: getVesselIcon(layersType, "Selected"),
										iconSize: [40, 40],
										rotationAngle: properties.cog,
										className: "vmVesselIconChecked",
										overlap: false
									})
								};
						}
					}
				},
				interactive: true,
				getFeatureId: function (featrue) {
					return featrue.properties.imo;
				}
			})
			.addTo(layers);

		const vesselInfoPopup = L.popup({
			closeButton: false,
			offset: [0, -7]
		});
		const hoverDiv = L.SVG.create("image");
		hoverDiv.setAttribute("width", 40 + "px"),
			hoverDiv.setAttribute("height", 40 + "px"),
			hoverDiv.setAttribute("href", getVesselIcon(layersType, "", "vesselHoveredIcon"));
		vectorLayer.on("mouseover", function (e) {
			var properties = e.layer.properties;
			var hoverDivOffset = 1;
			if (properties.imo == vectorLayer.checkedVessel) {
				hoverDivOffset = 0;
			}
			const positionLat = e.latlng.lat;
			const positionLng = getRepeatLng(e);

			console.log("mouseover", e.layer.properties);

			hoverDiv.style.display = "block";
			hoverDiv.setAttribute(
				"x",
				parseFloat(e.sourceTarget._path.getAttribute("x")) - hoverDivOffset
			),
				hoverDiv.setAttribute(
					"y",
					parseFloat(e.sourceTarget._path.getAttribute("y")) - hoverDivOffset
				),
				e.sourceTarget._path.parentNode.insertBefore(hoverDiv, e.sourceTarget._path);
			// hoverDiv.setAttribute("transform", `rotate(${e.layer.properties?.cog})`);
			console.log("properties");

			properties.type === "vesselIcon" &&
				vesselInfoPopup
					.setContent(
						'<div class="polyline-measure-tooltip leaflet-zoom-animated" tabindex="0" role="button" style="box-shadow: none;margin-left: 4px; margin-top: 4px; width: 12px; height: 12px; z-index: 210;padding: 0;background: none">' +
							'<div style="display: flex; column-gap: 10px"><div class="polyline-measure-tooltip-label" style="color: #C8C8C8;font-size: 12px;font-weight: 400">Voyage No:</div>' +
							layersType ===
							"vm"
							? `<div class="polyline-measure-tooltip-position" style="color: #fff;font-size: 12px;font-weight: 400">${properties.voyageNo}</div></div>`
							: "" +
									'<div style="display: flex; column-gap: 10px"><div class="polyline-measure-tooltip-label" style="color: #C8C8C8;font-size: 12px;font-weight: 400">Vessel Name:</div>' +
									"<div>" +
									'<span class="polyline-measure-tooltip-position" style="color: #fff;font-size: 12px;font-weight: 400">' +
									properties.vesselName +
									"</span>" +
									"</div></div>" +
									'<div style="display: flex; column-gap: 10px"><div class="polyline-measure-tooltip-label" style="color: #C8C8C8;font-size: 12px;font-weight: 400">Vessel Status:</div>' +
									"<div>" +
									'<span class="polyline-measure-tooltip-position" style="color: #fff;font-size: 12px;font-weight: 400">' +
									properties.vesselStatus +
									"</span>" +
									"</div></div>" +
									"</div>"
					)
					.setLatLng([positionLat, positionLng])
					.openOn(layers);
		});
		vectorLayer.on("mouseout", function (e) {
			vesselInfoPopup.removeFrom(layers);
			hoverDiv.style.display = "none";
		});
		console.log("create complete!");
		vectorLayer.geometryParam = geometrys;
		return vectorLayer;
	};

	const resetTrackLayers = () => {
		setCurrentVessel(null);
		trackLayers?.clearLayers?.();
		const params: [string, string, string] =
			layersType == "vm"
				? [
						"vmVesselTrackPointLineLayer",
						"vmVesselTrackPointsLayer",
						"vmVesselTrackPointLabelLayer"
					]
				: layersType === "area"
					? [
							"areaVesselTrackPointLineLayer",
							"areaVesselTrackPointsLayer",
							"areaVesselTrackPointLabelLayer"
						]
					: layersType === "port"
						? [
								"portVesselTrackPointLineLayer",
								"portVesselTrackPointsLayer",
								"portVesselTrackPointLabelLayer"
							]
						: [
								"aisVesselTrackPointLineLayer",
								"aisVesselTrackPointsLayer",
								"aisVesselTrackPointLabelLayer"
							];
		onTrackLayersAdd([], params);
	};

	console.log("currentVessel", currentVessel, currentVessel);

	const loadVesselGroup = (layers: any, vesselGroup: MonitoringMapVesselItemType[]) => {
		const geometrys = {
			type: "FeatureCollection",
			features: []
		};
		console.log("vesselGroup", vesselGroup, currentVessel);
		// 提取单点 GeoJSON 数组中的坐标，并添加到多点 GeoJSON 的坐标数组中
		vesselGroup?.forEach((vesselItem, index) => {
			vesselItem.feature &&
				((vesselItem.feature.properties = Object.assign({}, vesselItem.feature.properties, {
					vesselName: vesselItem.vesselName,
					voyageNo: vesselItem.voyageNo,
					imo: vesselItem.imo,
					_latlng: {
						lat: vesselItem.feature.geometry.coordinates[1],
						lng: vesselItem.feature.geometry.coordinates[0]
					},
					type: currentVessel?.imo === vesselItem.imo ? "vmVesselIconChecked" : "vesselIcon",
					vesselStatus: vesselItem.type,
					id: vesselItem.id
				})),
				geometrys.features.push(vesselItem.feature));
		});
		layers.clearLayers();

		// 当currentvessel存在时，清除 ais 轨迹
		if (
			["ais", "area", "port"]?.includes(layersType) &&
			!vesselGroup?.find((vessel) => currentVessel?.imo === vessel.imo)
		) {
			resetTrackLayers();
		}

		const newVectorLayer = createVmVesselsLayer(geometrys, layers);
		setVectorLayer(newVectorLayer);
		setDataSource(vesselGroup);
	};

	const loadTrackLayers = (layers: any, vesselProperties, geometrys) => {
		console.log("vesselProperties", vesselProperties);
		layers.clearLayers();
		if (geometrys.features.length > 0) {
			// if (loadVesselTrack) {
			L.GridLayer.vectorGrid
				.slicer(geometrys, {
					rendererFactory: L.svg.tile,
					pane: "overlayPane",
					style: {
						overflow: "visible",
						pointerEvents: "none"
					},
					vectorTileLayerStyles: {
						sliced: function (properties, zoom) {
							switch (properties.type) {
								case "trackLine":
									return {
										weight: 1.5,
										color: "rgba(53,86,145,0.75)"
									};
								case "trackPoint":
									return {
										icon: L.icon({
											iconUrl: MonitoringTrackPointIcon,
											iconSize: [15, 33],
											rotationAngle: properties.cog,
											className: "vmTrackPoint"
										})
									};
								case "vesselIconChecked":
									return {
										icon: L.icon({
											iconUrl: vesselIconMap.vmVesselCheckedIcon,
											iconSize: [40, 40],
											rotationAngle: vesselProperties.cog,
											className: "vmVesselIconChecked",
											overlap: false
										})
									};
							}
						}
					},
					interactive: true,
					getFeatureId: function (f) {
						return f.properties.id;
					}
				})
				.addTo(trackLayers);
			const bounds = L.geoJson(geometrys).getBounds();
			meteoCore?.map?.fitBounds(bounds, {
				maxZoom: 5
			});
		} else {
			reminder("warning", "No track data!");
		}
	};

	const clearNotFollowVesselAndTrack = (vesselLayers: any, vesselTrackLayers: any) => {
		const newVectorLayer = createVmVesselsLayer(
			{
				type: "FeatureCollection",
				features: []
			},
			vesselLayers
		);
		setVectorLayer(newVectorLayer);
		vesselLayers?.clearLayers();
		trackLayers?.clearLayers?.();
		const params: [string, string, string] =
			layersType == "vm"
				? [
						"vmVesselTrackPointLineLayer",
						"vmVesselTrackPointsLayer",
						"vmVesselTrackPointLabelLayer"
					]
				: [
						"aisVesselTrackPointLineLayer",
						"aisVesselTrackPointsLayer",
						"aisVesselTrackPointLabelLayer"
					];
		onTrackLayersAdd([], params);
	};

	const loadNotFollowVesselAndTrack = (
		vesselLayers: any,
		trackLayers: any,
		tracks: {
			properties: any;
			ais: any;
			trackPoints: any[];
		}
	) => {
		const { properties: vesselProperties, ais, trackPoints } = tracks;
		console.log("tracks", tracks);
		vesselLayers?.clearLayers();

		if (vesselProperties) {
			const geometry = {
				type: "FeatureCollection",
				features: [
					{
						type: "Feature",
						properties: {
							cog: vesselProperties?.course,
							vesselName: vesselProperties?.vesselName,
							voyageNo: vesselProperties?.voyageNo,
							imo: vesselProperties?.imo,
							_latlng: {
								lat: vesselProperties?.lat,
								lng: vesselProperties?.lon
							},
							type: "vmVesselIconChecked",
							vesselStatus: "Ballast",
							id: vesselProperties?.id
						},
						geometry: {
							type: "Point",
							coordinates: [vesselProperties?.lon, vesselProperties?.lat]
						}
					}
				]
			};
			const newVmVectorLayer = createVmVesselsLayer(geometry, vesselLayers);
			newVmVectorLayer.checkedVessel = vesselProperties.imo;
			newVmVectorLayer.on("load", () => {
				vectorLayer?.removeFrom(vesselLayers);
				// setVmVectorLayer(newVmVectorLayer)
				setVectorLayer(newVmVectorLayer);
			});
		}
		const geometrys = {
			type: "FeatureCollection",
			features: []
		};
		const trackLineFeature = ais?.features[0];
		if (trackLineFeature) {
			trackLineFeature.properties.type = "trackLine";
			const coors = trackLineFeature.geometry.coordinates[0];
			const trackPoints = getTrackPoints(coors);
			const newestPosition = {
				type: "Feature",
				geometry: {
					type: "Point",
					coordinates: coors[0]
					// "coordinates": [
					//   vesselProperties?.lon,
					//   vesselProperties?.lat
					// ]
				},
				properties: {
					type: "vesselIconChecked",
					cog: 0
				}
			};
			geometrys.features.push(...trackPoints);
			geometrys.features.push(trackLineFeature);
			// geometrys.features.push(newestPosition)
		}
		console.log("geometrys", {
			geometrys,
			trackLineFeature
		});
		loadTrackLayers(trackLayers, vesselProperties, geometrys);
		const params: [string, string, string] =
			layersType == "vm"
				? [
						"vmVesselTrackPointLineLayer",
						"vmVesselTrackPointsLayer",
						"vmVesselTrackPointLabelLayer"
					]
				: [
						"aisVesselTrackPointLineLayer",
						"aisVesselTrackPointsLayer",
						"aisVesselTrackPointLabelLayer"
					];
		onTrackLayersAdd(trackPoints ?? [], params);
	};

	const loadVesselAndTrack = (
		vesselLayers: any,
		trackLayers: any,
		tracks: {
			properties: any;
			ais: any;
			trackPoints: any[];
		}
	) => {
		const { properties: vesselProperties, ais, trackPoints } = tracks;
		vesselLayers?.clearLayers();
		console.log("mapStatus", tracks, vectorLayer?.geometryParam);
		if (vesselProperties) {
			// vmVectorLayer.checkedVessel && vmVectorLayer.resetFeatureStyle(vmVectorLayer.checkedVessel)
			const geometryParamNew = vectorLayer?.geometryParam;
			geometryParamNew.features = vectorLayer.geometryParam?.features.map((item) => {
				console.log("mapStatus", tracks, vesselProperties, item);
				if (item.properties?.imo === vesselProperties.imo) {
					item.properties.type = "vmVesselIconChecked";
					item.properties.cog = vesselProperties.course;
					item.properties._latlng = {
						lat: vesselProperties?.lat,
						lng: vesselProperties?.lon
					};
					item.geometry.coordinates = [vesselProperties?.lon, vesselProperties?.lat];
				} else {
					item.properties.type = "vesselIcon";
				}
				return item;
			});
			console.log("geometryParamNew", geometryParamNew);
			setCurrentVessel(vesselProperties);
			const newVmVectorLayer = createVmVesselsLayer(geometryParamNew, vesselLayers);
			newVmVectorLayer.checkedVessel = vesselProperties.imo;
			newVmVectorLayer.on("load", () => {
				vectorLayer?.removeFrom(vesselLayers);
				// setVmVectorLayer(newVmVectorLayer)
				setVectorLayer(newVmVectorLayer);
			});
			// vmVectorLayer.setFeatureStyle(vesselProperties.imo, {
			//   icon: L.icon({
			//     iconUrl: MonitoringVesselChecked,
			//     iconSize: [40, 40],
			//     rotationAngle: vesselProperties.cog,
			//     className: 'vmVesselIconChecked',
			//     overlap: false
			//   })
			// });
			// vmVectorLayer.redraw()
		}
		const geometrys = {
			type: "FeatureCollection",
			features: []
		};
		const trackLineFeature = ais?.features[0];
		if (trackLineFeature) {
			trackLineFeature.properties.type = "trackLine";
			const coors = trackLineFeature.geometry.coordinates[0];
			const trackPoints = getTrackPoints(coors);
			const newestPosition = {
				type: "Feature",
				geometry: {
					type: "Point",
					coordinates: coors[0]
				},
				properties: {
					type: "vesselIconChecked",
					cog: 0
				}
			};
			geometrys.features.push(...trackPoints);
			geometrys.features.push(trackLineFeature);
			// geometrys.features.push(newestPosition)
		}
		loadTrackLayers(trackLayers, vesselProperties, geometrys);
		const params: [string, string, string] =
			layersType == "vm"
				? [
						"vmVesselTrackPointLineLayer",
						"vmVesselTrackPointsLayer",
						"vmVesselTrackPointLabelLayer"
					]
				: layersType === "area"
					? [
							"areaVesselTrackPointLineLayer",
							"areaVesselTrackPointsLayer",
							"areaVesselTrackPointLabelLayer"
						]
					: layersType === "port"
						? [
								"portVesselTrackPointLineLayer",
								"portVesselTrackPointsLayer",
								"portVesselTrackPointLabelLayer"
							]
						: [
								"aisVesselTrackPointLineLayer",
								"aisVesselTrackPointsLayer",
								"aisVesselTrackPointLabelLayer"
							];
		onTrackLayersAdd(trackPoints ?? [], params);
	};

	const clearCurrentVesselAndTrack = (vesselLayers: any, vesselTrackLayers: any) => {
		const geometryParamNew = vectorLayer.geometryParam;
		geometryParamNew.features = vectorLayer.geometryParam?.features.map((item) => {
			item.properties.type = "vesselIcon";
			return item;
		});
		const newVmVectorLayer = createVmVesselsLayer(geometryParamNew, vesselLayers);
		newVmVectorLayer.on("load", () => {
			vectorLayer?.removeFrom(vesselLayers);
			setVectorLayer(newVmVectorLayer);
		});
		vesselTrackLayers?.clearLayers();
		const params: [string, string, string] =
			layersType == "vm"
				? [
						"vmVesselTrackPointLineLayer",
						"vmVesselTrackPointsLayer",
						"vmVesselTrackPointLabelLayer"
					]
				: layersType === "area"
					? [
							"areaVesselTrackPointLineLayer",
							"areaVesselTrackPointsLayer",
							"areaVesselTrackPointLabelLayer"
						]
					: layersType === "port"
						? [
								"portVesselTrackPointLineLayer",
								"portVesselTrackPointsLayer",
								"portVesselTrackPointLabelLayer"
							]
						: [
								"aisVesselTrackPointLineLayer",
								"aisVesselTrackPointsLayer",
								"aisVesselTrackPointLabelLayer"
							];
		onTrackLayersAdd([], params);
	};

	return {
		currentVessel,
		dataSource,
		vectorLayer,
		loadVesselGroup,
		loadVesselAndTrack,
		clearCurrentVesselAndTrack,
		loadNotFollowVesselAndTrack,
		clearNotFollowVesselAndTrack
	};
};

export default useCreateVessels;
