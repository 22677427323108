import React, { FC } from "react";
import classnames from "classnames";
import commonStyle from "../../../../index.module.less"
import style from "./index.module.less";
import { CommonCompanyPopupProps } from "./type";
import { FleetLoading } from "@/components";

const CommonCompanyPopup: FC<CommonCompanyPopupProps> = ({
  list,
  loading,
  onSelect,
  loadMore
}) => {
  const handleSelect = (e: React.MouseEvent<HTMLUListElement, MouseEvent>, item: CommonCompanyPopupProps["list"][number]) => {
    e?.stopPropagation()
    onSelect?.(item)
  }
  return <div className={classnames(commonStyle["common-select--Popup"], style["common-companySelect--Popup"])}>
    <FleetLoading loading={loading} />
    <ul className="select--popup--header">
      <li>Company Name</li>
      <li>Company Address</li>
      <li>Company Website</li>
    </ul>
    <div className="select--popup--container fleet-nonScrolBar">
      <div className="select--popup--scrollArea">
        {
          list?.map((item, index) => {
            return <ul key={index} className="select--popup--item" onClick={(e) => {
              handleSelect?.(e, item)
            }}>
              <li>{item?.companyName}</li>
              <li>{item?.companyAddress}</li>
              <li>{item?.companyWebsite}</li>
            </ul>
          })
        }
      </div>
    </div>
    <div className="select--popup--loadMore" onClick={loadMore}>
      <span>Load More</span>
      <span className="font_family fleet-back"></span>
    </div>
  </div>
}

export default CommonCompanyPopup