import { useState } from "react";
import { initialMovementTcvcOtherItem } from "../source";
import {
	MovementTcvcOtherItemType,
	MovementTcvcOtherCommitEvent,
	MovementTcvcOtherChangeEvent
} from "../type";

const useIndex = () => {
	const [dataSource, setDataSource] = useState<MovementTcvcOtherItemType>(
		initialMovementTcvcOtherItem()
	);

	const change: MovementTcvcOtherChangeEvent = (key, value) => {
		setDataSource((prev) => ({ ...prev, [key]: value }));
	};

	const commit: MovementTcvcOtherCommitEvent = (item) => {
		setDataSource((prev) => ({ ...prev, ...item }));
	};

	const init: MovementTcvcOtherCommitEvent = (item) => {
		setDataSource(initialMovementTcvcOtherItem(item));
	};

	return {
		dataSource,
		change,
		commit,
		init
	};
};

export default useIndex;
