import {
  loginApi,
  loginParams,
  loginSysApi,
  logoutSysApi
} from "@/api/login";

import {
  type ParameterType
} from "@/api";
import { loginOut } from "@/store/userSlice";

export const loginAction: ParameterType<loginParams> = (
  params,
  frontCallback,
  successCallback,
  errorCallback,
  dispatch,
  navigate
) => {
  frontCallback?.();
  loginApi(params).then(response => {
    successCallback?.(response)
  }).catch(err => {
    errorCallback?.(err)
  })
}

export const loginSysAction: ParameterType<{ username: string; password: string }> = (
  params,
  frontCallback,
  successCallback,
  errorCallback,
  dispatch,
  navigate
) => {
  frontCallback?.();
  loginSysApi(params).then(response => {
    successCallback?.(response)
  }).catch(err => {
    errorCallback?.(err)
  })
}

export const logoutSysAction: ParameterType<null> = (
  params,
  frontCallback,
  successCallback,
  errorCallback,
  dispatch,
  navigate
) => {
  frontCallback?.();
  logoutSysApi().then(response => {
    successCallback?.(response)
    dispatch(loginOut({}))
    navigate("/login")
  }).catch(err => {
    errorCallback?.(err)
  })
}