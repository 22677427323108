import toast from "react-hot-toast";
import { clsx, type ClassValue } from "clsx";
import { twMerge } from "tailwind-merge";

export function cn(...inputs: ClassValue[]) {
	return twMerge(clsx(inputs));
}

export const uuid = () => {
	var s = [];
	var hexDigits = "0123456789abcdef";
	for (var i = 0; i < 36; i++) {
		s[i] = hexDigits.substr(Math.floor(Math.random() * 0x10), 1);
	}
	s[14] = "4"; // bits 12-15 of the time_hi_and_version field to 0010
	s[19] = hexDigits.substr((s[19] & 0x3) | 0x8, 1); // bits 6-7 of the clock_seq_hi_and_reserved to 01
	s[8] = s[13] = s[18] = s[23] = "-";

	var uuid = s.join("");
	return uuid;
};

export const Capitalize = <T extends string>(str: T): Capitalize<T> => {
	return (str[0].toUpperCase() + str.slice(1)) as Capitalize<T>;
};

export const message = {
	success: (text: string) => {
		toast.success(text, {
			position: "top-right",
			duration: 2000
		});
	},
	error: (text: string) => {
		toast.error(text, {
			position: "top-right",
			duration: 2000
		});
	},
	loading: (text: string) => {
		toast.loading(text, {
			position: "top-right"
		});
	},
	dismiss: (messageId: any) => {
		toast.dismiss(messageId);
	}
};

export const toThousands = (num) => {
	var num = (num || 0).toString(),
		result = "",
		suffix = "";
	let num_str = num.split(".");
	num = num_str?.[0];
	suffix = (num_str?.[1] ?? "") + "00";
	while (num.length > 3) {
		result = "," + num.slice(-3) + result;
		num = num.slice(0, num.length - 3);
	}
	if (num) {
		result = num + result + `.${suffix.slice(0, 2)}`;
	}
	if (/^(-,{1}|,{1}).+/.test(result)) {
		result = result.replace(",", "");
	}
	return result;
};

export const range = (start, stop, step) =>
	Array.from({ length: (stop - start) / step + 1 }, (_, i) => start + i * step);

export const _range = (minY: number, maxY: number) => {
	const range = maxY - minY;
	const step = range / 4;

	let res = minY;
	let resArr = [];
	while (res < maxY) {
		resArr.push(res);
		res = +(res + step).toFixed(2);
	}
	console.log("resArr", resArr);

	if (resArr[resArr.length - 1] < maxY) {
		resArr[resArr.length - 1] = maxY;
	}

	return resArr;
};

export function getRandomColor() {
	var colorArray = [
		"#68bdf6",
		"#6dce9e",
		"#faafc2",
		"#f2baf6",
		"#ff928c",
		"#fcea7e",
		"#ffc766",
		"#405f9e",
		"#a5abb6",
		"#78cecb",
		"#b88cbb",
		"#ced2d9",
		"#e84646",
		"#fa5f86",
		"#ffab1a",
		"#fcda19",
		"#797b80",
		"#c9d96f",
		"#47991f",
		"#70edee",
		"#ff75ea"
	];

	var randomIndex = Math.floor(Math.random() * colorArray.length);
	return colorArray[randomIndex];
}

export const transform2lnglat = (dm: string = ""): number => {
	const dmList = dm.split("°");
	let lnglat = parseFloat(dmList[0]);
	const decimal = parseFloat(dmList[1]) / 60;
	lnglat = lnglat + decimal;
	return lnglat;
};
export const transform2DM = (latlong: number = 0): string => {
	let dm = "";
	if (latlong) {
		latlong = Math.abs(latlong);
		const degrees = Math.trunc(latlong);
		const decimal = latlong - degrees;
		const minutes = (decimal * 60).toFixed(3);
		dm = `${degrees}°${minutes}`;
		// const minutes = Math.trunc(decimal * 60)
		// const seconds = Math.trunc((decimal * 60 - minutes) * 60)
		// return `${degrees}°${minutes}.${seconds}`
	}
	return dm;
};

export const openDownloadDialog = (url: any, saveName: string) => {
	return new Promise((resolve, reject) => {
		resolve("");
	}).then((res) => {
		if (typeof url == "object" && url instanceof Blob) {
			url = URL.createObjectURL(url); // 创建blob地址
		}
		var aLink = document.createElement("a");
		aLink.href = url;
		aLink.target = "_blank";
		aLink.download = saveName || "fdsfasdf.txt"; // HTML5新增的属性，指定保存文件名，可以不要后缀，注意，file:///模式下不会生效
		var event;
		if (window.MouseEvent) event = new MouseEvent("click");
		else {
			event = document.createEvent("MouseEvents");
			event.initMouseEvent(
				"click",
				true,
				false,
				window,
				0,
				0,
				0,
				0,
				0,
				false,
				false,
				false,
				false,
				0,
				null
			);
		}
		aLink.dispatchEvent(event);
	});
};

export const transformBolbToJson = (
	blob: Blob
): Promise<Error | { code: number; msg: string; data: any }> => {
	return new Promise((resolve, reject) => {
		const reader = new FileReader();
		reader.onload = function (event) {
			try {
				const json = JSON.parse(event.target.result);
				resolve(json);
			} catch (e) {
				reject(e);
			}
		};

		reader.onerror = function () {
			reject(reader?.error);
		};

		reader.readAsText(blob);
	});
};
