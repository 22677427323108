import { ColumnsType } from "antd/es/table/interface";
import { ApArColumnItemType } from "./type";
import React from "react";
import { dateFormat } from "@/tools/date";
import { formatThousandthNumber } from "@/tools/amount";
import { AparTag } from "./components";

export const columns: ColumnsType<
	{
		key: React.Key;
	} & ApArColumnItemType
> = [
	{
		dataIndex: "voyageNo",
		width: 150,
		title: "Voyage No.",
		key: "voyageNo",
		fixed: "left",
		align: "center"
	},
	{
		dataIndex: "voyageType",
		width: 150,
		title: "Voyage Type",
		key: "voyageType",
		align: "center",
		render: (value, record, index) => {
			return <AparTag type={value} />;
		}
	},
	{
		dataIndex: "vesselName",
		width: 150,
		title: "Vessel Name",
		key: "vesselName",
		align: "center",
		fixed: "left"
	},
	{
		dataIndex: "start",
		width: 160,
		title: "Start",
		key: "start",
		align: "center",
		render: (value, record, index) => {
			return <span>{dateFormat("YY-mm-dd HH:MM", value)}</span>;
		}
	},
	{
		dataIndex: "end",
		width: 160,
		title: "End",
		key: "end",
		align: "center",
		render: (value, record, index) => {
			return <span>{dateFormat("YY-mm-dd HH:MM", value)}</span>;
		}
	},
	{
		dataIndex: "arAp",
		width: 100,
		title: "AP&AR",
		key: "arAp",
		align: "center",
		render: (value, record, index) => {
			return <AparTag type={value} />;
		}
	},
	{
		dataIndex: "description",
		width: 200,
		title: "Description",
		key: "description",
		align: "center"
	},
	{
		dataIndex: "title",
		width: 150,
		title: "Counterparty",
		key: "title",
		align: "center"
	},
	{
		dataIndex: "invNo",
		width: 150,
		title: "Invoice No.",
		key: "invNo",
		align: "center"
	},
	{
		dataIndex: "invAmount",
		width: 200,
		title: "Invoice Amount",
		key: "invAmount",
		align: "center",
		render: (value, record, index) => {
			return <span>{formatThousandthNumber(value)}</span>;
		}
	},
	// {
	//   dataIndex: "estAmountAp",
	//   width: 200,
	//   title: "Est. Amount (AP)",
	//   key: "estAmountAp",
	//   align: "center",
	//   className:"td-colomun-cell--ap"
	// },
	{
		dataIndex: "status",
		width: 150,
		title: "Payment Status",
		key: "status",
		align: "center",
		render: (value, record, index) => {
			return <AparTag type={value} />;
		}
	},
	{
		dataIndex: "pic",
		width: 150,
		title: "PIC",
		key: "pic",
		align: "center"
	}
];