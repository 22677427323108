import React, { FC } from "react";
import classnames from "classnames";
import style from "./index.module.less";
import { InvStatementBunkerPriceFoType, InvStatementBunkerPriceMoType, InvStatementBunkerPriceProps } from "./type";
import { InvoiceAddTo } from "@/pages/voyagemanage/business/invoices/module";
import { FleetFormItem, FleetSelect, FleetVerifyInput } from "@/components";
import { invStatementFoOptions, invStatementMoOptions } from "./source";

const InvStatementBunkerPrice: FC<InvStatementBunkerPriceProps> = ({
  item,
  onCommit,
  otherItem,
  onOtherItemChange
}) => {
  return (
		<div className={classnames("invoicesDetail-form", style["invStatement-bunkerPrice"])}>
			<div className="invoicesForm-header">
				<InvoiceAddTo
					type="addBunker"
					value={otherItem?.addBunker}
					onChange={(key, val) => {
						onOtherItemChange?.("addBunker", val);
					}}
				>
					<span>Bunker Price</span>
				</InvoiceAddTo>
				<span>Bunker On Delivery (BOD)</span>
			</div>
			<div className="invoicesForm-container">
				<FleetFormItem label="FO Type" needVerity>
					<FleetSelect
						value={[item?.bodFoType]}
						options={invStatementFoOptions}
						onChange={(val: InvStatementBunkerPriceFoType) => {
							onCommit?.({
								bodFoType: val,
								borFoType: val,
								bohFoType: val
							});
						}}
					/>
				</FleetFormItem>
				<FleetVerifyInput
					value={item?.bodFoConsumption}
					min={0}
					type="number"
					label="FO On Board (MT)"
					needVerity
					onChange={(val: number) => {
						const bodFoTotalValue = val * +item?.bodFoPrice;
						onCommit?.({
							bodFoConsumption: val,
							bodFoTotalValue: bodFoTotalValue
						});
					}}
				/>
				<FleetVerifyInput
					value={item?.bodFoPrice}
					type="number"
					min={0}
					label="FO Price"
					needVerity
					onChange={(val: number) => {
						const bodFoTotalValue = val * +item?.bodFoConsumption;
						onCommit?.({
							bodFoPrice: val,
							bodFoTotalValue: bodFoTotalValue
						});
					}}
				/>
				<FleetVerifyInput
					value={item?.bodFoTotalValue}
					type="number"
					disabled
					needVerity
					label="Total Value"
				/>
				<FleetFormItem label="MDO/GO Type" needVerity>
					<FleetSelect
						value={[item?.bodMdoGoType]}
						options={invStatementMoOptions}
						onChange={(val: InvStatementBunkerPriceMoType) => {
							onCommit?.({
								borMdoGoType: val,
								bodMdoGoType: val,
								bohMdoGoType: val
							});
						}}
					/>
				</FleetFormItem>
				<FleetVerifyInput
					value={item?.bodMdoConsumption}
					type="number"
					min={0}
					needVerity
					label="MDO On Board (MT)"
					onChange={(val: number) => {
						const bodMdoTotalValue = val * +item?.bodMdoPrice;
						onCommit?.({
							bodMdoConsumption: val,
							bodMdoTotalValue: bodMdoTotalValue
						});
					}}
				/>
				<FleetVerifyInput
					value={item?.bodMdoPrice}
					type="number"
					min={0}
					label="MDO Price"
					needVerity
					onChange={(val: number) => {
						const bodMdoTotalValue = val * +item?.bodMdoConsumption;
						onCommit?.({
							bodMdoPrice: val,
							bodMdoTotalValue: bodMdoTotalValue
							// remitAmount
						});
					}}
				/>
				<FleetVerifyInput
					needVerity
					value={item?.bodMdoTotalValue}
					type="number"
					disabled
					label="Total Value"
				/>
			</div>
			<span className="invoicesRate-computed">Bunker On Redelivery (BOR)</span>
			<div className="invoicesForm-container">
				<FleetFormItem label="FO Type" needVerity>
					<FleetSelect
						value={[item?.borFoType]}
						options={invStatementFoOptions}
						onChange={(val: InvStatementBunkerPriceFoType) => {
							onCommit?.({
								bodFoType: val,
								borFoType: val,
								bohFoType: val
							});
						}}
					/>
				</FleetFormItem>
				<FleetVerifyInput
					needVerity
					value={item?.borFoConsumption}
					type="number"
					min={0}
					label="FO On Board (MT)"
					onChange={(val: number) => {
						const borFoTotalValue = val * +item?.borFoPrice;
						onCommit?.({
							borFoConsumption: val,
							borFoTotalValue: borFoTotalValue
						});
					}}
				/>
				<FleetVerifyInput
					value={item?.borFoPrice}
					type="number"
					needVerity
					min={0}
					label="FO Price"
					onChange={(val: number) => {
						const borFoTotalValue = val * +item?.borFoConsumption;
						onCommit?.({
							borFoPrice: val,
							borFoTotalValue: borFoTotalValue
						});
					}}
				/>
				<FleetVerifyInput
					value={item?.borFoTotalValue}
					type="number"
					needVerity
					disabled
					label="Total Value"
				/>
				<FleetFormItem label="MDO/GO Type" needVerity>
					<FleetSelect
						value={[item?.borMdoGoType]}
						options={invStatementMoOptions}
						onChange={(val: InvStatementBunkerPriceMoType) => {
							onCommit?.({
								borMdoGoType: val,
								bodMdoGoType: val,
								bohMdoGoType: val
							});
						}}
					/>
				</FleetFormItem>
				<FleetVerifyInput
					needVerity
					value={item?.borMdoConsumption}
					type="number"
					min={0}
					label="MDO On Board (MT)"
					onChange={(val: number) => {
						const borMdoTotalValue = val * +item?.borMdoPrice;
						onCommit?.({
							borMdoConsumption: val,
							borMdoTotalValue: borMdoTotalValue
						});
					}}
				/>
				<FleetVerifyInput
					needVerity
					value={item?.borMdoPrice}
					type="number"
					min={0}
					label="MDO Price"
					onChange={(val: number) => {
						const borMdoTotalValue = val * +item?.borMdoConsumption;
						onCommit?.({
							borMdoPrice: val,
							borMdoTotalValue: borMdoTotalValue
						});
					}}
				/>
				<FleetVerifyInput
					value={item?.borMdoTotalValue}
					needVerity
					type="number"
					disabled
					label="Total Value"
				/>
			</div>
			<span className="invoicesRate-computed">Bunker Off-Hire</span>
			<div className="invoicesForm-container">
				<FleetFormItem label="FO Type" needVerity>
					<FleetSelect
						value={[item?.bohFoType]}
						options={invStatementFoOptions}
						onChange={(val: InvStatementBunkerPriceFoType) => {
							onCommit?.({
								bodFoType: val,
								borFoType: val,
								bohFoType: val
							});
						}}
					/>
				</FleetFormItem>
				<FleetVerifyInput
					needVerity
					value={item?.bohFoConsumption}
					type="number"
					min={0}
					label="FO On Board (MT)"
					onChange={(val: number) => {
						const bohFoTotalValue = val * +item?.bohFoPrice;
						onCommit?.({
							bohFoConsumption: val,
							bohFoTotalValue: bohFoTotalValue
						});
					}}
				/>
				<FleetVerifyInput
					value={item?.bohFoPrice}
					type="number"
					needVerity
					min={0}
					label="FO Price"
					onChange={(val: number) => {
						const bohFoTotalValue = val * +item?.bohFoConsumption;
						onCommit?.({
							bohFoPrice: val,
							bohFoTotalValue: bohFoTotalValue
						});
					}}
				/>
				<FleetVerifyInput
					value={item?.bohFoTotalValue}
					type="number"
					needVerity
					disabled
					label="Total Value"
				/>
				<FleetFormItem label="MDO/GO Type" needVerity>
					<FleetSelect
						value={[item?.bohMdoGoType]}
						options={invStatementMoOptions}
						onChange={(val: InvStatementBunkerPriceMoType) => {
							onCommit?.({
								borMdoGoType: val,
								bodMdoGoType: val,
								bohMdoGoType: val
							});
						}}
					/>
				</FleetFormItem>
				<FleetVerifyInput
					needVerity
					value={item?.bohMdoConsumption}
					type="number"
					min={0}
					label="MDO On Board (MT)"
					onChange={(val: number) => {
						const bohMdoTotalValue = val * +item?.bohMdoPrice;
						onCommit?.({
							bohMdoConsumption: val,
							bohMdoTotalValue: bohMdoTotalValue
						});
					}}
				/>
				<FleetVerifyInput
					needVerity
					value={item?.bohMdoPrice}
					type="number"
					min={0}
					label="MDO Price"
					onChange={(val: number) => {
						const bohMdoTotalValue = val * +item?.bohMdoConsumption;
						onCommit?.({
							bohMdoPrice: val,
							bohMdoTotalValue: bohMdoTotalValue
						});
					}}
				/>
				<FleetVerifyInput
					value={item?.bohMdoTotalValue}
					needVerity
					type="number"
					disabled
					label="Total Value"
				/>
			</div>
			<span className="invoicesRate-computed">
				Tips: Total Value = FO/MDO On Board X FO/MDO Price;
			</span>
		</div>
	);
}

export default InvStatementBunkerPrice