import { useCommonIndex } from "@/hook";
import { useRef } from "react";
import { AreaVesselFilterItemType } from "../type";
import { initialFilterItem } from "../source";

const useIndex = () => {
	const agxMaxInputRef = useRef<HTMLInputElement>(null);
	const aisSpeedMinInputRef = useRef<HTMLInputElement>(null);
	const aisSpeedMaxInputRef = useRef<HTMLInputElement>(null);
	const dwtMinInputRef = useRef<HTMLInputElement>(null);
	const dwtMaxInputRef = useRef<HTMLInputElement>(null);

	const { dataSource, commit, init } = useCommonIndex<AreaVesselFilterItemType>(initialFilterItem);

	const handleFilterInit = (item: AreaVesselFilterItemType) => {
		init(item);
		agxMaxInputRef.current.value = (item?.ageMax ?? "") as string;
		aisSpeedMinInputRef.current.value = (item?.aisSpeedMin ?? "") as string;
		aisSpeedMaxInputRef.current.value = (item?.aisSpeedMax ?? "") as string;
		dwtMinInputRef.current.value = (item?.dwtMin ?? "") as string;
		dwtMaxInputRef.current.value = (item?.dwtMax ?? "") as string;
	};

	return {
		agxMaxInputRef,
		aisSpeedMinInputRef,
		aisSpeedMaxInputRef,
		dwtMinInputRef,
		dwtMaxInputRef,
		dataSource,
		commit,
		handleFilterInit
	};
};

export default useIndex;
