import { configureStore } from "@reduxjs/toolkit";
import GlobalStore from "./globalSlice/index";
import UserStore from "./userSlice/index"

const store = configureStore({
    reducer: {
        GlobalStore,
        UserStore
    }
});

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;

export default store;