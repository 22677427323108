export {
  InvFreightDesc,
  useFreightDescIndex,
  InvFreightDescProps,
  InvFreightDescInitItemType
} from "./descrition/module"

export {
  InvFreightBase,
  useFreightBaseIndex,
  InvFreightBaseInitItemType
} from "./base/module"

export {
  InvFreightTemplate
} from "./template/module"