import React, { CSSProperties, ReactNode, useState } from "react";
// import { Tooltip } from "react-tooltip"
import Popover from "antd/es/popover";
import { FleetButton, FleetIcon } from "..";
import style from "./index.module.less";
import { FleetIconProps, IconKeys } from "../Icon/icons";
import { ButtonProps } from "../Button";
import { AbstractTooltipProps } from "antd/es/tooltip";
import classnames from "classnames";

const FleetTooltip: React.FC<{
  children?: React.ReactNode,
  isOpen?: boolean,
  setIsOpen?: (val: boolean) => void,
  onOpenChange?: (open: boolean) => void,
  trigger?: "click" | "hover",
  disabled?: boolean,
  render?: (setIsOpen) => ReactNode,
  buttonProps?: ButtonProps,
  iconProps?: FleetIconProps,
  outerLayer?: React.ReactNode,
  placement?: AbstractTooltipProps["placement"];
  overlayStyle?: CSSProperties
  overlayClassName?: string
}> = ({
  children,
  isOpen,
  placement = "bottomRight",
  outerLayer,
  onOpenChange,
  trigger = "click",
  disabled,
  render,
  buttonProps,
  iconProps,
  overlayStyle,
  overlayClassName
}) => {
    const [open, setIsOpen] = useState(false);
    const handleOpen = (open: boolean) => {
      if (!disabled) {
        onOpenChange?.(open);
        setIsOpen?.(open)
      }
    }

    return <Popover
      content={render ? render(setIsOpen) : children}
      placement={placement}
      open={open}
      trigger={trigger}
      onOpenChange={handleOpen}
      overlayClassName={classnames(style["fleet-tooltip"], overlayClassName)}
      overlayStyle={overlayStyle}
    >
      {
        buttonProps ? <FleetButton
          {...buttonProps}
          disabled={disabled}
          size="small"
        ></FleetButton> : (outerLayer ? outerLayer : <FleetIcon {...iconProps} />)
      }
    </Popover>
  }

export default FleetTooltip;
