import { PerformanceType } from "./type"

export const portColumns: string[] = [
  "L/D",
  "Speed",
  "HSFO 3.5%S",
  "LSFO 0.5%S",
  "MDO 0.5%S",
  "MGO 0.1%S",
  "Remarks"
]

export const otherColumns: string[] = [
  "Port",
  null,
  null,
  null,
  null,
  null,
  null,
]

const initPortForm = (initItem?: PerformanceType["ballast"]) => {
  const portList: PerformanceType["ballast"] = [];
  for (let i = 0; i < 3; i++) {
    portList.push({
      speed: initItem?.[i]?.speed ?? "",
      hsFo: initItem?.[i]?.hsFo ?? "",
      lsFo: initItem?.[i]?.lsFo ?? "",
      mdo: initItem?.[i]?.mdo ?? "",
      mgo: initItem?.[i]?.mgo ?? "",
      remarks: initItem?.[i]?.remarks ?? ""
    })
  }
  return portList
}

export const initialPerformance = (initPortFormItems?: Partial<PerformanceType>): PerformanceType => {
  return {
    goodWeatherDef: initPortFormItems?.goodWeatherDef ?? "",
    performanceClause: initPortFormItems?.performanceClause ?? "",
    work: {
      hsFo: initPortFormItems?.work?.hsFo ?? "",
      lsFo: initPortFormItems?.work?.lsFo ?? "",
      mdo: initPortFormItems?.work?.mdo ?? "",
      mgo: initPortFormItems?.work?.mgo ?? "",
      remarks: initPortFormItems?.work?.remarks ?? ""
    },
    idle: {
      hsFo: initPortFormItems?.idle?.hsFo ?? "",
      lsFo: initPortFormItems?.idle?.lsFo ?? "",
      mdo: initPortFormItems?.idle?.mdo ?? "",
      mgo: initPortFormItems?.idle?.mgo ?? "",
      remarks: initPortFormItems?.idle?.remarks ?? ""
    },
    ballast: initPortForm(initPortFormItems?.ballast),
    laden: initPortForm(initPortFormItems?.laden)
  }
}