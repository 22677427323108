import { CommonInitItemEvent } from "@/types/event";
import { InvStatementBaseType } from "./type";

export const initialStatementBaseItem: CommonInitItemEvent<InvStatementBaseType> = (item) => {
	return {
		invNo: item?.invNo ?? "",
		invDate: item?.invDate ?? "",
		period: item?.period ?? null,
		cpDate: item?.cpDate ?? "",
		vesselName: item?.vesselName ?? "",
		ownerId: item?.ownerId ?? null,
		ownerName: item?.ownerId ? item?.ownerName ?? "" : "",
		address: item?.address ?? "",
		tel: item?.tel ?? "",
		email: item?.email ?? "",
		beneficiaryName: item?.beneficiaryName ?? "",
		beneficiaryAddress: item?.beneficiaryAddress ?? "",
		accountNo: item?.accountNo ?? "",
		swiftCode: item?.swiftCode ?? "",
		bankCode: item?.bankCode ?? "",
		beneficiaryBank: item?.beneficiaryBank ?? ""
	};
};

export const checkKeys: (keyof InvStatementBaseType)[] = [
	"invNo",
	"invDate",
	"ownerId",
	"ownerName",
	"beneficiaryName",
	"beneficiaryAddress",
	"accountNo",
	"swiftCode",
	"bankCode",
	"beneficiaryBank"
];
