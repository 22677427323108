import { EventData, MapLayerEventType } from "mapbox-gl";
import { MutableRefObject, useRef } from "react";
import { vesselGroupLayers } from "../source";
import ReactDOMServer from "react-dom/server";
import { RouteCommonPointPopup } from "../components";
import React from "react";
import mapboxgl from "mapbox-gl";
import { onRequest } from "@/action";
import dayjs from "dayjs";
import { useAppDispatch } from "@/hook";
import { useNavigate } from "react-router-dom";
import useReminder from "@/hook/useReminder";
import mapStyle from "../index.module.less";
import { RouteCommonMapRefType } from "../type";

const useDiscrete = (mapboxGl: MutableRefObject<mapboxgl.Map>) => {
	const dispatch = useAppDispatch();
	const navigate = useNavigate();
	const { reminder } = useReminder();
	const discreteLayers = useRef(new window.L.LayerGroup());
	const pointOption = useRef<{ visible: boolean; popup: mapboxgl.Popup }>({
		visible: false,
		popup: null
	});
	const loadDiscreteLayers = () => {
		// addSourceToMap(mapboxGl.current, {
		// 	sourceName: "route-discrete-source",
		// 	source: {
		// 		type: "geojson",
		// 		data: {
		// 			type: "FeatureCollection",
		// 			features: []
		// 		}
		// 	}
		// });
		// mapboxGl.current.addLayer({
		// 	id: "route-discrete-layer",
		// 	type: "symbol",
		// 	source: "route-discrete-source",
		// 	layout: {
		// 		"icon-image": "discretePointerIcon",
		// 		"icon-size": 0.2,
		// 		"icon-offset": [0, -50]
		// 	}
		// });
	};

	const getRouteCommonDiscreteFailed = (error) => {
		reminder("error", error?.msg ? error?.msg + ": " + error?.data : error?.data);
	};

	const onDiscreteListen = async (event: MapLayerEventType["click"] & EventData) => {
		if (!pointOption.current.visible) return;
		const layers = Object.values(vesselGroupLayers),
			features = mapboxGl?.current?.queryRenderedFeatures(event?.point),
			groupFeatures = features?.filter((feature) => layers?.includes(feature?.layer?.id));
		if (groupFeatures?.length > 0) return;
		onRequest(
			"getRouteCommonDiscreteApi",
			{
				lat: event?.lngLat?.lat,
				lng: event?.lngLat?.lng,
				dataTime: dayjs()?.valueOf()
			},
			null,
			(response) => {
				const htmlString = ReactDOMServer.renderToString(
					<RouteCommonPointPopup
						item={{
							...response?.data?.[0],
							lat: event?.lngLat?.lat,
							lng: event?.lngLat?.lng
						}}
					/>
				);
				if (pointOption.current.popup) {
					pointOption.current.popup?.setContent(htmlString).setLatLng(event?.lngLat);
					return;
				}

				const popup = new window.L.popup({
					closeButton: false,
					offset: [128, -22],
					autoClose: true,
					closeOnClick: false,
					className: mapStyle["routeCommon-map-leafletPopup"]
				});
				popup.setContent(htmlString).setLatLng(event?.lngLat).openOn(discreteLayers?.current);
				pointOption.current.popup = popup;
			},
			getRouteCommonDiscreteFailed,
			dispatch,
			navigate
		);
	};

	// const onDiscreteListen = async (event: MapLayerEventType["click"] & EventData) => {
	// 	// return;
	// 	if (!pointOption.current.visible) return;
	// 	const layers = Object.values(vesselGroupLayers),
	// 		features = mapboxGl?.current?.queryRenderedFeatures(event?.point),
	// 		groupFeatures = features?.filter((feature) => layers?.includes(feature?.layer?.id));
	// 	if (groupFeatures?.length > 0) return;
	// 	const currentSource = mapboxGl?.current?.getSource("route-discrete-source") as GeoJSONSource;
	// 	// // return;
	// 	onRequest(
	// 		"getRouteCommonDiscreteApi",
	// 		{
	// 			lat: event?.lngLat?.lat,
	// 			lng: event?.lngLat?.lng,
	// 			dataTime: dayjs()?.valueOf()
	// 		},
	// 		null,
	// 		(response) => {
	// 			currentSource?.setData({
	// 				type: "FeatureCollection",
	// 				features: [
	// 					{
	// 						type: "Feature",
	// 						geometry: {
	// 							type: "Point",
	// 							coordinates: [event?.lngLat?.lng, event?.lngLat?.lat]
	// 						},
	// 						properties: {}
	// 					}
	// 				]
	// 			});

	// 			const htmlString = ReactDOMServer.renderToString(
	// 				<RouteCommonPointPopup
	// 					item={{
	// 						...response?.data?.[0],
	// 						lat: event?.lngLat?.lat,
	// 						lng: event?.lngLat?.lng
	// 					}}
	// 				/>
	// 			);
	// 			if (pointOption.current.popup) {
	// 				pointOption.current.popup?.setHTML(htmlString).setLngLat(event?.lngLat);
	// 				return;
	// 			}

	// 			const popup = new mapboxgl.Popup({
	// 				closeButton: false,
	// 				closeOnClick: false,
	// 				closeOnMove: false,
	// 				anchor: "bottom-left",
	// 				offset: [0, -25],
	// 				className: mapStyle["routeCommon-map-vesselInfo-popup"]
	// 			})
	// 				.setHTML(htmlString)
	// 				.setLngLat(event?.lngLat)
	// 				.addTo(mapboxGl?.current);
	// 			pointOption.current.popup = popup;
	// 		},
	// 		getRouteCommonDiscreteFailed,
	// 		dispatch,
	// 		navigate
	// 	);
	// };

	const handlePointControlSelect: RouteCommonMapRefType["onPointControlSelect"] = (value) => {
		const canvas = mapboxGl?.current?.getCanvas();
		if (!value) {
			pointOption?.current?.popup?.remove();
			pointOption.current.popup = null;
			canvas.style.cursor = "drag";
			// const currentSource = mapboxGl?.current?.getSource("route-discrete-source") as GeoJSONSource;
			// currentSource?.setData({
			// 	type: "FeatureCollection",
			// 	features: []
			// });
		}
		pointOption.current.visible = value;
		canvas.style.cursor = "pointer";
		// const htmlString = ReactDOMServer.renderToString(<RouteCommonPointPopup />);

		// const popup = new mapboxgl.Popup({
		// 	closeButton: false,
		// 	offset: [0, -25]
		// })
		// 	.setHTML(htmlString)
		// 	.setLngLat(option?.position)
		// 	.addTo(mapboxGl);
	};

	return {
		discreteLayers,
		pointOption,
		loadDiscreteLayers,
		onDiscreteListen,
		handlePointControlSelect
	};
};

export default useDiscrete;
