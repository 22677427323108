import { useAppDispatch } from "@/hook";
import { RouteAisListProps, RouteAisListRefType } from "../type";
import { useNavigate } from "react-router-dom";
import useReminder from "@/hook/useReminder";
import { DragEventHandler, useEffect, useRef, useState } from "react";
import {
	RouteCommonAisGroupItemProps,
	RouteCommonAisGroupItemType,
	RouteCommonAisItemType
} from "../components";
import { onRequest } from "@/action";
import { useRouteCommonContext } from "@/pages/routeCommon/store";
import { RouteCommonGroupAddProps } from "@/pages/routeCommon/common";

const useIndex = (
	activeTab: RouteAisListProps["activeTab"],
	onSelect: RouteAisListProps["onSelect"],
	onVesselLoad: RouteAisListProps["onVesselLoad"]
) => {
	const dispatch = useAppDispatch();
	const navigate = useNavigate();
	const { reminder } = useReminder();
	const { activeAisVessel, hideGroups, onVesselGroupHide } = useRouteCommonContext();

	const [totalCount, setTotalCount] = useState<number>(0);
	const [loading, setLoading] = useState<boolean>(false);
	const [dataSource, setDataSource] = useState<RouteCommonAisGroupItemType[]>([]);
	const [expandRows, setExpandRows] = useState<RouteCommonAisGroupItemProps["expandRows"]>([]);

	const dragOption = useRef<{
		dragElement: HTMLDivElement;
		dropGroupElement: HTMLDivElement;
	}>({
		dragElement: null,
		dropGroupElement: null
	});

	const handleExpandChange: RouteCommonAisGroupItemProps["onRowExpand"] = (groupId) => {
		setExpandRows((prev) => {
			const index = prev?.findIndex((item) => item === groupId);
			if (index === -1) {
				prev?.push(groupId);
			} else {
				prev?.splice(index, 1);
			}
			return [...prev];
		});
	};

	const handleGroupHide: RouteCommonAisGroupItemProps["onGroupHide"] = (groupId) => {
		const gourps = new Set(dataSource?.map((item) => item?.groupId)),
			_hideGroups = new Set([...hideGroups]);
		const currentIndex = hideGroups?.findIndex((item) => item === groupId);
		if (currentIndex === -1) {
			_hideGroups?.add(groupId);
		} else {
			_hideGroups.delete(groupId);
		}
		const showGroups = new Set([...gourps].filter((item) => !_hideGroups.has(item)));
		onVesselGroupHide?.([...showGroups], [..._hideGroups]);
	};

	const handleDragStart: DragEventHandler<HTMLDivElement> = (event) => {
		const dragElement = event?.target as HTMLDivElement;
		dragElement.classList.add("dragging");
		dragOption.current.dragElement = dragElement;
	};

	const handleDragEnter: DragEventHandler<HTMLDivElement> = (event) => {
		const dropElement = event?.target as HTMLDivElement,
			parentElement = dropElement?.parentElement?.parentElement,
			dragParentElement = dragOption?.current?.dragElement?.parentElement?.parentElement;
		if (dropElement?.id?.includes("routeCommon-aisGroup--") && dropElement !== dragParentElement) {
			dropElement?.classList?.add("drag-enter");
			dragOption.current.dropGroupElement = dropElement;
			return;
		}
		if (
			dropElement?.id?.startsWith("routeCommon-aisItem--") &&
			dropElement !== dragOption?.current?.dragElement &&
			parentElement !== dragParentElement
		) {
			const dropGroupElement = dropElement?.parentElement?.parentElement as HTMLDivElement;
			dropGroupElement?.classList?.add("drag-enter");
			dragOption.current.dropGroupElement = dropGroupElement;
		}
	};

	const handleDragEnd: DragEventHandler<HTMLDivElement> = async (event) => {
		dragOption.current.dragElement?.classList?.remove("dragging");
		if (dragOption.current.dropGroupElement) {
			dragOption.current?.dropGroupElement?.classList?.remove("drag-enter");
			const curentImo = dragOption.current?.dragElement?.id?.split("--")?.[1],
				currentGroupId = dragOption.current?.dropGroupElement?.id?.split("--")?.[1];
			handleSaveCollection(curentImo, currentGroupId);
		}
		dragOption.current = {
			dragElement: null,
			dropGroupElement: null
		};
	};

	const handleDragLeave: DragEventHandler<HTMLDivElement> = (event) => {
		const element = event?.target as HTMLDivElement;
		if (
			(element?.id?.startsWith("routeCommon-aisItem--") &&
				element !== dragOption?.current?.dragElement) ||
			element?.id?.startsWith("routeCommon-aisGroup--")
		) {
			element.classList.remove("drag-enter");
		}
	};

	const handleDrop: DragEventHandler<HTMLDivElement> = (event) => {
		event?.preventDefault();
		if (dragOption.current?.dropGroupElement) {
			dragOption.current?.dropGroupElement?.classList?.remove("drag-enter");
		}
	};

	const handleGetAisSource = (items: RouteCommonAisGroupItemType[]): RouteCommonAisItemType[] => {
		return items?.reduce((prev, curr) => {
			const currentGroup = curr?.dataList?.map((item) => ({
				...item,
				color: curr?.color ?? "Purple"
			}));
			return [...prev, ...currentGroup];
		}, []);
	};

	const getAisStarGroupFront = () => {
		setLoading(true);
	};

	const getAisStarGroupFailed = (error) => {
		setLoading(false);
		reminder("error", error?.msg ? error?.msg + ": " + error?.data : error?.data);
	};

	const getAisGroupSuccess = (response) => {
		setLoading(false);
		setDataSource(response?.data?.groupList);
		setTotalCount(response?.data?.totalCount);
		const aisList = handleGetAisSource(response?.data?.groupList);
		console.log("aisList", aisList);
		onVesselLoad?.("ais", aisList);
		if (activeAisVessel && !aisList?.find((item) => item?.imo === activeAisVessel?.imo)) {
			// setCurrentAisItem(null);
			onSelect?.(null);
		}
	};

	const getAisGroup = () => {
		onRequest(
			"getRouteCommonAisCollectionApi",
			null,
			getAisStarGroupFront,
			getAisGroupSuccess,
			getAisStarGroupFailed,
			dispatch,
			navigate
		);
	};

	const handleReset = () => {
		getAisGroup();
	};

	const handleSelect: RouteAisListProps["onSelect"] = (item) => {
		onSelect?.(item);
	};

	const handleCollection: RouteCommonAisGroupItemProps["onCollection"] = (item) => {
		onRequest(
			"cancleRouteCommonAisCollectionApi",
			{
				imo: item?.imo
			},
			getAisStarGroupFront,
			handleReset,
			getAisStarGroupFailed,
			dispatch,
			navigate
		);
	};

	const handleSaveCollection = (imo: string, groupId: string) => {
		onRequest(
			"updateRouteCommonAisCollectionApi",
			{
				imo: imo,
				groupId: groupId
			},
			getAisStarGroupFront,
			handleReset,
			getAisStarGroupFailed,
			dispatch,
			navigate
		);
		return;
	};

	const handleGroupAddSuccess = ({ data }) => {
		setDataSource((prev) => [
			{ groupId: data?.id, groupName: data?.groupName, dataList: [], color: data?.color },
			...prev
		]);
		setLoading(false);
	};

	const handleGroupEditSuccess = ({ data }) => {
		let _prev: RouteCommonAisGroupItemType[] = [];
		setDataSource((prev) => {
			const currentIndex = prev?.findIndex((item) => item?.groupId === data?.id);
			const currentItem = prev?.[currentIndex];
			prev.splice(currentIndex, 1, {
				...currentItem,
				groupName: data?.groupName,
				color: data?.color
			});
			const aisList = handleGetAisSource(prev);
			console.log("prev", prev, data, aisList, data.color);
			onVesselLoad?.("ais", aisList);
			return [...prev];
		});
		setLoading(false);
	};

	const handleGroupEdit: RouteCommonGroupAddProps["onConfirm"] = (mode, groupItem) => {
		onRequest(
			"updateRouteCommonAisGroupApi",
			{
				id: groupItem?.groupId,
				groupName: groupItem?.groupName,
				color: groupItem?.color
			},
			getAisStarGroupFront,
			mode === "newly" ? handleGroupAddSuccess : handleGroupEditSuccess,
			getAisStarGroupFailed,
			dispatch,
			navigate
		);
	};

	const handleGroupDelete: RouteCommonAisGroupItemProps["onDelete"] = (item) => {
		onRequest(
			"deleteRouteCommonAisGroupApi",
			{ groupId: item?.groupId },
			getAisStarGroupFront,
			(response) => {
				setDataSource((prev) => {
					const currentIndex = prev?.findIndex(
						(selectItem) => selectItem?.groupId === item?.groupId
					);
					prev.splice(currentIndex, 1);
					return [...prev];
				});
				if (expandRows?.includes(item?.groupId)) {
					handleExpandChange(item?.groupId);
				}
				reminder("success", `Group ${item?.groupName} has been deleted`);
				setLoading(false);
			},
			getAisStarGroupFailed,
			dispatch,
			navigate
		);
	};

	const handleGetFollow: RouteAisListRefType["onGetFollow"] = (selectItem) => {
		const aisItems = handleGetAisSource(dataSource);
		return !!aisItems?.find((item) => item?.imo === selectItem?.currentState?.imo);
	};

	useEffect(() => {
		if (activeTab !== "ais") return;
		getAisGroup();
	}, [activeTab]);

	console.log("expandRows", expandRows);

	return {
		expandRows,
		activeAisVessel,
		loading,
		dataSource,
		totalCount,
		handleReset,
		handleGetFollow,
		handleSelect,
		handleGroupEdit,
		handleGroupDelete,
		handleCollection,
		handleSaveCollection,
		dragOption,
		handleDragStart,
		handleDragEnter,
		handleDragEnd,
		handleDragLeave,
		handleDrop,
		handleExpandChange,
		hideGroups,
		handleGroupHide
	};
};

export default useIndex;
