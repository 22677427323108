import { useEffect, useMemo, useRef, useState } from "react";
import {
	CommonLatitudeCoordinateOptionChangeEvent,
	CommonLatitudeCoordinateOptionType
} from "../type";

const DEGRESS_REG = /^[1-9]\d{0,2}\.{0,1}[0-9]{0,2}$/;

const useIndex = (open: boolean, onBlur?: (e) => void) => {
	const [coordinate, setCoordinate] = useState<CommonLatitudeCoordinateOptionType>({
		lon: "E",
		lat: "N"
	});
	const status = useRef<"focus" | "blur">("blur");
	const inputRef = useRef(null);
	const currentIndex = useRef(-1);
	const [value, setValue] = useState("");
	const [items, setItems] = useState<any[]>(["", "", "", "", "", ""]);
	const [active, setActive] = useState<number>(-1);

	const isFull = useMemo(() => {
		return items.every((item) => item?.length !== 0);
	}, [items]);

	const handleCoordinateChange: CommonLatitudeCoordinateOptionChangeEvent = (key, value) => {
		setCoordinate((prev) => ({ ...prev, [key]: value }));
		inputRef?.current?.focus();
	};

	const blur: React.FocusEventHandler<HTMLInputElement> = (e) => {
		// setActive(-1);
		// setValue("");
		// currentIndex.current = -1;
		// setStatus("blur");
		// onBlur?.(e)
		status.current = "blur";
	};

	const change = (val: string) => {
		if (+val === 0 || val.length === 0 || DEGRESS_REG.test(val)) {
			setValue(val);
			setItems((prev) => {
				prev.splice(currentIndex.current, 1, val);
				return [...prev];
			});
		}
	};

	const select = (index: number) => {
		setActive(+index);
		currentIndex.current = +index;
		setValue(items[+index]);
		status.current = "focus";
		inputRef?.current?.focus();
	};

	const translate = () => {
		let res = "",
			nTag = coordinate?.lat === "N" ? "" : "-",
			wTag = coordinate?.lon === "E" ? "" : "-";
		let nRes, wRes;
		wRes = Math.abs(items[0]) + (Math.abs(items[1]) / 60 + Math.abs(items[2]) / 3600);
		nRes = Math.abs(items[3]) + (Math.abs(items[4]) / 60 + Math.abs(items[5]) / 3600);
		res = parseFloat(`${wTag}${wRes}`).toFixed(6) + ", " + parseFloat(`${nTag}${nRes}`).toFixed(6);
		return res;
	};

	const keyDown: React.KeyboardEventHandler<HTMLInputElement> = (e) => {
		switch (e.key) {
			case "Tab":
			case "ArrowRight":
				e.preventDefault();
				setValue("");
				setActive((prev) => {
					if (prev === 5) return 0;
					return prev + 1;
				});
				currentIndex.current = currentIndex.current === 5 ? 0 : currentIndex.current + 1;
				break;
			case "ArrowLeft":
				e.preventDefault();
				setValue("");
				setActive((prev) => {
					if (prev === 0) return 5;
					return prev - 1;
				});
				currentIndex.current = currentIndex.current === 0 ? 5 : currentIndex.current - 1;
				break;
			default:
				break;
		}
	};

	useEffect(() => {
		if (!open) return;
		setActive(0);
		currentIndex.current = 0;
		inputRef?.current?.focus();
		// setActive(-1);
		setValue("");
		// currentIndex.current = -1;
		setItems(["", "", "", "", "", ""]);
	}, [open]);

	return {
		status,
		currentIndex,
		items,
		active,
		value,
		inputRef,
		translate,
		isFull,
		change,
		keyDown,
		blur,
		select,
		coordinate,
		handleCoordinateChange
	};
};

export default useIndex;
