import { FleetOptionType } from "@/types/advancedType";

export const wavesOptions: FleetOptionType<
	"2" | "3" | "4" | "5" | "6" | "7" | "8" | "9" | "10" | "11" | ">11",
	| "rgb(148, 231, 238)"
	| "rgb(127, 255, 226)"
	| "rgb(165, 255, 174)"
	| "rgb(206, 255, 133)"
	| "rgb(255, 255, 127)"
	| "rgb(255, 227, 127)"
	| "rgb(255, 183, 127)"
	| "rgb(255, 127, 127)"
	| "rgb(252, 127, 172)"
	| "rgb(212, 127, 212)"
	| "rgb(253, 130, 232)"
>[] = [
	{ value: "rgb(148, 231, 238)", label: "2" },
	{ value: "rgb(127, 255, 226)", label: "3" },
	{ value: "rgb(165, 255, 174)", label: "4" },
	{ value: "rgb(206, 255, 133)", label: "5" },
	{ value: "rgb(255, 255, 127)", label: "6" },
	{ value: "rgb(255, 227, 127)", label: "7" },
	{ value: "rgb(255, 183, 127)", label: "8" },
	{ value: "rgb(255, 127, 127)", label: "9" },
	{ value: "rgb(252, 127, 172)", label: "10" },
	{ value: "rgb(212, 127, 212)", label: "11" },
	{ value: "rgb(253, 130, 232)", label: ">11" }
];
