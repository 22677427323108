import React, { FC } from "react";
import classnames from "classnames"
import style from "./index.module.less";
import { InvRemitAmountProps } from "./type";
import { formatThousandthNumber } from "@/tools/amount";

const InvRemitAmount: FC<InvRemitAmountProps> = ({
  remitAmount
}) => {
  return <ul className={style["invoices-remitAmount"]}>
    <li className="remitAmount-title">Remit Amount</li>
    <li className={classnames("remitAmount-summary", {
      "fall-down": remitAmount < 0
    })}>{formatThousandthNumber(remitAmount)}</li>
  </ul>
}

export default InvRemitAmount