import { type ApiParameter } from "@/api";
import { appVersion } from "@/api/constant";
import request from "@/service";
import { PageParams } from "../estimation";
import { DatabaseVesselAddFormType } from "@/featrue/database/vessel/add/type";

const enum ApiMap {
	getVesselGroupParameter,
	deleteVesselParameter,
	getVesselParameter,
	addVesselParameter,
	editVesselParameter
}

type ApiUrl = keyof typeof ApiMap;

const vesselApi: ApiParameter<ApiUrl> = {
	getVesselGroupParameter: {
		method: "get",
		url: appVersion + "/vessel/list"
	},
	deleteVesselParameter: {
		method: "delete",
		url: appVersion + "/vessel"
	},
	getVesselParameter: {
		method: "get",
		url: appVersion + "/vessel"
	},
	addVesselParameter: {
		method: "post",
		url: appVersion + "/vessel"
	},
	editVesselParameter: {
		method: "put",
		url: appVersion + "/vessel"
	}
};

export type getVesselGroupParams = PageParams & {
	keyword: string;
};

export type getVesselParams = {
	id: string | number;
};

export type deleteVesselParams = {
	id: string | number;
};

export type addVesselParams = {} & DatabaseVesselAddFormType;

export type editVesselParams = {
	id?: string | number;
} & DatabaseVesselAddFormType;

export const getVesselGroupApi = (params: getVesselGroupParams) => {
	return request.request({
		...vesselApi.getVesselGroupParameter,
		params
	});
};

export const getVesselApi = (params: getVesselParams) => {
	return request.request({
		...vesselApi.getVesselParameter,
		url: `${vesselApi.getVesselParameter.url}/${params?.id}`
	});
};

export const deleteVesselApi = (params: deleteVesselParams) => {
	return request.request({
		...vesselApi.deleteVesselParameter,
		url: `${vesselApi.deleteVesselParameter.url}/${params?.id}`
	});
};

export const addVesselApi = (params: addVesselParams) => {
	return request.request({
		...vesselApi.addVesselParameter,
		data: params
	});
};

export const editVesselApi = (params: editVesselParams) => {
	return request.request({
		...vesselApi.editVesselParameter,
		data: params
	});
};
