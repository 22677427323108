import React, { useMemo } from "react";
import style from "./index.module.less";
import FleetTabs from "@/components/Tabs";
import DatabaseRouter from "@/pages/database/router";
import { useAppSelector } from "@/hook";

const DatabaseHeader: React.FC<{

}> = (props) => {
  const { firstSelected } = useAppSelector(state => state.GlobalStore);
  const { userInfo } = useAppSelector(state => state.UserStore)
  console
  const tabs = useMemo(() => {
    const children = userInfo?.menus?.find(menu => menu.key === "/layout/database")?.children
    return children?.map(item => {
      return {
        ...item,
        label: item?.meta?.title
      }
    })
  }, [userInfo])
  
  return <div className={style["database-header"]}>
    <FleetTabs className="database-header-tabs" list={tabs} value={firstSelected} />
    <span className="database-header-line"></span>
  </div>
}

export default DatabaseHeader