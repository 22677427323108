import { useCommonIndex } from "@/hook"
import { checkKeys, initialStatementBaseItem } from "../source"
import { InvStatementBaseInitItemType } from "../type"
import { useInvFieldNames } from "@/pages/voyagemanage/business/invoices/module"

const useIndex = () => {
  const {
    dataSource,
    change,
    commit,
    init: handleBaseItemInit,
    check
  } = useCommonIndex(initialStatementBaseItem, checkKeys)

  const {
    delIds: basicsDelIds,
    dataSource: basicsFieldItems,
    change: handleBasicsFieldItemChange,
    remove: handleBasicsFieldDelete,
    init: handleBasicsFieldInit,
    add: handleBasicsFieldAdd
  } = useInvFieldNames()

  const {
    delIds: counterPartyDelIds,
    dataSource: counterPartyFieldItems,
    change: handleCounterPartyFieldItemChange,
    remove: handleCounterPartyFieldFieldDelete,
    init: handleCounterPartyFieldFieldInit,
    add: handleCounterPartyFieldFieldAdd
  } = useInvFieldNames()

  const init = (type: "init" | "details", item: Partial<InvStatementBaseInitItemType>) => {
    handleBaseItemInit(item)
    handleBasicsFieldInit(type, item?.basicsFieldList)
    handleCounterPartyFieldFieldInit(type, item?.counterFieldList)
  }

  return {
    baseItem: dataSource,
    handleBaseItemChange: change,
    handleBaseItemCommit: commit,
    handleBaseInit: init,
    handleBaseCheck: check,
    basicsDelIds,
    basicsFieldItems,
    handleBasicsFieldItemChange,
    handleBasicsFieldDelete,
    handleBasicsFieldAdd,
    counterPartyDelIds,
    counterPartyFieldItems,
    handleCounterPartyFieldItemChange,
    handleCounterPartyFieldFieldDelete,
    handleCounterPartyFieldFieldAdd
  }
}

export default useIndex