import { formatThousandthNumber } from "@/tools/amount";
import { dateFormat } from "@/tools/date";
import { RefObject } from "react";

export const loadImage = (mapboxgl: RefObject<mapboxgl.Map>, imgUrl: string, layerName: string) => {
  if (!mapboxgl.current) return;
  //     let anchorImage = new Image(20, 20);
  //     anchorImage.src = anchorSvgBase64
  //     anchorImage.onload = function () {
  //       map.current.addImage('anchor-icon', anchorImage)
  //     };
  mapboxgl.current.loadImage(imgUrl, (error, result) => {
    if (error) throw error;
    mapboxgl.current.addImage(layerName, result);
  })
}

export const addLayer = (mapboxgl: RefObject<mapboxgl.Map>, layer: mapboxgl.AnyLayer, before?: string) => {
  if (!mapboxgl.current) return;
  return mapboxgl.current.addLayer(layer, before)
}

export const addSource = (mapboxgl: RefObject<mapboxgl.Map>, sourceName: string, source: mapboxgl.AnySourceData) => {
  if (!mapboxgl.current) return;
  return mapboxgl.current.addSource(sourceName, source)
}

export const toggleLayerVisibility = (mapboxgl: RefObject<mapboxgl.Map>, layerId: string, visibility: "visible" | "none") => {
  mapboxgl.current.setLayoutProperty(layerId, 'visibility', visibility);
}

// 控制图层内不同feature的显示和隐藏
export const toggleFeature = (mapboxgl: RefObject<mapboxgl.Map>, sourName: string, layerName: string, featureId: string | number) => {
  // 获取图层的源数据



}

export const isFeatureHidden = (targetFeature: any) => {
  if (targetFeature) {
    return targetFeature.properties.hidden === true;
  }
  return false;
}

// 隐藏特定的 Feature
export const hideFeature = (mapboxgl: RefObject<mapboxgl.Map>, layerName: string, targetFeature: any) => {
  if (targetFeature) {
    if (isFeatureHidden(targetFeature)) return;
    targetFeature.properties.hidden = true;
    const layer = mapboxgl.current.getLayer(layerName) as any;
    // 更新图层的数据源
    const source = mapboxgl.current.getSource(layer.source) as any;
    source.setData(source._data);
  }
}

export const findFeatureInLayer = (mapboxgl: RefObject<mapboxgl.Map>, layerName: string, featureId: string) => {
  const layer = mapboxgl.current.getLayer(layerName) as any;
  const sourceId = layer.source;

  // 获取图层对应的 GeoJSON 数据源
  const source = mapboxgl.current.getSource(sourceId) as any;

  // 获取 GeoJSON 数据源中的所有 Feature
  const allFeatures = source._data.features;

  // 找到特定的 Feature
  const targetFeature = allFeatures.find(feature => feature.properties.id === featureId);

  return targetFeature;
}


// const animate = () => {
//   if (counterIndex.current > source?.geometry?.coordinates.length - 1) {
//     counterIndex.current = 0;
//     return
//   }
//   let startPoint,endPoint;
//   if (counterIndex.current === 0) {
//     startPoint = source?.geometry?.coordinates[counterIndex.current]
//     endPoint = source?.geometry?.coordinates[counterIndex.current + 1]
//   } else {
//     startPoint = source?.geometry?.coordinates[counterIndex.current - 1]
//     endPoint = source?.geometry?.coordinates[counterIndex.current]
//   }

//   iconSource.current.data.features[0].properties.bearing = bearing(
//     point(startPoint),
//     point(endPoint),
//   ) + -90;

//   LineString.current.features[0].geometry.coordinates.push(source?.geometry?.coordinates[counterIndex.current]);
//   iconSource.current.data.features[0].geometry.coordinates = source?.geometry?.coordinates[counterIndex.current]
//   map.current.getSource("pathSource").setData(LineString.current);
//   map.current.getSource("iconSource").setData(iconSource.current.data)
//   counterIndex.current += 1;
//   // requestAnimationFrame(animate);
// }