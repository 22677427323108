import React from "react";
import classnames from "classnames";
import { ColumnsType } from "antd/es/table/interface";
import { DatabaseTaskItemType } from "@/featrue/database/task/edit/type";

export type DataType = {
	key: React.Key;
} & Record<keyof DatabaseTaskItemType | "option", any>;

export const columns: ColumnsType<DataType> = [
	{
		dataIndex: "stage",
		width: 150,
		title: "Status",
		key: "stage",
		fixed: "left",
		align: "center"
	},
	{
		dataIndex: "voyageType",
		width: 150,
		title: "Voyage Type",
		key: "voyageType",
		fixed: "left",
		align: "center",
		render: (value, record, index) => {
			return (
				<span className={classnames("task-voyageType", record?.voyageType)}>
					{record?.voyageType}
				</span>
			);
		}
	},
	{
		dataIndex: "title",
		width: 200,
		title: "Title",
		key: "title",
		align: "center"
	},
	{
		dataIndex: "contents",
		width: 867,
		title: "Contents",
		key: "contents",
		align: "center"
	}
];