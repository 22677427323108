import { ApiParameter, RequestApiMapType } from "@/api";
import request from "@/service";
import {
	getMovementTctcParams,
	saveMovementTctcParams,
	downloadMovementTctcPnlParams,
	MovementTctcParamsType
} from "./type";
import { appVersion } from "@/api/constant";

const enum ApiMap {
	getMovementTctcParameter,
	saveMovementTctcParameter,
	downloadMovementTctcPnlParameter
}

export type ApiUrl = keyof typeof ApiMap;

const movementTctcApi: ApiParameter<ApiUrl> = {
	getMovementTctcParameter: {
		method: "get",
		url: appVersion + "/move/voyage/tctc"
	},
	saveMovementTctcParameter: {
		method: "put",
		url: appVersion + "/move/voyage/tctc"
	},
	downloadMovementTctcPnlParameter: {
		method: "post",
		url: appVersion + "/excel/download/tctc/pnl"
	}
};

export const getMovementTctcApi = (params: getMovementTctcParams) => {
	return request.request({
		...movementTctcApi.getMovementTctcParameter,
		url: `${movementTctcApi.getMovementTctcParameter.url}/${params?.id}`
	});
};

export const saveMovementTctcApi = (params: saveMovementTctcParams) => {
	return request.request({
		...movementTctcApi.saveMovementTctcParameter,
		data: params
	});
};

export const downloadMovementTctcPnlApi = (params: downloadMovementTctcPnlParams) => {
	return request.request({
		...movementTctcApi.downloadMovementTctcPnlParameter,
		data: params,
		responseType: "blob"
	});
};

export const movementTctcApiMap: RequestApiMapType<ApiUrl, MovementTctcParamsType> = {
	getMovementTctcApi,
	saveMovementTctcApi,
	downloadMovementTctcPnlApi
};
