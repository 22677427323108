import React, { FC, useState } from "react";
import classnames from "classnames";
import style from "./index.module.less";
import { HandoverFormLoadMoreProps } from "./type";

const HandoverFormLoadMore: FC<HandoverFormLoadMoreProps> = ({ children }) => {
  const [open, setOpen] = useState(false)
  return <div className={style["handoverForm-loadMore"]}>
    <div className={classnames("loadMore-container", {
      open
    })}>
      {children}
    </div>
    <div className="loadMore-action" onClick={() => setOpen(!open)}>
      <span>More</span>
      <span className={classnames("font_family fleet-back", { open })}></span>
    </div>
  </div>
}

export default HandoverFormLoadMore