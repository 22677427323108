import React, { useEffect, useMemo } from "react";
import { FleetButton, FleetInput, FleetSelect } from "@/components";
import useIndex from "./hook/useIndex";
import dayjs, { Dayjs } from "dayjs";
import { Checkbox } from "antd";
import {
  SeaReportTableConfig,
  SeaReportTableHeaders,
  SeaReportTableHeaderGroup,
  typeOptions,
  sumProps,
} from "./source";
import { SeaReportsTableProps } from "./type";
import style from "./index.module.less";
import LatOrLonInput from "../latOrLonInput/latOrLonInput";
import { FleetTimePicker } from "@/components/DatePicker";
import { Button, Popover } from "@/_components";
import { FleetToast } from "@/common";

const SeaReportsTable: React.FC<SeaReportsTableProps> = ({
  tableData,
  onChange,
  ondbClick,
  onHandleRow,
  filterHeader,
  selectRow,
}) => {
  const {
    rowSelection,
    editRow,
    showingDetailRow,
    setShowingDetailRow,
    position,
    setWarningClass,
    cantEdit,
  } = useIndex(onChange, tableData, onHandleRow);

  const seaReportTableHeaderProp = useMemo(() => {
    let filterList = ["check", "alarm", "status", "action", "type", "alarm"];
    for (let [k, v] of Object.entries(filterHeader)) {
      filterList = filterList.concat(v);
    }
    return SeaReportTableHeaders.filter((item) => {
      return filterList.includes(item.dataIndex);
    });
  }, [filterHeader]);

  const seaReportTableHeaderFilter = useMemo(() => {
    let filterList = JSON.parse(JSON.stringify(seaReportTableHeaderProp)).map(
      (i) => {
        return i.dataIndex;
      }
    );
    const groups = Object.keys(SeaReportTableHeaderGroup);
    const newHeader = SeaReportTableConfig.map((item, index) => {
      const rows = item.row.filter((ele) => {
        if (groups.includes(ele.prop)) {
          let col = 0;
          filterList.forEach((i: any) => {
            if (SeaReportTableHeaderGroup[ele.prop].includes(i)) col++;
          });
          if (col != 0 && !filterList.some((k: string) => k == ele.prop)) {
            filterList.push(ele.prop);
            ele.colSpan = col;
          }
        }
        return filterList.includes(ele.prop);
      });
      return {
        rowIndex: index,
        row: rows,
      };
    });
    return newHeader;
  }, [seaReportTableHeaderProp]);

  const totalSum = useMemo(() => {
    const sums = {};
    sumProps.forEach((prop) => {
      sums[prop] = tableData
        .reduce((acc, item) => acc + Number(item[prop]), 0)
        .toFixed(2);
    });
    seaReportTableHeaderProp.map((ele) => {
      if (
        ele?.dataIndex &&
        ["brobHfo", "brobLsfo", "brobMdo", "brobMgo"].includes(ele?.dataIndex)
      ) {
        sums[ele.dataIndex] = tableData[tableData.length - 1]?.[ele.dataIndex];
      }
    });
    return sums;
  }, [tableData]);

  useEffect(() => {}, []);
  return (
    <div className={style["movement-sea-reports-table"]}>
      <div className="sea-reports-table">
        <table cellSpacing={0}>
          <colgroup>
            {seaReportTableHeaderProp.map((item, index) => {
              return (
                <col
                  className={`sea-reports-table-col sea-reports-table-${item.dataIndex}`}
                  key={index}
                ></col>
              );
            })}
          </colgroup>
          <thead>
            {seaReportTableHeaderFilter.map((item, idx) => {
              return (
                <tr key={idx}>
                  {item?.row?.map((ele, n) => {
                    if (ele.prop == "check") {
                      return (
                        <th rowSpan={ele.rowSpan} colSpan={ele.colSpan} key={n}>
                          <Checkbox
                            onChange={(e) =>
                              onChange(e.target.checked, -1, "check")
                            }
                          />
                        </th>
                      );
                    } else
                      return (
                        <th rowSpan={ele.rowSpan} colSpan={ele.colSpan} key={n}>
                          {ele.label}
                        </th>
                      );
                  })}
                </tr>
              );
            })}
          </thead>
          <tbody>
            {tableData?.map((item, index) => {
              return (
                <tr
                  onDoubleClick={() => {
                    setShowingDetailRow(index);
                    ondbClick(index);
                  }}
                  className={
                    showingDetailRow === index ? "table-showing-detail" : ""
                  }
                  key={index}
                >
                  {/* 有空再换 */}
                  {/* {seaReportTableHeaderProp?.map((colItem, colIndex) => {
                    return (
                      <td
                        key={colIndex}
                      >
                        {colItem?.render
                          ? 
                          colItem?.render(
                              item?.[colItem?.dataIndex],
                              item,
                              idx,
                              actions,
                              otherSource
                            )
                          : item?.[colItem?.dataIndex] ?? "-"}
                        {colItem?.renderCheckbox ? colItem?.renderCheckbox(checkbox?.includes(item?.id), item, actions) : (colItem?.render ? colItem?.render(item?.[colItem?.dataIndex], item, idx, actions, dataSource?.[idx - 1]) : (item?.[colItem?.dataIndex] ?? "-"))}
                      </td>
                    );
                  })} */}

                  {seaReportTableHeaderProp.map((ele, m) => {
                    if (ele.dataIndex == "check") {
                      return (
                        <td key={m}>
                          <Checkbox
                            checked={selectRow[index].check}
                            // disabled={item.status== "Updated"}
                            onChange={(e) =>
                              onChange(e.target.checked, index, "check")
                            }
                          />
                        </td>
                      );
                    } else if (ele.dataIndex == "type") {
                      if (item.isEdit) {
                        return (
                          <td key={m}>
                            <FleetSelect
                              value={item[ele.dataIndex]}
                              options={typeOptions}
                              onChange={(e) => {
                                // if (e == "Noon Report") {
                                onChange(false, index, "showPortName");
                                // }
                                onChange(e, index, "type");
                              }}
                            ></FleetSelect>
                          </td>
                        );
                      } else {
                        return <td key={m}>{item[ele.dataIndex]}</td>;
                      }
                    } else if (
                      ["reportDateTime", "timeOfNextPort"].includes(
                        ele.dataIndex
                      )
                    ) {
                      if (item.isEdit && cantEdit(item, ele.dataIndex)) {
                        return (
                          <td key={m}>
                            <FleetTimePicker
                              allowClear
                              value={
                                item[ele.dataIndex]
                                  ? dayjs(item[ele.dataIndex])
                                  : null
                              }
                              onChange={(val: string) => {
                                onChange?.(
                                  dayjs(val).valueOf(),
                                  index,
                                  ele.dataIndex
                                );
                              }}
                            ></FleetTimePicker>
                          </td>
                        );
                      } else {
                        return (
                          <td key={m}>
                            {item[ele.dataIndex]
                              ? dayjs(item[ele.dataIndex]).format(
                                  "YYYY-MM-DD HH:mm"
                                )
                              : "--"}
                          </td>
                        );
                      }
                    } else if (["action"].includes(ele.dataIndex)) {
                      return (
                        <td key={m}>
                          {item.isEdit ? (
                            <div className="action-btn">
                              <Button
                                type="text"
                                size="small"
                                onClick={() => onHandleRow(index, "save")}
                              >
                                Save
                              </Button>
                              <Button
                                type="text"
                                size="small"
                                onClick={() => onHandleRow(index, "cancel")}
                              >
                                Cancel
                              </Button>
                            </div>
                          ) : (
                            <div>
                              <Button
                                type="text"
                                size="small"
                                onClick={() => onHandleRow(index, "edit")}
                              >
                                Edit
                              </Button>
                              <Popover
                                render={(setIsOpen) => {
                                  return (
                                    <FleetToast
                                      label="Are you sure delete this item?"
                                      onCancel={() => {
                                        setIsOpen(false);
                                      }}
                                      onConfirm={() => {
                                        onHandleRow(index, "delete");
                                        setIsOpen(false);
                                      }}
                                    />
                                  );
                                }}
                              >
                                <span
                                  className="action-btn-del font_family fleet-delete"
                                ></span>
                              </Popover>
                            </div>
                          )}
                        </td>
                      );
                    } else if (ele.dataIndex === "status") {
                      return (
                        <td key={m}>
                          <div className="state">
                            <div
                              className={
                                item[ele.dataIndex] == "Updated"
                                  ? "state-btn"
                                  : "state-btn state-btn-not"
                              }
                            >
                              {item[ele.dataIndex]}
                            </div>
                          </div>
                        </td>
                      );
                    } else if (ele.dataIndex == "alarm") {
                      return index == 0 ? (
                        <td key={m}></td>
                      ) : (
                        <td key={m} className="sea-reports-table-alarm">
                          <span
                            className={
                              item.warningNumber
                                ? "font_family fleet-warn"
                                : "font_family fleet-warn zero-warning"
                            }
                          ></span>
                          <span className="table-alarm-number">
                            {item.warningNumber || 0}
                          </span>
                        </td>
                      );
                    } else if (["positionLat"].includes(ele.dataIndex)) {
                      if (item.isEdit) {
                        return (
                          <td key={m}>
                            <LatOrLonInput
                              title="Latitude"
                              value={item[ele.dataIndex]}
                              onChange={(val) =>
                                onChange(val, index, ele.dataIndex)
                              }
                            ></LatOrLonInput>
                          </td>
                        );
                      } else {
                        return item.showPortName && item["portName"] ? (
                          <td
                            key={m}
                            onClick={() => {
                              if (item.type == "Noon Report") return;
                              onChange(false, index, "showPortName");
                            }}
                          >
                            {item["portName"]}
                          </td>
                        ) : (
                          <td
                            key={m}
                            onClick={() => {
                              if (item.type == "Noon Report") return;
                              onChange(true, index, "showPortName");
                            }}
                          >
                            {position(item[ele.dataIndex], ele.dataIndex)}
                          </td>
                        );
                      }
                    } else if (["positionLon"].includes(ele.dataIndex)) {
                      if (item.isEdit) {
                        return (
                          <td key={m}>
                            <LatOrLonInput
                              title="Longitude"
                              value={item[ele.dataIndex]}
                              onChange={(val) =>
                                onChange(val, index, ele.dataIndex)
                              }
                            ></LatOrLonInput>
                          </td>
                        );
                      } else {
                        return item.showPortName && item["portName"] ? (
                          <td key={m}></td>
                        ) : (
                          <td key={m}>
                            {position(item[ele.dataIndex], ele.dataIndex)}
                          </td>
                        );
                      }
                    } else {
                      if (item.isEdit && cantEdit(item, ele.dataIndex)) {
                        return (
                          <td
                            key={m}
                            className={
                              setWarningClass(item, ele.dataIndex)
                            }
                          >
                            <div className="table-input">
                              <FleetInput
                                value={item[ele.dataIndex]}
                                type={
                                  [
                                    "remark",
                                    "type",
                                    "windBF",
                                    "remarks",
                                  ].includes(ele.dataIndex)
                                    ? "text"
                                    : "number"
                                }
                                onChange={(val) =>
                                  onChange(val, index, ele.dataIndex)
                                }
                              ></FleetInput>
                            </div>
                          </td>
                        );
                      } else {
                        return (
                          <td
                            key={m}
                            className={
                              setWarningClass(item, ele.dataIndex)
                            }
                          >
                            {item[ele.dataIndex] || "--"}
                          </td>
                        );
                      }
                    }
                  })}
                </tr>
              );
            })}
            <tr className="table-blank"></tr>
          </tbody>
          <tfoot>
            <tr className="sea-reports-total">
              {seaReportTableHeaderProp.map((ele, index) => {
                if (index == 0) {
                  return (
                    <td colSpan={3} rowSpan={1} key={index}>
                      Summary
                    </td>
                  );
                } else if (index > seaReportTableHeaderProp.length - 3) {
                  return <td key={index}></td>;
                } else if (sumProps.includes(ele.dataIndex)) {
                  return <td key={index}>{totalSum[ele.dataIndex]}</td>;
                } else if (index > 2) {
                  return <td key={index}></td>;
                }
              })}
            </tr>
          </tfoot>
        </table>
      </div>
    </div>
  );
};

export default SeaReportsTable;
