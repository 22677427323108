import React, { FC } from "react";
import style from "./index.module.less";
import { PreOperationDetailsProps } from "./type";
import { FleetLoading } from "@/components";
import FleetTabs from "@/components/Tabs";
import { preOperationTabs } from "./source";
import useIndex from "./hooks/useIndex";
import {
	VoyageManageHandoverForm,
	VoyageManageVoyageRemark
} from "@/pages/voyagemanage/business/preOp&movement";
import dayjs from "dayjs";
import { Button } from "@/_components";
import { CommonRoleWrap } from "@/common";
import classnames from "classnames";

const PreOperationDetails: FC<PreOperationDetailsProps> = ({
	voyageType,
	activeItem,
	onAdd,
	onUpdate
}) => {
	const {
		loading,
		activeTab,
		handoverFormRef,
		voyageRemarkRef,
		handleActiveTabChange,
		handleSave,
		handleToMovement
	} = useIndex(activeItem?.id, voyageType, onAdd, onUpdate);

	return (
		<div className={style["preOperation-details"]}>
			<FleetLoading loading={loading} />
			<div className="preOperation-header">
				<div className="header-tags">
					<span>Pre-Operation</span>
					{voyageType && (
						<span className={classnames("voyage-type", voyageType)}>{voyageType}</span>
					)}
				</div>
				<div className="header-actions">
					<ul className="update-container">
						<li>Last Update:</li>
						<li>
							{activeItem?.updateTime
								? dayjs(activeItem?.updateTime)?.format("MM/DD/YY hh:mm")
								: ""}
						</li>
					</ul>
					<CommonRoleWrap roleStr="preOp:detail:save">
						<Button
							size="large"
							disabled={activeTab === "voyageRemark" && !activeItem?.id}
							type="secondary"
							prefixIcon="fleet-save"
							onClick={handleSave}
						>
							Save
						</Button>
					</CommonRoleWrap>
					<CommonRoleWrap roleStr="preOp:detail:moveToMovement">
						<Button
							prefixIcon="fleet-update1"
							size="large"
							onClick={handleToMovement}
							disabled={activeTab === "voyageRemark" && !activeItem?.id}
						>
							Move To Movement
						</Button>
					</CommonRoleWrap>
				</div>
			</div>
			<FleetTabs
				list={preOperationTabs}
				onClick={handleActiveTabChange}
				value={activeTab}
				className="preOperation-tabs"
			/>
			<div className="preOperation-conatainer">
				{activeTab === "handoverForm" ? (
					<VoyageManageHandoverForm
						voyageId={activeItem?.id}
						className="preOperation-handoverForm"
						type="preOp"
						ref={handoverFormRef}
						voyageType={voyageType}
					/>
				) : (
					<VoyageManageVoyageRemark
						voyageId={activeItem?.id}
						ref={voyageRemarkRef}
						type="preOp"
						voyageType={voyageType}
					/>
				)}
			</div>
		</div>
	);
};

export default PreOperationDetails;
