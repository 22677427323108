import React, { useState } from "react";
import { Button, Space, Select, DatePicker } from "antd";
import {
	CaretRightOutlined,
	CaretLeftOutlined,
	MenuUnfoldOutlined,
	MenuFoldOutlined
} from "@ant-design/icons";
import { useMonitoringContext } from "@/pages/monitoring/MonitoringContext";
import dayjs from "dayjs";
import { MeteoType, SwitchType } from "@/pages/wmap/type";
import style from "./index.module.less";
import { FleetIcon } from "@/components";
import { Tooltip as ReactTooltip } from "react-tooltip";
import useReminder from "@/hook/useReminder";
export type OperationBarProps = {
	collapsed: boolean;
	onPlay?: (startTime, callback) => void;
	onPause?: () => void;
	onSetNow?: (val: number) => void;
	onChangeMeteo?: (meteoType: string, switchType: SwitchType) => void;
	onChangeVoyageLayerAvtive?: (layerIds: string, active: boolean) => void;
	onCollopsed: () => void;
	getMeteoCalendar: () => object;
};

const control_status_initial = {
	Ports: {
		label: "Ports",
		active: false,
		layerIds: ["ports"]
	},
	Loadline: {
		label: "Load Line",
		active: false,
		layerIds: ["load-line-point", "load-line-polygon"]
	},
	Eca: {
		label: "ECA Zones",
		active: false,
		layerIds: ["eca-zones-point", "eca-zones-polygon"]
	},
	War: {
		label: "HRA/WAR Zones",
		active: false,
		layerIds: ["war-zones-point", "war-zones-polygon"]
	}
};

const timeOffset = dayjs().utcOffset();
const timeOffsetUnit = "minute";

const getUtcNow = () => {
	const nowUtc = dayjs().add(-timeOffset, timeOffsetUnit).minute(0).second(0);
	const hourFormat = Math.floor(nowUtc.hour() / 6) * 6;
	return nowUtc.hour(hourFormat);
};

const OperationBar: React.FC<OperationBarProps> = (props) => {
	const { reminder } = useReminder();

	const [isPlay, SetIsPlay] = useState(false);
	const [dateTime, setDateTime] = useState(getUtcNow()); // utc
	const [hours, SetHours] = useState(6);
	const { meteoStatus, setMeteoStatus } = useMonitoringContext();
	const [controlStatus, setControlStatus] = useState(control_status_initial);
	const handleSetNow = () => {
		const dtNow = getUtcNow();
		setDateTime(dtNow);
		props.onSetNow?.(dtNow.valueOf());
	};

	const handleChangeHours = (a) => {
		console.log("a", a);
		const dtNow = dateTime.add(hours * a, "h"); // utc

		// const meteoCalendar = props.getMeteoCalendar()
		const paramDate = dtNow.add(timeOffset, timeOffsetUnit).valueOf();
		// if (!(paramDate > meteoCalendar.start && paramDate < meteoCalendar.end)) {
		//   reminder('warning', "No more data")
		//   return
		// }
		setDateTime(dtNow);
		props.onSetNow?.(paramDate);
	};

	const handleChangeTime = (val) => {
		// const meteoCalendar = props.getMeteoCalendar()
		val = val.add(timeOffset, timeOffsetUnit);
		// let val_trans
		// val_trans = val.valueOf() > meteoCalendar.end ? dayjs(new Date(meteoCalendar.end)) : (val.valueOf() < meteoCalendar.start ? dayjs(new Date(meteoCalendar.start)) : val);

		setDateTime(val.add(-timeOffset, timeOffsetUnit));
		props.onSetNow?.(val.valueOf());
	};

	const onChangeMeteo = (meteoType: string, statu: boolean) => {
		const meteoStatusNew = JSON.parse(JSON.stringify(meteoStatus));
		meteoStatusNew[meteoType] = statu;
		if (meteoType == "Swell" && statu) {
			meteoStatusNew["Wave"] = !statu;
		}
		if (meteoType == "Wave" && statu) {
			meteoStatusNew["Swell"] = !statu;
		}
		setMeteoStatus(meteoStatusNew);
		props.onChangeMeteo?.(meteoType, statu ? SwitchType.On : SwitchType.Off);
	};

	const onChangeLayerShow = (layerName: string, active: boolean) => {
		const controlStatusNew = JSON.parse(JSON.stringify(controlStatus));
		controlStatusNew[layerName].active = active;
		setControlStatus(controlStatusNew);
		props.onChangeVoyageLayerAvtive?.(controlStatusNew[layerName].layerIds, active);
	};

	const disabledDate = (e) => {
		const meteoCalendar = props.getMeteoCalendar();
		const paramDate = e.add(timeOffset, timeOffsetUnit).valueOf();
		return !(paramDate > meteoCalendar.start && paramDate < meteoCalendar.end);
	};

	return (
		<div className={style.operationBar}>
			<Button type="link" onClick={props.onCollopsed} size="large">
				{props.collapsed ? <MenuUnfoldOutlined /> : <MenuFoldOutlined />}
			</Button>
			<Space className={style.operationBarTimeline}>
				<Button
					type={isPlay ? "primary" : "default"}
					onClick={() => {
						const newStatus = !isPlay;
						SetIsPlay(newStatus);
						if (newStatus) {
							props.onPlay?.(
								dateTime,
								(playTime) => {
									setDateTime(playTime);
								},
								hours
							);
						} else {
							props.onPause?.();
						}
					}}
				>
					{isPlay ? "Pause" : "PLAY"}
				</Button>
				<Button onClick={handleSetNow}>Today</Button>
				<Select
					value={hours}
					onChange={(val) => {
						SetHours(val);
					}}
					options={[
						{ value: 6, label: "6 hours" },
						{ value: 12, label: "12 hours" },
						{ value: 24, label: "24 hours" }
					]}
				/>
				<Button icon={<CaretLeftOutlined />} onClick={handleChangeHours.bind(this, -1)}></Button>
				<DatePicker
					// style={{ width: "160px" }}
					value={dateTime}
					showToday={false}
					format="YYYY-MM-DD HH:00"
					// disabledDate={disabledDate}
					onChange={handleChangeTime}
				/>
				<Button icon={<CaretRightOutlined />} onClick={handleChangeHours.bind(this, 1)}></Button>
			</Space>

			<Space className={style.operationBarMeteoControl}>
				{Object.keys(meteoStatus).map((meteoItem) => {
					return (
						<div
							data-tooltip-id="meteo-tooltip"
							data-tooltip-content={meteoItem === "Wave" ? "Sig.Wave" : meteoItem}
							key={meteoItem + "-button"}
						>
							<ReactTooltip
								style={{ zIndex: 100000, position: "absolute" }}
								place="bottom"
								id="meteo-tooltip"
							/>
							{!meteoStatus[meteoItem] ? (
								<FleetIcon
									outerLayerClassname={style.operationBarIconContainer}
									onClick={() => onChangeMeteo(meteoItem, !meteoStatus[meteoItem])}
									type={"Monitoring" + meteoItem + "Icon"}
								/>
							) : (
								<FleetIcon
									onClick={() => onChangeMeteo(meteoItem, !meteoStatus[meteoItem])}
									type={"Monitoring" + meteoItem + "CheckedIcon"}
								/>
							)}
						</div>
					);
				})}
				<span
					style={{
						border: "1px solid #ccc",
						margin: "5px",
						height: "24px",
						display: "inline-block"
					}}
				></span>
				{Object.keys(controlStatus).map((item) => {
					return (
						<div
							data-tooltip-id="meteo-tooltip"
							data-tooltip-content={controlStatus[item].label}
							key={item + "-control-button"}
						>
							<ReactTooltip
								style={{ zIndex: 100000, position: "absolute" }}
								place="bottom"
								id="meteo-tooltip"
							/>
							{!controlStatus[item]?.active ? (
								<FleetIcon
									outerLayerClassname={style.operationBarIconContainer}
									onClick={() => onChangeLayerShow(item, !controlStatus[item].active)}
									type={"Monitoring" + item + "Icon"}
								/>
							) : (
								<FleetIcon
									onClick={() => onChangeLayerShow(item, !controlStatus[item].active)}
									type={"Monitoring" + item + "CheckedIcon"}
								/>
							)}
						</div>
					);
				})}
			</Space>
		</div>
	);
};

export default OperationBar;
