import { AccountNewlyFormType } from "./type"
import React from "react"

export const columns: {
  title: string
  dataIndex: Exclude<keyof AccountNewlyFormType, "id" | "companyId">,
  width: number,
  fixed?: "left" | "right",
  required?: boolean,
  fixedWidth?: number,
  render?: (
    value: string | number,
    record: AccountNewlyFormType,
    index: number,
    actions?: {
      onRemove?: (record: AccountNewlyFormType) => void
    }
  ) => React.ReactNode
}[] = [
    {
      title: "Bank Name",
      dataIndex: "bankName",
      width: 186,
      fixed: "left",
      fixedWidth: 0,
      required: true
    },
    {
      title: "Bank Account Address",
      dataIndex: "bankAccountAddress",
      required: true,
      width: 208
    },
    {
      title: "Bank Account Number",
      dataIndex: "bankAccountNumber",
      width: 225,
      required: true
    },
    {
      title: "Beneficiary Name",
      dataIndex: "beneficiaryName",
      width: 136,
      required: true
    },
    {
      title: "Beneficiary Address",
      dataIndex: "beneficiaryAddress",
      width: 250,
      required: true
    },
    {
      title: "Beneficiary Company Address",
      dataIndex: "beneficiaryCompanyAddress",
      width: 250
    },
    {
      title: "Swift Code",
      dataIndex: "swiftNumber",
      width: 207
    },
    {
      title: "action",
      dataIndex: "bankAccountAddress",
      width: 70,
      fixed: "right",
      fixedWidth: 0,
      render: (value, record, index, actions) => {
        return <span
          className="font_family fleet-delete"
          onClick={() => actions?.onRemove?.(record)}
        ></span>
      }
    }
  ]

export const initialNewlyAccount = (company: { companyId: number, companyName: string }): AccountNewlyFormType => {
  return {
    ...company,
    bankName: "",
    bankAccountAddress: "",
    bankAccountNumber: "",
    beneficiaryAddress: "",
    beneficiaryCompanyAddress: "",
    beneficiaryName: "",
    swiftNumber: ""
  }
}

export const checkAccountKeys: (keyof AccountNewlyFormType)[] = [
  "bankName",
  "bankAccountAddress",
  "bankAccountNumber",
  "beneficiaryName",
  "beneficiaryAddress"
]