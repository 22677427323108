import { AxiosResponse } from "axios";
import { type ApiParameter } from ".";
import { appVersion } from "@/api/constant";

import request from "@/service";
import { Dispatch } from "@reduxjs/toolkit";
import { NavigateFunction } from "react-router-dom";

const enum ApiMap {
	loginParameter,
	logoutParameter
}

type ApiUrl = keyof typeof ApiMap;

const userApi: ApiParameter<ApiUrl> = {
	loginParameter: {
		method: "post",
		url: appVersion + "/user/login"
	},
	logoutParameter: {
		method: "post",
		url: "/gateway/sys" + "/logout"
	}
};

export type loginParams = {
	userName: string;
	password: string;
};

// export type ParamsType = {
// 	loginParams: loginParams;
// 	logoutParams: null;
// };

// export type RequestApiType<P extends ParamsType> = (params: P) => Promise<AxiosResponse<any, any>>;

// export type RenderApiType<T extends `${string}Parameter`> = T extends `${infer Prefix}Parameter`
// 	? `${Prefix}Api`
// 	: never;

// export type RenderParamsType<T extends `${string}Parameter`> = T extends `${infer Prefix}Parameter`
// 	? `${Prefix}Params`
// 	: never;

// export type ParameterActionType<
// 	ParameterType extends `${string}Parameter`,
// 	U = Dispatch<any>,
// 	N = NavigateFunction
// > = <T extends RenderApiType<ParameterType>, P extends RenderParamsType<ParameterType>>(
// 	type: T,
// 	params: P extends keyof ParamsType ? ParamsType[P] : null,
// 	frontCallback: (...args: any) => any,
// 	successCallback: (response?: any) => any,
// 	errorCallback: (response?: any) => any,
// 	dispatch?: U,
// 	navigate?: N
// ) => void;

// export const onRequest: ParameterActionType<ApiUrl> = (
// 	type,
// 	params,
// 	frontCallback,
// 	successCallback,
// 	errorCallback,
// 	dispatch,
// 	navigate
// ) => {
// 	frontCallback?.();
// 	requestActionMap[type]?.(params)
// 		?.then((response) => {
// 			successCallback?.(response);
// 		})
// 		?.catch((err) => {
// 			errorCallback?.(err);
// 			if (err?.loginStatus === "token faild") {
// 				// dispatch(loginOutAction(() => navigate?.("/login")));
// 			}
// 		});
// };

// export type RequestApiMapType<ParameterType extends `${string}Parameter`,ParamsType> = Record<
// 	RenderApiType<ParameterType>,
// 	<P extends RenderParamsType<ParameterType>>(
// 		params: P extends keyof ParamsType ? ParamsType[P] : null
// 	) => Promise<AxiosResponse<any, any>>
// >;

// export const loginApi = (params: loginParams) => {
//   return request.request({
//     ...userApi.loginParameter,
//     data: params
//   })
// }

export const loginApi = (params: loginParams) => {
	return request.request({
		...userApi.loginParameter,
		data: params
	});
};

export const logoutSysApi = () => {
	return request.request({
		...userApi.logoutParameter
	});
};

export const loginSysApi = (params: { username: string; password: string }) => {
	return request.request({
		method: "post",
		url: "/gateway/sys/login",
		data: params
	});
};
