import React, { createContext, useContext, useState } from "react";
import { AISInfo, DailyRepotyDataType, VesselInfo } from "@/pages/monitoring/components";

interface MonitoringProviderProps {
	value: {
		legendVisible: boolean;
		setLegendVisible?: (val: boolean) => void;
		abortController?: AbortController;
	};
	children?: React.ReactNode;
}

enum MeteoType {
	Wind = "wind",
	Wave = "wave",
	Swell = "swell1",
	Current = "current",
	Typhoon = "typhoon",
	Pressure = "pressure"
}

const meteoStatusInit = {};
Object.keys(MeteoType).forEach((item) => {
	meteoStatusInit[item] = false;
});

console.log("meteoStatusInit", meteoStatusInit);

console.log("meteoStatusInit", meteoStatusInit);

export const MonitoringContext = createContext<{
	legendVisible: boolean;
	dailyReportSelectRow?: DailyRepotyDataType[];
	meteoStatus: {};
	abortController?: AbortController;
	currentVessel?: VesselInfo;
	currentAis?: AISInfo;
	setLegendVisible?: (val: boolean) => void;
	setMeteoStatus?: (val: {}) => void;
	setDailyReportSelectRow?: (val: DailyRepotyDataType[]) => void;
	setCurrentVessel?: (val: {}) => void;
	setCurrentAis?: (val: AISInfo) => void;
}>({
	legendVisible: false,
	meteoStatus: meteoStatusInit
});

export const useMonitoringContext = () => {
	const context = useContext(MonitoringContext);
	return (
		context || {
			legendVisible: false,
			meteoStatus: meteoStatusInit
		}
	);
};
const MonitoringProvider: React.FC<MonitoringProviderProps> = ({ value, children }) => {
	const [meteoStatus, setMeteoStatus] = useState(meteoStatusInit);

	const token = {
		...value,
		meteoStatus,
		setMeteoStatus
	};

	return <MonitoringContext.Provider value={token}>{children}</MonitoringContext.Provider>;
};

export default MonitoringProvider;
