import React from "react";
import { type PageRouterProps } from "@/router";
import VoyageManageApar from "./business/apAr/index";
import VoyagemanageFixtureList from "./business/fixtureList";
import VoyagemanageInvoices from "./business/invoices";
import VoyagemanagePreoperation from "./business/preOperation";
import VoyagemanageCargoBook from "./business/cargoBook";
import VoyageManageMovementRouter from "./business/movement/router";

const VoyageManageRouter: PageRouterProps[] = [
  {
    path: "fixturelist",
    key: "/layout/voyagemanage/fixturelist",
    element: <VoyagemanageFixtureList />,
    label: "Head Fixture List"
  },
  {
    path: "cargobook",
    key: "/layout/voyagemanage/cargobook",
    element: <VoyagemanageCargoBook />,
    label: "Cargo Book"
  },
  {
    path: "preoperation",
    key: "/layout/voyagemanage/preoperation",
    element: <VoyagemanagePreoperation />,
    label: "Pre Operation"
  },
  ...VoyageManageMovementRouter,
  {
    path: "invoices",
    key: "/layout/voyagemanage/invoices",
    element: <VoyagemanageInvoices />,
    label: "Invoices"
  },
  {
    path: "apar",
    key: "/layout/voyagemanage/apar",
    element: <VoyageManageApar />,
    label: "AP&AR"
  }
];

export default VoyageManageRouter;