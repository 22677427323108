import React, { FC, useMemo } from "react";
import classnames from "classnames";
import style from "./index.module.less";
import { MonitoringAisItemProps } from "./type";

const AisItem: FC<MonitoringAisItemProps> = ({
	item,
	onItemClick,
	currentItem,
	onAisStarCancel
}) => {
	const isSelect = useMemo(() => {
		return currentItem?.imo === item.imo;
	}, [item, currentItem]);

	return (
		<div
			className={classnames(style["monitoring-aisItem"], {
				[style["monitoring-aisItem--active"]]: isSelect
			})}
			onClick={() => onItemClick?.(item)}
		>
			<div className="aisItem-header">
				<span>{item?.vesselName ?? item?.vesselNameEn}</span>
				<span
					className={classnames("font_family", "fleet-collected", "active")}
					onClick={(e) => {
						e?.stopPropagation?.();
						onAisStarCancel?.(item);
					}}
				></span>
			</div>
			<span className="aisItem-mmsi">mmsi: {item?.mmsi}</span>
		</div>
	);
};

export default AisItem;
