import React from "react";
import style from "./index.module.less";
import classNames from "classnames";

export type MovementDataType = Record<string, any>;

export type MovementTableColumnsType<
  DataType extends MovementDataType,
  OptionType extends {
    onChange?: (...args: any[]) => void,
    onCommit?: (...args: any[]) => void,
    [propName: string]: (...args: any[]) => void,
  } = {},
  OtherSourceType extends Record<string | number, keyof any> = {}
> = {
  width: number,
  dataIndex: string,
  fixed?: "left" | "right",
  fixedWidth?: number,
  label: string,
  zIndex?: number,
  render?: (
    value: string | number,
    record: DataType,
    index: number,
    actions?: OptionType,
    otherDataSource?: OtherSourceType
  ) => React.ReactNode,
  renderCheckbox?: (value: boolean, record: DataType, actions?: OptionType, checkAll?: boolean) => React.ReactNode
}

const MovementTable: React.FC<{
  columns: MovementTableColumnsType<MovementDataType>[],
  dataSource: any[],
  className?: string,
  checkbox?: number[],
  actions?: {
    onChange?: (...args: any[]) => void,
    onCommit?: (...args: any[]) => void,
    [propName: string]: (...args: any[]) => void,
  },
  renderSummay?: () => React.ReactNode;
}> = ({
  columns,
  dataSource,
  checkbox,
  className,
  renderSummay,
  actions
}) => {
    return <div className={classNames(style["movement-table"], className)}>
      <table className="table-layout">
        <colgroup>
          {
            columns?.map((item, idx) => {
              return <col style={{
                width: item?.width
              }} key={idx} />
            })
          }
        </colgroup>
        <thead className="table-thead">
          <tr>
            {
              columns?.map((item, idx) => {
                return <th
                  key={idx}
                  style={{
                    [item?.fixed]: item?.fixedWidth
                  }}
                >{item?.renderCheckbox ? item?.renderCheckbox(checkbox?.length !== 0 && checkbox?.length - 1 === dataSource?.length - 1, null, actions, true) : item?.label}</th>
              })
            }
          </tr>
        </thead>
        <tbody className="table-tbody">
          {
            dataSource?.map((item, idx) => {
              return <tr key={idx}>
                {
                  columns?.map((colItem, colIndex) => {
                    return <td
                      key={colIndex}
                      style={{
                        position: colItem?.fixed ? "sticky" : "relative",
                        [colItem?.fixed]: colItem?.fixedWidth,
                        zIndex: colItem?.zIndex
                      }}
                    >{colItem?.renderCheckbox ? colItem?.renderCheckbox(checkbox?.includes(item?.id), item, actions) : (colItem?.render ? colItem?.render(item?.[colItem?.dataIndex], item, idx, actions,dataSource?.[idx - 1]) : (item?.[colItem?.dataIndex] ?? "-"))}</td>
                  })
                }
              </tr>
            })
          }
          {renderSummay && renderSummay?.()}
        </tbody>
      </table>
    </div>
  }

export default MovementTable