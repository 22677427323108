import { useEffect, useState } from "react";
import { FleetFieldAddFormType, FleetFieldAddProps, FleetFieldsChangeEvent, initialFleetFieldAdd } from "./type";
import useReminder from "@/hook/useReminder";
import { CommonChangeEvent } from "@/types/event";

const useFieldAdd = (
  hide: FleetFieldAddProps["hide"],
  onAdd: FleetFieldAddProps["onAdd"]
) => {
  const { reminder } = useReminder()
  const [fields, setFields] = useState<FleetFieldAddFormType>({
    ...initialFleetFieldAdd
  });

  const change: CommonChangeEvent<FleetFieldAddFormType> = (key, value) => {
    setFields(prev => ({
      ...prev, [key]: value
    }));
  }

  const handleAddField = () => {
    if (fields?.fieldName?.length <= 0) {
      reminder("warning", "Field Title is empty!");
      return
    }
    onAdd?.(fields);
  }

  useEffect(() => {
    if (!hide) return;
    setFields({
      ...initialFleetFieldAdd
    })
  }, [hide])

  return {
    fields,
    change,
    handleAddField
  }

}

export default useFieldAdd