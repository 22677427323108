import React, { FC } from "react";
import classnames from "classnames";
import style from "./index.module.less";
import { AreaListFilterProps } from "./type";
import { Button } from "@/_components";
import { options } from "./source";
import useIndex from "./hooks/useIndex";
import { FleetFormItem, FleetRangePicker } from "@/components";
import dayjs, { Dayjs } from "dayjs";
const AreaListFilter: FC<AreaListFilterProps> = ({
	filter,
	activeIndex,
	selectedRows,
	onCommit,
	onDownLoad,
	onTimeRange
}) => {
	const { optionList, loading, timeRange, loadMore, onDropdown, focusPopup, handleTimeRange } =
		useIndex(activeIndex, onTimeRange);
	return (
		<div className={classnames(style["area-list-filter"])}>
			<div className="areaListFilter-header">
				<FleetFormItem label="Period">
					<FleetRangePicker
						allowClear
						value={[
							timeRange.startDate ? dayjs(timeRange.startDate) : null,
							timeRange.endDate ? dayjs(timeRange.endDate) : null
						]}
						onChange={(vals: [Dayjs, Dayjs]) => {
							handleTimeRange({
								startDate: vals?.[0].format("YYYY-MM-DD"),
								endDate: vals?.[1].format("YYYY-MM-DD")
							});
						}}
						disabledDate={(current) => {
							return current && current < dayjs().subtract(1, "day").endOf("day");
						}}
					/>
				</FleetFormItem>
				{options?.map((item, index) => {
					return (
						<FleetFormItem label={item.label}>
							{item?.render(
								item?.value,
								filter,
								{ onCommit, loadMore, onDropdown, focusPopup },
								optionList,
								loading
							)}
						</FleetFormItem>
					);
				})}

				<Button
					className="filterHeader-download"
					type="secondary"
					size="small"
					disabled={selectedRows.length > 0 ? false : true}
					onClick={onDownLoad}
					prefixIcon="fleet-download"
				>
					Download
				</Button>
			</div>
		</div>
	);
};

export default AreaListFilter;
