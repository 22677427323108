import React from "react";
import classnames from "classnames";
import style from "./index.module.less";
import { HfPaymentTermsFormProps } from "./type";
import { HeadFixtueLabelMap } from "@/types/voyageManage/headFixtureList";
import { FleetDatePicker, FleetFormItem, FleetVerifyInput } from "@/components";
import dayjs from "dayjs";

const HfPaymentTermsForm: React.FC<HfPaymentTermsFormProps> = ({
  item,
  onChange
}) => {
  return <div className={classnames(style["headFixture-payment"], "headFixture-form")}>
    <span className="vm-common-title">Payment Terms</span>
    <div className="form-container">
      {/* <FleetVerifyInput
        label={HeadFixtueLabelMap["paymentTerm"]}
        needVerity
        value={item?.paymentTerm}
        onChange={(val: number) => {
          onChange?.("paymentTerm", val)
        }}
        dimension={<div className={style["headFixture-paymentTerm-tooltip"]}>
          How many days of Hire first payment shoudl be settled
        </div>}
      />
      <FleetVerifyInput
        label={HeadFixtueLabelMap["paymentRate"]}
        needVerity
        value={item?.paymentRate}
        onChange={(val: number) => {
          onChange?.("paymentRate", val)
        }}
        dimension={<div className={style["headFixture-paymentTerm-tooltip"]}>
          How many days First Payment Need to Be Paid After Vessel's Delivery.
        </div>}
      /> */}
      <FleetFormItem
        label={HeadFixtueLabelMap["paymentStartDay"]}
      >
        <FleetDatePicker
          // disabled
          allowClear
          value={item?.paymentStartDay ? dayjs(item?.paymentStartDay) : null}
          onChange={(val: string) => {
            onChange?.("paymentStartDay", val)
          }}
        />
      </FleetFormItem>
    </div>
  </div>
}

export default HfPaymentTermsForm;