import { useMemo, useState } from "react";
import { MovementBunkerPortCostProps } from "../type";
import { getAdjustDiff, getOilCost } from "../tools";
import { MovementTctcBunkerPlanType } from "../../../type";

const useIndex = (
	otherSource: MovementBunkerPortCostProps["otherSource"] &
		Pick<MovementTctcBunkerPlanType, "scrubberBenefit" | "scrubberPrice">
) => {
	const [dataSource, setDataSource] = useState<MovementBunkerPortCostProps["items"]>([]);

	const hsfoDataSource = useMemo(() => {
		return dataSource?.find((item) => item?.oilType === 1);
	}, [dataSource]);

	const summary = useMemo(() => {
		let bunkerCount = 0;
		return dataSource?.reduce(
			(prev, curr, index, arr) => {
				const { adjustCost } = getAdjustDiff(curr, otherSource);
				const bunkerCost = getOilCost(curr, otherSource);
				if (+curr?.bunkerSummaryPrice > 0) {
					bunkerCount += 1;
				}
				return {
					atjCost: prev?.atjCost + adjustCost,
					bunkerSummaryPrice:
						index < arr?.length - 1
							? +prev?.bunkerSummaryPrice + +curr?.bunkerSummaryPrice
							: (+prev?.bunkerSummaryPrice + +curr?.bunkerSummaryPrice) / (bunkerCount || 1),
					bunkerSummaryCost:
						prev?.bunkerSummaryCost + +curr?.bunkerSummaryQuantity * +curr?.bunkerSummaryPrice,
					bunkerSummaryConsum:
						prev?.bunkerSummaryConsum +
						+curr?.bunkerSummaryQuantity +
						+curr?.bodQuantity -
						+curr?.borQuantity,
					totolCost: prev?.totolCost + bunkerCost
				};
			},
			{
				atjCost: 0,
				bunkerSummaryPrice: 0,
				bunkerSummaryCost: 0,
				bunkerSummaryConsum: 0,
				totolCost: 0
			}
		);
	}, [dataSource, otherSource]);

	console.log("summary", summary);

	const change: MovementBunkerPortCostProps["onChange"] = (currentIndex, key, value) => {
		setDataSource((prev) => {
			const currentItem = prev?.[currentIndex];
			prev?.splice(currentIndex, 1, {
				...currentItem,
				[key]: value
			});
			return [...prev];
		});
	};

	const commit: MovementBunkerPortCostProps["onCommit"] = (currentIndex, item) => {
		setDataSource((prev) => {
			const currentItem = prev?.[currentIndex];
			prev?.splice(currentIndex, 1, {
				...currentItem,
				...item
			});
			return [...prev];
		});
	};

	const init = (items: MovementBunkerPortCostProps["items"]) => {
		setDataSource(items ?? []);
	};

	const handleBorPriceCommit = () => {
		setDataSource((prev) =>
			prev?.map((item) => {
				return {
					...item,
					borPrice: item?.bodPrice
				};
			})
		);
	};

	const handleCalcTotalCost = () => {
		return dataSource?.reduce(
			(prev, curr) => {
				switch (true) {
					case curr?.oilType === 1:
						prev.hsfoTotalPrice = getOilCost(curr, otherSource);
						otherSource?.scrubberBenefit === 1 &&
							(prev.scrubberTotalPrice =
								(+curr?.bunkerSummaryQuantity + +curr?.bodQuantity - +curr?.borQuantity) *
								+otherSource?.scrubberPrice);
						break;
					case curr?.oilType === 2:
						prev.lsfoTotalPrice = getOilCost(curr, otherSource);
						break;
					case curr?.oilType === 3:
						prev.mdoTotalPrice = getOilCost(curr, otherSource);
						break;
					case curr?.oilType === 4:
						prev.mgoTotalPrice = getOilCost(curr, otherSource);
						break;
					default:
						break;
				}
				return prev;
			},
			{
				hsfoTotalPrice: 0,
				lsfoTotalPrice: 0,
				mdoTotalPrice: 0,
				mgoTotalPrice: 0,
				scrubberTotalPrice: 0
			}
		);
	};

	return {
		summary,
		hsfoDataSource,
		dataSource,
		commit,
		change,
		handleBorPriceCommit,
		handleCalcTotalCost,
		init
	};
};

export default useIndex;
