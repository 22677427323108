import { ParameterType } from "@/api";
import {
  queryEstTemplateGroupParams,
  deleteEstTemplateParams,
  saveEstTemplateParams,
  queryEstTemplateGroupApi,
  deleteEstTemplateApi,
  saveEstTemplateApi
} from "@/api/estimation/template";
import { loginOutAction } from "@/store/userSlice";

export const queryEstTemplateGroupAction: ParameterType<queryEstTemplateGroupParams> = (
  params,
  frontCallback,
  successCallback,
  errorCallback,
  dispatch,
  navigate
) => {
  frontCallback?.();
  queryEstTemplateGroupApi(params).then(response => {
    successCallback?.(response)
  }).catch(err => {
    errorCallback?.(err);
    if (err?.loginStatus === 'token faild') {
      dispatch(loginOutAction(() => navigate?.("/login")))
    }
  })
}

export const deleteEstTemplateAction: ParameterType<deleteEstTemplateParams> = (
  params,
  frontCallback,
  successCallback,
  errorCallback,
  dispatch,
  navigate
) => {
  frontCallback?.();
  deleteEstTemplateApi(params).then(response => {
    successCallback?.(response)
  }).catch(err => {
    errorCallback?.(err);
    if (err?.loginStatus === 'token faild') {
      dispatch(loginOutAction(() => navigate?.("/login")))
    }
  })
}

export const saveEstTemplateAction: ParameterType<saveEstTemplateParams> = (
  params,
  frontCallback,
  successCallback,
  errorCallback,
  dispatch,
  navigate
) => {
  frontCallback?.();
  saveEstTemplateApi(params).then(response => {
    successCallback?.(response)
  }).catch(err => {
    errorCallback?.(err);
    if (err?.loginStatus === 'token faild') {
      dispatch(loginOutAction(() => navigate?.("/login")))
    }
  })
}