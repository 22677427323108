import { FleetSelect } from "@/components";
import useSelect from "@/hook/common/useNoSelect";
import React, { useEffect } from "react";

export type NoSelectOption = {
  label: string;
  value: string;
}

export type NoSelectProps = {
  label: string,
  type: "headFixture" | "cargoBook" | "company",
  mode?: "multiple",
  initItem?: NoSelectOption | NoSelectOption[],
  onFinish?: (
    item: NoSelectOption | NoSelectOption[] | null,
    newItem: NoSelectOption,
    type: "add" | "del"
  ) => void,
  className?: string;
  allowClear?: boolean;
  disabled?: boolean;
  onClear?: () => void;
}

const NoSelect: React.FC<NoSelectProps> = ({
  label,
  disabled,
  mode,
  type,
  initItem,
  onFinish,
  className,
  allowClear,
  onClear
}) => {
  const {
    loading,
    open,
    selectValue,
    loadMore,
    options,
    closePopup,
    focusPopup,
    tagRender,
    itemSelect,
    initLable,
    search,
  } = useSelect(label, type, mode ? true : false, onFinish);

  const handleClear = () => {
    if (onClear) {
      initLable([]);
      onClear?.()
    }
  }

  useEffect(() => {
    initLable(initItem)
  }, [initItem])

  return <FleetSelect
    showSearch
    disabled={disabled}
    loading={loading}
    value={selectValue}
    className={className}
    options={options}
    allowClear={allowClear}
    onClear={handleClear}
    onDropdownVisibleChange={closePopup}
    mode={mode}
    open={open}
    onSearch={search}
    onFocus={() => {
      // debugger
      focusPopup()
    }}
    tagRender={tagRender}
    onSelect={itemSelect}
  />
}

export default NoSelect