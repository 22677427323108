import { savePreopTctcHandoverFormAction, savePreopTcvcHandoverFormAction } from "@/action/voyageManage/preOperation"
import { VoyageManageHandoverFormRefType } from "@/pages/voyagemanage/business/preOp&movement/business/handoverForm/type"
import { useEffect, useRef, useState } from "react"
import { useAppDispatch } from "@/hook";
import { useNavigate } from "react-router-dom";
import useReminder from "@/hook/useReminder";
import { HandoverTctcFormItemType, HandoverTcvcFormItemType } from "@/pages/voyagemanage/business/preOp&movement";
import { MovementHandoverProps } from "../type";

const useIndex = (
	voyageId: MovementHandoverProps["voyageId"],
	voyageType: MovementHandoverProps["voyageType"],
	hide: boolean,
	onClose?: MovementHandoverProps["onClose"]
) => {
	const dispatch = useAppDispatch();
	const navigate = useNavigate();
	const { reminder } = useReminder();
	const [loading, setLoading] = useState(false);
	const handoverFormRef = useRef<VoyageManageHandoverFormRefType>(null);

	const savePreopHandoverFormFront = () => {
		setLoading(true);
	};

	const savePreopHandoverFormSuccess = (response) => {
		setLoading(false);
		reminder("success", response?.msg);
		onClose?.();
	};

	const savePreopHandoverFormFailed = (error) => {
		setLoading(false);
		reminder("error", error?.msg ? error?.msg + ": " + error?.data : error?.data);
	};

	const savePreopTcvcHandoverForm = () => {
		const params = handoverFormRef?.current?.combineParams() as HandoverTcvcFormItemType;
		savePreopTcvcHandoverFormAction(
			{ ...params },
			savePreopHandoverFormFront,
			savePreopHandoverFormSuccess,
			savePreopHandoverFormFailed,
			dispatch,
			navigate
		);
	};

	const savePreopTctcHandoverForm = () => {
		const params = handoverFormRef?.current?.combineParams() as HandoverTctcFormItemType;
		savePreopTctcHandoverFormAction(
			{ ...params },
			savePreopHandoverFormFront,
			savePreopHandoverFormSuccess,
			savePreopHandoverFormFailed,
			dispatch,
			navigate
		);
	};

	const savePreopHandoverForm = () => {
		if (!handoverFormRef?.current?.check()) return;
		switch (true) {
			case voyageType === "TCVC":
				savePreopTcvcHandoverForm();
				break;
			case voyageType === "TCTC":
				savePreopTctcHandoverForm();
				break;
			default:
				break;
		}
	};

	useEffect(() => {
		if (hide) return;
		if (!voyageId) return;
		handoverFormRef?.current?.reset();
	}, [hide, voyageId]);

	return {
		loading,
		handoverFormRef,
		savePreopHandoverForm
	};
};

export default useIndex