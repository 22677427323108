import {
	RouteCommonVesselLoadEvent,
	RouteCommonVesselTrackLoadEvent,
	RouteVoyageTabType
} from "@routeCommon/type";
import {
	RouteCommonSelectTimeKeys,
	RouteCommonVesselCardItemType,
	RouteCommonVesselCardItemProps
} from "../type";
import { useEffect, useState } from "react";
import { useAppDispatch } from "@/hook";
import { useNavigate } from "react-router-dom";
import useReminder from "@/hook/useReminder";
import { onRequest } from "@/action";
import { RouteCommonVesselCardOperatorProps } from "../components";
import { RouteLegItemType } from "@/pages/routeCommon/components";
import { useRouteCommonContext } from "@/pages/routeCommon/store";
import { getRangeTime, getRangeType } from "../tools";

const useIndex = (
	mode: RouteVoyageTabType,
	activeItem: RouteCommonVesselCardItemProps["activeItem"],
	onSelect: RouteCommonVesselCardItemProps["onSelect"],
	onTrackRefresh: RouteCommonVesselCardItemProps["onTrackRefresh"],
	onAisListReset: RouteCommonVesselCardItemProps["onAisListReset"]
) => {
	const dispatch = useAppDispatch();
	const navigate = useNavigate();
	const { reminder } = useReminder();

	const { activeDetailsOption, onVesselTrackLoad, onDetailsOptionCommit, onAdditionalCommit } =
		useRouteCommonContext();

	const [loading, setLoading] = useState(false);
	const [dataSource, setDataSource] = useState<RouteCommonVesselCardItemType>(null);

	const [rangeOption, setRangeOption] = useState<RouteCommonVesselCardOperatorProps["rangeOption"]>(
		{ startDate: null, endDate: null }
	);
	const [activeTime, setActiveTime] = useState<RouteCommonSelectTimeKeys>("7/day");

	const handleRangeCommit: RouteCommonVesselCardOperatorProps["onRangeCommit"] = (vals) => {
		const params = getRangeTime(activeTime);
		let timeType: 0 | 1 = 0;
		if (vals) {
			params.startDate = vals?.[0]?.format("YYYY-MM-DD HH:mm:ss");
			params.endDate = vals?.[1]?.format("YYYY-MM-DD HH:mm:ss");
			timeType = getRangeType({ startDate: vals?.[0], endDate: vals?.[1] });
			setRangeOption({ ...params });
		} else {
			setRangeOption({ startDate: null, endDate: null });
		}
		handleTrackRefresh({ ...params, type: timeType }, "tracking");
	};

	const handleSaveCollection: RouteCommonVesselCardOperatorProps["onSave"] = (item, groupId) => {
		if (item?.isCollect) {
			onRequest(
				"cancleRouteCommonAisCollectionApi",
				{
					imo: item?.imo
				},
				getVesselCardInfoFront,
				(response) => {
					setDataSource((prev) => ({ ...prev, isCollect: 0 }));
					setLoading(false);
					onAisListReset?.();
				},
				getVesselCardInfoFailed,
				dispatch,
				navigate
			);
			return;
		}
		onRequest(
			"updateRouteCommonAisCollectionApi",
			{
				groupId: groupId,
				imo: item?.imo
			},
			getVesselCardInfoFront,
			(response) => {
				setDataSource((prev) => ({ ...prev, isCollect: 1 }));
				setLoading(false);
				onAisListReset?.();
			},
			getVesselCardInfoFailed,
			dispatch,
			navigate
		);
	};

	const handleTrackRefresh = (
		params: RouteCommonVesselCardOperatorProps["rangeOption"] & { type: 0 | 1 },
		source: Parameters<RouteCommonVesselTrackLoadEvent>[2]
	) => {
		const currentVessel =
			typeof activeItem?.currentState === "string"
				? JSON.parse(activeItem?.currentState)
				: activeItem?.currentState;
		onTrackRefresh?.(
			{
				...params,
				imo: currentVessel?.imo
			},
			{
				front: () => {
					setLoading(true);
				},
				success: (response) => {
					setLoading(false);
					onAdditionalCommit?.({ rangeOption: params });
					onVesselTrackLoad?.(mode, response?.data, source);
				},
				error: (error) => {
					setLoading(false);
					reminder("error", error?.msg ? error?.msg + ": " + error?.data : error?.data);
				}
			}
		);
	};

	const handleTimeSelect = (value: RouteCommonSelectTimeKeys) => {
		if (activeTime === value && (!rangeOption.startDate || !rangeOption?.endDate)) return;
		setActiveTime(value);
		const params = getRangeTime(value);
		setRangeOption({ startDate: null, endDate: null });
		handleTrackRefresh({ ...params, type: 0 }, "tracking");
	};

	const handleToolbarOpen: RouteCommonVesselCardOperatorProps["onOpen"] = (toolType) => {
		// console.log("handleToolbarOpen", currentAisData);
		if (toolType === "particular") {
			onSelect?.(mode, "particular");
			return;
		}
		const params =
			rangeOption?.startDate && rangeOption?.endDate ? rangeOption : getRangeTime(activeTime);
		onSelect?.(mode, "ais", {
			...params,
			type: getRangeType(rangeOption)
		});
	};

	const handlePortCallOpen: RouteCommonVesselCardOperatorProps["onPortCallOpen"] = (item) => {
		console.log("activeDetailsOption", activeDetailsOption, item);
		if (activeDetailsOption?.currentImo === item?.imo && !activeDetailsOption?.isPortCallVisible)
			return;
		onDetailsOptionCommit?.({
			isPortCallVisible: true,
			currentImo: item?.imo,
			portCallLabel: mode
		});
	};

	const getVesselCardInfoFront = () => {
		setLoading(true);
		if (rangeOption?.startDate && rangeOption?.endDate) {
			setRangeOption({ startDate: null, endDate: null });
		}
	};

	const getVesselCardInfoSuccess = (response) => {
		setDataSource(response?.data);
		setLoading(false);
		const params = getRangeTime(activeTime);
		handleTrackRefresh({ ...params, type: 0 }, "collect");
	};

	const getVesselCardInfoFailed = (error) => {
		setLoading(false);
		reminder("error", error?.msg ? error?.msg + ": " + error?.data : error?.data);
	};

	const getVesselCardInfo = () => {
		console.log("activeItem", activeItem);
		if ((mode as "leg") === "leg") {
			onRequest(
				"getRouteCommonVesselLegInfoApi",
				{
					voyageNo: (activeItem as RouteLegItemType).voyageNo,
					type: (activeItem as RouteLegItemType).vesselStatus
				},
				getVesselCardInfoFront,
				getVesselCardInfoSuccess,
				getVesselCardInfoFailed,
				dispatch,
				navigate
			);
			return;
		}
		const currentVessel =
			typeof activeItem?.currentState === "string"
				? JSON.parse(activeItem?.currentState)
				: activeItem?.currentState;
		onRequest(
			"getRouteCommonVesselInfoApi",
			{
				imo: currentVessel?.imo
			},
			getVesselCardInfoFront,
			getVesselCardInfoSuccess,
			getVesselCardInfoFailed,
			dispatch,
			navigate
		);
	};

	useEffect(() => {
		if (!activeItem) return;
		getVesselCardInfo();
	}, [activeItem]);

	return {
		loading,
		dataSource,
		activeTime,
		handleTimeSelect,
		handleToolbarOpen,
		handlePortCallOpen,
		handleSaveCollection,
		rangeOption,
		handleRangeCommit
	};
};

export default useIndex;
