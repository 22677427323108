import dayjs, { Dayjs, ManipulateType } from "dayjs";
import { RouteCommonSelectTimeKeys } from "./type";
import { RouteCommonVesselCardOperatorProps } from "./components";

export const getRangeTime = (selectTime: RouteCommonSelectTimeKeys) => {
	const [count, rule] = selectTime?.split("/") as [number, ManipulateType];
	console.log("dayjs", dayjs()?.format("YYYY-MM-DD HH:mm:ss"));
	const params: RouteCommonVesselCardOperatorProps["rangeOption"] = {
		endDate: dayjs()?.format("YYYY-MM-DD HH:mm:ss"),
		startDate: dayjs()?.subtract(count, rule)?.format("YYYY-MM-DD HH:mm:ss")
	};
	return params;
};

export const getRangeType = (
	rangeOption: Record<keyof RouteCommonVesselCardOperatorProps["rangeOption"], string | Dayjs>
): 0 | 1 => {
	if (!rangeOption?.startDate || !rangeOption?.endDate) return 0;
	const selectEndDate = dayjs(rangeOption?.endDate);
	return selectEndDate?.isSame(dayjs(), "minute") ||
		(selectEndDate?.isBefore(dayjs(), "minute") &&
			selectEndDate?.isAfter(dayjs()?.subtract(1, "hour"), "minute"))
		? 0
		: 1;
};
