import { useMemo, useRef, useState } from "react";
import { SeaReportTableDataItem } from "../type";
const useSeaReports = (onChange, tableData: SeaReportTableDataItem[], onHandleRow) => {
	const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([]);
	const [showingDetailRow, setShowingDetailRow] = useState<number>();
	const onSelectChange = (newSelectedRowKeys: React.Key[]) => {
		console.log("selectedRowKeys changed: ", newSelectedRowKeys);
		setSelectedRowKeys(newSelectedRowKeys);
	};
	const rowSelection = {
		selectedRowKeys,
		onChange: onSelectChange
	};
	const editRow = (index, type: "save" | "edit" | "cancel") => {
		if (["save", "cancel"].includes(type)) {
			onChange(false, index, "isEdit");
		} else {
			onChange(true, index, "isEdit");
		}
	};
	const position = (value, prop: "positionLat" | "positionLon") => {
		if (value == 0) {
			return "0°0'";
		}
		if (!value) {
			return "--";
		}
		const val = value.toString().replace("-", "");
		if (prop == "positionLat") {
			return (
				val.split(".")[0] +
				"°" +
				(Number(`0.${val.split(".")[1] || 0}`) * 60).toFixed(1) +
				"'" +
				(value < 0 ? "S" : "N")
			);
		} else {
			return (
				val.split(".")[0] +
				"°" +
				(Number(`0.${val.split(".")[1] || 0}`) * 60).toFixed(1) +
				"'" +
				(value < 0 ? "W" : "E")
			);
		}
	};
	const setWarningClass = (row, prop) => {
		let className = "";
		if (
			((prop == "brobMdo" && row.mdoWarning) ||
				(prop == "brobMgo" && row.mgoWarning) ||
				(prop == "brobLsfo" && row.lsfoWarning) ||
				(prop == "brobHfo" && row.hsfoWarning) ||
				(prop == "dailyAvgSpeed" && row.speedWarning)) &&
			row.warningNumber
		) {
			className += row[prop] ? "warning-text " : "";
		}
		if (
			(["meHfoSlr", "meLsfoSlr", "auxHfoSlr", "auxLsfoSlr"].includes(prop) &&
				row.segmentFoWarning == 2) ||
			(["meMdoSlr", "meMgoSlr", "auxMdoSlr", "auxMgoSlr"].includes(prop) &&
				row.segmentDoWarning == 2) ||
			(["dailyAvgSpeed"].includes(prop) && row.segmentSpeedWarning == 2)
		) {
			className += row[prop] ? "warning-bg-weight " : "";
		}
		if (
			(["meHfoSlr", "meLsfoSlr", "auxHfoSlr", "auxLsfoSlr"].includes(prop) &&
				row.segmentFoWarning == 1) ||
			(["meMdoSlr", "meMgoSlr", "auxMdoSlr", "auxMgoSlr"].includes(prop) &&
				row.segmentDoWarning == 1) ||
			(["dailyAvgSpeed"].includes(prop) && row.segmentSpeedWarning == 1)
		) {
			className += row[prop] ? "warning-bg-light " : "";
		}
		return className;
	};
	const cantEdit = (row, prop) => {
		console.log(prop);
		if (row.type == "COSP") {
			return ![
				"course",
				"seaTimeSteam",
				"seaTimeStop",
				"seaDistance",
				"dailyAvgSpeed",
				"windDir",
				"windBF",
				"seaDir",
				"seaDSS",
				"meRpm",
				"tcRpm",
				"meOutput",
				"mePower",
				"meLoadSlr",
				"slip",
				"meHfoSlr",
				"meLsfoSlr",
				"meMdoSlr",
				"meMgoSlr",
				"auxHfoSlr",
				"auxLsfoSlr",
				"auxMdoSlr",
				"auxMgoSlr",
				"meRevolutionCounter",
				"foFlowMeter",
				"reportDateStr",
				"positionStr"
			].includes(prop);
		} else if (row.type == "EOSP") {
			return !["timeOfNextPort", "reportDateStr", "positionStr"].includes(prop);
		} else {
			return !["reportDateStr", "positionStr"].includes(prop);
		}
	};
	return {
		rowSelection,
		editRow,
		showingDetailRow,
		setShowingDetailRow,
		position,
		setWarningClass,
		cantEdit
	};
};
export default useSeaReports;
