import { useRef, useState } from "react";
import { PortCallListFilterDataType } from "../components/filter/type";
import { initPortCallListFilterData } from "../components/filter/source";
import { PortCallListItemType, PortCallListTableProps } from "../components/table/type";
import useReminder from "@/hook/useReminder";
import { useAppDispatch } from "@/hook";
import { useNavigate } from "react-router-dom";
import { getPortCallListAction, downPortCallAction } from "@/action/monitoring/portCallList";
import { MonitoringPortCallListProps } from "../type";

const useIndex = (currentVessel: MonitoringPortCallListProps["currentVessel"]) => {
	const { reminder } = useReminder();
	const dispatch = useAppDispatch();
	const navigate = useNavigate();
	const [loading, setLoading] = useState<boolean>(false);
	const [portCallListFilterData, setPortCallListFilterData] = useState<PortCallListFilterDataType>(
		initPortCallListFilterData()
	);
	const pageNum = useRef<number>(1);
	const totalNum = useRef<number>(0);
	const [portCallListTable, setPortCallListTable] = useState<PortCallListTableProps["dataList"]>(
		[]
	);
	const handleCommitFilterData = (item: Partial<PortCallListFilterDataType>) => {
		setPortCallListFilterData((prev) => {
			return { ...prev, ...item };
		});
		getPortCallList({ ...portCallListFilterData, ...item });
	};
	const portCallListFront = () => {
		setLoading(true);
	};
	const portCallListError = (error) => {
		setLoading(false);
		reminder("error", error?.msg ? error?.msg + ": " + error?.data : error?.data);
	};

	const handleDownLoad = () => {
		// let data: PortCallListItemType[] = [];
		// portCallListTable.map((item) => {
		// 	data = data.concat(item?.dataList);
		// });
		downPortCallAction(
			portCallListTable,
			portCallListFront,
			() => {
				setLoading(false);
			},
			portCallListError,
			dispatch,
			navigate
		);
	};
	const getPortCallListSuccess = (res, type: "LoadMore" | "" = "") => {
		setLoading(false);
		console.log(res.data, "77777");
		let data = [];
		totalNum.current = res?.data?.totalCount ?? 0;
		if (res?.data?.dateGroupDataList) {
			res.data.dateGroupDataList.map((item) => {
				data = data.concat(item?.dataList ?? []);
			});
			if (type == "LoadMore") {
				setPortCallListTable((prev) => {
					const originData = prev.concat(data);
					return [...originData];
				});
			} else setPortCallListTable(data);
		}
	};
	const getPortCallList = (param?: PortCallListFilterDataType, type: "LoadMore" | "" = "") => {
		if (!currentVessel?.imo) return;
		getPortCallListAction(
			{
				imo: currentVessel?.imo,
				startDate: param?.startDate,
				endDate: param?.endDate,
				pageNum: pageNum.current,
				pageSize: 20
			},
			portCallListFront,
			(res) => getPortCallListSuccess(res, type),
			portCallListError,
			dispatch,
			navigate
		);
	};
	const loadMore = () => {
		if (!(pageNum.current * 20 < totalNum.current)) {
			reminder("warning", "no more data");
			return;
		}
		++pageNum.current;
		getPortCallList(portCallListFilterData, "LoadMore");
	};
	return {
		portCallListFilterData,
		portCallListTable,
		handleCommitFilterData,
		handleDownLoad,
		getPortCallList,
		loading,
		loadMore,
		pageNum
	};
};

export default useIndex;
