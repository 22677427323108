import { onRequest } from "@/action";
import { useAppDispatch, useCommonIndex } from "@/hook";
import useReminder from "@/hook/useReminder";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { MovementTctcOtherItemType, MovementTctcProps } from "../type";
import useDialogIndex from "./useDialog";
import { initialMovementTctcOtherItem } from "../source";
import { VoyageStatusType } from "../../../type";
import { editMovementVoyageStatusAction } from "@/action/voyageManage/movement";
import { useVesselBasicsIndex } from "../components";
import { useSettingsIndex } from "../../../common";
import usePnl from "./usePnl";

const useIndex = (
	voyageId: MovementTctcProps["voyageId"],
	onLoadingChange: MovementTctcProps["onLoadingChange"]
) => {
	const dispatch = useAppDispatch();
	const navigate = useNavigate();
	const { reminder } = useReminder();

	const { dataSource: dialogGroup, change: handleDialogChange } = useDialogIndex();

	const [refresh, setRefresh] = useState(false);

	const {
		dataSource: otherItem,
		change: handleOtherItemChange,
		commit: handleOtherItemCommit,
		init: handleOtherItemInit
	} = useCommonIndex<MovementTctcOtherItemType>(initialMovementTctcOtherItem);

	const {
		dataSource: rentVesselBasics,
		commit: handleRentVesselBasicCommit,
		change: handleRentVesselBasicChange,
		init: handleRentVesselBasicInit
	} = useVesselBasicsIndex();

	const {
		dataSource: reletVesselBasics,
		commit: handleReletVesselBasicCommit,
		change: handleReletVesselBasicChange,
		init: handleReletVesselBasicInit
	} = useVesselBasicsIndex();

	const {
		dataSource: settingsItem,
		commit: handleSettingsCommit,
		change: handleSettingsChange,
		init: handleSettingsInit
	} = useSettingsIndex();

	const {
		totalRevenues,
		totalCost,
		totalDisputeCharge,
		totalProfit,
		totalRevenue,
		tce,
		totalRentCost,
		otherExpenses,
		rentCommission,
		rentBrokerage,
		reletCommission,
		reletBrokerage,
		rentHireCost,
		reletHireCost,
		rentTotalBunkerCost,
		reletTotalBunkerCost,
		disputeCharges,
		handleCombineParams
	} = usePnl(rentVesselBasics, reletVesselBasics, otherItem);

	const handleStatusChange = (val: VoyageStatusType) => {
		// if (otherForm?.status === "Closed") {
		//   reminder("error", "Voyage was closed");
		//   return
		// }
		editMovementVoyageStatusAction(
			{
				id: voyageId,
				status: val
			},
			getMovementTctcFront,
			(response) => {
				handleOtherItemChange("status", val);
				onLoadingChange?.(false);
				reminder("success", response?.msg);
			},
			getMovementTctcError,
			dispatch,
			navigate
		);
	};

	const getMovementTctcFront = () => {
		onLoadingChange?.(true);
	};

	const getMovementTctcSuccess = (response) => {
		const { reletVesselBasics, rentVesselBasics, setting, ...restDataSource } = response?.data;
		handleRentVesselBasicInit(rentVesselBasics);
		handleReletVesselBasicInit(reletVesselBasics);
		handleSettingsInit(setting);
		handleOtherItemInit(restDataSource);
		onLoadingChange?.(false);
	};

	const getMovementTctcError = (error) => {
		onLoadingChange?.(false);
		reminder("error", error?.msg ? error?.msg + ": " + error?.data : error?.data);
	};

	const saveMovementTctcSuccess = (response) => {
		reminder("success", response?.msg);
		getMovementTctc();
		setRefresh(!refresh);
	};

	const getMovementTctc = () => {
		onRequest(
			"getMovementTctcApi",
			{ id: voyageId },
			getMovementTctcFront,
			getMovementTctcSuccess,
			getMovementTctcError,
			dispatch,
			navigate
		);
	};

	const saveMovementTctc = () => {
		onRequest(
			"saveMovementTctcApi",
			{
				...otherItem,
				rentVesselBasics: rentVesselBasics,
				reletVesselBasics: reletVesselBasics,
				setting: settingsItem,
				stat: {
					tce,
					totalProfit,
					totalRevenue,
					reletBrokerage,
					reletCommission,
					rentBrokerage,
					rentCommission,
					rentHireCost,
					reletHireCost,
					otherExpenses: otherExpenses?.total,
					reletBunkerCost: reletTotalBunkerCost,
					rentBunkerCost: rentTotalBunkerCost,
					otherIncome: otherItem?.otherIncome,
					disputeCharges: disputeCharges.total,
					totalCost: totalRentCost
				}
			},
			getMovementTctcFront,
			saveMovementTctcSuccess,
			getMovementTctcError,
			dispatch,
			navigate
		);
	};

	const handlePnlDownloadSuccess = (response) => {
		console.log("response", response?.msg);
		reminder("success", response?.msg);
	};

	const handlePnlDownload = () => {
		const params = handleCombineParams({
			voyageId,
			voyageNo: otherItem?.voyageNo,
			vesselName: rentVesselBasics?.vesselName,
			charterName: reletVesselBasics?.ownerName,
			ownerName: rentVesselBasics?.ownerName,
			charterCpDate: reletVesselBasics?.cpDate,
			ownerCpDate: rentVesselBasics?.cpDate
		});
		onRequest(
			"downloadMovementTctcPnlApi",
			params,
			null,
			handlePnlDownloadSuccess,
			getMovementTctcError,
			dispatch,
			navigate
		);
	};

	useEffect(() => {
		getMovementTctc();
	}, [voyageId]);

	return {
		dialogGroup,
		handleDialogChange,
		otherItem,
		handleOtherItemChange,
		handleOtherItemCommit,
		handleStatusChange,
		rentVesselBasics,
		handleRentVesselBasicCommit,
		handleRentVesselBasicChange,
		reletVesselBasics,
		handleReletVesselBasicCommit,
		handleReletVesselBasicChange,
		settingsItem,
		handleSettingsCommit,
		handleSettingsChange,
		saveMovementTctc,
		totalRevenues,
		totalCost,
		totalDisputeCharge,
		handlePnlDownload,
		refresh,
		calculate: {
			totalProfit,
			totalRevenue,
			totalCost: totalRentCost,
			rentHireCost,
			reletIncome: reletHireCost,
			disputeCharge: disputeCharges?.total
		}
	};
};

export default useIndex;
