import React, { useState } from "react";
import style from "./index.module.less";
import { FleetPrompt } from "@/common";
import { FleetButton, FleetFormItem, FleetIcon, FleetLoading, FleetVerifyInput } from "@/components";
import useFreighSensitivity, { useCountRate, type FreightSenOriginSource, FreightSenProps } from "@/types/estimation/details/freighSensitivity";
import classnames from "classnames";
import { toThousands } from "@/tools";

type FrTableItem = { rate: number, res: number }

const FrTable: React.FC<{
  type: "frt" | "tce",
  result: FrTableItem[]
}> = ({
  type,
  result
}) => {
    return <div className={style["fr-table"]}>
      <div className="fr-headTr">
        <span>{type === "frt" ? "Freight Rate" : "TCE"}</span>
        <span>{type === "frt" ? "TCE" : "Freight Rate"}</span>
      </div>
      {
        result?.map((item: FrTableItem, idx: number) => {
          return <div key={idx} className={classnames("fr-tr", {
            "fr-tr__tce": type === "frt"
          })}>
            <span>{toThousands(item.rate)}</span>
            <span>{toThousands(item.res)}</span>
          </div>
        })
      }
    </div>
  }

const FreightSensitivity: React.FC<{
  hide?: boolean;
  onClose?: () => void;
  midValueGroup: {
    freightRate: number | "",
    tce: number
  },
  id: string | number;
  originSource: FreightSenOriginSource;
  onConfirm: (data: FreightSenProps) => void
}> = ({
  hide,
  id,
  onClose,
  originSource,
  onConfirm,
  midValueGroup
}) => {
    const {
      freightSen,
      loading,
      change: handleFreightSenChange,
      updateFreightSen: handleFreightSenUpdate,
    } = useFreighSensitivity(id, hide, midValueGroup, onConfirm);

    const { tceResulGroup, frtResulGroup } = useCountRate({
      frtMidValue: +freightSen?.frtMidValue,
      tceMidValue: freightSen?.tceMidValue,
      frtStep: freightSen?.frtStep,
      tceStep: freightSen?.tceStep
    }, originSource)

    return <FleetPrompt onClose={onClose} hide={hide} mode="fixed">
      <div className={style["esdetails-freightSen"]}>
        <FleetLoading loading={loading} />
        <div className="prompt-header freightSen-header">
          <span className="header-title">Freight Sensitivity</span>
          <FleetIcon
            type="FleetCloseIcon"
            outerLayerClassname="header-close"
            onClick={onClose}
          />
        </div>
        <div className="freightSen-container">
          <div className="freightSen">
            <div className="freightSen-label">
              <span>Freight Rate</span>
            </div>
            <FleetVerifyInput
              value={freightSen.frtMidValue}
              label="Mid Value"
              type="number"
              containerClassname="freightSen-input"
              onChange={(value: number) => {
                handleFreightSenChange("frtMidValue", value)
              }}
            />
            <FleetVerifyInput
              value={freightSen.frtStep}
              label="Step"
              type="number"
              containerClassname="freightSen-input"
              onChange={(value: number) => {
                handleFreightSenChange("frtStep", value)
              }}
            />
            <div className="freightSen-label freightSen-label__result">
              <span>Result</span>
            </div>
            <FrTable
              result={tceResulGroup}
              type="frt"
            />
          </div>
          <div className="freightSen">
            <div className="freightSen-label freightSen-label__tce">
              <span>TCE</span>
            </div>
            <FleetVerifyInput
              value={freightSen.tceMidValue}
              type="number"
              label="Mid Value"
              containerClassname="freightSen-input"
              onChange={(value: number) => {
                handleFreightSenChange("tceMidValue", value)
              }}
            />
            <FleetVerifyInput
              value={freightSen.tceStep}
              label="Step"
              type="number"
              containerClassname="freightSen-input"
              onChange={(value: number) => {
                handleFreightSenChange("tceStep", value)
              }}
            />
            <div className="freightSen-label freightSen-label__tce freightSen-label__result">
              <span>Result</span>
            </div>
            <FrTable type="tce" result={frtResulGroup} />
          </div>
        </div>
        <div className="freightSen-footer">
          <FleetButton onClick={handleFreightSenUpdate}>Confirm</FleetButton>
        </div>
      </div>
    </FleetPrompt>
  }

export default FreightSensitivity;