import React, { FC } from "react";
import style from "./index.module.less";
import { RoutePortCurrentItemProps } from "./type";
import { ArrivalVesselSubTypeMap } from "@/common/Select";

const RoutePortCurrentItem: FC<RoutePortCurrentItemProps> = ({ item }) => {
	return (
		<div className={style["route-portCurrentItem"]}>
			<div className="portCurrentItem-header">
				<span className="portCurrentItem-vesselType">
					{ArrivalVesselSubTypeMap[item?.vesselTypeSub]}
				</span>
				<span className="portCurrentItem-vesselTotal">{item?.totalCount ?? 0} Vessels</span>
			</div>
			<ul className="portCurrentItem-container">
				<li>
					<span>Mooring:</span>
					<span>{item?.mooring ?? 0}</span>
				</li>
				<li>
					<span>At Berth:</span>
					<span>{item?.berth ?? 0}</span>
				</li>
			</ul>
		</div>
	);
};

export default RoutePortCurrentItem;
