import React, { FC } from "react";
import { FleetSelect } from "@/components";
import { CommonVesselSelectProps } from "./type";
import useIndex from "./hooks/useIndex";
import { CommonVesselPopup } from "./components";

const CommonVesselSelect: FC<CommonVesselSelectProps> = ({
  onFinish,
  activeItems,
  showKey,
  showCount,
  className,
  disabled,
  placeholder,
  primaryKey = "id",
}) => {
  const {
    open,
    loadMore,
    loading,
    list,
    tagRender,
    handleItemSelect,
    handleSearch,
    focusPopup,
    selectValue,
    closePopup
  } = useIndex(
    activeItems,
    showKey,
    onFinish,
    primaryKey,
    disabled
  )

  return <FleetSelect
    value={selectValue}
    showSearch
    popupMatchSelectWidth={false}
    onDropdownVisibleChange={closePopup}
    mode={Array?.isArray(activeItems) ? "multiple" : undefined}
    open={open}
    onSearch={handleSearch}
    onFocus={() => focusPopup()}
    className={className}
    placeholder={placeholder}
    disabled={disabled}
    showCount={showCount}
    tagRender={tagRender}
    dropdownRender={() => <CommonVesselPopup
      loadMore={loadMore}
      list={list}
      loading={loading}
      onSelect={handleItemSelect}
    />}
  />
}

export default CommonVesselSelect;

