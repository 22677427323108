import { useState } from "react"
import { BunkerClauseProps } from "../type"
import { initialBunkerClause } from "../source"


const useIndex = () => {
  const [dataSource, setDataSource] = useState<BunkerClauseProps["item"]>(initialBunkerClause())

  const change: BunkerClauseProps["onChange"] = (key, value) => {
    setDataSource(prev => ({ ...prev, [key]: value }))
  }

  const commit: BunkerClauseProps["onCommit"] = (item) => {
    setDataSource(prev => ({ ...prev, ...item }))
  }

  const init: BunkerClauseProps["onCommit"] = (item) => {
    setDataSource(initialBunkerClause(item))
  }

  return {
    dataSource,
    change,
    commit,
    init
  }
}

export default useIndex