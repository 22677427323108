import {
	getAreaInfoListAction,
	getAreaInfoSelectListAction,
	updateAreaSelectListAction,
	clearAreaSelectListAction
} from "@/action/monitoring/area";
import { useAppDispatch } from "@/hook";
import useReminder from "@/hook/useReminder";
import { useEffect, useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";
import { AreaItemType, MonitoringAreaListProps } from "../type";
import { debounce } from "lodash";

const useIndex = (
	activeTab: MonitoringAreaListProps["activeTab"],
	areaSelectItems: MonitoringAreaListProps["items"],
	onAreaDelete: MonitoringAreaListProps["onAreaDelete"],
	onAreaReset: MonitoringAreaListProps["onAreaReset"],
	onAreaSelect: MonitoringAreaListProps["onAreaSelect"],
	onOpenDetailTab: MonitoringAreaListProps["onOpenDetailTab"]
) => {
	const dispatch = useAppDispatch();
	const navigate = useNavigate();
	const { reminder } = useReminder();
	const [loading, setLoading] = useState(false);
	const [areaList, setAreaList] = useState<AreaItemType[]>([]);

	const getAreaInfoListFront = () => {
		setLoading(true);
	};

	const getAreaInfoListSuccess = (response) => {
		setLoading(false);
		setAreaList(response?.data);
	};

	const getAreaSelectListSuccess = (response) => {
		setLoading(false);
		onAreaReset?.(response?.data);
		onOpenDetailTab?.("areaVesslDetails");
	};

	const updateAreaSelectListSuccess = (response) => {
		setLoading(false);
		const currentIndex = areaList?.findIndex((item) => item?.areaCode === response?.data?.areaCode);
		const currentItem = areaList?.[currentIndex];
		setAreaList((prev) => {
			prev?.splice(currentIndex, 1, {
				...currentItem,
				...response?.data
			});
			return [...prev];
		});
		console.log("test123", response.data);

		if (response?.data?.isCurrentSelect === 1) {
			onAreaSelect?.({
				...currentItem,
				...response?.data
			});
		} else {
			onAreaDelete?.(currentItem);
		}
		onOpenDetailTab?.("areaVesslDetails");
	};

	const getAreaInfoListFailed = (error) => {
		setLoading(false);
		reminder("error", error?.msg ? error?.msg + ": " + error?.data : error?.data);
	};
	const handleInput = useMemo(() => {
		const loadOptions = (val: string) => {
			setAreaList([]);
			getAreaInfoList(val);
		};
		return debounce(loadOptions, 800);
	}, []);

	const handleCheckChange = (item: AreaItemType) => {
		const currentIndex = areaList?.findIndex(
			(selectItem) => selectItem?.areaCode === item?.areaCode
		);
		const currentItem = areaList?.[currentIndex];
		updateAreaSelectList({
			...currentItem,
			isCurrentSelect: currentItem?.isCurrentSelect ? 0 : 1
		});
	};

	const getAreaInfoList = (val?: string) => {
		getAreaInfoListAction(
			{ keyword: val },
			getAreaInfoListFront,
			getAreaInfoListSuccess,
			getAreaInfoListFailed,
			dispatch,
			navigate
		);
	};

	const getAreaInfoSelectList = () => {
		getAreaInfoSelectListAction(
			null,
			getAreaInfoListFront,
			getAreaSelectListSuccess,
			getAreaInfoListFailed,
			dispatch,
			navigate
		);
	};

	const updateAreaSelectList = (areaList: AreaItemType) => {
		updateAreaSelectListAction(
			areaList,
			getAreaInfoListFront,
			updateAreaSelectListSuccess,
			getAreaInfoListFailed,
			dispatch,
			navigate
		);
	};

	const handleClearAreaSelect = (type: "single" | "mutil", selectItem?: AreaItemType) => {
		clearAreaSelectListAction(
			type === "single" ? [selectItem?.areaCode] : areaSelectItems?.map((item) => item?.areaCode),
			getAreaInfoListFront,
			(response) => {
				if (type === "single") {
					onAreaDelete?.(selectItem);
				} else {
					onAreaReset?.();
				}
				setLoading(false);
				getAreaInfoList();
			},
			getAreaInfoListFailed,
			dispatch,
			navigate
		);
	};

	useEffect(() => {
		if (activeTab !== "area") {
			setAreaList([]);
			return;
		}
		getAreaInfoList();
		getAreaInfoSelectList();
	}, [activeTab]);

	return {
		loading,
		areaList,
		handleInput,
		handleCheckChange,
		handleClearAreaSelect
	};
};
export default useIndex;
