import React from "react";
import classnames from "classnames";
import style from "./index.module.less";
import { FleetLoading } from "@/components";
import useIndex from "./hooks/useIndex";
import { MovementTctcDetails, MovemoentTcvcDetails } from "./business";

const MovemoentDetails: React.FC<{}> = (props) => {
	const { voyageId, voyageType, loading, setLoading } = useIndex();
	return (
		<div className={classnames("voyagemanage-common", style["movement-details"])}>
			<FleetLoading loading={loading} />
			{voyageType === "TCVC" ? (
				<MovemoentTcvcDetails voyageId={voyageId} loading={loading} onLoadingChange={setLoading} />
			) : voyageType === "TCTC" ? (
				<MovementTctcDetails voyageId={voyageId} loading={loading} onLoadingChange={setLoading} />
			) : (
				<></>
			)}
		</div>
	);
};

export default MovemoentDetails;
