import { type ParameterType } from "@/api";
import { loginOutAction } from "@/store/userSlice";

import {
  getAisDataApi,
  getAisDataParams,
  getAisVesselTrackApi,
  getAisVesselTrackParams,
  getThirdPartyTrackApi,
  getAisApi,
  getAisParams,
  getVesselBaseInfoApi,
  getVesselBaseInfoParams,
} from "@/api/estimation/aisData";

import {
  getVesselArchivesApi,
  getVesselArchivesParams
} from "@/api/estimation/index"

export const getAisDataAction: ParameterType<getAisDataParams> = (
  params,
  frontCallback,
  successCallback,
  errorCallback,
  dispatch,
  navigate
) => {
  frontCallback?.();
  getAisDataApi(params).then(response => {
    successCallback?.(response)
  }).catch(err => {
    errorCallback?.(err);
    if (err?.loginStatus === 'token faild') {
      // dispatch?.(loginOut({}));
      // navigate?.('/')
      dispatch(loginOutAction(() => navigate?.("/login")))
    }
  })
}

export const getAisVesselTrackAction: ParameterType<getAisVesselTrackParams> = (
  params,
  frontCallback,
  successCallback,
  errorCallback,
  dispatch,
  navigate
) => {
  frontCallback?.();
  getAisVesselTrackApi(params).then(response => {
    successCallback?.(response)
  }).catch(err => {
    errorCallback?.(err);
    if (err?.loginStatus === 'token faild') {
      // dispatch?.(loginOut({}));
      // navigate?.('/')
      dispatch(loginOutAction(() => navigate?.("/login")))
    }
  })
}

export const getThirdPartyTrackAction: ParameterType<string> = (
  params,
  frontCallback,
  successCallback,
  errorCallback,
  dispatch,
  navigate
) => {
  frontCallback?.();
  getThirdPartyTrackApi(params).then(response => {
    successCallback?.(response)
  }).catch(err => {
    errorCallback?.(err);
    if (err?.loginStatus === 'token faild') {
      // dispatch?.(loginOut({}));
      // navigate?.('/')
      dispatch(loginOutAction(() => navigate?.("/login")))
    }
  })
}

export const getAisAction: ParameterType<getAisParams> = (
  params,
  frontCallback,
  successCallback,
  errorCallback,
  dispatch,
  navigate
) => {
  frontCallback?.();
  getAisApi(params).then(response => {
    successCallback?.(response)
  }).catch(err => {
    errorCallback?.(err);
    if (err?.loginStatus === 'token faild') {
      // dispatch?.(loginOut({}));
      // navigate?.('/')
      dispatch(loginOutAction(() => navigate?.("/login")))
    }
  })
}

export const getVesselBaseInfoAction: ParameterType<getVesselBaseInfoParams> = (
  params,
  frontCallback,
  successCallback,
  errorCallback,
  dispatch,
  navigate
) => {
  frontCallback?.();
  getVesselBaseInfoApi(params).then(response => {
    successCallback?.(response)
  }).catch(err => {
    errorCallback?.(err);
    if (err?.loginStatus === 'token faild') {
      dispatch(loginOutAction(() => navigate?.("/login")))
    }
  })
}


export const getVesselArchivesAction: ParameterType<getVesselArchivesParams> = (
  params,
  frontCallback,
  successCallback,
  errorCallback,
  dispatch,
  navigate
) => {
  frontCallback?.();
  getVesselArchivesApi(params).then(response => {
    successCallback?.(response)
  }).catch(err => {
    errorCallback?.(err);
    if (err?.loginStatus === 'token faild') {
      dispatch(loginOutAction(() => navigate?.("/login")))
    }
  })
}