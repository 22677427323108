import React, { FC, useMemo } from "react";
import style from "./index.module.less";
import charterBaseStyle from "../index.module.less";
import { MovementCargoProps } from "./type";
import MovementTable from "../../common/table";
import { actualColumns, estColumns } from "./source";
import classnames from "classnames";

const MovementCargo: FC<MovementCargoProps> = ({
	activeType,
	actualItems,
	estItems,
	onActualChange,
	onActualCommit,
	onEstChange,
	onEstCommit
}) => {
	const columns = useMemo(() => {
		return activeType === "actual" ? actualColumns : estColumns;
	}, [activeType]);

	const dataSource = useMemo(() => {
		return activeType === "actual" ? actualItems : estItems;
	}, [activeType, actualItems, estItems]);
	return (
		<div
			className={classnames(
				charterBaseStyle["movement-charterBase--container"],
				style["movement-cargo"]
			)}
		>
			<div className="movement-details--header">
				<span className="fleet-title">Cargo</span>
			</div>
			<MovementTable
				dataSource={dataSource}
				columns={columns}
				className="cargo-container movement-charterBase--table"
				actions={{
					onChange: activeType === "actual" ? onActualChange : onEstChange,
					onCommit: activeType === "actual" ? onActualCommit : onEstCommit
				}}
			/>
		</div>
	);
};

export default MovementCargo;
