import React from "react";
import { type PageRouterProps } from "@/router";
import DatabaseContact from "./business/contact";
import DatabaseVessel from "./business/vessel";
import DatabasePort from "./business/port";
import DatabaseTask from "./business/task";
import DatabasePortDa from "./business/portDa";
import DatabaseCompany from "./business/company";
import DatabaseAccount from "./business/account";
import DatabaseVesselArchives from "./business/vesselArchives";

const DatabaseRouter: PageRouterProps[] = [
  {
    // index: true,
    path: "vessel",
    key: "/layout/database/vessel",
    element: <DatabaseVessel />,
    label: "Vessel"
  },
  {
    path: "port",
    key: "/layout/database/port",
    element: <DatabasePort />,
    label: "Port"
  },
  {
    path: "task",
    key: "/layout/database/task",
    element: <DatabaseTask />,
    label: "Task"
  },
  {
    path: "portDa",
    element: <DatabasePortDa />,
    label: "Port DA",
    key: "/layout/database/portDa",
  },
  {
    path: "company",
    element: <DatabaseCompany />,
    label: "Company",
    key: "/layout/database/company",
  },
  {
    path: "account",
    element: <DatabaseAccount />,
    label: "Account",
    key: "/layout/database/account",
  },
  {
    path: "contact",
    key: "/layout/database/contact",
    element: <DatabaseContact />,
    label: "Contact"
  },
  {
    path: "vesselarchives",
    key: "/layout/database/vesselarchives",
    element: <DatabaseVesselArchives />,
    label: "VesselArchives"
  },
]

export default DatabaseRouter