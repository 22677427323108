import { ApiParameter, RequestApiMapType } from "@/api";
import request from "@/service";

import {
	MovementBunkerCostParamsType,
	getMovementBunkerCostParams,
	saveMovementBunkerCostParams
} from "./type";
import { appVersion } from "@/api/constant";

const enum ApiMap {
	getMovementBunkerCostParameter,
	saveMovementBunkerCostParameter
}

export type ApiUrl = keyof typeof ApiMap;

const bunkerPlanApi: ApiParameter<ApiUrl> = {
	getMovementBunkerCostParameter: {
		method: "get",
		url: appVersion + "/move/dialog/bunker/plan"
	},
	saveMovementBunkerCostParameter: {
		method: "put",
		url: appVersion + "/move/dialog/bunker/plan"
	}
};

export const getMovementBunkerCostApi = (params: getMovementBunkerCostParams) => {
	return request.request({
		...bunkerPlanApi.getMovementBunkerCostParameter,
		url: `${bunkerPlanApi.getMovementBunkerCostParameter.url}/${params?.voyageId}`,
		params: {
			type: params?.type
		}
	});
};

export const saveMovementBunkerCostApi = (params: saveMovementBunkerCostParams) => {
	return request.request({
		...bunkerPlanApi.saveMovementBunkerCostParameter,
		data: params
	});
};

export const bunkerPlanApiMap: RequestApiMapType<ApiUrl, MovementBunkerCostParamsType> = {
	getMovementBunkerCostApi,
	saveMovementBunkerCostApi
};
