import {
	EstPortRotationItemType,
	EstVesselParticularType
} from "@/pages/estimation/module";
import { ExtendsKeys } from "@/types";
import { PortPurposeType } from "@/types/common";

export const isBallast = (purpose: PortPurposeType) => {
	return ["LD", "LB", "LT"].includes(purpose);
};

export const isLaden = (purpose: PortPurposeType) => {
	return ["DC", "DB", "DT"].includes(purpose);
};

export const matchPortType = (purpose: PortPurposeType) => {
	switch (true) {
		case isBallast(purpose):
			return "ballast";
		case isLaden(purpose):
			return "laden";
	}
};

export const calcSeaValues = <
	K extends ExtendsKeys<
		keyof EstPortRotationItemType,
		"distance" | "seaDays" | "ecaDistance" | "insideEcaDays"
	>
>(
	item: EstPortRotationItemType,
	vesselItem: Pick<EstVesselParticularType, "ballastSpeed" | "ladenSpeed">,
	key: K,
	value: EstPortRotationItemType[K]
) => {
	const params: Partial<
		Pick<EstPortRotationItemType, "distance" | "seaDays" | "ecaDistance" | "insideEcaDays">
	> = {
		[key]: value
	};
	const portType = matchPortType(item?.purpose);
	const speed = portType === "ballast" ? vesselItem?.ballastSpeed : vesselItem?.ladenSpeed;
	switch (key) {
		case "distance":
			const seaDays = speed ? +value / speed / 24 : 0;
			params.seaDays = +seaDays?.toFixed(3);
			break;
		case "ecaDistance":
			const insideEcaDays = speed ? +value / speed / 24 : 0;
			params.insideEcaDays = +insideEcaDays?.toFixed(3);
			break;
		case "insideEcaDays":
			const ecaDistance = +speed * 24 * +value;
			params.ecaDistance = +ecaDistance?.toFixed(3);
			break;
		case "seaDays":
			const distance = +speed * 24 * +value;
			params.distance = +distance?.toFixed(3);
			break;
		default:
			break;
	}
	return params;
};
