import { type ApiParameter } from "@/api";
import { appVersion } from "@/api/constant";
import request from "@/service";
import {
	queryEstTemplateGroupParams,
	deleteEstTemplateParams,
	saveEstTemplateParams
} from "./type";

const enum ApiMap {
	queryEstTemplateGroupParameter,
	deleteEstTemplateParameter,
	saveEstTemplateParameter
}

type ApiUrl = keyof typeof ApiMap;

const templateApi: ApiParameter<ApiUrl> = {
	queryEstTemplateGroupParameter: {
		method: "get",
		url: appVersion + "/est/vc/vessel/template/query"
	},
	deleteEstTemplateParameter: {
		method: "delete",
		url: appVersion + "/est/vc/vessel/template"
	},
	saveEstTemplateParameter: {
		method: "post",
		url: appVersion + "/est/vc/vessel/template"
	}
};

export const queryEstTemplateGroupApi = (params: queryEstTemplateGroupParams) => {
	return request.request({
		...templateApi.queryEstTemplateGroupParameter,
		params
	});
};

export const deleteEstTemplateApi = (params: deleteEstTemplateParams) => {
	return request.request({
		...templateApi.deleteEstTemplateParameter,
		url: `${templateApi.deleteEstTemplateParameter.url}/${params?.id}`
	});
};

export const saveEstTemplateApi = (params: saveEstTemplateParams) => {
	return request.request({
		...templateApi.saveEstTemplateParameter,
		data: params
	});
};
