import React, { FC } from "react";
import style from "./index.module.less";
import { MovementFinancialDataProps } from "./type";
import { renderFinancialData } from "./tools";
import { Button } from "@/_components";
import classnames from "classnames";

const MovementFinancialData: FC<MovementFinancialDataProps> = ({
	totalRevenuesItem,
	totalCostItem,
	totalDisputeChargeItem,
	activePlan,
	onPnlDowload,
	voyageType
}) => {
	return (
		<div
			className={classnames(style["movement-financialData"], {
				[style["movement-financialData-tctc"]]: voyageType === "TCTC"
			})}
		>
			<div className="movement-details--header">
				<span className="fleet-title">Financial Data</span>
				<Button size="minuscule" prefixIcon="fleet-download" onClick={onPnlDowload}>
					Download
				</Button>
			</div>
			<div className="financialData-container">
				<div className="financialData-scrollArea">
					<ul className="financialData-item--common financialData-header">
						<li>Description</li>
						{voyageType === "TCVC" && <li>{activePlan?.planName ?? "Plan"}</li>}
						<li>Actual</li>
						<li>(%)</li>
						<li>Posted</li>
						<li>(%)</li>
					</ul>
					{renderFinancialData(voyageType, totalRevenuesItem)}
					{renderFinancialData(voyageType, totalCostItem)}
					{renderFinancialData(voyageType, totalDisputeChargeItem)}
				</div>
			</div>
		</div>
	);
};

export default MovementFinancialData;
