import { FleetOptionType } from "@/types/advancedType";
import { RouteCommonDetailsTabType } from "./type";
import { RouteVoyageTabType } from "@/pages/routeCommon/type";

export const tabOptions: (FleetOptionType<
	"Route Plans" | "Performance" | "Daily Report" | "Area Vessel" | "Port Vessel",
	RouteCommonDetailsTabType
> & {
	hidden?: boolean;
	relations: RouteVoyageTabType[];
})[] = [
	// {
	// 	label: "Performance",
	// 	value: "performance",
	// 	hidden: true,
	// 	relations: ["leg"]
	// },
	{
		label: "Daily Report",
		value: "dailyReport",
		relations: ["leg"]
	},
	{
		label: "Route Plans",
		value: "routePlans",
		relations: ["leg"]
	},
	{
		label: "Area Vessel",
		value: "areaVessel",
		relations: ["area", "port"]
	},
	{
		label: "Port Vessel",
		value: "portVessel",
		relations: ["area", "port"]
	}
];
