import { ApiParameter, RenderApiType, RequestApiMapType } from "@/api";
import request from "@/service";
import {
	getMovementOffHireParams,
	saveMovementOffHireParams,
	MovementOffHireParamsType
} from "./type";
import { AxiosResponse } from "axios";
import { appVersion, appVersionV2 } from "@/api/constant";

const enum ApiMap {
	getMovementOffHireParameter,
	saveMevementOffHireParameter
}

export type ApiUrl = keyof typeof ApiMap;

const offHireApi: ApiParameter<ApiUrl> = {
	getMovementOffHireParameter: {
		method: "get",
		url: appVersion + "/move/dialog/offhire/voyage"
	},
	saveMevementOffHireParameter: {
		method: "post",
		url: appVersion + "/move/dialog/offhire"
	}
};

export const getMovementOffHireApi = (params: getMovementOffHireParams) => {
	return request.request({
		...offHireApi.getMovementOffHireParameter,
		url: `${offHireApi.getMovementOffHireParameter.url}/${params?.id}`,
		params: { type: params?.type }
	});
};

export const saveMevementOffHireApi = (params: saveMovementOffHireParams) => {
	return request.request({
		...offHireApi.saveMevementOffHireParameter,
		data: params
	});
};

export const offHireApiMap: RequestApiMapType<ApiUrl, MovementOffHireParamsType> = {
	getMovementOffHireApi,
	saveMevementOffHireApi
};
