import { HfBunkerClauseFormChangeEvent, HfBunkerClauseFormCommitEvent, HfBunkerClauseFormType, checkBunkerClauseKeys, initialBunkerClauseForm } from "@/featrue/voyagemanage/headFixture/bunkerClause/type";
import { useState } from "react";


const useBunkerClauseForm = () => {
  const [bunkerClauseForm, setBunkerClauseForm] = useState<HfBunkerClauseFormType>({
...initialBunkerClauseForm
  });

  const change: HfBunkerClauseFormChangeEvent = (key, value) => {
    setBunkerClauseForm(prev => {
      return {
        ...prev,
        [key]: value
      }
    })
  }

  const commit: HfBunkerClauseFormCommitEvent = (item) => {
    setBunkerClauseForm(prev => {
      return {
        ...prev,
        ...item
      }
    })
  }

  const clear = () => {
    setBunkerClauseForm({
      ...initialBunkerClauseForm
    })
  }

  const check = () => {
    let checkKey = "";
    let checked = true;
    for(let item of checkBunkerClauseKeys) {
      if(bunkerClauseForm[item] !== 0 && !bunkerClauseForm[item]) {
        checkKey = item;
        checked = false;
        break;
      }
    }
    return {
      checked,
      checkKey
    }
  }

  return {
    bunkerClauseForm,
    change,
    commit,
    clear,
    check
  }
}

export default useBunkerClauseForm;