import { useRef, useState } from "react"
import { HandoverFormPortDetailsProps } from "../type"
import { initialPortDetailItem, checkKeys } from "../source";
import { FleetNumType } from "@/types/common";

const useIndex = () => {
  const [dataSource, setDataSource] = useState<HandoverFormPortDetailsProps["items"]>([]);

  const delIds = useRef<number[]>([])

  const change: HandoverFormPortDetailsProps["onChange"] = (currentIndex, key, value) => {
    setDataSource(prev => {
      const currentItem = prev[currentIndex];
      prev?.splice(currentIndex, 1, {
        ...currentItem,
        [key]: value
      })
      return [...prev]
    })
  }

  const commit: HandoverFormPortDetailsProps["onCommit"] = (currentIndex, item) => {
    setDataSource(prev => {
      const currentItem = prev[currentIndex];
      prev?.splice(currentIndex, 1, {
        ...currentItem,
        ...item
      })
      return [...prev]
    })
  }

  const dhdCommit = () => {
    const newDataSource = dataSource?.map(item => ({ ...item, desRate: (item?.demRate ? +item?.demRate / 2 : "") as FleetNumType }))
    setDataSource(newDataSource)
  }

  const remove: HandoverFormPortDetailsProps["onDelete"] = (currentIndex) => {
    setDataSource(prev => {
      const currentItem = prev?.[currentIndex]
      currentItem?.id && (delIds.current?.push(currentItem?.id))
      prev?.splice(currentIndex, 1)
      return [...prev]
    })
  }

  const add: HandoverFormPortDetailsProps["onAdd"] = () => {
    setDataSource(prev => ([...prev, { ...initialPortDetailItem }]))
  }

  const init = (items: HandoverFormPortDetailsProps["items"]) => {
    setDataSource(items ?? [])
    delIds.current = []
  }

  const check = () => {
    let checkKey = "";
    let checked = true;

    for (let item of checkKeys) {
      for (let portItem of dataSource) {
        if (portItem[item] !== 0 && !portItem[item]) {
          checked = false;
          checkKey = item;
          break;
        }
      }
    }
    return {
      checkKey,
      checked
    }
  }

  const move: HandoverFormPortDetailsProps["onMove"] = (type,currentIndex) => {
    if(type === "up") {
      if(currentIndex === 0) return
      const prevIndex = currentIndex - 1;
      const prevItem = dataSource?.[prevIndex];
      const currentItem = dataSource?.[currentIndex];
      setDataSource(prev => {
        prev?.splice(prevIndex, 2, currentItem, prevItem)
        return [...prev]
      })
      return
    }

    if(currentIndex === dataSource?.length - 1) return
    const nextIndex = currentIndex + 1;
    const nextItem = dataSource?.[nextIndex];
    const currentItem = dataSource?.[currentIndex];
    setDataSource(prev => {
      prev?.splice(currentIndex, 2, nextItem, currentItem)
      return [...prev]
    })
  }

  return {
    delIds,
    dataSource,
    change,
    commit,
    dhdCommit,
    remove,
    move,
    add,
    init,
    check
  }

}

export default useIndex