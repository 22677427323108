import {
  type ParameterType
} from "@/api";
import { loginOutAction } from "@/store/userSlice";

import {
  getVesselApi,
  getVesselGroupApi,
  getVesselGroupParams,
  getVesselParams,
  deleteVesselApi,
  deleteVesselParams,
  addVesselApi,
  addVesselParams,
  editVesselApi,
  editVesselParams
} from "@/api/dataBase/vessel"

export const getVesselAction: ParameterType<getVesselParams> = (
  params,
  frontCallback,
  successCallback,
  errorCallback,
  dispatch,
  navigate
) => {
  frontCallback?.();
  getVesselApi(params).then(response => {
    successCallback?.(response)
  }).catch(err => {
    errorCallback?.(err);
    if (err?.loginStatus === 'token faild') {
      dispatch(loginOutAction(() => navigate?.("/login")))
    }
  })
}

export const getVesselGroupAction: ParameterType<getVesselGroupParams> = (
  params,
  frontCallback,
  successCallback,
  errorCallback,
  dispatch,
  navigate
) => {
  frontCallback?.();
  getVesselGroupApi(params).then(response => {
    successCallback?.(response)
  }).catch(err => {
    errorCallback?.(err);
    if (err?.loginStatus === 'token faild') {
      dispatch(loginOutAction(() => navigate?.("/login")))
    }
  })
}

export const deleteVesselAction: ParameterType<deleteVesselParams> = (
  params,
  frontCallback,
  successCallback,
  errorCallback,
  dispatch,
  navigate
) => {
  frontCallback?.();
  deleteVesselApi(params).then(response => {
    successCallback?.(response)
  }).catch(err => {
    errorCallback?.(err);
    if (err?.loginStatus === 'token faild') {
      dispatch(loginOutAction(() => navigate?.("/login")))
    }
  })
}

export const addVesselAction: ParameterType<addVesselParams> = (
  params,
  frontCallback,
  successCallback,
  errorCallback,
  dispatch,
  navigate
) => {
  frontCallback?.();
  addVesselApi(params).then(response => {
    successCallback?.(response)
  }).catch(err => {
    errorCallback?.(err);
    if (err?.loginStatus === 'token faild') {
      dispatch(loginOutAction(() => navigate?.("/login")))
    }
  })
}

export const editVesselAction: ParameterType<editVesselParams> = (
  params,
  frontCallback,
  successCallback,
  errorCallback,
  dispatch,
  navigate
) => {
  frontCallback?.();
  editVesselApi(params).then(response => {
    successCallback?.(response)
  }).catch(err => {
    errorCallback?.(err);
    if (err?.loginStatus === 'token faild') {
      dispatch(loginOutAction(() => navigate?.("/login")))
    }
  })
}