import React from "react";
import style from "./index.module.less";
import { RoutePlanUploadProps } from "./type";
import MonitoringRoutePlanModal from "../../common/Modal";
import { FleetButton, FleetDatePicker, FleetFormItem, FleetImage, FleetInput, FleetLoading, FleetRadio, FleetSelect, FleetUpload } from "@/components";
import useIndex from "./hooks/useIndex";
import { FleetCustomRadio } from "@/components/Radio";
import classnames from "classnames";

const RoutePlanUpload: React.FC<RoutePlanUploadProps> = ({
  hide,
  onClose,
  currentRecord,
  onReset
}) => {
  const {
    loading,
    activeDownload,
    planType,
    planTime,
    uploadStatus,
    filelist,
    setPlanTime,
    setPlanType,
    handleChange,
    handleUpload,
    handleFileDelete,
    handleDownloadTemplate,
    handleConfirm
  } = useIndex(currentRecord, hide, onReset)
  return <MonitoringRoutePlanModal
    width={482}
    hide={hide}
    onClose={onClose}
    title="Upload Route Plans"
    onConfirm={handleConfirm}
  >
    <FleetLoading loading={loading} />
    <div className={style["routePlan-upload"]}>
      <FleetFormItem type="row" label="Plan Type" containerClassname="upload-field">
        <FleetSelect
          className="upload-input"
          value={[planType]}
          options={[{
            label: "Master",
            value: "Master"
          }, {
            label: "Custom",
            value: "Custom"
          }]}
          onChange={(val: string) => setPlanType(val)}
        />
      </FleetFormItem>
      <FleetFormItem type="row" label="ETD" containerClassname="upload-field">
        <FleetDatePicker
          value={planTime}
          className="upload-datePicker"
          onChange={(val, timeStamp) => {
            setPlanTime(timeStamp)
          }}
        />
      </FleetFormItem>
      <span className="attach-title">Attachment</span>
      <div className="template-container">
        <FleetButton
          size="small"
          type="secondary"
          className="template-download"
          onClick={handleDownloadTemplate}
        ><span className="font_family fleet-download"></span>Download template</FleetButton>
        <FleetCustomRadio
          value={activeDownload === "a"}
          onChange={(val) => {
            handleChange?.("a")
          }}
          label="Template one"
        />
        <FleetCustomRadio
          value={activeDownload === "b"}
          onChange={(val) => {
            handleChange?.("b")
          }}
          label="Template two"
        />
      </div>
      {
        filelist?.length > 0 && filelist?.map((item, index) => {
          return <div className="uploadfile-item" key={index}>
            <div className="uploadfile-info">
              <span className="font_family fleet-excel"></span>
              <span>{item?.name}</span>
            </div>
            <span className="font_family fleet-delete uploadfile-remove" onClick={() => handleFileDelete(item?.uid)}></span>
          </div>
        })
      }
      <FleetUpload
        type="dragger"
        onUpload={handleUpload}
        showFileList={false}
        disabled={filelist?.length > 0}
        uploadStatus={uploadStatus}
        fileList={filelist}
        // onDelete={handleFileDelete}
        loading={loading}
        className={classnames({
          "not-empty": filelist?.length > 0
        })}
      />
    </div>
  </MonitoringRoutePlanModal>
}

export default RoutePlanUpload