import React, { FC, useMemo, useState } from "react";
import { ColumnsType } from "antd/es/table";
import classnames from "classnames";
import style from "./index.module.less";
import { PortAreaListItemType, PortAreaListTableProps } from "./type";
import { FleetLoading, FleetTable } from "@/components";
import useIndex from "./hooks/useIndex";
import { columns } from "./source";
import MovementTable from "@/pages/voyagemanage/business/movement/details/components/charterBase/common/table";
import { FleetCustomRadio } from "@/components/Radio";

const PortAreaListTable: FC<PortAreaListTableProps> = ({
	tableData,
	selectedRows,
	sortOption,
	onClick,
	onSelect,
	onSelectAll,
	onSort
}) => {
	const { loading } = useIndex();

	return (
		<div className={classnames(style["portArea-list-table"])}>
			<FleetLoading loading={loading} />
			<FleetCustomRadio
				value={selectedRows.length != 0 && selectedRows.length == tableData.length}
				onChange={onSelectAll}
				className="portAreaListTable-selectAll"
			/>
			<div className="portAreaListTable-container">
				<MovementTable
					sortOption={sortOption}
					dataSource={tableData}
					columns={columns}
					className="portAreaListTable-table"
					otherSource={{ selectedRows }}
					actions={{ onSelect, onClick, onSort }}
				/>
			</div>
			<span className="portAreaListTable-total">
				选中{`${selectedRows?.length}/${tableData?.length}`}条
			</span>
		</div>
	);
};

export default PortAreaListTable;
