import React, { useEffect } from "react";
import { PromptChildrenProps } from "@/common/Prompt";
import CBBasicForm from "../basic";
import CBPaymentTermsForm from "../freightPayterm";
import NewContract from "@/featrue/voyagemanage/common/newContract";
import CBCargoInfoForm from "../cargoInfo";
import CBPortRotation from "../portRotation";
import useAddCargoBook from "@/hook/voyageManage/cargoBook/useAddCargoBook";
import { addCargoBookAction } from "@/action/voyageManage/cargoBook";
import { useAppDispatch } from "@/hook";
import { useNavigate } from "react-router-dom";
import useReminder from "@/hook/useReminder";

export type NewCargoBookProps = {
  onReset?:() => void
} & PromptChildrenProps;

const NewCargoBook: React.FC<NewCargoBookProps> = ({
  hide,
  onClose,
  onReset
}) => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { reminder } = useReminder()
  const {
    loading,
    basicForm,
    payTermForm,
    cargoInfoForm,
    portRotation,
    setLoading,
    handleBasicFormChange,
    handlePayTermFormChange,
    handlePayTermFormCommit,
    handleCargoInfoFormCommit,
    handleCargoInfoFormhange,
    handlePortFormChange,
    handleBasicFormCommit,
    handlePortCommitForm,
    handlePortAdd,
    handlePortDel,
    clear,
    check
  } = useAddCargoBook();

  const addCargoBookFront = () => {
    setLoading(true);
  }

  const addCargoBookSuccess = (response) => {
    reminder("success", response?.data);
    setLoading(false);
    onClose?.();
    onReset?.();
  }

  const addCargoBookError = (error) => {
    reminder("error", error?.msg ? (error?.msg + ": " + error?.data) : error?.data);
    setLoading(false);
  }

  const addCargoBook = () => {
    if(!check()) return;
    const {
      fixtureNo,
      ...restBasicForm
    } = basicForm;
    addCargoBookAction(
      {
        ...restBasicForm,
        ...payTermForm,
        ...cargoInfoForm,
        ...payTermForm,
        portRotationList: portRotation

      },
      addCargoBookFront,
      addCargoBookSuccess,
      addCargoBookError,
      dispatch,
      navigate
    )
  }

  useEffect(() => {
    if (hide) return;
    clear()
  }, [hide])

  return <NewContract
    label="New Cargo Book"
    loading={loading}
    hide={hide}
    onClose={onClose}
    onSave={addCargoBook}
  >
    <CBBasicForm
      type="newAdd"
      item={basicForm}
      onChange={handleBasicFormChange}
      onCommit={handleBasicFormCommit}
      onPaymentTermsChange={handlePayTermFormChange}
      otherItem={payTermForm}
    />
    <CBPaymentTermsForm
      item={payTermForm}
      otherItem={basicForm}
      onChange={handlePayTermFormChange}
      onCommit={handlePayTermFormCommit}
    />
    <CBCargoInfoForm
      item={cargoInfoForm}
      onChange={handleCargoInfoFormhange}
      onCommit={handleCargoInfoFormCommit}
    />
    <CBPortRotation
      items={portRotation}
      onChange={handlePortFormChange}
      onCommit={handlePortCommitForm}
      onAdd={handlePortAdd}
      onDelete={handlePortDel}
    />
  </NewContract>

}

export default NewCargoBook;