import {
  getFreightSenApi,
  getFreightSenParams,
  updateFreightSenApi,
  updateFreightSenParams
} from "@/api/estimation/freightSensitivity";

import { type ParameterType } from "@/api";
import { loginOut, loginOutAction } from "@/store/userSlice";

export const getFreightSenAction: ParameterType<getFreightSenParams> = (
  params,
  frontCallback,
  successCallback,
  errorCallback,
  dispatch,
  navigate
) => {
  frontCallback?.();
  getFreightSenApi(params).then(response => {
    successCallback?.(response)
  }).catch(err => {
    errorCallback?.(err);
    if (err?.loginStatus === 'token faild') {
      // dispatch?.(loginOut({}));
      // navigate?.('/')
      dispatch(loginOutAction(() => navigate?.("/login")))
    }
  })
}

export const updateFreightSenAction: ParameterType<updateFreightSenParams> = (
  params,
  frontCallback,
  successCallback,
  errorCallback,
  dispatch,
  navigate
) => {
  frontCallback?.();
  updateFreightSenApi(params).then(response => {
    successCallback?.(response)
  }).catch(err => {
    errorCallback?.(err);
    if (err?.loginStatus === 'token faild') {
      // dispatch?.(loginOut({}));
      // navigate?.('/')
      dispatch(loginOutAction(() => navigate?.("/login")))
    }
  })
}
