import React, { useEffect } from "react";
import style from "./index.module.less";
import { FleetPrompt } from "@/common";
import { type PromptChildrenProps } from "@/common/Prompt"
import { FleetIcon, FleetVerifyInput, FleetLoading } from "@/components";
import { LabelMap } from "@/types";
import useStowagePlan from "@/hook/estimation/details/useStowagePlan";
import { FleetTabbar } from "@/components/Tabs";
import { PortRotationFormType } from "@/types/estimation/details/index";
import { toThousands } from "@/tools";
import { Button } from "@/_components";

export type StowagePlanProps = {
  source?: {
    resultId: string,
    ballastSpeed: number | "",
    ladenSpeed: number | "",
    seaFuelBallast: number | "",
    seaFuelLaden: number | "",
    seaFuelMdo: number | "",
    portFuelWork: number | "",
    portFuelIdle: number | "",
    portFuelMdo: number | "",
    delievryPortName?: string;
    delievryPortId?: string | number;
    delievryPortCode?: string;
    ballastPortList?: PortRotationFormType[];
    ladenPortList?: PortRotationFormType[];
    startVoyTime: number
  };
}

const StowagePlan: React.FC<StowagePlanProps & PromptChildrenProps> = ({
  hide,
  onClose,
  source
}) => {

  const {
    stowagePlan,
    portChange,
    vesselChange,
    getStowage,
    loading,
    active,
    setActive,
    stowageGroup,
    updateStowage,
  } = useStowagePlan(source, onClose);

  useEffect(() => {
    if (hide) return
    getStowage()
  }, [source?.resultId, hide])

  return <FleetPrompt onClose={onClose} hide={hide} mode="fixed">
    <div className={style["esDetails-stowagePlan"]}>
      <FleetLoading loading={loading} />
      <div className="stowagePlan-header prompt-header">
        <span className="header-title">Estimated Stowage Plan</span>
        <FleetIcon
          type="FleetCloseIcon"
          outerLayerClassname="header-close"
          onClick={onClose}
        />
      </div>
      <div className="stowagePlan-container">
        <div className="stowagePlan">
          <div className="stowagePlan-label">
            <span>{stowagePlan.firstPortName} Pt</span>
          </div>
          <div className="pt-container">
            <span className="pt-vpLabel">Vessel Particular</span>
            <div className="pt-item">
              <FleetVerifyInput
                label={LabelMap["dwt"]}
                value={stowagePlan?.vesselVo?.dwt}
                type="number"
                disabled={active !== "dwt"}
                containerClassname="pt-formItem-1"
                onChange={(val: string) => {
                  vesselChange("dwt", val)
                }}
              />
              <FleetIcon
                type={active === "dwt" ? "FleetRadioActiveIcon" : "FleetRadioIcon"}
                outerLayerClassname="pt-radio"
                onClick={() => {
                  if (active === "dwt") return;
                  setActive("dwt")
                }}
              />
            </div>
            <div className="pt-item">
              <FleetVerifyInput
                label={LabelMap["grain"]}
                value={stowagePlan?.vesselVo?.grain}
                disabled={active !== "grain"}
                type="number"
                containerClassname="pt-formItem-1"
                onChange={(val: string) => {
                  vesselChange("grain", val)
                }}
              />
              <FleetIcon
                type={active === "grain" ? "FleetRadioActiveIcon" : "FleetRadioIcon"}
                outerLayerClassname="pt-radio"
                onClick={() => {
                  if (active === "grain") return;
                  setActive("grain")
                }}
              />
            </div>
            <div className="pt-item">
              <FleetVerifyInput
                label={LabelMap["bale"]}
                type="number"
                disabled={active !== "bale"}
                value={stowagePlan?.vesselVo?.bale}
                containerClassname="pt-formItem-1"
                onChange={(val: string) => {
                  vesselChange("bale", val)
                }}
              />
              <FleetIcon
                type={active === "bale" ? "FleetRadioActiveIcon" : "FleetRadioIcon"}
                outerLayerClassname="pt-radio"
                onClick={() => {
                  if (active === "bale") return;
                  setActive("bale")
                }}
              />
            </div>
            <div className="pt-item">
              <FleetVerifyInput
                label="TPC/TPI"
                type="number"
                value={stowagePlan?.vesselVo?.tonnageValue}
                containerClassname="pt-formItem-2"
                onChange={(val: string) => {
                  // setRadioVal(val)

                  vesselChange("tonnageValue", val)
                }}
              />
              <FleetTabbar
                value={stowagePlan?.vesselVo?.tonnageType}
                list={["TPC", "TPI"]}
                onChange={(val: string) => {
                  vesselChange("tonnageType", val)
                }}
              />
            </div>
            <div className="pt-item">
              <FleetVerifyInput
                label={LabelMap["sswDraft"]}
                containerClassname="pt-formItem-3"
                value={stowagePlan?.vesselVo?.sswDraft}
                type="number"
                onChange={(val: string) => {
                  vesselChange("sswDraft", val);
                }}
              />
              <FleetVerifyInput
                label={LabelMap["stowageFactor"]}
                containerClassname="pt-formItem-3"
                value={stowagePlan?.vesselVo?.stowageFactor}
                type="number"
                onChange={(val: string) => {
                  vesselChange("stowageFactor", val);
                }}
              />
            </div>
          </div>
          <div className="stowagePlan-label loaded-label">
            <span>Loaded</span>
          </div>
          <div className="loaded-container">
            <div className="pt-item">
              <FleetVerifyInput
                containerClassname="pt-formItem-4"
                type="number"
                label={LabelMap["constant"]}
                value={stowagePlan?.vesselVo?.constant}
                onChange={(val: string) => {
                  vesselChange("constant", val)
                }}
              />
            </div>
            <div className="pt-item">
              <FleetVerifyInput
                containerClassname="pt-formItem-3"
                label={LabelMap["ballastWater"]}
                type="number"
                value={stowagePlan?.vesselVo?.ballastWater}
                onChange={(val: string) => {
                  vesselChange("ballastWater", val)
                }}
              />
              <FleetVerifyInput
                containerClassname="pt-formItem-3"
                label={LabelMap["freshWater"]}
                type="number"
                value={stowagePlan?.vesselVo?.freshWater}
                onChange={(val: string) => {
                  vesselChange("freshWater", val)
                }}
              />
            </div>
            <div className="pt-item">
              <FleetVerifyInput
                containerClassname="pt-formItem-3"
                label={LabelMap["brobFo"]}
                type="number"
                value={stowagePlan?.vesselVo?.brobFo}
                onChange={(val: string) => {
                  vesselChange("brobFo", val)
                }}
              />
              <FleetVerifyInput
                containerClassname="pt-formItem-3"
                label={LabelMap["brobDo"]}
                type="number"
                value={stowagePlan?.vesselVo?.brobDo}
                onChange={(val: string) => {
                  vesselChange("brobDo", val)
                }}
              />
            </div>
            <div className="pt-item">
              <FleetVerifyInput
                containerClassname="pt-formItem-3"
                label={LabelMap["others"]}
                type="number"
                value={stowagePlan?.vesselVo?.loadedOthers}
                onChange={(val: string) => {
                  vesselChange("loadedOthers", val)
                }}
              />
              <FleetVerifyInput
                containerClassname="pt-formItem-3"
                label={LabelMap["tropical"]}
                type="number"
                value={stowagePlan?.vesselVo?.tropical}
                onChange={(val: string) => {
                  vesselChange("tropical", val)
                }}
              />
            </div>
          </div>
        </div>
        {
          stowagePlan?.portInfoVos?.map((item, idx) => {
            return <div key={idx} className="stowagePlan stowagePlan-minaSapr">
              <div className="stowagePlan-label">
                <span>{item?.portName}</span>
              </div>
              <div className="minaSapr-container">
                <div className="pt-item">
                  <FleetVerifyInput
                    containerClassname="pt-formItem-4"
                    label="Sea IFO"
                    type="number"
                    value={item?.seaFuelFo}
                    onChange={(val: string | number) => {
                      portChange(item.id, "seaFuelFo", val)
                    }}
                  />
                </div>
                <div className="pt-item">
                  <FleetVerifyInput
                    containerClassname="pt-formItem-4"
                    label="Sea MDO"
                    type="number"
                    value={item?.seaFuelDo}
                    onChange={(val: string) => {
                      portChange(item.id, "seaFuelDo", val)
                    }}
                  />
                </div>
                <div className="pt-item">
                  <FleetVerifyInput
                    containerClassname="pt-formItem-4"
                    label="Port IFO"
                    value={item.portFo}
                    type="number"
                    onChange={(val: string) => {
                      portChange(item.id, "portFo", val)
                    }}
                  />
                </div>
                <div className="pt-item">
                  <FleetVerifyInput
                    containerClassname="pt-formItem-4"
                    label="Port MDO"
                    value={item.portDo}
                    type="number"
                    onChange={(val: string) => {
                      portChange(item.id, "portDo", val)
                    }}
                  />
                </div>
                <div className="pt-item">
                  <FleetVerifyInput
                    containerClassname="pt-formItem-4"
                    label="Bunker IFO"
                    type="number"
                    value={item.bunkerFo}
                    onChange={(val: string) => {
                      portChange(item.id, "bunkerFo", val)
                    }}
                  />
                </div>
                <div className="pt-item">
                  <FleetVerifyInput
                    containerClassname="pt-formItem-4"
                    label="Bunker MDO"
                    type="number"
                    value={item.bunkerDo}
                    onChange={(val: string) => {
                      portChange(item.id, "bunkerDo", val)
                    }}
                  />
                </div>
                <div className="pt-item">
                  <FleetVerifyInput
                    containerClassname="pt-formItem-4"
                    label="Density"
                    value={item.density}
                    onChange={(val: string) => {
                      portChange(item.id, "density", val)
                    }}
                  />
                </div>
                <div className="pt-item">
                  <FleetVerifyInput
                    containerClassname="pt-formItem-4"
                    label={LabelMap["draftLimitation"]}
                    value={item.draftLimitation}
                    onChange={(val: string) => {
                      portChange(item.id, "draftLimitation", val)
                    }}
                  />
                </div>
                <div className="pt-item">
                  <FleetVerifyInput
                    containerClassname="pt-formItem-4"
                    label={LabelMap["adjustedDwt"]}
                    value={toThousands(stowageGroup[idx].adjustedDwt)}
                    disabled
                  // onChange={(val: string) => {
                  //   portChange(item.id, "adjustedDwt", val)
                  // }}
                  />
                </div>
              </div>
              <div className="stowagePlan-label maxQuantity-label">
                <span>Max Quantity:</span>
                <span>{toThousands(stowageGroup[idx].maxQuantity)}</span>
              </div>
            </div>
          })
        }
      </div>
      <div className="stowagePlan-footer">
        <Button
          onClick={onClose}
          type="secondary"
        >Cancel</Button>
        <Button
          onClick={() => updateStowage(stowageGroup)}
        >Confirm</Button>
      </div>
    </div>
  </FleetPrompt>
}

export default StowagePlan;