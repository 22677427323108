import { useState } from "react"
import { MovementCargoInfoProps } from "../type"
import { initialCargoInfo } from "../source"


const useIndex = () => {
  const [dataSource, setDataSource] = useState<MovementCargoInfoProps["item"]>(initialCargoInfo())

  const change: MovementCargoInfoProps["onChange"] = (key, value) => {
    setDataSource(prev => ({ ...prev, [key]: value }))
  }

  const commit: MovementCargoInfoProps["onCommit"] = (item) => {
    setDataSource(prev => ({ ...prev, ...item }))
  }

  const init: MovementCargoInfoProps["onCommit"] = (item) => {
    setDataSource(initialCargoInfo(item))
  }

  return {
    dataSource,
    change,
    commit,
    init
  }
}

export default useIndex