import { useEffect, useMemo, useRef, useState } from "react";
import { RouteLegFilterProps, RouteLegItemProps } from "../components";
import { useAppDispatch } from "@/hook";
import { useLocation, useNavigate } from "react-router-dom";
import useReminder from "@/hook/useReminder";
import { FleetPageParams } from "@/types/common";
import { RouteLegListProps } from "../type";
import debounce from "lodash/debounce";
import { onRequest } from "@/action";

const useIndex = (
	activeTab: RouteLegListProps["activeTab"],
	activeItem: RouteLegListProps["activeItem"],
	onSelect: RouteLegListProps["onSelect"],
	onVesselLoad: RouteLegListProps["onVesselLoad"]
) => {
	const dispatch = useAppDispatch();
	const navigate = useNavigate();
	const { reminder } = useReminder();

	const { search } = useLocation();
	const searchParams = new URLSearchParams(search);

	// const { currentLegItem } = useMonitoringContext();

	const [selectRows, setSelectRows] = useState<RouteLegFilterProps["selectStatuItems"]>([
		"Preparation",
		"Departed",
		"Arrived",
		"Completed"
	]);

	const [searchVal, setSearchVal] = useState(searchParams?.get("voyageNo"));
	const [loading, setLoading] = useState(false);
	const [hasMore, setHasMore] = useState(true);
	const pager = useRef<FleetPageParams & { total: number }>({
		pageNum: 1,
		pageSize: 20,
		total: 0
	});

	const [dataSource, setDataSource] = useState<RouteLegItemProps["item"][]>([]);

	const getVesselListFront = () => {
		setLoading(true);
	};

	const getVesselListSuccess = ({ data }) => {
		debugger;
		let total = 0;
		if (Array.isArray(data?.voyageList)) {
			// data?.currentVoList?.forEach((currentItem) => {
			// 	const targets = data?.voyageList.filter(
			// 		(voyageItem) => currentItem.voyageNo === voyageItem.voyageNo
			// 	);
			// 	targets.forEach((voyageItem) => {
			// 		voyageItem.cog = currentItem?.feature?.properties?.cog;
			// 	});

			// 	let target;
			// 	if (targets.length > 0) {
			// 		target = targets.sort((a, b) => b.actualCommenceDate - a.actualCommenceDate)[0];
			// 		currentItem.mmsi = target.mmsi;
			// 		currentItem.id = target.id;
			// 		currentItem.imo = target.imo;
			// 		currentItem.type = target.vesselStatus;
			// 	}
			// });
			if (!activeItem && searchParams?.get("voyageNo")) {
				const currentVesselItem = data?.voyageList?.find(
					(item) => item?.voyageNo === searchParams?.get("voyageNo")
				);
				// setCurrentVessel(currentVesselItem)
				onSelect?.(currentVesselItem);
				// setTimeout(() => {
				//   onSelect?.("vm", currentVesselItem, true)
				// })
			}
			total = +data?.totalCount;
			setHasMore(true);
		} else {
			setHasMore(false);
		}
		pager.current = {
			...pager.current,
			total,
			pageNum: 1
		};
		setDataSource(data?.voyageList ?? []);
		// setCurrentVoyageList(data?.currentVoList ?? []);
		onVesselLoad?.("leg", data?.voyageList ?? []);
		setLoading(false);
	};

	const getVesselListFaild = (error) => {
		setLoading(false);
		reminder("error", error?.msg ? error?.msg + ": " + error?.data : error?.data);
	};

	const getVesselList = (params?: {
		val?: string;
		selectRows?: RouteLegFilterProps["selectStatuItems"];
	}) => {
		onRequest(
			"getRouteCommonLegListApi",
			{
				keyword: params?.val ?? searchVal,
				statusList: (params?.selectRows ?? selectRows)?.join(","),
				pageNum: 1,
				pageSize: pager?.current?.pageSize
			},
			getVesselListFront,
			getVesselListSuccess,
			getVesselListFaild,
			dispatch,
			navigate
		);
	};

	const loadMoreListSuccess = ({ data }) => {
		let total = 0;
		console.log("loadMoreListSuccess", dataSource);
		if ((data?.voyageList?.length ?? 0) + dataSource?.length >= pager?.current?.total) {
			setHasMore(false);
		} else {
			setHasMore(true);
		}
		if (Array.isArray(data?.voyageList)) {
			// data?.currentVoList?.forEach((currentItem) => {
			// 	const targets = data?.voyageList.filter(
			// 		(voyageItem) => currentItem.voyageNo === voyageItem.voyageNo
			// 	);
			// 	targets.forEach((voyageItem) => {
			// 		voyageItem.cog = currentItem?.feature?.properties?.cog;
			// 	});

			// 	let target;
			// 	if (targets.length > 0) {
			// 		target = targets.sort((a, b) => b.actualCommenceDate - a.actualCommenceDate)[0];
			// 		currentItem.mmsi = target.mmsi;
			// 		currentItem.id = target.id;
			// 		currentItem.type = target.vesselStatus;
			// 	}
			// });
			total = data?.totalCount;
			setDataSource((prev) => [...prev, ...data?.voyageList]);
			onVesselLoad?.("leg", [...dataSource, ...data?.voyageList]);
		}
		pager.current = {
			...pager.current,
			total,
			pageNum: pager?.current?.pageNum + 1
		};
		setLoading(false);
	};

	const loadMoreList = () => {
		if (dataSource?.length >= pager?.current?.total) {
			hasMore && setHasMore(false);
			return;
		}
		onRequest(
			"getRouteCommonLegListApi",
			{
				pageNum: pager?.current?.pageNum + 1,
				pageSize: pager?.current?.pageSize,
				keyword: searchVal,
				statusList: selectRows?.join(",")
			},
			getVesselListFront,
			loadMoreListSuccess,
			getVesselListFaild,
			dispatch,
			navigate
		);
	};

	const onSearch = useMemo(() => {
		const loadOptions = (val: string) => {
			setDataSource([]);
			setSearchVal(val);
			getVesselList({ val });
		};
		return debounce(loadOptions, 500);
	}, []);

	const handleScrollIntoVoyageItem = (voyageNo: string, items?: RouteLegItemProps["item"][]) => {
		const currentList = items ?? dataSource;
		const itemIndex = currentList.findIndex((item) => item.voyageNo === voyageNo);
		if (itemIndex !== -1) {
			document.getElementById(voyageNo)?.scrollIntoView({ behavior: "smooth", block: "start" });
		}
	};

	const handleFilterConfirm: RouteLegFilterProps["onConfirm"] = (items) => {
		getVesselList({ selectRows: items });
		setSelectRows(items);
	};

	useEffect(() => {
		if (activeTab !== "leg") return;
		getVesselList();
	}, [activeTab]);

	return {
		hasMore,
		loadMoreList,
		dataSource,
		loading,
		onSearch,
		selectRows,
		handleFilterConfirm,
		handleScrollIntoVoyageItem
	};
};

export default useIndex;
