import { useState } from "react"
import { VesselParticularItemProps } from "../type"
import { checkKeys, initalVesselParticular } from "../source"
import { EstimationNewlyCommonCrudEvent } from "../../../type";
import useReminder from "@/hook/useReminder";

const useIndex = () => {
  const { reminder } = useReminder();
  const [dataSource, setDataSource] = useState<VesselParticularItemProps["item"][]>([{ ...initalVesselParticular(null) }]);

  const change: VesselParticularItemProps["onChange"] = (currentIndex, key, value) => {
    setDataSource(prev => {
      const item = prev[currentIndex];
      item[key] = value;
      prev.splice(currentIndex, 1, item);
      return [...prev]
    })
  }

  const commit: VesselParticularItemProps["onCommit"] = (currentIndex, item) => {
    setDataSource(prev => {
      prev.splice(currentIndex, 1, {
        ...prev[currentIndex],
        ...item
      });
      return [...prev]
    })
  }

  const crud: EstimationNewlyCommonCrudEvent = (type, currentIndex) => {
    switch (true) {
      case type === "add":
        setDataSource(prev => ([...prev, initalVesselParticular(null)]));
        break;
      case type === "delete":
        if (dataSource?.length <= 1) {
          reminder("info", "Please reserve at least one ship or cargo plan");
          return
        }
        setDataSource(prev => {
          prev.splice(currentIndex, 1);
          return [...prev]
        });
        break;
      case type === "parse":
        setDataSource(prev => {
          const copyItem = prev[currentIndex];
          return [...prev, initalVesselParticular(copyItem)]
        });
        break;
      default:
        break
    }
  }

  const check = () => {
    let flag = true;
    let flagKey = ''
    for (let item of dataSource) {
      flag = checkKeys.every(vesselKey => item[vesselKey] === 0 || (item[vesselKey] && item[vesselKey] !== undefined));
      if (!flag) {
        flagKey = checkKeys.find(e => !Boolean(item[e]))
        break;
      }
    }
    return { flag, flagKey }
  }

  const clear = () => {
    setDataSource([{ ...initalVesselParticular(null) }])
  }

  return {
    dataSource,
    change,
    commit,
    crud,
    check,
    clear
  }
}

export default useIndex