import { useAppDispatch } from "@/hook";
import useReminder from "@/hook/useReminder";
import { useEffect, useMemo, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { ApArColumnItemType } from "../type";
import { AparListFilterItemType, AparListFilterProps } from "../components/filter/type";
import debounce from "lodash/debounce";
import { columns } from "../source";
import React from "react";
import { Button } from "@/_components";
import { CommonRoleWrap } from "@/common";
import { onRequest } from "@/action";

const useIndex = () => {
	const dispatch = useAppDispatch();
	const navigate = useNavigate();
	const { reminder } = useReminder();

	const [financialHide, setFinancialHide] = useState(true);

	const [loading, setLoading] = useState(false);
	const [historyHide, setHistoryHide] = useState(true);
	const record = useRef<ApArColumnItemType>(null);
	const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([]);

	const [pager, setPager] = useState<{
		total: number;
		pageNum: number;
		pageSize: number;
		arapTotal: number;
	}>({ total: 0, pageNum: 0, pageSize: 20, arapTotal: 0 });

	const [search, setSearch] = useState("");

	const cacheSource = useRef<{ [index: number]: ApArColumnItemType[] }>({});

	const dataSource = cacheSource.current[pager.pageNum];

	console.log("pager", pager?.pageNum);

	const [filter, setFilter] = useState<AparListFilterItemType>({
		companyName: "",
		counterpartyId: null,
		end: undefined,
		start: undefined,
		type: null,
		status: null,
		voyageType: null
	});

	const handleRecordClick = (item: ApArColumnItemType) => {
		record.current = item;
		setHistoryHide(false);
	};

	const handleClose = () => {
		record.current = null;
		setHistoryHide(true);
	};

	const handleFinancialClick = (item: ApArColumnItemType) => {
		record.current = item;
		setFinancialHide(false);
	};

	const handleFinancialClose = () => {
		record.current = null;
		setFinancialHide(true);
	};

	const handleFinancialConfirm = () => {
		handleFinancialClose();
		getApArList();
	};

	const updateApArStatus = (
		type: "all" | "single",
		record: ApArColumnItemType,
		params: (Pick<ApArColumnItemType, "dataId" | "dataType" | "status" | "voyageId"> & {
			arap: ApArColumnItemType["arAp"];
		})[]
	) => {
		onRequest(
			"updateApArStatusApi",
			params,
			getApArListFront,
			type === "all"
				? updateApArStatusSuccess
				: (response) => {
						handleRecordChange(record, "status", params?.[0]?.status);
						setLoading(false);
					},
			getApArListFailed,
			dispatch,
			navigate
		);
	};

	const list_columns = useMemo(() => {
		const _columns = [...columns];
		_columns.push({
			dataIndex: "option",
			width: 160,
			title: "Action",
			key: "option",
			align: "center",
			fixed: "right",
			render: (value, record, index) => {
				return (
					<div className="td-operate">
						<CommonRoleWrap roleStr="apar:list:settle">
							<Button
								type="secondary"
								size="minuscule"
								prefixIcon="fleet-check"
								// onClick={() => onSettle?.("Settled")}
								onClick={() => {
									// updateApArStatus("single", record, [params]);
									handleFinancialClick(record);
								}}
							>
								Settled
							</Button>
						</CommonRoleWrap>
						{/* {record?.status === "Unsettled" ? (
							<CommonRoleWrap roleStr="apar:list:settle">
								<Button
									type="secondary"
									size="minuscule"
									prefixIcon="fleet-check"
									// onClick={() => onSettle?.("Settled")}
									onClick={() => {
										const params: Pick<
											ApArColumnItemType,
											"dataId" | "dataType" | "status" | "voyageId"
										> & { arap: ApArColumnItemType["arAp"] } = {
											dataId: record?.dataId,
											dataType: record?.dataType,
											status: record?.status === "Settled" ? "Unsettled" : "Settled",
											voyageId: record?.voyageId,
											arap: record?.arAp
										};
										// updateApArStatus("single", record, [params]);
										handleFinancialClick(record);
									}}
								>
									Settled
								</Button>
							</CommonRoleWrap>
						) : (
							<CommonRoleWrap roleStr="apar:list:cancel">
								<Button
									type="secondary"
									size="minuscule"
									prefixIcon="fleet-check"
									// onClick={() => onSettle?.("Settled")}
									onClick={() => {
										const params: Pick<
											ApArColumnItemType,
											"dataId" | "dataType" | "status" | "voyageId"
										> & { arap: ApArColumnItemType["arAp"] } = {
											dataId: record?.dataId,
											dataType: record?.dataType,
											status: record?.status === "Settled" ? "Unsettled" : "Settled",
											voyageId: record?.voyageId,
											arap: record?.arAp
										};
										updateApArStatus("single", record, [params]);
									}}
								>
									Cancel
								</Button>
							</CommonRoleWrap>
						)} */}
						{record?.total === null ? null : (
							<div
								className="inv-history"
								onClick={() => {
									handleRecordClick(record);
								}}
							>
								<span className="font_family fleet-history"></span>
								<span>{record?.total}</span>
							</div>
						)}
					</div>
				);
			}
		});
		return _columns;
	}, [updateApArStatus]);

	const handleFilterCommit: AparListFilterProps["onCommit"] = (item) => {
		setFilter((prev) => ({ ...prev, ...item }));
	};

	const onSearch = useMemo(() => {
		const loadOptions = (val: string) => {
			cacheSource.current = {};
			setSearch(val);
			getApArList(val);
		};
		return debounce(loadOptions, 500);
	}, []);

	const getApArListFront = () => {
		setLoading(true);
	};

	const getApArListSuccess = (response) => {
		let total = 0;
		switch (true) {
			case !Array.isArray(response?.data):
				// setDataSource(response?.data?.items);
				cacheSource.current[1] = response?.data?.list;
				total = response?.data?.total;
				break;
			default:
				// setDataSource([]);
				cacheSource.current = {};
				break;
		}
		setPager((prev) => ({ ...prev, total, pageNum: 1, arapTotal: response?.data?.arapTotal ?? 0 }));
		setLoading(false);
	};

	const getApArListFailed = (error) => {
		reminder("error", error?.msg ? error?.msg + ": " + error?.data : error?.data);
		setLoading(false);
	};

	const getApArList = (searchVal?: string) => {
		cacheSource.current = {};
		onRequest(
			"getApArListApi",
			{
				...filter,
				pageNum: 1,
				pageSize: pager.pageSize,
				filter: searchVal ?? search
			},
			getApArListFront,
			getApArListSuccess,
			getApArListFailed,
			dispatch,
			navigate
		);
	};

	const onSelectChange = (newSelectedRowKeys: React.Key[]) => {
		setSelectedRowKeys(newSelectedRowKeys);
	};

	const onPageChange = (page: number, size: number) => {
		if (dataSource?.length >= pager.total) return;
		// if (page * size <= dataSource?.length) return;
		if (cacheSource.current[page]) {
			setPager((prev) => ({ ...prev, pageNum: page }));
			return;
		}
		onRequest(
			"getApArListApi",
			{
				...filter,
				pageNum: page,
				pageSize: pager.pageSize,
				filter: search
			},
			getApArListFront,
			(response) => {
				if (!Array.isArray(response?.data)) {
					cacheSource.current[page] = response?.data?.list;
				} else {
					cacheSource.current[page] = [];
				}
				setPager((prev) => ({ ...prev, pageNum: page }));
				setLoading(false);
			},
			getApArListFailed,
			dispatch,
			navigate
		);
	};

	const handleDownload = () => {
		if (selectedRowKeys?.length === 0) return;
		const selectList = dataSource
			?.filter((item) => selectedRowKeys.includes(item?.key))
			?.map((item) => {
				return {
					dataId: item?.dataId,
					dataType: item?.dataType,
					voyageId: item?.voyageId
				};
			});
		onRequest("downloadApArListApi", selectList, null, null, getApArListFailed, dispatch, navigate);
	};

	const handleRecordChange = <K extends keyof ApArColumnItemType>(
		record: ApArColumnItemType,
		key: K,
		value: ApArColumnItemType[K]
	) => {
		debugger;
		const currentSource = cacheSource?.current?.[pager.pageNum];
		const currentIndex = cacheSource?.current?.[pager.pageNum]?.findIndex(
			(item) => item?.key === record?.key
		);
		currentSource?.splice(currentIndex, 1, {
			...record,
			[key]: value
		});
		cacheSource.current = {
			...cacheSource.current,
			[pager.pageNum]: [...currentSource]
		};
	};

	const updateApArStatusSuccess = (response) => {
		setSelectedRowKeys([]);
		getApArList();
	};

	const handleSettle: AparListFilterProps["onSettle"] = (type) => {
		if (selectedRowKeys?.length <= 0) return;
		const params = Object?.values(cacheSource?.current)
			?.flat()
			?.filter((item) => selectedRowKeys?.includes(item?.key) && item?.status !== type)
			?.map((item) => {
				return {
					arap: item?.arAp,
					voyageId: item?.voyageId,
					dataType: item?.dataType,
					dataId: item?.dataId,
					status:
						item?.status === "Settled" ? "Unsettled" : ("Settled" as ApArColumnItemType["status"])
				};
			});
		updateApArStatus("all", null, params);
	};

	useEffect(() => {
		getApArList();
	}, [filter]);

	return {
		financialHide,
		record,
		historyHide,
		filter,
		pager,
		dataSource,
		loading,
		onSearch,
		list_columns,
		onPageChange,
		selectedRowKeys,
		onSelectChange,
		handleDownload,
		handleFilterCommit,
		handleSettle,
		handleClose,
		handleFinancialClose,
		handleFinancialConfirm
	};
};

export default useIndex;
