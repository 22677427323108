import { FleetSelect } from "@/components";
import React from "react";
import useIndex from "./hooks/useIndex";
import { TemplatePopup } from "./components";
import { CommonTemplateItemType } from "./components/TempPopup/type";

const TemplateSelect: React.FC<{
  onFinish?: (newItem: CommonTemplateItemType, type: "add" | "del") => void
  activeItem: Partial<CommonTemplateItemType>
  className?: string;
  placeholder?: string
}> = ({
  onFinish,
  activeItem,
  className,
  placeholder
}) => {
    const {
      loading,
      list,
      open,
      selectValue,
      loadMore,
      closePopup,
      focusPopup,
      handleSelect,
      search,
      deleteEstTemplate
    } = useIndex(activeItem, onFinish);

    return <FleetSelect
      showSearch
      className={className}
      placeholder={placeholder}
      value={selectValue}
      popupMatchSelectWidth={false}
      onDropdownVisibleChange={closePopup}
      open={open}
      onSearch={search}
      onFocus={() => focusPopup()}
      dropdownRender={() => {
        return <TemplatePopup
          loading={loading}
          activeItem={activeItem}
          items={list}
          onDelete={deleteEstTemplate}
          onSelect={handleSelect}
          onLoadMore={loadMore}
        />
      }}
    />
  }

export default TemplateSelect;

