import React, { FC, useEffect, useState } from "react";
import { FleetLoading, FleetTextarea } from "@/components";
import useReminder from "@/hook/useReminder";
import { RouteCommonDailyReportDetailsProps } from "./type";
import { useAppDispatch } from "@/hook";
import { useNavigate } from "react-router-dom";
import { useRouteCommonContext } from "../../store";
import { onRequest } from "@/action";
import { Icon } from "@/_components";
import style from "./index.module.less";
import { cn } from "@/tools";

const RouteCommonDailyReportDetails: FC<RouteCommonDailyReportDetailsProps> = (props) => {
	const dispatch = useAppDispatch();
	const navigate = useNavigate();
	const { reminder } = useReminder();
	const [data, setData] = useState("");
	const [loading, setLoading] = useState<boolean>(false);
	const { activeDetailsOption, activeLegVessel, onDetailsOptionCommit } = useRouteCommonContext();

	const getDailyReportDetailFront = () => {
		setLoading(true);
	};

	const getDailyReportDetailSuccess = (response) => {
		console.log(response?.data);
		setData(response?.data || "");
		setLoading(false);
	};

	const getDailyReportDetailFailed = (error) => {
		reminder("error", error?.msg ? error?.msg + ": " + error?.data : error?.data);
		setLoading(false);
	};

	const getDailyReportDetail = () => {
		const activeDailyReport = activeDetailsOption?.selectDailyReports?.[0];
		onRequest(
			"getRouteCommonDailyReportDetailsApi",
			{
				voyageNo: activeDailyReport?.voyageNo,
				reportTime: activeDailyReport?.reportDateTime,
				reportType: activeDailyReport?.type
			},
			getDailyReportDetailFront,
			getDailyReportDetailSuccess,
			getDailyReportDetailFailed,
			dispatch,
			navigate
		);
	};
	useEffect(() => {
		if (
			activeDetailsOption?.activeTab === "dailyReport" &&
			!!activeLegVessel &&
			activeDetailsOption?.selectDailyReports?.length > 0
		) {
			getDailyReportDetail();
		}
	}, [activeDetailsOption]);

	return (
		<div
			className={cn(
				style["routeCommon-dailyReportDetails"],
				"w-[1px] z-0 h-full bg-[#f4f4f4] border-t-[#ddd] border border-solid relative flex flex-col",
				{
					"w-[320px] z-[3] border-l border-l-[#E1E1E1] border-solid overflow-hidden":
						activeDetailsOption?.activeTab === "dailyReport" &&
						!!activeLegVessel &&
						activeDetailsOption?.selectDailyReports?.length > 0
				}
			)}
		>
			<FleetLoading loading={loading} />
			<div className="w-full h-[52px] bg-[#EFF1F5] py-[10px] px-[16px] flex justify-between items-center">
				<span className="text-[#000000d9] text-[16px] font-medium leading-[22px] flex items-center before:content-[''] before:w-[6px] before:h-[14px] before:rounded-full before:bg-[#355691] before:inline-block before:mr-[8px]">
					Daily Report
				</span>
				<Icon
					size={16}
					type="fleet-close"
					className="cursor-pointer"
					onClick={() => {
						onDetailsOptionCommit?.({
							selectDailyReports: []
						});
					}}
				/>
			</div>
			<div className="dailyReportDetails-container w-full h-[calc(100%-52px)] overflow-x-hidden overflow-y-auto px-[24px] py-[12px] over">
				<FleetTextarea value={data} className="dailyReportDetails-scrollArea"></FleetTextarea>
			</div>
		</div>
	);
};

export default RouteCommonDailyReportDetails;
