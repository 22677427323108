import { CommonInitItemEvent } from "@/types/event";
import { DepartureReportPortItem, TableCellType } from "./type";
import { FleetTimePicker } from "@/components/DatePicker";
import React from "react";
import dayjs from "dayjs";
import { FleetInput, FleetSelect } from "@/components";
export type TableRowType = {
  rowIndex: number;
  row: TableCellType[];
};
export const departureReportConfig: TableRowType[] = [
  {
    rowIndex: 0,
    row: [
      { colSpan: 1, rowSpan: 1, label: "PURPOSE" },
      { colSpan: 2, rowSpan: 1, label: "DEPART TIME(UTC)" },
      { colSpan: 2, rowSpan: 1, label: "ETA NEXT PORT" },
      { colSpan: 1, rowSpan: 1, label: "ADD IFO" },
      { colSpan: 1, rowSpan: 1, label: "ADD MDO" },
      { colSpan: 1, rowSpan: 1, label: "ADD FW" },
    ],
  },
  {
    rowIndex: 1,
    row: [
      {
        colSpan: 1,
        rowSpan: 1,
        prop: "purpose",
        render: (value, index, record, actions) => {
          return (
            <FleetSelect
              value={value}
              options={purposeOptions}
              onChange={(val: string) => {
                actions.onCommit?.(index, {
                  purpose: val,
                });
              }}
            ></FleetSelect>
          );
        },
      },
      {
        colSpan: 2,
        rowSpan: 1,
        prop: "departTime",
        render: (value, index, record, actions) => {
          return (
            <FleetTimePicker
              allowClear
              className="cell-select"
              value={value ? dayjs(value) : null}
              onChange={(val: string) => {
                actions.onCommit?.(index, {
                  departTime: dayjs(val).valueOf(),
                });
              }}
            ></FleetTimePicker>
          );
        },
      },
      {
        colSpan: 2,
        rowSpan: 1,
        prop: "etaNextPort",
        render: (value, index, record, actions) => {
          return (
            <FleetTimePicker
              allowClear
              className="cell-select"
              value={value ? dayjs(value) : null}
              onChange={(val: string) => {
                actions.onCommit?.(index, {
                  etaNextPort: dayjs(val).valueOf(),
                });
              }}
            ></FleetTimePicker>
          );
        },
      },
      {
        colSpan: 1,
        rowSpan: 1,
        prop: "addIfo",
        render: (value, index, record, actions) => {
          return (
            <FleetInput
              value={value}
              type={"number"}
              onChange={(val) => actions?.onCommit(index, { addIfo: +val })}
            ></FleetInput>
          );
        },
      },
      {
        colSpan: 1,
        rowSpan: 1,
        prop: "addMdo",
        render: (value, index, record, actions) => {
          return (
            <FleetInput
              value={value}
              type={"number"}
              onChange={(val) => actions?.onCommit(index, { addMdo: +val })}
            ></FleetInput>
          );
        },
      },
      {
        colSpan: 1,
        rowSpan: 1,
        prop: "addFw",
        render: (value, index, record, actions) => {
          return (
            <FleetInput
              value={value}
              type={"number"}
              onChange={(val) => actions?.onCommit(index, { addFw: +val })}
            ></FleetInput>
          );
        },
      },
    ],
  },
  {
    rowIndex: 2,
    row: [
      { colSpan: 1, rowSpan: 1, label: "DRAFT(F)" },
      { colSpan: 2, rowSpan: 1, label: "DRAFT(M)" },
      { colSpan: 2, rowSpan: 1, label: "DRAFT(A)" },
      { colSpan: 1, rowSpan: 1, label: "ROB IFO" },
      { colSpan: 1, rowSpan: 1, label: "ROB MDO" },
      { colSpan: 1, rowSpan: 1, label: "ROB FW" },
    ],
  },
  {
    rowIndex: 3,
    row: [
      {
        colSpan: 1,
        rowSpan: 1,
        prop: "driftF",
        render: (value, index, record, actions) => {
          return (
            <FleetInput
              value={value}
              type={"number"}
              onChange={(val) => actions?.onCommit(index, { driftF: +val })}
            ></FleetInput>
          );
        },
      },
      {
        colSpan: 2,
        rowSpan: 1,
        prop: "driftM",
        render: (value, index, record, actions) => {
          return (
            <FleetInput
              value={value}
              type={"number"}
              onChange={(val) => actions?.onCommit(index, { driftM: +val })}
            ></FleetInput>
          );
        },
      },
      {
        colSpan: 2,
        rowSpan: 1,
        prop: "driftA",
        render: (value, index, record, actions) => {
          return (
            <FleetInput
              value={value}
              type={"number"}
              onChange={(val) => actions?.onCommit(index, { driftA: +val })}
            ></FleetInput>
          );
        },
      },
      {
        colSpan: 1,
        rowSpan: 1,
        prop: "brobIfo",
        render: (value, index, record, actions) => {
          return (
            <FleetInput
              value={value}
              type={"number"}
              onChange={(val) => actions?.onCommit(index, { brobIfo: +val })}
            ></FleetInput>
          );
        },
      },
      {
        colSpan: 1,
        rowSpan: 1,
        prop: "brobMdo",
        render: (value, index, record, actions) => {
          return (
            <FleetInput
              value={value}
              type={"number"}
              onChange={(val) => actions?.onCommit(index, { brobMdo: +val })}
            ></FleetInput>
          );
        },
      },
      {
        colSpan: 1,
        rowSpan: 1,
        prop: "brobFw",
        render: (value, index, record, actions) => {
          return (
            <FleetInput
              value={value}
              type={"number"}
              onChange={(val) => actions?.onCommit(index, { brobFw: +val })}
            ></FleetInput>
          );
        },
      },
    ],
  },
  {
    rowIndex: 4,
    row: [
      { colSpan: 1, rowSpan: 1, label: "CARGO NAME" },
      { colSpan: 2, rowSpan: 1, label: "CARGO QTTY" },
      { colSpan: 5, rowSpan: 1, label: "NO. OF TUG(S) USED" },
    ],
  },
  {
    rowIndex: 5,
    row: [
      { colSpan: 1, rowSpan: 1, prop: "cargoName",
      render: (value, index, record, actions) => {
        return (
          <FleetInput
            value={value}
            type={"text"}
            onChange={(val) => actions?.onCommit(index, { cargoName: val })}
          ></FleetInput>
        );
      }, },
      { colSpan: 2, rowSpan: 1, prop: "cargoQuantity",
      render: (value, index, record, actions) => {
        return (
          <FleetInput
            value={value}
            type={"number"}
            onChange={(val) => actions?.onCommit(index, { cargoQuantity: +val })}
          ></FleetInput>
        );
      }, },
      { colSpan: 5, rowSpan: 1, prop: "noOfTugUsed",
      render: (value, index, record, actions) => {
        return (
          <FleetInput
            value={value}
            type={"number"}
            onChange={(val) => actions?.onCommit(index, { noOfTugUsed: +val })}
          ></FleetInput>
        );
      }, },
    ],
  },
];
export const purposeOptions = [
  { value: "Load", label: "Load" },
  { value: "Discharge", label: "Discharge" },
  { value: "Bunker", label: "Bunker" },
  { value: "Transit", label: "Transit" },
];

export const newDepartureReportRow: CommonInitItemEvent<
  DepartureReportPortItem
> = (item) => {
  return {
    voyageId: item?.voyageId ?? null,
    purpose: item?.purpose ?? "",
    departTime: item?.departTime ?? null,
    etaNextPort: item?.etaNextPort ?? null,
    addIfo: item?.addIfo ?? "",
    addMdo: item?.addMdo ?? "",
    addFw: item?.addFw ?? "",
    driftF: item?.driftF ?? "",
    driftM: item?.driftM ?? "",
    driftA: item?.driftA ?? "",
    brobIfo: item?.brobIfo ?? "",
    brobMdo: item?.brobMdo ?? "",
    brobFw: item?.brobFw ?? "",
    cargoName: item?.cargoName ?? "",
    cargoQuantity: item?.cargoQuantity ?? "",
    noOfTugUsed: item?.noOfTugUsed ?? "",
    remark: item?.remark ?? "",
  };
};
