import { FleetOptionType } from "@/types/advancedType";

export const vesselTypeSubOption: FleetOptionType<
  | "VLCC"
  | "Aframax"
  | "Suezmax"
  | "145K"
  | "160K"
  | "174K"
  | "200+K"
  | "Other"
  | "VLOC"
  | "Capesize"
  | "Post-Panamax"
  | "Panamax"
  | "Supramax"
  | "Handymax"
  | "Handysize"
  | "Small Bulk"
  | "PCC"
  | "CNTR",
  1 | 2 | 3 | 11 | 12 | 13 | 14 | 15 | 21 | 22 | 23 | 24 | 25 | 26 | 27 | 28 | 31 | 41
>[] = [
  { label: "VLCC", value: 1 },
  { label: "Aframax", value: 2 },
  { label: "Suezmax", value: 3 },
  { label: "145K", value: 11 },
  { label: "160K", value: 12 },
  { label: "174K", value: 13 },
  { label: "200+K", value: 14 },
  { label: "Other", value: 15 },
  { label: "VLOC", value: 21 },
  { label: "Capesize", value: 22 },
  { label: "Post-Panamax", value: 23 },
  { label: "Panamax", value: 24 },
  { label: "Supramax", value: 25 },
  { label: "Handymax", value: 26 },
  { label: "Handysize", value: 27 },
  { label: "Small Bulk", value: 28 },
  { label: "PCC", value: 31 },
  { label: "CNTR", value: 41 }
];