import React, { Fragment, ReactNode, useMemo } from "react";
import { useInteractions, useFloating, useClick, useDismiss, flip, shift, offset, autoUpdate, } from "@floating-ui/react"
import classnames from "classnames";
import style from "./index.module.less";
import { createPortal } from "react-dom";

const useSelect = (open: boolean, onDropVisible: (value: boolean) => void) => {
  const {
    refs,
    floatingStyles,
    context
  } = useFloating({
    open,
    onOpenChange: onDropVisible,
    whileElementsMounted: autoUpdate,
    placement: "bottom-start",
    middleware: [
      offset(10),
      flip(),
      shift()
    ]
  });

  const clickEvent = useClick(context);

  const dismiss = useDismiss(context)

  const {
    getFloatingProps,
    getReferenceProps
  } = useInteractions([clickEvent, dismiss])

  const el = useMemo(() => {
    const el = document.createElement('div');
    el.className = `wrapper`;
    el.style.cssText = "z-index:9999;position:fixed;"

    document.body.appendChild(el);
    return el;
  }, []);

  return {
    el,
    refs,
    getReferenceProps,
    getFloatingProps,
    floatingStyles
  }
}

const FleetSelect: React.FC<{
  value?: string | number,
  open: boolean,
  render: ReactNode,
  onDropVisible: (value: boolean) => void
}> = ({
  value,
  open,
  render,
  onDropVisible
}) => {
    const {
      el,
      refs,
      getReferenceProps,
      getFloatingProps,
      floatingStyles,
    } = useSelect(open, onDropVisible)

    const floating = open && <div
      className={classnames(style["fleet-select-options"], {
        [style["fleet-select-options--open"]]: open
      })}
      {...getFloatingProps()}
      style={floatingStyles}
      ref={refs?.setFloating}

    >
      {render}
    </div>

    return <Fragment>
      <div
        className={style["fleet-select"]}
        ref={refs?.setReference}
        {...getReferenceProps()}
      >
        {value}
      </div>
      {createPortal(floating, el)}
    </Fragment>
  }

export default FleetSelect