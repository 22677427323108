import { CommonColumnChangeEvent } from "@/types/event";
import { useRef, useState } from "react";
import { InvCommonFieldItemType, initialInvCommonFieldItem } from "../module";

const useFieldNames = () => {
  const [dataSource, setDataSource] = useState<InvCommonFieldItemType[]>([]);

  const delIds = useRef<number[]>([]);

  const change: CommonColumnChangeEvent<InvCommonFieldItemType> = (currentIndex, key, value) => {
    setDataSource(prev => {
      const currentItem = prev?.[currentIndex];
      prev.splice(currentIndex, 1, {
        ...currentItem,
        [key]: value
      });
      return [...prev]
    })
  }

  const add = (item: InvCommonFieldItemType) => {
    setDataSource(prev => ([...prev, initialInvCommonFieldItem("init", item)]))
  }

  const init = (type: "init" | "details", items: InvCommonFieldItemType[] = []) => {
    setDataSource(items?.map(item => initialInvCommonFieldItem(type, item)));
    delIds.current = [];
  }

  const remove = (currentIndex: number) => {
    const item = dataSource?.[currentIndex];
    item?.id && delIds.current.push(item?.id);
    setDataSource(prev => {
      prev.splice(currentIndex, 1);
      return [...prev]
    })
  }

  return {
    dataSource,
    delIds,
    change,
    init,
    remove,
    add
  }
}

export default useFieldNames