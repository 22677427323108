import React, { FC } from "react";
import style from "./index.module.less";
import { RouteCommonTabsProps } from "./type";
import { FleetSelect } from "@/components";
import classNames from "classnames";
import { voyageTabOptions } from "./source";
import { Icon } from "@/_components";

const RouteCommonTabs: FC<RouteCommonTabsProps> = ({
	activeTab,
	visibleRows,
	onSelect,
	onShow
}) => {
	return (
		<div className={style["routeCoommon-tabs"]}>
			<FleetSelect
				value={activeTab}
				options={voyageTabOptions}
				className="tabs-container"
				onSelect={onSelect}
				labelRender={(props: (typeof voyageTabOptions)[number]) => {
					return (
						<span className={classNames("routeCommon-tab--active", props?.value)}>
							{props?.label}
						</span>
					);
				}}
			/>
			<div
				className="tabs-visible"
				onClick={() => {
					onShow?.(activeTab);
				}}
			>
				<Icon type={visibleRows?.includes(activeTab) ? "fleet-legend_show" : "fleet-legend_hide"} />
			</div>
		</div>
	);
};

export default RouteCommonTabs;
