import { ParameterType } from "@/api";
import { useAppDispatch } from "@/hook";
import useReminder from "@/hook/useReminder";
import {
	CommonColumnChangeEvent,
	CommonColumnCommitEvent,
	CommonInitItemEvent
} from "@/types/event";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { MovementOtherReportsProps } from "../type";

const useCommonReport = <T extends { voyageId?: string; portName: string }>(
	voyageId: MovementOtherReportsProps["voyageId"],
	initReportItem: CommonInitItemEvent<T>,
	actions: {
		getReportList: ParameterType<{ voyageId: MovementOtherReportsProps["voyageId"] }>;
		saveReport: ParameterType<T[]>;
		deleteReport: ParameterType<number[]>;
	}
) => {
	const dispatch = useAppDispatch();
	const { reminder } = useReminder();
	const navigate = useNavigate();

	const [loading, setLoading] = useState(false);

	const [dataSource, setDataSource] = useState<T[]>([]);

	const commit: CommonColumnCommitEvent<T> = (currentIndex, item) => {
		setDataSource((prev) => {
			const currentItem = prev?.[currentIndex];
			prev?.splice(currentIndex, 1, {
				...currentItem,
				...item
			});
			return [...prev];
		});
	};

	const change: CommonColumnChangeEvent<T> = (currentIndex, key, value) => {
		setDataSource((prev) => {
			const currentItem = prev?.[currentIndex];
			prev?.splice(currentIndex, 1, {
				...currentItem,
				[key]: value
			});
			return [...prev];
		});
	};

	const add = () => {
		setDataSource((prev) => [...prev, initReportItem({ voyageId: voyageId })]);
	};

	const remove = (currentIndex: number) => {
		setDataSource((prev) => {
			prev?.splice(currentIndex, 1);
			return [...prev];
		});
	};

	const handleRequestFront = () => {
		setLoading(true);
	};

	const handleRequestFailed = (error) => {
		setLoading(false);
		reminder("error", error?.msg ? error?.msg + ": " + error?.data : error?.data);
	};

	const handleDelete = (currentIndex: number, id: number) => {
		if (!id) {
			remove(currentIndex);
			return;
		}
		actions?.deleteReport(
			[id],
			handleRequestFront,
			(response) => {
				remove(currentIndex);
				setLoading(false);
			},
			handleRequestFailed,
			dispatch,
			navigate
		);
	};

	const getReportSuccess = (response) => {
		setDataSource(response?.data ?? []);
		setLoading(false);
	};

	const getReportList = () => {
		actions?.getReportList(
			{ voyageId },
			handleRequestFront,
			getReportSuccess,
			handleRequestFailed,
			dispatch,
			navigate
		);
	};

	const handleSaveSuccess = (response) => {
		getReportList();
	};

	const handleSave = () => {
		const params = dataSource?.filter((item) => !!item?.portName);
		if (params.length != 0) {
			actions?.saveReport(
				params,
				handleRequestFront,
				handleSaveSuccess,
				handleRequestFailed,
				dispatch,
				navigate
			);
		}
	};

	return {
		loading,
		dataSource,
		commit,
		change,
		add,
		handleDelete,
		getReportList,
		handleSave
	};
};

export default useCommonReport;
