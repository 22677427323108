import { useMemo } from "react";
import { MovementPortRotationProps } from "../type";
import { actualColumns, estColumns } from "../source";
import { matchPortType } from "@/tools/port";

const useCalcIndex = (
	activeType: MovementPortRotationProps["activeType"],
	actualItems: MovementPortRotationProps["actualItems"],
	estItems: MovementPortRotationProps["estItems"],
	estOtherItem: MovementPortRotationProps["estOtherItem"]
) => {
	const columns = useMemo(() => {
		return activeType === "actual" ? actualColumns : estColumns;
	}, [activeType]);

	const dataSource = useMemo(() => {
		return activeType === "actual" ? actualItems : estItems;
	}, [activeType, actualItems, estItems]);

	const actualSummary: Partial<MovementPortRotationProps["actualItems"][number]> = useMemo(() => {
		return actualItems?.reduce(
			(prev, curr) => {
				return {
					distance: prev?.distance + +curr?.distance,
					seaDay: prev?.seaDay + +curr?.seaDay,
					workDays: prev?.workDays + +curr?.workDays,
					idleDays: prev?.idleDays + +curr?.idleDays,
					totalDays: prev?.totalDays + +curr?.totalDays,
					portExpense: prev?.portExpense + +curr?.portExpense,
					seaFuelHsFo: prev?.seaFuelHsFo + +curr?.seaFuelHsFo,
					seaFuelLsFo: prev?.seaFuelLsFo + +curr?.seaFuelLsFo,
					seaFuelMdo: prev?.seaFuelMdo + +curr?.seaFuelMdo,
					seaFuelMgo: prev?.seaFuelMgo + +curr?.seaFuelMgo,
					portHsFo: prev?.portHsFo + +curr?.portHsFo,
					portLsFo: prev?.portLsFo + +curr?.portLsFo,
					portMdo: prev?.portMdo + +curr?.portMdo,
					portMgo: prev?.portMgo + +curr?.portMgo
					// hsfoBrob: prev?.hsfoBrob + +curr?.hsfoBrob,
					// lsfoBrob: prev?.lsfoBrob + +curr?.lsfoBrob,
					// mdoBrob: prev?.mdoBrob + +curr?.mdoBrob,
					// mgoBrob: prev?.mgoBrob + +curr?.mgoBrob,
				};
			},
			{
				distance: 0,
				seaDay: 0,
				workDays: 0,
				idleDays: 0,
				totalDays: 0,
				portExpense: 0,
				seaFuelHsFo: 0,
				seaFuelLsFo: 0,
				seaFuelMdo: 0,
				seaFuelMgo: 0,
				portHsFo: 0,
				portLsFo: 0,
				portMdo: 0,
				portMgo: 0
				// hsfoBrob: 0,
				// lsfoBrob: 0,
				// mdoBrob: 0,
				// mgoBrob: 0
			}
		);
	}, [actualItems]);

	const estSummary: Partial<MovementPortRotationProps["estItems"][number]> = useMemo(() => {
		const result = estItems?.reduce(
			(prev, curr, arr, index) => {
				const portType = curr?.purpose === "RD" ? "ballast" : matchPortType(curr?.purpose);
				console.log("")
				return {
					ballastDistance: prev?.ballastDistance + (portType === "ballast" ? +curr?.distance : 0),
					ballastEcaDistance: prev?.ballastEcaDistance + (portType === "ballast" ? +curr?.ecaDistance : 0),
					ladenEcaDistance: prev?.ladenEcaDistance + (portType === "laden" ? +curr?.ecaDistance : 0),
					ladenDistance: prev?.ladenDistance + (portType === "laden" ? +curr?.distance : 0),
					ballastSeaDays: prev?.ballastSeaDays + (portType === "ballast" ? +curr?.seaDays : 0),
					ballastEcaDays: prev?.ballastEcaDays + (portType === "ballast" ? +curr?.insideEcaDays : 0),
					ladenSeaDays: prev?.ladenSeaDays + (portType === "laden" ? +curr?.seaDays : 0),
					ladenEcaDays: prev?.ladenEcaDays + (portType === "laden" ? +curr?.insideEcaDays : 0),
					workDays: prev?.workDays + +curr?.workDays,
					idleDays: prev?.idleDays + +curr?.idleDays,
					totalDays: prev?.totalDays + +curr?.totalDays,
					portExpense: prev?.portExpense + +curr?.portExpense,
					seaMdo: prev?.seaMdo + +curr?.seaMdo,
					seaFo: prev?.seaFo + +curr?.seaFo,
					conDo: prev?.conDo + +curr?.conDo,
					conFo: prev?.conFo + +curr?.conFo
				};
			},
			{
				ballastDistance: 0,
				ballastEcaDistance: 0,
				ladenEcaDistance: 0,
				ladenDistance: 0,
				ballastSeaDays: 0,
				ballastEcaDays: 0,
				ladenSeaDays: 0,
				ladenEcaDays: 0,
				workDays: 0,
				idleDays: 0,
				totalDays: 0,
				portExpense: 0,
				seaMdo: 0,
				seaFo: 0,
				conDo: 0,
				conFo: 0
			}
		);
		const ttlBallastDistance =estOtherItem?.ballastMarginFlag === 1 ? +result?.ballastDistance : +result?.ballastDistance * (1 + +estOtherItem?.ballastMarginDistance / 100),
			ttlBallastEcaDistance = estOtherItem?.ballastMarginFlag === 1 ? +result?.ballastEcaDistance : +result?.ballastEcaDistance * (1 + +estOtherItem?.ballastMarginDistance / 100),
			ttlLadenDistance =estOtherItem?.ladenMarginFlag === 1 ? +result?.ladenDistance : +result?.ladenDistance * (1 + +estOtherItem?.ladenMarginDistance / 100),
			ttlLadenEcaDistance = estOtherItem?.ladenMarginFlag === 1 ? +result?.ladenEcaDistance : +result?.ladenEcaDistance * (1 + +estOtherItem?.ladenMarginDistance / 100),
			ttlSeaDayBallast =estOtherItem?.ballastMarginFlag === 1 ? (+result?.ballastSeaDays+ +estOtherItem?.ballastMarginDay) :  +result?.ballastSeaDays,
			ttlEcaDayBallast =estOtherItem?.ballastMarginFlag === 1 ? (+result?.ballastEcaDays+ +estOtherItem?.ballastMarginDay) :  +result?.ballastEcaDays,
			ttlSeaDayLaden =estOtherItem?.ladenMarginFlag === 1 ? (+result?.ladenSeaDays+ +estOtherItem?.ladenMarginDay) :  +result?.ladenSeaDays,
			ttlEcaDayLaden =estOtherItem?.ladenMarginFlag === 1 ? (+result?.ladenEcaDays+ +estOtherItem?.ladenMarginDay) :  +result?.ladenEcaDays;
		return {
			...result,
			distance: ttlBallastDistance + ttlLadenDistance,
			ecaDistance: ttlBallastEcaDistance + ttlLadenEcaDistance,
			seaDays: ttlSeaDayBallast + ttlSeaDayLaden,
			insideEcaDays: ttlEcaDayBallast + ttlEcaDayLaden
		}
	}, [estItems,estOtherItem]);

	const summary = useMemo(() => {
		return activeType === "actual" ? actualSummary : estSummary;
	}, [activeType, estSummary, actualSummary]);

	return {
		columns,
		dataSource,
		summary
	};
};

export default useCalcIndex;
