import { ExcludeKeys, ExtendsKeys } from "@/types";
import { CargoBookLabelMap, CargoBookKeys } from "@/types/voyageManage/cargoBook/index";
import { Dayjs } from "dayjs";

export type CargoUnitType = "MT" | "CBM";

export type FreightType = "FR" | "LP";

export type CBCargoInfoKeys = ExtendsKeys<CargoBookKeys,
  "cargoName" | "cargoQuantity" | "cargoUnit" |
  "freightRate" | "freightType" | "commission" |
  "brokerName" | "brokerId" | "brokerage"
>;

export type CBCargoInfoNumKeys = ExtendsKeys<CBCargoInfoKeys,
  "cargoQuantity" | "freightRate" | "commission" | "brokerage"
>;

export type CBCargoInfoEnumKeys = ExtendsKeys<CBCargoInfoKeys,
  "freightType" | "cargoUnit"
>;

export type CBCargoInfoStrKeys = ExcludeKeys<CBCargoInfoKeys,
  CBCargoInfoNumKeys | CBCargoInfoEnumKeys
>;

export type CBCargoInfoFormKeys = CBCargoInfoNumKeys | CBCargoInfoEnumKeys | CBCargoInfoStrKeys;

export type CBCargoInfoFormType = Record<CBCargoInfoStrKeys, string> &
  Record<CBCargoInfoNumKeys, number | ""> &
  {
    [K in CBCargoInfoEnumKeys]: K extends "freightType" ? FreightType : (
      K extends "cargoUnit" ? CargoUnitType : never
    )
  };

/**
* hooks type
*/

export type CBCargoInfoFormChangeEvent = <K extends CBCargoInfoFormKeys>(key: K, value: CBCargoInfoFormType[K]) => void;

export type CBCargoInfoFormCommitEvent = (item: Partial<CBCargoInfoFormType>) => void;

/**
 * hfbasicprops type
 */

export type CBPaymentTermsFormProps = {
  item: CBCargoInfoFormType;
  onChange: CBCargoInfoFormChangeEvent;
  onCommit?: CBCargoInfoFormCommitEvent;
}

/**
 * init data
 */
export const initialCargoInfoForm: CBCargoInfoFormType = {
  cargoName: "",
  cargoQuantity: "",
  cargoUnit: "MT",
  freightRate: "",
  freightType: "FR",
  commission: "",
  brokerage: "",
  brokerId: "",
  brokerName: ""
}

export const checkCBCargoInfoKeys:Partial<CBCargoInfoFormKeys>[] = [
  "cargoName","cargoQuantity","freightRate"
]