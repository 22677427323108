import React, { FC } from "react";
import style from "./index.module.less";
import classnames from "classnames";
import { InvFreightSettlementProps, InvFreightSettlementType } from "./type";
import { InvFreightPort } from "./components";
import { FleetVerifyInput } from "@/components";
import { FleetTabbar } from "@/components/Tabs";
import { calcFreightValues } from "./tools";
import { InvoiceAddTo } from "@/pages/voyagemanage/business/invoices/module";

const InvFreightSettlement: FC<InvFreightSettlementProps> = ({
	item,
	onCommit,
	loadPortItems,
	dischargePortItems,
	onPortItemChange,
	onPortItemCommit,
	otherItem,
	onOtherItemChange
}) => {
	return (
		<div className={classnames("invoicesDetail-form", style["invFreight-settlement"])}>
			<div className="invoicesForm-header">
				<span>Settlement</span>
				<span>Full cost settlement</span>
			</div>
			<InvoiceAddTo type="addFreightIncome" value={otherItem?.addFreightIncome} onChange={onOtherItemChange}>
				<span className="invoicesForm-subheading">Freight Income</span>
			</InvoiceAddTo>
			<div className="invoicesForm-container">
				<div className="invoicesForm-freightRate">
					<FleetVerifyInput
						label={`${item?.freightType === "FR" ? "Freight Rate" : "Lumpsum"}`}
						value={item?.freightRate}
						needVerity
						type="number"
						onChange={(val: number) => {
							const params = calcFreightValues(item, "freightRate", val);
							onCommit?.(params);
						}}
					/>
					<FleetTabbar
						// disabled
						list={["FR", "LP"]}
						value={item?.freightType}
						onChange={(val: InvFreightSettlementType["freightType"]) => {
							const params = calcFreightValues(item, "freightType", val);
							onCommit?.(params);
						}}
					/>
				</div>
				<FleetVerifyInput
					value={item?.ttlCargoQuantity}
					type="number"
					needVerity
					disabled={otherItem?.addCargo}
					onChange={(val: number) => {
						onCommit?.({ ttlCargoQuantity: val });
					}}
					label="TTL Cargo Quantity"
				/>
				<FleetVerifyInput value={item?.freightIncome} needVerity disabled type="number" label="Freight Income" />
				<FleetVerifyInput
					label="Payment Ratio(%)"
					value={item?.freightPercent}
					needVerity
					type="number"
					max={100}
					min={0}
					onChange={(val: number) => {
						const params = calcFreightValues(item, "freightPercent", val);
						onCommit?.(params);
					}}
				/>
			</div>
			<span className="invoicesForm-subheading">Others</span>
			<div className="invoicesForm-container">
				<div className="invoicesForm-freight">
					<FleetVerifyInput value={item?.addComm} type="number" disabled label="Add. Comm." />
					<FleetVerifyInput
						value={item?.addCommRate}
						containerClassname="freight-unit"
						max={100}
						label="%"
						min={0}
						type="number"
						onChange={(val: number) => {
							const addComm = (+item?.freightIncome * +val) / 100;
							onCommit?.({
								addCommRate: val,
								addComm: addComm
							});
						}}
					/>
				</div>
				<div className="invoicesForm-freight">
					<FleetVerifyInput value={item?.brokerage} type="number" label="Brokerage" disabled />
					<FleetVerifyInput
						value={item?.brokerageRate}
						containerClassname="freight-unit"
						label="%"
						max={100}
						min={0}
						type="number"
						onChange={(val: number) => {
							const brokerage = (+item?.freightIncome * +val) / 100;
							onCommit?.({
								brokerageRate: val,
								brokerage: brokerage
							});
						}}
					/>
				</div>
			</div>
			<span className="invoicesRate-computed">
				Tips: Add. Comm.= Ocean Freight * (N%); Brokerage= (N%) * Ocean Freight;{" "}
			</span>
			<InvFreightPort
				loadItems={loadPortItems}
				dischargeItems={dischargePortItems}
				onChange={onPortItemChange}
				onCommit={onPortItemCommit}
				otherItem={otherItem}
				onOtherItemChange={onOtherItemChange}
			/>
			<span className="invoicesRate-computed">Tips: Ocean Freight= Cargo Quantity (N%)* Freight Rate; </span>
		</div>
	);
};

export default InvFreightSettlement;
