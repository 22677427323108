import { getFreightInvHistoryAction, getStatementHistoryAction } from "@/action/voyageManage/invoices";
import { useAppDispatch } from "@/hook";
import useReminder from "@/hook/useReminder";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { InvoicesHistoryDuplicateItemType, InvoicesHistoryDuplicateProps } from "../type";

const useIndex = (
  hide: InvoicesHistoryDuplicateProps["hide"],
  invId: InvoicesHistoryDuplicateProps["invId"],
  invoicesType: InvoicesHistoryDuplicateProps["invoicesType"],
  period: InvoicesHistoryDuplicateProps["period"]
) => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { reminder } = useReminder()
  const [dataSource, setDataSource] = useState<InvoicesHistoryDuplicateItemType[]>([]);
  const [loading, setLoading] = useState<boolean>(false);

  const getInvHistoryFront = () => {
    setLoading(true)
  }

  const getInvHistortSuccess = (response) => {
    setDataSource(response?.data)
    setLoading(false)
  }

  const getInvHistoryError = (error) => {
    reminder("error", error?.msg ? (error?.msg + ": " + error?.data) : error?.data);
    setLoading(false);
  }

  const getInvHistory = () => {
    switch (invoicesType) {
      case "freightInv":
        getFreightInvHistoryAction(
          {
            invId: invId,
            period: period
          },
          getInvHistoryFront,
          getInvHistortSuccess,
          getInvHistoryError,
          dispatch,
          navigate
        )
        break;
      case "statement":
        getStatementHistoryAction(
          {
            invId: invId,
            period: period
          },
          getInvHistoryFront,
          getInvHistortSuccess,
          getInvHistoryError,
          dispatch,
          navigate
        )
        break;
      default:
        break;
    }
  }


  useEffect(() => {
    if (hide) return;
    getInvHistory()
  }, [hide])

  return {
    loading,
    dataSource
  }
}

export default useIndex