import { useCommonIndex } from "@/hook"
import { initialEstOtherItem } from "../source"

const useOtherIndex = () => {
  const {
    dataSource,
    init,
    commit,
    change
  } = useCommonIndex(initialEstOtherItem)

  return {
    dataSource,
    change,
    commit,
    init
  }
}

export default useOtherIndex