import React, { useEffect, useState, useRef } from "react"
import enUSIntl from 'antd/lib/locale/en_US';
import { Modal,Button,ConfigProvider } from 'antd'
import { PlusOutlined, MinusOutlined } from '@ant-design/icons'
import type { ProColumns } from '@ant-design/pro-components';
import { EditableProTable } from '@ant-design/pro-components';
import { DetailDrawerItem } from '@/pages/monitoring/components/DetailDrawer'
import { SegmentInfo } from '@/pages/monitoring/components'
import { getSegmentListAction, getCPTermsAction, addSegmentsAction, updateSegmentsAction } from '@/action/monitoring/vessel'
import { UpdateSegmentParams } from "@/api/monitoring/vessel";
import useReminder from "@/hook/useReminder";
import { FleetLoading } from "@/components";
import dayjs from 'dayjs'
import style from './index.module.less'

export type SegmentProps = {
  data: DetailDrawerItem,
  isModalOpen: boolean,
  onCancel?:() => void
}

const Segment: React.FC<SegmentProps> = ({ data, isModalOpen, onCancel }) => {
  const [tableData, setTableData] = useState<readonly SegmentInfo[]>([])
  const [loading, setLoading] = useState<boolean>(false)
  const [cpterms, setCPTerms] = useState([])
  // 用来满足后端接口的奇怪调用逻辑 1.区分保存时操作的接口。编辑接口：queryData有数据；新增接口：queryData无数据； 2. 拼凑接口参数
  const [queryData, setQueryData] = useState<SegmentInfo[]>([])
  const { reminder } = useReminder();
  const editableFormRef = useRef(null);
  const [editableKeys, setEditableRowKeys] = useState<React.Key[]>([]);
  const columns: ProColumns<SegmentInfo>[] = [
    {
      title: 'Seg',
      dataIndex: 'segNo',
      valueType: 'digit',
      width: 120,
      align: 'center',
      fieldProps: (_, { rowIndex }) => {
        return {
          onChange: (value: number) => {
            const cpTermsId = tableData[rowIndex]?.cpTermsId
            if (cpTermsId) {
              let newtableData = [...tableData]
              newtableData = newtableData?.map?.((t: SegmentInfo, index: number) => {
                if (t.cpTermsId === cpTermsId ) {
                  editableFormRef?.current?.setRowData?.(index, { segNo: value })
                }
                return t
              })
            }
          }
        }
      }
    },
    {
      title: 'DueDate',
      dataIndex: 'dueDatetime',
      valueType: 'dateTime',
      align: 'center',
      width: 500,
      fieldProps: {
        format: 'YYYY-MM-DD HH:mm',
      },
      formItemProps: {
        rules: [
          { required: true, message: 'This value cannot be empty'}
        ]
      }
    },
    {
      title: 'Plan',
      width: 120,
      align:'center',
      dataIndex: 'cpTermsId',
      valueType: 'select',
      fieldProps: (_, { rowIndex }) => {
        return {
          options:cpterms,
          onChange: (value: number) => {
            const [first] = tableData.filter((t: SegmentInfo) => t.cpTermsId === value)
            if (first) {
              let newtableData = [...tableData]
              newtableData = newtableData?.map?.((t: SegmentInfo, index: number) => {
                if (index === rowIndex || (t.cpTermsId === first.cpTermsId && t.segNo !== first.segNo)) {
                  editableFormRef?.current?.setRowData?.(index, { segNo: first.segNo })
                }
                return t
              })
            }
          }
        }
      },
      formItemProps: {
        rules: [
          { required: true, message: 'This value cannot be empty'}
        ]
      }
    },
    {
      title: '',
      valueType: 'option',
      align: 'center',
      width: 100,
      render: () => null
    }
  ]

  const loadCPTerms = async () => {
    try {
      const res = await getCPTermsAction(data?.voyageNo)
      const list = (res?.data || []).map((t: any) => {
        return { label:t?.cpTermsName,value:t?.id }
      })
      setCPTerms(list)
    } catch (error) {
      reminder("error", error?.data)
      setCPTerms([])
    }
  }

  const loadData = () => {
    const { voyageNo, vesselStatus } = data ?? {}
    if (voyageNo && vesselStatus) {
      getSegmentListAction(
        { voyageNo, legType: vesselStatus },
        () => { setLoading(true) },
        ({ data }) => {
          setLoading(false)
          const table = (data || []).map((t: SegmentInfo, index: number) => {
            return {
              ...t,
              guid: t.id,
              dueDatetime: t.dueDatetime ? dayjs(t.dueDatetime) : t.dueDatetime
            }
          })
          setTableData(table)
          setQueryData(table)
          setEditableRowKeys(table.map((item: SegmentInfo) => item.guid))
        },
        (error) => {
          setLoading(false)
          reminder("error", error?.data)
        }
      )
    }
  }

  const add = (payload:UpdateSegmentParams) => {
    addSegmentsAction(
      payload,
      () => { setLoading(true) },
      ({ data }) => {
        setLoading(false)
        reminder('success','success')
        onCancel?.()
      },
      (error) => {
        setLoading(false)
        reminder("error", error?.data)
      },
    )
  }

  const update = (payload:UpdateSegmentParams) => {
    updateSegmentsAction(
      payload,
      () => { setLoading(true) },
      ({ data }) => {
        setLoading(false)
        reminder('success','success')
        onCancel?.()
      },
      (error) => {
        setLoading(false)
        reminder("error", error?.data)
      },
    )
  }

  const handleOk = async () => {
    try {
    const vaild = await editableFormRef?.current?.validateFields()
    if(vaild){
      const delItems = queryData.filter((t: SegmentInfo) => !editableKeys.includes(t.guid))
      const segmentList = tableData.map((t: SegmentInfo) => {
        const { guid, ...rest } = t
        if (rest.dueDatetime) {
          rest.dueDatetime = dayjs(rest.dueDatetime).valueOf?.()
        }
        return rest
      })
      const payload: UpdateSegmentParams = { delIds: delItems.map((t: SegmentInfo) => t.id), segmentList }

      if (queryData.length > 0) {
        update(payload)
      } else {
        add(payload)
        }            
      }      
    } catch (error) {
      
    }
  }

  useEffect(() => {
    if(isModalOpen) {
      loadCPTerms()
      loadData()
    } 
  }, [isModalOpen])
  
  return <ConfigProvider locale={enUSIntl}>
  <FleetLoading loading={loading} />
    <Modal
    maskClosable={false}
    destroyOnClose={true}
    centered
    width={946}
    className={style.segment}
    closeIcon={false}
    title={<>
      <div className={style.header}>
        <span className={style.title}>Segment</span>
        <Button
          key="add"
          icon={<PlusOutlined />}
          onClick={() => {
            const newRecord = {
              guid: Date.now(),
              voyageNo: data?.voyageNo,
              legType: data?.vesselStatus,
              id: null,
              segNo: (tableData?.length||0) + 1
            }
            setTableData([...tableData,newRecord])
            setEditableRowKeys([...editableKeys,newRecord.guid])
          }}
          >
            Add
          </Button>
      </div>
    </>}
    open={isModalOpen}
    okText="Confirm"
    onOk={handleOk}
    onCancel={onCancel}>
      <EditableProTable<SegmentInfo>
        className={style.editableTable}
        params={{
          voyageNo: data?.voyageNo
        }}
        bordered={true}
        headerTitle={null}
        columns={columns}
        rowKey="guid"
        value={tableData}
        onChange={setTableData}
        recordCreatorProps={false}
        scroll={{
          y: 158
        }}
        editable={{
          type: 'multiple',
          editableKeys,
          deleteText: <Button className={style.delete} key="delete" shape="circle" icon={<MinusOutlined />} />,
          actionRender: (row, config, defaultDoms) => {
            return [defaultDoms.delete];
          },
          onValuesChange: (record, recordList) => {
            setTableData(recordList);
          },
          onChange: setEditableRowKeys,
        }}
        editableFormRef={editableFormRef}
      />
    </Modal>
  </ConfigProvider>
}

export default Segment
