import { FleetInput, FleetSelect } from "@/components";
import { FleetTimePicker } from "@/components/DatePicker";
import dayjs from "dayjs";
import React from "react";
import { SegmentItem } from "./type";

export const segmentColumnConfig: Record<keyof SegmentItem, any> = {
  shiftTime: {
    render: (value, onChange, index, length) => {
      if (index === length - 1) {
        return <>--</>;
      } else {
        return (
          <FleetTimePicker
            allowClear
            className="cell-select"
            value={value ? dayjs(value) : null}
            onChange={(val: string) => {
              onChange?.(dayjs(val).valueOf(), "shiftTime", index);
            }}
          ></FleetTimePicker>
        );
      }
    },
  },
  cpSpeed: {
    render: (value, onChange, index) => {
      return (
        <FleetInput
          value={value}
          type={"number"}
          onChange={(val) => onChange(val, "cpSpeed", index)}
        ></FleetInput>
      );
    },
  },
  cpOilConsumFo: {
    render: (value, onChange, index) => {
      return (
        <FleetInput
          value={value}
          type={"number"}
          onChange={(val) => onChange(val, "cpOilConsumFo", index)}
        ></FleetInput>
      );
    },
  },
  cpOilConsumDo: {
    render: (value, onChange, index) => {
      return (
        <FleetInput
          value={value}
          type={"number"}
          onChange={(val) => onChange(val, "cpOilConsumDo", index)}
        ></FleetInput>
      );
    },
  },
  seg: {
    render: (value, onChange, index) => {
      return <>{index + 1}</>;
    },
  },
  delete: {
    render: (value, onChange, index) => {
      return (
        <span
          className="font_family fleet-minus"
          style={{ fontSize: 28 }}
          onClick={() => onChange(null, "delete", index)}
        ></span>
      );
    },
  },
};

export default segmentColumnConfig;
