import React, { useState } from "react";
import style from "./index.module.less";
import { PromptChildrenProps } from "@/common/Prompt";
import { FleetPrompt } from "@/common";
import { FleetButton, FleetIcon, FleetLoading } from "@/components";

export type NewContractProps = {
  children: React.ReactNode;
  label: string;
  loading:boolean;
  onSave?:() => void;
} & PromptChildrenProps;

const NewContract: React.FC<NewContractProps> = ({
  hide,
  children,
  loading,
  label,
  onClose,
  onSave
}) => {
  const [fillHide, setFillHide] = useState(true);
  return (
		<FleetPrompt hide={hide} mode="fixed">
			<div className={style["contract-newAdd"]}>
				<FleetLoading loading={loading} />
				<div className="newAdd-header">
					<div className="header-title">
						<span>{label}</span>
					</div>
					<FleetIcon type="FleetCloseIcon" outerLayerClassname="header-close" onClick={onClose} />
				</div>
				<div className="newAdd-container fleet-nonScrolBar">
					<div className="newAdd-scrollArea">{children}</div>
				</div>
				<div className="newAdd-footer">
					<FleetButton type="secondary" className="footer-action" onClick={onClose}>
						Cancel
					</FleetButton>
					<FleetButton onClick={onSave}>Confirm</FleetButton>
				</div>
			</div>
		</FleetPrompt>
	);
}

export default NewContract