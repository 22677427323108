import React from "react";
import { ExtendsKeys } from "@/types";
import { ColumnsType } from "antd/es/table/interface";
import { dateFormat } from "@/tools/date";

enum PurposeMap {
	DL = "delivery",
	RD = "redelivery",
	LD = "load",
	LB = "load bunker",
	LT = "load transit",
	DC = "discharge",
	DB = "discharge bunker",
	DT = "discharge transit"
}

type PortKeys =
	| "createUser"
	| "createTime"
	| "updateUser"
	| "updateTime"
	| "id"
	| "portCode"
	| "portName"
	| "portNameCn"
	| "alias"
	| "lon"
	| "lat"
	| "countryCode"
	| "country"
	| "timeZone"
	| "draftLimitation"
	| "portType";

type PortNumKeys = ExtendsKeys<
	PortKeys,
	"createUser" | "createTime" | "updateUser" | "updateTime" | "lon" | "lat" | "timeZone" | "draftLimitation"
>;

type PortStrKeys = ExtendsKeys<
	PortKeys,
	"portCode" | "portName" | "portNameCn" | "alias" | "countryCode" | "country" | "timeZone" | "portType"
>;

type PortIdsKeys = ExtendsKeys<PortKeys, "id">;

type DatabasePortKeys = PortNumKeys | PortStrKeys | PortIdsKeys;

export type DatabasePortItemType = Record<PortNumKeys, number> &
	Record<PortStrKeys, string> &
	Partial<Record<PortIdsKeys, string | number>>;

export type DatabasePortColumnKeys = DatabasePortKeys | "option";

export type DataType = {
	key: React.Key;
} & Record<DatabasePortColumnKeys, any>;

export const columns: ColumnsType<DataType> = [
	{
		dataIndex: "portName",
		width: 150,
		title: "Port Name",
		key: "portName",
		fixed: "left",
		align: "center",
		render: (value, record, index) => {
			return <span>{record?.portName ?? record?.portNameCn}</span>;
		}
	},
	{
		dataIndex: "portCode",
		width: 200,
		title: "Port Code",
		key: "portCode",
		align: "center"
	},
	{
		dataIndex: "portType",
		width: 100,
		title: "Port Type",
		key: "portType",
		align: "center",
		render: (value, record, index) => {
			return <span>{PurposeMap[value]}</span>;
		}
	},
	{
		dataIndex: "alias",
		width: 250,
		title: "Alias",
		key: "alias",
		align: "center",
		ellipsis: true
	},
	{
		dataIndex: "countryCode",
		width: 150,
		title: "Country Code",
		key: "countryCode",
		align: "center"
	},
	{
		dataIndex: "country",
		width: 150,
		title: "Country",
		key: "country",
		align: "center"
	},
	{
		dataIndex: "lon",
		width: 150,
		title: "LON",
		key: "lon",
		align: "center"
	},
	{
		dataIndex: "lat",
		width: 150,
		title: "LAT",
		key: "lat",
		align: "center"
	},
	{
		dataIndex: "timeZone",
		width: 150,
		title: "Time Zone",
		key: "timeZone",
		align: "center"
	},
	{
		dataIndex: "draftLimitation",
		width: 150,
		title: "DraftLimitation",
		key: "draftLimitation",
		align: "center"
	},
	{
		dataIndex: "createUser",
		width: 150,
		title: "Create User",
		key: "createUser",
		align: "center"
	},
	{
		dataIndex: "updateTime",
		width: 150,
		title: "Update Time",
		key: "updateTime",
		align: "center",
		render: (value, record, index) => {
			return <span>{dateFormat("YY-mm-dd HH:MM", value)}</span>;
		}
	},
	{
		dataIndex: "createTime",
		width: 150,
		title: "Create Time",
		key: "createTime",
		align: "center",
		render: (value, record, index) => {
			return <span>{dateFormat("YY-mm-dd HH:MM", value)}</span>;
		}
	}
];
