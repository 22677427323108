import React, { useEffect, useMemo } from "react";
import style from "./index.module.less";
import { MovementPortReportsProps } from "./type";
import { FleetCategory, FleetPrompt } from "@/common";
import {
  FleetButton,
  FleetIcon,
  FleetInput,
  FleetLoading,
  FleetRangePicker,
  FleetTooltip,
  FleetUpload,
} from "@/components";
import usePortReports from "./hook/usePortReports";
import { tabList } from "./source";
import { Tabs, Table } from "antd";
import BasicInfo from "./components/basicInfo/index";
import StowagePlan from "./components/stowagePlan/index";
import OtherRemarks from "./components/otherRemark/index";
import DetailDelays from "./components/detailDelays/index";
import BunkerLog from "./components/bunkerLog/index";
import { Button } from "@/_components";
const MovementPortReports: React.FC<MovementPortReportsProps> = ({
  hide,
  onClose,
  onConfirm,
  voyageId,
}) => {
  const {
    loading,
    isOpen,
    active,
    changeTab,
    portList,
    portReportsForm,
    handleChange,
    handleAdd,
    handleDelete,
    getMovementPortList,
    handleSelectPort,
    editMovementPortLog,
    uploadStatus,
    onUpload,
    updateMovement,
    portRotationId,
    setPortRotationId,
    scrollNow,
    resetPortLog,
    setPortReportsForm,
    setCurrentPort,
    currentPort,
    handleCommit
  } = usePortReports(voyageId, onClose, onConfirm);

  useEffect(() => {
    if (hide) {
      const initForm = resetPortLog();
      setPortRotationId(null);
      setPortReportsForm(initForm);
      return;
    }
    getMovementPortList("init");
  }, [hide]);
  useEffect(() => {
    console.log(scrollNow.current);
  }, [scrollNow.current]);
  return (
    <FleetPrompt hide={hide} loading={loading} mode="fixed">
      <div className={style["movement-portReports"]}>
        {/* <FleetLoading loading={loading} /> */}
        <div className="prompt-header--container">
          <span className="header-title">Port Log</span>
          <span
            className="font_family fleet-close header-close"
            onClick={onClose}
          ></span>
        </div>
        <div className="port-reports-main">
          <div className="port-reports-cards">
            {portList.map((item, i) => {
              return (
                <div
                  className={
                    portRotationId == item.id
                      ? "port-card selected-port"
                      : "port-card"
                  }
                  key={i}
                  onClick={() => {
                    setCurrentPort(item);
                    handleSelectPort(item);
                  }}
                >
                  <div className={"port-card-port"}>
                    <div className="port-card-name">{item.portName}</div>
                    <div className="port-card-behavier">
                      {item.purpose == "LD" ? "Loading" : "Discharging"}
                    </div>
                  </div>
                  <div className="port-card-state">
                    <span
                      className={
                        item.status
                          ? item.status == "Not"
                            ? "port-card-state state-not"
                            : "port-card-state state-update"
                          : "port-card-state"
                      }
                    >
                      {item.status || ""}
                    </span>
                  </div>
                </div>
              );
            })}
          </div>
          <div className="port-reports-container">
            <FleetUpload
              showFileList={false}
              onUpload={onUpload}
              uploadStatus={uploadStatus}
              loading={loading}
              buttonText="Analysis Excel"
            />
            <Tabs
              defaultActiveKey="1"
              activeKey={active}
              items={tabList}
              onChange={(v) => changeTab(v)}
            />
            <div className="port-reports-tables">
              <BasicInfo
                basicInfoData={portReportsForm["basicInfoForm"]}
                portLogIds={{ voyageId, portRotationId }}
                currentPort={currentPort}
                onChange={(val, prop) =>
                  handleChange(val, prop, "basicInfoForm")
                }
                onCommit={(item)=>handleCommit(item,'basicInfoForm')}
              />
              <StowagePlan
                stowagePlanData={portReportsForm["stowagePlanForm"]}
                portLogIds={{ voyageId, portRotationId }}
                onChange={(val, index, prop) =>
                  handleChange(val, prop, "stowagePlanForm", index)
                }
              />
              <BunkerLog
                bunkerLogData={portReportsForm["bunkerLogForm"]}
                portLogIds={{ voyageId, portRotationId }}
                onChange={(val, index, prop) =>
                  handleChange(val, prop, "bunkerLogForm", index)
                }
                onAdd={(row) => handleAdd(row, "bunkerLogForm")}
                onDelete={(index) => handleDelete(index, "bunkerLogForm")}
              />
              <DetailDelays
                detailDelaysData={portReportsForm["detailDelaysForm"]}
                portLogIds={{ voyageId, portRotationId }}
                onChange={(val, index, prop) =>
                  handleChange(val, prop, "detailDelaysForm", index)
                }
                onAdd={(row) => handleAdd(row, "detailDelaysForm")}
                onDelete={(index) => handleDelete(index, "detailDelaysForm")}
              />
              <OtherRemarks
                otherRemarksData={portReportsForm["otherRemarksForm"]}
                portLogIds={{ voyageId, portRotationId }}
                onChange={(val, index, prop) =>
                  handleChange(val, prop, "otherRemarksForm", index)
                }
                addRow={(row) => handleAdd(row, "otherRemarksForm")}
                deleteRow={(index) => handleDelete(index, "otherRemarksForm")}
              />
            </div>
          </div>
        </div>
        <div className="port-reports-footer">
          <Button type="secondary" onClick={onClose}>
            Cancel
          </Button>
          <Button type="secondary" onClick={updateMovement}>
            Update Movement
          </Button>
          <Button onClick={editMovementPortLog}>Confirm</Button>
        </div>
      </div>
    </FleetPrompt>
  );
};

export default MovementPortReports;
