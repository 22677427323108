import React, { FC, Fragment } from "react";
import style from "./index.module.less";
import { AreaVesselFilterItemProps } from "./type";
import { Collapse } from "antd";
import { Icon } from "@/_components";
import classnames from "classnames";
import { FleetCustomRadio } from "@/components/Radio";

export default function AreaVesselFilterItem<K extends string, V>({
	label,
	options,
	selectRows,
	selectCount,
	onSelect,
	render
}: AreaVesselFilterItemProps<K, V>) {
	return (
		<Collapse
			size="small"
			defaultActiveKey={[label]}
			expandIconPosition="end"
			className={style["areaVessel-filterItem"]}
			expandIcon={(panelProps) => {
				return (
					<Icon
						type="fleet-back"
						className={classnames("filter-item-icon", {
							active: panelProps?.isActive
						})}
					/>
				);
			}}
			bordered={false}
			items={[
				{
					key: label,
					label: (
						<div className="filter-item-header">
							<span className="filter-item-label">{label}</span>
							{(selectRows || selectCount || selectCount === 0) && (
								<span className="filter-item-count">{selectRows?.length ?? selectCount}</span>
							)}
						</div>
					),
					children: (
						<ul className="filter-item-wrapper">
							{render
								? render?.()
								: options?.map((item, index) => {
										const selected = selectRows?.includes(item?.value);
										return (
											<FleetCustomRadio
												value={selected}
												className="filter-item-select"
												onChange={(checked) => {
													onSelect?.(item?.value, !selected);
												}}
												label={item?.label}
											/>
										);
									})}
						</ul>
					)
				}
			]}
		/>
	);
}
