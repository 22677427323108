import React, { FC, useEffect, useMemo } from "react";
import classnames from "classnames";
import style from "./index.module.less";
import { MonitoringPortCallListProps } from "./type";
import { PortCallListFilter, PortCallListTable } from "./components";
import useIndex from "./hooks/useIndex";
import { FleetLoading } from "@/components";
const MonitoringPortCallList: FC<MonitoringPortCallListProps> = ({
	currentVessel,
	activeIndex,
	currentTab
}) => {
	const {
		portCallListFilterData,
		portCallListTable,
		handleCommitFilterData,
		handleDownLoad,
		getPortCallList,
		loading,
		loadMore,
		pageNum
	} = useIndex(currentVessel);
	useEffect(() => {
		if (activeIndex == "portCallList") {
			getPortCallList();
			pageNum.current = 1;
		}
	}, [currentVessel, activeIndex, currentTab]);
	return (
		<div
			className={classnames(style["portCall-list"], {
				[style["portCall-list--active"]]:
					activeIndex === "portCallList" && ["port", "area"].includes(currentTab)
			})}
		>
			<FleetLoading loading={loading} />
			<div className="portCallList-main">
				<PortCallListFilter
					vesselName={currentVessel?.vesselName}
					imo={currentVessel?.imo}
					searchParam={portCallListFilterData}
					onCommit={handleCommitFilterData}
					onDownLoad={handleDownLoad}
				/>
				<div className="portCallList-group">
					<PortCallListTable dataList={portCallListTable} year={""} />
					{/* {portCallListTable?.map((item) => {
						return <PortCallListTable dataList={item?.dataList} year={item?.year} />;
					})} */}
				</div>
				<span className="portCallList-loadMore" onClick={loadMore}>
					Load More
				</span>
			</div>
		</div>
	);
};

export default MonitoringPortCallList;
