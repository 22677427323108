import { ApiParameter, RequestApiMapType } from "@/api";
import request from "@/service";

import {
	PreopOtherIncomeParamsType,
	getPreopOtherIncomeParams,
	savePreopOtherIncomeParams
} from "./type";
import { appVersion } from "@/api/constant";

const enum ApiMap {
	getPreopOtherIncomeParameter,
	savePreopOtherIncomeParameter
}

export type ApiUrl = keyof typeof ApiMap;

const otherIncomeApi: ApiParameter<ApiUrl> = {
	getPreopOtherIncomeParameter: {
		method: "get",
		url: appVersion + "/pre-op/other-income/list"
	},
	savePreopOtherIncomeParameter: {
		method: "post",
		url: appVersion + "/pre-op/other-income/tctc"
	}
};

export const getPreopOtherIncomeApi = (params: getPreopOtherIncomeParams) => {
	return request.request({
		...otherIncomeApi.getPreopOtherIncomeParameter,
		url: `${otherIncomeApi.getPreopOtherIncomeParameter.url}/${params?.id}`
	});
};

export const savePreopOtherIncomeApi = (params: savePreopOtherIncomeParams) => {
	return request.request({
		...otherIncomeApi.savePreopOtherIncomeParameter,
		data: params
	});
};

export const otherIncomeApiMap: RequestApiMapType<ApiUrl, PreopOtherIncomeParamsType> = {
	getPreopOtherIncomeApi,
	savePreopOtherIncomeApi
};
