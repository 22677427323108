import { getPerformanceModelAction, updatePerformanceAction } from "@/action/estimation/performance";
import { updatePerformanceParams } from "@/api/estimation/performance";
import { useAppDispatch } from "@/hook";
import useReminder from "@/hook/useReminder";
import { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";

export type PerformanceProps = {
  activeKey: "L" | "B";
} & Pick<updatePerformanceParams, Exclude<keyof updatePerformanceParams, "id">>

export type PerformanceData = {
  ecoFoList: number[];
  ecoSpeedList: number[];
  fullFoList: number[];
  fullSpeedList: number[];
  maxEcoFo: number;
  maxEcoSpeed: number;
  maxFullFo: number;
  maxFullSpeed: number;
}



export type performanceChangeEvent = (key: keyof PerformanceProps, value: PerformanceProps["activeKey"] | string | number) => void;

const usePerformance = (id:string | number,mmsi: string | number, hide: boolean) => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { reminder } = useReminder();
  const [loading, setLoading] = useState(false);
  const [performance, setPerformance] = useState<PerformanceProps>({
    activeKey: "B",
    cpIfoBallastEco: 0,
    cpIfoBallastFull: 0,
    cpIfoLadenEco: 0,
    cpIfoLadenFull: 0,
    cpSpeedBallastEco: 0,
    cpSpeedBallastFull: 0,
    cpSpeedLadenEco: 0,
    cpSpeedLadenFull: 0
  });
  const pmSourceData = useRef<PerformanceData>({
    ecoFoList: [],
    ecoSpeedList: [],
    fullFoList: [],
    fullSpeedList: [],
    maxEcoFo: 0,
    maxEcoSpeed: 0,
    maxFullFo: 0,
    maxFullSpeed: 0,
  })

  const change: performanceChangeEvent = (key, value) => {
    console.log('performance key',key,value)
    setPerformance(prev => {
      return {
        ...prev,
        [key]: value
      }
    })
  }

  const getPerformanceModelFront = () => {
    setLoading(true)
  }

  const getPerformanceModelSuccess = (response) => {
    console.log('res', response);
    pmSourceData.current = {
      ...pmSourceData.current,
      ecoFoList: response?.data?.ecoFoList,
      ecoSpeedList: response?.data?.ecoSpeedList,
      fullFoList: response?.data?.fullFoList,
      fullSpeedList: response?.data?.fullSpeedList,
      maxEcoFo: response?.data?.maxEcoFo,
      maxEcoSpeed: response?.data?.maxEcoSpeed,
      maxFullFo: response?.data?.maxFullFo,
      maxFullSpeed: response?.data?.maxFullSpeed
    };
    setPerformance(prev => {
      return {
        ...prev,
        cpIfoBallastEco: response?.data?.cpIfoBallastEco ?? prev?.cpIfoBallastEco,
        cpIfoBallastFull: response?.data?.cpIfoBallastFull ?? prev?.cpIfoBallastFull,
        cpIfoLadenEco: response?.data?.cpIfoLadenEco ?? prev?.cpIfoLadenEco,
        cpIfoLadenFull: response?.data?.cpIfoLadenFull ?? prev?.cpIfoLadenFull,
        cpSpeedBallastEco: response?.data?.cpSpeedBallastEco ?? prev?.cpSpeedBallastEco,
        cpSpeedBallastFull: response?.data?.cpSpeedBallastFull ?? prev?.cpSpeedBallastFull,
        cpSpeedLadenEco: response?.data?.cpSpeedLadenEco ?? prev?.cpSpeedLadenEco,
        cpSpeedLadenFull: response?.data?.cpSpeedLadenFull ?? prev?.cpSpeedLadenFull,
      }
    })
    // reminder("success", response?.msg)
    setLoading(false)
  }

  const getPerformanceModelError = (error) => {
    setLoading(false);
    reminder("error", error?.msg ? (error?.msg + ": " + error?.data) : error?.data)
  }

  const getPerformanceModel = () => {
    if (!mmsi) {
			reminder("info", "Please select a vessel!");
			return;
		}
    getPerformanceModelAction(
      {
        resultId:id,
        mmsi: mmsi,
        lb: performance.activeKey
      },
      getPerformanceModelFront,
      getPerformanceModelSuccess,
      getPerformanceModelError,
      dispatch,
      navigate
    )
  }

  const updatePerformanceFront = () => {
    setLoading(true);
  }

  const updatePerformanceError = (error) => {
    reminder("error", error?.msg ? (error?.msg + ": " + error?.data) : error?.data);
    setLoading(false);
  }

  const updatePerformance = (onClose?:() => void) => {
    const {
      activeKey,
      ...rest
    } = performance;
    updatePerformanceAction(
      {
        id,
        ...rest
      },
      updatePerformanceFront,
      (response) => {
        reminder("success",response?.msg);
        setLoading(false);
        clear();
        onClose?.()
      },
      updatePerformanceError,
      dispatch,
      navigate
    )
  }

  const clear = () => {
    pmSourceData.current = {
      ecoFoList: [],
      ecoSpeedList: [],
      fullFoList: [],
      fullSpeedList: [],
      maxEcoFo: 0,
      maxEcoSpeed: 0,
      maxFullFo: 0,
      maxFullSpeed: 0,
    };
    setPerformance({
      activeKey: "B",
      cpIfoBallastEco: 0,
      cpIfoBallastFull: 0,
      cpIfoLadenEco: 0,
      cpIfoLadenFull: 0,
      cpSpeedBallastEco: 0,
      cpSpeedBallastFull: 0,
      cpSpeedLadenEco: 0,
      cpSpeedLadenFull: 0
    })
  }

  useEffect(() => {
    if (hide) return;
    getPerformanceModel()
  }, [hide, performance.activeKey])

  return {
    performance,
    pmSourceData: pmSourceData.current,
    updatePerformance,
    loading,
    clear,
    change
  }
}

export default usePerformance