import React, { ReactNode, useMemo, useState } from "react";
import classnames from "classnames";
import Tabs, { type TabsProps } from "antd/es/tabs/index"
import style from "./index.module.less";
import { useNavigate } from "react-router-dom";

export type FleetTabItem = {
  title?: string;
  path?: string;
  index?: boolean;
  key: string;
  label: string;
  disabled?: boolean;
  notTab?:boolean;
}

type FleetTabsProps = {
  className?: string;
  list: FleetTabItem[];
  onClick?: Function;
  defaultActiveKey?: string;
  value?: string;
  onTabClick?: (activeKey: string, e: React.KeyboardEvent<Element> | React.MouseEvent<Element, MouseEvent>) => void
}

const FleetTabs: React.FC<FleetTabsProps> = ({
  className,
  list,
  onClick,
  defaultActiveKey,
  onTabClick,
  value
}) => {

  const navigator = useNavigate();

  // console.log("activeKey", value,list)

  const handleChange = (activeKey: string) => {
    if (onClick) {
      onClick(activeKey);
      return;
    }
    navigator(activeKey)
  };

  return <div className={classnames(style["fleet-tabs"], className)}>
    <Tabs
      activeKey={value}
      items={list}
      onTabClick={onTabClick}
      className="tabs"
      tabBarGutter={36}
      onChange={handleChange}
      defaultActiveKey={defaultActiveKey}
    />
  </div>
}

export const FleetTabbar: React.FC<{
  className?: string;
  onChange?: (activeKey: string | number) => void;
  list?: [string, string] | [number, number];
  disabled?: boolean;
  value?: string | number;
  render?: (item: string | number) => ReactNode
}> = ({
  className,
  list,
  onChange,
  disabled,
  value,
  render
}) => {
    return <div className={classnames(style["fleet-tabbar"], className)}>
      {
        list?.map((item: string | number, idx: number) => {
          return <span key={idx} className={classnames("tabbar-item", {
            "left": idx === 0,
            "right": idx === 1,
            "active": item == (value || list?.[0]),
            "disabled": disabled
          })} onClick={() => {
            if (disabled) return;
            if (item == value) return;
            onChange?.(item);
          }}>
            {render ? render(item) : item}
          </span>
        })
      }
    </div>
  }

export const FleetPortTabs: React.FC<{
	onChange?: (activeKey: string | number) => void;
	disabled?: boolean;
	value?: string | number;
	list: { label: string; value: string | number }[];
	className?: string;
}> = ({ onChange, value, className, list }) => {
	return (
		<div className={classnames(style["fleet-portTabs"], className)}>
			{list?.map((item, idx) => {
				return (
					<span
						className={classnames("tab-item", {
							active: value === item?.value
						})}
						key={idx}
						onClick={() => {
							if (item?.value === value) return;
							onChange?.(item?.value);
						}}
					>
						{item?.label}
					</span>
				);
			})}
		</div>
	);
};

export default FleetTabs;