import { useAppDispatch } from "@/hook";
import useNewlyCargoAndPort from "../components/cargo&port/hooks/useIndex"
import useNewlyVessel from "../components/vesselParticular/hooks/useIndex"
import { EstimationNewlyCrudEvent, EstimationNewlyProps } from "../type";
import { useNavigate } from "react-router-dom";
import useReminder from "@/hook/useReminder";
import { useCallback, useState } from "react";
import { useTranslation } from "react-i18next";
import { addEstimationAction } from "@/action/estimation";
import { VesselParticularItemProps } from "../components/vesselParticular/type";
import { saveEstTemplateAction } from "@/action/estimation/template";

const useIndex = (onClose?: EstimationNewlyProps["onClose"], onSave?: EstimationNewlyProps["onSave"]) => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { reminder } = useReminder();
  const { t } = useTranslation()
  const [loading, setLoading] = useState(false);
  const {
    dataSource: cargoItems,
    change: handleCargoItemChange,
    commit: handleCargoItemCommit,
    crud: handleCargoItemCrud,
    check: handleCargoItemCheck,
    clear: handleCargoItemClear,
  } = useNewlyCargoAndPort();

  const {
    dataSource: vesselItems,
    change: handleVesselItemChange,
    commit: handleVesselItemCommit,
    crud: handleVesselItemCrud,
    check: handleVesselItemCheck,
    clear: handleVesselItemClear
  } = useNewlyVessel();

  const handleNewlyCrud: EstimationNewlyCrudEvent = (type, currentIndex) => {
    const [newlyType, crudType] = type?.split("/") as ["cargo" | "vessel", "add" | "delete" | "parse"];
    if (["add", "parse"]?.includes(crudType) && cargoItems?.length + vesselItems?.length >= 6) {
      reminder("info", "A total of up to six ships, ports or cargo can be added.");
      return
    }
    switch (true) {
      case newlyType === "cargo":
        handleCargoItemCrud(crudType, currentIndex);
        break;
      case newlyType === "vessel":
        handleVesselItemCrud(crudType, currentIndex);
        break;
      default:
        break
    }
  }

  const saveEstTemplateSuccess = (response) => {
    setLoading(false);
    reminder("success", "Template saved successfully");
  }

  const handleSaveTemplate: VesselParticularItemProps["onTemplateSave"] = (currentIndex, templateName) => {
    const {
      dwt,
      ballastSpeed,
      ladenSpeed,
      seaFuelBallast,
      seaFuelLaden,
      seaFuelMdo,
      tonnageType,
      tonnageValue,
      portFuelIdle,
      portFuelMdo,
      portFuelWork
    } = vesselItems?.[currentIndex];
    saveEstTemplateAction(
      {
        dwt,
        ballastSpeed,
        ladenSpeed,
        seaFuelBallast,
        seaFuelLaden,
        seaFuelMdo,
        tonnageType,
        tonnageValue,
        templateName,
        portFuelIdle,
        portFuelMdo,
        portFuelWork
      },
      addEstimationFront,
      saveEstTemplateSuccess,
      addEstimationError,
      dispatch,
      navigate
    )
  }

  const check = () => {
    let { flag: cargoFlag, flagKey: cargoKey } = handleCargoItemCheck();
    let { flag: vesselFlag, flagKey: vesselKey } = handleVesselItemCheck();
    return {
      flag: cargoFlag && vesselFlag,
      flagKey: cargoKey || vesselKey
    }
  }

  const clear = () => {
    handleCargoItemClear()
    handleVesselItemClear()
  }

  const handleClose = useCallback(() => {
    onClose?.();
    clear()
  }, [])

  const addEstimationFront = () => {
    setLoading(true)
  }

  const addEstimationSuccess = (response) => {
    onSave?.(response?.data?.ids?.join(",") ?? "");
    setLoading(false);
    clear()
  }

  const addEstimationError = (error) => {
    setLoading(false);
    reminder("error", error?.msg ? (error?.msg + ": " + error?.data) : error?.data);
  }

  const handleSave = () => {
    const { flag, flagKey } = check()
    if (!flag) {
      reminder("info", `Fields ${t(`estimation.${flagKey}`)} is empty`);
      return;
    }
    addEstimationAction(
      {
        status: "DFT",
        cargoPortFoList: cargoItems,
        vesselFoList: vesselItems
      },
      addEstimationFront,
      addEstimationSuccess,
      addEstimationError,
      dispatch,
      navigate
    )
  }


  return {
    loading,
    cargoItems,
    vesselItems,
    handleNewlyCrud,
    handleCargoItemChange,
    handleCargoItemCommit,
    handleVesselItemChange,
    handleVesselItemCommit,
    handleSaveTemplate,
    handleSave,
    handleClose
  }
}

export default useIndex