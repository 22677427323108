import React, { FC } from "react";
import style from "./index.module.less";
import { InvoiceAddToProps } from "./type";
import { FleetCustomRadio } from "@/components/Radio";

const InvoiceAddTo: FC<InvoiceAddToProps> = ({
  type,
  value,
  onChange,
  children
}) => {
  return <div className={style["invoices-addTo"]}>
    {children}
    <FleetCustomRadio
      className="addTo-radio"
      label="Add to invoice"
      value={value}
      onChange={(checked) => {
        onChange?.(type, checked)
      }}
    />
  </div>
}

export default InvoiceAddTo