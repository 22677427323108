import { RequestApiMapType } from "@/api";
import {
	PreopOtherExpensesApiType,
	PreopOtherExpensesParamsType,
	otherExpensesApiMap
} from "./otherExpenses";

import {
	otherIncomeApiMap,
	PreopOtherIncomeApiType,
	PreopOtherIncomeParamsType
} from "./otherIncome";

export {
	getVoyageGroupApi,
	getVoyageDetailApi,
	addVoyageApi,
	editVoyageApi,
	toMovementApi,
	getVoyageAttachApi,
	deleteVoyageAttactApi,
	uploadVoyageAttachApi,
	saveVoyageRemarkApi,
	queryCargoBookGroupApi,
	queryHeadFixtureGroupApi,
	getVoyageContactGroupApi,
	getVoyageContactDetailApi,
	deleteVoyageContactApi,
	addVoyageContactApi,
	queryVoyageContactSelectApi,
	deleteVoyageApi
} from "./api";

export {
	getVoyageDetailParams,
	getVoyageGroupParams,
	addVoyageParams,
	toMovementParams,
	getVoyageAttachParams,
	deleteVoyageAttactParams,
	uploadVoyageAttachParams,
	saveVoyageRemarkParams,
	queryCargoBookGroupParams,
	queryHeadFixtureGroupParams,
	getVoyageContactGroupParams,
	getVoyageContactParams,
	deleteVoyageContactParams,
	addVoyageContactParams,
	deleteVoyageParams
} from "./type";

export {
	getPreopTcvcHandoverFormApi,
	savePreopTcvcHandoverFormApi,
	getPreopTctcHandoverFormApi,
	savePreopTctcHandoverFormApi,
	getPreopTcvcHandoverFormParams,
	savePreopTcvcHandoverFormParams,
	getPreopTctcHandoverFormParams,
	savePreopTctcHandoverFormParams
} from "./handoverForm";

export {
	getVoyageAttachAndContactApi,
	saveVoyageContactApi,
	updatePreopRemarkApi,
	uploadVoyageAttachApi as uploadVmVoyageAttachApi,
	getVoyageAttachAndContactParams,
	saveVoyageContactParams,
	updatePreopRemarkParams,
	uploadVoyageAttachParams as uploadVmVoyageAttachParams
} from "./attachment&contacts";

export type PreopApiType = PreopOtherExpensesApiType | PreopOtherIncomeApiType;
export type PreopParamsType = PreopOtherExpensesParamsType | PreopOtherIncomeParamsType;

export const preopApiMap: RequestApiMapType<PreopApiType, PreopParamsType> = {
	...otherExpensesApiMap,
	...otherIncomeApiMap
};
