import { useAppDispatch } from "@/hook";
import { InvoicesVoyageListProps } from "../type";
import { useNavigate } from "react-router-dom";
import useReminder from "@/hook/useReminder";
import { useEffect, useMemo, useRef, useState } from "react";
import { InvoicesVoyageFilterProps, InvoicesVoyageItemProps } from "../components";
import { getInvoicesVoyageGroupAction } from "@/action/voyageManage/invoices";
import debounce from "lodash/debounce";

const useIndex = (
	activeVoyageItem: InvoicesVoyageListProps["activeVoyageItem"],
	onVoyageSelect: InvoicesVoyageListProps["onVoyageSelect"]
) => {
	const dispatch = useAppDispatch();
	const navigate = useNavigate();
	const { reminder } = useReminder();

	const [loading, setLoading] = useState(false);
	const [hasMore, setHasMore] = useState(true);

	const [dataSource, setDataSource] = useState<InvoicesVoyageItemProps["item"][]>([]);
	const record = useRef<InvoicesVoyageItemProps["item"]>(null);

	const [search, setSearch] = useState("");

	const [pager, setPager] = useState<{
		total: number;
		pageNum: number;
		pageSize: number;
	}>({ total: 0, pageNum: 1, pageSize: 20 });

	const [activeStatus, setActiveStatus] =
		useState<InvoicesVoyageFilterProps["activeStatus"]>("all");

	const handleStatusSelect: InvoicesVoyageFilterProps["onStatusSelect"] = (value) => {
		if (activeStatus === value) return;
		setActiveStatus(value);
	};

	const handleVoyageItemSelect: InvoicesVoyageItemProps["onSelect"] = (item) => {
		if (item?.id === activeVoyageItem?.id) return;
		record.current = item;
		onVoyageSelect?.({ id: item?.id, voyageType: item?.voyageType });
	};

	const getVoyageGroupFront = () => {
		setHasMore(true);
		setLoading(true);
	};

	const loadVoyageGroupSucess = (response) => {
		if ((response?.data?.items?.length ?? 0) + dataSource?.length >= pager.total) {
			setHasMore(false);
		} else {
			setHasMore(true);
		}
		setDataSource((prev) => {
			return [...prev, ...(response?.data?.items ?? [])];
		});
		setPager((prev) => ({ ...prev, pageNum: prev.pageNum + 1 }));
		setLoading(false);
	};

	const getVoyageGroupSuccess = (response) => {
		debugger;
		let total = 0;
		if (!Array.isArray(response?.data)) {
			setDataSource(response?.data?.items ?? []);
			total = response?.data?.totalCount;
			setHasMore(true);
			const currentVoyageItem = response?.data?.items?.find(
				(item) => item?.id === activeVoyageItem?.id
			);
			onVoyageSelect?.(currentVoyageItem || response?.data?.items?.[0]);
			// onInvoicesChange?.(response?.data?.items?.[0]?.id)
		} else {
			setHasMore(false);
			setDataSource([]);
			onVoyageSelect?.(null);
		}
		setPager((prev) => ({ ...prev, total, pageNum: 1 }));
		setLoading(false);
	};

	const getVoyageGroupError = (error) => {
		reminder("error", error?.msg ? error?.msg + ": " + error?.data : error?.data);
		setLoading(false);
	};

	const getVoyageGroup = (searchVal?: string) => {
		getInvoicesVoyageGroupAction(
			{
				pageNum: 1,
				pageSize: pager.pageSize,
				keyword: searchVal ?? search,
				state: activeStatus === "all" ? null : activeStatus
			},
			getVoyageGroupFront,
			getVoyageGroupSuccess,
			getVoyageGroupError,
			dispatch,
			navigate
		);
	};

	const loadVoyageGroup = () => {
		getInvoicesVoyageGroupAction(
			{
				pageNum: pager.pageNum + 1,
				pageSize: pager.pageSize,
				keyword: search,
				state: activeStatus === "all" ? null : activeStatus
			},
			getVoyageGroupFront,
			loadVoyageGroupSucess,
			getVoyageGroupError,
			dispatch,
			navigate
		);
	};

	const onSearch = useMemo(() => {
		const loadOptions = (val: string) => {
			setDataSource([]);
			setSearch(val);
			getVoyageGroup(val);
		};
		return debounce(loadOptions, 500);
	}, []);

	useEffect(() => {
		getVoyageGroup();
	}, [activeStatus]);

	return {
		loading,
		hasMore,
		record,
		pager,
		activeStatus,
		dataSource,
		onSearch,
		loadVoyageGroup,
		handleStatusSelect,
		handleVoyageItemSelect
	};
};

export default useIndex;
