import { useMemo, useRef, useState } from "react";
import { AISInfo, AISStateInfo, VesselDetailInfo, VesselInfo } from "../components";
import { useAppDispatch } from "@/hook";
import { useNavigate } from "react-router-dom";
import useReminder from "@/hook/useReminder";
import {
	addAisStarAction,
	getAisCurrentForImoAction,
	getMonitoringAisDataForImoAction,
	getMonitoringThreeAisDataAction
} from "@/action/monitoring/aisGroup";
import { MonitoringAisDetailsProps } from "../components/monitoringDetails/type";
import dayjs, { ManipulateType } from "dayjs";
import { MonitoringVoyageListProps, MonitoringVoyageTabType } from "../components";
import useArrival from "./useArrival";
import useAreaIndex from "../components/VoyageList/components/area/hooks/useAreaIndex";
import useMonitorDetailIndex from "../components/monitoringDetails/hooks/useIndex";
import { MonitoringDetailMoreTabType } from "../components/DetailDrawer/type";
import { MonitoringPortListParams } from "../components/VoyageList/components/port/type";

const useIndex = () => {
	const dispatch = useAppDispatch();
	const navigate = useNavigate();
	const { reminder } = useReminder();
	const [monitoringDetailTab, setMonitoringDetailTab] =
		useState<MonitoringDetailMoreTabType>("routePlan");
	const [aisDetailLoading, setAisDetailLoading] = useState(false);
	const [activeTab, setActiveTab] = useState<MonitoringVoyageTabType>("vm");
	const {
		dataSource: areaItems,
		handleSelect: handleAreaSelect,
		handleReset: handleAreaReset,
		handleDelete: handleAreaDelete
	} = useAreaIndex();
	const [portVesselParams, setPortVesselParams] = useState<MonitoringPortListParams>({
		dateRangeHistory: { startDate: "", endDate: "" },
		dateRangeExpert: { startDate: "", endDate: "" },
		termType: 2,
		currentPort: {
			portCode: "",
			portName: ""
		}
	});
	const handleTabSelect = (type: MonitoringVoyageTabType) => {
		if (activeTab === type) return;
		setActiveTab(type);
		if (["port"].includes(type)) setMonitoringDetailTab("portAreaDetails");
	};

	const {
		activeDate: currentVoyageTime,
		setActiveDate: setCurrentVoyageTime,
		activeItem: currentVessel,
		setActiveItem: setCurrentVessel,
		activeDetailItem: vesselDetailSource,
		setActiveDetailItem: setVesselDetailSource
	} = useMonitorDetailIndex<VesselInfo, VesselDetailInfo>();

	const {
		activeDate: currentAisTime,
		setActiveDate: setCurrentAisTime,
		activeItem: currentAis,
		setActiveItem: setCurrentAis,
		activeDetailItem: aisDetailSource,
		setActiveDetailItem: setAisDetailSource
	} = useMonitorDetailIndex<AISInfo, AISStateInfo>();

	const {
		activeDate: currentAreaTime,
		setActiveDate: setCurrentAreaTime,
		activeItem: currentArea,
		setActiveItem: setCurrentArea,
		activeDetailItem: areaDetailSource,
		setActiveDetailItem: setAreaDetailSource,
		loading: areaDetailLoading,
		setLoading: setAreaDetailLoading
	} = useMonitorDetailIndex();

	const {
		activeDate: currentPortTime,
		setActiveDate: setCurrentPortTime,
		activeItem: currentPort,
		setActiveItem: setCurrentPort,
		activeDetailItem: portDetailSource,
		setActiveDetailItem: setPortDetailSource,
		loading: portDetailLoading,
		setLoading: setPortDetailLoading
	} = useMonitorDetailIndex();

	const actionRef = useRef(undefined);
	const abortController = useRef<AbortController>(null);
	const voyageListActionRef = useRef(undefined);

	const { arrivalHide, aisChartHide, dataSource, handleOpen, handlClose } = useArrival();

	const currentRecord = useMemo(() => {
		return {
			currentTime: !dataSource.aisType
				? null
				: dataSource?.aisType === "ais"
					? currentAisTime
					: dataSource?.aisType === "area"
						? currentAreaTime
						: dataSource?.aisType === "port"
							? currentPortTime
							: currentVoyageTime,
			aisData: !dataSource.aisType
				? null
				: dataSource?.aisType === "ais"
					? aisDetailSource
					: dataSource?.aisType === "area"
						? areaDetailSource
						: dataSource?.aisType === "port"
							? portDetailSource
							: vesselDetailSource,
			arrivalData: !dataSource.arrivalType
				? null
				: dataSource?.arrivalType === "ais"
					? aisDetailSource
					: dataSource?.arrivalType === "area"
						? areaDetailSource
						: dataSource?.arrivalType === "port"
							? portDetailSource
							: vesselDetailSource
		};
	}, [
		currentAis,
		currentVessel,
		currentArea,
		currentPort,
		currentAisTime,
		currentVoyageTime,
		currentAreaTime,
		currentPortTime,
		dataSource
	]);

	const getAisCurrentStateFront = () => {
		setAisDetailLoading(true);
	};

	const getAisCurrentStateFailed = (error) => {
		setAisDetailLoading(false);
		reminder("error", error?.msg ? error?.msg + ": " + error?.data : error?.data);
	};

	const addAisStarSuccess = (response) => {
		setAisDetailLoading(false);
		setAisDetailSource((prev) => ({ ...prev, isCollect: prev?.isCollect ? 0 : 1 }));
		voyageListActionRef?.current?.aisListRef?.current?.onAisGroupReset?.();
	};
	const getMonitoringAisData = (
		type: "ais" | "area" | "port",
		item: { mmsi: number; imo: number },
		selectTime: MonitoringAisDetailsProps["activeTime"],
		isStar: boolean = true
	) => {
		debugger;
		const [count, rule] = selectTime?.split("/") as [number, ManipulateType];
		if (selectTime === "3/day") {
			getMonitoringThreeAisDataAction(
				{ imo: item?.imo },
				null,
				(response) => {
					const { data } = response;
					if (!data?.current && !data?.route) return;
					const params = {
						...data?.route,
						properties: data?.current
					};
					switch (true) {
						case type === "ais" && isStar:
							actionRef.current?.loadVesselTrack("ais", params, false);
							setAisDetailLoading(false);
							break;
						case type === "ais" && !isStar:
							actionRef.current?.loadVesselTrack("notFollow", params);
							setAisDetailLoading(false);
							break;
						case type === "area":
							actionRef.current?.loadVesselTrack("area", params, false);
							setAreaDetailLoading(false);
							break;
						case type === "port":
							actionRef.current?.loadVesselTrack("port", params, false);
							setPortDetailLoading(false);
							break;
						default:
							break;
					}
				},
				getAisCurrentStateFailed,
				dispatch,
				navigate
			);
			return;
		}

		getMonitoringAisDataForImoAction(
			{
				imo: item?.imo,
				endDate: dayjs()?.format("YYYY-MM-DD HH:mm:ss"),
				startDate: dayjs()?.subtract(count, rule)?.format("YYYY-MM-DD HH:mm:ss")
			},
			null,
			(response) => {
				const { data } = response;
				if (!data?.current && !data?.route) return;
				const params = {
					...data?.route,
					properties: data?.current
				};
				switch (true) {
					case type === "ais" && isStar:
						actionRef.current?.loadVesselTrack("ais", params, false);
						setAisDetailLoading(false);
						break;
					case type === "ais" && !isStar:
						actionRef.current?.loadVesselTrack("notFollow", params);
						setAisDetailLoading(false);
						break;
					case type === "area":
						actionRef.current?.loadVesselTrack("area", params, false);
						setAreaDetailLoading(false);
						break;
					case type === "port":
						actionRef.current?.loadVesselTrack("port", params, false);
						setPortDetailLoading(false);
						break;
					default:
						break;
				}
			},
			getAisCurrentStateFailed,
			dispatch,
			navigate
		);
	};

	const getAisCurrentStateForImo = (
		type: "area" | "port" | "ais",
		imo: number,
		selectTime: MonitoringAisDetailsProps["activeTime"],
		isStar: boolean = true
	) => {
		getAisCurrentForImoAction(
			{ imo: imo },
			getAisCurrentStateFront,
			(response) => {
				const { data } = response;
				switch (true) {
					case !!data && type === "area":
						setAreaDetailSource(data);
						break;
					case !!data && type === "port":
						setPortDetailSource(data);
						break;
					case !!data && type === "ais":
						setAisDetailSource(data);
						break;
					default:
						break;
				}
				if (data?.imo ?? imo) {
					getMonitoringAisData(type, data, selectTime, isStar);
				} else {
					// setAisDetailLoading(false);
					switch (true) {
						case type === "area":
							setAreaDetailLoading(false);
							break;
						case type === "port":
							setPortDetailLoading(false);
							break;
						case type === "ais":
							setAisDetailLoading(false);
							break;
					}
				}
			},
			getAisCurrentStateFailed,
			dispatch,
			navigate
		);
	};

	const handleAisStarItemSelect: MonitoringVoyageListProps["onAisStarItemSelect"] = (item) => {
		switch (true) {
			case !item:
				setAisDetailSource(null);
				setCurrentAis(null);
				handlClose("aisData");
				break;
			case item?.imo === currentAis?.imo:
				setAisDetailSource(null);
				setCurrentAis(null);
				handlClose("aisData");

				actionRef?.current?.clearVesselAndTrackLayers("ais");
				return;
			case item?.imo !== currentAis?.imo:
				setCurrentAis(item);
				getAisCurrentStateForImo("ais", item?.imo, currentAisTime);
				break;
			default:
				break;
		}
	};

	const handleAisItemSelect: MonitoringVoyageListProps["onAisItemSelect"] = (
		type,
		item,
		isStar
	) => {
		// isstar 是否存在于关注列表
		// 1. 从ais列表选择: aislist，如果选中的 vessel 在关注列表中，那么直接给 map 上的 vessel 设为选中状态以及添加 track points 即可
		//          如果选中的 vessel 不在关注列表中，那么需要将此条船展示出来并且添加 track points
		// 2.
		const currentItem = type === "ais" ? currentAis : type === "area" ? currentArea : currentPort;
		const currentTime =
			type === "ais" ? currentAisTime : type === "area" ? currentAreaTime : currentPortTime;
		switch (true) {
			case type === "ais" && item && !isStar:
				actionRef?.current?.clearVesselAndTrackLayers(type);
				getAisCurrentStateForImo(type, item?.imo, currentTime, false);
				break;
			case !item:
				if (type === "ais") {
					setAisDetailLoading(false);
					setCurrentAis(null);
				} else if (type === "area") {
					setAreaDetailLoading(false);
					setCurrentArea(null);
				} else if (type === "port") {
					setPortDetailLoading(false);
					setCurrentPort(null);
				}
				// setAisDetailSource(null);
				// setCurrentAis(null);
				handlClose("aisData");
				return;

			case currentItem?.imo !== item?.imo:
				// setCurrentAis(item);
				setCurrentAis(item);
				if (type === "ais") {
					setCurrentAis(item);
				} else if (type === "area") {
					setCurrentArea(item);
				} else if (type === "port") {
					setCurrentPort(item);
				}
				getAisCurrentStateForImo(type, item?.imo, currentTime);
				actionRef?.current?.clearVesselAndTrackLayers(type);
				return;
			case currentItem?.imo === item?.imo:
				// setAisDetailSource(null)
				// setCurrentAis(null)
				// actionRef?.current?.clearVesselAndTrackLayers("ais")
				return;
			default:
				break;
		}
	};

	const handleAisDetailSelect = (
		type: "ais" | "vm" | "area" | "port",
		item: AISStateInfo & VesselDetailInfo,
		selectTime: MonitoringAisDetailsProps["activeTime"]
	) => {
		let isStar = false;
		switch (true) {
			case type === "ais":
				isStar = voyageListActionRef?.current?.aisListRef?.current?.onGetIsFollow?.();
				setCurrentAisTime(selectTime);
				getAisCurrentStateForImo(type, item?.imo, selectTime, isStar);
				break;
			case type === "area":
				setCurrentAreaTime(selectTime);
				getAisCurrentStateForImo(type, item?.imo, selectTime, isStar);
				break;
			case type === "port":
				setCurrentPortTime(selectTime);
				getAisCurrentStateForImo(type, item?.imo, selectTime, isStar);
				break;
			default:
				break;
		}
		// setCurrentRecord(pregv => ({ ...pregv, selectTime }))
		// handlClose('ais')
	};

	const handleDetailClose: MonitoringAisDetailsProps["onAisCancel"] = (type) => {
		switch (type) {
			case "notFollow":
				setAisDetailSource(null);
				handlClose("aisData");
				actionRef?.current?.clearVesselAndTrackLayers("notFollow");
				break;
			case "ais":
				setAisDetailSource(null);
				setCurrentAis(null);
				handlClose("aisData");
				actionRef?.current?.clearVesselAndTrackLayers("notFollow");
				actionRef?.current?.clearVesselAndTrackLayers("ais");
				break;
			case "port":
				setPortDetailLoading(null);
				setCurrentPort(null);
				setPortDetailSource(null);
				handlClose("aisData");
				actionRef?.current?.clearVesselAndTrackLayers("port");
				break;
			case "area":
				setAreaDetailSource(null);
				setCurrentArea(null);
				handlClose("aisData");
				actionRef?.current?.clearVesselAndTrackLayers("area");
				break;
			case "vm":
				abortController?.current?.abort();
				actionRef.current?.clearRoutePlan();
				actionRef.current?.clearDailyReport();
				setCurrentVessel(null);
				setVesselDetailSource(null);
				handlClose("aisData");
				actionRef?.current?.clearVesselAndTrackLayers("vm");
				break;
			default:
				break;
		}
	};

	const handleAddAisStar: MonitoringAisDetailsProps["onAisStarAdd"] = (item) => {
		addAisStarAction(
			{ imoList: [item?.imo], type: item?.isCollect ? 0 : 1 },
			getAisCurrentStateFront,
			addAisStarSuccess,
			getAisCurrentStateFailed,
			dispatch,
			navigate
		);
	};
	const handleChangePortVesselParams = (val: MonitoringPortListParams) => {
		if (!val.currentPort.portCode) {
			actionRef.current?.refreshPortVessel([]);
		}
		setPortVesselParams(val);
	};
	return {
		actionRef,
		aisDetailSource,
		currentAisTime,
		aisDetailLoading,
		abortController,
		currentVessel,
		vesselDetailSource,
		voyageListActionRef,
		currentAis,
		currentVoyageTime,
		arrivalHide,
		aisChartHide,
		currentRecord,
		getMonitoringAisData,
		setCurrentVoyageTime,
		setCurrentAis,
		setVesselDetailSource,
		setCurrentVessel,
		handleAisItemSelect,
		handleDetailClose,
		handleAddAisStar,
		handleAisStarItemSelect,
		handleAisDetailSelect,
		handlClose,
		handleOpen,
		activeTab,
		handleTabSelect,
		areaItems,
		handleAreaSelect,
		handleAreaDelete,
		handleAreaReset,
		areaDetailLoading,
		areaDetailSource,
		currentArea,
		currentAreaTime,
		portDetailLoading,
		portDetailSource,
		currentPort,
		currentPortTime,
		setCurrentArea,
		setCurrentPort,
		monitoringDetailTab,
		setMonitoringDetailTab,
		portVesselParams,
		handleChangePortVesselParams
	};
};

export default useIndex;
