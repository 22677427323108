import React from "react";
import style from "./index.module.less";
import FleetPrompt, { PromptChildrenProps } from "@/common/Prompt";
import { FleetButton, FleetIcon, FleetLoading } from "@/components";
import { Button } from "@/_components";

export type EditContractProps = {
  children: React.ReactNode;
  label: string;
  loading:boolean;
  onSave?:() => void
} & PromptChildrenProps;

const EditContract: React.FC<EditContractProps> = ({
  hide,
  onClose,
  onSave,
  loading,
  label,
  children
}) => {
  return <FleetPrompt
    hide={hide}
    drawer
  >
    <div className={style["contract-edit"]}>
      <FleetLoading loading={loading} />
      <div className="edit-header">
        <FleetIcon
          type="FleetCloseIcon"
          outerLayerClassname="edit-close"
          onClick={onClose}
        />
        <div className="edit-header-container ">
          <div className="edit-header-info">
            <div className="edit-info-top">
              <span>Vessel Name</span>
              <span>/</span>
              <span>{label}</span>
            </div>
            <div className="edit-info-bot">
              <span>Voyage No.</span>
              <span>12323423</span>
            </div>
          </div>
          <div className="edit-header-action">
            <span className="edit-header-time">
              Last Update:
              07/14/20 07:31
            </span>
            <Button
              type="secondary"
              size="large"
              onClick={onSave}
              prefixIcon="fleet-save"
            >Save</Button>
          </div>
        </div>
      </div>
      <div className="edit-container fleet-nonScrolBar">
        <div className="edit-scrollArea">
          {children}
        </div>
      </div>
    </div>
  </FleetPrompt>
}

export default EditContract