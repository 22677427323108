import { useEffect, useState } from "react";
import { CommonNewlyCompanyItemType } from "../type";
import { checkKeys, initialCompanyItem } from "../source";
import { useAppDispatch, useCommonIndex } from "@/hook";
import { useNavigate } from "react-router-dom";
import useReminder from "@/hook/useReminder";
import { getCompanyDetailsAction, updateCompanyAction } from "@/action/dataBase/company";
import { useTranslation } from "react-i18next";
const useIndex = (hide: boolean, onReset: () => void, type: "add" | "edit", id?: number) => {
	const dispatch = useAppDispatch();
	const navigate = useNavigate();
	const { reminder } = useReminder();
	const { t } = useTranslation();
	const [loading, setLoading] = useState(false);

	const { dataSource, change, init, check } = useCommonIndex<CommonNewlyCompanyItemType>(initialCompanyItem, checkKeys);

	const getCompanyDetailsFront = () => {
		setLoading(true);
	};

	const getCompanyDetailsSuccess = (response) => {
		init(response.data);
		setLoading(false);
	};

	const updateCompanySuccess = (response) => {
		reminder("success", response?.msg);
		setLoading(false);
		onReset?.();
	};

	const getCompanyDetailsFailed = (error) => {
		setLoading(false);
		reminder("error", error?.msg ? error?.msg + ": " + error?.data : error?.data);
	};

	const getCompanyDetails = () => {
		getCompanyDetailsAction(
			{ id: id },
			getCompanyDetailsFront,
			getCompanyDetailsSuccess,
			getCompanyDetailsFailed,
			dispatch,
			navigate
		);
	};

	const updateCompany = () => {
		const { checkKey, checked } = check();
		if (!checked) {
			reminder("error", `Field ${t(`dataBase.company.${checkKey}`)} is required`);
			return;
		}
		updateCompanyAction(
			{ params: dataSource, method: type === "edit" ? "put" : "post" },
			getCompanyDetailsFront,
			updateCompanySuccess,
			getCompanyDetailsFailed,
			dispatch,
			navigate
		);
	};

	useEffect(() => {
		if (hide) {
			init(null);
			return;
		}
		if (type === "add" || !id) return;
		getCompanyDetails();
	}, [hide]);

	return {
		loading,
		dataSource,
		change,
		updateCompany
	};
};

export default useIndex;
