import { uuid } from "@/tools";
import { ExtendsKeys, ExcludeKeys } from "@/types";
import { FleetNumType, FleetOperatorType } from "@/types/common";
import { Dayjs } from "dayjs";

export type MovementLaytimeCalculatorType = {
  id?: number
  movePrId?: number
  workFrom: FleetNumType
  workTo: FleetNumType
  calcRate: FleetNumType
  timeUsedD: FleetNumType
  timeUsedH: FleetNumType
  timeUsedM: FleetNumType
  status: 0 | 1
  isSelect: 0 | 1
  workDate: string
  remark: string
  week: string
} & Partial<FleetOperatorType>

export type MovementExceptFormType = {
  date: Dayjs | undefined;
  startTime: number;
  endTime: number;
  uuid?: string;
  status?: 0 | 1;
  isSelect: 0 | 1
}

export type MovementLaytimeTreeNode = {
  startTime: number | undefined;
  endTime: number | undefined;
  next: MovementLaytimeTreeNode | null;
  uuid?: string;
  status?: 0 | 1;
}

export type MovementExceptFormChangeEvent = <K extends ExtendsKeys<keyof MovementExceptFormType, "date" | "isSelect">>(index: number, key: K, value: MovementExceptFormType[K]) => void;

export type MovementExceptFormComiitEvent = (index: number, key: "date" | "time", item: Partial<MovementExceptFormType>, originItem?: MovementExceptFormType) => void;

export type MovementExceptRangeComiitEvent = (index: number, item: Pick<MovementExceptFormType, "endTime" | "startTime">, originItem?: MovementExceptFormType) => void;

export type MovementExceptFormeCrudEvent = (type: "add" | "delete", index: number) => void;

export type MovementCalculatorRangeDateChangeEvent = (id: string | number, vals: [Dayjs | undefined, Dayjs | undefined]) => void;

export type MovementCalculatorFormChangeEvent = <K extends ExtendsKeys<keyof MovementLaytimeCalculatorType, "remark" | "calcRate">>(index: number, key: K, value: MovementLaytimeCalculatorType[K]) => void;

export type MovementCalculatorProps = {
  onRangeDateChange: MovementCalculatorRangeDateChangeEvent;
  rangeDate: [Dayjs | undefined, Dayjs | undefined];
  norDate: [Dayjs | undefined, Dayjs | undefined];
  onNorDateChange: (vals: [Dayjs | undefined, Dayjs | undefined]) => void;
  items: MovementLaytimeCalculatorType[];
  activeIndex: string | number;
  exceptDate: MovementExceptFormType[];
  summary: {
    allowed: string;
    used: string;
    amount: number;
  };
  onExpectDateCommit: MovementExceptRangeComiitEvent;
  onExpectDateCrud: MovementExceptFormeCrudEvent;
  onExpectDateChange: MovementExceptFormChangeEvent;
  onChange: MovementCalculatorFormChangeEvent;
}

export const initialExceptForm = (): MovementExceptFormType => {
  return {
    date: undefined,
    startTime: 0,
    endTime: 0,
    status: 1,
    uuid: uuid(),
    isSelect: 0
  }
}

// 时间段数据链表节点结构
export function LaytimeTreeNode(
  startTime,
  endTime,
  status: 0 | 1,
  uuid?: string,
  next?: MovementLaytimeTreeNode | null
) {
  this.startTime = startTime;
  this.endTime = endTime;
  this.next = (!next ? null : next);
  this.uuid = uuid
  this.status = status;
}