import React, { useEffect, useMemo } from "react";
import style from "./index.module.less";
import {
	DatabaseTaskEditProps,
	DatabaseTaskStageKeys,
	tctcTaskStageOptions,
	tcvcTaskStageOptions
} from "./type";
import { FleetFormItem, FleetSelect, FleetTextarea, FleetVerifyInput } from "@/components";
import DatabaseCommonAdd from "../../layout/common/add";
import useEditTask from "@/hook/database/task/useEditTask";
import { VoyageType } from "@/types/common";
import { voyageTypeOptions } from "@/types/option";

const DatabaseTaskEdit: React.FC<DatabaseTaskEditProps> = ({
	hide,
	onClose,
	onReset,
	type,
	taskId
}) => {
	const { dataSource, loading, handleEditTask, getTask, clear, change, commit } = useEditTask(
		type,
		taskId,
		onReset
	);

	const options = useMemo(() => {
		if (!dataSource?.voyageType) return [];
		if (dataSource?.voyageType === "TCVC") return tcvcTaskStageOptions;
		return tctcTaskStageOptions;
	}, [dataSource?.voyageType]);

	useEffect(() => {
		if (hide) {
			clear();
			return;
		}
		getTask();
	}, [hide]);

	return (
		<DatabaseCommonAdd
			hide={hide}
			onClose={onClose}
			title={`${type === "add" ? "Add" : "Edit"} Task`}
			loading={loading}
			onAdd={handleEditTask}
			className={style["database-taskEdit"]}
			sectionName="task"
		>
			<div className="commonAddItem">
				<div className="commonAddItem-container">
					<FleetVerifyInput
						label="Task Name"
						needVerity
						value={dataSource?.title}
						onChange={(value: string) => {
							change("title", value);
						}}
					/>
					<FleetVerifyInput
						label="Sort Num"
						needVerity
						type="number"
						min={0}
						value={dataSource?.sortNum}
						onChange={(value: number) => {
							change("sortNum", value);
						}}
					/>
				</div>
				<div className="commonAddItem-container">
					<FleetFormItem label="Status" needVerity>
						<FleetSelect
							options={options}
							disabled={!dataSource?.voyageType}
							value={[dataSource?.stage]}
							onChange={(value: DatabaseTaskStageKeys) => {
								change("stage", value);
							}}
						/>
					</FleetFormItem>
					<FleetFormItem label="Voyage Type" needVerity>
						<FleetSelect
							value={dataSource?.voyageType}
							onChange={(value: VoyageType) => {
								if (dataSource?.voyageType === value) return;
								commit({
									voyageType: value,
									stage: null
								});
							}}
							options={voyageTypeOptions}
						/>
					</FleetFormItem>
				</div>
				<FleetTextarea
					value={dataSource?.contents}
					onChange={(val: string) => {
						change("contents", val);
					}}
					label="Content"
					placeholder="Please input the Content"
					className="taskEdit-textarea"
				/>
			</div>
		</DatabaseCommonAdd>
	);
};

export default DatabaseTaskEdit;
