import useCommonIndex from "../../../hooks/useIndex"
import { CommonCompanyItemType } from "../type"
import { getCompanyGroupAction } from "@/action/dataBase/company";
const useIndex = (
  activeItems: Partial<CommonCompanyItemType> | (Partial<CommonCompanyItemType>)[],
  showKey: keyof CommonCompanyItemType,
  onFinish: (newItem: CommonCompanyItemType, operateType: "add" | "del") => void,
  primaryKey: keyof CommonCompanyItemType = "id",
  disabled: boolean,
  params?: { businessType: string }
) => {
  const {
    loading,
    open,
    list,
    searchValue,
    selectValue,
    page,
    handleSearch,
    focusPopup,
    closePopup,
    loadMore,
    handleItemSelect,
    tagRender
  } = useCommonIndex<CommonCompanyItemType, keyof CommonCompanyItemType>(
    activeItems,
    showKey,
    onFinish,
    getCompanyGroupAction,
    primaryKey,
    disabled,
    params
  );

  const handleClear = () => {
    if(!activeItems[primaryKey]) return
    onFinish?.(null, "del")
  }

  return {
    loading,
    open,
    list,
    searchValue,
    selectValue,
    page,
    handleSearch,
    focusPopup,
    closePopup,
    loadMore,
    handleItemSelect,
    tagRender,
    handleClear
  }
}

export default useIndex