import React, { forwardRef, useImperativeHandle } from "react";
import { FleetLoading, FleetSelect } from "@/components";
import classnames from 'classnames'
import style from './index.module.less'
import useIndex from "./hooks/useIndex";
import { MonitorAisGroupProps, MonitorAisGroupRefType } from "./type"
import AisItem from "../aisItem";

const AISList = forwardRef<MonitorAisGroupRefType, MonitorAisGroupProps>(({
  activeTab,
  onItemClick,
  onVesselLoad,
  onStarItemSelect
}, ref) => {
  const {
    open,
    currentAis,
    selectValue,
    dataSource,
    selectList,
    loading,
    selectLoading,
    handleItemClick,
    handleClosePopup,
    getAisGroup,
    handleSearch,
    handleAisStarCancel,
    handleReset,
    onGetIsFollow
  } = useIndex(activeTab, onItemClick, onVesselLoad)

  useImperativeHandle(ref, () => ({
    onAisGroupReset: handleReset,
    onGetIsFollow: onGetIsFollow
  })
  )
  return <div className={classnames(style["monitoring-aisList"], {
    [style["active"]]: activeTab === "ais"
  })}>
    <FleetLoading loading={loading} />
    <FleetSelect
      value={selectValue}
      showSearch
      popupMatchSelectWidth={false}
      className="aisList-search"
      onDropdownVisibleChange={handleClosePopup}
      open={open}
      onSearch={handleSearch}
      onFocus={() => getAisGroup()}
      dropdownRender={() => <div className={style["aisList-vesselContainer"]}>
        <FleetLoading loading={selectLoading} />
        {
          selectList?.map((item, index) => {
            return <span
              className="vessel-item"
              key={index}
              onClick={() => handleItemClick(item)}
            >{item?.vesselName ?? item?.vesselNameEn}({item?.imo})</span>
          })
        }
      </div>}
    />
    <span className="aisList-line"></span>
    <div className="aisList-container">
      <div className="aisList-scrollArea">
        {
          dataSource?.map((item, index) => {
            return <AisItem
              key={index}
              currentItem={currentAis}
              item={item}
              onItemClick={onStarItemSelect}
              onAisStarCancel={handleAisStarCancel}
            />
          })
        }
        {dataSource?.length === 0 && <span className="aisList-empty">Nothing more</span>}
      </div>
    </div>
  </div >
})

export default AISList

