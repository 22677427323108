import React, { FC } from "react";
import classnames from "classnames";
import style from "./index.module.less";
import { termOptions } from "./source";
import { PortListTabProps, PortListTabType } from "./type";

const PortListTab: FC<PortListTabProps> = ({ activeName, onChange }) => {
	return (
		<div className={classnames(style["port-list-tab"])}>
			<ul className="portListTab-list">
				{[3, 2, 1].map((item) => {
					return (
						<li
							className={classnames("portListTab-item", item === activeName ? "active" : "")}
							onClick={() => onChange?.(item as 1 | 2 | 3)}
						>
							{termOptions[item]}
						</li>
					);
				})}
			</ul>
		</div>
	);
};

export default PortListTab;
