import React, { FC, useState } from "react";
import style from "./index.module.less";
import { EstVesselParticularProps } from "./type";
import { useTranslation } from "react-i18next";
import { CommonPortSelect, CommonVesselSelect } from "@/common";
import { FleetFormItem, FleetVerifyInput } from "@/components";
import classnames from "classnames";
import { CommonOtherExpenses } from "@/common";
import { Button } from "@/_components";
import { EstTemplate } from "@/pages/estimation/components";

const EstVesselParticular: FC<EstVesselParticularProps> = ({
	item,
	deliveryDisabled,
	onChange,
	onCommit,
	onTemplateSave,
	onSpdAllocationOpen,
	onDeliveryPortSelect,
	onPortBatchCommit,
	onPortConsumCommit
}) => {
	const { t } = useTranslation();
	const [hide, setHide] = useState(true);
	return (
		<div className={style["estDetails-vesselParticular"]}>
			<div className="vesselParticular-header">
				<span className="details-title">Vessel Particular</span>
				<EstTemplate
					className="vesselParticular-template"
					onCommit={onCommit}
					onTemplateSave={onTemplateSave}
					activeItem={{
						id: item?.templateId,
						templateName: item?.templateName
					}}
				/>
			</div>
			<div className="vesselParticular-container">
				<div className="vesselParticular-item bisector">
					<FleetFormItem label={t("common.vesselName")}>
						<CommonVesselSelect
							showKey="vesselName"
							primaryKey="mmsi"
							activeItems={{
								vesselName: item?.vesselName,
								mmsi: item?.mmsi
							}}
							onFinish={(newItem, type) => {
								onCommit?.({
									bale: newItem?.baleCapacity ?? null,
									grain: newItem?.grainCapacity ?? null,
									dwt: newItem?.dwt ?? null,
									imo: newItem?.imo ?? null,
									mmsi: newItem?.mmsi ?? null,
									vesselName: newItem?.vesselName ?? ""
								});
							}}
						/>
					</FleetFormItem>
					<FleetFormItem needVerity={true} label={t("common.deliveryPortName")}>
						<CommonPortSelect
							activeItems={{
								id: item?.deliveryPortId,
								portCode: item?.deliveryPortCode,
								portName: item?.deliveryPortName
							}}
							disabled={deliveryDisabled}
							showKey="portName"
							primaryKey="portCode"
							onFinish={(newItem, type) => {
								console.log("newItem", newItem);
								onDeliveryPortSelect?.({
									id: newItem?.id,
									portCode: newItem?.portCode,
									portName: newItem?.portName
								});
							}}
						/>
					</FleetFormItem>
				</div>
				<div className="vesselParticular-item untrisection">
					<FleetVerifyInput
						type="number"
						value={item.ballastSpeed}
						onChange={(val: number) => {
							onChange?.("ballastSpeed", val);
							onPortBatchCommit?.("ballast", { ballastSpeed: val, ladenSpeed: item?.ladenSpeed });
						}}
						placeholder="Ballast"
						label={t("common.ballastSpeed")}
					/>
					<FleetVerifyInput
						type="number"
						value={item.ladenSpeed}
						onChange={(val: number) => {
							onChange?.("ladenSpeed", val);
							onPortBatchCommit?.("laden", { ladenSpeed: val, ballastSpeed: item?.ballastSpeed });
						}}
						placeholder="Laden"
						label={t("common.ladenSpeed")}
					/>
					<Button onClick={onSpdAllocationOpen} size="small" type="primary">
						SPD.Allocation
					</Button>
				</div>
				<div className="vesselParticular-item">
					<FleetVerifyInput
						type="number"
						needVerity
						value={item.seaFuelBallast}
						placeholder="Ballast"
						onChange={(val: number) => {
							onChange?.("seaFuelBallast", val);
						}}
						label={t("common.seaFuelBallast")}
					/>
					<FleetVerifyInput
						type="number"
						needVerity
						value={item.seaFuelLaden}
						placeholder="Laden"
						onChange={(val: number) => {
							onChange?.("seaFuelLaden", val);
						}}
						label={t("common.seaFuelLaden")}
					/>
					<FleetVerifyInput
						type="number"
						needVerity
						value={item.seaFuelMdo}
						placeholder="MDO"
						onChange={(val: number) => {
							onChange?.("seaFuelMdo", val);
						}}
						label={t("common.seaFuelMdo")}
					/>
				</div>
				<div className="vesselParticular-item bisector">
					<FleetVerifyInput
						type="number"
						value={item.portFuelWork}
						onChange={(val: number) => {
							onChange?.("portFuelWork", val);
							onPortConsumCommit?.({ ...item, portFuelWork: val });
						}}
						needVerity
						placeholder="Work"
						label={t("estimation.portFuelWork")}
					/>
					<FleetVerifyInput
						value={item.portFuelIdle}
						needVerity
						placeholder="Idle"
						onChange={(val: number) => {
							onChange?.("portFuelIdle", val);
							onPortConsumCommit?.({ ...item, portFuelIdle: val });
						}}
						type="number"
						label={t("estimation.portFuelIdle")}
					/>
				</div>
				<div className="vesselParticular-item bisector">
					<FleetVerifyInput
						needVerity
						value={item.portWorkMdo}
						placeholder="MDO"
						type="number"
						onChange={(val: number) => {
							onChange?.("portWorkMdo", val);
							onPortConsumCommit?.({ ...item, portWorkMdo: val });
						}}
						label={t("estimation.portWorkMdo")}
					/>
					<FleetVerifyInput
						needVerity
						value={item.portIdleMdo}
						placeholder="MDO"
						type="number"
						onChange={(val: number) => {
							onChange?.("portIdleMdo", val);
							onPortConsumCommit?.({ ...item, portIdleMdo: val });
						}}
						label={t("estimation.portFuelMdo")}
					/>
				</div>
				<div className="vesselParticular-item">
					<FleetVerifyInput
						type="number"
						needVerity
						label={t("common.dailyHire")}
						value={item?.dailyHire}
						onChange={(val: number) => {
							onChange?.("dailyHire", val);
						}}
						placeholder="example"
					/>
					<FleetVerifyInput
						type="number"
						label={t("common.commission")}
						value={item?.commission}
						placeholder="example"
						onChange={(val: number) => {
							onChange?.("commission", val);
						}}
					/>
					<FleetVerifyInput
						label={t("common.brokerage")}
						type="number"
						placeholder="example"
						value={item?.brokerage}
						onChange={(val: number) => {
							onChange?.("brokerage", val);
						}}
					/>
				</div>
				<div className="vesselParticular-item">
					<FleetVerifyInput
						placeholder="example"
						label={t("common.bunkerPriceIfo")}
						type="number"
						needVerity
						value={item?.bunkerPriceIfo}
						onChange={(val: number) => {
							onChange?.("bunkerPriceIfo", val);
						}}
					/>
					<FleetVerifyInput
						label={t("common.bunkerPriceMdo")}
						value={item?.bunkerPriceMdo}
						needVerity
						placeholder="example"
						type="number"
						onChange={(val: number) => {
							onChange?.("bunkerPriceMdo", val);
						}}
					/>
					<FleetVerifyInput
						label={t("common.otherExpenses")}
						type="number"
						value={item?.otherExpenses}
						placeholder="example"
						onChange={(val: number) => {
							onChange?.("otherExpenses", val);
						}}
						renderSuffix={() => {
							return (
								<span
									className={classnames("font_family fleet-deploy", {
										"fleet-disabled": !item?.resultId
									})}
									onClick={(e) => {
										e?.stopPropagation();
										if (!item?.resultId) return;
										setHide(false);
									}}
								></span>
							);
						}}
					/>
				</div>
			</div>
			<CommonOtherExpenses
				hide={hide}
				onClose={() => setHide(true)}
				primaryId={item?.resultId}
				type="est"
				onConfirm={(items) => {
					const otherExpenses = items?.reduce((prev, curr) => {
						return prev + +curr?.netExpense;
					}, 0);
					onChange?.("otherExpenses", otherExpenses);
				}}
			/>
		</div>
	);
};

export default EstVesselParticular;
