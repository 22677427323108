import React from "react";
import style from "./index.module.less";
import FleetPrompt, { PromptChildrenProps } from "../Prompt";
import { FleetButton, FleetIcon } from "@/components";
import { Button } from "@/_components";

const FleetNotification: React.FC<
  {
    title?: string,
    content?: string,
    onConfirm?:() => void
  } & PromptChildrenProps
> = ({
  title,
  content,
  hide,
  onClose,
  onConfirm
}) => {
    return <FleetPrompt mode="fixed" hide={hide}>
      <div className={style["fleet-notification"]}>
        <div className="notification-container">
          <FleetIcon
            outerLayerClassname="notification-icon"
            type="FleetTipIcon"
          />
          <div className="notification">
            <div className="notification-header">
              <span>{title}</span>
              <FleetIcon
                type="FleetCloseIcon"
                onClick={onClose}
              />
            </div>
            <div className="notification-content">
              {content}
            </div>
          </div>
        </div>
        <div className="notification-footer">
          <Button
            size="small"
            type="secondary"
            onClick={onClose}
          >
            Cancel
          </Button>
          <Button
            size="small"
            onClick={onConfirm}
          >
            Confirm
          </Button>
        </div>
      </div>
    </FleetPrompt>
  }

  export default FleetNotification