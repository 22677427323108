import { TableCellType } from "./type";
export type TableRowType = {
  rowIndex: number;
  row: (TableCellType & {disabled?:boolean})[];
};
export const basicInfoTableConfig: TableRowType[] = [
  {
    rowIndex: 0,
    row: [
      { colSpan: 1, rowSpan: 1, label: "DATE / TIME (LT / UTC) OF REPORT" },
      { colSpan: 1, rowSpan: 1, prop: "dateTime" },
      { colSpan: 1, rowSpan: 1, label: "HOURS SINCE LAST REPORT" },
      { colSpan: 1, rowSpan: 1, prop: "hoursSinceLastPort" },
      { colSpan: 1, rowSpan: 1, label: " TTL WORKING DAYS" },
      { colSpan: 1, rowSpan: 1, prop: "ttlWorkDay" }
    ],
  },
  {
    rowIndex: 1,
    row: [
      { colSpan: 1, rowSpan: 1, label: "WORKING HOURS last 24 hrs :" },
      { colSpan: 1, rowSpan: 1, prop: "workingHoursLastDay" },
      { colSpan: 1, rowSpan: 1, label: "Vessel Name" },
      { colSpan: 1, rowSpan: 1, prop: "vesselName" },
      { colSpan: 1, rowSpan: 1, label: "Name of Port" },
      { colSpan: 1, rowSpan: 1, prop: "nameOfPort" },
    ],
  },
  {
    rowIndex: 2,
    row: [
      { colSpan: 1, rowSpan: 1, label: "Name of Berth" },
      { colSpan: 1, rowSpan: 1, prop: "nameOfBerth" },
      { colSpan: 1, rowSpan: 1, label: "ETCD (LT)" },
      { colSpan: 1, rowSpan: 1, prop: "ETCD" },
      { colSpan: 1, rowSpan: 1, label: "Arrival Day&Time" },
      { colSpan: 1, rowSpan: 1, prop: "arrivalDateTime" },
    ],
  },
  {
    rowIndex: 3,
    row: [
      { colSpan: 1, rowSpan: 1, label: "NOR TENDERED" },
      { colSpan: 1, rowSpan: 1, prop: "norTendered" },
      { colSpan: 1, rowSpan: 1, label: "VSL ANCHORED" },
      { colSpan: 1, rowSpan: 1, prop: "vslAnchored" },
      { colSpan: 1, rowSpan: 1, label: "ANCHOR AWEIGH" },
      { colSpan: 1, rowSpan: 1, prop: "anchorAweigh" },
    ],
  },
  {
    rowIndex: 4,
    row: [
      { colSpan: 1, rowSpan: 1, label: "POB" },
      { colSpan: 1, rowSpan: 1, prop: "pob" },
      { colSpan: 1, rowSpan: 1, label: "FIRST LINE ASHORE" },
      { colSpan: 1, rowSpan: 1, prop: "firstLineAshore" },
      { colSpan: 1, rowSpan: 1, label: "ALL MADE FAST" },
      { colSpan: 1, rowSpan: 1, prop: "allMadeFast" },
    ],
  },
  {
    rowIndex: 5,
    row: [
      { colSpan: 1, rowSpan: 1, label: "INITIAL DRAFT SURVEY COMMENCED" },
      { colSpan: 1, rowSpan: 1, prop: "initialDraftSurveyCommenced" },
      { colSpan: 1, rowSpan: 1, label: "INITIAL DRAFT SURVEY COMPLETED" },
      { colSpan: 1, rowSpan: 1, prop: "initialDraftSurveyCompleted" },
      { colSpan: 1, rowSpan: 1, label: "LOAD COMMENCED" },
      { colSpan: 1, rowSpan: 1, prop: "loadDishCommenced" },
    ],
  },
  {
    rowIndex: 6,
    row: [
      { colSpan: 1, rowSpan: 1, label: "LOAD COMPLETED" },
      { colSpan: 1, rowSpan: 1, prop: "loadDishCompleted" },
      { colSpan: 1, rowSpan: 1, label: "FINAL DRAFT SURVEY COMMENCED" },
      { colSpan: 1, rowSpan: 1, prop: "finalDraftSurveyCommenced" },
      { colSpan: 1, rowSpan: 1, label: "FINAL DRAFT SURVEY COMPLETED" },
      { colSpan: 1, rowSpan: 1, prop: "finalDraftSurveyCompleted" },
    ],
  },
];
