import React from "react";
import classnames from "classnames";
import style from "./index.module.less";
import { MovementLaytimePortProps } from "./type";
import { FleetCustomRadio } from "@/components/Radio";
import { PurposeMap } from "../subTotal/type";

const LaytimeTabs: React.FC<MovementLaytimePortProps> = ({
  items,
  activeIndex,
  onChange
}) => {
  return <div className={style["layTime-leftArea"]}>
    <div className="leftArea">
      {
        items?.map((item, idx) => {
          return <div className={classnames("leftArea-item", {
            "active": item?.id === activeIndex
          })} key={idx}>
            <div className="leftArea-portArea">
              <span>{item?.portName}</span>
              <span>{PurposeMap[item?.purpose]}</span>
            </div>
            <FleetCustomRadio 
              value={activeIndex === item?.id}
              onChange={(checked) => onChange?.(item?.id,checked)}
            />
          </div>
        })
      }
    </div>
  </div>
}

export default LaytimeTabs;