import { type ParameterType } from "@/api";
import { loginOut, loginOutAction } from "@/store/userSlice";

import {
  getPerformanceModelApi,
  getPerformanceModelParams,
  updatePerformanceApi,
  updatePerformanceParams
} from "@/api/estimation/performance";

export const getPerformanceModelAction: ParameterType<getPerformanceModelParams> = (
  params,
  frontCallback,
  successCallback,
  errorCallback,
  dispatch,
  navigate
) => {
  frontCallback?.();
  getPerformanceModelApi(params).then(response => {
    successCallback?.(response)
  }).catch(err => {
    errorCallback?.(err);
    if (err?.loginStatus === 'token faild') {
      // dispatch?.(loginOut({}));
      // navigate?.('/')
      dispatch(loginOutAction(() => navigate?.("/login")))
    }
  })
}

export const updatePerformanceAction: ParameterType<updatePerformanceParams> = (
  params,
  frontCallback,
  successCallback,
  errorCallback,
  dispatch,
  navigate
) => {
  frontCallback?.();
  updatePerformanceApi(params).then(response => {
    successCallback?.(response)
  }).catch(err => {
    errorCallback?.(err);
    if (err?.loginStatus === 'token faild') {
      // dispatch?.(loginOut({}));
      // navigate?.('/')
      dispatch(loginOutAction(() => navigate?.("/login")))
    }
  })
}