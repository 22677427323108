import { initialPerformance } from "./source";
import { PerformanceType } from "./type";

export const handlerPerformance = (item: PerformanceType, val: string, goodWeatherDef: string): Partial<PerformanceType> => {
  let performanceForm = initialPerformance({...item, goodWeatherDef});

  let speedReg = /(LADEN|BALLAST):\s*(-?\d+(?:\.\d+)?)\s*KNOTS\s*(ON\s*)?(-?\d+(?:\.\d+)?)\s*MT\s*(-?\d+(?:\.\d+)?)\s*(VLSFO|VHSFO)\s*\+\s*(-?\d+(?:\.\d+)?)\s*MT\s*LS(MGO|MDO)/gi;
  let portReg = /(WORKING|IDLE):\s*(-?\d+(?:\.\d+)?)\s*MT\s*(-?\d+(?:\.\d+)?)\s*(VLSFO|VHSFO)\s*\+\s*(-?\d+(?:\.\d+)?)\s*MT\s*LS(MGO|MDO)/gi;
  let speedStrList = val.match(speedReg) ?? [];
  let portStrList = val.match(portReg) ?? [];
  let ballastIdx = 0;
  let ladenIdx = 0;
  let index = 0;
  for (let i = 0; i <= speedStrList?.length - 1; i++) {
    if (ballastIdx >= 3 && ladenIdx >= 3) break
    const item = speedStrList?.[i];
    let title: any = item.match(/^(LADEN|BALLAST)/i);
    let speed = item.match(/(-?\d+(?:\.\d+)?)\s*KNOTS/i);
    let fo = item.match(/(-?\d+(?:\.\d+)?)\s*MT\s*(-?\d+(?:\.\d+)?)\s*(VLSFO|VHSFO)/i);
    let go = item.match(/(-?\d+(?:\.\d+)?)\s*MT\s*LS(MGO|MDO)/i);
    title = title[1]?.toLowerCase();
    // console.log('title',{
    //   i,
    //   title,
    //   speed,
    //   fo,
    //   go
    // })
    index = title === "ballast" ? ballastIdx : ladenIdx;
    switch (true) {
      case fo[fo?.length - 1]?.toLowerCase().includes("lsfo"):
        performanceForm[title][index].lsFo = (+fo?.[1] ?? "");
        break;
      case fo[fo?.length - 1]?.toLowerCase().includes("hsfo"):
        performanceForm[title][index].hsFo = (+fo?.[1] ?? "");
        break;
      default:
        break
    }

    switch (true) {
      case go[go?.length - 1]?.toLowerCase().includes("mgo"):
        performanceForm[title][index].mgo = (+go?.[1] ?? "");
        break;
      case go[go?.length - 1]?.toLowerCase().includes("mdo"):
        performanceForm[title][index].mdo = (+go?.[1] ?? "");
        break;
      default:
        break
    }
    performanceForm[title][index].speed = (+speed?.[1] ?? "");
    title === "ballast" ? (ballastIdx++) : (ladenIdx++)
  }
  for (let item of portStrList) {
    let title: any = item.match(/^(WORKING|IDLE)/i);
    let fo = item.match(/(-?\d+(?:\.\d+)?)\s*MT\s*(-?\d+(?:\.\d+)?)\s*(VLSFO|VHSFO)/i);
    let go = item.match(/(-?\d+(?:\.\d+)?)\s*MT\s*LS(MGO|MDO)/i);
    title = title[1]?.toLowerCase()?.slice(0, 4);
    switch (true) {
      case fo[fo?.length - 1]?.toLowerCase().includes("lsfo"):
        performanceForm[title].lsFo = (+fo?.[1] ?? "");
        break;
      case fo[fo?.length - 1]?.toLowerCase().includes("hsfo"):
        performanceForm[title].hsFo = (+fo?.[1] ?? "");
        break;
      default:
        break
    }

    switch (true) {
      case go[go?.length - 1]?.toLowerCase().includes("mgo"):
        performanceForm[title].mgo = (+go?.[1] ?? "");
        break;
      case go[go?.length - 1]?.toLowerCase().includes("mdo"):
        performanceForm[title].mdo = (+go?.[1] ?? "");
        break;
      default:
        break
    }
  }
  performanceForm.performanceClause = val
  return performanceForm
}