import { useCallback, useMemo, useRef, useState } from "react";
import { BunkerLogTableDisabledType } from "../type";
import useReminder from "@/hook/useReminder";
import dayjs from "dayjs";
const useIndex = (addRow, deleteRow, bunkerLogData) => {
  const [bunkerLogTableDisabled, setBunkerLogTableDisabled] =
    useState<BunkerLogTableDisabledType>([]);
  const [first, setFirst] = useState(true);
  const onMouse = (index, prop, type) => {
    if (type == "enter") {
      const newDisabled = JSON.parse(JSON.stringify(bunkerLogTableDisabled));
      newDisabled.splice(index, 1, {
        ...bunkerLogTableDisabled[index],
        [prop]: false,
      });
      setBunkerLogTableDisabled(newDisabled);
    } else {
      const newDisabled = JSON.parse(JSON.stringify(bunkerLogTableDisabled));
      newDisabled.splice(index, 1, {
        ...bunkerLogTableDisabled[index],
        [prop]: true,
      });
      setBunkerLogTableDisabled(newDisabled);
    }
  };
  const handleAdd = () => {
    const newDisabled = JSON.parse(JSON.stringify(bunkerLogTableDisabled));
    newDisabled.push({
      dateTime: true,
      dailyBunkerHfo: true,
      dailyBunkerLsfo: true,
      dailyBunkerMdo: true,
      dailyBunkerMgo: true,
      bunkerRobHfo: true,
      bunkerRobLsfo: true,
      bunkerRobMdo: true,
      bunkerRobMgo: true,
    });
    setBunkerLogTableDisabled(newDisabled);
    addRow({
      dateTime: dayjs().valueOf(),
      dailyBunkerHfo: null,
      dailyBunkerLsfo: null,
      dailyBunkerMdo: null,
      dailyBunkerMgo: null,
      bunkerRobHfo: null,
      bunkerRobLsfo: null,
      bunkerRobMdo: null,
      bunkerRobMgo: null,
    });
  };
  const handleDelete = (index) => {
    const newDisabled = JSON.parse(JSON.stringify(bunkerLogTableDisabled));
    newDisabled.splice(index, 1);
    setBunkerLogTableDisabled(newDisabled);
    deleteRow(index);
  };
  const cellValue = (row, prop) => {
    if (prop == "action") return "";
    if (prop == "dateTime") {
      return row[prop] ? dayjs(row[prop]).format("YYYY-MM-DD HH:mm") : null;
    } else {
      if (prop.indexOf("daily") != -1 || prop.indexOf("Rob") != -1) {
        return row[prop]
          ? Number(row[prop])
              ?.toFixed(2)
              .replace(/(\d)(?=(\d{3})+\.)/g, "$1,")
          : null;
      } else {
        return row[prop] || null;
      }
    }
  };
  return {
    bunkerLogTableDisabled,
    onMouse,
    handleAdd,
    handleDelete,
    cellValue,
    first,
    setFirst,
    setBunkerLogTableDisabled,
  };
};
export default useIndex;
