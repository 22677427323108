import { HfHireAndFeesFormChangeEvent, HfHireAndFeesFormCommitEvent, HfHireAndFeesFormType, checkHireAndFeesFormKeys, initialHireAndFeesForm } from "@/featrue/voyagemanage/headFixture/hireAndFees/type";
import { useState } from "react";

const useHireAndFeesForm = () => {
  const [hireAndFeesForm, setHireAndFeesForm] = useState<HfHireAndFeesFormType>({
    ...initialHireAndFeesForm
  })

  const change: HfHireAndFeesFormChangeEvent = (key, value) => {
    setHireAndFeesForm(prev => {
      return {
        ...prev,
        [key]: value
      }
    })
  }

  const commit: HfHireAndFeesFormCommitEvent = (item) => {
    setHireAndFeesForm(prev => {
      return {
        ...prev,
        ...item
      }
    })
  }

  const clear = () => {
    setHireAndFeesForm({
      ...initialHireAndFeesForm
    })
  }
  const check = () => {
    let checkKey = "";
    let checked = true;
    for (let item of checkHireAndFeesFormKeys) {
      if (hireAndFeesForm[item] !== 0 && !hireAndFeesForm[item]) {
        checkKey = item;
        checked = false;
        break;
      }
    }

    return {
      checked,
      checkKey
    }
  }
  return {
    hireAndFeesForm,
    check,
    change,
    commit,
    clear
  }
}

export default useHireAndFeesForm;