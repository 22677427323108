import { ParameterActionType, RequestApiType, RequestParamsType, requestActionMap } from "@/api";
import { loginOutAction } from "@/store/userSlice";

export const onRequest: ParameterActionType<RequestApiType, RequestParamsType> = (
	type,
	params,
	frontCallback,
	successCallback,
	errorCallback,
	dispatch,
	navigate
) => {
	frontCallback?.();
	requestActionMap[type]?.(params)
		?.then((response) => {
			successCallback?.(response);
		})
		?.catch((err) => {
			errorCallback?.(err);
			if (err?.loginStatus === "token faild") {
				dispatch(loginOutAction(() => navigate?.("/login")));
			}
		});
};
