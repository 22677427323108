import { useState } from "react";
import { PortAreaListItemType } from "../type";

const useIndex = () => {
	const [loading, setLoading] = useState<boolean>(false);
	return {
		loading,
	};
};

export default useIndex;
