import { FleetOptionType } from "@/types/advancedType"

export const voyageStatusOptions: FleetOptionType<
  "Completed" | "UnCompleted" | "All",
  0 | 1 | 2
>[] = [
    { label: "All", value: 2 },
    { label: "UnCompleted", value: 0 },
    { label: "Completed", value: 1 }
  ];

export const levelOptions: FleetOptionType<
  "All" | "Low" | "Medium" | "High",
  1 | 2 | 3 | 4
>[] = [
    { label: "All", value: 4 },
    { label: "High", value: 3 },
    { label: "Medium", value: 2 },
    { label: "Low", value: 1 },
  ]