import { CommonInitItemEvent } from "@/types/event"
import { MovementTaskListItemType } from "./type"

export const initialMovementTaskItem: CommonInitItemEvent<MovementTaskListItemType> = (item) => {
  return {
    portName: item?.portName ?? "",
    portRotationId: item?.portRotationId ?? null,
    stage: item?.stage ?? "Delivery",
    count: item?.count ?? 0,
    totalCount: item?.totalCount ?? 0
  }
}