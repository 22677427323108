import { useMemo, useState } from "react"
import { BunkerDetailsProps } from "../type"

const useIndex = () => {
  const [dataSource, setDataSource] = useState<BunkerDetailsProps["items"]>([])

  const summary = useMemo(() => {
    return dataSource?.reduce((prev, curr) => {
      return prev + +curr?.portExpense
    }, 0)
  }, [dataSource])

  const change: BunkerDetailsProps["onChange"] = (currentIndex, key, value) => {
    setDataSource(prev => {
      const currentItem = prev?.[currentIndex];
      prev?.splice(currentIndex, 1, {
        ...currentItem,
        [key]: value
      })
      return [...prev]
    })
  }

  const commit: BunkerDetailsProps["onCommit"] = (currentIndex, item) => {
    setDataSource(prev => {
      const currentItem = prev?.[currentIndex];
      prev?.splice(currentIndex, 1, {
        ...currentItem,
        ...item
      })
      return [...prev]
    })
  }

  const init = (items: BunkerDetailsProps["items"]) => {
    setDataSource(items ?? [])
  }

  return {
    dataSource,
    change,
    commit,
    summary,
    init
  }
}

export default useIndex