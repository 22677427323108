import { ApiParameter } from "@/api";
import { appVersion } from "@/api/constant";
import request from "@/service";
import {
	addAisStarParams,
	getAisCurrentStateParams,
	getAisGroupParams,
	getMonitoringAisDataParams,
	getMonitoringThreeAisDataParams,
	getAisCurrentForImoParams,
	getMonitoringAisDataForImoParams
} from "./type";

const enum ApiMap {
	getAisStarGroupParameter,
	getAisGroupParameter,
	addAisStarParameter,
	getAisCurrentStateParameter,
	getMonitoringThreeAisDataParameter,
	getMonitoringAisDataParameter,
	getAisCurrentForImoParameter,
	getMonitoringAisDataForImoParameter
}

type ApiUrl = keyof typeof ApiMap;

const aisApi: ApiParameter<ApiUrl> = {
	getAisStarGroupParameter: {
		method: "get",
		url: appVersion + "/moni/collect/list"
	},
	getAisGroupParameter: {
		method: "get",
		url: appVersion + "/moni/collect/query"
	},
	addAisStarParameter: {
		method: "post",
		url: appVersion + "/moni/collect/save"
	},
	getAisCurrentStateParameter: {
		method: "get",
		url: appVersion + "/ais/cmes/state"
	},
	getMonitoringAisDataParameter: {
		method: "get",
		url: appVersion + "/ais/cmes/data"
	},
	getMonitoringThreeAisDataParameter: {
		method: "get",
		url: appVersion + "/ais/cmes/three-high"
	},
	getAisCurrentForImoParameter: {
		method: "get",
		url: appVersion + "/ais/cmes/state/imo"
	},
	getMonitoringAisDataForImoParameter: {
		method: "get",
		url: appVersion + "/ais/cmes/data/imo"
	}
};

export const getAisStarGroupApi = () => {
	return request.request({
		...aisApi.getAisStarGroupParameter
	});
};

export const getAisGroupApi = (params: getAisGroupParams) => {
	return request.request({
		...aisApi.getAisGroupParameter,
		params
	});
};

export const addAisStarApi = (params: addAisStarParams) => {
	return request.request({
		...aisApi.addAisStarParameter,
		data: params
	});
};

export const getAisCurrentStateApi = (params: getAisCurrentStateParams) => {
	return request.request({
		...aisApi.getAisCurrentStateParameter,
		url: `${aisApi.getAisCurrentStateParameter.url}/${params?.mmsi}`
	});
};

export const getMonitoringAisDataApi = (params: getMonitoringAisDataParams) => {
	return request.request({
		...aisApi.getMonitoringAisDataParameter,
		params
	});
};

export const getAisCurrentForImoApi = (params: getAisCurrentForImoParams) => {
	return request.request({
		...aisApi.getAisCurrentForImoParameter,
		url: `${aisApi.getAisCurrentForImoParameter.url}/${params?.imo}`
	});
};

export const getMonitoringAisDataForImoApi = (params: getMonitoringAisDataForImoParams) => {
	return request.request({
		...aisApi.getMonitoringAisDataForImoParameter,
		params
	});
};

export const getMonitoringThreeAisDataApi = (params: getMonitoringThreeAisDataParams) => {
	return request.request({
		...aisApi.getMonitoringThreeAisDataParameter,
		url: `${aisApi.getMonitoringThreeAisDataParameter.url}/${params?.imo}`
	});
};
