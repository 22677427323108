import React, { FC } from "react";
import style from "./index.module.less";
import { CommonContactPopupProps } from "./type";
import { FleetLoading } from "@/components";

const CommonContactPopup: FC<CommonContactPopupProps> = ({
  list,
  loading,
  onSelect,
  loadMore
}) => {
  const handleSelect = (e: React.MouseEvent<HTMLUListElement, MouseEvent>, item: CommonContactPopupProps["list"][number]) => {
    e?.stopPropagation()
    onSelect?.(item)
  }
  return <div className={style["common-contactSelect--Popup"]}>
    <FleetLoading loading={loading} />
    <ul className="contactSelect--popup--header">
      <li>Name</li>
      <li>Company Name</li>
      <li>Email</li>
      <li>Business Type</li>
    </ul>
    <div className="contactSelect--popup--container fleet-nonScrolBar">
      <div className="contact--popup--scrollArea">
        {
          list?.map((item, index) => {
            return <ul key={index} className="contact--popup--item" onClick={(e) => {
              handleSelect?.(e, item)
            }}>
              <li>{item?.name}</li>
              <li>{item?.companyName}</li>
              <li>{item?.email}</li>
              <li>{item?.businessType}</li>
            </ul>
          })
        }
      </div>
    </div>
    <div className="contactSelect--popup--loadMore" onClick={loadMore}>
      <span>Load More</span>
      <span className="font_family fleet-back"></span>
    </div>
  </div>
}

export default CommonContactPopup