import React, { useCallback } from "react";
import { FleetButton, FleetIcon } from "@/components";
import style from "./index.module.less";
import classnames from "classnames";
import { useNavigate } from "react-router-dom";
import { useAppDispatch } from "@/hook";
import { loginOutAction } from "@/store/userSlice";

const Page404: React.FC<{

}> = (props) => {
  const navigate = useNavigate()
  const dispatch = useAppDispatch()
  const handleBack = useCallback(() => {
    navigate("/")
  }, [])

  const handleExit = useCallback(() => {
    dispatch(loginOutAction(() => navigate("/login")))
  }, [])
  return <div className={classnames("fleet-common", style["fleet-404"])}>
    <div className="errorPage">
      <FleetIcon
        outerLayerClassname="errorPage-logo"
        type="Fleet404Icon"
      />
      <FleetIcon
        outerLayerClassname="errorPage-logoTitle"
        type="FleetLogoTitle"
      />
      <FleetIcon
        type="Fleet404Image"
        outerLayerClassname="errorPage-image"
      />
      <span className="errorPage-404">404</span>
      <span className="errorPage-tip">Sorry, you are not authorized to access this page.</span>
      <div className="errorPage-operation">
        <FleetButton onClick={handleBack}>Back Home</FleetButton>
        <FleetButton onClick={handleExit}>Exit</FleetButton>
      </div>
    </div>
  </div>
}

export default Page404;