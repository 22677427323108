import React, { FC } from "react";
import style from "./index.module.less";
import { PreOperationVoyageListProps } from "./type";
import { FleetInput, FleetLoading } from "@/components";
import { PreOperationVoyageItem, PreOperationNewlyMenu } from "./components";
import { CommonRoleWrap } from "@/common";
import { useAppSelector } from "@/hook";
import { UserStore } from "@/store/userSlice/state";
import classnames from "classnames";
import { Button, Popover } from "@/_components";

const PreOperationVoyageList: FC<PreOperationVoyageListProps> = ({
	items,
	loading,
	onAdd,
	onSearch,
	onSelect,
	activeIndex,
	onDelete
}) => {
	const userInfo = useAppSelector<UserStore>((state) => state.UserStore);
	return (
		<div className={style["preOperation-voyageList"]}>
			<div className="voyageList-header">
				<span className="fleet-title">Voyage List</span>
				<div
					className={classnames("voyageList-header--filter", {
						"role-add-empty":
							!userInfo?.permissions?.includes("*:*:*") &&
							!userInfo?.permissions?.includes("preOp:detail:add")
					})}
				>
					<FleetInput
						value={undefined}
						onChange={onSearch}
						placeholder="Search"
						prefix="LayoutSearchIcon"
						className="voyageList-header--search"
					/>
					<CommonRoleWrap roleStr="preOp:detail:add">
						<Popover
							render={(setIsOpen) => {
								return (
									<PreOperationNewlyMenu
										onSelect={(val) => {
											setIsOpen(false);
											onAdd?.(val);
										}}
									/>
								);
							}}
							children={
								<Button
									size="small"
									type="primary"
									className="voyageList-header--add"
									suffixIcon="fleet-back"
								>
									Add
								</Button>
							}
						/>
					</CommonRoleWrap>
					{/* <CommonRoleWrap roleStr="preOp:detail:add">
            <div className="voyageList-header--add" onClick={onAdd}>
              <span className="font_family fleet-add"></span>
              <span>New Voyage</span>
            </div>
          </CommonRoleWrap> */}
				</div>
			</div>
			<div
				className={classnames("voyageList-container", {
					"role-add-empty":
						!userInfo?.permissions?.includes("*:*:*") &&
						!userInfo?.permissions?.includes("preOp:detail:add")
				})}
			>
				<FleetLoading loading={loading} />
				<div className="voyageList-scrollArea">
					{items?.map((item, index) => {
						return (
							<PreOperationVoyageItem
								key={index}
								activeIndex={activeIndex}
								currentIndex={index}
								item={item}
								onDelete={onDelete}
								onSelect={onSelect}
							/>
						);
					})}
				</div>
			</div>
		</div>
	);
};

export default PreOperationVoyageList;
