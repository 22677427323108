import React from "react";
import { InvFreightTemplateProps } from "./type";
import { InvTemplateStyle } from "../../../../common";
import useIndex from "./hooks/useIndex";
// import useIndex from "./hooks/useIndex";

const InvFreightTemplate: React.FC<InvFreightTemplateProps> = ({
	canvasRef,
	dataSource,
	activeTab
}) => {
	useIndex(canvasRef, dataSource, activeTab);
	return (
		<div className={InvTemplateStyle["invoices-template-common"]}>
			<canvas
				width="930"
				height="1364"
				id="invoices-template"
				ref={canvasRef}
			></canvas>
		</div>
	);
};

export default InvFreightTemplate;
