import { CommonInitItemEvent } from "@/types/event";
import { RouteCommonGroupSelectItemType } from "./type";

export const initialGroupItem: CommonInitItemEvent<RouteCommonGroupSelectItemType> = (item) => {
	return {
		id: item?.id ?? null,
		groupName: item?.groupName ?? "",
		userId: item?.userId ?? null
	};
};
