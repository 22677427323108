import React, { FC } from "react";
import style from "./index.module.less"
import { VesselArchivesFilterProps } from "./type";
import { FleetVerifyInput } from "@/components";

const VesselArchivesFilter: FC<VesselArchivesFilterProps> = ({
  onSearch
}) => {
  return <div className={style["vesselArchives-filter"]}>
    <FleetVerifyInput
      containerClassname="filter-search"
      value={undefined}
      onChange={onSearch}
      label="Filter"
      placeholder="Search"
    />
  </div>
}

export default VesselArchivesFilter