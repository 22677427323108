import ReactDOMServer from "react-dom/server";
import { RouteCommonVesselInfoPopup, RouteCommonVesselInfoPopupItemType } from "../components";
import React, { MutableRefObject } from "react";
import mapboxgl, { AnySourceData } from "mapbox-gl";
import mapStyle from "../index.module.less";

export const generateVesselInfoPopup = (
	mapboxGl: mapboxgl.Map,
	originEvent: MouseEvent,
	option: {
		popupElement: MutableRefObject<mapboxgl.Popup>;
		dataSource: RouteCommonVesselInfoPopupItemType;
		position: [number, number];
		type: "newly" | "delete";
	}
) => {
	if (!mapboxGl) return;
	if (option?.type === "delete") {
		option.popupElement.current?.remove();
		option.popupElement.current = null;
		return;
	}
	const htmlString = ReactDOMServer.renderToString(
		<RouteCommonVesselInfoPopup
			item={
				typeof option?.dataSource === "string" ? JSON.parse(option?.dataSource) : option?.dataSource
			}
		/>
	);
	const popupOption: mapboxgl.PopupOptions = {
		closeOnMove: false,
		closeButton: false,
		offset: [0, -25],
		anchor: "bottom",
		className: mapStyle["routeCommon-map-vesselInfo-popup"]
	};
	if (originEvent?.offsetY - 219 <= 0) {
		popupOption.offset = [0, 25];
		popupOption.anchor = "top";
	} else if (originEvent?.offsetX - 235 / 2 - 335 <= 0) {
		popupOption.offset = [25, 0];
		popupOption.anchor = "left";
	}
	const popup = new mapboxgl.Popup(popupOption)
		.setHTML(htmlString)
		.setLngLat(option?.position)
		.addTo(mapboxGl);
	option.popupElement.current = popup;
};

export const loadImage = (
	mapboxGl: mapboxgl.Map,
	option: {
		imagePath: string;
		imageName: string;
	}
) => {
	if (!mapboxGl) return;
	mapboxGl.loadImage(option?.imagePath, (error, image) => {
		if (error) throw error;
		mapboxGl.addImage(option?.imageName, image);
	});
};

export const addSourceToMap = (
	mapboxGl: mapboxgl.Map,
	option: {
		sourceName: string;
		source: AnySourceData;
	}
) => {
	if (!mapboxGl) return;
	if (mapboxGl.getSource(option?.sourceName)) return;
	mapboxGl.addSource(option?.sourceName, option?.source);
};

export const addVesselGroupLayerToMap = (
	mapboxGl: mapboxgl.Map,
	option: {
		layerName: string;
		sourceName: string;
		imageName: string;
	}
) => {
	if (!mapboxGl) return;
	const currentLayer = mapboxGl.addLayer({
		id: option?.layerName,
		type: "symbol",
		source: option?.sourceName,
		layout: {
			"icon-image": option?.imageName,
			"icon-size": 0.2
		}
	});
	currentLayer.on("mouseenter", (event) => {});
};
