import React, { FC } from "react";
import style from "./index.module.less";
import classnames from "classnames";
import { FleetCustomRadio } from "@/components/Radio";
import { MonitoringAreaPresetProps } from "./type";
import { FleetLoading } from "@/components";
const MonitoringAreaPreset: FC<MonitoringAreaPresetProps> = ({ areaList, onChange, loading }) => {
	return (
		<div className={classnames(style["area-preset"])}>
			<div className="monitoring-area-container">
				<div className="monitoring-area-preset">
					<span className="monitoring-area-title">Preset</span>
					<div className="monitoring-preset-box fleet-nonScrolBar">
						<FleetLoading loading={loading} />
						{areaList?.map((item, index) => {
							return (
								<div className="monitoring-preset-boxItem">
									<FleetCustomRadio
										value={item.isCurrentSelect === 1}
										onChange={(val) => {
											onChange(item);
										}}
									/>
									<span className="monitoring-preset-label">{item.areaName}</span>
								</div>
							);
						})}
					</div>
				</div>
			</div>
		</div>
	);
};

export default MonitoringAreaPreset;
