import React, { FC, useEffect, useState } from "react";
import { useMonitoringContext } from "@/pages/monitoring/MonitoringContext";
import { getDailyReportDetailAction } from "@/action/monitoring/vessel";
import { FleetLoading, FleetTextarea } from "@/components";
import style from "./index.module.less";
import useReminder from "@/hook/useReminder";
import { DailyReportDetailProps } from "./type";
import { useAppDispatch } from "@/hook";
import { useNavigate } from "react-router-dom";

const DailyReportDrawer: FC<DailyReportDetailProps> = ({ onClose }) => {
	const dispatch = useAppDispatch();
	const navigate = useNavigate();
	const { reminder } = useReminder();
	const [data, setData] = useState("");
	const [loading, setLoading] = useState<boolean>(false);
	const { dailyReportSelectRow } = useMonitoringContext();

	const getDailyReportDetailFront = () => {
		setLoading(true);
	};

	const getDailyReportDetailSuccess = (response) => {
		console.log(response?.data);
		setData(response?.data || "");
		setLoading(false);
	};

	const getDailyReportDetailFailed = (error) => {
		reminder("error", error?.msg ? error?.msg + ": " + error?.data : error?.data);
		setLoading(false);
	};

	const getDailyReportDetail = () => {
		const [first] = dailyReportSelectRow;
		if (first) {
			getDailyReportDetailAction(
				{ voyageNo: first.voyageNo, reportTime: first.reportDateTime, reportType: first.type },
				getDailyReportDetailFront,
				getDailyReportDetailSuccess,
				getDailyReportDetailFailed,
				dispatch,
				navigate
			);
		}
	};
	useEffect(() => {
		getDailyReportDetail();
	}, [dailyReportSelectRow]);

	return (
		<div className={style["dailyReportDrawer"]}>
			<FleetLoading loading={loading} />
			<div className="dailyReportDrawer-header">
				<span className="header-title">Daily Report</span>
				<span className="font_family fleet-close" onClick={onClose}></span>
			</div>
			<div className="dailyReportDrawer-container">
				<FleetTextarea value={data} className="scrollArea"></FleetTextarea>
				{/* <div className="scrollArea">{data}</div> */}
			</div>
		</div>
	);
};

export default DailyReportDrawer;
