import React, { FC } from "react";
import classnames from "classnames";
import { Tag, Tooltip } from 'antd';
import style from './index.module.less'
import { VoyageItemProps } from "./type";
import { FleetTooltip } from "@/components";

const VoyageItem: FC<VoyageItemProps> = ({
  id,
  className,
  data,
  currentVessel,
  onItemClick
}) => {
  return <div
    className={classnames(style["monitoring-voyageItem"], {
      [style["monitoring-voyageItem--active"]]: currentVessel?.voyageNo === data.voyageNo && currentVessel?.vesselStatus === data?.vesselStatus
    })}
    onClick={() => onItemClick?.(data)}
    id={id}
  >
    <div className="voyageItem-header">
      <div className="voyageItem-vessel">
        <span>{data?.vesselName}</span>
        <span>{data?.vesselStatus?.[0]}</span>
      </div>
      <span className={classnames("voyageItem-status", data?.status)}>{data?.status}</span>
    </div>
    <div className="voyageItem-footer">
      {/* <span className="voyageItem-voyageNo">Voy. NO.:{data?.voyageNo}</span>
      <span className="voyageItem-pic">PIC:{data?.charaByName}</span> */}
      <FleetTooltip
        trigger="hover"
        placement="bottom"
        overlayClassName={style["voyageItem-tooltip"]}
        outerLayer={<span className="voyageItem-pic">Voy. NO.:{data?.voyageNo}</span>}
        children={<span className="aisDetail-latLon">{data?.voyageNo}</span>}
      />
      <FleetTooltip
        trigger="hover"
        placement="bottom"
        overlayClassName={style["voyageItem-tooltip"]}
        outerLayer={<span className="voyageItem-pic">PIC:{data?.charaByName}</span>}
        children={<span className="aisDetail-latLon">{data?.charaByName}</span>}
      />
    </div>
  </div>
}

const VesselItem: FC<VoyageItemProps> = ({
  id,
  className,
  data,
  onItemClick,
  currentVessel
}) => {
  return <div id={id} className={classnames([style.vesselItem, {
    [style["monitoring-voyageItem--active"]]: currentVessel?.voyageNo === data.voyageNo && currentVessel?.vesselStatus === data?.vesselStatus
  }])} onClick={() => onItemClick?.(data)}>
    <div className={classnames([style.item, style.title])}>
      <span className={style.ellipsis}>
        <span>{data.vesselName}</span>
        <span className={style.status}>{data.vesselStatus?.[0]}</span>
      </span>
      <Tag className={style[data.status]} style={{ margin: '0px' }}>{data.status}</Tag>
    </div>
    <div className={classnames([style.item, style.info])}>
      <Tooltip title={`Voy. NO. : ${data.voyageNo}`}>
        <span className={style.ellipsis}>Voy. NO. : {data.voyageNo}</span>
      </Tooltip>
      <Tooltip title={`PIC: ${data.charaByName}`}>
        <span className={style.ellipsis}>PIC: {data.charaByName}</span>
      </Tooltip>
    </div>
  </div >
}

export default VesselItem
