import { type ApiParameter } from "@/api";
import { appVersion } from "@/api/constant";
import request from "@/service";
import {
	saveMovementPortLogParams,
	updateToMovementParams,
	uploadMovementPortLogParams
} from "./type";

const enum ApiMap {
	getMovementPortLogParameter,
	getMovementPortListParameter,
	saveMovementPortLogParameter,
	analysisMovementPortLogParameter,
	updateToMovementParameter
}

type ApiUrl = keyof typeof ApiMap;

const movementPortLogApi: ApiParameter<ApiUrl> = {
	getMovementPortLogParameter: {
		method: "get",
		url: appVersion + `/move/port-report/`
	},
	getMovementPortListParameter: {
		method: "get",
		url: appVersion + `/move/port-report/port/list/`
	},
	saveMovementPortLogParameter: {
		method: "post",
		url: appVersion + `/move/port-report/save`
	},
	analysisMovementPortLogParameter: {
		method: "post",
		url: appVersion + `/move/port-report/analyze`
	},
	updateToMovementParameter: {
		method: "post",
		url: appVersion + `/move/port-report/updatetomovement`
	}
};

export const getMovementPortLogApi = (id: number) => {
	return request.request({
		...movementPortLogApi.getMovementPortLogParameter,
		url: movementPortLogApi.getMovementPortLogParameter.url + `${id}`
	});
};

export const getMovementPortListApi = (id: number) => {
	return request.request({
		...movementPortLogApi.getMovementPortListParameter,
		url: movementPortLogApi.getMovementPortListParameter.url + `${id}`
	});
};

export const saveMovementPortLogApi = (data: saveMovementPortLogParams) => {
	return request.request({
		...movementPortLogApi.saveMovementPortLogParameter,
		data
	});
};

export const analysisMovementPortLogApi = (data: uploadMovementPortLogParams) => {
	return request.request({
		...movementPortLogApi.analysisMovementPortLogParameter,
		data
	});
};

export const updateToMovementApi = (data: updateToMovementParams) => {
	return request.request({
		...movementPortLogApi.updateToMovementParameter,
		data
	});
};
