import { useState } from "react"
import { MovementBunkerPlanType, MovementBunkerPlanChangeEvent } from "../type"
import { initialBunkerPlan } from "../source"


const useIndex = () => {
  const [dataSource, setDataSource] = useState<MovementBunkerPlanType>(initialBunkerPlan())

  const change: MovementBunkerPlanChangeEvent = (key, value) => {
    setDataSource(prev => ({ ...prev, [key]: value }))
  }

  const init = (item: MovementBunkerPlanType) => {
    setDataSource(initialBunkerPlan(item))
  }

  return {
    dataSource,
    change,
    init
  }
}

export default useIndex