import { type ParameterType } from "@/api";
import { loginOut, loginOutAction } from "@/store/userSlice";

import {
  getPortDistanceParams,
  getPortDistanceApi
} from "@/api/estimation/cargoPort";

export const getPortDistanceAction: ParameterType<getPortDistanceParams> = (
  params,
  frontCallback,
  successCallback,
  errorCallback,
  dispatch,
  navigate
) => {
  frontCallback?.();
  getPortDistanceApi(params).then(response => {
    successCallback?.(response)
  }).catch(err => {
    errorCallback?.(err);
    if (err?.loginStatus === 'token faild') {
      // dispatch?.(loginOut({}));
      // navigate?.('/')
      dispatch(loginOutAction(() => navigate?.("/login")))
    }
  })
}