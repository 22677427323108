import React from "react";
import style from "./index.module.less";
import { MonitoringRoutePlanModalProps } from "./type";
import { FleetPrompt } from "@/common";
import { FleetButton } from "@/components";

const MonitoringRoutePlanModal: React.FC<MonitoringRoutePlanModalProps> = ({
  width,
  title,
  children,
  hide,
  onClose,
  onConfirm
}) => {
  return <FleetPrompt hide={hide} mode="fixed">
    <div className={style["routePlan-modal"]} style={{ width: width }}>
      <div className="modal-header">
        <span className="header-title">{title}</span>
        <span className="font_family fleet-close" onClick={onClose}></span>
      </div>
      {children}
      <div className="modal-footer">
        <FleetButton size="small" type="secondary" onClick={onClose}>cancel</FleetButton>
        <FleetButton size="small" onClick={onConfirm}>confirm</FleetButton>
      </div>
    </div>
  </FleetPrompt>
}

export default MonitoringRoutePlanModal