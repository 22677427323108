import { ApiParameter, RequestApiMapType } from "@/api";
import request from "@/service";

import { getRouteCommonDiscreteParams, RouteCommonMeteorologicalParamsType } from "./type";
import { appVersion } from "@/api/constant";

const enum ApiMap {
	getRouteCommonDiscreteParameter
}

export type ApiUrl = keyof typeof ApiMap;

const meteorologicalApi: ApiParameter<ApiUrl> = {
	getRouteCommonDiscreteParameter: {
		method: "post",
		url: "/meteoapi/meteorological/discrete"
	}
};

export const getRouteCommonDiscreteApi = (params: getRouteCommonDiscreteParams) => {
	return request.request({
		...meteorologicalApi.getRouteCommonDiscreteParameter,
		data: [params],
		headers: {
			// "x-api-key": "hf9r5RUhDd3dIwhe3cycH4xHOUvBDGT537d3T3VV",
			"Content-Type": "application/json"
		}
	});
};

export const meteorologicalApiMap: RequestApiMapType<ApiUrl, RouteCommonMeteorologicalParamsType> =
	{
		getRouteCommonDiscreteApi
	};
