

export enum MovementTaskPriority {
  L = 1,
  M,
  H
}

export enum MovementTaskPriorityLabelMap {
  low = 1,
  medium,
  high
}