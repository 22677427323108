import { AnyAction, Dispatch } from "@reduxjs/toolkit";
import { NavigateFunction } from "react-router-dom";
import { AxiosRequestConfig, AxiosResponse } from "axios";
import { movementApiMap, MovementApiType, MovementParamsType } from "./voyageManage/movement";
import { EstApiType, EstParamsType, estApiMap } from "./estimation/index";
import { PreopApiType, PreopParamsType, preopApiMap } from "./voyageManage/preOperation";
import { routeCommonApiMap, RouteCommonApiType, RouteCommonParamsType } from "./routeCommon";
import { aparApiMap, AparApiType, AparParamsType } from "./voyageManage/apAr/index";

export const appVersion =
	process.env.NODE_ENV === "development" ? "/gateway/voyage/v1" : "/gateway/voyage/v1";

console.log("process", process.env.NODE_ENV, appVersion);

export const appVersionV2 =
	process.env.NODE_ENV === "development" ? "/gateway/voyage/v2" : "/gateway/voyage/v2";

export type ApiParameter<T extends keyof any> = Record<
	T,
	{
		method: "get" | "post" | "put" | "delete";
		url: string;
	}
>;

export type RenderApiType<T extends `${string}Parameter`> = T extends `${infer Prefix}Parameter`
	? `${Prefix}Api`
	: never;

export type ParameterActionType<
	ParameterType extends `${string}Parameter`,
	ParamsType extends {} | null,
	U = Dispatch<any>,
	N = NavigateFunction
> = (
	type: RenderApiType<ParameterType>,
	params: ParamsType,
	frontCallback: (...args: any) => any,
	successCallback: (response?: any) => any,
	errorCallback: (response?: any) => any,
	dispatch?: U,
	navigate?: N
) => void;

export type ParameterType<T extends {} | null, U = Dispatch<any>, N = NavigateFunction> = (
	params: T,
	frontCallback: (...args: any) => any,
	successCallback: (response?: any) => any,
	errorCallback: (response?: any) => any,
	dispatch?: U,
	navigate?: N
) => void;

export type ParameterWithConfigType<
	T extends {} | null,
	U = Dispatch<AnyAction>,
	N = NavigateFunction,
	C = AxiosRequestConfig
> = (
	params: T,
	frontCallback: (...args: any) => any,
	successCallback: (response?: any) => any,
	errorCallback: (response?: any) => any,
	dispatch?: U,
	navigate?: N,
	config?: C
) => void;

export type RequestApiMapType<ParameterType extends `${string}Parameter`, ParamsType> = Record<
	RenderApiType<ParameterType>,
	(params: ParamsType) => Promise<AxiosResponse<any, any>>
>;

export type RequestApiType =
	| MovementApiType
	| EstApiType
	| PreopApiType
	| RouteCommonApiType
	| AparApiType;

export type RequestParamsType =
	| MovementParamsType
	| EstParamsType
	| PreopParamsType
	| RouteCommonParamsType
	| AparParamsType;

export const requestActionMap: RequestApiMapType<RequestApiType, RequestParamsType> = {
	...movementApiMap,
	...estApiMap,
	...preopApiMap,
	...routeCommonApiMap,
	...aparApiMap
};
