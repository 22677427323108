import { useRef, useState } from "react";
import { AreaCountrySelectProps } from "../type";
import { getAreaCodeListAction, getCountryCodeListAction } from "@/action/monitoring/portList";
import { FleetOptionType } from "@/types/advancedType";
import useReminder from "@/hook/useReminder";
import { useAppDispatch } from "@/hook";
import { useNavigate } from "react-router-dom";

const useIndex = (showKey: AreaCountrySelectProps["showKey"]) => {
	const { reminder } = useReminder();
	const dispatch = useAppDispatch();
	const navigate = useNavigate();
	const [loading, setLoading] = useState<boolean>(false);
	const pageNum = useRef<number>(1);
	const [searchValue, setSearchValue] = useState<string>("");
	const [codeOptions, setCodeOptions] = useState<FleetOptionType<string, number>[]>([]);

	const handleFocusPopup = () => {
		getCodeList();
	};
	const handleSearch = (e: string) => {
		setSearchValue(e);
		getCodeList();
	};
	const getCodeListSuccess = (res, type: "loadMore" | "" = "") => {
		setLoading(false);
		if (showKey == "area") {
			if (res?.data) {
				setCodeOptions(
					res.data.map((item) => {
						return { value: item?.areaCode, label: item?.areaName };
					})
				);
			}
		} else if (showKey == "country") {
			if (res?.data?.list) {
				if (type == "loadMore") {
					setCodeOptions((prev) => {
						const newData = res.data.list.map((item) => {
							return { value: item.countryCode, label: item.countryName };
						});
						return prev.concat(newData);
					});
				} else {
					setCodeOptions(
						res.data.list.map((item) => {
							return { value: item.countryCode, label: item.countryName };
						})
					);
				}
			}
		}
	};
	const getCodeListFront = () => {
		setLoading(true);
	};
	const getCodeListError = (error) => {
		setLoading(false);
		reminder("error", error?.msg ? error?.msg + ": " + error?.data : error?.data);
	};
	const getCodeList = (type: "loadMore" | "" = "") => {
		if (showKey == "area") {
			getAreaCodeListAction(
				{ keyword: searchValue },
				getCodeListFront,
				getCodeListSuccess,
				getCodeListError,
				dispatch,
				navigate
			);
		} else if (showKey == "country") {
			getCountryCodeListAction(
				{ keyword: searchValue, pageNum: pageNum.current, pageSize: 10 },
				getCodeListFront,
				(res) => getCodeListSuccess(res, type),
				getCodeListError,
				dispatch,
				navigate
			);
		}
	};
	const loadMore = () => {
		++pageNum.current, getCodeList("loadMore");
	};
	return {
		loading,
		codeOptions,
		handleFocusPopup,
		handleSearch,
		searchValue,
		setSearchValue,
		loadMore,
		getCodeList
	};
};
export default useIndex;
