import { type PageParams } from "@/api/estimation";
import React, { useState, useRef, useMemo } from "react";
import style from "@/components/Select/index.module.less";
import { FleetIcon } from "@/components";
import { useNavigate } from "react-router-dom";
import { useAppDispatch } from "@/hook";
import debounce from 'lodash/debounce';
import {
  queryCargoBookGroupAction,
  queryHeadFixtureGroupAction
} from "@/action/voyageManage/preOperation";
import { getCompanyGroupAction } from "@/action/dataBase/company";

export type NoSelectOption = {
  label: string;
  value: string;
}

const useSelect = (
  label: string,
  type: "headFixture" | "cargoBook" | "company",
  multiple?: boolean,
  onFinish?: (item: NoSelectOption | NoSelectOption[] | null, newItem: NoSelectOption, type: "add" | "del") => void
) => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch()
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [options, setOptions] = useState<{
    label: string;
    value: string;
  }[]>([]);
  const [selectList, setSelectList] = useState<NoSelectOption[]>([]);
  const [searchValue, setSearchVal] = useState("");
  const page = useRef<PageParams>({
    pageNum: 1,
    pageSize: 100
  });

  const selectValue = useMemo(() => {
    return selectList.map(item => item?.label)
  }, [selectList, multiple])

  const closePopup = (open: boolean) => {
    if (!open) {
      // setSearchVal("");
      page.current.pageNum = 1;
    }
    setOpen(open);
  }

  const focusPopup = (loadMore?: boolean, searchVal?: string) => {
    switch (type) {
      case "headFixture":
        queryHeadFixtureGroupAction(
          {
            keyword: searchVal || searchValue,
            pageNum: page.current?.pageNum,
            pageSize: page.current?.pageSize,
            notBindVoy: 1
          },
          queryPortGroupFront,
          queryPortGroupSuccess,
          queryPortGroupError,
          dispatch,
          navigate
        );
        break;
      case "cargoBook":
        queryCargoBookGroupAction(
          {
            keyword: searchVal || searchValue,
            pageNum: page.current?.pageNum,
            pageSize: page.current?.pageSize,
            notBindVoy: 1
          },
          queryPortGroupFront,
          queryPortGroupSuccess,
          queryPortGroupError,
          dispatch,
          navigate
        );
        break;
      case "company":
        getCompanyGroupAction(
          {
            keyword: searchVal || searchValue,
            pageNum: page.current?.pageNum,
            pageSize: page.current?.pageSize
          },
          queryPortGroupFront,
          queryPortGroupSuccess,
          queryPortGroupError,
          dispatch,
          navigate
        )
      default:
        break
    };
  }

  const itemSelect = (item: string) => {
    const index = selectList.findIndex((selectItem: NoSelectOption) => selectItem.value === item);
    const optionItem = options.find(optionItem => optionItem.value == item);;

    if (multiple) {
      if (index === -1) {
        setSelectList([...selectList, optionItem]);
        onFinish?.([...selectList, optionItem], optionItem, "add");
      } else {
        const _selectList = selectList;
        _selectList.splice(index, 1);
        setSelectList([..._selectList]);
        onFinish?.(_selectList, optionItem, "del");
      }
    } else {
      if (index === -1) {
        setSelectList([optionItem]);
        onFinish?.(optionItem, optionItem, "add");
      } else {
        setSelectList([]);
        onFinish?.(null, optionItem, "del")
      }
      setOpen(false);
    }
  }

  const queryPortGroupFront = () => {
    setLoading(true);
    // setOpen(true)
  }

  const queryPortGroupSuccess = (response) => {
    setLoading(false);
    setOptions((response?.data?.items ?? response?.data)?.map(item => {
      return {
        label: item?.[label],
        value: item?.id
      }
    }));
  }

  const queryPortGroupError = (error) => {
    setLoading(false)
  }

  // const loadMoreSuccess = (response) => {
  //   const data = response?.data?.items ?? [];
  //   console.log('new data', [...list, ...data])
  //   setList(prev => [...prev, ...data]);
  //   setLoading(false);
  // }


  const loadMore = () => {
    page.current.pageNum += 1;
    focusPopup(true)
  };



  const search = useMemo(() => {

    const loadOptions = (val: string) => {
      page.current.pageNum = 1;
      setOptions([]);
      focusPopup(false, val);
    }
    return debounce(loadOptions, 800)
  }, [focusPopup])


  const tagRender = (props) => {
    const tagItem = selectList.find(item => item.value == props.value)
    return <div className={style["fleet-select-tag"]}>
      <span>{tagItem.label}</span>
      <FleetIcon
        type="FleetCloseIcon"
        outerLayerClassname="rm-icon"
        onClick={() => {
          itemSelect(tagItem.label)
        }}
      />
    </div>
  }

  console.log("options",options)

  const initLable = (item: NoSelectOption | NoSelectOption[] = []) => {
    console.log("item",item)
    if (Array.isArray(item)) {
      setSelectList(item)
    } else {
      setSelectList([item])
    }
  }

  return {
    loading,
    options,
    open,
    selectValue,
    loadMore,
    closePopup,
    focusPopup,
    tagRender,
    itemSelect,
    initLable,
    search,
  }
}

export default useSelect;