import { useMemo, useRef, useState } from "react";
import { calcSeaValues, isBallast, isLaden, matchPortType } from "@/tools/port";
import { commonItemsCheck } from "@/tools/check";
import {
	EstPortRotationItemProps,
	EstPortRotationItemType,
	EstPortRotationProps,
	checkKeys,
	initialEstPortItem
} from "@/pages/estimation/components";
import useCalcDistance from "./useCalcDistance";
import { delEsPortAction } from "@/action/estimation";
import { useAppDispatch } from "@/hook";
import { useNavigate } from "react-router-dom";
import useReminder from "@/hook/useReminder";
import { EstPortBatchCommitEvent, EstPortConsumCommitEvent, calculateConsumValues } from "@/pages/estimation/module";

const useIndex = () => {
	const dispatch = useAppDispatch();
	const navigate = useNavigate();
	const { reminder } = useReminder();
	const delPortIds = useRef<number[]>([]);
	const [dataSource, setDataSource] = useState<EstPortRotationProps["items"]>([]);

	const portCategory = useMemo(() => {
		const ballastPortList = dataSource?.filter((portItem) => isBallast(portItem?.purpose));
		const ladenPortList = dataSource?.filter((portItem) => isLaden(portItem?.purpose));

		return {
			ballastPortList,
			ladenPortList
		};
	}, [dataSource]);

	const commit: EstPortRotationItemProps["onCommit"] = (currentIndex, item) => {
		setDataSource((prev) => {
			const currentItem = prev?.[currentIndex];
			prev?.splice(currentIndex, 1, {
				...currentItem,
				...item
			});
			return [...prev];
		});
	};

	const add = () => {
		const newPortItem = initialEstPortItem({ purpose: dataSource?.length === 0 ? "DL" : "DC" });
		setDataSource((prev) => {
			return [...prev, newPortItem];
		});
	};

	const change: EstPortRotationItemProps["onChange"] = (currentIndex, key, value) => {
		setDataSource((prev) => {
			const currentItem = prev?.[currentIndex];
			prev?.splice(currentIndex, 1, {
				...currentItem,
				[key]: value
			});
			return [...prev];
		});
	};

	const remove: EstPortRotationItemProps["onRemove"] = (currentIndex) => {
		setDataSource((prev) => {
			prev.splice(currentIndex, 1);
			return [...prev];
		});
	};

	const init = (items: EstPortRotationItemType[] = []) => {
		setDataSource(items);
		delPortIds.current = [];
	};

	const check = () => {
		return commonItemsCheck(dataSource, checkKeys);
	};

	const handleBatchCommit: EstPortBatchCommitEvent = (type, vesselItem) => {
		debugger;
		for (let currentIndex = 0; currentIndex < dataSource?.length; currentIndex++) {
			const currentItem = dataSource?.[currentIndex];
			if (matchPortType(currentItem?.purpose) !== type || currentItem?.purpose === "RD") continue;
			const seaParams = calcSeaValues(
				currentItem,
				vesselItem,
				"distance",
				currentItem?.distance
			);
			const ecaParams = calcSeaValues(
				currentItem,
				vesselItem,
				"ecaDistance",
				currentItem?.ecaDistance
			);
			commit(currentIndex, {
				...seaParams,
				...ecaParams
			});
		}
	};

	const handleBatchFoConsumCommit: EstPortConsumCommitEvent = (vesselItem) => {
		setDataSource(prev => {
			for (let i = 0; i <= prev?.length - 1; i++) {
				prev?.splice(i, 1, calculateConsumValues(prev?.[i], vesselItem))
			}
			return [...prev]
		})
	}

	const handleUpdatePort = (options: { distance: any; ecaDistance: any; indexList: number[] }) => {
		if (refresh !== -1) {
			const { distance, ecaDistance, indexList } = options;
			for (let arrIndex of indexList) {
				const params: Partial<
					Pick<EstPortRotationProps["items"][number], "distance" | "ecaDistance">
				> = {};
				switch (true) {
					case !!(distance[arrIndex] || distance[arrIndex] === 0):
						params.distance = +distance[arrIndex]?.toFixed(3);
						break;
					case !!(ecaDistance[arrIndex] || ecaDistance[arrIndex] === 0):
						params.ecaDistance = +ecaDistance[arrIndex]?.toFixed(3);
						break;
					default:
						break;
				}
				commit(arrIndex, params);
			}
		}
	};

	const {
		loading,
		refresh,
		handleChangeCalcDistance,
		handleDeleteCalcDistance,
		handleCalcDistanceInit
	} = useCalcDistance(handleUpdatePort);

	const delEsPortFailed = (error) => {
		reminder("error", error?.msg ? error?.msg + ": " + error?.data : error?.data);
	};

	const handlePortItemDelete: EstPortRotationItemProps["onRemove"] = (currentIndex) => {
		const newItems = [...dataSource];
		const currentItem = newItems?.[currentIndex];
		if (currentItem?.id) {
			delEsPortAction(
				{
					portId: currentItem?.id
				},
				null,
				(response) => {
					delPortIds?.current?.push(currentItem?.id);
					handleDeleteCalcDistance(currentIndex, newItems, () => {
						remove(currentIndex);
					});
				},
				delEsPortFailed,
				dispatch,
				navigate
			);
			return;
		}
		handleDeleteCalcDistance(currentIndex, newItems, () => {
			remove(currentIndex);
		});
	};

	const handlePortCommit: EstPortRotationItemProps["onPortCommit"] = (currentIndex, item) => {
		const newItems = [...dataSource];
		newItems?.splice(currentIndex, 1, {
			...newItems[currentIndex],
			...item
		});
		handleChangeCalcDistance(currentIndex, newItems, () => {
			commit(currentIndex, item);
		});
	};

	return {
		loading,
		delPortIds,
		dataSource,
		portCategory,
		change,
		check,
		commit,
		init,
		add,
		handlePortCommit,
		handleChangeCalcDistance,
		handleCalcDistanceInit,
		handlePortItemDelete,
		handleBatchCommit,
		handleBatchFoConsumCommit
	};
};

export default useIndex;
