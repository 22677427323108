import { queryVesselArchiveGroupAction } from "@/action/dataBase/vesselArchives";
import useCommonIndex from "../../../hooks/useIndex"
import { CommonVesselItemType } from "../type"
const useIndex = (
  activeItems: Partial<CommonVesselItemType> | (Partial<CommonVesselItemType>)[],
  showKey: keyof CommonVesselItemType,
  onFinish: (newItem: CommonVesselItemType, operateType: "add" | "del") => void,
  primaryKey: keyof CommonVesselItemType = "id",
  disabled: boolean
) => {
  const {
    loading,
    open,
    list,
    searchValue,
    selectValue,
    page,
    handleSearch,
    focusPopup,
    closePopup,
    loadMore,
    handleItemSelect,
    tagRender
  } = useCommonIndex<CommonVesselItemType, keyof CommonVesselItemType>(
    activeItems,
    showKey,
    onFinish,
    queryVesselArchiveGroupAction,
    primaryKey,
    disabled
  );

  return {
    loading,
    open,
    list,
    searchValue,
    selectValue,
    page,
    handleSearch,
    focusPopup,
    closePopup,
    loadMore,
    handleItemSelect,
    tagRender
  }
}

export default useIndex