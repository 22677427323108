import mapboxgl from "mapbox-gl";
import { MutableRefObject } from "react";
import { currentVesselLayers, currentVesselSource } from "../source";
import { addSourceToMap } from "../utils";

const useCreateVessel = (mapboxGl: MutableRefObject<mapboxgl.Map>) => {
	const loadCurrentVesselSource = () => {
		for (let [vesselTag, sourceName] of Object.entries(currentVesselSource)) {
			addSourceToMap(mapboxGl.current, {
				sourceName: sourceName,
				source: {
					type: "geojson",
					data: {
						type: "FeatureCollection",
						features: []
					}
				}
			});
		}
	};
	const loadCurrentVesselLayers = () => {
		for (let [vesselTag, layerName] of Object.entries(currentVesselLayers)) {
			mapboxGl.current.addLayer({
				id: layerName,
				type: "symbol",
				source: currentVesselSource[vesselTag],
				layout: {
					"icon-image":
						vesselTag === "ais"
							? ["concat", "routeAis", ["get", "color"], "VesselSelectedIcon"]
							: `${vesselTag}VesselSelectedIcon`,
					"icon-size": 0.6,
					"icon-rotate": ["get", "course"],
					"icon-allow-overlap": true
				}
			});
		}
	};

	return {
		loadCurrentVesselLayers,
		loadCurrentVesselSource
	};
};

export default useCreateVessel;
