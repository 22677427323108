import React, { useEffect } from "react";
import { PortType, portTypeMap, timeZones } from "../add/type";
import useAddPort from "@/hook/database/port/useAddPort";
import { FleetFormItem, FleetSelect, FleetTooltip, FleetVerifyInput } from "@/components";
import { CommonLatitudeSelect } from "@/common";
import DatabaseCommonEdit from "@/featrue/database/layout/common/edit";
import { DatabasePortEditProps } from "./type";
import Select from "@/components/_Select";

const DatabasePortEdit: React.FC<DatabasePortEditProps> = ({
	hide,
	portId,
	onClose,
	item,
	onReset
}) => {
	const { dataSource, open, position, setOpen, change, editPort, getPort, loading, latitudeClose } =
		useAddPort(onReset, portId);

	useEffect(() => {
		if (hide) return;
		getPort();
	}, [hide]);

	return (
		<DatabaseCommonEdit
			hide={hide}
			onClose={onClose}
			onEdit={editPort}
			title={item?.portName}
			loading={loading}
			sectionName="port"
		>
			<div className="commonEditItem">
				<span className="commonEditItem-label">Basis</span>
				<div className="commonEditItem-container">
					<FleetVerifyInput
						label="Port Name"
						needVerity
						value={dataSource?.portName}
						onChange={(val: string) => {
							change("portName", val);
						}}
					/>
					<FleetVerifyInput
						label="Chinese Name"
						value={dataSource?.portNameCn}
						onChange={(val: string) => {
							change("portNameCn", val);
						}}
					/>
					<FleetVerifyInput
						label="Alias"
						value={dataSource?.alias}
						onChange={(val: string) => {
							change("alias", val);
						}}
					/>
					<FleetFormItem label="Port Type">
						<FleetSelect
							options={portTypeMap}
							value={[dataSource?.portType]}
							onChange={(value: PortType) => {
								change("portType", value);
							}}
						/>
					</FleetFormItem>
					<FleetVerifyInput label="Port Code" needVerity disabled value={dataSource?.portCode} />
					<FleetFormItem label="Position">
						<Select
							value={position}
							open={open}
							onDropVisible={setOpen}
							render={<CommonLatitudeSelect onClose={latitudeClose} open={open} />}
						/>
					</FleetFormItem>
					<FleetVerifyInput
						label="Country"
						value={dataSource?.country}
						onChange={(val: string) => {
							change("country", val);
						}}
					/>
					<FleetVerifyInput
						label="Country Code"
						value={dataSource?.countryCode}
						onChange={(val: string) => {
							change("countryCode", val);
						}}
					/>
					<FleetFormItem label="Time Zone">
						<FleetSelect
							options={timeZones}
							value={[dataSource?.timeZone]}
							onChange={(value: number) => {
								change("timeZone", value);
							}}
						/>
					</FleetFormItem>
					<FleetVerifyInput
						type="number"
						label="Draft Limitation"
						needVerity
						value={dataSource?.draftLimitation}
						onChange={(val: number) => {
							change("draftLimitation", val);
						}}
					/>
					<FleetVerifyInput
						label="Area"
						value={dataSource?.area}
						onChange={(val: string) => {
							change("area", val);
						}}
					/>
				</div>
			</div>
		</DatabaseCommonEdit>
	);
};

export default DatabasePortEdit;
