import React from "react"
import { MovementCommonTableColumnsType } from "../../../charterBase/common/table/type"
import { MovementBunkerPortCostChangeEvent, MovementBunkerPortCostCommitEvent, MovementBunkerPortCostItemType, MovementBunkerPortCostSummaryType } from "./type"
import { FleetInput } from "@/components"
import { getAdjustDiff, getOilCost } from "./tools"

export enum MovementBunkerPortCostOilMap {
  "HSFO 3.5%S" = 1,
  "LSFO 0.5%S",
  "MDO 0.5%S",
  "MGO 0.1%S"
}

export const movementBunkerPortCostSummaryList: (keyof MovementBunkerPortCostSummaryType | "Summary" | "")[] = [
  "Summary",
  "",
  "atjCost",
  "",
  "bunkerSummaryPrice",
  "bunkerSummaryCost",
  "bunkerSummaryConsum",
  "totolCost"
]

export const columns: MovementCommonTableColumnsType<
  MovementBunkerPortCostItemType,
  keyof MovementBunkerPortCostItemType,
  {
    onChange: MovementBunkerPortCostChangeEvent,
    onCommit: MovementBunkerPortCostCommitEvent
  }
>[] = [
    {
      label: "",
      dataIndex: "oilType",
      width: 96.5,
      fixed: "left",
      className: "td-title",
      render: (value, record, index, actions) => {
        return <span>{MovementBunkerPortCostOilMap[record?.oilType]}</span>
      }
    },
    {
      label: "Quantity",
      dataIndex: "bodQuantity",
      width: 96.5,
      render: (value, record, index, actions) => {
        return <FleetInput
          className="td-input"
          value={value}
          type="number"
          onChange={(val: number) => {
            actions?.onCommit?.(index, {
              bodQuantity: val
            })
          }}
        />
      }
    },
    {
      label: "C/P Price",
      dataIndex: "bodPrice",
      width: 96.5,
      render: (value, record, index, actions, otherSource) => {
        return <FleetInput
          className="td-input"
          value={value}
          type="number"
          onChange={(val: number) => {
            const params: Partial<Pick<MovementBunkerPortCostItemType, "borPrice" | "bodPrice">> = {
              bodPrice: val
            }
            if (otherSource?.borPrice === "same") {
              params.borPrice = val
            }
            actions?.onCommit?.(index, params)
          }}
        />
      }
    },
    {
      label: "Quantity",
      dataIndex: "borQuantity",
      width: 96.5,
      render: (value, record, index, actions) => {
        return <FleetInput
          className="td-input"
          value={value}
          type="number"
          onChange={(val: number) => {
            actions?.onCommit?.(index, {
              borQuantity: val
            })
          }}
        />
      }
    },
    {
      label: "Price",
      dataIndex: "borPrice",
      width: 96.5,
      render: (value, record, index, actions, otherSource) => {
        console.log("otherSource", otherSource)
        return <FleetInput
          className="td-input"
          disabled={otherSource?.borPrice === "same"}
          value={value}
          type="number"
          onChange={(val: number) => {
            actions?.onCommit?.(index, {
              borPrice: val
            })
          }}
        />
      }
    },
    {
      label: "Qty Diff.",
      dataIndex: "id",
      width: 96.5,
      render: (value, record, index, actions) => {
        console.log("record", record)
        return <FleetInput
          className="td-input"
          value={+record?.bodQuantity - +record?.borQuantity}
          type="number"
          disabled
        />
      }
    },
    {
      label: "Adj Qty",
      dataIndex: "id",
      width: 96.5,
      render: (value, record, index, actions, otherSource) => {
        const { adjustQty } = getAdjustDiff(record, otherSource)
        return <FleetInput
          className="td-input"
          disabled
          value={adjustQty}
          type="number"
        />
      }
    },
    {
      label: "Price",
      dataIndex: "adjustedPrice",
      width: 96.5,
      render: (value, record, index, actions, otherSource) => {
        return <FleetInput
          className="td-input"
          value={value}
          type="number"
          onChange={(val: number) => {
            actions?.onCommit?.(index, {
              adjustedPrice: val
            })
          }}
        />
      }
    },
    {
      label: "Cost",
      dataIndex: "id",
      width: 96.5,
      render: (value, record, index, actions, otherSource) => {
        const { adjustCost } = getAdjustDiff(record, otherSource)
        return <FleetInput
          className="td-input"
          value={adjustCost}
          type="number"
          disabled
        />
      }
    },
    {
      label: "Quantity",
      dataIndex: "bunkerSummaryQuantity",
      width: 96.5,
      render: (value, record, index, actions) => {
        return <FleetInput
          className="td-input"
          value={value}
          type="number"
          onChange={(val: number) => {
            actions?.onCommit?.(index, {
              bunkerSummaryQuantity: val
            })
          }}
        />
      }
    },
    {
      label: "Price",
      dataIndex: "bunkerSummaryPrice",
      width: 96.5,
      render: (value, record, index, actions) => {
        return <FleetInput
          className="td-input"
          value={value}
          type="number"
          onChange={(val: number) => {
            actions?.onCommit?.(index, {
              bunkerSummaryPrice: val
            })
          }}
        />
      }
    },
    {
      label: "Cost",
      dataIndex: "id",
      width: 96.5,
      render: (value, record, index, actions) => {
        return <FleetInput
          className="td-input"
          value={+record?.bunkerSummaryPrice * +record?.bunkerSummaryQuantity}
          type="number"
          disabled
        />
      }
    },
    {
      label: "Consumpt",
      dataIndex: "id",
      width: 96.5,
      render: (value, record, index, actions) => {
        return <FleetInput
          className="td-input"
          value={+record?.bunkerSummaryQuantity + +record?.bodQuantity - +record?.borQuantity}
          type="number"
          disabled
        />
      }
    },
    {
      label: "Cost",
      dataIndex: "id",
      width: 96.5,
      render: (value, record, index, actions, otherSource) => {
        const totalCost = getOilCost(record, otherSource)
        return <FleetInput
          className="td-input"
          value={totalCost}
          type="number"
          disabled
        />
      }
    },
  ]