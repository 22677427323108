import { type ApiParameter } from "@/api";
import { appVersion } from "@/api/constant";
import request from "@/service";
import {
	saveDepartureReportParams,
	saveArrivalReportParams,
	saveShiftingReportParams,
	getOtherReportParams
} from "./type";

const enum ApiMap {
	getDepartureReportParameter,
	saveDepartureReportParameter,
	deleteDepartureReportParameter,
	getArrivalReportParameter,
	saveArrivalReportParameter,
	deleteArrivalReportParameter,
	getShiftingReportParameter,
	saveShiftingReportParameter,
	deleteShiftingReportParameter
}

type ApiUrl = keyof typeof ApiMap;

const movementOtherReportsApi: ApiParameter<ApiUrl> = {
	getDepartureReportParameter: {
		method: "get",
		url: appVersion + `/move/new-report/departure/`
	},
	saveDepartureReportParameter: {
		method: "post",
		url: appVersion + `/move/new-report/departure`
	},
	deleteDepartureReportParameter: {
		method: "delete",
		url: appVersion + `/move/new-report/departure`
	},
	getArrivalReportParameter: {
		method: "get",
		url: appVersion + `/move/new-report/arrival/`
	},
	saveArrivalReportParameter: {
		method: "post",
		url: appVersion + `/move/new-report/arrival`
	},
	deleteArrivalReportParameter: {
		method: "delete",
		url: appVersion + `/move/new-report/arrival`
	},
	getShiftingReportParameter: {
		method: "get",
		url: appVersion + `/move/new-report/shifting/`
	},
	saveShiftingReportParameter: {
		method: "post",
		url: appVersion + `/move/new-report/shifting`
	},
	deleteShiftingReportParameter: {
		method: "delete",
		url: appVersion + `/move/new-report/shifting`
	}
};

export const getDepartureReportApi = (params: getOtherReportParams) => {
	return request.request({
		...movementOtherReportsApi.getDepartureReportParameter,
		url: movementOtherReportsApi.getDepartureReportParameter.url + `${params.voyageId}`
	});
};

export const saveDepartureReportApi = (data: saveDepartureReportParams) => {
	return request.request({
		...movementOtherReportsApi.saveDepartureReportParameter,
		data
	});
};

export const deleteDepartureReportApi = (data: number[]) => {
	return request.request({
		...movementOtherReportsApi.deleteDepartureReportParameter,
		data
	});
};

export const getArrivalReportApi = (params: getOtherReportParams) => {
	return request.request({
		...movementOtherReportsApi.getArrivalReportParameter,
		url: movementOtherReportsApi.getArrivalReportParameter.url + `${params.voyageId}`
	});
};

export const saveArrivalReportApi = (data: saveArrivalReportParams) => {
	return request.request({
		...movementOtherReportsApi.saveArrivalReportParameter,
		data
	});
};

export const deleteArrivalReportApi = (data: number[]) => {
	return request.request({
		...movementOtherReportsApi.deleteArrivalReportParameter,
		data
	});
};

export const getShiftingReportApi = (params: getOtherReportParams) => {
	return request.request({
		...movementOtherReportsApi.getShiftingReportParameter,
		url: movementOtherReportsApi.getShiftingReportParameter.url + `${params.voyageId}`
	});
};

export const saveShiftingReportApi = (data: saveShiftingReportParams) => {
	return request.request({
		...movementOtherReportsApi.saveShiftingReportParameter,
		data
	});
};

export const deleteShiftingReportApi = (data: number[]) => {
	return request.request({
		...movementOtherReportsApi.deleteShiftingReportParameter,
		data
	});
};
