import React, { FC } from "react";
import style from "./index.module.less";
import { MovementBunkerPlanProps } from "./type";
import { CommonNewlyCompany, FleetPrompt } from "@/common";
import { FleetLoading } from "@/components";
import { MovementBunkerClause, MovementBunkerDetails, MovementBunkerPortCost } from "./components";
import useIndex from "./hooks/useIndex";
import { Button } from "@/_components";

const MovementBunkerPlan: FC<MovementBunkerPlanProps> = ({
	voyageId,
	onClose,
	onConfirm,
	hide
}) => {
	const {
		loading,
		hsfoDataSource,
		bunkerClause,
		bunkerDetails,
		bunkerPlan,
		handleBunkerPlanChange,
		handleBunkerClauseChange,
		handleBunkerClauseCommit,
		handleBunkerDetailsChange,
		handleBunkerDetailsCommit,
		bunkerCost,
		bunkerCostSummary,
		handleBunkerCostChange,
		handleBunkerCostCommit,
		handleBorPriceCommit,
		saveMovementBunkerCost,
		companyHide,
		setCompanyHide,
		handleCompanyClose
	} = useIndex(voyageId, hide, onConfirm, onClose);

	return (
		<FleetPrompt hide={hide} mode="fixed">
			<div className={style["movement-bunkerPlan"]}>
				<FleetLoading loading={loading} />
				<div className="prompt-header--container">
					<span className="header-title">Bunker Plan</span>
					<span className="font_family fleet-close" onClick={onClose}></span>
				</div>
				<div className="bunkerPlan">
					<div className="bunkerPlan-header">
						<MovementBunkerDetails
							items={bunkerDetails}
							onChange={handleBunkerDetailsChange}
							onCommit={handleBunkerDetailsCommit}
							onOpen={() => {
								setCompanyHide(false);
							}}
						/>
						<MovementBunkerClause
							item={bunkerClause}
							onChange={handleBunkerClauseChange}
							onCommit={handleBunkerClauseCommit}
							onBunkerBorPriceCommit={handleBorPriceCommit}
							otherItem={{
								scrubberBenefit: bunkerPlan?.scrubberBenefit,
								scrubberPrice: bunkerPlan?.scrubberPrice,
								scrubberTotalPrice: bunkerPlan?.scrubberTotalPrice,
								bodQuantity: hsfoDataSource?.bodQuantity,
								borQuantity: hsfoDataSource?.borQuantity,
								bunkerSummaryQuantity: hsfoDataSource?.bunkerSummaryQuantity
							}}
							onOtherItemChange={handleBunkerPlanChange}
						/>
					</div>
					<MovementBunkerPortCost
						items={bunkerCost}
						summaryItem={bunkerCostSummary}
						onChange={handleBunkerCostChange}
						onCommit={handleBunkerCostCommit}
						otherSource={{
							borAbout: bunkerClause?.borAbout,
							borPrice: bunkerClause?.borPrice
						}}
					/>
				</div>
				<div className="prompt-footer--container">
					<Button size="small" type="secondary" onClick={onClose}>
						Cancel
					</Button>
					<Button size="small" onClick={saveMovementBunkerCost}>
						Confirm
					</Button>
				</div>
				<CommonNewlyCompany
					type="add"
					hide={companyHide}
					onClose={handleCompanyClose}
					onConfirm={handleCompanyClose}
				/>
			</div>
		</FleetPrompt>
	);
};

export default MovementBunkerPlan;
