import React from "react";
import style from "./index.module.less";
import { Outlet } from "react-router-dom";

const Estimation: React.FC<{}> = (props) => {
	return (
		<div className={style["fleet-estimation"]}>
			<Outlet />
		</div>
	);
};

export default Estimation;
