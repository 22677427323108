import React, { FC, Fragment, useRef } from "react";
import style from "./index.module.less";
import { RouteAreaPresetProps } from "./type";
import { RouteAreaItem } from "../item/module";
import { Collapse } from "antd";
import { FleetCustomRadio } from "@/components/Radio";
import { Icon, Popover, Tooltip } from "@/_components";
import classnames from "classnames";

const RouteAreaPreset: FC<RouteAreaPresetProps> = ({ items, onSelect }) => {
	return (
		<div className={style["route-areaPreset"]}>
			<span className="areaPreset-header">All Preset</span>
			<div className="areaPreset-container">
				<div className="areaPreset-scrollArea">
					<Collapse
						size="small"
						expandIconPosition="end"
						className="areaPreset-item"
						expandIcon={(panelProps) => {
							return (
								<Icon
									type="fleet-back"
									className={classnames("areaPreset-item-icon", {
										active: panelProps?.isActive
									})}
								/>
							);
						}}
						bordered={false}
						items={items?.map((presetItem, presetIndex) => {
							const isGroupSelected = presetItem?.dataList?.some((item) => !item?.isCurrentSelect);
							return {
								key: presetItem?.groupName,
								label: (
									<Tooltip
										trigger="hover"
										className="areaPreset-group-popover-trigger"
										title={presetItem?.groupName}
										disabled
										children={
											<FleetCustomRadio
												className="areaPreset-group-header"
												value={!isGroupSelected}
												onChange={(checked) => {
													onSelect?.("multiple", presetItem?.groupName);
												}}
												label={presetItem?.groupName}
											/>
										}
									/>
								),
								children: (
									<Fragment>
										{presetItem?.dataList?.map((item, index) => {
											return (
												<RouteAreaItem
													key={item?.areaCode}
													mode="preset"
													item={item}
													onSelect={onSelect}
												/>
											);
										})}
									</Fragment>
								)
							};
						})}
					/>
				</div>
			</div>
		</div>
	);
};

export default RouteAreaPreset;
