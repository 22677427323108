import { PromptChildrenProps } from "@/common/Prompt";
import { ExcludeKeys, ExtendsKeys } from "@/types";

export type VesselType =
  "handysize" |
  "handymax" |
  "supermax" |
  "panamasize" |
  "capesize" |
  "vloc" |
  "vlcc" |
  "ulcc";

type VesselAddKeys =
  "vesselName" |
  "vesselNameCn" |
  "vesselNameEn" |
  "vesselTypeLv1" |
  "vesselTypeLv2" |
  "exName1" |
  "exName2" |
  "imo" |
  "mmsi" |
  "callsign" |
  "builder" |
  "buildCountry" |
  "buildDate" |
  "grt" |
  "nrt" |
  "loa" |
  "dwt" |
  "grain" |
  "bale" |
  "tpc" |
  "summerDraft" |
  "performance" |
  "id";


type VesselAddNumKeys = ExtendsKeys<VesselAddKeys,
  "bale" |
  "dwt" |
  "grain" |
  "grt" |
  "imo" |
  "loa" |
  "mmsi" |
  "nrt" |
  "summerDraft" |
  "tpc"
>;

type VesselAddStrKeys = ExtendsKeys<VesselAddKeys,
  "buildCountry" |
  "buildDate" |
  "builder" |
  "callsign" |
  "exName1" |
  "exName2" |
  "vesselName" |
  "vesselNameCn" |
  "vesselNameEn" |
  "vesselTypeLv1" |
  "vesselTypeLv2"
>;

type VesselAddIdKeys = ExtendsKeys<VesselAddKeys, "id">;

type VesselAddEunmKeys = ExtendsKeys<VesselAddKeys, "performance">;

type DatabaseVesselAddKeys = VesselAddNumKeys | VesselAddStrKeys | VesselAddIdKeys | VesselAddEunmKeys;


type VesselPerformanceKeys =
  "speed" |
  "hsFo" |
  "lsFo" |
  "mdo" |
  "mgo";


type VesselPerformanceNumKeys = ExtendsKeys<VesselPerformanceKeys, "speed" | "hsFo" | "lsFo" | "mdo" | "mgo">;

type VesselPerformanceFormType = Record<VesselPerformanceNumKeys, number | "">;

export type VesselPerformancePortFormType = Omit<VesselPerformanceFormType, "speed">;

export type DatabaseVesselPerformanceFormType = {
  work: VesselPerformancePortFormType;
  idle: VesselPerformancePortFormType;
  ballast: VesselPerformanceFormType[];
  laden: VesselPerformanceFormType[]
}

export type DatabaseVesselAddChangeEvent = <K extends ExcludeKeys<DatabaseVesselAddKeys, "performance">>(key: K, value: DatabaseVesselAddFormType[K]) => void;

export type DatabaseVesselPerformancePortChangeEvent = <K extends keyof VesselPerformanceFormType>(
  portKey: "ballast" | "laden",
  id: number,
  key: K,
  value: VesselPerformanceFormType[K]
) => void;

export type DatabaseVesselPerformanceWorkChangeEvent = <K extends keyof VesselPerformancePortFormType>(
  portKey: "work" | "idle",
  key: K,
  value: VesselPerformancePortFormType[K]
) => void;

export type DatabaseVesselAddCommitEvent = (item: Partial<Omit<DatabaseVesselAddFormType, "performance">>) => void;

export type DatabaseVesselAddFormType =
  Record<VesselAddStrKeys, string> &
  Record<VesselAddNumKeys, number | ""> &
  Partial<Record<VesselAddIdKeys, string | number>> & {
    performance: DatabaseVesselPerformanceFormType
  }

export type DatabaseVesselAddProps = {
  onReset?: () => void;
  portId?: string | number;
} & PromptChildrenProps;

export const initialDatabaseVesselForm: Omit<DatabaseVesselAddFormType, "performance"> = {
  buildCountry: "",
  buildDate: "",
  builder: "",
  callsign: "",
  exName1: "",
  exName2: "",
  vesselName: "",
  vesselNameCn: "",
  vesselNameEn: "",
  vesselTypeLv1: "",
  vesselTypeLv2: "",
  bale: "",
  dwt: "",
  grain: "",
  grt: "",
  imo: "",
  loa: "",
  mmsi: "",
  nrt: "",
  summerDraft: "",
  tpc: ""
}

export const vesselTypeMap: { label: Capitalize<VesselType>, value: VesselType }[] = [
  {
    label: "Handysize",
    value: "handysize"
  },
  {
    label: "Handymax",
    value: "handymax"
  },
  {
    label: "Supermax",
    value: "supermax"
  },
  {
    label: "Panamasize",
    value: "panamasize"
  },
  {
    label: "Capesize",
    value: "capesize"
  },
  {
    label: "Vloc",
    value: "vloc"
  },
  {
    label: "Vlcc",
    value: "vlcc"
  },
  {
    label: "Ulcc",
    value: "ulcc"
  }
]

const initPortForm = (initItem?: DatabaseVesselPerformanceFormType["ballast"]) => {
  const portList: DatabaseVesselPerformanceFormType["ballast"] = [];
  for (let i = 0; i < 3; i++) {
    portList.push({
      speed: initItem?.[i]?.speed ?? "",
      hsFo: initItem?.[i]?.hsFo ?? "",
      lsFo: initItem?.[i]?.lsFo ?? "",
      mdo: initItem?.[i]?.mdo ?? "",
      mgo: initItem?.[i]?.mgo ?? "",
    })
  }
  return portList
}

export const initialPerformanceForm = (initPortFormItems?: DatabaseVesselPerformanceFormType): DatabaseVesselPerformanceFormType => {
  return {
    work: {
      hsFo: initPortFormItems?.work?.hsFo ?? "",
      lsFo: initPortFormItems?.work?.lsFo ?? "",
      mdo: initPortFormItems?.work?.mdo ?? "",
      mgo: initPortFormItems?.work?.mgo ?? ""
    },
    idle: {
      hsFo: initPortFormItems?.idle?.hsFo ?? "",
      lsFo: initPortFormItems?.idle?.lsFo ?? "",
      mdo: initPortFormItems?.idle?.mdo ?? "",
      mgo: initPortFormItems?.idle?.mgo ?? ""
    },
    ballast: initPortForm(initPortFormItems?.ballast),
    laden: initPortForm(initPortFormItems?.laden)
  }
}

export const checkVesselKeys: DatabaseVesselAddKeys[] = [
  "vesselNameEn",
  "mmsi",
  "imo"
]