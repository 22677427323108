import { useAppDispatch } from "@/hook";
import useReminder from "@/hook/useReminder";
import { useEffect, useRef, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import {
	getMovementTaskListAction,
	updateMovementTaskItemAction
} from "@/action/voyageManage/movement";
import { MovementTaskListItemType, MovementTaskListProps } from "../..";
import {
	MovementDetailTaskListProps,
	MovementTaskItemType,
	initialMovementTaskItem
} from "../components";
import { getMovementTaskListParams } from "@/api/voyageManage/movement";
import { MovementTaskDetailsProps } from "../type";

const useIndex = (
	open: boolean,
	voyageId: string,
	currentItem: MovementTaskListItemType,
	activeVoyageType: MovementTaskDetailsProps["activeVoyageType"]
) => {
	const dispatch = useAppDispatch();
	const navigate = useNavigate();
	const { reminder } = useReminder();
	const [searchParmas] = useSearchParams();

	// const taskOpen = useRef(!!searchParmas?.get("task_open") || false)
	// const currentStage = searchParmas?.get(
	//   "stage"
	// ) as MovementTaskListProps["items"][number]["stage"];
	// const currentPortRotationId = searchParmas?.get("port_rotation_id") as unknown as number;
	// const currentTaskId = searchParmas?.get("task_id") as unknown as number;

	const [loading, setLoading] = useState(false);
	const [filterItem, setFilterItem] = useState<MovementDetailTaskListProps["filterItem"]>({
		isComplete: 2,
		level: 4
	});
	const [dataSource, setDataSource] = useState<MovementTaskItemType[]>([]);

	const [activeTaskItem, setActiveTaskItem] = useState<MovementTaskItemType>(null);

	const handleFilterChange: MovementDetailTaskListProps["onFilterChange"] = (key, value) => {
		if (filterItem[key] === value) return;
		setFilterItem((prev) => ({ ...prev, [key]: value }));
	};

	const handleChange: MovementDetailTaskListProps["onChange"] = (id, key, value) => {
		const currentTaskItem = dataSource?.find((item) => item.id === id);
		updateMovementTask({
			...currentTaskItem,
			[key]: value
		});
	};

	const handleSelect: MovementDetailTaskListProps["onSelect"] = (item) => {
		if (activeTaskItem?.id === item?.id) {
			setActiveTaskItem(null);
			return;
		}
		setActiveTaskItem(item);
	};

	const updateMovementTask = (item: MovementTaskItemType) => {
		updateMovementTaskItemAction(
			item,
			null,
			(response) => {
				setLoading(false);
				setDataSource((prev) => {
					const currentTaskIndex = prev?.findIndex((selectItem) => selectItem?.id === item.id);
					prev?.splice(currentTaskIndex, 1, { ...item });
					return [...prev];
				});
			},
			getMovementTaskListFailed,
			dispatch,
			navigate
		);
	};

	const getMovementTaskListFront = () => {
		setLoading(true);
	};

	const getMovementTaskListSuccess = (response) => {
		setDataSource(response?.data);
		setActiveTaskItem(null);
		setLoading(false);
	};

	const getMovementTaskListFailed = (error) => {
		reminder("error", error?.msg ? error?.msg + ": " + error?.data : error?.data);
		setLoading(false);
	};

	const getMovementTaskList = () => {
		const params: getMovementTaskListParams = {
			...filterItem,
			stage: currentItem?.stage,
			voyageId
		};
		if (activeVoyageType === "TCVC") {
			params.portRotationId = currentItem?.portRotationId;
		}
		getMovementTaskListAction(
			params,
			getMovementTaskListFront,
			getMovementTaskListSuccess,
			getMovementTaskListFailed,
			dispatch,
			navigate
		);
	};

	useEffect(() => {
		debugger;
		if (!open || !currentItem) {
			setActiveTaskItem(null);
			return;
		}
		getMovementTaskList();
	}, [open, currentItem, filterItem]);

	return {
		loading,
		dataSource,
		filterItem,
		activeTaskItem,
		handleFilterChange,
		handleChange,
		handleSelect
	};
};

export default useIndex;
