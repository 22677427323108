import { CommonInitItemEvent } from "@/types/event";
import { InvStatementHireItemType, InvStatementOffHireItemType } from "./type";
import { InvCommonFieldInitEvent } from "@/pages/voyagemanage/business/invoices/module";

export const initialStatementOffHireItem: InvCommonFieldInitEvent<InvStatementOffHireItemType> = (type, item) => {
  return {
    offHireStartDay: item?.offHireStartDay ?? null,
    offHireEndDay: item?.offHireEndDay ?? null,
    totalOffHire: item?.totalOffHire ?? 0,
    id: type === "details" && item?.id ? item?.id : null,
    invId: type === "details" && item?.invId ? item?.invId : null,
    period: item?.period ?? null
  }
}

export const initialStatementHireItem: InvCommonFieldInitEvent<InvStatementHireItemType> = (type, item) => {
  return {
    onHireEndDay: item?.onHireEndDay ?? null,
    onHireStartDay: item?.onHireStartDay ?? null,
    totalHire: item?.totalHire ?? 0,
    dailyHire: item?.dailyHire ?? "",
    ttlOnHireDay: item?.ttlOnHireDay ?? 0,
    ttlOffHireDay: item?.ttlOffHireDay ?? 0,
    addCommRate: item?.addCommRate ?? "",
    brokerageRate: item?.brokerageRate ?? "",
    brokerage: item?.brokerage ?? 0,
    addComm: item?.addComm ?? 0,
    offHireFoList: (item?.offHireFoList ?? [])?.map(offHireItem => initialStatementOffHireItem(type, offHireItem))
  }
}

export const checkKeys: (keyof InvStatementHireItemType)[] = [
  "onHireStartDay",
  "onHireEndDay",
  "dailyHire"
]