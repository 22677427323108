import React, { FC } from "react";
import { CommonRoleProps } from "./interface";
import { useAppSelector } from "@/hook";
import { UserStore } from "@/store/userSlice/state";

const CommonRoleWrap: FC<CommonRoleProps> = ({ children, roleStr, render }) => {
  const userInfo = useAppSelector<UserStore>((state) => state.UserStore);
  if (!userInfo?.permissions?.includes("*:*:*") && !userInfo?.permissions?.includes(roleStr)) return null

  return render ? render() : children;
};

export default CommonRoleWrap;
