import {
  type ParameterType
} from "@/api";
import { loginOut, loginOutAction } from "@/store/userSlice";

import {
  getCompanyDetailsApi,
  getCompanyGroupApi,
  deleteCompanyApi,
  updateCompanyApi
} from "@/api/dataBase/company/index"

import {
  getCompanyDetailParams,
  getCompanyGroupParmas,
  deleteComponyParams,
  updateCompanyPayload
} from "@/api/dataBase/company/type"

export const getCompanyGroupAction: ParameterType<getCompanyGroupParmas> = (
  params,
  frontCallback,
  successCallback,
  errorCallback,
  dispatch,
  navigate
) => {
  frontCallback?.();
  getCompanyGroupApi(params).then(response => {
    successCallback?.(response)
  }).catch(err => {
    errorCallback?.(err);
    if (err?.loginStatus === 'token faild') {
      dispatch(loginOutAction(() => navigate?.("/login")))
    }
  })
}

export const getCompanyDetailsAction: ParameterType<getCompanyDetailParams> = (
  params,
  frontCallback,
  successCallback,
  errorCallback,
  dispatch,
  navigate
) => {
  frontCallback?.();
  getCompanyDetailsApi(params).then(response => {
    successCallback?.(response)
  }).catch(err => {
    errorCallback?.(err);
    if (err?.loginStatus === 'token faild') {
      dispatch(loginOutAction(() => navigate?.("/login")))
    }
  })
}

export const deleteCompanyAction: ParameterType<deleteComponyParams> = (
  params,
  frontCallback,
  successCallback,
  errorCallback,
  dispatch,
  navigate
) => {
  frontCallback?.();
  deleteCompanyApi(params).then(response => {
    successCallback?.(response)
  }).catch(err => {
    errorCallback?.(err);
    if (err?.loginStatus === 'token faild') {
      dispatch(loginOutAction(() => navigate?.("/login")))
    }
  })
}

export const updateCompanyAction: ParameterType<updateCompanyPayload> = (
  params,
  frontCallback,
  successCallback,
  errorCallback,
  dispatch,
  navigate
) => {
  frontCallback?.();
  updateCompanyApi(params).then(response => {
    successCallback?.(response)
  }).catch(err => {
    errorCallback?.(err);
    if (err?.loginStatus === 'token faild') {
      dispatch(loginOutAction(() => navigate?.("/login")))
    }
  })
}