import { CommonInitItemEvent } from "@/types/event";
import { InvFreightOtherItemType } from "./type";

export const initialInvFreightOtherItem: CommonInitItemEvent<InvFreightOtherItemType> = (item) => {
	return {
		voyageId: item?.voyageId ?? null,
		id: item?.id ?? null,
		addCargo: item?.addCargo ?? false,
		addDesDem: item?.addDesDem ?? false,
		addOther: item?.addOther ?? false,
		addFreightIncome: item?.addFreightIncome ?? false,
		addPort: item?.addPort ?? false,
		companyName: item?.companyName ?? "",
		pastList: item?.pastList ?? [],
		status: item?.status ?? "Unsettled",
		settledTime: item?.settledTime ?? null,
		total: item?.total ?? 0,
		updateTime: item?.updateTime ?? null
	};
};
