import React, { useEffect, useMemo } from "react";
import style from "./index.module.less";
import { MovementSeaReportsProps } from "./type";
import { FleetCategory, FleetPrompt } from "@/common";
import { FleetUpload } from "@/components";
import useSeaReports from "./hook/useSeaReports";
import { Tabs } from "antd";
import SeaReportsTable from "./components/seaReportTable";
import { seaTableHeadersGroup } from "./components/seaReportTable/source";
import TextArea from "antd/es/input/TextArea";
import { Button, Popover } from "@/_components";
import { Upload } from "antd";
import Segment from "./components/segment";
const { Dragger } = Upload;
const MovementSeaReports: React.FC<MovementSeaReportsProps> = ({
	hide,
	onClose,
	onConfirm,
	voyageId,
	voyageNo
}) => {
	const {
		loading,
		active,
		setActive,
		tabList,
		seaReportTableData,
		handleChange,
		isOpen,
		setIsOpen,
		handleAdd,
		closeTextarea,
		isAdd,
		showTextArea,
		handledbClick,
		handleReset,
		checkGroup,
		headerChange,
		getMovementSeaReports,
		handleRow,
		messageText,
		setMessageText,
		analysisMovementSeaReports,
		downloadSeaReport,
		selectRow,
		updateToMovement,
		uploadStatus,
		onUpload,
		showDrop,
		setShowDrop
	} = useSeaReports(voyageId, voyageNo, onConfirm);

	useEffect(() => {
		if (hide) return;
		getMovementSeaReports();
	}, [hide, active]);

	const seaReportTableDataSum = useMemo(() => {
		return seaReportTableData.length || 0;
	}, [seaReportTableData]);
	const cantAdd = useMemo(() => {
		if (
			seaReportTableData.some((item) => {
				return !item?.uuid;
			})
		) {
			return true;
		} else {
			return false;
		}
	}, [seaReportTableData]);
	return (
		<FleetPrompt hide={hide} loading={loading} mode="fixed">
			<div className={style["movement-sea-reports"]}>
				{/* <FleetLoading loading={loading} /> */}
				<div className="sea-reports-header">
					<div className="prompt-header--container">
						<span className="header-title">Sea Reports</span>
						<span className="font_family fleet-close header-close" onClick={onClose}></span>
					</div>
					<Tabs
						defaultActiveKey="1"
						activeKey={active}
						items={tabList}
						onChange={(v) => setActive(v)}
					/>
					<div
						onDragEnter={() => {
							setShowDrop(true);
						}}
						onDragOver={() => {
							setShowDrop(true);
						}}
						onDragLeave={() => {
							setShowDrop(false);
						}}
						onDrop={() => {
							setShowDrop(false);
						}}
						className="sea-reports-uploadarea"
					>
						<Dragger
							multiple={false}
							customRequest={() => {}}
							showUploadList={false}
							beforeUpload={onUpload}
							className={showDrop ? "sea-reports-upload-drop" : "sea-reports-upload-drop-hide"}
						>
							<span className="font_family fleet-update1 sea-reports-upload-icon"></span>
						</Dragger>
					</div>
				</div>

				<div className="sea-reports-main">
					<div
						className={
							showTextArea
								? "sea-reports-container"
								: "sea-reports-container sea-reports-container-full"
						}
					>
						<div className="sea-reports-button">
							<div className="sea-reports-button-group">
								<span className="sea-reports-page">Total Rows: {seaReportTableDataSum}</span>
								<Popover
									overlayClassName={style["movement-seaReport-category"]}
									open={isOpen}
									onOpenChange={setIsOpen}
									buttonProps={{
										children: "Filter",
										size: "small"
									}}
									content={
										<div>
											<FleetCategory
												className="sea-table-category"
												options={seaTableHeadersGroup}
												checkGroup={checkGroup}
												onChange={headerChange}
												onReset={handleReset}
											/>
										</div>
									}
								></Popover>
							</div>
							<div className="sea-reports-button-group">
								<Button
									type="secondary"
									size="small"
									onClick={downloadSeaReport}
									prefixIcon="fleet-download"
								>
									Download
								</Button>
								<Segment id={voyageId} type={active} afterConfirm={getMovementSeaReports} />
								{/* <WarningPop
                  id={voyageId}
                  afterConfirm={getMovementSeaReports}
                /> */}
								<Button
									type="secondary"
									size="small"
									onClick={updateToMovement}
									prefixIcon="fleet-update"
								>
									Update Movement
								</Button>
								<Button
									type="secondary"
									size="small"
									onClick={handleAdd}
									prefixIcon="fleet-add-square"
									disabled={cantAdd}
								>
									Add
								</Button>
								<FleetUpload
									showFileList={false}
									onUpload={onUpload}
									uploadStatus={uploadStatus}
									buttonText="Analysis Excel"
									loading={loading}
								/>
							</div>
						</div>
						<SeaReportsTable
							tableData={seaReportTableData}
							onChange={handleChange}
							ondbClick={handledbClick}
							onHandleRow={handleRow}
							selectRow={selectRow}
							filterHeader={checkGroup}
						/>
					</div>
					{showTextArea ? (
						<div className="sea-reports-textarea">
							<div className="textarea-header">
								<span>{isAdd ? "Add Reports" : "Commence of Sea Passage"}</span>
								<span className="font_family fleet-close" onClick={closeTextarea}></span>
							</div>
							{isAdd ? (
								<div className="sea-reports-addarea">
									<TextArea
										value={messageText}
										autoSize={{ minRows: 28, maxRows: 28 }}
										placeholder="Paste the content of the Reports"
										onChange={(val) => {
											setMessageText(val.target.value);
										}}
									/>
									<Button type="primary" size="small" onClick={analysisMovementSeaReports}>
										<span style={{ textAlign: "center", width: "100%" }}>Identify</span>
									</Button>
								</div>
							) : (
								<div className="sea-reports-messageText">{messageText}</div>
							)}
						</div>
					) : (
						<></>
					)}
				</div>
			</div>
		</FleetPrompt>
	);
};

export default MovementSeaReports;
