import {
  type ParameterType
} from "@/api";
import { loginOut, loginOutAction } from "@/store/userSlice";

import {
  getPortDaDetailsApi,
  getPortDaGroupApi,
  deletePortDaApi,
  updatePortDaApi
} from "@/api/dataBase/portDa"

import {
  getPortDaDetailsParams,
  getPortDaGroupParams,
  deletePortDaParmas,
  updatePortDaPayload
} from "@/api/dataBase/portDa/type"

export const getPortDaDetailsAction: ParameterType<getPortDaDetailsParams> = (
  params,
  frontCallback,
  successCallback,
  errorCallback,
  dispatch,
  navigate
) => {
  frontCallback?.();
  getPortDaDetailsApi(params).then(response => {
    successCallback?.(response)
  }).catch(err => {
    errorCallback?.(err);
    if (err?.loginStatus === 'token faild') {
      dispatch(loginOutAction(() => navigate?.("/login")))
    }
  })
}

export const getPortDaGroupAction: ParameterType<getPortDaGroupParams> = (
  params,
  frontCallback,
  successCallback,
  errorCallback,
  dispatch,
  navigate
) => {
  frontCallback?.();
  getPortDaGroupApi(params).then(response => {
    successCallback?.(response)
  }).catch(err => {
    errorCallback?.(err);
    if (err?.loginStatus === 'token faild') {
      dispatch(loginOutAction(() => navigate?.("/login")))
    }
  })
}

export const deletePortDaAction: ParameterType<deletePortDaParmas> = (
  params,
  frontCallback,
  successCallback,
  errorCallback,
  dispatch,
  navigate
) => {
  frontCallback?.();
  deletePortDaApi(params).then(response => {
    successCallback?.(response)
  }).catch(err => {
    errorCallback?.(err);
    if (err?.loginStatus === 'token faild') {
      dispatch(loginOutAction(() => navigate?.("/login")))
    }
  })
}

export const updatePortDaAction: ParameterType<updatePortDaPayload> = (
  params,
  frontCallback,
  successCallback,
  errorCallback,
  dispatch,
  navigate
) => {
  frontCallback?.();
  updatePortDaApi(params).then(response => {
    successCallback?.(response)
  }).catch(err => {
    errorCallback?.(err);
    if (err?.loginStatus === 'token faild') {
      dispatch(loginOutAction(() => navigate?.("/login")))
    }
  })
}
