import { IconProps } from "@/_components/Icon/interface";
import { FleetOptionType } from "@/types/advancedType";
import { RouteCommonAisItemToolType } from "./type";

export const toolOptions: (FleetOptionType<
	RouteCommonAisItemToolType,
	RouteCommonAisItemToolType
> & {
	icon: IconProps["type"];
})[] = [
	// { label: "warning", value: "warning", icon: "fleet-warn1" },
	// { label: "line", value: "line", icon: "fleet-line_chart" },
	// { label: "routeing", value: "routeing", icon: "fleet-route1" },
	{ label: "delete", value: "delete", icon: "fleet-close" }
];
