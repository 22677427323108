import React, { useEffect, useMemo, useRef } from "react";
import { echarts } from ".";
import { EChartsType } from "echarts";
import { debounce } from "lodash";
import classNames from "classnames";
import style from "./index.module.less";
import { FleetChartProps } from "./type";

export default function FleetChart(props: FleetChartProps) {
	const chartRef = useRef<HTMLDivElement>(null);
	const chartInstance = useRef<EChartsType>(null);

	const draw = () => {
		if (chartInstance?.current) {
			chartInstance?.current?.setOption(props.option, { notMerge: true });
		}
	};

	const init = () => {
		if (!chartRef?.current) return;
		chartInstance.current = echarts.getInstanceByDom(chartRef?.current) as unknown as EChartsType;
		if (!chartInstance.current) {
			chartInstance.current = echarts.init(chartRef.current, props.theme, {
				renderer: "canvas"
			}) as unknown as EChartsType;

			draw();
		}
	};

	// 窗口自适应并开启过渡动画
	const resize = useMemo(() => {
		const loadOptions = () => {
			chartInstance.current?.resize({ animation: { duration: 300 } });
		};
		return debounce(loadOptions, 500);
	}, []);

	useEffect(() => {
		init();
		window.addEventListener("resize", resize);
		return () => {
			window.removeEventListener("resize", resize);
		};
	}, []);

	return (
		<div
			className={classNames(style["fleet-chart"], props?.className)}
			id="echart"
			ref={chartRef}
		></div>
	);
}
