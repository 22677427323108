import React, { FC } from "react";
import style from "./index.module.less";
import classnames from "classnames";
import { InvFreightCargoDetailsProps, InvFreightCargoItemType } from "./type";
import { InvoiceAddTo } from "@/pages/voyagemanage/business/invoices/module";
import { FleetDatePicker, FleetFormItem, FleetVerifyInput } from "@/components";
import { FleetTabbar } from "@/components/Tabs";
import dayjs from "dayjs";
import { Button } from "@/_components";

const InvFreightCargoDetails: FC<InvFreightCargoDetailsProps> = ({
	items,
	onChange,
	onAdd,
	onDelete,
	otherItem,
	onOtherItemChange,
	onSettleCommit
}) => {
	return (
		<div className={classnames("invoicesDetail-form", style["invFreight-cargoDetails"])}>
			<div className="invoicesForm-header">
				<InvoiceAddTo
					type="addCargo"
					value={otherItem?.addCargo}
					onChange={(key, value) => {
						onOtherItemChange?.("addCargo", value);
						if (value) {
							onSettleCommit?.({
								ttlCargoQuantity: 0
							});
							return;
						}
					}}
				>
					<span>Cargo Details</span>
				</InvoiceAddTo>
				<span>Cargo information</span>
			</div>
			{items?.map((cargoItem, index) => {
				return (
					<div className="invoicesForm-container invoicesForm-cargos" key={index}>
						<FleetVerifyInput
							value={cargoItem?.bolNo}
							label="B/L Details"
							onChange={(val: string) => {
								onChange?.(index, "bolNo", val);
							}}
						/>
						<FleetVerifyInput
							value={cargoItem?.cargoName}
							label="Cargo Name"
							needVerity
							onChange={(val: string) => {
								onChange?.(index, "cargoName", val);
							}}
						/>
						<div className="invoicesFormCargo-form quantity">
							<FleetVerifyInput
								value={cargoItem?.cargoQuantity}
								label="Cargo Quantity"
								type="number"
								needVerity
								onChange={(val: number) => {
									onChange?.(index, "cargoQuantity", val);
								}}
							/>
							<FleetTabbar
								list={["MT", "CBM"]}
								value={cargoItem?.cargoUnit}
								disabled
								onChange={(val: InvFreightCargoItemType["cargoUnit"]) => {
									onChange?.(index, "cargoUnit", val);
								}}
							/>
						</div>
						<div className="invoicesFormCargo-form">
							<FleetFormItem label="B/L Date" needVerity>
								<FleetDatePicker
									value={cargoItem?.bolDate ? dayjs(cargoItem?.bolDate) : null}
									onChange={(val: string) => {
										onChange?.(index, "bolDate", val);
									}}
									allowClear
								/>
							</FleetFormItem>
							{index !== 0 && (
								<span
									className="font_family fleet-minus"
									onClick={() => {
										onDelete?.(index);
									}}
								></span>
							)}
						</div>
					</div>
				);
			})}
			<Button prefixIcon="fleet-plus" size="small" type="secondary" onClick={onAdd}>
				Add Cargo
			</Button>
		</div>
	);
};

export default InvFreightCargoDetails;
