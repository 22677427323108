import { CBPaymentTermsFormType, CBPaymentTermsFormChangeEvent, CBPaymentTermsFormCommitEvent, checkCBPaymentTermsFormKeys } from "@/featrue/voyagemanage/cargoBook/freightPayterm/type";
import { initialPaymentTermsForm } from "@/featrue/voyagemanage/cargoBook/freightPayterm/type";
import { useState } from "react";

const usePayTermForm = (voyageId?:string | number) => {
  const [payTermForm, setPayTermForm] = useState<CBPaymentTermsFormType>({
    ...initialPaymentTermsForm
  });

  const change: CBPaymentTermsFormChangeEvent = (key, value) => {
    setPayTermForm(prev => {
      return {
        ...prev,
        [key]: value
      }
    })
  }

  const commit: CBPaymentTermsFormCommitEvent = (item) => {
    setPayTermForm(prev => {
      return {
        ...prev,
        ...item
      }
    })
  }

  const clear = () => {
    setPayTermForm({
      ...initialPaymentTermsForm
    })
  }

  const check = () => {
    let checkKey = "";
    let checked = true;
    for(let item of checkCBPaymentTermsFormKeys) {
      if(payTermForm[item] !== 0 && !payTermForm[item]) {
        checkKey = item;
        checked = false;
        break;
      }
    }

    return {
      checked,
      checkKey
    }
  }

  return {
    payTermForm,
    check,
    change,
    commit,
    clear
  }
}

export default usePayTermForm