import { ExtendsKeys } from "@/types";
import { EstPortRotationItemType } from "./components/item/type";
import { VesselParticularItemType } from "../../business/list/components/newEst/components/vesselParticular/type";
import { FleetNumType } from "@/types/common";
import { EstVesselParticularType } from "../../module";
import { matchPortType } from "@/tools/port";

type BatchEstPortRotationItemKeys = ExtendsKeys<
	keyof EstPortRotationItemType,
	| "crane"
	| "loadingDischargeRate"
	| "idleDays"
	| "workDays"
	| "portMarginDay"
	| "portMarginUnit"
	| "cargoQuantity"
>;

export const getTotalDays = (
	item: Pick<EstPortRotationItemType, "workDays" | "idleDays" | "portMarginDay" | "portMarginUnit">
) => {
	if (item?.portMarginUnit === "Day") {
		return +item.workDays + +item.idleDays + +item.portMarginDay;
	}
	return (+item?.idleDays + +item?.workDays) * (1 + +item?.portMarginDay / 100);
};

const getPortMargin = (
	item: Pick<EstPortRotationItemType, "workDays" | "idleDays" | "portMarginDay" | "portMarginUnit">
) => {
	if (item?.portMarginUnit === "%") {
		return +item.portMarginDay / 100;
	}
	const notMarginTotalDays = +item?.idleDays + +item?.workDays;
	return notMarginTotalDays <= 0 ? 0 : +item?.portMarginDay / notMarginTotalDays;
};

export const calculateValues = <K extends BatchEstPortRotationItemKeys>(
	item: EstPortRotationItemType,
	vesselItem: Pick<
		VesselParticularItemType,
		"portFuelWork" | "portFuelIdle" | "portFuelMdo" | "portWorkMdo" | "portIdleMdo"
	>,
	key: K,
	value: EstPortRotationItemType[K]
) => {
	const params: Partial<EstPortRotationItemType> = {
		[key]: value
	};
	let totaldays = getTotalDays({
		workDays: item?.workDays,
		idleDays: item?.idleDays,
		portMarginDay: item?.portMarginDay,
		portMarginUnit: item?.portMarginUnit
	}),
		portStayMargin = getPortMargin({
			workDays: item?.workDays,
			idleDays: item?.idleDays,
			portMarginDay: item?.portMarginDay,
			portMarginUnit: item?.portMarginUnit
		}),
		workFuel = !!item?.crane ? +vesselItem?.portFuelWork : +vesselItem?.portFuelIdle,
		idleDays = item?.idleDays,
		workDays = item?.workDays;
	switch (true) {
		case key === "portMarginUnit":
			totaldays = getTotalDays({
				workDays: item?.workDays,
				idleDays: item?.idleDays,
				portMarginDay: item?.portMarginDay,
				portMarginUnit: value as EstPortRotationItemType["portMarginUnit"]
			});
			portStayMargin = getPortMargin({
				workDays: item?.workDays,
				idleDays: item?.idleDays,
				portMarginDay: item?.portMarginDay,
				portMarginUnit: value as EstPortRotationItemType["portMarginUnit"]
			});
			break;
		case key === "crane":
			workFuel = !!value ? +vesselItem?.portFuelWork : +vesselItem?.portFuelIdle;
			break;
		case key === "idleDays":
			totaldays = getTotalDays({
				workDays: item?.workDays,
				idleDays: value as FleetNumType,
				portMarginDay: item?.portMarginDay,
				portMarginUnit: item?.portMarginUnit
			});
			portStayMargin = getPortMargin({
				workDays: item?.workDays,
				idleDays: value as FleetNumType,
				portMarginDay: item?.portMarginDay,
				portMarginUnit: item?.portMarginUnit
			});
			idleDays = value as FleetNumType;
			break;
		case key === "workDays":
			totaldays = getTotalDays({
				idleDays: item?.idleDays,
				workDays: value as FleetNumType,
				portMarginDay: item?.portMarginDay,
				portMarginUnit: item?.portMarginUnit
			});
			portStayMargin = getPortMargin({
				idleDays: item?.idleDays,
				workDays: value as FleetNumType,
				portMarginDay: item?.portMarginDay,
				portMarginUnit: item?.portMarginUnit
			});
			workDays = value as FleetNumType;
			params.loadingDischargeRate = +value === 0 ? 0 : +(+item?.cargoQuantity / +value)?.toFixed(3);
			break;
		case key === "cargoQuantity":
			workDays = item?.loadingDischargeRate
				? +(+(value as FleetNumType) / item?.loadingDischargeRate)?.toFixed(3)
				: 0;
			params.workDays = workDays;
			totaldays = getTotalDays({
				idleDays: item?.idleDays,
				workDays: workDays,
				portMarginDay: item?.portMarginDay,
				portMarginUnit: item?.portMarginUnit
			});
			portStayMargin = getPortMargin({
				idleDays: item?.idleDays,
				workDays: workDays,
				portMarginDay: item?.portMarginDay,
				portMarginUnit: item?.portMarginUnit
			});
			break;
		case key === "portMarginDay":
			totaldays = getTotalDays({
				workDays: item?.workDays,
				idleDays: item?.idleDays,
				portMarginDay: value as FleetNumType,
				portMarginUnit: item?.portMarginUnit
			});
			portStayMargin = getPortMargin({
				workDays: item?.workDays,
				idleDays: item?.idleDays,
				portMarginDay: value as FleetNumType,
				portMarginUnit: item?.portMarginUnit
			});
			console.log("portStayMargin", {
				portStayMargin,
				totaldays
			});
			break;
		case key === "loadingDischargeRate":
			workDays = +value === 0 ? 0 : +(+item?.cargoQuantity / +value)?.toFixed(2);
			totaldays = getTotalDays({
				workDays: workDays,
				idleDays: item?.idleDays,
				portMarginDay: item?.portMarginDay,
				portMarginUnit: item?.portMarginUnit
			});
			portStayMargin = getPortMargin({
				workDays: workDays,
				idleDays: item?.idleDays,
				portMarginDay: item?.portMarginDay,
				portMarginUnit: item?.portMarginUnit
			});
			params.workDays = workDays;
		default:
			break;
	}
	params.totalDays = +totaldays?.toFixed(2);
	params.conDo = +(
		+workDays * +vesselItem.portWorkMdo +
		+idleDays * +vesselItem.portIdleMdo
	)?.toFixed(2);
	params.conFo = +(
		(+workDays * workFuel + +idleDays * +vesselItem?.portFuelIdle) *
		(1 + +portStayMargin)
	)?.toFixed(2);
	console.log("params", {
		params,
		workDays,
		workFuel,
		idleDays,
		vesselItem,
		portStayMargin
	});
	return params;
};

export const calculateConsumValues = (
	item: EstPortRotationItemType,
	vesselItem: Pick<
		VesselParticularItemType,
		"portFuelWork" | "portFuelIdle" | "portWorkMdo" | "portIdleMdo"
	>
) => {
	const params: EstPortRotationItemType = {
		...item
	},
		workFuel = !!item?.crane ? +vesselItem?.portFuelWork : +vesselItem?.portFuelIdle,
		portStayMargin = getPortMargin({
			workDays: item?.workDays,
			idleDays: item?.idleDays,
			portMarginDay: item?.portMarginDay,
			portMarginUnit: item?.portMarginUnit
		});
	params.conDo = +(
		+item?.workDays * +vesselItem.portWorkMdo +
		+item?.idleDays * +vesselItem.portIdleMdo
	)?.toFixed(2);
	params.conFo = +(
		(+item?.workDays * workFuel + +item?.idleDays * +vesselItem?.portFuelIdle) *
		(1 + +portStayMargin)
	)?.toFixed(2);

	return params
};

export const calcSeaValues = <
	K extends ExtendsKeys<
		keyof EstPortRotationItemType,
		"distance" | "seaDays" | "ecaDistance" | "insideEcaDays"
	>
>(
	item: EstPortRotationItemType,
	vesselItem: Pick<EstVesselParticularType, "ballastSpeed" | "ladenSpeed">,
	key: K,
	value: EstPortRotationItemType[K]
) => {
	const params: Partial<
		Pick<EstPortRotationItemType, "distance" | "seaDays" | "ecaDistance" | "insideEcaDays">
	> = {
		[key]: value
	};
	const speed =
		matchPortType(item?.purpose) === "ballast" ? vesselItem?.ballastSpeed : vesselItem?.ladenSpeed;
	switch (key) {
		case "distance":
			params.seaDays = speed ? +value / speed / 24 : 0;
			break;
		case "ecaDistance":
			params.insideEcaDays = speed ? +value / speed / 24 : 0;
			break;
		case "insideEcaDays":
			params.ecaDistance = +speed * 24 * +value;
			break;
		case "seaDays":
			params.distance = +speed * 24 * +value;
			break;
		default:
			break;
	}

	return params;
};
