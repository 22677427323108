import React, { FC } from "react";
import { FleetSelect } from "@/components";
import { RouteCommonOtherSelectProps } from "./type";
import style from "./index.module.less";
import classnames from "classnames";
import useIndex from "./hooks/useIndex";
import { RouteCommonOtherSelectPopup } from "./components";

export default function RouteCommonOtherSelect<SelectItem>({
	onFinish,
	onClear,
	activeItems,
	showKey,
	showCount = 1,
	className,
	disabled,
	placeholder,
	primaryKey = "id",
	type
}: RouteCommonOtherSelectProps<SelectItem>) {
	const {
		open,
		loadMore,
		loading,
		list,
		tagRender,
		handleItemSelect,
		handleSearch,
		focusPopup,
		selectValue,
		closePopup
	} = useIndex(type, activeItems, showKey, onFinish, primaryKey, disabled);

	return (
		<FleetSelect
			value={selectValue as unknown as string | number}
			showSearch
			popupMatchSelectWidth={true}
			onDropdownVisibleChange={closePopup}
			mode={Array?.isArray(activeItems) ? "multiple" : undefined}
			open={open}
			allowClear
			placeholder={placeholder}
			popupClassName={style["routeCommon-commonVessel-select"]}
			onSearch={handleSearch}
			onFocus={() => focusPopup()}
			className={classnames(style["routeCommon-commonVessel-select"], className)}
			onClear={onClear}
			disabled={disabled}
			showCount={showCount}
			tagRender={tagRender}
			dropdownRender={() => (
				<RouteCommonOtherSelectPopup<SelectItem>
					loadMore={loadMore}
					showKey={showKey}
					type={type}
					list={list}
					loading={loading}
					onSelect={handleItemSelect}
				/>
			)}
		/>
	);
}
