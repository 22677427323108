import React, { FC } from "react";
import classnames from "classnames";
import style from './index.module.less';
import { PrefReportVoyageItemProps } from "./type";

const PrefReportVoyageItem: FC<PrefReportVoyageItemProps> = ({
  item,
  active,
  onSelect
}) => {
  return <div
    onClick={() => onSelect?.(item)}
    className={classnames(style["perfreport-voyageItem"], {
      active: active
    })}
  >
    <div className="voyageItem-header">
      <div className="voyageItem-vesselInfo">
        <span className="vesselInfo-vesselName">{item?.vesselName}</span>
        <span className="vesselInfo-status">{item?.vesselStatus === 'ballast' ? 'B' : 'L'}</span>
      </div>
      {item?.isDownload && <span className="is-downloaded">Downloaded</span>}
    </div>
    <div className="voyageItem-container">
      <span>Voy. NO. :{item?.voyageNo}</span>
      <span>PIC: {item?.pic}</span>
    </div>
  </div>
}

export default PrefReportVoyageItem