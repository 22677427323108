import React, { ReactNode, useCallback, useState } from "react";
import classnames from "classnames";
import style from "./index.module.less";
import { FleetButton, FleetIcon } from "@/components";
import { EstimationQuicklockType } from "@/hook/estimation/details/useEstimation";
import { toThousands } from "@/tools";
import { formatThousandthNumber } from "@/tools/amount";
import { useNavigate } from "react-router-dom";
import { Button } from "@/_components";
import { CommonRoleWrap } from "@/common";

const matchSuffix = (
	type: "tce" | "bunkerCost" | "ttlDistance" | "seaDays" | "portDays",
	reverse: boolean
) => {
	switch (type) {
		case "tce":
			return "$";
		case "bunkerCost":
			if (reverse) return "MT";
			return "$";
		case "ttlDistance":
			return "nm";
		case "seaDays":
			return "days";
		case "portDays":
			if (reverse) return "$";
			return "days";
		default:
			break;
	}
};

export enum QuicklockItemlabelMap {
	"tce" = "totalCost",
	"bunkerCost" = "totalIfo",
	"ttlDistance" = "ecaDistance",
	"seaDays" = "ecaDays",
	"portDays" = "portCost",
	"totalCost" = "tce",
	"totalIfo" = "bunkerCost",
	"ecaDistance" = "ttlDistance",
	"ecaDays" = "seaDays",
	"portCost" = "portDays"
}

export enum QuicklockItemValueMap {
	"revenues" = "operationCost",
	"profit" = "hireCost",
	"operationCost" = "revenues",
	"hireCost" = "profit",
	"ifo" = "ballastIfo",
	"ballastIfo" = "ifo",
	"mdo" = "ladenIfo",
	"ladenIfo" = "mdo",
	"ballast" = "ballast",
	"laden" = "laden",
	"ballastDays" = "ballastDays",
	"ladenDays" = "ladenDays",
	"totalLadenPortDays" = "ladenPortCost",
	"totalBallastPortDays" = "ballastPortCost",
	"ladenPortCost" = "totalLadenPortDays",
	"ballastPortCost" = "totalBallastPortDays"
}

export type QuicklockItemlabelKeys = keyof typeof QuicklockItemlabelMap;

export type QuicklockItemValueKeys = keyof typeof QuicklockItemValueMap;

type QuicklockItemCategoty<
	T extends QuicklockItemlabelKeys,
	U extends QuicklockItemValueKeys
> = Record<T, U[]>;

// export const quicklockItemCategoty: QuicklockItemCategoty<QuicklockItemlabelKeys, QuicklockItemValueKeys> = {
//   "TCE": ["Revenues", "Profit"],
//   "Bunker Cost": ["IFO", "MDO"],
//   "Total Distance": ["Ballast", "Laden"],
//   "Sea Days": ["Ballast Days", "Laden Days"],
//   "Port Days": ["LP TTL Port Day", "DP TTL Port Day"],
//   "Total Cost": ["Operation Cost", "Hire Cost"],
//   "Total IFO Con.": ["Ballast IFO", "Laden IFO"],
//   "Inside ECA Dis.": ["Ballast", "Laden"],
//   "Inside ECA Days": ["Ballast Days", "Laden Days"],
//   "Port Cost": ["LP Cost", "DP Cost"]
// }

enum QuickItemUnitMap {
	"TCE" = "USD",
	"Bunker Cost" = "USD",
	"Total Distance" = "nm",
	"Sea Days" = "days",
	"Port Days" = "days",
	"Total Cost" = "USD",
	"Total IFO Con." = "MT",
	"Inside ECA Dis." = "nm",
	"Inside ECA Days" = "days",
	"Port Cost" = "USD"
}

export type QuicklockItemProps = {};

const QuicklockItem: React.FC<{
	onOpen?: React.MouseEventHandler<HTMLDivElement>;
	labelGroup?: [[string, string, string], [string, string, string]];
	itemGroup?: [[number, number, number], [number, number, number]];
	type: "tce" | "bunkerCost" | "ttlDistance" | "seaDays" | "portDays";
}> = ({ onOpen, labelGroup, itemGroup, type }) => {
	const [reverse, setReverse] = useState(false);
	const handleReverse = () => {
		setReverse(!reverse);
	};
	return (
		<div onClick={handleReverse} className={style["estimation-quicklockItem-container"]}>
			<div
				onClick={() => {
					setReverse(true);
				}}
				className={classnames(style["estimation-quicklockItem"], {
					[style["quicklockItem-normal"]]: reverse,
					[style["quicklockItem-normal--reverse"]]: !reverse
				})}
			>
				<div className="quicklockItem">
					<div className="header-left">
						<span>{labelGroup?.[0][0]}</span>
						{!reverse && type === "tce" && (
							<FleetIcon
								onClick={onOpen}
								type="FleetExtendsIcon"
								outerLayerClassname="header-extends"
							/>
						)}
					</div>
					<span className="header-right">
						<span>{toThousands(itemGroup?.[0][0])}</span>
						<span>{matchSuffix(type, false)}</span>
					</span>
				</div>
				<div className="quicklockItem">
					<span className="content-text">{labelGroup?.[0][1]}</span>
					<div className="quicklockItem-content">
						<span>{toThousands(itemGroup?.[0][1])}</span>
						<span>{matchSuffix(type, false)}</span>
					</div>
				</div>
				<div className="quicklockItem">
					<span className="content-text">{labelGroup?.[0][2]}</span>
					<div className="quicklockItem-content">
						<span>{toThousands(itemGroup?.[0][2])} </span>
						<span>{matchSuffix(type, false)}</span>
					</div>
				</div>
			</div>
			<div
				onClick={() => {
					setReverse(false);
				}}
				className={classnames(style["estimation-quicklockItem"], {
					[style["quicklockItem-flip"]]: reverse,
					[style["quicklockItem-flip--reverse"]]: !reverse
				})}
			>
				<div className="quicklockItem">
					<div className="header-left">
						<span>{labelGroup?.[1][0]}</span>
						{!reverse && type === "tce" && (
							<FleetIcon
								onClick={() => {}}
								type="FleetExtendsIcon"
								outerLayerClassname="header-extends"
							/>
						)}
					</div>
					<span className="header-right">
						<span>{toThousands(itemGroup?.[1][0])}</span>
						<span>{matchSuffix(type, true)}</span>
					</span>
				</div>
				<div className="quicklockItem">
					<span className="content-text">{labelGroup?.[1][1]}</span>
					<div className="quicklockItem-content">
						<span>{toThousands(itemGroup?.[1][1])}</span>
						<span>{matchSuffix(type, true)}</span>
					</div>
				</div>
				<div className="quicklockItem">
					<span className="content-text">{labelGroup?.[1][2]}</span>
					<div className="quicklockItem-content">
						<span>{toThousands(itemGroup?.[1][2])} </span>
						<span>{matchSuffix(type, true)}</span>
					</div>
				</div>
			</div>
		</div>
	);
};

const Quicklock: React.FC<{
	status: string;
	quicklockForm?: EstimationQuicklockType;
	onSave?: () => void;
	onOpenFrt?: () => void;
	onOpenPm?: () => void;
	onSendVm?: () => void;
}> = ({ status, quicklockForm, onSave, onOpenFrt, onSendVm, onOpenPm }) => {
	console.log("sta", quicklockForm);
	const navigate = useNavigate();
	return (
		<div className={style["estimation-quicklock"]}>
			<div className="qiucklock-header">
				<div className="header-title">
					<span className="details-title">Quick Look</span>
					<div className="quicklock-duration">
						<span>Total Duration:</span>
						<span>
							{formatThousandthNumber(+quicklockForm?.totalPortDays + +quicklockForm?.totalSeaDays)}{" "}
							days
						</span>
					</div>
				</div>
				<div className="header-actions">
					<Button
						type="icon"
						onClick={() => {
							navigate("/layout/estimation");
						}}
					>
						<span className="font_family fleet-back"></span>
					</Button>
					<Button size="small" type="primary" onClick={onOpenPm}>
						Performance Insight
						<FleetIcon
							type="FleetBottomPointerIcon"
							outerLayerClassname="performance-insight-icon"
						/>
					</Button>
					<Button type="secondary" size="small" prefixIcon="fleet-save" onClick={onSave}>
						Save
					</Button>
					<CommonRoleWrap roleStr="est:detail:sendtoVM">
						<Button onClick={onSendVm} disabled={status !== "EST"} prefixIcon="fleet-send">
							Send to VM
						</Button>
					</CommonRoleWrap>
				</div>
			</div>
			<div className="quicklock-container">
				<QuicklockItem
					type="tce"
					labelGroup={[
						["TCE", "Revenuse", "Profit"],
						["Total Cost", "Operation Cost", "Hire Cost"]
					]}
					itemGroup={[
						[quicklockForm.tce, quicklockForm.totalRevenue, quicklockForm.totalProfit],
						[quicklockForm.totalCost, quicklockForm.operationCost, quicklockForm.hireCost]
					]}
					onOpen={(e) => {
						e.stopPropagation();
						onOpenFrt?.();
					}}
				/>
				<QuicklockItem
					type="bunkerCost"
					labelGroup={[
						["Bunker Cost", "IFO", "MDO"],
						["Total IFO Con.", "Ballast IFO", "Laden IFO"]
					]}
					itemGroup={[
						[quicklockForm.bunkerCost, quicklockForm.ifo, quicklockForm.mdo],
						[quicklockForm.totalIfo, quicklockForm.ballastIfo, quicklockForm.ladenIfo]
					]}
				/>
				<QuicklockItem
					type="ttlDistance"
					labelGroup={[
						["Total Distance", "Ballast", "Laden"],
						["Inside ECA Dis.", "Ballast", "Laden"]
					]}
					itemGroup={[
						[
							quicklockForm.adjustFactorttlDistanceBallast +
								quicklockForm.adjustFactorttlDistanceLaden,
							quicklockForm.adjustFactorttlDistanceBallast,
							quicklockForm.adjustFactorttlDistanceLaden
						],
						[
							quicklockForm.adjustFactorecaDistanceBallast +
								quicklockForm.adjustFactorecaDistanceLaden,
							quicklockForm.adjustFactorecaDistanceBallast,
							quicklockForm.adjustFactorecaDistanceLaden
						]
					]}
				/>
				<QuicklockItem
					type="seaDays"
					labelGroup={[
						["Sea Days", "Ballast Days", "Laden Days"],
						["Inside ECA Days", "Ballast Days", "Laden Days"]
					]}
					itemGroup={[
						[quicklockForm.totalSeaDays, quicklockForm.ballastSeaDays, quicklockForm.ladenSeaDays],
						[quicklockForm.totalEcaDays, quicklockForm.ballastEcaDays, quicklockForm.ladenEcaDays]
					]}
				/>
				<QuicklockItem
					type="portDays"
					labelGroup={[
						["Port Days", "LP TTL Port Day", "DP TTL Port Day"],
						["Port Cost", "LP Cost", "DP Cost"]
					]}
					itemGroup={[
						[
							quicklockForm.totalPortDays,
							quicklockForm.ballastPortTotalDays,
							quicklockForm.ladenPortTotalDays
						],
						[
							quicklockForm.postExpenses,
							quicklockForm.ballastPortExpenses,
							quicklockForm.loadPortExpenses
						]
					]}
				/>
			</div>
		</div>
	);
};

export default Quicklock;
