import { ColumnsType } from "antd/es/table";
import { RoutePlanWayPointItemType } from "./type";
import React from "react";
import { dateToUtcString } from "@/tools/date";
import { formatThousandthNumber } from "@/tools/amount";

export const columns: ColumnsType<RoutePlanWayPointItemType> = [
	{
		dataIndex: "no",
		width: 49,
		title: "No.",
		key: "no",
		fixed: "left",
		align: "center",
		render: (value, record, index) => {
			return <span>{index + 1}</span>;
		}
	},
	{
		dataIndex: "waypointName",
		width: 89,
		title: "WP Name",
		key: "waypointName",
		fixed: "left",
		align: "center",
		ellipsis: true
	},
	{
		dataIndex: "navType",
		width: 90,
		title: "Nav. Type",
		key: "navType",
		align: "center",
		fixed: "left",
		ellipsis: true
	},
	{
		dataIndex: "lon/lat",
		width: 201,
		title: "Lat/Lon",
		key: "lon/lat",
		align: "center",
		fixed: "left",
		ellipsis: true,
		render: (value, record, index) => {
			return <span>{`${record?.latStr},${record?.lonStr}`}</span>;
		}
	},
	{
		dataIndex: "dateTime",
		width: 155,
		title: "Time (UTC)",
		key: "dateTime",
		align: "center",
		ellipsis: true,
		render: (value, record, index) => {
			return <span>{value ? dateToUtcString(value).slice(0, -3) : null}</span>;
		}
	},
	{
		dataIndex: "speed",
		width: 94,
		title: "SPD (kts)",
		key: "speed",
		align: "center",
		ellipsis: true,
		render(value, record, index) {
			return <span>{formatThousandthNumber(value)}</span>;
		}
	},
	{
		dataIndex: "distance",
		width: 156,
		title: "Dist (nm)",
		key: "distance",
		align: "center",
		ellipsis: true,
		render: (value, record, index) => {
			return <span>{formatThousandthNumber(value)}</span>;
		}
	},
	{
		dataIndex: "remainingDistance",
		width: 156,
		title: "Dist To Go (nm)",
		key: "remainingDistance",
		align: "center",
		ellipsis: true,
		render: (value, record, index) => {
			return <span>{formatThousandthNumber(value)}</span>;
		}
	},
	{
		dataIndex: "stoppage",
		width: 109,
		title: "Stoppage (hrs)",
		key: "stoppage",
		align: "center",
		ellipsis: true,
		render: (value, record, index) => {
			return <span>{formatThousandthNumber(value)}</span>;
		}
	},
	{
		dataIndex: "heading",
		width: 68,
		title: "Course",
		key: "heading",
		align: "center",
		ellipsis: true,
		render: (value, record, index) => {
			return <span>{formatThousandthNumber(value, 0, 0)}</span>;
		}
	},
	{
		dataIndex: "duration",
		width: 111,
		title: "Duration (hrs)",
		key: "duration",
		align: "center",
		ellipsis: true,
		render: (value, record, index) => {
			return <span>{formatThousandthNumber(value)}</span>;
		}
	},
	{
		dataIndex: "remainingDuration",
		width: 168,
		title: "Remaining Duration (hrs)",
		key: "remainingDuration",
		align: "center",
		ellipsis: true,
		render: (value, record, index) => {
			return <span>{formatThousandthNumber(value)}</span>;
		}
	},
	{
		dataIndex: "windSpeed",
		width: 121,
		title: "Wind SPD (kts)",
		key: "windSpeed",
		align: "center",
		ellipsis: true,
		render: (value, record, index) => {
			return <span>{formatThousandthNumber(value)}</span>;
		}
	},
	{
		dataIndex: "windDir",
		width: 109,
		title: "Wind DIR (° )",
		key: "windDir",
		align: "center",
		ellipsis: true,
		render: (value, record, index) => {
			return <span>{formatThousandthNumber(value, 0, 0)}</span>;
		}
	},
	{
		dataIndex: "sigwaveHeight",
		width: 121,
		title: "Sig. Wave HT (m)",
		key: "sigwaveHeight",
		align: "center",
		ellipsis: true,
		render: (value, record, index) => {
			return <span>{formatThousandthNumber(value)}</span>;
		}
	},
	{
		dataIndex: "swellHeight",
		width: 100,
		title: "Swell HT (m)",
		key: "swellHeight",
		align: "center",
		ellipsis: true,
		render: (value, record, index) => {
			return <span>{formatThousandthNumber(value)}</span>;
		}
	},
	{
		dataIndex: "swellDir",
		width: 102,
		title: "Swell DIR (° )",
		key: "swellDir",
		align: "center",
		ellipsis: true,
		render: (value, record, index) => {
			return <span>{formatThousandthNumber(value, 0, 0)}</span>;
		}
	},
	{
		dataIndex: "cf",
		width: 102,
		title: "CF (kts)",
		key: "cf",
		align: "center",
		ellipsis: true,
		render: (value, record, index) => {
			return <span>{formatThousandthNumber(value)}</span>;
		}
	}
];
