import React, { forwardRef, useImperativeHandle } from "react";
import style from "../../index.module.less";
import {
	HandoverFormBasicInfo,
	HandoverFormBunkerAndPerformance,
	HandoverFormHeadFixture
} from "@/pages/voyagemanage/business/preOp&movement/components";
import classnames from "classnames";
import { tctcHandoverIdMap } from "./source";
import useIndex from "./hooks/useIndex";
import { CommonNewlyContact } from "@/common";
import { VoyageManageHandoverFormProps, VoyageManageHandoverFormCommonRefType } from "../../type";
import { FleetLoading } from "@/components";

const VoyageManageTctcHandoverForm = forwardRef<
	VoyageManageHandoverFormCommonRefType,
	VoyageManageHandoverFormProps
>(({ className, voyageId }, ref) => {
	const {
		loading,
		basicInfo,
		handleBasicInfoChange,
		handleBasicInfoCommit,
		rentHeadFixture,
		handleRentHeadFixtureChange,
		handleRentHeadFixtureCommit,
		rentBunkerClause,
		handleRentBunkerClauseChange,
		handleRentBunkerClauseCommit,
		rentPerformance,
		handleRentPerformanceChange,
		handleRentPerformanceCommit,
		reletHeadFixture,
		handleReletHeadFixtureChange,
		handleReletHeadFixtureCommit,
		reletBunkerClause,
		handleReletBunkerClauseChange,
		handleReletBunkerClauseCommit,
		reletPerformance,
		handleReletPerformanceChange,
		handleReletPerformanceCommit,
		otherItem,
		handleOtherChange,
		handleOtherCommit,
		activeIndex,
		containerRef,
		currentIndex,
		handleScrollIntoView,
		// handleScroll,
		handleCombine,
		handleReset,
		handleCheck,
		contactOption,
		handleContactChange,
		handleContactAdd
	} = useIndex(voyageId);

	console.log("baseinfo", basicInfo);

	useImperativeHandle(ref, () => ({
		combineParams: handleCombine,
		reset: handleReset,
		check: handleCheck
	}));

	return (
		<div className={classnames("fleet-nonScrolBar", style["voyageManage-handoverForm"], className)}>
			<FleetLoading loading={loading} />
			<div className="handoverForm-container" ref={containerRef}>
				<HandoverFormBasicInfo
					currentId="handoverForm-tctc--basicInfo"
					item={basicInfo}
					otherItem={otherItem}
					onChange={handleBasicInfoChange}
					onCommit={handleBasicInfoCommit}
					onOtherChange={handleOtherChange}
				/>
				<HandoverFormHeadFixture
					voyageType="TCTC"
					currentId="handoverForm-tctc-rent"
					item={rentHeadFixture}
					onChange={handleRentHeadFixtureChange}
					onCommit={handleRentHeadFixtureCommit}
					voyageId={voyageId}
					onContactOpen={handleContactChange}
				/>
				<HandoverFormBunkerAndPerformance
					voyageType="TCTC"
					currentId="handoverForm-tctc-rent--bunkerAndperformance"
					onBunkerPlanChange={handleOtherChange}
					bunkerClauseItem={rentBunkerClause}
					onBunkerClauseChange={handleRentBunkerClauseChange}
					onBunkerClauseCommit={handleRentBunkerClauseCommit}
					performanceItem={rentPerformance}
					onPerformanceChange={handleRentPerformanceChange}
					onPerformanceCommit={handleRentPerformanceCommit}
					onContactOpen={handleContactChange}
				/>
				<HandoverFormHeadFixture
					voyageType="TCTC"
					currentId="handoverForm-tctc-relet"
					item={reletHeadFixture}
					onChange={handleReletHeadFixtureChange}
					onCommit={handleReletHeadFixtureCommit}
					voyageId={voyageId}
					onContactOpen={handleContactChange}
				/>
				<HandoverFormBunkerAndPerformance
					voyageType="TCTC"
					currentId="handoverForm-tctc-relet--bunkerAndperformance"
					onBunkerPlanChange={handleOtherChange}
					bunkerClauseItem={reletBunkerClause}
					onBunkerClauseChange={handleReletBunkerClauseChange}
					onBunkerClauseCommit={handleReletBunkerClauseCommit}
					performanceItem={reletPerformance}
					onPerformanceChange={handleReletPerformanceChange}
					onPerformanceCommit={handleReletPerformanceCommit}
					onContactOpen={handleContactChange}
				/>
			</div>
			<div className="handoverForm-anchor">
				<div className="anchor-scrollBar--scrollArea">
					<span
						className="anchor-scrollBar"
						style={{
							transform: `translateY(${currentIndex * 28}px)`
						}}
					></span>
				</div>
				<ul className="anchor-list">
					{tctcHandoverIdMap?.map((item, index) => {
						return (
							<li
								key={item?.value}
								onClick={(e) => {
									handleScrollIntoView?.(e, item?.value, index);
								}}
								className={classnames({
									active: activeIndex === item?.value
								})}
							>
								{item?.label}
							</li>
						);
					})}
				</ul>
			</div>
			<CommonNewlyContact
				hide={contactOption?.hide}
				loading={contactOption?.loading}
				onClose={() => {
					handleContactChange?.({ hide: true, type: null });
				}}
				onConfirm={handleContactAdd}
				contanctParams={{ businessType: contactOption?.type }}
			/>
		</div>
	);
});

export default VoyageManageTctcHandoverForm;
