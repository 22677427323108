import { _useCommonSelectIndex } from "@/common/Select";
import { RouteCommonGroupSelectItemType, RouteCommonGroupSelectProps } from "../type";
import { useState } from "react";
import { initialGroupItem } from "../source";
import { onRequest } from "@/action";
import { useAppDispatch } from "@/hook";
import { useNavigate } from "react-router-dom";
import useReminder from "@/hook/useReminder";
import { RouteCommonGroupAddProps } from "@/pages/routeCommon/common";

const useIndex = (onConfirm: RouteCommonGroupSelectProps["onConfirm"]) => {
	const dispatch = useAppDispatch();
	const navigate = useNavigate();
	const { reminder } = useReminder();
	const [activeItem, setActiveItem] = useState<RouteCommonGroupSelectItemType>(initialGroupItem());

	const {
		loading,
		open,
		list,
		searchValue,
		selectValue,
		page,
		handleSearch,
		focusPopup,
		closePopup,
		loadMore,
		handleItemSelect,
		tagRender
	} = _useCommonSelectIndex<RouteCommonGroupSelectItemType, keyof RouteCommonGroupSelectItemType>(
		"getRouteCommonAisGroupApi",
		activeItem,
		"groupName",
		setActiveItem,
		"id",
		false
	);

	console.log("activeItem", activeItem);

	const handleConfirm = () => {
		if (!activeItem?.id) return;
		onConfirm?.(activeItem);
	};

	const handleGroupAddFailed = (error) => {
		reminder("error", error?.msg ? error?.msg + ": " + error?.data : error?.data);
	};

	const handleGroupAddSuccess = (response) => {
		setActiveItem(initialGroupItem(response?.data));
	};

	const handleGroupAdd: RouteCommonGroupAddProps["onConfirm"] = (mode, groupItem) => {
		onRequest(
			"updateRouteCommonAisGroupApi",
			{
				id: groupItem?.groupId,
				groupName: groupItem?.groupName,
				color: groupItem?.color
			},
			null,
			handleGroupAddSuccess,
			handleGroupAddFailed,
			dispatch,
			navigate
		);
	};

	return {
		activeItem,
		loading,
		open,
		list,
		searchValue,
		selectValue,
		page,
		handleSearch,
		focusPopup,
		closePopup,
		loadMore,
		handleItemSelect,
		tagRender,
		handleConfirm,
		handleGroupAdd
	};
};

export default useIndex;
