import { CBCargoInfoFormChangeEvent, CBCargoInfoFormCommitEvent, CBCargoInfoFormType, checkCBCargoInfoKeys, initialCargoInfoForm } from "@/featrue/voyagemanage/cargoBook/cargoInfo/type";
import { useState } from "react";

const useCargoInfoForm = () => {
  const [cargoInfoForm, setCargoInfoForm] = useState<CBCargoInfoFormType>({
    ...initialCargoInfoForm
  });

  const change: CBCargoInfoFormChangeEvent = (key, value) => {
    setCargoInfoForm(prev => {
      return {
        ...prev,
        [key]: value
      }
    })
  }

  const commit: CBCargoInfoFormCommitEvent = (item) => {
    setCargoInfoForm(prev => {
      return {
        ...prev,
        ...item
      }
    })
  }

  const clear = () => {
    setCargoInfoForm({
      ...initialCargoInfoForm
    })
  }

  const check = () => {
    let checkKey = "";
    let checked = true;
    for(let item of checkCBCargoInfoKeys) {
      if(cargoInfoForm[item] !== 0 && !cargoInfoForm[item]) {
        checkKey = item;
        checked = false;
        break;
      }
    }

    return {
      checked,
      checkKey
    }
  }

  return {
    cargoInfoForm,
    check,
    change,
    commit,
    clear
  }
}

export default useCargoInfoForm