import React from "react";
import classnames from "classnames";
import style from "./index.module.less";
import { useIndex } from "./hooks";
import { InvoicesVoyageList, InvoicesViews } from "./components";

const VoyagemanageInvoices: React.FC<{}> = (props) => {
	const {
		activeVoyageItem,
		handleVoyageSelect,
		activeInvType,
		handleInvTypeSelect,
		newly,
		handleNewlyCommit
	} = useIndex();

	return (
		<div className={classnames(style["voyagemanage-invoices"], "voyagemanage-common")}>
			<InvoicesVoyageList activeVoyageItem={activeVoyageItem} onVoyageSelect={handleVoyageSelect} />
			<InvoicesViews
				activeVoyageItem={activeVoyageItem}
				activeInvType={activeInvType}
				onInvTypeSelect={handleInvTypeSelect}
				newly={newly}
				onNewlyCommit={handleNewlyCommit}
			/>
		</div>
	);
};

export default VoyagemanageInvoices;
