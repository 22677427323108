import { useCallback, useState } from "react";

export type CheckGroupChangeEvent<K, T extends unknown[]> = (key: K, val: T) => void;

const useCheckGroup = <
  InitGroupType,
  CategoryKeys extends string,
  GroupColumnKeys
>(initGroup: InitGroupType) => {
  const [checkGroup, setCheckGroup] = useState<InitGroupType>({ ...initGroup });

  const reset = useCallback(() => {
    setCheckGroup(initGroup)
  }, []);

  const change = (key: CategoryKeys, val: GroupColumnKeys[]) => {
    setCheckGroup({
      ...checkGroup,
      [key]: val
    })
  }

  return {
    checkGroup,
    reset,
    change
  }
}

export default useCheckGroup