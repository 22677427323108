import { ApiParameter } from "@/api";
import request from "@/service";

import { downloadMovementPnlParams, initMovementDetailsParams } from "./type";
import { appVersion } from "@/api/constant";

const enum ApiMap {
	downloadMovementPnlParameter,
	initMovementDetailsParameter
}

type ApiUrl = keyof typeof ApiMap;

const pnlApi: ApiParameter<ApiUrl> = {
	downloadMovementPnlParameter: {
		method: "post",
		url: appVersion + "/excel/download/pnl"
	},
	initMovementDetailsParameter: {
		method: "post",
		url: appVersion + "/move/voyage/actual/balance"
	}
};

export const downloadMovementPnlApi = (params: downloadMovementPnlParams) => {
	return request.request({
		...pnlApi.downloadMovementPnlParameter,
		data: params,
		responseType: "blob"
	});
};

export const initMovementDetailsApi = (params: initMovementDetailsParams) => {
	return request.request({
		...pnlApi.initMovementDetailsParameter,
		data: params
	});
};
