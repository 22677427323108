import React, { FC } from "react";
import style from "./index.module.less";
import { CommonOtherIncomeProps } from "./type";
import { FleetPrompt } from "@/common";
import { columns } from "./source";
import useIndex from "./hooks/useIndex";
import { Button } from "@/_components";
import MovementTable from "@/pages/voyagemanage/business/movement/details/components/charterBase/common/table";

const CommonOtherIncome: FC<CommonOtherIncomeProps> = ({
	type,
	hide,
	onClose,
	voyageId,
	onConfirm,
	voyageType
}) => {
	const { loading, dataSource, summary, change, remove, commit, add, saveOtherIncome } = useIndex(
		hide,
		voyageId,
		voyageType,
		type,
		onConfirm,
		onClose
	);
	return (
		<FleetPrompt hide={hide} mode="fixed" loading={loading}>
			<div className={style["common-otherIncome"]}>
				<div className="prompt-header--container">
					<span className="header-title">Other Income</span>
					<span className="font_family fleet-close" onClick={onClose}></span>
				</div>
				<MovementTable
					bordered
					className="otherIncome-container"
					columns={columns}
					dataSource={dataSource}
					summary={summary}
					actions={{
						onChange: change,
						onDelete: remove,
						onCommit: commit
					}}
				/>
				<Button type="secondary" onClick={add} size="small">
					Add Income
				</Button>
				<div className="prompt-footer--container">
					<Button size="small" type="secondary" onClick={onClose}>
						Cancel
					</Button>
					<Button size="small" onClick={saveOtherIncome}>
						Confirm
					</Button>
				</div>
			</div>
		</FleetPrompt>
	);
};

export default CommonOtherIncome;
