import { useState } from "react"
import { BunkerClauseChangeEvent, BunkerClauseCommitEvent, BunkerClauseType } from "../type"
import { initialBunkerClause } from "../source"

const useIndex = () => {
  const [dataSourse, setDataSource] = useState<BunkerClauseType>({ ...initialBunkerClause() });

  const change: BunkerClauseChangeEvent = (key, value) => {
    setDataSource(prev => ({ ...prev, [key]: value }))
  }

  const commit: BunkerClauseCommitEvent = (item) => {
    setDataSource(prev => ({ ...prev, ...item }))
  }

  const init: BunkerClauseCommitEvent = (item) => {
    commit(initialBunkerClause(item))
  }

  return {
    dataSourse,
    change,
    commit,
    init
  }
}

export default useIndex