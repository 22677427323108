const useVesselGlLayer = () => {

  const addVesselTrackPointLineLayer = (mapboxgl: mapboxgl.Map, layerId: string, sourceId: string, before?: string) => {
    console.log("mapboxgl", mapboxgl)
    mapboxgl.addLayer({
      // id: "vesselTrackPointLineLayer",
      // source: "vesselTrackPointsLineSource",
      id: layerId,
      source: sourceId,
      type: "line",
      layout: {
        'line-join': 'round',
        'line-cap': 'round'
      },
      paint: {
        'line-color': 'transparent',
        'line-width': 2
      }
    }, before)
  }

  const addVesselTrackPointsLayer = (mapboxgl: mapboxgl.Map, layerId: string, sourceId: string, layerIconName: string, before?: string) => {
    mapboxgl.addLayer({
      // id: "vesselTrackPointsLayer",
      // source: "vesselTrackPointsSource",
      id: layerId,
      source: sourceId,
      type: "symbol",
      layout: {
        // 'symbol-placement': 'line',
        'symbol-spacing': 100, // 图标间隔，默认为250
        // 'icon-image': 'vessel-point-icon', //
        'icon-image': layerIconName,
        'icon-size': 1,
        // 'icon-rotate': -90
      }
    }, before)
  }

  const addVesselTrackPointLabelLayer = (mapboxgl: mapboxgl.Map, layerId: string, sourceId: string, layerIconName: string, before?: string) => {
    mapboxgl.addLayer({
      // id: "vesselTrackPointLabelLayer",
      type: "symbol",
      // source: "vesselTrackPointLabelSource",
      id: layerId,
      source: sourceId,
      layout: {
        // 'symbol-placement': 'line',
        'symbol-spacing': 100, // 图标间隔，默认为250
        // 'icon-image': 'vessel-bg-icon', //箭头图标
        'icon-image': layerIconName,
        'icon-size': 1,
        "text-field": ['concat', ['get', 'time'], 'Z ', ['get', 'speed'], ' knots'],
        'icon-offset': [100, 0],
        'text-offset': [10, 0],
        "text-max-width": 1000,  // 设置一个足够大的宽度，确保文本不会换行
        // 文字大小
        "text-size": 10,
      }
    }, before)
  }

  return {
    addVesselTrackPointsLayer,
    addVesselTrackPointLabelLayer,
    addVesselTrackPointLineLayer
  }
}

export default useVesselGlLayer