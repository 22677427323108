import { useEffect, useMemo, useRef, useState } from "react";
import {
	CommonOtherIncomeChangeEvent,
	CommonOtherIncomeCommitEvent,
	CommontOtherIncomeDeleteEvent,
	CommonOtherIncomeItemType,
	CommonOtherIncomeProps
} from "../type";
import { initialOtherIncomeItem } from "../source";
import { useAppDispatch } from "@/hook";
import { useNavigate } from "react-router-dom";
import useReminder from "@/hook/useReminder";
import { onRequest } from "@/action";

const useIndex = (
	hide: boolean,
	voyageId: CommonOtherIncomeProps["voyageId"],
	voyageType: CommonOtherIncomeProps["voyageType"],
	type: CommonOtherIncomeProps["type"],
	onConfirm: CommonOtherIncomeProps["onConfirm"],
	onClose?: () => void
) => {
	const dispatch = useAppDispatch();
	const navigate = useNavigate();
	const { reminder } = useReminder();
	const [loading, setLoading] = useState(false);
	const delIds = useRef<number[]>([]);
	const [dataSource, setDataSource] = useState<CommonOtherIncomeItemType[]>([]);

	const summary = useMemo(() => {
		return dataSource?.reduce(
			(prev, curr) => {
				return {
					itemName: "Total",
					itemValue: +prev.itemValue + +curr.itemValue
				};
			},
			{
				itemName: "",
				itemValue: 0
			}
		);
	}, [dataSource]);

	const change: CommonOtherIncomeChangeEvent = (currentIndex, key, value) => {
		setDataSource((prev) => {
			const currentItem = prev?.[currentIndex];
			prev?.splice(currentIndex, 1, {
				...currentItem,
				[key]: value
			});
			return [...prev];
		});
	};

	const commit: CommonOtherIncomeCommitEvent = (currentIndex, item) => {
		setDataSource((prev) => {
			const currentItem = prev?.[currentIndex];
			prev?.splice(currentIndex, 1, {
				...currentItem,
				...item
			});
			return [...prev];
		});
	};

	const remove: CommontOtherIncomeDeleteEvent = (currentIndex) => {
		setDataSource((prev) => {
			const currentItem = prev?.[currentIndex];
			if (currentItem?.id) {
				delIds.current.push(currentItem.id);
			}
			prev.splice(currentIndex, 1);
			return [...prev];
		});
	};

	const add = () => {
		setDataSource((prev) => {
			return [...prev, initialOtherIncomeItem(voyageId)];
		});
	};

	const getOtherIncomeFront = () => {
		setLoading(true);
	};

	const getOtherIncomeSuccess = (response) => {
		setLoading(false);
		setDataSource(response?.data);
	};

	const getOtherIncomeFailed = (error) => {
		setLoading(false);
		reminder("error", error?.msg ? error?.msg + ": " + error?.data : error?.data);
	};

	const saveOtherIncomeSuccess = (response) => {
		setLoading(false);
		onConfirm?.(voyageType, summary?.itemValue);
		onClose?.();
	};

	const getOtherIncome = () => {
		onRequest(
			type === "movement" ? "getMovementOtherIncomeApi" : "getPreopOtherIncomeApi",
			{ id: voyageId },
			getOtherIncomeFront,
			getOtherIncomeSuccess,
			getOtherIncomeFailed,
			dispatch,
			navigate
		);
	};

	const saveOtherIncome = () => {
		onRequest(
			type === "movement" ? "saveMovementOtherIncomeApi" : "savePreopOtherIncomeApi",
			{
				deleteIds: delIds?.current,
				incomes: dataSource
			},
			getOtherIncomeFront,
			saveOtherIncomeSuccess,
			getOtherIncomeFailed,
			dispatch,
			navigate
		);
	};

	useEffect(() => {
		if (hide) {
			delIds.current = [];
			return;
		}
		getOtherIncome();
	}, [hide]);

	return {
		loading,
		dataSource,
		summary,
		change,
		remove,
		commit,
		add,
		saveOtherIncome
	};
};

export default useIndex;
