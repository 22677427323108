import React, { useEffect } from "react";
import { Outlet } from "react-router-dom";
import LayoutHeader from "./header";
import style from "./index.module.less";
import { useAppDispatch } from "@/hook";
import { setFirstSelected } from "@/store/globalSlice";

const Layout: React.FC<{}> = (props) => {
	const dispatch = useAppDispatch();
	const handleBeforeunload = (event: BeforeUnloadEvent) => {
		// Cancel the event as stated by the standard.
		event.preventDefault();
		// Chrome requires returnValue to be set.
		event.returnValue = "";
	};

	const init = () => {
		if (process.env.NODE_ENV === "development") return;
		window.addEventListener("beforeunload", handleBeforeunload);
		return () => {
			window.removeEventListener("beforeunload", handleBeforeunload);
		};
	};

	useEffect(() => {
		return init();
	}, []);

	useEffect(() => {
		dispatch(setFirstSelected(location?.pathname));
	}, [location?.pathname]);
	return (
		<div className={style["fleet-layout"]}>
			<LayoutHeader />
			<Outlet />
		</div>
	);
};

export default Layout;
