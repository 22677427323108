import { useEffect, useState } from "react";
import usePayTermForm from "../cargoBook/usePayTermForm";
import useBasicForm from "./useBasicForm"
import useBunkerClauseForm from "./useBunkerClauseForm";
import useHireAndFeesForm from "./useHireAndFeesForm";
import usePerformanceForm from "./usePerformanceForm";
import usePayTermsForm from "./usePayTermsForm";
import useReminder from "@/hook/useReminder";
import { editHeadFixtureParams } from "@/api/voyageManage/headFixture";
import { initialPerformanceForm } from "@/featrue/voyagemanage/headFixture/performance/type";
import { useTranslation } from "react-i18next";

const useAddHeadFixture = (
  
) => {
  const [loading,setLoading] = useState(false);
  const { reminder } = useReminder();
  const { t } = useTranslation()
  const {
    basicForm,
    change: handleBasicFormChange,
    commit: handleBasicFormCommit,
    clear: handleBasicFormClear,
    check:handleBasicFormCheck
  } = useBasicForm();

  const {
    hireAndFeesForm,
    change: handleHireAndFeesFormChange,
    commit: handleHireAndFeesFormCommit,
    clear: handleHireAndFeesFormClear,
    check:handleHireAndFeesFormCheck
  } = useHireAndFeesForm();

  const {
    bunkerClauseForm,
    change: handleBunkerClauseFormChange,
    commit: handleBunkerClauseFormCommit,
    clear: handleBunkerClauseFormClear,
    check:handleBunkerClauseFormCheck
  } = useBunkerClauseForm();

  const {
    payTermForm,
    change: handlePayTermFormChange,
    commit: handlePayTermFormCommit,
    clear: handlePayTermFormClear,
    check:handlePayTermFormCheck
  } = usePayTermsForm();

  const {
    performanceForm,
    portChange: handlePortChange,
    workChange: handleWorkChange,
    change: handlePerformanceFormChange,
    clear: handlePortClear,
    commit:handlePortCommit,
  } = usePerformanceForm();

  const clear = () => {
    handleBasicFormClear();
    handleHireAndFeesFormClear();
    handleBunkerClauseFormClear();
    handlePayTermFormClear();
    handlePortClear();
  }

  const check = () => {
    const { checkKey: basicCheckKey, checked: basicChecked } = handleBasicFormCheck();
    if (!basicChecked) {
      reminder("error", `Field ${t(`voyageManagement.headFixtrueList.${basicCheckKey}`)} is required`);
      return false;
    }

    const { checkKey: payTermCheckKey, checked: payTermChecked } = handlePayTermFormCheck();
    if (!payTermChecked) {
      reminder("error", `Field ${payTermCheckKey} is required`);
      return false;
    }

    const { checkKey: hireAndFeesCheckKey, checked: hireAndFeesChecked } = handleHireAndFeesFormCheck();
    if (!hireAndFeesChecked) {
      reminder("error", `Field ${t(`voyageManagement.headFixtrueList.${hireAndFeesCheckKey}`)} is required`);
      return false;
    }

    const { checkKey: bunkerClauseCheckKey, checked: bunkerClauseChecked } = handleBunkerClauseFormCheck();
    if (!bunkerClauseChecked) {
      reminder("error", `Field ${bunkerClauseCheckKey} is required`);
      return false;
    }

    return true
  }

  const initFixture = (data: editHeadFixtureParams) => {
    const initPerformanceForm = initialPerformanceForm()
    handleBasicFormCommit({
      cpNo: data?.cpNo ?? "",
      minDuration: data?.minDuration ?? "",
      maxDuration: data?.maxDuration ?? "",
      bale: data?.bale ?? "",
      dwt: data?.dwt ?? "",
      imo: data?.imo ?? "",
      grain: data?.grain ?? "",
      mmsi: data?.mmsi ?? "",
      charteredBy: data?.charteredBy ?? [],
      layCanFrom: data?.layCanFrom ?? undefined,
      layCanTo: data?.layCanTo ?? undefined,
      ownerName: data?.ownerName ?? "",
      vesselName: data?.vesselName ?? "",
      cpDate: data?.cpDate ?? "",
      vesselNameCn: data?.vesselNameCn ?? "",
      vesselNameEn: data?.vesselNameEn ?? "",
      tradingArea: data?.tradingArea ?? "",
      deliveryPortCode: data?.deliveryPortCode ?? "",
      deliveryPortName: data?.deliveryPortName ?? "",
      deliveryDate: data?.deliveryDate ?? "",
      estRedeliveryDate: data?.estRedeliveryDate ?? "",
      redeliveryPortCode: data?.redeliveryPortCode ?? "",
      redeliveryPortName: data?.redeliveryPortName ?? "",
      charateList:data?.charateList ?? [],
      id:data?.id
    });
    handleHireAndFeesFormCommit({
      dailyHire: data?.dailyHire ?? "",
      commission: data?.commission ?? "",
      brokerId: data?.brokerId ?? "",
      brokerage: data?.brokerage ?? "",
      brokerName: data?.brokerName ?? "",
      ilohc: data?.ilohc ?? "",
      cve: data?.cve ?? "",
    });
    handleBunkerClauseFormCommit({
      hsFoQuantity: data?.hsFoQuantity ?? "",
      lsFoQuantity: data?.lsFoQuantity ?? "",
      mdoQuantity: data?.mdoQuantity ?? "",
      mgoQuantity: data?.mgoQuantity ?? "",
      borAbout: data?.borAbout ?? 5,
      borPrice: data?.borPrice || "same",
      bodPrice: data?.bodPrice ?? "",
      hsFoPrice: data?.hsFoPrice ?? "",
      lsFoPrice: data?.lsFoPrice ?? "",
      mdoPrice: data?.mdoPrice ?? "",
      mgoPrice: data?.mgoPrice ?? "",
      bunkerClause: data?.bunkerClause ?? "",
      goodWeatherDef: data?.goodWeatherDef ?? "",
    });
    handlePayTermFormCommit({
      // paymentRate: data?.paymentRate ?? "",
      // paymentTerm: data?.paymentTerm ?? "",
      paymentStartDay: data?.paymentStartDay ?? "",
    });
    handlePortCommit({
      work: data?.performanceList?.work ?? initPerformanceForm?.work,
      idle: data?.performanceList?.idle ?? initPerformanceForm?.idle,
      ballast: data?.performanceList?.ballast ?? initPerformanceForm?.ballast,
      laden: data?.performanceList?.laden ?? initPerformanceForm?.laden,
      performanceClause: data?.performanceClause ?? ""
    })
  }


  return {
    basicForm,
    loading,
    setLoading,
    handleBasicFormChange,
    handleBasicFormCommit,
    hireAndFeesForm,
    handleHireAndFeesFormChange,
    handleHireAndFeesFormCommit,
    bunkerClauseForm,
    handleBunkerClauseFormChange,
    handleBunkerClauseFormCommit,
    payTermForm,
    handlePayTermFormChange,
    handlePayTermFormCommit,
    performanceForm,
    handlePortChange,
    handleWorkChange,
    handlePerformanceFormChange,
    handlePortCommit,
    clear,
    check,
    initFixture
  }
}

export type useAddHeadFixtureReturnType = ReturnType<typeof useAddHeadFixture>

export default useAddHeadFixture