import React, { FC } from "react";
import style from "./index.module.less";
import { RouteCommonTimeAxisProps } from "./type";
import { TimeAxisControl, TimeAxisTimeLine } from "./components";
import useIndex from "./hooks/useIndex";

const RouteCommonTimeAxis: FC<RouteCommonTimeAxisProps> = ({ onMeteoTimeChange, onRun }) => {
	const { activeOption, commit, handleNext, handleRun, timeLineRef, handleTimeLineChange } =
		useIndex(onMeteoTimeChange, onRun);
	return (
		<div id="routeCommon-timeAxis" className={style["routeCommon-timeAxis"]}>
			<TimeAxisControl
				item={activeOption}
				onChange={commit}
				onNext={handleNext}
				onRun={handleRun}
			/>
			<TimeAxisTimeLine
				currentTime={activeOption?.currentTime}
				ref={timeLineRef}
				onChange={handleTimeLineChange}
			/>
		</div>
	);
};

export default RouteCommonTimeAxis;
