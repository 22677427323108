import React, { FC } from "react";
import style from "./index.module.less";
import { MovementDisputeChargeProps } from "./type";
import { FleetPrompt } from "@/common";
import { columns } from "./source";
import useIndex from "./hooks/useIndex";
import { Button } from "@/_components";
import MovementTable from "../../components/charterBase/common/table";

const MovementDisputeCharge: FC<MovementDisputeChargeProps> = ({
	hide,
	onClose,
	voyageId,
	onConfirm,
	voyageType
}) => {
	const { loading, dataSource, summary, change, commit, remove, add, saveMovementDisputeCharge } =
		useIndex(hide, voyageId, voyageType, onConfirm, onClose);
	return (
		<FleetPrompt hide={hide} mode="fixed" loading={loading}>
			<div className={style["movement-disputeCharge"]}>
				<div className="prompt-header--container">
					<span className="header-title">Dispute Charge</span>
					<span className="font_family fleet-close" onClick={onClose}></span>
				</div>
				<MovementTable
					bordered
					className="disputeCharge-container"
					columns={columns}
					summary={summary}
					dataSource={dataSource}
					actions={{
						onChange: change,
						onCommit: commit,
						onDelete: remove
					}}
				/>
				<Button type="secondary" onClick={add} size="small">
					Add Charge
				</Button>
				<div className="prompt-footer--container">
					<Button size="small" type="secondary" onClick={onClose}>
						Cancel
					</Button>
					<Button size="small" onClick={saveMovementDisputeCharge}>
						Confirm
					</Button>
				</div>
			</div>
		</FleetPrompt>
	);
};

export default MovementDisputeCharge;
