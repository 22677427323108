import { RequestApiMapType, type ApiParameter } from "@/api";
import { appVersion } from "@/api/constant";
import request from "@/service";

import {
	getApArListParams,
	updateApArStatusParams,
	downloadApArListParams,
	getFinancialInfoParams,
	deleteFinancialAttachParams,
	updateFinancialInfoParams,
	getAparFilePathParams,
	AparParamsType
} from "./type";

const enum ApiMap {
	getApArListParameter,
	updateApArStatusParameter,
	downloadApArListParameter,
	getFinancialInfoParameter,
	deleteFinancialAttachParameter,
	updateFinancialInfoParameter,
	getAparFilePathParameter
}

export type ApiUrl = keyof typeof ApiMap;

const apArApi: ApiParameter<ApiUrl> = {
	getApArListParameter: {
		method: "get",
		url: appVersion + "/invoices/arap/list"
	},
	updateApArStatusParameter: {
		method: "put",
		url: appVersion + "/invoices/status"
	},
	downloadApArListParameter: {
		method: "post",
		url: appVersion + "/invoices/export"
	},
	getFinancialInfoParameter: {
		method: "get",
		url: appVersion + "/invoices/arap/detail"
	},
	deleteFinancialAttachParameter: {
		method: "delete",
		url: appVersion + "/pre/voyage/attach"
	},
	updateFinancialInfoParameter: {
		method: "post",
		url: appVersion + "/invoices/arap/detail"
	},
	getAparFilePathParameter: {
		method: "get",
		url: appVersion + "/move/voyage/url"
	}
};

export const getApArListApi = (params: getApArListParams) => {
	return request.request({
		...apArApi.getApArListParameter,
		params
	});
};

export const updateApArStatusApi = (params: updateApArStatusParams) => {
	return request.request({
		...apArApi.updateApArStatusParameter,
		data: params
	});
};

export const downloadApArListApi = (params: downloadApArListParams) => {
	return request.request({
		...apArApi.downloadApArListParameter,
		data: params,
		responseType: "blob"
	});
};

export const getFinancialInfoApi = (params: getFinancialInfoParams) => {
	return request.request({
		...apArApi.getFinancialInfoParameter,
		params
	});
};

export const deleteFinancialAttachApi = (params: deleteFinancialAttachParams) => {
	return request.request({
		...apArApi.deleteFinancialAttachParameter,
		url: `${apArApi.deleteFinancialAttachParameter?.url}/${params?.id}`
	});
};

export const updateFinancialInfoApi = (params: updateFinancialInfoParams) => {
	return request.request({
		...apArApi.updateFinancialInfoParameter,
		data: params,
		headers: {
			"Content-Type": "multipart/form-data"
		}
	});
};

export const getAparFilePathApi = (params: getApArListParams) => {
	return request.request({
		...apArApi.getAparFilePathParameter,
		params
	});
};

export const aparApiMap: RequestApiMapType<ApiUrl, AparParamsType> = {
	getApArListApi,
	updateApArStatusApi,
	downloadApArListApi,
	getFinancialInfoApi,
	deleteFinancialAttachApi,
	updateFinancialInfoApi,
	getAparFilePathApi
};
