import { queryContactGroupAction } from "@/action/dataBase/contact";
import useCommonIndex from "../../../hooks/useIndex";
import { CommonContactItemType } from "../type";
const useIndex = (
	activeItems: Partial<CommonContactItemType> | Partial<CommonContactItemType>[],
	showKey: keyof CommonContactItemType,
	onFinish: (newItem: CommonContactItemType, operateType: "add" | "del") => void,
	primaryKey: keyof CommonContactItemType = "id",
	disabled: boolean,
	params?: { businessType: CommonContactItemType["businessType"] }
) => {
	const {
		loading,
		open,
		list,
		searchValue,
		selectValue,
		page,
		handleSearch,
		focusPopup,
		closePopup,
		loadMore,
		handleItemSelect,
		tagRender
	} = useCommonIndex<CommonContactItemType, keyof CommonContactItemType>(
		activeItems,
		showKey,
		onFinish,
		queryContactGroupAction,
		primaryKey,
		disabled,
		params
	);

	return {
		loading,
		open,
		list,
		searchValue,
		selectValue,
		page,
		handleSearch,
		focusPopup,
		closePopup,
		loadMore,
		handleItemSelect,
		tagRender
	};
};

export default useIndex;
