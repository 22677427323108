import React from "react";
import classnames from "classnames";
import Cascader, { type DefaultOptionType } from "antd/es/cascader/index";
import style from "./index.module.less";

export type FleetCascaderOption = {
	value: string;
	label: string;
	children?: FleetCascaderOption[];
};

const FleetCascader: React.FC<{
	className?: string;
	options: FleetCascaderOption[];
	onClear?: () => void;
	onChange: (value: string[]) => void;
	value?: (string | number)[] | (string | number)[][];
	placeholder?: string;
	dropdownMatchSelectWidth?: boolean;
	disabled?: boolean;
}> = ({
	className,
	placeholder,
	value,
	options,
	onClear,
	onChange,
	dropdownMatchSelectWidth,
	disabled
}) => {
	const filter = (inputValue: string, path: DefaultOptionType[]) => {
		return path.some(
			(option) => (option.label as string).toLowerCase().indexOf(inputValue.toLowerCase()) > -1
		);
	};
	return (
		<Cascader
			className={classnames(style["fleet-cascader"], className)}
			options={options}
			disabled={disabled}
			onChange={onChange}
			value={value}
			placeholder={placeholder}
			dropdownMatchSelectWidth={dropdownMatchSelectWidth}
			onClear={onClear}
			popupClassName={style["fleet-cascader-popup"]}
			showSearch={{ filter }}
			// onSearch={(value) => console.log((value)}

			// getPopupContainer
			// open
		/>
	);
};

export default FleetCascader;
