import { useEffect, useRef } from "react";
import { dateToInvoiceTime } from "@/tools/date";
import { formatThousandthNumber } from "@/tools/amount";
import { useInvTemplate } from "../../../../../common";
import { InvStatementTemplateProps, InvStatementTemplateReSourceType } from "../type";
import { FleetNumType } from "@/types/common";

const useIndex = (
	canvasRef: InvStatementTemplateProps["canvasRef"],
	dataSource: InvStatementTemplateProps["dataSource"],
	activeTab: InvStatementTemplateProps["activeTab"]
) => {
	const {
		markRef,
		ctxPosition,
		getFontInfo,
		drawLine,
		drawHeader,
		drawSpecialText,
		drawText,
		markRefInit,
		drawFooter,
		splitWords
	} = useInvTemplate();

	const resource = useRef<InvStatementTemplateReSourceType>({
		ownerFields: [],
		plusItems: [],
		lessItems: []
	});

	const initDataSource = () => {
		const {
			basicsFieldItems,
			counterPartyFieldItems,
			baseItem,
			otherItem,
			otherExpenses,
			fixedExpenses
		} = dataSource;

		const ownerFields: InvStatementTemplateReSourceType["ownerFields"] = [
			...counterPartyFieldItems,
			{ fieldName: "Beneficiary Name", fieldVal: baseItem?.beneficiaryName },
			{ fieldName: "Beneficiary Bank", fieldVal: baseItem?.beneficiaryBank },
			{ fieldName: "Company Address", fieldVal: baseItem?.beneficiaryAddress },
			{ fieldName: "Swift Code", fieldVal: baseItem?.swiftCode },
			{ fieldName: "Bank Code", fieldVal: baseItem?.bankCode },
			{ fieldName: "Account No", fieldVal: baseItem?.accountNo }
		];

		let lessItems: InvStatementTemplateReSourceType["lessItems"] = [],
			plusItems: InvStatementTemplateReSourceType["lessItems"] = [];

		switch (true) {
			case +fixedExpenses?.ilohc > 0 && otherItem?.addFixed:
				plusItems?.push({ fieldName: "ILOHC", fieldVal: fixedExpenses?.ilohc });
				break;
			case +fixedExpenses?.ilohc <= 0 && otherItem?.addFixed:
				lessItems?.push({ fieldName: "ILOHC", fieldVal: fixedExpenses?.ilohc });
				break;
			default:
				break;
		}

		switch (true) {
			case +fixedExpenses?.cve > 0 && otherItem?.addFixed:
				plusItems?.push({ fieldName: "C/V/E", fieldVal: fixedExpenses?.cve });
				break;
			case +fixedExpenses?.cve <= 0 && otherItem?.addFixed:
				lessItems?.push({ fieldName: "C/V/E", fieldVal: fixedExpenses?.cve });
				break;
			default:
				break;
		}
		if (otherItem?.addOther) {
			otherExpenses?.forEach((expenseItem) => {
				switch (true) {
					case +expenseItem?.fieldVal > 0:
						plusItems?.push({ fieldName: expenseItem?.fieldName, fieldVal: expenseItem?.fieldVal });
						break;
					default:
						lessItems?.push({ fieldName: expenseItem?.fieldName, fieldVal: expenseItem?.fieldVal });
						break;
				}
			});
		}

		ctxPosition.current = {
			currentX: 66,
			currentY: 52
		};

		resource.current = {
			ownerFields,
			plusItems,
			lessItems
		};
	};

	const drawHireItem = (
		currentWidth: number,
		form: string | number,
		to: string | number,
		totalDays: number,
		isOff?: boolean
	) => {
		const ctx = canvasRef?.current?.getContext("2d");
		let currentY = ctxPosition.current.currentY,
			currentX = currentWidth,
			occupyZone;
		ctx.save();
		ctx.font = "400 12px Arial";
		ctx.textAlign = "left";
		ctx.fillStyle = "rgba(0, 0, 0, 0.88)";

		const {
			suffix: formSuffix,
			year: formYear,
			month: formMonth,
			days: formDays,
			hours: formHours,
			minutes: formMinutes
		} = dateToInvoiceTime(form);

		const {
			suffix: toSuffix,
			year: toYear,
			month: toMonth,
			days: toDays,
			hours: toHours,
			minutes: toMinutes
		} = dateToInvoiceTime(to);

		if (form) {
			occupyZone = drawText(ctx, "From", currentX, currentY);
			currentWidth += occupyZone.occupyWidth + 8;
			occupyZone = drawText(
				ctx,
				`${formDays}-${formMonth}-${formYear} ${formHours}:${formMinutes}`,
				currentWidth,
				currentY
			);
			currentWidth += occupyZone.occupyWidth + 10;

			occupyZone = drawText(ctx, "GMT", currentWidth, currentY);
			currentWidth += occupyZone.occupyWidth + 138;

			occupyZone = drawText(ctx, "To", currentWidth, currentY);
			currentWidth += occupyZone.occupyWidth + 16;
		}

		if (to) {
			occupyZone = drawText(
				ctx,
				`${toDays}-${toMonth}-${toYear} ${toHours}:${toMinutes}`,
				currentWidth,
				currentY
			);
			currentWidth += occupyZone.occupyWidth + 12;

			occupyZone = drawText(ctx, "GMT", currentWidth, currentY);
		}

		ctx.textAlign = "right";
		occupyZone = drawText(
			ctx,
			`${totalDays} Days`,
			canvasRef.current.width - ctxPosition.current.currentX,
			currentY
		);
		if (!isOff) {
			ctx.textAlign = "left";
			drawText(
				ctx,
				"=",
				canvasRef.current.width - ctxPosition.current.currentX - occupyZone.occupyWidth - 9,
				currentY
			);
			ctxPosition.current.currentY += occupyZone.occupyHeight + 10.5;
		} else {
			ctxPosition.current.currentY += occupyZone.occupyHeight + 5;
		}
		ctx.restore();
	};

	// 绘制 hire 发票主体部分
	const drawHireBody = () => {
		const { baseItem, hireItem, otherItem } = dataSource;
		const ctx = canvasRef?.current?.getContext("2d");
		let currentX = ctxPosition.current.currentX,
			currentY = ctxPosition.current.currentY;
		ctx.save();
		ctx.font = "700 14px Arial";
		ctx.textAlign = "left";
		ctx.fillStyle = "rgba(0, 0, 0, 0.88)";

		const {
			day: cpDay,
			suffix: cpSuffix,
			month: cpMonth,
			year: cpYear
		} = dateToInvoiceTime(baseItem?.cpDate);
		const {
			day: startDay,
			suffix: startSuffix,
			month: startMonth,
			year: startYear
		} = dateToInvoiceTime(hireItem?.onHireStartDay);
		const {
			day: endDay,
			suffix: endSuffix,
			month: endMonth,
			year: endYear
		} = dateToInvoiceTime(hireItem?.onHireEndDay);

		let hireBodyHeader = getFontInfo(
			ctx,
			`MV ${baseItem?.vesselName} - CP DATED ${cpDay}${cpSuffix} ${cpMonth} ${cpYear} - ${baseItem?.period}ST HIRE (${startDay}/${startMonth} - ${endDay}/${endMonth}, ${endYear})`
		);

		let occupyZone = drawText(
			ctx,
			`MV ${baseItem?.vesselName} - CP DATED ${cpDay}${cpSuffix} ${cpMonth} ${cpYear} - ${baseItem?.period}ST HIRE (${startDay}/${startMonth} - ${endDay}/${endMonth}, ${endYear})`,
			Math.floor((canvasRef?.current?.width - hireBodyHeader?.width) / 2),
			currentY
		); // 绘制文字 To

		currentY += occupyZone?.occupyHeight + 26;

		occupyZone = drawText(ctx, `TO: ${baseItem?.ownerName}`, currentX, currentY);

		currentY += occupyZone.occupyHeight + 2;

		occupyZone = drawText(ctx, `Business registration address: `, currentX, currentY);

		const { lines } = splitWords(ctx, baseItem?.address, 930 - 65 * 2 - occupyZone.occupyWidth);

		for (let line of lines) {
			const _occupyZone = drawText(ctx, line, currentX + occupyZone.occupyWidth, currentY);
			currentY += _occupyZone.occupyHeight + 1;
		}

		if (lines?.length <= 0) {
			currentY += occupyZone.occupyHeight;
		}

		currentY += 27;

		drawLine(
			ctx,
			ctxPosition.current.currentX,
			currentY,
			canvasRef.current.width - ctxPosition.current.currentX,
			currentY
		);

		currentY += 1 + 9.5;

		ctx.font = "600 12px Arial";
		ctx.textAlign = "left";
		ctx.fillStyle = "rgba(0, 0, 0, 0.88)";

		ctxPosition.current.currentY = currentY;

		if (otherItem?.addHire) {
			occupyZone = drawText(ctx, "On-Hire:", currentX, currentY); // 绘制文字
			currentX += occupyZone.occupyWidth + 14;

			occupyZone = drawText(
				ctx,
				"Off-Hire:",
				currentX - (occupyZone.occupyWidth + 14),
				ctxPosition.current.currentY + occupyZone.occupyHeight + 10.5
			); // 绘制文字 To
			ctx.font = "400 12px Arial";
			drawHireItem(
				currentX,
				hireItem?.onHireStartDay,
				hireItem?.onHireEndDay ?? "",
				hireItem?.ttlOnHireDay
			);
			if (hireItem?.offHireFoList?.length > 0) {
				for (let item of hireItem?.offHireFoList) {
					drawHireItem(
						currentX,
						item?.offHireStartDay,
						item?.offHireEndDay,
						item?.totalOffHire,
						true
					);
				}
			}
			if (hireItem?.offHireFoList?.length === 0) {
				ctxPosition.current.currentY += occupyZone?.occupyHeight + 5;
			}

			currentY = ctxPosition.current.currentY + 3;

			drawLine(ctx, currentX, currentY, currentX + 735, currentY, 1, "rgba(0, 0, 0, 0.88)");

			currentY += 1 + 8;

			drawText(ctx, "Total On-Hire Period:", currentX, currentY); // 绘制文字 整个可以收租的租金期间
			ctx.textAlign = "right";
			occupyZone = drawText(
				ctx,
				`${hireItem?.ttlOnHireDay - hireItem?.ttlOffHireDay} Days`,
				canvasRef.current.width - ctxPosition.current.currentX,
				currentY
			);

			currentY += occupyZone.occupyHeight + 16.5;
		}

		ctx.restore();

		ctxPosition.current.currentY = currentY;
	};

	const drawInvoiceType = () => {
		const ctx = canvasRef?.current?.getContext("2d");
		let currentX = ctxPosition.current.currentX,
			currentY = ctxPosition.current.currentY;
		ctx.save();
		ctx.font = "400 24px Arial";
		ctx.fillStyle = "rgba(0, 0, 0, 0.88)";
		ctx.textAlign = "left";
		let occupyZone = drawText(ctx, "Statement of Hire", currentX + 133.5 + 150, currentY);
		currentY += occupyZone.occupyHeight + 4;

		drawLine(
			ctx,
			currentX + 133.5 + 150,
			currentY,
			currentX + 133.5 + 150 + occupyZone.occupyWidth,
			currentY,
			1.5,
			"#000"
		);
		currentY += 1.5 + 11;
		ctxPosition.current.currentY = currentY;
		ctx.restore();
	};

	const drawTtlHireBody = () => {
		const { otherItem, hireItem } = dataSource;
		const ctx = canvasRef?.current?.getContext("2d");
		let currentX = ctxPosition.current.currentX,
			currentY = ctxPosition.current.currentY;
		ctx.save();
		ctx.font = "700 14px Arial";
		ctx.textAlign = "right";
		ctx.fillStyle = "rgba(0, 0, 0, 0.88)";
		drawSpecialText(ctx, canvasRef, "USD", "prefix", currentY, true);
		currentY += drawSpecialText(ctx, canvasRef, "USD", "suffix", currentY, true) + 9;

		if (otherItem?.addHire) {
			ctx.font = "700 12px Arial";
			ctx.textAlign = "left";

			let occupyZone = drawText(ctx, "TTL Hire:", currentX, currentY);
			currentX += occupyZone.occupyWidth + 10;

			ctx.font = "400 12px Arial";

			occupyZone = drawText(
				ctx,
				`${formatThousandthNumber(hireItem?.ttlOnHireDay)} Days`,
				currentX,
				currentY
			);
			currentX += occupyZone.occupyWidth + 44;

			occupyZone = drawText(ctx, "-", currentX, currentY);
			currentX += occupyZone.occupyWidth + 15;

			occupyZone = drawText(ctx, "Off-Hire", currentX, currentY);
			currentX += occupyZone.occupyWidth + 20;

			drawText(
				ctx,
				`${formatThousandthNumber(hireItem?.ttlOffHireDay)}.Day(s)`,
				currentX,
				currentY
			);

			currentY += occupyZone.occupyHeight + 5;
			currentX = 129;

			occupyZone = drawText(
				ctx,
				`= ${formatThousandthNumber(hireItem?.ttlOnHireDay - hireItem?.ttlOffHireDay)} Days`,
				currentX,
				currentY
			);
			currentX += occupyZone.occupyWidth + 31;

			occupyZone = drawText(ctx, "x", currentX, currentY);
			currentX += occupyZone.occupyWidth + 12;

			drawText(ctx, `USD ${formatThousandthNumber(+hireItem?.dailyHire)} /Day`, currentX, currentY);
			drawSpecialText(
				ctx,
				canvasRef,
				`${formatThousandthNumber(+hireItem?.dailyHire * (hireItem?.ttlOnHireDay - hireItem?.ttlOffHireDay))}`,
				"suffix",
				currentY
			);
			currentY += occupyZone.occupyHeight + 10;
			ctx.restore();
		}
		ctxPosition.current.currentY = currentY;
	};

	const drawBodOrBorItem = (
		type: "bod" | "bor" | "offHire",
		oil: "LSMGO" | "VLSFO",
		consumption: number,
		price: number
	) => {
		const ctx = canvasRef?.current?.getContext("2d");
		let currentX = ctxPosition.current.currentX,
			currentY = ctxPosition.current.currentY;
		ctx.save();
		ctx.fillStyle = "rgba(0, 0, 0, 0.88)";
		ctx.font = "400 12px Arial";
		ctx.textAlign = "left";
		currentX += 63;

		let occupyZone = drawText(ctx, `${oil} ${consumption} MT`, currentX, currentY);
		currentX += occupyZone.occupyWidth + 8;

		occupyZone = drawText(ctx, "x", currentX, currentY);
		currentX += occupyZone.occupyWidth + 7;

		occupyZone = drawText(ctx, `${price} USD/MT`, currentX, currentY);

		drawSpecialText(
			ctx,
			canvasRef,
			formatThousandthNumber(consumption * price),
			type === "bod" ? "suffix" : "prefix",
			currentY
		);
		ctx.restore();
		ctxPosition.current.currentY = currentY + occupyZone.occupyHeight;
	};

	const drawBodOrBor = (type: "bod" | "bor" | "offHire") => {
		const { otherItem, bunkerPriceItem } = dataSource;
		if (!otherItem?.addBunker) return;
		const ctx = canvasRef?.current?.getContext("2d");
		let currentX = ctxPosition.current.currentX,
			currentY = ctxPosition.current.currentY;
		ctx.save();
		ctx.font = "700 12px Arial";
		ctx.textAlign = "left";
		ctx.fillStyle = "rgba(0, 0, 0, 0.88)";
		const rowLabel =
			type === "bod" ? "Value of BOD" : type === "bor" ? "Value of BOR" : "Value of BOH";
		let occupyZone = drawText(ctx, rowLabel, currentX, currentY);
		ctxPosition.current.currentY = currentY + occupyZone.occupyHeight + 9;
		ctx.restore();

		drawBodOrBorItem(
			type,
			"VLSFO",
			type === "bod"
				? +bunkerPriceItem?.bodFoConsumption
				: type === "bor"
					? +bunkerPriceItem?.borFoConsumption
					: +bunkerPriceItem?.bohFoConsumption,
			type === "bod"
				? +bunkerPriceItem?.bodFoPrice
				: type === "bor"
					? +bunkerPriceItem?.borFoPrice
					: +bunkerPriceItem?.bohFoPrice
		);
		ctxPosition.current.currentY += 5;
		drawBodOrBorItem(
			type,
			"LSMGO",
			type === "bod"
				? +bunkerPriceItem?.bodMdoConsumption
				: type === "bor"
					? +bunkerPriceItem?.borMdoConsumption
					: +bunkerPriceItem?.bohMdoConsumption,
			type === "bod"
				? +bunkerPriceItem?.bodMdoPrice
				: type === "bor"
					? +bunkerPriceItem?.borMdoPrice
					: +bunkerPriceItem?.bohMdoPrice
		);
		ctxPosition.current.currentY += type === "offHire" ? 30 : 11;
	};

	const drawPlusItem = (
		text: string,
		price: number,
		currentWidth: number,
		type: "suffix" | "prefix" = "suffix"
	) => {
		const ctx = canvasRef?.current?.getContext("2d");
		let currentX = currentWidth,
			currentY = ctxPosition.current.currentY;
		ctx.save();
		ctx.font = "400 12px Arial";
		ctx.textAlign = "left";
		ctx.fillStyle = "rgba(0, 0, 0, 0.88)";

		let occupyZone = drawText(ctx, text, currentX, currentY);

		drawSpecialText(ctx, canvasRef, formatThousandthNumber(price), type, currentY);

		ctx.restore();

		ctxPosition.current.currentY = currentY + occupyZone.occupyHeight;
	};

	const drawPlus = () => {
		const { otherExpenses, otherItem } = dataSource;
		const { plusItems } = resource?.current;
		if (!otherItem?.addOther && !otherItem?.addFixed) return;
		const ctx = canvasRef?.current?.getContext("2d");
		let currentX = ctxPosition.current.currentX,
			currentY = ctxPosition.current.currentY;
		ctx.save();
		ctx.font = "700 12px Arial";
		ctx.textAlign = "left";
		ctx.fillStyle = "rgba(0, 0, 0, 0.88)";

		let occupyZone = drawText(ctx, "Plus:", currentX, currentY);

		currentX += occupyZone.occupyWidth + 33;

		for (let i = 0; i < plusItems?.length; i++) {
			const currentItem = plusItems?.[i];
			drawPlusItem(currentItem?.fieldName, +currentItem?.fieldVal, currentX);
			if (i !== otherExpenses?.length - 1) {
				ctxPosition.current.currentY += 5;
			}
		}
		ctx.restore();
		ctxPosition.current.currentY += 20;
	};

	const drawLessItem = (
		type: "comisson" | "brokerage",
		rate: FleetNumType,
		totalPrice: number,
		currentWidth: number
	) => {
		const ctx = canvasRef?.current?.getContext("2d");
		let currentX = currentWidth,
			currentY = ctxPosition.current.currentY;
		ctx.save();
		ctx.font = "400 12px Arial";
		ctx.textAlign = "left";
		ctx.fillStyle = "rgba(0, 0, 0, 0.88)";

		let occupyZone = drawText(ctx, `${rate || 0}%`, currentX, currentY);
		currentX += occupyZone?.occupyWidth + 8;

		occupyZone = drawText(ctx, type === "brokerage" ? "Brokerage" : "Add Comm", currentX, currentY);

		drawSpecialText(
			ctx,
			canvasRef,
			formatThousandthNumber(Math.abs(totalPrice)),
			"prefix",
			currentY
		);

		ctx.restore();

		ctxPosition.current.currentY = currentY + occupyZone.occupyHeight;
	};

	const drawLessBody = () => {
		const { hireItem, otherItem } = dataSource;
		const { lessItems } = resource?.current;
		if (!otherItem?.addOther && !otherItem?.addFixed) return;
		const ctx = canvasRef?.current?.getContext("2d");
		let currentX = ctxPosition.current.currentX,
			currentY = ctxPosition.current.currentY;
		ctx.save();
		ctx.font = "700 12px Arial";
		ctx.textAlign = "left";
		ctx.fillStyle = "rgba(0, 0, 0, 0.88)";

		let occupyZone = drawText(ctx, "Less:", currentX, currentY);
		currentX += occupyZone?.occupyWidth + 31;

		if (otherItem?.addHire) {
			drawLessItem("comisson", hireItem?.addCommRate, hireItem?.addComm, currentX);
			ctxPosition.current.currentY += 5;
			drawLessItem("brokerage", hireItem?.brokerageRate, hireItem?.brokerage, currentX);
			ctxPosition.current.currentY += 5;
		}

		for (let i = 0; i < lessItems?.length; i++) {
			const currentItem = lessItems?.[i];
			drawPlusItem(currentItem?.fieldName, Math.abs(+currentItem?.fieldVal), currentX, "prefix");
			if (i !== lessItems?.length - 1) {
				ctxPosition.current.currentY += 5;
			}
		}

		ctxPosition.current.currentY += 12;

		ctx.restore();
	};

	const drawSecondLessItem = (period: number, remitAmount: number, currentWidth: number) => {
		const ctx = canvasRef?.current?.getContext("2d");
		let currentX = currentWidth,
			currentY = ctxPosition.current.currentY;
		ctx.save();
		ctx.font = "400 12px Arial";
		ctx.textAlign = "left";
		ctx.fillStyle = "rgba(0, 0, 0, 0.88)";

		let occupyZone = drawText(ctx, `${period} Hire Paid`, currentX, currentY);

		drawSpecialText(ctx, canvasRef, formatThousandthNumber(remitAmount), "prefix", currentY);
		ctxPosition.current.currentY += occupyZone.occupyHeight;
	};

	const drawSecondLessBody = () => {
		const { otherItem } = dataSource;

		const ctx = canvasRef?.current?.getContext("2d");
		let currentX = ctxPosition.current.currentX,
			currentY = ctxPosition.current.currentY;
		ctx.save();
		ctx.font = "700 12px Arial";
		ctx.textAlign = "left";
		ctx.fillStyle = "rgba(0, 0, 0, 0.88)";

		let occupyZone = drawText(ctx, "Less:", currentX, currentY);
		currentX += occupyZone?.occupyWidth + 30;

		for (let i = 0; i < otherItem?.pastList?.length; i++) {
			const currentItem = otherItem?.pastList?.[i];
			drawSecondLessItem(currentItem?.period, currentItem?.remitAmount, currentX);
			if (i !== otherItem?.pastList?.length - 1) {
				ctxPosition.current.currentY += 5;
			}
		}

		if (otherItem?.pastList?.length === 0) {
			ctxPosition.current.currentY += occupyZone?.occupyHeight;
		}

		ctxPosition.current.currentY += 10.5;
		drawLine(
			ctx,
			ctxPosition.current.currentX,
			ctxPosition.current.currentY,
			canvasRef.current.width - ctxPosition.current.currentX,
			ctxPosition.current.currentY
		);

		ctx.restore();
		ctxPosition.current.currentY += 8.5 + 1;
	};

	const drawSummary = () => {
		const { summary } = dataSource;

		const diffRate = summary?.plus - summary?.less;
		const ctx = canvasRef?.current?.getContext("2d");
		let currentX = ctxPosition.current.currentX,
			currentY = ctxPosition.current.currentY;
		ctx.save();
		ctx.textAlign = "left";
		ctx.fillStyle = "rgba(0, 0, 0, 0.88)";
		ctx.font = "400 12px Arial";
		drawSpecialText(ctx, canvasRef, formatThousandthNumber(summary?.less), "prefix", currentY);
		let occupyHeight = drawSpecialText(
			ctx,
			canvasRef,
			formatThousandthNumber(summary?.plus),
			"suffix",
			currentY
		);
		currentY += occupyHeight + 5;

		ctx.font = "700 12px Arial";
		drawText(ctx, "Balance in Owner's Favour", currentX, currentY);

		let textHeight = drawSpecialText(
			ctx,
			canvasRef,
			formatThousandthNumber(diffRate),
			"prefix",
			currentY
		);

		currentY += textHeight + 8.5;
		drawLine(
			ctx,
			ctxPosition.current.currentX,
			currentY,
			canvasRef.current.width - ctxPosition.current.currentX,
			currentY
		);
		currentY += 8.5;

		ctx.font = "400 12px Arial";
		drawSpecialText(
			ctx,
			canvasRef,
			formatThousandthNumber(+diffRate + +summary?.less),
			"prefix",
			currentY
		);
		textHeight = drawSpecialText(
			ctx,
			canvasRef,
			formatThousandthNumber(summary?.plus),
			"suffix",
			currentY
		);

		ctx.restore();
		ctxPosition.current.currentY = currentY + 19.5 + textHeight;
	};

	const drawInvoices = () => {
		const { baseItem, otherItem } = dataSource;
		const ctx = canvasRef?.current?.getContext("2d");
		// drawHeader(ctx, [], beneficiaryName ?? "Company Name", invDate, `Ref No. ${invNo}`);
		drawHeader(ctx, [], otherItem?.companyName, baseItem?.invDate, `Ref No. ${baseItem?.invNo}`);
		drawInvoiceType();

		drawHireBody();
		drawTtlHireBody();
		drawBodOrBor("bod");
		drawBodOrBor("bor");
		drawBodOrBor("offHire");
		drawPlus();
		drawLessBody();
		drawSecondLessBody();
		drawSummary();
		drawFooter(ctx, "statement", resource?.current?.ownerFields);
	};

	useEffect(() => {
		if (activeTab === "form") return;
		const ctx = canvasRef?.current?.getContext("2d");
		const markCallback = markRefInit();
		ctx?.clearRect(0, 0, canvasRef.current?.width, canvasRef?.current?.height);
		initDataSource();
		drawInvoices();
		return markCallback;
	}, [activeTab]);

	return {
		markRef
	};
};

export default useIndex;
