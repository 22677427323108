import React, { forwardRef, useImperativeHandle } from "react";
import { Button } from "antd";
import { LeftOutlined } from "@ant-design/icons";
import { AISInfo, VesselInfo } from "..";
import VesselList from "./components/VesselList";
import AISList from "./components/aisList";
import style from "./index.module.less";
import classnames from "classnames";
import { MonitoringVoyageListProps, MonitoringVoyageListRefType } from "./type";
import useIndex from "./hooks/useIndex";
import { MonitoringAreaList, MonitoringPortList } from "./components";
import { tabOptions } from "./source";
import MonitoringLegList from "./components/leg";

const VoyageList = forwardRef<MonitoringVoyageListRefType, MonitoringVoyageListProps>(
	(
		{
			activeTab,
			areaItems,
			onVesselItemClick,
			onAisItemSelect,
			handleChangeVessels,
			onAisVesselLoad,
			onAisStarItemSelect,
			onTabSelect,
			onAreaDelete,
			onAreaSelect,
			onAreaReset,
			onOpenDetailTab,
			portVesselParams,
			onChangePortVesselParams
		},
		ref
	) => {
		const { voyageNo, vesselListRef, aisListRef, handleJumpMovement } = useIndex();

		useImperativeHandle(ref, () => ({
			vesselListRef,
			aisListRef
		}));

		return (
			<div className={style["monitoring-list"]}>
				<div className="monitoring-list-header">
					<div className="monitoring-list-label">
						<span>Voy. Management List</span>
						{voyageNo && <Button icon={<LeftOutlined />} onClick={handleJumpMovement}></Button>}
					</div>
					<ul className="monitoring-list-tabs">
						{tabOptions?.map((tabItem) => {
							return (
								<li
									className={classnames({
										active: activeTab === tabItem?.value
									})}
									key={tabItem?.value}
									onClick={() => onTabSelect(tabItem?.value)}
								>
									{tabItem?.label}
								</li>
							);
						})}
					</ul>
				</div>
				<div className="monitoring-list-container">
					<VesselList
						ref={vesselListRef}
						activeTab={activeTab}
						onLegReset={handleChangeVessels}
						handleChangeVessels={(vessels) => handleChangeVessels?.(vessels)}
						onSelect={(type, item) => {
							onVesselItemClick?.("init", item, "7/day", true, true);
						}}
						onItemClick={(item: VesselInfo, isScorllTo: boolean) =>
							onVesselItemClick?.("init", item, "7/day", true, isScorllTo)
						}
					/>
					<AISList
						activeTab={activeTab}
						ref={aisListRef}
						onStarItemSelect={onAisStarItemSelect}
						onItemClick={(item, isStar) => onAisItemSelect?.("ais", item, isStar)}
						onVesselLoad={onAisVesselLoad}
					/>
					<MonitoringPortList
						activeTab={activeTab}
						onOpenDetailTab={onOpenDetailTab}
						searchParams={portVesselParams}
						onChangeSearchParams={(item) => {
							onChangePortVesselParams({ ...portVesselParams, ...item });
						}}
					/>
				</div>
			</div>
		);
	}
);

export default VoyageList;
