import { ExcludeKeys, ExtendsKeys } from "@/types";
import { HeadFixtueKeys, HeadFixtueLabelMap } from "@/types/voyageManage/headFixtureList";

export type HfHireAndFeesKeys = ExtendsKeys<HeadFixtueKeys,
  "dailyHire" | "commission" | "brokerName" | "brokerage" |
  "ilohc" | "cve" | "brokerId"
>;

export type HfHireAndFeesNumKeys = ExtendsKeys<HfHireAndFeesKeys,
  "brokerage" | "commission" | "dailyHire" | "cve" | "ilohc"
>;

export type HfHireAndFeesStrKeys = ExcludeKeys<HfHireAndFeesKeys, HfHireAndFeesEnumKeys | HfHireAndFeesNumKeys>;

export type HfHireAndFeesEnumKeys =  ExtendsKeys<HfHireAndFeesKeys, "brokerId">;

export type HfHireAndFeesFormKeys = HfHireAndFeesNumKeys | HfHireAndFeesStrKeys;

export type HfHireAndFeesFormType = Record<HfHireAndFeesStrKeys, string>
 & Record<HfHireAndFeesNumKeys, number | "">
 & Record<HfHireAndFeesEnumKeys,string | number>;

export type HfHireAndFeesFormValues = HfHireAndFeesFormType[HfHireAndFeesFormKeys];

/**
 * hooks type
 */

export type HfHireAndFeesFormChangeEvent = <K extends HfHireAndFeesFormKeys>(key: K, value: HfHireAndFeesFormType[K]) => void;

export type HfHireAndFeesFormCommitEvent = (item: Partial<HfHireAndFeesFormType>) => void;
/**
 * hfbasicprops type
 */

export type HfHireAndFeesFormProps = {
  item: HfHireAndFeesFormType;
  onChange: HfHireAndFeesFormChangeEvent;
  onCommit: HfHireAndFeesFormCommitEvent;
}

/**
 * init data
 */
export const initialHireAndFeesForm:HfHireAndFeesFormType = {
  dailyHire:"",
  commission:"",
  brokerId:"",
  brokerage:"",
  brokerName:"",
  ilohc:"",
  cve:""
}

export const checkHireAndFeesFormKeys:Partial<HfHireAndFeesFormKeys>[] = [
  "dailyHire"
]