import { useCommonIndex } from "@/hook"
import { checkKeys, initialVesselParticular } from "../source"
import { EstVesselParticularCommitEvent, EstVesselParticularType } from "../type"
import { IdentifierMapChangeEvent, ShippingMapCommitEvent } from "@/hook/estimation/details/useDetails";
import { useMemo } from "react";

const useIndex = (
  onShippingCommit?: ShippingMapCommitEvent,
  onIdentifierChange?: IdentifierMapChangeEvent,
  isPlan?: boolean
) => {

  const requiredKeys = useMemo(() => {
    return isPlan ? checkKeys?.filter(item => !["deliveryPortName", "deliveryPortCode"]?.includes(item)) : checkKeys
  }, [isPlan])

  const {
    dataSource,
    commit: commonCommit,
    change,
    init,
    check
  } = useCommonIndex<EstVesselParticularType>(initialVesselParticular, requiredKeys);

  const commit: EstVesselParticularCommitEvent = (item) => {
    commonCommit?.(item)
    if (item?.deliveryPortCode && item?.deliveryPortId) {
      onShippingCommit?.({
        vesselHisTrack: {}
      });
      onIdentifierChange?.("vesselHisTrack", (preVal) => preVal + 1);
    }
  }

  return {
    dataSource,
    commit,
    change,
    init,
    check
  }
}

export default useIndex