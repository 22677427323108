import { type ApiParameter } from "@/api";
import { appVersion } from "@/api/constant";
import request from "@/service";
import { PageParams } from "../estimation";
import { PortType } from "@/featrue/database/port/filter/type";
import { DatabasePortAddFormType } from "@/featrue/database/port/add/type";

const enum ApiMap {
	getPortGroupParameter,
	getPortParameter,
	deletePortParameter,
	addPortParameter,
	editPortParameter
}

type ApiUrl = keyof typeof ApiMap;

const portApi: ApiParameter<ApiUrl> = {
	getPortGroupParameter: {
		method: "get",
		url: appVersion + "/port/list"
	},
	getPortParameter: {
		method: "get",
		url: appVersion + "/port"
	},
	deletePortParameter: {
		method: "delete",
		url: appVersion + "/port"
	},
	addPortParameter: {
		method: "post",
		url: appVersion + "/port"
	},
	editPortParameter: {
		method: "put",
		url: appVersion + "/port"
	}
};

export type getPortGroupParams = PageParams & {
	keyword: string;
	country?: string;
	portType?: PortType | "";
};

export type getPortParams = {
	id: string | number;
};

export type deletePortParams = {
	id: string;
};

export type addPortParams = {} & DatabasePortAddFormType;

export type editPortParams = {
	id: string | number;
} & DatabasePortAddFormType;

export const getPortGroupApi = (params: getPortGroupParams) => {
	return request.request({
		...portApi.getPortGroupParameter,
		params
	});
};

export const getPortApi = (params: getPortParams) => {
	return request.request({
		...portApi.getPortParameter,
		url: `${portApi.getPortParameter.url}/${params?.id}`
	});
};

export const deletePortApi = (params: deletePortParams) => {
	return request.request({
		...portApi.deletePortParameter,
		url: `${portApi.deletePortParameter.url}/${params?.id}`
	});
};

export const addPortApi = (params: addPortParams) => {
	return request.request({
		...portApi.addPortParameter,
		data: params
	});
};

export const editPortApi = (params: editPortParams) => {
	return request.request({
		...portApi.editPortParameter,
		data: params
	});
};
