import { onRequest } from "@/action";
import { useAppDispatch } from "@/hook";
import useReminder from "@/hook/useReminder";
import { useEffect, useMemo, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import {
	AreaVesselListProps,
	initialFilterItem,
	RouteCommonAreaVesselFilterItemType,
	RouteCommonAreaVesselFilterProps,
	useFilterIndex
} from "../components";
import { FleetNumType } from "@/types/common";
import debounce from "lodash/debounce";
import dayjs from "dayjs";
import { useRouteCommonContext } from "@/pages/routeCommon/store";
import { RouteAreaItemType } from "@/pages/routeCommon/components";
import { RouteCommonAreaVesselProps } from "../type";

const useIndex = (onVesselLoad: RouteCommonAreaVesselProps["onVesselLoad"]) => {
	const dispatch = useAppDispatch();
	const navigate = useNavigate();
	const { reminder } = useReminder();

	const { activeAreaItems, activeDetailsOption } = useRouteCommonContext();
	const [loading, setLoading] = useState(false);
	const [selectRows, setSelectRows] = useState<AreaVesselListProps["selectRows"]>([]);
	const [dataSource, setDataSource] = useState<AreaVesselListProps["items"]>([]);

	const {
		dataSource: filters,
		commit,
		handleFilterInit,
		agxMaxInputRef,
		aisSpeedMinInputRef,
		aisSpeedMaxInputRef,
		dwtMinInputRef,
		dwtMaxInputRef
	} = useFilterIndex();

	const [timeRange, setTimeRange] = useState<RouteCommonAreaVesselFilterProps["timeRange"]>({
		startDate: dayjs().format("YYYY-MM-DD"),
		endDate: dayjs().format("YYYY-MM-DD")
	});

	const handleTimeRangeChange: RouteCommonAreaVesselFilterProps["onTimeRangeCommit"] = (item) => {
		setTimeRange(item);
	};

	const handleRowSelect: AreaVesselListProps["onSelect"] = (vals) => {
		setSelectRows(vals);
	};

	const handleCommit: RouteCommonAreaVesselFilterProps["onCommit"] = (item) => {
		updateAreaArguments(item);
	};

	const getAreaArgumentsFront = () => {
		setLoading(true);
	};

	const getAreaArgumentsSuccess = (response) => {
		setLoading(false);
		handleFilterInit(response?.data);
		getAreaVesselList(initialFilterItem(response?.data));
	};

	const getAreaArgumentsFaild = (error) => {
		console.log("error", error);
		setLoading(false);
		reminder("error", error?.msg ? error?.msg + ": " + error?.data : error?.data);
	};

	const getAreaArguments = () => {
		onRequest(
			"getRouteCommonAreaVesselArgumentsApi",
			null,
			getAreaArgumentsFront,
			getAreaArgumentsSuccess,
			getAreaArgumentsFaild,
			dispatch,
			navigate
		);
	};

	const updateAreaArguments = (params: Partial<RouteCommonAreaVesselFilterItemType>) => {
		console.log("filters", filters, params);
		onRequest(
			"updateRouteCommonAreaVesselArgumentsApi",
			{ ...filters, ...params },
			getAreaArgumentsFront,
			(response) => {
				commit(params);
				getAreaVesselList({ ...filters, ...params });
				setLoading(false);
			},
			getAreaArgumentsFaild,
			dispatch,
			navigate
		);
	};

	const getAreaVesselListSuccess = (response) => {
		setLoading(false);
		setDataSource(response?.data);
		setSelectRows([]);
		onVesselLoad?.("area", response?.data ?? []);
	};

	const downloadAreaVesselListSuccess = (response) => {
		reminder("success", "Download Successfully!");
		setLoading(false);
	};

	const getAreaVesselList = (params: RouteCommonAreaVesselFilterItemType = filters) => {
		if (dataSource?.length <= 0 && activeAreaItems?.length <= 0) return;
		onRequest(
			"getRouteCommonAreaVesselListApi",
			{
				...params,
				...timeRange,
				areaCodeList: activeAreaItems?.map((item) => item?.areaCode)
			},
			getAreaArgumentsFront,
			getAreaVesselListSuccess,
			getAreaArgumentsFaild,
			dispatch,
			navigate
		);
	};

	const handeSearch = useMemo(() => {
		const loadOptions = (
			key: keyof Pick<
				RouteCommonAreaVesselFilterItemType,
				"ageMax" | "aisSpeedMax" | "aisSpeedMin" | "dwtMax" | "dwtMin"
			>,
			value: FleetNumType
		) => {
			updateAreaArguments({ [key]: value });
		};
		return debounce(loadOptions, 800);
	}, [updateAreaArguments]);

	const handleReset = (areaItems: RouteAreaItemType[]) => {
		onRequest(
			"getRouteCommonAreaVesselListApi",
			{
				...filters,
				...timeRange,
				areaCodeList: areaItems?.map((item) => item?.areaCode)
			},
			getAreaArgumentsFront,
			getAreaVesselListSuccess,
			getAreaArgumentsFaild,
			dispatch,
			navigate
		);
	};

	const handleDownload = () => {
		if (selectRows?.length <= 0) return;
		console.log("selectRows", selectRows);
		const selectItems = dataSource?.filter((selectItem) => selectRows?.includes(selectItem?.imo));
		onRequest(
			"downloadRouteCommonAreaVesselListApi",
			selectItems,
			getAreaArgumentsFront,
			downloadAreaVesselListSuccess,
			getAreaArgumentsFaild,
			dispatch,
			navigate
		);
	};

	useEffect(() => {
		if (activeDetailsOption?.activeTab !== "areaVessel") return;
		getAreaArguments();
	}, [activeDetailsOption?.activeTab]);

	return {
		loading,
		filters,
		agxMaxInputRef,
		aisSpeedMinInputRef,
		aisSpeedMaxInputRef,
		dwtMinInputRef,
		dwtMaxInputRef,
		handleCommit,
		handeSearch,
		timeRange,
		handleTimeRangeChange,
		dataSource,
		handleReset,
		selectRows,
		handleRowSelect,
		handleDownload,
		activeDetailsOption
	};
};

export default useIndex;
