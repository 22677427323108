import React, { FC } from "react";
import classnames from "classnames";
import style from "./index.module.less";
import { PortListHistoryProps } from "./type";
import { FleetRangePicker } from "@/components";
import { VesselItem } from "../index";
import { disabledDate } from "./source";
import dayjs, { Dayjs } from "dayjs";
import useIndex from "./hooks/useIndex";
const PortListHistory: FC<PortListHistoryProps> = ({ dateRange, onCommit }) => {
	const { startDate, endDate, setStartDate, setEndDate } = useIndex();
	return (
		<div className={classnames(style["port-list-history"])}>
			<div className="portListHistory-header">
				<FleetRangePicker
					allowClear
					value={[
						dateRange?.startDate ? dayjs(dateRange?.startDate) : null,
						dateRange?.endDate ? dayjs(dateRange?.endDate) : null
					]}
					onChange={(vals: [Dayjs, Dayjs]) => {
						onCommit?.({
							startDate: vals?.[0]?.format("YYYY-MM-DD"),
							endDate: vals?.[1].format("YYYY-MM-DD")
						});
					}}
					className="portListHistory-range"
					disabledDate={disabledDate}
					showHour={false}
					showMinute={false}
					showTime={false}
				/>
				<span>*Last 90 days historical data is available</span>
			</div>
			{/* <div className="portListHistory-list">
				{dataSource?.map((item) => {
					return <VesselItem item={item} />;
				})}
			</div> */}
		</div>
	);
};

export default PortListHistory;
