import { ApiParameter, RequestApiMapType } from "@/api";
import request from "@/service";

import {
	RouteCommonDailyReportParamsType,
	getRouteCommonDailyReportParams,
	getRouteCommonDailyReportDetailsParams
} from "./type";
import { appVersion, appVersionV2 } from "@/api/constant";

const enum ApiMap {
	getRouteCommonDailyReportParameter,
	getRouteCommonDailyReportDetailsParameter
}

export type ApiUrl = keyof typeof ApiMap;

const dailyReportApi: ApiParameter<ApiUrl> = {
	getRouteCommonDailyReportParameter: {
		method: "get",
		url: appVersionV2 + "/monitoring/report/daily"
	},
	getRouteCommonDailyReportDetailsParameter: {
		method: "get",
		url: appVersion + "/monitoring/report/detail"
	}
};

export const getRouteCommonDailyReportApi = (params: getRouteCommonDailyReportParams) => {
	return request.request({
		...dailyReportApi.getRouteCommonDailyReportParameter,
		url: `${dailyReportApi.getRouteCommonDailyReportParameter.url}/${params.voyageNo}`,
		params: { legType: params.legType }
	});
};

export const getRouteCommonDailyReportDetailsApi = (
	params: getRouteCommonDailyReportDetailsParams
) => {
	return request.request({
		...dailyReportApi.getRouteCommonDailyReportDetailsParameter,
		params
	});
};

export const dailyReportApiMap: RequestApiMapType<ApiUrl, RouteCommonDailyReportParamsType> = {
	getRouteCommonDailyReportApi,
	getRouteCommonDailyReportDetailsApi
};
