import { ExcludeKeys, ExtendsKeys } from "@/types";
import { HeadFixtueKeys, HeadFixtueLabelMap } from "@/types/voyageManage/headFixtureList";
import { Dayjs } from "dayjs";

export type HfBunkerClauseKeys = ExtendsKeys<HeadFixtueKeys,
  "bunkerClause" |
  "goodWeatherDef" |
  "hsFoQuantity" |
  "lsFoQuantity" |
  "mdoQuantity" |
  "mgoQuantity" |
  "borAbout" |
  "borPrice" |
  "bodPrice" |
  "hsFoPrice" |
  "lsFoPrice" |
  "mdoPrice" |
  "mgoPrice"
>;

export type HfBunkerClauseNumKeys = ExtendsKeys<HfBunkerClauseKeys,
  "hsFoQuantity" |
  "lsFoQuantity" |
  "mdoQuantity" |
  "mgoQuantity" |
  "borAbout" |
  "bodPrice" |
  "hsFoPrice" |
  "lsFoPrice" |
  "mdoPrice" |
  "mgoPrice"
>;

export type HfBunkerClauseStrKeys = ExcludeKeys<HfBunkerClauseKeys, HfBunkerClauseNumKeys>;

export type HfBunkerClauseFormKeys = HfBunkerClauseStrKeys | HfBunkerClauseNumKeys;

export type HfBunkerClauseFormType = Record<HfBunkerClauseStrKeys, string> &
  Record<HfBunkerClauseNumKeys, number | "">;

/**
 * hooks type
 */

export type HfBunkerClauseFormChangeEvent = <K extends HfBunkerClauseFormKeys>(key: K, value: HfBunkerClauseFormType[K]) => void;

export type HfBunkerClauseFormCommitEvent = (item: Partial<HfBunkerClauseFormType>) => void;

/**
 * BunkerClauseForm type
 */

export type HfBunkerClauseFormProps = {
	item: HfBunkerClauseFormType;
	onChange: HfBunkerClauseFormChangeEvent;
	onCommit?: HfBunkerClauseFormCommitEvent;
}

/**
 * init data
 */
export const initialBunkerClauseForm:HfBunkerClauseFormType = {
  hsFoQuantity: "",
  lsFoQuantity: "",
  mdoQuantity: "",
  mgoQuantity: "",
  borAbout: 5,
  borPrice: "same",
  bodPrice: "",
  hsFoPrice: "",
  lsFoPrice: "",
  mdoPrice: "",
  mgoPrice: "",
  bunkerClause: "",
  goodWeatherDef: ""
}

export const checkBunkerClauseKeys:Partial<HfBunkerClauseFormKeys>[] = [
  
]