import React, { FC } from "react";
import { InvoicesViewsDetailsProps } from "./type";
import { InvFreight, InvStatement } from "./components";

const InvoicesViewsDetails: FC<InvoicesViewsDetailsProps> = ({ activeInvType, ...restProps }) => {
	console.log("activeVoyageItem", restProps?.activeVoyageItem);
	if (!restProps?.activeVoyageItem?.voyageType) return null;
	return activeInvType === "freightInv" ? (
		<InvFreight {...restProps} />
	) : (
		<InvStatement activeInvType={activeInvType} {...restProps} />
	);
};

export default InvoicesViewsDetails;
