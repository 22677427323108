import React from "react";
import { ColumnCategoryItemProps } from "./type";
import { FleetCustomRadio } from "@/components/Radio";
import { cn } from "@/tools";

export default function ColumnCategoryItem<T extends string, U extends string>({
	selectRows,
	options,
	onSelect,
	onSelectAll
}: ColumnCategoryItemProps<T, U>) {
	const handleSelect = (checked: boolean) => {
		const selectList =
			selectRows?.length === options?.children?.length
				? options?.children?.filter((item) => item?.disabled)
				: options?.children;
		onSelectAll?.(
			options?.dataIndex,
			selectList.map((item) => item?.value)
		);
	};
	return (
		<div className="flex flex-col gap-[12px]">
			<FleetCustomRadio
				value={selectRows?.length === options?.children?.length}
				onChange={handleSelect}
				label={options?.dataIndex}
				className="!h-fit"
			/>
			<div className="flex flex-row before:content-[''] before:w-[2px] before:h-full before:bg-[#d9d9d9] before:inline-block before:mr-[12px] before:rounded-full">
				<div className="flex flex-col gap-[4px]">
					{options?.children?.map((item) => {
						return (
							<FleetCustomRadio
								key={item?.value}
								value={selectRows?.includes(item?.value)}
								onChange={(checked) => {
									onSelect?.(options?.dataIndex, item?.value);
								}}
								label={item?.label}
								className={cn("!h-fit", { "!cursor-not-allowed": item?.disabled })}
								disabled={item?.disabled}
							/>
						);
					})}
				</div>
			</div>
		</div>
	);
}
