import { useRouteCommonContext } from "@/pages/routeCommon/store";
import { CommonCommitEvent } from "@/types/event";
import { useEffect, useRef } from "react";
import { RouteCommonAreaVesselRefType, RouteCommonPortVesselRefType } from "../components";

const useIndex = () => {
	const headerRef = useRef<HTMLDivElement>(null);
	const wrapperRef = useRef<HTMLDivElement>(null);

	const areaVesselRef = useRef<RouteCommonAreaVesselRefType>(null);
	const portVesselRef = useRef<RouteCommonPortVesselRefType>(null);

	const { onDetailsOptionCommit } = useRouteCommonContext();

	const dragOptions = useRef<{
		mouseY: number;
		currentHeight: number;
		// 鼠标最后移动的位置（Y轴）
		maxHeight: number;
		minHeight: number;
	}>({
		mouseY: 0,
		maxHeight: 9999,
		currentHeight: 0,
		minHeight: 40
	});

	const handleDragOptionCommit: CommonCommitEvent<typeof dragOptions.current> = (item) => {
		dragOptions.current = {
			...dragOptions.current,
			...item
		};
	};

	const handleDragUp = (event: MouseEvent) => {
		// 移除鼠标移动事件
		document.removeEventListener("mousemove", handleDragMove);
		// 移除鼠标放开事件
		document.removeEventListener("mouseup", handleDragUp);
		// 允许用户选择网页中文字
		document.onselectstart = null;
		// 允许用户拖动元素
		document.ondragstart = null;
	};

	const handleDragMove = (event: MouseEvent) => {
		const latLngControll = document.querySelector(".routeCommon-lnglat-control") as HTMLDivElement,
			timelineLegendPanelElement = document.getElementById("routeCommon-timelineLegendPanel"),
			oldWraperHeight = wrapperRef.current?.getBoundingClientRect()?.height,
			// 旧的wrapper的高度
			mouseY = dragOptions?.current?.mouseY,
			// 上一次鼠标停留位置
			mouseDistance = Math.abs(parseInt(((mouseY - event.clientY) * 100).toString(), 10) / 100);
		// 本次鼠标移动产生的差值
		let newWraperHeight = 0;
		// 最终需要变化的容器高度

		if (mouseY > event?.clientY) {
			if (oldWraperHeight >= dragOptions?.current?.maxHeight) return false;
			newWraperHeight = oldWraperHeight + mouseDistance;
		} else {
			if (oldWraperHeight <= dragOptions?.current?.minHeight) return false;
			newWraperHeight = oldWraperHeight - mouseDistance;
		}

		if (newWraperHeight > dragOptions?.current?.maxHeight) {
			newWraperHeight = dragOptions?.current?.maxHeight;
		}

		if (newWraperHeight < dragOptions?.current?.minHeight) {
			newWraperHeight = dragOptions?.current?.minHeight;
		}

		wrapperRef.current.style.height = newWraperHeight + "px";
		timelineLegendPanelElement.style.bottom = newWraperHeight + 12 + "px";
		latLngControll && (latLngControll.style.bottom = newWraperHeight + 50 + "px");
		handleDragOptionCommit({ mouseY: event?.clientY, currentHeight: newWraperHeight });
	};

	const handleDragDown = (event: MouseEvent) => {
		const windyElement = document.getElementById("windy");
		// 禁止用户选择网页中文字
		document.onselectstart = () => false;
		// 禁止用户拖动元素
		document.ondragstart = () => false;
		// 保存鼠标最后移动的位置（Y轴）
		handleDragOptionCommit({
			mouseY: event?.clientY,
			maxHeight: windyElement?.clientHeight,
			minHeight: headerRef?.current?.clientHeight
		});
		// 绑定鼠标移动事件
		document.addEventListener("mousemove", handleDragMove);
		// 绑定鼠标放开事件
		document.addEventListener("mouseup", handleDragUp);
	};

	const handlePortCallOpen = (item: { imo: string }) => {
		onDetailsOptionCommit?.({
			isPortCallVisible: true,
			currentImo: item?.imo,
			portCallLabel: null
		});
	};

	useEffect(() => {
		headerRef?.current?.addEventListener("mousedown", handleDragDown);
		return () => {
			headerRef?.current?.removeEventListener("mousedown", handleDragDown);
		};
	}, []);

	return {
		areaVesselRef,
		portVesselRef,
		headerRef,
		wrapperRef,
		handlePortCallOpen
	};
};

export default useIndex;
