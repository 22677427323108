import { InvFreightCargoItemType } from "./type";

export const initialInvFreightCargoItem = (type: "init" | "details", item?: Partial<InvFreightCargoItemType>): InvFreightCargoItemType => {
  return {
    id: type === "details" && item?.id ? item?.id : null,
    invId: type === "details" && item?.invId ? item?.invId : null,
    invPeriod: item?.invPeriod ?? 1,
    cargoName: item?.cargoName ?? "",
    cargoQuantity: item?.cargoQuantity ?? "",
    bolDate: item?.bolDate ?? "",
    bolNo: item?.bolNo ?? "",
    cargoUnit: item?.cargoUnit ?? "MT"
  }
}

export const checkKeys: (keyof InvFreightCargoItemType)[] = [
  "cargoName",
  "cargoQuantity",
  "bolDate"
]