import { ApiParameter, RequestApiMapType } from "@/api";
import request from "@/service";

import {
	MovementPortExpensesParamsType,
	getMovementPortExpensesParams,
	saveMovementPortExpensesParams
} from "./type";
import { appVersion } from "@/api/constant";

const enum ApiMap {
	getMovementPortExpensesParameter,
	saveMovementPortExpensesParameter
}

export type ApiUrl = keyof typeof ApiMap;

const portExpensesApi: ApiParameter<ApiUrl> = {
	getMovementPortExpensesParameter: {
		method: "get",
		url: appVersion + "/move/dialog/port/expense"
	},
	saveMovementPortExpensesParameter: {
		method: "put",
		url: appVersion + "/move/dialog/port/expense"
	}
};

export const getMovementPortExpensesApi = (params: getMovementPortExpensesParams) => {
	return request.request({
		...portExpensesApi.getMovementPortExpensesParameter,
		url: `${portExpensesApi.getMovementPortExpensesParameter.url}/${params?.id}`
	});
};

export const saveMovementPortExpensesApi = (params: saveMovementPortExpensesParams) => {
	return request.request({
		...portExpensesApi.saveMovementPortExpensesParameter,
		data: params
	});
};

export const movementPortExpensesApiMap: RequestApiMapType<ApiUrl, MovementPortExpensesParamsType> =
	{
		getMovementPortExpensesApi,
		saveMovementPortExpensesApi
	};
