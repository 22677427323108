import { ExtendsKeys } from "@/types";
import { FleetOptionType } from "@/types/advancedType";
import { VoyageType } from "@/types/common";

export const voyageTypeOptions: FleetOptionType<Uppercase<ExtendsKeys<VoyageType, ExtendsKeys<VoyageType, "TCTC" | "TCVC">>>, "TCTC" | "TCVC">[] = [
  {
    label: "TCTC",
    value: "TCTC"
  },
  {
    label: "TCVC",
    value: "TCVC"
  }
];
