import { FleetOptionType } from "@/types/advancedType";
import { MonitoringVoyageTabType } from "./type";

export const tabOptions: FleetOptionType<
	"Leg List" | "AIS List" | "Area" | "Port",
	MonitoringVoyageTabType
>[] = [
	{ label: "Leg List", value: "vm" },
	// { label: "Area", value: "area" },
	{ label: "Port", value: "port" },
	{ label: "AIS List", value: "ais" }
];
