import React, { FC, Fragment } from "react";
import style from "./index.module.less";
import {
	AreaVesselFilterHeadingMap,
	AreaVesselFilterItemType,
	AreaVesselFilterProps,
	AreaVesselFilterPurposeMap,
	AreaVesselFilterVesselTypeSubType
} from "./type";
import {
	AreaVesselFilterItem,
	RouteCommonAreaSelectItemType,
	RouteCommonCountrySelectItemType,
	RouteCommonOtherSelect,
	RouteCommonPortSelectItemType
} from "./common";
import { headingTypeOption, purposeOption, vesselSubTypeOptions } from "./source";
import { ArrivalVesselSubTypeMap } from "@/common/Select";
import { FleetFormItem, FleetInput, FleetRangePicker } from "@/components";
import dayjs, { Dayjs } from "dayjs";
import { FleetCustomRadio } from "@/components/Radio";
import { FleetNumType } from "@/types/common";

const AreaVesselFilter: FC<AreaVesselFilterProps> = ({
	item,
	timeRange,
	onTimeRangeCommit,
	onCommit,
	onSearch,
	agxMaxInputRef,
	aisSpeedMinInputRef,
	aisSpeedMaxInputRef,
	dwtMinInputRef,
	dwtMaxInputRef
}) => {
	return (
		<div className={style["areaVessel-filter"]}>
			<span className="areaVessel-filter-title">Filters</span>
			<div className="areaVessel-filter-container">
				<div className="areaVessel-filter-scrollArea">
					<FleetFormItem label="Period">
						<FleetRangePicker
							allowClear
							// value={[
							// 	timeRange.startDate ? dayjs(timeRange.startDate) : null,
							// 	timeRange.endDate ? dayjs(timeRange.endDate) : null
							// ]}
							onChange={(vals: [Dayjs, Dayjs]) => {
								onTimeRangeCommit({
									startDate: vals?.[0].format("YYYY-MM-DD"),
									endDate: vals?.[1].format("YYYY-MM-DD")
								});
							}}
							disabledDate={(current) => {
								return current && current < dayjs().subtract(1, "day").endOf("day");
							}}
						/>
					</FleetFormItem>
					<AreaVesselFilterItem<
						keyof typeof ArrivalVesselSubTypeMap,
						AreaVesselFilterVesselTypeSubType
					>
						label="Vessel Type Sub"
						selectRows={item?.vesselTypeSub}
						options={vesselSubTypeOptions}
						onSelect={(key, checked) => {
							const currentItem = [...item?.vesselTypeSub];
							if (checked) {
								currentItem?.push(key);
							} else {
								const currentIndex = currentItem?.findIndex((selectItem) => selectItem === key);
								currentItem?.splice(currentIndex, 1);
							}
							onCommit?.({ vesselTypeSub: currentItem });
						}}
					/>
					<AreaVesselFilterItem<
						keyof Pick<
							AreaVesselFilterItemType,
							"ageMax" | "dwtMin" | "dwtMax" | "aisSpeedMin" | "aisSpeedMax"
						>,
						FleetNumType
					>
						label="Custom Parameter"
						render={() => {
							return (
								<div className="areaVessel-filter-custom">
									<FleetFormItem label="Max Age">
										<FleetInput
											value={undefined}
											placeholder="Enter Age"
											inputRef={agxMaxInputRef}
											type="number"
											onChange={(value: number) => {
												onSearch?.("ageMax", value);
											}}
										/>
									</FleetFormItem>
									<FleetFormItem label="Min AIS Speed">
										<FleetInput
											value={undefined}
											placeholder="Enter Speed"
											inputRef={aisSpeedMinInputRef}
											type="number"
											onChange={(value: number) => {
												onSearch?.("aisSpeedMin", value);
											}}
										/>
									</FleetFormItem>
									<FleetFormItem label="Max AIS Speed">
										<FleetInput
											value={undefined}
											placeholder="Enter Speed"
											inputRef={aisSpeedMaxInputRef}
											type="number"
											onChange={(value: number) => {
												onSearch?.("aisSpeedMax", value);
											}}
										/>
									</FleetFormItem>
									<FleetFormItem label="Min DWT">
										<FleetInput
											value={undefined}
											placeholder="Enter DWT"
											inputRef={dwtMinInputRef}
											type="number"
											onChange={(value: number) => {
												onSearch?.("dwtMin", value);
											}}
										/>
									</FleetFormItem>
									<FleetFormItem label="Max DWT">
										<FleetInput
											value={undefined}
											placeholder="Enter DWT"
											inputRef={dwtMaxInputRef}
											type="number"
											onChange={(value: number) => {
												onSearch?.("dwtMax", value);
											}}
										/>
									</FleetFormItem>
								</div>
							);
						}}
					/>
					<AreaVesselFilterItem<keyof typeof AreaVesselFilterHeadingMap, AreaVesselFilterHeadingMap>
						label="Heading"
						selectRows={item?.headingList}
						options={headingTypeOption}
						onSelect={(key, checked) => {
							const currentItem = [...item?.headingList];
							if (checked) {
								currentItem?.push(key);
							} else {
								const currentIndex = currentItem?.findIndex((selectItem) => selectItem === key);
								currentItem?.splice(currentIndex, 1);
							}
							onCommit?.({ headingList: currentItem });
						}}
					/>
					<AreaVesselFilterItem<keyof typeof AreaVesselFilterPurposeMap, AreaVesselFilterPurposeMap>
						label="Last Purpose"
						selectRows={item?.lastPurpose}
						options={purposeOption}
						onSelect={(key, checked) => {
							const currentItem = [...item?.lastPurpose];
							if (checked) {
								currentItem?.push(key);
							} else {
								const currentIndex = currentItem?.findIndex((selectItem) => selectItem === key);
								currentItem?.splice(currentIndex, 1);
							}
							onCommit?.({ lastPurpose: currentItem });
						}}
					/>
					<AreaVesselFilterItem<keyof typeof AreaVesselFilterPurposeMap, AreaVesselFilterPurposeMap>
						label="Last Country Name"
						onSelect={(key, checked) => {}}
						selectCount={item?.lastPortCountry?.length}
						render={() => (
							<Fragment>
								<RouteCommonOtherSelect<RouteCommonCountrySelectItemType>
									type="country"
									showKey="countryName"
									primaryKey="countryCode"
									activeItems={item?.lastPortCountry}
									onClear={() => {
										onCommit?.({ lastPortCountry: [] });
									}}
									onFinish={(newItem, type) => {
										const currentItem = [...item?.lastPortCountry];
										const currentIndex = currentItem?.findIndex(
											(selectItem) => selectItem?.countryCode === newItem?.countryCode
										);
										if (currentIndex === -1) {
											currentItem?.push(newItem);
										} else {
											currentItem?.splice(currentIndex, 1);
										}
										onCommit?.({ lastPortCountry: currentItem });
									}}
								/>
								{item?.lastPortCountry?.map((countryItem, countryIndex) => {
									return (
										<FleetCustomRadio
											value={true}
											key={countryItem?.countryCode}
											className="filter-item-select"
											onChange={(checked) => {
												const currentItem = [...item?.lastPortCountry];
												const currrentIndex = currentItem?.findIndex(
													(selectItem) => selectItem.countryCode === countryItem?.countryCode
												);
												currentItem?.splice(currrentIndex, 1);
												onCommit?.({ lastPortCountry: currentItem });
											}}
											label={countryItem?.countryName}
										/>
									);
								})}
							</Fragment>
						)}
					/>
					<AreaVesselFilterItem<keyof AreaVesselFilterItemType["startAreaList"][number], string>
						label="Last Area Name"
						selectCount={item?.startAreaList?.length}
						onSelect={(key, checked) => {}}
						render={() => (
							<Fragment>
								<RouteCommonOtherSelect<RouteCommonAreaSelectItemType>
									type="area"
									showKey="areaName"
									primaryKey="areaCode"
									onClear={() => {
										onCommit?.({ startAreaList: [] });
									}}
									activeItems={item?.startAreaList}
									onFinish={(newItem, type) => {
										const currentItem = [...item?.startAreaList];
										const currentIndex = currentItem?.findIndex(
											(selectItem) => selectItem?.areaCode === newItem?.areaCode
										);
										if (currentIndex === -1) {
											currentItem?.push(newItem);
										} else {
											currentItem?.splice(currentIndex, 1);
										}
										onCommit?.({ startAreaList: currentItem });
									}}
								/>
								{item?.startAreaList?.map((areaItem, areaIndex) => {
									return (
										<FleetCustomRadio
											value={true}
											key={areaItem?.areaCode}
											className="filter-item-select"
											onChange={(checked) => {
												const currentItem = [...item?.startAreaList];
												const currrentIndex = currentItem?.findIndex(
													(selectItem) => selectItem.areaCode === areaItem?.areaCode
												);
												currentItem?.splice(currrentIndex, 1);
												onCommit?.({ startAreaList: currentItem });
											}}
											label={areaItem?.areaName}
										/>
									);
								})}
							</Fragment>
						)}
					/>
					<AreaVesselFilterItem<keyof AreaVesselFilterItemType["areaList"][number], string>
						label="Selected Area Name"
						selectCount={item?.areaList?.length}
						render={() => (
							<Fragment>
								<RouteCommonOtherSelect<RouteCommonAreaSelectItemType>
									type="area"
									showKey="areaName"
									primaryKey="areaCode"
									activeItems={item?.areaList}
									onClear={() => {
										onCommit?.({ areaList: [] });
									}}
									onFinish={(newItem, type) => {
										const currentItem = [...item?.areaList];
										const currentIndex = currentItem?.findIndex(
											(selectItem) => selectItem?.areaCode === newItem?.areaCode
										);
										if (currentIndex === -1) {
											currentItem?.push(newItem);
										} else {
											currentItem?.splice(currentIndex, 1);
										}
										onCommit?.({ areaList: currentItem });
									}}
								/>
								{item?.areaList?.map((areaItem, areaIndex) => {
									return (
										<FleetCustomRadio
											value={true}
											key={areaItem?.areaCode}
											className="filter-item-select"
											onChange={(checked) => {
												const currentItem = [...item?.areaList];
												const currrentIndex = currentItem?.findIndex(
													(selectItem) => selectItem.areaCode === areaItem?.areaCode
												);
												currentItem?.splice(currrentIndex, 1);
												onCommit?.({ areaList: currentItem });
											}}
											label={areaItem?.areaName}
										/>
									);
								})}
							</Fragment>
						)}
					/>
					<AreaVesselFilterItem<keyof AreaVesselFilterItemType["desPortList"][number], string>
						label="Destination Port"
						selectCount={item?.desPortList?.length}
						onSelect={(key, checked) => {}}
						render={() => (
							<Fragment>
								<RouteCommonOtherSelect<RouteCommonPortSelectItemType>
									type="port"
									showKey="portName"
									primaryKey="portCode"
									activeItems={item?.desPortList}
									onClear={() => {
										onCommit?.({ desPortList: [] });
									}}
									onFinish={(newItem, type) => {
										const currentItem = [...item?.desPortList];
										const currentIndex = currentItem?.findIndex(
											(selectItem) => selectItem?.portCode === newItem?.portCode
										);
										if (currentIndex === -1) {
											currentItem?.push(newItem);
										} else {
											currentItem?.splice(currentIndex, 1);
										}
										onCommit?.({ desPortList: currentItem });
									}}
								/>
								{item?.desPortList?.map((portItem, portIndex) => {
									return (
										<FleetCustomRadio
											value={true}
											key={portItem?.portCode}
											className="filter-item-select"
											onChange={(checked) => {
												const currentItem = [...item?.desPortList];
												const currrentIndex = currentItem?.findIndex(
													(selectItem) => selectItem.portCode === portItem?.portCode
												);
												currentItem?.splice(currrentIndex, 1);
												onCommit?.({ desPortList: currentItem });
											}}
											label={portItem?.portName}
										/>
									);
								})}
							</Fragment>
						)}
					/>
					<AreaVesselFilterItem<keyof AreaVesselFilterItemType["desPortCountry"][number], string>
						label="Destination Country"
						selectCount={item?.desPortCountry?.length}
						render={() => (
							<Fragment>
								<RouteCommonOtherSelect<RouteCommonCountrySelectItemType>
									type="country"
									showKey="countryName"
									primaryKey="countryCode"
									onClear={() => {
										onCommit?.({ desPortCountry: [] });
									}}
									activeItems={item?.desPortCountry}
									onFinish={(newItem, type) => {
										const currentItem = [...item?.desPortCountry];
										const currentIndex = currentItem?.findIndex(
											(selectItem) => selectItem?.countryCode === newItem?.countryCode
										);
										if (currentIndex === -1) {
											currentItem?.push(newItem);
										} else {
											currentItem?.splice(currentIndex, 1);
										}
										onCommit?.({ desPortCountry: currentItem });
									}}
								/>
								{item?.desPortCountry?.map((countryItem, countryIndex) => {
									return (
										<FleetCustomRadio
											value={true}
											key={countryItem?.countryCode}
											className="filter-item-select"
											onChange={(checked) => {
												const currentItem = [...item?.desPortCountry];
												const currrentIndex = currentItem?.findIndex(
													(selectItem) => selectItem.countryCode === countryItem?.countryCode
												);
												currentItem?.splice(currrentIndex, 1);
												onCommit?.({ desPortCountry: currentItem });
											}}
											label={countryItem?.countryName}
										/>
									);
								})}
							</Fragment>
						)}
					/>
				</div>
			</div>
		</div>
	);
};

export default AreaVesselFilter;
