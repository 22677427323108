import React, { useMemo, useState } from "react";
import classnames from "classnames";
import style from "./index.module.less";
import { FleetButton } from "@/components";
import Checkbox from "antd/es/checkbox";
import type { CheckboxChangeEvent } from 'antd/es/checkbox';
import type { CheckboxValueType } from 'antd/es/checkbox/Group';
import { Button } from "@/_components";

export enum VoyageFormMap {
  ownerName = "Owner Name",
  vesselName = "Vessel Name",
  cpDate = "CP Date",
  tradingArea = "Trading Area",
  laycanTo = "Laycan To",
  laycanFrom = "Laycan From",
  delievryPort = "Delievry Port",
  deliveryDate = "Delivery Date",
  redeliveryPort = "Redelivery Port",
  estRedeliveryDate = "Est. Redelivery Date",
  cPNo = "CP No.",
  dailyHire = "Daily Hire",
  brokerName = "Broker Name",
  brokerage = "Brokerage",
  addComm = "Add. Comm.",
  iLOHC = "ILOHC",
  cVE = "CVE",
  paymentTerm = "Payment Term",
  paymentTiming = "Payment Timing",
  paymentMethod = "Payment Method",
  minDuration = "Min. Duration",
  maxDuration = "Max. Duration",
  ballastSpeed = "Ballast Speed",
  ballastIFO = "Ballast IFO",
  ballastMDO = "Ballast MDO",
  ballastLSG = "Ballast LSG",
  ladenSpeed = "Laden Speed",
  ladenIFO = "Laden IFO",
  ladenMDO = "Laden MDO",
  ladenLSG = "Laden LSG",
  portWorkIFO = "Port Work IFO",
  portWorkMDO = "Port Work MDO",
  portWorkLSG = "Port Work LSG",
  portIdleIFO = "Port Idle IFO",
  portIdleMDO = "Port Idle MDO",
  portIdleLSG = "Port Idle LSG",
  createdBy = "Created By",
  charteredBy = "Chartered By",
  operatedBy = "Operated By",
  status = "Status",
  option = "Option",
  commission = "Add. Commission",
  cargoLaycanFrom = "Cargo Laycan From",
  cargoLaycanTo = "Cargo Laycan To",
  cargoName = "Cargo Name",
  cargoQuantity = "Cargo Quantity",
  cargoUnit = "Cargo Unit",
  chartererName = "Charterer Name",
  dPTT = "DP TT",
  demurrageRate = "Demurrage Rate",
  despatchRate = "Despatch Rate",
  dischargePort = "Discharge Port",
  dischargeRate = "Discharge Rate",
  fixtureDate = "Fixture Date",
  fixtureNo = "Fixture No.",
  freightRate = "Freight Rate",
  freightType = "Freight Type",
  lPTT = "LP TT",
  laytimeTerm = "Laytime Term",
  loadPort = "Load Port",
  loadRate = "Load Rate",
  paymentRatio = "Payment Ratio",
  paymentType = "Payment Type",
  updatedDate = "Updated Date"
}

export type VoyageFormKeys = keyof typeof VoyageFormMap;

/**
 * 分类keys
 */
export type VoyageCagetoryKeys = "Basic" | "HireAndFees" | "Cargo Info" | "Payment Terms" | "Laytime" | "Setup" | "Duration" | "Performance";

export type CargoBookCategoryKeys = Extract<VoyageCagetoryKeys, "Basic" | "Cargo Info" | "Payment Terms" | "Laytime" | "Setup">;

export type HeadFixtureCategoryKeys = Extract<VoyageCagetoryKeys, "Basic" | "HireAndFees" | "Duration" | "Payment Terms" | "Performance" | "Setup">;

export type CargoBookKeys = Extract<VoyageFormKeys,
  "chartererName" | "fixtureDate" | "fixtureNo" | 
  "cargoName" | "cargoQuantity" | "cargoUnit" | "cargoLaycanFrom" | "cargoLaycanTo" | "loadPort" | "dischargePort" | "freightType" | "freightRate" | "commission" | "brokerName" | "brokerage" |
   "paymentType" | "paymentTiming" | "paymentRatio" | 
   "laytimeTerm" | "loadRate" | "lPTT" | "dischargeRate" | "dPTT" | "demurrageRate" | "despatchRate" | 
   "createdBy" | "charteredBy" | "operatedBy" | "updatedDate" | "status" | "option"
>;

export type HeadFixtureKeys = Extract<VoyageFormKeys,
  "ownerName" | "vesselName" | "cpDate" | "tradingArea" | "laycanFrom" | "laycanTo" | "delievryPort" | "deliveryDate" 
  | "redeliveryPort" | "estRedeliveryDate" | "cPNo" | "dailyHire" 
  | "brokerName" | "brokerage" | "addComm" | "iLOHC" 
  | "cVE" | "paymentTerm" | "paymentTiming" | "paymentMethod" 
  | "minDuration" | "maxDuration" | 
  "ballastSpeed" | "ballastIFO" | "ballastMDO" | "ballastLSG" | "ladenSpeed" | "ladenIFO" | "ladenMDO" | "ladenLSG" | "portWorkIFO" | "portWorkMDO" | "portWorkLSG" | "portIdleIFO" | "portIdleMDO" | "portIdleLSG"
   | "createdBy" | "charteredBy" | "operatedBy" | "updatedDate" | "status" | "option"
>
/**
 * checkout options
 */

type CargoBookOptionType = {
  value: CargoBookKeys;
  label: string;
  disabled?: boolean;
}

type CargoBookOptionsType = {
  key: CargoBookCategoryKeys;
  defaultValue?: string[];
  basicDisabled?: boolean;
  list: CargoBookOptionType[]
}

type HeadFixtureOptionType = {
  value: HeadFixtureKeys;
  label: string;
  disabled?: boolean;
}

type HeadFixtureOptionsType = {
  key: HeadFixtureCategoryKeys;
  defaultValue?: string[];
  basicDisabled?: boolean;
  list: HeadFixtureOptionType[]
}

export const CargoBookOptions: CargoBookOptionsType[] = [
  {
    key: "Basic",
    basicDisabled: true,
    list: [
      {
        label: "Charterer Name",
        value: "chartererName",

      },
      {
        label: "Fixture Date",
        value: "fixtureDate"
      },
      {
        label: "Fixture No.",
        value: "fixtureNo"
      },
    ]
  },
  {
    key: "Cargo Info",
    basicDisabled: true,
    list: [
      {
        label: "Cargo Name",
        value: "cargoName",
        disabled: true
      },
      {
        label: "Cargo Quantity",
        value: "cargoQuantity",
        disabled: true
      },
      {
        label: "Cargo Unit",
        value: "cargoUnit",
        disabled: true
      },
      {
        label: "Cargo Laycan From",
        value: "cargoLaycanFrom",
        disabled: true
      },
      {
        label: "Cargo Laycan To",
        value: "cargoLaycanTo",
        disabled: true
      },
      {
        label: "Load Port",
        value: "loadPort",
        disabled: true
      },
      {
        label: "Discharge Port",
        value: "dischargePort"
      },
      {
        label: "Freight Type",
        value: "freightType"
      },
      {
        label: "Freight Rate",
        value: "freightRate"
      },
      {
        label: "Add. Commission",
        value: "commission"
      },
      {
        label: "Broker",
        value: "brokerName"
      },
      {
        label: "Brokerage",
        value: "brokerage"
      }
    ]
  },
  {
    key: "Payment Terms",
    list: [
      {
        label: "Payment Type",
        value: "paymentType"
      },
      {
        label: "Payment Timing",
        value: "paymentTiming"
      },
      {
        label: "Payment Ratio",
        value: "paymentRatio"
      }
    ]
  },
  {
    key: "Laytime",
    list: [
      {
        "label": "Laytime Term",
        "value": "laytimeTerm"
      },
      {
        "label": "Load Rate",
        "value": "loadRate"
      },
      {
        "label": "LP TT",
        "value": "lPTT"
      },
      {
        "label": "Discharge Rate",
        "value": "dischargeRate"
      },
      {
        "label": "DP TT",
        "value": "dPTT"
      },
      {
        "label": "Demurrage Rate",
        "value": "demurrageRate"
      },
      {
        "label": "Despatch Rate",
        "value": "despatchRate"
      }
    ]
  },
  {
    key: "Setup",
    basicDisabled:true,
    list: [
      {
        "label": "Created By",
        "value": "createdBy"
      },
      {
        "label": "Chartered By",
        "value": "charteredBy"
      },
      {
        "label": "Operated By",
        "value": "operatedBy"
      },
      {
        "label": "Updated Date",
        "value": "updatedDate"
      },
      {
        "label": "Status",
        "value": "status",
        "disabled":true
      },
      {
        "label": "Option",
        "value": "option",
        "disabled":true
      }
    ]
  }
]

export const HeadFixtureOptions: HeadFixtureOptionsType[] = [
  {
    key: "Basic",
    basicDisabled: true,
    list: [
      {
        "value": "ownerName",
        "label": "Owner Name",
        disabled:true
      },
      {
        "value": "vesselName",
        "label": "Vessel Name",
        disabled:true
      },
      {
        "value": "cpDate",
        "label": "CP Date",
        disabled:true
      },
      {
        "value": "tradingArea",
        "label": "Trading Area",
        disabled:true
      },
      {
        "value": "laycanFrom",
        "label": "Laycan From",
        disabled:true
      },
      {
        "value": "laycanTo",
        "label": "Laycan To",
        disabled:true
      },
      {
        "value": "delievryPort",
        "label": "Delievry Port",
        disabled:true
      },
      {
        "value": "deliveryDate",
        "label": "Delivery Date",
        disabled:true
      },
      {
        "value": "redeliveryPort",
        "label": "Redelivery Port",
        disabled:true
      },
      {
        "value": "estRedeliveryDate",
        "label": "Est. Redelivery Date",
        disabled:true
      },
      {
        "value": "cPNo",
        "label": "CP No.",
        disabled:true
      }
    ]
  },
  {
    key: "HireAndFees",
    list: [
      {
        "value": "dailyHire",
        "label": "Daily Hire"
      },
      {
        "value": "brokerName",
        "label": "Broker Name"
      },
      {
        "value": "brokerage",
        "label": "Brokerage"
      },
      {
        "value": "addComm",
        "label": "Add. Comm."
      },
      {
        "value": "iLOHC",
        "label": "ILOHC"
      },
      {
        "value": "cVE",
        "label": "CVE"
      },
    ]
  }, {
    key: "Payment Terms",
    list: [
      {
        "value": "paymentTerm",
        "label": "Payment Term"
      },
      {
        "value": "paymentTiming",
        "label": "Payment Timing"
      },
      {
        "value": "paymentMethod",
        "label": "Payment Method"
      },
    ]
  },
  {
    key: "Duration",
    list: [
      {
        "value": "minDuration",
        "label": "Min. Duration"
      },
      {
        "value": "maxDuration",
        "label": "Max. Duration"
      },
    ]
  },
  {
    key: "Performance",
    list: [
      {
        "value": "ballastSpeed",
        "label": "Ballast Speed"
      },
      {
        "value": "ballastIFO",
        "label": "Ballast IFO"
      },
      {
        "value": "ballastMDO",
        "label": "Ballast MDO"
      },
      {
        "value": "ballastLSG",
        "label": "Ballast LSG"
      },
      {
        "value": "ladenSpeed",
        "label": "Laden Speed"
      },
      {
        "value": "ladenIFO",
        "label": "Laden IFO"
      },
      {
        "value": "ladenMDO",
        "label": "Laden MDO"
      },
      {
        "value": "ladenLSG",
        "label": "Laden LSG"
      },
      {
        "value": "portWorkIFO",
        "label": "Port Work IFO"
      },
      {
        "value": "portWorkMDO",
        "label": "Port Work MDO"
      },
      {
        "value": "portWorkLSG",
        "label": "Port Work LSG"
      },
      {
        "value": "portIdleIFO",
        "label": "Port Idle IFO"
      },
      {
        "value": "portIdleMDO",
        "label": "Port Idle MDO"
      },
      {
        "value": "portIdleLSG",
        "label": "Port Idle LSG"
      },
    ]
  },
  {
    key: "Setup",
    basicDisabled:true,
    list: [
      {
        "value": "createdBy",
        "label": "Created By"
      },
      {
        "value": "charteredBy",
        "label": "Chartered By"
      },
      {
        "value": "operatedBy",
        "label": "Operated By"
      },
      {
        "value": "updatedDate",
        "label": "Updated Date"
      },
      {
        "value": "status",
        "label": "Status",
        "disabled":true
      },
      {
        "value": "option",
        "label": "Option",
        disabled:true
      }
    ]
  }
]

const CheckboxGroup: React.FC<{
  checkedList: CheckboxValueType[],
  options: CargoBookOptionsType[] | HeadFixtureOptionsType[],
  category?: VoyageCagetoryKeys,
  onChange?: (val: any[]) => void,
  basicDisabled?: boolean,
  defaultValue?: string[]
}> = ({
  checkedList,
  options,
  category,
  defaultValue,
  basicDisabled,
  onChange
}) => {
    const indeterminate = useMemo(() => {
      return checkedList.length > 0 && checkedList.length < options.length;
    }, [checkedList, options]);

    const handleChange = (list: CheckboxValueType[]) => {
      onChange?.(list)
    };

    const onCheckAllChange = (e: CheckboxChangeEvent) => {
      onChange?.(e.target.checked ? options.map(item => item.value) : [])
    };

    return <div className={style["fleet-category-checkboxGroup"]}>
      <Checkbox disabled={basicDisabled} className="checkbox-all" indeterminate={indeterminate} onChange={onCheckAllChange} checked={options.length === checkedList.length}>
        {category}
      </Checkbox>
      {/* @ts-ignored */}
      <Checkbox.Group defaultValue={defaultValue} className="checkbox-group" options={options} value={checkedList} onChange={handleChange} />
    </div>
  }


const FleetCategory: React.FC<{
  className?: string;
  options?:CargoBookOptionsType[] | HeadFixtureOptionsType[];
  checkGroup?: Partial<Record<VoyageCagetoryKeys, any[]>>;
  onChange?: (key: VoyageCagetoryKeys, val: any[]) => void;
  onReset?: () => void;
}> = ({
  className,
  onReset,
  options,
  checkGroup,
  onChange
}) => {
    return <div onClick={(e) => e.stopPropagation()} className={classnames(style["fleet-category"], className)}>
      <div className="category-operate">
        Select Columns To Display
        <Button onClick={onReset} size="small">Reset Column</Button>
      </div>
      <div className="category-select">
        {
          options.map((item, idx) => {
            return <CheckboxGroup basicDisabled={item?.basicDisabled} category={item?.key} defaultValue={item?.defaultValue} checkedList={checkGroup[item.key]} key={idx} options={item.list} onChange={((val) => {
              onChange(item.key, val)
            })} />
          })
        }

      </div>
    </div>
  }

export default FleetCategory;