import React, { FC } from "react";
import classnames from "classnames";
import classes from "./index.module.less";
import { IconProps } from "./interface";

const Icon: FC<IconProps> = ({ className, size = 16, type, color, disabled, style, onClick }) => {
	const handleClick: React.MouseEventHandler<HTMLSpanElement> = (event) => {
		if (disabled) return;
		onClick?.(event);
	};
	return (
		<span
			style={{
				...style,
				fontSize: size,
				fill: color,
				color: color
			}}
			className={classnames(classes["fleet-icon"], "font_family", type, className, {
				"fleet-disabled": disabled
			})}
			onClick={handleClick}
		></span>
	);
};

export default Icon;
