import { createAxiosByinterceptors } from "./service";

const request = createAxiosByinterceptors({
	baseURL: process.env.API_BASE_HOST,
	headers: {
		// Origin: process.env.API_BASE_HOST
		// Authorization: cacheStorage.get('access_token')
	}
});

export default request;
