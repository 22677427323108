import React from "react";
import style from "./index.module.less";
import { FleetLoading } from "@/components";
import commonStyle from "../../index.module.less";
import classnames from "classnames";
import { CommonSelectListProps } from "./type";
import { Icon } from "@/_components";
import { initialUppercase } from "@/tools/string";

export default function CommonSelectList<T extends Record<string, string | number>>({
	showKey,
	list,
	loadMore,
	loading,
	onSelect
}: CommonSelectListProps<T>) {
	const handleSelect = (e: React.MouseEvent<HTMLUListElement, MouseEvent>, item: T) => {
		e?.stopPropagation();
		onSelect?.(item);
	};
	return (
		<div className={classnames(commonStyle["common-select--Popup"], style["common-select--list"])}>
			<FleetLoading loading={loading} />
			<ul className="select--popup--header">
				<span>{initialUppercase(showKey as string)}</span>
			</ul>
			<div className="select--popup--container fleet-nonScrolBar">
				<div className="select--popup--scrollArea">
					{list?.map((item, index) => {
						return (
							<ul
								key={index}
								className="select--popup--item"
								onClick={(e) => {
									handleSelect?.(e, item);
								}}
							>
								<li>{item?.[showKey]}</li>
							</ul>
						);
					})}
				</div>
			</div>
			<div className="select--popup--loadMore" onClick={loadMore}>
				<span>Load More</span>
				<Icon type="fleet-back" />
			</div>
		</div>
	);
}
