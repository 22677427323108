import { HfPaymentTermsFormType, HfPaymentTermsFormChangeEvent, HfPaymentTermsFormCommitEvent, initialPaymentTermsForm, checkPaymentTermsFormKeys } from "@/featrue/voyagemanage/headFixture/paymentTerms/type";
import { useState } from "react";


const usePayTermsForm = () => {
  const [payTermForm, setPayTermsForm] = useState<HfPaymentTermsFormType>({
    ...initialPaymentTermsForm
  });

  const change: HfPaymentTermsFormChangeEvent = (key, value) => {
    setPayTermsForm(prev => {
      return {
        ...prev,
        [key]: value
      }
    })
  }

  const commit: HfPaymentTermsFormCommitEvent = (item) => {
    setPayTermsForm(prev => {
      return {
        ...prev,
        ...item
      }
    })
  }

  const clear = () => {
    setPayTermsForm({
      ...initialPaymentTermsForm
    })
  }

  const check = () => {
    let checkKey = "";
    let checked = true;
    for(let item of checkPaymentTermsFormKeys) {
      if(payTermForm[item] !== 0 && !payTermForm[item]) {
        checkKey = item;
        checked = false;
        break;
      }
    }

    return {
      checked,
      checkKey
    }
  }

  return {
    payTermForm,
    change,
    commit,
    check,
    clear
  }
}

export default usePayTermsForm;