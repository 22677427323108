import React from "react";
import classnames from "classnames";
import style from "./index.module.less";
import { FleetButton, FleetTable } from "@/components";
import useGroup from "@/hook/database/task/useGroup";
import DatabaseTaskEdit from "@/featrue/database/task/edit";
import TaskFilter from "@/featrue/database/task/filter";
import { Button } from "@/_components";
import { CommonRoleWrap } from "@/common";

const DatabaseTask: React.FC<{}> = (props) => {
  const {
    loading,
    hide,
    record,
    type,
    columns,
    dataSource,
    pager,
    onPageChange,
    selectedRowKeys,
    onDeleteItems,
    onSelectChange,
    handleOpen,
    onSearch,
    onReset,
    setHide,
    onRow,
  } = useGroup();
  return (
    <div className={classnames(style["database-task"], "database-common")}>
      <div className="database-toolbar task-toolbar">
        <TaskFilter onSearch={onSearch} />
        <div className="task-operator">
          <CommonRoleWrap roleStr="task:list:batchDelete">
            <Button
              size="small"
              onClick={() => onDeleteItems("multiple")}
              disabled={selectedRowKeys?.length === 0}
              prefixIcon="fleet-delete"
            >
              Delete
            </Button>
          </CommonRoleWrap>
          <CommonRoleWrap roleStr="task:list:add">
            <Button
              onClick={() => handleOpen("add")}
              size="small"
              prefixIcon="fleet-add"
            >
              Add Task
            </Button>
          </CommonRoleWrap>
        </div>
      </div>
      <FleetTable
        columns={columns}
        className="database-table"
        dataSource={dataSource}
        loading={loading}
        id="id"
        pagination={{
          current: pager.pageNum,
          pageSize: pager.pageSize,
          total: pager.total,
          onChange: onPageChange,
          showSizeChanger: false,
        }}
        rowSelection={{
          selectedRowKeys,
          onChange: onSelectChange,
        }}
        onRow={onRow}
      />
      <DatabaseTaskEdit
        type={type}
        hide={hide}
        onClose={() => setHide(true)}
        onReset={onReset}
        taskId={record?.current?.id}
      />
    </div>
  );
};

export default DatabaseTask;
