import { IconProps } from "@/_components/Icon/interface";
import { ExtendsKeys } from "@/types";
import { FleetOptionType } from "@/types/advancedType";

export const typhoonOptions: FleetOptionType<
	"<32" | "43" | "50" | "58" | "70" | ">70",
	ExtendsKeys<
		IconProps["type"],
		| "fleet-typhoon"
		| "fleet-typhoon_32"
		| "fleet-typhoon42"
		| "fleet-typhoon50"
		| "fleet-typhoon58"
		| "fleet-typhoon70"
		| "fleet-typhoon_70"
	>
>[] = [
	{ label: "<32", value: "fleet-typhoon_32" },
	{ label: "43", value: "fleet-typhoon42" },
	{ label: "50", value: "fleet-typhoon50" },
	{ label: "58", value: "fleet-typhoon58" },
	{ label: "70", value: "fleet-typhoon70" },
	{ label: ">70", value: "fleet-typhoon_70" }
];
