import { cacheStorage } from "@/storage";
import { StorageKeyMap } from "@/storage/types";
import { PageRouterProps } from "@/router";
import { FleetRoleUnionKeys, FleetUserRoleKeys } from "@/types/role";

export interface UserStore {
	access_token: string;
	userInfo?: Record<keyof any, any>;
	remember: boolean;
	menus: PageRouterProps[];
	permissions: (FleetRoleUnionKeys | "*:*:*")[];
	roles: FleetUserRoleKeys[];
}

const userInfo = cacheStorage.get(StorageKeyMap["user_info"]);
const access_token = cacheStorage.get(StorageKeyMap["access_token"]);
const remember = cacheStorage.get(StorageKeyMap["remember"]);
const permissions = cacheStorage.get(StorageKeyMap["permissions"]);
const roles = cacheStorage.get(StorageKeyMap["roles"]);

export const initialUserState: UserStore = {
	access_token: access_token || "",
	userInfo: userInfo || {},
	remember: remember || false,
	menus: [],
	permissions: permissions || [],
	roles: roles || []
};
