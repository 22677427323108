import React from "react";
import classnames from "classnames";
import { Collapse } from "antd";
import style from "./index.module.less";
import { FleetTooltip } from "@/components";
import { MonitoringAisDetailSelectTimeKeys, MonitoringAisDetailsProps } from "./type";
import { convertToDms } from "@/tools/graph";
import dayjs from "dayjs";
import { monitoringAisDetailSelectTime } from "./source";
import { FleetAisDateSelect } from "@/common";
import { dateToUtcString } from "@/tools/date";
import { Popover } from "@/_components";

const MonitoringDetails: React.FC<MonitoringAisDetailsProps> = ({
	activeTime,
	type,
	data,
	loading,
	onAisItemSelect,
	onAisCancel,
	onAisStarAdd,
	onVesselArrivalOpen
}) => {
	const handleTimeSelect = (item: MonitoringAisDetailSelectTimeKeys) => {
		if (activeTime === item) return;
		onAisItemSelect?.(type, data, item);
	};

	return (
		<Collapse
			className={classnames(style["monitoring-aisDetails"], type)}
			items={[
				{
					key: "1",
					label: (
						<div className="aisDetails-header">
							<div className={classnames("header-warpper", type)}>
								<FleetTooltip
									trigger="hover"
									placement="bottom"
									overlayClassName={style["aisDetails-tooltip"]}
									outerLayer={
										<span className="header-vesselName">
											{data?.vesselName ?? data?.vesselNameEn}
										</span>
									}
									children={
										<span className="aisDetail-latLon">
											{data?.vesselName ?? data?.vesselNameEn}
										</span>
									}
								/>
								{["ais", "area", "port"]?.includes(type) && (
									<>
										<span className={classnames("header-tag", type)}>{type?.toLowerCase()}</span>
										{type === "ais" && (
											<span
												className={classnames(
													"font_family",
													data?.isCollect === 1 ? "fleet-collected active" : "fleet-collect"
												)}
												onClick={(e) => {
													e?.stopPropagation();

													onAisStarAdd?.(data);
												}}
											></span>
										)}
									</>
								)}
								{type === "vm" && (
									<div className="header-voyageNumber">
										<span>Voy. Number: </span>
										<FleetTooltip
											trigger="hover"
											placement="bottom"
											overlayClassName={style["aisDetails-tooltip"]}
											outerLayer={<span className="label">{data?.voyageNo}</span>}
											children={<span className="aisDetail-latLon">{data?.voyageNo}</span>}
										/>
									</div>
								)}
							</div>
							<span
								className="font_family fleet-close aisDetails-close"
								onClick={() => onAisCancel?.(type)}
							></span>
						</div>
					),
					children: (
						<div className="aisDetails-container">
							<div className="aisDetails-portContainer">
								<div className="port-item">
									<div className="port-name">
										<span className="font_family fleet-port"></span>
										<Popover
											trigger="hover"
											placement="bottom"
											overlayClassName={style["aisDetails-tooltip"]}
											content={
												<span>
													{["ais", "area", "port"]?.includes(type)
														? convertToDms(data?.lat, data?.lon)
														: data?.startPortName}
												</span>
											}
											children={
												<span className="aisDetail-latLon">
													{["ais", "area", "port"]?.includes(type)
														? convertToDms(data?.lat, data?.lon)
														: data?.startPortName}
												</span>
											}
										/>
									</div>
									{type === "vm" && (
										<span className="port-dateTime">
											{data?.startArrivedTime
												? dayjs(data?.startArrivedTime)?.format("YYYY-MM-DD HH:mm")
												: null}
										</span>
									)}
								</div>
								<span className="font_family fleet-arrow port-pointer"></span>
								<div className="port-item">
									<div className="port-name">
										<span className="font_family fleet-port"></span>
										<FleetTooltip
											trigger="hover"
											placement="bottom"
											overlayClassName={style["aisDetails-tooltip"]}
											outerLayer={
												<span>
													{["ais", "area", "port"]?.includes(type)
														? data?.desAisPort
														: data?.endPortName}
												</span>
											}
											children={
												<span className="aisDetail-latLon">
													{["ais", "area", "port"]?.includes(type)
														? data?.desAisPort
														: data?.endPortName}
												</span>
											}
										/>
									</div>
									{type === "vm" && (
										<span className="port-dateTime">
											{data?.endArrivedTime
												? dayjs(data?.endArrivedTime)?.format("YYYY-MM-DD HH:mm")
												: null}
										</span>
									)}
								</div>
							</div>
							<div className="aisDetails-otherContainer">
								<div className="otherContainer-item">
									<span className="label">Tracking:</span>
									<FleetAisDateSelect
										activeTime={activeTime}
										onSelect={handleTimeSelect}
										options={monitoringAisDetailSelectTime}
									/>
								</div>
								<div className="otherContainer-item">
									<span className="label">AIS Latest Time :</span>
									<span className="value">
										{data?.lastTime ?? data?.updateTime
											? dateToUtcString(data?.lastTime ?? data?.updateTime)
											: null}
										{/* {type === "vm" ? (data?.lastTime ? dayjs(data?.lastTime)?.format('YYYY-MM-DD HH:MM') : null) : (data?.updateTime ? dayjs(data?.updateTime)?.format('YYYY-MM-DD HH:MM') : null)} */}
									</span>
								</div>
								<div className="otherContainer-item">
									<span className="label">
										Speed: <span className="value">{data?.speed} kts</span>
									</span>
									<span className="label">
										ETA:{" "}
										<span className="value">{data?.eta ? dateToUtcString(data?.eta) : ""}</span>
									</span>
								</div>
							</div>
							<ul className="aisDetails-operator">
								<li
									className="operator-item"
									onClick={() => {
										onVesselArrivalOpen?.("arrival", type);
									}}
								>
									<span className="font_family fleet-particular"></span>
									<span>Particular</span>
								</li>
								<li
									className="operator-item"
									onClick={() => {
										onVesselArrivalOpen?.("aisData", type);
									}}
								>
									<span className="font_family fleet-chart"></span>
									<span>AIS Data</span>
								</li>
							</ul>
						</div>
					)
				}
			]}
			defaultActiveKey={["1"]}
		/>
	);
};

export default MonitoringDetails;
