// import "./lib/leaflet-1.9.4-src";
import "./lib/libBoot";
// import "./lib/Leaflet.VectorGrid.bundled";
// import "./lib/leaflet-mapbox-gl";
import React, { useEffect, useState, forwardRef, useImperativeHandle, useRef } from "react";
import style from "./index.module.less";
import "./css/PolylineMeasure.css";
import MeasureControl from "@/pages/wmap/components/MeasureControl";
import LegendControl from "@/pages/wmap/components/LegendControl";
import { AISStateInfo, DailyRepotyDataType, VesselStateInfo } from "@/pages/monitoring/components";
import { useMonitoringContext } from "@/pages/monitoring/MonitoringContext";
import MonitoringDailyReportIcon from "static/icons/monitoring_dailyreport_point.svg";
import MonitoringDailyReportIconHover from "static/icons/monitoring_dailyreport_point_hover.svg";
import MonitoringRouteplanPopupTitleIcon from "static/icons/monitoring_routeplan_popup_title_icon.png";
import MonitoringDailyReportSelectedIcon from "static/icons/monitoring_dailyreport_point_selected.svg";
import MonitoringPositionIcon from "static/image/monitor_position.png";
import useReminder from "@/hook/useReminder";
import { transform2DM, uuid } from "@/tools";
import dayjs from "dayjs";
import { formatThousandthNumber } from "@/tools/amount";
import { dateToUtcString } from "@/tools/date";
import { IconMap } from "@/components/Icon/icons";
import { commonGeoJson, lineStringGeoJson, vesselIconMap } from "./source";
import { convertToDms } from "@/tools/graph";
import { MeteoType, MonitoringMaRefType, MonitoringMapProps, SwitchType } from "./type";
import useVesselGlLayer from "./hooks/useVesselGlLayer";
import { loadImage } from "./utils/tool";
import useCreateVessels from "./hooks/useCreateVessels";
import useVesselFuture from "./hooks/useRoutePlan";
import mapboxgl from "mapbox-gl";
import { connectMapboxglToLeaflet } from "./tools";

console.log("mapboxgl", mapboxgl);

const L = window?.L;

connectMapboxglToLeaflet(L, mapboxgl);

let aisVesselLayer = new L.LayerGroup();
let vesselsLayer = new L.LayerGroup();
let vesselTrackLayer = new L.LayerGroup();
let aisVesselTrackLayer = new L.LayerGroup();
let notFollowAisLayer = new L.LayerGroup();
let portVesselLayer = new L.LayerGroup();
let areaVesselLayer = new L.LayerGroup();
let portVesselTrackLayer = new L.LayerGroup();
let areaVesselTrackLayer = new L.LayerGroup();
let routePlanLayerGroup = new L.LayerGroup();
let routePlanPointsLayerGroup = new L.LayerGroup();
let dailyReportLayerGroup = new L.LayerGroup();

export function calculateBearing(lat1, lon1, lat2, lon2) {
	const dLon = ((lon2 - lon1) * Math.PI) / 180;

	const y = Math.sin(dLon) * Math.cos((lat2 * Math.PI) / 180);
	const x =
		Math.cos((lat1 * Math.PI) / 180) * Math.sin((lat2 * Math.PI) / 180) -
		Math.sin((lat1 * Math.PI) / 180) * Math.cos((lat2 * Math.PI) / 180) * Math.cos(dLon);

	let angle = (Math.atan2(y, x) * 180) / Math.PI;
	if (angle < 0) {
		angle += 360;
	}

	return angle;
}
export function calculateDestinationPointAndBearing(coordinates, distanceRatio) {
	// 遍历坐标点，计算航行k公里后的位置
	var accumulatedDistance = 0;
	var destinationPoint = null;
	var bearing = null; // 延长线的角度
	coordinates = coordinates?.flat();
	for (var i = 0; i < coordinates.length - 1; i++) {
		var startPoint = coordinates[i];
		var endPoint = coordinates[i + 1];
		// 计算当前线段的长度
		var segmentLength = calculateDistance(startPoint[1], startPoint[0], endPoint[1], endPoint[0]);
		// 如果累积距离加上当前线段长度大于等于目标距离，则找到目标点
		if (accumulatedDistance + segmentLength >= distanceRatio) {
			var remainingDistance = distanceRatio - accumulatedDistance;
			bearing = calculateBearing(startPoint[1], startPoint[0], endPoint[1], endPoint[0]);
			destinationPoint = calculateDestinationPoint(
				startPoint[1],
				startPoint[0],
				endPoint[1],
				endPoint[0],
				remainingDistance
			);
			break;
		}
		// 累积距离
		accumulatedDistance += segmentLength;
	}

	bearing ||
		(bearing = calculateBearing(
			coordinates[coordinates.length - 2][1],
			coordinates[coordinates.length - 2][0],
			coordinates[coordinates.length - 1][1],
			coordinates[coordinates.length - 2][0]
		));
	destinationPoint || (destinationPoint = coordinates[coordinates.length - 1]);

	return { point: destinationPoint, bearing: bearing };
}

export function calculateDestinationPoint(lat1, lon1, lat2, lon2, distance) {
	if (distance == 0) {
		return [lon1, lat1];
	}
	if (calculateDistance(lat1, lon1, lat2, lon2) <= distance) {
		return [lon2, lat2];
	}

	// 将经纬度转换为弧度
	const toRadians = (angle) => angle * (Math.PI / 180);
	const toDegrees = (angle) => angle * (180 / Math.PI);

	// 地球半径（单位：千米）
	const earthRadius = 6371;

	// 将经纬度转换为弧度
	const φ1 = toRadians(lat1);
	const φ2 = toRadians(lat2);
	const Δλ = toRadians(lon2 - lon1);

	// 计算初始角度
	const y = Math.sin(Δλ) * Math.cos(φ2);
	const x = Math.cos(φ1) * Math.sin(φ2) - Math.sin(φ1) * Math.cos(φ2) * Math.cos(Δλ);
	const initialBearing = Math.atan2(y, x);

	// 计算终点坐标
	const angularDistance = distance / earthRadius;
	const φ3 = Math.asin(
		Math.sin(φ1) * Math.cos(angularDistance) +
			Math.cos(φ1) * Math.sin(angularDistance) * Math.cos(initialBearing)
	);
	const λ3 =
		toRadians(lon1) +
		Math.atan2(
			Math.sin(initialBearing) * Math.sin(angularDistance) * Math.cos(φ1),
			Math.cos(angularDistance) - Math.sin(φ1) * Math.sin(φ3)
		);

	// 将终点坐标转换为经度和纬度
	const lat3 = toDegrees(φ3);
	const lon3 = toDegrees(λ3);

	return [lon3, lat3];
}
export function calculateDistance(lat1, lon1, lat2, lon2) {
	const R = 6371393; // earth's mean radius in km
	const dLat = ((lat2 - lat1) * Math.PI) / 180;
	const dLon = ((lon2 - lon1) * Math.PI) / 180;
	(lat1 = (lat1 * Math.PI) / 180), (lat2 = (lat2 * Math.PI) / 180);

	const a =
		Math.sin(dLat / 2) * Math.sin(dLat / 2) +
		Math.cos(lat1) * Math.cos(lat2) * Math.sin(dLon / 2) * Math.sin(dLon / 2);
	const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
	let dist: string | number = R * c;

	if (dist >= 185200) {
		dist = (dist / 1852).toFixed(2);
	} else if (dist >= 18520) {
		dist = (dist / 1852).toFixed(2);
	} else if (dist >= 1852) {
		dist = (dist / 1852).toFixed(2);
	} else {
		dist = (dist / 1852).toFixed(3); // there's no subunit of Nautical Miles for horizontal length measurements. "Cable length" (1/10th of 1 nm) is rarely used
	}

	return parseFloat(dist);
}

const useLoadVesselImage = (mapboxgl: mapboxgl.Map, pointIconName: string, bgIconName: string) => {
	loadImage(mapboxgl, IconMap.EstimationVesselPointIcon, pointIconName);
	loadImage(mapboxgl, IconMap.EstimationVesselBgIcon, bgIconName);
};

const WMap = forwardRef<MonitoringMaRefType, MonitoringMapProps>(
	({ onVesselItemClick, onAisVesselItemClick, onAisDetailItemChange }, ref) => {
		const measureRef = useRef(null);
		const { reminder } = useReminder();
		const [meteoCore, setMeteoCore] = useState(null);
		const [glMap, setGlMap] = useState(null);
		const [routeplanData, setRouteplanData] = useState(null);
		const [dailyReportLayer, setDailyReportLayer] = useState(null);
		const {
			legendVisible,
			setLegendVisible,
			dailyReportSelectRow,
			setDailyReportSelectRow,
			currentVessel
		} = useMonitoringContext();
		const coordinatesController = useRef<HTMLDivElement>(null);

		const [mapStatus, setMapStatus] = useState(false);
		const renderQueue = useRef<(() => void)[]>([]);

		const [currentAisVessel, setCurrentAisVessel] = useState(null);

		const layerGroup = useRef<{
			[layerId: string]: {
				sourseId: string;
				layerIcon?: string;
			};
		}>({
			vmVesselTrackPointLineLayer: {
				sourseId: "vmVesselTrackPointsLineSource"
			},
			vmVesselTrackPointsLayer: {
				sourseId: "vmVesselTrackPointsSource",
				layerIcon: "vm-vessel-point-icon"
			},
			vmVesselTrackPointLabelLayer: {
				sourseId: "vmVesselTrackPointLabelSource",
				layerIcon: "vm-vessel-bg-icon"
			},
			aisVesselTrackPointLineLayer: {
				sourseId: "aisVesselTrackPointsLineSource"
			},
			aisVesselTrackPointsLayer: {
				sourseId: "aisVesselTrackPointsSource",
				layerIcon: "ais-vessel-point-icon"
			},
			aisVesselTrackPointLabelLayer: {
				sourseId: "aisVesselTrackPointLabelSource",
				layerIcon: "ais-vessel-bg-icon"
			},
			portVesselTrackPointLineLayer: {
				sourseId: "portVesselTrackPointsLineSource"
			},
			portVesselTrackPointsLayer: {
				sourseId: "portVesselTrackPointsSource",
				layerIcon: "port-vessel-point-icon"
			},
			portVesselTrackPointLabelLayer: {
				sourseId: "portVesselTrackPointLabelSource",
				layerIcon: "port-vessel-bg-icon"
			},
			areaVesselTrackPointLineLayer: {
				sourseId: "areaVesselTrackPointsLineSource"
			},
			areaVesselTrackPointsLayer: {
				sourseId: "areaVesselTrackPointsSource",
				layerIcon: "area-vessel-point-icon"
			},
			areaVesselTrackPointLabelLayer: {
				sourseId: "areaVesselTrackPointLabelSource",
				layerIcon: "area-vessel-bg-icon"
			}
		});

		const addTrackPoints = (trackPoints: any[], rest: [string, string, string]) => {
			const [layerName1, layerName2, layerName3] = rest;
			let direction = true;
			const coordinates: [number, number][] = [];
			const features = [];
			trackPoints?.map((item, idx) => {
				coordinates.push([item.lon, item.lat]);
				const id = uuid();
				features.push({
					type: "Feature",
					id: idx,
					geometry: {
						type: "Point",
						coordinates: [item.lon, item.lat]
					},
					// visibility: 'none',
					properties: {
						speed: formatThousandthNumber(item?.speed),
						time: dateToUtcString(item?.time),
						longitude: item?.lon,
						latitude: item?.lat,
						direction: direction ? -100 : 100,
						textDirection: direction ? -10 : 10,
						hidden: false,
						id
					}
				});
				direction = !direction;
			});
			glMap?.getSource(layerGroup.current[layerName1].sourseId)?.setData({
				type: "FeatureCollection",
				features: [
					{
						type: "Feature",
						geometry: {
							type: "LineString",
							coordinates: coordinates
						},
						properties: {}
					}
				]
			});
			glMap?.getSource(layerGroup.current[layerName2].sourseId)?.setData({
				type: "FeatureCollection",
				features: features
			});
			glMap?.getSource(layerGroup.current[layerName3].sourseId)?.setData({
				type: "FeatureCollection",
				features: features
			});
		};

		const {
			vectorLayer: vmVectorLayer,
			loadVesselGroup: loadVmVesselGroup,
			loadVesselAndTrack: loadVmVesselAndTrack,
			clearCurrentVesselAndTrack: clearVmCurrentVesselAndTrack
		} = useCreateVessels("vm", vesselTrackLayer, meteoCore, addTrackPoints);

		const {
			vectorLayer: aisVectorLayer,
			loadVesselGroup: loadAisVesselGroup,
			loadVesselAndTrack: loadAisVesselAndTrack,
			clearCurrentVesselAndTrack: clearAisCurrentVesselAndTrack
		} = useCreateVessels("ais", aisVesselTrackLayer, meteoCore, addTrackPoints);

		const {
			vectorLayer: notFollowAisVectorLayer,
			loadNotFollowVesselAndTrack,
			clearNotFollowVesselAndTrack
		} = useCreateVessels("ais", aisVesselTrackLayer, meteoCore, addTrackPoints);

		const {
			vectorLayer: portVectorLayer,
			loadVesselGroup: loadPortVesselGroup,
			loadVesselAndTrack: loadPortVesselAndTrack,
			clearCurrentVesselAndTrack: clearPortCurrentVesselAndTrack
		} = useCreateVessels("port", portVesselTrackLayer, meteoCore, addTrackPoints);

		const {
			vectorLayer: areaVectorLayer,
			loadVesselGroup: loadAreaVesselGroup,
			loadVesselAndTrack: loadAreaVesselAndTrack,
			clearCurrentVesselAndTrack: clearAreaCurrentVesselAndTrack
		} = useCreateVessels("area", areaVesselTrackLayer, meteoCore, addTrackPoints);

		const {
			addVesselTrackPointsLayer,
			addVesselTrackPointLabelLayer,
			addVesselTrackPointLineLayer
		} = useVesselGlLayer();

		const { loadVoyageOptions } = useVesselFuture();

		const meteoLayerControl = (meteoTypeKey, switchType) => {
			console.log("meteoLayerControl", meteoTypeKey, switchType);
			const meteoType = MeteoType[meteoTypeKey];

			let meteoTypeForControl;
			let switchTypeForControl;
			switch (meteoType) {
				case MeteoType.Wave:
				case MeteoType.Swell:
					meteoTypeForControl = "isobands";
					switchTypeForControl = switchType === SwitchType.On ? meteoType : "off";
					break;
				case MeteoType.Pressure:
					meteoTypeForControl = "isolines";
					switchTypeForControl = switchType === SwitchType.On ? meteoType : "off";
					break;
				case MeteoType.Current:
					meteoTypeForControl = "particlesAnim";
					switchTypeForControl = switchType;
					SwitchType.On === switchType && meteoCore.store.set("overlay", "wind");
					break;
				case MeteoType.Typhoon:
					if (meteoCore.map.hurricanesLayers) {
						switchType === SwitchType.On
							? meteoCore.map.hurricanesLayers.forEach((item) => item.addTo(meteoCore.map))
							: meteoCore.map.hurricanesLayers.forEach((item) => item.removeFrom(meteoCore.map));
						meteoTypeForControl = null;
					} else {
						meteoTypeForControl = "hurricanes";
						switchTypeForControl = switchType;
					}
					break;
				case MeteoType.Wind:
					meteoTypeForControl = "discrete";
					switchTypeForControl = switchType;
					break;
			}
			if (meteoTypeForControl) {
				meteoCore.store.set(meteoTypeForControl, switchTypeForControl);
			}
		};
		const voyageLayerControl = (layerIds, status) => {
			const visibilityValue = status === true ? "visible" : "none";
			debugger;
			layerIds?.forEach?.((layerId) => {
				glMap.setLayoutProperty(layerId, "visibility", visibilityValue);
			});
		};
		const getMeteoCalendar = () => {
			return meteoCore.store.get("calendar");
		};
		const setMeteoTime = (timestamp) => {
			meteoCore.store.set("timestamp", timestamp);
			updateVesselFuturePosition(timestamp);
		};
		const refreshVessels = (vessels: VesselStateInfo[]) => {
			if (!mapStatus) {
				renderQueue.current?.push(() => {
					loadVmVesselGroup(vesselsLayer, vessels);
				});
			} else {
				loadVmVesselGroup(vesselsLayer, vessels);
			}
		};

		const refreshAisVessels = (vessels: AISStateInfo[]) => {
			console.log("vessels", vessels);
			loadAisVesselGroup(aisVesselLayer, vessels);
		};

		const refreshAreaVessels = (vessels) => {
			loadAreaVesselGroup(areaVesselLayer, vessels);
		};

		const refreshPortVessel = (vessels) => {
			loadPortVesselGroup(portVesselLayer, vessels);
		};

		const loadRoutePlan = (geojson_line, geojson_points, loadData) => {
			setRouteplanData(loadData);
			loadRoutePlanGeojson(geojson_line);
			updateVesselFuturePosition(meteoCore.store.get("timestamp"), loadData);
		};
		const clearRoutePlan = () => {
			routePlanLayerGroup?.clearLayers();
			routePlanPointsLayerGroup?.clearLayers();
			setRouteplanData([]);
		};
		const loadDailyReport = (geojson) => {
			loadDailyReportGeojson(geojson);
		};
		const clearDailyReport = () => {
			dailyReportLayerGroup?.clearLayers();
		};
		const handleDailyReportIconChange = (newItem: DailyRepotyDataType) => {
			const [first] = dailyReportSelectRow;
			first && updateDailyReportIcon(first?.no + first?.positionStr, false);
			setDailyReportSelectRow(newItem ? [newItem] : []);
			newItem && updateDailyReportIcon(newItem?.no + newItem?.positionStr, true);
		};

		const handleFlyTo = (coordinates: [number, number]) => {
			const bounds = L.geoJson({
				type: "FeatureCollection",
				features: [
					{
						type: "Feature",
						geometry: {
							type: "Point",
							coordinates: coordinates
						}
					}
				]
			}).getBounds();

			const icon = L.icon({
				iconUrl: MonitoringPositionIcon,
				iconSize: [30, 30]
			});

			// L.marker([51.5, -0.09], { icon: icon }).addTo(meteoCore.map);

			L.marker(coordinates, { icon: icon }).addTo(meteoCore.map);

			// L.circle(coordinates, {
			// 	color: 'red',
			// 	fillColor: '#f03',
			// 	fillOpacity: 1,
			// 	radius: 500
			// }).addTo(meteoCore.map);

			meteoCore.map.fitBounds(bounds, {
				maxZoom: 5
			});
		};

		useImperativeHandle(ref, () => ({
			meteoLayerControl,
			voyageLayerControl,
			setMeteoTime,
			refreshVessels,
			loadVesselTrack,
			loadAisVesselAndTrack,
			loadVmVesselAndTrack,
			getMeteoCalendar,
			loadRoutePlan,
			clearRoutePlan,
			loadDailyReport,
			clearDailyReport,
			handleDailyReportIconChange,
			handleFlyTo,
			refreshAisVessels,
			clearVesselAndTrackLayers,
			loadVoyageOptions,
			refreshAreaVessels,
			refreshPortVessel
		}));

		const loadVesselTrack = (type: "vm" | "ais" | "notFollow" | "area" | "port", tracks) => {
			debugger;
			if (type === "ais" || type === "notFollow") {
				clearNotFollowVesselAndTrack(notFollowAisLayer, aisVesselTrackLayer);
			}
			switch (type) {
				case "notFollow":
					loadNotFollowVesselAndTrack(notFollowAisLayer, aisVesselTrackLayer, tracks);
					break;
				case "vm":
					loadVmVesselAndTrack(vesselsLayer, vesselTrackLayer, tracks);
					break;
				case "ais":
					loadAisVesselAndTrack(aisVesselLayer, aisVesselTrackLayer, tracks);
					break;
				case "port":
					loadPortVesselAndTrack(portVesselLayer, portVesselTrackLayer, tracks);
					break;
				case "area":
					loadAreaVesselAndTrack(areaVesselLayer, areaVesselTrackLayer, tracks);
					break;
				default:
					break;
			}
		};

		const clearVesselAndTrackLayers = (type: "vm" | "ais" | "notFollow" | "area" | "port") => {
			switch (type) {
				case "notFollow":
					clearNotFollowVesselAndTrack(notFollowAisLayer, aisVesselTrackLayer);
					break;
				case "vm":
					clearVmCurrentVesselAndTrack(vesselsLayer, vesselTrackLayer);
					break;
				case "ais":
					clearAisCurrentVesselAndTrack(aisVesselLayer, aisVesselTrackLayer);
					break;
				case "port":
					clearPortCurrentVesselAndTrack(portVesselLayer, portVesselTrackLayer);
					break;
				case "area":
					clearAreaCurrentVesselAndTrack(areaVesselLayer, areaVesselTrackLayer);
					break;
				default:
					break;
			}
		};

		const loadRoutePlanGeojson = (geojson_line) => {
			console.log("geojson_line", geojson_line);
			routePlanLayerGroup.clearLayers();
			const routeplanLineLayer = L.GridLayer.vectorGrid
				.slicer(geojson_line, {
					rendererFactory: L.svg.tile,
					pane: "overlayPane",
					style: {
						pointerEvents: "none"
					},
					vectorTileLayerStyles: {
						sliced: function (properties, zoom) {
							switch (properties.geometryType || properties.type) {
								// case 'mainRouteplanLine':
								//   return {
								//     weight: 5,
								//     color: '#6081CB',
								//     className: 'routeplan-line-path',
								//     stroke: 'red'
								//   }
								case "mainRouteplanLine1":
									return {
										weight: 4,
										color: "#365691",
										dashArray: [10, 15],
										className: "routeplan-line-path"
									};
								case "routeplanLine":
									return {
										weight: 4,
										color: "rgba(96,129,203,0.6)",
										className: "routeplan-line-path"
									};
							}
						}
					},
					interactive: true
				})
				.addTo(routePlanLayerGroup);

			routeplanLineLayer.on("click", (e) => {
				var properties = e.layer.properties;

				const positionLat = e.latlng.lat;
				const positionLng = e.latlng.lng;
				const routeplanInfoPopup = L.popup({
					closeButton: false,
					offset: [0, -3]
				});
				routeplanInfoPopup
					.setContent(
						"" +
							'<div class="polyline-measure-tooltip leaflet-zoom-animated" tabindex="0" role="button" style="box-shadow: none;margin-left: 4px; margin-top: 4px; width: 12px; height: 12px; z-index: 210;padding: 0;background: none; padding-right: 20px">' +
							'<div style="display: flex; column-gap: 10px">' +
							'<div class="polyline-measure-tooltip-position" style="color: #fff;font-size: 14px;font-weight: 400;text-align: left;width: 100%; display: flex;align-items: center;"><img  style="width: 12px; height: 12px; margin-right: 10px" src="' +
							MonitoringRouteplanPopupTitleIcon +
							'" /><span>' +
							properties.planType +
							"</span></div></div>" +
							'<div class="polyline-measure-tooltip leaflet-zoom-animated" tabindex="0" role="button" style="box-shadow: none;margin-left: 4px; margin-top: 4px; width: 12px; height: 12px; z-index: 210;padding: 0;background: none">' +
							'<div style="display: flex; column-gap: 10px"><div class="polyline-measure-tooltip-label" style="color: #C8C8C8;font-size: 12px;font-weight: 400">Start Time:</div>' +
							'<div class="polyline-measure-tooltip-position" style="color: #fff;font-size: 12px;font-weight: 400">' +
							dayjs(properties.startTime).format("YYYY/MM/DD HH:mm") +
							"【UTC】</div></div>" +
							'<div style="display: flex; column-gap: 10px"><div class="polyline-measure-tooltip-label" style="color: #C8C8C8;font-size: 12px;font-weight: 400">Distance:</div>' +
							"<div>" +
							'<span class="polyline-measure-tooltip-position" style="color: #fff;font-size: 12px;font-weight: 400">' +
							properties.totalDistance +
							" nm</span>" +
							"</div></div>" +
							'<div style="display: flex; column-gap: 10px"><div class="polyline-measure-tooltip-label" style="color: #C8C8C8;font-size: 12px;font-weight: 400">Time Spend:</div>' +
							"<div>" +
							'<span class="polyline-measure-tooltip-position" style="color: #fff;font-size: 12px;font-weight: 400">' +
							properties.totalTime +
							" Hours</span>" +
							"</div></div>" +
							'<div style="display: flex; column-gap: 10px"><div class="polyline-measure-tooltip-label" style="color: #C8C8C8;font-size: 12px;font-weight: 400">CP Speed:</div>' +
							"<div>" +
							'<span class="polyline-measure-tooltip-position" style="color: #fff;font-size: 12px;font-weight: 400">' +
							Math.round(properties.cpSpeed * 100) / 100 +
							" nm/h</span>" +
							"</div></div>" +
							'<div style="display: flex; column-gap: 10px"><div class="polyline-measure-tooltip-label" style="color: #C8C8C8;font-size: 12px;font-weight: 400">ETA:</div>' +
							"<div>" +
							'<span class="polyline-measure-tooltip-position" style="color: #fff;font-size: 12px;font-weight: 400">' +
							properties.eta +
							"【UTC】</span>" +
							"</div></div>" +
							"</div>"
					)
					.setLatLng([positionLat, positionLng])
					.openOn(routePlanLayerGroup);
			});
		};
		const loadRoutePlanPointsGeojson = (geojson_points) => {
			console.log("geojson_points", geojson_points);
			routePlanPointsLayerGroup.clearLayers();
			geojson_points.features.length > 0 &&
				L.GridLayer.vectorGrid
					.slicer(geojson_points, {
						rendererFactory: L.svg.tile,
						pane: "overlayPane",
						style: {
							overflow: "visible",
							pointerEvents: "none"
						},
						vectorTileLayerStyles: {
							sliced: function (properties, zoom) {
								return {
									icon: L.icon({
										iconUrl: vesselIconMap.routePlanVesselIcon,
										iconSize: [30, 30],
										rotationAngle: properties.cog
									})
								};
							}
						},
						interactive: true
					})
					.addTo(routePlanPointsLayerGroup);
		};
		const updateVesselFuturePosition = (time, routeplanDataParam) => {
			console.log("routeplanDataParam", time, routeplanDataParam);
			time -= 8 * 60 * 60 * 1000;
			const geometrys = {
				type: "FeatureCollection",
				features: []
			};
			var routeplan = routeplanDataParam || routeplanData;
			console.log("routeplanDataParam", time, routeplan);
			routeplan &&
				routeplan.length > 0 &&
				routeplan.forEach((dataItem) => {
					// 临时注释 1.正式：dataItem.startTime 2.测试：(new Date().getTime() - 8 * 60 * 60 * 1000)
					const timeSpan = Math.max(time - dataItem.startTime, 0);
					const distance = (timeSpan / 60 / 60 / 1000) * dataItem.cpSpeed;
					const destination = calculateDestinationPointAndBearing(
						dataItem.coors,
						distance,
						dataItem.totalDistance
					);

					const pointJson = {
						type: "Feature",
						geometry: {
							type: "Point",
							coordinates: destination.point
						},
						properties: {
							type: "trackPoint",
							cog: destination.bearing
						}
					};

					geometrys.features.push(pointJson);
				});

			loadRoutePlanPointsGeojson(geometrys);
		};
		const loadDailyReportGeojson = (geojson) => {
			dailyReportLayerGroup?.clearLayers();
			if (geojson?.features?.[0]?.geometry?.coordinates?.length > 0) {
				const dailyreportLayer = L.GridLayer.vectorGrid
					.slicer(geojson, {
						rendererFactory: L.svg.tile,
						pane: "featuresPane",
						style: {
							overflow: "visible",
							pointerEvents: "none"
						},
						vectorTileLayerStyles: {
							sliced: function (properties, zoom) {
								switch (properties.geometryType || properties.type) {
									// case 'dailyReportLine':
									//   return {
									//     weight: 1.5,
									//     color: '#3F63A3'
									//   }
									case "dailyReportPoint":
										return {
											icon: L.icon({
												iconUrl: MonitoringDailyReportIcon,
												iconSize: [20, 20],
												className: "dailyReportPointIcon"
											})
										};
								}
							}
						},
						interactive: true,
						getFeatureId: function (f) {
							return f.properties.no + f.properties.positionStr;
						}
					})
					.addTo(dailyReportLayerGroup);
				setDailyReportLayer(dailyreportLayer);
				const hoverDiv = L.SVG.create("image");
				hoverDiv.setAttribute("width", 30 + "px"),
					hoverDiv.setAttribute("height", 30 + "px"),
					hoverDiv.setAttribute("href", MonitoringDailyReportIconHover);
				dailyreportLayer.on("mouseover", (e) => {
					if (e.layer?.options?.icon?.options?.className === "dailyReportPointSelectedIcon") return;
					var properties = e.layer.properties;
					hoverDiv.style.display = "block";
					if (
						properties.type === "dailyReportPoint" ||
						properties.geometryType === "dailyReportPoint"
					) {
						hoverDiv.setAttribute("x", parseFloat(e.sourceTarget._path.getAttribute("x")) - 5),
							hoverDiv.setAttribute("y", parseFloat(e.sourceTarget._path.getAttribute("y")) - 5),
							e.sourceTarget._path.parentNode.insertBefore(hoverDiv, e.sourceTarget._path);
					}
				});
				dailyreportLayer.on("mouseout", (e) => {
					hoverDiv.style.display = "none";
				});
				// const bounds = L.geoJson(geojson).getBounds();
				// meteoCore.map.fitBounds(bounds);
			}
		};
		const updateDailyReportIcon = (id: string, checked: boolean) => {
			dailyReportLayer?.setFeatureStyle?.(id, {
				icon: L.icon({
					iconUrl: checked ? MonitoringDailyReportSelectedIcon : MonitoringDailyReportIcon,
					iconSize: checked ? [30, 30] : [20, 20],
					className: checked ? "dailyReportPointSelectedIcon" : "dailyReportPointIcon"
				})
			});
			dailyReportLayer?.redraw();
		};

		useEffect(() => {
			delete require.cache[require.resolve("./lib/libBoot")];
			require("./lib/libBoot");
			window.meteoApi(
				{
					verbose: false,
					lat: 32.99,
					lon: 105.78,
					zoom: 3
				},
				(core) => {
					console.log("core", core);
					window.core = core;
					setMeteoCore(core);
					core.store.set("product", "ecmwf");
					const mapboxMap = L.mapboxGL({
						accessToken:
							"pk.eyJ1IjoibGl0dGxlZml2ZTE5OTUiLCJhIjoiY2w1OWRtdTB3MmJqbTNjcXFpcWE4dDIxdSJ9.3ZH5BCZI085b4B9XVflZpg",
						style: "mapbox://styles/littlefive1995/clpgntuil00h201p97zby73r2",
						padding: 0,
						dragPan: true,
						pane: "mapboxgl-tile-pane",
						paneZindex: 399,
						mouseEvent: true
					}).addTo(core.map);

					console.log("layer init");

					const mapboxGlMap = mapboxMap.getMapboxMap();
					mapboxGlMap.on("load", () => {
						// 单独设置symbol-spacing属性
						mapboxGlMap.setLayoutProperty("ports", "icon-padding", 48);
						mapboxGlMap.setLayoutProperty("ports", "text-size", 10);
						mapboxGlMap.on("click", "ports", (e) => {
							const properties = e.features[0].properties;
							const portInfoPopup = L.popup({
								closeButton: true,
								offset: [0, 0],
								autoClose: true,
								closeOnClick: false
							});
							portInfoPopup
								.setContent(
									'<div class="polyline-measure-tooltip leaflet-zoom-animated" tabindex="0" role="button" style="box-shadow: none;margin-left: 4px; margin-top: 4px; width: 12px; height: 12px; z-index: 210; padding: 0;padding-right: 20px;background: none">' +
										'<div style="display: flex; column-gap: 10px"><div class="polyline-measure-tooltip-label" style="color: #C8C8C8;font-size: 12px;font-weight: 400">Position:</div>' +
										'<div class="polyline-measure-tooltip-position" style="color: #fff;font-size: 12px;font-weight: 400">' +
										`${transform2DM(properties.x)}${properties.x >= 0 ? "E" : "W"} ${transform2DM(properties.y)}${properties.y >= 0 ? "S" : "N"}` +
										"</div></div>" +
										'<div style="display: flex; column-gap: 10px"><div class="polyline-measure-tooltip-label" style="color: #C8C8C8;font-size: 12px;font-weight: 400">Country/Area:</div>' +
										"<div>" +
										'<span class="polyline-measure-tooltip-position" style="color: #fff;font-size: 12px;font-weight: 400">' +
										properties.country +
										"</span>" +
										"</div></div>" +
										'<div style="display: flex; column-gap: 10px"><div class="polyline-measure-tooltip-label" style="color: #C8C8C8;font-size: 12px;font-weight: 400">Port Code:</div>' +
										"<div>" +
										'<span class="polyline-measure-tooltip-position" style="color: #fff;font-size: 12px;font-weight: 400">' +
										properties.port_code +
										"</span>" +
										"</div></div>" +
										'<div style="display: flex; column-gap: 10px"><div class="polyline-measure-tooltip-label" style="color: #C8C8C8;font-size: 12px;font-weight: 400">Port Name:</div>' +
										"<div>" +
										'<span class="polyline-measure-tooltip-position" style="color: #fff;font-size: 12px;font-weight: 400">' +
										properties.port_name +
										"</span>" +
										"</div></div>" +
										"</div>"
								)
								.setLatLng(e.lngLat)
								.openOn(vesselTrackLayer);
						});
						setMapStatus(true);
						// 创建鼠标经纬度控件
						L.control
							.mousePosition({
								position: "bottomleft",
								emptyString: "没有经纬度数据",
								lngFirst: true,
								numDigits: 6,
								prefix: "经度:",
								separator: " | ",
								suffix: " 纬度:",
								showDecimal: true,
								showDMS: false,
								wrapLng: true,
								wrapLat: false,
								lngFormatter: function (lng) {
									return lng.toFixed(6);
								},
								latFormatter: function (lat) {
									return lat.toFixed(6);
								},
								formatter: function (lng: number, lat: number) {
									const rTextareaWrap = document.getElementById("bottom-container");
									// console.log("rTextareaWrap", rTextareaWrap.style.height);
									return `<div class="wmap-lnglat-control" style="bottom: ${rTextareaWrap.style.height}">Lat/Lng: ${convertToDms(lat, lng)}</div>`;
								}
							})
							.addTo(core.map);

						// mapboxGlMap.addControl(coordinatesControl)

						useLoadVesselImage(
							mapboxGlMap,
							layerGroup.current["vmVesselTrackPointsLayer"].layerIcon,
							layerGroup.current["vmVesselTrackPointLabelLayer"].layerIcon
						);
						useLoadVesselImage(
							mapboxGlMap,
							layerGroup.current["aisVesselTrackPointsLayer"].layerIcon,
							layerGroup.current["aisVesselTrackPointLabelLayer"].layerIcon
						);
						useLoadVesselImage(
							mapboxGlMap,
							layerGroup.current["portVesselTrackPointsLayer"].layerIcon,
							layerGroup.current["portVesselTrackPointLabelLayer"].layerIcon
						);
						useLoadVesselImage(
							mapboxGlMap,
							layerGroup.current["areaVesselTrackPointsLayer"].layerIcon,
							layerGroup.current["areaVesselTrackPointLabelLayer"].layerIcon
						);

						mapboxGlMap.addSource(layerGroup.current["vmVesselTrackPointLineLayer"].sourseId, {
							...lineStringGeoJson
						});
						mapboxGlMap.addSource(layerGroup.current["vmVesselTrackPointsLayer"].sourseId, {
							...commonGeoJson
						});
						mapboxGlMap.addSource(layerGroup.current["vmVesselTrackPointLabelLayer"].sourseId, {
							...commonGeoJson
						});

						mapboxGlMap.addSource(layerGroup.current["aisVesselTrackPointLineLayer"].sourseId, {
							...lineStringGeoJson
						});
						mapboxGlMap.addSource(layerGroup.current["aisVesselTrackPointsLayer"].sourseId, {
							...commonGeoJson
						});
						mapboxGlMap.addSource(layerGroup.current["aisVesselTrackPointLabelLayer"].sourseId, {
							...commonGeoJson
						});

						mapboxGlMap.addSource(layerGroup.current["portVesselTrackPointLineLayer"].sourseId, {
							...lineStringGeoJson
						});
						mapboxGlMap.addSource(layerGroup.current["portVesselTrackPointsLayer"].sourseId, {
							...commonGeoJson
						});
						mapboxGlMap.addSource(layerGroup.current["portVesselTrackPointLabelLayer"].sourseId, {
							...commonGeoJson
						});

						mapboxGlMap.addSource(layerGroup.current["areaVesselTrackPointLineLayer"].sourseId, {
							...lineStringGeoJson
						});
						mapboxGlMap.addSource(layerGroup.current["areaVesselTrackPointsLayer"].sourseId, {
							...commonGeoJson
						});
						mapboxGlMap.addSource(layerGroup.current["areaVesselTrackPointLabelLayer"].sourseId, {
							...commonGeoJson
						});

						addVesselTrackPointLineLayer(
							mapboxGlMap,
							"vmVesselTrackPointLineLayer",
							layerGroup.current["vmVesselTrackPointLineLayer"].sourseId
						);
						addVesselTrackPointsLayer(
							mapboxGlMap,
							"vmVesselTrackPointsLayer",
							layerGroup.current["vmVesselTrackPointsLayer"].sourseId,
							layerGroup.current["vmVesselTrackPointsLayer"].layerIcon
						);
						addVesselTrackPointLabelLayer(
							mapboxGlMap,
							"vmVesselTrackPointLabelLayer",
							layerGroup.current["vmVesselTrackPointLabelLayer"].sourseId,
							layerGroup.current["vmVesselTrackPointLabelLayer"].layerIcon
						);

						addVesselTrackPointLineLayer(
							mapboxGlMap,
							"aisVesselTrackPointLineLayer",
							layerGroup.current["aisVesselTrackPointLineLayer"].sourseId
						);
						addVesselTrackPointsLayer(
							mapboxGlMap,
							"aisVesselTrackPointsLayer",
							layerGroup.current["aisVesselTrackPointsLayer"].sourseId,
							layerGroup.current["aisVesselTrackPointsLayer"].layerIcon
						);
						addVesselTrackPointLabelLayer(
							mapboxGlMap,
							"aisVesselTrackPointLabelLayer",
							layerGroup.current["aisVesselTrackPointLabelLayer"].sourseId,
							layerGroup.current["aisVesselTrackPointLabelLayer"].layerIcon
						);

						addVesselTrackPointLineLayer(
							mapboxGlMap,
							"portVesselTrackPointLineLayer",
							layerGroup.current["portVesselTrackPointLineLayer"].sourseId
						);
						addVesselTrackPointsLayer(
							mapboxGlMap,
							"portVesselTrackPointsLayer",
							layerGroup.current["portVesselTrackPointsLayer"].sourseId,
							layerGroup.current["portVesselTrackPointsLayer"].layerIcon
						);
						addVesselTrackPointLabelLayer(
							mapboxGlMap,
							"portVesselTrackPointLabelLayer",
							layerGroup.current["portVesselTrackPointLabelLayer"].sourseId,
							layerGroup.current["portVesselTrackPointLabelLayer"].layerIcon
						);

						addVesselTrackPointLineLayer(
							mapboxGlMap,
							"areaVesselTrackPointLineLayer",
							layerGroup.current["areaVesselTrackPointLineLayer"].sourseId
						);
						addVesselTrackPointsLayer(
							mapboxGlMap,
							"areaVesselTrackPointsLayer",
							layerGroup.current["areaVesselTrackPointsLayer"].sourseId,
							layerGroup.current["areaVesselTrackPointsLayer"].layerIcon
						);
						addVesselTrackPointLabelLayer(
							mapboxGlMap,
							"areaVesselTrackPointLabelLayer",
							layerGroup.current["areaVesselTrackPointLabelLayer"].sourseId,
							layerGroup.current["areaVesselTrackPointLabelLayer"].layerIcon
						);
					});
					setGlMap(mapboxGlMap);
					core.map.on("zoom", () => {
						const zoom = core.map.getZoom();
						const iconPadding = Math.min(Math.max(0, 48 - (zoom - 2) * 12), 48);
						mapboxGlMap.setLayoutProperty("ports", "icon-padding", iconPadding);
					});
					core.map.createPane("measure");
					core.map.getPane("measure").style.zIndex = 9999;
					core.map.createPane("measure-line");
					core.map.getPane("measure-line").style.zIndex = 9998;
					measureRef?.current?.createMeasureController?.(core.map);

					aisVesselLayer.addTo(core.map);
					vesselsLayer.addTo(core.map);
					vesselTrackLayer.addTo(core.map);
					aisVesselTrackLayer.addTo(core.map);
					notFollowAisLayer.addTo(core.map);
					routePlanLayerGroup.addTo(core.map);
					dailyReportLayerGroup.addTo(core.map);
					routePlanPointsLayerGroup.addTo(core.map);
					portVesselLayer.addTo(core.map);
					areaVesselLayer.addTo(core.map);
					portVesselTrackLayer.addTo(core.map);
					areaVesselTrackLayer.addTo(core.map);
					// portsLayerGroup.addTo(core.map)
					core.store.on("hurricanes", (data) => {
						console.log("data", data);
						if (data === "no") {
							reminder("success", "No active typhoon");
						}
					});
				}
			);
			console.log("L", window.L);
		}, []);

		const handleVmVectorLayerClick = (e) => {
			var properties = e.layer.properties;
			let checkedVessel = properties.imo;
			// properties.type = properties.vesselStatus;
			if (properties.type === "vmVesselIconChecked") return;
			onVesselItemClick("init", properties, "7/day", false, true);
		};

		const handleAisVectorLayerClick = (e) => {
			console.log("vmVesselIconChecked", e);
			if (e.layer.properties.type === "vmVesselIconChecked") return;
			// if (e?.layer?.properties?.imo === currentAisVessel?.imo) {
			// 	// setCurrentAisVessel(null);
			// 	return;
			// }
			onAisVesselItemClick?.("ais", {
				imo: e?.layer?.properties.imo,
				id: e?.layer?.properties.id,
				isCollect: e?.layer?.properties.isCollect
			});
		};

		const handleAisNotFollowLayersClick = (e) => {
			debugger;
			console.log("handleAisNotFollowLayersClick", e);
			if (e.layer.properties.type === "vmVesselIconChecked") return;
			onAisDetailItemChange?.("notFollow");
		};

		const handlePortVectorLayerClick = (e) => {
			console.log("handleAisNotFollowLayersClick", e);
			if (e.layer.properties.type === "vmVesselIconChecked") return;
			console.log("port", e);
			onAisVesselItemClick?.("port", {
				imo: e?.layer?.properties.imo,
				id: e?.layer?.properties.id,
				isCollect: e?.layer?.properties.isCollect
			});
			// if (e?.layer?.properties?.imo === currentAisVessel?.imo) {
			// 	// setCurrentAisVessel(null);
			// 	return;
			// }
			// onAisVesselItemClick?.({
			// 	imo: e?.layer?.properties.imo,
			// 	id: e?.layer?.properties.id,
			// 	imo: e?.layer?.properties.id,
			// 	isCollect: e?.layer?.properties.isCollect
			// });
		};

		const handleAreaVectorLayerClick = (e) => {
			console.log("handleAreaVectorLayerClick", e);
			if (e.layer.properties.type === "vmVesselIconChecked") return;
			// if (e?.layer?.properties?.imo === currentAisVessel?.imo) {
			// 	// setCurrentAisVessel(null);
			// 	return;
			// }
			console.log("area", e);
			onAisVesselItemClick?.("area", {
				imo: e?.layer?.properties.imo,
				id: e?.layer?.properties.id,
				isCollect: e?.layer?.properties.isCollect
			});
			// onAisVesselItemClick?.({
			// 	imo: e?.layer?.properties.imo,
			// 	id: e?.layer?.properties.id,
			// 	imo: e?.layer?.properties.id,
			// 	isCollect: e?.layer?.properties.isCollect
			// });
		};

		const handledailyReportLayerClick = (e) => {
			handleDailyReportIconChange(e.layer.properties);
		};

		useEffect(() => {
			vmVectorLayer?.addEventListener("click", handleVmVectorLayerClick, false);
			aisVectorLayer?.addEventListener("click", handleAisVectorLayerClick, false);
			portVectorLayer?.addEventListener("click", handlePortVectorLayerClick, false);
			areaVectorLayer?.addEventListener("click", handleAreaVectorLayerClick, false);
			notFollowAisVectorLayer?.addEventListener("click", handleAisNotFollowLayersClick, false);
			return () => {
				vmVectorLayer?.removeEventListener("click", handleVmVectorLayerClick, false);
				aisVectorLayer?.removeEventListener("click", handleAisVectorLayerClick, false);
				portVectorLayer?.removeEventListener("click", handlePortVectorLayerClick, false);
				areaVectorLayer?.removeEventListener("click", handleAreaVectorLayerClick, false);
				notFollowAisVectorLayer?.removeEventListener("click", handleAisNotFollowLayersClick, false);
			};
		}, [
			vmVectorLayer,
			aisVectorLayer,
			portVectorLayer,
			areaVectorLayer,
			notFollowAisVectorLayer,
			currentVessel
		]);

		useEffect(() => {
			dailyReportLayer?.addEventListener("click", handledailyReportLayerClick, false);
			return () => {
				dailyReportLayer?.removeEventListener("click", handledailyReportLayerClick, false);
			};
		}, [dailyReportLayer, dailyReportSelectRow]);

		useEffect(() => {
			if (!mapStatus) return;
			console.log("mapStatus", mapStatus);
			while (renderQueue?.current?.length > 0) {
				const callback = renderQueue?.current?.shift();
				callback?.();
			}
		}, [mapStatus]);

		return (
			<div>
				<MeasureControl ref={measureRef}></MeasureControl>
				<LegendControl
					visible={legendVisible}
					onChange={(visible: boolean) => {
						setLegendVisible(visible);
					}}
				/>
				<div className={style["wMap"]} id={"windy"}></div>
			</div>
		);
	}
);

export default WMap;
