import React, { FC } from "react";
import classnames from "classnames";
import style from "./index.module.less";
import { RouteAreaItemProps } from "./type";
import { FleetCustomRadio } from "@/components/Radio";

const RouteAreaItem: FC<RouteAreaItemProps> = ({ mode, item, onDelete, onSelect }) => {
	return (
		<div
			className={classnames(style["route-areaItem"], {
				[style["route-areaItem--selected"]]: mode === "selectRows"
			})}
		>
			<FleetCustomRadio
				value={!!item?.isCurrentSelect}
				className="areaItem-preset-select"
				onChange={(checked) => {
					onSelect?.("single", item?.groupName, {
						...item,
						isCurrentSelect: item?.isCurrentSelect ? 0 : 1
					});
				}}
				label={item?.areaName}
			/>
		</div>
	);
};

export default RouteAreaItem;
