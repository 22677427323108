import React, { useEffect, useMemo } from "react";
import style from "./index.module.less";
import { OtherRemarksPropsType } from "./type";
import {} from "@/common";
import { FleetInput } from "@/components";
import useIndex from "./hook/useIndex";
import { otherRemarksTableConfig } from "./source";
import { FleetTimePicker } from "@/components/DatePicker";
import dayjs from "dayjs";
const OtherRemarks: React.FC<OtherRemarksPropsType> = ({
  otherRemarksData,
  onChange,
  addRow,
  deleteRow,
}) => {
  const {
    otherRemarksTableDisabled,
    onMouse,
    handleAdd,
    handleDelete,
    first,
    setFirst,
    setOtherRemarksDisabled,
  } = useIndex(addRow, deleteRow,otherRemarksData);
  useEffect(() => {
    if (otherRemarksData.length != 0 && first) {
      const rows = [];
      otherRemarksData.map((item, index) => {
        rows.push({});
        for (let [k, v] of Object.entries(item)) {
          rows[index][k] = true;
        }
      });
      setFirst(false);
      setOtherRemarksDisabled(rows);
    }
  }, [otherRemarksData]);
  return (
    <div className={style["portReport-otherRemarks"]}>
      <div className="port-report-title">Other Remarks</div>
      <div className="other-remarks-table">
        <table cellSpacing={0}>
          <thead className="other-remarks-table-thead">
            <tr>
              {otherRemarksTableConfig.map((item, index) => {
                return (
                  <th
                    colSpan={item.colSpan}
                    className="other-remarks-table-header"
                    key={index}
                  >
                    {item.label}
                  </th>
                );
              })}
            </tr>
          </thead>
          <tbody className="other-remarks-table-tbody">
            {otherRemarksData?.map((row, index) => {
              return (
                <tr key={index}>
                  {otherRemarksTableConfig.map((item, idx) => {
                    return (
                      <td colSpan={item.colSpan} key={idx}>
                        <div
                          onMouseEnter={() =>
                            onMouse(index, item.prop, "enter")
                          }
                          onMouseLeave={() =>
                            onMouse(index, item.prop, "leave")
                          }
                          className={`other-remarks-table-${item.prop}`}
                        >
                          {!otherRemarksTableDisabled[index]?.[item.prop] &&
                          !["action", "sno"].includes(item.prop) ? (
                            <FleetInput
                              value={row?.[item.prop]}
                              onChange={(val) =>
                                onChange(val, index, item.prop)
                              }
                            ></FleetInput>
                          ) : item.prop == "action" ? (
                            <div className="other-remarks-table-action">
                              <span
                                className="font_family fleet-delete"
                                onClick={() => handleDelete(index)}
                              ></span>
                            </div>
                          ) : (
                            row?.[item.prop] || "--"
                          )}
                        </div>
                      </td>
                    );
                  })}
                </tr>
              );
            })}
            {/* <tr>
              <td colSpan={7}>
                <span className="port-report-add" onClick={handleAdd}>
                  + ADD
                </span>
              </td>
            </tr> */}
            <tr></tr>
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default OtherRemarks;
