import { useState } from "react";
import { MovementTctcBunkerPlanType, MovementTctcBunkerPlanChangeEvent } from "../type";
import { initialTctcBunkerPlan } from "../source";

const useIndex = () => {
	const [dataSource, setDataSource] = useState<MovementTctcBunkerPlanType>(initialTctcBunkerPlan());

	const change: MovementTctcBunkerPlanChangeEvent = (key, value) => {
		setDataSource((prev) => ({ ...prev, [key]: value }));
	};

	const init = (item: MovementTctcBunkerPlanType) => {
		setDataSource(initialTctcBunkerPlan(item));
	};

	return {
		dataSource,
		change,
		init
	};
};

export default useIndex;
