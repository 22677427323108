import React, { FC, useMemo, useState } from "react";
import classnaems from "classnames";
import { type FleetIconProps, type FleetIconContainerProps, IconMap, useImage } from "./icons";
import { FleetLoading } from "@/components";
import style from "./index.module.less";

const IconContainer: React.FC<FleetIconContainerProps> = ({ error, src, loading }) => {
	if (error) return <img src={IconMap.ErrorImage} />;
	if (loading)
		return <FleetLoading loading={loading} loadingClassname="loading" className="mask-loading" />;

	return <img src={src} />;
};

const FleetIcon: React.FC<FleetIconProps> = ({ type, outerLayerClassname, onClick }) => {
	const { src, isLoading, error } = useImage({
		srcList: IconMap[type]
	});

	return (
		<div onClick={onClick} className={classnaems(style["fleet-icon"], outerLayerClassname)}>
			<IconContainer error={error} loading={isLoading} src={src} />
		</div>
	);
};

export default FleetIcon;
