import { useEffect, useRef, useState } from "react";
import { getCurrentIndex, getPortCodes } from "../tools";
import { useAppDispatch } from "@/hook";
import { useNavigate } from "react-router-dom";
import useReminder from "@/hook/useReminder";
import { getPortDistanceAction } from "@/action/estimation/cargoPort";
import { EstPortRotationItemType } from "../components/portRotation/components/item/type";
import { range } from "@/tools";

const useCalcDistance = (
	onPortItemCommit?: (
		needCommit: boolean,
		options: {
			distance: any;
			path: any;
			portGather: any;
			ecaDistance: any;
			indexList: number[];
		}
	) => void
) => {
	const dispatch = useAppDispatch();
	const navigate = useNavigate();
	const { reminder } = useReminder();

	const [loading, setLoading] = useState(false);

	const [refresh, setRefResh] = useState(-1);
	const options = useRef<{ indexList: number[]; portCodes: string; hasDeliveryPort: boolean }>({
		indexList: [],
		portCodes: "",
		hasDeliveryPort: false
	});

	const handleChangeCalcDistance = (
		currentIndex: number,
		deliveryPortCode: string,
		portItems: EstPortRotationItemType[],
		callback?: () => void
	) => {
		const indexList: number[] = [];
		let portCodes = getPortCodes(portItems, deliveryPortCode);
		const nextPortItem = portItems?.[currentIndex + 1];
		switch (true) {
			case !deliveryPortCode && currentIndex === 0:
				if (nextPortItem && nextPortItem?.portCode) {
					indexList.push(currentIndex + 1);
				}
				break;
			case currentIndex === portItems?.length - 1:
				indexList.push(currentIndex);
				break;
			default:
				indexList.push(currentIndex);
				if (nextPortItem && nextPortItem?.portCode) {
					indexList.push(currentIndex + 1);
				}
				break;
		}
		options.current = {
			indexList,
			portCodes,
			hasDeliveryPort: !!deliveryPortCode
		};
		callback?.();
		setRefResh((prev) => prev + 1);
	};

	const handleDeleteCalcDistance = (
		currentIndex: number,
		deliveryPortCode: string,
		portItems: EstPortRotationItemType[],
		callback?: () => void
	) => {
		const indexList: number[] = [];
		const ballastIndex = getCurrentIndex(portItems, "ballast"),
			ladenIndex = getCurrentIndex(portItems, "laden");
		switch (true) {
			case ballastIndex <= 1 && ladenIndex <= 1:
				reminder("info", "At least one or more Ballast or Laden type ports");
				return;
			case currentIndex === portItems?.length - 1:
				break;
			case currentIndex === 0 && !deliveryPortCode:
				indexList?.push(currentIndex + 1);
				break;
			case currentIndex === 0:
				const nextPortItem = portItems?.[currentIndex + 1];
				if (nextPortItem && nextPortItem?.portCode) {
					indexList.push(currentIndex);
				}
				break;
			default:
				const prevPortItem = portItems?.[currentIndex - 1];
				if (prevPortItem && prevPortItem?.portCode) {
					indexList.push(currentIndex);
				}
				break;
		}
		portItems?.splice(currentIndex, 1);
		let portCodes = getPortCodes(portItems, deliveryPortCode);
		options.current = {
			indexList,
			portCodes,
			hasDeliveryPort: !!deliveryPortCode
		};
		callback?.();
		setRefResh((prev) => prev + 1);
	};

	const getPortDistanceFront = () => {
		setLoading(true);
	};

	// const getPortDistanceSuccess = ({ data }) => {
	//   console.log("data", data, options?.current)
	//   const { distance, path, ports: portGather, ecaDistance } = data;
	//   if (!data || data?.length === 0) {
	//     return
	//   }
	//   onPortItemCommit?.({
	//     distance: options?.current?.hasDeliveryPort ? distance : [0, ...distance],
	//     ecaDistance: options?.current?.hasDeliveryPort ? ecaDistance : [0, ...ecaDistance],
	//     portGather,
	//     path,
	//     indexList: options?.current?.indexList
	//   })
	//   options.current = {
	//     indexList: [],
	//     portCodes: "",
	//     hasDeliveryPort: false
	//   }
	//   setLoading(false)
	// }

	const getPortDistanceFaild = (error) => {
		reminder("error", error?.msg);
		setLoading(false);
	};

	const handleCalcDeliveryDistance = (
		deliveryPortCode: string,
		portItems: EstPortRotationItemType[]
	) => {
		if (!deliveryPortCode && portItems?.length <= 1) return;
		const indexList: number[] = range(deliveryPortCode ? 0 : 1, portItems?.length - 1, 1);
		const portCodes = getPortCodes(portItems, deliveryPortCode);
		options.current = {
			indexList: indexList,
			portCodes: portCodes,
			hasDeliveryPort: !!deliveryPortCode
		};
		getPortDistance(false);
	};

	const getPortDistance = (needCommit: boolean = true) => {
		getPortDistanceAction(
			{ ports: options?.current?.portCodes },
			getPortDistanceFront,
			({ data }) => {
				console.log("data", data, options?.current);
				const { distance, path, ports: portGather, ecaDistance } = data;
				if (!data || data?.length === 0) {
					return;
				}
				debugger;
				onPortItemCommit?.(needCommit, {
					distance: needCommit
						? options?.current?.hasDeliveryPort
							? distance
							: [0, ...distance]
						: [],
					ecaDistance: needCommit
						? options?.current?.hasDeliveryPort
							? ecaDistance
							: [0, ...ecaDistance]
						: [],
					portGather,
					path,
					indexList: needCommit ? options?.current?.indexList : []
				});
				options.current = {
					indexList: [],
					portCodes: "",
					hasDeliveryPort: false
				};
				setLoading(false);
			},
			getPortDistanceFaild,
			dispatch,
			navigate
		);
	};

	useEffect(() => {
		if (refresh === -1) return;
		getPortDistance();
	}, [refresh]);

	return {
		loading,
		refresh,
		setRefResh,
		handleChangeCalcDistance,
		handleDeleteCalcDistance,
		handleCalcDeliveryDistance
	};
};

export default useCalcDistance;
