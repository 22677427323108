import { useState } from "react"
import { PerformanceItemChangeEvent, PerformanceItemCommitEvent, PerformanceItemType, PerformanceType } from "../type"
import { initialPerformance } from "../source"

const useIndex = () => {
  const [dataSourse, setDataSource] = useState<PerformanceType>({ ...initialPerformance() })

  const change: PerformanceItemChangeEvent = (type, currentIndex, key, value) => {
    if (type === "goodWeatherDef" || type === "performanceClause") {
      setDataSource(prev => {
        return {
          ...prev,
          [type]: value
        }
      })
      return
    }

    setDataSource(prev => {
      const currentSource = prev[type];
      switch (type) {
        case "ballast":
        case "laden":
          (currentSource as PerformanceItemType[])?.splice(currentIndex, 1, {
            ...currentSource?.[currentIndex],
            [key]: value
          })
          break
        case "work":
        case "idle":
          // @ts-ignore
          currentSource[key] = value
          break
        default:
          break
      }
      return {
        ...prev,
        [type]: currentSource
      }
    })
  }

  const commit: PerformanceItemCommitEvent = (item) => {
    setDataSource(prev => ({ ...prev, ...item }))
  }

  const init: PerformanceItemCommitEvent = (item) => {
    commit(initialPerformance(item))
  }

  return {
    dataSourse,
    change,
    commit,
    init
  }
}

export default useIndex