import { CommonOptionType } from "@/types/advancedType";

enum RouteCommonAisItemColorMap {
	Purple = "#B91CFD", // Bright Purple
	Pink = "#E538CF", // Vibrant Pink
	Blue = "#474BFF", // Electric Blue
	Red = "#FD451C", // Bright Red
	LightOrange = "#FFBA8C", // Light Orange
	Lavender = "#CE95FF", // Lavender
	LimeGreen = "#0CF000", // Neon Green
	DarkGray = "#990", // Dark Gray
	OliveGreen = "#8CC63E", // Olive Green
	ForestGreen = "#009345", // Forest Green
	SkyBlue = "#00C4EF", // Sky Blue
	AquaGreen = "#00FFA6" // Aqua Green
}

export const colorOptions: CommonOptionType<
	RouteCommonAisItemColorMap,
	keyof typeof RouteCommonAisItemColorMap
>[] = [
	{ label: RouteCommonAisItemColorMap.Purple, value: "Purple" },
	{ label: RouteCommonAisItemColorMap.Pink, value: "Pink" },
	{ label: RouteCommonAisItemColorMap.Blue, value: "Blue" },
	{ label: RouteCommonAisItemColorMap.Red, value: "Red" },
	{ label: RouteCommonAisItemColorMap.LightOrange, value: "LightOrange" },
	{ label: RouteCommonAisItemColorMap.Lavender, value: "Lavender" },
	{ label: RouteCommonAisItemColorMap.LimeGreen, value: "LimeGreen" },
	{ label: RouteCommonAisItemColorMap.DarkGray, value: "DarkGray" },
	{ label: RouteCommonAisItemColorMap.OliveGreen, value: "OliveGreen" },
	{ label: RouteCommonAisItemColorMap.ForestGreen, value: "ForestGreen" },
	{ label: RouteCommonAisItemColorMap.SkyBlue, value: "SkyBlue" },
	{ label: RouteCommonAisItemColorMap.AquaGreen, value: "AquaGreen" }
];
