import { PromptChildrenProps } from "@/common/Prompt";
import { MovementTaskGroupType } from "@/featrue/voyagemanage/movement/details/taskList/type";
import { FleetOptionType } from "@/types/advancedType";
import { FleetNumType, VoyageType } from "@/types/common";
import { CommonChangeEvent, CommonInitItemEvent } from "@/types/event";

export type DatabaseTaskStageKeys = keyof MovementTaskGroupType | "Running";

export type DatabaseTaskItemType = {
	id?: string;
	stage: DatabaseTaskStageKeys;
	contents: string;
	title: string;
	voyageType: VoyageType;
	sortNum: FleetNumType;
};

export type DatabaseTaskItemChangeEvent = CommonChangeEvent<DatabaseTaskItemType>;

export type DatabaseTaskEditProps = {
	type: "add" | "edit";
	taskId?: string | number;
	onReset?: () => void;
} & PromptChildrenProps;

export const initialTaskItem: CommonInitItemEvent<DatabaseTaskItemType> = (item) => {
	return {
		title: item?.title ?? "",
		stage: item?.stage ?? null,
		contents: item?.contents ?? "",
		voyageType: item?.voyageType ?? null,
		sortNum: item?.sortNum ?? 1
	};
};

export const checkTaskKeys: (keyof DatabaseTaskItemType)[] = [
	"title",
	"sortNum",
	"stage",
	"voyageType"
];

export const tcvcTaskStageOptions: FleetOptionType<DatabaseTaskStageKeys, DatabaseTaskStageKeys>[] =
	[
		{
			label: "Delivery",
			value: "Delivery"
		},
		{
			label: "Arrived LP",
			value: "Arrived LP"
		},
		{
			label: "Arrived DP",
			value: "Arrived DP"
		},
		{
			label: "Departed LP",
			value: "Departed LP"
		},
		{
			label: "Departed DP",
			value: "Departed DP"
		},
		{
			label: "Redelivery",
			value: "Redelivery"
		}
	];

export const tctcTaskStageOptions: FleetOptionType<DatabaseTaskStageKeys, DatabaseTaskStageKeys>[] =
	[
		{ label: "Delivery", value: "Delivery" },
		{ label: "Running", value: "Running" },
		{ label: "Redelivery", value: "Redelivery" }
	];
