import React, { FC, forwardRef, useImperativeHandle } from "react";
import style from "./index.module.less";
import { RouteCommonPortVesselProps, RouteCommonPortVesselRefType } from "./type";
import { PortVesselFilter, PortVesselList } from "./components";
import useIndex from "./hooks/useIndex";
import { FleetLoading } from "@/components";
import classnames from "classnames";

const RouteCommonPortVessel = forwardRef<RouteCommonPortVesselRefType, RouteCommonPortVesselProps>(
	({ onPortCallOpen, onVesselLoad, onVesselSelect }, ref) => {
		const {
			loading,
			filters,
			handleCommit,
			timeRange,
			handleTimeRangeChange,
			dataSource,
			handleReset,
			selectRows,
			handleRowSelect,
			handleDownload,
			activeDetailsOption
		} = useIndex(onVesselLoad);

		useImperativeHandle(ref, () => {
			return {
				onPortVesselReset: handleReset
			};
		});
		return (
			<div
				className={classnames(style["routeCommon-portVessel"], {
					"routeCommon-details--active":
						activeDetailsOption.activeTab === "portVessel" &&
						!activeDetailsOption?.isPortCallVisible
				})}
			>
				<FleetLoading loading={loading} />
				<PortVesselFilter
					item={filters}
					onCommit={handleCommit}
					timeRange={timeRange}
					onTimeRangeCommit={handleTimeRangeChange}
				/>
				<PortVesselList
					items={dataSource}
					selectRows={selectRows}
					onSelect={handleRowSelect}
					onDownload={handleDownload}
					onPortCallOpen={onPortCallOpen}
					onVesselSelect={onVesselSelect}
				/>
			</div>
		);
	}
);

export default RouteCommonPortVessel;
