import React, { FC, memo } from "react";
import style from "./index.module.less";
import { currentOptions } from "./source";
import classnames from "classnames";

const LegendCurrent: FC<{}> = (props) => {
	return (
		<div className={classnames("routeCommon-legend-item", style["legend-current"])}>
			<span className="routeCommon-legend-label">Current:</span>
			<ul className="legend-current-container">
				{currentOptions?.map((item) => {
					return <li key={item}>{item}</li>;
				})}
			</ul>
		</div>
	);
};

export default memo(LegendCurrent);
