import { ApiParameter, RequestApiMapType } from "@/api";
import request from "@/service";

import { RouteCommonAisDataParamsType, getRouteCommonAisDataParams } from "./type";
import { appVersion } from "@/api/constant";

const enum ApiMap {
	getRouteCommonAisDataParameter
}

export type ApiUrl = keyof typeof ApiMap;

const aisDataApi: ApiParameter<ApiUrl> = {
	getRouteCommonAisDataParameter: {
		method: "get",
		url: appVersion + "/ais/cmes/data/imo"
	}
};

export const getRouteCommonAisDataApi = (params: getRouteCommonAisDataParams) => {
	return request.request({
		...aisDataApi.getRouteCommonAisDataParameter,
		params
	});
};

export const aisDataApiMap: RequestApiMapType<ApiUrl, RouteCommonAisDataParamsType> = {
	getRouteCommonAisDataApi
};
