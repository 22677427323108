import React, { FC, useMemo, useState } from "react";
import classnames from "classnames";
import style from "./index.module.less";
import { AreaListTableProps } from "./type";
import { FleetLoading } from "@/components";
import { columns } from "./source";
import MovementTable from "@/pages/voyagemanage/business/movement/details/components/charterBase/common/table";
import { FleetCustomRadio } from "@/components/Radio";

const AreaListTable: FC<AreaListTableProps> = ({
	loading,
	tableData,
	selectedRows,
	onClick,
	onSelect,
	onSelectAll
}) => {
	return (
		<div className={classnames(style["area-list-table"])}>
			<FleetLoading loading={loading} />
			<FleetCustomRadio
				value={selectedRows.length != 0 && selectedRows.length == tableData.length}
				onChange={onSelectAll}
				className="portAreaListTable-selectAll"
			/>
			<MovementTable
				dataSource={tableData}
				columns={columns}
				otherSource={{ selectedRows }}
				className="area-table"
				actions={{ onSelect, onClick }}
			/>
			<span className="areaListTable-total">
				选中{`${selectedRows.length}/${tableData.length}`}条
			</span>
		</div>
	);
};

export default AreaListTable;
