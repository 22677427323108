import { useState } from "react";
import { useAppDispatch } from "@/hook";
import { useNavigate } from "react-router-dom";
import useReminder from "@/hook/useReminder";
import { setMovementMainPlanAction, updateMovementPlanAction } from "@/action/voyageManage/movement";
import { useEstCargoIndex, useEstVesselIndex } from "@/pages/estimation/business/details/components";
import usePortIndex from "./usePortIndex";
import useEstimation from "@/pages/estimation/business/details/hooks/useEstimation";
import useOtherIndex from "@/pages/estimation/business/details/hooks/useOtherIndex";
import { EstCompareItemType } from "@/pages/estimation/business/list/module";
import { useEstPlanIndex } from "../components";
import { MovementPlanItemType, MovementPlanListProps } from "../components/planList/type";

const useIndex = (voyageId: number) => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { reminder } = useReminder();

  const [loading, setLoading] = useState(false)

  const {
    dataSource: vesselItem,
    change: handleVesselItemChange,
    init: handleVesselItemInit,
    check: handleVesselItemCheck
  } = useEstVesselIndex(null, null, true)

  const {
    dataSource: cargoItem,
    change: handleCargoItemChange,
    commit: handleCargoItemCommit,
    init: handleCargoItemInit,
    check: handleCargoItemCheck
  } = useEstCargoIndex()

  const {
    loading: queryLoading,
    dataSource: portList,
    delPortIds,
    check: handlePortItemCheck,
    commit: handlePortItemCommit,
    change: handlePortItemChange,
    init: handlePortItemInit,
    add: handlePortItemAdd,
    handlePortCommit: handleEstPortCommit,
    handlePortItemDelete,
    handleCalcDistanceInit,
    handleBatchCommit,
    handleBatchFoConsumCommit
  } = usePortIndex()

  const {
    dataSource: otherItem,
    change: handleOtherItemChange,
    init: handleOtherItemInit
  } = useOtherIndex()

  const init = (item: EstCompareItemType) => {
    const {
      vesselParticular,
      cargoParticularList,
      portRotationList,
      ...rest
    } = item;
    handleVesselItemInit(vesselParticular)
    handleCargoItemInit(cargoParticularList?.[0])
    handlePortItemInit(portRotationList)
    handleOtherItemInit(rest)
    // portRotationList?.length >= 1 && handleCalcDistanceInit(portRotationList)
  }

  const check = () => {
    const { checked: vesselChecked, checkKey: vesselCheckKey } = handleVesselItemCheck();
    if (!vesselChecked) {
      reminder("error", `Field ${vesselCheckKey} is required`);
      return false;
    }

    const { checked: cargoChecked, checkKey: cargoCheckKey } = handleCargoItemCheck();
    if (!cargoChecked) {
      reminder("error", `Field ${cargoCheckKey} is required`);
      return false;
    }


    const { checked: portChecked, checkKey: portCheckKey } = handlePortItemCheck();
    if (portList?.length <= 0) {
      reminder("error", "No loading or unloading port selected");
      return false
    }
    if (!portChecked) {
      reminder("error", `Field ${portCheckKey} is required`);
      return false;
    }

    return true
  }

  const {
    calculate,
    quicklockForm
  } = useEstimation(
    portList,
    cargoItem,
    vesselItem,
    otherItem
  );

  const handlePlanSave = (item: MovementPlanItemType, callback?: (newPlanItem: MovementPlanItemType) => void) => {
    saveMovementPlan(item, callback)
  }

  const {
    activePlanIndex,
    activePlanItem,
    activePlanType,
    dataSource: planItems,
    select: handleEstPlanItemSelect,
    add: handleEstPlanItemAdd,
    init: handleEstPlanInit,
    commit: handleEstPlanCommit,
    handlePlanSet
  } = useEstPlanIndex(
    voyageId,
    setLoading,
    init,
    handlePlanSave
  )

  const getEsCompareFront = () => {
    setLoading(true)
  }

  const getEsCompareError = (error) => {
    reminder("error", error.msg);
    setLoading(false)
  }

  const setMovementMainPlanSuccess = (response) => {
    handlePlanSet(activePlanIndex)
    setLoading(false)
  }

  const setMovementMainPlan: MovementPlanListProps["onPlanSet"] = (currentIndex, currentItem) => {
    setMovementMainPlanAction(
      {
        id: voyageId,
        estResultId: currentItem?.estResultId
      },
      getEsCompareFront,
      setMovementMainPlanSuccess,
      getEsCompareError,
      dispatch,
      navigate
    )
  }

  const saveMovementPlan = (currentItem: MovementPlanItemType, callback?: (newPlanItem: MovementPlanItemType) => void) => {
    if (!check()) return
    const { id, ...restCargoItem } = cargoItem;
    const { id: vesselId, ...restVesselItem } = vesselItem;
    const newCargoItem = currentItem?.estResultId ? cargoItem : restCargoItem
    const newVesselItem = currentItem?.estResultId ? vesselItem : restVesselItem
    const portRotationList = portList?.map(item => {
      const { id, resultId, ...rest } = item;
      const newItem = {
        ...rest,
        cargoName: newCargoItem?.cargoName
      }
      if (!activePlanItem?.estResultId) {
        return newItem
      }
      return {
        ...newItem,
        resultId,
        id
      }
    })
    updateMovementPlanAction(
      {
        method: !currentItem?.estResultId ? "post" : "put",
        params: {
          ...calculate,
          ...otherItem,
          delPortIds: delPortIds?.current,
          voyageId: voyageId,
          id: currentItem?.estResultId,
          planName: currentItem?.planName,
          ecaDays: quicklockForm.totalEcaDays,
          ecaBallastDays: quicklockForm.ballastEcaDays,
          ecaLadenDays: quicklockForm.ladenEcaDays,
          seaDays: quicklockForm.totalSeaDays,
          seaBallastDays: quicklockForm.ballastSeaDays,
          seaLadenDays: quicklockForm.ladenSeaDays,
          portDays: quicklockForm.totalPortDays,
          portWorkDays: quicklockForm.portWorkDays,
          portIdleDays: quicklockForm.portIdleDays,
          status: "PVY",
          startVoyTime: new Date(otherItem.startVoyTime)?.getTime(),
          marginValue: calculate.totalMarginValue,
          remark: otherItem.remark,
          vesselParticular: newVesselItem,
          cargoParticularList: [newCargoItem],
          portRotationList: portRotationList
        }
      },
      getEsCompareFront,
      (response) => {
        if (callback) {
          callback?.(response?.data)
        } else {
          reminder("success", response?.msg);
          handleEstPlanCommit(activePlanIndex, response?.data)
        }
        setLoading(false)
      },
      getEsCompareError,
      dispatch,
      navigate
    )
  }

  return {
    queryLoading,
    loading,
    cargoItem,
    vesselItem,
    calculate,
    quicklockForm,
    handleCargoItemChange,
    handleVesselItemChange,
    handleCargoItemCommit,
    handleEstInit: init,
    setMovementMainPlan,
    portList,
    handlePortItemCommit,
    handlePortItemChange,
    handlePortItemAdd,
    handlePortItemDelete,
    handleEstPortCommit,
    otherItem,
    handleOtherItemChange,
    activePlanIndex,
    activePlanItem,
    activePlanType,
    planItems,
    handleEstPlanItemSelect,
    handlePlanAdd: handleEstPlanItemAdd,
    handleEstPlanInit,
    handleEstPlanCommit,
    handlePlanSave,
    handleBatchCommit,
    handleBatchFoConsumCommit
  }
}

export default useIndex;