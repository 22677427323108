import { ApiParameter, RequestApiMapType } from "@/api";
import request from "@/service";

import {
	RouteCommonAreaParamsType,
	getRouteCommonAreaGroupParams,
	updateRouteCommonAreaGroupParams,
	getRouteCommonAreaSelectRowsParams
} from "./type";
import { appVersion } from "@/api/constant";

const enum ApiMap {
	getRouteCommonAreaGroupParameter,
	updateRouteCommonAreaGroupParameter,
	getRouteCommonAreaSelectRowsParameter
}

export type ApiUrl = keyof typeof ApiMap;

const areaApi: ApiParameter<ApiUrl> = {
	getRouteCommonAreaGroupParameter: {
		method: "get",
		url: appVersion + "/moni/area/info/group"
	},
	updateRouteCommonAreaGroupParameter: {
		method: "put",
		url: appVersion + "/moni/area/info/latest"
	},
	getRouteCommonAreaSelectRowsParameter: {
		method: "get",
		url: appVersion + "/moni/area/info/select/list"
	}
};

export const getRouteCommonAreaGroupApi = (params: getRouteCommonAreaGroupParams) => {
	return request.request({
		...areaApi.getRouteCommonAreaGroupParameter,
		params
	});
};

export const updateRouteCommonAreaGroupApi = (params: updateRouteCommonAreaGroupParams) => {
	return request.request({
		...areaApi.updateRouteCommonAreaGroupParameter,
		data: params
	});
};

export const getRouteCommonAreaSelectRowsApi = () => {
	return request.request({
		...areaApi.getRouteCommonAreaSelectRowsParameter
	});
};

export const routeCommonAreaApiMap: RequestApiMapType<ApiUrl, RouteCommonAreaParamsType> = {
	getRouteCommonAreaGroupApi,
	updateRouteCommonAreaGroupApi,
	getRouteCommonAreaSelectRowsApi
};
