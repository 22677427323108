import React, { useEffect } from "react";
import DatabaseCommonAdd from "@/featrue/database/layout/common/add";
import { DatabasePortAddProps, PortType, portTypeMap, timeZones } from "./type";
import useAddPort from "@/hook/database/port/useAddPort";
import { FleetFormItem, FleetSelect, FleetVerifyInput } from "@/components";
import { CommonLatitudeSelect } from "@/common";
import Select from "@/components/_Select";

const DatabasePortAdd: React.FC<DatabasePortAddProps> = ({ hide, onClose, onReset }) => {
	const { dataSource, open, position, setOpen, change, addPort, loading, clear, latitudeClose } =
		useAddPort(onReset);

	useEffect(() => {
		if (hide) return;
		clear();
	}, [hide]);

	return (
		<DatabaseCommonAdd
			hide={hide}
			onClose={onClose}
			onAdd={addPort}
			title="Add Port"
			loading={loading}
		>
			<div className="commonAddItem">
				<span className="commonAddItem-label">Basis</span>
				<div className="commonAddItem-container">
					<FleetVerifyInput
						label="Port Name"
						needVerity
						value={dataSource?.portName}
						onChange={(val: string) => {
							change("portName", val);
						}}
					/>
					<FleetVerifyInput
						label="Chinese Name"
						value={dataSource?.portNameCn}
						onChange={(val: string) => {
							change("portNameCn", val);
						}}
					/>
					<FleetVerifyInput
						label="Alias"
						value={dataSource?.alias}
						onChange={(val: string) => {
							change("alias", val);
						}}
					/>
					<FleetFormItem label="Port Type">
						<FleetSelect
							options={portTypeMap}
							value={[dataSource?.portType]}
							onChange={(value: PortType) => {
								change("portType", value);
							}}
						/>
					</FleetFormItem>
					<FleetVerifyInput
						label="Port Code"
						needVerity
						value={dataSource?.portCode}
						onChange={(val: string) => {
							change("portCode", val);
						}}
					/>
					<FleetFormItem label="Position">
						<Select
							value={position}
							open={open}
							onDropVisible={setOpen}
							render={<CommonLatitudeSelect onClose={latitudeClose} open={open} />}
						/>
					</FleetFormItem>
					<FleetVerifyInput
						label="Country"
						value={dataSource?.country}
						onChange={(val: string) => {
							change("country", val);
						}}
					/>
					<FleetVerifyInput
						label="Country Code"
						value={dataSource?.countryCode}
						onChange={(val: string) => {
							change("countryCode", val);
						}}
					/>
					<FleetFormItem label="Time Zone">
						<FleetSelect
							options={timeZones}
							value={[dataSource?.timeZone]}
							onChange={(value: number) => {
								change("timeZone", value);
							}}
						/>
					</FleetFormItem>
					<FleetVerifyInput
						type="number"
						needVerity
						label="Draft Limitation"
						value={dataSource?.draftLimitation}
						onChange={(val: number) => {
							change("draftLimitation", val);
						}}
					/>
					<FleetVerifyInput
						label="Area"
						value={dataSource?.area}
						onChange={(val: string) => {
							change("area", val);
						}}
					/>
				</div>
			</div>
		</DatabaseCommonAdd>
	);
};

export default DatabasePortAdd;
