import mapboxgl, { EventData, MapLayerEventType, MapboxGeoJSONFeature } from "mapbox-gl";
import { MutableRefObject, useRef } from "react";
import { vesselGroupLayers } from "../source";
import { RouteCommonAisItemType, RouteLegItemType } from "../../list";
import { generateVesselInfoPopup } from "../utils";
import { RouteCommonCurrentStateItemType } from "@routeCommon/type";
import { useRouteCommonContext } from "@routeCommon/store";
import {
	RouteCommonPortVesselItemType,
	RouteCommonAreaVesselItemType
} from "@routeCommon/components";
import { transformStringToObj } from "../tools";

type RouteCommonCurrentStateGeoJson<T extends { currentState: RouteCommonCurrentStateItemType }> =
	GeoJSON.Feature<GeoJSON.Point, T> &
		Pick<MapboxGeoJSONFeature, "layer" | "source" | "sourceLayer" | "state">;

type GetCurrentVesselItemType<T extends { currentState: RouteCommonCurrentStateItemType }> =
	GeoJSON.Feature<GeoJSON.Point, T>;

const useVesselEvent = (mapboxGl: MutableRefObject<mapboxgl.Map>) => {
	const currentHoveredFeature =
		useRef<RouteCommonCurrentStateGeoJson<{ currentState: RouteCommonCurrentStateItemType }>>(null);
	const {
		activeLegVessel,
		setActiveLegVessel,
		activePortVessel,
		setActivePortVessel,
		activeAisVessel,
		activeAreaVessel,
		setActiveAisVessel,
		setActiveAreaVessel
	} = useRouteCommonContext();
	const vesselInfoPopup = useRef<mapboxgl.Popup>(null);

	console.log("setActiveLegVessel", activeLegVessel);

	const handleSetHoverImage = (
		type: "visible" | "hidden",
		currentFeature: RouteCommonCurrentStateGeoJson<{
			currentState: RouteCommonCurrentStateItemType;
		}>
	) => {
		console.log("handleSetHoverImage", currentFeature, type);
		if (type === "hidden" && !currentFeature) return;
		if (currentFeature?.layer?.id === vesselGroupLayers.leg) {
			if (type === "visible") {
				mapboxGl.current.setLayoutProperty(vesselGroupLayers.leg, "icon-image", [
					"case",
					[
						"all",
						[
							"==",
							["get", "voyageNo"],
							(currentFeature as RouteCommonCurrentStateGeoJson<RouteLegItemType>).properties
								.voyageNo ?? null
						],
						[
							"==",
							["get", "vesselStatus"],
							(currentFeature as RouteCommonCurrentStateGeoJson<RouteLegItemType>).properties
								.vesselStatus
						]
					],
					`legVesselHoverdIcon`,
					"legVesselIcon"
				]);
				currentHoveredFeature.current = currentFeature;
				return;
			}
			mapboxGl.current.setLayoutProperty(vesselGroupLayers.leg, "icon-image", "legVesselIcon");
			currentHoveredFeature.current = null;
			return;
		}
		const currentState = transformStringToObj(currentFeature?.properties?.currentState);
		const currentLayerId = currentFeature?.layer?.id,
			currentVesselTag = currentLayerId?.split("-")?.[0];
		if (currentFeature?.layer?.id === vesselGroupLayers.ais) {
			if (type === "visible") {
				mapboxGl.current.setLayoutProperty(currentLayerId, "icon-image", [
					"case",
					["==", ["get", "imo"], currentState?.imo],
					["concat", "routeAis", ["get", "color"], "VesselHoveredIcon"],
					["concat", "routeAis", ["get", "color"], "VesselIcon"]
				]);
				currentHoveredFeature.current = currentFeature;
				return;
			}
			mapboxGl.current.setLayoutProperty(currentLayerId, "icon-image", [
				"concat",
				"routeAis",
				["get", "color"],
				"VesselIcon"
			]);
			currentHoveredFeature.current = null;
			return;
		}
		if (type === "visible") {
			mapboxGl.current.setLayoutProperty(currentLayerId, "icon-image", [
				"case",
				["==", ["get", "imo"], currentState?.imo],
				`${currentVesselTag}VesselHoverdIcon`,
				`${currentVesselTag}VesselIcon`
			]);
			currentHoveredFeature.current = currentFeature;
			return;
		}
		mapboxGl.current.setLayoutProperty(
			currentLayerId,
			"icon-image",
			`${currentVesselTag}VesselIcon`
		);
		currentHoveredFeature.current = null;
		// const currentHoverSource = mapboxGl.current.getSource("vessel-hover-source") as GeoJSONSource;
		// if (type === "visible") {
		// 	currentHoverSource.setData({
		// 		type: "FeatureCollection",
		// 		features: [
		// 			{
		// 				type: "Feature",
		// 				geometry: {
		// 					type: "Point",
		// 					coordinates: currentFeature?.geometry?.coordinates
		// 				},
		// 				properties: {
		// 					...currentFeature?.properties
		// 				}
		// 			}
		// 		]
		// 	});
		// 	return;
		// }
		// currentHoverSource.setData({
		// 	type: "FeatureCollection",
		// 	features: []
		// });
	};

	const handleMapMouseEnter = (event: MapLayerEventType["mouseenter"] & EventData) => {
		if (vesselInfoPopup?.current) return;
		const selectFeature = event?.features?.[0] as unknown as RouteCommonCurrentStateGeoJson<{
			currentState: RouteCommonCurrentStateItemType;
		}>;
		console.log("handleMapMouseEnter", event, selectFeature, currentHoveredFeature);
		if (
			selectFeature?.layer?.id === currentHoveredFeature?.current?.layer?.id &&
			selectFeature?.properties?.currentState?.imo ===
				currentHoveredFeature?.current?.properties?.currentState?.imo
		)
			return;
		generateVesselInfoPopup?.(mapboxGl.current, event?.originalEvent, {
			dataSource: selectFeature?.properties?.currentState,
			position: selectFeature?.geometry?.coordinates as [number, number],
			popupElement: vesselInfoPopup,
			type: "newly"
		});
		handleSetHoverImage("visible", selectFeature);
		// handleSetHoverImage?.("visible", currentFeature);
	};

	const handleMapMouseLeave = (event: MapLayerEventType["mouseleave"] & EventData) => {
		const selectFeature = event?.features?.[0] as unknown as RouteCommonCurrentStateGeoJson<{
			currentState: RouteCommonCurrentStateItemType;
		}>;
		console.log("currentFeature", {
			event,
			selectFeature,
			currentHoveredFeature: currentHoveredFeature?.current
		});
		generateVesselInfoPopup?.(mapboxGl.current, event?.originalEvent, {
			dataSource: null,
			position: selectFeature?.geometry?.coordinates as [number, number],
			popupElement: vesselInfoPopup,
			type: "delete"
		});
		handleSetHoverImage("hidden", currentHoveredFeature?.current);
	};

	const handleLegVesselSelect = (event: MapLayerEventType["click"] & EventData) => {
		event?.preventDefault();
		const currentFeature = event
			?.features?.[0] as unknown as GetCurrentVesselItemType<RouteLegItemType>;
		console.log("currentFeature", {
			event,
			currentFeature,
			activeLegVessel
		});
		if (
			activeLegVessel &&
			activeLegVessel.voyageNo === currentFeature?.properties?.voyageNo &&
			activeLegVessel.vesselStatus === currentFeature?.properties?.vesselStatus
		)
			return;
		// if (
		// 	!activeLegVessel ||
		// 	(currentFeature.properties.voyageNo !== activeLegVessel?.voyageNo &&
		// 		currentFeature?.properties?.vesselStatus !== activeLegVessel?.vesselStatus)
		// ) {
		// 	setActiveLegVessel(currentFeature?.properties);
		// }
		setActiveLegVessel(currentFeature?.properties);
		// mapboxGl.current.setLayoutProperty(vesselGroupLayers.leg, "icon-image", [
		// 	"case",
		// 	[
		// 		"all",
		// 		["==", ["get", "voyageNo"], currentFeature.properties.voyageNo ?? null],
		// 		["==", ["get", "vesselStatus"], currentFeature.properties.vesselStatus]
		// 	],
		// 	`legVesselSelectedIcon`,
		// 	"legVesselIcon"
		// ]);
	};

	const handleAisVesselSelect = (event: MapLayerEventType["click"] & EventData) => {
		console.log("handleAisVesselSelect", {
			event,
			features: event?.features
		});
		// event?.preventDefault();
		event?.originalEvent?.stopImmediatePropagation();

		const currentFeature = event
			?.features?.[0] as unknown as GetCurrentVesselItemType<RouteCommonAisItemType>;
		const currentState = transformStringToObj(currentFeature?.properties?.currentState);
		if (currentState?.imo === activeAisVessel?.imo) return;
		if (!activeAisVessel || activeAisVessel?.imo !== currentState?.imo) {
			setActiveAisVessel(currentFeature?.properties);
		}
	};

	const handleAreaVesselSelect = (event: MapLayerEventType["click"] & EventData) => {
		event?.preventDefault();
		const currentFeature = event
			?.features?.[0] as unknown as GetCurrentVesselItemType<RouteCommonAreaVesselItemType>;
		const currentState = transformStringToObj(currentFeature?.properties?.currentState);
		if (currentState?.imo === activeAreaVessel?.imo) return;
		if (!activeAreaVessel || activeAreaVessel?.imo !== currentState?.imo) {
			setActiveAreaVessel(currentFeature?.properties);
		}
	};

	const handlePortVesselSelect = (event: MapLayerEventType["click"] & EventData) => {
		event?.preventDefault();
		const currentFeature = event
			?.features?.[0] as unknown as GetCurrentVesselItemType<RouteCommonPortVesselItemType>;
		console.log("portcurrentFeature", currentFeature);
		const currentState = transformStringToObj(currentFeature?.properties?.currentState);
		if (currentState?.imo === activePortVessel?.imo) return;
		if (!activePortVessel || activePortVessel?.imo !== currentState?.imo) {
			setActivePortVessel(currentFeature?.properties);
		}
		// mapboxGl.current.setLayoutProperty(vesselGroupLayers.port, "icon-image", [
		// 	"case",
		// 	["==", ["get", "imo"], currentFeature?.properties?.imo],
		// 	`portVesselSelectedIcon`,
		// 	"portVesselIcon"
		// ]);
	};

	const handleMapVesselMouseMove = (event: MapLayerEventType["mousemove"] & EventData) => {
		const selectFeature = event?.features?.[0] as unknown as RouteCommonCurrentStateGeoJson<{
			currentState: RouteCommonCurrentStateItemType;
		}>;

		if (vesselInfoPopup?.current && !selectFeature) {
			generateVesselInfoPopup?.(mapboxGl.current, event?.originalEvent, {
				dataSource: null,
				position: currentHoveredFeature?.current?.geometry?.coordinates as [number, number],
				popupElement: vesselInfoPopup,
				type: "delete"
			});
			handleSetHoverImage("hidden", currentHoveredFeature?.current);
			return;
		}
		if (
			vesselInfoPopup?.current &&
			currentHoveredFeature?.current?.layer?.id === selectFeature?.layer?.id
		) {
			const currentState = transformStringToObj(selectFeature?.properties?.currentState),
				hoveredState = transformStringToObj(
					currentHoveredFeature?.current?.properties?.currentState
				);
			if (currentState?.imo === hoveredState?.imo) return;
			vesselInfoPopup.current?.remove();
			vesselInfoPopup.current = null;
			currentHoveredFeature.current = null;
			generateVesselInfoPopup?.(mapboxGl.current, event?.originalEvent, {
				dataSource: selectFeature?.properties?.currentState,
				position: selectFeature?.geometry?.coordinates as [number, number],
				popupElement: vesselInfoPopup,
				type: "newly"
			});
			handleSetHoverImage("visible", selectFeature);
			return;
		}
		if (!vesselInfoPopup?.current && selectFeature) {
			generateVesselInfoPopup?.(mapboxGl.current, event?.originalEvent, {
				dataSource: selectFeature?.properties?.currentState,
				position: selectFeature?.geometry?.coordinates as [number, number],
				popupElement: vesselInfoPopup,
				type: "newly"
			});
			handleSetHoverImage("visible", selectFeature);
		}
	};

	const onVesselGroupListen = () => {
		if (!mapboxGl.current) return;
		mapboxGl.current?.on("mousemove", vesselGroupLayers.leg, handleMapVesselMouseMove);
		mapboxGl.current?.on("mouseleave", vesselGroupLayers.leg, handleMapMouseLeave);
		mapboxGl.current?.on("mousemove", vesselGroupLayers.ais, handleMapVesselMouseMove);
		mapboxGl.current?.on("mouseleave", vesselGroupLayers.ais, handleMapMouseLeave);
		mapboxGl.current?.on("mousemove", vesselGroupLayers.area, handleMapVesselMouseMove);
		mapboxGl.current?.on("mouseleave", vesselGroupLayers.area, handleMapMouseLeave);
		mapboxGl.current?.on("mousemove", vesselGroupLayers.port, handleMapVesselMouseMove);
		mapboxGl.current?.on("mouseleave", vesselGroupLayers.port, handleMapMouseLeave);
	};

	const onVesselGroupSelectRemove = () => {
		mapboxGl.current?.off("click", vesselGroupLayers.leg, handleLegVesselSelect);
		mapboxGl.current?.off("click", vesselGroupLayers.ais, handleAisVesselSelect);
		mapboxGl.current?.off("click", vesselGroupLayers.area, handleAreaVesselSelect);
		mapboxGl.current?.off("click", vesselGroupLayers.port, handlePortVesselSelect);
	};

	const onVesselGroupSelectListen = () => {
		mapboxGl.current?.on("click", vesselGroupLayers.leg, handleLegVesselSelect);
		mapboxGl.current?.on("click", vesselGroupLayers.ais, handleAisVesselSelect);
		mapboxGl.current?.on("click", vesselGroupLayers.area, handleAreaVesselSelect);
		mapboxGl.current?.on("click", vesselGroupLayers.port, handlePortVesselSelect);
	};

	return {
		onVesselGroupListen,
		onVesselGroupSelectListen,
		onVesselGroupSelectRemove
	};
};

export default useVesselEvent;
