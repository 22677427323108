import { FleetButton, FleetInput, FleetSelect } from "@/components";
import style from "./index.module.less";
import React, { useEffect, useMemo, useState } from "react";
import { segmentColumnConfig } from "./column";
import useIndex from "./hook/useIndex";
import { SegmentProps } from "./type";
import { segmentColumn } from "./source";
import { Button, Popover } from "@/_components";
const Segment: React.FC<SegmentProps> = ({ id, afterConfirm, type }) => {
  const {
    open,
    setOpen,
    handleChange,
    confirmSeaReportsWarning,
    handleAdd,
    segmentTable,
    handleGetSegment,
    handleSaveSegment
  } = useIndex(id, type, afterConfirm);
  useEffect(() => {
    if (open) {
      handleGetSegment()
    }
  }, [open]);
  const segmentContent = (setIsOpen) => {
    return (
      <div className={style["movementSeareport-segment"]}>
        <div className="segment-header">
          <span className="fleet-header">Warning</span>
          <Button type="secondary" size="small" onClick={handleAdd}>
            + Add
          </Button>
        </div>

        <div className="segment-container">
          <table cellSpacing={0}>
            <colgroup>
              {segmentColumn.map((item, index) => {
                return (
                  <col
                    className={`segmentTable-${item.prop}`}
                    key={index}
                  ></col>
                );
              })}
            </colgroup>
            <thead className="segmentTable-thead">
              <tr>
                {segmentColumn.map((ele) => {
                  return (
                    <th key={ele.prop} colSpan={ele.colSpan} rowSpan={1}>
                      {ele.label}
                    </th>
                  );
                })}
              </tr>
            </thead>
            <tbody className="segmentTable-tbody">
              {segmentTable.map((row, index) => {
                return (
                  <tr key={index}>
                    {segmentColumn.map((item, idx) => {
                      return (
                        <td colSpan={item.colSpan} key={idx}>
                          {segmentColumnConfig[item.prop]?.render(
                            row[item.prop],
                            handleChange,
                            index,
                            segmentTable.length
                          )}
                        </td>
                      );
                    })}
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
        <div className="warning-foot">
          <Button
            type="secondary"
            size="small"
            onClick={() => setOpen(false)}
          >
            Cancel
          </Button>
          <Button
            size="small"
            onClick={handleSaveSegment}
          >
            Confirm
          </Button>
        </div>
      </div>
    );
  };
  // const content = (

  // );
  return (
    <Popover
      placement={"top"}
      trigger="click"
      render={segmentContent}
      open={open}
      onOpenChange={(val) => setOpen(val)}
    >
      <Button type="secondary" size="small" prefixIcon="fleet-setting">
        Warning
      </Button>
    </Popover>
  );
};

export default Segment;
