import React, { useState } from "react";
import style from "./index.module.less";
import { MonitoringRoutePlanDownloadProps } from "./type";
import MonitoringRoutePlanModal from "../../common/Modal";
import { FleetCustomRadio } from "@/components/Radio";
import useIndex from "./hooks/useIndex";
import { FleetLoading } from "@/components";

const RoutePlanDownload: React.FC<MonitoringRoutePlanDownloadProps> = ({
  hide,
  onClose,
  currentRecord
}) => {
  const {
    loading,
    activeKey,
    handleChange,
    downloadRoutePlan
  } = useIndex(currentRecord,onClose)
  return <MonitoringRoutePlanModal
    title="Download"
    hide={hide}
    onClose={onClose}
    width={449}
    onConfirm={downloadRoutePlan}
  >
    <FleetLoading loading={loading} />
    <div className={style["routePlan-download"]}>
      <div className="download-container">
        <FleetCustomRadio
          value={activeKey === "A"}
          onChange={(val: boolean) => {
            handleChange("A")
          }}
          className="download-radio"
        />
        <div className="download-item">
          <span className="linear-measure">01°15.854'N</span>
          <span className="linear-measure">103°57.854'E</span>
        </div>
      </div>
      <div className="download-container">
        <FleetCustomRadio
          value={activeKey === "B"}
          onChange={(val: boolean) => {
            handleChange("B")
          }}
          className="download-radio"
        />
        <div className="download-item">
          <span>01</span>
          <span>15.864</span>
          <span>N</span>
          <span>103</span>
          <span>57.854</span>
          <span>E</span>
        </div>
      </div>
    </div>
  </MonitoringRoutePlanModal>
}

export default RoutePlanDownload