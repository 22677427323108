import React, { FC } from "react";
import style from "./index.module.less";
import { FleetImage } from "@/components";
import { routeCommonVesselIconMap } from "../../source";

export default function RouteCommonVesselFocusPopup() {
	return (
		<FleetImage
			src={routeCommonVesselIconMap.vesselHoveredIcon}
			className={style["routeCommon-map-vesselFocusPopup"]}
		/>
	);
}
