import { FleetAisSelectTimeProps } from "../AisTimeSelect/type";

export const options: FleetAisSelectTimeProps["options"] = [
  {
    label: "7",
    value: "7/day"
  },
  {
    label: "14",
    value: "14/day"
  },
  {
    label: "30",
    value: "30/day"
  },
  {
    label: "60",
    value: "60/day"
  },
  {
    label: "90",
    value: "90/day"
  },
  {
    label: "1Y",
    value: "1/year"
  },
  {
    label: "2Y",
    value: "2/year"
  }
]