import { useCallback, useMemo, useRef, useState } from "react";
import { OtherRemarksTableDisabledType } from "../type";
import useReminder from "@/hook/useReminder";
import dayjs from "dayjs";
const useIndex = (addRow,deleteRow,otherRemarksData) => {
  const [otherRemarksTableDisabled, setOtherRemarksDisabled] =
    useState<OtherRemarksTableDisabledType>([]);
    const [first, setFirst] = useState(true);
  const onMouse = (index, prop, type) => {
    if (type == "enter") {
      const newDisabled = JSON.parse(JSON.stringify(otherRemarksTableDisabled))
      newDisabled.splice(index, 1, {
        ...otherRemarksTableDisabled[index],
        [prop]: false,
      });
      setOtherRemarksDisabled(newDisabled);
    } else {
      const newDisabled = JSON.parse(JSON.stringify(otherRemarksTableDisabled))
      newDisabled.splice(index, 1, {
        ...otherRemarksTableDisabled[index],
        [prop]: true,
      });
      setOtherRemarksDisabled(newDisabled);
    }
  };
  const handleAdd = ()=>{
    const newDisabled = JSON.parse(JSON.stringify(otherRemarksTableDisabled))
    newDisabled.push({date:true,remark:true})
    setOtherRemarksDisabled(newDisabled);
    addRow({sno:otherRemarksData.length+1,remark:''})
  }
  const handleDelete = (index)=>{
    const newDisabled = JSON.parse(JSON.stringify(otherRemarksTableDisabled));
    newDisabled.splice(index,1);
    setOtherRemarksDisabled(newDisabled);
    deleteRow(index)
  }
  return {
    otherRemarksTableDisabled,
    onMouse,
    handleAdd,
    handleDelete,
    first,
    setFirst,
    setOtherRemarksDisabled
  };
};
export default useIndex;
