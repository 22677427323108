import { useState } from "react";
import { MovementTctcVesselBasicsProps } from "../type";
import { initialDataSource } from "../source";

const useIndex = () => {
	const [dataSource, setDataSource] =
		useState<MovementTctcVesselBasicsProps["item"]>(initialDataSource());

	const change: MovementTctcVesselBasicsProps["onChange"] = (key, value) => {
		setDataSource((prev) => {
			return {
				...prev,
				[key]: value
			};
		});
	};

	const commit: MovementTctcVesselBasicsProps["onCommit"] = (item) => {
		setDataSource((prev) => {
			return {
				...prev,
				...item
			};
		});
	};

	const init: MovementTctcVesselBasicsProps["onCommit"] = (item) => {
		commit(initialDataSource(item));
	};

	return {
		dataSource,
		change,
		commit,
		init
	};
};

export default useIndex;
