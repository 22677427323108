import React, { FC, useState } from "react";
import classnames from "classnames";
import style from "./index.module.less";
import { HandoverFormPortDetailsProps } from "./type";
import { FleetCustomRadio } from "@/components/Radio";
import MovementTable from "../../../movement/details/components/charterBase/common/table";
import { columns } from "./source";
import { Button } from "@/_components";
import { CommonNewlyCompany } from "@/common";

const HandoverFormPortDetails: FC<HandoverFormPortDetailsProps> = ({
	currentId,
	items,
	otherItem,
	onChange,
	onAdd,
	onCommit,
	onDelete,
	onOtherChange,
	onMove,
	onDndCommit
}) => {
	const [companyHide, setCompanyHide] = useState(true);
	const handleCompanyClose = () => {
		setCompanyHide(true);
	};
	return (
		<div
			id={currentId}
			className={classnames("handoverForm--common", style["handoverForm-portDetails"])}
		>
			<span className="handoverForm--common--header">Port Details</span>
			<div className="portDetails-container">
				<div className="portDetails--header">
					<div className="header--rate">
						<span>DEM Rate</span>
						<FleetCustomRadio
							value={otherItem?.dhd}
							label="DHD"
							onChange={(val: boolean) => {
								onOtherChange?.("dhd", val);
								val && onDndCommit?.();
							}}
						/>
					</div>
					<Button
						type="secondary"
						size="small"
						onClick={() => {
							onAdd?.();
						}}
					>
						Add Port
					</Button>
				</div>
				<MovementTable
					columns={columns}
					dataSource={items}
					className="portDetails-table"
					otherSource={{ dnd: otherItem?.dhd }}
					bordered
					actions={{
						onChange,
						onCommit,
						onDelete,
						onMove,
						onOpen: () => {
							setCompanyHide(false);
						}
					}}
				/>
				<CommonNewlyCompany
					type="add"
					hide={companyHide}
					onClose={handleCompanyClose}
					onConfirm={handleCompanyClose}
				/>
			</div>
		</div>
	);
};

export default HandoverFormPortDetails;
