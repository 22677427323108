import { InvCommonFieldItemType } from "./module";

export const initialInvCommonFieldItem = (type: "init" | "details", item?: Partial<InvCommonFieldItemType>): InvCommonFieldItemType => {
  return {
    fieldName: item?.fieldName ?? "",
    fieldVal: item?.fieldVal ?? "",
    description: item?.description ?? "",
    id: type === "details" && item?.id ? item?.id : null,
    invId: type === "details" && item?.invId ? item?.invId : null,
    type: item?.type ?? ""
  }
}