import { ExcludeKeys, ExtendsKeys } from "@/types";
import { CargoBookLabelMap, CargoBookKeys } from "@/types/voyageManage/cargoBook/index";
import { Dayjs } from "dayjs";
import { CBBasicFormChangeEvent } from "../basic/type";

export type CBfreightPayTermKeys = ExtendsKeys<CargoBookKeys,
  "paymentTerm" | "paymentRate" | "paymentStartDay"
>;

export type CBPaymentTermsNumKeys = ExtendsKeys<CBfreightPayTermKeys,
  "paymentRate" | "paymentTerm"
>;

export type CBPaymentTermsStrKeys = ExcludeKeys<CBfreightPayTermKeys, CBPaymentTermsNumKeys>;


export type CBPaymentTermsFormKeys = CBPaymentTermsNumKeys | CBPaymentTermsStrKeys;


export type CBPaymentTermsFormType =
  Record<CBPaymentTermsStrKeys, string> &
  Record<CBPaymentTermsNumKeys, number | "">;

/**
 * hooks type
 */

export type CBPaymentTermsFormChangeEvent = <K extends CBPaymentTermsFormKeys>(key: K, value: CBPaymentTermsFormType[K]) => void;

export type CBPaymentTermsFormCommitEvent = (item: Partial<CBPaymentTermsFormType>) => void;

/**
 * hfbasicprops type
 */

export type CBPaymentTermsFormProps = {
  item: CBPaymentTermsFormType;
  onChange: CBPaymentTermsFormChangeEvent;
  // onCargoBasicChange:CBBasicFormChangeEvent
  onCommit?: CBPaymentTermsFormCommitEvent;
  isPreOpNewVoyage?: boolean;
  otherItem?: {
    isPreOpNewVoyage?: boolean;
    layCanTo: number | "";
  }
}

/**
 * init data
 */
export const initialPaymentTermsForm: CBPaymentTermsFormType = {
  paymentRate: "",
  paymentTerm: "",
  paymentStartDay: ""
}

export const checkCBPaymentTermsFormKeys: Partial<CBPaymentTermsFormKeys>[] = [
  "paymentRate"
]