import React, { useMemo, useRef } from "react";
import style from "./index.module.less";
import { FleetChart, FleetFormItem, FleetInput, FleetLoading } from "@/components";
import { FleetAisDateSelect } from "@/common";
import { options } from "./source";
import { FleetAisDataProps } from "./type";
import useIndex from "./hooks/useIndex";
import { Button } from "@/_components";
import dayjs from "dayjs";

export type AisDataItem = {
	title?: string;
	content?: string;
};

const FleetAisData: React.FC<FleetAisDataProps> = ({
	onChange,
	type,
	loading,
	dataSource,
	onVesselArrivalOpen
}) => {
	const chartRef = useRef<HTMLDivElement>(null);

	// const data = useMemo(() => {
	// 	return dataSource?.speeds?.map((item) => [item?.time, item?.speed]);
	// }, [dataSource?.speeds]);
	useIndex({
		root: chartRef.current,
		data: dataSource.speeds,
		dateRange: [
			dayjs(dataSource?.speeds?.[0]?.time)?.format("YYYY-MM-DD"),
			dayjs(dataSource?.speeds?.at(-1)?.time)?.format("YYYY-MM-DD")
		],
		cpSpeed: dataSource?.speed,
		dateLength: +dataSource.days?.split("/")?.[0]
	});

	console.log("FleetAisData", dataSource);

	return (
		<div className={style["fleet-aisData"]}>
			{type === "est" && (
				<div className="aisData-header">
					<span className="details-title">AIS Data</span>
					<div className="aisData-toolbar">
						<Button size="small" onClick={onVesselArrivalOpen}>
							Particular
						</Button>
						<FleetFormItem label="CP Speed" type="row" containerClassname="aisData-search">
							<FleetInput
								value={dataSource?.speed}
								type="number"
								onChange={(val: number) => {
									onChange?.("speed", val);
								}}
								className="aisData-input"
							/>
						</FleetFormItem>
						<FleetAisDateSelect
							activeTime={dataSource?.days}
							onSelect={(val) => {
								onChange?.("days", val);
							}}
							options={options}
						/>
					</div>
				</div>
			)}
			<div className="aisData-lineChart">
				<FleetLoading loading={loading} />
				<div className="lineChart-actions">
					<span>CP Speed</span>
					<span>AIS Speed</span>
				</div>
				<div className="lineChart" ref={chartRef}></div>
				{/* <FleetChart
					option={{
						xAxis: [
							{
								type: "time",
								axisLine: {
									onZero: true,
									onZeroAxisIndex: 0
								},
								axisTick: {
									show: false
								},
								gridIndex: 0,
								axisPointer: {
									show: true,
									// snap:true,
									label: {
										show: true,
										formatter: function (params) {
											return dayjs(params?.value)?.format("YYYY-MM-DD");
										}
									}
								},
								// minInterval: 1000 * 60 * 60 * 24 * 30, // 设置x轴时间间隔
								axisLabel: {
									show: true,
									// align: 'left',
									color: "#AEAEB2",
									fontWeight: 400,
									fontSize: 13,
									formatter: function (value) {
										return dayjs(value)?.format("YYYY-MM-DD");
									}
								}
								// min: 'dataMin',
								// max: 'dataMax'
							}
						],
						grid: [
							{
								id: "gd1",
								top: 20,
								left: 34,
								right: 44,
								height: "80%",
								width: "calc(100% - 78px)"
							}
						],
						yAxis: [
							{
								type: "value",
								gridIndex: 0,
								scale: true,
								position: "right",
								axisLine: {
									onZero: false,
									onZeroAxisIndex: 0
								},
								axisLabel: {
									//label文字设置
									color: "#c7c7c7",
									inside: false, //label文字朝内对齐
									margin: 20 // 坐标轴 label 与 坐标轴的距离
								},
								axisTick: {
									show: false
								},
								splitLine: {
									//分割线设置
									show: false
								}
							}
						],
						blendMode: "source-over",
						dataZoom: {
							type: "inside",
							xAxisIndex: [0], //控件联动
							start: 0,
							end: 100,
							minSpan: 0,
							maxSpan: 100
						},
						tooltip: {
							trigger: "axis",
							axisPointer: {
								type: "cross"
							},
							borderWidth: 1,
							borderColor: "#ccc",
							padding: 10,
							textStyle: {
								color: "#000"
							}
						},
						series: [
							{
								id: "brent-days",
								name: "布伦特原油价格(每日)",
								type: "line",
								smooth: true,
								data: data,
								color: "#2986BB",
								xAxisIndex: 0,
								symbolSize: 2,
								yAxisIndex: 1,
								lineStyle: {
									width: 1.5,
									color: "#2986BB"
								},
								triggerLineEvent: true
							}
						]
					}}
				/> */}
			</div>
		</div>
	);
};

export default FleetAisData;
