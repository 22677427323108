import { ApiParameter, RequestApiMapType } from "@/api";
import request from "@/service";

import {
	EstOtherExpensesParamsType,
	getEstExpensesParams,
	saveEstOtherExpensesParams
} from "./type";
import { appVersion } from "@/api/constant";

const enum ApiMap {
	getEstOtherExpensesParameter,
	saveEstOtherExpensesParameter
}

export type ApiUrl = keyof typeof ApiMap;

const otherExpensesApi: ApiParameter<ApiUrl> = {
	getEstOtherExpensesParameter: {
		method: "get",
		url: appVersion + "/other/expense/est"
	},
	saveEstOtherExpensesParameter: {
		method: "post",
		url: appVersion + "/other/expense/est"
	}
};

export const getEstOtherExpensesApi = (params: getEstExpensesParams) => {
	return request.request({
		...otherExpensesApi.getEstOtherExpensesParameter,
		url: `${otherExpensesApi.getEstOtherExpensesParameter.url}/${params?.id}`
	});
};

export const saveEstOtherExpensesApi = (params: saveEstOtherExpensesParams) => {
	return request.request({
		...otherExpensesApi.saveEstOtherExpensesParameter,
		data: params
	});
};

export const otherExpensesApiMap: RequestApiMapType<ApiUrl, EstOtherExpensesParamsType> = {
	getEstOtherExpensesApi,
	saveEstOtherExpensesApi
};
