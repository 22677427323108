import i18n from "@/locale"
import { MovementCommonTableColumnsType } from "../../../movement/details/components/charterBase/common/table/type"
import { HandoverFormPortDetailsItemType, HandoverFormPortDetailsProps } from "./type"
import React from "react"
import { FleetInput, FleetSelect } from "@/components"
import { PortPurposeType } from "@/types/common"
import { CommonPortSelect } from "@/common"
import { FleetOptionType } from "@/types/advancedType"
import { CommonCompanySelect } from "@/common/Select"
import { Icon } from "@/_components";

export const portPurposeOptions: FleetOptionType<"load" | "discharge", PortPurposeType>[] = [
	{ label: "load", value: "LD" },
	{ label: "discharge", value: "DC" }
];

export const columns: MovementCommonTableColumnsType<
	HandoverFormPortDetailsItemType,
	keyof HandoverFormPortDetailsItemType,
	{
		onChange: HandoverFormPortDetailsProps["onChange"];
		onCommit: HandoverFormPortDetailsProps["onCommit"];
		onDelete?: HandoverFormPortDetailsProps["onDelete"];
		onMove: HandoverFormPortDetailsProps["onMove"];
		onOpen: () => void;
	}
>[] = [
	{
		dataIndex: "portName",
		label: i18n.t("common.portName"),
		needVerity: true,
		width: 106,
		fixed: "left",
		render: (value, record, index, actions, otherSource) => {
			return (
				<CommonPortSelect
					activeItems={{
						portName: record?.portName,
						portCode: record?.portCode
					}}
					primaryKey="portCode"
					showKey="portName"
					onFinish={(newItem, type) => {
						actions?.onCommit(index, {
							portCode: newItem?.portCode ?? "",
							portName: newItem?.portName ?? ""
						});
					}}
				/>
			);
		}
	},
	{
		dataIndex: "purpose",
		label: i18n.t("common.purpose"),
		needVerity: true,
		width: 106,
		fixed: "left",
		render: (value, record, index, actions, otherSource) => {
			return (
				<FleetSelect
					options={portPurposeOptions}
					value={[value]}
					onChange={(val: PortPurposeType) => {
						actions?.onChange?.(index, "purpose", val);
					}}
				/>
			);
		}
	},
	{
		dataIndex: "loadingDischargeRate",
		width: 106,
		label: "L/D Rate (%)",
		render: (value, record, index, actions, otherSource) => {
			return (
				<FleetInput
					value={value}
					type="number"
					className="td-input"
					onChange={(val: number) => {
						actions?.onChange?.(index, "loadingDischargeRate", val);
					}}
				/>
			);
		}
	},
	{
		dataIndex: "draftLimitation",
		width: 106,
		label: "Draft Limits",
		render: (value, record, index, actions, otherSource) => {
			return (
				<FleetInput
					value={value}
					type="number"
					className="td-input"
					onChange={(val: number) => {
						actions?.onChange?.(index, "draftLimitation", val);
					}}
				/>
			);
		}
	},
	{
		dataIndex: "demRate",
		width: 106,
		label: "DEM Rate (%)",
		render: (value, record, index, actions, otherSource) => {
			return (
				<FleetInput
					value={value}
					type="number"
					className="td-input"
					onChange={(val: number) => {
						const params: Partial<Pick<HandoverFormPortDetailsItemType, "demRate" | "desRate">> = {
							demRate: val
						};
						if (otherSource?.dnd) {
							params.desRate = val ? val / 2 : "";
						}
						actions?.onCommit?.(index, params);
					}}
				/>
			);
		}
	},
	{
		dataIndex: "desRate",
		width: 106,
		label: "DES Rate (%)",
		render: (value, record, index, actions, otherSource) => {
			return (
				<FleetInput
					disabled={otherSource?.disabled || otherSource?.dnd}
					value={value}
					className="td-input"
					type="number"
					onChange={(val: number) => {
						actions?.onChange?.(index, "desRate", val);
					}}
				/>
			);
		}
	},
	{
		dataIndex: "portAgentId",
		width: 182,
		label: "Port Agent",
		render: (value, record, index, actions, otherSource) => {
			return (
				<div className="flex items-center justify-between">
					<CommonCompanySelect
						showKey="companyName"
						primaryKey="id"
						activeItems={{
							id: record?.portAgentId as unknown as number,
							companyName: record?.portAgentName
						}}
						onFinish={(newNoItem, type) => {
							actions?.onCommit?.(index, {
								portAgentId: newNoItem?.id ?? null,
								portAgentName: newNoItem?.companyName ?? ""
							});
						}}
						className="w-[calc(100%-36px)]"
					/>
					<Icon
						type="fleet-plus"
						className="cursor-pointer opacity-40"
						size={32}
						onClick={actions?.onOpen}
					/>
				</div>
			);
		}
	},
	{
		dataIndex: "action",
		width: 65,
		label: "Action",
		fixed: "right",
		render: (value, record, index, actions, otherSource) => {
			return (
				<div className="td-actions move">
					<span
						className="font_family fleet-delete"
						onClick={() => {
							if (otherSource?.disabled) return;
							actions?.onDelete(index);
						}}
					></span>
					<div className="port-moveActions">
						<span
							className="font_family fleet-back"
							onClick={() => {
								actions?.onMove?.("up", index);
							}}
						></span>
						<span
							className="font_family fleet-back"
							onClick={() => {
								actions?.onMove?.("down", index);
							}}
						></span>
					</div>
				</div>
			);
		}
	}
];

export const initialPortDetailItem: HandoverFormPortDetailsItemType = {
  portName: "",
  portCode: "",
  purpose: "DC",
  demRate: "",
  desRate: "",
  draftLimitation: "",
  loadingDischargeRate: "",
  portAgentId: null,
  portAgentName: "",
}

export const checkKeys: (keyof HandoverFormPortDetailsItemType)[] = [
  "portName",
  "purpose"
]