export {
  getVoyageAttachAndContactApi,
  saveVoyageContactApi,
  updatePreopRemarkApi,
  uploadVoyageAttachApi
} from "./api"

export {
  getVoyageAttachAndContactParams,
  saveVoyageContactParams,
  updatePreopRemarkParams,
  uploadVoyageAttachParams
} from "./type"

