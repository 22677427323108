import { downloadRoutePlanAction } from "@/action/monitoring/routePlan";
import { RoutePlanItemType } from "../../../type";
import { useRef, useState } from "react";
import { useAppDispatch } from "@/hook";
import { useNavigate } from "react-router-dom";
import useReminder from "@/hook/useReminder";
import { RoutePlanModalRefType } from "../../modal/type";

const useIndex = () => {
	const dispatch = useAppDispatch();
	const navigate = useNavigate();
	const { reminder } = useReminder();
	const [activeKey, setActiveKey] = useState<"A" | "B">("A");
	const [loading, setLoading] = useState(false);
	const [hide, setHide] = useState(true);
	const activeRoutePlan = useRef<RoutePlanItemType>(null);

	const handleChange = (val: "A" | "B") => {
		if (activeKey === val) return;
		setActiveKey(val);
	};

	const downloadRoutePlanFront = () => {
		setLoading(true);
	};

	const downloadRoutePlanSuccess = (response) => {
		if (response instanceof Blob) {
			reminder("error", "There are no downloadable files for the current routing plan!");
			setLoading(false);
			return;
		}
		setLoading(false);
		closeModal();
	};

	const downloadRoutePlanFailed = (error) => {
		setLoading(false);
		reminder("error", error?.msg ? error?.msg + ": " + error?.data : error?.data);
	};

	const downloadRoutePlan = () => {
		downloadRoutePlanAction(
			{ routePlanUuid: activeRoutePlan?.current?.id, type: activeKey },
			downloadRoutePlanFront,
			downloadRoutePlanSuccess,
			downloadRoutePlanFailed,
			dispatch,
			navigate
		);
	};

	const openModal: RoutePlanModalRefType<RoutePlanItemType>["openModal"] = (item) => {
		activeRoutePlan.current = item;
		setHide(false);
	};

	const closeModal = () => {
		activeRoutePlan.current = null;
		setHide(true);
	};

	return {
		hide,
		loading,
		activeKey,
		handleChange,
		downloadRoutePlan,
		openModal,
		closeModal
	};
};

export default useIndex;
