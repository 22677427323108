import React, { FC, useEffect, useMemo } from "react";
import classnames from "classnames";
import style from "./index.module.less";
import { MonitoringPortAreaListProps } from "./type";
import { PortAreaListFilter, PortAreaListTable } from "./components";
import useIndex from "./hooks/useIndex";
import { FleetLoading } from "@/components";
const MonitoringPortAreaList: FC<MonitoringPortAreaListProps> = ({
	activeIndex,
	currentTab,
	searchParams,
	onOpenPortCall,
	onAisItemSelect,
	onPortVesselLoad
}) => {
	const {
		portAreaListFilterData,
		portAreaListTable,
		handleCommitFilterData,
		handleDownLoad,
		handleRowClick,
		handleRowSelect,
		getPortVesselList,
		selectedRows,
		getPortVesselSearch,
		loading,
		handleSelectAll,
		setPortAreaListTable,
		activeSortOption,
		sortList,
		handleRowSort
	} = useIndex(searchParams, onOpenPortCall, onAisItemSelect, onPortVesselLoad);
	useEffect(() => {
		console.log("searchParams", searchParams);
		if (activeIndex === "portAreaDetails" && currentTab == "port") {
			if (!searchParams?.currentPort?.portCode) {
				setPortAreaListTable([]);
				return;
			}
			getPortVesselList();
		}
	}, [activeIndex, searchParams]);

	useEffect(() => {
		if (activeIndex === "portAreaDetails" && currentTab == "port") {
			getPortVesselSearch();
		}
	}, [activeIndex]);

	return (
		<div
			className={classnames(style["portArea-list"], {
				[style["portArea-list--active"]]: activeIndex === "portAreaDetails" && currentTab === "port"
			})}
		>
			<FleetLoading loading={loading} />
			<div className="portAreaList-main">
				<PortAreaListFilter
					disabled={loading}
					searchParam={portAreaListFilterData}
					onCommit={handleCommitFilterData}
					onDownLoad={handleDownLoad}
				/>
				<PortAreaListTable
					sortOption={activeSortOption}
					onSort={handleRowSort}
					tableData={sortList}
					onClick={handleRowClick}
					selectedRows={selectedRows}
					onSelect={handleRowSelect}
					onSelectAll={handleSelectAll}
				/>
			</div>
		</div>
	);
};

export default MonitoringPortAreaList;
