import { ApiParameter, RequestApiMapType } from "@/api";
import request from "@/service";

import {
	MovementDisputeChargeParamsType,
	getMovementDisputeChargeParams,
	saveMovementDisputeChargeParams
} from "./type";
import { appVersion } from "@/api/constant";

const enum ApiMap {
	getMovementDisputeChargeParameter,
	saveMovementDisputeChargeParameter
}

export type ApiUrl = keyof typeof ApiMap;

const disputeChargeApi: ApiParameter<ApiUrl> = {
	getMovementDisputeChargeParameter: {
		method: "get",
		url: appVersion + "/move/dialog/dispute/charge/list"
	},
	saveMovementDisputeChargeParameter: {
		method: "put",
		url: appVersion + "/move/dialog/dispute/charge"
	}
};

export const getMovementDisputeChargeApi = (params: getMovementDisputeChargeParams) => {
	return request.request({
		...disputeChargeApi.getMovementDisputeChargeParameter,
		url: `${disputeChargeApi.getMovementDisputeChargeParameter.url}/${params?.id}`
	});
};

export const saveMovementDisputeChargeApi = (params: saveMovementDisputeChargeParams) => {
	return request.request({
		...disputeChargeApi.saveMovementDisputeChargeParameter,
		data: params
	});
};

export const disputeChargeApiMap: RequestApiMapType<ApiUrl, MovementDisputeChargeParamsType> = {
	getMovementDisputeChargeApi,
	saveMovementDisputeChargeApi
};
