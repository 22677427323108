import { PreOperationVoyageItemType } from "./components/item/type"

export const initialVoyageItem = (item?: Partial<PreOperationVoyageItemType>): PreOperationVoyageItemType => {
  return {
		id: item?.id ?? null,
		cargoName: item?.cargoName ?? "",
		cargoQuantity: item?.cargoQuantity ?? "",
		cargoUnit: item?.cargoUnit ?? "MT",
		charaByName: item?.charaByName ?? "",
		chartererName: item?.chartererName ?? "",
		createTime: item?.createTime ?? "",
		createUserName: item?.createUserName ?? "",
		ownerName: item?.ownerName ?? "",
		vesselName: item?.vesselName ?? "",
		updateTime: item?.updateTime ?? null,
		voyageType: item?.voyageType ?? null,
		createUser: item?.createUser ?? null
	};
}