import { useRef } from "react"

export type useVesselFutureOptionsType = {
  start: [number, number],
  end: [number, number],
  totalTime: number,
  totalDistance: number
}

const useVesselFuture = () => {
  const voyageDataSource = useRef<useVesselFutureOptionsType[]>([])

  const routeDataSource = useRef<any>([])

  const loadVoyageOptions = (options: useVesselFutureOptionsType[]) => {
    voyageDataSource.current = options
    console.log('options', options)
  }

  return {
    loadVoyageOptions
  }

}

export default useVesselFuture