import {
	getVoyageAttachAndContactAction,
	saveVoyageContactAction
} from "@/action/voyageManage/preOperation";
import { useAppDispatch } from "@/hook";
import useReminder from "@/hook/useReminder";
import { MutableRefObject, useState } from "react";
import { useNavigate } from "react-router-dom";
import {
	VoyageManageRemarkContactOptionType,
	VoyageManageVoyageRemarkCommonRefType,
	VoyageManageVoyageRemarkContactType,
	VoyageManageVoyageRemarkItemType,
	VoyageManageRemarkContactChangeEvent
} from "../type";
import { VoyageManageAttachAndContactProps } from "@/pages/voyagemanage/business/preOp&movement/components";
import { CommonContactItemType } from "@/common/Select";
import { CommonInitItemEvent } from "@/types/event";
import { initialContactOption } from "../source";
import { VoyageManageTcvcVoyageRemarkType } from "../components/tcvc/type";
import { VoyageManageTctcVoyageRemarkType } from "../components/tctc/type";

const useCommonIndex = <
	DataSourceType extends VoyageManageTcvcVoyageRemarkType | VoyageManageTctcVoyageRemarkType,
	CurrentType extends VoyageManageVoyageRemarkContactType
>(
	voyageId: string,
	initItem: CommonInitItemEvent<DataSourceType>,
	currentType: MutableRefObject<CurrentType>
) => {
	const dispatch = useAppDispatch();
	const navigate = useNavigate();
	const { reminder } = useReminder();

	const [loading, setLoading] = useState(false);
	const [highlightedPoint, setHighlightedPoint] = useState("");
	const [dataSource, setDataSource] = useState<DataSourceType>(initItem());
	// const currentType = useRef<keyof VoyageManageVoyageRemarkType>("head_fixture");

	const [contactOption, setContactOption] =
		useState<VoyageManageRemarkContactOptionType>(initialContactOption());

	const handleContactChange: VoyageManageRemarkContactChangeEvent = (item) => {
		setContactOption((prev) => ({ ...prev, ...item }));
	};

	const handleContactSelect: VoyageManageAttachAndContactProps["onContactSelect"] = (
		type,
		item
	) => {
		debugger;
		const alreadyExist = Object.values(dataSource)?.some((selectItem) => {
			return !!selectItem?.contact?.find((contactItem) => contactItem?.contactsId === item?.id);
		});
		if (alreadyExist) {
			reminder("warning", "The contacts is already exist");
			return;
		}
		console.log("type", type);
		saveVoyageContact({ ...item, contactsType: type });
	};

	const handleContactConfirm = (item: CommonContactItemType) => {
		saveVoyageContact({ ...item, contactsType: currentType?.current });
	};

	const getVoyageAttachAndContactFront = () => {
		setLoading(true);
	};

	const getVoyageAttachAndContactSuccess = ({ data }) => {
		setHighlightedPoint(data?.highlightedPoint);
		setDataSource(initItem(data?.attachmentAndContact));
		setLoading(false);
	};

	const getVoyageAttachAndContactFailed = (error) => {
		setLoading(false);
		reminder("error", error?.msg ? error?.msg + ": " + error?.data : error?.data);
	};

	const getVoyageAttachAndContact = () => {
		if (!voyageId) {
			setHighlightedPoint("");
			setDataSource(initItem(null));
			return;
		}
		getVoyageAttachAndContactAction(
			{ voyageId },
			getVoyageAttachAndContactFront,
			getVoyageAttachAndContactSuccess,
			getVoyageAttachAndContactFailed,
			dispatch,
			navigate
		);
	};

	const commit: VoyageManageAttachAndContactProps["onCommit"] = (voyageType, type, item) => {
		setDataSource((prev) => {
			const currentItem = prev[voyageType];
			switch (type) {
				case "attachment":
					currentItem.attachment?.push(...(item as VoyageManageVoyageRemarkItemType["attachment"]));
					break;
				case "contact":
					currentItem.contact?.push(item as VoyageManageVoyageRemarkItemType["contact"][number]);
					break;
				default:
					break;
			}
			return {
				...prev,
				[voyageType]: { ...currentItem }
			};
		});
	};

	const remove: VoyageManageAttachAndContactProps["onDelete"] = (voyageType, type, id) => {
		let index = -1;
		setDataSource((prev) => {
			const currentItem = prev[voyageType];
			switch (type) {
				case "attachment":
					index = currentItem.attachment?.findIndex((item) => item?.id === id);
					index !== -1 && currentItem.attachment?.splice(index, 1);
					break;
				case "contact":
					index = currentItem.contact?.findIndex((item) => item?.id === id);
					index !== -1 && currentItem.contact?.splice(index, 1);
				default:
					break;
			}
			return {
				...prev,
				[voyageType]: { ...currentItem }
			};
		});
	};

	const handleAdd = (type: CurrentType) => {
		handleContactChange({ hide: false, type });
		currentType.current = type;
	};

	const saveVoyageContactFront = () => {
		handleContactChange({ loading: true });
	};

	const saveVoyageContactFailed = (error) => {
		handleContactChange({ loading: false });
		reminder("error", error?.msg ? error?.msg + ": " + error?.data : error?.data);
	};

	const saveVoyageContact = (item: CommonContactItemType) => {
		console.log("saveVoyageContact", item);
		const { id, ...restItem } = item;
		const params = {
			...restItem,
			contactsId: id,
			del: true,
			voyageId
		};
		saveVoyageContactAction(
			params,
			saveVoyageContactFront,
			(response) => {
				commit(item?.contactsType, "contact", { ...params, id: response?.data?.id });
				handleContactChange({ loading: false, hide: true });
				reminder("success", response?.msg);
			},
			saveVoyageContactFailed,
			dispatch,
			navigate
		);
	};

	const handleCombine: VoyageManageVoyageRemarkCommonRefType["combineParams"] = () => {
		return {
			highlightedPoint
		};
	};

	return {
		highlightedPoint,
		setHighlightedPoint,
		dataSource,
		loading,
		handleAdd,
		commit,
		remove,
		handleReset: getVoyageAttachAndContact,
		saveVoyageContact,
		handleCombine,
		contactOption,
		handleContactChange,
		handleContactConfirm,
		handleContactSelect
	};
};

export default useCommonIndex;
