import { useRouteCommonContext } from "@/pages/routeCommon/store";
import { useEffect, useState } from "react";
import { DailyReportItemType, DailyReportCategoryType } from "../type";
import { onRequest } from "@/action";
import { useAppDispatch } from "@/hook";
import { useNavigate } from "react-router-dom";
import useReminder from "@/hook/useReminder";
import { useCheckGroup } from "@/hooks/common";
import { initialCheckGroup } from "../source";

const useIndex = () => {
	const dispatch = useAppDispatch();
	const navigate = useNavigate();
	const { reminder } = useReminder();
	const { activeDetailsOption, activeLegVessel, onDetailsOptionCommit } = useRouteCommonContext();

	const handleRowSelect = (selectedRowKeys: string[], selectedRows: DailyReportItemType[]) => {
		onDetailsOptionCommit({ selectDailyReports: selectedRows });
	};

	const {
		dataSource: checkedGroup,
		init: handleCheckedGroupReset,
		select: handleCheckedGroupSelect,
		selectAll: handleCheckedGroupSelectAll
	} = useCheckGroup<DailyReportCategoryType, keyof DailyReportItemType>(initialCheckGroup);

	const [loading, setLoading] = useState(false);
	const [dataSource, setDataSource] = useState<DailyReportItemType[]>([]);

	const getDailyReportFront = () => {
		setLoading(true);
	};

	const getDailyReportSuccess = (response) => {
		setDataSource(response?.data);
		setLoading(false);
	};

	const getDailyReportFaild = (error) => {
		console.log("error", error);
		setLoading(false);
		reminder("error", error?.msg ? error?.msg + ": " + error?.data : error?.data);
	};

	const getDailyReport = () => {
		onRequest(
			"getRouteCommonDailyReportApi",
			{
				legType: activeLegVessel?.vesselStatus,
				voyageNo: activeLegVessel?.voyageNo
			},
			getDailyReportFront,
			getDailyReportSuccess,
			getDailyReportFaild,
			dispatch,
			navigate
		);
	};

	useEffect(() => {
		if (activeDetailsOption?.activeTab !== "dailyReport" || !activeLegVessel) return;

		getDailyReport();
	}, [activeDetailsOption?.activeTab, activeLegVessel]);

	return {
		loading,
		dataSource,
		activeDetailsOption,
		checkedGroup,
		handleRowSelect,
		handleCheckedGroupReset,
		handleCheckedGroupSelect,
		handleCheckedGroupSelectAll
	};
};

export default useIndex;
