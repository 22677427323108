import React, { FC } from "react";
import classnames from "classnames";
import style from "./index.module.less";
import { HandoverFormBasicInfoProps } from "./type";
import { FleetFormItem, FleetTextarea, FleetVerifyInput } from "@/components";
import { CommonUserSelect, CommonVesselSelect } from "@/common";

const HandoverFormBasicInfo: FC<HandoverFormBasicInfoProps> = ({
	currentId,
	item,
	otherItem,
	onChange,
	onCommit,
	onOtherChange
}) => {
	return (
		<div
			id={currentId}
			className={classnames("handoverForm--common", style["handoverForm-basicInfo"])}
		>
			<span className="handoverForm--common--header">Basic Information</span>
			<div className="basicInfo-container">
				<div className="basicInfo-voyageInfo">
					<FleetVerifyInput
						value={otherItem?.voyageNo}
						onChange={(val: string) => {
							onOtherChange?.("voyageNo", val);
						}}
						label="Voyage No."
						needVerity
						minLength={0}
						maxLength={15}
					/>
					<FleetFormItem label="Vessel Name" needVerity>
						<CommonVesselSelect
							activeItems={{
								vesselName: item?.vesselName,
								mmsi: item?.mmsi as number
							}}
							primaryKey="mmsi"
							showKey="vesselName"
							onFinish={(newItem, type) => {
								debugger;
								onCommit?.({
									dwt: newItem?.dwt ?? "",
									bale: newItem?.baleCapacity ?? "",
									grain: newItem?.grainCapacity ?? "",
									imo: newItem?.imo ?? null,
									mmsi: newItem?.mmsi ?? null,
									vesselName: newItem?.vesselName ?? ""
								});
							}}
						/>
					</FleetFormItem>
					<FleetFormItem label="Operated By" needVerity>
						<CommonUserSelect
							activeItems={item?.operateList}
							showKey="nickName"
							roleParams={{
								type: 0
							}}
							showCount={1}
							onFinish={(newItem, type) => {
								console.log("newitem", newItem);
								switch (true) {
									case type === "add":
										onCommit?.({
											operateList: [newItem],
											operatedName: newItem?.nickName ?? "",
											operatedBy: (newItem?.userId as unknown as number) ?? null
										});
										break;
									case type === "del":
										onCommit?.({
											operateList: [],
											operatedBy: null,
											operatedName: ""
										});
										break;
									default:
										break;
								}
							}}
						/>
						{/* <CommonContactSelect
            activeItems={{
              id: item?.operatedBy,
              name: item?.operatedName
            }}
            showKey="name"
            primaryKey="id"
            onFinish={(newNoItem, type) => {
              onCommit?.({
                operatedBy: newNoItem?.id,
                operatedName: newNoItem?.name
              })
            }}
          /> */}
					</FleetFormItem>
					<FleetFormItem label="Chartered By">
						<CommonUserSelect
							activeItems={item?.charateList}
							showKey="nickName"
							showCount={2}
							onFinish={(newItem, type) => {
								console.log("newitem", newItem);
								switch (true) {
									case type === "add":
										onCommit?.({
											charateList: [...item?.charateList, newItem],
											charteredBy: [...item?.charteredBy, newItem?.userId]
										});
										break;
									case type === "del":
										const charateIndex = item?.charateList?.findIndex(
											(charateItem) => charateItem?.userId === newItem?.userId
										);
										const charateList = item?.charateList;
										charateList?.splice(charateIndex, 1);
										onCommit?.({
											charateList: [...charateList],
											charteredBy: charateList?.map((charateItem) => charateItem?.userId)
										});
										break;
									default:
										break;
								}
							}}
						/>
					</FleetFormItem>
				</div>
				<FleetFormItem needVerity label="Present Vessel Movement">
					<FleetTextarea
						placeholder=""
						className="basicInfo--textarea"
						value={otherItem?.presentPortAgentInfo}
						onChange={(val: string) => {
							onOtherChange?.("presentPortAgentInfo", val);
						}}
					/>
				</FleetFormItem>
				<FleetFormItem needVerity label="Present Port Agent Information">
					<FleetTextarea
						placeholder=""
						className="basicInfo--textarea"
						value={otherItem?.presentVesselMovement}
						onChange={(val: string) => {
							onOtherChange?.("presentVesselMovement", val);
						}}
					/>
				</FleetFormItem>
				<FleetFormItem needVerity label="Ship’s Contact Details">
					<FleetTextarea
						placeholder=""
						className="basicInfo--textarea"
						value={otherItem?.shipContactDetail}
						onChange={(val: string) => {
							onOtherChange?.("shipContactDetail", val);
						}}
					/>
				</FleetFormItem>
			</div>
		</div>
	);
};

export default HandoverFormBasicInfo;
