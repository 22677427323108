import { type ApiParameter } from "@/api";
import { appVersion } from "@/api/constant";
import request from "@/service";

const enum ApiMap {
	queryPortsRouteParameter
}

type ApiUrl = keyof typeof ApiMap;

const routeApi: ApiParameter<ApiUrl> = {
	queryPortsRouteParameter: {
		method: "get",
		url: appVersion + "/route/db/route/vm"
	}
};

export type queryPortsRouteParams = {
	arrPort: string;
	depPort: string;
};

export const queryPortsRouteApi = (params: queryPortsRouteParams) => {
	return request.request({
		...routeApi.queryPortsRouteParameter,
		params: params
	});
};
