import React, { FC } from "react";
import { CargoAndPortItemProps } from "./type";
import {
  FleetDatePicker,
  FleetFormItem,
  FleetRangePicker,
  FleetVerifyInput,
} from "@/components";
import { CommonPortSelect } from "@/common";
import { FleetTabbar } from "@/components/Tabs";
import { CargoUnitType, FreightType } from "@/types/common";
import dayjs from "dayjs";
import { useTranslation } from "react-i18next";

const CargoAndPortItem: FC<CargoAndPortItemProps> = ({
  item,
  currentIndex,
  onChange,
  onCommit,
  onCrud
}) => {
  const { t } = useTranslation();
  return (
    <div className="estimation-newly--item scroll-allowed">
      <div className="newly-item--header">
        <span className="header-title ">Cargo & Port</span>
        <div className="header-actions">
          <span
            className="font_family fleet-add-square"
            onClick={() => onCrud("cargo/add")}
          ></span>
          <span
            className="font_family fleet-copy"
            onClick={() => onCrud("cargo/parse", currentIndex)}
          ></span>
          <span
            className="font_family fleet-delete"
            onClick={() => onCrud("cargo/delete", currentIndex)}
          ></span>
        </div>
      </div>
      <div className="newly-item--container">
        <div className="newly-item--scrollArea">
          <div className="newly-item--formItem full">
            <FleetVerifyInput
              label={t(`common.chartererName`)}
              value={item?.chartererName}
              onChange={(val: string) => {
                onChange?.(currentIndex, "chartererName", val);
              }}
            />
          </div>
          <div className="newly-item--formItem full">
            <FleetVerifyInput
              label={t(`common.cargoName`)}
              placeholder={t(`common.cargoName`)}
              value={item?.cargoName}
              onChange={(val: string) => {
                onChange?.(currentIndex, "cargoName", val);
              }}
            />
          </div>
          <div className="newly-item--formItem untrisection">
            <FleetVerifyInput
              needVerity
              label={t(`common.cargoQuantity`)}
              value={item?.cargoQuantity}
              type="number"
              onChange={(val: number) => {
                let newLoadWorkDay =
                  +item?.loadRate === 0
                    ? 0
                    : +(+val / +item?.loadRate)?.toFixed(3);
                let newDischargeWorkDay =
                  +item?.dischargeRate === 0
                    ? 0
                    : +(+val / +item?.dischargeRate)?.toFixed(3);
                onCommit?.(currentIndex, {
                  cargoQuantity: val,
                  loadWorkDays: newLoadWorkDay,
                  dischargeWorkDays: newDischargeWorkDay,
                });
              }}
            />
            <FleetTabbar
              disabled
              list={["MT", "CBM"]}
              value={item?.cargoUnit}
              onChange={(val: CargoUnitType) => {
                onChange?.(currentIndex, "cargoUnit", val);
              }}
            />
            <FleetVerifyInput
              label={t(`common.mollo`)}
              value={item?.cargoQuantityDiscrepancy}
              type="number"
              onChange={(val: number) => {
                onChange?.(currentIndex, "cargoQuantityDiscrepancy", val);
              }}
            />
          </div>

          <div className="newly-item--formItem full">
            <FleetFormItem
              label={t("common.layCanFrom")}
            >
              <FleetDatePicker
                value={item?.layCanFrom ? dayjs(item?.layCanFrom) : null}
                onChange={(val, timeStramp) => {
                  onChange?.(currentIndex, "layCanFrom", timeStramp?.startOf("minutes")?.valueOf());
                }}
                format={(value) => value?.format("YYYY-MM-DD HH:mm")}
                showTime={{
                  format: "HH:mm"
                }}
                showHour
                showMinute
                allowClear
                disabledDate={(current) => {
                  return (item?.layCanTo && current > dayjs(item?.layCanTo).endOf("minutes"))
                }}
              />
            </FleetFormItem>
          </div>
          <div className="newly-item--formItem full">
            <FleetFormItem
              label={t("common.layCanTo")}
            >
              <FleetDatePicker
                value={item?.layCanTo ? dayjs(item?.layCanTo) : null}
                onChange={(val, timeStramp) => {
                  onChange?.(currentIndex, "layCanTo", timeStramp?.startOf("minutes")?.valueOf());
                }}
                format={(value) => value?.format("YYYY-MM-DD HH:mm")}
                showTime={{
                  format: "HH:mm"
                }}
                showHour
                showMinute
                allowClear
                disabledDate={(current) => {
                  return (item?.layCanFrom && current < dayjs(item?.layCanFrom).endOf("minutes"))
                }}
              />
            </FleetFormItem>
          </div>
          <div className="newly-item--formItem unbisector">
            <FleetFormItem
              value={item?.loadPortCode}
              label={t(`common.loadPort`)}
              needVerity
            >
              <CommonPortSelect
                activeItems={{
                  portCode: item?.loadPortCode,
                  portName: item?.loadPortName,
                }}
                showKey="portName"
                primaryKey="portCode"
                onFinish={(newPortItem, type) => {
                  onCommit?.(currentIndex, {
                    loadPortName: newPortItem?.portName ?? "",
                    loadPortCode: newPortItem?.portCode ?? "",
                  });
                }}
              />
            </FleetFormItem>
            <FleetVerifyInput
              label={t(`common.loadPortExpense`)}
              value={item?.loadPortExpense}
              type="number"
              onChange={(val: number) => {
                onChange?.(currentIndex, "loadPortExpense", val);
              }}
            />
          </div>
          <div className="newly-item--formItem unbisector">
            <FleetVerifyInput
              label={t(`common.loadRate`)}
              value={item?.loadRate}
              type="number"
              onChange={(val: number) => {
                let newWorkDay =
                  +val === 0 ? 0 : +(+item?.cargoQuantity / +val)?.toFixed(3);
                onCommit?.(currentIndex, {
                  loadRate: val,
                  loadWorkDays: newWorkDay,
                });
              }}
            />
            <FleetVerifyInput
              label={t(`common.loadPortDays`)}
              value={item?.loadWorkDays}
              type="number"
              onChange={(val: number) => {
                let newRate =
                  +val === 0 ? 0 : +(+item?.cargoQuantity / +val)?.toFixed(3);
                onCommit?.(currentIndex, {
                  loadWorkDays: val,
                  loadRate: newRate,
                });
              }}
            />
          </div>
          {/* dischargePort */}
          <div className="newly-item--formItem unbisector">
            <FleetFormItem
              value={item?.dischargePortCode}
              label={t(`common.dischargePort`)}
              needVerity
            >
              <CommonPortSelect
                activeItems={{
                  portCode: item?.dischargePortCode,
                  portName: item?.dischargePortName,
                }}
                showKey="portName"
                primaryKey="portCode"
                onFinish={(newPortItem, type) => {
                  onCommit?.(currentIndex, {
                    dischargePortName: newPortItem?.portName ?? "",
                    dischargePortCode: newPortItem?.portCode ?? "",
                  });
                }}
              />
            </FleetFormItem>
            <FleetVerifyInput
              label={t(`common.disPortExpenses`)}
              value={item?.dischargePortExpense}
              type="number"
              onChange={(val: number) => {
                onChange?.(currentIndex, "dischargePortExpense", val);
              }}
            />
          </div>
          <div className="newly-item--formItem unbisector">
            <FleetVerifyInput
              label={t(`common.dischargeRate`)}
              value={item?.dischargeRate}
              type="number"
              onChange={(val: number) => {
                let newWorkDay =
                  +val === 0 ? 0 : +(+item?.cargoQuantity / +val)?.toFixed(3);
                onCommit?.(currentIndex, {
                  dischargeRate: val,
                  dischargeWorkDays: newWorkDay,
                });
              }}
            />
            <FleetVerifyInput
              label={t(`common.dischargeWorkDays`)}
              value={item?.dischargeWorkDays}
              type="number"
              onChange={(val: number) => {
                let newRate =
                  +val === 0 ? 0 : +(+item?.cargoQuantity / +val)?.toFixed(3);
                onCommit?.(currentIndex, {
                  dischargeWorkDays: val,
                  dischargeRate: newRate,
                });
              }}
            />
          </div>
          <div className="newly-item--formItem eightyPercent">
            <FleetVerifyInput
              needVerity
              type="number"
              label={t(`common.freightRate`)}
              value={item?.freightRate}
              onChange={(val: number) => {
                onChange?.(currentIndex, "freightRate", val);
              }}
            />
            <FleetTabbar
              list={["FR", "LP"]}
              value={item?.freightType}
              onChange={(val: FreightType) => {
                onChange?.(currentIndex, "freightType", val);
              }}
            />
          </div>
          <div className="newly-item--formItem bisector">
            <FleetVerifyInput
              type="number"
              label={t(`common.commission`)}
              value={item?.commission}
              onChange={(val: number) => {
                onChange?.(currentIndex, "commission", val);
              }}
            />
            <FleetVerifyInput
              type="number"
              label={t(`common.brokerage`)}
              value={item?.brokerage}
              onChange={(val: number) => {
                onChange?.(currentIndex, "brokerage", val);
              }}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default CargoAndPortItem;
