import { useRef, useState } from "react";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import { MonitoringVoyageTabType } from "../type";
import { MonitorAisGroupRefType } from "../components/aisList/type";

const useIndex = () => {
	const navigate = useNavigate();
	const [searchParams] = useSearchParams();
	const { search } = useLocation();

	const voyageNo = new URLSearchParams(search)?.get?.("voyageNo");
	const id = searchParams.get("id"),
		voyageType = searchParams?.get("voyageType");

	const vesselListRef = useRef(undefined);
	const aisListRef = useRef<MonitorAisGroupRefType>(null);

	const [activeTab, setActiveTab] = useState<MonitoringVoyageTabType>("vm");

	const handleTabSelect = (type: MonitoringVoyageTabType) => {
		if (activeTab === type) return;
		setActiveTab(type);
	};

	const handleJumpMovement = () => {
		navigate(`/layout/voyagemanage/movement/details?id=${id}&voyageType=${voyageType}`);
	};

	return {
		activeTab,
		handleTabSelect,
		voyageNo,
		vesselListRef,
		aisListRef,
		handleJumpMovement
	};
};

export default useIndex;
