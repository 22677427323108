import React, { FC, useMemo } from "react";
import style from "./index.module.less";
import { EstPortRotationItemProps } from "./type";
import { FleetFormItem, FleetTooltip, FleetVerifyInput } from "@/components";
import { FleetToast } from "@/common";
import { CargoUnitType, PortPurposeType } from "@/types/common";
import { useTranslation } from "react-i18next";
import { FleetPortTabs, FleetTabbar } from "@/components/Tabs";
import { calculateValues } from "../../tools";
import { FleetCustomRadio } from "@/components/Radio";
import { CommonPortSelect } from "@/common";
import useReminder from "@/hook/useReminder";
import { dischargePurposeOptions, loadPurposeOptions } from "./source";
import { calcSeaValues } from "@/tools/port";

const EstPortRotationItem: FC<EstPortRotationItemProps> = ({
	item,
	currentIndex,
	portType,
	vesselSource,
	cargoSource,
	currentQuantity,
	onChange,
	onCommit,
	onPortCommit,
	onRemove
}) => {
	const { t } = useTranslation();
	const { reminder } = useReminder();

	const purposeOptions = useMemo(() => {
		return portType === "ballast" ? loadPurposeOptions : dischargePurposeOptions;
	}, [portType]);

	return (
		<div className={style["portRotation-item"]}>
			<div className="portRotation-item--header">
				<span className="port-name">{item?.portName}</span>
				<FleetTooltip
					outerLayer={<span className="font_family fleet-close header-close"></span>}
					render={(setIsOpen) => {
						return (
							<FleetToast
								label="Are you sure delete this item?"
								onCancel={() => {
									setIsOpen(false);
								}}
								onConfirm={() => {
									onRemove?.(currentIndex);
									setIsOpen(false);
								}}
							/>
						);
					}}
				/>
			</div>
			<FleetPortTabs
				list={purposeOptions}
				value={item?.purpose}
				onChange={(val: PortPurposeType) => {
					onChange?.(currentIndex, "purpose", val);
				}}
			/>
			<div className="portRotation-item--container">
				<div className="portRotation-item--formItem full">
					<FleetFormItem label={t("common.portName")} needVerity={true}>
						<CommonPortSelect
							activeItems={{
								portCode: item?.portCode,
								portName: item?.portName
							}}
							showKey="portName"
							primaryKey="portCode"
							onFinish={(newItem, type) => {
								onPortCommit?.(currentIndex, {
									portName: newItem?.portName ?? "",
									portCode: newItem?.portCode ?? ""
								});
							}}
						/>
					</FleetFormItem>
				</div>
				<div className="portRotation-item--formItem full">
					<FleetVerifyInput
						label={t("common.cargoName")}
						disabled
						placeholder={t("common.cargoName")}
						value={item?.cargoName}
					/>
				</div>
				<div className="portRotation-item--formItem eightyPercent">
					<FleetVerifyInput
						type="number"
						label={t("common.cargoQuantity")}
						disabled={["DB", "DT", "LB", "LT"].includes(item?.purpose)}
						value={item?.cargoQuantity}
						onChange={(val: number) => {
							debugger;
							if (currentQuantity - +item?.cargoQuantity + val > +cargoSource?.cargoQuantity) {
								reminder("info", "the quantity of goods cannot exceed the total weight");
								return;
							}
							const params = calculateValues(item, vesselSource, "cargoQuantity", val);
							onCommit?.(currentIndex, params);
						}}
					/>
					<FleetTabbar
						disabled
						list={["MT", "CBM"]}
						// 这里需要同步外层的 cargoUnit，后面再加
						value={item?.cargoUnit}
						onChange={(val: CargoUnitType) => {
							onChange?.(currentIndex, "cargoUnit", val);
						}}
					/>
				</div>
				<div className="portRotation-item--formItem bisector">
					<FleetVerifyInput
						label={portType === "ballast" ? "Loading Rate" : "Discharging Rate"}
						needVerity={true}
						type="number"
						disabled={["DB", "DT", "LB", "LT"].includes(item?.purpose)}
						value={item?.loadingDischargeRate}
						onChange={(val: number) => {
							const params = calculateValues(item, vesselSource, "loadingDischargeRate", val);
							onCommit?.(currentIndex, params);
						}}
					/>
					<FleetVerifyInput
						type="number"
						label={t("common.portExpenses")}
						value={item?.portExpense}
						onChange={(val: number) => {
							onChange?.(currentIndex, "portExpense", val);
						}}
					/>
				</div>
				<div className="portRotation-item--formItem bisector">
					<FleetVerifyInput
						label={t("estimation.workDays")}
						value={item?.workDays}
						onChange={(val: number) => {
							const params = calculateValues(item, vesselSource, "workDays", val);
							onCommit?.(currentIndex, params);
						}}
					/>
					<FleetVerifyInput
						label={t("estimation.idleDays")}
						value={item?.idleDays}
						onChange={(val: number) => {
							const params = calculateValues(item, vesselSource, "idleDays", val);
							onCommit?.(currentIndex, params);
						}}
					/>
				</div>
				<div className="portRotation-item--formItem eightyPercent">
					<FleetVerifyInput
						label={t("estimation.portMarginDay")}
						value={item?.portMarginDay}
						min={item?.portMarginUnit === "%" ? 0 : undefined}
						max={item?.portMarginUnit === "%" ? 100 : undefined}
						onChange={(val: number) => {
							const params = calculateValues(item, vesselSource, "portMarginDay", val);
							onCommit?.(currentIndex, params);
						}}
					/>
					<FleetTabbar
						list={["%", "Day"]}
						value={item?.portMarginUnit}
						onChange={(val: "%" | "Day") => {
							const params = calculateValues(item, vesselSource, "portMarginUnit", val);
							onCommit?.(currentIndex, params);
						}}
					/>
				</div>
				<div className="portRotation-item--formItem eightyPercent">
					<FleetVerifyInput
						label={t("estimation.totalDays")}
						disabled
						value={item?.totalDays}
						onChange={(val: number) => {
							onChange?.(currentIndex, "totalDays", val);
						}}
					/>
					<FleetCustomRadio
						label="Crane ?"
						value={!!item?.crane}
						onChange={(val) => {
							const params = calculateValues(item, vesselSource, "crane", +val as 0 | 1);
							onCommit?.(currentIndex, params);
						}}
					/>
				</div>
				<div className="portRotation-item--formItem bisector">
					<FleetVerifyInput
						type="number"
						label={t("estimation.conFo")}
						disabled
						value={item?.conFo}
						onChange={(val: number) => {
							onChange?.(currentIndex, "conFo", val);
						}}
					/>
					<FleetVerifyInput
						label={t("estimation.conDo")}
						value={item?.conDo}
						disabled
						onChange={(val: number) => {
							onChange?.(currentIndex, "conDo", val);
						}}
					/>
				</div>
				<div className="portRotation-item--formItem bisector">
					<FleetVerifyInput
						type="number"
						label={t("estimation.distance")}
						value={item?.distance}
						onChange={(val: number) => {
							const params = calcSeaValues(item, vesselSource, "distance", val);
							onCommit?.(currentIndex, params);
						}}
					/>
					<FleetVerifyInput
						label={t("estimation.ecaDistance")}
						value={item?.ecaDistance}
						onChange={(val: number) => {
							const params = calcSeaValues(item, vesselSource, "ecaDistance", val);
							onCommit?.(currentIndex, params);
						}}
					/>
				</div>
			</div>
		</div>
	);
};

export default EstPortRotationItem;
