import React, { FC } from "react";
import { FleetAisSelectTimeProps } from "./type"
import style from "./index.module.less";
import { FleetTooltip } from "@/components";
import classnames from "classnames";

const FleetAisDateSelect: FC<FleetAisSelectTimeProps> = ({
  options,
  activeTime,
  onSelect
}) => {
  return <ul className={style["fleet-aisDateSelect"]}>
    {
      options?.map((item, index) => {
        if (item?.value === "3/day") {
          return <FleetTooltip
            children={<span>High Frequency</span>}
            placement="top"
            trigger="hover"
            overlayClassName={style["fleet-aisDateSelect-tooltip"]}
            outerLayer={<li
              key={index}
              onClick={() => onSelect(item?.value)}
              className={classnames("highest", {
                active: activeTime === item?.value
              })}
            >{item?.label}</li>}
          />
        }
        return <li
          key={index}
          onClick={() => onSelect(item?.value)}
          className={classnames({
            active: activeTime === item?.value
          })}
        >{item?.label}</li>
      })
    }
  </ul>
}

export default FleetAisDateSelect