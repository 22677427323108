import React, { Ref, forwardRef, useEffect, useImperativeHandle, useMemo } from "react";
import style from "./index.module.less";
import { ArrivalReportProps } from "./type";
import { arrivalReportConfig, newArrivalReportRow } from "./source";
import { CommonPortSelect, FleetToast } from "@/common";
import Button from "@/_components/Button";
import { Popover } from "@/_components";
import { FleetLoading, FleetTextarea } from "@/components";
import { OtherReportsCommonRefType } from "../../type";
import useCommonReport from "../../hooks/useCommonReport";
import {
  deleteArrivalReportAction,
  getArrivalReportAction,
  saveArrivalReportAction
} from "@/action/voyageManage/otherReports";
const ArrivalReport = forwardRef<OtherReportsCommonRefType, ArrivalReportProps>(
  ({ type, voyageId }, ref) => {
    const {
      loading,
      dataSource,
      commit,
      change,
      add,
      handleDelete,
      getReportList,
      handleSave,
    } = useCommonReport(voyageId, newArrivalReportRow, {
      getReportList: getArrivalReportAction,
      saveReport: saveArrivalReportAction,
      deleteReport: deleteArrivalReportAction,
    });
    useEffect(() => {
      if (type == "arrivalReport") getReportList();
    }, [type]);
    useImperativeHandle(ref, () => ({
      onSave: handleSave,
      data: dataSource
    }));
    return (
      <div className={style["otherReport-arrival"]}>
        <FleetLoading loading={loading} />
        <div className="arrival-header">
          <span>Arrival Report</span>
          <Button type="secondary" onClick={add}>
            Add New
          </Button>
        </div>
        <div className="arrival-main">
          {dataSource?.map((portItem, portIndex) => {
            return (
              <div className="arrival-item" key={portIndex}>
                <div className="arrival-port">
                  <span>Port Name</span>
                  <CommonPortSelect
                    activeItems={{
                      // id: item.deliveryPortId,
                      portCode: portItem?.portCode,
                      portName: portItem?.portName,
                    }}
                    showKey="portName"
                    primaryKey="portCode"
                    onFinish={(newPortItemm, operateType) => {
                      commit?.(portIndex, {
                        portCode: newPortItemm?.portCode,
                        portName: newPortItemm?.portName,
                      });
                    }}
                  />
                </div>
                <table cellSpacing={0}>
                  <tbody>
                    {arrivalReportConfig.map((item, index) => {
                      return (
                        <tr className="arrivalTable-tr" key={index}>
                          {item.row.map((ele, idx) => {
                            return (
                              <td
                                colSpan={ele.colSpan}
                                rowSpan={ele.rowSpan}
                                key={idx}
                                className={
                                  ele.label
                                    ? "arrivalTable-header"
                                    : "arrivalTable-input"
                                }
                              >
                                {ele.label ? (
                                  <div>{ele.label}</div>
                                ) : (
                                  <div className="arrivalTable-cell">
                                    {ele?.render?.(
                                      portItem[ele.prop],
                                      portIndex,
                                      portItem,
                                      { onCommit: commit }
                                    )}
                                  </div>
                                )}
                              </td>
                            );
                          })}
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
                <div className="arrival-footer">
                  <div className="arrival-remark">
                    <span>Remark</span>
                    <FleetTextarea
                      className="arrival-remarkContain"
                      value={portItem.remark}
                      onChange={(val: string) => {
                        commit(portIndex, { remark: val });
                      }}
                      placeholder="Please input"
                    />
                  </div>
                  <Popover
                    render={(setIsOpen) => {
                      return (
                        <FleetToast
                          label="Are you sure delete this item?"
                          onCancel={() => {
                            setIsOpen(false);
                          }}
                          onConfirm={() => {
                            handleDelete(portIndex, portItem.id);
                            setIsOpen(false);
                          }}
                        />
                      );
                    }}
                  >
                    <Button
                      size="small"
                      type="secondary"
                      className="arrival-delete"
                    >
                      <span
                        className="font_family fleet-delete"
                        style={{ color: "red" }}
                      ></span>
                    </Button>
                  </Popover>
                </div>
              </div>
            );
          })}
        </div>
      </div>
    );
  }
);

export default ArrivalReport;
