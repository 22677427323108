import { PromptChildrenProps } from "@/common/Prompt";
import { ExtendsKeys } from "@/types";
import { CommonInitItemEvent } from "@/types/event";

export type PortType = "P" | "T" | "C";

type PortAddKeys =
	| "portName"
	| "portNameCn"
	| "alias"
	| "portType"
	| "portCode"
	| "lon"
	| "lat"
	| "country"
	| "countryCode"
	| "timeZone"
	| "draftLimitation"
	| "area";

type PortAddStrKeys = ExtendsKeys<
	PortAddKeys,
	"portName" | "portNameCn" | "portCode" | "alias" | "country" | "countryCode" | "area"
>;

type PortAddNumKeys = ExtendsKeys<PortAddKeys, "lon" | "lat" | "draftLimitation" | "timeZone">;

type PortAddEnumKeys = ExtendsKeys<PortAddKeys, "portType">;

type DatabasePortAddKeys = PortAddStrKeys | PortAddNumKeys | PortAddEnumKeys;

export type DatabasePortAddFormType = Record<PortAddStrKeys, string> &
	Record<PortAddNumKeys, number | ""> & {
		[K in PortAddEnumKeys]: K extends "portType" ? PortType : never;
	};

export type DatabasePortAddFormChangeEvent = <K extends DatabasePortAddKeys>(
	key: K,
	value: DatabasePortAddFormType[K]
) => void;

export type DatabasePortAddFormCommitEvent = (item: Partial<DatabasePortAddFormType>) => void;

export type DatabasePortAddFormInitEvent = (item: DatabasePortAddFormType) => void;

export type DatabasePortAddProps = {
	onReset: () => void;
} & PromptChildrenProps;

// var timeZones = [
//   { label: "国际日期变更线西", value: -12 },
//   { label: "西十一区", value: -11 },
//   { label: "西十区", value: -10 },
//   { label: "西九区", value: -9 },
//   { label: "西八区", value: -8 },
//   { label: "西七区", value: -7 },
//   { label: "西六区", value: -6 },
//   { label: "西五区", value: -5 },
//   { label: "西四区", value: -4 },
//   { label: "西三区", value: -3 },
//   { label: "西二区", value: -2 },
//   { label: "西一区", value: -1 },
//   { label: "UTC", value: 0 },
//   { label: "东一区", value: 1 },
//   { label: "东二区", value: 2 },
//   { label: "东三区", value: 3 },
//   { label: "东四区", value: 4 },
//   { label: "东五区", value: 5 },
//   { label: "东六区", value: 6 },
//   { label: "东七区", value: 7 },
//   { label: "东八区", value: 8 },
//   { label: "东九区", value: 9 },
//   { label: "东十区", value: 10 },
//   { label: "东十一区", value: 11 },
//   { label: "国际日期变更线东", value: 12 }
// ];

export const initialPortItem: CommonInitItemEvent<DatabasePortAddFormType> = (item) => {
	return {
		lon: item?.lon ?? "",
		lat: item?.lat ?? "",
		draftLimitation: item?.draftLimitation ?? 0,
		timeZone: item?.timeZone ?? "",
		portType: item?.portType ?? "P",
		portCode: item?.portCode ?? "",
		portName: item?.portName ?? "",
		portNameCn: item?.portNameCn ?? "",
		alias: item?.alias ?? "",
		area: item?.area ?? "",
		country: item?.country ?? "",
		countryCode: item?.countryCode ?? ""
	};
};

export const initialDatabasePortAddForm: DatabasePortAddFormType = {
	lon: "",
	lat: "",
	draftLimitation: "",
	timeZone: "",
	portType: "P",
	portCode: "",
	portName: "",
	portNameCn: "",
	alias: "",
	area: "",
	country: "",
	countryCode: ""
};

export const timeZones = [
	{ label: "IDLW (-12:00)", value: -12 },
	{ label: "UTC-11 (-11:00)", value: -11 },
	{ label: "UTC-10 (-10:00)", value: -10 },
	{ label: "UTC-9 (-09:00)", value: -9 },
	{ label: "UTC-8 (-08:00)", value: -8 },
	{ label: "UTC-7 (-07:00)", value: -7 },
	{ label: "UTC-6 (-06:00)", value: -6 },
	{ label: "UTC-5 (-05:00)", value: -5 },
	{ label: "UTC-4 (-04:00)", value: -4 },
	{ label: "UTC-3 (-03:00)", value: -3 },
	{ label: "UTC-2 (-02:00)", value: -2 },
	{ label: "UTC-1 (-01:00)", value: -1 },
	{ label: "UTC (+00:00)", value: 0 },
	{ label: "UTC+1 (+01:00)", value: 1 },
	{ label: "UTC+2 (+02:00)", value: 2 },
	{ label: "UTC+3 (+03:00)", value: 3 },
	{ label: "UTC+4 (+04:00)", value: 4 },
	{ label: "UTC+5 (+05:00)", value: 5 },
	{ label: "UTC+6 (+06:00)", value: 6 },
	{ label: "UTC+7 (+07:00)", value: 7 },
	{ label: "UTC+8 (+08:00)", value: 8 },
	{ label: "UTC+9 (+09:00)", value: 9 },
	{ label: "UTC+10 (+10:00)", value: 10 },
	{ label: "UTC+11 (+11:00)", value: 11 },
	{ label: "IDLE (+12:00)", value: 12 }
];

export const portTypeMap = [
	{
		label: "Port",
		value: "P"
	},
	{
		label: "Transit",
		value: "T"
	},
	{
		label: "Customize",
		value: "C"
	}
];

export const checkPortKeys: DatabasePortAddKeys[] = ["portName", "portCode", "draftLimitation"];
