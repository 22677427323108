import React, { FC, memo } from "react";
import style from "./index.module.less";
import { vesselList } from "./source";
import { Icon } from "@/_components";

const LegendVessel: FC<{}> = memo((props) => {
	return (
		<div className={style["legend-vessels"]}>
			<span className="vessels-title">Vessels Icon:</span>
			<ul className="vessels-container">
				{vesselList?.map((item, index) => {
					return (
						<li key={item?.value}>
							<Icon size={24} type={item?.icon} color={item?.color} />
							<span>{item?.label}</span>
						</li>
					);
				})}
			</ul>
		</div>
	);
});

export default LegendVessel;
