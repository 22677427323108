import { HandoverFormFixtureNotesType } from "./type";

export const initialFixtureNotes = (item?: Partial<HandoverFormFixtureNotesType>): HandoverFormFixtureNotesType => {
  return {
    chartererId: item?.chartererId ?? null,
    chartererName: item?.chartererName ?? "",
    shipperId: item?.shipperId ?? null,
    shipperName: item?.shipperName ?? "",
    brokerId: item?.brokerId ?? null,
    brokerName: item?.brokerName ?? "",
    fixtureDate: item?.fixtureDate ?? "",
    cargoName: item?.cargoName ?? "",
    cargoQuantity: item?.cargoQuantity ?? "",
    cargoUnit: item?.cargoUnit ?? "MT",
    freightRate: item?.freightRate ?? "",
    freightType: item?.freightType ?? "FR",
    commission: item?.commission ?? "",
    brokerage: item?.brokerage ?? "",
    layCanFrom: item?.layCanFrom ?? "",
    layCanTo: item?.layCanTo ?? "",
    chartererRatingSpecialClaus: item?.chartererRatingSpecialClaus ?? "",
    tough: item?.tough ?? false,
    vip: item?.vip ?? false,
    paymentRate: item?.paymentRate ?? "",
    paymentTerm: item?.paymentTerm ?? "",
    paymentStartDay: item?.paymentStartDay ?? "",
    id: item?.id ?? null,
    voyageId: item?.voyageId ?? null
  }
}

export const checkKeys:(keyof HandoverFormFixtureNotesType)[] = [
  "chartererName",
  "fixtureDate",
  "cargoName",
  "cargoQuantity",
  "cargoUnit",
  "freightRate",
  "freightType",
  "layCanFrom",
  "layCanTo",
  "chartererRatingSpecialClaus"
]