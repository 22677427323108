import { CommonInitItemEvent } from "@/types/event";
import { VoyageManageRemarkContactOptionType } from "./type";

export const initialContactOption: CommonInitItemEvent<VoyageManageRemarkContactOptionType> = (
	item
) => {
	return {
		type: item?.type ?? null,
		loading: item?.loading ?? false,
		hide: item?.hide ?? true
	};
};
