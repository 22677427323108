import { HfPerformanceCommitEvent, HfPerformanceFormChangeEvent, HfPerformancePortChangeEvent, HfPerformanceWorkChangeEvent, HfPortFormType, checkPerformanceFormKeys, initialPerformanceForm } from "@/featrue/voyagemanage/headFixture/performance/type";
import { useState } from "react";

const usePerformanceForm = () => {
  const [performanceForm, setPerformanceForm] = useState<HfPortFormType>({
    ...initialPerformanceForm()
  });

  const portChange: HfPerformancePortChangeEvent = (portKey: "ballast" | "laden", id, key, value) => {
    setPerformanceForm(prev => {
      const portItem = prev[portKey];
      const item = portItem[id];
      item[key] = value;
      portItem.splice(id, 1, item);
      return {
        ...prev,
        [portKey]: portItem
      }
    })
  }

  const workChange: HfPerformanceWorkChangeEvent = (portKey, key, value) => {
    setPerformanceForm(prev => {
      const workItem = prev[portKey];
      workItem[key] = value;
      return {
        ...prev,
        [portKey]: workItem
      }
    })
  }

  const commit: HfPerformanceCommitEvent = (item) => {
    console.log("setPerformanceForm", item?.work)
    setPerformanceForm(item)
  }

  const change = (key: "performanceClause", value: string) => {
    setPerformanceForm(prev => {
      return {
        ...prev,
        [key]: value
      }
    })
  }

  const clear = () => {
    setPerformanceForm({
      ...initialPerformanceForm()
    })
  }

  const check = () => {
    let checkKey = "";
    let checked = true;
    for (let item of checkPerformanceFormKeys) {
      if (performanceForm[item] !== 0 && !performanceForm[item]) {
        checkKey = item;
        checked = false;
        break;
      }
    }
    return {
      checked,
      checkKey
    }
  }

  return {
    performanceForm,
    portChange,
    workChange,
    check,
    commit,
    change,
    clear
  }

}

export default usePerformanceForm