import React, { FC } from "react";
import classnames from "classnames";
import style from "./index.module.less";
import { InvFreightCounterPartyProps } from "./type";
import { FleetFormItem, FleetVerifyInput } from "@/components";
import { CommonCompanySelect } from "@/common/Select";
import { Button } from "@/_components";

const InvFreightCounterParty: FC<InvFreightCounterPartyProps> = ({
  item,
  onCommit,
  fieldItems,
  onFieldChange,
  onFieldDelete,
  onFieldOpen
}) => {
  return <div className={classnames("invoicesDetail-form", style["invFreight-basics"])}>
    <div className="invoicesForm-header">
      <span>Counter party</span>
      <span>Recipient information</span>
    </div>
    <div className="invoicesForm-container">
      <FleetFormItem label="Company/Charterer" needVerity>
        <CommonCompanySelect
          showKey="companyName"
          activeItems={{
            id: item?.ownerId,
            companyName: item?.ownerName
          }}
          onFinish={(newItem, type) => {
            onCommit?.({
              ownerId: newItem?.id ?? null,
              ownerName: newItem?.companyName ?? "",
              registerAddress: newItem?.registerAddress ?? ""
            })
          }}
        />
      </FleetFormItem>
      <FleetVerifyInput
        value={item?.registerAddress}
        label="Business registration address"
        onChange={(val: string) => {
          onCommit?.({
            registerAddress: val
          })
        }}
      />
      {
        fieldItems?.map((item, index) => {
          return <div className="freight-fields" key={index}>
            <FleetVerifyInput
              value={item?.fieldVal}
              label={item?.fieldName}
              onChange={(val: string) => {
                onFieldChange?.(index, "fieldVal", val)
              }}
            />
            <span className="font_family fleet-minus"></span>
          </div>
        })
      }
      <Button
        size="small"
        type="secondary"
        prefixIcon="fleet-plus"
        // onClick={() => onFieldOpen?.("basic")}
        disabled
      >Add field</Button>
    </div>
  </div>
}

export default InvFreightCounterParty