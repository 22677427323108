import { FleetButton, FleetInput, FleetSelect } from "@/components";
import { lanOrLonInputPropsType } from "./type";
import style from "./index.module.less";
import React, { useMemo, useState } from "react";
// import { Popover } from "antd";
import useIndex from "./hook/useIndex";
import { Button, Popover } from "@/_components";
const LatLonInput: React.FC<lanOrLonInputPropsType> = ({ onCommit,latValue,lonValue }) => {
  const {
    minLat,
    secLat,
    minLon,
    secLon,
    directionLat,
    directionLon,
    openLatLon,
    northSouth,
    eastWest,
    setMinLat,
    setSecLat,
    setMinLon,
    setSecLon,
    setDirectionLat,
    setDirectionLon,
    setOpenLatLon,
    confirmLatLon,
  } = useIndex(onCommit);
  const position = useMemo(() => {
    if (!latValue && !lonValue ) {
      return "";
    }
    const lat = latValue?.toString().replace("-", "");
    const lon = lonValue?.toString().replace("-", "");
    const latNew =
      lat?.split(".")[0] +
      "°" +
      (Number(`0.${lat?.split(".")[1] || 0}`) * 60).toFixed(1) +
      "'" +
      (latValue < 0 ? "S" : "N");
    const lonNew =
      lon?.split(".")[0] +
      "°" +
      (Number(`0.${lon?.split(".")[1] || 0}`) * 60).toFixed(1) +
      "'" +
      (lonValue < 0 ? "W" : "E");
    return `${latNew} / ${lonNew}`;
  }, [latValue,lonValue]);
  const renderContent = (setIsOpen) => {
    return (
      <div className={style["movement-lat-lon-input"]}>
        <div className="lat-lon-input-content">
          <FleetInput
            value={minLat}
            type="number"
            onChange={(val) => setMinLat(Number(val))}
          ></FleetInput>
          °
          <FleetInput
            value={secLat}
            type="number"
            onChange={(val) => setSecLat(Number(val))}
          ></FleetInput>
          '
          <FleetSelect
            className="lat-lon-select"
            value={directionLat}
            options={northSouth}
            onChange={(val) => setDirectionLat(val)}
          />
          <FleetInput
            value={minLon}
            type="number"
            onChange={(val) => setMinLon(Number(val))}
          ></FleetInput>
          °
          <FleetInput
            value={secLon}
            type="number"
            onChange={(val) => setSecLon(Number(val))}
          ></FleetInput>
          '
          <FleetSelect
            className="lat-lon-select"
            value={directionLon}
            options={eastWest}
            onChange={(val) => setDirectionLon(val)}
          />
          <Button
            onClick={() => {
              confirmLatLon();
              setIsOpen(false);
            }}
          >
            ✓
          </Button>
        </div>
      </div>
    );
  };
  return (
    <Popover
      placement={"bottom"}
      trigger="click"
      open={openLatLon}
      render={renderContent}
      onOpenChange={(val) => setOpenLatLon(val)}
    >
      <div className={style["movement-lat-lon-div"]}>{position}</div>
    </Popover>
  );
};

export default LatLonInput;
