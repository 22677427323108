import React, { FC, memo } from "react";
import style from "./index.module.less";
import { typhoonOptions } from "./source";
import classnames from "classnames";
import { Icon } from "@/_components";

const LegendTyphoons: FC<{}> = (props) => {
	return (
		<div className={classnames("routeCommon-legend-item", style["legend-typhoons"])}>
			<span className="routeCommon-legend-label ">Typhoon(kts):</span>
			<ul className="legend-typhoons-container">
				{typhoonOptions?.map((item, index) => {
					return (
						<li className="legend-typhoons-item" key={item?.label}>
							<Icon type={item?.value} size={24} color="rgb(144,144,144)" />
							<span>{item?.label}</span>
						</li>
					);
				})}
			</ul>
		</div>
	);
};

export default memo(LegendTyphoons);
