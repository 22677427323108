import React from "react";
import style from "./index.module.less";
import { FleetFieldAddProps } from "./type";
import { FleetPrompt } from "..";
import { FleetButton, FleetIcon, FleetTextarea, FleetVerifyInput } from "@/components";
import useFieldAdd from "./useFieldAdd";
import { Button } from "@/_components";

const FleetFieldAdd: React.FC<FleetFieldAddProps> = ({
  hide,
  valueProp = {
    label: "Value",
    type: "text"
  },
  onClose,
  onAdd,
  mode = "absolute"
}) => {
  const { fields, change, handleAddField } = useFieldAdd(hide, onAdd);

  return <FleetPrompt hide={hide} mode={mode}>
    <div className={style["fleet-fieldAdd"]}>
      <div className="filedAdd-header">
        <span className="fleet-title">Add field</span>
        <FleetIcon
          type="FleetCloseIcon"
          outerLayerClassname="header-icon"
          onClick={onClose}
        />
      </div>
      <div className="filedAdd-container">
        <div>
          <FleetVerifyInput
            needVerity
            label="Title"
            value={fields?.fieldName}
            onChange={(val: string) => {
              change("fieldName", val);
            }}
          />
          <FleetVerifyInput
            label={valueProp?.label}
            type={valueProp.type}
            value={fields?.fieldVal}
            onChange={(val: string | number) => {
              change("fieldVal", val);
            }}
          />
        </div>
        <FleetTextarea
          label="Descriptions"
          value={fields?.description}
          onChange={(val: string) => {
            change("description", val);
          }}
        />
      </div>
      <div className="filedAdd-footer">
        <Button
          type="secondary"
          size="small"
          onClick={onClose}
        >Cancel</Button>
        <Button
          size="small"
          onClick={handleAddField}
        >Confirm</Button>
      </div>
    </div>
  </FleetPrompt>
}

export default FleetFieldAdd