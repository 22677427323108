import { useMemo, useRef } from "react";
import { InvStatementHireItemType, InvStatementHireProps } from "../type";
import { checkKeys, initialStatementHireItem, initialStatementOffHireItem } from "../source";
import { useCommonIndex } from "@/hook";
import { getRetainDecimalsNumber } from "@/tools/amount";

const useIndex = (otherItem: Pick<InvStatementHireProps["otherItem"], "addHire">) => {
	const { dataSource, setDataSource, commit, check } = useCommonIndex<InvStatementHireItemType>(
		(item: InvStatementHireItemType) => initialStatementHireItem("init", item),
		checkKeys
	);

	const offHireDelIds = useRef<number[]>([]);

	const summary = useMemo(() => {
		if (!otherItem?.addHire)
			return {
				plus: 0,
				less: 0
			};
		return {
			plus: dataSource?.ttlOnHireDay * +dataSource?.dailyHire,
			less: dataSource?.addComm + dataSource?.brokerage
		};
	}, [dataSource, otherItem?.addHire]);

	const ttlOffHireDaySummary = useMemo(() => {
		return dataSource?.offHireFoList?.reduce((prev, curr) => {
			return (
				prev +
				getRetainDecimalsNumber(
					(curr?.offHireEndDay - curr?.offHireStartDay) / (1000 * 60 * 60 * 24),
					3
				)
			);
		}, 0);
	}, [dataSource?.offHireFoList]);

	const handleOffHireCommit: InvStatementHireProps["onOffHireItemCommit"] = (
		currentIndex,
		item
	) => {
		setDataSource((prev) => {
			const offHireItems = prev?.["offHireFoList"];
			const currentItem = offHireItems?.[currentIndex];
			offHireItems?.splice(currentIndex, 1, { ...currentItem, ...item });
			return { ...prev, offHireFoList: [...offHireItems] };
		});
	};

	const handleOffHireAdd: InvStatementHireProps["onOffHireItemAdd"] = () => {
		setDataSource((prev) => {
			const offHireItems = prev?.["offHireFoList"];
			return { ...prev, offHireFoList: [...offHireItems, initialStatementOffHireItem(null)] };
		});
	};

	const handleOffHireDelete: InvStatementHireProps["onOffHireItemDelete"] = (currentIndex) => {
		setDataSource((prev) => {
			const offHireItems = prev?.["offHireFoList"];
			const currentItem = offHireItems?.[currentIndex];
			currentItem?.id && offHireDelIds.current.push(currentItem?.id);
			offHireItems?.splice(currentIndex, 1);
			return { ...prev, offHireFoList: [...offHireItems] };
		});
	};

	const init = (type: "init" | "details", item: Partial<InvStatementHireItemType>) => {
		setDataSource(initialStatementHireItem(type, item));
		offHireDelIds.current = [];
	};

	return {
		dataSource: {
			...dataSource,
			ttlOffHireDay: ttlOffHireDaySummary
		},
		summary,
		offHireDelIds,
		commit,
		handleOffHireCommit,
		handleOffHireAdd,
		handleOffHireDelete,
		handleHireCheck: check,
		init
	};
};

export default useIndex;