import { useEffect, useRef } from "react";

const useHorizontalScroll = <T extends HTMLElement>(option:{
  speed: number
}) => {
  const elementRef = useRef<T>(null);

  const onHorizontalScroll = () => {
    elementRef?.current?.addEventListener('wheel', (event) => {
      event.preventDefault(); // 阻止默认的纵向滚动行为
      elementRef.current.scrollLeft += event.deltaY / (option?.speed ?? 1); // 使用 deltaY 控制横向滚动
    });
  }

  useEffect(() => {
    elementRef?.current?.addEventListener("wheel", onHorizontalScroll)
    return () => {
      elementRef?.current?.removeEventListener("wheel", onHorizontalScroll)
    }
  },[])

  return {
    elementRef
  }

}

export default useHorizontalScroll;