import { addVesselAction, editVesselAction, getVesselAction } from "@/action/dataBase/vessel";
import {
  DatabaseVesselAddChangeEvent,
  DatabaseVesselAddCommitEvent,
  DatabaseVesselAddFormType,
  DatabaseVesselPerformancePortChangeEvent,
  DatabaseVesselPerformanceWorkChangeEvent,
  checkVesselKeys,
  initialDatabaseVesselForm,
  initialPerformanceForm
} from "@/featrue/database/vessel/add/type";
import { useAppDispatch } from "@/hook";
import useReminder from "@/hook/useReminder";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
const useVessel = (
  onReset?: () => void,
  portId?: string | number
) => {
  const { t } = useTranslation()
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { reminder } = useReminder();
  const [loading, setLoading] = useState(false);
  const [dataSource, setDataSource] = useState<Omit<DatabaseVesselAddFormType, "performance">>({
    ...initialDatabaseVesselForm
  });
  const [performanceSource, setPerformanceSource] = useState<DatabaseVesselAddFormType["performance"]>({
    ...initialPerformanceForm()
  });

  const change: DatabaseVesselAddChangeEvent = (key, value) => {
    setDataSource(prev => ({ ...prev, [key]: value }))
  }

  const portChange: DatabaseVesselPerformancePortChangeEvent = (portKey, id, key, value) => {
    setPerformanceSource(prev => {
      const performanceItem = prev[portKey];
      const portItem = performanceItem[id];
      portItem[key] = value;
      performanceItem.splice(id, 1, portItem);
      return {
        ...prev,
        [portKey]: performanceItem
      }
    })
  }

  const workChange: DatabaseVesselPerformanceWorkChangeEvent = (portKey, key, value) => {
    setPerformanceSource(prev => {
      const performanceItem = prev[portKey];
      performanceItem[key] = value;
      return {
        ...prev,
        [portKey]: performanceItem
      }
    })
  }

  const commit: DatabaseVesselAddCommitEvent = (item) => {
    setDataSource(prev => ({ ...prev, ...item }))
  }

  const clear = () => {
    setDataSource({ ...initialDatabaseVesselForm });
    setPerformanceSource({ ...initialPerformanceForm() })
  }

  const check = () => {
    let checkKeys = "";
    let checked = true;
    for (let key of checkVesselKeys) {
      if (dataSource[key] !== 0 && !dataSource[key]) {
        checkKeys = key;
        checked = false;
        break
      }
    }

    return {
      checkKeys,
      checked
    }
  }

  const getVesselFront = () => {
    setLoading(true)
  }

  const getVesselSuccess = (response) => {
    commit({
      ...response?.data
    })
    setLoading(false);
  }

  const getVesselError = (error) => {
    reminder("error", error?.msg ? (error?.msg + ": " + error?.data) : error?.data);
    setLoading(false);
  }

  const addVesselSuccess = (response) => {
    setLoading(false);
    reminder("success", response?.msg);
    onReset?.()
  }

  const getVessel = () => {
    getVesselAction(
      {
        id: portId
      },
      getVesselFront,
      getVesselSuccess,
      getVesselError,
      dispatch,
      navigate
    )
  }

  const addVessel = () => {
    const { checkKeys, checked } = check();
    if (!checked) {
      reminder("error", `Field ${t(`vesselArrival.${checkKeys}`)} is required`);
      return
    }
    addVesselAction(
      {
        ...dataSource,
        performance: performanceSource
      },
      getVesselFront,
      addVesselSuccess,
      getVesselError,
      dispatch,
      navigate
    )
  }

  const editVessel = () => {
    const { checkKeys, checked } = check();
    if (!checked) {
      reminder("error", `Field ${t(`vesselArrival.${checkKeys}`)} is required`);
      return
    }
    editVesselAction(
      {
        ...dataSource,
        id: portId,
        performance: performanceSource
      },
      getVesselFront,
      addVesselSuccess,
      getVesselError,
      dispatch,
      navigate
    )
  }

  return {
    dataSource,
    performanceSource,
    loading,
    clear,
    change,
    workChange,
    portChange,
    commit,
    getVessel,
    addVessel,
    editVessel
  }

}

export default useVessel;