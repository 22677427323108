import { FleetOptionType } from "@/types/advancedType";
import { RouteCommonSelectTimeKeys, RouteCommonVesselCardItemType } from "../../type";
import { ExtendsKeys } from "@/types";
import { ArrivalAisStatusMap, ArrivalShipStatusMap } from "@/common/Select";
import { formatThousandthNumber } from "@/tools/amount";
import { IconProps } from "@/_components/Icon/interface";
import { RouteCommonVesselCardOperatorToolType } from "./type";
import dayjs from "dayjs";
import React from "react";

export const selectTimeOptions: FleetOptionType<
	"3" | "7" | "14" | "30" | "60" | "90" | "1Y" | "2Y",
	RouteCommonSelectTimeKeys
>[] = [
	{
		label: "3",
		value: "3/day"
	},
	{
		label: "7",
		value: "7/day"
	},
	{
		label: "14",
		value: "14/day"
	},
	{
		label: "30",
		value: "30/day"
	},
	{
		label: "60",
		value: "60/day"
	},
	{
		label: "90",
		value: "90/day"
	},
	{
		label: "1Y",
		value: "1/year"
	},
	{
		label: "2Y",
		value: "2/year"
	}
];

export const options: (FleetOptionType<
	"Updated(LT)" | "Speed" | "ETA(LT)" | "IMO" | "Status" | "DWT",
	ExtendsKeys<
		keyof RouteCommonVesselCardItemType,
		"speed" | "eta" | "imo" | "shipStatus" | "dwt" | "updateTime"
	>
> & {
	formatter: (item: RouteCommonVesselCardItemType) => string | React.ReactNode;
})[] = [
	{
		label: "Updated(LT)",
		value: "updateTime",
		formatter: (item) => {
			const diffInMinutes = dayjs(item?.updateTime).diff(dayjs(), "minute");
			const marker = diffInMinutes > 0 ? "" : "-",
				hours = Math.floor(Math.abs(diffInMinutes) / 60),
				minute = Math.floor(Math.abs(diffInMinutes) % 60),
				diffFmt = `${marker}` + (hours >= 1 ? `${hours}h ` : "") + `${minute}m`;
			console.log("diffInMinutes", {
				diffInMinutes,
				marker,
				hours,
				minute,
				diffFmt
			});
			return (
				<span className={`tracking-item--value updateTime`}>
					{item?.updateTime && (
						<span className="bg-[#FF8632] rounded-[20px] text-[14px] text-white font-normal leading-[16px] px-[4px] py-[2px] mr-[2px]">
							{diffFmt}
						</span>
					)}
					{item?.updateTime ? dayjs(item?.updateTime)?.format("YYYY-MM-DD HH:mm:ss") : null}
				</span>
			);
		}
	},
	{
		label: "Speed",
		value: "speed",
		formatter: (item) => {
			return <span className={`tracking-item--value speed`}>{`${item?.speed ?? 0} KTS`}</span>;
		}
	},
	{
		label: "ETA(LT)",
		value: "eta",
		formatter: (item) => {
			const diffInMinutes = dayjs(item?.eta).diff(dayjs()) / (1000 * 60),
				minute = Math.abs(diffInMinutes),
				marker = diffInMinutes > 0 ? "" : "-",
				days = Math.floor(minute / 1440),
				hours = Math.floor((minute % 1440) / 60),
				minutes = Math.floor(minute % 60),
				diffFmt =
					`${marker}` +
					(days >= 1 ? `${days}d ` : "") +
					(hours >= 1 ? `${hours}h ` : "") +
					(minutes >= 1 ? `${minutes}m` : "");
			return (
				<span className={`tracking-item--value eta`}>
					{item?.eta && (
						<span className="bg-[#E1F9DE] rounded-[20px] text-[14px] text-[#355691] font-normal leading-[16px] px-[4px] py-[2px] mr-[2px]">
							{diffFmt}
						</span>
					)}
					{item?.eta ? dayjs(item?.eta)?.format("YYYY-MM-DD HH:mm:ss") : null}
				</span>
			);
		}
	},
	{
		label: "IMO",
		value: "imo",
		formatter: (item) => {
			return <span className={`tracking-item--value imo`}>{item?.imo}</span>;
		}
	},
	{
		label: "Status",
		value: "shipStatus",
		formatter: (item) => {
			return (
				<span className={`tracking-item--value shipStatus`}>
					{ArrivalAisStatusMap[item?.status]}
				</span>
			);
			// return ArrivalShipStatusMap[item?.shipStatus ?? item?.status];
		}
	},
	{
		label: "DWT",
		value: "dwt",
		formatter: (item) => {
			return (
				<span className={`tracking-item--value dwt`}>
					{formatThousandthNumber(item?.dwt, 0, 0)}
				</span>
			);
			// return formatThousandthNumber(item?.dwt, 0, 0);
		}
	}
];

export const toolOptions: (FleetOptionType<
	"Save" | "Portcall" | "Particular" | "Speed",
	RouteCommonVesselCardOperatorToolType
> & { icon: IconProps["type"] })[] = [
	{
		label: "Save",
		value: "save",
		icon: "fleet-collected"
	},
	{
		label: "Portcall",
		value: "portCall",
		icon: "fleet-ports"
	},
	{
		label: "Particular",
		value: "particular",
		icon: "fleet-particular"
	},
	{
		label: "Speed",
		value: "ais",
		icon: "fleet-chart"
	}
];
