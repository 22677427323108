import { deleteMovementTaskCommentAction, getMovementTaskCommentListAction, saveMovementTaskCommentAction } from "@/action/voyageManage/movement";
import { useAppDispatch } from "@/hook";
import useReminder from "@/hook/useReminder";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { MovementTaskCommentItemType } from "../components";
import { MovementTaskCommentProps } from "../type";

const useIndex = (
  currentTaskItem: MovementTaskCommentProps["currentTaskItem"],
  onTaskItemChange: MovementTaskCommentProps["onTaskItemChange"]
) => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { reminder } = useReminder();

  const [loading, setLoading] = useState(false);
  const [dataSource, setDataSource] = useState<MovementTaskCommentItemType[]>([])
  const [content, setContent] = useState<string>("")

  const saveMovementTaskCommentSuccess = (response) => {
    getMovementTaskCommentList()
    setContent("")
  }

  const saveMovementTaskComment = () => {
    if (!currentTaskItem || !content) return
    saveMovementTaskCommentAction(
      {
        content: content,
        moveTaskId: currentTaskItem?.id
      },
      getMovementTaskCommentListFront,
      saveMovementTaskCommentSuccess,
      getMovementTaskCommentListFailed,
      dispatch,
      navigate
    )
  }

  const deleteMovementTaskComment = (commentId: number) => {
    deleteMovementTaskCommentAction(
      {
        id: commentId
      },
      getMovementTaskCommentListFront,
      saveMovementTaskCommentSuccess,
      getMovementTaskCommentListFailed,
      dispatch,
      navigate
    )
  }

  const getMovementTaskCommentListFront = () => {
    setLoading(true)
  }

  const getMovementTaskCommentListSuccess = (response) => {
    setDataSource(response?.data)
    setLoading(false)
    onTaskItemChange?.(currentTaskItem?.id, "totalCount", response?.data?.length)
  }

  const getMovementTaskCommentListFailed = (error) => {
    reminder("error", error?.msg ? (error?.msg + ": " + error?.data) : error?.data);
    setLoading(false)
  }

  const getMovementTaskCommentList = () => {
    getMovementTaskCommentListAction(
      {
        id: currentTaskItem?.id
      },
      getMovementTaskCommentListFront,
      getMovementTaskCommentListSuccess,
      getMovementTaskCommentListFailed,
      dispatch,
      navigate
    )
  }

  useEffect(() => {
    if (!currentTaskItem) {
      setDataSource([])
      return
    }
    getMovementTaskCommentList()
  }, [currentTaskItem])

  return {
    loading,
    dataSource,
    content,
    setContent,
    saveMovementTaskComment,
    deleteMovementTaskComment
  }
}

export default useIndex