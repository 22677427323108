import { CommonInitItemEvent } from "@/types/event";
import { EstCargoItemType } from "./type";

export const initialEstCargoItem: CommonInitItemEvent<EstCargoItemType> = (item) => {
  return {
    cargoName: item?.cargoName ?? "",
    id: item?.id ?? null,
    cargoQuantity: item?.cargoQuantity ?? "",
    cargoQuantityDiscrepancy: item?.cargoQuantityDiscrepancy ?? "",
    cargoUnit: item?.cargoUnit ?? "MT",
    freightRate: item?.freightRate ?? "",
    freightType: item?.freightType ?? "FR",
    brokerage: item?.brokerage ?? "",
    commission: item?.commission ?? "",
    layCanFrom: item?.layCanFrom ?? null,
    layCanTo: item?.layCanTo ?? null,
    dischargePortName: item?.dischargePortName ?? "",
    dischargePortCode: item?.dischargePortCode ?? "",
    loadPortName: item?.loadPortName ?? "",
    loadPortCode: item?.loadPortCode ?? "",
    resultId: item?.resultId ?? null,
    chartererName: item?.chartererName ?? ""
  }
}

export const checkKeys: (keyof EstCargoItemType)[] = [
  "freightRate"
]