import { ApiParameter, RequestApiMapType } from "@/api";
import request from "@/service";

import {
	getPreopExpensesParams,
	savePreopOtherExpensesParams,
	PreopOtherExpensesParamsType
} from "./type";
import { appVersion } from "@/api/constant";

const enum ApiMap {
	getPreopOtherExpensesParameter,
	savePreopOtherExpensesParameter
}

export type ApiUrl = keyof typeof ApiMap;

const otherExpensesApi: ApiParameter<ApiUrl> = {
	getPreopOtherExpensesParameter: {
		method: "get",
		url: appVersion + "/other/expense/pre-op"
	},
	savePreopOtherExpensesParameter: {
		method: "post",
		url: appVersion + "/other/expense/pre-op"
	}
};

export const getPreopOtherExpensesApi = (params: getPreopExpensesParams) => {
	return request.request({
		...otherExpensesApi.getPreopOtherExpensesParameter,
		url: `${otherExpensesApi.getPreopOtherExpensesParameter.url}/${params?.id}`,
		params: {
			voyageType: params?.voyageType
		}
	});
};

export const savePreopOtherExpensesApi = (params: savePreopOtherExpensesParams) => {
	return request.request({
		...otherExpensesApi.savePreopOtherExpensesParameter,
		data: params
	});
};

export const otherExpensesApiMap: RequestApiMapType<ApiUrl, PreopOtherExpensesParamsType> = {
	getPreopOtherExpensesApi,
	savePreopOtherExpensesApi
};
