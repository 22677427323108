import React, { useEffect, useState, useLayoutEffect } from "react";
import style from "./index.module.less";
import { cacheStorage } from "@/storage";
import { useAppDispatch, useAppSelector } from "@/hook";
import { useLocation, useNavigate } from "react-router-dom";
import PersonalInfo from "./components/personalInfo";
import BasicInfo from "./components/basicInfo";
import { getPersonalInfoAction } from "@/action/personalCenter/personalCenter";
import useReminder from "@/hook/useReminder";
import { PersonalCenterItemType } from "./type";
import { BasicInfoChangeEvent } from "./components/basicInfo/type";

const PersonalCenter: React.FC<{}> = (props) => {
  const navigate = useNavigate();
  const { reminder } = useReminder();
  const dispatch = useAppDispatch();
  const searchParams = useLocation()
  const { firstSelected } = useAppSelector((state) => state.GlobalStore);
  const access_token = cacheStorage.get("access_token");
  const _access_token = useAppSelector((state) => state.UserStore.access_token);

  const [data, setData] = useState<PersonalCenterItemType>();
  const getPersonalInfoSuccess = (res) => {
    setData(res.data?.user);
  };
  const getPersonalInfoError = (error) => {
    reminder(
      "error",
      error?.msg ? error?.msg + ": " + error?.data : error?.data
    );
  };

  const handleChange: BasicInfoChangeEvent = (key, value) => {
    setData((prev) => ({ ...prev, [key]: value }));
  };

  const getPersonalInfoData = () => {
    getPersonalInfoAction(
      null,
      () => {},
      getPersonalInfoSuccess,
      getPersonalInfoError,
      dispatch,
      navigate
    );
  };
  const handleBack = () => {
    navigate(`${firstSelected}${searchParams.search}`);
  };
  useEffect(() => {
    if (!(access_token || _access_token)) {
      navigate("/login");
      return;
    }
    getPersonalInfoData();
  }, []);
  return (
    <div className={style["fleet-personal-center"]}>
      <div className="personal-center-back" onClick={handleBack}>
        <span className="font_family fleet-back back-icon"></span>
        <span className="back-title">Back</span>
      </div>
      <div className="personal-center-content">
        <PersonalInfo data={data} />
        <BasicInfo data={data} onReset={getPersonalInfoData} onChange={handleChange} />
      </div>
    </div>
  );
};

export default PersonalCenter;
