import React, { FC } from "react";
import { FleetSelect } from "@/components";
import { CommonUserSelectProps } from "./type";
import useIndex from "./hooks/useIndex";
import { CommonUserPopup } from "./components";

const CommonUserSelect: FC<CommonUserSelectProps> = ({
	onFinish,
	activeItems,
	showKey,
	showCount,
	className,
	disabled,
	placeholder,
	primaryKey = "userId",
	roleParams
}) => {
	const {
		open,
		loadMore,
		loading,
		list,
		tagRender,
		handleItemSelect,
		handleSearch,
		focusPopup,
		selectValue,
		closePopup
	} = useIndex(activeItems, showKey, onFinish, primaryKey, disabled, roleParams);

	return (
		<FleetSelect
			value={selectValue}
			showSearch
			popupMatchSelectWidth={false}
			onDropdownVisibleChange={closePopup}
			mode={Array?.isArray(activeItems) ? "multiple" : undefined}
			open={open}
			placeholder={placeholder}
			onSearch={handleSearch}
			onFocus={() => focusPopup()}
			className={className}
			disabled={disabled}
			showCount={showCount}
			tagRender={tagRender}
			dropdownRender={() => (
				<CommonUserPopup
					loadMore={loadMore}
					list={list}
					loading={loading}
					onSelect={handleItemSelect}
				/>
			)}
		/>
	);
};

export default CommonUserSelect;
