import { CommonInitItemEvent } from "@/types/event"
import { VoyageManageTctcVoyageRemarkType } from "./type"

export const initialVoyageManageTctcVoyageRemark: CommonInitItemEvent<VoyageManageTctcVoyageRemarkType> = (item) => {
  return {
    tctc_rent_head_fixture: {
      attachment: item?.tctc_rent_head_fixture?.attachment ?? [],
      contact: item?.tctc_rent_head_fixture?.contact ?? []
    },
    tctc_relet_head_fixture: {
      attachment: item?.tctc_relet_head_fixture?.attachment ?? [],
      contact: item?.tctc_relet_head_fixture?.contact ?? []
    },
    tctc_other: {
      attachment: item?.tctc_other?.attachment ?? [],
      contact: item?.tctc_other?.contact ?? []
    }
  }
}