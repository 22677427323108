import React from "react";
import classnames from "classnames";
import style from "./index.module.less";
import { FleetButton, FleetTable } from "@/components";
import useGroup from "@/hook/database/vessel/useGroup";
import VesselFilter from "@/featrue/database/vessel/filter";
import DatabaseVesselAdd from "@/featrue/database/vessel/add";
import DatabaseVesselEdit from "@/featrue/database/vessel/edit";
import { Button } from "@/_components";
import { CommonRoleWrap } from "@/common";

const DatabaseVessel: React.FC<{}> = (props) => {
  const {
    loading,
    hideMap,
    onHideChange,
    onReset,
    record,
    columns,
    dataSource,
    pager,
    onPageChange,
    selectedRowKeys,
    onSelectChange,
    onSearch,
    activeIndex,
    onTabChange,
    onRow,
  } = useGroup();
  return (
    <div className={classnames(style["database-vessel"], "database-common")}>
      <div className="database-toolbar vessel-toolbar">
        <VesselFilter
          activeIndex={activeIndex}
          onSearch={onSearch}
          onTabChange={onTabChange}
        />
        <CommonRoleWrap roleStr="vessel:list:add">
          <Button
            size="small"
            onClick={() => onHideChange("add", false)}
            prefixIcon="fleet-add"
          >
            Add Vessel
          </Button>
        </CommonRoleWrap>
      </div>
      <FleetTable
        columns={columns}
        className="database-table"
        dataSource={dataSource}
        loading={loading}
        id="mmsi"
        pagination={{
          current: pager.pageNum,
          pageSize: pager.pageSize,
          total: pager.total,
          onChange: onPageChange,
          showSizeChanger: false,
        }}
        rowSelection={{
          selectedRowKeys,
          onChange: onSelectChange,
        }}
        onRow={onRow}
      />
      <DatabaseVesselAdd
        hide={hideMap?.add}
        onReset={onReset}
        onClose={() => onHideChange("add", true)}
      />
      <DatabaseVesselEdit
        hide={hideMap?.edit}
        onReset={onReset}
        portId={record?.current?.mmsi}
        onClose={() => onHideChange("edit", true)}
        item={record?.current}
      />
    </div>
  );
};

export default DatabaseVessel;
