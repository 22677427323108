import { CommonChangeEvent } from "@/types/event";
import { useState } from "react";

const useCheckGroup = <T extends string, U extends string>(initialGroup: () => Record<T, U[]>) => {
	const [dataSource, setDataSource] = useState<Record<T, U[]>>(initialGroup());

	const init = () => {
		setDataSource(initialGroup());
	};

	const select: CommonChangeEvent<Record<T, U>> = (key, value) => {
		setDataSource((prev) => {
			const currentItem = prev?.[key];
			const currentIndex = currentItem?.findIndex((item) => item === value);
			if (currentIndex === -1) {
				currentItem?.push(value);
			} else {
				currentItem?.splice(currentIndex, 1);
			}
			return {
				...prev,
				[key]: [...currentItem]
			};
		});
	};

	const selectAll: CommonChangeEvent<Record<T, U[]>> = (key, items) => {
		setDataSource((prev) => ({ ...prev, [key]: [...items] }));
	};

	return {
		dataSource,
		init,
		select,
		selectAll
	};
};

export default useCheckGroup;
