import React from "react";
import classnames from "classnames";
import style from "./index.module.less";
import { FleetTable, FleetVerifyInput } from "@/components";
import { columns } from "./source";
import useIndex from "./hooks/useIndex";
import { Button } from "@/_components";
import { CommonNewlyCompany, CommonRoleWrap } from "@/common";

const DatabaseCompany: React.FC<{}> = (props) => {
	const {
		loading,
		hide,
		record,
		opereteColumns,
		dataSource,
		editType,
		pager,
		onPageChange,
		setHide,
		selectedRowKeys,
		onSelectChange,
		onSearch,
		handleReset,
		deleteCompany,
		handleEdit
	} = useIndex();
	return (
		<div className={classnames(style["database-company"], "database-common")}>
			<div className="database-toolbar company-toolbar">
				<FleetVerifyInput
					value={undefined}
					onChange={onSearch}
					label="Filter"
					placeholder="Company Name"
					containerClassname="toolbar-filter"
				/>
				<div className="toolbar-actions">
					<CommonRoleWrap roleStr="company:list:batchDelete">
						<Button
							size="small"
							disabled={selectedRowKeys?.length === 0}
							onClick={() => deleteCompany("all")}
							prefixIcon="fleet-delete"
						>
							Delete
						</Button>
					</CommonRoleWrap>
					<CommonRoleWrap roleStr="company:list:add">
						<Button size="small" prefixIcon="fleet-add" onClick={() => handleEdit("add", null)}>
							Add Company
						</Button>
					</CommonRoleWrap>
				</div>
			</div>
			<FleetTable
				columns={[...columns, ...opereteColumns]}
				className="database-table"
				dataSource={dataSource}
				loading={loading}
				id="id"
				pagination={{
					current: pager.pageNum,
					pageSize: pager.pageSize,
					total: pager.total,
					onChange: onPageChange,
					showSizeChanger: false
				}}
				rowSelection={{
					selectedRowKeys,
					onChange: onSelectChange
				}}
			/>
			<CommonNewlyCompany
				id={record?.current?.id}
				hide={hide}
				onClose={() => setHide(true)}
				onConfirm={handleReset}
				type={editType}
			/>
		</div>
	);
};

export default DatabaseCompany;
