import { useAppDispatch } from "@/hook";
import useReminder from "@/hook/useReminder";
import { useEffect, useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";
import { RouteAreaItemType, RouteAreaPresetProps, RouteAreaSelectListProps } from "../componnets";
import debounce from "lodash/debounce";
import { RouteAreaListProps } from "../type";
import { onRequest } from "@/action";
import { useRouteCommonContext } from "@/pages/routeCommon/store";

const useIndex = (
	activeTab: RouteAreaListProps["activeTab"],
	onReset: RouteAreaListProps["onReset"]
) => {
	const dispatch = useAppDispatch();
	const navigate = useNavigate();
	const { reminder } = useReminder();
	const { setActiveAreaItems, activeDetailsOption } = useRouteCommonContext();
	const [loading, setLoading] = useState(false);
	const [dataSource, setDataSource] = useState<RouteAreaPresetProps["items"]>([]);
	const [selectRows, setSelectRows] = useState<RouteAreaSelectListProps["items"]>([]);

	const handleSelectRowsChange = (selectItem: RouteAreaItemType) => {
		setSelectRows((prev) => {
			const index = prev?.findIndex((item) => selectItem?.areaCode === item?.areaCode);
			if (index === -1) {
				prev?.push(selectItem);
			} else {
				prev?.splice(index, 1);
			}
			setActiveAreaItems(prev);
			if (activeDetailsOption.activeTab === "areaVessel") {
				onReset?.(prev);
			}
			return [...prev];
		});
	};

	// const selectRows = useMemo(() => {
	// 	const selectList = dataSource?.reduce((prev, curr) => {
	// 		const filters = curr?.dataList?.filter((item) => !!item?.isCurrentSelect);
	// 		return [...prev, ...filters];
	// 	}, []);
	// 	setActiveAreaItems(selectList);
	// 	if (activeDetailsOption.activeTab === "areaVessel") {
	// 		onReset?.(selectList);
	// 	}
	// 	return selectList;
	// }, [dataSource]);

	const handleGroupItemChange = (groupName: string, areaItem: RouteAreaItemType) => {
		setDataSource((prev) => {
			const currentIndex = prev?.findIndex((groupItem) => groupItem?.groupName === groupName);
			const groupItem = prev?.[currentIndex];
			const areaIndex = groupItem?.dataList?.findIndex(
				(selectItem) => selectItem?.areaCode === areaItem?.areaCode
			);
			groupItem.dataList.splice(areaIndex, 1, {
				...areaItem
			});
			prev.splice(currentIndex, 1, {
				...groupItem,
				dataList: [...groupItem.dataList]
			});
			return [...prev];
		});
	};

	const handleChange = (
		type: "single" | "multiple",
		groupName: string,
		items: RouteAreaPresetProps["items"][number]["dataList"]
	) => {
		if (type === "single") {
			handleGroupItemChange(groupName, items?.[0]);
			handleSelectRowsChange(items?.[0]);
			return;
		}
		setDataSource((prev) => {
			const currentIndex = prev?.findIndex((groupItem) => groupItem?.groupName === groupName);
			const currentItem = prev?.[currentIndex];
			prev.splice(currentIndex, 1, {
				...currentItem,
				dataList: [...items]
			});
			return [...prev];
		});
		getAreaSelectList();
	};

	const getAreaListFront = () => {
		setLoading(true);
	};

	const getAreaListSuccess = ({ data }) => {
		setLoading(false);
		setDataSource(data ?? []);
	};

	console.log("datasource", dataSource);

	const getAreaListFailed = (error) => {
		setLoading(false);
		reminder("error", error?.msg ? error?.msg + ": " + error?.data : error?.data);
	};

	const getAreaSelectListSuccess = (response) => {
		setLoading(false);
		setSelectRows(response?.data ?? []);
		setActiveAreaItems(response?.data ?? []);
		if (activeDetailsOption.activeTab === "areaVessel") {
			onReset?.(response?.data ?? []);
		}
	};

	const getAreaList = (val?: string) => {
		onRequest(
			"getRouteCommonAreaGroupApi",
			{ keyword: val },
			getAreaListFront,
			getAreaListSuccess,
			getAreaListFailed,
			dispatch,
			navigate
		);
	};

	const getAreaSelectList = () => {
		onRequest(
			"getRouteCommonAreaSelectRowsApi",
			null,
			getAreaListFront,
			getAreaSelectListSuccess,
			getAreaListFailed,
			dispatch,
			navigate
		);
	};

	const updateAreaSelectList = (
		type: "single" | "multiple",
		groupName: string,
		items: RouteAreaPresetProps["items"][number]["dataList"]
	) => {
		onRequest(
			"updateRouteCommonAreaGroupApi",
			items,
			getAreaListFront,
			(response) => {
				handleChange(type, groupName, items);
				setLoading(false);
			},
			getAreaListFailed,
			dispatch,
			navigate
		);
	};

	const handleSearch = useMemo(() => {
		const loadOptions = (val: string) => {
			getAreaList(val);
		};
		return debounce(loadOptions, 800);
	}, []);

	const handleDeleteSuccess = (response) => {
		const groupNames: string[] = [...new Set(selectRows?.map((item) => item?.groupName))];
		setDataSource((prev) => {
			for (let groupName of groupNames) {
				const currentIndex = prev?.findIndex((selectItem) => selectItem?.groupName === groupName);
				const currentItem = prev?.[currentIndex];
				prev?.splice(currentIndex, 1, {
					...currentItem,
					dataList: currentItem?.dataList?.map((item) => ({ ...item, isCurrentSelect: 0 }))
				});
			}
			return [...prev];
		});
		setSelectRows([]);
		setActiveAreaItems([]);
		if (activeDetailsOption.activeTab === "areaVessel") {
			onReset?.([]);
		}
		setLoading(false);
	};

	const handleDelete: RouteAreaSelectListProps["onDelele"] = (type, selectItem) => {
		if (type === "single") {
			handlePresetSelect(type, selectItem?.groupName, selectItem);
			return;
		}
		if (selectRows?.length <= 0) return;
		onRequest(
			"updateRouteCommonAreaGroupApi",
			selectRows?.map((item) => ({ ...item, isCurrentSelect: 0 })),
			getAreaListFront,
			handleDeleteSuccess,
			getAreaListFailed,
			dispatch,
			navigate
		);
	};

	const handlePresetSelect: RouteAreaPresetProps["onSelect"] = (type, groupName, item) => {
		debugger;
		if (type === "single") {
			updateAreaSelectList(type, groupName, [item]);
			return;
		}

		const currentGroup = dataSource?.find((groupItem) => groupItem?.groupName === groupName),
			currentSelected = currentGroup?.dataList?.every(
				(selectItem) => selectItem?.isCurrentSelect === 1
			);
		const selectItems: RouteAreaItemType[] = currentGroup?.dataList?.map((selectItem) => ({
			...selectItem,
			isCurrentSelect: currentSelected ? 0 : 1
		}));
		updateAreaSelectList(type, groupName, selectItems);
	};

	useEffect(() => {
		if (activeTab !== "area") {
			return;
		}
		getAreaList();
		getAreaSelectList();
	}, [activeTab]);

	return {
		loading,
		selectRows,
		dataSource,
		handleSearch,
		handleDelete,
		handlePresetSelect
	};
};

export default useIndex;
