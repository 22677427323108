import { TableCellType, BunkerLogFormType } from "./type";
export type bunkerLogTableConfigType = {
  rowIndex: number;
  row: TableCellType[];
};
export const bunkerLogTableConfig: bunkerLogTableConfigType[] = [
  {
    rowIndex: 0,
    row: [
      { colSpan: 2, rowSpan: 2, label: "Date/Time", prop: "dateTime" },
      {
        colSpan: 4,
        rowSpan: 1,
        label: "Daily Bunker Consumption",
        prop: "daily",
      },
      { colSpan: 4, rowSpan: 1, label: "Bunker ROB	", prop: "rob" },
      { colSpan: 1, rowSpan: 2, label: "Action", prop: "action" },
    ],
  },
  {
    rowIndex: 1,
    row: [
      { colSpan: 1, rowSpan: 1, label: "HSFO", prop: "dailyBunkerHfo" },
      { colSpan: 1, rowSpan: 1, label: "LSFO", prop: "dailyBunkerLsfo" },
      { colSpan: 1, rowSpan: 1, label: "MDO", prop: "dailyBunkerMdo" },
      { colSpan: 1, rowSpan: 1, label: "MGO", prop: "dailyBunkerMgo" },
      { colSpan: 1, rowSpan: 1, label: "HSFO", prop: "bunkerRobHfo" },
      { colSpan: 1, rowSpan: 1, label: "LSFO", prop: "bunkerRobLsfo" },
      { colSpan: 1, rowSpan: 1, label: "MDO", prop: "bunkerRobMdo" },
      { colSpan: 1, rowSpan: 1, label: "MGO", prop: "bunkerRobMgo" },
    ],
  },
];

type BunkerLogKeysTuple = ({
  [K in keyof BunkerLogFormType]: K;
}[keyof BunkerLogFormType])[];

export const BunkerLogKeys: BunkerLogKeysTuple= [
  "dateTime",
  "dailyBunkerHfo",
  "dailyBunkerLsfo",
  "dailyBunkerMdo",
  "dailyBunkerMgo",
  "bunkerRobHfo",
  "bunkerRobLsfo",
  "bunkerRobMdo",
  "bunkerRobMgo",
  "action"
];
