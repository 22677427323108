import { useState } from "react";
import useBasicForm from "./useBasicForm"
import useCargoInfoForm from "./useCargoInfoForm";
import usePayTermForm from "./usePayTermForm";
import usePortRotation from "./usePortRotation";
import useReminder from "@/hook/useReminder";
import { editCargoBookParams } from "@/api/voyageManage/cargoBook";
import { useTranslation } from "react-i18next";

const useAddCargoBook = () => {
  const [loading, setLoading] = useState(false);
  const { reminder } = useReminder();
  const { t } = useTranslation()
  const { basicForm,
    change: handleBasicFormChange,
    commit: handleBasicFormCommit,
    clear: handleBasicFormClear,
    check: handleBasicFormCheck
  } = useBasicForm();

  const { payTermForm,
    change: handlePayTermFormChange,
    commit: handlePayTermFormCommit,
    clear: handlePayTermFormClear,
    check: handlePayTermFormCheck
  } = usePayTermForm();

  const { cargoInfoForm,
    change: handleCargoInfoFormhange,
    commit: handleCargoInfoFormCommit,
    clear: handleCargoInfoFormClear,
    check: handleCargoInfoFormCheck,
  } = useCargoInfoForm();

  const {
    portRotation,
    change: handlePortFormChange,
    commit: handlePortCommitForm,
    add: handlePortAdd,
    del: handlePortDel,
    clear: handlePortClear,
    init: handlePortInit,
    check: handlePortCheck,
    delIds
  } = usePortRotation();

  const clear = () => {
    handleBasicFormClear();
    handlePayTermFormClear();
    handleCargoInfoFormClear();
    handlePortClear();
  }

  const check = () => {
    const { checkKey: basicCheckKey, checked: basicChecked } = handleBasicFormCheck();
    if (!basicChecked) {
      reminder("error", `Field ${t(`voyageManagement.cargoBook.${basicCheckKey}`)} is required`);
      return false;
    }

    const { checkKey: payTermCheckKey, checked: payTermChecked } = handlePayTermFormCheck();
    if (!payTermChecked) {
      reminder("error", `Field ${t(`voyageManagement.cargoBook.${payTermCheckKey}`)} is required`);
      return false;
    }

    const { checkKey: cargoInfoCheckKey, checked: cargoInfoChecked } = handleCargoInfoFormCheck();
    if (!cargoInfoChecked) {
      reminder("error", `Field11 ${t(`voyageManagement.cargoBook.${cargoInfoCheckKey}`)} is required`);
      return false;
    }

    const { checkKey: portCheckKey, checked: portChecked } = handlePortCheck();
    if (!portChecked) {
      reminder("error", `Field ${t(`voyageManagement.cargoBook.${portCheckKey}`)} is required`);
      return false;
    }

    return true
  }

  const initCargoBook = (data:editCargoBookParams) => {
    console.log("fixtureNo",data?.fixtureNo)
    handleBasicFormCommit({
      fixtureNo: data?.fixtureNo ?? "",
      chartererName: data?.chartererName ?? "",
      chartererId: data?.chartererId ?? "",
      fixtureDate: data?.fixtureDate ?? "",
      layCanFrom: data?.layCanFrom ?? undefined,
      layCanTo: data?.layCanTo ?? undefined,
      id:data?.id
    });

    handlePayTermFormCommit({
      paymentRate:data?.paymentRate ?? "",
      paymentTerm:data?.paymentTerm ?? "",
      paymentStartDay:data?.paymentStartDay ?? ""
    });

    handleCargoInfoFormCommit({
      cargoName: data?.cargoName ?? "",
      cargoQuantity: data?.cargoQuantity ?? "",
      cargoUnit: data?.cargoUnit ?? "MT",
      freightRate: data?.freightRate ?? "",
      freightType: data?.freightType ?? "FR",
      commission: data?.commission ?? "",
      brokerage: data?.brokerage ?? "",
      brokerId: data?.brokerId ?? "",
      brokerName:data?.brokerName ??  ""
    });

    handlePortInit(data?.portRotationList ?? [])
  }

  return {
    loading,
    basicForm,
    payTermForm,
    cargoInfoForm,
    portRotation,
    setLoading,
    handleBasicFormChange,
    handlePayTermFormChange,
    handleCargoInfoFormhange,
    handlePortFormChange,
    handleBasicFormCommit,
    handlePayTermFormCommit,
    handleCargoInfoFormCommit,
    handlePortCommitForm,
    handlePortAdd,
    handlePortDel,
    delIds,
    handlePortInit,
    clear,
    check,
    initCargoBook
  }

}

export type useAddCargoBookReturnType = ReturnType<typeof useAddCargoBook>

export default useAddCargoBook