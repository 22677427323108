import { CommonInitItemEvent } from "@/types/event"
import { EstPortRotationItemType } from "./type"
import { FleetOptionType } from "@/types/advancedType";
import { ExtendsKeys } from "@/types";
import { PortPurposeType } from "@/types/common";

export const initialEstPortItem: CommonInitItemEvent<EstPortRotationItemType> = (item) => {
  return {
    id: item?.id ?? null,
    purpose: item?.purpose ?? "DC",
    portName: item?.portName ?? "",
    portCode: item?.portCode ?? "",
    distance: item?.distance ?? "",
    ecaDistance: item?.ecaDistance ?? "",
    seaDays: item?.seaDays ?? "",
    insideEcaDays: item?.insideEcaDays ?? "",
    cargoQuantity: item?.cargoQuantity ?? "",
    workDays: item?.workDays ?? "",
    idleDays: item?.idleDays ?? "",
    portMarginDay: item?.portMarginDay ?? "",
    portMarginUnit: item?.portMarginUnit ?? "%",
    totalDays: item?.totalDays ?? "",
    crane: item?.crane ?? 0,
    portExpense: item?.portExpense ?? "",
    eta: item?.eta ?? "",
    etd: item?.etd ?? "",
    seaFo: item?.seaFo ?? "",
    seaMdo: item?.seaMdo ?? "",
    conFo: item?.conFo ?? "",
    conDo: item?.conDo ?? "",
    loadingDischargeRate: item?.loadingDischargeRate ?? 0,
    cargoName: item?.cargoName ?? "",
    cargoUnit: item?.cargoUnit ?? "MT",
    resultId: item?.resultId ?? null
  }
}

export const checkKeys: (keyof EstPortRotationItemType)[] = [
  "portName",
  "purpose",
  "loadingDischargeRate",
  "portCode"
]

export const loadPurposeOptions: FleetOptionType<
  "Loading" | "Bunker" | "Transit",
  ExtendsKeys<PortPurposeType, "LD" | "LB" | "LT">
>[] = [
    { label: "Loading", value: "LD" },
    { label: "Bunker", value: "LB" },
    { label: "Transit", value: "LT" }
  ];

export const dischargePurposeOptions: FleetOptionType<
  "Discharing" | "Bunker" | "Transit",
  ExtendsKeys<PortPurposeType, "DC" | "DB" | "DT">
>[] = [
    { label: "Discharing", value: "DC" },
    { label: "Bunker", value: "DB" },
    { label: "Transit", value: "DT" }
  ];