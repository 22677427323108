import { useEffect, useState } from "react";
import {
	RouteCommonPortTabbarProps,
	RoutePortCurrentProps,
	RoutePortFilterProps,
	RoutePortOtherIntervalsProps
} from "../components";
import { RoutePortListProps } from "../type";
import useReminder from "@/hook/useReminder";
import { useAppDispatch } from "@/hook";
import { useNavigate } from "react-router-dom";
import { getPortVesselSummaryAction } from "@/action/monitoring/portList";
import dayjs from "dayjs";
import { useRouteCommonContext } from "@/pages/routeCommon/store";

const useIndex = (
	activeTab: RoutePortListProps["activeTab"],
	onPortVesselsReset: RoutePortListProps["onPortVesselsReset"]
) => {
	const { reminder } = useReminder();
	const dispatch = useAppDispatch();
	const navigate = useNavigate();

	const [loading, setLoading] = useState<boolean>(false);
	const [dataSource, setDataSource] = useState<RoutePortCurrentProps["items"]>([]);
	const { activePortItem, onPortItemCommit, activeDetailsOption } = useRouteCommonContext();
	const [expectRange, setExpectRange] = useState<RoutePortOtherIntervalsProps["item"]>({
		startDate: undefined,
		endDate: undefined
	});
	const [historyRange, setHistoryRange] = useState<RoutePortOtherIntervalsProps["item"]>({
		startDate: undefined,
		endDate: undefined
	});

	const handleTabChange: RouteCommonPortTabbarProps["onSelect"] = (value) => {
		if (activePortItem.type === value) return;
		onPortItemCommit({
			type: value,
			startDate:
				value === 1
					? expectRange?.startDate
					: value === 3
						? historyRange?.startDate
						: dayjs()?.format("YYYY-MM-DD"),
			endDate:
				value === 1
					? expectRange?.endDate
					: value === 3
						? historyRange?.endDate
						: dayjs()?.format("YYYY-MM-DD")
		});
	};

	const handlePortCommit: RoutePortFilterProps["onCommit"] = (item) => {
		onPortItemCommit(item);
	};

	const handleRangeCommit: RoutePortOtherIntervalsProps["onCommit"] = (item) => {
		onPortItemCommit(item);
		if (activePortItem.type === 1) {
			setExpectRange(item);
			return;
		}
		setHistoryRange(item);
	};

	const getPortVesselSummaryFront = () => {
		setLoading(true);
	};

	const getPortVesselSummarySuccess = (response) => {
		setDataSource(response?.data ?? []);
		setLoading(false);
		if (activeDetailsOption.activeTab === "portVessel") {
			onPortVesselsReset?.(true);
		}
	};

	const getPortVesselSummaryFailed = (error) => {
		setLoading(false);
		reminder("error", error?.msg ? error?.msg + ": " + error?.data : error?.data);
	};

	const getPortVesselSummary = () => {
		if (!activePortItem?.portCode) {
			if (dataSource?.length > 0) {
				setDataSource([]);
			}
			if (activeDetailsOption.activeTab === "portVessel") {
				onPortVesselsReset?.(false);
			}
			return;
		}
		const params: RoutePortOtherIntervalsProps["item"] = {
			startDate:
				activePortItem.type === 1
					? expectRange?.startDate
					: activePortItem.type === 3
						? historyRange?.startDate
						: dayjs()?.format("YYYY-MM-DD"),
			endDate:
				activePortItem.type === 1
					? expectRange?.endDate
					: activePortItem.type === 3
						? historyRange?.endDate
						: dayjs()?.format("YYYY-MM-DD")
		};
		getPortVesselSummaryAction(
			{
				...params,
				type: activePortItem.type,
				portCode: activePortItem?.portCode
			},
			getPortVesselSummaryFront,
			getPortVesselSummarySuccess,
			getPortVesselSummaryFailed,
			dispatch,
			navigate
		);
	};

	useEffect(() => {
		if (activeTab !== "port") return;
		getPortVesselSummary();
	}, [activeTab, expectRange, historyRange, activePortItem.portCode, activePortItem.type]);

	return {
		dataSource,
		loading,
		expectRange,
		historyRange,
		activePortItem,
		handleTabChange,
		handlePortCommit,
		handleRangeCommit
	};
};

export default useIndex;
