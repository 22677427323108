import React, { memo, useEffect } from "react";
import classnames from "classnames";
import ContactSelectStyle from "../ContactSelect/index.module.less";
import style from "./index.module.less";
import { FleetIcon, FleetLoading, FleetSelect } from "@/components";
import useSelect from "@/hook/common/useAccountSelect";
import { AccountItemProps, AccountItemType } from "./type";

const AccountItem: React.FC<AccountItemProps> = memo(({ item, onItemSelect }) => {
	const handleSelect = (item: AccountItemType) => {
		onItemSelect?.(item);
	};
	return (
		<div
			className="contactPopup-item"
			onClick={(e) => {
				e.stopPropagation();
				handleSelect(item);
			}}
		>
			<span>{item?.beneficiaryName}</span>
			<span>{item?.bankAccountNumber || "-"}</span>
			<span>{item?.bankAccountAddress || "-"}</span>
		</div>
	);
});

const AccountPopup: React.FC<{
	list?: AccountItemType[];
	loading?: boolean;
	loadMore?: () => void;
	onItemSelect?: (item: AccountItemType) => void;
}> = ({ list, loading, loadMore, onItemSelect }) => {
	return (
		<div
			className={classnames(ContactSelectStyle["fleet-contactPopup"], style["fleet-accountPopup"])}
		>
			<FleetLoading loading={loading} />
			<div className="contactPopup-header">
				<span>Beneficiary Name</span>
				<span>Bank Account</span>
				<span>BankAccount Address</span>
			</div>
			<div className="contactPopup-container fleet-nonScrolBar">
				{list?.map((item: AccountItemType, idx: number) => {
					return <AccountItem key={idx} item={item} onItemSelect={onItemSelect} />;
				})}
			</div>
			<div className="popup-loadMore">
				<span onClick={loadMore}>Load More</span>
				<FleetIcon type="FleetLoadMoreIcon" outerLayerClassname="loadMore-image" />
			</div>
		</div>
	);
};

const AccountSelect: React.FC<{
	onFinish?: (item: AccountItemType, newItem: AccountItemType, type: "add" | "del") => void;
	mode?: "multiple";
	label?: keyof AccountItemType;
	showCount?: number;
	accountItems?: Partial<AccountItemType> | Partial<AccountItemType>[];
}> = ({ onFinish, showCount, label, mode, accountItems }) => {
	const {
		loading,
		list,
		open,
		search,
		loadMore,
		closePopup,
		focusPopup,
		tagRender,
		itemSelect,
		selectValue
	} = useSelect(mode ? true : false, onFinish, label, accountItems);

	return (
		<FleetSelect
			value={selectValue}
			showSearch
			showCount={showCount}
			popupMatchSelectWidth={false}
			onDropdownVisibleChange={closePopup}
			mode={mode}
			open={open}
			onSearch={search}
			onFocus={() => focusPopup()}
			tagRender={tagRender}
			dropdownRender={() => (
				<AccountPopup
					// loadMore={loadMore}
					list={list}
					loading={loading}
					onItemSelect={itemSelect}
				/>
			)}
		/>
	);
};

export default AccountSelect;
