import { AreaListItemType, AreaListTableProps } from "./type";
import dayjs from "dayjs";
import React from "react";
import { dateToUtcString } from "@/tools/date";
import { formatThousandthNumber } from "@/tools/amount";
import { FleetCustomRadio } from "@/components/Radio";
import { MovementCommonTableColumnsType } from "@/pages/voyagemanage/business/movement/details/components/charterBase/common/table/type";
import {
	ArrivalEcoEngineMap,
	ArrivalSoxScrubberStatusMap,
	ArrivalStatusMap,
	ArrivalVesselSubTypeMap,
	ArrivalVesselTypeMap
} from "@/common/Select";

export enum AisStatusMap {
	Running = 1,
	Mooring,
	Disable,
	"Draft-Limits",
	"At Berth",
	"Stranded",
	"Unknown" = 15
}

export enum EcoEngineMap {
	"Non-ECO",
	"ECO"
}

export enum SoxScrubberStatusMap {
	"NO",
	"YES"
}
export const columns: MovementCommonTableColumnsType<
	any,
	keyof AreaListItemType,
	{
		onClick: AreaListTableProps["onClick"];
		onSelect: AreaListTableProps["onSelect"];
	}
>[] = [
	{
		width: 40,
		label: "",
		dataIndex: "aisLat",
		fixed: "left",
		render: (value, record, index, actions, otherSource) => {
			return (
				<FleetCustomRadio
					value={otherSource?.selectedRows?.some((ele) => ele.imo == record?.imo)}
					onChange={(val) => {
						actions?.onSelect(record);
					}}
				/>
			);
		}
	},
	{
		label: "Vessel Name",
		dataIndex: "vesselName",
		width: 137,
		fixed: "left"
	},
	{
		label: "IMO",
		dataIndex: "imo",
		width: 137,
		fixed: "left"
	},
	// {
	// 	label: "VesselType",
	// 	dataIndex: "vesselType",
	// 	width: 137,
	// 	render: (value) => {
	// 		return <span>{ArrivalVesselTypeMap[value]}</span>;
	// 	}
	// },
	{
		label: "Vessel Sub Type",
		dataIndex: "vesselTypeSub",
		width: 137,
		render: (value) => {
			return <span>{ArrivalVesselSubTypeMap[value]}</span>;
		}
	},

	{
		label: "DWT",
		dataIndex: "dwt",
		width: 137,
		render: (value) => {
			return <span>{formatThousandthNumber(Number(value))}</span>;
		}
	},
	{
		label: "Gross Tonnage",
		dataIndex: "grossTonnage",
		width: 137
	},
	{
		label: "Build Year",
		dataIndex: "build",
		width: 137
	},
	{
		label: "Estimated Des. Port",
		dataIndex: "desPortName",
		width: 137
	},
	{
		label: "ETA",
		dataIndex: "eta",
		width: 137,
		render(value, record, index) {
			return <span>{value ? dayjs(value)?.format("YYYY-MM-DD hh:mm") : "-"}</span>;
		}
	},
	{
		label: "AIS Speed",
		dataIndex: "aisSpeed",
		width: 137
	},
	{
		label: "Ais Status",
		dataIndex: "aisStatus",
		width: 137,
		render: (value) => {
			return <span>{AisStatusMap[value]}</span>;
		}
	},
	{
		label: "LOA",
		dataIndex: "loa",
		width: 137
	},
	{
		label: "BM",
		dataIndex: "bm",
		width: 137
	},
	{
		label: "Draft",
		dataIndex: "draft",
		width: 137
	},
	{
		label: "Status",
		dataIndex: "status",
		width: 137,
		render: (value) => {
			return <span>{ArrivalStatusMap[value]}</span>;
		}
	},

	{
		label: "Eco Engine",
		dataIndex: "ecoEngine",
		width: 137,
		render: (value) => {
			return <span>{EcoEngineMap[value]}</span>;
		}
	},
	{
		label: "Sox Scrubber Status",
		dataIndex: "soxScrubberStatus",
		width: 137,
		render: (value) => {
			return <span>{SoxScrubberStatusMap[value]}</span>;
		}
	},
	{
		label: "Ais Update",
		dataIndex: "aisUpdate",
		width: 137,
		render(value, record, index) {
			return <span>{dayjs(value)?.format("YYYY-MM-DD hh:mm")}</span>;
		}
	},
	{
		label: "Current Area Name",
		dataIndex: "areaName",
		width: 130
	},
	{
		label: "Current Port Name",
		dataIndex: "portName",
		width: 130
	},
	{
		label: "Purpose",
		dataIndex: "purpose",
		width: 130
	},
	{
		label: "Start Area Code",
		dataIndex: "startAreaCode",
		width: 130
	},
	{
		label: "Start Area Name",
		dataIndex: "startAreaName",
		width: 130
	},
	{
		label: "Last Port Name",
		dataIndex: "lastPortName",
		width: 130
	},
	{
		label: "Ship Status",
		dataIndex: "shipStatus",
		width: 130
	},

	{
		label: "Actions",
		dataIndex: "action",
		width: 159,
		fixed: "right",
		render: (value, record, index, actions) => {
			return (
				<div className="areaListTable-actions">
					<span
						className="areaListTable-button"
						onClick={() => actions?.onClick?.(record, "detail")}
					>
						Detail
					</span>
					<span
						className="areaListTable-button"
						onClick={() => actions?.onClick?.(record, "portCall")}
					>
						PortCall
					</span>
				</div>
			);
		}
	}
];
