import React from "react";
import style from "./index.module.less";
import classnames from "classnames";
import { HfPerformanceFormProps, initialPerformanceForm } from "./type";
import { HeadFixtueLabelMap } from "@/types/voyageManage/headFixtureList";
import { FleetInput, FleetTextarea } from "@/components";

const HfPerformance: React.FC<HfPerformanceFormProps> = ({
  item,
  onPortChange,
  onWorkChange,
  onChange,
  onCommit
}) => {
  console.log('HfPerformance',item)
  return <div className={classnames("headFixture-form", style["headFixture-performance"])}>
    <span className="vm-common-title">Performance</span>
    <div className="performance">
      <div className="performance-common performance-header">
        <span className="performance-item">L/D</span>
        <span className="performance-item">{HeadFixtueLabelMap["speed"]}</span>
        <span className="performance-item">{HeadFixtueLabelMap["hsFo"]}</span>
        <span className="performance-item">{HeadFixtueLabelMap["lsFo"]}</span>
        <span className="performance-item">{HeadFixtueLabelMap["mdo"]}</span>
        <span className="performance-item">{HeadFixtueLabelMap["mgo"]}</span>
        <span className="performance-item">{HeadFixtueLabelMap["remarks"]}</span>
      </div>
      <div className="performance-container">
        {
          item?.ballast?.map((item, idx) => {
            return <div className="performance-common performance-content" key={idx}>
              <span className="performance-item">{HeadFixtueLabelMap["ballast"]}</span>
              <div className="performance-item">
                <FleetInput
                  placeholder="knot"
                  type="number"
                  value={item?.speed}
                  onChange={(val: number) => {
                    onPortChange?.("ballast", idx, "speed", val)
                  }}
                />
              </div>
              <div className="performance-item">
                <FleetInput
                  placeholder="MT/Day"
                  type="number"
                  value={item?.hsFo}
                  onChange={(val: number) => {
                    onPortChange?.("ballast", idx, "hsFo", val)
                  }}
                />
              </div>
              <div className="performance-item">
                <FleetInput
                  placeholder="MT/Day"
                  type="number"
                  value={item?.lsFo}
                  onChange={(val: number) => {
                    onPortChange?.("ballast", idx, "lsFo", val)
                  }}
                />
              </div>
              <div className="performance-item">
                <FleetInput
                  placeholder="MT/Day"
                  type="number"
                  value={item?.mdo}
                  onChange={(val: number) => {
                    onPortChange?.("ballast", idx, "mdo", val)
                  }}
                />
              </div>
              <div className="performance-item">
                <FleetInput
                  placeholder="MT/Day"
                  type="number"
                  value={item?.mgo}
                  onChange={(val: number) => {
                    onPortChange?.("ballast", idx, "mgo", val)
                  }}
                />
              </div>
              <div className="performance-item">
                <FleetInput
                  placeholder="About/(MT/Day)"
                  value={item?.remarks}
                  onChange={(val: string) => {
                    onPortChange?.("ballast", idx, "remarks", val)
                  }}
                />
              </div>
            </div>
          })
        }
        {
          item?.laden?.map((item, idx) => {
            return <div className="performance-common performance-content" key={idx}>
              <span className="performance-item">{HeadFixtueLabelMap["laden"]}</span>
              <div className="performance-item">
                <FleetInput
                  placeholder="knot"
                  type="number"
                  value={item?.speed}
                  onChange={(val: number) => {
                    onPortChange?.("laden", idx, "speed", val)
                  }}
                />
              </div>
              <div className="performance-item">
                <FleetInput
                  placeholder="MT/Day"
                  type="number"
                  value={item?.hsFo}
                  onChange={(val: number) => {
                    onPortChange?.("laden", idx, "hsFo", val)
                  }}
                />
              </div>
              <div className="performance-item">
                <FleetInput
                  placeholder="MT/Day"
                  type="number"
                  value={item?.lsFo}
                  onChange={(val: number) => {
                    onPortChange?.("laden", idx, "lsFo", val)
                  }}
                />
              </div>
              <div className="performance-item">
                <FleetInput
                  placeholder="MT/Day"
                  type="number"
                  value={item?.mdo}
                  onChange={(val: number) => {
                    onPortChange?.("laden", idx, "mdo", val)
                  }}
                />
              </div>
              <div className="performance-item">
                <FleetInput
                  placeholder="MT/Day"
                  type="number"
                  value={item?.mgo}
                  onChange={(val: number) => {
                    onPortChange?.("laden", idx, "mgo", val)
                  }}
                />
              </div>
              <div className="performance-item">
                <FleetInput
                  placeholder="About/(MT/Day)"
                  value={item?.remarks}
                  onChange={(val: string) => {
                    onPortChange?.("laden", idx, "remarks", val)
                  }}
                />
              </div>
            </div>
          })
        }
      </div>

    </div>
    <div className="performance">
      <div className="performance-common performance-header">
        <span className="performance-item">Port</span>
        <span className="performance-item"></span>
        <span className="performance-item"></span>
        <span className="performance-item"></span>
        <span className="performance-item"></span>
        <span className="performance-item"></span>
        <span className="performance-item"></span>
      </div>
      <div className="performance-container">
        <div className="performance-common performance-content">
          <span className="performance-item">{HeadFixtueLabelMap["work"]}</span>
          <div className="performance-item">
          </div>
          <div className="performance-item">
            <FleetInput
              placeholder="MT/Day"
              type="number"
              value={item?.work?.hsFo}
              onChange={(val: number) => {
                onWorkChange?.("work", "hsFo", val)
              }}
            />
          </div>
          <div className="performance-item">
            <FleetInput
              placeholder="MT/Day"
              type="number"
              value={item?.work?.lsFo}
              onChange={(val: number) => {
                onWorkChange?.("work", "lsFo", val)
              }}
            />
          </div>
          <div className="performance-item">
            <FleetInput
              placeholder="MT/Day"
              type="number"
              value={item?.work?.mdo}
              onChange={(val: number) => {
                onWorkChange?.("work", "mdo", val)
              }}
            />
          </div>
          <div className="performance-item">
            <FleetInput
              placeholder="MT/Day"
              type="number"
              value={item?.work?.mgo}
              onChange={(val: number) => {
                onWorkChange?.("work", "mgo", val)
              }}
            />
          </div>
          <div className="performance-item">
            <FleetInput
              placeholder="About/(MT/Day)"
              value={item?.work?.remarks}
              onChange={(val: string) => {
                onWorkChange?.("work", "remarks", val)
              }}
            />
          </div>
        </div>
        <div className="performance-common performance-content">
          <span className="performance-item">{HeadFixtueLabelMap["idle"]}</span>
          <div className="performance-item">
          </div>
          <div className="performance-item">
            <FleetInput
              placeholder="MT/Day"
              type="number"
              value={item?.idle?.hsFo}
              onChange={(val: number) => {
                onWorkChange?.("idle", "hsFo", val)
              }}
            />
          </div>
          <div className="performance-item">
            <FleetInput
              placeholder="MT/Day"
              type="number"
              value={item?.idle?.lsFo}
              onChange={(val: number) => {
                onWorkChange?.("idle", "lsFo", val)
              }}
            />
          </div>
          <div className="performance-item">
            <FleetInput
              placeholder="MT/Day"
              type="number"
              value={item?.idle?.mdo}
              onChange={(val: number) => {
                onWorkChange?.("idle", "mdo", val)
              }}
            />
          </div>
          <div className="performance-item">
            <FleetInput
              placeholder="MT/Day"
              type="number"
              value={item?.idle?.mgo}
              onChange={(val: number) => {
                onWorkChange?.("idle", "mgo", val)
              }}
            />
          </div>
          <div className="performance-item">
            <FleetInput
              placeholder="About/(MT/Day)"
              value={item?.idle?.remarks}
              onChange={(val: string) => {
                onWorkChange?.("idle", "remarks", val)
              }}
            />
          </div>
        </div>
      </div>
    </div>
    <FleetTextarea
      className="performance-textarea"
      value={item?.performanceClause}
      onChange={(val: string) => {
        onChange?.("performanceClause", val)
      }}
      onBlur={(e) => {
        let val = e.target.value;
        let performanceForm = initialPerformanceForm();

        let speedReg = /(LADEN|BALLAST):\s*(-?\d+(?:\.\d+)?)\s*KNOTS\s*(ON\s*)?(-?\d+(?:\.\d+)?)\s*MT\s*(-?\d+(?:\.\d+)?)\s*(VLSFO|VHSFO)\s*\+\s*(-?\d+(?:\.\d+)?)\s*MT\s*LS(MGO|MDO)/gi;
        let portReg = /(WORKING|IDLE):\s*(-?\d+(?:\.\d+)?)\s*MT\s*(-?\d+(?:\.\d+)?)\s*(VLSFO|VHSFO)\s*\+\s*(-?\d+(?:\.\d+)?)\s*MT\s*LS(MGO|MDO)/gi;
        let speedStrList = val.match(speedReg) ?? [];
        let portStrList = val.match(portReg) ?? [];
        let ballastIdx = 0;
        let ladenIdx = 0;
        let index = 0;
        for (let i = 0; i <= speedStrList?.length - 1; i++) {
          if(ballastIdx >=3 && ladenIdx >= 3) break
          const item = speedStrList?.[i];
          let title: any = item.match(/^(LADEN|BALLAST)/i);
          let speed = item.match(/(-?\d+(?:\.\d+)?)\s*KNOTS/i);
          let fo = item.match(/(-?\d+(?:\.\d+)?)\s*MT\s*(-?\d+(?:\.\d+)?)\s*(VLSFO|VHSFO)/i);
          let go = item.match(/(-?\d+(?:\.\d+)?)\s*MT\s*LS(MGO|MDO)/i);
          title = title[1]?.toLowerCase();
          // console.log('title',{
          //   i,
          //   title,
          //   speed,
          //   fo,
          //   go
          // })
          index = title === "ballast" ? ballastIdx : ladenIdx;
          switch (true) {
            case fo[fo?.length - 1]?.toLowerCase().includes("lsfo"):
              performanceForm[title][index].lsFo = (+fo?.[1] ?? "");
              break;
            case fo[fo?.length - 1]?.toLowerCase().includes("hsfo"):
              performanceForm[title][index].hsFo = (+fo?.[1] ?? "");
              break;
            default:
              break
          }

          switch (true) {
            case go[go?.length - 1]?.toLowerCase().includes("mgo"):
              performanceForm[title][index].mgo = (+go?.[1] ?? "");
              break;
            case go[go?.length - 1]?.toLowerCase().includes("mdo"):
              performanceForm[title][index].mdo = (+go?.[1] ?? "");
              break;
            default:
              break
          }
          performanceForm[title][index].speed = (+speed?.[1] ?? "");
          title === "ballast" ? (ballastIdx++) : (ladenIdx++)
        }
        for (let item of portStrList) {
          let title: any = item.match(/^(WORKING|IDLE)/i);
          let fo = item.match(/(-?\d+(?:\.\d+)?)\s*MT\s*(-?\d+(?:\.\d+)?)\s*(VLSFO|VHSFO)/i);
          let go = item.match(/(-?\d+(?:\.\d+)?)\s*MT\s*LS(MGO|MDO)/i);
          title = title[1]?.toLowerCase()?.slice(0,4);
          switch (true) {
            case fo[fo?.length - 1]?.toLowerCase().includes("lsfo"):
              performanceForm[title].lsFo = (+fo?.[1] ?? "");
              break;
            case fo[fo?.length - 1]?.toLowerCase().includes("hsfo"):
              performanceForm[title].hsFo = (+fo?.[1] ?? "");
              break;
            default:
              break
          }

          switch (true) {
            case go[go?.length - 1]?.toLowerCase().includes("mgo"):
              performanceForm[title].mgo = (+go?.[1] ?? "");
              break;
            case go[go?.length - 1]?.toLowerCase().includes("mdo"):
              performanceForm[title].mdo = (+go?.[1] ?? "");
              break;
            default:
              break
          }
        }

        performanceForm.performanceClause = e.target.value;
        console.log('performanceForm',performanceForm)
        // if(portStrList?.length ===0 && speedStrList?.length === 0)return;
        onCommit?.(performanceForm)

      }}
      placeholder=""
      // placeholder={`AT SEA ECO SPEED ----------------- LADEN: 11.50 KNOTS ON 20.50 MT 380 VLSFO + 0.2 MT LSMGO BALLAST: 12.00 KNOTS ON 20.50\nMT 380 VLSFO + 0.2 MT LSMGO\nAT PORT ------- WORKING: 4.30 MT 380 VLSFO + 0.5 MT LSMGO IDLE: 2.90 MT 380 VLSFO + 0.5 MT LSMGO`}
    />
  </div>
}

export default HfPerformance;

// /[LADEN|BALLAST]:+.*([\d.]+).*KNOTS+.*([\d.]+)+.*MT+.*(VLSFO|VHSFO)+.*\+(.*[\d.]+)+.*MT+.*LS(MGO|MDO)/gi;
