import { CommonInitItemEvent } from "@/types/event";
import { MovementTctcBunkerPlanType, MovementTctcBunkerPlanTabType } from "./type";

export enum BunkerPlanRentMap {
	reletBunkerPlan = "Relet",
	rentBunkerPlan = "Rent"
}

export const bunkerTabs: { key: MovementTctcBunkerPlanTabType; label: "Charter In" | "Relet" }[] = [
	{ key: "rentBunkerPlan", label: "Charter In" },
	{ key: "reletBunkerPlan", label: "Relet" }
];

export const initialTctcBunkerPlan: CommonInitItemEvent<MovementTctcBunkerPlanType> = (item) => {
	return {
		id: item?.id ?? null,
		scrubberBenefit: item?.scrubberBenefit ?? 0,
		scrubberPrice: item?.scrubberPrice ?? "",
		scrubberTotalPrice: item?.scrubberTotalPrice ?? 0,
		hsfoTotalPrice: item?.hsfoTotalPrice ?? 0,
		lsfoTotalPrice: item?.lsfoTotalPrice ?? 0,
		mdoTotalPrice: item?.mdoTotalPrice ?? 0,
		mgoTotalPrice: item?.mgoTotalPrice ?? 0,
		settledTime: item?.settledTime ?? null,
		status: item?.status ?? "Unsettled"
	};
};
