import React, { FC } from "react";
import style from "./index.module.less";
import { InvoicesViewsContainerProps } from "./type";
import { FleetFormItem, FleetIcon, FleetLoading } from "@/components";
import { Button, Popover } from "@/_components";
import { FleetPortTabs } from "@/components/Tabs";
import { CommonRoleWrap } from "@/common";
import classnames from "classnames";
import { HistoryDuplicate } from "@/pages/voyagemanage/components";
import useIndex from "./hooks/useIndex";

const InvoicesViewsContainer: FC<InvoicesViewsContainerProps> = ({
	loading,
	activeInvType,
	activeInvoice,
	activeTab,
	onDownloadPdf,
	onSave,
	onTabSelect,
	period,
	total,
	previewChildren,
	formChildren,
	activeVoyageItem
}) => {
	const { handleDownloadInv, hide, setHide, handleMovementBack } = useIndex(
		activeVoyageItem,
		activeInvoice,
		activeInvType
	);

	return (
		<div className={style["invoices-detail"]}>
			<FleetLoading loading={loading} />
			<div className="invoicesDetail-header">
				<div className="invoicesPeriod-container">
					<div className="invoicesPeriod-version">
						<span>#</span>
						<FleetFormItem
							type="row"
							label={`${period ?? 0}`}
							containerClassname="invoicesPeriod-input"
						></FleetFormItem>
						<span>ST</span>
					</div>
					<Button
						size="small"
						type="icon"
						className="toolbar-action"
						onClick={() => {
							setHide(false);
						}}
					>
						<FleetIcon type="InvoicesHistoryIcon" outerLayerClassname="invoicesDetail-icon" />
						{total}
					</Button>
				</div>
				<FleetPortTabs
					className="invoicesTemplate-container"
					value={activeTab}
					list={[
						{
							label: "Form",
							value: "form"
						},
						{
							label: "Preview",
							value: "preview"
						}
					]}
					onChange={onTabSelect}
				/>
				<div className="invoicesToolbar-container">
					{/* <FleetButton
      type="icon"
      size="small"
      className="toolbar-action"
    >
      <FleetIcon
        type="InvoicesDeleteIcon"
        outerLayerClassname="toolbar-icon"
      />
    </FleetButton> */}
					{activeTab === "form" ? (
						<>
							<Button type="secondary" size="small" onClick={handleMovementBack}>
								Movement
							</Button>
							<CommonRoleWrap roleStr="invoices:detail:save">
								<Button type="secondary" size="small" prefixIcon="fleet-save" onClick={onSave}>
									Save
								</Button>
							</CommonRoleWrap>
						</>
					) : (
						<Popover
							buttonProps={{
								prefixIcon: "fleet-download",
								size: "small",
								type: "secondary",
								children: "Export"
							}}
							render={(setIsOpen) => {
								return (
									<div className={style["operate-menu"]}>
										<div
											className="operateMenu-item"
											onClick={() => {
												onDownloadPdf?.();
												setIsOpen(false);
											}}
										>
											<FleetIcon type="FleetDownloadIcon" outerLayerClassname="operateMenu-icon" />
											<span>Pdf</span>
										</div>
										<div
											className="operateMenu-item"
											onClick={() => {
												handleDownloadInv();
												setIsOpen(false);
											}}
										>
											<FleetIcon type="FleetDownloadIcon" outerLayerClassname="operateMenu-icon" />
											<span>Excel</span>
										</div>
									</div>
								);
							}}
						></Popover>
					)}
				</div>
			</div>
			<div
				className={classnames("invoicesDetail-container", {
					preview: activeTab === "preview"
				})}
			>
				<div className="invoicesDetail-scrollArea fleet-nonScrolBar">{formChildren}</div>
				<div className="invoicesTemplate-scrollArea fleet-nonScrolBar">{previewChildren}</div>
			</div>
			<HistoryDuplicate
				hide={hide}
				invoicesType={activeInvType}
				onClose={() => setHide(true)}
				invId={activeInvoice?.id}
				period={period}
				mode="fixed"
			/>
		</div>
	);
};

export default InvoicesViewsContainer;
