import React from "react";
import { PageRouterProps } from "@/router";
import Estimation from "@/pages/estimation";
import VoyageManage from "@/pages/voyagemanage";
import Monitoring from "@/pages/monitoring";
import { IconKeys } from "@/components/Icon/icons";
import FleetDatabase from "@/pages/database";

import VoyageManageRouter from "@/pages/voyagemanage/router";
import EstimationRouter from "@/pages/estimation/router";
// import VoyageManageMovementRouter from "@/pages/voyagemanage/business/movement/router";
import DatabaseRouter from "@/pages/database/router";
import PerfReport from "@/pages/perfReport";
import PersonalCenter from "@/pages/personalCenter";
import MarketInsights from "@/pages/marketInsights";
import { BiInsights, RouteCommon } from "@/pages";

export interface LayoutChildrenProps extends PageRouterProps {
	hidden?: boolean;
	icon: Extract<
		IconKeys,
		| "LayoutHeaderMatching"
		| "LayoutHeaderEstinamtion"
		| "LayoutHeaderManage"
		| "LayoutHeaderMonitoring"
		| "LayoutHeaderInsights"
	>;
	selectedIcon: Extract<
		IconKeys,
		| "LayoutHeaderMatchingActive"
		| "LayoutHeaderEstinamtionActive"
		| "LayoutHeaderManageActive"
		| "LayoutHeaderMonitoringActive"
		| "LayoutHeaderInsightsActive"
	>;
}

export enum LayoutMap {
	"/layout/estimation" = "@/pages/estimation",
	"/layout/voyagemanage" = "@/pages/voyagemanage",
	"/layout/monitoring" = "@/pages/monitoring",
	"/layout/database" = "@/pages/database",
	"/layout/perfreport" = "@/pages/perfReport"
}

export const LayoutChildren: PageRouterProps[] = [
	// {
	// 	element: <MarketInsights />,
	// 	key: "/layout/marketinsights",
	// 	path: "marketinsights",
	// 	label: "Market Insights",
	// 	icon: "LayoutHeaderEstinamtion",
	// 	selectedIcon: "LayoutHeaderEstinamtionActive"
	// },
	{
		// index: true,
		key: "/layout/estimation",
		path: "estimation",
		label: "Estimation",
		element: <Estimation />,
		icon: "LayoutHeaderEstinamtion",
		selectedIcon: "LayoutHeaderEstinamtionActive",
		children: EstimationRouter
	},
	{
		key: "/layout/voyagemanage",
		path: "voyagemanage",
		label: "Voyage Management",
		element: <VoyageManage />,
		children: VoyageManageRouter,
		icon: "LayoutHeaderManage",
		selectedIcon: "LayoutHeaderManageActive"
		// disabled:true
	},
	// {
	// 	key: "/layout/monitoring",
	// 	path: "monitoring",
	// 	label: "Monitoring",
	// 	element: <Monitoring />,
	// 	icon: "LayoutHeaderMonitoring",
	// 	selectedIcon: "LayoutHeaderMonitoringActive"
	// 	// disabled:true
	// },
	{
		key: "/layout/biInsights",
		path: "biInsights",
		label: "BI Insights",
		element: <BiInsights />,
		icon: "LayoutHeaderInsights",
		selectedIcon: "LayoutHeaderInsightsActive"
	},
	{
		key: "/layout/database",
		path: "database",
		label: "Database",
		element: <FleetDatabase />,
		children: DatabaseRouter,
		icon: "LayoutHeaderInsights",
		selectedIcon: "LayoutHeaderInsightsActive"
	},
	{
		key: "/layout/perfreport",
		element: <PerfReport />,
		children: [],
		path: "perfreport",
		label: "Perf. Report"
	},
	{
		key: "/layout/monitoring",
		path: "monitoring",
		element: <RouteCommon />,
		label: "Route. Common"
	}
];

const LayoutRouter: PageRouterProps[] = [].concat(LayoutChildren);

export default LayoutRouter;
