import { FleetOptionType } from "@/types/advancedType";

export const profitOptions: (FleetOptionType<
  "Profit" |
  "TCE" |
  "Revenues" |
  "Freight Income" |
  "Freight Add Com." |
  "Brokerage" |
  "Total Cost" |
  "IFO Cost" |
  "MDO Cost" |
  "Port Expenses" |
  "Hire Cost" |
  "Add.Commssion" |
  "Other Expenses",
  "totalProfit" |
  "tce" |
  "totalRevenue" |
  "freightIncome" |
  "freightAddCommission" |
  "freightBrokerage" |
  "totalCost" |
  "ifoCost" |
  "mdoCost" |
  "portExpense" |
  "hireCost" |
  "commission" |
  "brokerage" |
  "otherExpense"
> & { highest?: boolean })[] = [
    { label: "Profit", value: "totalProfit", highest: true },
    { label: "TCE", value: "tce", highest: true },
    { label: "Revenues", value: "totalRevenue", highest: true },
    { label: "Freight Income", value: "freightIncome" },
    { label: "Freight Add Com.", value: "freightAddCommission" },
    { label: "Brokerage", value: "freightBrokerage" },
    { label: "Total Cost", value: "totalCost", highest: true },
    { label: "IFO Cost", value: "ifoCost" },
    { label: "MDO Cost", value: "mdoCost" },
    { label: "Port Expenses", value: "portExpense" },
    { label: "Hire Cost", value: "hireCost" },
    { label: "Add.Commssion", value: "commission" },
    { label: "Brokerage", value: "brokerage" },
    { label: "Other Expenses", value: "otherExpense" }
  ]