import { useCommonIndex } from "@/hook";
import { useAppDispatch } from "@/hook";
import useReminder from "@/hook/useReminder";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { checkKeys, initialContactItem } from "../source";
import { CommonNewlyContactProps } from "../type";
import { useTranslation } from "react-i18next";
import { addContactAction } from "@/action/dataBase/contact";

const useIndex = (
	hide: boolean,
	contanctParams: CommonNewlyContactProps["contanctParams"],
	setLoading: (loading: boolean) => void,
	onConfirm: CommonNewlyContactProps["onConfirm"],
	onClose: CommonNewlyContactProps["onClose"]
) => {
	const disptach = useAppDispatch();
	const navigate = useNavigate();
	const { reminder } = useReminder();
	const { t } = useTranslation();

	const { dataSource, commit, init, change, check } = useCommonIndex(initialContactItem, checkKeys);

	const [companyHide, setCompanyHide] = useState(true);

	const handleClose = (e) => {
		e.stopPropagation();
		setCompanyHide(true);
		onClose?.();
	};

	const handleCompanyClose = () => {
		setCompanyHide(true);
	};

	const handleConfirm = () => {
		const { checkKey, checked } = check();
		if (!checked) {
			reminder("error", `Field ${t(`dataBase.contract.${checkKey}`)} is required`);
			return;
		}
		onConfirm?.(dataSource);
	};

	useEffect(() => {
		if (!hide) {
			init(contanctParams);
		}
	}, [hide]);

	return {
		dataSource,
		change,
		commit,
		init,
		check,
		handleClose,
		handleConfirm,
		companyHide,
		setCompanyHide,
		handleCompanyClose
	};
};

export default useIndex;
