import { useState } from "react";
import { AreaItemType } from "../type";
import { AreaItemSelectedEvent } from "../components/preset/type";

const useAreaIndex = () => {
	const [dataSource, setDataSource] = useState<AreaItemType[]>([]);
	const handleSelect: AreaItemSelectedEvent = (selectItem) => {
		const currentIndex = dataSource?.findIndex((item) => item?.areaCode === selectItem?.areaCode);
		if (currentIndex === -1) {
			setDataSource((prev) => [...prev, selectItem]);
			return;
		}
		setDataSource((prev) => {
			const currentItem = prev?.[currentIndex];
			prev?.splice(currentIndex, 1, {
				...currentItem,
				isCurrentSelect: currentItem?.isCurrentSelect ? 0 : 1
			});
			return [...prev];
		});
	};

	const handleReset = (items?: AreaItemType[]) => {
		setDataSource(items ?? []);
	};

	const handleDelete: AreaItemSelectedEvent = (selectItem) => {
		setDataSource((prev) => {
			const currentIndex = prev?.findIndex((item) => item?.areaCode === selectItem?.areaCode);
			prev?.splice(currentIndex, 1);
			return [...prev];
		});
	};

	return {
		dataSource,
		handleSelect,
		handleReset,
		handleDelete
	};
};
export default useAreaIndex;
