import React, { useState } from "react";
import classnames from "classnames";
import style from "./index.module.less";
import { FleetIcon } from "@/components";
import { cn } from "@/tools";

export type FleetRadioProps = {
	label?: React.ReactNode;
	onChange?: (checked: boolean) => void;
	className?: string;
	defaultKey?: boolean;
	defaultChecked?: boolean;
	disabled?: boolean;
};

const FleetRadio: React.FC<FleetRadioProps> = ({
	label,
	onChange,
	className,
	defaultChecked,
	defaultKey
}) => {
	const [checked, setChecked] = useState(defaultChecked ?? false);
	const handleChange: React.MouseEventHandler<HTMLDivElement> = (e) => {
		e?.stopPropagation();
		setChecked(!checked);
		onChange?.(!checked);
	};
	return (
		<div className={classnames(style["fleet-radio"], className)} onClick={handleChange}>
			<FleetIcon
				type={checked ? "FleetCheckedIcon" : "FleetCheckedBgIcon"}
				outerLayerClassname="radio-icon"
			/>
			{label && <span className="radio-label">{label}</span>}
		</div>
	);
};

export const FleetCustomRadio: React.FC<
	FleetRadioProps & {
		value: boolean;
	}
> = ({ label, onChange, className, disabled, value }) => {
	const handleChange: React.MouseEventHandler<HTMLDivElement> = (e) => {
		e?.stopPropagation();
		if (disabled) return;
		onChange?.(!value);
	};
	return (
		<div className={classnames(style["fleet-radio"], className)} onClick={handleChange}>
			<FleetIcon
				type={value ? "FleetCheckedIcon" : "FleetCheckedBgIcon"}
				outerLayerClassname={cn("radio-icon", { "!cursor-not-allowed": disabled })}
			/>
			{label && (
				<span className={cn("radio-label", { "!cursor-not-allowed": disabled })}>{label}</span>
			)}
		</div>
	);
};

export default FleetRadio;
