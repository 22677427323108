import React, { FC, useEffect, useRef, useState } from "react";
import style from "./index.module.less";
import { Button, Popover } from "@/_components";
import { FleetFormItem, FleetInput, FleetSelect } from "@/components";
import { RouteCommonGroupAddProps } from "./type";
import useReminder from "@/hook/useReminder";
import { colorOptions } from "./source";
import classNames from "classnames";

const RouteCommonGroupAdd: FC<RouteCommonGroupAddProps> = ({
	groupItem,
	children,
	mode,
	onConfirm
}) => {
	const { reminder } = useReminder();
	const inputRef = useRef<HTMLInputElement>(null);
	const [currentColor, setCurrentColor] =
		useState<RouteCommonGroupAddProps["groupItem"]["color"]>(null);
	useEffect(() => {
		if (mode === "newly") {
			setCurrentColor("Purple");
			return;
		}
		setCurrentColor(groupItem?.color);
	}, [mode, groupItem]);
	console.log("currentColor", currentColor, groupItem?.color);
	return (
		<Popover
			trigger="click"
			placement="right"
			render={(setIsOpen) => {
				return (
					<div className={style["routeCommon-ais-groupAdd"]}>
						<span className="groupAdd-header">
							{mode === "edit" ? "Edit Group Name" : "Add Groups"}
						</span>
						<div className="groupAdd-container">
							<FleetFormItem needVerity label="Group Name：" type="row">
								<FleetInput
									defaultValue={groupItem?.groupName ?? ""}
									inputRef={inputRef}
									value={undefined}
								/>
							</FleetFormItem>
							<FleetFormItem label="Color: " type="row">
								<ul className="groupAdd-colors">
									{colorOptions?.map((colorItem) => {
										return (
											<li
												key={colorItem?.value}
												style={{ background: colorItem?.label }}
												className={classNames({
													active: currentColor === colorItem?.value
												})}
												onClick={() => {
													if (currentColor === colorItem?.value) return;
													setCurrentColor(colorItem?.value);
												}}
											></li>
										);
									})}
								</ul>
							</FleetFormItem>
						</div>
						<div className="groupAdd-footer">
							<Button
								size="minuscule"
								type="secondary"
								onClick={() => {
									setIsOpen(false);
									setCurrentColor(mode === "newly" ? "Purple" : groupItem?.color);
									inputRef.current.value = "";
								}}
							>
								Cancel
							</Button>
							<Button
								size="minuscule"
								onClick={() => {
									if (!inputRef?.current?.value) {
										reminder("warning", "Group name cannot be empty!");
										return;
									}
									onConfirm?.(mode, {
										groupId: groupItem?.groupId ?? null,
										groupName: inputRef?.current?.value,
										dataList: groupItem?.dataList ?? [],
										color: currentColor
									});
									inputRef.current.value = "";
									setIsOpen(false);
								}}
							>
								Confirm
							</Button>
						</div>
					</div>
				);
			}}
			children={children}
		/>
	);
};

export default RouteCommonGroupAdd;
