import React, { FC } from "react";
import style from "./index.module.less";
import { RouteLegListProps } from "./type";
import { RouteLegFilter, RouteLegItem } from "./components";
import classnames from "classnames";
import useIndex from "./hooks/useIndex";
import { CommonInfinityScroll } from "@/common";
import { FleetInput, FleetLoading } from "@/components";

const RouteLegList: FC<RouteLegListProps> = ({ onSelect, onVesselLoad, activeTab, activeItem }) => {
	const { hasMore, onSearch, loading, dataSource, selectRows, handleFilterConfirm, loadMoreList } =
		useIndex(activeTab, activeItem, onSelect, onVesselLoad);

	return (
		<div
			className={classnames("routeCommon-list-common", style["route-legList"], {
				[style["route-legList--active"]]: activeTab === "leg"
			})}
		>
			<FleetInput
				className="routeCommon-list-header"
				prefix="LayoutSearchIcon"
				onChange={onSearch}
				value={undefined}
				placeholder="Search Vessels"
			/>
			<div className={classnames("routeCommon-list-container legList-wrapper")}>
				<FleetLoading loading={loading} />
				<RouteLegFilter
					selectStatuItems={selectRows}
					onSearch={onSearch}
					onConfirm={handleFilterConfirm}
					total={dataSource?.length}
				/>
				<CommonInfinityScroll
					hasMore={hasMore}
					onLoadMore={loadMoreList}
					outerClassName="legList-container"
					scrollClassName="legList-scrollArea"
				>
					{dataSource?.map((item, index) => {
						return (
							<RouteLegItem key={index} item={item} activeItem={activeItem} onSelect={onSelect} />
						);
					})}
				</CommonInfinityScroll>
			</div>
		</div>
	);
};

export default RouteLegList;
