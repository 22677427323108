import React, { useEffect, useMemo } from "react";
import style from "./index.module.less";
import { MovementOtherReportsProps } from "./type";
import { FleetPrompt } from "@/common";
import useIndex from "./hooks/useIndex";
import { typeList } from "./source";
import {
  ArrivalReport,
  DepartureReport,
  ShiftingReport,
} from "./components/index";
import { Button } from "@/_components";
import classnames from "classnames";
const MovementOtherReports: React.FC<MovementOtherReportsProps> = ({
  hide,
  onClose,
  onConfirm,
  voyageId,
}) => {
  const {
    activeType,
    handleChangeType,
    handleSave,
    setActiveType,
    arrivalReportRef,
    departureReportRef,
    shiftingReportRef,
  } = useIndex();

  useEffect(() => {
    if (hide) {
      setActiveType("departureReport");
      return;
    }
  }, [hide]);
  return (
    <FleetPrompt hide={hide} mode="fixed">
      <div className={style["movement-otherReports"]}>
        <div className="prompt-header--container">
          <span className="header-title">Other Reports</span>
          <span
            className="font_family fleet-close header-close"
            onClick={onClose}
          ></span>
        </div>
        <div className="otherReports-main">
          <div className="otherReports-type">
            {typeList.map((item) => {
              return (
                <div
                  className={classnames("otherReports-typeItem", {
                    "active-typeItem": activeType === item.value,
                  })}
                  key={item.value}
                  onClick={() => handleChangeType(item)}
                >
                  {item.label}
                </div>
              );
            })}
          </div>
          <div className="otherReports-container">
            {activeType == "departureReport" ? (
              <DepartureReport
                ref={departureReportRef}
                type={activeType}
                voyageId={voyageId}
              />
            ) : activeType == "arrivalReport" ? (
              <ArrivalReport
                ref={arrivalReportRef}
                type={activeType}
                voyageId={voyageId}
              />
            ) : (
              <ShiftingReport
                ref={shiftingReportRef}
                type={activeType}
                voyageId={voyageId}
              />
            )}
          </div>
        </div>
        <div className="otherReports-footer">
          <Button type="secondary" onClick={onClose}>
            Cancel
          </Button>
          <Button onClick={handleSave}>Confirm</Button>
        </div>
      </div>
    </FleetPrompt>
  );
};

export default MovementOtherReports;
