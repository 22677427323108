import React, { useEffect, useMemo } from "react";
import style from "./index.module.less";
import { BasicInfoPropsType } from "./type";
import { FleetInput } from "@/components";
import useIndex from "./hook/useIndex";
import { basicInfoTableConfig } from "./source";
import { FleetTimePicker } from "@/components/DatePicker";
import dayjs from "dayjs";
const BasicInfo: React.FC<BasicInfoPropsType> = ({
  basicInfoData,
  onChange,
  currentPort,
  onCommit
}) => {
  const {
    handleMouseEnter,
    handleMouseLeave,
    basicInfoFormDisabled,
    cellValue,
    calculateTtlDays,
  } = useIndex(basicInfoData); 
  const newBasicFormConfig = useMemo(() => {
    const purpose = currentPort?.purpose == "LD" ? "LOAD" : "DISCHARGE";
    const config = JSON.parse(JSON.stringify(basicInfoTableConfig));
    config[5].row[4].label = `${purpose} COMMENCED`;
    config[6].row[0].label = `${purpose} COMPLETED`;
    return config;
  }, [currentPort]);
  return (
    <div className={style["portReport-basicInfo"]}>
      <div className="port-report-title">Basic Information</div>
      <div className="basic-info-table">
        <table cellSpacing={0}>
          <tbody>
            {newBasicFormConfig.map((item, i) => {
              return (
                <tr className="basic-info-table-tr" key={i}>
                  {item.row.map((ele, idx) => {
                    return (
                      <td
                        colSpan={ele.colSpan}
                        rowSpan={ele.rowSpan}
                        key={idx}
                        className={
                          ele.label
                            ? "basic-info-table-header"
                            : "basic-info-table-input"
                        }
                      >
                        {ele.label ? (
                          <div>{ele.label}</div>
                        ) : (
                          <div
                            onMouseEnter={() => handleMouseEnter(ele.prop)}
                            onMouseLeave={() => handleMouseLeave(ele.prop)}
                            className="basic-info-table-cell"
                          >
                            {!basicInfoFormDisabled[ele.prop] &&
                            ![
                              "dateTime",
                              "hoursSinceLastPort",
                              "workingHoursLastDay",
                              "vesselName",
                              "nameOfPort",
                              "ttlWorkDay",
                            ].includes(ele.prop) ? (
                              ele.prop == "nameOfBerth" ? (
                                <FleetInput
                                  value={basicInfoData.nameOfBerth}
                                  onChange={(val: string) => {
                                    onChange?.(val, "nameOfBerth");
                                  }}
                                ></FleetInput>
                              ) : (
                                <FleetTimePicker
                                  allowClear
                                  value={
                                    basicInfoData?.[ele.prop]
                                      ? dayjs(basicInfoData?.[ele.prop])
                                      : null
                                  }
                                  onChange={(val: string) => {
                                    if(['loadDishCommenced','loadDishCompleted'].includes(ele.prop)){
                                      const newTtl = calculateTtlDays(
                                        dayjs(val).valueOf(),
                                        ele.prop
                                      );
                                      onCommit?.({
                                        "ttlWorkDay":newTtl,
                                        [ele.prop]:dayjs(val).valueOf()
                                        }
                                      );
                                      return
                                    }  
                                    onChange?.(dayjs(val).valueOf(), ele.prop);
                                  }}
                                ></FleetTimePicker>
                              )
                            ) : ![
                                "hoursSinceLastPort",
                                "workingHoursLastDay",
                                "vesselName",
                                "nameOfPort",
                                "nameOfBerth",
                                "ttlWorkDay",
                              ].includes(ele.prop) &&
                              basicInfoData?.[ele.prop] ? (
                              // console.log(basicInfoData?.[ele.prop])
                              dayjs(basicInfoData?.[ele.prop]).format(
                                "YYYY-MM-DD HH:mm"
                              )
                            ) : (
                              cellValue(basicInfoData?.[ele.prop], ele.prop) ||
                              "--"
                            )}
                          </div>
                        )}
                      </td>
                    );
                  })}
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default BasicInfo;
