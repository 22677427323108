import React, { FC } from "react";
import style from "./index.module.less";
import { CommonVesselArrivalProps } from "./type";
import { FleetPrompt } from "@/common";
import useIndex from "./hooks/useIndex";
import { FleetLoading } from "@/components";
import { VesselArrivalTitleMap, columns } from "./source";
import VesselArrivalItem from "./components/item";

const CommonVesselArrival: FC<CommonVesselArrivalProps> = ({
  hide,
  imo,
  type,
  actions,
  onClose
}) => {
  const { loading, dataSource } = useIndex(imo, hide)
  return (
		<FleetPrompt hide={hide}>
			<div className={style["common-vesselArrival"]}>
				<FleetLoading loading={loading} />
				<div className="vesselArrival-header">
					<span className="header-title">Vessel Particular</span>
					<span className="font_family fleet-close" onClick={onClose}></span>
				</div>
				<div className="vesselArrival-container fleet-nonScrolBar">
					<div className="vesselArrival-scrollArea">
						{columns?.map((column, index) => {
							return (
								<VesselArrivalItem
									key={index}
									type={type}
									items={column}
									record={dataSource}
									title={VesselArrivalTitleMap[index]}
								/>
							);
						})}
					</div>
				</div>
			</div>
		</FleetPrompt>
	);
}

export default CommonVesselArrival