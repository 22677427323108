import React from "react";
import { Outlet } from "react-router-dom";
import VoyagemanageHeader from "./components/header"
import style from "./index.module.less";

const VoyageManage: React.FC<{}> = (props) => {
  return <div className={style["fleet-voyagemanage"]}>
    <VoyagemanageHeader />
    <Outlet />
  </div>
};

export default VoyageManage;