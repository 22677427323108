import { ParameterType } from "@/api";
import debounce from "lodash/debounce";
import React, { useEffect, useMemo, useRef, useState } from "react"
import { useAppDispatch } from "..";
import { useNavigate } from "react-router-dom";
import useReminder from "../useReminder";

const useCommonGroup = <
  ColumnItemsType,
  Filter extends Record<string, any>
>(
  getGroupAction: ParameterType<any>,
  delColumnAction: ParameterType<any>,
  filter: Filter = null
) => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { reminder } = useReminder();
  const cacheSource = useRef<{ [index: number]: ColumnItemsType[] }>({});
  const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([]);
  const record = useRef<ColumnItemsType>(null);
  const [search, setSearch] = useState("");
  const [loading, setLoading] = useState(false);
  const [pager, setPager] = useState<{
    total: number,
    pageNum: number,
    pageSize: number
  }>({ total: 0, pageNum: 0, pageSize: 20 });

  const dataSource = useMemo(() => {
    return cacheSource.current[pager.pageNum]
  }, [pager.pageNum, pager.total])

  console.log("dataSource", dataSource, cacheSource.current)

  const onSearch = useMemo(() => {
    const loadOptions = (val: string) => {
      cacheSource.current = {}
      setSearch(val)
      getGroup(val)
    }
    return debounce(loadOptions, 500)
  }, [])

  const getGroupFront = () => {
    setLoading(true);
  }

  const getGroupError = (error) => {
    reminder("error", error?.msg ? (error?.msg + ": " + error?.data) : error?.data);
    setLoading(false)
  }

  const getGroupSuccess = (response) => {
    let total = 0;
    switch (true) {
      case !Array.isArray(response?.data):
        cacheSource.current[1] = response?.data?.items
        total = response?.data?.totalCount;
        break;
      default:
        cacheSource.current = {};
        break;
    }
    setPager(prev => ({ ...prev, total, pageNum: 1 }))
    setLoading(false);
  }

  const delColumnResultSuccess = (response) => {
    reminder("success", response?.msg);
    setSelectedRowKeys([]);
    getGroup()
  }

  const onPageChange = (page: number, size: number) => {
    if (dataSource?.length >= pager.total) return;
    // if (page * size <= dataSource?.length) return;
    if (cacheSource.current[page]) {
      setPager(prev => ({ ...prev, pageNum: page }));
      return;
    }
    getGroupAction(
      {
        ...filter,
        pageNum: page,
        pageSize: pager.pageSize,
        keyword: search
      },
      getGroupFront,
      (response) => {
        if (!Array.isArray(response?.data)) {
          cacheSource.current[page] = response?.data?.items
        } else {
          cacheSource.current[page] = []
        }
        setPager(prev => ({ ...prev, pageNum: page }));
        setLoading(false)
      },
      getGroupError,
      dispatch,
      navigate
    )
  }

  const getGroup = (searchVal?: string) => {
    getGroupAction(
      {
        ...filter,
        pageNum: 1,
        pageSize: pager.pageSize,
        keyword: searchVal ?? search,
      },
      getGroupFront,
      getGroupSuccess,
      getGroupError,
      dispatch,
      navigate
    )
  }

  const delColumnMultiple = (type: "multiple" | "single",isLimit:boolean = false, id?: string) => {
    delColumnAction(
      { id: type === "multiple" ? selectedRowKeys.join(",") : id },
      getGroupFront,
      delColumnResultSuccess,
      getGroupError,
      dispatch,
      navigate
    )
  }

  return {
    record,
    dataSource,
    pager,
    loading,
    selectedRowKeys,
    onSearch,
    search,
    onPageChange,
    getGroup,
    delColumnMultiple,
    setSelectedRowKeys,
    getGroupFront,
    getGroupError,
    dispatch,
    navigate,
    reminder,
  }

}

export default useCommonGroup