export const loadImage = (mapboxgl: mapboxgl.Map, imgUrl: string, layerName: string) => {
	if (!mapboxgl) return;
	//     let anchorImage = new Image(20, 20);
	//     anchorImage.src = anchorSvgBase64
	//     anchorImage.onload = function () {
	//       map.current.addImage('anchor-icon', anchorImage)
	//     };
	mapboxgl.loadImage(imgUrl, (error, result) => {
		if (error) throw error;
		mapboxgl.addImage(layerName, result);
	});
};

export function calculateBearing(lat1, lon1, lat2, lon2) {
	const dLon = ((lon2 - lon1) * Math.PI) / 180;

	const y = Math.sin(dLon) * Math.cos((lat2 * Math.PI) / 180);
	const x =
		Math.cos((lat1 * Math.PI) / 180) * Math.sin((lat2 * Math.PI) / 180) -
		Math.sin((lat1 * Math.PI) / 180) * Math.cos((lat2 * Math.PI) / 180) * Math.cos(dLon);

	let angle = (Math.atan2(y, x) * 180) / Math.PI;
	if (angle < 0) {
		angle += 360;
	}

	return angle;
}

export const getTrackPoints = (coors) => {
	console.log("getTrackPoints", coors);
	const tracks = [];
	const pointCount = Math.min(Math.max(1, coors.length / 10), 10);
	const pointIndexSpan = Math.floor(coors.length / pointCount);
	for (let i = pointIndexSpan; i < coors.length - 2; i += pointIndexSpan) {
		const pointJson = {
			type: "Feature",
			geometry: {
				type: "Point",
				coordinates: coors[i]
			},
			properties: {
				type: "trackPoint",
				cog: calculateBearing(coors[i][1], coors[i][0], coors[i - 1][1], coors[i - 1][0])
			}
		};
		tracks.push(pointJson);
	}

	console.log("tracks", tracks);

	return tracks;
};
