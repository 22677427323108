import React, { FC, forwardRef, useEffect, useImperativeHandle } from "react";
import classnames from "classnames";
import style from "./index.module.less";
import { MonitoringPortListProps } from "./type";
import { FleetInput, FleetLoading } from "@/components";
import useIndex from "./hooks/useIndex";
import {
	PortListCurrent,
	PortListHistory,
	PortListTab,
	PortListExpert,
	PortSelect
} from "./components";
const MonitoringPortList: FC<MonitoringPortListProps> = ({
	activeTab,
	onOpenDetailTab,
	searchParams,
	onChangeSearchParams
}) => {
	const { vesselData, getPortVesselList, handleChangeTab, loading } = useIndex(
		onOpenDetailTab,
		onChangeSearchParams,
		searchParams
	);
	useEffect(() => {
		if (activeTab !== "port") return;
		getPortVesselList();
	}, [searchParams]);
	return (
		<div
			className={classnames(style["monitoring-portList"], {
				[style["monitoring-portList--active"]]: activeTab === "port"
			})}
		>
			<FleetLoading loading={loading} />
			<div className="portList-header">
				<span className="portList-header-label">Find a Port</span>
				<PortSelect
					activeItems={searchParams?.currentPort}
					showKey="portName"
					primaryKey="portCode"
					onClear={() => {
						onChangeSearchParams?.({
							currentPort: {
								portCode: null,
								portName: null
							}
						});
					}}
					onFinish={(newPortItem, operateType) => {
						onChangeSearchParams?.({
							currentPort: {
								portCode: newPortItem?.portCode ?? null,
								portName: newPortItem?.portName ?? null
							}
						});
					}}
				/>
			</div>
			<PortListTab activeName={searchParams?.termType} onChange={handleChangeTab} />
			{searchParams?.termType == 3 ? (
				<PortListHistory
					dateRange={searchParams?.dateRangeHistory}
					onCommit={(val) => {
						onChangeSearchParams?.({ dateRangeHistory: val });
					}}
				/>
			) : searchParams?.termType == 2 ? (
				<PortListCurrent dataSource={vesselData} />
			) : (
				<PortListExpert
					dateRange={searchParams?.dateRangeExpert}
					onCommit={(val) => {
						onChangeSearchParams?.({ dateRangeExpert: val });
					}}
				/>
			)}
		</div>
	);
};

export default MonitoringPortList;
