import React from "react";
import classnames from "classnames";
import style from "./index.module.less";
import { InvoicesItemProps } from "./type";
import { dateFormat } from "@/tools/date";

const InvoicesItem: React.FC<InvoicesItemProps> = ({
  item,
  currentIndex,
  activeIndex,
  onSelect
}) => {
  return <div
    onClick={() => onSelect?.(currentIndex, item)}
    className={classnames(style["invoices-item"], {
      [style["invoices-item-active"]]: currentIndex === activeIndex
    })}
  >
    <div className="invoicesItem-header" >
      <span className="invoicesItem-period">#&nbsp;{item?.period}&nbsp;ST</span>
      {item?.status === "Settled" && <span className="invoicesItem-tag">Settled</span>}
    </div>
    <ul className="invoicesItem-info">
      <li>INV. No.</li>
      <li>{item?.invNo ?? "-"}</li>
    </ul>
    <ul className="invoicesItem-info">
      <li>Create Date</li>
      <li>{dateFormat("YY-mm-dd", item?.createTime)}</li>
    </ul>
  </div>
}

export default InvoicesItem