import { FleetOptionType } from "@/types/advancedType";
import { HandoverFormTcvcOtherItemType, VoyageManageTcvcHandoverFormIdKeys } from "./type";
import { CommonInitItemEvent } from "@/types/event";

export const tcvcHandoverIdMap: FleetOptionType<
  "Basic Information" |
  "Head Fixture" |
  "Bunker & Vessel Performance" |
  "Fixture Notes" |
  "Port Details",
  VoyageManageTcvcHandoverFormIdKeys
>[] = [
    { label: "Basic Information", value: "handoverForm-basicInfo" },
    { label: "Head Fixture", value: "handoverForm-headFixture" },
    { label: "Bunker & Vessel Performance", value: "handoverForm-bunkerAndperformance" },
    { label: "Fixture Notes", value: "handoverForm-fixtureNotes" },
    { label: "Port Details", value: "handoverForm-portDetails" }
  ]

export const initialHandoverFormOtherItem: CommonInitItemEvent<HandoverFormTcvcOtherItemType> = (item) => {
  return {
    id: item?.id,
    dhd: item?.dhd ?? false,
    bunkeringPortCode: item?.bunkeringPortCode ?? "",
    bunkeringPortName: item?.bunkeringPortName ?? "",
    physicalSupplierId: item?.physicalSupplierId ?? null,
    physicalSupplierName: item?.physicalSupplierName ?? "",
    bunkerTimeBar: item?.bunkerTimeBar ?? "",
    orderPeriodStart: item?.orderPeriodStart ?? "",
    orderPeriodEnd: item?.orderPeriodEnd ?? "",
    bunkeringPeriodStart: item?.bunkeringPeriodStart ?? "",
    bunkeringPeriodEnd: item?.bunkeringPeriodEnd ?? "",
    bunkerClauseSpecialRemark: item?.bunkerClauseSpecialRemark ?? "",
    presentVesselMovement: item?.presentVesselMovement ?? "",
    presentPortAgentInfo: item?.presentPortAgentInfo ?? "",
    shipContactDetail: item?.shipContactDetail ?? "",
    voyageNo: item?.voyageNo ?? "",
    status: item?.status ?? ""
  }
}