import React, { FC } from "react";
import classnames from "classnames";
import style from "./index.module.less";
import { InvCommonOtherExpensesProps } from "./type";
import { FleetIcon, FleetVerifyInput } from "@/components";
import { Button } from "@/_components";
import { InvoiceAddTo } from "@/pages/voyagemanage/business/invoices/module";

const InvCommonOtherExpenses: FC<InvCommonOtherExpensesProps> = ({
  items,
  onFieldOpen,
  onChange,
  onDelete,
  otherItem,
  onOtherItemChange
}) => {
  return <div className={classnames("invoicesDetail-form", style["invoices-otherExpenses"])}>
    <div className="invoicesForm-header">
      <InvoiceAddTo
        type="addOther"
        value={otherItem?.addOther}
        onChange={(key, val) => {
          onOtherItemChange?.("addOther", val)
        }}
      ><span>Other Items</span></InvoiceAddTo>
      <span>Settlement other expenses</span>
    </div>
    <div className="invoicesForm-container">
      {
        items?.map((item, index) => {
          return <div className="otherExpenses-fields" key={index}>
            <FleetVerifyInput
              value={item?.fieldVal}
              label={item?.fieldName}
              type="number"
              onChange={(val: number) => {
                onChange?.(index, "fieldVal", val);
              }}
            />
            <FleetIcon
              type="FleetPortDelIcon"
              onClick={() => {
                onDelete?.(index);
              }}
            />
          </div>
        })
      }
      <Button
        prefixIcon="fleet-add"
        type="secondary"
        size="small"
        className="invoices-add"
        onClick={() => onFieldOpen?.("otherItems")}
      >
        Add field
      </Button>
    </div>
  </div>
}

export default InvCommonOtherExpenses