import { type ApiParameter, RequestApiMapType } from "@/api";
import request from "@/service";
import { appVersion } from "@/api/constant";
import {
	otherExpensesApiMap,
	EstOtherExpensesApiType,
	EstOtherExpensesParamsType
} from "./otherExpenses";

const enum ApiMap {
	moveToVmParameter
}

type ApiUrl = keyof typeof ApiMap;

const esApi: ApiParameter<ApiUrl> = {
	moveToVmParameter: {
		method: "put",
		url: appVersion + "/est/vc/move"
	}
};

export type moveToVmParams = {
	resultId: string | number;
};

export const moveToVmApi = (params: moveToVmParams) => {
	return request.request({
		...esApi.moveToVmParameter,
		url: `${esApi?.moveToVmParameter?.url}/${params?.resultId}`
	});
};

export { getVesselArchivesApi, getVesselArchivesParams } from "./aisData/index";

export type EstApiType = EstOtherExpensesApiType;
export type EstParamsType = EstOtherExpensesParamsType;

export const estApiMap: RequestApiMapType<EstApiType, EstParamsType> = {
	...otherExpensesApiMap
};
