import { FleetOptionType } from "@/types/advancedType";
import { PortPurposeType } from "@/types/common";

export const portPurposeOptions: FleetOptionType<
	| "delivery"
	| "redelivery"
	| "load"
	| "load bunker"
	| "load transit"
	| "discharge"
	| "discharge bunker"
	| "discharge transit",
	PortPurposeType
>[] = [
	{
		label: "delivery",
		value: "DL"
	},
	{
		label: "redelivery",
		value: "RD"
	},
	{
		label: "load",
		value: "LD"
	},
	{
		label: "load bunker",
		value: "LB"
	},
	{
		label: "load transit",
		value: "LT"
	},
	{
		label: "discharge",
		value: "DC"
	},
	{
		label: "discharge bunker",
		value: "DB"
	},
	{
		label: "discharge transit",
		value: "DT"
	}
];
