import React, { useState } from "react";
import classnames from "classnames";
import style from "./index.module.less";
import { FleetTextarea } from "@/components";
import { EstimationProfitType } from "@/types/estimation/details";
import { toThousands } from "@/tools";

const ProfitDetail: React.FC<{
  calculate?: EstimationProfitType
}> = ({
  calculate
}) => {
    return <>
      <div className="profit-item">
        <span className="item-left-0">Profit</span>
        <div className="item-right-0">
          <span>{toThousands(calculate?.totalProfit)}</span>
          <span>USD</span>
        </div>
      </div>
      <div className="profit-item">
        <span className="item-left-0">TCE</span>
        <div className="item-right-0">
          <span>{toThousands(calculate?.tce)}</span>
          <span>USD</span>
        </div>
      </div>
      <div className="profit-item">
        <span className="item-left-0">Revenues</span>
        <div className="item-right-0">
          <span>{toThousands(calculate?.totalRevenue)}</span>
          <span>USD</span>
        </div>
      </div>
      <div className="profit-item">
        <span className="item-left-1">Freight Income</span>
        <span className="item-right-1">{toThousands(calculate?.freightIncome)} USD</span>
      </div>
      <div className="profit-item">
        <span className="item-left-1">Freight Add Com.</span>
        <span className="item-right-1">{toThousands(calculate?.freightAddCommission)} USD</span>
      </div>
      <div className="profit-item">
        <span className="item-left-1">Brokerage</span>
        <span className="item-right-1">{toThousands(calculate?.freightBrokerage)} USD</span>
      </div>
      <div className="profit-item">
        <span className="item-left-0">Total Cost</span>
        <div className="item-right-0">
          <span>{toThousands(calculate?.totalCost)}</span>
          <span>USD</span>
        </div>
      </div>
      <div className="profit-item">
        <span className="item-left-1">Operation Cost</span>
        <span className="item-right-1">{toThousands(calculate?.operationCost)} USD</span>
      </div>
      <div className="profit-item">
        <span className="item-left-2">IFO Cost</span>
        <span className="item-right-1">{toThousands(calculate?.ifoCost)} USD</span>
      </div>
      <div className="profit-item">
        <span className="item-left-2">MDO Cost</span>
        <span className="item-right-1">{toThousands(calculate?.mdoCost)} USD</span>
      </div>
      <div className="profit-item">
        <span className="item-left-2">Port Expenses</span>
        <span className="item-right-1">{toThousands(calculate?.portExpense)} USD</span>
      </div>
      <div className="profit-item">
        <span className="item-left-2">Other Expenses</span>
        <span className="item-right-1">{toThousands(calculate?.otherExpense)} USD</span>
      </div>
      <div className="profit-item">
        <span className="item-left-1">Total Hire Cost</span>
        <span className="item-right-1">{toThousands(calculate?.totalHireCost)} USD</span>
      </div>
      <div className="profit-item">
        <span className="item-left-2">Hire Cost</span>
        <span className="item-right-1">{toThousands(calculate?.hireCost)} USD</span>
      </div>
      <div className="profit-item">
        <span className="item-left-2">Add.Commssion</span>
        <span className="item-right-1">{toThousands(calculate?.commission)} USD</span>
      </div>
      <div className="profit-item">
        <span className="item-left-2">Brokerage</span>
        <span className="item-right-1">{toThousands(calculate?.brokerage)} USD</span>
      </div>
    </>
  }

const EstimationProfit: React.FC<{
  className?: string;
  calculate?: EstimationProfitType;
  onChange?: (val: string) => void;
  remark?: string;
}> = ({
  className,
  onChange,
  remark,
  calculate
}) => {
    return <div className={classnames(style["estimation-profit"], className)}>
      <div className="profit">
        <div className="profit-header">
          <span className="details-title">Profit(Loss)</span>
        </div>
        <ProfitDetail calculate={calculate} />
      </div>
      <div className="remakes">
        <div className="remakes-header">
          <span className="details-title">Remarks</span>
        </div>
        <FleetTextarea
          value={remark}
          onChange={onChange}
          className="remakes-container"
        />
      </div>
    </div>
  }

export default EstimationProfit;