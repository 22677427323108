import React, { FC } from "react";
import style from "./index.module.less";
import { FleetPrompt, FleetToast } from "@/common";
import { EstListCompareProps } from "./type";
import { Button } from "@/_components";
import useIndex from "./hooks/useIndex";
import { EstCompareItem } from "./components";
import { FleetLoading, FleetTooltip } from "@/components";

const EstListCompare: FC<EstListCompareProps> = ({
  ids,
  hide,
  onClose,
  onConfirm
}) => {
  const {
    loading,
    dataSource,
    selectList,
    cache,
    handleSelect,
    saveEsCompare
  } = useIndex(ids, hide, onConfirm, onClose)
  return <FleetPrompt hide={hide}>
    <div className={style["estList-voyageCompare"]}>
      <FleetLoading loading={loading} />
      <div className="prompt-header--container">
        <span className="header-title">Voyage Comparison</span>
        <span className="font_family fleet-close header-close" onClick={onClose}></span>
      </div>
      <div className="estList-voyageCompare--container">
        {
          dataSource?.map((item, index) => {
            return <EstCompareItem
              key={index}
              item={item}
              cache={cache}
              isActive={selectList?.includes(item?.id)}
              onSelect={handleSelect}
            />
          })
        }
      </div>
      <div className="prompt-footer--container">
        <Button size="small" type="secondary" onClick={onClose}>Cancel</Button>
        <FleetTooltip
          outerLayer={<Button size="small">Save</Button>}
          placement="top"
          disabled={loading}
          render={(setIsOpen) => {
            return <FleetToast
              label="Unselected items will be deleted. Are you sure?"
              onCancel={() => { setIsOpen(false) }}
              onConfirm={() => {
                setIsOpen(false)
                saveEsCompare()
              }}
            />
          }}
        />
      </div>
    </div>
  </FleetPrompt>
}

export default EstListCompare