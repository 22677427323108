import { CurrentDirective } from "./source";

export const getCurrentDir = (val: number): CurrentDirective => {
	switch (true) {
		case val === 0 || val === 360:
			return 0;
		case val > 0 && val < 90:
			return 45;
		case val === 90:
			return 90;
		case val > 90 && val < 180:
			return 135;
		case val === 180:
			return 180;
		case val > 180 && val < 270:
			return 225;
		case val === 270:
			return 270;
		case val > 270 && val < 360:
			return 315;
	}
};

export const getWindLevel = (val: number) => {
	switch (true) {
		case val < 0.3:
			return 0;
		case val < 1.6:
			return 1;
		case val < 3.4:
			return 2;
		case val < 5.5:
			return 3;
		case val < 8.0:
			return 4;
		case val < 10.8:
			return 5;
		case val < 13.9:
			return 6;
		case val < 17.2:
			return 7;
		case val < 20.8:
			return 8;
		case val < 24.5:
			return 9;
		case val < 28.5:
			return 10;
		case val < 32.7:
			return 11;
		default:
			return 12;
	}
};
