import { FleetOptionType } from "@/types/advancedType";
import { RoutePortTabItemType } from "./type";

export const timeRangeOptions: FleetOptionType<
	"Current" | "History" | "Expect",
	RoutePortTabItemType
>[] = [
	{
		label: "History",
		value: 3
	},
	{
		label: "Current",
		value: 2
	},
	{
		label: "Expect",
		value: 1
	}
];
