import React, { FC } from "react";
import style from './index.module.less';
import { PrefReportVoyageFilterProps } from "./type";
import { FleetInput, FleetSelect, FleetTooltip } from "@/components";
import { filterOptions } from "./source";

const PrefReportVoyageFilter: FC<PrefReportVoyageFilterProps> = ({
  activeDownload,
  onSearch,
  onDownloadedSelect
}) => {
  return <div className={style["perfreport-voyageFilter"]}>
    <span className="voyageFilter-title">Perf. Report</span>
    <div className="voyageFilter-container">
      <FleetInput
        value={undefined}
        onChange={onSearch}
        className="voyageFilter-search"
      />
      <FleetTooltip
        buttonProps={{
          type: "icon",
          className: "voyageFilter-select",
          children: <span className="font_family fleet-filter"></span>
        }}
        render={(setIsOpen) => {
          return <FleetSelect
            options={filterOptions}
            value={[activeDownload]}
            onChange={(val: 0 | 1) => onDownloadedSelect?.(val, setIsOpen)}
          />
        }}
      />
    </div>
  </div>
}

export default PrefReportVoyageFilter