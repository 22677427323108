import { ApiParameter, RequestApiMapType } from "@/api";
import request from "@/service";

import {
	RouteCommonOtherParamsType,
	getRouteCommonAreaListParams,
	getRouteCommonCountryListParams,
	getRouteCommonPortListParams
} from "./type";
import { appVersion } from "@/api/constant";

const enum ApiMap {
	getRouteCommonAreaListParameter,
	getRouteCommonCountryListParameter,
	getRouteCommonPortListParameter
}

export type ApiUrl = keyof typeof ApiMap;

const commonApi: ApiParameter<ApiUrl> = {
	getRouteCommonAreaListParameter: {
		method: "get",
		url: appVersion + "/moni/area/info/list"
	},
	getRouteCommonCountryListParameter: {
		method: "get",
		url: appVersion + "/ibiz-country"
	},
	getRouteCommonPortListParameter: {
		method: "get",
		url: appVersion + "/ibiz-port"
	}
};

export const getRouteCommonAreaListApi = (params: getRouteCommonAreaListParams) => {
	return request.request({
		...commonApi.getRouteCommonAreaListParameter,
		params
	});
};

export const getRouteCommonCountryListApi = (params: getRouteCommonCountryListParams) => {
	return request.request({
		...commonApi.getRouteCommonCountryListParameter,
		params
	});
};
export const getRouteCommonPortListApi = (params: getRouteCommonPortListParams) => {
	return request.request({
		...commonApi.getRouteCommonPortListParameter,
		params
	});
};

export const commonApiMap: RequestApiMapType<ApiUrl, RouteCommonOtherParamsType> = {
	getRouteCommonAreaListApi,
	getRouteCommonCountryListApi,
	getRouteCommonPortListApi
};
