import React, { FC, forwardRef, useImperativeHandle, useRef } from "react";
import style from "./index.module.less";
import { RouteCommonListProps, RouteCommonListRefType } from "./type";
import useIndex from "./hooks/useIndex";
import {
	RouteAisList,
	RouteLegList,
	RoutePortList,
	RouteAreaList,
	RouteAisListRefType
} from "./components";
import { useRouteCommonContext } from "../../store";
import classnames from "classnames";

const RouteCommonList = forwardRef<RouteCommonListRefType, RouteCommonListProps>(
	(
		{
			activeTab,
			visibleRows,
			onTabSelect,
			onAisSelect,
			onLegSelect,
			onPortVesselReset,
			onAreaVesselReset,
			onVesselLoad
		},
		ref
	) => {
		const aisListRef = useRef<RouteAisListRefType>(null);
		const { voyageNo, handleJumpMovement } = useIndex();
		const { activeLegVessel } = useRouteCommonContext();

		useImperativeHandle(ref, () => {
			return {
				aisListRef: aisListRef
			};
		});

		return (
			<div
				className={classnames(style["routeCommon-list"], {
					[style["routeCommon-list--hidden"]]: !visibleRows?.includes(activeTab)
				})}
			>
				{/* <div className="routeCommon-list-header">
				<div className="routeCommon-list-label">
					<span>Voy. Management List</span>
					{voyageNo && (
						<Button type="icon" size="small" onClick={handleJumpMovement}>
							<span className="font_family fleet-back"></span>
						</Button>
					)}
				</div>
				<RouteListTabbar mode="monitoring" activeTab={activeTab} onSelect={onTabSelect} />
			</div> */}
				<RouteLegList
					activeTab={activeTab}
					activeItem={activeLegVessel}
					onSelect={onLegSelect}
					onVesselLoad={onVesselLoad}
				/>
				<RoutePortList activeTab={activeTab} onPortVesselsReset={onPortVesselReset} />
				<RouteAisList
					activeTab={activeTab}
					onSelect={onAisSelect}
					onVesselLoad={onVesselLoad}
					ref={aisListRef}
				/>
				<RouteAreaList activeTab={activeTab} onReset={onAreaVesselReset} />
			</div>
		);
	}
);

export default RouteCommonList;
