import { CommonInitItemEvent } from "@/types/event";
import { InvFreightBaseType } from "./type";

export const initialFreightBaseItem: CommonInitItemEvent<InvFreightBaseType> = (item) => {
  return {
    invNo: item?.invNo ?? "",
    invDate: item?.invDate ?? "",
    period: item?.period ?? null,
    cpDate: item?.cpDate ?? "",
    vesselName: item?.vesselName ?? "",
    beneficiaryName: item?.beneficiaryName ?? "",
    beneficiaryAddress: item?.beneficiaryAddress ?? "",
    beneficiaryAccount: item?.beneficiaryAccount ?? "",
    beneficiaryBank: item?.beneficiaryBank ?? "",
    bankAddress: item?.bankAddress ?? "",
    swiftCode: item?.swiftCode ?? "",
    ownerId: item?.ownerId ?? null,
    ownerName: item?.ownerId ? (item?.ownerName ?? "") : "",
    registerAddress: item?.registerAddress ?? ""
  }
}

export const checkKeys: (keyof InvFreightBaseType)[] = [
  "invNo",
  "invDate",
  "ownerId",
  "ownerName",
  "beneficiaryName",
  "beneficiaryAccount",
  "beneficiaryBank",
  "swiftCode",
  "beneficiaryBank"
]