import React from "react";
import classnames from "classnames";
import style from "./index.module.less";
import { DatabaseCommonAddProps } from "./type";
import { FleetButton, FleetIcon, FleetLoading } from "@/components";
import { CommonRoleWrap, FleetPrompt } from "@/common";
import { Button } from "@/_components";

const DatabaseCommonAdd: React.FC<DatabaseCommonAddProps> = ({
  title,
  onClose,
  loading,
  className,
  children,
  hide,
  onAdd,
  sectionName,
}) => {
  return (
    <FleetPrompt hide={hide}>
      <div className={classnames(style["database-commonAdd"], className)}>
        <FleetLoading loading={loading} />
        <div className="commonAdd-header">
          <span className="fleet-title">{title}</span>
          <FleetIcon
            type="FleetCloseIcon"
            outerLayerClassname="header-close"
            onClick={onClose}
          />
        </div>
        <div className="commonAdd-container fleet-nonScrolBar">{children}</div>
        <div className="commonAdd-footer">
          <Button size="small" type="secondary" onClick={onClose}>
            Cancel
          </Button>
          {sectionName ? (
            <CommonRoleWrap roleStr={`${sectionName}:list:edit`}>
              <Button size="small" onClick={onAdd}>
                Confirm
              </Button>
            </CommonRoleWrap>
          ) : (
            <Button size="small" onClick={onAdd}>
              Confirm
            </Button>
          )}
        </div>
      </div>
    </FleetPrompt>
  );
};

export default DatabaseCommonAdd;
