import React, { FC } from "react";
import style from "./index.module.less";
import { MovementPortExpensesProps } from "./type";
import { FleetPrompt } from "@/common";
import { FleetButton, FleetFormItem, FleetInput } from "@/components";
import MovementTable from "../charterBase/common/table";
import { columns } from "./source";
import useIndex from "./hooks/useIndex";
import { useTranslation } from "react-i18next";
import { ArrivalVesselSubTypeMap } from "@/pages/estimation/business/details/components/vesselArrival/source";
import { Button } from "@/_components";

const MovementPortExpenses: FC<MovementPortExpensesProps> = ({
	hide,
	onClose,
	voyageId,
	onConfirm
}) => {
	const { t } = useTranslation();
	const { loading, dataSource, summary, change, commit, saveMovementPortExpenses } = useIndex(
		hide,
		voyageId,
		onConfirm,
		onClose
	);
	return (
		<FleetPrompt hide={hide} mode="fixed" loading={loading}>
			<div className={style["movement-portExpenses"]}>
				<div className="prompt-header--container">
					<span className="header-title">{t("voyageManagement.movement.portExpenses")}</span>
					<span className="font_family fleet-close" onClick={onClose}></span>
				</div>
				<div className="portExpenses-container">
					<div className="portExpenses-vesselInfo">
						<FleetFormItem label={t("common.vesselName")} type="row">
							<FleetInput value={dataSource?.vesselName} disabled />
						</FleetFormItem>
						<FleetFormItem label={t("common.vesselType")} type="row">
							<FleetInput
								value={
									dataSource?.vesselType
										? ArrivalVesselSubTypeMap[dataSource?.vesselType]
										: dataSource?.vesselType
								}
								disabled
							/>
						</FleetFormItem>
						<FleetFormItem label={t("common.cargoName")} type="row">
							<FleetInput value={dataSource?.cargoName} disabled />
						</FleetFormItem>
					</div>
					<MovementTable
						bordered
						className="portExpenses-portList"
						columns={columns}
						dataSource={dataSource?.portExpenseVoList}
						actions={{
							onChange: change,
							onCommit: commit
						}}
						summary={summary}
					/>
				</div>
				<div className="prompt-footer--container">
					<Button size="small" type="secondary" onClick={onClose}>
						Cancel
					</Button>
					<Button size="small" onClick={saveMovementPortExpenses}>
						Confirm
					</Button>
				</div>
			</div>
		</FleetPrompt>
	);
};

export default MovementPortExpenses;
