import { PortAreaListItemType, PortAreaListTableProps } from "./type";
import dayjs from "dayjs";
import React from "react";
import { dateToUtcString } from "@/tools/date";
import { formatThousandthNumber } from "@/tools/amount";
import { FleetCustomRadio } from "@/components/Radio";
import { MovementCommonTableColumnsType } from "@/pages/voyagemanage/business/movement/details/components/charterBase/common/table/type";
import { vesselTypeSubOption, aisStatusOptions } from "../filter/source";
export const columns: MovementCommonTableColumnsType<
	PortAreaListItemType,
	keyof PortAreaListItemType,
	{
		onClick: PortAreaListTableProps["onClick"];
		onSelect: PortAreaListTableProps["onSelect"];
	}
>[] = [
	{
		width: 40,
		label: "",
		dataIndex: "aisLat",
		fixed: "left",
		render: (value, record, index, actions, otherSource) => {
			return (
				<FleetCustomRadio
					value={otherSource?.selectedRows?.some((ele) => ele == record?.imo)}
					onChange={(val) => {
						actions?.onSelect(record, val, index);
					}}
				/>
			);
		}
	},
	{
		label: "Vessel Name",
		dataIndex: "vesselName",
		width: 130,
		fixed: "left",
		sorted: true
	},
	{
		label: "IMO",
		dataIndex: "imo",
		width: 122,
		sorted: true
	},
	// {
	// 	label: "Vessel Type",
	// 	dataIndex: "vesselType",
	// 	width: 137
	// },
	{
		label: "Vessel Type",
		dataIndex: "vesselTypeSub",
		width: 137,
		render(val, record, index) {
			return <span>{vesselTypeSubOption?.find((item) => item.value == val)?.label ?? "-"}</span>;
		},
		sorted: true
	},
	{
		label: "DWT",
		dataIndex: "dwt",
		width: 137,
		render: (value) => {
			return <span>{formatThousandthNumber(+value, 0, 0)}</span>;
		},
		sorted: true
	},
	{
		label: "AIS Status",
		dataIndex: "aisStatus",
		width: 130,
		render(val, record, index) {
			return <span>{aisStatusOptions?.find((item) => item.value == val)?.label ?? "-"}</span>;
		},
		sorted: true
	},
	{
		label: "ATA/ETA (UTC)",
		dataIndex: "ata",
		width: 159,
		render(value, record, index) {
			return <span>{value ? dayjs(value)?.format("YYYY-MM-DD hh:mm") : "-"}</span>;
		},
		sorted: true
	},
	{
		label: "Destination Port ETA",
		dataIndex: "desPortEta",
		width: 180,
		render(value, record, index) {
			return <span>{value ? dayjs(value)?.format("YYYY-MM-DD hh:mm") : "-"}</span>;
		},
		sorted: true
	},
	{
		label: "Port Name",
		dataIndex: "portName",
		width: 130,
		sorted: true
	},
	{
		label: "Area Name",
		dataIndex: "areaName",
		width: 130,
		sorted: true
	},
	{
		label: "Country Name",
		dataIndex: "countryName",
		width: 130,
		sorted: true
	},
	// {
	// 	label: "Latitude",
	// 	dataIndex: "lat",
	// 	width: 130
	// },
	// {
	// 	label: "Longitude",
	// 	dataIndex: "lat",
	// 	width: 130
	// },
	{
		label: "Last Port Name",
		dataIndex: "startPortName",
		width: 100,
		sorted: true
	},
	{
		label: "Last Area Name",
		dataIndex: "startAreaName",
		width: 100,
		sorted: true
	},
	{
		label: "Actions",
		dataIndex: "action",
		width: 159,
		fixed: "right",
		render: (value, record, index, actions) => {
			return (
				<div className="portAreaListTable-actions">
					<span onClick={() => actions?.onClick?.(record, "detail")}>Detail</span>
					<span onClick={() => actions?.onClick?.(record, "portCall")}>Port Call</span>
				</div>
			);
		}
	}
];
