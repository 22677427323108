import React, { FC } from "react";
import style from "./index.module.less";
import classnames from "classnames";
import { InvStatementHireProps, InvStatementHireItemType } from "./type";
import {
	FleetButton,
	FleetDatePicker,
	FleetFormItem,
	FleetIcon,
	FleetRangePicker,
	FleetVerifyInput
} from "@/components";
import dayjs from "dayjs";
import { InvoiceAddTo } from "@/pages/voyagemanage/business/invoices/components";
import { calcCveValues } from "../fixedExpenses/module";
import { getRetainDecimalsNumber } from "@/tools/amount";

const InvStatementHire: FC<InvStatementHireProps> = ({
	item,
	otherItem,
	onOtherItemChange,
	onCommit,
	onOffHireItemAdd,
	onOffHireItemCommit,
	onOffHireItemDelete,
	fixedExpenses,
	onFixedExpensesChange
}) => {
	return (
		<div className={classnames("invoicesDetail-form", style["invStatement-offHire"])}>
			<div className="invoicesForm-header">
				<InvoiceAddTo
					type="addHire"
					value={otherItem?.addHire}
					onChange={(key, val) => {
						onOtherItemChange?.("addHire", val);
					}}
				>
					<span>Total Hire</span>
				</InvoiceAddTo>
				<span></span>
			</div>
			<div className="invoicesForm-container ttl-onhireForm">
				<FleetVerifyInput
					value={item?.ttlOnHireDay}
					label="TTL On-Hire days"
					type="number"
					disabled={!item?.onHireStartDay}
					onChange={(val: number) => {
						if (!item?.onHireStartDay) return;
						const totalHire = val * +item?.dailyHire;
						const addComm = (totalHire * +item?.addCommRate) / 100;
						const brokerage = (totalHire * +item?.brokerageRate) / 100;
						const params: Partial<InvStatementHireItemType> = {
							ttlOnHireDay: val,
							onHireEndDay: dayjs(item?.onHireStartDay)?.add(val, "day")?.valueOf(),
							totalHire: totalHire,
							addComm,
							brokerage
						};
						onCommit(params);
						onFixedExpensesChange?.("cve", calcCveValues(fixedExpenses, { ttlOnHireDay: val }));
					}}
				/>
				<FleetFormItem label="On-Hire days(start)" needVerity>
					<FleetDatePicker
						value={item?.onHireStartDay ? dayjs(item?.onHireStartDay) : null}
						showHour
						showMinute
						showTime
						format="YYYY-MM-DD HH:mm"
						allowClear
						disabledDate={(current) => {
							return (
								(current && item?.onHireEndDay && current.isAfter(item?.onHireEndDay)) ||
								current.isSame(item?.onHireEndDay)
							);
						}}
						onChange={(val, timeStramp) => {
							const params: Partial<InvStatementHireItemType> = {
								onHireStartDay: timeStramp?.startOf("minute")?.valueOf()
							};
							if (item?.onHireEndDay) {
								const diffVal = +(
									dayjs(item?.onHireEndDay)?.diff(timeStramp) /
									(1000 * 60 * 60 * 24)
								)?.toFixed(2);
								const totalHire = diffVal * +item?.dailyHire;
								params.ttlOnHireDay = diffVal;
								params.addComm = (totalHire * +item?.addCommRate) / 100;
								params.brokerage = (totalHire * +item?.brokerageRate) / 100;
								onFixedExpensesChange?.(
									"cve",
									calcCveValues(fixedExpenses, { ttlOnHireDay: diffVal })
								);
							} else if (item?.ttlOnHireDay) {
								params.onHireEndDay = dayjs(timeStramp)?.add(item?.ttlOnHireDay, "day")?.valueOf();
							}
							onCommit?.(params);
						}}
					/>
				</FleetFormItem>
				<FleetFormItem label="On-Hire days(end)" needVerity>
					<FleetDatePicker
						value={item?.onHireEndDay ? dayjs(item?.onHireEndDay) : null}
						showHour
						showMinute
						showTime
						format="YYYY-MM-DD HH:mm"
						allowClear
						disabled={!item?.onHireStartDay}
						onChange={(val, timeStramp) => {
							if (!item?.onHireStartDay) return;
							const params: Partial<InvStatementHireItemType> = {
								onHireEndDay: timeStramp?.startOf("minute")?.valueOf()
							};
							const diffVal = +(
								dayjs(timeStramp)?.diff(item?.onHireStartDay) /
								(1000 * 60 * 60 * 24)
							)?.toFixed(2);
							const totalHire = diffVal * +item?.dailyHire;
							params.totalHire = totalHire;
							params.ttlOnHireDay = diffVal;
							params.addComm = (totalHire * +item?.addCommRate) / 100;
							params.brokerage = (totalHire * +item?.brokerageRate) / 100;
							onCommit(params);
							onFixedExpensesChange?.(
								"cve",
								calcCveValues(fixedExpenses, { ttlOnHireDay: diffVal })
							);
						}}
					/>
				</FleetFormItem>
				{/* <FleetFormItem label="On-Hire days" needVerity>
					<FleetRangePicker
						value={[
							item?.onHireStartDay ? dayjs(item?.onHireStartDay) : null,
							item?.onHireEndDay ? dayjs(item?.onHireEndDay) : null
						]}
						showHour
						showMinute
						showTime
						format="YYYY-MM-DD HH:mm"
						allowClear
						onChange={(value) => {
							const params: Partial<Omit<InvStatementHireItemType, "offHireItems">> = {
								onHireStartDay: null,
								onHireEndDay: null,
								ttlOnHireDay: 0,
								totalHire: 0,
								addComm: 0,
								brokerage: 0
							};
							if (!value) {
								onCommit?.({
									onHireStartDay: null,
									onHireEndDay: null,
									ttlOnHireDay: 0,
									totalHire: 0,
									addComm: 0,
									brokerage: 0
								});
								return;
							}
							const onHireStartDay = value?.[0]?.startOf("minute")?.valueOf();
							const onHireEndDay = value?.[1]?.startOf("minute")?.valueOf();
							const diffVal = getRetainDecimalsNumber(
								(onHireEndDay - onHireStartDay) / (1000 * 60 * 60 * 24),
								3
							);
							const totalHire = diffVal * +item?.dailyHire;
							const addComm = (totalHire * +item?.addCommRate) / 100;
							const brokerage = (totalHire * +item?.brokerageRate) / 100;
							onCommit?.({
								ttlOnHireDay: diffVal,
								onHireStartDay,
								onHireEndDay,
								totalHire,
								addComm,
								brokerage
							});
							onFixedExpensesChange?.(
								"cve",
								calcCveValues(fixedExpenses, { ttlOnHireDay: diffVal })
							);
						}}
					/>
				</FleetFormItem> */}
				<FleetVerifyInput value={item?.totalHire} label="TTL Hire" type="number" disabled />
				<FleetVerifyInput
					value={item?.dailyHire}
					needVerity
					type="number"
					label="Daily Hire"
					onChange={(val: number) => {
						const totalHire = val * +item?.ttlOnHireDay;
						const addComm = (totalHire * +item?.addCommRate) / 100;
						const brokerage = (totalHire * +item?.brokerageRate) / 100;
						onCommit?.({
							dailyHire: val,
							totalHire: totalHire,
							addComm: addComm,
							brokerage: brokerage
						});
					}}
				/>
			</div>
			<div className="invoicesForm-container ttl-onoffForm">
				<FleetVerifyInput
					value={item?.ttlOffHireDay}
					label="TTL Off- Hire days"
					containerClassname="invoicesForm-ttlOffHireDay"
					type="number"
					disabled
				/>
				{item?.offHireFoList?.map((offHireItem, idx) => {
					return (
						<div className="statement-fields" key={idx}>
							<FleetFormItem label="Off- Hire days">
								<FleetRangePicker
									showHour
									showMinute
									showTime
									allowClear
									format="YYYY-MM-DD HH:mm"
									value={[
										offHireItem?.offHireStartDay ? dayjs(offHireItem?.offHireStartDay) : null,
										offHireItem?.offHireEndDay ? dayjs(offHireItem?.offHireEndDay) : null
									]}
									onChange={(value) => {
										if (!value) {
											onOffHireItemCommit?.(idx, {
												offHireStartDay: 0,
												offHireEndDay: 0,
												totalOffHire: 0
											});
											return;
										}
										// debugger
										const offHireStartDay = value?.[0]?.startOf("minute")?.valueOf();
										const offHireEndDay = value?.[1]?.startOf("minute")?.valueOf();
										const diffVal = getRetainDecimalsNumber(
											(offHireEndDay - offHireStartDay) / (1000 * 60 * 60 * 24),
											3
										);
										onOffHireItemCommit?.(idx, {
											offHireStartDay: offHireStartDay,
											offHireEndDay: offHireEndDay,
											totalOffHire: diffVal
										});
									}}
								/>
							</FleetFormItem>
							<FleetIcon type="FleetPortDelIcon" onClick={() => onOffHireItemDelete?.(idx)} />
						</div>
					);
				})}
				<FleetButton
					icon="EstimationcardAddIcon"
					size="small"
					type="secondary"
					className="invoices-add"
					onClick={onOffHireItemAdd}
				>
					Add Off-Hire
				</FleetButton>
			</div>
			<div className="invoicesForm-container">
				<div className="invoicesForm-freight">
					<FleetVerifyInput value={item?.addComm} type="number" label="Add. Comm." disabled />
					<FleetVerifyInput
						value={item?.addCommRate}
						containerClassname="freight-unit"
						label="%"
						min={0}
						max={100}
						type="number"
						onChange={(val: number) => {
							const addComm = (val * +item?.totalHire) / 100;
							onCommit?.({
								addCommRate: val,
								addComm
							});
						}}
					/>
				</div>
				<div className="invoicesForm-freight">
					<FleetVerifyInput
						value={item?.brokerage}
						type="number"
						label="Brokerage"
						disabled
						// onChange={(val: number) => {
						//   onChange?.("brokerage", val)
						// }}
					/>
					<FleetVerifyInput
						value={item?.brokerageRate}
						label="%"
						min={0}
						max={100}
						type="number"
						containerClassname="freight-unit"
						onChange={(val: number) => {
							const brokerage = (val * +item?.totalHire) / 100;
							onCommit?.({
								brokerageRate: val,
								brokerage
							});
						}}
					/>
				</div>
			</div>
			<span className="invoicesRate-computed">
				Tips: Add. Comm.= (N%) X Hire; Brokerage= (N%) X Hire;
			</span>
		</div>
	);
};

export default InvStatementHire;
