import React, { FC } from "react";
import style from "./index.module.less";
import classnames from "classnames";
import { BunkerDetailsProps } from "./type";
import MovementTable from "../../../charterBase/common/table";
import { columns } from "./source";

const BunkerDetails: FC<BunkerDetailsProps> = ({ items, onChange, onCommit, onOpen }) => {
	return (
		<div className={classnames("bunkerPlan-commom", style["bunkerPlan-clause"])}>
			<span className="bunkerPlan-header--title">Bunkering Details</span>
			<MovementTable
				dataSource={items}
				className="bunkerPlan-details--table"
				bordered
				columns={columns}
				actions={{
					onChange,
					onCommit,
					onOpen
				}}
			/>
		</div>
	);
};

export default BunkerDetails