import React, { FC } from "react";
import style from "./index.module.less";
import { RouteCommonRoutePlanDetailsProps, RoutePlanWayPointItemType } from "./type";
import { cn } from "@/tools";
import useIndex from "./hooks/useIndex";
import { FleetLoading } from "@/components";
import { Icon, Table } from "@/_components";
import { columns } from "./source";

const RouteCommonRoutePlanDetails: FC<RouteCommonRoutePlanDetailsProps> = ({ onFlyTo }) => {
	const { activeDetailsOption, loading, dataSource, onDetailsOptionCommit } = useIndex();
	return (
		<div
			className={cn(
				"w-full h-full relative hidden gap-[20px] pt-[12px] pr-[12px] pb-[8px] pl-[25px] flex-col",
				style["routeCommon-routePlanDetails"],
				{
					flex:
						activeDetailsOption?.activeTab === "routePlans" &&
						!!activeDetailsOption?.activeRoutePlan
				}
			)}
		>
			<FleetLoading loading={loading} />
			<div className="w-full flex items-center gap-[20px]">
				<div
					className="flex flex-row gap-[8px] items-center cursor-pointer"
					onClick={() => {
						onDetailsOptionCommit?.({ activeRoutePlan: null });
					}}
				>
					<Icon type="fleet-back" size={20} color="#333" />
					<span className="text-[#221E1E] text-[16px] font-normal leading-[22px]">Back</span>
				</div>
				<div className="flex flex-row gap-[20px]">
					<span className="text-[#333] leading-[22px] font-medium text-[16px]">WayPoint List</span>
					<span className="text-[14px] text-[#48484899] font-medium leading-[22px]">
						Total:{""}
						<span className="text-[#333]">{dataSource?.length}</span>
					</span>
				</div>
			</div>
			<Table<RoutePlanWayPointItemType>
				columns={columns}
				dataSource={dataSource}
				pagination={false}
				primaryKey="key"
				className="routePlanDetails-list  w-full h-[calc(100%-52px)]"
				onRow={(record) => {
					return {
						onDoubleClick: () => {
							onFlyTo?.({ lat: record?.lat, lon: record?.lon });
						}
					};
				}}
			/>
		</div>
	);
};

export default RouteCommonRoutePlanDetails;
