import { ExcludeKeys, ExtendsKeys } from "@/types";
import { HeadFixtueKeys, HeadFixtueLabelMap } from "@/types/voyageManage/headFixtureList";
import { Dayjs } from "dayjs";

const initPortForm = (initItem?: HfPortFormType["ballast"]) => {
  const portList: HfPortFormType["ballast"] = [];
  for (let i = 0; i < 3; i++) {
    portList.push({
      speed: initItem?.[i]?.speed ?? "",
      hsFo: initItem?.[i]?.hsFo ?? "",
      lsFo: initItem?.[i]?.lsFo ?? "",
      mdo: initItem?.[i]?.mdo ?? "",
      mgo: initItem?.[i]?.mgo ?? "",
      remarks: initItem?.[i]?.remarks ?? ""
    })
  }
  return portList
}

export type HfPerformanceKeys = ExtendsKeys<HeadFixtueKeys,
  "speed" | "hsFo" | "lsFo" | "mdo" | "mgo" | "remarks" | "performanceClause"
>;

export type HfPerformanceNumKeys = ExtendsKeys<HfPerformanceKeys, "speed" | "hsFo" | "lsFo" | "mdo" | "mgo">;

export type HfPerformanceStrKeys = ExcludeKeys<HfPerformanceKeys, HfPerformanceNumKeys>;

export type HfPerformanceFormType = Record<HfPerformanceStrKeys, string> &
  Record<HfPerformanceNumKeys, number | "">;

export type HfPerformancePortFormType = Pick<HfPerformanceFormType, ExcludeKeys<HfPerformanceStrKeys, "performanceClause">> &
  Pick<HfPerformanceFormType, ExcludeKeys<HfPerformanceNumKeys, "speed">>;

export type HfPerformanceSpeedFormType = Pick<HfPerformanceFormType, ExcludeKeys<keyof HfPerformanceFormType, "performanceClause">>;

export type HfPortFormType = {
  work: HfPerformancePortFormType;
  idle: HfPerformancePortFormType;
  ballast: HfPerformanceSpeedFormType[];
  laden: HfPerformanceSpeedFormType[]
  performanceClause: HfPerformanceFormType["performanceClause"]
}

/**
 * hooks type
 */

export type HfPerformanceFormChangeEvent = (
  portKey: "work" | "idle" | "ballast" | "laden" | "performanceClause",
  id: number | undefined,
  key: HfPerformanceKeys,
  value: any
) => void;

export type HfPerformancePortChangeEvent = <K extends keyof HfPerformanceSpeedFormType>(
  portKey: "ballast" | "laden",
  id: number,
  key: K,
  value: HfPerformanceSpeedFormType[K]
) => void;

export type HfPerformanceWorkChangeEvent = <K extends keyof HfPerformancePortFormType>(
  portKey: "work" | "idle",
  key: K,
  value: HfPerformancePortFormType[K]
) => void;


export type HfSpeedGroupCommitEvent = (items: HfPerformanceSpeedFormType[]) => void;

export type HfPerformancePortFormCommitEvent = (item: Partial<HfPerformancePortFormType>) => void;

export type HfPerformanceCommitEvent = (item: HfPortFormType) => void;
/**
 * hfbasicprops type
 */

export type HfPerformanceFormProps = {
  item: HfPortFormType;
  onPortChange: HfPerformancePortChangeEvent;
  onWorkChange: HfPerformanceWorkChangeEvent;
  onCommit:HfPerformanceCommitEvent;
  onChange: (key: HfPortFormType["performanceClause"], value: string) => void
}

/**
 * init data
 */
export const initialPerformanceForm = (initPortFormItems?: HfPortFormType): HfPortFormType => {
  return {
    performanceClause: initPortFormItems?.performanceClause ?? "",
    work: {
      hsFo: initPortFormItems?.work?.hsFo ?? "",
      lsFo: initPortFormItems?.work?.lsFo ?? "",
      mdo: initPortFormItems?.work?.mdo ?? "",
      mgo: initPortFormItems?.work?.mgo ?? "",
      remarks: initPortFormItems?.work?.remarks ?? ""
    },
    idle: {
      hsFo: initPortFormItems?.idle?.hsFo ?? "",
      lsFo: initPortFormItems?.idle?.lsFo ?? "",
      mdo: initPortFormItems?.idle?.mdo ?? "",
      mgo: initPortFormItems?.idle?.mgo ?? "",
      remarks: initPortFormItems?.idle?.remarks ?? ""
    },
    ballast: initPortForm(initPortFormItems?.ballast),
    laden: initPortForm(initPortFormItems?.laden)
  }
}

export const checkPerformanceFormKeys = [];