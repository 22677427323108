import React, { FC } from "react";
import style from "./index.module.less";
import { MovementDetailTaskListFilterType, MovementDetailTaskListProps } from "./type";
import { FleetFormItem, FleetSelect } from "@/components";
import { levelOptions, voyageStatusOptions } from "./source";
import { MovementTaskItem } from "./components";

const MovementDetailTaskList: FC<MovementDetailTaskListProps> = ({
  currentVoyageItem,
  items,
  activeTaskItem,
  onChange,
  onSelect,
  filterItem,
  onFilterChange,
  onOpenDialog
}) => {
  return <div className={style["movement-taskDetails-taskList"]}>
    <div className="taskList-header">
      <div className="taskList-header--tag">{currentVoyageItem?.stage}</div>
      <div className="taskList-header--filter">
        <FleetFormItem
          label="Task Status"
        >
          <FleetSelect
            options={voyageStatusOptions}
            allowClear
            value={filterItem?.isComplete}
            onChange={(value: MovementDetailTaskListFilterType["isComplete"]) => {
              onFilterChange?.("isComplete", value ?? 2)
            }}
          />
        </FleetFormItem>
        <FleetFormItem
          label="Risk level"
        >
          <FleetSelect
            allowClear
            options={levelOptions}
            value={filterItem?.level}
            onChange={(value: MovementDetailTaskListFilterType["level"]) => {
              console.log("MovementDetailTaskListFilterType", value)
              onFilterChange?.("level", value ?? 4)
            }}
          />
        </FleetFormItem>
      </div>
    </div>
    <div className="taskList-container">
      <div className="taskList-scrollArea">
        {
          items?.map((item, index) => {
            return <MovementTaskItem
              key={index}
              item={item}
              isSelected={activeTaskItem?.id === item?.id}
              onChange={onChange}
              onSelect={onSelect}
              onOpenDialog={onOpenDialog}
            />
          })
        }
      </div>
    </div>
  </div>
}

export default MovementDetailTaskList