import React, { useEffect } from "react";
import { PromptChildrenProps } from "@/common/Prompt";
import HfBasicForm from "../basic";
import HfHireAndFeesForm from "../hireAndFees";
import HfPaymentTermsForm from "../paymentTerms";
import HfBunkerClause from "../bunkerClause";
import HfPerformance from "../performance";
import NewContract from "@/featrue/voyagemanage/common/newContract";
import useAddHeadFixture from "@/hook/voyageManage/headFixture/useAddHeadFixture";
import { useAppDispatch } from "@/hook";
import { useNavigate } from "react-router-dom";
import useReminder from "@/hook/useReminder";
import { addHeadFixtureAction } from "@/action/voyageManage/headFixture";

export type NewHeadFixtureProps = {
  onReset?:() => void
} & PromptChildrenProps;

const NewHeadFixture: React.FC<NewHeadFixtureProps> = ({
  hide,
  onClose,
  onReset
}) => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { reminder } = useReminder()
  const {
    loading,
    setLoading,
    basicForm,
    handleBasicFormChange,
    handleBasicFormCommit,
    hireAndFeesForm,
    handleHireAndFeesFormChange,
    handleHireAndFeesFormCommit,
    bunkerClauseForm,
    handleBunkerClauseFormChange,
    handleBunkerClauseFormCommit,
    payTermForm,
    handlePayTermFormChange,
    handlePayTermFormCommit,
    performanceForm,
    handlePortChange,
    handleWorkChange,
    handlePerformanceFormChange,
    handlePortCommit,
    clear,
    check
  } = useAddHeadFixture();

  const addHeadFixtureFront = () => {
    setLoading(true);
  }

  const addHeadFixtureSuccess = (response) => {
    reminder("success", response?.data);
    setLoading(false);
    onClose?.();
    onReset?.();
  }

  const addHeadFixtureError = (error) => {
    reminder("error", error?.msg ? (error?.msg + ": " + error?.data) : error?.data);
    setLoading(false);
  }

  const addHeadFixture = () => {
    if(!check()) return;
    const {
      performanceClause,
      ...restPerformanceForm
    } = performanceForm;
    const {
      cpNo,
      ...restBasicForm
    } = basicForm;
    addHeadFixtureAction(
      {
        ...restBasicForm,
        ...hireAndFeesForm,
        ...bunkerClauseForm,
        ...payTermForm,
        performanceClause: performanceClause,
        performanceList: restPerformanceForm

      },
      addHeadFixtureFront,
      addHeadFixtureSuccess,
      addHeadFixtureError,
      dispatch,
      navigate
    )
  }

  useEffect(() => {
    if (hide) return;
    clear()
  }, [hide])

  return <NewContract
    label="New Head Fixture"
    loading={loading}
    hide={hide}
    onClose={onClose}
    onSave={addHeadFixture}
  >
    <HfBasicForm
      type="newAdd"
      item={basicForm}
      onChange={handleBasicFormChange}
      onCommit={handleBasicFormCommit}
    />
    <HfHireAndFeesForm
      item={hireAndFeesForm}
      onChange={handleHireAndFeesFormChange}
      onCommit={handleHireAndFeesFormCommit}
    />
    <HfPaymentTermsForm
      item={payTermForm}
      onChange={handlePayTermFormChange}
    />
    <HfBunkerClause
      item={bunkerClauseForm}
      onChange={handleBunkerClauseFormChange}
      onCommit={handleBunkerClauseFormCommit}
    />
    <HfPerformance
      item={performanceForm}
      onPortChange={handlePortChange}
      onWorkChange={handleWorkChange}
      onChange={handlePerformanceFormChange}
      onCommit={handlePortCommit}
    />
  </NewContract>

}

export default NewHeadFixture;