import React, { MutableRefObject } from "react";
import style from "./index.module.less";
import EsRouteGraph from "../routeGraph";
import EsRouteline, { EsRoutelineProps } from "../routeLine";
import { IdentifierMapChangeEvent, IdentifierMapType, ShippingMapCommitEvent, ShippingVesselHisChangeEvent, shippingMapType } from "@/hook/estimation/details/useDetails";

const EstimationRoute: React.FC<EsRoutelineProps & {
  shippingMap?: MutableRefObject<shippingMapType>;
  identifierMap?: IdentifierMapType;
  deliveryPort?: number[];
  portSeq: ("laden" | "ballast")[];
  onIdentifierChange?: IdentifierMapChangeEvent,
  onVesselHisCommit?: ShippingVesselHisChangeEvent,
  onShippingCommit?: ShippingMapCommitEvent
}> = ({
  otherItem,
  cargoItem,
  onCargoItemChange,
  onOtherItemChange,
  shippingMap,
  identifierMap,
  deliveryPort,
  ballastPortList,
  ladenPortList,
  delievryPort,
  speedList,
  portSeq,
  onIdentifierChange,
  onVesselHisCommit
}) => {
    return <div className={style["estimation-route"]}>
      <EsRouteGraph
        shippingMap={shippingMap}
        identifierMap={identifierMap}
        deliveryPort={deliveryPort}
        portSeq={portSeq}
      />
      <EsRouteline
        cargoItem={cargoItem}
        otherItem={otherItem}
        onCargoItemChange={onCargoItemChange}
        onOtherItemChange={onOtherItemChange}
        shippingMap={shippingMap}
        onIdentifierChange={onIdentifierChange}
        onVesselHisCommit={onVesselHisCommit}
        delievryPort={delievryPort}
        ballastPortList={ballastPortList}
        ladenPortList={ladenPortList}
        speedList={speedList}
      />
    </div>
  }
export default EstimationRoute;