import { useMemo, useState } from "react";
import { PortAreaListFilterDataType } from "../components/filter/type";
import { initPortAreaListFilterData } from "../components/filter/source";
import { PortAreaListItemType, PortAreaListTableProps } from "../components/table/type";
import {
	getPortVesselListAction,
	getPortVesselSearchDataAction,
	savePortVesselSearchDataAction,
	downLoadPortVesselAction
} from "@/action/monitoring/portList";
import useReminder from "@/hook/useReminder";
import { useAppDispatch } from "@/hook";
import { useNavigate } from "react-router-dom";
import { MonitoringPortAreaListProps } from "../type";
import { useMonitoringContext } from "@/pages/monitoring/MonitoringContext";
import dayjs from "dayjs";
import { compare } from "../source";
const useIndex = (
	searchParams: MonitoringPortAreaListProps["searchParams"],
	onOpenPortCall: MonitoringPortAreaListProps["onOpenPortCall"],
	onAisItemSelect: MonitoringPortAreaListProps["onAisItemSelect"],
	onPortVesselLoad: MonitoringPortAreaListProps["onPortVesselLoad"]
) => {
	const { reminder } = useReminder();
	const dispatch = useAppDispatch();
	const navigate = useNavigate();
	const [selectedRows, setSelectedRows] = useState<string[]>([]);
	const [loading, setLoading] = useState<boolean>(false);
	const [portAreaListFilterData, setPortAreaListFilterData] = useState<PortAreaListFilterDataType>(
		initPortAreaListFilterData()
	);
	const [portAreaListTable, setPortAreaListTable] = useState<PortAreaListItemType[]>([]);
	const [activeSortOption, setActiveSortOption] = useState<PortAreaListTableProps["sortOption"]>({
		type: null,
		value: null
	});
	const sortList = useMemo(() => {
		if (!activeSortOption.type || !activeSortOption.value) return portAreaListTable;
		return [...portAreaListTable]?.sort((prev, next) => {
			return compare(
				prev?.[activeSortOption?.value],
				next?.[activeSortOption?.value],
				activeSortOption?.type
			);
		});
	}, [portAreaListTable, activeSortOption]);
	// 搜索
	const handleCommitFilterData = (item: Partial<PortAreaListFilterDataType>) => {
		if (!item?.hasOwnProperty("filterEndDate")) {
			savePortVesselSearch({ ...portAreaListFilterData, ...item });
		}
		getPortVesselList({ ...portAreaListFilterData, ...item });
		setPortAreaListFilterData((prev) => {
			return { ...prev, ...item };
		});
	};
	const handleRowSort: PortAreaListTableProps["onSort"] = (item) => {
		switch (true) {
			case activeSortOption.value === item?.value && activeSortOption.type === "desc":
				setActiveSortOption((prev) => ({ ...prev, type: "asc" }));
				break;
			case activeSortOption.value === item?.value && activeSortOption.type === "asc":
				setActiveSortOption({ type: null, value: null });
				break;
			case activeSortOption.value !== item?.value:
				setActiveSortOption((prev) => ({ value: item?.value, type: "desc" }));
				break;
			default:
				break;
		}
	};
	// 下载
	const handleDownLoad = () => {
		if (selectedRows.length == 0) {
			reminder("warning", "Please select first");
			return;
		}
		downLoadPortVesselAction(
			portAreaListTable.filter((item) => {
				return selectedRows?.includes(item.imo);
			}),
			portVesselListFront,
			() => {
				setLoading(false);
			},
			portVesselListError,
			dispatch,
			navigate
		);
	};
	const handleRowClick: PortAreaListTableProps["onClick"] = (item, type) => {
		if (type == "portCall") {
			onOpenPortCall?.(item, "portCallList");
			return;
		}
		onAisItemSelect?.("port", item, true);
	};
	const handleSelectAll = () => {
		if (selectedRows.length != portAreaListTable.length) {
			setSelectedRows(
				portAreaListTable.map((item) => {
					return item?.imo;
				})
			);
		} else {
			setSelectedRows([]);
		}
	};
	const handleRowSelect: PortAreaListTableProps["onSelect"] = (item, select, index) => {
		const currentIndex = selectedRows.findIndex((ele) => ele == item.imo);
		if (currentIndex == -1) {
			setSelectedRows((prev) => [...prev, item.imo]);
		} else {
			setSelectedRows((prev) => {
				prev.splice(currentIndex, 1);
				return [...prev];
			});
		}
	};
	const portVesselListFront = () => {
		setLoading(true);
	};
	const portVesselListError = (error) => {
		setLoading(false);
		reminder("error", error?.msg ? error?.msg + ": " + error?.data : error?.data);
	};
	// 保存搜索条件成功
	const savePortVesselSearchSuccess = (res) => {
		setLoading(false);
	};
	// 保存搜索条件
	const savePortVesselSearch = (params: PortAreaListFilterDataType) => {
		savePortVesselSearchDataAction(
			params,
			portVesselListFront,
			savePortVesselSearchSuccess,
			portVesselListError,
			dispatch,
			navigate
		);
	};
	// 获取搜索条件成功
	const getPortVesselSearchSuccess = (res) => {
		setLoading(false);
		if (res?.data) {
			setPortAreaListFilterData(res.data);
		}
	};
	// 获取搜索条件
	const getPortVesselSearch = () => {
		getPortVesselSearchDataAction(
			null,
			portVesselListFront,
			getPortVesselSearchSuccess,
			portVesselListError,
			dispatch,
			navigate
		);
	};
	// 查询成功
	const getPortVesselListSuccess = (res) => {
		setLoading(false);
		if (res?.data) {
			setPortAreaListTable(
				res?.data.map((item) => {
					return { ...item };
				})
			);
			debugger;
			console.log("getPortVesselListSuccess", res?.data);
			onPortVesselLoad?.(res?.data);
		}
	};
	// 查询
	const getPortVesselList = (param: PortAreaListFilterDataType = portAreaListFilterData) => {
		if (!searchParams?.currentPort?.portCode) return;
		let startDate = dayjs().format("YYYY-MM-DD");
		let endDate = dayjs().format("YYYY-MM-DD");
		if (searchParams?.termType == 3) {
			if (!searchParams?.dateRangeHistory?.startDate) return;
			(startDate = searchParams?.dateRangeHistory?.startDate),
				(endDate = searchParams?.dateRangeHistory?.endDate);
		}
		if (searchParams?.termType == 1) {
			if (!searchParams?.dateRangeExpert?.startDate) return;
			(startDate = searchParams?.dateRangeExpert?.startDate),
				(endDate = searchParams?.dateRangeExpert?.endDate);
		}
		getPortVesselListAction(
			{
				type: searchParams?.termType,
				startDate,
				endDate,
				portCode: searchParams?.currentPort?.portCode,
				keyword: param?.keyword,
				filterStartDate: param?.filterStartDate,
				filterEndDate: param?.filterEndDate,
				vesselTypeSub: param?.vesselTypeSub,
				aisStatus: param?.aisStatus,
				startAreaCode: param?.startAreaCode,
				lastPortCountry: param?.lastPortCountry
			},
			portVesselListFront,
			getPortVesselListSuccess,
			portVesselListError,
			dispatch,
			navigate
		);
	};
	return {
		portAreaListFilterData,
		portAreaListTable,
		handleCommitFilterData,
		handleDownLoad,
		handleRowClick,
		handleRowSelect,
		getPortVesselList,
		selectedRows,
		activeSortOption,
		getPortVesselSearch,
		loading,
		handleSelectAll,
		setPortAreaListTable,
		sortList,
		handleRowSort
	};
};

export default useIndex;
