import React from "react";
import style from "./index.module.less";
import { FleetButton, FleetIcon, FleetInput } from "@/components";
import classNames from "classnames";
import useIndex from "./hooks/useIndex";

const LatitudeSelect: React.FC<{
	onClose: (portCode: string) => void;
	open: boolean;
	onBlur?: () => void;
}> = ({ onClose, onBlur, open }) => {
	const {
		status,
		currentIndex,
		items,
		active,
		value,
		translate,
		inputRef,
		isFull,
		change,
		keyDown,
		blur,
		select
	} = useIndex(open, onBlur);

	const handleConfirm = () => {
		const result = translate();
		onClose?.(result);
	};

	return (
		<div className={style["fleet-latitudeSelect"]}>
			<div className="latitudeSelect-header">
				<span>Longitude</span>
				<span>Latitude</span>
			</div>
			<div className="latitudeSelect-container">
				<div className="latitudeSelect-wrap">
					<FleetInput
						value={value}
						className={classNames("latitudeSelect-input", status.current)}
						inputStyle={{
							left: 80 * currentIndex.current
						}}
						inputRef={inputRef}
						type="number"
						onKeyDown={keyDown}
						// onFocus={handleFocus}
						onBlur={blur}
						onChange={change}
					/>
					<div className="latitudeSelect-content">
						{items.map((item, idx) => {
							return (
								<div
									key={idx}
									onClick={() => {
										select(idx);
									}}
									className={classNames("latitudeSelect-item", {
										active: active === idx
									})}
								>
									<span>{items[idx]}</span>
								</div>
							);
						})}
					</div>
				</div>
				<FleetButton
					className="latitudeSelect-action"
					type="primary"
					size="small"
					disabled={!isFull}
					onClick={handleConfirm}
				>
					<FleetIcon type="FleetConfirmIcon" outerLayerClassname="latitudeSelect-icon" />
				</FleetButton>
			</div>
		</div>
	);
};

export default LatitudeSelect;
