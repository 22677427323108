import { useMemo, useState } from "react";
import { useBunkerPriceIndex, useFixedExpensesIndex, useHireIndex } from "../components";
import {
	InvStatementDescInitItemType,
	InvStatementDescItemType,
	InvStatementDescProps
} from "../type";
import { useOtherExpensesIndex } from "../../../../../common";

const useIndex = (otherItem: InvStatementDescProps["otherItem"]) => {
	const [dataSource, setDataSource] = useState<InvStatementDescItemType>({
		voyageNo: "",
		remitAmount: 0,
		amount: 0
	});

	const {
		dataSource: hireItems,
		offHireDelIds,
		summary: hireSummary,
		commit: handleHireCommit,
		handleHireCheck,
		handleOffHireCommit,
		handleOffHireAdd,
		handleOffHireDelete,
		init: handleHireInit
	} = useHireIndex({ addHire: otherItem?.addHire });

	const {
		summary: bunkerPriceSummary,
		dataSource: bunkerPriceItem,
		commit: handleBunkerPriceCommit,
		init: handleBunkerPriceInit,
		check: handleBunkerPriceCheck
	} = useBunkerPriceIndex({ addBunker: otherItem?.addBunker });

	const {
		summary: otherExpensesSummary,
		dataSource: otherExpenses,
		delIds: otherExpenseDelIds,
		change: handleOtherExpensesChange,
		add: handleOtherExpensesAdd,
		remove: handleOtherExpensesDelete,
		init: handleOtherExpensesInit
	} = useOtherExpensesIndex({ addOther: otherItem?.addOther });

	const {
		dataSource: fixedExpenses,
		summary: fixedExpensesSumary,
		change: handleFixedExpensesChange,
		commit: handleFixedExpensesCommit,
		init: handleFixedExpensesInit
	} = useFixedExpensesIndex({ addFixed: otherItem?.addFixed });

	console.log("summary", {
		hireSummary,
		bunkerPriceSummary,
		otherExpensesSummary,
		fixedExpensesSumary
	});

	const summary = useMemo(() => {
		return {
			less: hireSummary?.less + bunkerPriceSummary?.less + otherExpensesSummary?.less,
			plus:
				hireSummary?.plus +
				bunkerPriceSummary?.plus +
				otherExpensesSummary?.plus +
				fixedExpensesSumary
		};
	}, [hireSummary, bunkerPriceSummary, otherExpensesSummary, fixedExpensesSumary]);

	const check = () => {
		const { checked: bunkerPriceChecked, checkKey: bunkerPriceCheckKey } = handleBunkerPriceCheck();
		if (!bunkerPriceChecked) return { checked: bunkerPriceChecked, checkKey: bunkerPriceCheckKey };

		const { checked: hireChecked, checkKey: hireCheckKey } = handleHireCheck();
		if (!hireChecked) return { checked: hireChecked, checkKey: hireCheckKey };

		return {
			checked: true,
			checkKey: null
		};
	};

	const init = (type: "init" | "details", item: Partial<InvStatementDescInitItemType>) => {
		handleHireInit(type, item);
		handleBunkerPriceInit(item);
		handleOtherExpensesInit(type, item?.otherFieldList);
		handleFixedExpensesInit(item);
		setDataSource({
			voyageNo: item?.voyageNo ?? "",
			remitAmount: item?.remitAmount ?? 0,
			amount: item?.amount ?? 0
		});
	};

	return {
		descItem: {
			...dataSource,
			amount:
				hireSummary?.plus +
				bunkerPriceSummary?.plus +
				fixedExpensesSumary -
				bunkerPriceSummary?.less -
				hireSummary?.less
		},
		offHireDelIds,
		summary,
		hireItems,
		handleHireCommit,
		handleOffHireCommit,
		handleOffHireAdd,
		handleOffHireDelete,
		bunkerPriceItem,
		handleBunkerPriceCommit,
		otherExpenses,
		otherExpenseDelIds,
		handleOtherExpensesChange,
		handleOtherExpensesAdd,
		handleOtherExpensesDelete,
		fixedExpenses,
		handleFixedExpensesChange,
		handleFixedExpensesCommit,
		check,
		init
	};
};

export default useIndex;
