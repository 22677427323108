import React, { useCallback } from "react";
import { FleetIcon, FleetInput, FleetTooltip } from "@/components";
import style from "./index.module.less";
import { IconKeys } from "@/components/Icon/icons";
import { useAppDispatch } from "@/hook";
import { useLocation, useNavigate } from "react-router-dom";
import { loginOutAction } from "@/store/userSlice";
import { Popover } from "@/_components";

export type HeaderMenuItemProps = {
	icon?: IconKeys;
	label: string;
	path?: string;
	disabled?: boolean;
	type: "close" | "exit";
};

const HeaderMenu: React.FC<{
	list?: HeaderMenuItemProps[];
	onClose?: () => void;
	onExit?: () => void;
	onClick?: (item) => void;
}> = ({
	list = [
		{
			label: "Personal Center",
			icon: "FleetHeaderMenuUserIcon",
			type: "close"
		},
		{
			label: "Exit",
			icon: "FleetHeaderExitIcon",
			type: "exit"
		}
	],
	onClose,
	onExit,
	onClick
}) => {
	const handleClick = (item) => {
		if (onClick) {
			onClick?.(item);
			return;
		}
		if (item?.type === "close") {
			onClose?.();
		} else if (item?.type === "exit") {
			onExit?.();
		}
	};
	return (
		<div className={style["layout-header-menu"]}>
			{list?.map((item: HeaderMenuItemProps, idx: number) => {
				return (
					<div
						key={idx}
						className="menu-item"
						onClick={() => {
							handleClick(item);
						}}
					>
						<FleetIcon type={item?.icon} outerLayerClassname="menu-item-icon" />
						<span className="menu-item-text">{item?.label}</span>
					</div>
				);
			})}
		</div>
	);
};

const HeaderFilter: React.FC<{}> = (props) => {
	const dispatch = useAppDispatch();
	const navigate = useNavigate();
	const searchParams = useLocation();
	const handleExit = useCallback(() => {
		dispatch(loginOutAction(() => navigate("/login")));
	}, []);

	const handleJumpPersonal = useCallback(() => {
		navigate(`/personal${searchParams.search}`);
	}, [searchParams]);

	return (
		<div className={style["layout-header-filter"]}>
			<FleetInput value="" disabled className="filter-input" prefix="LayoutSearchIcon" />
			<Popover
				render={(setIsOpen) => (
					<HeaderMenu
						onClose={() => {
							setIsOpen(false);
							handleJumpPersonal();
						}}
						onExit={handleExit}
					/>
				)}
			>
				<div className="filter-avatar">
					<span>F</span>
				</div>
			</Popover>
		</div>
	);
};

export default HeaderFilter;
