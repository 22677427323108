import { useState } from "react";
import { MovementTctcDialogItemKeys } from "../type";

const useIndex = () => {
	const [dataSource, setDataSource] = useState<Record<MovementTctcDialogItemKeys, boolean>>({
		bunkerPlan: true,
		offHire: true,
		otherExpenses: true,
		otherIncome: true,
		disputeCharge: true,
		handoverForm: true
	});

	const change = (key: MovementTctcDialogItemKeys, value: boolean) => {
		setDataSource((prev) => ({ ...prev, [key]: value }));
	};

	return {
		dataSource,
		change
	};
};

export default useIndex;
