import React, { forwardRef, useEffect, useImperativeHandle, useMemo, useRef, useState } from "react";
import { Table } from 'antd'
import type { ColumnsType } from 'antd/es/table';
import { FleetLoading, FleetTooltip } from "@/components";
import classnames from "classnames";
import useReminder from "@/hook/useReminder";
import { DetailDrawerItem } from '../..'
import { getRoutePlanAction } from '@/action/monitoring/vessel'
import dayjs from 'dayjs'
import style from './index.module.less'
import { calculateBearing } from '../../../../../wmap'
import { useMonitoringContext } from "@/pages/monitoring/MonitoringContext";
import { RoutePlanDownload } from "./components";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { deleteRoutePlanAction, getRoutePlanLinesAction } from "@/action/monitoring/routePlan";
import { FleetToast } from "@/common";
import { RowSelectMethod } from "antd/es/table/interface";
import { columns } from "./source";
import { RoutePlanItemType, RoutePlanRefType } from "./type";
import { MonitoringDetailMoreTabType } from "../../type";

export type RoutePlanProps = {
  className?: string,
  data: DetailDrawerItem,
  mapRef?: React.MutableRefObject<any>
  onRowClick?: (record: RoutePlanItemType) => void
  activeIndex: MonitoringDetailMoreTabType
}

const getEta = (data, actualCommenceDate) => {
  // // 临时注释 data?.actualCommenceDate
  // return dayjs(((data?.duration || 0) * 60 * 60 * 1000) + (((new Date().getTime() - 8 * 60 * 60 * 1000)))).format('YYYY/MM/DD HH:mm')

  if (data?.eta === undefined) {
    return dayjs(((data?.duration || 0) * 60 * 60 * 1000) + ((((actualCommenceDate || 0) - 8 * 60 * 60 * 1000)))).format('YYYY/MM/DD HH:mm')
  } else {
    return data?.eta ? dayjs(data?.eta).format('YYYY/MM/DD HH:mm') : data?.eta
  }
}
const RoutePlan = forwardRef<RoutePlanRefType, RoutePlanProps>(({ data, className, mapRef, onRowClick, activeIndex }, ref) => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const [download, setDownload] = useState(true)
  const record = useRef<RoutePlanItemType>(null)

  console.log("currentVessel", data)

  useImperativeHandle(ref, () => ({
    onReset: loadData
  }))

  const deleteRoutePlanFront = () => {
    setLoading(true)
  }

  const deleteRoutePlanSuccess = (response) => {
    reminder("success", response?.msg ? (response?.msg + ": " + response?.data) : response?.data);
    setSelectedRows([])
    loadData()
  }

  const deleteRoutePlanFailed = (error) => {
    reminder("error", error?.msg ? (error?.msg + ": " + error?.data) : error?.data);
    setLoading(false);
  }

  const deleteRoutePlan = (id: string) => {
    deleteRoutePlanAction(
      { id },
      deleteRoutePlanFront,
      deleteRoutePlanSuccess,
      deleteRoutePlanFailed,
      dispatch,
      navigate
    )
  }

  const operateColumns: ColumnsType<RoutePlanItemType> = useMemo(() => {
    return [
      {
        title: 'Action',
        fixed: 'right',
        width: 152,
        render: (value, item, index) => {
          return <div className="routePlan-actions">
            <span className="routePlan-detail" onClick={() => onRowClick?.(item)}>Detail</span>
            <span className="font_family fleet-download" onClick={() => {
              record.current = item
              setDownload(false)
            }}></span>
            <FleetTooltip
              outerLayer={<span className="font_family fleet-delete"></span>}
              render={(setIsOpen) => {
                return <FleetToast
                  label="That file will be deleted. Are you sure?"
                  onCancel={() => { setIsOpen(false) }}
                  onConfirm={() => {
                    setIsOpen(false)
                    deleteRoutePlan(item?.id)
                  }}
                />
              }}
            />
            {/* <span className="font_family fleet-delete" onClick={() => setUpload(false)}></span> */}
          </div>
        }
      }
    ]
  }, [])

  const routeObject = useRef<{ [uuid: string]: any }>({})
  const [tableData, setTableData] = useState<RoutePlanItemType[]>([])
  const [selectedRows, setSelectedRows] = useState<RoutePlanItemType[]>([]);
  const [loading, setLoading] = useState<boolean>(false)
  const { reminder } = useReminder();
  const { abortController } = useMonitoringContext()
  const [curretVessel, setCurrentVessel] = useState<DetailDrawerItem>(null)

  useEffect(() => {
    if (activeIndex !== "routePlan") return
    setSelectedRows([])
    setTableData([])
    routeObject.current = {}
    mapRef?.current?.clearRoutePlan()
    if (!data?.voyageNo) return
    loadData()
    // if (data?.voyageNo && curretVessel?.voyageNo !== data?.voyageNo) {
    //   setCurrentVessel(data)
    //   loadData()
    // }
    // else if (data?.voyageNo) {
    //   iniSelect(tableData)
    // }
  }, [data, activeIndex])

  const loadData = () => {
    getRoutePlanAction(
      { voyageNo: data.voyageNo, legType: data.vesselStatus },
      // { voyageNo: "538004675ASL20240206", legType: "Ballast" },
      () => { setLoading(true) },
      (res) => {
        setLoading(false)
        // const _data = (res?.data || []).map((t: RoutePlanDataType, index: number) => ({ ...t, id: `${data.voyageNo}${data.vesselStatus}${index}` }))
        setTableData(res?.data ?? [])

        iniSelect(res?.data ?? [])
      },
      (error) => {
        setLoading(false)
        !error?.isCancel && reminder("error", error?.msg ? (error?.msg + ": " + error?.data) : error?.data)

      },
      dispatch,
      navigate
    )
  }

  const getRoutePlanLinesFront = () => {
    setLoading(true)
  }

  const getRoutePlanLinesFailed = (error) => {
    setLoading(false)
    reminder("error", error?.msg ? (error?.msg + ": " + error?.data) : error?.data)
  }

  const getRoutePlanLines = (id: string, items: RoutePlanItemType[],) => {
    getRoutePlanLinesAction(
      { uuid: id },
      getRoutePlanLinesFront,
      (response) => {
        setLoading(false)
        routeObject.current = {}
        id?.split?.(",")?.map(_id => {
          routeObject.current[_id] = response?.data?.[_id]?.features
        })
        setSelectedRows([...items])
        loadRoutePlan([...items])
      },
      getRoutePlanLinesFailed,
      dispatch,
      navigate
    )
  }

  const iniSelect = (data: RoutePlanItemType[]) => {
    const filters = data?.filter((t: RoutePlanItemType) => t.planType === 'Fleet Insight')
    const [first] = filters?.sort?.((a: RoutePlanItemType, b: RoutePlanItemType) => b.createDate - a.createDate)
    if (first) {
      getRoutePlanLines(first.id, [first])
      // setSelectedRows([first])
      // loadRoutePlan([first], true)
    }
  }

  const rowSelection = {
    selectedRowKeys: selectedRows.map((t: RoutePlanItemType) => t.id),
    onChange: (selectedRowKeys: string[], currentSelectedRows: RoutePlanItemType[], info: { type: RowSelectMethod; }) => {
      // debugger
      switch (true) {
        case info?.type === "all" && currentSelectedRows?.length === 0:
          setSelectedRows([])
          loadRoutePlan([])
          return
        case info?.type === "all" && selectedRows?.length !== currentSelectedRows?.length:
          getRoutePlanLines(selectedRowKeys?.join(","), currentSelectedRows)
          break
        case info?.type === "single" && currentSelectedRows?.length === 0:
          setSelectedRows([])
          loadRoutePlan([])
          break
        case info?.type === "single" && currentSelectedRows?.length !== 0:
          getRoutePlanLines(selectedRowKeys?.join(","), currentSelectedRows)
          break
        default:
          break;
      }
    }
  };

  function interpolateColor(minValue, maxValue, targetValue) {
    const minColor = [139, 251, 63];
    const maxColor = [252, 70, 110];

    const range = maxValue - minValue;
    const offset = targetValue - minValue;

    const color = [
      minColor[0] + offset / range * (maxColor[0] - minColor[0]),
      minColor[1] + offset / range * (maxColor[1] - minColor[1]),
      minColor[2] + offset / range * (maxColor[2] - minColor[2]),
    ];

    return 'rgba(' + color.join(',') + ',1.0)';
  }

  console.log('routePlan', data, curretVessel)

  const loadRoutePlan = (selectedRows: RoutePlanItemType[], isMainRoute: boolean = false) => {
    debugger;
    const geojson_line = {
      "type": "FeatureCollection",
      "features": []
    }
    const geojson_points = {
      "type": "FeatureCollection",
      "features": []
    }
    const loadData = []
    const sortedPlanData = tableData.filter(obj => obj.planType.replaceAll(" ", "").toLowerCase() === 'fleetinsight').sort((a, b) => b?.createDate - a?.createDate)?.[0];
    selectedRows.map((t: RoutePlanItemType) => {
      const currentRouteObject = routeObject?.current?.[t.id]
      const features = currentRouteObject
      if (features?.[0]) {
        let lineType = 'routeplanLine'

        // const totalTime = dayjs(t.eta)?.diff(dayjs(data?.actualCommenceDate || curretVessel?.actualCommenceDate), "hour")
        const totalTime = dayjs(t.endTime)?.diff(dayjs(t.startTime), "hour")
        const voyageTime = dayjs(t.endTime)?.diff(dayjs(t.startTime), "hour")
        features[0].properties = Object.assign(features[0].properties, {
          planType: t.planType,
          totalDistance: t.ttlDist,
          totalTime: totalTime,
          coors: features[0].geometry.coordinates,
          cpSpeed: (!t?.cpSpeed || t?.cpSpeed === 0) ? (t.ttlDist / totalTime) : t.cpSpeed,
          // startTime: data?.actualCommenceDate || curretVessel?.actualCommenceDate,
          startTime: t.startTime,
          eta: getEta(t, data?.actualCommenceDate || curretVessel?.actualCommenceDate),
          // interpolateColor: interpolateColor(0, sortedTotalTime.length, sortedTotalTime.indexOf(t.totalTime))
        })
        if (t.id === sortedPlanData?.id || isMainRoute) {
          lineType = 'mainRouteplanLine'

          // 为了实现交错线段的样式，线段多添加一次
          const routeFeature1 = JSON.parse(JSON.stringify(features?.[0]))
          routeFeature1.properties.type = 'mainRouteplanLine1'
          geojson_line.features.push(routeFeature1)
        }
        features[0].properties.type = lineType
        loadData.push(features[0].properties)
        geojson_line.features.unshift(features?.[0])

        if (features[0].geometry.coordinates?.[0]?.length > 2) {
          const point0 = features[0].geometry.coordinates?.[0]?.[0]
          const point1 = features[0].geometry.coordinates?.[0]?.[0]

          const route_start_point = {
            "type": "Feature",
            "geometry": {
              "type": "Point",
              "coordinates": point0
            },
            "properties": {
              "type": "routePlanVesselIcon",
              "cog": calculateBearing(point0[1], point0[0], point1[1], point1[0])
            }
          }
          geojson_points.features.push(route_start_point)
        }
      }
    })
    console.log("geojson_line", geojson_line, geojson_points, loadData)
    mapRef?.current?.loadRoutePlan(geojson_line, geojson_points, loadData)
    // mapRef?.current?.loadVoyageOptions({
    //   start: 
    // })
  }

  return <div className={classnames([style.routePlan, activeIndex === "routePlan" && style['active']])}>
    <FleetLoading loading={loading} />
    <Table
      className={style.table}
      rowKey={(record) => record?.["id"]}
      rowSelection={{ type: 'checkbox', ...rowSelection }}
      columns={[...columns, ...operateColumns]}
      dataSource={tableData}
      pagination={false}
      scroll={{ x: 1200, y: 'calc(100% - 36px)' }}
    />
    <RoutePlanDownload
      hide={download}
      onClose={() => setDownload(true)}
      currentRecord={record?.current}
    />
  </div >
})

export default RoutePlan
