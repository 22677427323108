import React, { FC, forwardRef, useImperativeHandle } from "react";
import style from "./index.module.less";
import { RouteCommonAreaVesselProps, RouteCommonAreaVesselRefType } from "./type";
import { AreaVesselFilter, AreaVesselList } from "./components";
import useIndex from "./hooks/useIndex";
import { FleetLoading } from "@/components";
import classnames from "classnames";

const RouteCommonAreaVessel = forwardRef<RouteCommonAreaVesselRefType, RouteCommonAreaVesselProps>(
	({ onPortCallOpen, onVesselLoad, onVesselSelect }, ref) => {
		const {
			loading,
			filters,
			agxMaxInputRef,
			aisSpeedMinInputRef,
			aisSpeedMaxInputRef,
			dwtMinInputRef,
			dwtMaxInputRef,
			handleCommit,
			handeSearch,
			timeRange,
			handleTimeRangeChange,
			dataSource,
			handleReset,
			selectRows,
			handleRowSelect,
			handleDownload,
			activeDetailsOption
		} = useIndex(onVesselLoad);

		useImperativeHandle(ref, () => {
			return {
				onAreaVesselReset: handleReset
			};
		});
		return (
			<div
				className={classnames(style["routeCommon-areaVessel"], {
					"routeCommon-details--active":
						activeDetailsOption.activeTab === "areaVessel" &&
						!activeDetailsOption?.isPortCallVisible
				})}
			>
				<FleetLoading loading={loading} />
				<AreaVesselFilter
					item={filters}
					agxMaxInputRef={agxMaxInputRef}
					dwtMaxInputRef={dwtMaxInputRef}
					dwtMinInputRef={dwtMinInputRef}
					aisSpeedMaxInputRef={aisSpeedMaxInputRef}
					aisSpeedMinInputRef={aisSpeedMinInputRef}
					onCommit={handleCommit}
					onSearch={handeSearch}
					timeRange={timeRange}
					onTimeRangeCommit={handleTimeRangeChange}
				/>
				<AreaVesselList
					items={dataSource}
					selectRows={selectRows}
					onSelect={handleRowSelect}
					onDownload={handleDownload}
					onPortCallOpen={onPortCallOpen}
					onVesselSelect={onVesselSelect}
				/>
			</div>
		);
	}
);

export default RouteCommonAreaVessel;
