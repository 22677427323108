import { matchPortType } from "@/tools/port";
import { EstPortRotationItemType } from "./components";

export const getPortCodes = (portItems: EstPortRotationItemType[], initDeliveryPort?: string): string => {
  let portCodes = "";
  if(initDeliveryPort) {
    portCodes += initDeliveryPort
  }
  for(let i = 0;i<portItems?.length;i++) {
    if(i == 0 && !initDeliveryPort) {
      portCodes += portItems?.[i]?.portCode
      continue
    }
    if(portItems?.[i]?.portCode) {
      portCodes += `_${portItems?.[i]?.portCode}`
    }
  }
  return portCodes
}

export const getCurrentIndex = (portItems: EstPortRotationItemType[], type: "ballast" | "laden" | "delivery") => {
  const lastIndex = portItems?.findLastIndex(item => matchPortType(item?.purpose) === type);
  switch (true) {
    case type === "ballast" && lastIndex === -1:
      return 0;
    case type === "laden" && lastIndex === -1:
      return portItems?.length - 1;
    case type === "delivery":
      return portItems?.findIndex(item => item?.purpose === "DL")
    default:
      return lastIndex
  }
}