import React, { useEffect, useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";
import debounce from "lodash/debounce";
import useCheckGroup, {
  type CheckGroupChangeEvent,
} from "@/hook/common/useCheckGroup";
import { CommonRoleWrap, FleetToast } from "@/common";
import { FleetButton, FleetTooltip } from "@/components";
import { useAppDispatch } from "@/hook";
import useReminder from "@/hook/useReminder";

import {
  CBCategoryGroupType,
  CBCategoryKeys,
  CBGroupColumnKeys,
  CBGroupOptionItemKeys,
  initCBCategoryGroup,
  columns as list_columns,
} from "@/types/voyageManage/cargoBook/list";
import { Dayjs } from "dayjs";
import {
  delCargoBookAction,
  getCargoBookGroupAction,
} from "@/action/voyageManage/cargoBook";
import { Popover } from "@/_components";
import classnames from "classnames";

const useGroup = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { reminder } = useReminder();
  const [hide, setHide] = useState(true);
  const [editHide, setEditHide] = useState(true);
  const [recordId, setRecordId] = useState(null);
  const [dataSource, setDataSource] = useState([]);
  const [total, setToal] = useState<number>(0);
  const [selectedRowKeys, setSelectedRowKeys] = useState<number[]>([]);
  const [ids, setIds] = useState<string>("");
  const [pageNum, setPageNum] = useState<number>(1);
  const [pageSize, setPageSize] = useState<number>(20);
  const [search, setSearch] = useState("");
  const [loading, setLoading] = useState(false);
  const [layCan, setLayCan] = useState<[Dayjs | undefined, Dayjs | undefined]>([
    undefined,
    undefined,
  ]);
  const {
    checkGroup,
    reset: handleReset,
    change: handleChange,
  } = useCheckGroup<CBCategoryGroupType, CBCategoryKeys, CBGroupOptionItemKeys>(
    initCBCategoryGroup
  );

  const delCbMultiple = (type: "single" | "all", id?: number) => {
    delCargoBookAction(
      type === "single" ? [id] : selectedRowKeys,
      getCbGroupFront,
      delCbResultSuccess,
      getCbGroupError,
      dispatch,
      navigate
    );
  };

  const delCbResultSuccess = (response) => {
    reminder("success", response?.msg);
    setIds("");
    setSelectedRowKeys([]);
    getCBGroup();
  };

  const getCbGroupFront = () => {
    setLoading(true);
  };

  const getCbGroupError = (error) => {
    reminder(
      "error",
      error?.msg ? error?.msg + ": " + error?.data : error?.data
    );
    setLoading(false);
  };

  const columns = useMemo(() => {
    let column_keys = [];
    Object.values(checkGroup).forEach((item) => {
      column_keys.push(...item);
    });
    let _columns = list_columns.filter((column) =>
      column_keys.includes(column.key)
    );

    _columns.push({
      dataIndex: "option",
      width: 85,
      title: "Option",
      key: "option",
      align: "center",
      fixed: "right",
      render: (value, record, index) => {
        return (
          <div className="td-operate">
            <CommonRoleWrap roleStr="cargobook:list:delete">
              <Popover
                disabled={!!record?.voyageId}
                // iconProps={{
                //   type: "FleetEslistDeleteIcon",
                //   outerLayerClassname: "operate-icon"
                // }}
                render={(setIsOpen) => {
                  return (
                    <FleetToast
                      label="Are you sure delete this item?"
                      onCancel={() => {
                        setIsOpen(false);
                      }}
                      onConfirm={() => {
                        delCbMultiple("single", record?.id);
                        setIsOpen(false);
                      }}
                    />
                  );
                }}
              >
                <span className={classnames("font_family fleet-delete", {
                  'fleet-disabled': !!record?.voyageId
                })} style={{ color: 'red' }}></span>
              </Popover>
            </CommonRoleWrap>
          </div>
        );
      },
    });
    return _columns;
  }, [checkGroup]);

  const onSelectChange = (newSelectedRowKeys: number[]) => {
    setSelectedRowKeys(newSelectedRowKeys);
    setIds(newSelectedRowKeys?.join(","));
  };

  const getCBGroupFront = () => {
    setLoading(true);
  };

  const getCBSuccess = (response) => {
    if (!Array.isArray(response?.data)) {
      setDataSource(response?.data?.items);
      setToal(response?.data?.totalCount);
    } else {
      setDataSource([]);
      setToal(0);
    }
    setPageNum(1);
    setLoading(false);
  };

  const onPageChangeSuccess = (response) => {
    if (!Array.isArray(response?.data)) {
      setDataSource((val) => [...val, ...response?.data?.items]);
    }
    setLoading(false);
  };

  const getCBError = (error) => {
    reminder(
      "error",
      error?.msg ? error?.msg + ": " + error?.data : error?.data
    );
    setLoading(false);
  };

  const getCBGroup = (searchVal?: string) => {
    getCargoBookGroupAction(
      {
        pageNum: 1,
        pageSize,
        keyword: searchVal ?? search,
        layCanFrom: layCan?.[0]?.valueOf(),
        layCanTo: layCan?.[1]?.valueOf(),
      },
      getCBGroupFront,
      getCBSuccess,
      getCBError,
      dispatch,
      navigate
    );
  };

  const onPageChange = (page: number, size: number) => {
    setPageNum(page);
    if (dataSource?.length >= total) return;
    if (page * size <= dataSource?.length) return;
    getCargoBookGroupAction(
      {
        pageNum: page,
        pageSize,
        keyword: search,
        layCanFrom: layCan?.[0]?.valueOf(),
        layCanTo: layCan?.[1]?.valueOf(),
      },
      getCBGroupFront,
      onPageChangeSuccess,
      getCBError,
      dispatch,
      navigate
    );
  };

  const onSearch = useMemo(() => {
    const loadOptions = (val: string) => {
      setDataSource([]);
      getCBGroup(val);
    };
    return debounce(loadOptions, 500);
  }, [search, layCan]);

  const onRow = (record) => {
    return {
      onDoubleClick: () => {
        setRecordId(record?.id);
        setEditHide(false);
      },
    };
  };

  useEffect(() => {
    getCBGroup();
  }, [search, layCan]);

  return {
    search,
    checkGroup,
    layCan,
    ids,
    selectedRowKeys,
    pageNum,
    pageSize,
    columns,
    dataSource,
    loading,
    total,
    hide,
    recordId,
    editHide,
    setHide,
    setEditHide,
    onLayCanChange: setLayCan,
    handleReset,
    handleChange,
    onPageChange,
    onSelectChange,
    onKeywordChange: setSearch,
    onIdsChange: setIds,
    onSearch,
    onDeleteItems: delCbMultiple,
    onReset: getCBGroup,
    onRow,
  };
};

export default useGroup;
