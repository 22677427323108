import { useEffect, useMemo, useRef, useState } from "react";
import { RouteCommonPortCallItemType } from "../type";
import { useAppDispatch } from "@/hook";
import { useNavigate } from "react-router-dom";
import useReminder from "@/hook/useReminder";
import { onRequest } from "@/action";
import { useRouteCommonContext } from "@/pages/routeCommon/store";

const useIndex = () => {
	const dispatch = useAppDispatch();
	const navigate = useNavigate();
	const { reminder } = useReminder();
	const [loading, setLoading] = useState(false);
	const { activeDetailsOption, onDetailsOptionCommit } = useRouteCommonContext();

	const [pager, setPager] = useState<{
		total: number;
		pageNum: number;
		pageSize: number;
	}>({ total: 0, pageNum: 1, pageSize: 20 });

	const cacheSource = useRef<{ [index: number]: RouteCommonPortCallItemType[] }>({});

	const dataSource = useMemo(() => {
		return cacheSource.current[pager.pageNum];
	}, [pager.pageNum, pager.total]);

	const [selectRows, setSelectRows] = useState<RouteCommonPortCallItemType["ata"][]>([]);

	const handleRowSelect = (vals: RouteCommonPortCallItemType["ata"][]) => {
		setSelectRows(vals);
	};

	const getPortCallListFront = () => {
		setLoading(true);
		setSelectRows([]);
	};

	const onPageChangeFront = () => {
		setLoading(true);
	};

	const getPortCallListSuccess = (response) => {
		setLoading(false);
		let total = 0;
		switch (true) {
			case !Array.isArray(response?.data):
				// setDataSource(response?.data?.items);
				cacheSource.current[1] = response?.data?.dataList;
				total = response?.data?.totalCount;
				break;
			default:
				// setDataSource([]);
				cacheSource.current = {};
				break;
		}
		setPager((prev) => ({ ...prev, total, pageNum: 1 }));
		setLoading(false);
	};

	const getPortCallListFaild = (error) => {
		console.log("error", error);
		setLoading(false);
		reminder("error", error?.msg ? error?.msg + ": " + error?.data : error?.data);
	};

	const downloadRouteCommonPortCallSuccess = (response) => {
		reminder("success", "Download Successfully!");
		setLoading(false);
	};

	const getPortCallList = () => {
		if (!activeDetailsOption?.currentImo) return;
		onRequest(
			"getRouteCommonPortCallListApi",
			{
				imo: activeDetailsOption?.currentImo,
				pageNum: 1,
				pageSize: pager.pageSize
			},
			getPortCallListFront,
			getPortCallListSuccess,
			getPortCallListFaild,
			dispatch,
			navigate
		);
	};

	const onPageChange = (page: number, size: number) => {
		if (dataSource?.length >= pager.total) return;
		// if (page * size <= dataSource?.length) return;
		if (cacheSource.current[page]) {
			setPager((prev) => ({ ...prev, pageNum: page }));
			return;
		}
		onRequest(
			"getRouteCommonPortCallListApi",
			{
				imo: activeDetailsOption?.currentImo,
				pageNum: page,
				pageSize: pager.pageSize
			},
			onPageChangeFront,
			(response) => {
				if (!Array.isArray(response?.data)) {
					cacheSource.current[page] = response?.data?.dataList;
				} else {
					cacheSource.current[page] = [];
				}
				setPager((prev) => ({ ...prev, pageNum: page }));
				setLoading(false);
			},
			getPortCallListFaild,
			dispatch,
			navigate
		);
	};

	const handleBack = () => {
		onDetailsOptionCommit?.({ isPortCallVisible: false, currentImo: null });
	};

	const handleDownload = () => {
		if (selectRows?.length <= 0) return;
		debugger;
		const totalItems = Object.values(cacheSource?.current)?.flat();
		const selectItems = totalItems?.filter((selectItem) => selectRows?.includes(selectItem?.ata));
		onRequest(
			"downloadRouteCommonPortCallApi",
			selectItems,
			onPageChangeFront,
			downloadRouteCommonPortCallSuccess,
			getPortCallListFaild,
			dispatch,
			navigate
		);
	};

	useEffect(() => {
		if (!activeDetailsOption?.isPortCallVisible) return;
		getPortCallList();
	}, [activeDetailsOption]);

	return {
		loading,
		dataSource,
		selectRows,
		handleRowSelect,
		pager,
		onPageChange,
		activeDetailsOption,
		handleBack,
		handleDownload
	};
};

export default useIndex;
