import { ApiParameter, RequestApiMapType } from "@/api";
import request from "@/service";

import {
	MovementOtherIncomeParamsType,
	getMovementOtherIncomeParams,
	saveMovementOtherIncomeParams
} from "./type";
import { appVersion } from "@/api/constant";

const enum ApiMap {
	getMovementOtherIncomeParameter,
	saveMovementOtherIncomeParameter
}

export type ApiUrl = keyof typeof ApiMap;

const otherIncomeApi: ApiParameter<ApiUrl> = {
	getMovementOtherIncomeParameter: {
		method: "get",
		url: appVersion + "/move/dialog/other/income/list"
	},
	saveMovementOtherIncomeParameter: {
		method: "put",
		url: appVersion + "/move/dialog/other/income"
	}
};

export const getMovementOtherIncomeApi = (params: getMovementOtherIncomeParams) => {
	return request.request({
		...otherIncomeApi.getMovementOtherIncomeParameter,
		url: `${otherIncomeApi.getMovementOtherIncomeParameter.url}/${params?.id}`
	});
};

export const saveMovementOtherIncomeApi = (params: saveMovementOtherIncomeParams) => {
	return request.request({
		...otherIncomeApi.saveMovementOtherIncomeParameter,
		data: params
	});
};

export const otherIncomeApiMap: RequestApiMapType<ApiUrl, MovementOtherIncomeParamsType> = {
	getMovementOtherIncomeApi,
	saveMovementOtherIncomeApi
};
