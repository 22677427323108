import React, { FC } from "react";
import style from "./index.module.less";
import { CommonInfinityScrollProps } from "./type";
import classnames from "classnames";
import useInfinityScroll from "@/hook/common/useInfiniteScroll";
import { FleetLoading } from "@/components";

const CommonInfinityScroll: FC<CommonInfinityScrollProps> = ({
	outerClassName,
	scrollClassName,
	onLoadMore,
	hasMore,
	children
}) => {
	const { scrollRef } = useInfinityScroll(hasMore, onLoadMore);
	return (
		<div className={classnames(style["common-infinityScroll"], outerClassName)} ref={scrollRef}>
			<div className={classnames("infinityScroll-scrollArea", scrollClassName)}>{children}</div>
		</div>
	);
};

export default CommonInfinityScroll;
