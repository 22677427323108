import { RcFile } from "antd/es/upload";
import { useEffect, useRef, useState } from "react";
import { VoyageManageAttachUploadProps } from "../type";

const useIndex = (
	hide: VoyageManageAttachUploadProps["hide"],
	onConfirm: VoyageManageAttachUploadProps["onConfirm"]
) => {
	const [fileList, setFileList] = useState<RcFile[]>([]);

	const uploadStatus = useRef<"uploading" | "static">("static");

	const handleUpload = (files: RcFile[]) => {
		console.log("files", files);
		uploadStatus.current = "uploading";
		setFileList((prev) => [...prev, ...files]);
		requestIdleCallback(() => {
			uploadStatus.current = "static";
		});
	};

	const handleDelete = (item: RcFile) => {
		setFileList((prev) => {
			const currentIndex = prev?.findIndex((selectItem) => selectItem.uid === item?.uid);
			prev?.splice(currentIndex, 1);
			return [...prev];
		});
	};

	const handleConfirm = () => {
		onConfirm?.(fileList);
	};

	useEffect(() => {
		if (hide) {
			setFileList([]);
		}
	}, [hide]);

	return {
		fileList,
		uploadStatus,
		handleUpload,
		handleDelete,
		handleConfirm
	};
};

export default useIndex;
