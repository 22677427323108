import React, { FC } from "react";
import style from "./index.module.less";
import popoverStyle from "../../index.module.less";
import { RouteCommonVesselCardHeaderProps } from "./type";
import { Icon, Popover } from "@/_components";
import classnames from "classnames";

const RouteCommonVesselCardHeader: FC<RouteCommonVesselCardHeaderProps> = ({
	item,
	mode,
	onClose
}) => {
	return (
		<div className={style["routeCommon-vesselCard-header"]}>
			<div className="header-warpper">
				<Popover
					trigger="hover"
					placement="bottom"
					overlayClassName={popoverStyle["routeCommon-vesselCard--popover"]}
					content={<span className="vesselCard-title">{item?.vesselName}</span>}
					children={
						<span className={classnames("header-vesselName", mode)}>{item?.vesselName}</span>
					}
				/>
				<span className={classnames("header-tag", mode)}>{mode?.toUpperCase()}</span>
			</div>
			<Icon
				type="fleet-close"
				className="header-close"
				onClick={(event) => {
					event?.stopPropagation();
					onClose?.(mode);
				}}
			/>
		</div>
	);
};

export default RouteCommonVesselCardHeader;
