import request from '@/service';
import { savePersonalInfoParams,changePasswordParams,changeAvatarParams } from "./type";
import {
  type ApiParameter,
} from "@/api";

const enum ApiMap {
  getPersonalInfo,
  savePersonalInfo,
  changePassword,
  changeAvatar
}

type ApiUrl = keyof typeof ApiMap;

const personCenterApi: ApiParameter<ApiUrl> = {
  getPersonalInfo: {
    method: "get",
    url:'/gateway/sys/system/user/profile'
  },
  savePersonalInfo: {
    method: "put",
    url:'/gateway/sys/system/user/profile'
  },
  changePassword: {
    method: "put",
    url:'/gateway/sys/system/user/profile/updatePwd'
  },
  changeAvatar: {
    method: "post",
    url:'/gateway/sys/system/user/profile/avatar'   
  }
};
export const savePersonalInfoApi = (data: savePersonalInfoParams) => {
  return request.request({
    ...personCenterApi.savePersonalInfo,
    data
  })
}
export const changePasswordApi = (params: changePasswordParams) => {
  return request.request({
    ...personCenterApi.changePassword,
    params
  })
}
export const getPersonalInfoApi = () => {
  return request.request({
    ...personCenterApi.getPersonalInfo
  })
}
export const changeAvatarApi = (data:FormData) => {
  return request.request({
    ...personCenterApi.changeAvatar,
    data,
    headers:{
      'Content-Type':'multipart/form-data'
    }
  })
}