import { CommonInitItemEvent } from "@/types/event";
import { MovementOffHireItemType } from "./type";

export enum OffHireRentMap {
	reletOffHire = "Relet",
	rentOffHire = "Rent"
}

export const initialOffHireItem: CommonInitItemEvent<MovementOffHireItemType> = (item) => {
	return {
		voyageId: item?.voyageId ?? null,
		startDatetime: item?.startDatetime ?? null,
		endDatetime: item?.endDatetime ?? null,
		duration: item?.duration ?? "",
		offHireFo: item?.offHireFo ?? 0,
		offHireDo: item?.offHireDo ?? 0,
		remark: item?.remark ?? ""
	};
};

export const tctcTabs: { key: keyof typeof OffHireRentMap; label: "Charter In" | "Relet" }[] = [
	{ key: "rentOffHire", label: "Charter In" },
	{ key: "reletOffHire", label: "Relet" }
];
