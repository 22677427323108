import { useState } from "react"
import { MovementDialogItemKeys } from "../../../type";

const useIndex = () => {
  const [dataSource, setDataSource] = useState<Record<MovementDialogItemKeys, boolean>>({
    bunkerPlan: true,
    stowagePlan: true,
    offHire: true,
    otherExpenses: true,
    otherIncome: true,
    disputeCharge: true,
    portExpenses: true,
    seaReports: true,
    portLog: true,
    handoverForm: true,
    layTime: true,
    otherReports:true
  })

  const change = (key: MovementDialogItemKeys, value: boolean) => {
    // debugger
    setDataSource(prev => ({ ...prev, [key]: value }))
  }

  return {
    dataSource,
    change
  }
}

export default useIndex