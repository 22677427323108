import { useEffect, useRef, useState } from "react"
import { useAppDispatch } from "@/hook";
import { useNavigate } from "react-router-dom";
import useReminder from "@/hook/useReminder";
import { getPortDistanceAction } from "@/action/estimation/cargoPort";
import { range } from "@/tools";
import { getCurrentIndex, getPortCodes } from "@/pages/estimation/tools";
import { EstPortRotationItemType } from "@/pages/estimation/components";

const useCalcDistance = (
  onPortItemCommit?: (
    options: {
      distance: any,
      ecaDistance: any,
      indexList: number[]
    }
  ) => void
) => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { reminder } = useReminder()
  const [loading, setLoading] = useState(false)
  const [refresh, setRefResh] = useState(-1);
  const options = useRef<{ indexList: number[], portCodes: string }>({
    indexList: [],
    portCodes: ""
  })

  const handleChangeCalcDistance = (
    currentIndex: number,
    portItems: EstPortRotationItemType[],
    callback?: () => void
  ) => {
    if (portItems?.length <= 1) {
      callback?.()
      return
    }
    const indexList: number[] = []
    let portCodes = getPortCodes(portItems)
    const nextPortItem = portItems?.[currentIndex + 1]
    switch (true) {
      case currentIndex === 0:
        if (nextPortItem && nextPortItem?.portCode) {
          indexList?.push(currentIndex + 1)
        }
        break
      case currentIndex === portItems?.length - 1:
        indexList.push(currentIndex);
        break
      default:
        indexList.push(currentIndex);
        if (nextPortItem && nextPortItem?.portCode) {
          indexList.push(currentIndex + 1);
        }
        break
    }
    options.current = {
      indexList,
      portCodes
    }
    callback?.()
    setRefResh(prev => prev + 1)
  }

  const handleDeleteCalcDistance = (
    currentIndex: number,
    portItems: EstPortRotationItemType[],
    callback?: () => void
  ) => {
    const indexList: number[] = []
    const ballastIndex = getCurrentIndex(portItems, "ballast"),
      ladenIndex = getCurrentIndex(portItems, "laden"),
      deliveryIndex = getCurrentIndex(portItems, "delivery");
    switch (true) {
      case deliveryIndex === -1 && ballastIndex <= 1 && ladenIndex <= 1:
        reminder("info", "At least one or more Ballast or Laden type ports");
        return
      case currentIndex === portItems?.length - 1:
        callback?.()
        return
      default:
        const prevPortItem = portItems?.[currentIndex - 1]
        if (prevPortItem && prevPortItem?.portCode) {
          indexList.push(currentIndex);
        }
        break
    }
    portItems?.splice(currentIndex, 1)
    let portCodes = getPortCodes(portItems)
    options.current = {
      indexList,
      portCodes
    }
    callback?.()
    setRefResh(prev => prev + 1)
  }

  const getPortDistanceFront = () => {
    setLoading(true)
  }

  const getPortDistanceSuccess = ({ data }) => {
    console.log("data", data, options?.current)
    const { distance, path, ports: portGather, ecaDistance } = data;
    if (!data || data?.length === 0) {
      return
    }
    onPortItemCommit?.({
      distance: [0, ...distance],
      ecaDistance: [0, ...ecaDistance],
      indexList: options?.current?.indexList
    })
    options.current = {
      indexList: [],
      portCodes: ""
    }
    setLoading(false)
  }

  const getPortDistanceFaild = (error) => {
    reminder("error", error?.msg)
    setLoading(false)
  }

  const handleCalcDistanceInit = (
    portItems: EstPortRotationItemType[]
  ) => {
    if (portItems?.length <= 1) return
    const indexList: number[] = range(0, portItems?.length - 1, 1);
    const portCodes = getPortCodes(portItems);
    options.current = {
      indexList: indexList,
      portCodes: portCodes
    }
    setRefResh(prev => prev + 1)
  }

  const getPortDistance = () => {
    getPortDistanceAction(
      { ports: options?.current?.portCodes },
      getPortDistanceFront,
      getPortDistanceSuccess,
      getPortDistanceFaild,
      dispatch,
      navigate
    )
  }

  useEffect(() => {
    if (refresh === -1) return;
    getPortDistance()
  }, [refresh])

  return {
    loading,
    refresh,
    setRefResh,
    handleChangeCalcDistance,
    handleDeleteCalcDistance,
    handleCalcDistanceInit
  }
}

export default useCalcDistance