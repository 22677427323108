import React, { useEffect, useMemo } from "react";
import style from "./index.module.less";
import { BunkerLogPropsType, TableCellType } from "./type";
import {} from "@/common";
import { FleetInput } from "@/components";
import useIndex from "./hook/useIndex";
import { bunkerLogTableConfig, BunkerLogKeys } from "./source";
import { FleetTimePicker } from "@/components/DatePicker";
import dayjs from "dayjs";
const BunkerLog: React.FC<BunkerLogPropsType> = ({
  bunkerLogData,
  onChange,
  onAdd,
  onDelete,
}) => {
  const {
    bunkerLogTableDisabled,
    onMouse,
    handleAdd,
    handleDelete,
    cellValue,
    first,
    setFirst,
    setBunkerLogTableDisabled,
  } = useIndex(onAdd, onDelete, bunkerLogData);
  useEffect(() => {
    if (bunkerLogData.length != 0 && first) {
      const rows = [];
      bunkerLogData.map((item, index) => {
        rows.push({});
        for (let [k, v] of Object.entries(item)) {
          rows[index][k] = true;
        }
      });
      setFirst(false);
      setBunkerLogTableDisabled(rows);
    }
  }, [bunkerLogData]);
  // 根据分级表头构造实际需要的一维表头prop
  const BunkerLogTableProp = useMemo<TableCellType[]>(() => {
    const result = [];
    bunkerLogTableConfig.map((item) => {
      item.row.map((ele) => {
        if (!["daily", "rob", "action"].includes(ele.prop)) result.push(ele);
      });
    });
    result.push(bunkerLogTableConfig[0].row[3]);
    console.log(result);
    return result;
  }, [bunkerLogTableConfig]);

  const totalSum = useMemo(() => {
    const sums = {};
    BunkerLogTableProp.forEach((item) => {
      if (item.prop.indexOf("dailyBunker") != -1) {
        sums[item.prop] = bunkerLogData
          ?.reduce((acc, ele) => acc + Number(ele[item.prop]), 0)
          .toFixed(2)
          .replace(/(\d)(?=(\d{3})+\.)/g, "$1,");
      }
      if (item.prop.indexOf("bunkerRob") != -1) {
        const sum = bunkerLogData[bunkerLogData.length - 1]?.[item.prop]
        sums[item.prop] = sum? Number(sum).toFixed(2).replace(/(\d)(?=(\d{3})+\.)/g, "$1,"):'0.00'
      }
    });
    return sums;
  }, [bunkerLogData]);

  return (
    <div className={style["portReport-bunkerLog"]}>
      <div className="port-report-title">BUNKER LOG</div>
      <div className="bunker-log-table">
        <table cellSpacing={0}>
          <thead className="bunker-log-table-thead">
            {bunkerLogTableConfig.map((item, index) => {
              return (
                <tr key={index}>
                  {item.row.map((ele) => {
                    return (
                      <th
                        colSpan={ele.colSpan}
                        rowSpan={ele.rowSpan}
                        className="bunker-log-table-header"
                        key={ele.prop}
                      >
                        {ele.label}
                      </th>
                    );
                  })}
                </tr>
              );
            })}
          </thead>
          <tbody className="bunker-log-table-tbody">
            {bunkerLogData?.map((row, index) => {
              return (
                <tr key={index}>
                  {BunkerLogTableProp.map((item, idx) => {
                    return (
                      <td colSpan={item.colSpan} key={idx}>
                        <div
                          onMouseEnter={() =>
                            onMouse(index, item.prop, "enter")
                          }
                          onMouseLeave={() =>
                            onMouse(index, item.prop, "leave")
                          }
                        >
                          {!bunkerLogTableDisabled[index]?.[item.prop] &&
                          !["action"].includes(item.prop) ? (
                            ["dateTime"].includes(item.prop) ? (
                              <FleetTimePicker
                                allowClear
                                value={dayjs(row?.[item.prop]) || null}
                                onChange={(val: string) => {
                                  onChange?.(
                                    dayjs(val).valueOf(),
                                    index,
                                    item.prop
                                  );
                                }}
                              ></FleetTimePicker>
                            ) : (
                              <FleetInput
                                value={row?.[item.prop] || ""}
                                onChange={(val) =>
                                  onChange(val, index, item.prop)
                                }
                              ></FleetInput>
                            )
                          ) : item.prop == "action" ? (
                            <div className="bunker-log-table-action">
                              <span
                                className="font_family fleet-delete"
                                onClick={() => handleDelete(index)}
                              ></span>
                            </div>
                          ) : (
                            cellValue(row, item.prop) || "--"
                          )}
                        </div>
                      </td>
                    );
                  })}
                </tr>
              );
            })}
            {/* <tr>
              <td colSpan={11}>
                <span className="port-report-add" onClick={handleAdd}>
                  + ADD
                </span>
              </td>
            </tr> */}
            <tr></tr>
          </tbody>
          <tfoot className="bunker-log-table-tfoot">
            <tr>
              {BunkerLogTableProp.map((item) => {
                if (item.prop == "action") {
                  return <td key={item.prop} colSpan={item.colSpan}></td>;
                } else if (item.prop == "dateTime") {
                  return (
                    <td key={item.prop} colSpan={item.colSpan}>
                      Total
                    </td>
                  );
                } else {
                  return (
                    <td key={item.prop} colSpan={item.colSpan}>
                      {totalSum[item.prop]}
                    </td>
                  );
                }
              })}
            </tr>
          </tfoot>
        </table>
      </div>
    </div>
  );
};

export default BunkerLog;
