import React, { useEffect, useMemo, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import debounce from "lodash/debounce";
import { CommonRoleWrap, FleetToast } from "@/common";
import { FleetTooltip } from "@/components";
import { useAppDispatch } from "@/hook";
import useReminder from "@/hook/useReminder";
import {
  DatabaseVesselFormType,
  calcuateVesselKeyType,
  calcuateVesselKeys,
  columns as list_columns,
} from "@/pages/database/business/vessel/type";
import {
  getVesselGroupAction,
  deleteVesselAction,
} from "@/action/dataBase/vessel";
import { DatabaseVesselTabChangeEvnet } from "@/featrue/database/vessel/filter/type";
import { Popover } from "@/_components";

const useGroup = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { reminder } = useReminder();
  const record = useRef<DatabaseVesselFormType>(null);
  const cacheSource = useRef<{ [index: number]: any[] }>({});
  const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([]);
  const search = useRef<string>("");
  const [loading, setLoading] = useState(false);
  const [pager, setPager] = useState<{
    total: number;
    pageNum: number;
    pageSize: number;
  }>({ total: 0, pageNum: 0, pageSize: 20 });

  const [active, setActive] =
    useState<Parameters<DatabaseVesselTabChangeEvnet>[0]>("basic");

  const [hideMap, setHide] = useState({
    add: true,
    edit: true,
  });

  const dataSource = useMemo(() => {
    return cacheSource.current[pager.pageNum];
  }, [pager.pageNum, pager?.total]);

  const delVesselMultiple = (type: "multiple" | "single", id?: string) => {
    deleteVesselAction(
      { id: type === "multiple" ? selectedRowKeys.join(",") : id },
      getVesselGroupFront,
      delVesselResultSuccess,
      getVesselGroupError,
      dispatch,
      navigate
    );
  };

  const delVesselResultSuccess = (response) => {
		reminder("success", response?.msg);
		setSelectedRowKeys([]);
		console.log("delVesselResultSuccess", search);
		getVesselGroup();
	};

	const getVesselGroupError = (error) => {
		reminder("error", error?.msg ? error?.msg + ": " + error?.data : error?.data);
		setLoading(false);
	};

	const columns = useMemo(() => {
		const _columns = [...list_columns];
		_columns.push({
			dataIndex: "option",
			width: 85,
			title: "Action",
			key: "option",
			align: "center",
			fixed: "right",
			render: (value, record, index) => {
				return (
					<div className="td-operate">
						<CommonRoleWrap roleStr="vessel:list:delete">
							<Popover
								// iconProps={{
								//   type: "FleetEslistDeleteIcon",
								//   outerLayerClassname: "operate-icon"
								// }}
								render={(setIsOpen) => {
									return (
										<FleetToast
											label="Are you sure delete this item?"
											onCancel={() => {
												setIsOpen(false);
											}}
											onConfirm={() => {
												delVesselMultiple("single", record?.mmsi);
												setIsOpen(false);
											}}
										/>
									);
								}}
							>
								<span className="font_family fleet-delete" style={{ color: "red" }}></span>
							</Popover>
						</CommonRoleWrap>
					</div>
				);
			}
		});
		if (active === "calculation") return _columns;
		return _columns?.filter(
			(item) => !calcuateVesselKeys.includes(item?.key as calcuateVesselKeyType)
		);
	}, [active]);

	const onSelectChange = (newSelectedRowKeys: React.Key[]) => {
		setSelectedRowKeys(newSelectedRowKeys);
	};

	const getVesselGroupFront = () => {
		setLoading(true);
	};

	const getVesselSuccess = (response) => {
		let total = 0;
		switch (true) {
			case !Array.isArray(response?.data):
				// setDataSource(response?.data?.items);
				cacheSource.current[1] = response?.data?.items;
				total = response?.data?.totalCount;
				break;
			default:
				// setDataSource([]);
				cacheSource.current = {};
				break;
		}
		setPager((prev) => ({ ...prev, total, pageNum: 1 }));
		setLoading(false);
	};

	const getVesselError = (error) => {
		reminder("error", error?.msg ? error?.msg + ": " + error?.data : error?.data);
		setLoading(false);
	};

	const getVesselGroup = (searchVal?: string) => {
		console.log("searchVal", search);
		cacheSource.current = {};
		getVesselGroupAction(
			{
				pageNum: 1,
				pageSize: pager.pageSize,
				keyword: searchVal ?? search.current
			},
			getVesselGroupFront,
			getVesselSuccess,
			getVesselError,
			dispatch,
			navigate
		);
	};

	const onHideChange = (key: "add" | "edit", value: boolean) => {
		setHide((prev) => ({ ...prev, [key]: value }));
	};

	const onPageChange = (page: number, size: number) => {
		if (dataSource?.length >= pager.total) return;
		// if (page * size <= dataSource?.length) return;
		if (cacheSource.current[page]) {
			setPager((prev) => ({ ...prev, pageNum: page }));
			return;
		}
		getVesselGroupAction(
			{
				pageNum: page,
				pageSize: pager.pageSize,
				keyword: search.current
			},
			getVesselGroupFront,
			(response) => {
				if (!Array.isArray(response?.data)) {
					cacheSource.current[page] = response?.data?.items;
				} else {
					cacheSource.current[page] = [];
				}
				setPager((prev) => ({ ...prev, pageNum: page }));
				setLoading(false);
			},
			getVesselError,
			dispatch,
			navigate
		);
	};

	const onReset = () => {
		onHideChange("add", true);
		onHideChange("edit", true);
		setSelectedRowKeys([]);
		getVesselGroup();
	};

	const onSearch = useMemo(() => {
		const loadOptions = (val: string) => {
			cacheSource.current = {};
			search.current = val;
			getVesselGroup(val);
		};
		return debounce(loadOptions, 500);
	}, [search]);

  const onRow = (item) => {
    return {
      onDoubleClick: () => {
        record.current = item;
        onHideChange("edit", false);
        // navigate(`/layout/voyagemanage/movement/details?id=${record?.id}`)
      },
    };
  };

  useEffect(() => {
    getVesselGroup();
  }, []);

  return {
    activeIndex: active,
    search,
    record,
    selectedRowKeys,
    pager,
    columns,
    onReset,
    dataSource,
    loading,
    hideMap,
    onHideChange,
    navigate,
    onPageChange,
    onSelectChange,
    onSearch,
    onDeleteItems: delVesselMultiple,
    onRow,
    onTabChange: setActive,
  };
};

export default useGroup;
