import { type ApiParameter } from "@/api";
import { appVersion } from "@/api/constant";
import request from "@/service";
import { PageParams } from "../estimation";
import {
	DatabaseContactNewFormType,
	DatabaseContactType
} from "@/pages/database/business/contact/type";
import { FleetPageParams } from "@/types/common";

const enum ApiMap {
	queryContactGroupParameter,
	getUserGroupParameter,
	getContactGroupParameter,
	deleteContactParameter,
	addContactParameter,
	editContactParameter,
	getContactParameter,
	queryUserRoleGroupParameter
}

type ApiUrl = keyof typeof ApiMap;

export type queryContactParams = {
	keyword: string;
	businessType?: string;
	companyName?: string;
} & PageParams;

export type getContactParams = {
	id: string | number;
};

export type addContactParams = DatabaseContactNewFormType;

export type editContactParams = Pick<
	DatabaseContactType,
	| "id"
	| "name"
	| "businessType"
	| "companyName"
	| "mobilePhone1"
	| "mobilePhone2"
	| "landline1"
	| "landline2"
	| "faxNumber"
	| "email"
	| "remark"
>;

export type queryUserRoleGroupParams = {
	type: 0 | 1;
} & FleetPageParams;

const contactApi: ApiParameter<ApiUrl> = {
	queryContactGroupParameter: {
		method: "get",
		url: appVersion + "/contact/search"
	},
	getUserGroupParameter: {
		method: "get",
		url: appVersion + "/user/info/query"
	},
	getContactGroupParameter: {
		method: "get",
		url: appVersion + "/contact/list"
	},
	deleteContactParameter: {
		method: "delete",
		url: appVersion + "/contact"
	},
	addContactParameter: {
		method: "post",
		url: appVersion + "/contact"
	},
	editContactParameter: {
		method: "put",
		url: appVersion + "/contact"
	},
	getContactParameter: {
		method: "get",
		url: appVersion + "/contact"
	},
	queryUserRoleGroupParameter: {
		method: "get",
		url: appVersion + "/user/info/op/list"
	}
};

export const queryContactGroupApi = (params: queryContactParams) => {
	return request.request({
		...contactApi.queryContactGroupParameter,
		params
	});
};

export const queryUserGroupApi = (params: queryContactParams) => {
	return request.request({
		...contactApi.getUserGroupParameter,
		params
	});
};

export const queryUserRoleGroupApi = (params: queryUserRoleGroupParams) => {
	return request.request({
		...contactApi.queryUserRoleGroupParameter,
		params
	});
};

export const getContactGroupApi = (params: queryContactParams) => {
	return request.request({
		...contactApi.getContactGroupParameter,
		params
	});
};

export const deleteContactApi = (params: getContactParams) => {
	return request.request({
		...contactApi.deleteContactParameter,
		url: `${contactApi.deleteContactParameter.url}/${params?.id}`
	});
};

export const addContactApi = (params: addContactParams) => {
	return request.request({
		...contactApi.addContactParameter,
		data: params
	});
};

export const editContactApi = (params: editContactParams) => {
	return request.request({
		...contactApi.editContactParameter,
		data: params
	});
};

export const getContactApi = (params: getContactParams) => {
	return request.request({
		...contactApi.getContactParameter,
		url: `${contactApi.getContactParameter.url}/${params?.id}`
	});
};
