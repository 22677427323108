import React, { FC } from "react";
import style from "./index.module.less";
import classnames from "classnames";
import { AreaItemType } from "../../type";
const MonitoringAreaSelected: FC<{
	areaSelectList: AreaItemType[];
	onClear: (type: "single" | "mutil", selectItem?: AreaItemType) => void;
}> = ({ areaSelectList, onClear }) => {
	return (
		<div className={classnames(style["area-selected"])}>
			<div className="area-selected-title">
				<span>Selected List （{areaSelectList?.length}）</span>
				<span
					className="clear-btn"
					onClick={() => {
						onClear("mutil");
					}}
				>
					Clear all
				</span>
			</div>
			<div className="monitoring-selected-box fleet-nonScrolBar">
				{areaSelectList?.map((item, index) => {
					return (
						<div className="monitoring-selected-boxItem">
							<span className="monitoring-selected-label">{item.areaName}</span>
							<span
								className="font_family fleet-close areaSelected-close"
								onClick={() => {
									onClear("single", item);
								}}
							></span>
						</div>
					);
				})}
			</div>
		</div>
	);
};

export default MonitoringAreaSelected;
