import React from "react";
import {
	MovementDisputeChargeChangeEvent,
	MovementDisputeChargeCommitEvent,
	MovementDisputeChargeDeleteEvent,
	MovementDisputeChargeItemType
} from "./type";
import { FleetInput, FleetTooltip } from "@/components";
import { CommonContactSelect, FleetToast } from "@/common";
import { MovementCommonTableColumnsType } from "../../components/charterBase/common/table/type";

export const initialDisputeChargeItem = (voyageId: string): MovementDisputeChargeItemType => {
	return {
		voyageId,
		itemName: "",
		initialAmount: "",
		finalAmount: "",
		difference: "",
		remark: "",
		agentId: null,
		agentName: ""
	};
};

export const columns: MovementCommonTableColumnsType<
	MovementDisputeChargeItemType,
	keyof MovementDisputeChargeItemType,
	{
		onChange: MovementDisputeChargeChangeEvent;
		onDelete: MovementDisputeChargeDeleteEvent;
		onCommit: MovementDisputeChargeCommitEvent;
	}
>[] = [
	{
		label: "Item Name",
		dataIndex: "itemName",
		width: 150,
		fixed: "left",
		render: (value, record, index, actions) => {
			return (
				<FleetInput
					className="td-input"
					value={value}
					onChange={(val: string) => {
						actions?.onChange?.(index, "itemName", val);
					}}
				/>
			);
		}
	},
	{
		label: "Initial Amount",
		dataIndex: "initialAmount",
		width: 125,
		render: (value, record, index, actions) => {
			return (
				<FleetInput
					type="number"
					className="td-input"
					value={value}
					onChange={(val: number) => {
						actions?.onCommit?.(index, {
							initialAmount: val,
							difference: val - +record?.finalAmount
						});
					}}
				/>
			);
		}
	},
	{
		label: "Final Amount",
		dataIndex: "finalAmount",
		width: 125,
		render: (value, record, index, actions) => {
			return (
				<FleetInput
					type="number"
					className="td-input"
					value={value}
					onChange={(val: number) => {
						actions?.onCommit?.(index, {
							finalAmount: val,
							difference: +record?.initialAmount - val
						});
					}}
				/>
			);
		}
	},
	{
		label: "Difference",
		dataIndex: "difference",
		width: 125,
		render: (value, record, index, actions) => {
			return <FleetInput type="number" className="td-input" value={value} disabled />;
		}
	},
	{
		label: "Counter Party",
		dataIndex: "agentId",
		width: 200,
		render: (value, record, index, actions) => {
			return (
				<CommonContactSelect
					showKey="companyName"
					primaryKey="id"
					activeItems={{
						id: record?.agentId,
						companyName: record?.agentName
					}}
					onFinish={(newNoItem, type) => {
						actions?.onCommit?.(index, {
							agentId: newNoItem?.id ?? null,
							agentName: newNoItem?.companyName ?? ""
						});
					}}
				/>
			);
		}
	},
	{
		label: "Remarks",
		dataIndex: "remark",
		width: 300,
		render: (value, record, index, actions) => {
			return (
				<FleetInput
					className="td-input"
					value={value}
					onChange={(val: string) => {
						actions?.onChange?.(index, "remark", val);
					}}
				/>
			);
		}
	},
	{
		label: "Action",
		dataIndex: "action",
		fixed: "right",
		width: 60,
		render: (value, record, index, actions) => {
			return (
				<div className="td-actions">
					<FleetTooltip
						outerLayer={<span className="font_family fleet-delete"></span>}
						render={(setIsOpen) => {
							return (
								<FleetToast
									label="Are you sure delete this item?"
									onCancel={() => {
										setIsOpen(false);
									}}
									onConfirm={() => {
										actions?.onDelete?.(index);
										setIsOpen(false);
									}}
								/>
							);
						}}
					/>
				</div>
			);
		}
	}
];
