import React from "react";
import style from "./index.module.less";
import { CommonColumnCategoryProps } from "./type";
import { Button } from "@/_components";
import { ColumnCategoryItem } from "./components";

export default function CommonColumnCategory<T extends string, U extends string>({
	selectRows,
	options,
	onReset,
	onSelect,
	onSelectAll
}: CommonColumnCategoryProps<T, U>) {
	return (
		<div className={style["common-category"]}>
			<div className="category-header">
				<span>Select Columns To Display</span>
				<Button size="small" onClick={onReset}>
					Reset Column
				</Button>
			</div>
			<div className="category-container gap-[12px]">
				{options?.map((option) => {
					return (
						<ColumnCategoryItem<T, U>
							key={option?.dataIndex}
							selectRows={selectRows[option?.dataIndex]}
							options={option}
							onSelect={onSelect}
							onSelectAll={onSelectAll}
						/>
					);
				})}
			</div>
		</div>
	);
}
