import React, { FC, useMemo, useState } from "react";
import { ColumnsType } from "antd/es/table";
import classnames from "classnames";
import style from "./index.module.less";
import { PortCallListItemType, PortCallListTableProps } from "./type";
import { FleetLoading, FleetTable } from "@/components";
import useIndex from "./hooks/useIndex";
import { columns } from "./source";
import MovementTable from "@/pages/voyagemanage/business/movement/details/components/charterBase/common/table";

const PortCallListTable: FC<PortCallListTableProps> = ({ dataList, year }) => {
	const { loading } = useIndex();

	return (
		<div className={classnames(style["portCall-list-table"])}>
			<MovementTable dataSource={dataList} columns={columns} className="portCallList-table" />
		</div>
	);
};

export default PortCallListTable;
