import React from "react";
import { type PageRouterProps } from "@/router";
import EstimationDetails from "./business/details";
import EstimationList from "./business/list";


const EstimationRouter: PageRouterProps[] = [
  {
    // path: "preoperation",
    path: "list",
    key: "/layout/estimation/list",
    element: <EstimationList />,
    label: "Estimation List"
  },
  {
    path: "details",
    key: "/layout/estimation/details",
    element: <EstimationDetails />,
    label: "Details"
  }
]

export default EstimationRouter