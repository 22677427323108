import React, { useMemo, useRef } from "react";
import classnames from "classnames";
import style from "./index.module.less";
import { CBPortRotationFormProps } from "./type";
import { FleetFormItem, FleetIcon, FleetVerifyInput } from "@/components";
import { CommonPortSelect } from "@/common";
import { CargoBookLabelMap, PortLabelMap } from "@/types/voyageManage/cargoBook";
import { FleetTabbar } from "@/components/Tabs";
import { CargoUnitType } from "../cargoInfo/type";

const CBPortRotation: React.FC<CBPortRotationFormProps> = ({
  items,
  onChange,
  onCommit,
  onAdd,
  onDelete
}) => {
  const cargoPortRef = useRef<HTMLDivElement>(null);
  const ballastItems = useMemo(() => {
    return items?.filter(item => ["LD", "LB", "LT"].includes(item.purpose))
  }, [items]);

  const ladenItems = useMemo(() => {
    return items?.filter(item => ["DC", "DB", "DT"].includes(item.purpose))
  }, [items]);

  const scrollIntoView = (portType: "DC" | "LD", type: "add" | "del") => {

  }

  return <div ref={cargoPortRef} className={classnames("headFixture-form", style["cargoBook-portRotation"])}>
    <span className="vm-common-title">Port Rotation</span>
    {
      ballastItems?.map?.((portItem, idx, arr) => {
        return <div className="form-container" key={idx}>
          <FleetFormItem
            label={CargoBookLabelMap["loadPort"]}
            needVerity
          >
            <CommonPortSelect
              activeItems={{
                portCode: portItem?.portCode,
                portName: portItem?.portName
              }}
              showKey="portName"
              primaryKey="portCode"
              onFinish={(newItem, type) => {
                onCommit?.(portItem?.portId, {
                  portCode: newItem?.portCode ?? "",
                  portName: newItem?.portName ?? ""
                })
              }}
            />
          </FleetFormItem>
          <div className="portRotation-formItem">
            <FleetVerifyInput
              value={portItem?.loadingDischargeRate}
              // label={PortLabelMap["loadRate"]}
              placeholder="MT/Day"
              needVerity
              label="Loading Rate"
              type="number"
              className="formItem-input-1"
              onChange={(val: number) => {
                onChange?.(portItem?.portId, "loadingDischargeRate", val)
              }}
            />
            <FleetVerifyInput
              className="formItem-input-1"
              value={portItem?.lptt}
              placeholder="TT"
              label={PortLabelMap["lptt"]}
              type="number"
              onChange={(val: number) => {
                onChange?.(portItem?.portId, "lptt", val)
              }}
            />
          </div>
          <div className="portRotation-formItem">
            <FleetVerifyInput
              value={portItem?.cargoQuantity}
              label={CargoBookLabelMap["cargoQuantity"]}
              type="number"
              needVerity
              placeholder="MT"
              className="formItem-input-2"
              onChange={(val: number) => {
                onChange?.(portItem?.portId, "cargoQuantity", val)
              }}
            />
            <FleetTabbar
              disabled
              list={["MT", "CBM"]}
              value={portItem?.cargoUnit}
              onChange={(val: CargoUnitType) => {
                onChange?.(portItem?.portId, "cargoUnit", val)
              }}
            />
          </div>
          <div className="portRotation-formItem">
            <FleetVerifyInput
              value={portItem?.demRate}
              label={PortLabelMap["demRate"]}
              type="number"
              needVerity
              placeholder="USD/Day"
              className="formItem-input-1"
              onChange={(val: number) => {
                onChange?.(portItem?.portId, "demRate", val)
              }}
            />
            <FleetVerifyInput
              className="formItem-input-3"
              value={portItem?.desRate}
              label={PortLabelMap["desRate"]}
              type="number"
              needVerity
              placeholder="USD/Day"
              onChange={(val: number) => {
                onChange?.(portItem?.portId, "desRate", val)
              }}
            />
            <FleetIcon
              type={idx === 0 ? "FleetPortAddIcon" : "FleetPortDelIcon"}
              outerLayerClassname="formItem-icon"
              onClick={() => {
                if (idx === 0) {
                  onAdd?.("LD", arr?.at(-1)?.portId);
                  return;
                }
                onDelete?.(portItem?.portId)
              }}
            />
          </div>
        </div>
      })
    }
    {
      ladenItems?.map?.((portItem, idx, arr) => {
        return <div className="form-container" key={idx}>
          <FleetFormItem
            label={CargoBookLabelMap["discharingPort"]}
            needVerity
          >
            <CommonPortSelect
              activeItems={{
                portCode: portItem?.portCode,
                portName: portItem?.portName
              }}
              showKey="portName"
              primaryKey="portCode"
              onFinish={(newItem, type) => {
                onCommit?.(portItem?.portId, {
                  portCode: newItem?.portCode ?? "",
                  portName: newItem?.portName ?? ""
                })
              }}
            />
          </FleetFormItem>
          <div className="portRotation-formItem">
            <FleetVerifyInput
              needVerity
              value={portItem?.loadingDischargeRate}
              // label={PortLabelMap["discharingRate"]}
              label="Discharing Rate"
              type="number"
              placeholder="MT/Day"
              className="formItem-input-1"
              onChange={(val: number) => {
                onChange?.(portItem?.portId, "loadingDischargeRate", val)
              }}
            />
            <FleetVerifyInput
              className="formItem-input-1"
              value={portItem?.lptt}
              placeholder="TT"
              label={PortLabelMap["lptt"]}
              type="number"
              onChange={(val: number) => {
                onChange?.(portItem?.portId, "lptt", val)
              }}
            />
          </div>
          <div className="portRotation-formItem">
            <FleetVerifyInput
              value={portItem?.cargoQuantity}
              placeholder="MT"
              needVerity
              label={CargoBookLabelMap["cargoQuantity"]}
              type="number"
              className="formItem-input-2"
              onChange={(val: number) => {
                onChange?.(portItem?.portId, "cargoQuantity", val)
              }}
            />
            <FleetTabbar
              disabled
              list={["MT", "CBM"]}
              value={portItem?.cargoUnit}
              onChange={(val: CargoUnitType) => {
                onChange?.(portItem?.portId, "cargoUnit", val)
              }}
            />
          </div>
          <div className="portRotation-formItem">
            <FleetVerifyInput
              needVerity
              value={portItem?.demRate}
              label={PortLabelMap["demRate"]}
              type="number"
              placeholder="USD/Day"
              className="formItem-input-1"
              onChange={(val: number) => {
                onChange?.(portItem?.portId, "demRate", val)
              }}
            />
            <FleetVerifyInput
              needVerity
              className="formItem-input-3"
              value={portItem?.desRate}
              label={PortLabelMap["desRate"]}
              type="number"
              placeholder="USD/Day"
              onChange={(val: number) => {
                onChange?.(portItem?.portId, "desRate", val)
              }}
            />
            <FleetIcon
              type={idx === 0 ? "FleetPortAddIcon" : "FleetPortDelIcon"}
              outerLayerClassname="formItem-icon"
              onClick={() => {
                if (idx === 0) {
                  onAdd?.("DC", arr?.at(-1)?.portId);
                  return;
                }
                onDelete?.(portItem?.portId)
              }}
            />
          </div>
        </div>
      })
    }
  </div>
}

export default CBPortRotation