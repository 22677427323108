import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
// import LanguageDetector from 'i18next-browser-languagedetector';
// import Backend from 'i18next-xhr-backend';
import translationEN from 'static/locales/en/translation.json'; // 导入英文翻译资源文件
import translationCN from 'static/locales/zh/translation.json'; // 导入法文翻译资源文件

i18n
  // .use(Backend) // 异步加载语言文件
  // .use(LanguageDetector) // 自动检测语言环境
  .use(initReactI18next) // 初始化 react-i18next
  .init({
    fallbackLng: 'en', // 默认语言
    debug: true, // 打开调试模式，方便查看国际化日志
    interpolation: {
      escapeValue: false, // 使用 React 组件时无需转义（例如：<strong>）
    },
    resources: {
      en: {
        translation: translationEN
      },
      zh: {
        translation: translationCN
      },
    },
  });

export default i18n;

// import { useTranslation } from 'react-i18next';

// const LanguageSwitcher = () => {
//   const { i18n } = useTranslation();

//   const handleLanguageChange = (language) => {
//     i18n.changeLanguage(language);
//   };

//   return (
//     <div>
//       <button onClick={() => handleLanguageChange('en')}>English</button>
//       <button onClick={() => handleLanguageChange('zh')}>中文</button>
//     </div>
//   );
// };

// export default LanguageSwitcher;
