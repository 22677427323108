import { type ApiParameter } from "@/api";
import request from "@/service";
import { appVersion } from "@/api/constant";

const enum ApiMap {
	getFreightSenParameter,
	updateFreightSenParameter
}

type ApiUrl = keyof typeof ApiMap;

const freightSenApi: ApiParameter<ApiUrl> = {
	getFreightSenParameter: {
		method: "get",
		url: appVersion + "/est/vc/freight"
	},
	updateFreightSenParameter: {
		method: "put",
		url: appVersion + "/est/vc/fright"
	}
};

export type getFreightSenParams = {
	resultId: string | number;
};

export type updateFreightSenParams = {
	id: string | number;
	frtMidValue: number;
	frtStep: number;
	tceMidValue: number;
	tceStep: number;
};

export const getFreightSenApi = (params: getFreightSenParams) => {
	return request.request({
		...freightSenApi.getFreightSenParameter,
		url: `${freightSenApi.getFreightSenParameter.url}/${params?.resultId}`
	});
};

export const updateFreightSenApi = (params: updateFreightSenParams) => {
	return request.request({
		...freightSenApi.updateFreightSenParameter,
		data: params
	});
};
