import { ApiParameter } from "@/api";
import request from "@/service";

import { downloadLaytimeAttachParams } from "./type";
import { appVersion } from "@/api/constant";

const enum ApiMap {
	downloadLaytimeAttachParameter
}

type ApiUrl = keyof typeof ApiMap;

const laytimeApi: ApiParameter<ApiUrl> = {
	downloadLaytimeAttachParameter: {
		method: "post",
		url: appVersion + "/move/dialog/laytime/download"
	}
};

export const downloadLaytimeAttachApi = (params: downloadLaytimeAttachParams) => {
	return request.request({
		...laytimeApi.downloadLaytimeAttachParameter,
		responseType: "blob",
		data: params
	});
};
