import { useState } from "react";
import {
  getSegmentAction,
  saveSegmentAction,
  deleteSegmentAction,
} from "@/action/voyageManage/seaReports";
import useReminder from "@/hook/useReminder";
import { useAppDispatch } from "@/hook";
import { useNavigate } from "react-router-dom";
import { SegmentItem, SegmentProps } from "../type";
import { padEnd } from "lodash";
const useIndex = (
  voyageId: SegmentProps["id"],
  type: SegmentProps["type"],
  afterConfirm: SegmentProps["afterConfirm"]
) => {
  const [open, setOpen] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [segmentTable, setSegmentTable] = useState<SegmentItem[]>([
    {
      voyageId,
      type,
      shiftTime: 0,
      cpSpeed: "",
      cpOilConsumDo: "",
      cpOilConsumFo: "",
    },
  ]);
  const { reminder } = useReminder();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const segmentApiError = (error) => {
    reminder(
      "error",
      error?.msg ? error?.msg + ": " + error?.data : error?.data
    );
  };
  const handleReset = (res) => {
    setLoading(false);
    if (res.data.length == 0) {
      setSegmentTable([
        {
          voyageId,
          type,
          shiftTime: null,
          cpSpeed: "",
          cpOilConsumDo: "",
          cpOilConsumFo: "",
        },
      ]);
    } else setSegmentTable(res.data);
  };
  const handleGetSegment = () => {
    getSegmentAction(
      { voyageId, type },
      () => {
        setLoading(true);
      },
      handleReset,
      segmentApiError,
      dispatch,
      navigate
    );
  };
  const handleSaveSegment = () => {
    saveSegmentAction(
      { voyageId, dataList: segmentTable },
      () => {
        setLoading(true);
      },
      () => {
        afterConfirm();
        setOpen(false);
        reminder("success", "Success");
      },
      segmentApiError,
      dispatch,
      navigate
    );
  };

  const handleDeleteSegment = (id: number, index: number) => {
    deleteSegmentAction(
      [id],
      () => {
        setLoading(true);
      },
      () => {
        setSegmentTable(segmentTable.filter((item, idx) => idx != index));
      },
      segmentApiError,
      dispatch,
      navigate
    );
  };
  const confirmSeaReportsWarning = () => {};
  const handleChange = (val: any, prop: keyof SegmentItem, index: number) => {
    if (prop === "delete") {
      const id = segmentTable[index].id;
      if (id) handleDeleteSegment(id, index);
      else setSegmentTable(segmentTable.filter((item, idx) => idx != index));
    } else {
      setSegmentTable(
        segmentTable.map((item, idx) => {
          if (idx == index) {
            return { ...item, [prop]: val };
          } else {
            return item;
          }
        })
      );
    }
  };
  const handleAdd = () => {
    setSegmentTable([
      {
        cpSpeed: "",
        cpOilConsumFo: "",
        cpOilConsumDo: "",
        shiftTime: undefined,
        voyageId,
        type,
      },
      ...segmentTable,      
    ]);
  };
  return {
    open,
    setOpen,
    handleChange,
    confirmSeaReportsWarning,
    segmentTable,
    handleAdd,
    handleGetSegment,
    handleSaveSegment,
  };
};
export default useIndex;
