import React, { FC } from "react";
import style from "./index.module.less";
import { CommonUserPopupProps } from "./type";
import { FleetLoading } from "@/components";
import commonStyle from "@/common/Select/index.module.less";
import classnames from "classnames";

const CommonUserPopup: FC<CommonUserPopupProps> = ({ list, loading, onSelect, loadMore, type }) => {
	const handleSelect = (
		e: React.MouseEvent<HTMLUListElement, MouseEvent>,
		item: CommonUserPopupProps["list"][number]
	) => {
		e?.stopPropagation();
		onSelect?.(item);
	};
	return (
		<div className={classnames(style["common-userSelect--Popup"])}>
			<FleetLoading loading={loading} />

			<div className="areaSelect--popup--container">
				<div className="select--popup--scrollArea">
					{list?.map((item, index) => {
						return (
							<ul
								key={index}
								className="select--popup--item"
								onClick={(e) => {
									handleSelect?.(e, item);
								}}
							>
								<li>{item?.[type]}</li>
							</ul>
						);
					})}
				</div>
			</div>
			{type !== "areaName" && (
				<div className="select--popup--loadMore" onClick={loadMore}>
					<span>Load More</span>
				</div>
			)}
		</div>
	);
};

export default CommonUserPopup;
