import { useAppDispatch } from "@/hook"
import { useNavigate } from "react-router-dom"
import useReminder from "@/hook/useReminder"
import { useEffect, useState } from "react";
import { AccountNewlyFormChangeEvent, AccountNewlyFormType } from "../type";
import { checkAccountKeys, initialNewlyAccount } from "../source";
import { getAccountGroupAction, updateAccountAction } from "@/action/dataBase/account";
import { useTranslation } from "react-i18next";
const useIndex = (
  hide: boolean,
  onReset: () => void,
  type: "add" | "edit",
  id?: number,
  companyName?: string
) => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { reminder } = useReminder();
  const { t } = useTranslation()
  const [loading, setLoading] = useState(false);

  const [delList, setDelList] = useState<number[]>([])

  const [company, setCompany] = useState<{ id: number | null; name: string }>({
    id: null,
    name: ""
  })

  const [dataSource, setDataSource] = useState<AccountNewlyFormType[]>([]);

  const handleChange: AccountNewlyFormChangeEvent = (currentIndex, key, value) => {
    setDataSource(prev => {
      const current = prev[currentIndex];
      prev.splice(currentIndex, 1, {
        ...current,
        [key]: value
      })
      return [...prev]
    })
  }

  const check = () => {
    let flag = true;
    let checkKeys = ''
    for (let item of dataSource) {
      flag = checkAccountKeys.every(vesselKey => item[vesselKey] === 0 || (item[vesselKey] && item[vesselKey] !== undefined));

      if (!flag){
        checkKeys = checkAccountKeys.find(key => !Boolean(item[key]))
        break;
      }
    }
    return{
      flag,
      checkKeys
    }
  }

  const handleAdd = () => {
    if (!company?.id) {
      reminder("warning", "Please select a company")
      return
    }
    const params = type === "add" ? { companyId: company?.id, companyName: company?.name } : { companyId: id, companyName: companyName }
    const newlyAccountItem = initialNewlyAccount(params)
    setDataSource(prev => ([...prev, newlyAccountItem]))
  }

  const handleDelete = (currentIndex: number) => {
    setDataSource(prev => {
      const current = prev[currentIndex];
      current?.id && (setDelList(prev => [...prev, current.id]))
      prev.splice(currentIndex, 1)
      return [...prev]
    })
  }

  const handleCompanySelect = (company: { id: number | null; name: string }) => {
    setCompany(company)
    setDataSource(prev => {
      return prev?.map(item => {
        return { ...item, companyId: company?.id, companyName: company?.name }
      })
    })
  }


  const getAccountDetailsFront = () => {
    setLoading(true)
  }

  const getAccountDetailsSuccess = (response) => {
    setDataSource(response?.data?.items ?? [])
    setCompany({
      id: id,
      name: companyName
    })
    setLoading(false)
  }

  const updateAccountSuccess = (response) => {
    reminder("success", response?.msg)
    setLoading(false)
    onReset?.()
  }

  const getAccountDetailsFailed = (error) => {
    setLoading(false)
    reminder("error", error?.msg ? (error?.msg + ": " + error?.data) : error?.data);
  }


  const updateAccount = () => {
    if(!company?.id){
      reminder("error", `Company Name is empty`);
      return 
    }
    if (dataSource?.length === 0 && delList?.length === 0) return
    const {flag,checkKeys} = check()
    if(!flag) {
      reminder("error", `Required field ${t(`dataBase.account.${checkKeys}`)} is empty`);
      return 
    }
    updateAccountAction(
      { params: { accountList: dataSource, deleteIds: delList }, method: "post" },
      getAccountDetailsFront,
      updateAccountSuccess,
      getAccountDetailsFailed,
      dispatch,
      navigate
    )
  }

  const getAccountDetails = () => {
    getAccountGroupAction(
      { pageNum: 1, pageSize: 100, companyId: id, keyword: "" },
      getAccountDetailsFront,
      getAccountDetailsSuccess,
      getAccountDetailsFailed,
      dispatch,
      navigate
    )
  }

  useEffect(() => {
    if (hide) {
      setDataSource([])
      setDelList([])
      setCompany({ id: null, name: "" })
      return
    }
    if (type === "add" || !id) return
    getAccountDetails()
  }, [hide])

  return {
    dataSource,
    loading,
    company,
    setCompany,
    handleAdd,
    handleDelete,
    handleChange,
    updateAccount,
    handleCompanySelect
  }
}

export default useIndex