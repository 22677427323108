import { FleetButton, FleetInput, FleetSelect } from "@/components";
import { lanOrLonInputPropsType } from "./type";
import style from "./index.module.less";
import React, { useMemo, useState } from "react";
// import { Popover } from "antd";
import useIndex from "./hook/useIndex";
import { Button ,Popover} from "@/_components";
const LanOrLonInput: React.FC<lanOrLonInputPropsType> = ({
  value,
  onChange,
  title,
}) => {
  const {
    min,
    sec,
    openLanOrLon,
    setMin,
    setSec,
    setOpenLanOrLon,
    confirmLatOrLon,
    northSouth,
    eastWest,
    direction,
    setDirection,
  } = useIndex(onChange);
  const position = useMemo(()=>{
    if(!value){
      return "0°0'"
    }    
    const val = value.toString().replace("-", "");
    if(title=='Latitude'){
      return val.split(".")[0] +"°" +(Number(`0.${val.split(".")[1] || 0}`) * 60).toFixed(1) +"'" +  (value < 0 ? "S" : "N");
    }else{
      return val.split(".")[0] +"°" +(Number(`0.${val.split(".")[1] || 0}`) * 60).toFixed(1) +"'" +  (value < 0 ? "W" : "E");
    }
  },[value])
  const renderContent = (setIsOpen) => {
    return (
      <div className={style["movement-seaReport-lat-lon-input"]}>
        <div className="seaReport-lat-lon-input-title">{title}</div>
        <div className="seaReport-lat-lon-input-content">
          <FleetInput
            value={min}
            type="number"
            onChange={(val) => setMin(Number(val))}
          ></FleetInput>
          °
          <FleetInput
            value={sec}
            type="number"
            onChange={(val) => setSec(Number(val))}
          ></FleetInput>
          '
          <FleetSelect
            className="lat-lon-select"
            value={direction}
            options={title == "Latitude" ? northSouth : eastWest}
            onChange={(val)=>setDirection(val)}
          />
          <Button onClick={() =>{ confirmLatOrLon(title);setIsOpen(false)}}> ✓ </Button>
        </div>
      </div>
    );
  };
  return (
    <Popover
      placement={"bottom"}
      trigger="click"
      open={openLanOrLon}
      render={renderContent}
      onOpenChange={(val) => setOpenLanOrLon(val)}
    >
      <div className={style["movement-seaReport-lat-lon-div"]}>{position}</div>
    </Popover>
  );
};

export default LanOrLonInput;
