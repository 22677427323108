export const dateToUtcString = (d: Date | string | number) => {
  const date = new Date(d);
  const YY = date.getUTCFullYear() + '-';
  const MM =
    (date.getUTCMonth() + 1 < 10
      ? '0' + (date.getUTCMonth() + 1)
      : date.getUTCMonth() + 1) + '-';
  const DD = date.getUTCDate() < 10 ? '0' + date.getUTCDate() : date.getUTCDate();
  const hh =
    (date.getUTCHours() < 10 ? '0' + date.getUTCHours() : date.getUTCHours()) + ':';
  const mm =
    (date.getUTCMinutes() < 10 ? '0' + date.getUTCMinutes() : date.getUTCMinutes()) +
    ':';
  const ss =
    date.getUTCSeconds() < 10 ? '0' + date.getUTCSeconds() : date.getUTCSeconds();
  return YY + MM + DD + ' ' + hh + mm + ss;
}


export function dateFormat(fmt: any, date: any) {
  if (!date) return ""
  if (!isNaN(date)) {
    date = new Date(+date)
  } else {
    date = new Date(date)
  }
  // console.log(date)
  let ret
  const opt = {
    'Y+': date.getFullYear().toString(), // 年
    'm+': (date.getMonth() + 1).toString(), // 月
    'd+': date.getDate().toString(), // 日
    'H+': date.getHours().toString(), // 时
    'M+': date.getMinutes().toString(), // 分
    'S+': date.getSeconds().toString(), // 秒
    // 有其他格式化字符需求可以继续添加，必须转化成字符串
  }
  for (let k in opt) {
    ret = new RegExp('(' + k + ')').exec(fmt)
    if (ret) {
      fmt = fmt.replace(ret[1], ret[1].length == 1 ? opt[k] : opt[k].padStart(ret[1].length, '0'))
    }
  }
  // console.log(opt, date)
  return fmt
}

export function getMonthName(monthNumber) {
  var date = new Date();
  date.setMonth(monthNumber - 1); // 月份从0开始，所以需要减1
  var monthName = date.toLocaleString('en-US', { month: 'long' });
  return monthName;
}

function getMonthAbbreviation(month: number): string {
  const months = [
    "Jan", "Feb", "Mar", "Apr", "May", "Jun",
    "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"
  ];
  return months[month - 1];
}

export function dateToInvoiceTime(time: string | number): {
  day: number,
  suffix: 'TH' | 'ST' | 'RD' | 'ND',
  month: string,
  year: number,
  hours: string,
  minutes: string,
  days:string;
} {
  let date = new Date(time);
  const day = date.getDate()
  const month = getMonthAbbreviation(date.getMonth() + 1)
  // const month = date.toLocaleString('default', { month: 'short' }).toUpperCase()
  const year = date.getFullYear();
  const hours = date.getHours();
  const minutes = date.getMinutes();

  let suffix: 'TH' | 'ST' | 'RD' | 'ND';
  if (day >= 11 && day <= 13) {
    suffix = 'TH'
  } else {
    switch (day % 10) {
      case 1: suffix = 'ST'; break;
      case 2: suffix = 'ND'; break;
      case 3: suffix = 'RD'; break;
      default: suffix = 'TH'; break;
    }
  }

  return {
    day,
    suffix,
    month,
    year,
    hours: hours?.toString()?.padStart(2, '0'),
    minutes: minutes?.toString()?.padStart(2, '0'),
    days:day?.toString()?.padStart(2,'0')
  }
}
// `${day}${suffix} ${month}, ${year}`