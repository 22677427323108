import React, { useEffect, useState, useRef } from "react";
import { Button, Table, Popover, Checkbox, Space, Divider, Card, Row, Col } from 'antd'
import { UnorderedListOutlined } from '@ant-design/icons'
import type { ColumnsType, ColumnType } from 'antd/es/table';
import { FleetLoading } from "@/components";
import classnames from "classnames";
import useReminder from "@/hook/useReminder";
import { DetailDrawerItem } from '../../'
import { getDailyReportAction } from '@/action/monitoring/vessel'
import { transform2lnglat } from '@/tools'
import { useMonitoringContext } from '@/pages/monitoring/MonitoringContext'
import { DailyRepotyDataType } from '@/pages/monitoring/components'
import style from './index.module.less'
import { dateToUtcString } from "@/tools/date";
import { MonitoringDetailMoreTabType } from "../../type";

export type DailyReportProps = {
  className?: string,
  data: DetailDrawerItem,
  mapRef?: React.MutableRefObject<any>
  activeIndex: MonitoringDetailMoreTabType
}

type CustomeColumnType<T> = (ColumnType<T> & { group?: string })[]
const DailyReport: React.FC<DailyReportProps> = ({ data, className, mapRef, activeIndex }) => {

  const columnGroups = ['General', 'NAVIGGATION', 'BUNKER MONITORING (MT)', 'Prime Mover', 'Loading Condition', 'Weather Condition']
  const allColumns: CustomeColumnType<DailyRepotyDataType> = [
    {
      title: 'No.',
      dataIndex: 'no',
      width: 100,
      fixed: 'left',
      group: 'General',
    }, {
      title: 'Type',
      dataIndex: 'type',
      width: 100,
      fixed: 'left',
      ellipsis: true,
      group: 'General',
    }, {
      title: 'Date/Time(utc)',
      dataIndex: 'reportDateTime',
      fixed: 'left',
      ellipsis: true,
      group: 'General',
      render: (_, record) => { return <>{record.reportDateTime ? dateToUtcString(record.reportDateTime)?.slice(0, -3) : record.reportDateTime}</> }
    }, {
      title: 'Position',
      dataIndex: 'positionStr',
      width: 160,
      ellipsis: true,
      group: 'General',
    }, {
      title: 'SOG(Kts)',
      dataIndex: 'sog',
      ellipsis: true,
      group: 'NAVIGGATION',
    },
    // {
    //   title: 'WATER SPEED(kts)',
    //   dataIndex: 'avgWaterSpeed',
    //   ellipsis: true,
    //   group: 'NAVIGGATION',
    // }, 
    {
      title: 'PERF.SPEED(kts)',
      dataIndex: 'perfSpeed',
      ellipsis: true,
      group: 'NAVIGGATION',
    },
    {
      title: 'Course(°)',
      dataIndex: 'course',
      ellipsis: true,
      group: 'NAVIGGATION',
    },
    // {
    //   title: 'WF(kts)',
    //   dataIndex: 'wf',
    //   ellipsis: true,
    //   group: 'NAVIGGATION',
    // }, {
    //   title: 'CF(kts)',
    //   dataIndex: 'cf',
    //   ellipsis: true,
    //   group: 'NAVIGGATION',
    // }, 
    {
      title: 'Steam Time SLR(HRS)',
      dataIndex: 'steamTimeSlr',
      ellipsis: true,
      group: 'NAVIGGATION',
    }, {
      title: 'Distance SLR(NM)',
      dataIndex: 'distSlr',
      ellipsis: true,
      group: 'NAVIGGATION',
    }, {
      title: 'GW Day Selection',
      dataIndex: 'gwDay',
      ellipsis: true,
      group: 'NAVIGGATION',
    }, {
      title: 'BROB HSFO(MT)',
      dataIndex: 'brobHfo',
      ellipsis: true,
      group: 'BUNKER MONITORING (MT)',
    }, {
      title: 'BROB LSFO(MT)',
      dataIndex: 'brobLsfo',
      ellipsis: true,
      group: 'BUNKER MONITORING (MT)',
    }, {
      title: 'BROB MDO(MT)',
      dataIndex: 'brobMdo',
      ellipsis: true,
      group: 'BUNKER MONITORING (MT)',
    }, {
      title: 'BROB MGO(MT)',
      dataIndex: 'brobMgo',
      ellipsis: true,
      group: 'BUNKER MONITORING (MT)',
    }, {
      title: 'ME HSFO CONS(MT)',
      dataIndex: 'meHfoSlr',
      ellipsis: true,
      group: 'BUNKER MONITORING (MT)',
    }, {
      title: 'ME LSFO CONS(MT)',
      dataIndex: 'meLsfoSlr',
      ellipsis: true,
      group: 'BUNKER MONITORING (MT)',
    }, {
      title: 'ME MDO CONS(MT)',
      dataIndex: 'meMdoSlr',
      ellipsis: true,
      group: 'BUNKER MONITORING (MT)',
    }, {
      title: 'ME MGO CONS(MT)',
      dataIndex: 'meMgoSlr',
      ellipsis: true,
      group: 'BUNKER MONITORING (MT)',
    }, {
      title: 'AUX HSFO CONS(MT)',
      dataIndex: 'auxHfoSlr',
      ellipsis: true,
      group: 'BUNKER MONITORING (MT)',
    }, {
      title: 'AUX LSFO CONS(MT)',
      dataIndex: 'auxLsfoSlr',
      ellipsis: true,
      group: 'BUNKER MONITORING (MT)',
    }, {
      title: 'AUS MDO CONS(MT)',
      dataIndex: 'auxMdoSlr',
      ellipsis: true,
      group: 'BUNKER MONITORING (MT)',
    }, {
      title: 'AUX MGO CONS(MT)',
      dataIndex: 'auxMgoSlr',
      ellipsis: true,
      group: 'BUNKER MONITORING (MT)',
    }, {
      title: 'AVG RPM(rpm)',
      dataIndex: 'avgRpm',
      ellipsis: true,
      group: 'Prime Mover',
    }, {
      title: 'ME POWER(kW)',
      dataIndex: 'mePower',
      ellipsis: true,
      group: 'Prime Mover',
    }, {
      title: 'ME Load SLR',
      dataIndex: 'meLoadSlr',
      ellipsis: true,
      group: 'Prime Mover',
    }, {
      title: 'Slip(%)',
      dataIndex: 'slip',
      ellipsis: true,
      group: 'Prime Mover',
    }, {
      title: 'FORE DRAFT',
      dataIndex: 'draftFwd',
      ellipsis: true,
      group: 'Loading Condition',
    }, {
      title: 'AFT DRAFT',
      dataIndex: 'draftAft',
      ellipsis: true,
      group: 'Loading Condition',
    }, {
      title: 'MEAN DRAFT',
      dataIndex: 'meanDraft',
      ellipsis: true,
      group: 'Loading Condition',
    }, {
      title: 'Wind BF',
      dataIndex: 'windBf',
      ellipsis: true,
      group: 'Weather Condition',
    }, {
      title: 'Wind Direction',
      dataIndex: 'windDir',
      ellipsis: true,
      group: 'Weather Condition',
    }, {
      title: 'Sea Height',
      dataIndex: 'sea',
      ellipsis: true,
      group: 'Weather Condition',
    }, {
      title: 'Swell Height',
      dataIndex: 'swellHeight',
      ellipsis: true,
      group: 'Weather Condition',
    }, {
      title: 'Swell Direction',
      dataIndex: 'swellDir',
      ellipsis: true,
      group: 'Weather Condition',
    }
  ]
  const disabledFilterCloumns = ['no', 'type', 'reportDateTime']
  const [dataSource, setDataSource] = useState([])
  const [tableData, setTableData] = useState([])
  const [loading, setLoading] = useState<boolean>(false)
  const [columns, setColumns] = useState<ColumnsType<DailyRepotyDataType>>(allColumns)
  const [filterVisible, SetFilterVisible] = useState<boolean>(false)
  const [filterValue, setFilterValue] = useState<string[]>(allColumns.map((t: ColumnType<DailyRepotyDataType>) => t.dataIndex as string))
  const { reminder } = useReminder();
  const { dailyReportSelectRow, setDailyReportSelectRow } = useMonitoringContext()

  useEffect(() => {
    if (activeIndex !== "dailyReport") return
    setDataSource([])
    setDailyReportSelectRow([])
    setTableData([])
    mapRef.current?.clearDailyReport();
    if (!data?.voyageNo) return
    loadData()
    // setColumns(allColumns)
  }, [activeIndex, data])

  const loadData = () => {
    getDailyReportAction(
      { voyageNo: data?.voyageNo, legType: data?.vesselStatus },
      () => { setLoading(true) },
      ({ data }) => {
        console.log('dailyreport', data);
        setLoading(false)
        const rowkeyList = (data || []).map((t: DailyRepotyDataType, index: number) => { return { ...t, no: index + 1 } })
        setDataSource([{}]);
        setTableData(rowkeyList)
        // 暂时先去掉午报连接的线条
        let geojson = {
          "type": "FeatureCollection",
          "features": [
            // {
            //   "type": "Feature",
            //   "properties": {
            //     type: 'dailyReportLine'
            //   },
            //   "geometry": {
            //     "type": "LineString",
            //     "coordinates": []
            //   }
            // }
          ]
        }
        let dailyRepostLineCoors = []
        rowkeyList?.forEach((t: DailyRepotyDataType) => {
          const positions = t.positionStr?.split(',')
          const latFlag = positions[1]?.at(-1) === 'N' ? 1 : -1
          const lat = transform2lnglat(positions[1]?.substring(0, positions[1].length - 1)) * latFlag
          const lngFlag = positions[0]?.at(-1) === 'E' ? 1 : -1
          const lng = transform2lnglat(positions[0]?.substring(0, positions[0].length - 1)) * lngFlag
          dailyRepostLineCoors.push([lng, lat])
          geojson.features.push({
            "type": "Feature",
            "geometry": {
              "type": "Point",
              "coordinates": [lng, lat]
            },
            "properties": Object.assign({
              "geometryType": "dailyReportPoint",
              "_latlng": [lng, lat]
            }, t)
          })
        })
        // geojson.features[0].geometry.coordinates = dailyRepostLineCoors
        mapRef?.current?.loadDailyReport(geojson)
      },
      (error) => {
        setLoading(false)
        reminder("error", error?.msg ? (error?.msg + ": " + error?.data) : error?.data)
      }
    )
  }

  console.log('colum,ns', columns)

  const onAllCheckChange = (key: string, val: boolean) => {
    let newfilterValue = [...filterValue]
    if (val) {
      const items: any = allColumns.filter((t) => t.group === key && !filterValue.includes(t.dataIndex as string))
      const itemKeys: string[] = items.map((t: ColumnType<DailyRepotyDataType>) => t.dataIndex)
      newfilterValue.push(...itemKeys)
      setFilterValue(newfilterValue)
    } else {
      const items: any = allColumns.filter((t) => t.group === key && !disabledFilterCloumns.includes(t.dataIndex as string))
      const itemKeys: string[] = items.map((t: ColumnType<DailyRepotyDataType>) => t.dataIndex)
      newfilterValue = filterValue.filter(t => !itemKeys.includes(t))
      setFilterValue(newfilterValue)
    }
    const newColumns = allColumns.filter((t: ColumnType<DailyRepotyDataType>) => newfilterValue.includes(t.dataIndex as string))
    setColumns(newColumns)
  }

  const handleColumnReset = () => {
    setFilterValue(disabledFilterCloumns)
    const newColumns = allColumns.filter((t: ColumnType<DailyRepotyDataType>) => disabledFilterCloumns.includes(t.dataIndex as string))
    setColumns(newColumns)
  }
  const onFliterColumnChange = (checked: boolean, val: string) => {
    let newfilterValue = [...filterValue]
    if (checked) {
      newfilterValue.push(val)
      setFilterValue(newfilterValue)
    } else {
      newfilterValue = newfilterValue.filter(t => t !== val)
      setFilterValue(newfilterValue)
    }
    const newColumns = allColumns.filter((t: ColumnType<DataType>) => newfilterValue.includes(t.dataIndex as string))
    setColumns(newColumns)
  }

  const rowSelection = {
    selectedRowKeys: dailyReportSelectRow.map((t: DailyRepotyDataType) => t.no),
    onChange: (selectedRowKeys: string[], selectedRows: DailyRepotyDataType[]) => {
      const [first] = selectedRows
      mapRef?.current?.handleDailyReportIconChange(first)
    }
  };

  const columnsFilter =
    <>
      <Space>
        <span style={{ fontSize: '14px' }}>Select Columns To Display</span>
        <Button type="primary" onClick={handleColumnReset}>Reset Column</Button>
      </Space>
      <Row gutter={[24, 24]} style={{ marginTop: '12px' }}>
        {columnGroups.map((key: string, index: number) => {
          const items: any = allColumns.filter((t) => t.group === key)
          const itemKeys = items.map((o) => o.dataIndex as string)
          const checkedItems = filterValue.filter((t) => itemKeys.includes(t))
          const indeterminate = checkedItems.length > 0 && checkedItems.length < items.length
          return <Col flex={1} key={index}>
            <Checkbox indeterminate={indeterminate} onChange={(e) => onAllCheckChange(key, e.target.checked)} checked={items.length === checkedItems.length}>
              {key}
            </Checkbox>
            <div style={{ display: 'flex', flexFlow: 'column nowrap', marginTop: '12px', paddingLeft: '12px', borderLeft: '1px solid rgba(217, 217, 217, 1)' }}>
              {items.map((item) => {
                return <Checkbox key={item.dataIndex} checked={filterValue.includes(item.dataIndex)} disabled={disabledFilterCloumns.includes(item.dataIndex)} onChange={(e) => onFliterColumnChange(e.target.checked, item.dataIndex)}>{item.title} </Checkbox>
              })}
            </div>
          </Col>
        })}
      </Row>
    </>

  return <div className={classnames([style.dailyReport, activeIndex === "dailyReport" && style['active']])}>
    <FleetLoading loading={loading} />
    <div className={style.toolbar}>
      <Space>
        <span>Total Rows : {dataSource.length}</span>
        <Divider type="vertical" />
        <Popover placement="right" trigger="click" title={null} open={filterVisible} onOpenChange={(val) => SetFilterVisible(val)}
          content={columnsFilter}>
          <Button icon={<UnorderedListOutlined />}> Filter </Button>
        </Popover>
      </Space>
      {/* <Button type="primary" icon={<DownloadOutlined />}>Download</Button> */}
    </div>
    <Table className={style.table} rowKey="no" rowSelection={{ type: 'radio', ...rowSelection }} columns={columns} dataSource={tableData} pagination={false} scroll={{ x: 4800, y: 'calc(100% - 36px)' }} />
  </div >
}

export default DailyReport
