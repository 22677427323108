import React from "react";
import { MovementCommonTableColumnsType } from "../../common/table/type";
import {
	MovementCharterBasePerformanceType,
	MovementCharterBasePerformanceChangeEvent
} from "./type";
import i18n from "@/locale";
import { FleetInput } from "@/components";
import { EstPortBatchCommitEvent, EstPortConsumCommitEvent } from "@/pages/estimation/module";

export const columns: MovementCommonTableColumnsType<
	MovementCharterBasePerformanceType,
	keyof MovementCharterBasePerformanceType,
	{
		onChange: MovementCharterBasePerformanceChangeEvent;
		onBatchCommit: EstPortBatchCommitEvent;
		onConsumCommit: EstPortConsumCommitEvent
	}
>[] = [
		{
			dataIndex: "ballastSpeed",
			width: 108,
			label: i18n.t("common.ballastSpeed"),
			render: (value, record, index, actions, otherItem) => {
				return (
					<FleetInput
						value={value}
						type="number"
						className="td-input"
						onChange={(val: number) => {
							actions?.onChange?.("ballastSpeed", val);
							actions?.onBatchCommit?.(
								"ballast",
								{ ballastSpeed: val, ladenSpeed: record?.ladenSpeed },
							);
						}}
					/>
				);
			}
		},
		{
			dataIndex: "ladenSpeed",
			width: 108,
			label: i18n.t("common.ladenSpeed"),
			render: (value, record, index, actions, otherItem) => {
				return (
					<FleetInput
						value={value}
						type="number"
						className="td-input"
						onChange={(val: number) => {
							actions?.onChange?.("ladenSpeed", val);
							actions?.onBatchCommit?.(
								"laden",
								{ ballastSpeed: record?.ballastSpeed, ladenSpeed: val },
							);
						}}
					/>
				);
			}
		},
		{
			dataIndex: "seaFuelBallast",
			width: 108,
			label: i18n.t("common.seaFuelBallast"),
			render: (value, record, index, actions) => {
				return (
					<FleetInput
						value={value}
						type="number"
						className="td-input"
						onChange={(val: number) => {
							actions?.onChange?.("seaFuelBallast", val);
						}}
					/>
				);
			}
		},
		{
			dataIndex: "seaFuelLaden",
			width: 108,
			label: i18n.t("common.seaFuelLaden"),
			render: (value, record, index, actions) => {
				return (
					<FleetInput
						value={value}
						type="number"
						className="td-input"
						onChange={(val: number) => {
							actions?.onChange?.("seaFuelLaden", val);
						}}
					/>
				);
			}
		},
		{
			dataIndex: "seaFuelMdo",
			width: 108,
			label: i18n.t("common.seaFuelMdo"),
			render: (value, record, index, actions) => {
				return (
					<FleetInput
						value={value}
						type="number"
						className="td-input"
						onChange={(val: number) => {
							actions?.onChange?.("seaFuelMdo", val);
						}}
					/>
				);
			}
		},
		{
			dataIndex: "portFuelWork",
			width: 108,
			label: i18n.t("estimation.portFuelWork"),
			render: (value, record, index, actions) => {
				return (
					<FleetInput
						value={value}
						type="number"
						className="td-input"
						onChange={(val: number) => {
							actions?.onChange?.("portFuelWork", val);
							actions?.onConsumCommit?.({ ...record, portFuelWork: val })
						}}
					/>
				);
			}
		},
		{
			dataIndex: "portFuelIdle",
			width: 108,
			label: i18n.t("estimation.portFuelIdle"),
			render: (value, record, index, actions) => {
				return (
					<FleetInput
						value={value}
						type="number"
						className="td-input"
						onChange={(val: number) => {
							actions?.onChange?.("portFuelIdle", val);
							actions?.onConsumCommit?.({ ...record, portFuelIdle: val })
						}}
					/>
				);
			}
		},
		{
			dataIndex: "portWorkMdo",
			width: 108,
			label: i18n.t("estimation.portWorkMdo"),
			render: (value, record, index, actions) => {
				return (
					<FleetInput
						value={value}
						type="number"
						className="td-input"
						onChange={(val: number) => {
							actions?.onChange?.("portWorkMdo", val);
							actions?.onConsumCommit?.({ ...record, portWorkMdo: val })
						}}
					/>
				);
			}
		},
		{
			dataIndex: "portIdleMdo",
			width: 108,
			label: i18n.t("estimation.portIdleMdo"),
			render: (value, record, index, actions) => {
				return (
					<FleetInput
						value={value}
						type="number"
						className="td-input"
						onChange={(val: number) => {
							actions?.onChange?.("portIdleMdo", val);
							actions?.onConsumCommit?.({ ...record, portIdleMdo: val })
						}}
					/>
				);
			}
		},
		{
			dataIndex: "bunkerPriceIfo",
			width: 108,
			label: i18n.t("common.bunkerPriceIfo"),
			render: (value, record, index, actions) => {
				return (
					<FleetInput
						value={value}
						type="number"
						className="td-input"
						onChange={(val: number) => {
							actions?.onChange?.("bunkerPriceIfo", val);
						}}
					/>
				);
			}
		},
		{
			dataIndex: "bunkerPriceMdo",
			width: 108,
			label: i18n.t("common.bunkerPriceMdo"),
			render: (value, record, index, actions) => {
				return (
					<FleetInput
						value={value}
						type="number"
						className="td-input"
						onChange={(val: number) => {
							actions?.onChange?.("bunkerPriceMdo", val);
						}}
					/>
				);
			}
		},
		{
			dataIndex: "dailyHire",
			width: 108,
			label: i18n.t("common.dailyHire"),
			render: (value, record, index, actions) => {
				return (
					<FleetInput
						value={value}
						type="number"
						className="td-input"
						onChange={(val: number) => {
							actions?.onChange?.("dailyHire", val);
						}}
					/>
				);
			}
		}
	];
