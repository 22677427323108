import { InvFreightOtherItemType } from "@/pages/voyagemanage/business/invoices/module";
import { useFreightPortIndex } from "../components";
import { useMemo, useState } from "react";
import { InvFreightSettlementCommitEvent, InvFreightSettlementType } from "../type";
import { checkKeys, initialInvFreightSettlementItem } from "../source";
import { commonCheck } from "@/tools/check";

const useIndex = (
	otherItem: Pick<InvFreightOtherItemType, "addDesDem" | "addFreightIncome" | "addCargo"> & {
		ttlSummary: number;
	}
) => {
	const {
		loadDataSource,
		dischargeDataSource,
		summary: portSummaty,
		delIds: delPortIds,
		change: handlePortItemChange,
		commit: handlePortItemCommit,
		add: handlePortAdd,
		remove: handlePortRemove,
		init: handlePortInit,
		check: handlePortCheck
	} = useFreightPortIndex(otherItem);

	const [dataSource, setDataSource] = useState<InvFreightSettlementType>(
		initialInvFreightSettlementItem()
	);

	const freightIncome = useMemo(() => {
		if (!otherItem?.addFreightIncome) return 0;
		return (
			((dataSource?.freightType === "FR"
				? +dataSource?.freightRate *
					(otherItem?.addCargo ? otherItem?.ttlSummary : +dataSource?.ttlCargoQuantity)
				: +dataSource?.freightRate) *
				+dataSource?.freightPercent) /
			100
		);
	}, [
		otherItem?.addFreightIncome,
		dataSource?.freightType,
		dataSource?.freightRate,
		dataSource?.freightPercent,
		otherItem?.ttlSummary,
		dataSource?.ttlCargoQuantity,
		otherItem?.addCargo
	]);

	const brokerage = useMemo(() => {
		return (freightIncome * +dataSource?.brokerageRate) / 100;
	}, [dataSource?.brokerageRate, freightIncome]);

	const addComm = useMemo(() => {
		return (freightIncome * +dataSource?.addCommRate) / 100;
	}, [dataSource?.addCommRate, freightIncome]);

	const summary = useMemo(() => {
		return freightIncome - brokerage - addComm - portSummaty;
	}, [freightIncome, portSummaty, brokerage, addComm]);

	const commit: InvFreightSettlementCommitEvent = (item) => {
		setDataSource((prev) => ({ ...prev, ...item }));
	};

	const init: InvFreightSettlementCommitEvent = (item) => {
		setDataSource(initialInvFreightSettlementItem(item));
	};

	const check = () => {
		const { checked, checkKey } = handlePortCheck();
		if (!checked)
			return {
				checked,
				checkKey
			};
		return commonCheck(
			{
				...dataSource,
				ttlCargoQuantity: otherItem?.addCargo
					? otherItem?.ttlSummary
					: dataSource?.ttlCargoQuantity,
				freightIncome,
				brokerage,
				addComm
			},
			checkKeys
		);
	};

	return {
		loadDataSource,
		dischargeDataSource,
		delPortIds,
		handlePortItemChange,
		handlePortItemCommit,
		handlePortAdd,
		handlePortRemove,
		handlePortInit,
		settlement: {
			...dataSource,
			amount: summary,
			ttlCargoQuantity: otherItem?.addCargo ? otherItem?.ttlSummary : dataSource?.ttlCargoQuantity,
			freightIncome,
			brokerage,
			addComm
		},
		summary,
		handleSettlementCommit: commit,
		handleSettlementInit: init,
		handleSettlementCheck: check
	};
};

export default useIndex;
