import React, { FC } from "react";
import classnames from "classnames";
import style from "./index.module.less";
import { RouteCommonPortCallItemType, RouteCommonPortCallProps } from "./type";
import { Button, Icon, Table } from "@/_components";
import useIndex from "./hooks/useIndex";
import { columns } from "./source";
import { useTranslation } from "react-i18next";
import { FleetLoading } from "@/components";

const RouteCommonPortCall: FC<RouteCommonPortCallProps> = (props) => {
	const { t } = useTranslation();
	const {
		loading,
		dataSource,
		selectRows,
		handleRowSelect,
		pager,
		onPageChange,
		activeDetailsOption,
		handleBack,
		handleDownload
	} = useIndex();
	return (
		<div
			className={classnames(style["routeCommon-portCall"], {
				"routeCommon-details--active": activeDetailsOption.isPortCallVisible
			})}
		>
			<FleetLoading loading={loading} />
			<div className="portCall-header">
				<div className="portCall-header-leftArea">
					<div className="portCall-header-crumbs">
						<Icon
							size={20}
							type="fleet-arrow-right"
							className="crumbs-pointer"
							onClick={handleBack}
						/>
						<ul className="crumbs-container">
							<li>
								{t(`routeCommon.portCall.${activeDetailsOption?.portCallLabel}`, {
									defaultValue: t(`routeCommon.portCall.${activeDetailsOption?.activeTab}`)
								})}
							</li>
							<li>/</li>
							<li>Portcall</li>
						</ul>
					</div>
					<div className="portCall-header-summaryItem">
						Total: <span>{pager?.total}</span>
					</div>
					<div className="portCall-header-summaryItem">
						Selected: <span>{selectRows?.length}</span>
					</div>
				</div>
				<Button
					type="secondary"
					prefixIcon="fleet-download"
					disabled={selectRows?.length <= 0}
					onClick={handleDownload}
				>
					Download
				</Button>
			</div>
			<Table<RouteCommonPortCallItemType>
				className="routeCommon-details-table portCall-container pagination"
				dataSource={dataSource}
				columns={columns}
				rowSelection={{
					selectedRowKeys: selectRows,
					onChange: handleRowSelect
				}}
				primaryKey="ata"
				pagination={{
					current: pager.pageNum,
					pageSize: pager.pageSize,
					total: pager.total,
					onChange: onPageChange,
					showSizeChanger: false
				}}
			/>
		</div>
	);
};

export default RouteCommonPortCall;
