import React, { FC } from "react";
import style from "./index.module.less";
import { RouteCommonOtherSelectPopupProps } from "./type";
import { FleetLoading } from "@/components";
import classnames from "classnames";

export default function RouteCommonOtherSelectPopup<T extends { id?: number }>({
	list,
	loading,
	onSelect,
	loadMore,
	type,
	showKey
}: RouteCommonOtherSelectPopupProps<T>) {
	const handleSelect = (
		e: React.MouseEvent<HTMLUListElement, MouseEvent>,
		item: RouteCommonOtherSelectPopupProps<T>["list"][number]
	) => {
		e?.stopPropagation();
		onSelect?.(item);
	};
	return (
		<div className={classnames(style["routeCommon-otherSelect--Popup"])}>
			<FleetLoading loading={loading} />

			<div className="otherSelect--popup--container">
				<div className="select--popup--scrollArea">
					{list?.map((item, index) => {
						return (
							<ul
								key={index}
								className="select--popup--item"
								onClick={(e) => {
									handleSelect?.(e, item);
								}}
							>
								<li>{item?.[showKey as string]}</li>
							</ul>
						);
					})}
				</div>
			</div>
			{type !== "area" && (
				<div className="select--popup--loadMore" onClick={loadMore}>
					<span>Load More</span>
				</div>
			)}
		</div>
	);
}
