import React, { FC } from "react";
import { PrefReportVoyageFilter, PrefReportVoyageItem } from "./components"
import style from './index.module.less';

const PerfReportVoyageList: FC<{}> = (props) => {
  return <div className={style["perfreport-voyageList"]}>
    <PrefReportVoyageFilter />
    <div className="voyageList-warper">
      <div className="voyageList-scrollArea"></div>
    </div>
  </div>
}

export default PerfReportVoyageList