export enum CargoBookLabelMap {
  fixtureNo = "Fixture NO.",
  chartererId = "Charterer Id",
  chartererName = "Charterer Name",
  fixtureDate = "Fixture Date",
  layCan = "Laycan From/To",
  layCanFrom = "Cargo layCan From",
  layCanTo = "Cargo layCan To",
  paymentRate = "Freight Payment Rate",
  paymentTerm = "Payment Term (Day)",
  paymentStartDay = "Payment Start",
  cargoName = "Cargo Name",
  cargoQuantity = "Cargo Quantity",
  cargoUnit = "Cargo Unit",
  mollo = "MOLLO",
  freightType = "Freight Type",
  freightRate = "Freight Rate",
  commission = "Add. Comm.",
  brokerage = "Brokerage",
  brokerId = "Broker Id",
  brokerName = "Broker Name",
  voyageId = "Voyage Id",
  status = "Status",


  loadPort = "Load Port",
  loadPortCode = "Load Port Code",
  loadPortName = "Load Port Name",
  discharingPort = "Discharing Port",
  discharingPortCode = "Discharing Port Code",
  discharingPortName = "Discharing Port Name",

  charteredBy = "Chartered By",
  createdBy = "Created By",
  operatedBy = "Operated By",
  updatedDate = "Updated Date",
  id = "Id",
  option = "Option"
}

export enum PortLabelMap {
  seq = "Seq",
  purpose = "Purpose",
  portCode = "Port Code",
  portName = "Port Name",
  cargoQuantity = "Cargo Quantity",
  cargoUnit = "Cargo Unit",
  loadingDischargeRate = "Rate",
  loadRate = "Load Rate",
  discharingRate = "Discharing Rate",
  lptt = "LP TT",
  demRate = "Dem Rate",
  desRate = "Des Rate",
  cargoBookId = "CargoBook Id",
  portId = "Id",
  id = "Id"
}

export type CargoBookKeys = keyof typeof CargoBookLabelMap;

export type PortRotationKeys = keyof typeof PortLabelMap;