import mapboxgl from "mapbox-gl";
import { vesselGroupLayers, vesselGroupSource } from "../source";
import { MutableRefObject } from "react";
import { addSourceToMap } from "../utils";
import { generateGeoJson } from "../utils/generate";
import { RouteVoyageTabType } from "@/pages/routeCommon/type";

const useVesselGroup = (mapboxGl: MutableRefObject<mapboxgl.Map>) => {
	const addLayerToMap = (option: {
		layerName: string;
		sourceName: string;
		imageName: string;
		vesselTag?: RouteVoyageTabType;
	}) => {
		if (!mapboxGl.current) return;
		mapboxGl.current.addLayer({
			id: option?.layerName,
			type: "symbol",
			source: option?.sourceName,
			layout: {
				"icon-image":
					option?.vesselTag === "ais"
						? ["concat", "routeAis", ["get", "color"], "VesselIcon"]
						: option?.imageName,
				"icon-size": 0.6,
				"icon-rotate": ["get", "course"],
				"icon-allow-overlap": true
			}
		});
	};

	const loadSource = () => {
		addSourceToMap(mapboxGl.current, {
			sourceName: "vessel-hover-source",
			source: {
				type: "geojson",
				data: generateGeoJson()
			}
		});
		for (let sourceName of Object.values(vesselGroupSource)) {
			addSourceToMap(mapboxGl.current, {
				sourceName: sourceName,
				source: {
					type: "geojson",
					data: generateGeoJson()
				}
			});
		}
	};

	const loadLayers = () => {
		for (let [vesselTag, layerName] of Object.entries(vesselGroupLayers)) {
			addLayerToMap({
				layerName: layerName,
				sourceName: vesselGroupSource[vesselTag],
				imageName: `${vesselTag}VesselIcon`,
				vesselTag: vesselTag as RouteVoyageTabType
			});
		}
		addLayerToMap({
			layerName: "vessel-hover-layer",
			sourceName: "vessel-hover-source",
			imageName: "vesselHoveredIcon"
		});
	};

	return {
		loadSource,
		loadLayers
	};
};

export default useVesselGroup;
