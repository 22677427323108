import React, { FC } from "react";
import style from "./index.module.less";
import { InvoicesVoyageFilterProps } from "./type";
import { FleetInput, FleetSelect } from "@/components";
import { statusOptions } from "./source";

const InvoicesVoyageFilter: FC<InvoicesVoyageFilterProps> = ({
  activeStatus,
  onSearch,
  onStatusSelect
}) => {
  return <div className={style["voyageList-filter"]}>
    <span className="filter-title">VOYAGE LIST</span>
    <div className="filter-container">
      <FleetInput
        value={undefined}
        onChange={onSearch}
        placeholder="Search"
        prefix="LayoutSearchIcon"
        className="filter-search"
      />
      <FleetSelect
        className="filter-select"
        options={statusOptions}
        value={[activeStatus]}
        popupMatchSelectWidth={false}
        onChange={(val: InvoicesVoyageFilterProps["activeStatus"]) => {
          onStatusSelect?.(val)
        }}
      />
    </div>
  </div>
}

export default InvoicesVoyageFilter