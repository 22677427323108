import React, { FC } from "react";
import style from "./index.module.less";
import { InvStatementProps } from "./type";
import { InvoicesViewsContainer } from "../../common";
import useIndex from "./hooks/useIndex";
import { InvStatementBase, InvStatementDesc, InvStatementTemplate } from "./components";
import { CommonFieldAdd } from "@/common";

const InvStatement: FC<InvStatementProps> = ({
	activeInvoice,
	newly,
	activeInvType,
	activeVoyageItem,
	onInvoiceAdd,
	onInvoiceUpdate
}) => {
	const {
		canvasRef,
		loading,
		activeTab,
		handleTabSelect,
		activeField,
		handleFieldOpen,
		handleFieldAdd,
		otherItem,
		handleOtherItemChange,
		dialogItem,
		handleDialogChange,
		baseItem,
		handleBaseItemChange,
		handleBaseItemCommit,
		basicsFieldItems,
		counterPartyFieldItems,
		descItem,
		summary,
		hireItems,
		handleHireCommit,
		handleOffHireCommit,
		handleOffHireAdd,
		handleOffHireDelete,
		bunkerPriceItem,
		handleBunkerPriceCommit,
		otherExpenses,
		handleOtherExpensesChange,
		handleOtherExpensesDelete,
		fixedExpenses,
		handleFixedExpensesChange,
		handleFixedExpensesCommit,
		handleSave,
		handleDownPdf
	} = useIndex(
		activeVoyageItem,
		activeInvoice,
		activeInvType,
		newly,
		onInvoiceUpdate,
		onInvoiceAdd
	);

	return (
		<InvoicesViewsContainer
			loading={loading}
			activeVoyageItem={activeVoyageItem}
			activeInvType="statement"
			activeInvoice={activeInvoice}
			activeTab={activeTab}
			onTabSelect={handleTabSelect}
			period={baseItem?.period}
			total={otherItem?.total}
			onSave={handleSave}
			onDownloadPdf={handleDownPdf}
			formChildren={
				<div className={style["invocies-statement"]}>
					<InvStatementBase
						activeInvType={activeInvType}
						item={baseItem}
						onChange={handleBaseItemChange}
						onCommit={handleBaseItemCommit}
						basicsFieldList={basicsFieldItems}
						counterFieldList={counterPartyFieldItems}
						otherItem={otherItem}
						onFieldOpen={handleFieldOpen}
					/>
					<InvStatementDesc
						item={descItem}
						summary={summary}
						otherItem={otherItem}
						onOtherItemChange={handleOtherItemChange}
						otherExpenses={otherExpenses}
						onOtherExpensesChange={handleOtherExpensesChange}
						onOtherExpensesDelete={handleOtherExpensesDelete}
						fixedExpensesItem={fixedExpenses}
						onFixedExpensesChange={handleFixedExpensesChange}
						onFixedExpensesCommit={handleFixedExpensesCommit}
						bunkerPriceItem={bunkerPriceItem}
						onBunkerPriceCommit={handleBunkerPriceCommit}
						hireItem={hireItems}
						onHireCommit={handleHireCommit}
						onOffHireItemAdd={handleOffHireAdd}
						onOffHireItemCommit={handleOffHireCommit}
						onOffHireItemDelete={handleOffHireDelete}
						onOtherExpensesFieldOpen={handleFieldOpen}
					/>
					<CommonFieldAdd
						hide={dialogItem?.field}
						mode="fixed"
						valueProp={{
							label: "Value",
							type: activeField.current === "otherItems" ? "number" : "text"
						}}
						onClose={() => handleDialogChange("field", true)}
						onAdd={handleFieldAdd}
					/>
				</div>
			}
			previewChildren={
				<InvStatementTemplate
					canvasRef={canvasRef}
					activeTab={activeTab}
					dataSource={{
						baseItem,
						basicsFieldItems,
						counterPartyFieldItems,
						summary,
						hireItem: hireItems,
						fixedExpenses,
						otherExpenses,
						otherItem,
						bunkerPriceItem
					}}
				/>
			}
		/>
	);
};

export default InvStatement;
// {
//   "registry-mirrors":[
//     "https://registry.docker-cn.com",				//国内官方镜像
// 	"https://mirrors.tuna.tsinghua.edu.cn",			//清华镜像
// 	"https://aeckruos.mirror.aliyuncs.com",			//阿里云镜像,需要注册
// 	"http://hub-mirror.c.163.com"，					//网易163镜像
// 	"https://docker.mirrors.ustc.edu.cn",			//中科大镜像
//   ],
//  "insecure-registries": ["192.168.6.129:5000"]		//docker私库地址
// }
