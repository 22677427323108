import { MutableRefObject, useState } from "react";
import {
	RouteCommonMapRefType,
	RouteCommonToolbarMeteoSwitchMap,
	RouteCommonToolbarProps
} from "../components";
import { initialMeteoStatus } from "../source";

const useControlIndex = (mapRef: MutableRefObject<RouteCommonMapRefType>) => {
	const [meteoStatus, setMeteoStatus] = useState<RouteCommonToolbarProps["meteoStatus"]>({
		...initialMeteoStatus
	});

	const handleMeteoStatusChange: RouteCommonToolbarProps["onMeteoStatusChange"] = (key) => {
		const currentMeteoStatus = meteoStatus?.[key] === RouteCommonToolbarMeteoSwitchMap.On;
		const params: Partial<RouteCommonToolbarProps["meteoStatus"]> = {
			[key]: currentMeteoStatus
				? RouteCommonToolbarMeteoSwitchMap.Off
				: RouteCommonToolbarMeteoSwitchMap.On
		};
		debugger;
		switch (true) {
			case key === "Swell" &&
				!currentMeteoStatus &&
				meteoStatus["Wave"] === RouteCommonToolbarMeteoSwitchMap.On:
				params.Wave = RouteCommonToolbarMeteoSwitchMap.Off;
				break;
			case key === "Wave" &&
				!currentMeteoStatus &&
				meteoStatus["Swell"] === RouteCommonToolbarMeteoSwitchMap.On:
				params.Swell = RouteCommonToolbarMeteoSwitchMap.Off;
				break;
			default:
				break;
		}
		setMeteoStatus((prev) => ({ ...prev, ...params }));
		mapRef?.current?.meteoChange?.(key, params?.[key]);
	};

	const handleMeteoStatusInit = () => {
		setMeteoStatus({ ...initialMeteoStatus });
	};

	return {
		meteoStatus,
		handleMeteoStatusChange,
		handleMeteoStatusInit
	};
};

export default useControlIndex;
