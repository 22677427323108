import { ColumnsType } from "antd/es/table";
import { DataType } from "./type";

export const columns: ColumnsType<DataType> = [
	{
		dataIndex: "companyName",
		width: 169,
		title: "Company Name",
		key: "companyName",
		fixed: "left",
		align: "center",
		ellipsis: true
	},
	{
		dataIndex: "bankName",
		width: 120,
		title: "Bank Name",
		key: "bankName",
		fixed: "left",
		align: "center",
		ellipsis: true
	},
	{
		dataIndex: "bankAccountAddress",
		width: 189,
		title: "Bank Account Address",
		key: "bankAccountAddress",
		align: "center",
		ellipsis: true
	},
	{
		dataIndex: "bankAccountNumber",
		width: 189,
		title: "Bank Account Number",
		key: "bankAccountNumber",
		align: "center",
		ellipsis: true
	},
	{
		dataIndex: "beneficiaryName",
		width: 169,
		title: "Beneficiary Name",
		key: "beneficiaryName",
		align: "center",
		ellipsis: true
	},
	{
		dataIndex: "beneficiaryAddress",
		width: 169,
		title: "Beneficiary Address",
		key: "beneficiaryAddress",
		align: "center",
		ellipsis: true
	},
	// {
	//   dataIndex: "beneficiaryCompanyAddress",
	//   width: 230,
	//   title: "Beneficiary Company Address",
	//   key: "beneficiaryCompanyAddress",
	//   align: "center",
	//   ellipsis: true
	// },
	{
		dataIndex: "swiftNumber",
		width: 169,
		title: "Swift Code",
		key: "swiftNumber",
		align: "center",
		ellipsis: true
	}
];