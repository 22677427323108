import React, { FC } from "react";
import style from "./index.module.less";
import { PreOperationNewlyMenuProps } from "./type";
import { voyageTypeOptions } from "./source";

const PreOperationNewlyMenu: FC<PreOperationNewlyMenuProps> = ({ onSelect }) => {
	return (
		<ul className={style["preOperation-voyageTypeMenu"]}>
			{voyageTypeOptions?.map((item, index) => {
				return (
					<li className="voyageTypeMenu-item" key={index} onClick={() => onSelect?.(item?.value)}>
						{item?.label}
					</li>
				);
			})}
		</ul>
	);
};

export default PreOperationNewlyMenu;
