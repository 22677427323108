import { FleetOptionType } from "@/types/advancedType";
import React from "react";
import { RouteCommonVesselInfoPopupItemType } from "./type";
import { formatThousandthNumber } from "@/tools/amount";
import { ArrivalAisStatusMap } from "@/common/Select";
import dayjs from "dayjs";

export const options: (FleetOptionType<
	"Name" | "IMO" | "Speed" | "DWT" | "Draft" | "Ais Status" | "Updated(LT)" | "Destination",
	keyof RouteCommonVesselInfoPopupItemType
> & {
	render?: <T extends keyof RouteCommonVesselInfoPopupItemType>(
		value: RouteCommonVesselInfoPopupItemType[T]
	) => React.ReactNode;
})[] = [
	{
		label: "Name",
		value: "vesselName"
	},
	{
		label: "IMO",
		value: "imo"
	},
	{
		label: "Speed",
		value: "speed",
		render: (value) => {
			return (
				<div>
					<span>{value}</span> kts
				</div>
			);
		}
	},
	{
		label: "DWT",
		value: "dwt",
		render: (value) => {
			return <span>{formatThousandthNumber(value as number, 0, 0)} MT</span>;
		}
	},
	{
		label: "Draft",
		value: "draft",
		render: (value) => {
			return <span>{value} M</span>;
		}
	},
	{
		label: "Ais Status",
		value: "status",
		render: (value) => {
			return (
				<span>{ArrivalAisStatusMap[value as RouteCommonVesselInfoPopupItemType["status"]]}</span>
			);
		}
	},
	{
		label: "Updated(LT)",
		value: "updateTime",
		render: (value) => {
			return <span>{value ? dayjs(value)?.format("YYYY-MM-DD HH:mm:ss") : "--"}</span>;
		}
	},
	{
		label: "Destination",
		value: "desAisPort"
	}
];
