import { FleetOptionType } from "./advancedType";
import { CargoUnitType, VoyageType } from "./common";

export const CargoUnitOptions: FleetOptionType<CargoUnitType, CargoUnitType>[] = [
	{ label: "MT", value: "MT" },
	{ label: "CBM", value: "CBM" }
];

export const voyageTypeOptions: FleetOptionType<VoyageType, VoyageType>[] = [
	{ label: "TCTC", value: "TCTC" },
	{ label: "TCVC", value: "TCVC" }
	// { label: "VCVC", value: "VCVC" }
];