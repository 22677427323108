export const generateGeoJson = <
	G extends GeoJSON.Geometry | null = GeoJSON.Geometry,
	P = GeoJSON.GeoJsonProperties
>(
	features: Array<GeoJSON.Feature<G, P>> = []
): GeoJSON.FeatureCollection<G, P> => {
	return {
		type: "FeatureCollection",
		features
	};
};
