import React from "react";
import {
	useSnackbar,
	ProviderContext,
	VariantType,
	SnackbarMessage,
	SnackbarOrigin,
	SnackbarKey
} from "notistack";
import { uuid } from "@/tools";

enum ReminderTipMap {
	success = "Success",
	error = "Failed",
	warning = "Warning",
	info = "Info",
	default = "Unknown"
}

const useReminder = (key?: SnackbarKey) => {
	const { enqueueSnackbar, closeSnackbar } = useSnackbar();

	const reminder = (
		type: VariantType,
		message: SnackbarMessage,
		position: SnackbarOrigin = {
			vertical: "top",
			horizontal: "center"
		},
		autoHideDuration: number = 1500
	) => {
		enqueueSnackbar(message || ReminderTipMap[type], {
			key: key ?? uuid(),
			variant: type,
			anchorOrigin: position,
			autoHideDuration
		});
	};

	const closeReminder = () => {
		closeSnackbar(key);
	};

	return {
		reminder,
		closeReminder
	};
};

export default useReminder;
