import React, { MutableRefObject } from "react";
import style from "./index.module.less";
import { IdentifierMapType, shippingMapType } from "@/hook/estimation/details/useDetails";
import useGraph from "@/hook/estimation/details/useGraph";

const RouteGraph: React.FC<{
  shippingMap?: MutableRefObject<shippingMapType>;
  identifierMap?: IdentifierMapType;
  deliveryPort?: number[],
  portSeq: ("laden" | "ballast")[]
}> = ({
  shippingMap,
  identifierMap,
  deliveryPort,
  portSeq,
}) => {
    const { mapContainer } = useGraph(portSeq, shippingMap, identifierMap, deliveryPort)

    return <div className={style["fleet-routeGraph"]}>
      <div className="routeGraph" ref={mapContainer}></div>
    </div>
  }

export default RouteGraph;