import React from "react";
import style from "./index.module.less";
import { EstPortRotationProps } from "./type";
import { FleetPrompt } from "@/common";
import { EstPortRotationItem } from "./components";
import useIndex from "./hooks/useIndex";
import { matchPortType } from "@/tools/port";
import { Button } from "@/_components";
import { FleetLoading } from "@/components";

const EstPortRotation: React.FC<EstPortRotationProps> = ({
  hide,
  portType,
  items,
  vesselSource,
  cargoSource,
  onClose,
  onConfirm
}) => {
  const {
    loading,
    dataSource,
    currentQuantity,
    change,
    commit,
    handlePortCommit,
    handleConfirm,
    add,
    handlePortItemDelete
  } = useIndex(
    hide,
    portType,
    vesselSource,
    items,
    onConfirm,
    onClose
  );

  return <FleetPrompt hide={hide}>
    <div className={style["estimation-portRotation"]}>
      <FleetLoading loading={loading} />
      <div className="prompt-header--container">
        <span className="header-title">{portType?.toUpperCase()} Port Rotation</span>
        <span className="font_family fleet-close header-close" onClick={onClose}></span>
      </div>
      <div className="portRotation-container">
        {
          dataSource?.map((item, index) => {
            if (matchPortType(item?.purpose) !== portType) return null
            return <EstPortRotationItem
              key={index}
              portType={portType}
              item={item}
              currentIndex={index}
              cargoSource={cargoSource}
              currentQuantity={currentQuantity}
              onChange={change}
              onCommit={commit}
              onRemove={handlePortItemDelete}
              onPortCommit={handlePortCommit}
              vesselSource={vesselSource}
            />
          })
        }
        <div className="portRotation-add">
          <span
            className="font_family fleet-add add-container"
            onClick={add}
          ></span>
        </div>
      </div>
      <div className="prompt-footer--container">
        <Button size="small" type="secondary" onClick={onClose}>Cancel</Button>
        <Button disabled={loading} size="small" onClick={handleConfirm}>Confirm</Button>
      </div>
    </div>
  </FleetPrompt>
}

export default EstPortRotation