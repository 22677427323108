import { type ApiParameter } from "@/api";
import { appVersion } from "@/api/constant";
import request from "@/service";
import {
	getAccountDetailParams,
	getAccountGroupParams,
	deleteAccountParams,
	updateAccountPayload
} from "./type";

const enum ApiMap {
	getAccountDetailParameter,
	getAccountGroupParameter,
	deleteAccountParameter,
	updateAccountParameter
}

type ApiUrl = keyof typeof ApiMap;

const accountApi: ApiParameter<ApiUrl> = {
	getAccountGroupParameter: {
		method: "get",
		url: appVersion + "/account/page"
	},
	getAccountDetailParameter: {
		method: "get",
		url: appVersion + "/account"
	},
	deleteAccountParameter: {
		method: "delete",
		url: appVersion + "/account/batch"
	},
	updateAccountParameter: {
		method: "post",
		url: appVersion + "/account"
	}
};

export const getAccountGroupApi = (params: getAccountGroupParams) => {
	return request.request({
		...accountApi.getAccountGroupParameter,
		params
	});
};

export const getAccountDetailApi = (params: getAccountDetailParams) => {
	return request.request({
		...accountApi.getAccountDetailParameter,
		url: `${accountApi.getAccountDetailParameter.url}/${params?.id}`
	});
};

export const deleteAccountApi = (params: deleteAccountParams) => {
	return request.request({
		...accountApi.deleteAccountParameter,
		data: params
	});
};

export const updateAccountApi = (params: updateAccountPayload) => {
	return request.request({
		...accountApi.updateAccountParameter,
		method: params.method,
		data: params.params
	});
};
