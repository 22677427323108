import { type ParameterType } from "@/api";
import { loginOutAction } from "@/store/userSlice";

import {
  getHeadFixtureGroupApi,
  getHeadFixtureGroupParams,
  getHeadFixtureApi,
  getHeadFixtureParams,
  addHeadFixtureApi,
  addHeadFixtureParams,
  editHeadFixtureApi,
  delHeadFixtureApi,
  editHeadFixtureParams,
  deleteHeadFixtureParams
} from "@/api/voyageManage/headFixture";

export const getHeadFixtureGroupAction: ParameterType<getHeadFixtureGroupParams> = (
  params,
  frontCallback,
  successCallback,
  errorCallback,
  dispatch,
  navigate
) => {
  frontCallback?.();
  getHeadFixtureGroupApi(params).then(response => {
    successCallback?.(response)
  }).catch(err => {
    errorCallback?.(err);
    if (err?.loginStatus === 'token faild') {
      // dispatch?.(loginOut({}));
      // navigate?.('/')
      dispatch(loginOutAction(() => navigate?.("/login")))
    }
  })
}

export const getHeadFixtureAction: ParameterType<getHeadFixtureParams> = (
  params,
  frontCallback,
  successCallback,
  errorCallback,
  dispatch,
  navigate
) => {
  frontCallback?.();
  getHeadFixtureApi(params).then(response => {
    successCallback?.(response)
  }).catch(err => {
    errorCallback?.(err);
    if (err?.loginStatus === 'token faild') {
      // dispatch?.(loginOut({}));
      // navigate?.('/')
      dispatch(loginOutAction(() => navigate?.("/login")))
    }
  })
}

export const addHeadFixtureAction: ParameterType<addHeadFixtureParams> = (
  params,
  frontCallback,
  successCallback,
  errorCallback,
  dispatch,
  navigate
) => {
  frontCallback?.();
  addHeadFixtureApi(params).then(response => {
    successCallback?.(response)
  }).catch(err => {
    errorCallback?.(err);
    if (err?.loginStatus === 'token faild') {
      // dispatch?.(loginOut({}));
      // navigate?.('/')
      dispatch(loginOutAction(() => navigate?.("/login")))
    }
  })
}

export const editHeadFixtureAction: ParameterType<editHeadFixtureParams> = (
  params,
  frontCallback,
  successCallback,
  errorCallback,
  dispatch,
  navigate
) => {
  frontCallback?.();
  editHeadFixtureApi(params).then(response => {
    successCallback?.(response)
  }).catch(err => {
    errorCallback?.(err);
    if (err?.loginStatus === 'token faild') {
      // dispatch?.(loginOut({}));
      // navigate?.('/')
      dispatch(loginOutAction(() => navigate?.("/login")))
    }
  })
}

export const delHeadFixtureAction: ParameterType<deleteHeadFixtureParams> = (
  params,
  frontCallback,
  successCallback,
  errorCallback,
  dispatch,
  navigate
) => {
  frontCallback?.();
  delHeadFixtureApi(params).then(response => {
    successCallback?.(response)
  }).catch(err => {
    errorCallback?.(err);
    if (err?.loginStatus === 'token faild') {
      // dispatch?.(loginOut({}));
      // navigate?.('/')
      dispatch(loginOutAction(() => navigate?.("/login")))
    }
  })
}