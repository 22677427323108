import { FleetOptionType } from "@/types/advancedType";
import { OtherReportsFormNameType } from "./type";
export const typeList: FleetOptionType<
  "Departure Report" | "Arrival Report" | "Shifting Report",
  OtherReportsFormNameType
>[] = [
  { value: "departureReport", label: "Departure Report" },
  { value: "arrivalReport", label: "Arrival Report" },
  { value: "shiftingReport", label: "Shifting Report" },
];
