import { FleetOptionType } from "@/types/advancedType";
import { VoyageStatusType } from "./type";
import { IconKeys } from "@/components/Icon/icons";

export const MovementVoaygeStatusOptions: (FleetOptionType<VoyageStatusType, VoyageStatusType> & {
	icon: IconKeys;
})[] = [
	{
		label: "Commencing",
		value: "Commencing",
		icon: "VoyageStatusComencingIcon"
	},
	{
		label: "Completed",
		value: "Completed",
		icon: "VoyageStatusCompletedIcon"
	},
	{
		label: "Closed",
		value: "Closed",
		icon: "VoyageStatusClosedIcon"
	}
];