import { ExcludeKeys } from "@/types";
import { VoyageType } from "@/types/common";
import { useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { InvoicesViewsProps, InvoicesVoyageListProps } from "../components";

const useIndex = () => {
	const [searchParams] = useSearchParams();
	const voyageId = searchParams.get("voyageId");
	const voyageType = searchParams.get("voyageType") as ExcludeKeys<VoyageType, "VCVC">;
	const navigate = useNavigate();
	const [activeVoyageItem, setActiveVoyageItem] = useState<
		InvoicesVoyageListProps["activeVoyageItem"]
	>({
		id: voyageId || null,
		voyageType: voyageType || null
	});

	const [newly, setNewly] = useState<InvoicesViewsProps["newly"]>({
		status: -1,
		type: "first"
	});

	const [activeInvType, setActiveInvType] =
		useState<InvoicesViewsProps["activeInvType"]>("freightInv");

	const handleInvTypeSelect: InvoicesViewsProps["onInvTypeSelect"] = (type) => {
		setActiveInvType(type);
	};

	const handleNewlyCommit: InvoicesViewsProps["onNewlyCommit"] = (item) => {
		setNewly((prev) => ({ ...prev, ...item }));
	};

	const handleVoyageSelect: InvoicesVoyageListProps["onVoyageSelect"] = (item) => {
		if (activeVoyageItem?.id === item?.id && activeVoyageItem?.voyageType === item?.voyageType)
			return;
		setActiveVoyageItem(item);
		switch (true) {
			case item?.voyageType === "TCVC" && ["rent", "relet"]?.includes(activeInvType):
				setActiveInvType("freightInv");
				handleNewlyCommit({ status: -1 });
				break;
			case item?.voyageType === "TCTC" && ["freightInv", "statement"]?.includes(activeInvType):
				setActiveInvType("rent");
				handleNewlyCommit({ status: -1 });
				break;
			default:
				break;
		}
		navigate(`/layout/voyagemanage/invoices` + (item?.id ? `?voyageId=${item?.id}` : ""), {
			replace: true
		});
	};

	return {
		activeVoyageItem,
		handleVoyageSelect,
		activeInvType,
		handleInvTypeSelect,
		newly,
		handleNewlyCommit
	};
};

export default useIndex;
