import React, { FC, Fragment } from "react";
import style from "./index.module.less";
import { PortVesselFilterItemType, PortVesselFilterProps } from "./type";
import {
	AreaVesselFilterItem,
	RouteCommonAreaSelectItemType,
	RouteCommonCountrySelectItemType,
	RouteCommonOtherSelect
} from "./common";
import { aisStatusOptions, vesselSubTypeOptions } from "./source";
import { ArrivalAisStatusMap, ArrivalVesselSubTypeMap } from "@/common/Select";
import { FleetFormItem, FleetRangePicker } from "@/components";
import dayjs, { Dayjs } from "dayjs";
import { FleetCustomRadio } from "@/components/Radio";
import { useRouteCommonContext } from "@/pages/routeCommon/store";

const PortVesselFilter: FC<PortVesselFilterProps> = ({
	item,
	timeRange,
	onTimeRangeCommit,
	onCommit
}) => {
	const { activePortItem } = useRouteCommonContext();
	return (
		<div className={style["areaVessel-filter"]}>
			<span className="areaVessel-filter-title">Filters</span>
			<div className="areaVessel-filter-container">
				<div className="areaVessel-filter-scrollArea">
					<FleetFormItem label="Period">
						<FleetRangePicker
							allowClear
							value={[
								timeRange.startDate ? dayjs(timeRange.startDate) : null,
								timeRange.endDate ? dayjs(timeRange.endDate) : null
							]}
							onChange={(vals: [Dayjs, Dayjs]) => {
								onTimeRangeCommit({
									startDate: vals?.[0].format("YYYY-MM-DD"),
									endDate: vals?.[1].format("YYYY-MM-DD")
								});
							}}
							disabledDate={(current) => {
								console.log("activePortItem", activePortItem);
								switch (true) {
									case !!activePortItem?.startDate && !!activePortItem?.endDate:
										return (
											current < dayjs(activePortItem?.startDate) ||
											current > dayjs(activePortItem?.endDate)
										);
									case !!activePortItem?.startDate:
										return current < dayjs(activePortItem?.startDate);
									case !!activePortItem?.endDate:
										return current > dayjs(activePortItem?.endDate);
									case activePortItem?.type === 1:
										return current < dayjs()?.startOf("day");
									case activePortItem?.type === 3:
										return current > dayjs()?.startOf("day");
									default:
										return false;
								}
							}}
						/>
					</FleetFormItem>
					<AreaVesselFilterItem<keyof typeof ArrivalVesselSubTypeMap, ArrivalVesselSubTypeMap>
						label="Vessel Type Sub"
						selectRows={item?.vesselTypeSub}
						options={vesselSubTypeOptions}
						onSelect={(key, checked) => {
							const currentItem = [...item?.vesselTypeSub];
							if (checked) {
								currentItem?.push(key);
							} else {
								const currentIndex = currentItem?.findIndex((selectItem) => selectItem === key);
								currentItem?.splice(currentIndex, 1);
							}
							onCommit?.({ vesselTypeSub: currentItem });
						}}
					/>
					<AreaVesselFilterItem<keyof typeof ArrivalAisStatusMap, ArrivalAisStatusMap>
						label="AIS Status"
						selectRows={item?.aisStatus}
						options={aisStatusOptions}
						onSelect={(key, checked) => {
							const currentItem = [...item?.aisStatus];
							if (checked) {
								currentItem?.push(key);
							} else {
								const currentIndex = currentItem?.findIndex((selectItem) => selectItem === key);
								currentItem?.splice(currentIndex, 1);
							}
							onCommit?.({ aisStatus: currentItem });
						}}
					/>
					<AreaVesselFilterItem<keyof PortVesselFilterItemType["lastPortCountry"][number], string>
						label="Last Country Name"
						selectCount={item?.lastPortCountry?.length}
						render={() => (
							<Fragment>
								<RouteCommonOtherSelect<RouteCommonCountrySelectItemType>
									type="country"
									showKey="countryName"
									primaryKey="countryCode"
									onClear={() => {
										onCommit?.({ lastPortCountry: [] });
									}}
									activeItems={item?.lastPortCountry}
									onFinish={(newItem, type) => {
										const currentItem = [...item?.lastPortCountry];
										const currentIndex = currentItem?.findIndex(
											(selectItem) => selectItem?.countryCode === newItem?.countryCode
										);
										if (currentIndex === -1) {
											currentItem?.push(newItem);
										} else {
											currentItem?.splice(currentIndex, 1);
										}
										onCommit?.({ lastPortCountry: currentItem });
									}}
								/>
								{item?.lastPortCountry?.map((countryItem, countryIndex) => {
									return (
										<FleetCustomRadio
											value={true}
											key={countryItem?.countryCode}
											className="filter-item-select"
											onChange={(checked) => {
												const currentItem = [...item?.lastPortCountry];
												const currrentIndex = currentItem?.findIndex(
													(selectItem) => selectItem.countryCode === countryItem?.countryCode
												);
												currentItem?.splice(currrentIndex, 1);
												onCommit?.({ lastPortCountry: currentItem });
											}}
											label={countryItem?.countryName}
										/>
									);
								})}
							</Fragment>
						)}
					/>
					<AreaVesselFilterItem<keyof PortVesselFilterItemType["lastAreaList"][number], string>
						label="Last Area Name"
						selectCount={item?.lastAreaList?.length}
						onSelect={(key, checked) => {}}
						render={() => (
							<Fragment>
								<RouteCommonOtherSelect<RouteCommonAreaSelectItemType>
									type="area"
									showKey="areaName"
									primaryKey="areaCode"
									onClear={() => {
										onCommit?.({ lastAreaList: [] });
									}}
									activeItems={item?.lastAreaList}
									onFinish={(newItem, type) => {
										const currentItem = [...item?.lastAreaList];
										const currentIndex = currentItem?.findIndex(
											(selectItem) => selectItem?.areaCode === newItem?.areaCode
										);
										if (currentIndex === -1) {
											currentItem?.push(newItem);
										} else {
											currentItem?.splice(currentIndex, 1);
										}
										onCommit?.({ lastAreaList: currentItem });
									}}
								/>
								{item?.lastAreaList?.map((areaItem, areaIndex) => {
									return (
										<FleetCustomRadio
											value={true}
											key={areaItem?.areaCode}
											className="filter-item-select"
											onChange={(checked) => {
												const currentItem = [...item?.lastAreaList];
												const currrentIndex = currentItem?.findIndex(
													(selectItem) => selectItem.areaCode === areaItem?.areaCode
												);
												currentItem?.splice(currrentIndex, 1);
												onCommit?.({ lastAreaList: currentItem });
											}}
											label={areaItem?.areaName}
										/>
									);
								})}
							</Fragment>
						)}
					/>
				</div>
			</div>
		</div>
	);
};

export default PortVesselFilter;
