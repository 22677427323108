import React, { FC } from "react";
import classnames from "classnames";
import style from "./index.module.less";
import { MonitoringAreaListProps } from "./type";
import { FleetInput } from "@/components";
import { MonitoringAreaPreset, MonitoringAreaSelected } from "./components/index";
import useIndex from "./hooks/useIndex";

const MonitoringAreaList: FC<MonitoringAreaListProps> = ({
	activeTab,
	items,
	onAreaDelete,
	onAreaReset,
	onAreaSelect,
	onOpenDetailTab
}) => {
	const { loading, areaList, handleInput, handleCheckChange, handleClearAreaSelect } = useIndex(
		activeTab,
		items,
		onAreaDelete,
		onAreaReset,
		onAreaSelect,
		onOpenDetailTab
	);
	return (
		<div
			className={classnames(style["monitoring-list-area"], {
				[style["monitoring-list-area--active"]]: activeTab === "area"
			})}
		>
			<div className="monitoring-area-header">
				<span className="monitoring-list-total">Search Area({areaList.length})</span>
				<FleetInput
					value={undefined}
					prefix="LayoutSearchIcon"
					placeholder="Search	"
					onChange={handleInput}
				/>
			</div>

			<div className="monitoring-area-container">
				<div className="monitoring-area-box">
					<MonitoringAreaPreset
						areaList={areaList}
						onChange={handleCheckChange}
						loading={loading}
					/>
					<MonitoringAreaSelected areaSelectList={items} onClear={handleClearAreaSelect} />
				</div>
			</div>
		</div>
	);
};

export default MonitoringAreaList;
