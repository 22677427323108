import { useCommonIndex } from "@/hook"
import { initialStatementFixedExpensesItem } from "../source"
import { InvStatementFixedExpensesProps } from "../type"
import { useMemo } from "react"

const useIndex = (
  otherItem: Pick<InvStatementFixedExpensesProps["otherItem"], "addFixed">
) => {
  const {
    dataSource,
    change,
    commit,
    init
  } = useCommonIndex<InvStatementFixedExpensesProps["item"]>(initialStatementFixedExpensesItem)

  const summary = useMemo(() => {
    return otherItem?.addFixed ? (+dataSource?.cve + +dataSource?.ilohc) : 0
  }, [dataSource, otherItem?.addFixed])

  return {
    dataSource,
    summary,
    change,
    commit,
    init
  }
}

export default useIndex