import { MovementBunkerPlanType } from "./type";

export const initialBunkerPlan = (item?: Partial<MovementBunkerPlanType>): MovementBunkerPlanType => {
  return {
		id: item?.id ?? null,
		scrubberBenefit: item?.scrubberBenefit ?? 0,
		scrubberPrice: item?.scrubberPrice ?? "",
		scrubberTotalPrice: item?.scrubberTotalPrice ?? 0,
		hsfoTotalPrice: item?.hsfoTotalPrice ?? 0,
		lsfoTotalPrice: item?.lsfoTotalPrice ?? 0,
		mdoTotalPrice: item?.mdoTotalPrice ?? 0,
		mgoTotalPrice: item?.mgoTotalPrice ?? 0
	};
}