import React, { useEffect, useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";
import debounce from "lodash/debounce";
import { CommonRoleWrap, FleetToast } from "@/common";
import { FleetSelect } from "@/components";
import { useAppDispatch } from "@/hook";
import useCheckGroup from "@/hook/common/useCheckGroup";
import useReminder from "@/hook/useReminder";
import {
	MovementCategoryGroupType,
	MovementCategoryKeys,
	MovementGroupColumnKeys,
	initMovementCategoryGroup,
	columns as list_columns
} from "@/types/voyageManage/movement/list";
import { Dayjs } from "dayjs";
import {
	getMovementGroupAction,
	deleteMovementAction,
	editMovementVoyageStatusAction
} from "@/action/voyageManage/movement";
import { Popover } from "@/_components";

const useGroup = () => {
	const dispatch = useAppDispatch();
	const navigate = useNavigate();
	const { reminder } = useReminder();
	const [hide, setHide] = useState(true);
	const [editHide, setEditHide] = useState(true);
	const [recordId, setRecordId] = useState(null);
	const [dataSource, setDataSource] = useState([]);
	const [total, setToal] = useState<number>(0);
	const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([]);
	const [ids, setIds] = useState<string>("");
	const [pageNum, setPageNum] = useState<number>(1);
	const [pageSize, setPageSize] = useState<number>(20);
	const [search, setSearch] = useState("");
	const [loading, setLoading] = useState(false);
	const [layCan, setLayCan] = useState<[Dayjs | undefined, Dayjs | undefined]>([
		undefined,
		undefined
	]);
	const [statusList, setStatusList] = useState<string[]>(["Commencing", "Completed"]);
	const {
		checkGroup,
		reset: handleReset,
		change: handleChange
	} = useCheckGroup<MovementCategoryGroupType, MovementCategoryKeys, MovementGroupColumnKeys>(
		initMovementCategoryGroup
	);

	const delMovementMultiple = (type: "multiple" | "single", id?: string) => {
		deleteMovementAction(
			{ ids: type === "multiple" ? selectedRowKeys.join(",") : id },
			getMovementGroupFront,
			delMovementResultSuccess,
			getMovementGroupError,
			dispatch,
			navigate
		);
	};

	const delMovementResultSuccess = (response) => {
		reminder("success", response?.msg);
		setIds("");
		setSelectedRowKeys([]);
		getMovementGroup();
	};

	const getMovementGroupError = (error) => {
		reminder("error", error?.msg ? error?.msg + ": " + error?.data : error?.data);
		setLoading(false);
	};

	const editMovementVoyageStatus = (voyageId: string | number, status: string) => {
		editMovementVoyageStatusAction(
			{
				id: voyageId,
				status
			},
			getMovementGroupFront,
			(response) => {
				setDataSource((prev) => {
					const index = prev?.findIndex((item) => item?.id === voyageId);
					const voyageItem = prev[index];
					voyageItem.status = status;
					return [...prev];
				});
				setLoading(false);
				reminder("success", response?.msg);
			},
			getMovementGroupError,
			dispatch,
			navigate
		);
	};

	const columns = useMemo(() => {
		let column_keys = [];
		Object.values(checkGroup).forEach((item) => {
			column_keys.push(...item);
		});
		console.log("list_columns", list_columns, column_keys);
		let _columns = list_columns.filter((column) => column_keys.includes(column.key));
		console.log("_columns", _columns);
		_columns.push({
			dataIndex: "option",
			width: 175,
			title: "Action",
			key: "option",
			align: "center",
			fixed: "right",
			render: (value, record, index) => {
				return (
					<div className="td-operate">
						<CommonRoleWrap roleStr="movement:list:commence">
							<FleetSelect
								popupMatchSelectWidth={false}
								className="status-select"
								options={[
									{
										label: "Commencing",
										value: "Commencing"
									},
									{
										label: "Completed",
										value: "Completed"
									},
									{
										label: "Closed",
										value: "Closed"
									}
								]}
								value={record?.status}
								onChange={(val: string) => {
									// if (record?.status === "closed") {
									//   reminder("error", "Voyage was closed");
									//   return
									// }
									editMovementVoyageStatus(record?.id, val);
								}}
							/>
						</CommonRoleWrap>
						{/* <span className="operate-btn" > Send to VM </span> */}
						<CommonRoleWrap roleStr="movement:list:delete">
							<Popover
								// iconProps={{
								//   type: "FleetEslistDeleteIcon",
								//   outerLayerClassname: "operate-icon"
								// }}
								render={(setIsOpen) => {
									return (
										<FleetToast
											label="Are you sure delete this item?"
											onCancel={() => {
												setIsOpen(false);
											}}
											onConfirm={() => {
												delMovementMultiple("single", record?.id);
												setIsOpen(false);
											}}
										/>
									);
								}}
							>
								<span className="font_family fleet-delete" style={{ color: "red" }}></span>
							</Popover>
						</CommonRoleWrap>
					</div>
				);
			}
		});
		return _columns;
	}, [checkGroup]);

	const onSelectChange = (newSelectedRowKeys: React.Key[]) => {
		setSelectedRowKeys(newSelectedRowKeys);
		setIds(newSelectedRowKeys?.join(","));
	};

	const getMovementGroupFront = () => {
		setLoading(true);
	};

	const getMovementSuccess = (response) => {
		if (!Array.isArray(response?.data)) {
			setDataSource(response?.data?.items);
			setToal(response?.data?.totalCount);
		} else {
			setDataSource([]);
			setToal(0);
		}
		setPageNum(1);
		setLoading(false);
	};

	const onPageChangeSuccess = (response) => {
		if (!Array.isArray(response?.data)) {
			setDataSource((val) => [...val, ...response?.data?.items]);
		}
		setLoading(false);
	};

	const getMovementError = (error) => {
		reminder("error", error?.msg ? error?.msg + ": " + error?.data : error?.data);
		setLoading(false);
	};

	const getMovementGroup = (searchVal?: string) => {
		getMovementGroupAction(
			{
				pageNum: 1,
				pageSize,
				keyword: searchVal ?? search,
				actualCommenceDate: layCan?.[0]?.valueOf(),
				actualCompleteDate: layCan?.[1]?.valueOf(),
				statusList: statusList?.join(",")
			},
			getMovementGroupFront,
			getMovementSuccess,
			getMovementError,
			dispatch,
			navigate
		);
	};

	const onPageChange = (page: number, size: number) => {
		setPageNum(page);
		if (dataSource?.length >= total) return;
		if (page * size <= dataSource?.length) return;
		getMovementGroupAction(
			{
				pageNum: page,
				pageSize,
				keyword: search,
				actualCommenceDate: layCan?.[0]?.valueOf(),
				actualCompleteDate: layCan?.[1]?.valueOf(),
				statusList: statusList?.join(",")
			},
			getMovementGroupFront,
			onPageChangeSuccess,
			getMovementError,
			dispatch,
			navigate
		);
	};

	const onSearch = useMemo(() => {
		const loadOptions = (val: string) => {
			setDataSource([]);
			getMovementGroup(val);
		};
		return debounce(loadOptions, 500);
	}, [search, layCan]);

	const onRow = (record) => {
		return {
			onDoubleClick: () => {
				// navigate(`/layout/voyagemanage/movement/details?id=${record?.id}`);
				window.open(
					`/layout/voyagemanage/movement/details?id=${record?.id}&voyageType=${record?.voyageType}`,
					"_blank"
				);
			}
		};
	};

	useEffect(() => {
		getMovementGroup();
	}, [search, layCan, statusList]);

	return {
		statusList,
		setStatusList,
		search,
		checkGroup,
		layCan,
		ids,
		selectedRowKeys,
		pageNum,
		pageSize,
		columns,
		dataSource,
		loading,
		total,
		hide,
		recordId,
		editHide,
		navigate,
		setHide,
		setEditHide,
		onLayCanChange: setLayCan,
		handleReset,
		handleChange,
		onPageChange,
		onSelectChange,
		onKeywordChange: setSearch,
		onIdsChange: setIds,
		onSearch,
		onDeleteItems: delMovementMultiple,
		onReset: getMovementGroup,
		onRow
	};
};

export default useGroup;
