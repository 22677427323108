import { CargoAndPortItemType } from "./type";

export const initalNewlyCargoAndPort = (item: CargoAndPortItemType | null): CargoAndPortItemType => {
  return {
    chartererName: "",
    cargoName: "",
    cargoQuantity: "",
    layCanFrom: null,
    layCanTo: null,
    cargoUnit: "MT",
    cargoQuantityDiscrepancy: "",
    loadPortCode: "",
    loadPortName: "",
    loadPortExpense: "",
    loadPurpose: "LD",
    loadRate: "",
    loadWorkDays: "",
    dischargePortName: "",
    dischargePortCode: "",
    dischargePortExpense: "",
    dischargePurpose: "DC",
    dischargeRate: "",
    dischargeWorkDays: "",
    commission: 3.75,
    brokerage: "",
    freightType: "FR",
    freightRate: "",
    ...item
  }
}

export const checkKeys: (keyof CargoAndPortItemType)[] = [
  "cargoQuantity", "freightRate",
  "loadPortCode", "dischargePortCode",
  "loadPortName", "dischargePortName"
]