import {
	addAisStarAction,
	getAisGroupAction,
	getAisStarGroupAction
} from "@/action/monitoring/aisGroup";
import { useAppDispatch } from "@/hook";
import useReminder from "@/hook/useReminder";
import { AISInfo } from "@/pages/monitoring/components";
import { useEffect, useMemo, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { MonitorAisGroupProps } from "../type";
import debounce from "lodash/debounce";
import { MonitoringAisItemProps } from "../../aisItem/type";
import { useMonitoringContext } from "@/pages/monitoring/MonitoringContext";

const useIndex = (
	activeTab: MonitorAisGroupProps["activeTab"],
	onItemClick: MonitorAisGroupProps["onItemClick"],
	onVesselLoad: MonitorAisGroupProps["onVesselLoad"]
) => {
	const dispatch = useAppDispatch();
	const navigate = useNavigate();
	const { reminder } = useReminder();

	const { currentAis, setCurrentAis } = useMonitoringContext();

	const [open, setOpen] = useState(false);
	const [keyword, setKeyword] = useState<string>("");
	const [loading, setLoading] = useState<boolean>(false);
	const [selectLoading, setSelectLoading] = useState(false);
	const pager = useRef<{
		total: number;
		pageNum: number;
		pageSize: number;
	}>({
		total: 0,
		pageNum: 1,
		pageSize: 20
	});

	const [dataSource, setDataSource] = useState<AISInfo[]>([]);
	const [selectList, setSelectList] = useState<AISInfo[]>([]);

	const selectValue = useMemo(() => {
		return [currentAis?.vesselNameEn];
	}, [selectList, currentAis]);

	const getAisStarGroupFront = () => {
		setLoading(true);
	};

	const getAisGroupFront = () => {
		setSelectLoading(true);
	};

	const getAisStarGroupSuccess = (response) => {
		const { data } = response;
		setLoading(false);
		setDataSource(data);
		onVesselLoad?.(data);
		if (currentAis && !data?.find((item) => item?.imo === currentAis?.imo)) {
			setCurrentAis(null);
			onItemClick?.(null);
		}
	};

	const getAisGroupFailed = (error) => {
		setSelectLoading(false);
		reminder("error", error?.msg ? error?.msg + ": " + error?.data : error?.data);
	};

	const getAisGroupSuccess = (response) => {
		setSelectLoading(false);
		if ((pager.current.pageNum + 1) * pager.current.pageSize < response?.data?.totalCount) {
			setSelectList((prev) => [...prev, ...response?.data?.items]);
		} else {
			setSelectList(response?.data?.items);
		}
		pager.current = {
			...pager.current,
			total: response?.data?.totalCount,
			pageNum: pager.current.pageNum + 1
		};
	};

	const getAisStarGroupFailed = (error) => {
		setLoading(false);
		reminder("error", error?.msg ? error?.msg + ": " + error?.data : error?.data);
	};

	const getAisStarGroup = () => {
		getAisStarGroupAction(
			null,
			getAisStarGroupFront,
			getAisStarGroupSuccess,
			getAisStarGroupFailed,
			dispatch,
			navigate
		);
	};

	const getAisGroup = (searchVal?: string) => {
		if (selectList?.length !== 0 && selectList?.length >= pager?.current.total) return;
		getAisGroupAction(
			{
				keyword: searchVal ?? keyword,
				pageNum: pager.current.pageNum,
				pageSize: pager.current.pageSize
			},
			getAisGroupFront,
			getAisGroupSuccess,
			getAisGroupFailed,
			dispatch,
			navigate
		);
	};

	const addAisStarSuccess = (response) => {
		getAisStarGroup();
	};

	const handleSearch = useMemo(() => {
		const loadOptions = (val: string) => {
			pager.current.pageNum = 1;
			pager.current = {
				...pager.current,
				pageNum: 1,
				total: 0
			};
			setSelectList([]);
			setKeyword(val);
			getAisGroup(val);
		};
		return debounce(loadOptions, 800);
	}, []);

	const handleItemClick = (item: AISInfo) => {
		let isStar = dataSource?.find((starItem) => starItem?.imo === item?.imo);
		if (item.imo !== currentAis?.imo) {
			setCurrentAis(item);
		}
		setOpen(false);
		onItemClick?.(item, isStar ? true : false);
	};

	const handleAisStarCancel: MonitoringAisItemProps["onAisStarCancel"] = (item) => {
		addAisStarAction(
			{ imoList: [item?.imo], type: 0 },
			getAisStarGroupFront,
			addAisStarSuccess,
			getAisGroupFailed,
			dispatch,
			navigate
		);
	};

	const handleClosePopup = (open: boolean) => {
		if (!open) {
			// setSearchVal("");
			// setPager(prev => ({ ...prev, total: 0, pageNum: 1 }))
		}
		setOpen(open);
	};

	const handleResetSuccess = (response) => {
		const { data } = response;
		setLoading(false);
		setDataSource(data);
		onVesselLoad?.(data);
	};

	const handleReset = () => {
		getAisStarGroupAction(
			null,
			getAisStarGroupFront,
			handleResetSuccess,
			getAisStarGroupFailed,
			dispatch,
			navigate
		);
	};

	const onGetIsFollow = (item: AISInfo) => {
		let isStar = dataSource?.find((starItem) => starItem?.imo === item?.imo);
		return !!isStar;
	};

	useEffect(() => {
		if (activeTab !== "ais") {
			setSelectList([]);
			return;
		}
		getAisStarGroup();
	}, [activeTab]);

	return {
		open,
		keyword,
		loading,
		dataSource,
		selectList,
		currentAis,
		selectValue,
		selectLoading,
		handleItemClick,
		handleClosePopup,
		getAisGroup,
		handleAisStarCancel,
		handleSearch,
		handleReset,
		onGetIsFollow
	};
};

export default useIndex;
