import { useEffect, useState } from "react"
import { PortDaNewlyFormChangeEvent, PortDaNewlyFormCommitEvent, PortDaNewlyFormType } from "../type"
import { checkPdaKeys, initialNewlyForm } from "../source"
import { getPortDaDetailsAction, updatePortDaAction } from "@/action/dataBase/portDa"
import { useAppDispatch } from "@/hook"
import { useNavigate } from "react-router-dom"
import useReminder from "@/hook/useReminder"
import { useTranslation } from "react-i18next";
const useIndex = (
  hide: boolean,
  onReset: () => void,
  type: "add" | "edit",
  id?: number
) => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { t } = useTranslation()
  const { reminder } = useReminder();
  const [loading, setLoading] = useState(false)
  const [dataSource, setDataSource] = useState<PortDaNewlyFormType>({ ...initialNewlyForm })

  const handleChange: PortDaNewlyFormChangeEvent = (key, value) => {
    setDataSource(prev => ({ ...prev, [key]: value }))
  }

  const handleCommit: PortDaNewlyFormCommitEvent = (item) => {
    setDataSource(prev => ({ ...prev, ...item }))
  }

  const check = () => {
    let checkKeys = "";
    let checked = true;
    for (let key of checkPdaKeys) {
      if (dataSource[key] !== 0 && !dataSource[key]) {
        checkKeys = key;
        checked = false;
        break
      }
    }

    return {
      checkKeys,
      checked
    }
  }

  const getPortDaDetailsFront = () => {
    setLoading(true)
  }

  const getPortDaDetailsSuccess = (response) => {
    setDataSource(response.data)
    setLoading(false)
  }

  const updatePortDaSuccess = (response) => {
    reminder("success", response?.msg)
    setLoading(false)
    onReset?.()
  }

  const getPortDaDetailsFailed = (error) => {
    setLoading(false)
    reminder("error", error?.msg ? (error?.msg + ": " + error?.data) : error?.data);
  }

  const getPortDaDetails = () => {
    getPortDaDetailsAction(
      { id: id },
      getPortDaDetailsFront,
      getPortDaDetailsSuccess,
      getPortDaDetailsFailed,
      dispatch,
      navigate
    )
  }

  const updatePortDa = () => {
    const { checkKeys, checked } = check();
    if (!checked) {
      reminder("error", `Field ${t(`dataBase.portPDA.${checkKeys}`)} is required`);
      return
    }
    updatePortDaAction(
      { params: dataSource, method: type === "edit" ? "put" : "post" },
      getPortDaDetailsFront,
      updatePortDaSuccess,
      getPortDaDetailsFailed,
      dispatch,
      navigate
    )
  }

  useEffect(() => {
    if (hide) {
      setDataSource({ ...initialNewlyForm })
      return
    }
    if (type === "add" || !id) return
    getPortDaDetails()
  }, [hide])

  return {
    loading,
    dataSource,
    handleChange,
    handleCommit,
    updatePortDa
  }
}

export default useIndex