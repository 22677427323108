import React, { forwardRef, useImperativeHandle, useState, useEffect } from "react";
import { FleetButton } from "@/components";
import classnames from "classnames";
import style from "./index.module.less";

const MeasureControl = forwardRef((props, ref) => {
	const [measureControl, setMeasureControl] = useState(null);
	const [isGreatCircle, setIsGreatCircle] = useState(null);
	const [lineTabIndex, setLineTabIndex] = useState(1);
	const [circleTabIndex, setCircleTabIndex] = useState(1);

	const createMeasureController = (map) => {
		const measureControl = L.control
			.polylineMeasure({
				showBearings: true,
				clearMeasurementsOnStop: true,
				showClearControl: false,
				showUnitControl: false,
				position: "topright"
			})
			.addTo(map);
		setMeasureControl(measureControl);
		// map.on('contextmenu', mapContextMenu.bind(this, measureControl))
	};

	const switchMeasure = (isGreatCircleParam) => {
		console.log(
			"isGreatCircleParam",
			isGreatCircleParam,
			measureControl._measuring,
			measureControl.options.greatCircle
		);
		if (measureControl.options.greatCircle === isGreatCircleParam) {
			setIsGreatCircle(null);
			measureControl.options.greatCircle = null;
			measureControl._toggleMeasure(false);
		} else {
			setIsGreatCircle(isGreatCircleParam);
			measureControl.options.greatCircle = isGreatCircleParam;
			if (!measureControl._measuring) {
				measureControl._toggleMeasure();
			}
		}
		if (isGreatCircleParam === true) {
			setLineTabIndex(2);
			setCircleTabIndex(1);
		} else if (isGreatCircleParam === false) {
			setLineTabIndex(1);
			setCircleTabIndex(2);
		}
	};

	useImperativeHandle(ref, () => ({
		createMeasureController
	}));

	useEffect(() => {
		//监听键盘事件
		document.addEventListener("keydown", popupKeydown, false);
		document.addEventListener("keyup", popupKeyup, false);
		return () => {
			//销毁键盘事件
			document.removeEventListener("keydown", popupKeydown, false);
			document.removeEventListener("keyup", popupKeyup, false);
		};
	});
	//键盘事件函数
	const popupKeydown = (e) => {
		if (e.code === "Tab") {
			e.returnValue = false;
		}
	};
	//键盘事件函数
	const popupKeyup = (e) => {
		if (e.code === "Tab") {
			if (isGreatCircle != null) {
				switchMeasure(!measureControl.options.greatCircle);
			}
			e.returnValue = false;
		} else if (e.code === "Escape") {
			if (isGreatCircle != null) {
				switchMeasure(isGreatCircle);
			}
			e.returnValue = false;
		}
	};

	return (
		<div className={classnames([style.MeasureControlContainer])}>
			{isGreatCircle === false ? (
				<div className={style.active}>
					<FleetButton
						tabIndex={lineTabIndex}
						type="text"
						icon="MonitoringMeasureLineChecked"
						onClick={() => switchMeasure(false)}
					>
						Rhumb Line
					</FleetButton>
				</div>
			) : (
				<div className={style.nonActive}>
					<FleetButton
						tabIndex={lineTabIndex}
						type="text"
						icon="MonitoringMeasureLine"
						onClick={() => switchMeasure(false)}
					>
						Rhumb Line
					</FleetButton>
				</div>
			)}
			{isGreatCircle === true ? (
				<div className={style.active}>
					<FleetButton
						tabIndex={circleTabIndex}
						type="text"
						icon="MonitoringMeasureCircleChecked"
						onClick={() => switchMeasure(true)}
					>
						Great Circle
					</FleetButton>
				</div>
			) : (
				<div className={style.nonActive}>
					<FleetButton
						tabIndex={circleTabIndex}
						type="text"
						icon="MonitoringMeasureCircle"
						onClick={() => switchMeasure(true)}
					>
						Great Circle
					</FleetButton>
				</div>
			)}
		</div>
	);
});

export default MeasureControl;
