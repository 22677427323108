import React, { FC } from "react";
import style from "./index.module.less";
import popoverStyle from "../../index.module.less";
import { RouteCommonSegmentProps } from "./type";
import { Icon, Popover } from "@/_components";
import { convertToDms } from "@/tools/graph";
import { FleetImage } from "@/components";
import RouteCommonPortPointerImage from "static/images/routeCommon/routeCommon_port_pointer.png";

const RouteCommonSegment: FC<RouteCommonSegmentProps> = ({ item }) => {
	return (
		<div className={style["routeCommon-vesselCard-segment"]}>
			<div className="segment-container">
				<Icon type="fleet-port_background" color="rgba(63, 66, 76, 1)" />
				<Popover
					trigger="hover"
					placement="bottom"
					overlayClassName={popoverStyle["routeCommon-vesselCard--popover"]}
					content={
						<span className="vesselCard-title">
							{item?.startPortName ?? convertToDms(item?.lat, item?.lon)}
						</span>
					}
					children={<span>{item?.startPortName ?? convertToDms(item?.lat, item?.lon)}</span>}
				/>
			</div>
			<FleetImage src={RouteCommonPortPointerImage} className="segment-pointer" />
			<div className="segment-container">
				<Icon type="fleet-port_background" color="rgba(255, 134, 50, 1)" />
				<Popover
					trigger="hover"
					placement="bottom"
					overlayClassName={popoverStyle["routeCommon-vesselCard--popover"]}
					content={
						<span className="vesselCard-title">{item?.endPortName ?? item?.desAisPort}</span>
					}
					children={<span>{item?.endPortName ?? item?.desAisPort}</span>}
				/>
			</div>
		</div>
	);
};

export default RouteCommonSegment;
