import { ColumnsType } from "antd/es/table/interface";
import { DataType } from "./type";
import React from "react";
import { FleetTooltip } from "@/components";
import { FleetToast } from "@/common";
import { dateFormat } from "@/tools/date";

export const columns: ColumnsType<DataType> = [
  {
    dataIndex: "portName",
    width: 119,
    title: "Port Name",
    key: "portName",
    fixed: "left",
    align: "center",
    ellipsis: true
  },
  {
    dataIndex: "portCode",
    width: 100,
    title: "Port Code",
    key: "portCode",
    fixed: "left",
    align: "center",
    ellipsis: true
  },
  {
    dataIndex: "portCountry",
    width: 200,
    title: "Port Country",
    key: "portCountry",
    align: "center",
    ellipsis: true
  },
  {
    dataIndex: "agentName",
    width: 200,
    title: "Agent Company",
    key: "agentName",
    align: "center",
    ellipsis: true
  }, {
    dataIndex: "advancePayment",
    width: 98,
    title: "PDA",
    key: "advancePayment",
    align: "center",
    ellipsis: true
  },
  {
    dataIndex: "totalExpense",
    width: 130,
    title: "Total Expense",
    key: "totalExpense",
    align: "center",
    ellipsis: true
  }, {
    dataIndex: "vesselName",
    width: 132,
    title: "Ship Name",
    key: "vesselName",
    align: "center"
  }, {
    dataIndex: "cargoName",
    width: 130,
    title: "Cargo Name",
    key: "cargoName",
    align: "center"
  },
  {
    dataIndex: "cargoQuantity",
    width: 140,
    title: "Cargo Quantity",
    key: "cargoQuantity",
    align: "center"
  },
  {
    dataIndex: "portArrival",
    width: 150,
    title: "Port Arrival",
    key: "portArrival",
    align: "center",
    render: (value, record, index) => {
      return <span>{dateFormat("YY-mm-dd HH:MM", value)}</span>
    }
  }, {
    dataIndex: "updateUserName",
    width: 130,
    title: "Updated By",
    key: "updateUserName",
    align: "center"
  }, {
    dataIndex: "updateTime",
    width: 150,
    title: "Update Time",
    key: "updateTime",
    align: "center",
    render: (value, record, index) => {
      return <span>{dateFormat("YY-mm-dd HH:MM", value)}</span>
    }
  }
];