import { type ParameterType } from "@/api";
import { loginOutAction } from "@/store/userSlice";
import {
  getMovementSeaReportsApi,
  getMovementSeaReportsParams,
  saveMovementSeaReportsApi,
  saveMovementSeaReportsRowParams,
  deleteMovementSeaReportsApi,
  deleteSeaReportsWarningParams,
  analysisMovementSeaReportsApi,
  analysisMovementSeaReportsParams,
  getSeaReportsWarningApi,
  confirmSeaReportsWarningApi,
  confirmSeaReportsWarningParams,
  downloadSeaReportApi,
  downloadSeaReportParams,
  updateToMovementApi,
  updateToMovementParams,
  analysisSeaReportApi,
  getSegmentApi,
  saveSegmentApi,
  saveSegmentParams,
  deleteSegmentApi
} from "@/api/voyageManage/movement/seaReports";

export const getSegmentAction: ParameterType<{ voyageId: string; type: "Ballast" | "Laden" }> = (
	params,
	frontCallback,
	successCallback,
	errorCallback,
	dispatch,
	navigate
) => {
	frontCallback?.();
	getSegmentApi(params)
		.then((response) => {
			successCallback?.(response);
		})
		.catch((err) => {
			errorCallback?.(err);
			if (err?.loginStatus === "token faild") {
				dispatch(loginOutAction(() => navigate?.("/login")));
			}
		});
};

export const saveSegmentAction: ParameterType<
  saveSegmentParams
> = (
  params,
  frontCallback,
  successCallback,
  errorCallback,
  dispatch,
  navigate
) => {
  frontCallback?.();
  saveSegmentApi(params)
    .then((response) => {
      successCallback?.(response);
    })
    .catch((err) => {
      errorCallback?.(err);
      if (err?.loginStatus === "token faild") {
        dispatch(loginOutAction(() => navigate?.("/login")));
      }
    });
};

export const deleteSegmentAction: ParameterType<
  number[]
> = (
  params,
  frontCallback,
  successCallback,
  errorCallback,
  dispatch,
  navigate
) => {
  frontCallback?.();
  deleteSegmentApi(params)
    .then((response) => {
      successCallback?.(response);
    })
    .catch((err) => {
      errorCallback?.(err);
      if (err?.loginStatus === "token faild") {
        dispatch(loginOutAction(() => navigate?.("/login")));
      }
    });
};

export const getMovementSeaReportsAction: ParameterType<
  getMovementSeaReportsParams
> = (
  params,
  frontCallback,
  successCallback,
  errorCallback,
  dispatch,
  navigate
) => {
  frontCallback?.();
  getMovementSeaReportsApi(params)
    .then((response) => {
      successCallback?.(response);
    })
    .catch((err) => {
      errorCallback?.(err);
      if (err?.loginStatus === "token faild") {
        // dispatch?.(loginOut({}));
        // navigate?.('/')
        dispatch(loginOutAction(() => navigate?.("/login")));
      }
    });
};

export const saveMovementSeaReportsAction: ParameterType<
  saveMovementSeaReportsRowParams
> = (
  params,
  frontCallback,
  successCallback,
  errorCallback,
  dispatch,
  navigate
) => {
  frontCallback?.();
  saveMovementSeaReportsApi(params)
    .then((response) => {
      successCallback?.(response);
    })
    .catch((err) => {
      errorCallback?.(err);
      if (err?.loginStatus === "token faild") {
        // dispatch?.(loginOut({}));
        // navigate?.('/')
        dispatch(loginOutAction(() => navigate?.("/login")));
      }
    });
};

export const deleteMovementSeaReportsAction: ParameterType<
deleteSeaReportsWarningParams
> = (
  params,
  frontCallback,
  successCallback,
  errorCallback,
  dispatch,
  navigate
) => {
  frontCallback?.();
  deleteMovementSeaReportsApi(params)
    .then((response) => {
      successCallback?.(response);
    })
    .catch((err) => {
      errorCallback?.(err);
      if (err?.loginStatus === "token faild") {
        // dispatch?.(loginOut({}));
        // navigate?.('/')
        dispatch(loginOutAction(() => navigate?.("/login")));
      }
    });
};

export const analysisMovementSeaReportsAction: ParameterType<
  analysisMovementSeaReportsParams
> = (
  params,
  frontCallback,
  successCallback,
  errorCallback,
  dispatch,
  navigate
) => {
  frontCallback?.();
  analysisMovementSeaReportsApi(params)
    .then((response) => {
      successCallback?.(response);
    })
    .catch((err) => {
      errorCallback?.(err);
      if (err?.loginStatus === "token faild") {
        // dispatch?.(loginOut({}));
        // navigate?.('/')
        dispatch(loginOutAction(() => navigate?.("/login")));
      }
    });
};

export const getSeaReportsWarningAction: ParameterType<
  string
> = (
  id,
  frontCallback,
  successCallback,
  errorCallback,
  dispatch,
  navigate
) => {
  frontCallback?.();
  getSeaReportsWarningApi(id)
    .then((response) => {
      successCallback?.(response);
    })
    .catch((err) => {
      errorCallback?.(err);
      if (err?.loginStatus === "token faild") {
        // dispatch?.(loginOut({}));
        // navigate?.('/')
        dispatch(loginOutAction(() => navigate?.("/login")));
      }
    });
};

export const confirmSeaReportsWarningAction: ParameterType<
  confirmSeaReportsWarningParams
> = (
  data,
  frontCallback,
  successCallback,
  errorCallback,
  dispatch,
  navigate
) => {
  frontCallback?.();
  confirmSeaReportsWarningApi(data)
    .then((response) => {
      successCallback?.(response);
    })
    .catch((err) => {
      errorCallback?.(err);
      if (err?.loginStatus === "token faild") {
        // dispatch?.(loginOut({}));
        // navigate?.('/')
        dispatch(loginOutAction(() => navigate?.("/login")));
      }
    });
};

export const downloadSeaReportAction: ParameterType<
  downloadSeaReportParams
> = (
  data,
  frontCallback,
  successCallback,
  errorCallback,
  dispatch,
  navigate
) => {
  frontCallback?.();
  downloadSeaReportApi(data)
    .then((response) => {
      successCallback?.(response);
    })
    .catch((err) => {
      errorCallback?.(err);
      if (err?.loginStatus === "token faild") {
        // dispatch?.(loginOut({}));
        // navigate?.('/')
        dispatch(loginOutAction(() => navigate?.("/login")));
      }
    });
};

export const updateToMovementAction: ParameterType<
  updateToMovementParams
> = (
  data,
  frontCallback,
  successCallback,
  errorCallback,
  dispatch,
  navigate
) => {
  frontCallback?.();
  updateToMovementApi(data)
    .then((response) => {
      successCallback?.(response);
    })
    .catch((err) => {
      errorCallback?.(err);
      if (err?.loginStatus === "token faild") {
        // dispatch?.(loginOut({}));
        // navigate?.('/')
        dispatch(loginOutAction(() => navigate?.("/login")));
      }
    });
};

export const analysisSeaReportAction:ParameterType<FormData> = (
  params,
  frontCallback,
  successCallback,
  errorCallback,
  dispatch,
  navigate
) => {
  frontCallback?.();
  analysisSeaReportApi(params)
    .then((response) => {
      successCallback?.(response);
    })
    .catch((err) => {
      errorCallback?.(err);
      if (err?.loginStatus === "token faild") {
        // dispatch?.(loginOut({}));
        // navigate?.('/')
        dispatch(loginOutAction(() => navigate?.("/login")));
      }
    });
};