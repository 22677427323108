import React, { FC } from "react";
import style from "./index.module.less"
import { AccountFilterProps } from "./type";
import { FleetFormItem, FleetVerifyInput } from "@/components";
import { CommonCompanySelect } from "@/common/Select";

const AccountFilter: FC<AccountFilterProps> = ({
  item,
  onSearch,
  onCompanySelect
}) => {
  return <div className={style["account-filter"]}>
    <FleetVerifyInput
      containerClassname="filter-search"
      value={undefined}
      onChange={onSearch}
      label="Filter"
      placeholder="Account Name"
    />
    <FleetFormItem
      containerClassname="filter-companySelect"
      label="Company Name"
    >
      <CommonCompanySelect 
        showKey="companyName"
        activeItems={{
          id: item?.company?.id,
          companyName: item?.company?.name
        }}
        onFinish={(newItem,type) => {
          onCompanySelect?.({
            id: newItem?.id ?? null,
            name: newItem?.companyName ?? ''
          })
        }}
      />
    </FleetFormItem>
  </div>
}

export default AccountFilter