import React, { FC } from "react";
import classnames from "classnames";
import style from "./index.module.less";
import { RouteCommonVesselInfoPopupProps } from "./type";
import { options } from "./source";

const RouteCommonVesselInfoPopup: FC<RouteCommonVesselInfoPopupProps> = ({ item }) => {
	return (
		<div className={style["routeCommon-map-vesselPopup"]}>
			{options?.map((optionItem, index) => {
				return (
					<li className={classnames("vesselPopup-item", optionItem?.value)} key={optionItem?.value}>
						<span>{optionItem?.label}</span>
						{optionItem?.render ? (
							optionItem?.render(item?.[optionItem?.value])
						) : (
							<span>{item?.[optionItem?.value]}</span>
						)}
					</li>
				);
			})}
		</div>
	);
};

export default RouteCommonVesselInfoPopup;
