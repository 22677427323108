import React, { FC } from "react";
import style from "./index.module.less";
import { PerformanceProps, PerformanceType } from "./type";
import { otherColumns, portColumns } from "./source";
import { FleetInput, FleetTextarea } from "@/components";
import { handlerPerformance } from "./tools";
import HandoverFormLoadMore from "../../../loadMore";

const HandoverFormPerformance: FC<PerformanceProps> = ({
  item,
  onChange,
  onCommit
}) => {
  console.log("HandoverFormPerformance", item);
	return (
		<div className={style["handoverForm-performance"]}>
			<span className="handoverForm--common--header secondary">Vessel Performance</span>
			<div className="handoverForm-table performance">
				<ul className="table-common thead">
					{portColumns?.map((column, index) => {
						return <li key={index}>{column}</li>;
					})}
				</ul>
				{item?.ballast?.map((ballastItem, ballastIndex) => {
					return (
						<ul className="table-common tbody" key={ballastIndex}>
							<li>Ballast</li>
							<li>
								<FleetInput
									type="number"
									value={ballastItem?.speed}
									placeholder="knot"
									onChange={(val: number) => {
										onChange?.("ballast", ballastIndex, "speed", val);
									}}
								/>
							</li>
							<li>
								<FleetInput
									type="number"
									value={ballastItem?.hsFo}
									placeholder="MT/Day"
									onChange={(val: number) => {
										onChange?.("ballast", ballastIndex, "hsFo", val);
									}}
								/>
							</li>
							<li>
								<FleetInput
									type="number"
									placeholder="MT/Day"
									value={ballastItem?.lsFo}
									onChange={(val: number) => {
										onChange?.("ballast", ballastIndex, "lsFo", val);
									}}
								/>
							</li>
							<li>
								<FleetInput
									type="number"
									placeholder="MT/Day"
									value={ballastItem?.mdo}
									onChange={(val: number) => {
										onChange?.("ballast", ballastIndex, "mdo", val);
									}}
								/>
							</li>
							<li>
								<FleetInput
									type="number"
									placeholder="MT/Day"
									value={ballastItem?.mgo}
									onChange={(val: number) => {
										onChange?.("ballast", ballastIndex, "mgo", val);
									}}
								/>
							</li>
							<li>
								<FleetInput
									placeholder="About/(MT/Day)"
									value={ballastItem?.remarks}
									onChange={(val: string) => {
										onChange?.("ballast", ballastIndex, "remarks", val);
									}}
								/>
							</li>
						</ul>
					);
				})}
				{item?.laden?.map((ladenItem, ladenIndex) => {
					return (
						<ul className="table-common tbody" key={ladenIndex}>
							<li>Laden</li>
							<li>
								<FleetInput
									type="number"
									value={ladenItem?.speed}
									placeholder="knot"
									onChange={(val: number) => {
										onChange?.("laden", ladenIndex, "speed", val);
									}}
								/>
							</li>
							<li>
								<FleetInput
									type="number"
									value={ladenItem?.hsFo}
									placeholder="MT/Day"
									onChange={(val: number) => {
										onChange?.("laden", ladenIndex, "hsFo", val);
									}}
								/>
							</li>
							<li>
								<FleetInput
									type="number"
									placeholder="MT/Day"
									value={ladenItem?.lsFo}
									onChange={(val: number) => {
										onChange?.("laden", ladenIndex, "lsFo", val);
									}}
								/>
							</li>
							<li>
								<FleetInput
									type="number"
									placeholder="MT/Day"
									value={ladenItem?.mdo}
									onChange={(val: number) => {
										onChange?.("laden", ladenIndex, "mdo", val);
									}}
								/>
							</li>
							<li>
								<FleetInput
									type="number"
									placeholder="MT/Day"
									value={ladenItem?.mgo}
									onChange={(val: number) => {
										onChange?.("laden", ladenIndex, "mgo", val);
									}}
								/>
							</li>
							<li>
								<FleetInput
									placeholder="About/(MT/Day)"
									value={ladenItem?.remarks}
									onChange={(val: string) => {
										onChange?.("laden", ladenIndex, "remarks", val);
									}}
								/>
							</li>
						</ul>
					);
				})}
			</div>
			<div className="handoverForm-table performance">
				<ul className="table-common thead">
					{otherColumns?.map((column, index) => {
						return <li key={index}>{column}</li>;
					})}
				</ul>
				<ul className="table-common tbody">
					<li>Work</li>
					<li></li>
					<li>
						<FleetInput
							type="number"
							value={item?.work?.hsFo}
							placeholder="MT/Day"
							onChange={(val: number) => {
								onChange?.("work", -1, "hsFo", val);
							}}
						/>
					</li>
					<li>
						<FleetInput
							type="number"
							placeholder="MT/Day"
							value={item?.work?.lsFo}
							onChange={(val: number) => {
								onChange?.("work", -1, "lsFo", val);
							}}
						/>
					</li>
					<li>
						<FleetInput
							type="number"
							placeholder="MT/Day"
							value={item?.work?.mdo}
							onChange={(val: number) => {
								onChange?.("work", -1, "mdo", val);
							}}
						/>
					</li>
					<li>
						<FleetInput
							type="number"
							placeholder="MT/Day"
							value={item?.work?.mgo}
							onChange={(val: number) => {
								onChange?.("work", -1, "mgo", val);
							}}
						/>
					</li>
					<li>
						<FleetInput
							placeholder="About/(MT/Day)"
							value={item?.work?.remarks}
							onChange={(val: string) => {
								onChange?.("work", -1, "remarks", val);
							}}
						/>
					</li>
				</ul>
				<ul className="table-common tbody">
					<li>Idle</li>
					<li></li>
					<li>
						<FleetInput
							type="number"
							value={item?.idle?.hsFo}
							placeholder="MT/Day"
							onChange={(val: number) => {
								onChange?.("idle", -1, "hsFo", val);
							}}
						/>
					</li>
					<li>
						<FleetInput
							type="number"
							placeholder="MT/Day"
							value={item?.idle?.lsFo}
							onChange={(val: number) => {
								onChange?.("idle", -1, "lsFo", val);
							}}
						/>
					</li>
					<li>
						<FleetInput
							type="number"
							placeholder="MT/Day"
							value={item?.idle?.mdo}
							onChange={(val: number) => {
								onChange?.("idle", -1, "mdo", val);
							}}
						/>
					</li>
					<li>
						<FleetInput
							type="number"
							placeholder="MT/Day"
							value={item?.idle?.mgo}
							onChange={(val: number) => {
								onChange?.("idle", -1, "mgo", val);
							}}
						/>
					</li>
					<li>
						<FleetInput
							placeholder="About/(MT/Day)"
							value={item?.idle?.remarks}
							onChange={(val: string) => {
								onChange?.("idle", -1, "remarks", val);
							}}
						/>
					</li>
				</ul>
			</div>
			<FleetTextarea
				className="performance-textarea performance--clause"
				value={item?.performanceClause}
				onChange={(val: string) => {
					onChange?.("performanceClause", -1, "performanceClause", val);
				}}
				onBlur={(e) => {
					let val = e.target.value;
					let performanceForm: Partial<PerformanceType> = handlerPerformance(
						item,
						val,
						item?.goodWeatherDef
					);
					debugger;
					if (Object.keys(performanceForm)?.length === 0) {
						return;
					}
					onCommit?.(performanceForm);
				}}
				placeholder=""
				// placeholder={`BUNKER CLAUSE:\nBUNKERS ON DELIVERY ABOUT:\nLSFO ABT 600 MT @ USD 340 PMT\nLSMGO ABT 70 MT @ USD 350 PMT\nBUNKERS ON REDELIVERY ABT AS ON DELIVERY AND SAME PRICES BENDS`}
			/>
			<HandoverFormLoadMore>
				<div className="performance-goodWeatherRef">
					<span className="handoverForm--common--header secondary">Good Weather Definition</span>
					<FleetTextarea
						className="performance-textarea performance--goodWeather"
						value={item?.goodWeatherDef}
						onChange={(val: string) => {
							onChange?.("goodWeatherDef", -1, "goodWeatherDef", val);
						}}
						placeholder=""
						// placeholder={`BUNKER CLAUSE:\nBUNKERS ON DELIVERY ABOUT:\nLSFO ABT 600 MT @ USD 340 PMT\nLSMGO ABT 70 MT @ USD 350 PMT\nBUNKERS ON REDELIVERY ABT AS ON DELIVERY AND SAME PRICES BENDS`}
					/>
				</div>
			</HandoverFormLoadMore>
		</div>
	);
}

export default HandoverFormPerformance