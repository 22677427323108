import { ApiParameter, RequestApiMapType } from "@/api";
import request from "@/service";

import {
	RouteCommonVesselCardParamsType,
	getRouteCommonVesselInfoParams,
	getRouteCommonVesselLegInfoParams
} from "./type";
import { appVersion } from "@/api/constant";

const enum ApiMap {
	getRouteCommonVesselLegInfoParameter,
	getRouteCommonVesselInfoParameter
}

export type ApiUrl = keyof typeof ApiMap;

const vesselCardApi: ApiParameter<ApiUrl> = {
	getRouteCommonVesselLegInfoParameter: {
		method: "get",
		url: appVersion + "/moni/voy/detail"
	},
	getRouteCommonVesselInfoParameter: {
		method: "get",
		url: appVersion + "/ais/cmes/state/imo"
	}
};

export const getRouteCommonVesselLegInfoApi = (params: getRouteCommonVesselLegInfoParams) => {
	return request.request({
		...vesselCardApi.getRouteCommonVesselLegInfoParameter,
		params
	});
};

export const getRouteCommonVesselInfoApi = (params: getRouteCommonVesselInfoParams) => {
	return request.request({
		...vesselCardApi.getRouteCommonVesselInfoParameter,
		url: `${vesselCardApi.getRouteCommonVesselInfoParameter.url}/${params?.imo}`
	});
};

export const vesselCardApiMap: RequestApiMapType<ApiUrl, RouteCommonVesselCardParamsType> = {
	getRouteCommonVesselLegInfoApi,
	getRouteCommonVesselInfoApi
};
