import React, { FC, useMemo } from "react";
import classnames from "classnames";
import style from "./index.module.less";
import { VesselItemProps } from "./type";
import { vesselTypeSubOption } from "./source";
const VesselItem: FC<VesselItemProps> = ({ item }) => {
	const vesselType = useMemo(() => {
		return vesselTypeSubOption.find((ele) => ele.value === item?.vesselTypeSub)?.label ?? "";
	}, [item]);
	return (
		<div className={classnames(style["port-list-vesselItem"])}>
			<div className="vesselItem-header">
				<span className="vesselItem-headerName">{item?.otherName ?? vesselType}</span>
				<span className="vesselItem-headerNum">{item?.totalCount} Vessels</span>
			</div>
			<div className="vesselItem-footer">
				<div className="vesselItem-footerItem">
					<span>Mooring:</span>
					<span>{item?.mooring}</span>
				</div>
				<div className="vesselItem-footerItem">
					<span>At Berth:</span>
					<span>{item?.berth}</span>
				</div>
			</div>
		</div>
	);
};

export default VesselItem;
