import { type ApiParameter } from "@/api";
import { appVersion } from "@/api/constant";
import request from "@/service";
import { StowagePortInfoFormType, StowageVesselFormType } from "@/types/estimation/details/index";

const enum ApiMap {
	getStowageParameter,
	updateStowageParameter
}

type ApiUrl = keyof typeof ApiMap;

const stowageApi: ApiParameter<ApiUrl> = {
	getStowageParameter: {
		method: "get",
		url: appVersion + "/est/vc/stowage"
	},
	updateStowageParameter: {
		method: "put",
		url: appVersion + "/est/vc/stowage"
	}
};

export type getStowageParams = {
	resultId: string;
};

export type updateStowageParams = {
	firstPortName: string;
	vesselVo: StowageVesselFormType;
	portInfoVos: Partial<StowagePortInfoFormType[]>;
};

export const getStowageApi = (params: getStowageParams) => {
	return request.request({
		...stowageApi.getStowageParameter,
		url: `${stowageApi.getStowageParameter.url}/${params?.resultId}`
	});
};

export const updateStowageApi = (params: updateStowageParams) => {
	return request.request({
		...stowageApi.updateStowageParameter,
		data: params
	});
};
