import { useCommonIndex } from "@/hook";
import { PortVesselFilterItemType } from "../type";
import { initialFilterItem } from "../source";

const useIndex = () => {
	const { dataSource, commit, init } = useCommonIndex<PortVesselFilterItemType>(initialFilterItem);

	return {
		dataSource,
		commit,
		init
	};
};

export default useIndex;
