import { ArrivalVesselSubTypeMap, ArrivalAisStatusMap } from "@/common/Select";
import { FleetOptionType } from "@/types/advancedType";
import { CommonInitItemEvent } from "@/types/event";
import { PortVesselFilterItemType } from "./type";

export const vesselSubTypeOptions: FleetOptionType<
	keyof typeof ArrivalVesselSubTypeMap,
	ArrivalVesselSubTypeMap
>[] = [
	{ label: "VLOC", value: ArrivalVesselSubTypeMap.VLOC },
	{ label: "Capesize", value: ArrivalVesselSubTypeMap.Capesize },
	{ label: "Post-Panamax", value: ArrivalVesselSubTypeMap["Post-Panamax"] },
	{ label: "Panamax", value: ArrivalVesselSubTypeMap.Panamax },
	{ label: "Supramax", value: ArrivalVesselSubTypeMap.Supramax },
	{ label: "Handymax", value: ArrivalVesselSubTypeMap.Handymax },
	{ label: "Handysize", value: ArrivalVesselSubTypeMap.Handysize },
	{ label: "Small Bulk", value: ArrivalVesselSubTypeMap["Small Bulk"] }
];

export const aisStatusOptions: FleetOptionType<
	keyof typeof ArrivalAisStatusMap,
	ArrivalAisStatusMap
>[] = [
	{ value: ArrivalAisStatusMap.Running, label: "Running" },
	{ value: ArrivalAisStatusMap.Runaway, label: "Runaway" },
	{ value: ArrivalAisStatusMap.Mooring, label: "Mooring" },
	{ value: ArrivalAisStatusMap.Disable, label: "Disable" },
	{ value: ArrivalAisStatusMap["Draft Limits"], label: "Draft Limits" },
	{ value: ArrivalAisStatusMap["At Berth"], label: "At Berth" },
	{ value: ArrivalAisStatusMap.Stranded, label: "Stranded" },
	{ value: ArrivalAisStatusMap.Unknown, label: "Unknown" }
];

export const initialFilterItem: CommonInitItemEvent<PortVesselFilterItemType> = (item) => {
	return {
		vesselTypeSub: item?.vesselTypeSub ?? [],
		aisStatus: item?.aisStatus ?? [],
		lastPortCountry: item?.lastPortCountry ?? [],
		lastAreaList: item?.lastAreaList ?? []
	};
};
