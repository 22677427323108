import { BunkerClauseType } from "./type";

export const handlerBunkerClause = (item: BunkerClauseType, val: string): Partial<BunkerClauseType> => {
  let bunkerForm: Partial<BunkerClauseType> = { ...item };
  let moReg = /\w+(MGO|MDO) AB(T|OUT) (\d+) MT(.*)USD (\d+) (PMT|MT)/gi;
  let foReg = /\w+(FO) AB(T|OUT) (\d+) MT(.*)USD (\d+) (PMT|MT)/gi;

  let foStrList = val.match(foReg) ?? [];
  let moStrList = val.match(moReg) ?? [];

  let quantityReg = /AB[T|OUT] (\d+) MT/i;
  let priceReg = /USD (\d+) P{0,1}MT/i;

  for (let item of foStrList) {
    let title = item.match(/^\w+FO/i);
    let quantity = item.match(quantityReg);
    let price = item.match(priceReg);
    switch (true) {
      case title?.[0]?.toLocaleLowerCase() === "lsfo":
        bunkerForm["lsFoPrice"] = (+price?.[1] ?? "");
        bunkerForm["lsFoQuantity"] = (+quantity?.[1] ?? "");
        break;
      case title?.[0]?.toLocaleLowerCase() === "hsfo":
        bunkerForm["hsFoPrice"] = (+price?.[1] ?? "");
        bunkerForm["hsFoQuantity"] = (+quantity?.[1] ?? "");
        break;
      default:
        break
    }
  }

  for (let item of moStrList) {
    let title = item.match(/^\w+[MGO|MDO]/i);
    let quantity = item.match(quantityReg);
    let price = item.match(priceReg);
    switch (true) {
      case title?.[0]?.toLocaleLowerCase()?.includes("mdo"):
        bunkerForm["mdoPrice"] = (+price?.[1] ?? "");
        bunkerForm["mdoQuantity"] = (+quantity?.[1] ?? "");
        break;
      case title?.[0]?.toLocaleLowerCase()?.includes("mgo"):
        bunkerForm["mgoPrice"] = (+price?.[1] ?? "");
        bunkerForm["mgoQuantity"] = (+quantity?.[1] ?? "");
        break;
      default:
        break
    }
  }

  return bunkerForm
}