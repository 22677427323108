import { type ParameterType } from "@/api";
import { loginOut, loginOutAction } from "@/store/userSlice";

import {
  queryPortsRouteApi,
  queryPortsRouteParams
} from "@/api/estimation/route"

export const queryPortsRouteAction: ParameterType<queryPortsRouteParams> = (
  params,
  frontCallback,
  successCallback,
  errorCallback,
  dispatch,
  navigate
) => {
  frontCallback?.();
  queryPortsRouteApi(params).then(response => {
    successCallback?.(response)
  }).catch(err => {
    errorCallback?.(err);
    if (err?.loginStatus === 'token faild') {
      // dispatch?.(loginOut({}));
      // navigate?.('/')
      dispatch(loginOutAction(() => navigate?.("/login")))
    }
  })
}