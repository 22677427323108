import {
	deleteVoyageAttactAction,
	deleteVoyageContactAction,
	uploadVmVoyageAttachAction
} from "@/action/voyageManage/preOperation";
import {
	VoyageManageAttachAndContactProps,
	VoyageManageAttachmentItemType,
	VoyageManageContactItemType,
	VoyageManageVoyageRemarkCommitEvent,
	VoyageManageVoyageRemarkDeleteEvent
} from "../type";
import { useAppDispatch } from "@/hook";
import { useNavigate } from "react-router-dom";
import useReminder from "@/hook/useReminder";
import { useRef, useState } from "react";
import { getMovementFilePathAction } from "@/action/voyageManage/movement";

const useIndex = (
	voyageId: string,
	type: VoyageManageAttachAndContactProps["type"],
	onCommit: VoyageManageVoyageRemarkCommitEvent,
	onDelete: VoyageManageVoyageRemarkDeleteEvent
) => {
	const dispatch = useAppDispatch();
	const navigate = useNavigate();
	const { reminder } = useReminder();

	const [hide, setHide] = useState(true);
	const [loading, setLoading] = useState(false);

	const uploadVmVoyageAttachActionFront = () => {
		setLoading(true);
	};

	const uploadVmVoyageAttachActionSuccess = (response) => {
		setLoading(false);
		reminder("success", "Attachment uploaded successfully");
		setHide(true);
		onCommit?.(type, "attachment", response?.data);
	};

	const uploadVmVoyageAttachActionFailed = (error) => {
		setLoading(false);
		reminder("error", error?.msg ? error?.msg + ": " + error?.data : error?.data);
	};

	const handleUpload = (files: File[]) => {
		if (!voyageId || loading || files?.length <= 0) return;
		const formData = new FormData();
		for (let file of files) {
			formData.append("files", file);
		}
		uploadVmVoyageAttachAction(
			{
				voyageId,
				type,
				files: formData
			},
			uploadVmVoyageAttachActionFront,
			uploadVmVoyageAttachActionSuccess,
			uploadVmVoyageAttachActionFailed,
			dispatch,
			navigate
		);
	};

	const handleAttachDelete = (id: number) => {
		if (!voyageId) {
			reminder("error", "The voyage you have chosen is not a valid one");
			return;
		}
		deleteVoyageAttactAction(
			{ id: id },
			uploadVmVoyageAttachActionFront,
			(response) => {
				onDelete?.(type, "attachment", id);
				setLoading(false);
				reminder("success", response?.msg);
			},
			uploadVmVoyageAttachActionFailed,
			dispatch,
			navigate
		);
	};

	const handleContactDelete = (item: VoyageManageContactItemType) => {
		if (!item?.del) return;
		deleteVoyageContactAction(
			{
				id: item?.id
			},
			uploadVmVoyageAttachActionFront,
			(response) => {
				onDelete?.(type, "contact", item?.id);
				setLoading(false);
				reminder("success", response?.msg);
			},
			uploadVmVoyageAttachActionFailed,
			dispatch,
			navigate
		);
	};

	const handlePreview = (type: "preview" | "download", item: VoyageManageAttachmentItemType) => {
		getMovementFilePathAction(
			{
				path: item?.filePath
			},
			uploadVmVoyageAttachActionFront,
			(response) => {
				setLoading(false);
				if (type === "download") {
				} else {
					window.open(response?.data);
				}
			},
			uploadVmVoyageAttachActionFailed,
			dispatch,
			navigate
		);
	};

	const handleUploadClose = () => {
		setHide(true);
	};

	const handleUploadOpen = () => {
		setHide(false);
	};

	return {
		hide,
		loading,
		handleUpload,
		handleAttachDelete,
		handleContactDelete,
		handlePreview,
		handleUploadClose,
		handleUploadOpen
	};
};

export default useIndex;
