import { useMemo } from "react";
import { MovementTctcVesselBasicsItemType } from "../components";
import { MovementTctcOtherItemType } from "../type";
import useEstimation from "./useEstimation";
import { MovementFinancialDataItemType, initialFinacialItem } from "../../../common";
import { calcPercentage } from "@/tools/amount";
import { downloadMovementTctcPnlParams } from "@/api/voyageManage/movement/pnl/type";

const usePnl = (
	rentVesselItem: MovementTctcVesselBasicsItemType,
	reletVesselItem: MovementTctcVesselBasicsItemType,
	otherItem: MovementTctcOtherItemType
) => {
	const {
		totalProfit,
		totalRevenue,
		tce,
		totalCost: totalRentCost,
		rentTotalHireCost,
		reletTotalHireCost,
		otherExpenses,
		rentCommission,
		rentBrokerage,
		reletCommission,
		reletBrokerage,
		rentHireCost,
		reletHireCost,
		rentHireExpense,
		rentTotalBunkerCost,
		reletTotalBunkerCost,
		reletHireExpense,
		disputeCharges
	} = useEstimation(rentVesselItem, reletVesselItem, otherItem);

	const reletBunkerCost = useMemo(() => {
		return initialFinacialItem({
			desc: "Bunker Cost",
			actual: reletTotalBunkerCost,
			children: [
				initialFinacialItem({
					desc: "HSFO Cost",
					actual:
						otherItem?.reletBunkerPlan?.hsfoTotalPrice +
						+otherItem?.reletBunkerPlan?.scrubberTotalPrice
				}),
				initialFinacialItem({
					desc: "LSFO Cost",
					actual: otherItem?.reletBunkerPlan?.lsfoTotalPrice
				}),
				initialFinacialItem({
					desc: "MDO Cost",
					actual: otherItem?.reletBunkerPlan?.mdoTotalPrice
				}),
				initialFinacialItem({
					desc: "MGO Cost",
					actual: otherItem?.reletBunkerPlan?.mgoTotalPrice
				})
			]
		});
	}, [reletTotalBunkerCost, otherItem?.reletBunkerPlan]);

	const totalRevenues = useMemo<MovementFinancialDataItemType>(() => {
		return initialFinacialItem({
			desc: "Total Revenues",
			actual: totalRevenue,
			children: [
				initialFinacialItem({
					desc: "Hire Income",
					actual: reletHireCost,
					posted: reletHireExpense?.total,
					diffPosted: calcPercentage(reletHireCost, reletHireExpense?.total),
					children: reletHireExpense?.calcuate
				}),
				reletBunkerCost,
				initialFinacialItem({
					desc: "Others Income",
					actual: +otherItem?.otherIncome
				})
			]
		});
	}, [reletHireCost, totalRevenue, otherItem?.otherIncome, reletHireExpense, reletBunkerCost]);

	const rentBunkerCost = useMemo(() => {
		return initialFinacialItem({
			desc: "Bunker Cost",
			actual: rentTotalBunkerCost,
			children: [
				initialFinacialItem({
					desc: "HSFO Cost",
					actual:
						otherItem?.rentBunkerPlan?.hsfoTotalPrice +
						+otherItem?.rentBunkerPlan?.scrubberTotalPrice
				}),
				initialFinacialItem({
					desc: "LSFO Cost",
					actual: otherItem?.rentBunkerPlan?.lsfoTotalPrice
				}),
				initialFinacialItem({
					desc: "MDO Cost",
					actual: otherItem?.rentBunkerPlan?.mdoTotalPrice
				}),
				initialFinacialItem({
					desc: "MGO Cost",
					actual: otherItem?.rentBunkerPlan?.mgoTotalPrice
				})
			]
		});
	}, [rentTotalBunkerCost, otherItem?.rentBunkerPlan]);

	const totalCommission = useMemo(() => {
		return initialFinacialItem({
			desc: "Commission",
			actual: reletCommission + reletBrokerage + rentBrokerage + rentCommission,
			children: [
				initialFinacialItem({
					desc: "Chrt's Brokerage",
					actual: reletBrokerage
				}),
				initialFinacialItem({
					desc: "Chit's Addcom.",
					actual: reletCommission
				}),
				initialFinacialItem({
					desc: "Owr's Brokerage",
					actual: rentBrokerage
				}),
				initialFinacialItem({
					desc: "Owr's Addcom.",
					actual: rentCommission
				})
			]
		});
	}, [reletCommission, reletBrokerage, rentBrokerage, rentCommission]);
	const totalOtherExpenses = useMemo(() => {
		return initialFinacialItem({
			desc: "Other Expenses",
			actual: otherExpenses?.total,
			children: otherExpenses?.calcuate
		});
	}, [otherExpenses]);

	const totalCost = useMemo(() => {
		return initialFinacialItem({
			desc: "Total Cost",
			actual: totalRentCost,
			children: [
				rentBunkerCost,
				initialFinacialItem({
					desc: "Hire Cost",
					actual: rentHireCost,
					posted: rentHireExpense?.total,
					diffPosted: calcPercentage(rentHireCost, rentHireExpense?.total),
					children: rentHireExpense?.calcuate
				}),
				totalCommission,
				totalOtherExpenses
			]
		});
	}, [
		totalRentCost,
		rentBunkerCost,
		rentHireExpense,
		rentHireCost,
		totalCommission,
		totalOtherExpenses
	]);

	const totalDisputeCharge = useMemo(() => {
		return initialFinacialItem({
			desc: "Dispute Charge",
			actual: disputeCharges?.total,
			children: disputeCharges?.calcuate
		});
	}, [disputeCharges]);

	const handleCombineParams = (
		item: Omit<downloadMovementTctcPnlParams, "income" | "outgoing" | "disputeCharges" | "totalPnl">
	): downloadMovementTctcPnlParams => {
		return {
			...item,
			income: {
				hireList: reletHireExpense?.calcuate?.map((item) => ({
					desc: item?.desc,
					actual: item?.actual,
					time: item?.time,
					payerName: item?.payerName
				})),
				bunkerage: {
					bunkerCost: reletTotalBunkerCost,
					bunkerCostTime:
						otherItem?.reletBunkerPlan?.status === "Settled"
							? otherItem?.reletBunkerPlan?.settledTime
							: null
				},
				otherIncome: otherItem?.otherIncome,
				total: totalRevenue
			},
			outgoing: {
				hireList: rentHireExpense?.calcuate?.map((item) => ({
					desc: item?.desc,
					actual: item?.actual,
					time: item?.time,
					payerName: item?.payerName
				})),
				bunkerage: {
					bunkerCost: rentTotalBunkerCost,
					bunkerCostTime:
						otherItem?.rentBunkerPlan?.status === "Settled"
							? otherItem?.rentBunkerPlan?.settledTime
							: null
				},
				commission: {
					chrtsCommission: reletCommission,
					chrtsBrokerage: reletBrokerage,
					ownrsBrokerage: rentBrokerage,
					ownrsCommission: rentCommission
				},
				otherExpenses: otherExpenses?.calcuate?.map((item) => ({
					desc: item?.desc,
					actual: item?.actual,
					time: item?.time
				})),
				total: totalRentCost
			},
			disputeCharges: disputeCharges?.calcuate?.map((item) => ({
				desc: item?.desc,
				actual: item?.actual
			})),
			totalPnl: totalProfit
		};
	};

	return {
		totalRevenues,
		totalCost,
		totalDisputeCharge,
		totalProfit,
		totalRevenue,
		tce,
		totalRentCost,
		otherExpenses,
		rentCommission,
		rentBrokerage,
		reletCommission,
		reletBrokerage,
		rentHireCost,
		reletHireCost,
		rentTotalBunkerCost,
		reletTotalBunkerCost,
		disputeCharges,
		handleCombineParams
	};
};

export default usePnl;
