import React, { FC, useMemo } from "react";
import style from "./index.module.less";
import { TimeAxisControlProps, TimeAxisControlItemType } from "./type";
import { FleetSelect } from "@/components";
import { currentHoursOptions } from "./source";
import { Icon } from "@/_components";
import dayjs from "dayjs";

const TimeAxisControl: FC<TimeAxisControlProps> = ({ item, onChange, onNext, onRun }) => {
	const nextDisabled = useMemo(() => {
		return item?.currentTime?.startOf("day")?.isSame(dayjs()?.add(10, "day")?.startOf("day"));
	}, [item?.currentTime]);
	return (
		<div className={style["timeAxis-control"]}>
			<FleetSelect
				disabled={item?.runState}
				placeholder="select"
				popupMatchSelectWidth={false}
				className="timeAxis-control-timeSelect"
				value={item?.hours}
				options={currentHoursOptions}
				onChange={(value: TimeAxisControlItemType["hours"]) => {
					onChange?.({ hours: value });
				}}
			/>
			<div className="control-operation">
				<Icon
					type={item?.runState ? "fleet-pause" : "fleet-play"}
					color="#355691"
					disabled={nextDisabled}
					onClick={(event) => {
						event?.stopPropagation();
						onRun?.();
					}}
				/>
				<Icon
					type="fleet-forward"
					className="next"
					disabled={nextDisabled || item?.runState}
					color="#355691"
					onClick={(event) => {
						event?.stopPropagation();
						onNext?.();
					}}
				/>
			</div>
		</div>
	);
};

export default TimeAxisControl;
