import { useEffect, useMemo, useRef, useState } from "react";
import { useAppDispatch, useAppSelector } from "@/hook";
import { useNavigate } from "react-router-dom";
import useReminder from "@/hook/useReminder";
import { PreOperationVoyageListProps } from "../components/list/type";
import { deleteVoyageAction, getVoyageGroupAction } from "@/action/voyageManage/preOperation";
import { FleetPageParams, VoyageType } from "@/types/common";
import debounce from "lodash/debounce";
import { initialVoyageItem } from "../components/list/source";
import { PreOperationDetailsProps } from "../components/details/type";
import { ExtendsKeys } from "@/types";

const useIndex = () => {
	const dispatch = useAppDispatch();
	const navigate = useNavigate();
	const { reminder } = useReminder();
	const { userInfo } = useAppSelector((selector) => selector.UserStore);

	const [dataSource, setDataSource] = useState<PreOperationVoyageListProps["items"]>([]);
	const [activeIndex, setActiveIndex] = useState<number>(null);
	const [activeVoyageType, setActiveVoyageType] =
		useState<ExtendsKeys<VoyageType, "TCTC" | "TCVC">>("TCVC");
	const [loading, setLoading] = useState(false);

	const pager = useRef<FleetPageParams>({
		pageNum: 1,
		pageSize: 20
	});

	const activeItem = useMemo(() => {
		return dataSource?.find((item) => item?.id === activeIndex);
	}, [dataSource, activeIndex]);

	const handleVoyageSelect: PreOperationVoyageListProps["onSelect"] = (currentIndex, item) => {
		setActiveIndex(item?.id);
		setActiveVoyageType(item?.voyageType);
	};

	const handleVoyageAdd: PreOperationVoyageListProps["onAdd"] = (type) => {
		console.log("handleVoyageAdd", type);
		if (activeIndex !== 0 && !activeIndex && type === activeVoyageType) {
			reminder(
				"warning",
				"The current voyage has not been saved yet, and a new voyage cannot be added"
			);
			return;
		}
		setActiveIndex(null);
		setActiveVoyageType(type);
	};

	const handleVoyageRemove: PreOperationVoyageListProps["onDelete"] = (currentIndex) => {
		setDataSource((prev) => {
			prev.splice(currentIndex, 1);
			return [...prev];
		});
	};

	const handleVoyageDelete: PreOperationVoyageListProps["onDelete"] = (currentIndex) => {
		console.log("handleVoyageDelete", currentIndex);
		const currentItem = dataSource?.[currentIndex];
		if (userInfo?.userId != currentItem?.createUser) return;
		if (!currentItem.id) return;
		deleteVoyageAction(
			{ id: currentItem.id },
			getVoyageGroupFront,
			(response) => {
				if (currentItem.id === activeIndex) {
					setActiveIndex(null);
					setActiveVoyageType("TCVC");
				}
				handleVoyageRemove(currentIndex);
				setLoading(false);
			},
			getVoyageGroupError,
			dispatch,
			navigate
		);
	};

	const handleVoyageUpdate: PreOperationDetailsProps["onUpdate"] = (id, item) => {
		const currentIndex = dataSource?.findIndex((selectItem) => selectItem?.id === id);
		if (currentIndex !== -1) {
			setDataSource((prev) => {
				prev?.splice(currentIndex, 1, {
					...prev?.[currentIndex],
					...item
				});
				return [...prev];
			});
		}
	};

	const getVoyageGroupFront = () => {
		setLoading(true);
	};

	const getVoyageGroupError = (error) => {
		console.log("error", error);
		reminder("error", error?.msg ? error?.msg + ": " + error?.data : error?.data);
		setLoading(false);
	};

	const getVoyageGroupSuccess = (response) => {
		setDataSource(response?.data);
		setActiveIndex(response?.data?.length > 0 ? response?.data?.[0]?.id : null);
		setActiveVoyageType(response?.data?.length > 0 ? response?.data?.[0]?.voyageType : null);
		setLoading(false);
	};

	const getVoyageGroup = (searchVal?: string) => {
		getVoyageGroupAction(
			{
				keyword: searchVal,
				pageNum: pager?.current?.pageNum,
				pageSize: pager?.current?.pageSize
			},
			getVoyageGroupFront,
			getVoyageGroupSuccess,
			getVoyageGroupError,
			dispatch,
			navigate
		);
	};

	const handleSearch = useMemo(() => {
		const loadOptions = (val: string) => {
			pager.current.pageNum = 1;
			setDataSource([]);
			getVoyageGroup(val);
		};
		return debounce(loadOptions, 800);
	}, [getVoyageGroup]);

	const handleAdd = (item: PreOperationVoyageListProps["items"][number]) => {
		setDataSource((prev) => [initialVoyageItem(item), ...prev]);
		setActiveIndex(item?.id);
		setActiveVoyageType(item?.voyageType);
	};

	useEffect(() => {
		getVoyageGroup();
	}, []);

	return {
		loading,
		dataSource,
		activeIndex,
		activeItem,
		handleVoyageSelect,
		handleSearch,
		handleVoyageAdd,
		handleAdd,
		handleVoyageUpdate,
		activeVoyageType,
		handleVoyageDelete
	};
};

export default useIndex;
