import { ColumnsType } from "antd/es/table";
import { DataType } from "./type";

export const columns: ColumnsType<DataType> = [
  {
    dataIndex: "companyName",
    width: 245,
    title: "Company Name",
    key: "companyName",
    fixed: "left",
    align: "center",
    ellipsis: true
  },
  {
    dataIndex: "companyWebsite",
    width: 246,
    title: "Company Website",
    key: "companyWebsite",
    align: "center",
    ellipsis: true
  },
  {
    dataIndex: "companyAddress",
    width: 774,
    title: "Company Address",
    key: "companyAddress",
    align: "center",
    ellipsis: true
  },
]