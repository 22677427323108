import { type ApiParameter } from "@/api";
import { appVersion } from "@/api/constant";
import request from "@/service";
import { PageParams, delEsPortParams } from "../estimation";
import { HfBasicFormType } from "@/featrue/voyagemanage/headFixture/basic/type";
import { HfBunkerClauseFormType } from "@/featrue/voyagemanage/headFixture/bunkerClause/type";
import { HfHireAndFeesFormType } from "@/featrue/voyagemanage/headFixture/hireAndFees/type";
import { HfPaymentTermsFormType } from "@/featrue/voyagemanage/headFixture/paymentTerms/type";
import { HfPortFormType } from "@/featrue/voyagemanage/headFixture/performance/type";

const enum ApiMap {
	getHeadFixtureGroupParameter,
	getHeadFixtureParameter,
	addHeadFixtureParameter,
	editHeadFixtureParameter,
	delHeadFixtureParameter
}

type ApiUrl = keyof typeof ApiMap;

const headFixtureApi: ApiParameter<ApiUrl> = {
	getHeadFixtureGroupParameter: {
		method: "get",
		url: appVersion + "/pre/head-fixture/query"
	},
	getHeadFixtureParameter: {
		method: "get",
		url: appVersion + "/pre/head-fixture/vesselHead"
	},
	addHeadFixtureParameter: {
		method: "post",
		url: appVersion + "/pre/head-fixture"
	},
	editHeadFixtureParameter: {
		method: "put",
		url: appVersion + "/pre/head-fixture"
	},
	delHeadFixtureParameter: {
		method: "delete",
		url: appVersion + "/pre/head-fixture/batch"
	}
};

export type getHeadFixtureGroupParams = {
	keyword: string;
	layCanFrom: number;
	layCanTo: number;
	status?: string;
} & PageParams;

export type getHeadFixtureParams = {
	id: number | string;
};

export type deleteHeadFixtureParams = number[];

export type CommonHeadFixtureParams = HfBunkerClauseFormType &
	HfHireAndFeesFormType &
	HfPaymentTermsFormType &
	Pick<HfPortFormType, "performanceClause"> & {
		performanceList: Pick<HfPortFormType, "ballast" | "laden" | "idle" | "work">;
	};

export type addHeadFixtureParams = Omit<HfBasicFormType, "cpNo"> & CommonHeadFixtureParams;

export type editHeadFixtureParams = HfBasicFormType &
	CommonHeadFixtureParams & {
		id?: number;
		voyageId?: string | number;
	};

export const getHeadFixtureGroupApi = (params: getHeadFixtureGroupParams) => {
	return request.request({
		...headFixtureApi.getHeadFixtureGroupParameter,
		params
	});
};

export const getHeadFixtureApi = (params: getHeadFixtureParams) => {
	return request.request({
		...headFixtureApi.getHeadFixtureParameter,
		url: `${headFixtureApi.getHeadFixtureParameter.url}/${params?.id}`
	});
};

export const addHeadFixtureApi = (params: addHeadFixtureParams) => {
	return request.request({
		...headFixtureApi.addHeadFixtureParameter,
		data: params
	});
};

export const editHeadFixtureApi = (params: editHeadFixtureParams) => {
	return request.request({
		...headFixtureApi.editHeadFixtureParameter,
		data: params
	});
};

export const delHeadFixtureApi = (params: deleteHeadFixtureParams) => {
	return request.request({
		...headFixtureApi.delHeadFixtureParameter,
		data: params
	});
};
