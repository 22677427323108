import React, { FC } from "react";
import style from "./index.module.less";
import { RouteAisFilterProps } from "./type";
import useIndex from "./hooks/useIndex";
import { FleetLoading, FleetSelect } from "@/components";
import classNames from "classnames";
import { formatThousandthNumber } from "@/tools/amount";

const RouteAisFilter: FC<RouteAisFilterProps> = ({ className, activeItem, onSelect }) => {
	const {
		open,
		keyword,
		loading,
		dataSource,
		selectValue,
		handleClosePopup,
		handleSearch,
		getAisGroup,
		handleItemSelect
	} = useIndex(activeItem, onSelect);

	return (
		<FleetSelect
			value={selectValue}
			showSearch
			popupMatchSelectWidth={false}
			className={className}
			onDropdownVisibleChange={handleClosePopup}
			open={open}
			onSearch={handleSearch}
			onFocus={() => getAisGroup()}
			dropdownRender={() => (
				<div className={style["aisList-filter"]}>
					<FleetLoading loading={loading} />
					<span className="aisList-filter-header">Search</span>
					<div className="aisList-filter-container">
						<ul className="aisList-filter-scrollArea">
							{dataSource?.map((item, index) => {
								return (
									<li
										className={classNames("aisList-filter-item", {
											"fleet-disabled":
												activeItem?.currentState?.imo === item?.currentState?.imo ||
												!item?.currentState
										})}
										key={index}
										onClick={() => handleItemSelect(item)}
									>
										<span className="aisList-filter-vesselName">
											{item?.currentState?.vesselName}
										</span>
										<div className="aisList-filter-otherInfo">
											<span>IMO: {item?.currentState?.imo ?? item?.imo}</span>
											<span>DWT: {formatThousandthNumber(item?.currentState?.dwt, 0, 0)}</span>
										</div>
									</li>
								);
							})}
						</ul>
					</div>
				</div>
			)}
		/>
	);
};

export default RouteAisFilter;
