import { useMemo } from "react";
import { EstPortRotationItemType } from "@/pages/estimation/components/portRotation/components/item/type";
import { isBallast, isLaden } from "@/tools/port";
import {
	EstVesselParticularType,
	EstCargoItemType,
	EstOtherItemType
} from "@/pages/estimation/module";
import { getTotalDays } from "@/pages/estimation/components/portRotation/tools";

export type EstimationQuicklockType = {
	tce: number;
	totalProfit: number;
	totalRevenue: number;
	totalCost: number;
	operationCost: number;
	hireCost: number;
	bunkerCost: number;
	ifo: number;
	mdo: number;
	ballastIfo: number;
	ladenIfo: number;
	totalIfo: number;
	ttlDistance: number;
	ecaDistance: number;
	ttlDistanceBallast: number;
	ecaDistanceBallast: number;
	ttlDistanceLaden: number;
	ecaDistanceLaden: number;
	totalSeaDays: number;
	ballastSeaDays: number;
	ladenSeaDays: number;
	ladenEcaDays: number;
	ballastEcaDays: number;
	totalEcaDays: number;
	ballastPortTotalDays: number;
	ladenPortTotalDays: number;
	totalPortDays: number;
	loadPortExpenses: number;
	ballastPortExpenses: number;
	postExpenses: number;
	cargoQuantity: number;
	portWorkDays: number;
	portIdleDays: number;
	adjustFactorttlDistanceBallast: number;
	adjustFactorttlDistanceLaden: number;
	adjustFactorecaDistanceBallast: number;
	adjustFactorecaDistanceLaden: number;
};

const useEstimation = (
	portGroup: EstPortRotationItemType[],
	cargoParticular: EstCargoItemType,
	vesselParticular: EstVesselParticularType,
	otherItem: EstOtherItemType
) => {
	const portRotationDays = useMemo(() => {
		let ballastPortWorkDays = 0;
		let ladenPortWorkDays = 0;
		let ballastPortIdleDays = 0;
		let ladenPortIdleDays = 0;
		let ballastPortTotalDays = 0;
		let ladenPortTotalDays = 0;
		let ttlDistanceBallast = 0;
		let ttlDistanceLaden = 0;
		let ecaDistanceBallast = 0;
		let ecaDistanceLaden = 0;
		let cargoQuantity = 0;
		let adjustFactorttlDistanceBallast = 0;
		let adjustFactorttlDistanceLaden = 0;
		let adjustFactorecaDistanceBallast = 0;
		let adjustFactorecaDistanceLaden = 0;
		let ballastFo = 0,
			ballastDo = 0,
			ladenFO = 0,
			ladenDo = 0,
			ballastSeaDays = 0,
			ladenSeaDays = 0,
			ballastEcaDays = 0,
			ladenEcaDays = 0;
		let ballastExpenses = 0,
			ladenExpenses = 0;
		let ballastMarginDistance = 0,
			ladenMarginDistance = 0;
		for (let portItem of portGroup) {
			if (isBallast(portItem?.purpose)) {
				ballastPortWorkDays += +portItem?.workDays;
				ballastPortIdleDays += +portItem?.idleDays;
				ballastPortTotalDays += getTotalDays({
					workDays: portItem?.workDays,
					idleDays: portItem?.idleDays,
					portMarginDay: portItem?.portMarginDay,
					portMarginUnit: portItem?.portMarginUnit
				});
				ttlDistanceBallast += +portItem?.distance;
				ecaDistanceBallast += +portItem?.ecaDistance;
				ballastDo += +portItem?.conDo;
				ballastFo += +portItem?.conFo;
				ballastExpenses += +portItem?.portExpense;
				ballastSeaDays += +portItem?.seaDays;
				ballastEcaDays += +portItem?.insideEcaDays;
				continue;
			}

			if (isLaden(portItem?.purpose)) {
				ladenPortWorkDays += +portItem?.workDays;
				ladenPortIdleDays += +portItem?.idleDays;
				ladenPortTotalDays += getTotalDays({
					workDays: portItem?.workDays,
					idleDays: portItem?.idleDays,
					portMarginDay: portItem?.portMarginDay,
					portMarginUnit: portItem?.portMarginUnit
				});
				ttlDistanceLaden += +portItem?.distance;
				ecaDistanceLaden += +portItem?.ecaDistance;
				ballastDo += +portItem?.conDo;
				ballastFo += +portItem?.conFo;
				cargoQuantity += +portItem?.cargoQuantity;
				ladenExpenses += +portItem?.portExpense;
				ladenSeaDays += +portItem?.seaDays;
				ladenEcaDays += +portItem?.insideEcaDays;
			}
		}

		// adjustFactorttlDistanceBallast =
		// 	+ttlDistanceBallast * (1 + +otherItem.ballastMarginDistance / 100);
		// adjustFactorttlDistanceLaden = +ttlDistanceLaden * (1 + +otherItem.ladenMarginDistance / 100);
		// adjustFactorecaDistanceBallast =
		// 	+ecaDistanceBallast * (1 + +otherItem.ballastMarginDistance / 100);
		// adjustFactorecaDistanceLaden = +ecaDistanceLaden * (1 + +otherItem.ladenMarginDistance / 100);

		switch (true) {
			case otherItem?.ballastMarginFlag === 0:
				adjustFactorttlDistanceBallast =
					+ttlDistanceBallast * (1 + +otherItem.ballastMarginDistance / 100);
				adjustFactorecaDistanceBallast =
					+ecaDistanceBallast * (1 + +otherItem.ballastMarginDistance / 100);
				ballastMarginDistance = (+ttlDistanceBallast * +otherItem.ballastMarginDistance) / 100;
				const ballastEcaMarginDistance =
					(+ecaDistanceBallast * +otherItem.ballastMarginDistance) / 100;
				ballastSeaDays =
					ballastSeaDays +
					(vesselParticular?.ballastSpeed
						? ballastMarginDistance / +vesselParticular?.ballastSpeed / 24
						: 0);
				ballastEcaDays =
					ballastEcaDays +
					(vesselParticular?.ballastSpeed
						? ballastEcaMarginDistance / +vesselParticular?.ballastSpeed / 24
						: 0);
				break;
			default:
				adjustFactorttlDistanceBallast = +ttlDistanceBallast;
				adjustFactorecaDistanceBallast = +ecaDistanceBallast;
				ballastSeaDays = ballastSeaDays + +otherItem?.ballastMarginDay;
				// ballastEcaDays = ballastEcaDays + +otherItem?.ballastMarginDay;
				break;
		}

		switch (true) {
			case otherItem?.ladenMarginFlag === 0:
				adjustFactorttlDistanceLaden =
					+ttlDistanceLaden * (1 + +otherItem.ladenMarginDistance / 100);
				adjustFactorecaDistanceLaden =
					+ecaDistanceLaden * (1 + +otherItem.ladenMarginDistance / 100);
				ladenMarginDistance = (+ttlDistanceLaden * +otherItem.ladenMarginDistance) / 100;
				const ladenEcaMarginDistance = (+ecaDistanceLaden * +otherItem.ladenMarginDistance) / 100;
				ladenSeaDays =
					ladenSeaDays +
					(vesselParticular?.ladenSpeed
						? ladenMarginDistance / +vesselParticular?.ladenSpeed / 24
						: 0);
				ladenEcaDays =
					ladenEcaDays +
					(vesselParticular?.ladenSpeed
						? ladenEcaMarginDistance / +vesselParticular?.ladenSpeed / 24
						: 0);
				break;
			default:
				adjustFactorttlDistanceLaden = +ttlDistanceLaden;
				adjustFactorecaDistanceLaden = +ecaDistanceLaden;
				ladenSeaDays = ladenSeaDays + +otherItem?.ladenMarginDay;
				// ladenEcaDays = ladenEcaDays + +otherItem?.ladenMarginDay;
				break;
		}

		let totalMarginValue = ballastMarginDistance + ladenMarginDistance;
		console.log("seadays", {
			ballastEcaDays,
			ladenEcaDays,
			ballastSeaDays,
			ladenSeaDays,
			flag: otherItem?.ladenMarginFlag
		});

		return {
			ballastPortWorkDays,
			ladenPortWorkDays,
			ballastPortIdleDays,
			ladenPortIdleDays,
			ladenPortTotalDays,
			ballastPortTotalDays,
			ttlDistanceBallast,
			ttlDistanceLaden,
			ecaDistanceBallast,
			ecaDistanceLaden,
			cargoQuantity,
			adjustFactorttlDistanceBallast,
			adjustFactorttlDistanceLaden,
			adjustFactorecaDistanceBallast,
			adjustFactorecaDistanceLaden,
			totalMarginValue,
			ballastDo,
			ballastFo,
			ladenFO,
			ladenDo,
			ballastExpenses,
			ladenExpenses,
			ballastEcaDays,
			ladenEcaDays,
			ballastSeaDays,
			ladenSeaDays
		};
	}, [otherItem, portGroup, vesselParticular]);

	const bunkerExpenses = useMemo(() => {
		return portGroup?.reduce((prev, curr) => {
			return prev + (["LB", "DB"]?.includes(curr?.purpose) ? +curr?.portExpense : 0);
		}, 0);
	}, [portGroup]);

	console.log("portGroup", portGroup);

	/** 运费率： 包干 / 每公吨 * 货数 */
	const freightIncome = useMemo(() => {
		if (cargoParticular.freightType === "LP") {
			return +cargoParticular.freightRate;
		}
		return (
			+cargoParticular.freightRate *
			+cargoParticular.cargoQuantity *
			(1 + +cargoParticular.cargoQuantityDiscrepancy / 100)
		);
	}, [
		cargoParticular.freightType,
		cargoParticular.freightRate,
		cargoParticular.cargoQuantity,
		cargoParticular.cargoQuantityDiscrepancy
	]);

	/** 折扣费用 = 运费 * 折扣率 */
	const freightAddCom = useMemo(() => {
		return (+cargoParticular.commission * +freightIncome) / 100;
	}, [cargoParticular.commission, freightIncome]);

	/** 中介费用 = 运费 * 中介费率 */
	const revenuesBrokerage = useMemo(() => {
		return (+cargoParticular.brokerage * +freightIncome) / 100;
	}, [cargoParticular.brokerage, freightIncome]);

	/**收入 */ // 250683.84
	const revenues = useMemo(() => {
		return +freightIncome - +freightAddCom - +revenuesBrokerage;
	}, [freightIncome, freightAddCom, revenuesBrokerage]);

	// /** 空载航行天数 */
	// const ballastSeaDays = useMemo(() => {
	// 	return (
	// 		portRotationDays.adjustFactorttlDistanceBallast / +(vesselParticular.ballastSpeed || 1) / 24
	// 	);
	// }, [portRotationDays, vesselParticular.ballastSpeed]);

	// /** 满载航行天数 */
	// const ladenSeaDays = useMemo(() => {
	// 	return portRotationDays.adjustFactorttlDistanceLaden / +(vesselParticular.ladenSpeed || 1) / 24;
	// }, [portRotationDays, vesselParticular.ladenSpeed]);

	// const ballastEcaDays = useMemo(() => {
	// 	return (
	// 		+portRotationDays.adjustFactorecaDistanceBallast / (+vesselParticular.ballastSpeed || 1) / 24
	// 	);
	// }, [portRotationDays, vesselParticular.ballastSpeed]);

	// const ladenEcaDays = useMemo(() => {
	// 	return (
	// 		+portRotationDays.adjustFactorecaDistanceLaden / (+vesselParticular.ladenSpeed || 1) / 24
	// 	);
	// }, [portRotationDays, vesselParticular.ladenSpeed]);

	const ballastSeaDaysIfo = useMemo(() => {
		return (
			(+portRotationDays.ballastSeaDays - +portRotationDays.ballastEcaDays) *
			+vesselParticular.seaFuelBallast
		);
	}, [
		portRotationDays.ballastSeaDays,
		portRotationDays.ballastEcaDays,
		vesselParticular.seaFuelBallast
	]);

	const ladenSeaDaysIfo = useMemo(() => {
		return (
			(+portRotationDays.ladenSeaDays - +portRotationDays.ladenEcaDays) *
			+vesselParticular.seaFuelLaden
		);
	}, [portRotationDays.ladenSeaDays, portRotationDays.ladenEcaDays, vesselParticular.seaFuelLaden]);

	const ballastPortMtIfo = useMemo(() => {
		return (
			portRotationDays.ballastPortWorkDays * +vesselParticular.portFuelWork +
			portRotationDays.ballastPortIdleDays * +vesselParticular.portFuelIdle
		);
	}, [portRotationDays, vesselParticular.portFuelWork, vesselParticular.portFuelIdle]);

	const ladenPortMtIfo = useMemo(() => {
		return (
			portRotationDays.ladenPortWorkDays * +vesselParticular.portFuelWork +
			portRotationDays.ladenPortIdleDays * +vesselParticular.portFuelIdle
		);
	}, [portRotationDays, vesselParticular.portFuelWork, vesselParticular.portFuelIdle]);

	// const ballastIfo = useMemo(() => {
	//   return +ballastSeaDaysIfo + +ballastPortMtIfo;
	// }, [ballastSeaDaysIfo, ballastPortMtIfo]);

	// const ladenIfo = useMemo(() => {
	//   return +ladenPortMtIfo + +ladenSeaDaysIfo;
	// }, [ladenPortMtIfo, ladenSeaDaysIfo]);

	const ballastIfo = useMemo(() => {
		return +ballastSeaDaysIfo + +portRotationDays.ballastFo;
	}, [ballastSeaDaysIfo, portRotationDays.ballastFo]);

	const ladenIfo = useMemo(() => {
		return +portRotationDays.ladenFO + +ladenSeaDaysIfo;
	}, [ladenSeaDaysIfo, portRotationDays.ladenFO]);

	const ifoCost = useMemo(() => {
		return (ballastIfo + ladenIfo) * +vesselParticular.bunkerPriceIfo;
	}, [ballastIfo, ladenIfo, vesselParticular.bunkerPriceIfo]);

	const portExpenses = useMemo(() => {
		return portRotationDays?.ladenExpenses + portRotationDays?.ballastExpenses;
	}, [portRotationDays?.ladenExpenses, portRotationDays?.ballastExpenses]);

	const totalSeaDays = useMemo(() => {
		return +portRotationDays.ballastSeaDays + +portRotationDays.ladenSeaDays;
	}, [portRotationDays.ballastSeaDays, portRotationDays.ladenSeaDays]);

	const mdoCost = useMemo(() => {
		return (
			(portRotationDays.ballastEcaDays * +vesselParticular.seaFuelBallast +
				portRotationDays.ladenEcaDays * +vesselParticular.seaFuelLaden +
				portRotationDays.ballastDo +
				portRotationDays.ladenDo +
				totalSeaDays * +vesselParticular.seaFuelMdo) *
			+vesselParticular.bunkerPriceMdo
		);
	}, [
		portRotationDays.ballastEcaDays,
		portRotationDays.ladenEcaDays,
		vesselParticular.seaFuelBallast,
		vesselParticular.seaFuelLaden,
		portRotationDays.ballastDo,
		portRotationDays.ladenDo,
		totalSeaDays,
		vesselParticular.seaFuelMdo,
		vesselParticular.bunkerPriceMdo
	]);

	console.log("days", {
		ballastEcaDays: portRotationDays?.ballastEcaDays,
		ladenEcaDays: portRotationDays?.ladenEcaDays,
		ballastSeaDays: portRotationDays?.ballastSeaDays,
		ladenSeaDays: portRotationDays?.ladenSeaDays,
		ballastSeaDaysIfo:
			(+portRotationDays.ballastSeaDays - +portRotationDays.ballastEcaDays) *
			+vesselParticular.seaFuelBallast,
		ladenSeaDaysIfo:
			(+portRotationDays.ladenSeaDays - +portRotationDays.ladenEcaDays) *
			+vesselParticular.seaFuelLaden
	});

	// const mdoCost = useMemo(() => {
	//   return (ballastEcaDays * +vesselParticular.seaFuelBallast + ladenEcaDays * + vesselParticular.seaFuelLaden + (portRotationDays.ballastPortTotalDays + portRotationDays.ladenPortTotalDays) * +vesselParticular.portFuelMdo + totalSeaDays * +vesselParticular.seaFuelMdo) * +vesselParticular.bunkerPriceMdo
	// }, [ballastEcaDays, vesselParticular.seaFuelBallast, ladenEcaDays, vesselParticular.seaFuelLaden, portRotationDays.ballastPortTotalDays, portRotationDays.ladenPortTotalDays, vesselParticular.portFuelMdo, totalSeaDays, vesselParticular.seaFuelMdo, vesselParticular.bunkerPriceMdo])

	const operationCost = useMemo(() => {
		return ifoCost + mdoCost + portExpenses + +vesselParticular.otherExpenses;
	}, [ifoCost, mdoCost, portExpenses, vesselParticular.otherExpenses]);

	const bunkerCost = useMemo(() => {
		return ifoCost + mdoCost;
	}, [ifoCost, mdoCost]);

	const hireCost = useMemo(() => {
		return (
			+vesselParticular.dailyHire *
			(portRotationDays.ballastSeaDays +
				portRotationDays.ladenSeaDays +
				portRotationDays.ballastPortTotalDays +
				portRotationDays.ladenPortTotalDays)
		);
	}, [
		vesselParticular.dailyHire,
		portRotationDays.ballastSeaDays,
		portRotationDays.ladenSeaDays,
		portRotationDays.ballastPortTotalDays,
		portRotationDays.ladenPortTotalDays
	]);

	const addCom = useMemo(() => {
		return (hireCost * +vesselParticular.commission) / 100;
	}, [hireCost, vesselParticular.commission]);

	const totalHireCostBrokerage = useMemo(() => {
		return (hireCost * +vesselParticular.brokerage) / 100;
	}, [hireCost, vesselParticular.brokerage]);

	const totalHireCost = useMemo(() => {
		return hireCost - (addCom + totalHireCostBrokerage);
	}, [hireCost, addCom, totalHireCostBrokerage]);

	const totalCost = useMemo(() => {
		return operationCost + totalHireCost;
	}, [operationCost, totalHireCost]);

	/** profit: 利润 = 收入 - 成本 */
	const profit = useMemo(() => {
		return revenues - totalCost;
	}, [revenues, totalCost]);

	const tce = useMemo(() => {
		const totalDays =
			totalSeaDays + portRotationDays.ballastPortTotalDays + portRotationDays.ladenPortTotalDays;

		return totalDays ? (revenues - operationCost) / totalDays : 0;
	}, [revenues, operationCost, totalSeaDays, portRotationDays]);

	const quicklockForm: EstimationQuicklockType = {
		totalProfit: +profit.toFixed(3),
		tce: +tce.toFixed(3),
		totalRevenue: +revenues.toFixed(3),
		totalCost: +totalCost.toFixed(3),
		operationCost: +operationCost.toFixed(3),
		hireCost: +hireCost.toFixed(3),
		bunkerCost: +bunkerCost.toFixed(3),
		ifo: +ifoCost.toFixed(3),
		mdo: +mdoCost.toFixed(3),
		ballastIfo: +ballastIfo.toFixed(3),
		ladenIfo: +ladenIfo.toFixed(3),
		totalIfo: +ballastIfo.toFixed(3) + +ladenIfo.toFixed(3),
		ttlDistanceBallast: +portRotationDays.ttlDistanceBallast.toFixed(3),
		ecaDistanceBallast: +portRotationDays.ecaDistanceBallast.toFixed(3),
		ttlDistanceLaden: +portRotationDays.ttlDistanceLaden.toFixed(3),
		ecaDistanceLaden: +portRotationDays.ecaDistanceLaden.toFixed(3),
		ttlDistance: +(portRotationDays.ttlDistanceBallast + portRotationDays.ttlDistanceLaden).toFixed(
			3
		),
		ecaDistance: +(portRotationDays.ecaDistanceBallast + portRotationDays.ecaDistanceLaden).toFixed(
			3
		),
		totalSeaDays: +totalSeaDays.toFixed(3),
		ballastSeaDays: +portRotationDays.ballastSeaDays.toFixed(3),
		ladenSeaDays: +portRotationDays.ladenSeaDays.toFixed(3),
		ladenEcaDays: +portRotationDays.ladenEcaDays.toFixed(3),
		ballastEcaDays: +portRotationDays.ballastEcaDays.toFixed(3),
		totalEcaDays: +(portRotationDays.ballastEcaDays + portRotationDays.ladenEcaDays).toFixed(3),
		ballastPortTotalDays: +portRotationDays.ballastPortTotalDays.toFixed(3),
		ladenPortTotalDays: +portRotationDays.ladenPortTotalDays.toFixed(3),
		totalPortDays: +(
			portRotationDays.ballastPortTotalDays + portRotationDays.ladenPortTotalDays
		).toFixed(3),
		loadPortExpenses: +portRotationDays?.ladenExpenses?.toFixed(3),
		ballastPortExpenses: +portRotationDays?.ballastExpenses?.toFixed(3),
		postExpenses: +portExpenses.toFixed(3),
		cargoQuantity: portRotationDays.cargoQuantity,
		portIdleDays: portRotationDays.ladenPortIdleDays + portRotationDays.ballastPortIdleDays,
		portWorkDays: portRotationDays.ladenPortWorkDays + portRotationDays.ballastPortWorkDays,
		adjustFactorttlDistanceBallast: +portRotationDays.adjustFactorttlDistanceBallast?.toFixed(3),
		adjustFactorttlDistanceLaden: +portRotationDays.adjustFactorttlDistanceLaden?.toFixed(3),
		adjustFactorecaDistanceBallast: +portRotationDays.adjustFactorecaDistanceBallast?.toFixed(3),
		adjustFactorecaDistanceLaden: +portRotationDays.adjustFactorecaDistanceLaden?.toFixed(3)
	};

	return {
		quicklockForm,
		calculate: {
			totalProfit: +profit.toFixed(3),
			tce: +tce.toFixed(3),
			totalRevenue: +revenues.toFixed(3),
			totalHireCost: +totalHireCost.toFixed(3),
			freightIncome: +freightIncome.toFixed(3),
			freightAddCommission: +freightAddCom.toFixed(3),
			freightBrokerage: +revenuesBrokerage.toFixed(3),
			operationCost: +operationCost.toFixed(3),
			ifoCost: +ifoCost.toFixed(3),
			mdoCost: +mdoCost.toFixed(3),
			portExpense: +portExpenses.toFixed(3),
			otherExpense: +(+vesselParticular.otherExpenses).toFixed(3),
			totalCost: +totalCost.toFixed(3),
			commission: +addCom.toFixed(3),
			bunkerCost: +bunkerCost.toFixed(3),
			brokerage: +totalHireCostBrokerage.toFixed(3),
			hireCost: +hireCost.toFixed(3),
			ttlDistanceBallast: portRotationDays.ttlDistanceBallast,
			ecaDistanceBallast: portRotationDays.ecaDistanceBallast,
			ttlDistanceLaden: portRotationDays.ttlDistanceLaden,
			ecaDistanceLaden: portRotationDays.ecaDistanceLaden,
			adjustFactorttlDistanceBallast: +portRotationDays.adjustFactorttlDistanceBallast?.toFixed(3),
			adjustFactorttlDistanceLaden: +portRotationDays.adjustFactorttlDistanceLaden?.toFixed(3),
			adjustFactorecaDistanceBallast: +portRotationDays.adjustFactorecaDistanceBallast?.toFixed(3),
			adjustFactorecaDistanceLaden: +portRotationDays.adjustFactorecaDistanceLaden?.toFixed(3),
			totalMarginValue: +portRotationDays.totalMarginValue?.toFixed(2),
			bunkerExpenses
		}
	};
};

export default useEstimation;
