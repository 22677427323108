import React, { FC, Fragment, useEffect, useMemo, useRef } from "react";
import classnames from "classnames";
import style from "./index.module.less";
import { MovementTaskListProps } from "./type";
import useHorizontalScroll from "@/hook/common/useHorizontalScroll";
import { FleetCustomRadio } from "@/components/Radio";
import { FleetLoading, FleetSelect } from "@/components";
import { MovementTaskStageItemType } from "../../type";

const MovementTaskList: FC<MovementTaskListProps> = ({
	loading,
	currentStageItem,
	activeItem,
	activeVoyageType,
	items,
	onSelect,
	onStageSelect
}) => {
	const { elementRef } = useHorizontalScroll<HTMLDivElement>({ speed: 10 });

	const currentVoyageStage = useMemo(() => {
		return (
			`${currentStageItem?.currentStage}` +
			(currentStageItem?.portName ? `(${currentStageItem?.portName})` : "")
		);
	}, [currentStageItem]);

	const stageOptions = useMemo(() => {
		return items?.map((item) => {
			return {
				label: `${item?.stage}` + (item?.portName ? `(${item?.portName})` : ""),
				value: `${item?.stage}` + (item?.portName ? `(${item?.portName})` : "")
			};
		});
	}, [items]);

	console.log("stageOptions", currentStageItem, items);

	return (
		<div className={style["movement-taskList"]}>
			<div className="taskList-header">
				<FleetLoading loading={loading} />
				<div className="taskList-cursor">
					<span
						className={classnames("font_family fleet-a-slideup", {
							launch: !!activeItem
						})}
					></span>
					<span>TASK</span>
				</div>
				<div className="taskList-stage">
					<FleetCustomRadio
						value={activeVoyageType === "TCTC" ? true : !!currentStageItem?.type}
						disabled={activeVoyageType === "TCTC"}
						onChange={(value) => {
							onStageSelect?.({
								type: value ? 1 : 0
							});
						}}
					/>
					<FleetSelect
						className="taskList-stage--select"
						value={[currentVoyageStage]}
						options={stageOptions}
						onChange={(
							value: `${MovementTaskStageItemType["currentStage"]}(${MovementTaskStageItemType["portName"]})`
						) => {
							const [currentStage, currentPortName] = value?.split("(") as [
								MovementTaskStageItemType["currentStage"],
								MovementTaskStageItemType["portName"]
							];
							const taskItem = items?.find(
								(item) =>
									item?.stage === currentStage &&
									item?.portName === currentPortName?.replace(")", "")
							);
							console.log("currentStage", currentStage, {
								portName: taskItem?.portName,
								currentPortRotationId: taskItem?.portRotationId
							});
							onStageSelect?.({
								currentStage,
								portName: taskItem?.portName ?? "",
								currentPortRotationId: taskItem?.portRotationId ?? null
							});
						}}
						disabled={activeVoyageType === "TCTC" ? false : currentStageItem?.type === 0}
					/>
				</div>
			</div>
			<div className="taskList-container" ref={elementRef}>
				<div className="taskList-scrollArea">
					{items?.map((item, index, arr) => {
						console.log("activeVoyageType", item, activeItem);
						return (
							<Fragment key={index}>
								<div
									className={classnames("taskList-item", {
										["current-stage"]:
											item?.stage === currentStageItem?.currentStage &&
											item?.portRotationId === currentStageItem?.currentPortRotationId,
										active:
											item?.stage === activeItem?.stage &&
											item?.portRotationId === activeItem?.portRotationId
									})}
									onClick={(event) => {
										event?.currentTarget?.scrollIntoView({
											behavior: "smooth",
											block: "end"
										});
										onSelect?.(item);
									}}
								>
									<div className="taskList-item--portInfo">
										<span>{item?.stage}</span>
										<span>
											{item?.count} / {item?.totalCount} Task
										</span>
									</div>
									<span>{item?.portName}</span>
								</div>
								{index !== arr?.length - 1 && <span className="taskList-lines"></span>}
							</Fragment>
						);
					})}
				</div>
			</div>
		</div>
	);
};

export default MovementTaskList;
