import React, { FC } from "react";
import style from "./index.module.less";
import { AparListFilterProps, AparListFilterItemType } from "./type";
import { Button } from "@/_components";
import { FleetInput, FleetRangePicker, FleetSelect } from "@/components";
import dayjs from "dayjs";
import { invStatusOptions, settleOptions, voyageTypeOptions } from "./source";
import { CommonCompanySelect } from "@/common/Select";
import { CommonRoleWrap } from "@/common";

const AparListFilter: FC<AparListFilterProps> = ({
	item,
	disabled,
	onCommit,
	onSearch,
	onDownload,
	onSettle
}) => {
	return (
		<div className={style["apAr-list-filter"]}>
			<span className="filter-title">Transaction Summary</span>
			<div className="filter-container">
				<div className="filter-wrap">
					<FleetInput
						value={undefined}
						onChange={onSearch}
						placeholder="Vessel, Voyage No."
						prefix="LayoutSearchIcon"
					/>
					<FleetRangePicker
						value={[
							item?.start ? dayjs(item?.start) : undefined,
							item?.end ? dayjs(item?.end) : undefined
						]}
						onChange={(vals) => {
							if (!vals) {
								onCommit?.({
									start: null,
									end: null
								});
								return;
							}
							onCommit?.({
								start: vals?.[0]?.startOf("minutes")?.valueOf(),
								end: vals?.[1]?.startOf("minutes")?.valueOf()
							});
						}}
					/>
					<FleetSelect
						allowClear
						placeholder="Settled Status"
						value={item?.status}
						options={settleOptions}
						onChange={(val: AparListFilterItemType["status"]) => {
							onCommit?.({
								status: val
							});
						}}
					/>
					<FleetSelect
						allowClear
						placeholder="AP/AR Status"
						value={item?.type}
						options={invStatusOptions}
						onChange={(val: AparListFilterItemType["type"]) => {
							onCommit?.({
								type: val
							});
						}}
					/>
					<CommonCompanySelect
						placeholder="Counterparty"
						activeItems={{
							id: item?.counterpartyId,
							companyName: item?.companyName
						}}
						showKey="companyName"
						onFinish={(newItem, type) => {
							onCommit?.({
								counterpartyId: newItem?.id ?? null,
								companyName: newItem?.companyName ?? ""
							});
						}}
					/>
					<FleetSelect
						value={item?.voyageType}
						options={voyageTypeOptions}
						allowClear
						placeholder="Voyage Type"
						onChange={(val: AparListFilterItemType["voyageType"]) => {
							onCommit?.({
								voyageType: val
							});
						}}
					/>
				</div>
				<div className="filter-toolbar">
					{/* <CommonRoleWrap roleStr="apar:list:batchSettle">
						<Button
							type="secondary"
							prefixIcon="fleet-check"
							disabled={disabled}
							onClick={() => onSettle?.("Settled")}
						>
							Settled
						</Button>
					</CommonRoleWrap> */}
					<CommonRoleWrap roleStr="apar:list:download">
						<Button prefixIcon="fleet-download" disabled={disabled} onClick={onDownload}>
							Download
						</Button>
					</CommonRoleWrap>
					<CommonRoleWrap roleStr="apar:list:batchCancel">
						<Button
							type="secondary"
							prefixIcon="fleet-check"
							disabled={disabled}
							onClick={() => onSettle?.("Unsettled")}
						>
							Cancel
						</Button>
					</CommonRoleWrap>
				</div>
			</div>
		</div>
	);
};

export default AparListFilter;
