import React, { FC } from "react";
import classnames from "classnames";
import style from "./index.module.less";
import { PortListExpertProps } from "./type";
import { FleetRangePicker } from "@/components";
import { VesselItem } from "../index";
import dayjs, { Dayjs } from "dayjs";
import { disabledDate } from "./source";
import useIndex from "./hooks/useIndex";
const PortListExpert: FC<PortListExpertProps> = ({ dateRange, onCommit }) => {
	const { startDate, endDate, setStartDate, setEndDate } = useIndex();
	return (
		<div className={classnames(style["port-list-expert"])}>
			<div className="portListExpert-header">
				<FleetRangePicker
					allowClear
					value={[
						dateRange?.startDate ? dayjs(dateRange?.startDate) : null,
						dateRange?.endDate ? dayjs(dateRange?.endDate) : null
					]}
					onChange={(vals: [Dayjs, Dayjs]) => {
						onCommit?.({
							startDate: vals?.[0]?.format("YYYY-MM-DD"),
							endDate: vals?.[1].format("YYYY-MM-DD")
						});
					}}
					className="portListExpert-range"
					disabledDate={disabledDate}
					showHour={false}
					showMinute={false}
					showTime={false}
				/>
				<span>Next 30 days estimated arrival vessels is available</span>
			</div>
			{/* <div className="portListExpert-list">
				{dataSource?.map((item) => {
					return <VesselItem item={item} />;
				})}
			</div> */}
		</div>
	);
};

export default PortListExpert;
