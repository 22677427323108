import { uuid } from "@/tools";
import React, { MutableRefObject, PropsWithChildren, ReactNode, RefObject, createContext, useRef, useState } from "react";
import { createPortal } from "react-dom";

interface ConfigProviderType {
	currentZindex: number;
	list: { id: string; parentNode: HTMLElement; element: ReactNode }[]
	add?: (id: string, element: ReactNode, parentNode?: HTMLElement) => void
	remove?: (id: string) => void
	getElement?: (id: string) => { id: string; parentNode: HTMLElement; element: ReactNode }
}

export const initalConfigProvider: ConfigProviderType = {
	currentZindex: 0,
	list: []
}

export const DialogContext = createContext<ConfigProviderType>(initalConfigProvider);

export function ConfigProvider(props: PropsWithChildren) {

	const { children } = props;
	const [currentZindex, setCurrentZindex] = useState(0);
	const [dataSource, setDataSource] = useState<ConfigProviderType["list"]>([]);

	const remove = (id: string) => {		
		const currentIndex = dataSource?.findIndex(item => item?.id === id);
		if (currentIndex !== -1) {
			setDataSource(prev => {
				const currentItem = prev?.[currentIndex];
				const parentNode = currentItem.parentNode;
				const currentElement = parentNode.getElementsByClassName(currentItem?.id)
				currentElement?.[0] && parentNode?.removeChild(currentElement?.[0]);
				prev.splice(currentIndex, 1);
				return [...prev];
			})
		}
	}

	const add = (id: string, element: ReactNode, parentNode?: HTMLElement) => {
		setCurrentZindex(prev => prev + 1);
		const rootElement = parentNode ?? document?.getElementById("app");
		setDataSource(prev => {
			const currentIndex = prev?.findIndex(item => item.id === id);
			if (currentIndex === -1) {
				return [...prev, { id: id, parentNode: rootElement, element }]
			} else {
				prev?.splice(currentIndex, 1, {
					...prev?.[currentIndex],
					element
				})
				return [...prev]
			}
		})
	}

	const getElement = (id: string) => {
		return dataSource.find(item => item.id === id)
	}

	return (
		<DialogContext.Provider value={{ currentZindex, list: dataSource, add, remove, getElement }}>
			{children}
		</DialogContext.Provider>
	);
}
