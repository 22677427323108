import React, { FC, useEffect, useState } from "react";
import style from "./index.module.less";
import { EstCompareItemProps } from "./type";
import { FleetDatePicker, FleetFormItem, FleetInput, FleetVerifyInput } from "@/components";
import { FleetCustomRadio } from "@/components/Radio";
import { useEstIndex } from "@/pages/estimation/business/details/module";
import { useTranslation } from "react-i18next";
import { CommonPortSelect, CommonVesselSelect } from "@/common";
import { FleetTabbar } from "@/components/Tabs";
import { CargoUnitType, FreightType } from "@/types/common";
import dayjs from "dayjs";
import { Button } from "@/_components";
import { EstPortRotation } from "@/pages/estimation/components";
import { formatThousandthNumber } from "@/tools/amount";
import { profitOptions } from "./source";
import classnames from "classnames";

const EstCompareItem: FC<EstCompareItemProps> = ({ item, cache, isActive, onSelect }) => {
	const { t } = useTranslation();
	const {
		portList,
		portCategory,
		handlePortRotationConfirm,
		handlePortItemAdd,
		handlePortItemDelete,
		calculate,
		init,
		handleDeliverySelect,
		vesselItem,
		handleVesselItemChange,
		handleVesselItemCommit,
		cargoItem,
		handleCargoItemChange,
		otherItem,
		handleOtherItemChange,
		queryLoading,
		handleBatchFoConsumCommit
	} = useEstIndex(item?.id, cache);

	const [currentType, setCurrentType] = useState<"ballast" | "laden">("ballast");
	const [hide, setHide] = useState(true);

	const handleOpen = (type: "ballast" | "laden") => {
		setCurrentType(type);
		setHide(false);
	};

	useEffect(() => {
		if (!item) return;
		console.log("init", item);
		init(item);
	}, [item]);

	return (
		<div className={style["estCompare-item"]}>
			<div className="compareItem-header">
				<FleetCustomRadio
					label="Vessel Particular"
					className="compareItem-header--select"
					value={isActive}
					onChange={(val) => {
						onSelect?.(item?.id, val);
					}}
				/>
			</div>
			<div className="compareItem-container fleet-nonScrolBar">
				<div className="compareItem-scrollArea">
					<div className="compareItem-warp">
						<FleetFormItem label={t("common.vesselName")} needVerity>
							<CommonVesselSelect
								primaryKey="mmsi"
								showKey="vesselName"
								activeItems={{
									mmsi: vesselItem?.mmsi,
									vesselName: vesselItem?.vesselName
								}}
								onFinish={(newItem, type) => {
									handleVesselItemCommit?.({
										vesselName: newItem?.vesselName ?? "",
										dwt: newItem?.dwt ?? null,
										grain: newItem?.grainCapacity ?? null,
										bale: newItem?.baleCapacity ?? null,
										mmsi: newItem?.mmsi ?? null,
										imo: newItem?.imo ?? null
									});
								}}
							/>
						</FleetFormItem>
						<div className="compareItem-formItem two-equal ">
							<FleetVerifyInput
								label={t("common.ownerName")}
								value={vesselItem?.ownerName}
								onChange={(val: string) => {
									handleVesselItemChange?.("ownerName", val);
								}}
							/>
							<FleetVerifyInput
								needVerity={true}
								label={t("common.dwt")}
								type="number"
								value={vesselItem?.dwt}
								onChange={(val: number) => {
									handleVesselItemChange?.("dwt", val);
								}}
							/>
						</div>
						<div className="compareItem-formItem two-equal">
							<FleetVerifyInput
								label={t("common.grain")}
								value={vesselItem?.grain}
								onChange={(val: number) => {
									handleVesselItemChange?.("grain", val);
								}}
							/>
							<FleetVerifyInput
								label={t("common.bale")}
								value={vesselItem?.bale}
								onChange={(val: number) => {
									handleVesselItemChange?.("bale", val);
								}}
							/>
						</div>
						<div className="compareItem-formItem full">
							<FleetFormItem needVerity={true} label={t("common.delievryPort")}>
								<CommonPortSelect
									disabled={queryLoading}
									activeItems={{
										id: vesselItem?.deliveryPortId,
										portCode: vesselItem?.deliveryPortCode,
										portName: vesselItem?.deliveryPortName
									}}
									showKey="portName"
									onFinish={(newItem, type) => {
										handleDeliverySelect?.({
											id: newItem?.id,
											portCode: newItem?.portCode,
											portName: newItem?.portName
										});
									}}
								/>
							</FleetFormItem>
						</div>
						<div className="compareItem-formItem two-equal">
							<FleetVerifyInput
								needVerity={true}
								type="number"
								label={t("common.ballastSpeed")}
								value={vesselItem?.ballastSpeed}
								onChange={(val: number) => {
									handleVesselItemChange?.("ballastSpeed", val);
								}}
							/>
							<FleetVerifyInput
								needVerity={true}
								label={t("common.ladenSpeed")}
								type="number"
								value={vesselItem?.ladenSpeed}
								onChange={(val: number) => {
									handleVesselItemChange?.("ladenSpeed", val);
								}}
							/>
						</div>
						<div className="compareItem-formItem thirty-equal">
							<FleetVerifyInput
								needVerity={true}
								type="number"
								label={t("common.seaFuelBallast")}
								value={vesselItem?.seaFuelBallast}
								onChange={(val: number) => {
									handleVesselItemChange?.("seaFuelBallast", val);
									// onChange?.(index, "vesselParticular/seaFuelBallast", val)
								}}
							/>
							<FleetVerifyInput
								needVerity={true}
								type="number"
								label={t("common.seaFuelLaden")}
								value={vesselItem?.seaFuelLaden}
								onChange={(val: number) => {
									handleVesselItemChange?.("seaFuelLaden", val);
									// onChange?.(index, "vesselParticular/seaFuelLaden", val)
								}}
							/>
							<FleetVerifyInput
								needVerity={true}
								label={t("common.seaFuelMdo")}
								type="number"
								value={vesselItem?.seaFuelMdo}
								onChange={(val: number) => {
									handleVesselItemChange?.("seaFuelMdo", val);
									// onChange?.(index, "vesselParticular/seaFuelMdo", val)
								}}
							/>
						</div>
						<div className="compareItem-formItem two-equal">
							<FleetVerifyInput
								needVerity={true}
								type="number"
								label={t("estimation.portFuelWork")}
								value={vesselItem?.portFuelWork}
								onChange={(val: number) => {
									handleVesselItemChange?.("portFuelWork", val);
									handleBatchFoConsumCommit?.({ ...vesselItem, portFuelWork: val })
								}}
							/>
							<FleetVerifyInput
								needVerity={true}
								type="number"
								label={t("estimation.portFuelIdle")}
								value={vesselItem?.portFuelIdle}
								onChange={(val: number) => {
									handleVesselItemChange?.("portFuelIdle", val);
									handleBatchFoConsumCommit?.({ ...vesselItem, portFuelIdle: val })
								}}
							/>
						</div>
						<div className="compareItem-formItem two-equal">
							<FleetVerifyInput
								needVerity
								value={vesselItem.portWorkMdo}
								placeholder="MDO"
								type="number"
								onChange={(val: number) => {
									handleVesselItemChange?.("portWorkMdo", val);
									handleBatchFoConsumCommit?.({ ...vesselItem, portWorkMdo: val })
								}}
								label={t("estimation.portWorkMdo")}
							/>
							<FleetVerifyInput
								needVerity
								value={vesselItem.portIdleMdo}
								placeholder="MDO"
								type="number"
								onChange={(val: number) => {
									handleVesselItemChange?.("portIdleMdo", val);
									handleBatchFoConsumCommit?.({ ...vesselItem, portIdleMdo: val })
								}}
								label={t("estimation.portFuelMdo")}
							/>
						</div>
						<div className="compareItem-formItem two-equal">
							<FleetVerifyInput
								needVerity={true}
								type="number"
								label={t("common.bunkerPriceIfo")}
								value={vesselItem?.bunkerPriceIfo}
								onChange={(val: number) => {
									handleVesselItemChange?.("bunkerPriceIfo", val);
								}}
							/>
							<FleetVerifyInput
								needVerity={true}
								type="number"
								label={t("common.bunkerPriceMdo")}
								value={vesselItem?.bunkerPriceMdo}
								onChange={(val: number) => {
									handleVesselItemChange?.("bunkerPriceMdo", val);
								}}
							/>
						</div>
						<div className="compareItem-formItem two-equal">
							<FleetVerifyInput
								needVerity={true}
								type="number"
								label={t("common.dailyHire")}
								value={vesselItem?.dailyHire}
								onChange={(val: number) => {
									handleVesselItemChange?.("dailyHire", val);
								}}
							/>
							<FleetVerifyInput
								type="number"
								label={t("common.commission")}
								value={vesselItem?.commission}
								onChange={(val: number) => {
									handleVesselItemChange?.("commission", val);
								}}
							/>
						</div>
						<div className="compareItem-formItem two-equal">
							<FleetVerifyInput
								label={t("common.brokerage")}
								type="number"
								value={vesselItem?.brokerage}
								onChange={(val: number) => {
									handleVesselItemChange?.("brokerage", val);
								}}
							/>
							<FleetVerifyInput
								type="number"
								label={t("common.otherExpenses")}
								value={vesselItem?.otherExpenses}
								onChange={(val: number) => {
									handleVesselItemChange?.("otherExpenses", val);
								}}
							/>
						</div>
					</div>
					<span className="compareItem-formItem--title">Cargo&Port Rotation</span>
					<div className="compareItem-warp">
						<div className="compareItem-formItem full">
							<FleetVerifyInput
								label={t("common.chartererName")}
								value={cargoItem?.chartererName}
								onChange={(val: string) => {
									handleCargoItemChange?.("chartererName", val);
								}}
							/>
						</div>
						<div className="compareItem-formItem full">
							<FleetVerifyInput
								placeholder={t("common.cargoName")}
								label={t("common.cargoName")}
								value={cargoItem?.cargoName}
								onChange={(val: string) => {
									handleCargoItemChange?.("cargoName", val);
								}}
							/>
						</div>
						<div className="compareItem-formItem thirty-noequal">
							<FleetVerifyInput
								needVerity
								label={t("common.cargoQuantity")}
								type="number"
								value={cargoItem?.cargoQuantity}
								onChange={(val: number) => {
									handleCargoItemChange?.("cargoQuantity", val);
								}}
							/>
							<FleetTabbar
								disabled
								list={["MT", "CBM"]}
								value={cargoItem?.cargoUnit}
								onChange={(val: CargoUnitType) => {
									handleCargoItemChange?.("cargoUnit", val);
								}}
							/>
							<FleetInput
								type="number"
								value={cargoItem?.cargoQuantityDiscrepancy}
								onChange={(val: number) => {
									handleCargoItemChange?.("cargoQuantityDiscrepancy", val);
								}}
							/>
						</div>
						<div className="compareItem-formItem full">
							<FleetFormItem label={t("common.layCanFrom")}>
								<FleetDatePicker
									value={cargoItem?.layCanFrom ? dayjs(cargoItem?.layCanFrom) : null}
									onChange={(val, timeStramp) => {
										handleCargoItemChange?.(
											"layCanFrom",
											timeStramp?.startOf("minutes")?.valueOf()
										);
									}}
									format={(value) => value?.format("YYYY-MM-DD HH:mm")}
									showTime={{
										format: "HH:mm"
									}}
									showHour
									showMinute
									allowClear
									disabledDate={(current) => {
										return (
											cargoItem?.layCanTo && current > dayjs(cargoItem?.layCanTo).endOf("minutes")
										);
									}}
								/>
							</FleetFormItem>
						</div>
						<div className="compareItem-formItem full">
							<FleetFormItem label={t("common.layCanTo")}>
								<FleetDatePicker
									value={cargoItem?.layCanTo ? dayjs(cargoItem?.layCanTo) : null}
									onChange={(val, timeStramp) => {
										handleCargoItemChange?.("layCanTo", timeStramp?.startOf("minutes")?.valueOf());
									}}
									format={(value) => value?.format("YYYY-MM-DD HH:mm")}
									showTime={{
										format: "HH:mm"
									}}
									showHour
									showMinute
									allowClear
									disabledDate={(current) => {
										return (
											cargoItem?.layCanFrom &&
											current < dayjs(cargoItem?.layCanFrom).endOf("minutes")
										);
									}}
								/>
							</FleetFormItem>
						</div>
						<div className="compareItem-formItem nighty">
							<FleetFormItem needVerity label="Ballast Port Rotation">
								<CommonPortSelect
									showCount={2}
									disabled={queryLoading}
									activeItems={portCategory?.ballastPortList}
									primaryKey="portCode"
									showKey="portName"
									onFinish={(newItem, type) => {
										switch (true) {
											case type === "add":
												handlePortItemAdd?.("ballast", {
													portCode: newItem?.portCode,
													portName: newItem?.portName,
													purpose: "LD"
												});
												break;
											case type === "del":
												const currentIndex = portList?.findIndex(
													(item) => item?.portCode === newItem?.portCode
												);
												handlePortItemDelete?.(currentIndex);
												break;
											default:
												break;
										}
									}}
								/>
							</FleetFormItem>
							<Button
								disabled={queryLoading}
								type="icon"
								onClick={() => {
									handleOpen("ballast");
								}}
							>
								<span className="font_family fleet-port"></span>
							</Button>
						</div>
						<div className="compareItem-formItem fourty-fourty-unequal">
							<FleetVerifyInput
								value={otherItem?.ballastMarginDistance}
								type="number"
								min={0}
								max={100}
								label="Dis Margin(%)"
								disabled={otherItem?.ballastMarginFlag === 1}
								onChange={(val: number) => {
									handleOtherItemChange?.("ballastMarginDistance", val);
								}}
							/>
							<FleetVerifyInput
								value={otherItem?.ballastMarginDay}
								type="number"
								label="Time Margin(Day)"
								disabled={otherItem?.ballastMarginFlag === 0}
								onChange={(val: number) => {
									handleOtherItemChange?.("ballastMarginDay", val);
								}}
							/>
							<FleetTabbar
								value={otherItem?.ballastMarginFlag === 0 ? "Dis" : "Day"}
								list={["Dis", "Day"]}
								onChange={(val: "Dis" | "Day") => {
									handleOtherItemChange?.("ballastMarginFlag", val === "Dis" ? 0 : 1);
								}}
							/>
						</div>
						<div className="compareItem-formItem nighty">
							<FleetFormItem needVerity label="Laden Port Rotation">
								<CommonPortSelect
									showCount={2}
									disabled={queryLoading}
									activeItems={portCategory?.ladenPortList}
									primaryKey="portCode"
									showKey="portName"
									onFinish={(newItem, type) => {
										switch (true) {
											case type === "add":
												handlePortItemAdd?.("laden", {
													portCode: newItem?.portCode,
													portName: newItem?.portName,
													purpose: "DC"
												});
												break;
											case type === "del":
												const currentIndex = portList?.findIndex(
													(item) => item?.portCode === newItem?.portCode
												);
												handlePortItemDelete?.(currentIndex);
												break;
											default:
												break;
										}
									}}
								/>
							</FleetFormItem>
							<Button
								type="icon"
								disabled={queryLoading}
								onClick={() => {
									handleOpen("laden");
								}}
							>
								<span className="font_family fleet-port"></span>
							</Button>
						</div>
						<div className="compareItem-formItem fourty-fourty-unequal">
							<FleetVerifyInput
								value={otherItem?.ladenMarginDistance}
								type="number"
								min={0}
								max={100}
								label="Dis Margin(%)"
								disabled={otherItem?.ladenMarginFlag === 1}
								onChange={(val: number) => {
									handleOtherItemChange?.("ladenMarginDistance", val);
								}}
							/>
							<FleetVerifyInput
								value={otherItem?.ladenMarginDay}
								type="number"
								disabled={otherItem?.ladenMarginFlag === 0}
								label="Time Margin(Day)"
								onChange={(val: number) => {
									handleOtherItemChange?.("ladenMarginDay", val);
								}}
							/>
							<FleetTabbar
								value={otherItem?.ladenMarginFlag === 0 ? "Dis" : "Day"}
								list={["Dis", "Day"]}
								onChange={(val: "Dis" | "Day") => {
									handleOtherItemChange?.("ladenMarginFlag", val === "Dis" ? 0 : 1);
								}}
							/>
						</div>
						<div className="compareItem-formItem eighty-two">
							<FleetVerifyInput
								type="number"
								needVerity
								label={t("common.freightRate")}
								value={cargoItem?.freightRate}
								onChange={(val: number) => {
									handleCargoItemChange?.("freightRate", val);
								}}
							/>
							<FleetTabbar
								value={cargoItem?.freightType}
								onChange={(val: FreightType) => {
									handleCargoItemChange?.("freightType", val);
								}}
								list={["FR", "LP"]}
							/>
						</div>
						<div className="compareItem-formItem two-equal">
							<FleetVerifyInput
								type="number"
								label={t("common.commission")}
								value={cargoItem?.commission}
								onChange={(val: number) => {
									handleCargoItemChange?.("commission", val);
								}}
							/>
							<FleetVerifyInput
								type="number"
								label={t("common.brokerage")}
								value={cargoItem?.brokerage}
								onChange={(val: number) => {
									handleCargoItemChange?.("brokerage", val);
								}}
							/>
						</div>
						<div className="compareItem-formItem fourty-thirty-unequal">
							<FleetVerifyInput
								label="Total Distance (NM)"
								disabled
								value={calculate.ttlDistanceBallast}
							/>
							<FleetInput value={calculate.ttlDistanceLaden} disabled />
							<FleetVerifyInput label="Margin" value={calculate?.totalMarginValue} disabled />
						</div>
						<div className="compareItem-formItem two-equal">
							<FleetVerifyInput
								label="Inside ECA (NM)"
								disabled
								value={calculate.ecaDistanceBallast}
							/>
							<FleetInput disabled value={calculate.ecaDistanceLaden} />
						</div>
					</div>
					<span className="compareItem-formItem--title profit">Profit(Loss)</span>
					<ul className="compareItem-profit">
						{profitOptions?.map((profitItem, profitIndex) => {
							return (
								<li key={profitIndex}>
									<span className={classnames({ highest: profitItem?.highest })}>
										{profitItem.label}
									</span>
									<span className={classnames({ highest: profitItem?.highest })}>
										{formatThousandthNumber(calculate[profitItem?.value])}
									</span>
								</li>
							);
						})}
					</ul>
				</div>
			</div>
			<EstPortRotation
				vesselSource={{
					portFuelIdle: vesselItem?.portFuelIdle,
					portFuelMdo: vesselItem?.portFuelMdo,
					portFuelWork: vesselItem?.portFuelWork,
					portIdleMdo: vesselItem?.portIdleMdo,
					portWorkMdo: vesselItem?.portWorkMdo,
					deliveryPortCode: vesselItem?.deliveryPortCode,
					deliveryPortId: vesselItem?.deliveryPortId,
					deliveryPortName: vesselItem?.deliveryPortName,
					ballastSpeed: vesselItem?.ballastSpeed,
					ladenSpeed: vesselItem?.ladenSpeed
				}}
				cargoSource={{
					cargoQuantity: cargoItem?.cargoQuantity
				}}
				onConfirm={handlePortRotationConfirm}
				portType={currentType}
				items={portList}
				hide={hide}
				onClose={() => {
					setHide(true);
				}}
			/>
		</div>
	);
};

export default EstCompareItem;
