import React, { FC } from "react";
import style from "./index.module.less";
import { MovementSettingsProps } from "./type";
import { FleetFormItem, FleetInput, FleetSelect } from "@/components";
import { options } from "./source";
import { useTranslation } from "react-i18next";
import { CommonUserSelect } from "@/common";
import classnames from "classnames";
import { useAppSelector } from "@/hook";

const MovementSettings: FC<MovementSettingsProps> = ({ item, voyageType, onChange, onCommit }) => {
	const { t } = useTranslation();
	const { roles } = useAppSelector((selector) => selector.UserStore);
	return (
		<div
			className={classnames(style["movement-settings"], {
				[style["movement-settings-tctc"]]: voyageType === "TCTC"
			})}
		>
			<div className="movement-details--header">
				<span className="fleet-title">Settings</span>
			</div>
			<div className="movement-settings-container">
				<FleetFormItem
					containerClassname="settings-item"
					type="row"
					label={t("voyageManagement.common.voyageType")}
				>
					<FleetSelect
						disabled
						options={options}
						value={[item?.voyageType]}
						onChange={(val: MovementSettingsProps["item"]["voyageType"]) => {
							return;
							onChange?.("voyageType", val);
						}}
					/>
				</FleetFormItem>
				<FleetFormItem
					containerClassname="settings-item"
					type="row"
					label={t("voyageManagement.common.operatedName")}
				>
					<CommonUserSelect
						disabled={!roles?.includes("teamleader")}
						activeItems={item?.operateList}
						showKey="nickName"
						roleParams={{
							type: 1
						}}
						showCount={1}
						onFinish={(newItem, type) => {
							console.log("newitem", newItem);
							switch (true) {
								case type === "add":
									onCommit?.({
										operateList: [newItem],
										operatedName: newItem?.nickName ?? "",
										operatedBy: (newItem?.userId as unknown as number) ?? null
									});
									break;
								case type === "del":
									onCommit?.({
										operateList: [],
										operatedBy: null,
										operatedName: ""
									});
									break;
								default:
									break;
							}
						}}
					/>
				</FleetFormItem>
				<FleetFormItem
					containerClassname="settings-item"
					type="row"
					label={t("voyageManagement.common.charteredBy")}
				>
					<CommonUserSelect
						activeItems={item?.charateList}
						showKey="nickName"
						showCount={1}
						onFinish={(newItem, type) => {
							console.log("newitem", newItem);
							switch (true) {
								case type === "add":
									onCommit?.({
										charateList: [...item?.charateList, newItem],
										charteredBy: [...item?.charteredBy, newItem?.userId]
									});
									break;
								case type === "del":
									const charateIndex = item?.charateList?.findIndex(
										(charateItem) => charateItem?.userId === newItem?.userId
									);
									const charateList = item?.charateList;
									charateList?.splice(charateIndex, 1);
									onCommit?.({
										charateList: [...charateList],
										charteredBy: charateList?.map((charateItem) => charateItem?.userId)
									});
									break;
								default:
									break;
							}
						}}
					/>
				</FleetFormItem>
			</div>
		</div>
	);
};

export default MovementSettings;
