import { useCallback, useMemo, useRef, useState } from "react";
import { BasicInfoFormType, BasicIngoFormDisabledType } from "../type";

const useIndex = (basicInfoData: BasicInfoFormType) => {
  const [basicInfoFormDisabled, setBasicInfoFormDisabled] =
    useState<BasicIngoFormDisabledType>({
      dateTime: true,
      hoursSinceLastPort: true,
      workingHoursLastDay: true,
      vesselName: true,
      ttlWorkDay: true,
      ETCD: true,
      nameOfPort: true,
      nameOfBerth: true,
      arrivalDateTime: true,
      norTendered: true,
      vslAnchored: true,
      anchorAweigh: true,
      pob: true,
      firstLineAshore: true,
      allMadeFast: true,
      initialDraftSurveyCommenced: true,
      initialDraftSurveyCompleted: true,
      loadDishCommenced: true,
      loadDishCompleted: true,
      finalDraftSurveyCommenced: true,
      finalDraftSurveyCompleted: true,
      portRotationId: true,
      id: true,
    });
  const handleMouseEnter = (prop?: string) => {
    setBasicInfoFormDisabled({ ...basicInfoFormDisabled, [prop]: false });
  };
  const handleMouseLeave = (prop?: string) => {
    setBasicInfoFormDisabled({ ...basicInfoFormDisabled, [prop]: true });
  };
  const cellValue = (val, prop) => {
    if (prop == "ttlWorkDay" && val != "") {
      return val?.toFixed(2) || null;
    } else {
      return val || null;
    }
  };
  const calculateTtlDays = (newData: number, prop: string) => {
    const oldDays =
      (+basicInfoData.loadDishCompleted - +basicInfoData.loadDishCommenced) /
      1000 /
      3600 /
      24;
    let newDays = 0
    if (prop == "loadDishCommenced") {
      newDays = (+basicInfoData.loadDishCompleted - newData) / 1000 / 3600 / 24;
    } else if (prop == "loadDishCompleted") {
      newDays = (newData - +basicInfoData.loadDishCommenced) / 1000 / 3600 / 24;      
    }
    const a = +basicInfoData.ttlWorkDay - oldDays + newDays;
    return +basicInfoData.ttlWorkDay - oldDays + newDays;
  };
  return {
    handleMouseEnter,
    handleMouseLeave,
    basicInfoFormDisabled,
    cellValue,
    calculateTtlDays,
  };
};
export default useIndex;
