import { CommonInitItemEvent } from "@/types/event"
import { VoyageManageTcvcVoyageRemarkType } from "./type"

export const initialVoyageTcvcManageVoyageRemark: CommonInitItemEvent<VoyageManageTcvcVoyageRemarkType> = (item) => {
  return {
    head_fixture: {
      attachment: item?.head_fixture?.attachment ?? [],
      contact: item?.head_fixture?.contact ?? []
    },
    fixture_notes: {
      attachment: item?.fixture_notes?.attachment ?? [],
      contact: item?.fixture_notes?.contact ?? []
    },
    port: {
      attachment: item?.port?.attachment ?? [],
      contact: item?.port?.contact ?? []
    },
    other: {
      attachment: item?.other?.attachment ?? [],
      contact: item?.other?.contact ?? []
    }
  }
}