import { LabelKeys } from "@/types";
import { useCallback, useMemo, useState } from "react";
import { ExtendsKeys, VpFormType } from "../details";
import { uuid } from "@/tools";
import { PortRotationFormType } from "./portRotation";
import { CargoFormType } from "./cargo";

export type SpdAllocationOriginalKeys = ExtendsKeys<LabelKeys,
  "ballastSpeed" | "ladenSpeed" | "seaFuelBallast" | "seaFuelLaden" | "seaFuelMdo"
  | "bunkerPriceIfo" | "bunkerPriceMdo" | "id"
>;

export type SaOriginalFormType = Record<Exclude<SpdAllocationOriginalKeys, "id">, number | ""> & {
  id?: string;
};

export type SaOriginalFormChangeEvent = (id: string, key: Exclude<SpdAllocationOriginalKeys, "id">, val: string | number) => void;


const initSaOriginalForm: SaOriginalFormType = {
  id: "",
  bunkerPriceIfo: 0,
  bunkerPriceMdo: 0,
  ballastSpeed: 0,
  ladenSpeed: 0,
  seaFuelBallast: 0,
  seaFuelLaden: 0,
  seaFuelMdo: 0,
}


export const useSpdAllocation = (originForm: Record<Exclude<SpdAllocationOriginalKeys, "id">, number | "">) => {
  const [saOriginalFormGroup, setGroup] = useState<SaOriginalFormType[]>([]);
  const [activeKey, setActiveKey] = useState("");
  const add = () => {
    if (saOriginalFormGroup?.length >= 3) return;
    setGroup(prev => {
      return [...prev, { ...originForm, id: uuid() }]
    })
  }

  const change: SaOriginalFormChangeEvent = (id: string, key: Exclude<SpdAllocationOriginalKeys, "id">, val: number) => {
    setGroup(prev => {
      const spdIndex = prev.findIndex(item => item.id == id);
      const item = prev[spdIndex];
      item[key] = val;
      prev.splice(spdIndex, 1, item);
      return [...prev]
    })
  }

  const remove = (id: string) => {
    setGroup(prev => {
      const spdIndex = prev.findIndex(item => item.id == id);
      prev.splice(spdIndex, 1);
      return [...prev]
    })
  }

  const changeActiveKey = (id?: string) => {
    setActiveKey(prev => {
      return prev == id ? "" : id
    })
  }

  const clear = useCallback(() => {
    setGroup([]);
    setActiveKey("");
  }, [])

  return {
    activeKey,
    saOriginalFormGroup,
    add,
    change,
    clear,
    remove,
    changeActiveKey
  }
}

export const useEstimation = (
  cargoParticular: CargoFormType,
  vesselParticular: Pick<VpFormType, Exclude<
    keyof VpFormType,
    "imo" | "mmsi" | "id" | "vesselName" | "ownerName" | "layCanFrom" | "deliveryPortCode" | "deliveryPortCode"
    | "deliveryPortId" | "deliveryPortName" | "layCanTo" | "dwt" | "grain" | "bale" | "vesselNameEn" | "vesselNameCn"
  >
  >,
  ladenPortList: PortRotationFormType[],
  ballastPortList: PortRotationFormType[],
) => {
  const portRotationDays = useMemo(() => {
    let ballastPortWorkDays = 0;
    let ladenPortWorkDays = 0;
    let ballastPortIdleDays = 0;
    let ladenPortIdleDays = 0;
    let ballastPortTotalDays = 0;
    let ladenPortTotalDays = 0;
    let ttlDistanceBallast = 0;
    let ttlDistanceLaden = 0;
    let ecaDistanceBallast = 0;
    let ecaDistanceLaden = 0;
    let cargoQuantity = 0;
    let adjustFactorttlDistanceBallast = 0;
    let adjustFactorttlDistanceLaden = 0;
    let adjustFactorecaDistanceBallast = 0;
    let adjustFactorecaDistanceLaden = 0;

    ladenPortList?.forEach(item => {
      // ballastPortWorkDays += + item?.portWorkDay;
      // ladenPortWorkDays += +item?.ladenPortWorkDays;
      ladenPortWorkDays += +item?.workDays;
      // ballastPortIdleDays += + item?.portIdleDay;
      ladenPortIdleDays += +item?.idleDays;
      ladenPortTotalDays += +item?.workDays + +item?.idleDays;
      ttlDistanceLaden += + item?.distance;
      ecaDistanceLaden += +item?.ecaDistance;
    });

    ballastPortList?.forEach(item => {
      ballastPortWorkDays += + item?.workDays;
      // ladenPortWorkDays += +item?.ladenPortWorkDays;
      ballastPortIdleDays += + item?.idleDays;
      // ladenPortIdleDays += +item?.ladenPortIdleDays
      ballastPortTotalDays += +item?.workDays + +item?.idleDays;
      ttlDistanceBallast += + item?.distance;
      ecaDistanceBallast += +item?.ecaDistance;
      cargoQuantity += +item?.cargoQuantity;
    })

    adjustFactorttlDistanceBallast = +ttlDistanceBallast * (1 + +cargoParticular.marginValue / 100);
    adjustFactorttlDistanceLaden = +ttlDistanceLaden * (1 + +cargoParticular.marginValue / 100);
    adjustFactorecaDistanceBallast = +ecaDistanceBallast * (1 + +cargoParticular.marginValue / 100);
    adjustFactorecaDistanceLaden = +ecaDistanceLaden * (1 + +cargoParticular.marginValue / 100);

    return {
      ballastPortWorkDays,
      ladenPortWorkDays,
      ballastPortIdleDays,
      ladenPortIdleDays,
      ladenPortTotalDays,
      ballastPortTotalDays,
      ttlDistanceBallast,
      ttlDistanceLaden,
      ecaDistanceBallast,
      ecaDistanceLaden,
      cargoQuantity,
      adjustFactorttlDistanceBallast,
      adjustFactorttlDistanceLaden,
      adjustFactorecaDistanceBallast,
      adjustFactorecaDistanceLaden
    }
  }, [ladenPortList, ballastPortList,cargoParticular.marginValue]);

  /** 运费率： 包干 / 每公吨 * 货数 */
  const freightIncome = useMemo(() => {
    if (cargoParticular.freightType === "LP") {
      return +cargoParticular.freightRate
    }
    return +cargoParticular.freightRate * + cargoParticular.cargoQuantity
  }, [cargoParticular.freightType, cargoParticular.freightRate, cargoParticular.cargoQuantity]);

  /** 折扣费用 = 运费 * 折扣率 */
  const freightAddCom = useMemo(() => {
    return +cargoParticular.commission * +freightIncome / 100
  }, [cargoParticular.commission, freightIncome]);

  /** 中介费用 = 运费 * 中介费率 */
  const revenuesBrokerage = useMemo(() => {
    return + cargoParticular.brokerage * +freightIncome / 100
  }, [cargoParticular.brokerage, freightIncome]);

  /**收入 */ // 250683.84
  const revenues = useMemo(() => {
    return +freightIncome - +freightAddCom - +revenuesBrokerage
  }, [freightIncome, freightAddCom, revenuesBrokerage]);

  /** 空载航行天数 */
  const ballastSeaDays = useMemo(() => {
    return portRotationDays.adjustFactorttlDistanceBallast / +(vesselParticular.ballastSpeed || 1) / 24;
  }, [portRotationDays, vesselParticular.ballastSpeed]);

  /** 满载航行天数 */
  const ladenSeaDays = useMemo(() => {
    return portRotationDays.adjustFactorttlDistanceLaden / +(vesselParticular.ladenSpeed || 1) / 24;
  }, [portRotationDays, vesselParticular.ladenSpeed]);

  const ballastEcaDays = useMemo(() => {
    return +portRotationDays.adjustFactorecaDistanceBallast / (+vesselParticular.ballastSpeed || 1) / 24;
  }, [portRotationDays, vesselParticular.ballastSpeed]);

  const ladenEcaDays = useMemo(() => {
    return +portRotationDays.adjustFactorecaDistanceLaden / (+vesselParticular.ladenSpeed || 1) / 24;
  }, [portRotationDays, vesselParticular.ladenSpeed]);

  const ballastSeaDaysIfo = useMemo(() => {
    return (+ballastSeaDays - +ballastEcaDays) * +vesselParticular.seaFuelBallast
  }, [ballastSeaDays, ballastEcaDays, vesselParticular.seaFuelBallast]);

  const ladenSeaDaysIfo = useMemo(() => {
    return (+ladenSeaDays - +ladenEcaDays) * + vesselParticular.seaFuelLaden
  }, [ladenSeaDays, ladenEcaDays, vesselParticular.seaFuelLaden]);

  const ballastPortMtIfo = useMemo(() => {
    return portRotationDays.ballastPortWorkDays * +vesselParticular.portFuelWork + portRotationDays.ballastPortIdleDays * +vesselParticular.portFuelIdle;
  }, [portRotationDays, vesselParticular.portFuelWork, vesselParticular.portFuelIdle]);

  const ladenPortMtIfo = useMemo(() => {
    return portRotationDays.ladenPortWorkDays * +vesselParticular.portFuelWork + portRotationDays.ladenPortIdleDays * +vesselParticular.portFuelIdle;
  }, [portRotationDays, vesselParticular.portFuelWork, vesselParticular.portFuelIdle]);

  const ballastIfo = useMemo(() => {
    return +ballastSeaDaysIfo + +ballastPortMtIfo;
  }, [ballastSeaDaysIfo, ballastPortMtIfo]);

  const ladenIfo = useMemo(() => {
    return +ladenPortMtIfo + +ladenSeaDaysIfo;
  }, [ladenPortMtIfo, ladenSeaDaysIfo]);

  const ifoCost = useMemo(() => {
    return (ballastIfo + ladenIfo) * +vesselParticular.bunkerPriceIfo;
  }, [ballastIfo, ladenIfo, vesselParticular.bunkerPriceIfo]);

  const loadPortExpenses = useMemo(() => {
    return ladenPortList?.reduce((prev, curr) => {
      return prev + +(curr.portExpense)
    }, 0)
  }, [ladenPortList]);

  const dischargePortExpenses = useMemo(() => {
    return ballastPortList?.reduce((prev, curr) => {
      return prev + +(curr.portExpense)
    }, 0)
  }, [ballastPortList]);

  const portExpenses = useMemo(() => {
    return loadPortExpenses + dischargePortExpenses
  }, [loadPortExpenses, dischargePortExpenses]);

  const totalSeaDays = useMemo(() => {
    return +ballastSeaDays + +ladenSeaDays
  }, [ballastSeaDays, ladenSeaDays]);

  const mdoCost = useMemo(() => {
    return ballastEcaDays * +vesselParticular.seaFuelBallast + ladenEcaDays * + vesselParticular.seaFuelLaden + (portRotationDays.ballastPortTotalDays + portRotationDays.ladenPortTotalDays) * +vesselParticular.portFuelMdo + totalSeaDays * +vesselParticular.seaFuelMdo * +vesselParticular.bunkerPriceMdo
  }, [ballastEcaDays, vesselParticular.seaFuelBallast, ladenEcaDays, vesselParticular.seaFuelLaden, portRotationDays.ballastPortTotalDays, portRotationDays.ladenPortTotalDays, vesselParticular.portFuelMdo, totalSeaDays, vesselParticular.seaFuelMdo, vesselParticular.bunkerPriceMdo])

  const operationCost = useMemo(() => {
    return ifoCost + mdoCost + portExpenses + +vesselParticular.otherExpenses
  }, [ifoCost, mdoCost, portExpenses, vesselParticular.otherExpenses])

  console.log("operationCost", operationCost)

  const bunkerCost = useMemo(() => {
    return ifoCost + mdoCost
  }, [ifoCost, mdoCost])

  const hireCost = useMemo(() => {
    return +vesselParticular.dailyHire * (ballastSeaDays + ladenSeaDays + portRotationDays.ballastPortTotalDays + portRotationDays.ladenPortTotalDays)
  }, [vesselParticular.dailyHire, ballastSeaDays, ladenSeaDays, portRotationDays.ballastPortTotalDays, portRotationDays.ladenPortTotalDays]);

  const addCom = useMemo(() => {
    return hireCost * +vesselParticular.commission / 100;
  }, [hireCost, vesselParticular.commission]);

  const totalHireCostBrokerage = useMemo(() => {
    return hireCost * +vesselParticular.brokerage / 100;
  }, [hireCost, vesselParticular.brokerage])

  const totalHireCost = useMemo(() => {
    return hireCost - (addCom + totalHireCostBrokerage)
  }, [hireCost, addCom, totalHireCostBrokerage])

  const totalCost = useMemo(() => {
    return operationCost + totalHireCost
  }, [operationCost, totalHireCost])

  /** profit: 利润 = 收入 - 成本 */
  const profit = useMemo(() => {
    return revenues - totalCost
  }, [revenues, totalCost]);

  const tce = useMemo(() => {
    return (revenues - operationCost - freightAddCom - revenuesBrokerage) / ((totalSeaDays + portRotationDays.ballastPortTotalDays + portRotationDays.ladenPortTotalDays) || 1)
  }, [revenues,operationCost,freightAddCom,revenuesBrokerage, totalSeaDays, portRotationDays]);

  return {
    totalProfit: +profit.toFixed(3),
    tce: +tce.toFixed(3),
    totalRevenue: +revenues.toFixed(3),
    totalHireCost: +totalHireCost.toFixed(3),
    freightIncome: +freightIncome.toFixed(3),
    freightAddCommission: +freightAddCom.toFixed(3),
    freightBrokerage: +revenuesBrokerage.toFixed(3),
    operationCost: +operationCost.toFixed(3),
    ifoCost: +ifoCost.toFixed(3),
    mdoCost: +mdoCost.toFixed(3),
    portExpense: +portExpenses.toFixed(3),
    otherExpense: +(+vesselParticular.otherExpenses).toFixed(3),
    totalCost: +totalCost.toFixed(3),
    commission: +addCom.toFixed(3),
    bunkerCost: +bunkerCost.toFixed(3),
    brokerage: +totalHireCostBrokerage.toFixed(3),
    hireCost: +hireCost.toFixed(3),
    ttlDistanceBallast: portRotationDays.ttlDistanceBallast,
    ecaDistanceBallast: portRotationDays.ecaDistanceBallast,
    ttlDistanceLaden: portRotationDays.ttlDistanceLaden,
    ecaDistanceLaden: portRotationDays.ecaDistanceLaden
  }
}
