import React, { forwardRef, useEffect, useImperativeHandle } from "react";
import style from "./index.module.less";
import { ShiftingReportProps } from "./type";
import { newShiftingReportRow, shiftingReportConfig } from "./source";
import { CommonPortSelect, FleetToast } from "@/common";
import Button from "@/_components/Button";
import { Popover } from "@/_components";
import { FleetLoading, FleetTextarea } from "@/components";
import { OtherReportsCommonRefType } from "../../type";
import useCommonReport from "../../hooks/useCommonReport";
import {
  deleteShiftingReportAction,
  getShiftingReportAction,
  saveShiftingReportAction,
} from "@/action/voyageManage/otherReports";
const ShiftingReport = forwardRef<
  OtherReportsCommonRefType,
  ShiftingReportProps
>(({ type, voyageId }, ref) => {
  const {
    loading,
    dataSource,
    commit,
    change,
    add,
    handleDelete,
    getReportList,
    handleSave,
  } = useCommonReport(voyageId, newShiftingReportRow, {
    getReportList: getShiftingReportAction,
    saveReport: saveShiftingReportAction,
    deleteReport: deleteShiftingReportAction,
  });
  useEffect(() => {
    if (type == "shiftingReport") getReportList();
  }, [type]);
  useImperativeHandle(ref, () => ({
    onSave: handleSave,
    data:dataSource
  }));
  return (
    <div className={style["otherReport-shifting"]}>
      <FleetLoading loading={loading} />
      <div className="shifting-header">
        <span>Shifting Report</span>
        <Button type="secondary" onClick={add}>
          Add New
        </Button>
      </div>
      <div className="shifting-main">
        {dataSource.map((portItem, portIndex) => {
          return (
            <div className="shifting-item" key={portIndex}>
              <div className="shifting-port">
                <span>Port Name</span>
                <CommonPortSelect
                  activeItems={{
                    portCode: portItem?.portCode,
                    portName: portItem?.portName,
                  }}
                  showKey="portName"
                  primaryKey="portCode"
                  onFinish={(newPortItemm, operateType) => {
                    commit(portIndex, {
                      portCode: newPortItemm?.portCode,
                      portName: newPortItemm?.portName,
                    });
                  }}
                />
              </div>
              <table cellSpacing={0}>
                <tbody>
                  {shiftingReportConfig.map((item, index) => {
                    return (
                      <tr className="shiftingTable-tr" key={index}>
                        {item.row.map((ele, idx) => {
                          return (
                            <td
                              colSpan={ele.colSpan}
                              rowSpan={ele.rowSpan}
                              key={idx}
                              className={
                                ele.label
                                  ? "shiftingTable-header"
                                  : "shiftingTable-input"
                              }
                            >
                              {ele.label ? (
                                <div>{ele.label}</div>
                              ) : (
                                <div className="shiftingTable-cell">
                                  {ele?.render?.(
                                    portItem[ele.prop],
                                    portIndex,
                                    portItem,
                                    { onCommit: commit }
                                  )}
                                </div>
                              )}
                            </td>
                          );
                        })}
                      </tr>
                    );
                  })}
                </tbody>
              </table>
              <div className="shifting-footer">
                <div className="shifting-remark">
                  <span>Remark</span>
                  <FleetTextarea
                    className="shifting-remarkContain"
                    value={portItem?.remark}
                    onChange={(val: string) => {
                      commit(portIndex, { remark: val });
                    }}
                    placeholder="Please input"
                  />
                </div>
                <Popover
                  render={(setIsOpen) => {
                    return (
                      <FleetToast
                        label="Are you sure delete this item?"
                        onCancel={() => {
                          setIsOpen(false);
                        }}
                        onConfirm={() => {
                          handleDelete(portIndex, portItem.id);
                          setIsOpen(false);
                        }}
                      />
                    );
                  }}
                >
                  <Button
                    size="small"
                    type="secondary"
                    className="shifting-delete"
                  >
                    <span
                      className="font_family fleet-delete"
                      style={{ color: "red" }}
                    ></span>
                  </Button>
                </Popover>
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
});

export default ShiftingReport;
