import { VesselParticularItemType } from "./type";

export const initalVesselParticular = (
	item: VesselParticularItemType | null
): VesselParticularItemType => {
	return {
		templateId: "",
		vesselName: "",
		vesselNameCn: "",
		vesselNameEn: "",
		ownerName: "",
		dwt: "",
		grain: "",
		bale: "",
		mmsi: null,
		tpc: null,
		imo: null,
		deliveryPortCode: "",
		deliveryPortName: "",
		ballastSpeed: "",
		ladenSpeed: "",
		seaFuelBallast: "",
		seaFuelLaden: "",
		seaFuelMdo: "",
		portFuelWork: "",
		portFuelIdle: "",
		portFuelMdo: "",
		bunkerPriceIfo: "",
		bunkerPriceMdo: "",
		dailyHire: "",
		commission: "",
		brokerage: "",
		otherExpenses: "",
		portIdleMdo: "",
		portWorkMdo: "",
		...item
	};
};

export const checkKeys: (keyof VesselParticularItemType)[] = [
	"dwt",
	"deliveryPortName",
	"deliveryPortCode",
	"ballastSpeed",
	"ladenSpeed",
	"dailyHire",
	"seaFuelBallast",
	"seaFuelLaden",
	"seaFuelMdo",
	"portFuelWork",
	"portFuelIdle",
	"portFuelMdo",
	"bunkerPriceIfo",
	"bunkerPriceMdo"
];
