import React, { FC } from "react";
import style from "./index.module.less";
import { PortDaNewlyProps } from "./type";
import { FleetPrompt } from "@/common";
import { FleetButton, FleetDatePicker, FleetFormItem, FleetInput, FleetLoading, FleetVerifyInput } from "@/components";
import useIndex from "./hooks/useIndex";
import { FleetTabbar } from "@/components/Tabs";
import dayjs from "dayjs";
import { CommonContactSelect, CommonVesselSelect, CommonPortSelect } from "@/common";
import { Button } from "@/_components";

const PortDaNewly: FC<PortDaNewlyProps> = ({
  id,
  hide,
  type,
  onClose,
  onConfirm
}) => {
  const {
    loading,
    dataSource,
    handleChange,
    handleCommit,
    updatePortDa
  } = useIndex(hide, onConfirm, type, id);

  return <FleetPrompt hide={hide}>
    <div className={style["portDa-newly"]}>
      <FleetLoading loading={loading} />
      <div className="newly-header">
        <span className="header-title">Add PDA</span>
        <span className="font_family fleet-close" onClick={onClose}></span>
      </div>
      <div className="newly-container">
        <FleetFormItem
          label="Port Name"
          needVerity
        >
          <CommonPortSelect
            activeItems={{
              id: dataSource?.portId,
              portCode: dataSource?.portCode,
              portName: dataSource?.portName
            }}
            showKey="portName"
            onFinish={(newItem, type) => {
              handleCommit?.({
                portCode: newItem?.portCode ?? "",
                portName: newItem?.portName ?? "",
                portId: newItem?.id ?? null,
                portCountry: newItem?.country ?? ""
              })
            }}
          />
        </FleetFormItem>
        <FleetVerifyInput
          value={dataSource?.portCode}
          onChange={(val: string) => {
            handleChange("portCode", val)
          }}
          label="Port Code"
        />
        <FleetVerifyInput
          value={dataSource?.portCountry}
          onChange={(val: string) => {
            handleChange("portCountry", val)
          }}
          label="Port Country"
        />
        <FleetFormItem
          label="Agent"
        >

          <CommonContactSelect
            showKey="name"
            primaryKey="id"
            activeItems={{
              id: dataSource?.agentId,
              name: dataSource?.agentName
            }}
            contactParmas={{
              businessType: "Port Agent"
            }}
            onFinish={(newItem, type) => {
              switch (type) {
                case "add":
                  handleCommit({
                    agentId: newItem?.id,
                    agentName: newItem?.name,
                  })
                  break
                case "del":
                  handleCommit({
                    agentId: null,
                    agentName: ""
                  })
                  break
                default:
                  break
              }
            }}
          />
        </FleetFormItem>
        <FleetVerifyInput
          value={dataSource?.advancePayment}
          onChange={(val: number) => {
            handleChange("advancePayment", val)
          }}
          label="PDA（USD）"
          type="number"
        />
        <FleetVerifyInput
          value={dataSource?.totalExpense}
          onChange={(val: number) => {
            handleChange("totalExpense", val)
          }}
          label="Total Expense（USD）  "
          type="number"
          needVerity
        />
        <FleetFormItem
          label="Ship Name"
        >
          <CommonVesselSelect
            activeItems={{
              vesselName: dataSource?.vesselName,
              mmsi: dataSource?.vesselId
            }}
            primaryKey="mmsi"
            showKey="vesselName"
            onFinish={(newItem, type) => {
              handleCommit({
                vesselId: newItem?.mmsi ?? null,
                vesselName: newItem?.vesselName ?? ""
              })
            }}
          />
        </FleetFormItem>
        <FleetVerifyInput
          value={dataSource?.cargoName}
          onChange={(val: string) => {
            handleChange("cargoName", val)
          }}
          label="Cargo Name"
        />
        <FleetFormItem
          label="Cargo Quantity"
        >
          <div className="newly-cargoQuantity">
            <FleetInput
              value={dataSource?.cargoQuantity}
              type="number"
              onChange={(val: number) => {
                handleChange("cargoQuantity", val)
              }}
            />
            <FleetTabbar
              list={["MT", "CBM"]}
              value={dataSource?.cargoUnit}
              disabled
              onChange={(val: "MT" | "CBM") => {
                handleChange("cargoUnit", val)
              }}
            />
          </div>
        </FleetFormItem>
        <FleetFormItem
          label="Port Arrival"
        >
          <FleetDatePicker
            allowClear
            value={dataSource?.portArrival ? dayjs(dataSource?.portArrival) : null}
            onChange={(val, timeStramp) => {
              handleChange("portArrival", timeStramp?.format("YYYY-MM-DD"))
            }}
          />
        </FleetFormItem>
      </div>
      <div className="newly-footer">
        <Button size="small" type="secondary" onClick={onClose}>Cancel</Button>
        <Button size="small" onClick={updatePortDa} disabled={loading}>Confirm</Button>
      </div>
    </div>
  </FleetPrompt>
}

export default PortDaNewly