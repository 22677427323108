import MonitoringVmVesselIcon from "static/icons/monitoring_vessel_v1.png";
import MonitoringVmVesselCheckedIcon from "static/icons/monitoring_vessel_checked_v1.png";
import MonitoringAisVesselIcon from "static/icons/monitoring_ais_vessel_v1.png";
import MonitoringAisVesselCheckedIcon from "static/icons/monitoring_ais_vessel.png";
import MonitoringRouteplanIcon from "static/icons/monitoring_route_plan_vessel_v1.png";
import MonitoringPortVesselIcon from "static/icons/monitoring_port_vessel.png";
import MonitoringAreaVesselIcon from "static/icons/monitoring_area_vessel.png";
import MonitoringPortVesselCheckedIcon from "static/icons/monitoring_port_vessel_checked.png";
import MonitoringAreaVesselCheckedIcon from "static/icons/monitoring_area_vessel_checked.png";

import RouteLegVesselIcon from "static/images/routeCommon/routeCommon_leg_vessel.svg";
import RouteLegVesselSelectedIcon from "static/images/routeCommon/routeCommon_leg_vessel_selected.svg";
// import RouteLegVesselHoveredIcon from "static/images/routeCommon/routeCommon_leg_vessel_hovered.svg";
import RouteAisVesselIcon from "static/images/routeCommon/routeCommon_ais_vessel.svg";
import RouteAisVesselSelectedIcon from "static/images/routeCommon/routeCommon_ais_vessel_selected.svg";
// import RouteAisVesselHoveredIcon from "static/images/routeCommon/routeCommon_ais_vessel_hovered.svg";
import RouteAreaVesselIcon from "static/images/routeCommon/routeCommon_area_vessel.svg";
import RouteAreaVesselSelectedIcon from "static/images/routeCommon/routeCommon_area_vessel_selected.svg";
// import RouteAreaVesselHoveredIcon from "static/images/routeCommon/routeCommon_area_vessel_hovered.svg";
import RoutePortVesselIcon from "static/images/routeCommon/routeCommon_port_vessel.svg";
import RoutePortVesselSelectedIcon from "static/images/routeCommon/routeCommon_port_vessel_selected.svg";
// import RoutePortVesselHoveredIcon from "static/images/routeCommon/routeCommon_port_vessel_hovered.svg";
import RouteVesselHoveredIcom from "static/images/routeCommon/routeCommon_vessel_hovered.svg";

export const lineStringGeoJson = {
	type: "geojson",
	//@ts-ignore
	data: {
		type: "FeatureCollection",
		features: [
			{
				type: "Feature",
				geometry: {
					type: "LineString",
					coordinates: []
				},
				properties: {}
			}
		]
	}
};

export const commonGeoJson = {
	type: "geojson",
	//@ts-ignore
	data: {
		type: "FeatureCollection",
		features: []
	}
};

export const vesselIconMap = {
	vmVeeselIcon: MonitoringVmVesselIcon,
	vmVesselCheckedIcon: MonitoringVmVesselCheckedIcon,
	aisVeeselIcon: MonitoringAisVesselIcon,
	aisVeeselCheckedIcon: MonitoringAisVesselCheckedIcon,
	routePlanVesselIcon: MonitoringRouteplanIcon,
	areaVesselIcon: MonitoringAreaVesselIcon,
	portVesselIcon: MonitoringPortVesselIcon,
	portVesselCheckedIcon: MonitoringPortVesselCheckedIcon,
	areaVesselCheckedIcon: MonitoringAreaVesselCheckedIcon
};

export const routeCommonVesselIconMap: Record<
	`${"ais" | "vm" | "area" | "port"}Vessel${"" | "Selected"}Icon` | "vesselHoveredIcon",
	string
> = {
	vmVesselIcon: RouteLegVesselIcon,
	vmVesselSelectedIcon: RouteLegVesselSelectedIcon,
	// vmVesselHoveredIcon: RouteLegVesselHoveredIcon,
	aisVesselIcon: RouteAisVesselIcon,
	aisVesselSelectedIcon: RouteAisVesselSelectedIcon,
	// aisVesselHoveredIcon: RouteAisVesselHoveredIcon,
	areaVesselIcon: RouteAreaVesselIcon,
	areaVesselSelectedIcon: RouteAreaVesselSelectedIcon,
	// areaVesselHoveredIcon: RouteAreaVesselHoveredIcon,
	portVesselIcon: RoutePortVesselIcon,
	portVesselSelectedIcon: RoutePortVesselSelectedIcon,
	// portVesselHoveredIcon: RoutePortVesselHoveredIcon,
	vesselHoveredIcon: RouteVesselHoveredIcom
};
