import React from "react";
import { ExtendsKeys, ExcludeKeys } from "@/types";
import { ColumnsType } from "antd/es/table/interface";
import { dateFormat } from "@/tools/date";

type ContactKeys = "businessType" |
  "companyName" |
  "companyTypeId" |
  "createTime" |
  "createUser" |
  "email" |
  "faxNumber" |
  "id" |
  "landline1" |
  "landline2" |
  "mobilePhone1" |
  "mobilePhone2" |
  "name" |
  "updateTime" |
  "createTime" |
  "updateUser" |
  "remark" |
  "voyId";

type ContactNumKeys = ExtendsKeys<ContactKeys,
  "createUser" |
  "updateUser"
>;

type ContactStrKeys = ExtendsKeys<ContactKeys,
  "businessType" |
  "companyName" |
  "createTime" |
  "createTime" |
  "email" |
  "faxNumber" |
  "landline1" |
  "landline2" |
  "mobilePhone1" |
  "mobilePhone2" |
  "name" |
  "updateTime" |
  "remark"
>;

type ContactIdKeys = ExtendsKeys<ContactKeys,
  "companyTypeId" |
  "voyId" |
  "id"
>;

type DatabaseContactKeys = ContactNumKeys | ContactStrKeys | ContactIdKeys;

export type DatabaseContactType =
  Record<ContactNumKeys, number> &
  Record<ContactStrKeys, string> &
  Partial<Record<ContactIdKeys, string | number>>;

export type DatabaseContactColumnKeys = DatabaseContactKeys | "option";

export type DataType = {
  key: React.Key;
} & Record<DatabaseContactColumnKeys, any>;

export const columns: ColumnsType<DataType> = [
  {
    dataIndex: "name",
    width: 150,
    title: "Contact Name",
    key: "name",
    fixed: "left",
    align: "center"
  },
  {
    dataIndex: "companyName",
    width: 200,
    title: "Company Name",
    key: "companyName",
    align: "center"
  },
  {
    dataIndex: "businessType",
    width: 200,
    title: "Business Type",
    key: "businessType",
    align: "center"
  }, {
    dataIndex: "email",
    width: 150,
    title: "Email",
    key: "email",
    align: "center"
  }, {
    dataIndex: "mobilePhone1",
    width: 200,
    title: "MOB",
    key: "mobilePhone1",
    align: "center"
  }, {
    dataIndex: "mobilePhone2",
    width: 200,
    title: "MOB2",
    key: "mobilePhone2",
    align: "center"
  }, {
    dataIndex: "landline1",
    width: 200,
    title: "TEL",
    key: "landline1",
    align: "center"
  }, {
    dataIndex: "landline2",
    width: 200,
    title: "TEL2",
    key: "landline2",
    align: "center"
  }, {
    dataIndex: "faxNumber",
    width: 200,
    title: "FAX",
    key: "faxNumber",
    align: "center"
  }, {
    dataIndex: "createUser",
    width: 150,
    title: "Create User",
    key: "createUser",
    align: "center"
  }, {
    dataIndex: "updateTime",
    width: 150,
    title: "Update Time",
    key: "updateTime",
    align: "center",
    render: (value, record, index) => {
      return <span>{dateFormat("YY-mm-dd HH:MM", value)}</span>
    }
  }, {
    dataIndex: "createTime",
    width: 150,
    title: "Create Time",
    key: "createTime",
    align: "center",
    render: (value, record, index) => {
      return <span>{dateFormat("YY-mm-dd HH:MM", value)}</span>
    }
  }
]

export type DatabaseContactNewFormType = Pick<DatabaseContactType,
  "name" |
  "businessType" |
  "companyName" |
  "mobilePhone1" |
  "mobilePhone2" |
  "landline1" |
  "landline2" |
  "faxNumber" |
  "email" |
  "remark"
>;

export const checkContactKeys: DatabaseContactKeys[] = [
  "companyName",
  "companyName",
  "email"
]