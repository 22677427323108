import React, { MutableRefObject } from "react";
import classnames from "classnames"
import style from "./index.module.less";
import { FleetDatePicker, FleetFormItem, FleetIcon } from "@/components";
import { Capitalize } from "@/tools";
import dayjs from "dayjs";
import useRouteLine, { PortSport, RouteLineProps } from "@/hook/estimation/details/useRouteLine";
import { IconKeys } from "@/components/Icon/icons";
import { FleetTimePicker } from "@/components/DatePicker";
import MultipleRoute from "../multipleRoute";
import { IdentifierMapChangeEvent, ShippingMapCommitEvent, ShippingVesselHisChangeEvent, shippingMapType } from "@/hook/estimation/details/useDetails";
import { EstCargoItemChangeEvent, EstCargoItemType, EstOtherItemChangeEvent, EstOtherItemType } from "@/pages/estimation/module";
import { getTotalDays } from "@/pages/estimation/components/portRotation/tools";

type EsRoutePort = {
  portName?: string;
  status?: "delivery" | "loading" | "discharging";
  portCost?: string;
  days?: number;
}

enum PortStepMap {
  One = 1,
  Two,
  Three,
  Four,
  Five
}

export type EsRoutelineProps = {
  port?: EsRoutePort[];
  cargoItem: Pick<EstCargoItemType, "layCanFrom" | "layCanTo">
  otherItem: Pick<EstOtherItemType, "startVoyTime">
  onCargoItemChange: EstCargoItemChangeEvent
  onOtherItemChange: EstOtherItemChangeEvent
  onIdentifierChange?: IdentifierMapChangeEvent,
  onVesselHisCommit?: ShippingVesselHisChangeEvent,
  shippingMap?: MutableRefObject<shippingMapType>;
  onShippingCommit?: ShippingMapCommitEvent
} & RouteLineProps;

const PortStatus: React.FC<{
  status?: EsRoutePort["status"]
}> = ({
  status
}) => {
    return <span className={classnames("port-status", status)}>{Capitalize(status)}</span>
  }

const PortStep: React.FC<{
  seq: "delivery" | number,
  isLast?: boolean,
  onClick?: () => void
}> = ({
  seq,
  isLast,
  onClick
}) => {
    return <div className="port-step">
      <FleetIcon
        type={seq === "delivery" ? "FleetPortDelievryIcon" : `FleetPort${PortStepMap[seq]}Icon` as IconKeys}
        outerLayerClassname="portStep-icon"
        onClick={onClick}
      />
      {!isLast && <span className="portStep-line"></span>}
    </div>
  }

const EsRouteline: React.FC<EsRoutelineProps> = ({
  cargoItem,
  otherItem,
  ballastPortList,
  speedList,
  ladenPortList,
  delievryPort,
  onCargoItemChange,
  onOtherItemChange,
  shippingMap,
  onIdentifierChange,
  onVesselHisCommit,
  onShippingCommit
}) => {
  const {
    checkbox,
    portSportGroup,
    dataSource,
    loading,
    show,
    setShow,
    handleSelectPort,
    handleCheckAll,
    handleCheckBox
  } = useRouteLine({
    ballastPortList,
    ladenPortList,
    delievryPort,
    speedList,
    startVoyTime: +otherItem?.startVoyTime,
    shippingMap,
    onIdentifierChange,
    onVesselHisCommit,
    onShippingCommit
  })

  return <div className={style["esDetails-routeLineWrap"]}>
    <MultipleRoute
      checkbox={checkbox}
      show={show}
      onClose={() => setShow(false)}
      loading={loading}
      items={dataSource}
      // onConfirm={handleConfirm}
      actions={{
        onSelect: handleCheckBox,
        onSelectAll: handleCheckAll
      }}
    />
    <div style={{
      visibility: !show ? "unset" : "hidden",
      zIndex: !show ? 2 : 1
    }} className={classnames(style["estimation-routeLine"])}>
      <div className="routeLine-scrollArea fleet-nonScrolBar">
        <div className="routeline-laycan">
          <FleetFormItem
            needVerity={false}
            label="Cargo Laycan From"
            containerClassname="laycan-item"
          >
            <FleetDatePicker
              value={cargoItem?.layCanFrom ? dayjs(cargoItem?.layCanFrom) : null}
              onChange={(val, timeStramp) => {
                onCargoItemChange?.("layCanFrom", timeStramp?.startOf("minutes")?.valueOf());
              }}
              format={(value) => value?.format("YYYY-MM-DD HH:mm")}
              showTime={{
                format: "HH:mm"
              }}
              showHour
              showMinute
              allowClear
              disabledDate={(current) => {
                return (cargoItem?.layCanTo && current > dayjs(cargoItem?.layCanTo).endOf("minutes"))
              }}
            />
          </FleetFormItem>
          <FleetFormItem
            needVerity={false}
            label="Cargo Laycan To"
            containerClassname="laycan-item"
          >
            <FleetDatePicker
              value={cargoItem?.layCanTo ? dayjs(cargoItem?.layCanTo) : null}
              onChange={(val, timeStramp) => {
                onCargoItemChange?.("layCanTo", timeStramp?.startOf("minutes")?.valueOf());
              }}
              format={(value) => value?.format("YYYY-MM-DD HH:mm")}
              showTime={{
                format: "HH:mm"
              }}
              showHour
              showMinute
              allowClear
              disabledDate={(current) => {
                return (cargoItem?.layCanFrom && current < dayjs(cargoItem?.layCanFrom).endOf("minutes"))
              }}
            />
          </FleetFormItem>
        </div>
        <div className="routeline">
          <div className="routeline-timeline">
            <FleetTimePicker
              allowClear
              className="timeline-select"
              value={otherItem?.startVoyTime ? dayjs(otherItem?.startVoyTime) : null}
              onChange={(val: string) => {
                onOtherItemChange?.("startVoyTime", new Date(val).getTime())
              }}
            />
            {
              portSportGroup.map((item: PortSport, idx: number) => {
                return <div key={idx} className="timeline-info">
                  <span>{item?.distance} nm</span>
                  <span>{item?.timeConsue} hrs</span>
                  <span>{item?.endTime}</span>
                </div>
              })
            }
          </div>
          <div className="routeline-step">
            <div className="step-container">
              <PortStep seq="delivery" />
              {
                portSportGroup?.map((item, idx, arr) => {
                  return <PortStep
                    key={idx}
                    seq={idx + 1}
                    isLast={idx === arr?.length - 1}
                    onClick={() => handleSelectPort(item, idx === 0 ? { portCode: delievryPort?.delievryPortCode } : arr[idx - 1])}
                  />
                })
              }
            </div>
            <div className="port-container">
              <div className="port-item">
                <div className="port-info port-info--delivery">
                  <span>{delievryPort.delievryPortName}</span>
                  <PortStatus status="delivery" />
                </div>
              </div>
              {
                ballastPortList.map((item, idx) => {
                  console.log('item', item)
                  const totalDays = getTotalDays({
                    workDays: item?.workDays,
                    idleDays: item?.idleDays,
                    portMarginDay: item?.portMarginDay,
                    portMarginUnit: item?.portMarginUnit
                  })
                  return <div key={idx} className="port-item">
                    <div className="port-info">
                      <span>{item.portName}</span>
                      <PortStatus status="loading" />
                    </div>
                    <div className="port-costdays">
                      <span>Port Cost</span>
                      <span>{item?.portExpense || ""} USD</span>
                    </div>
                    <div className="port-costdays">
                      <span>Days in Port</span>
                      <span>{totalDays?.toFixed(2) || ""} Days</span>
                    </div>
                  </div>
                })
              }
              {
                ladenPortList.map((item, idx) => {
                  const totalDays = getTotalDays({
                    workDays: item?.workDays,
                    idleDays: item?.idleDays,
                    portMarginDay: item?.portMarginDay,
                    portMarginUnit: item?.portMarginUnit
                  })
                  return <div key={idx} className="port-item">
                    <div className="port-info">
                      <span>{item.portName}</span>
                      <PortStatus status="discharging" />
                    </div>
                    <div className="port-costdays">
                      <span>Port Cost</span>
                      <span>{item?.portExpense} USD</span>
                    </div>
                    <div className="port-costdays">
                      <span>Days in Port</span>
                      <span>{totalDays?.toFixed(2)} days</span>
                    </div>
                  </div>
                })
              }
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
}

export default EsRouteline;