import { CommonInitItemEvent } from "@/types/event";
import { MovementTaskStageItemType } from "./type";

export const initialMovementTaskStageItem: CommonInitItemEvent<MovementTaskStageItemType> = (item) => {
  return {
    currentPortRotationId: item?.currentPortRotationId ?? null,
    currentStage: item?.currentStage ?? "Delivery",
    id: item?.id ?? null,
    type: item?.type ?? 0,
    voyageId: item?.voyageId ?? null,
    portName: item?.portName ?? ""
  }
}