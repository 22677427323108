import React, { FC } from "react";
import style from "./index.module.less";
import { InvStatementCounterPartyProps } from "./type";
import classnames from "classnames";
import { FleetFormItem, FleetVerifyInput } from "@/components";
import AccountSelect from "@/common/AccountSelect";
import { Button } from "@/_components";

const InvStatementCounterParty: FC<InvStatementCounterPartyProps> = ({
	item,
	onCommit,
	fieldItems,
	onFieldChange,
	onFieldDelete,
	onFieldOpen
}) => {
	return (
		<div className={classnames("invoicesDetail-form", style["invStatement-counterParty"])}>
			<div className="invoicesForm-header">
				<span>Counterparty</span>
				<span>Recipient information</span>
			</div>
			<div className="invoicesForm-container">
				<FleetFormItem label="Company/Beneficiary Name" needVerity>
					<AccountSelect
						label="beneficiaryName"
						accountItems={{
							beneficiaryName: item?.beneficiaryName ?? "",
							bankAccountNumber: item?.accountNo ?? "",
							swiftNumber: item?.swiftCode ?? "",
							bankAccountAddress: item?.beneficiaryAddress ?? "",
							bankName: item?.beneficiaryBank ?? ""
						}}
						onFinish={(accountItem, newAccountItem, type) => {
							onCommit?.({
								beneficiaryName: accountItem?.beneficiaryName ?? "",
								beneficiaryAddress: accountItem?.beneficiaryAddress ?? "",
								accountNo: accountItem?.bankAccountNumber ?? "",
								swiftCode: accountItem?.swiftNumber ?? "",
								beneficiaryBank: accountItem?.bankName ?? ""
							});
						}}
					/>
				</FleetFormItem>
				<FleetVerifyInput
					value={item?.beneficiaryBank}
					needVerity
					onChange={(val: string) => {
						onCommit?.({ beneficiaryBank: val });
					}}
					label="BENEFICIARY'S BANK"
				/>
				<FleetVerifyInput
					needVerity
					value={item?.beneficiaryAddress}
					onChange={(val: string) => {
						onCommit?.({
							beneficiaryAddress: val
						});
					}}
					label="Company Address"
				/>
				<FleetVerifyInput
					needVerity
					value={item?.swiftCode}
					onChange={(val: string) => {
						onCommit?.({
							swiftCode: val
						});
					}}
					label="SWIFT CODE"
				/>
				<FleetVerifyInput
					needVerity
					value={item?.bankCode}
					onChange={(val: string) => {
						onCommit?.({
							bankCode: val
						});
					}}
					label="BANK CODE"
				/>
				<FleetVerifyInput
					needVerity
					value={item?.accountNo}
					onChange={(val: string) => {
						onCommit?.({
							accountNo: val
						});
					}}
					label="Account No"
				/>
				{fieldItems?.map((item, idx) => {
					return (
						<div className="statement-fields" key={idx}>
							<FleetVerifyInput
								value={item?.fieldVal}
								label={item?.fieldName}
								onChange={(val: string) => {
									onFieldChange?.(idx, "fieldVal", val);
								}}
							/>
							<span className="font_family fleet-minus"></span>
						</div>
					);
				})}
				<Button
					size="small"
					type="secondary"
					prefixIcon="fleet-plus"
					// onClick={() => onFieldOpen?.("counterParty")}
					disabled
				>
					Add field
				</Button>
			</div>
		</div>
	);
};

export default InvStatementCounterParty;
