import React from "react";
import AntdButton from "antd/es/button/index"
import classnames from "classnames";
import style from "./index.module.less"
import FleetLoading from "../Loading";
import FleetIcon from "../Icon";
import { IconKeys } from "../Icon/icons";

export type ButtonProps = {
  children?: React.ReactNode;
  label?: string | React.ReactNode;
  className?: string;
  type?: "primary" | "secondary" | "text" | "anchor" | "add" | "icon";
  size?: "normal" | "large" | "small";
  onClick?: (e?: any) => void;
  icon?: IconKeys;
  disabled?: boolean;
  loading?: boolean;
  suffix?: IconKeys;
  tabIndex?: number,
}

const Button: React.FC<ButtonProps> = ({
  children,
  className,
  size,
  type,
  onClick,
  icon,
  loading,
  disabled,
  suffix,
  label,
  tabIndex,
}) => {

  const handleClick = (e) => {
    if (disabled) return;
    onClick?.(e)
  }

  return <AntdButton
    className={classnames(style["fleet-button"], className, {
      [style["fleet-button__primary"]]: type === "primary",
      [style["fleet-button__secondary"]]: type === "secondary",
      [style["fleet-button__text"]]: type === "text",
      [style["fleet-button__anchor"]]: type === "anchor",
      [style["fleet-button__add"]]: type === "add",
      [style["fleet-button__icon"]]: type === "icon",
      [style["fleet-button__secondary__samll"]]: size === "small" && type === "secondary",
      [style["fleet-button__secondary__large"]]: size === "large" && type === "secondary",
    }, {
      [style["fleet-button__small"]]: size === "small",
      [style["fleet-button__large"]]: size === "large",
    })}
    type="primary"
    onClick={handleClick}
    disabled={disabled || loading}
    icon={icon && <FleetIcon type={icon} outerLayerClassname="btn-prefix" />}
    tabIndex={tabIndex}
  >
    {loading && <FleetLoading loadingClassname="btn-loading" loading={true} />}
    {children}
    {suffix && <FleetIcon type={suffix} outerLayerClassname="btn-suffix" />}
  </AntdButton>
}

export default Button
