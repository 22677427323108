import { MovementVesselBasicsType } from "./type";

export const initialDataSource = (item?: Partial<MovementVesselBasicsType>): MovementVesselBasicsType => {
  return {
    ownerName: item?.ownerName ?? "",
    id: item?.id ?? null,
    vesselName: item?.vesselName ?? "",
    commission: item?.commission ?? "",
    dailyHire: item?.dailyHire ?? "",
    brokerage: item?.brokerage ?? "",
    paymentStartDay: item?.paymentStartDay ?? "",
    estRedeliveryDate: item?.estRedeliveryDate ?? null,
    brokerName: item?.brokerName ?? "",
    brokerId: item?.brokerId ?? null,
    deliveryTime: item?.deliveryTime ?? null
  }
}