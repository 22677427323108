import React, { FC } from "react";
import style from "./index.module.less";
import { AreaVesselListProps, RouteCommonAreaVesselItemType } from "./type";
import { columns } from "./source";
import { Button, Table } from "@/_components";

const AreaVesselList: FC<AreaVesselListProps> = ({
	items,
	selectRows,
	onSelect,
	onDownload,
	onPortCallOpen,
	onVesselSelect
}) => {
	return (
		<div className={style["areaVessel-list"]}>
			<div className="areaVessel-list-header">
				<div className="areaVessel-list-info">
					<span>Vessels List </span>
					<div className="list-headerItem">
						Total:&nbsp;
						<span>{items?.length}</span>
					</div>
					<div className="list-headerItem">
						Selected:&nbsp;
						<span>{selectRows?.length}</span>
					</div>
				</div>
				<Button
					prefixIcon="fleet-download"
					type="secondary"
					disabled={!selectRows?.length}
					onClick={onDownload}
				>
					Download
				</Button>
			</div>
			<Table<RouteCommonAreaVesselItemType>
				primaryKey="imo"
				// virtual
				dataSource={items}
				columns={[
					...columns,
					{
						dataIndex: "action",
						title: "Action",
						width: 150,
						fixed: "right",
						render: (value, record, colIndex) => {
							return (
								<ul className="areaVessel-list-operation">
									<li
										onClick={() => {
											onVesselSelect?.(record);
										}}
									>
										Detail
									</li>
									<li
										onClick={() => {
											onPortCallOpen?.(record);
										}}
									>
										Port Call
									</li>
								</ul>
							);
						}
					}
				]}
				className="areaVessel-list-container"
				pagination={false}
				rowSelection={{
					selectedRowKeys: selectRows,
					onChange: onSelect,
					columnWidth: 32
				}}
			/>
		</div>
	);
};

export default AreaVesselList;
