import React from "react";
import style from "./index.module.less";
import { FleetPrompt } from "@/common";
import { PromptChildrenProps } from "@/common/Prompt";
import { FleetButton, FleetIcon, FleetLoading } from "@/components";
import { FleetPortTabs } from "@/components/Tabs";
import usePerformance, { PerformanceProps } from "@/hook/estimation/details/usePerformance";
import Plotting from "./Plotting";
import { Button } from "@/_components";

export type EsDetialsPerformanceProps = {
	mmsi: string | number;
	id: string | number;
} & PromptChildrenProps;

const Performance: React.FC<EsDetialsPerformanceProps> = ({ hide, onClose, mmsi, id }) => {
	const {
		performance,
		pmSourceData,
		updatePerformance,
		loading,
		clear,
		change: handlePerformanceChange
	} = usePerformance(id, mmsi, hide);

	const handleClose = () => {
		clear();
		onClose?.();
	};

	const handleConfirm = () => {
		updatePerformance(onClose);
	};

	return (
		<FleetPrompt onClose={handleClose} hide={hide} mode="fixed">
			<div className={style["esDetials-performance"]}>
				<FleetLoading loading={loading} />
				<div className="performance-header">
					<span className="header-title">Performance Model</span>
					<FleetIcon
						type="FleetCloseIcon"
						outerLayerClassname="header-close"
						onClick={handleClose}
					/>
				</div>
				<div className="performance-tabs">
					<FleetPortTabs
						list={[
							{
								label: "Ballast",
								value: "B"
							},
							{
								label: "Laden",
								value: "L"
							}
						]}
						value={performance.activeKey}
						className="tabs"
						onChange={(value: string) => {
							handlePerformanceChange?.("activeKey", value);
						}}
					/>
				</div>
				<div className="performance-container">
					<Plotting
						type="eco"
						performance={{
							cpEcoIfo:
								performance?.activeKey === "B"
									? performance?.cpIfoBallastEco
									: performance?.cpIfoLadenEco,
							cpEcoSpeed:
								performance?.activeKey === "B"
									? performance?.cpSpeedBallastEco
									: performance?.cpSpeedLadenEco
						}}
						onPmChange={handlePerformanceChange}
						activeKey={performance.activeKey}
						source={{
							maxEcoFo: pmSourceData?.maxEcoFo,
							maxEcoSpeed: pmSourceData?.maxEcoSpeed,
							ecoFoList: pmSourceData?.ecoFoList,
							ecoSpeedList: pmSourceData?.ecoSpeedList
						}}
					/>
					<Plotting
						type="full"
						onPmChange={handlePerformanceChange}
						activeKey={performance.activeKey}
						performance={{
							cpFullIfo:
								performance?.activeKey === "B"
									? performance?.cpIfoBallastFull
									: performance?.cpIfoLadenFull,
							cpFullSpeed:
								performance?.activeKey === "B"
									? performance?.cpSpeedBallastFull
									: performance?.cpSpeedLadenFull
						}}
						source={{
							maxFullFo: pmSourceData?.maxFullFo,
							maxFullSpeed: pmSourceData?.maxFullSpeed,
							fullFoList: pmSourceData?.fullFoList,
							fullSpeedList: pmSourceData?.fullSpeedList
						}}
					/>
				</div>
				<div className="performance-footer">
					<Button size="small" type="secondary" onClick={handleClose}>
						Cancel
					</Button>
					<Button size="small" onClick={handleConfirm}>
						Confirm
					</Button>
				</div>
			</div>
		</FleetPrompt>
	);
};

export default Performance;
