import { FleetOptionType } from "@/types/advancedType";
import { MovementSettingsType } from "./type";

export const options: FleetOptionType<
  Uppercase<MovementSettingsType["voyageType"]>,
  MovementSettingsType["voyageType"]
>[] = [
    {
      label: "TCVC",
      value: "TCVC"
    },
    {
      label: "TCTC",
      value: "TCTC"
    },
    {
      label: "VCVC",
      value: "VCVC"
    }
  ];

export const initialSettings = <T extends MovementSettingsType>(
  item?: Partial<T>
): MovementSettingsType => {
  return {
    voyageType: item?.voyageType ?? "TCVC",
    // voyageType: "VCVC",
    charteredBy: item?.charteredBy ?? [],
    operatedName: item?.operatedName ?? "",
    charateList: item?.charateList ?? [],
    operatedBy: item?.operatedBy ?? null,
    operateList: item?.operateList ?? []
  };
};
