import { useEffect, useMemo, useRef, useState } from "react";
import {
	MovementDisputeChargeItemType,
	MovementDisputeChargeChangeEvent,
	MovementDisputeChargeDeleteEvent,
	MovementDisputeChargeProps,
	MovementDisputeChargeCommitEvent
} from "../type";
import { initialDisputeChargeItem } from "../source";
import { useAppDispatch } from "@/hook";
import { useNavigate } from "react-router-dom";
import useReminder from "@/hook/useReminder";
import { onRequest } from "@/action";

const useIndex = (
	hide: boolean,
	voyageId: MovementDisputeChargeProps["voyageId"],
	voyageType: MovementDisputeChargeProps["voyageType"],
	onConfirm: MovementDisputeChargeProps["onConfirm"],
	onClose?: () => void
) => {
	const dispatch = useAppDispatch();
	const navigate = useNavigate();
	const { reminder } = useReminder();
	const [loading, setLoading] = useState(false);
	const delIds = useRef<number[]>([]);
	const [dataSource, setDataSource] = useState<MovementDisputeChargeItemType[]>([]);

	const summary = useMemo(() => {
		return dataSource?.reduce(
			(prev, curr) => {
				return {
					itemName: "Total",
					finalAmount: +prev.finalAmount + +curr.finalAmount,
					initialAmount: +prev?.initialAmount + +curr?.initialAmount,
					difference: +prev?.difference + +curr?.difference
				};
			},
			{
				itemName: "",
				finalAmount: 0,
				initialAmount: 0,
				difference: 0
			}
		);
	}, [dataSource]);

	const change: MovementDisputeChargeChangeEvent = (currentIndex, key, value) => {
		setDataSource((prev) => {
			const currentItem = prev?.[currentIndex];
			prev?.splice(currentIndex, 1, {
				...currentItem,
				[key]: value
			});
			return [...prev];
		});
	};

	const commit: MovementDisputeChargeCommitEvent = (currentIndex, item) => {
		setDataSource((prev) => {
			const currentItem = prev?.[currentIndex];
			prev?.splice(currentIndex, 1, {
				...currentItem,
				...item
			});
			return [...prev];
		});
	};

	const remove: MovementDisputeChargeDeleteEvent = (currentIndex) => {
		setDataSource((prev) => {
			const currentItem = prev?.[currentIndex];
			if (currentItem?.id) {
				delIds.current.push(currentItem.id);
			}
			prev.splice(currentIndex, 1);
			return [...prev];
		});
	};

	const add = () => {
		setDataSource((prev) => {
			return [...prev, initialDisputeChargeItem(voyageId)];
		});
	};

	const getMovementDisputeChargeFront = () => {
		setLoading(true);
	};

	const getMovementDisputeChargeSuccess = (response) => {
		setLoading(false);
		setDataSource(response?.data);
	};

	const getMovementDisputeChargeFailed = (error) => {
		setLoading(false);
		reminder("error", error?.msg ? error?.msg + ": " + error?.data : error?.data);
	};

	const saveMovementDisputeChargeSuccess = (response) => {
		setLoading(false);
		onConfirm?.("disputeChargeList", dataSource);
		onClose?.();
	};

	const getMovementDisputeCharge = () => {
		onRequest(
			"getMovementDisputeChargeApi",
			{ id: voyageId },
			getMovementDisputeChargeFront,
			getMovementDisputeChargeSuccess,
			getMovementDisputeChargeFailed,
			dispatch,
			navigate
		);
	};

	const saveMovementDisputeCharge = () => {
		onRequest(
			"saveMovementDisputeChargeApi",
			{
				deleteIds: delIds?.current,
				charges: dataSource
			},
			getMovementDisputeChargeFront,
			saveMovementDisputeChargeSuccess,
			getMovementDisputeChargeFailed,
			dispatch,
			navigate
		);
	};

	useEffect(() => {
		if (hide) {
			delIds.current = [];
			return;
		}
		getMovementDisputeCharge();
	}, [hide]);

	return {
		loading,
		dataSource,
		summary,
		change,
		commit,
		remove,
		add,
		saveMovementDisputeCharge
	};
};

export default useIndex;
