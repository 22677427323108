import { type ParameterType } from "@/api";
import { loginOutAction } from "@/store/userSlice";

import {
  getPersonalInfoApi,
  savePersonalInfoApi,
  changePasswordApi,
  changeAvatarApi,
  type savePersonalInfoParams,
  type changePasswordParams,
  type changeAvatarParams
} from "@/api/personalCenter/index";

export const getPersonalInfoAction: ParameterType<null> = (
  params,
  frontCallback,
  successCallback,
  errorCallback,
  dispatch,
  navigate
) => {
  frontCallback?.();
  getPersonalInfoApi().then(response => {
    successCallback?.(response)
  }).catch(err => {
    errorCallback?.(err);
    if (err?.loginStatus === 'token faild') {
      dispatch(loginOutAction(() => navigate?.("/login")))
    }
  })
}

export const savePersonalInfoAction: ParameterType<savePersonalInfoParams> = (
  params,
  frontCallback,
  successCallback,
  errorCallback,
  dispatch,
  navigate
) => {
  frontCallback?.();
  savePersonalInfoApi(params).then(response => {
    successCallback?.(response)
  }).catch(err => {
    errorCallback?.(err);
    if (err?.loginStatus === 'token faild') {
      // dispatch?.(loginOut({}));
      // navigate?.('/')
      dispatch(loginOutAction(() => navigate?.("/login")))
    }
  })
}

export const changePasswordAction: ParameterType<changePasswordParams> = (
  params,
  frontCallback,
  successCallback,
  errorCallback,
  dispatch,
  navigate
) => {
  frontCallback?.();
  changePasswordApi(params).then(response => {
    successCallback?.(response)
  }).catch(err => {
    errorCallback?.(err);
    if (err?.loginStatus === 'token faild') {
      // dispatch?.(loginOut({}));
      // navigate?.('/')
      dispatch(loginOutAction(() => navigate?.("/login")))
    }
  })
}

export const changeAvatarAction: ParameterType<FormData> = (
  params,
  frontCallback,
  successCallback,
  errorCallback,
  dispatch,
  navigate
) => {
  frontCallback?.();
  changeAvatarApi(params).then(response => {
    successCallback?.(response)
  }).catch(err => {
    errorCallback?.(err);
    if (err?.loginStatus === 'token faild') {
      // dispatch?.(loginOut({}));
      // navigate?.('/')
      dispatch(loginOutAction(() => navigate?.("/login")))
    }
  })
}
