import { ParameterType } from "@/api";
import {
  deleteRoutePlanApi,
  deleteRoutePlanParams,
  getRoutePlanWayPointApi,
  getRoutePlanLinesApi,
  getRoutePlanLinesParams,
  downloadRoutePlanApi,
  downloadRoutePlanParams,
  uploadRoutePlanApi,
  uploadRoutePlanParams,
  getRoutePlanWayPointParams
} from "@/api/monitoring/routePlan"
import { loginOutAction } from "@/store/userSlice";

export const deleteRoutePlanAction: ParameterType<deleteRoutePlanParams> = (
  params,
  frontCallback,
  successCallback,
  errorCallback,
  dispatch,
  navigate
) => {
  frontCallback?.();
  deleteRoutePlanApi(params).then(response => {
    successCallback?.(response)
  }).catch(err => {
    errorCallback?.(err);
    if (err?.loginStatus === 'token faild') {
      dispatch(loginOutAction(() => navigate?.("/login")))
    }
  })
}

export const getRoutePlanLinesAction: ParameterType<getRoutePlanLinesParams> = (
  params,
  frontCallback,
  successCallback,
  errorCallback,
  dispatch,
  navigate
) => {
  frontCallback?.();
  getRoutePlanLinesApi(params).then(response => {
    successCallback?.(response)
  }).catch(err => {
    errorCallback?.(err);
    if (err?.loginStatus === 'token faild') {
      dispatch(loginOutAction(() => navigate?.("/login")))
    }
  })
}

export const getRoutePlanWayPointAction: ParameterType<getRoutePlanWayPointParams> = (
  params,
  frontCallback,
  successCallback,
  errorCallback,
  dispatch,
  navigate
) => {
  frontCallback?.();
  getRoutePlanWayPointApi(params).then(response => {
    successCallback?.(response)
  }).catch(err => {
    errorCallback?.(err);
    if (err?.loginStatus === 'token faild') {
      dispatch(loginOutAction(() => navigate?.("/login")))
    }
  })
}

export const uploadRoutePlanAction: ParameterType<uploadRoutePlanParams> = (
  params,
  frontCallback,
  successCallback,
  errorCallback,
  dispatch,
  navigate
) => {
  frontCallback?.();
  uploadRoutePlanApi(params).then(response => {
    successCallback?.(response)
  }).catch(err => {
    errorCallback?.(err);
    if (err?.loginStatus === 'token faild') {
      dispatch(loginOutAction(() => navigate?.("/login")))
    }
  })
}

export const downloadRoutePlanAction: ParameterType<downloadRoutePlanParams> = (
  params,
  frontCallback,
  successCallback,
  errorCallback,
  dispatch,
  navigate
) => {
  frontCallback?.();
  downloadRoutePlanApi(params).then(response => {
    successCallback?.(response)
  }).catch(err => {
    errorCallback?.(err);
    if (err?.loginStatus === 'token faild') {
      dispatch(loginOutAction(() => navigate?.("/login")))
    }
  })
}