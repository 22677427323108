import React, { useEffect, useMemo, useState } from "react";
import classnames from "classnames";
import style from "./index.module.less";
import EstimationProfit from "@/featrue/estimation/details/profit";
import Quicklock from "@/featrue/estimation/details/quickLook";
import EstimationRoute from "@/featrue/estimation/details/route";
import { useNavigate, useSearchParams } from "react-router-dom";
import { getEsCompareAction, saveEsCompareAction } from "@/action/estimation";
import { useAppDispatch } from "@/hook";
import useReminder from "@/hook/useReminder";
import { FleetLoading } from "@/components";
import FreightSensitivity from "@/featrue/estimation/details/freightSensitivity";
import SpdAllocation from "@/featrue/estimation/details/spdAllocation";
import { FreightSenProps } from "@/types/estimation/details/freighSensitivity";
import EsPerformance from "@/featrue/estimation/details/performance";
import StowagePlan from "@/featrue/estimation/details/stowagePlan";
import { moveToVmAction } from "@/action/estimation/index";
import useTrack from "@/hook/estimation/details/useTrack";
import { EstCargoAndPort, EstVesselParticular } from "./components"
import { CommonVesselArrival, FleetAisData } from "@/common";
import { saveEstTemplateAction } from "@/action/estimation/template";
import { EstDetailsContext } from "./store";
import { useIndex } from "./hooks";
import useDialogIndex from "./hooks/useDialogIndex";

const EstimationDetails: React.FC<{}> = (props) => {
	const dispatch = useAppDispatch();
	const navigate = useNavigate();
	const { reminder } = useReminder();
	const [searchParams] = useSearchParams();
	const detailsId = useMemo(() => {
		return searchParams?.get("id") as unknown as number;
	}, [searchParams]);

	const {
		queryLoading,
		portList,
		portCategory,
		delPortIds,
		portSeq,
		handlePortRotationConfirm,
		handlePortItemAdd,
		handlePortItemDelete,
		handlePortBatchCommit,
		loading,
		calculate,
		quicklockForm,
		shippingMap,
		identifierMap,
		init,
		setLoading,
		check,
		handleDeliverySelect,
		handleShipCommit,
		handleGraphMapChange,
		handleVesselHisCommit,
		handleIdentifierCommit,
		vesselItem,
		handleVesselItemChange,
		handleVesselItemCommit,
		cargoItem,
		handleCargoItemChange,
		otherItem,
		handleOtherItemChange,
		handleOtherItemCommit,
		handleBatchFoConsumCommit
	} = useIndex(detailsId);

	const {
		aisParams: vesselData,
		// loading,
		aisVesselItem: aisData,
		routeData,
		change: handleVesselDataChange
	} = useTrack(vesselItem?.mmsi, identifierMap, handleIdentifierCommit, handleShipCommit);

	const { dataSource: dialogItem, change: handleDialogItemChange } = useDialogIndex();

	const [vesselArchivesHide, setVesselArchivesHide] = useState(true);

	const saveEstTemplateSuccess = (response) => {
		setLoading(false);
		reminder("success", "Template saved successfully");
	};

	const handleSaveTemplate = (templateName: string) => {
		const {
			dwt,
			ballastSpeed,
			ladenSpeed,
			seaFuelBallast,
			seaFuelLaden,
			seaFuelMdo,
			tonnageType,
			tonnageValue,
			portFuelIdle,
			portFuelMdo,
			portFuelWork
		} = vesselItem;
		saveEstTemplateAction(
			{
				dwt,
				ballastSpeed,
				ladenSpeed,
				seaFuelBallast,
				seaFuelLaden,
				seaFuelMdo,
				tonnageType,
				tonnageValue,
				templateName,
				portFuelIdle,
				portFuelMdo,
				portFuelWork
			},
			null,
			saveEstTemplateSuccess,
			saveEsCompareError,
			dispatch,
			navigate
		);
	};

	const saveEsCompareFront = () => {
		setLoading(true);
	};

	const saveEsCompareSuccess = (response) => {
		reminder("success", response?.msg);
		getEsCompare();
	};

	const saveEsCompareError = (error) => {
		reminder("error", error.msg);
		setLoading(false);
	};

	const saveEsCompare = () => {
		// console.log("resultlist", {
		// 	...calculate,
		// 	...otherItem,
		// 	id: detailsId,
		// 	delPortIds: delPortIds?.current,
		// 	shortestPath: JSON.stringify(shippingMap?.current?.portTrack ?? ""),
		// 	ecaDistanceAdjustFactor: otherItem?.ecaDistanceAdjustFactor,
		// 	ecaDays: quicklockForm.totalEcaDays,
		// 	ecaBallastDays: quicklockForm.ballastEcaDays,
		// 	ecaLadenDays: quicklockForm.ladenEcaDays,
		// 	seaDays: quicklockForm.totalSeaDays,
		// 	seaBallastDays: quicklockForm.ballastSeaDays,
		// 	seaLadenDays: quicklockForm.ladenSeaDays,
		// 	portDays: quicklockForm.totalPortDays,
		// 	portWorkDays: quicklockForm.portWorkDays,
		// 	portIdleDays: quicklockForm.portIdleDays,
		// 	status: otherItem.status === "DFT" ? "EST" : otherItem.status,
		// 	startVoyTime: new Date(otherItem.startVoyTime)?.getTime(),
		// 	cpSpeed: otherItem.cpSpeed,
		// 	frtMidValue: otherItem.frtMidValue,
		// 	frtStep: otherItem.frtStep,
		// 	marginValue: calculate.totalMarginValue,
		// 	remark: otherItem?.remark,
		// 	vesselParticular: vesselItem,
		// 	cargoParticularList: [cargoItem],
		// 	portRotationList: portList
		// });
		// return;
		if (!check()) return;
		saveEsCompareAction(
			{
				resultList: [
					{
						...calculate,
						...otherItem,
						id: detailsId,
						delPortIds: delPortIds?.current,
						shortestPath: JSON.stringify(shippingMap?.current?.portTrack ?? ""),
						ecaDistanceAdjustFactor: otherItem?.ecaDistanceAdjustFactor,
						ecaDays: quicklockForm.totalEcaDays,
						ecaBallastDays: quicklockForm.ballastEcaDays,
						ecaLadenDays: quicklockForm.ladenEcaDays,
						seaDays: quicklockForm.totalSeaDays,
						seaBallastDays: quicklockForm.ballastSeaDays,
						seaLadenDays: quicklockForm.ladenSeaDays,
						portDays: quicklockForm.totalPortDays,
						portWorkDays: quicklockForm.portWorkDays,
						portIdleDays: quicklockForm.portIdleDays,
						status: otherItem.status === "DFT" ? "EST" : otherItem.status,
						startVoyTime: new Date(otherItem.startVoyTime)?.getTime(),
						cpSpeed: otherItem.cpSpeed,
						frtMidValue: otherItem.frtMidValue,
						frtStep: otherItem.frtStep,
						marginValue: calculate.totalMarginValue,
						remark: otherItem?.remark,
						vesselParticular: vesselItem,
						cargoParticularList: [cargoItem],
						portRotationList: portList
					}
				]
			},
			saveEsCompareFront,
			saveEsCompareSuccess,
			saveEsCompareError,
			dispatch,
			navigate
		);
	};

	const getEsCompareFront = () => {
		setLoading(true);
	};

	const getEsCompareSuccess = (response) => {
		// init(response?.data ?? []);
		init(response?.data?.[0] ?? {});
		// setSelectList([])
		setLoading(false);
	};

	const getEsCompareError = (error) => {
		reminder("error", error.msg);
		setLoading(false);
	};

	const getEsCompare = () => {
		if (!detailsId || detailsId == null) return;
		getEsCompareAction(
			{ ids: detailsId },
			getEsCompareFront,
			getEsCompareSuccess,
			getEsCompareError,
			dispatch,
			navigate
		);
	};

	const moveToVmFront = () => {
		setLoading(true);
	};

	const moveToVmSuccess = (response) => {
		reminder("success", response?.data);
		setLoading(false);
		navigate("/layout/voyagemanage/preoperation");
	};

	const moveToVmError = (error) => {
		reminder("error", error?.msg ? error?.msg + ": " + error?.data : error?.data);
		setLoading(false);
	};

	const moveToVm = () => {
		moveToVmAction(
			{
				resultId: detailsId
			},
			moveToVmFront,
			moveToVmSuccess,
			moveToVmError,
			dispatch,
			navigate
		);
	};

	const handleRewrite = (item) => {
		console.log("handleRewrite", item);
		handleVesselItemCommit(item);
		handleDialogItemChange("spdAllocation", true);
	};

	const handleFrtRwrite = (data: FreightSenProps) => {
		handleOtherItemCommit(data);
		handleDialogItemChange("freightSensitivity", true);
	};

	useEffect(() => {
		getEsCompare();
	}, []);

	console.log("handleDialogItemChange", dialogItem);

	return (
		<EstDetailsContext.Provider
			value={{
				identifierMap,
				onIdentifierChange: handleGraphMapChange,
				onShippingCommit: handleShipCommit
			}}
		>
			<div className={classnames("estimation-common", style["fleet-estimationDetails"])}>
				<FleetLoading loading={loading} />
				<div className="estimationDetails fleet-nonScrolBar">
					<div className="estimationDetails-container">
						<div className="estimationDetails-form fleet-nonScrolBar">
							{/* <EstimationVesselParticular
            form={vesselParForm}
            onChange={handleVesselParChange}
            onOpenSpd={handleSpdOpen}
            onCommit={handleVesselParCommit}
            onSelectDeliveryPort={handleDeliverySelect}
            onTemplateSave={handleSaveTemplate}
          /> */}
							<EstVesselParticular
								item={vesselItem}
								onChange={handleVesselItemChange}
								onSpdAllocationOpen={() => handleDialogItemChange("spdAllocation", false)}
								onCommit={handleVesselItemCommit}
								onTemplateSave={handleSaveTemplate}
								onDeliveryPortSelect={handleDeliverySelect}
								deliveryDisabled={queryLoading}
								onPortBatchCommit={handlePortBatchCommit}
								onPortConsumCommit={handleBatchFoConsumCommit}
							/>
							<EstCargoAndPort
								portDisabled={queryLoading}
								portItems={portList}
								cargoItem={cargoItem}
								onCargoItemChange={handleCargoItemChange}
								otherItem={{
									ballastMarginDay: otherItem?.ballastMarginDay,
									ballastMarginDistance: otherItem?.ballastMarginDistance,
									ladenMarginDay: otherItem?.ladenMarginDay,
									ladenMarginDistance: otherItem?.ladenMarginDistance,
									ballastMarginFlag: otherItem?.ballastMarginFlag,
									ladenMarginFlag: otherItem?.ladenMarginFlag
								}}
								onOtherItemChange={handleOtherItemChange}
								vesselItem={vesselItem}
								onPortRotationConfirm={handlePortRotationConfirm}
								onPortItemAdd={handlePortItemAdd}
								onPortItemDelete={handlePortItemDelete}
								onPortBatchCommit={handlePortBatchCommit}
								calculate={{
									ttlDistanceBallast: calculate?.ttlDistanceBallast,
									ttlDistanceLaden: calculate?.ttlDistanceLaden,
									totalMarginValue: calculate?.totalMarginValue,
									ecaDistanceBallast: calculate?.ecaDistanceBallast,
									ecaDistanceLaden: calculate?.ecaDistanceLaden
								}}
								onStowagePlanOpen={() => handleDialogItemChange("stowagePlan", false)}
							/>
							{/* <EstimationDetailsRotation
            form={cargoForm}
            portGroup={portGroup}
            cargoQuantity={cargoForm.cargoQuantity}
            vesselSource={{
              portFuelIdle: vesselItem?.portFuelIdle,
              portFuelMdo: vesselItem?.portFuelMdo,
              portFuelWork: vesselItem?.portFuelWork
            }}
            ballastPortList={ballastPortList}
            ladenPortList={ladenPortList}
            onAddPort={addPort}
            onRmPort={rmPort}
            onPortCommit={handlePortGroupCommit}
            onCommit={handlePortCommit}
            distanceForm={{
              ttlDistanceBallast: calculate.ttlDistanceBallast,
              ecaDistanceBallast: calculate.ecaDistanceBallast,
              ttlDistanceLaden: calculate.ttlDistanceLaden,
              ecaDistanceLaden: calculate.ecaDistanceLaden,
              totalMarginValue: calculate.totalMarginValue
            }}
            onChange={handleCargoChange}
            onPortChange={handlePortGroupChange}
            onSpOpen={handleSpOpen}
          /> */}
						</div>
						<EstimationProfit
							className="estimationDetails-profit fleet-nonScrolBar"
							calculate={calculate}
							remark={otherItem?.remark}
							onChange={(val: string) => {
								handleOtherItemChange?.("remark", val);
							}}
						/>
					</div>
				</div>
				<div className="estimationDetails-graph fleet-nonScrolBar">
					<Quicklock
						status={otherItem?.status}
						quicklockForm={quicklockForm}
						onSave={saveEsCompare}
						onOpenFrt={() => handleDialogItemChange("freightSensitivity", false)}
						onOpenPm={() => handleDialogItemChange("performance", false)}
						onSendVm={moveToVm}
					/>
					<EstimationRoute
						shippingMap={shippingMap}
						identifierMap={identifierMap}
						cargoItem={{
							layCanFrom: cargoItem?.layCanFrom,
							layCanTo: cargoItem?.layCanTo
						}}
						otherItem={{
							startVoyTime: otherItem?.startVoyTime
						}}
						onCargoItemChange={handleCargoItemChange}
						onOtherItemChange={handleOtherItemChange}
						deliveryPort={otherItem?.deliveryPort}
						ballastPortList={portCategory?.ballastPortList}
						portSeq={portSeq}
						ladenPortList={portCategory?.ladenPortList}
						onVesselHisCommit={handleVesselHisCommit}
						onIdentifierChange={handleGraphMapChange}
						onShippingCommit={handleShipCommit}
						speedList={{
							ladenSpeed: vesselItem?.ladenSpeed,
							ballastSpeed: vesselItem?.ballastSpeed
						}}
						delievryPort={{
							delievryPortCode: vesselItem.deliveryPortCode,
							delievryPortId: vesselItem.deliveryPortId,
							delievryPortName: vesselItem.deliveryPortName
						}}
					/>
					<FleetAisData
						type="est"
						loading={routeData?.loading}
						dataSource={{
							speeds: routeData.speeds,
							dates: routeData.dates,
							speed: vesselData?.speed,
							days: vesselData?.days
						}}
						onChange={handleVesselDataChange}
						onVesselArrivalOpen={() => {
							setVesselArchivesHide(false);
						}}
					/>
					{/* <EstimationVesselArrival imo={vesselItem?.imo} aisData={aisData} /> */}
				</div>
				<CommonVesselArrival
					imo={vesselItem?.imo}
					type="details"
					hide={vesselArchivesHide}
					onClose={() => {
						setVesselArchivesHide(true);
					}}
				/>
				<SpdAllocation
					hide={dialogItem?.spdAllocation}
					onClose={() => handleDialogItemChange("spdAllocation", true)}
					onRewrite={handleRewrite}
					ballastPortList={portCategory?.ballastPortList}
					ladenPortList={portCategory?.ladenPortList}
					vesselItem={{
						otherExpenses: vesselItem.otherExpenses,
						brokerage: vesselItem.brokerage,
						commission: vesselItem.commission,
						dailyHire: vesselItem.dailyHire,
						portFuelWork: vesselItem.portFuelWork,
						portFuelIdle: vesselItem.portFuelIdle,
						portFuelMdo: vesselItem.portFuelMdo
					}}
					onChangeOriginForm={handleVesselItemChange}
					cargoItem={cargoItem}
					originForm={{
						bunkerPriceIfo: vesselItem.bunkerPriceIfo,
						bunkerPriceMdo: vesselItem.bunkerPriceMdo,
						ballastSpeed: vesselItem.ballastSpeed,
						ladenSpeed: vesselItem.ladenSpeed,
						seaFuelBallast: vesselItem.seaFuelBallast,
						seaFuelLaden: vesselItem.seaFuelLaden,
						seaFuelMdo: vesselItem.seaFuelMdo
					}}
				/>
				<FreightSensitivity
					hide={dialogItem?.freightSensitivity}
					onClose={() => handleDialogItemChange("freightSensitivity", true)}
					onConfirm={handleFrtRwrite}
					midValueGroup={{
						freightRate: cargoItem.freightType === "FR" ? cargoItem.freightRate : 0,
						tce: calculate.tce
					}}
					id={detailsId}
					originSource={{
						freightRate: cargoItem.freightRate,
						cargoQuantity: cargoItem.cargoQuantity,
						commission: cargoItem.commission,
						brokerage: cargoItem.brokerage,
						operationCost: calculate.operationCost,
						totalHireCost: calculate.totalHireCost,
						totalPortDays: quicklockForm.totalPortDays,
						totalSeaDays: quicklockForm.totalSeaDays
					}}
				/>
				<EsPerformance
					hide={dialogItem?.performance}
					onClose={() => handleDialogItemChange("performance", true)}
					mmsi={vesselItem.mmsi}
					id={detailsId}
				/>
				<StowagePlan
					hide={dialogItem?.stowagePlan}
					onClose={() => handleDialogItemChange("stowagePlan", true)}
					source={{
						resultId: detailsId,
						ballastPortList: portCategory?.ballastPortList,
						ladenPortList: portCategory?.ladenPortList,
						delievryPortCode: vesselItem?.deliveryPortCode,
						delievryPortId: vesselItem?.deliveryPortId,
						delievryPortName: vesselItem?.deliveryPortName,
						ballastSpeed: vesselItem?.ballastSpeed,
						ladenSpeed: vesselItem?.ladenSpeed,
						seaFuelBallast: vesselItem?.seaFuelBallast,
						seaFuelLaden: vesselItem?.seaFuelLaden,
						seaFuelMdo: vesselItem?.seaFuelMdo,
						portFuelWork: vesselItem?.portFuelWork,
						portFuelIdle: vesselItem?.portFuelIdle,
						portFuelMdo: vesselItem?.portFuelMdo,
						startVoyTime: otherItem?.startVoyTime
					}}
				/>
			</div>
		</EstDetailsContext.Provider>
	);
};

export default EstimationDetails;
