import { ExcludeKeys, ExtendsKeys } from "@/types";
import { HeadFixtueKeys, HeadFixtueLabelMap } from "@/types/voyageManage/headFixtureList";

export type HfPaymentTermsKeys = ExtendsKeys<HeadFixtueKeys,
  "paymentTerm" | "paymentRate" | "paymentStartDay"
>;

export type HfPaymentTermsNumKeys = ExtendsKeys<HfPaymentTermsKeys,
  "paymentRate" | "paymentTerm"
>;

// export type HfPaymentTermsDateKeys = ExtendsKeys<HfPaymentTermsKeys,"paymentStartDay">;

export type HfPaymentTermsStrKeys = ExcludeKeys<HfPaymentTermsKeys, HfPaymentTermsNumKeys>;

export type HfPaymentTermsFormKeys = HfPaymentTermsStrKeys;

export type HfPaymentTermsFormType =
  Record<HfPaymentTermsStrKeys, string> 
  // &
  // Record<HfPaymentTermsNumKeys, number | "">;



export type HPaymentTermsFormKeys =  HfPaymentTermsStrKeys;


/**
 * hooks type
 */

export type HfPaymentTermsFormChangeEvent = <K extends HPaymentTermsFormKeys>(key: K, value: HfPaymentTermsFormType[K]) => void;

export type HfPaymentTermsFormCommitEvent = (item: Partial<HfPaymentTermsFormType>) => void;

/**
 * hfbasicprops type
 */

export type HfPaymentTermsFormProps = {
	item: HfPaymentTermsFormType;
	onChange: HfPaymentTermsFormChangeEvent;
	onCommit?: HfPaymentTermsFormCommitEvent;
}

/**
 * init data
 */
export const initialPaymentTermsForm:HfPaymentTermsFormType = {
  // paymentRate:"",
  // paymentTerm:"",
  paymentStartDay:""
}

export const checkPaymentTermsFormKeys:Partial<HPaymentTermsFormKeys>[] = [
  // "paymentRate","paymentTerm","paymentStartDay"
]