import { FleetSelect } from "@/components";
import React, { FC } from "react";
import useIndex from "./hooks/useIndex";
import { RoutePortSelectProps } from "./type";
import { CommonLatitudeSelect } from "@/common";
import { RoutePortPopup } from "./components";

const RoutePortSelect: FC<RoutePortSelectProps> = ({
	onFinish,
	activeItems,
	primaryKey,
	showKey,
	showCount,
	className,
	disabled,
	placeholder
}) => {
	const {
		loading,
		list,
		open,
		loadMore,
		closePopup,
		focusPopup,
		searchValue,
		tagRender,
		handleEnter,
		handleSearch,
		handleItemSelect,
		handleLatitudeClose,
		selectValue,
		status
	} = useIndex(activeItems, showKey, onFinish, primaryKey, disabled);

	return (
		<FleetSelect
			showSearch
			disabled={disabled}
			className={className}
			placeholder={placeholder}
			value={selectValue}
			showCount={showCount}
			popupMatchSelectWidth={false}
			onDropdownVisibleChange={closePopup}
			tagRender={tagRender}
			mode={Array?.isArray(activeItems) ? "multiple" : undefined}
			open={open}
			allowClear
			onClear={() => onFinish?.(null, "del")}
			// searchValue={searchValue}
			onInputKeyDown={handleEnter}
			onSearch={handleSearch}
			onFocus={() => focusPopup()}
			searchValue={status === "num" ? searchValue : undefined}
			dropdownRender={() =>
				status === "num" ? (
					<CommonLatitudeSelect onClose={handleLatitudeClose} open={open} />
				) : (
					<RoutePortPopup
						loadMore={loadMore}
						list={list}
						onSelect={handleItemSelect}
						loading={loading}
					/>
				)
			}
		/>
	);
};

export default RoutePortSelect;
