import { _range, range } from "@/tools";
import React, { useEffect, useMemo, useRef, useState } from "react";
import { FleetTrackPointItemType } from "../type";

export interface Pos {
  x: number,
  y: number
}

export type ChartOption = {
  canvasWidth: number;
  canvasHeight: number;
  /**
  * 画布左右的外间距
  */
  canvasMargin: number;
  /**
  * 原点坐标位置
  */
  originX: number;
  originY: number;
  /**
  * 总共要画的坐标点数(x)
  */
  totalDots: number;
  /**
  * 坐标与坐标之间的距离
  */
  dotSpace: number
  /**
  * 总共要画的坐标点数(y:speed)
  */
  maxY: number;
  minY: number;
  rangeY: number;
  axisY: number[];
  axisLineX: string[] | number[];
  axisX: string[] | number[];
  ratioLineX: number;
  ratioY: number;
  ratioX: number;
  newXs: string[];
  newYs: string[];
  endAreaX: number;
  endAreaY: number;
  startAreaX: number;
  startAreaY: number;
  prePointPosX: number;
  prePointPosY: number;
  pointList: any[];
}
const useIndex = (option: {
  root: HTMLDivElement,
  data: FleetTrackPointItemType[],
  dateRange: string[],
  cpSpeed: number,
  dateLength: number
}) => {
  const { root, data, dateRange, cpSpeed, dateLength } = option;
  const options = useRef<Partial<ChartOption>>({
    canvasMargin: 25,
    totalDots: 0,
    canvasHeight: 0,
    canvasWidth: 0,
    dotSpace: 0,
    maxY: 0,
    rangeY: 0,
    minY: 0,
    axisX: [],
    axisLineX: [],
    ratioLineX: 0,
    axisY: [],
    ratioY: 0,
    ratioX: 0,
    newXs: [],
    newYs: [],
    endAreaX: 0,
    endAreaY: 0,
    startAreaX: 0,
    startAreaY: 0,
    prePointPosX: 0,
    prePointPosY: 0,
    pointList: []
  });

  const ctx = useRef<CanvasRenderingContext2D>(null);

  const initOptions = () => {

    options.current.canvasWidth = root.clientWidth;
    options.current.canvasHeight = root.clientHeight;

    // 折线图信息
    options.current.totalDots = data.length - 1;


    options.current.dotSpace = (options.current.canvasWidth - options.current.canvasMargin * 2) / options.current.totalDots;
    options.current.maxY = 16;
    options.current.minY = 0;
    options.current.rangeY = options.current.maxY - options.current.minY;
    // options.current.axisY = _range(options.current.minY, options.current.maxY);
    options.current.axisY = range(options.current.minY, options.current.maxY, 4);
    options.current.axisLineX = Array(dateLength);

    // options.current.axisX = data;
    options.current.ratioY = (options.current.canvasHeight - 2 * options.current.canvasMargin) / options.current.rangeY;
    options.current.ratioX = (options.current.canvasWidth - 2 * options.current.canvasMargin) / options.current.totalDots;
    options.current.ratioLineX = (options.current.canvasWidth - 2 * options.current.canvasMargin) / (dateLength - 1);

    options.current = {
      ...options.current,
      startAreaX: 0,
      startAreaY: 0,
      endAreaX: 0,
      endAreaY: 0,
      prePointPosX: 0,
      prePointPosY: 0
    }

    // ctx.current.translate(0.5, 0.5);  // 当只绘制1像素的线的时候，坐标点需要偏移，这样才能画出1像素实线
  }

  const initChart = () => {
    const canvas = document.createElement("canvas");
    root.firstElementChild && root.removeChild(root.firstElementChild)
    root.appendChild(canvas);
    ctx.current = canvas.getContext("2d");
    canvas.width = root.clientWidth;
    canvas.height = root.clientHeight;
    ctx.current.clearRect(0, 0, root.clientWidth, root.clientHeight);
    // console.log("canvas,",canvas,root.clientWidth,root.clientHeight, options.current.canvasWidth, options.current.canvasHeight)
  }

  const clearRect = () => {
    ctx.current?.clearRect(0, 0, window.outerWidth, window.outerHeight);
    // root.removeChild(root.firstElementChild)
  }

  const resiseInit = (e) => {
    initOptions()
  }

  const drawLine = (x, y, X, Y) => {
    ctx.current.beginPath();
    ctx.current.moveTo(x, y);
    ctx.current.lineTo(X, Y);
    ctx.current.stroke();
    ctx.current.closePath();
  }

  const drawXaxis = () => {
    const {
      canvasMargin,
      canvasWidth,
      canvasHeight,
      axisX,
      axisLineX,
      ratioLineX,
      ratioX,
      dotSpace
    } = options.current

    const xLen = axisLineX.length;
    console.log("xLen", xLen)
    for (let i = 0; i < xLen; i++) {
      let xPos = (canvasMargin + i * ratioLineX);
      if (i && i !== 0) {
        ctx.current.beginPath();
        ctx.current.moveTo(xPos, canvasHeight - canvasMargin);
        ctx.current.lineTo(xPos, canvasMargin);
        ctx.current.strokeStyle = '#ddd';
        ctx.current.setLineDash([2, 2])
        ctx.current.stroke();
        ctx.current.setLineDash([1])
      }
      ctx.current.beginPath();
      ctx.current.stroke();
      // options.current.newXs = [];
      // for (let index in axisX) {
      //   options.current.newXs.push(`${axisX[index]}`);
      // }

      if (i === 0) {
        ctx.current.fillText(dateRange?.[0], xPos - 1, canvasHeight - canvasMargin + 10);
      } else if (i === xLen - 1) {
        ctx.current.fillText(dateRange?.[1], canvasWidth - canvasMargin * 2 - 30, canvasHeight - canvasMargin + 10);
      }
    }
  }

  const drawYaxis = () => {
    const {
      rangeY,
      minY,
      canvasMargin,
      canvasWidth,
      ratioY,
      axisY,
      canvasHeight
    } = options.current;
    ctx.current.strokeStyle = "#E0E0E0";
    const yLen = axisY.length;
    options.current.newYs = [];
    for (let i = 0; i < yLen; i++) {
      let y = (rangeY * i) / (yLen - 1) + minY;
      let yPos = canvasHeight - canvasMargin - (y - minY) * ratioY;
      if (i) {
        ctx.current.beginPath();
        ctx.current.moveTo(canvasMargin, yPos);
        ctx.current.lineTo(canvasWidth - canvasMargin, yPos);
        ctx.current.setLineDash([2, 2])
        ctx.current.strokeStyle = '#ddd'
        ctx.current.stroke();
        ctx.current.setLineDash([1]);

        options.current.newYs.push(`${y}`)
      }

      ctx.current.beginPath();
      ctx.current.stroke();
      ctx.current.fillText(`${+y.toFixed(2)}` + '', canvasMargin - 25, yPos + 5);
    }
  }

  const drawCoordinateAxis = () => {
    const { canvasWidth, canvasMargin, canvasHeight } = options.current;
    const yPos = canvasHeight - canvasMargin - (cpSpeed - options.current.minY) * options.current.ratioY;
    if (cpSpeed <= options.current.minY || cpSpeed > options.current.maxY) return;
    ctx.current.beginPath();
    ctx.current.strokeStyle = '#FF8632'
    ctx.current.moveTo(canvasMargin, yPos);
    ctx.current.lineTo(canvasWidth - canvasMargin, yPos);
    ctx.current.stroke()
  }

  const drawFoldLine = () => {
    const { canvasMargin, canvasHeight, } = options.current;
    options.current.startAreaX = options.current.endAreaX
    options.current.startAreaY = options.current.endAreaY
    ctx.current.beginPath();
    ctx.current.moveTo(canvasMargin + options.current.endAreaX,
      canvasHeight - canvasMargin - (data[0]?.speed - options.current.minY) * options.current.ratioY);
    ctx.current.lineWidth = 2;
    ctx.current.setLineDash([0]);

    ctx.current.lineCap = "round"
    ctx.current.lineJoin = "round"

    let x = 0, y = 0, translateX = 0;
    for (let i = 0; i < data.length; i++) {
      x = (i * options.current.ratioX + canvasMargin + options.current.endAreaX + translateX);
      let yDis = (data[i]?.speed - options.current.minY) * options.current.ratioY;
      y = canvasHeight - canvasMargin - (yDis == 0 ? 1 : yDis);
      let x0 = (i - 1) * options.current.ratioX + canvasMargin + options.current.endAreaX + translateX;
      let y0 = canvasHeight - canvasMargin - (data[i - 1]?.speed - options.current.minY) * options.current.ratioY;
      let xc = x0 + options.current.ratioX / 2;
      options.current.prePointPosX = x
      options.current.prePointPosY = y
      ctx.current.lineTo(x, y);
      // if (i === 0) {
      //   options.current.prePointPosX = x
      //   options.current.prePointPosY = y
      //   ctx.current.lineTo(x, y);
      //   if (!(options.current.prePointPosX === x && options.current.prePointPosY === y)) {
      //     options.current.pointList.push({
      //       type: 'line',
      //       start: { x: options.current.prePointPosX, y: options.current.prePointPosY }, end: { x: x, y: y }
      //     })
      //   }
      // } else {
      //   ctx.current.bezierCurveTo(xc, y0, xc, y, x, y);
      //   options.current.pointList.push({
      //     type: 'curve',
      //     start: { x: options.current.prePointPosX, y: options.current.prePointPosY },
      //     end: { x: x, y: y }, control1: { x: xc, y: y0 },
      //     control2: { x: xc, y: y }
      //   })
      // }
      options.current.prePointPosX = x
      options.current.prePointPosY = y
      if (i === data.length - 1) {
        options.current.endAreaX = x;
        options.current.endAreaY = y;

        // if (firstEnding && id === newOpt.data.length - 1) {
        //   areaList.push({ x: x, y: y })
        // }
      }

    }

    ctx.current.strokeStyle = "#355691"
    ctx.current.stroke()
  }

  const drawLineLabelMarkers = () => {
    ctx.current.lineWidth = 1;
    ctx.current.fillStyle = "#566a80";
    ctx.current.strokeStyle = "#566a80";
    // y轴
    drawLine(
      options.current.canvasMargin,
      options.current.canvasMargin,
      options.current.canvasMargin,
      options.current.canvasHeight - options.current.canvasMargin
    );
    // x轴
    drawLine(
      options.current.canvasMargin,
      options.current.canvasHeight - options.current.canvasMargin,
      options.current.canvasWidth - options.current.canvasMargin,
      options.current.canvasHeight - options.current.canvasMargin,
    );
  }

  const drwaEmpty = () => {
    const {
      canvasHeight,
      canvasMargin,
      canvasWidth
    } = options.current;
    // ctx.current.fillStyle = "#333"
    // ctx.current.font = "30px"
    ctx.current.fillText("No Data", canvasWidth / 2, canvasHeight / 2)
  }

  const resize = () => {
    clearRect()
    draw()
  }

  const draw = () => {
    if (!root) return;
    initChart();
    initOptions();
    drawLineLabelMarkers();

    if (options.current.totalDots <= 0) {
      drwaEmpty()
    } else {
      drawYaxis();
      drawXaxis();
      drawFoldLine();
      drawCoordinateAxis()
    }
  }

  useEffect(() => {
    draw()
    window.addEventListener("resize", resize);
    return () => {
      window.removeEventListener("resize", resize)
    }
  }, [root, cpSpeed, data, dateRange])

  return {

  }
}

export default useIndex