import { useCommonIndex } from "@/hook"
import { useState } from "react"
import { InvStatementOtherItemType, InvStatementDialogChangeEvent, InvStatementDialogType } from "../type"
import { initialInvStatementOtherItem } from "../source"

const useOtherIndex = () => {
  const {
    dataSource,
    change,
    init
  } = useCommonIndex<InvStatementOtherItemType>(initialInvStatementOtherItem)

  const [dialogItem, setDialogItem] = useState<InvStatementDialogType>({
    history: true,
    field: true
  })

  const handleDialogChange: InvStatementDialogChangeEvent = (key, value) => {
    setDialogItem(prev => ({ ...prev, [key]: value }))
  }

  return {
    dataSource,
    change,
    init,
    dialogItem,
    handleDialogChange
  }
}

export default useOtherIndex