import { PromptChildrenProps } from "@/common/Prompt";
import { ExtendsKeys } from "@/types";

type ContactKeys = "businessType" |
  "companyName" |
  "contactsId" |
  "contactsType" |
  "email" |
  "faxNumber" |
  "id" |
  "landline1" |
  "landline2" |
  "mobilePhone1" |
  "mobilePhone2" |
  "name" |
  "remark" |
  "voyageId";

type ContactEditItemKeys = ExtendsKeys<ContactKeys,
  "name" |
  "companyName" |
  "mobilePhone1" |
  "mobilePhone2" |
  "landline1" |
  "landline2" |
  "faxNumber" |
  "email" |
  "remark" |
  "id" |
  "businessType"
>;

type ContactEditItemNumKeys = ExtendsKeys<ContactEditItemKeys,
  "id"
>;

type ContactEditItemStrKeys = ExtendsKeys<ContactEditItemKeys,
  "name" |
  "companyName" |
  "mobilePhone1" |
  "mobilePhone2" |
  "landline1" |
  "landline2" |
  "faxNumber" |
  "email" |
  "remark" |
  "businessType"
>;

export type DatabaseContactEditItemKeys = ContactEditItemNumKeys | ContactEditItemStrKeys;

export type DatabaseContactEditItemType = Partial<Record<ContactEditItemNumKeys, number>> & Record<ContactEditItemStrKeys, string>;

export type DatabaseContactEditItemChangeEvent = <K extends DatabaseContactEditItemKeys>(key: K, value: DatabaseContactEditItemType[K]) => void;

export type DatabaseContactEditItemCommitEvent = (item: Partial<DatabaseContactEditItemType>) => void;

export const initialDatabaseContactEditItem: DatabaseContactEditItemType = {
  name: "",
  companyName: "",
  mobilePhone1: "",
  mobilePhone2: "",
  landline1: "",
  landline2: "",
  faxNumber: "",
  email: "",
  remark: "",
  businessType: "",
}

export type DatabaseContactEditPorps = {
	id?: string | number;
	onConfirm?: () => void;
} & PromptChildrenProps;