import {
	getAreaInfoListAction,
	getCountryListAction,
	getPortCodeListAction
} from "@/action/monitoring/area";
import { useAppDispatch } from "@/hook";
import useReminder from "@/hook/useReminder";
import { useEffect, useMemo, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { AreaListFilterProps, FilterOptionType, FilterTimeRangeType } from "../type";
import { PageParams } from "@/api/estimation";
import { MonitoringDetailMoreTabType } from "@/pages/monitoring/components/DetailDrawer/type";
import { debounce } from "lodash";
import dayjs from "dayjs";

const useIndex = (
	activeIndex: MonitoringDetailMoreTabType,
	onTimeRange: AreaListFilterProps["onTimeRange"]
) => {
	const dispatch = useAppDispatch();
	const navigate = useNavigate();
	const { reminder } = useReminder();
	const [loading, setLoading] = useState<boolean>(false);
	const [optionList, setOptionList] = useState<FilterOptionType>();
	const [selectValue, setSelectValue] = useState();

	const [timeRange, setTimeRange] = useState<FilterTimeRangeType>({
		startDate: dayjs().format("YYYY-MM-DD"),
		endDate: dayjs().format("YYYY-MM-DD")
	});

	const page = useRef<PageParams>({
		pageNum: 1,
		pageSize: 20
	});

	const getAreaInfoListFront = () => {
		setLoading(true);
	};

	const portLoadMoreSuccess = (response) => {
		const items = response?.data?.list?.map((item) => {
			return { label: item.portName, value: item.portCode };
		});
		setOptionList((prev) => {
			return { ...prev, port: [...prev.port, ...items] };
		});
		setLoading(false);
	};

	const countryLoadMoreSuccess = (response) => {
		const items = response?.data?.list?.map((item) => {
			return { label: item.countryName, value: item.countryName };
		});
		setOptionList((prev) => {
			return { ...prev, country: [...prev.country, ...items] };
		});
		setLoading(false);
	};

	const getAreaInfoListSuccess = (response) => {
		setLoading(false);
		const areaCodeItems = response?.data?.map((item) => {
			return { label: item.areaName, value: item.areaCode };
		});
		setOptionList((prev) => {
			return { ...prev, area: areaCodeItems ?? [] };
		});
	};

	console.log("options", optionList);

	const getPortCodeListSuccess = (response) => {
		const portCodeItems = response?.data?.list?.map((item) => {
			return { label: item.portName, value: item.portCode };
		});
		setOptionList((prev) => {
			return { ...prev, port: portCodeItems ?? [] };
		});
		setLoading(false);
	};

	const getCountryListSuccess = (response) => {
		const countryItems = response?.data?.list?.map((item) => {
			return { label: item.countryName, value: item.countryName };
		});
		setOptionList((prev) => {
			return { ...prev, country: countryItems ?? [] };
		});
		setLoading(false);
	};

	const getAreaInfoListFailed = (error) => {
		setLoading(false);
		reminder("error", error?.msg ? error?.msg + ": " + error?.data : error?.data);
	};
	const getAreaInfoList = (areaName?: string) => {
		getAreaInfoListAction(
			{ keyword: areaName },
			getAreaInfoListFront,
			getAreaInfoListSuccess,
			getAreaInfoListFailed,
			dispatch,
			navigate
		);
	};

	const getPortCodeList = (loadMore?: boolean) => {
		getPortCodeListAction(
			{ pageNum: page.current.pageNum, pageSize: page.current.pageSize },
			getAreaInfoListFront,
			loadMore ? portLoadMoreSuccess : getPortCodeListSuccess,
			getAreaInfoListFailed,
			dispatch,
			navigate
		);
	};

	const getCountryList = (loadMore?: boolean) => {
		getCountryListAction(
			{ pageNum: page.current.pageNum, pageSize: page.current.pageSize },
			getAreaInfoListFront,
			loadMore ? countryLoadMoreSuccess : getCountryListSuccess,
			getAreaInfoListFailed,
			dispatch,
			navigate
		);
	};

	const loadMore = (type: "port" | "country") => {
		page.current = {
			...page.current,
			pageNum: page.current.pageNum + 1
		};
		if (type == "port") {
			getPortCodeList(true);
		} else if (type == "country") {
			getCountryList(true);
		}
	};

	const onDropdown = (open: boolean) => {
		if (!open) {
			page.current.pageNum = 1;
		}
	};

	const focusPopup = (type: "port" | "country") => {
		if (type == "port") {
			getPortCodeList();
		} else if (type == "country") {
			getCountryList();
		}
	};

	const handleTimeRange = (vals: FilterTimeRangeType) => {
		setTimeRange(vals);
		onTimeRange(vals);
	};

	useEffect(() => {
		if (activeIndex !== "areaVesslDetails") {
			setOptionList({ area: [], port: [], country: [] });
			return;
		}
		getAreaInfoList();
		getPortCodeList();
		getCountryList();
	}, [activeIndex]);
	return {
		optionList,
		loading,
		timeRange,
		selectValue,
		loadMore,
		onDropdown,
		focusPopup,
		handleTimeRange
	};
};

export default useIndex;
