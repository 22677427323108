import { useRef, useState } from "react";
import useReminder from "@/hook/useReminder";
import {
  MovementOtherReportsProps,
  OtherReportsFormNameType,
  OtherReportsCommonRefType,
} from "../type";
import { FleetOptionType } from "@/types/advancedType";
const useIndex = () => {
  const [activeType, setActiveType] =
    useState<OtherReportsFormNameType>("departureReport");
  const { reminder } = useReminder();
  const hasConfirmed = useRef(false);
  const arrivalReportRef = useRef<OtherReportsCommonRefType>();
  const departureReportRef = useRef<OtherReportsCommonRefType>();
  const shiftingReportRef = useRef<OtherReportsCommonRefType>();
  const handleChangeType = (
    item: FleetOptionType<
      "Departure Report" | "Arrival Report" | "Shifting Report",
      OtherReportsFormNameType
    >
  ) => {
    handleSave();
    setActiveType(item.value);
  };
  const handleSave = () => {
    if (activeType == "departureReport" && departureReportRef.current.data.length!=0) {
      departureReportRef.current.onSave();
      hasConfirmed.current = true;
      reminder("success", "Success");
    }
    if (activeType == "arrivalReport" && arrivalReportRef.current.data.length!=0) {
      arrivalReportRef.current.onSave();
      hasConfirmed.current = true;
      reminder("success", "Success");
    }
    if (activeType == "shiftingReport" && shiftingReportRef.current.data.length!=0) {
      shiftingReportRef.current.onSave();
      hasConfirmed.current = true;
      reminder("success", "Success");
    }
  };
  return {
    activeType,
    handleChangeType,
    handleSave,
    setActiveType,
    arrivalReportRef,
    departureReportRef,
    shiftingReportRef,
  };
};
export default useIndex;
