import React from "react";
import style from "./index.module.less";
import { DatabaseVesselFilterProps } from "./type";
import { FleetInput } from "@/components";
import { FleetPortTabs } from "@/components/Tabs";

const VesselFilter: React.FC<DatabaseVesselFilterProps> = ({
  activeIndex,
  onSearch,
  onTabChange
}) => {
  return <div className={style["vessel-filter"]}>
    <span className="filter-label">Filter</span>
    <div className="filter-container">
      <FleetInput
        value={undefined}
        prefix="LayoutSearchIcon"
        placeholder="Please enter"
        onChange={onSearch}
      />
      <FleetPortTabs
        className="tabs"
        value={activeIndex}
        list={
          [
            {
              label: "Basic",
              value: "basic"
            },
            {
              label: "Calculation",
              value: "calculation"
            }
          ]
        }
        onChange={onTabChange}
      />
    </div>
  </div>
}

export default VesselFilter