import React from "react";
import classnames from "classnames";
import style from "./index.module.less";
import { CBPaymentTermsFormProps, CargoUnitType, FreightType } from "./type";
import { FleetFormItem, FleetInput, FleetVerifyInput } from "@/components";
import { CargoBookLabelMap } from "@/types/voyageManage/cargoBook";
import { FleetTabbar } from "@/components/Tabs";
import NoSelect from "@/common/NoSelect";

const CBCargoInfoForm: React.FC<CBPaymentTermsFormProps> = ({
  item,
  onChange,
  onCommit
}) => {
  return <div className={classnames(style["cargoBook-cargoInfo"], "headFixture-form")}>
    <span className="vm-common-title">Cargo Info.</span>
    <div className="form-container">
      <FleetVerifyInput
        label={CargoBookLabelMap["cargoName"]}
        needVerity
        value={item?.cargoName}
        onChange={(val: string) => {
          onChange?.("cargoName", val)
        }}
      />
      <FleetFormItem
        label={CargoBookLabelMap["cargoQuantity"]}
        needVerity
      >
        <div className="cargoInfo-formItem">
          <FleetInput
            value={item?.cargoQuantity}
            type="number"
            className="formItem-input"
            onChange={(val: number) => {
              onChange?.("cargoQuantity", val)
            }}
          />
          <FleetTabbar
            disabled
            list={["MT", "CBM"]}
            value={item?.cargoUnit}
            onChange={(val: CargoUnitType) => {
              onChange?.("cargoUnit", val)
              // onChange?.(`portRotationList/${form.id}/cargoUnit`, val)
            }}
          />
        </div>
      </FleetFormItem>
      <FleetFormItem
        label={CargoBookLabelMap["freightRate"]}
        needVerity
      >
        <div className="cargoInfo-formItem">
          <FleetInput
            value={item?.freightRate}
            type="number"
            className="formItem-input"
            onChange={(val: number) => {
              onChange?.("freightRate", val)
            }}
          />
          <FleetTabbar
            // disabled
            list={["FR", "LP"]}
            value={item?.freightType}
            onChange={(val: FreightType) => {
              onChange?.("freightType", val)
              // onChange?.(`portRotationList/${form.id}/cargoUnit`, val)
            }}
          />
        </div>
      </FleetFormItem>
      <FleetVerifyInput
        value={item?.commission}
        onChange={(val: number) => {
          onChange?.("commission", val);
        }}
        type="number"
        label={CargoBookLabelMap["commission"]}
      />
      <FleetFormItem
        label={CargoBookLabelMap["brokerName"]}
      >
        <NoSelect
          initItem={[{
            value: (item?.brokerId) as string,
            label: item?.brokerName
          }]}
          label="companyName"
          type="company"
          onFinish={(noItem, newNoItem, type) => {
            onCommit?.({
              brokerId: noItem?.value,
              brokerName: noItem?.label
            })
          }}
        />
      </FleetFormItem>
      <FleetVerifyInput
        value={item?.brokerage}
        onChange={(val: number) => {
          onChange?.("brokerage", val);
        }}
        type="number"
        label={CargoBookLabelMap["brokerage"]}
      />
    </div>
  </div>
}

export default CBCargoInfoForm