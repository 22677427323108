import dayjs, { Dayjs } from "dayjs";
import utc from "dayjs/plugin/utc";
dayjs.extend(utc);

export const generateDates = () => {
	const currentUtcDay = new Date().getUTCDate();
	const currentDay = dayjs().set("date", currentUtcDay)?.startOf("day");
	const currentDates = [currentDay];
	for (let i = 1; i <= 9; i++) {
		currentDates?.push(currentDay?.add(i, "day")?.startOf("day"));
	}
	return currentDates;
};

export const getCurrentTimeStartOfHours = (currentTime: Dayjs) => {
	const startOfMinute = currentTime?.startOf("minute"),
		halfStartOfMinut = currentTime?.startOf("minute")?.set("minute", 30),
		startOfHour = currentTime?.startOf("hour");

	return startOfMinute?.isAfter(halfStartOfMinut) ? startOfHour?.add(1, "hour") : startOfHour;
};

export const calcTimeLineOffset = (time: Dayjs, toUtc?: boolean) => {
	const timeAreas = generateDates();
	const currentTime = toUtc
		? dayjs.utc(getCurrentTimeStartOfHours(time))
		: getCurrentTimeStartOfHours(time);
	const currentIndex = timeAreas?.findIndex((item, index) => {
		return currentTime?.get("date") === item?.get("date");
	});
	const timeOffset = currentIndex === -1 ? 0 : currentTime?.get("hour"),
		dateOffset = currentIndex === -1 ? 10 : currentIndex,
		resTime = currentIndex === -1 ? timeAreas?.at(-1)?.startOf("day")?.add(1, "day") : currentTime;
	console.log("calcTimeLineOffset", {
		timeAreas,
		currentIndex,
		time,
		currentTime,
		timeOffset,
		dateOffset,
		resTime
	});
	return {
		dateOffset,
		timeOffset,
		currentTime: resTime
	};
};
