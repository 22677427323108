import { addTaskAction, getTaskAction, updateTaskAction } from "@/action/dataBase/task";
import { DatabaseTaskItemType, initialTaskItem } from "@/featrue/database/task/edit/type";
import { useAppDispatch, useCommonIndex } from "@/hook";
import useReminder from "@/hook/useReminder";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { checkTaskKeys } from "@/featrue/database/task/edit/type";
const useEditTask = (type: "add" | "edit", taskId?: string | number, onReset?: () => void) => {
	const dispatch = useAppDispatch();
	const navigate = useNavigate();
	const { t } = useTranslation();
	const { reminder } = useReminder();
	const [loading, setLoading] = useState(false);

	const { dataSource, change, commit, check, init } = useCommonIndex<DatabaseTaskItemType>(
		initialTaskItem,
		checkTaskKeys
	);

	const clear = () => {
		init(null);
	};

	const addTaskFront = () => {
		setLoading(true);
	};

	const addTaskSuccess = (response) => {
		reminder("success", response?.msg);
		setLoading(false);
		onReset?.();
	};

	const addTaskError = (error) => {
		reminder("error", error?.msg ? error?.msg + ": " + error?.data : error?.data);
		setLoading(false);
	};

	const addTask = () => {
		const { checkKey, checked } = check();
		if (!checked) {
			reminder(
				"error",
				`Field ${t(`dataBase.task.${checkKey}`, {
					defaultValue: t(`common.${checkKey}`)
				})} is required`
			);
			return;
		}
		addTaskAction(
			{ ...dataSource },
			addTaskFront,
			addTaskSuccess,
			addTaskError,
			dispatch,
			navigate
		);
	};

	const editTask = () => {
		updateTaskAction(
			{ ...dataSource, id: taskId },
			addTaskFront,
			addTaskSuccess,
			addTaskError,
			dispatch,
			navigate
		);
	};

	const getTaskSuccess = (response) => {
		init(response?.data);
		setLoading(false);
	};

	const getTask = () => {
		if (!taskId || type === "add") return;
		getTaskAction({ id: taskId }, addTaskFront, getTaskSuccess, addTaskError, dispatch, navigate);
	};

	const handleEditTask = () => {
		if (type === "edit" && taskId) {
			editTask();
			return;
		}
		addTask();
	};

	return {
		loading,
		dataSource,
		change,
		commit,
		clear,
		handleEditTask,
		getTask
	};
};

export default useEditTask;
