import React, { FC, useEffect } from "react";
import classnames from "classnames";
import style from "./index.module.less";
import { AreaCountrySelectProps } from "./type";
import { FleetInput, FleetLoading, FleetRangePicker, FleetSelect } from "@/components";
import useIndex from "./hooks/useIndex";

const AreaCountrySelect: FC<AreaCountrySelectProps> = ({ value, showKey, onCommit, disabled }) => {
	const {
		loading,
		codeOptions,
		handleFocusPopup,
		handleSearch,
		searchValue,
		setSearchValue,
		loadMore,
		getCodeList
	} = useIndex(showKey);
	useEffect(() => {
		if (value && codeOptions.length == 0) {
			getCodeList();
		}
	}, [value]);
	return (
		<FleetSelect
			showSearch
			value={value}
			options={codeOptions}
			className={classnames(style["area-country-select"])}
			onChange={(val: number | string) => {
				const result = codeOptions.find((item) => item.value == val).label;
				setSearchValue(result);
				onCommit?.(showKey == "area" ? { area: val as number } : { country: val as string });
			}}
			disabled={disabled}
			allowClear
			onClear={() => {
				onCommit?.({ area: null, country: null });
			}}
			onSearch={handleSearch}
			searchValue={searchValue}
			onFocus={() => {
				handleFocusPopup();
			}}
			dropdownRender={(menu) => (
				<>
					<FleetLoading loading={loading} />
					{menu}
					{showKey == "country" && (
						<span className={classnames(style["areaCountrySelect-loadmore"])} onClick={loadMore}>
							Load More
						</span>
					)}
				</>
			)}
		/>
	);
};

export default AreaCountrySelect;
