import { useEffect, useRef, useState } from "react";

const useInfinityScroll = (hasMore: boolean, loadMore?: () => void) => {
	const scrollRef = useRef<HTMLDivElement>(null);
	const loadDataing = useRef<boolean>(false);

	const handleScroll = (event) => {
		const { scrollTop, clientHeight, scrollHeight } = event?.currentTarget;
		console.log("scrollTop", event, event?.currentTarget);
		debugger;

		if (Math.ceil(scrollTop + clientHeight) < scrollHeight) return;

		if (loadDataing.current || !hasMore) return;

		loadMore?.();
	};

	const scrollRefInit = () => {
		scrollRef.current?.addEventListener("scroll", handleScroll);
		return () => {
			scrollRef.current?.removeEventListener("scroll", handleScroll);
		};
	};

	const changeloadDataing = (val: boolean) => {
		loadDataing.current = val;
	};

	useEffect(() => {
		return scrollRefInit();
	}, [hasMore, loadMore]);

	return {
		scrollRef,
		handleScroll,
		changeloadDataing
	};
};

export default useInfinityScroll;
