import { useState } from "react";
import { changeAvatarParams } from "@/api/personalCenter/type";
import useReminder from "@/hook/useReminder";
import { useAppDispatch } from "@/hook";
import { useNavigate } from "react-router-dom";
import { changeAvatarAction } from "@/action/personalCenter/personalCenter";

const useIndex = () => {
  const navigate = useNavigate();
  const { reminder } = useReminder();
  const dispatch = useAppDispatch();
  const [loading, setLoading] = useState(false);
  const [imageUrl, setUrl] = useState<string>();

  const setImageUrl = (url) => {
    console.log(url);
  };
  const uploadSuccess = (res) => {
    reminder("success", "Upload Avatar Success");
    setLoading(false);
    setImageUrl(res);
  };
  const uploadError = (error) => {
    reminder(
      "error",
      error?.msg ? error?.msg + ": " + error?.data : error?.data
    );
    setLoading(false);
  };
  const handleUpload = (files) => {
    const formData = new FormData();
    formData.append("avatarfile", files);
    changeAvatarAction(
      formData,
      () => {
        setLoading(true);
      },
      uploadSuccess,
      uploadError,
      dispatch,
      navigate
    );
  };
  const handleChange = (info) => {
    setLoading(false);
    handleUpload(info);
    return false;
  };
  return {
    loading,
    handleChange,
    imageUrl,
    handleUpload,
  };
};
export default useIndex;
