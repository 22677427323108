import { type ApiParameter } from "@/api";
import request from "@/service";
import {
	getVoyageDetailParams,
	getVoyageGroupParams,
	addVoyageParams,
	toMovementParams,
	getVoyageAttachParams,
	deleteVoyageAttactParams,
	uploadVoyageAttachParams,
	saveVoyageRemarkParams,
	queryCargoBookGroupParams,
	queryHeadFixtureGroupParams,
	getVoyageContactGroupParams,
	getVoyageContactParams,
	deleteVoyageContactParams,
	addVoyageContactParams,
	deleteVoyageParams
} from "./type";
import { appVersion } from "@/api/constant";

const enum ApiMap {
	getVoyageGroupParameter,
	getVoyageDetailParameter,
	addVoyageParameter,
	editVoyageParameter,
	toMovementParameter,
	getVoyageAttachParameter,
	uploadVoyageAttachParameter,
	deleteVoyageAttactParameter,
	saveVoyageRemarkParameter,
	queryHeadFixtureGroupParameter,
	queryCargoBookGroupParameter,
	getVoyageContactGroupParameter,
	getVoyageContactDetailParameter,
	deleteVoyageContactParameter,
	addVoyageContactParameter,
	queryVoyageContactSelectParameter,
	deleteVoyageParameter
}

type ApiUrl = keyof typeof ApiMap;

const voyageApi: ApiParameter<ApiUrl> = {
	getVoyageGroupParameter: {
		method: "get",
		url: appVersion + "/pre/voyage/search"
	},
	getVoyageDetailParameter: {
		method: "get",
		url: appVersion + "/pre/voyage"
	},
	addVoyageParameter: {
		method: "post",
		url: appVersion + "/pre/voyage"
	},
	editVoyageParameter: {
		method: "put",
		url: appVersion + "/pre/voyage"
	},
	toMovementParameter: {
		method: "put",
		url: appVersion + "/pre/voyage/move"
	},
	deleteVoyageAttactParameter: {
		method: "delete",
		url: appVersion + "/pre/voyage/attach"
	},
	getVoyageAttachParameter: {
		method: "get",
		url: appVersion + "/pre/voyage/attachment"
	},
	uploadVoyageAttachParameter: {
		method: "post",
		url: appVersion + "/pre/voyage/attach"
	},
	saveVoyageRemarkParameter: {
		method: "put",
		url: appVersion + "/pre/voyage/remark"
	},
	queryCargoBookGroupParameter: {
		method: "get",
		url: appVersion + "/pre/cargo-book/search"
	},
	queryHeadFixtureGroupParameter: {
		method: "get",
		url: appVersion + "/pre/head-fixture/search"
	},
	getVoyageContactGroupParameter: {
		method: "get",
		url: appVersion + "/vm/contact/voyage"
	},
	getVoyageContactDetailParameter: {
		method: "get",
		url: appVersion + "/vm/contact"
	},
	deleteVoyageContactParameter: {
		method: "delete",
		url: appVersion + "/vm/contact"
	},
	addVoyageContactParameter: {
		method: "post",
		url: appVersion + "/vm/contact"
	},
	queryVoyageContactSelectParameter: {
		method: "get",
		url: appVersion + "/vm/contact/type/company/con"
	},
	deleteVoyageParameter: {
		method: "delete",
		url: appVersion + "/pre/voyage"
	}
};

export const getVoyageGroupApi = (params: getVoyageGroupParams) => {
	return request.request({
		...voyageApi.getVoyageGroupParameter,
		params
	});
};

export const getVoyageDetailApi = (params: getVoyageDetailParams) => {
	return request.request({
		...voyageApi.getVoyageDetailParameter,
		url: `${voyageApi.getVoyageDetailParameter.url}/${params?.voyageId}`
	});
};

export const addVoyageApi = (params: addVoyageParams) => {
	return request.request({
		...voyageApi.addVoyageParameter,
		data: params
	});
};

export const editVoyageApi = (params: addVoyageParams) => {
	return request.request({
		...voyageApi.editVoyageParameter,
		data: params
	});
};

export const toMovementApi = (params: toMovementParams) => {
	return request.request({
		...voyageApi.toMovementParameter,
		url: `${voyageApi.toMovementParameter.url}/${params?.voyageId}`
	});
};

export const getVoyageAttachApi = (params: getVoyageAttachParams) => {
	return request.request({
		...voyageApi.getVoyageAttachParameter,
		url: `${voyageApi?.getVoyageAttachParameter.url}/${params?.voyageId}`
	});
};

export const deleteVoyageAttactApi = (params: deleteVoyageAttactParams) => {
	return request.request({
		...voyageApi.deleteVoyageAttactParameter,
		url: `${voyageApi?.deleteVoyageAttactParameter.url}/${params?.id}`
	});
};

export const uploadVoyageAttachApi = (params: uploadVoyageAttachParams) => {
	return request.request({
		...voyageApi.uploadVoyageAttachParameter,
		url: `${voyageApi.uploadVoyageAttachParameter.url}/${params?.voyageId}`,
		data: params?.files
	});
};

export const saveVoyageRemarkApi = (params: saveVoyageRemarkParams) => {
	return request.request({
		...voyageApi.saveVoyageRemarkParameter,
		data: params
	});
};

export const queryCargoBookGroupApi = (params: queryCargoBookGroupParams) => {
	return request.request({
		...voyageApi.queryCargoBookGroupParameter,
		params
	});
};

export const queryHeadFixtureGroupApi = (params: queryHeadFixtureGroupParams) => {
	return request.request({
		...voyageApi.queryHeadFixtureGroupParameter,
		params
	});
};

export const getVoyageContactGroupApi = (params: getVoyageContactGroupParams) => {
	return request.request({
		...voyageApi.getVoyageContactGroupParameter,
		url: `${voyageApi.getVoyageContactGroupParameter.url}/${params?.id}`
	});
};

export const getVoyageContactDetailApi = (params: getVoyageContactParams) => {
	return request.request({
		...voyageApi.getVoyageContactDetailParameter,
		url: `${voyageApi.getVoyageContactDetailParameter.url}/${params?.id}`
	});
};

export const deleteVoyageContactApi = (params: deleteVoyageContactParams) => {
	return request.request({
		...voyageApi.deleteVoyageContactParameter,
		url: `${voyageApi.deleteVoyageContactParameter.url}/${params?.id}`
	});
};

export const addVoyageContactApi = (params: addVoyageContactParams) => {
	return request.request({
		...voyageApi.addVoyageContactParameter,
		data: params
	});
};

export const queryVoyageContactSelectApi = () => {
	return request.request({
		...voyageApi.queryVoyageContactSelectParameter
	});
};

export const deleteVoyageApi = (params: deleteVoyageParams) => {
	return request.request({
		...voyageApi.deleteVoyageParameter,
		url: `${voyageApi.deleteVoyageParameter.url}/${params?.id}`
	});
};
