import React, { FC } from "react";
import style from "./index.module.less";
import { RouteAreaSelectListProps } from "./type";
import classnames from "classnames";
import { RouteAreaItem } from "../item/module";

const RouteAreaSelectList: FC<RouteAreaSelectListProps> = ({ items, onDelele, onSelect }) => {
	return (
		<div className={style["route-areaSelectList"]}>
			<div className="areaSelectList-header">
				<span>Selected（{items?.length}）</span>
				<span
					className={classnames({
						"fleet-disabled": items?.length <= 0
					})}
					onClick={() => {
						onDelele?.("multiple");
					}}
				>
					Clear all
				</span>
			</div>
			<div className="areaSelectList-container">
				{items?.map((item, index) => {
					return (
						<RouteAreaItem
							onSelect={onSelect}
							mode="selectRows"
							key={index}
							item={item}
							onDelete={onDelele}
						/>
					);
				})}
			</div>
		</div>
	);
};

export default RouteAreaSelectList;
