import { ColumnsType } from "antd/es/table";
import { RoutePlanItemType } from "./type";
import dayjs from "dayjs";
import React from "react";
import { dateToUtcString } from "@/tools/date";
import { formatThousandthNumber } from "@/tools/amount";

export const columns: ColumnsType<RoutePlanItemType> = [
	{
		title: "Created Date",
		dataIndex: "createDate",
		width: 137,
		fixed: "left",
		ellipsis: true,
		render: (value, record, index) => {
			return <span>{dayjs(value)?.format("YYYY-MM-DD HH:mm")}</span>;
		}
	},
	{
		title: "Plan Type",
		dataIndex: "planType",
		width: 130,
		ellipsis: true
	},
	{
		title: "TTL Dist (nm)",
		dataIndex: "ttlDist",
		width: 100,
		ellipsis: true,
		render(value, record, index) {
			return <span>{formatThousandthNumber(value)}</span>;
		}
	},
	{
		title: "CP SPD (kts)",
		dataIndex: "cpSpeed",
		width: 100,
		ellipsis: true,
		render(value, record, index) {
			return <span>{formatThousandthNumber(value)}</span>;
		}
	},
	{
		title: "CP FO Cons(mt)",
		dataIndex: "cpFoCons",
		width: 159,
		ellipsis: true,
		render(value, record, index) {
			return <span>{formatThousandthNumber(value, 3, 3)}</span>;
		}
	},
	{
		title: "CP DGO Cons(mt)",
		dataIndex: "cpDgoCons",
		width: 159,
		ellipsis: true,
		render(value, record, index) {
			return <span>{formatThousandthNumber(value, 3, 3)}</span>;
		}
	},
	{
		title: "Departure",
		dataIndex: "departure",
		width: 118,
		ellipsis: true
	},
	{
		title: "ETD/ATD (UTC)",
		dataIndex: "etd",
		width: 137,
		ellipsis: true,
		render: (value, record, index) => {
			return <span>{dateToUtcString(record?.atd || record?.etd)?.slice(0, -3)}</span>;
		}
	},
	{
		title: "Arrival",
		dataIndex: "arrival",
		width: 137,
		ellipsis: true
	},
	{
		title: "ETA(UTC) ",
		dataIndex: "eta",
		width: 137,
		ellipsis: true,
		render: (value, record, index) => {
			return <span>{dateToUtcString(value)?.slice(0, -3)}</span>;
		}
	},
	{
		title: "AVG SPD (kts)",
		dataIndex: "avgSpeed",
		width: 122,
		ellipsis: true,
		render(value, record, index) {
			return <span>{formatThousandthNumber(value)}</span>;
		}
	},
	{
		title: "TTL FO Cons (mt) ",
		dataIndex: "ttlFoCons",
		width: 159,
		ellipsis: true,
		render(value, record, index) {
			return <span>{formatThousandthNumber(value, 3, 3)}</span>;
		}
	},
	{
		title: "TTL DGO Cons (mt)",
		dataIndex: "ttlDgoCons",
		width: 159,
		ellipsis: true,
		render(value, record, index) {
			return <span>{formatThousandthNumber(value, 3, 3)}</span>;
		}
	}
];
