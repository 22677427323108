import React, { useEffect, useMemo } from "react";
import style from "./index.module.less";
import { DetailDelaysPropsType } from "./type";
import {} from "@/common";
import { FleetInput } from "@/components";
import useIndex from "./hook/useIndex";
import { detailDelaysTableConfig } from "./source";
import { FleetTimePicker } from "@/components/DatePicker";
import dayjs from "dayjs";
const DetailDelays: React.FC<DetailDelaysPropsType> = ({
  detailDelaysData,
  onChange,
  onAdd,
  onDelete,
}) => {
  const {
    detailDelaysTableDisabled,
    onMouse,
    handleAdd,
    handleDelete,
    cellValue,
    first,
    setFirst,
    setDetailDelaysTableDisabled
  } = useIndex(onAdd, onDelete,detailDelaysData);
  useEffect(() => {
    if (detailDelaysData.length != 0 && first){
      const rows = [];
      detailDelaysData.map((item, index) => {
        rows.push({})
        for (let [k, v] of Object.entries(item)) {
          rows[index][k] = true;
        }
      });
      setFirst(false)
      setDetailDelaysTableDisabled(rows);
    }
  }, [detailDelaysData]);
  const totalSum = useMemo(() => {
    const sums  = detailDelaysData.reduce(
      (acc, ele) =>{
        const diffInDays = (ele.toTime - ele.fromTime)/1000/3600/24
        return acc + diffInDays
      },
      0
    );
    return sums.toFixed(2);
  }, [detailDelaysData]);
  return (
    <div className={style["portReport-detailDelays"]}>
      <div className="port-report-title">DETAIL ALL DELAYS</div>
      <div className="detail-delays-table">
        <table cellSpacing={0}>
          <thead className="detail-delays-table-thead">
            <tr>
              {detailDelaysTableConfig.map((item, index) => {
                return (
                  <th
                    colSpan={item.colSpan}
                    className="detail-delays-table-header"
                    key={index}
                  >
                    {item.label}
                  </th>
                );
              })}
            </tr>
          </thead>
          <tbody className="detail-delays-table-tbody">
            {detailDelaysData?.map((row, index) => {
              // console.log(row);
              return (
                <tr key={index}>
                  {detailDelaysTableConfig.map((item, idx) => {
                    return (
                      <td colSpan={item.colSpan} key={idx}>
                        <div
                          onMouseEnter={() =>
                            onMouse(index, item.prop, "enter")
                          }
                          onMouseLeave={() =>
                            onMouse(index, item.prop, "leave")
                          }
                          className={
                            item.prop === "sno"
                              ? "detail-delays-table-tbody-center"
                              : ""
                          }
                        >
                          {!detailDelaysTableDisabled[index]?.[item.prop] &&
                          !["action", "sno"].includes(item.prop) ? (
                            ["fromTime", "toTime"].includes(item.prop) ? (
                              <FleetTimePicker
                                allowClear
                                value={dayjs(row?.[item.prop]) || null}
                                onChange={(val: string) => {
                                  onChange?.(
                                    dayjs(val).valueOf(),
                                    index,
                                    item.prop
                                  );
                                }}
                              ></FleetTimePicker>
                            ) : (
                              <FleetInput
                                value={row?.[item.prop]}
                                onChange={(val) =>
                                  onChange(val, index, item.prop)
                                }
                              ></FleetInput>
                            )
                          ) : item.prop == "action" ? (
                            <div className="detail-delays-table-action">
                              <span
                                className="font_family fleet-delete"
                                onClick={() => handleDelete(index)}
                              ></span>
                            </div>
                          ) : (
                            cellValue(row, item.prop) || "--"
                          )}
                        </div>
                      </td>
                    );
                  })}
                </tr>
              );
            })}
            {/* <tr>
              <td colSpan={12}>
                <span className="port-report-add" onClick={handleAdd}>
                  + ADD
                </span>
              </td>
            </tr> */}
            <tr className="detail-delays-table-blank"></tr>
          </tbody>
          <tfoot className="detail-delays-table-tfoot">
            <tr>
              <td colSpan={1}>Total</td>
              <td colSpan={4}>{`${totalSum} Days`}</td>
              <td colSpan={7}></td>
            </tr>
          </tfoot>
        </table>
      </div>
    </div>
  );
};

export default DetailDelays;
