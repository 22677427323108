import React, { FC, useMemo, useState } from "react";
import style from "./index.module.less";
import { EstCargoAndPortProps } from "./type";
import { FleetFormItem, FleetInput, FleetVerifyInput } from "@/components";
import { useTranslation } from "react-i18next";
import { Button } from "@/_components";
import { FleetTabbar } from "@/components/Tabs";
import { CargoUnitType, FreightType } from "@/types/common";
import { isBallast, isLaden } from "@/tools/port";
import { EstPortRotation } from "@/pages/estimation/components";
import { CommonPortSelect } from "@/common";

const EstCargoAndPort: FC<EstCargoAndPortProps> = ({
	portDisabled,
	vesselItem,
	cargoItem,
	portItems,
	otherItem,
	calculate,
	onCargoItemChange,
	onPortRotationConfirm,
	onCargoItemCommit,
	onPortItemAdd,
	onPortItemDelete,
	onStowagePlanOpen,
	onOtherItemChange
}) => {
	const { t } = useTranslation();
	const [currentType, setCurrentType] = useState<"ballast" | "laden">("ballast");
	const [hide, setHide] = useState(true);

	const handleOpen = (type: "ballast" | "laden") => {
		setCurrentType(type);
		setHide(false);
	};

	const portCategory = useMemo(() => {
		const ballastPortList = portItems?.filter((portItem) => isBallast(portItem?.purpose));
		const ladenPortList = portItems?.filter((portItem) => isLaden(portItem?.purpose));

		return {
			ballast: ballastPortList,
			laden: ladenPortList
		};
	}, [portItems]);

	return (
		<div className={style["estDetails-cargoAndPort"]}>
			<div className="cargoAndPort-header">
				<span className="details-title">Cargo & Port Rotation</span>
			</div>
			<div className="cargoAndPort-container">
				<div className="cargoAndPort-item thirty-seven">
					<FleetVerifyInput
						label={t("common.cargoName")}
						placeholder={t("common.cargoName")}
						value={cargoItem?.cargoName}
						onChange={(val: string) => {
							onCargoItemChange?.("cargoName", val);
						}}
					/>
					<Button onClick={onStowagePlanOpen} type="primary" size="small">
						Stowage Plan
					</Button>
				</div>
				<div className="cargoAndPort-item thirty-unequal">
					<FleetVerifyInput
						label={t("common.cargoQuantity")}
						placeholder="example"
						value={cargoItem?.cargoQuantity}
						type="number"
						onChange={(val: number) => {
							onCargoItemChange?.("cargoQuantity", val);
						}}
					/>
					<FleetTabbar
						list={["MT", "CBM"]}
						value={cargoItem?.cargoUnit}
						disabled
						onChange={(val: CargoUnitType) => {
							onCargoItemChange?.("cargoUnit", val);
						}}
					/>
					<FleetInput
						type="number"
						placeholder={t("common.cargoQuantityDiscrepancy")}
						min={0}
						max={100}
						value={cargoItem?.cargoQuantityDiscrepancy}
						onChange={(val: number) => {
							onCargoItemChange?.("cargoQuantityDiscrepancy", val);
						}}
					/>
				</div>
				<div className="cargoAndPort-item fourty-unequal">
					<FleetVerifyInput
						label={t("common.freightRate")}
						placeholder="example"
						needVerity
						value={cargoItem?.freightRate}
						type="number"
						onChange={(val: number) => {
							onCargoItemChange?.("freightRate", val);
						}}
					/>
					<FleetTabbar
						value={cargoItem?.freightType}
						list={["FR", "LP"]}
						onChange={(val: FreightType) => {
							onCargoItemChange?.("freightType", val);
						}}
					/>
					<FleetVerifyInput
						label={t("common.commission")}
						value={cargoItem?.commission}
						type="number"
						onChange={(val: number) => {
							onCargoItemChange?.("commission", val);
						}}
					/>
					<FleetVerifyInput
						label={t("common.brokerage")}
						value={cargoItem?.brokerage}
						type="number"
						onChange={(val: number) => {
							onCargoItemChange?.("brokerage", val);
						}}
					/>
				</div>
				<div className="cargoAndPort-item ninety">
					<FleetFormItem needVerity label="Ballast Port Rotation">
						<CommonPortSelect
							showCount={3}
							activeItems={portCategory?.ballast}
							disabled={portDisabled}
							primaryKey="portCode"
							showKey="portName"
							onFinish={(newItem, type) => {
								switch (true) {
									case type === "add":
										onPortItemAdd?.("ballast", {
											portCode: newItem?.portCode,
											portName: newItem?.portName,
											purpose: "LD"
										});
										break;
									case type === "del":
										const currentIndex = portItems?.findIndex(
											(item) => item?.portCode === newItem?.portCode
										);
										onPortItemDelete?.(currentIndex);
										break;
									default:
										break;
								}
							}}
						/>
					</FleetFormItem>
					<Button
						type="icon"
						disabled={portDisabled}
						onClick={() => {
							handleOpen("ballast");
						}}
					>
						<span className="font_family fleet-port"></span>
					</Button>
				</div>
				<div className="cargoAndPort-item thirty-thirty-unequal">
					<FleetVerifyInput
						value={otherItem?.ballastMarginDistance}
						type="number"
						min={0}
						max={100}
						label="Dis Margin(%)"
						disabled={otherItem?.ballastMarginFlag === 1}
						onChange={(val: number) => {
							onOtherItemChange?.("ballastMarginDistance", val);
						}}
					/>
					<FleetVerifyInput
						value={otherItem?.ballastMarginDay}
						type="number"
						label="Time Margin(Day)"
						disabled={otherItem?.ballastMarginFlag === 0}
						onChange={(val: number) => {
							onOtherItemChange?.("ballastMarginDay", val);
						}}
					/>
					<FleetTabbar
						value={otherItem?.ballastMarginFlag === 0 ? "Dis" : "Day"}
						list={["Dis", "Day"]}
						onChange={(val: "Dis" | "Day") => {
							onOtherItemChange?.("ballastMarginFlag", val === "Dis" ? 0 : 1);
						}}
					/>
				</div>
				<div className="cargoAndPort-item ninety">
					<FleetFormItem needVerity label="Laden Port Rotation">
						<CommonPortSelect
							showCount={3}
							disabled={portDisabled}
							activeItems={portCategory?.laden}
							primaryKey="portCode"
							showKey="portName"
							onFinish={(newItem, type) => {
								switch (true) {
									case type === "add":
										onPortItemAdd?.("laden", {
											portCode: newItem?.portCode,
											portName: newItem?.portName,
											purpose: "DC"
										});
										break;
									case type === "del":
										const currentIndex = portItems?.findIndex(
											(item) => item?.portCode === newItem?.portCode
										);
										onPortItemDelete?.(currentIndex);
										break;
									default:
										break;
								}
							}}
						/>
					</FleetFormItem>
					<Button
						type="icon"
						disabled={portDisabled}
						onClick={() => {
							handleOpen("laden");
						}}
					>
						<span className="font_family fleet-port"></span>
					</Button>
				</div>
				<div className="cargoAndPort-item thirty-thirty-unequal">
					<FleetVerifyInput
						value={otherItem?.ladenMarginDistance}
						type="number"
						min={0}
						max={100}
						label="Dis Margin(%)"
						disabled={otherItem?.ladenMarginFlag === 1}
						onChange={(val: number) => {
							onOtherItemChange?.("ladenMarginDistance", val);
						}}
					/>
					<FleetVerifyInput
						value={otherItem?.ladenMarginDay}
						type="number"
						label="Time Margin(Day)"
						disabled={otherItem?.ladenMarginFlag === 0}
						onChange={(val: number) => {
							onOtherItemChange?.("ladenMarginDay", val);
						}}
					/>
					<FleetTabbar
						value={otherItem?.ladenMarginFlag === 0 ? "Dis" : "Day"}
						list={["Dis", "Day"]}
						onChange={(val: "Dis" | "Day") => {
							onOtherItemChange?.("ladenMarginFlag", val === "Dis" ? 0 : 1);
						}}
					/>
				</div>
				<div className="cargoAndPort-item fourty-thirty-unequal">
					<FleetVerifyInput
						label="Total Distance (NM)"
						placeholder="Ballast"
						disabled
						value={calculate?.ttlDistanceBallast}
					/>
					<FleetInput placeholder="Laden" value={calculate?.ttlDistanceLaden} disabled />
					<FleetVerifyInput
						placeholder="10.00%"
						label="Margin(NM)"
						type="number"
						disabled
						value={calculate?.totalMarginValue}
					/>
				</div>
				<div className="cargoAndPort-item two-equal">
					<FleetVerifyInput
						label="Inside ECA (NM)"
						disabled
						placeholder="Ballast"
						value={calculate?.ecaDistanceBallast}
					/>
					<FleetInput placeholder="Laden" value={calculate?.ecaDistanceLaden} disabled />
				</div>
			</div>
			<EstPortRotation
				vesselSource={{
					portFuelIdle: vesselItem?.portFuelIdle,
					portFuelMdo: vesselItem?.portFuelMdo,
					portFuelWork: vesselItem?.portFuelWork,
					portIdleMdo: vesselItem?.portIdleMdo,
					portWorkMdo: vesselItem?.portWorkMdo,
					deliveryPortCode: vesselItem?.deliveryPortCode,
					deliveryPortId: vesselItem?.deliveryPortId,
					deliveryPortName: vesselItem?.deliveryPortName,
					ballastSpeed: vesselItem?.ballastSpeed,
					ladenSpeed: vesselItem?.ladenSpeed
				}}
				cargoSource={{
					cargoQuantity: cargoItem?.cargoQuantity
				}}
				onConfirm={onPortRotationConfirm}
				portType={currentType}
				items={portItems}
				hide={hide}
				onClose={() => {
					setHide(true);
				}}
			/>
		</div>
	);
};

export default EstCargoAndPort;
