import React, { FC, useState } from "react";
import style from "./index.module.less";
import { FleetLoading } from "@/components";
import FleetTabs from "@/components/Tabs";
import { biInsightsTabs } from "./source";
import { BiInsightsTabType } from "./type";

const BiInsights: FC<{}> = (props) => {
	console.log("presss", process.env.BI_VOYAGE_SUMMARY_URL);
	const [loading, setLoading] = useState(true);
	const [activeTab, setActiveTab] = useState<BiInsightsTabType>("voyageSummary");
	return (
		<div className={style["fleet-biInsights"]}>
			<FleetTabs
				className="biInsights-tabs"
				list={biInsightsTabs}
				value={activeTab}
				onClick={(value: BiInsightsTabType) => {
					setLoading(true);
					setActiveTab(value);
				}}
			/>

			<div className="biInsights-wrapper">
				<FleetLoading loading={loading} />
				<iframe
					src={
						activeTab === "voyageSummary"
							? process.env.BI_VOYAGE_SUMMARY_URL
							: process.env.BI_PORT_SUMMARY_URL
					}
					frameBorder="0"
					allowFullScreen
					onLoad={(event) => {
						setLoading(false);
					}}
				></iframe>
			</div>
		</div>
	);
};

export default BiInsights;
