import React from "react";
import style from "./index.module.less";
import { DatabasePortFilterProps, PortType } from "./type";
import { FleetFormItem, FleetInput, FleetSelect, FleetVerifyInput } from "@/components";

const PortFilter: React.FC<DatabasePortFilterProps> = ({
  item,
  onSearch,
  onChange
}) => {
  return <div className={style["port-filter"]}>
    <FleetVerifyInput
      value={undefined}
      prefix="LayoutSearchIcon"
      placeholder="Please enter"
      onChange={onSearch}
      label="Filter"
      containerClassname="first-filter"
    />
    <FleetVerifyInput
      value={item?.country}
      prefix="LayoutSearchIcon"
      // placeholder="Country"
      label="Country"
      onChange={(val: string) => onChange?.("country", val)}
      containerClassname="other-filter"
    />
    <FleetFormItem label="Port Type">
      <FleetSelect
        value={[item.portType]}
        popupMatchSelectWidth={false}
        allowClear
        className="port-select other-filter"
        placeholder="Port Type"
        options={[
          {
            label: "Port",
            value: "P"
          },
          {
            label: "Transit",
            value: "T",
          },
          {
            label: "Customize",
            value: "C",
          }
        ]}
        onChange={(val: PortType) => {
          if (!item?.portType && !val) return
          onChange?.("portType", val)
        }}
      />
    </FleetFormItem>
    {/* <span className="filter-label">Filter</span>
    <div className="filter-container">
      <FleetInput
        value={undefined}
        prefix="LayoutSearchIcon"
        placeholder="Please enter"
        onChange={onSearch}
      />
      <FleetInput
        value={item?.country}
        prefix="LayoutSearchIcon"
        placeholder="Country"
        onChange={(val: string) => onChange?.("country", val)}
      />
      <FleetSelect
        value={[item.portType]}
        popupMatchSelectWidth={false}
        allowClear
        className="port-select"
        placeholder="Port Type"
        options={[
          {
            label: "Port",
            value: "P"
          },
          {
            label: "Transit",
            value: "T",
          },
          {
            label: "Customize",
            value: "C",
          }
        ]}
        onChange={(val: PortType) => {
          if (!item?.portType && !val) return
          onChange?.("portType", val)
        }}
      />
    </div> */}
  </div>
}

export default PortFilter