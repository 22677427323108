import { ApiParameter, RequestApiMapType } from "@/api";
import request from "@/service";

import {
	RouteCommonPortCallListParamsType,
	getRouteCommonPortCallListParams,
	downloadRouteCommonPortCallParams
} from "./type";
import { appVersion } from "@/api/constant";

const enum ApiMap {
	getRouteCommonPortCallListParameter,
	downloadRouteCommonPortCallParameter
}

export type ApiUrl = keyof typeof ApiMap;

const areaVesselApi: ApiParameter<ApiUrl> = {
	getRouteCommonPortCallListParameter: {
		method: "get",
		url: appVersion + "/moni/ship/history"
	},
	downloadRouteCommonPortCallParameter: {
		method: "post",
		url: appVersion + "/moni/ship/download/history"
	}
};

export const getRouteCommonPortCallListApi = (params: getRouteCommonPortCallListParams) => {
	return request.request({
		...areaVesselApi.getRouteCommonPortCallListParameter,
		params
	});
};

export const downloadRouteCommonPortCallApi = (params: downloadRouteCommonPortCallParams) => {
	return request.request({
		...areaVesselApi.downloadRouteCommonPortCallParameter,
		data: params,
		responseType: "blob"
	});
};

export const portCallApiMap: RequestApiMapType<ApiUrl, RouteCommonPortCallListParamsType> = {
	getRouteCommonPortCallListApi,
	downloadRouteCommonPortCallApi
};
