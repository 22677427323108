import { useAppDispatch } from "@/hook";
import useOtherIndex from "./useOtherIndex";
import { useNavigate } from "react-router-dom";
import useReminder from "@/hook/useReminder";
import { useEffect, useRef, useState } from "react";
import { useStatementBaseIndex, useStatementDescIndex } from "../components";
import {
	InvStatementFieldOpenEvent,
	InvStatementFieldType,
	InvStatementFirstInitItemType,
	InvStatementInitItemType,
	InvStatementProps
} from "../type";
import { CommonFieldAddProps } from "@/common";
import {
	getInitialStatementInvAction,
	getLastStatementInvAction,
	getStatementInvoicesAction,
	saveStatementInvoicesAction,
	updateStatementInvoicesAction
} from "@/action/voyageManage/invoices";
import { downloadPdf } from "@/tools/graph";
import { InvoicesViewsContainerProps } from "../../../common";
import { initialUppercase } from "@/tools/string";

const useIndex = (
	activeVoyageItem: InvStatementProps["activeVoyageItem"],
	activeInvoice: InvStatementProps["activeInvoice"],
	activeInvType: InvStatementProps["activeInvType"],
	newly: InvStatementProps["newly"],
	onInvoiceUpdate: InvStatementProps["onInvoiceUpdate"],
	onInvoiceAdd: InvStatementProps["onInvoiceAdd"]
) => {
	const dispatch = useAppDispatch();
	const navigate = useNavigate();
	const { reminder } = useReminder();

	const [loading, setLoading] = useState(false);
	const canvasRef = useRef<HTMLCanvasElement>(null);
	const [activeTab, setActiveTab] = useState<InvoicesViewsContainerProps["activeTab"]>("form");
	const activeField = useRef<InvStatementFieldType>("basics");

	const {
		dataSource: otherItem,
		change: handleOtherItemChange,
		init: handleOtherInit,
		dialogItem,
		handleDialogChange
	} = useOtherIndex();

	const {
		baseItem,
		handleBaseItemChange,
		handleBaseItemCommit,
		handleBaseInit,
		handleBaseCheck,
		basicsDelIds,
		basicsFieldItems,
		handleBasicsFieldItemChange,
		handleBasicsFieldDelete,
		handleBasicsFieldAdd,
		counterPartyDelIds,
		counterPartyFieldItems,
		handleCounterPartyFieldItemChange,
		handleCounterPartyFieldFieldDelete,
		handleCounterPartyFieldFieldAdd
	} = useStatementBaseIndex();

	const {
		descItem,
		summary,
		hireItems,
		offHireDelIds,
		handleHireCommit,
		handleOffHireCommit,
		handleOffHireAdd,
		handleOffHireDelete,
		bunkerPriceItem,
		handleBunkerPriceCommit,
		otherExpenses,
		otherExpenseDelIds,
		handleOtherExpensesChange,
		handleOtherExpensesAdd,
		handleOtherExpensesDelete,
		fixedExpenses,
		handleFixedExpensesChange,
		handleFixedExpensesCommit,
		check: handleDescCheck,
		init: handleDescInit
	} = useStatementDescIndex(otherItem);

	const init = (type: "init" | "details", item: Partial<InvStatementInitItemType>) => {
		const { basic, description, id, ...restItem } = item;
		const restParams = type === "init" ? restItem : { id, ...restItem };
		handleBaseInit(type, basic);
		handleDescInit(type, description);
		handleOtherInit(restParams);
	};

	const handleFirstInit = (item: InvStatementFirstInitItemType) => {
		const totalOffHire = (item?.offHireFoList ?? [])?.reduce((pre, cur) => {
			return pre + cur?.totalOffHire;
		}, 0);
		handleBaseInit("init", item);
		handleDescInit("init", {
			...item,
			bodFoTotalValue: +item?.bodFoConsumption * +item?.bodFoPrice,
			borMdoTotalValue: +item?.borMdoConsumption * +item?.borMdoPrice,
			borFoTotalValue: +item?.borFoConsumption * +item?.borFoPrice,
			bodMdoTotalValue: +item?.bodMdoConsumption * +item?.bodMdoPrice,
			ttlOffHireDay: +totalOffHire?.toFixed(2)
		});
		handleOtherInit(item);
	};

	const check = () => {
		const { checkKey: baseCheckKey, checked: baseChecked } = handleBaseCheck();
		if (!baseChecked) {
			reminder("error", `Field ${baseCheckKey} is required`);
			return false;
		}

		const { checkKey: descCheckKey, checked: descChecked } = handleDescCheck();
		if (!descChecked) {
			reminder("error", `Field ${descCheckKey} is required`);
			return false;
		}

		return true;
	};

	const handleTabSelect: InvoicesViewsContainerProps["onTabSelect"] = (val) => {
		if (activeTab === val) return;
		setActiveTab(val);
	};

	const handleFieldOpen: InvStatementFieldOpenEvent = (type) => {
		activeField.current = type;
		handleDialogChange("field", false);
	};

	const handleFieldAdd: CommonFieldAddProps["onAdd"] = (item) => {
		switch (true) {
			case activeField.current === "basics":
				handleBasicsFieldAdd({ ...item, type: "" });
				break;
			case activeField.current === "counterParty":
				handleCounterPartyFieldFieldAdd({ ...item, type: "" });
				break;
			case activeField.current === "otherItems":
				handleOtherExpensesAdd({ ...item, type: "" });
				break;
			default:
				break;
		}
		handleDialogChange("field", true);
	};

	const handleSaveFront = () => {
		setLoading(true);
	};

	const handleSaveError = (error) => {
		setLoading(false);
		reminder("error", error?.msg ? error?.msg + ": " + error?.data : error?.data);
	};

	const handleSaveSuccess = ({ data, msg }) => {
		onInvoiceUpdate?.({ id: data?.id, invNo: data?.invNo });
		reminder("success", msg);
		setLoading(false);
		getStatementInvoicesAction(
			{ invoiceId: data?.id },
			handleSaveFront,
			getStatementDetailSuccess,
			handleSaveError,
			dispatch,
			navigate
		);
	};

	const getInitialStatementInvoicesSuccess = ({ data }) => {
		handleFirstInit(data);
		onInvoiceAdd?.();
		setActiveTab("form");
		setLoading(false);
	};

	const getStatementDetailSuccess = ({ data }) => {
		init("details", data);
		setActiveTab("form");
		setLoading(false);
	};

	const getLastStatementInvSuccess = ({ data }) => {
		init("init", data);
		setActiveTab("form");
		setLoading(false);
		onInvoiceAdd?.();
	};

	const handleSave = () => {
		if (!check()) return;
		let params: any = {
			...otherItem,
			voyageId: activeVoyageItem?.id,
			basic: {
				...baseItem,
				counterFieldList: counterPartyFieldItems,
				basicsFieldList: basicsFieldItems
			},
			description: {
				...descItem,
				...fixedExpenses,
				...bunkerPriceItem,
				...hireItems,
				remitAmount: summary?.plus - summary?.less,
				otherFieldList: otherExpenses
			},
			type:
				activeInvType === "statement" ? null : (initialUppercase(activeInvType) as "Rent" | "Relet")
		};

		if (activeInvoice?.id) {
			updateStatementInvoicesAction(
				{
					...params,
					id: activeInvoice?.id,
					delFeildIdList: [
						...basicsDelIds.current,
						...counterPartyDelIds.current,
						...otherExpenseDelIds.current
					],
					delOffHireIdList: offHireDelIds.current
				},
				handleSaveFront,
				handleSaveSuccess,
				handleSaveError,
				dispatch,
				navigate
			);
			return;
		}

		saveStatementInvoicesAction(
			{
				...params
			},
			handleSaveFront,
			handleSaveSuccess,
			handleSaveError,
			dispatch,
			navigate
		);
	};

	const getNewlyStatementInvoices = () => {
		switch (newly.type) {
			case "lastDetail":
			case "last":
				getLastStatementInvAction(
					{
						voyageId: activeVoyageItem?.id,
						type:
							activeInvType === "statement"
								? null
								: (initialUppercase(activeInvType) as "Rent" | "Relet")
					},
					handleSaveFront,
					newly.type === "last" ? getLastStatementInvSuccess : getStatementDetailSuccess,
					handleSaveError,
					dispatch,
					navigate
				);
				break;
			case "first":
				getInitialStatementInvAction(
					{
						voyageId: activeVoyageItem?.id,
						type:
							activeInvType === "statement"
								? null
								: (initialUppercase(activeInvType) as "Rent" | "Relet")
					},
					handleSaveFront,
					getInitialStatementInvoicesSuccess,
					handleSaveError,
					dispatch,
					navigate
				);
				break;
			case "detail":
				getStatementInvoicesAction(
					{ invoiceId: activeInvoice?.id },
					handleSaveFront,
					getStatementDetailSuccess,
					handleSaveError,
					dispatch,
					navigate
				);
				break;
			default:
				break;
		}
	};

	const handleDownPdf = () => {
		downloadPdf(canvasRef, "statement_hire");
	};

	useEffect(() => {
		if (newly.status === -1) return;
		console.log("newly", newly);
		getNewlyStatementInvoices();
	}, [newly]);

	return {
		canvasRef,
		loading,
		activeTab,
		handleTabSelect,
		activeField,
		handleFieldOpen,
		handleFieldAdd,
		otherItem,
		handleOtherItemChange,
		dialogItem,
		handleDialogChange,
		baseItem,
		handleBaseItemChange,
		handleBaseItemCommit,
		basicsFieldItems,
		counterPartyFieldItems,
		descItem,
		summary,
		hireItems,
		handleHireCommit,
		handleOffHireCommit,
		handleOffHireAdd,
		handleOffHireDelete,
		bunkerPriceItem,
		handleBunkerPriceCommit,
		otherExpenses,
		handleOtherExpensesChange,
		handleOtherExpensesDelete,
		fixedExpenses,
		handleFixedExpensesChange,
		handleFixedExpensesCommit,
		handleSave,
		handleDownPdf
	};
};

export default useIndex;
