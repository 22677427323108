import React, { useState } from "react";
import style from "./index.module.less";
import { tabs as tabList } from "./source";
import { Tabs } from "antd";
import { BasicInfoProps } from "./type";
import Basic from "./components/basic";
import Password from "./components/password";

const BasicInfo: React.FC<BasicInfoProps> = ({ data, onReset, onChange }) => {
  const [activeTab, setActive] = useState("1");
  return (
    <div className={style["fleet-basic-information"]}>
      <span className="basic-information-header fleet-header">
        Basic Information
      </span>
      <div className="basic-information-tab">
        <Tabs
          defaultActiveKey="1"
          items={tabList}
          onChange={(v) => setActive(v)}
        />
        {activeTab === "1" ? (
          <Basic data={data} onChange={onChange} onReset={onReset} />
        ) : (
          <Password />
        )}
      </div>
    </div>
  );
};

export default BasicInfo;
