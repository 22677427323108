import React, { useEffect, useRef, useState } from "react";
import style from "./index.module.less";
import classnames from "classnames";
import { FleetButton } from "@/components";
import {
	RoutePlanDetails,
	RoutePlan,
	DailyReport,
	Performance,
	MonitoringAreaVesselList,
	MonitoringPortAreaList,
	MonitoringPortCallList
} from "./components";
import { RoutePlanUpload } from "./components/RoutePlan/components";
import { RoutePlanItemType, RoutePlanRefType } from "./components/RoutePlan/type";
import { useMonitoringContext } from "../../MonitoringContext";
import { MonitoringDetailMoreTabType, MonitoringDetailProps } from "./type";
import { VesselDetailInfo } from "..";
import { CommonRoleWrap } from "@/common";
import { Button } from "@/_components";
import { PortAreaListItemType } from "./components/portAreaList/components/table/type";
import { FleetNumType } from "@/types/common";

export type DetailDrawerItem = {
	voyageNo: string;
	id: number;
	vesselStatus: string;
	actualCommenceDate: number;
	startPortCode: string;
	endPortCode: string;
	startPortName: string;
	endPortName: string;
};

const DetailDrawer: React.FC<MonitoringDetailProps> = ({
	mapRef,
	currentVessel,
	open,
	onCollopsed,
	onAisItemSelect,
	onAreaPortVesselLoad,
	currentTab,
	areaItems,
	portVesselParams,
	onPortVesselLoad,
	activeTab,
	onChangeActiveTab
}) => {
	const [record, setRecord] = useState<Partial<RoutePlanItemType>>(null);
	const [currentPortCall, setCurrentPortCall] = useState<{ vesselName: string; imo: FleetNumType }>(
		{
			vesselName: "",
			imo: ""
		}
	);
	const [uploadHide, setUploadHide] = useState(true);
	const [textAreaWrap, setTextAreaWrap] = useState<{
		MaxHeight: number;
		MinHeight: number;
	}>({
		MaxHeight: 9999,
		MinHeight: 48
	});

	const routePlanRef = useRef<RoutePlanRefType>(null);

	var dragState = null;
	const handleMouseUp = function () {
		// 移除鼠标移动事件
		document.removeEventListener("mousemove", handleMouseMove);
		// 移除鼠标放开事件
		document.removeEventListener("mouseup", handleMouseUp);
		// 允许用户选择网页中文字
		document.onselectstart = null;
		// 允许用户拖动元素
		document.ondragstart = null;
	};
	const switchPanel = function () {
		// const bottom = document.getElementById("bottom-container").style.bottom
		// const newBottom = ((parseInt(bottom) === 0 || isNaN(parseInt(bottom))) ? ((document.getElementById("bottom-container").clientHeight - 48) * -1) : 0)
		// document.getElementById("bottom-container").style.bottom = newBottom + 'px'
		// debugger
		// onCollopsed()
		// document.getElementById("bottom-container").style.bottom = (!open ? 0 : ((document.getElementById("bottom-container").clientHeight - 48) * -1)) + 'px'
		// !open && document.getElementById('table-container').clientHeight == 0 && (document.getElementById('table-container').style.height = '300px')
	};
	const handleMouseDown = function (event) {
		// 禁止用户选择网页中文字
		document.onselectstart = () => false;
		// 禁止用户拖动元素
		document.ondragstart = () => false;

		// 保存鼠标最后移动的位置（Y轴）
		dragState = {
			// 鼠标开始移动的位置（Y轴）
			startMouseTop: event.clientY,
			// 鼠标最后移动的位置（Y轴）
			endMouseTop: event.clientY
		};
		// 绑定鼠标移动事件
		document.addEventListener("mousemove", handleMouseMove);
		// 绑定鼠标放开事件
		document.addEventListener("mouseup", handleMouseUp);
	};
	const handleMouseMove = function (event) {
		const latLngControll = document.querySelector(".wmap-lnglat-control") as HTMLDivElement;
		textAreaWrap.MaxHeight = document.getElementById("windy").clientHeight;
		const rTextareaWrap = document.getElementById("bottom-container");
		// 获取鼠标最后移动的位置（Y轴）
		const endMouseTop = dragState.endMouseTop;
		// 获取当前的文本框高度
		const oldTextAreaHeight = rTextareaWrap.getBoundingClientRect().height;
		// 新的文本框高度
		let newTextAreaHeight = 0;
		// 计算鼠标移动的距离
		const distance = Math.abs(parseInt(((endMouseTop - event.clientY) * 100).toString(), 10) / 100);
		// 若鼠标向上移动
		if (endMouseTop > event.clientY) {
			// 发送框高度达到最大
			if (oldTextAreaHeight >= textAreaWrap.MaxHeight) {
				// 修改光标为可被向下移动
				// rResizeLine.style.cursor = 's-resize'
				return false;
			}

			// 计算新的发送框高度
			newTextAreaHeight = oldTextAreaHeight + distance;
		}
		// 若鼠标向下移动
		else {
			// 发送框高度达到最小
			if (oldTextAreaHeight <= textAreaWrap.MinHeight) {
				// 修改光标为可被向上移动
				// rResizeLine.style.cursor = 'n-resize'
				return false;
			}

			// 计算新的发送框高度
			newTextAreaHeight = oldTextAreaHeight - distance;
		}

		// 兼容鼠标快速拖动的情况：新的发送框高度不能超过最大值
		if (newTextAreaHeight > textAreaWrap.MaxHeight) {
			newTextAreaHeight = textAreaWrap.MaxHeight;
		}

		// 兼容鼠标快速拖动的情况：新的发送框高度不能小于最小值
		if (newTextAreaHeight < textAreaWrap.MinHeight) {
			newTextAreaHeight = textAreaWrap.MinHeight;
		}

		// 修改发送框高度
		rTextareaWrap.style.height = newTextAreaHeight + "px";
		latLngControll && (latLngControll.style.bottom = newTextAreaHeight + 2 + "px");
		document.getElementById("legend").style.bottom = newTextAreaHeight + 10 + "px";
		// 修改光标为可移动
		// rResizeLine.style.cursor = 'move'

		// 更新鼠标最后移动的位置（Y轴）
		dragState.endMouseTop = event.clientY;
	};
	const handleOpenPortCall = (item: { vesselName: string; imo: FleetNumType }) => {
		onChangeActiveTab?.("portCallList");
		setCurrentPortCall({
			vesselName: item?.vesselName,
			imo: item?.imo
		});
	};
	const handleRoutePlanRowClick = (record: RoutePlanItemType) => {
		setRecord(record);
		onChangeActiveTab?.("routePlanDetails");
	};

	const handleBackRoutePlan = () => {
		setRecord(null);
		onChangeActiveTab?.("routePlan");
	};

	useEffect(() => {
		document.getElementById("bottom-header").removeEventListener("mousedown", handleMouseDown);
		document.getElementById("bottom-header").addEventListener("mousedown", handleMouseDown);
		document.getElementById("bottom-header").style.cursor = "n-resize";
		document.getElementById("bottom-container").style.height = "48px";
	}, []);

	return (
		<div id="bottom-container" className={style.detailDrawer}>
			<div className={style.header} id="bottom-header">
				<div className={style.tab}>
					{/* <div className={classnames([style.item, tab === 0 && style.active])} onClick={() => setActiveTab("performance")}>Performance</div> */}
					<div
						className={classnames([style.item, activeTab === "dailyReport" && style.active])}
						onClick={() => onChangeActiveTab?.("dailyReport")}
					>
						Daily Report
					</div>
					<div
						className={classnames([style.item, activeTab === "routePlan" && style.active])}
						onClick={() => onChangeActiveTab?.("routePlan")}
					>
						Route Plans
					</div>
					{currentTab === "area" && (
						<div
							className={classnames([style.item, activeTab === "areaVesslDetails" && style.active])}
							onClick={() => onChangeActiveTab?.("areaVesslDetails")}
						>
							Area Vessel List
						</div>
					)}
					{currentTab === "port" && (
						<div
							className={classnames([style.item, activeTab === "portAreaDetails" && style.active])}
							onClick={() => onChangeActiveTab?.("portAreaDetails")}
						>
							Port Vessel List
						</div>
					)}
					{["port", "area"].includes(currentTab) && (
						<div
							className={classnames([
								style.item,
								["portCallList"].includes(activeTab) && style.active
							])}
							onClick={() => onChangeActiveTab?.("portCallList")}
						>
							Port Call List
						</div>
					)}
					{/* <div className={classnames([style.item, tab === 3 && style.active])} onClick={() => setTab(3)}>Waypoint</div> */}
				</div>
				<CommonRoleWrap roleStr="monitoring:detail:upload">
					<Button
						type="secondary"
						disabled={!currentVessel || !currentVessel?.voyageNo}
						size="small"
						onClick={() => setUploadHide(false)}
					>
						Upload
					</Button>
				</CommonRoleWrap>
				{/*<Button type="link" onClick={onCollopsed} size="large">*/}
				{/*  {open ? <CaretDownOutlined /> : <CaretUpOutlined />}*/}
				{/*</Button>*/}
			</div>
			<div id="table-container" className={style.container}>
				<Performance data={currentVessel} mapRef={mapRef} activeIndex={activeTab} />
				<DailyReport data={currentVessel} mapRef={mapRef} activeIndex={activeTab} />
				<RoutePlan
					ref={routePlanRef}
					onRowClick={handleRoutePlanRowClick}
					data={currentVessel}
					mapRef={mapRef}
					activeIndex={activeTab}
				/>
				<RoutePlanDetails
					id={record?.id}
					activeIndex={activeTab}
					onBack={handleBackRoutePlan}
					mapRef={mapRef}
				/>
				<MonitoringAreaVesselList
					activeIndex={activeTab}
					areaItems={areaItems}
					currentTab={currentTab}
					onAisItemSelect={onAisItemSelect}
					onAreaPortVesselLoad={onAreaPortVesselLoad}
					onOpenPortCall={(item) => handleOpenPortCall(item)}
				/>
				<MonitoringPortAreaList
					activeIndex={activeTab}
					currentTab={currentTab}
					searchParams={portVesselParams}
					onAisItemSelect={onAisItemSelect}
					onAreaPortVesselLoad={onAreaPortVesselLoad}
					onPortVesselLoad={onPortVesselLoad}
					onOpenPortCall={(item) => handleOpenPortCall(item)}
				/>
				<MonitoringPortCallList
					activeIndex={activeTab}
					currentTab={currentTab}
					currentVessel={currentPortCall}
				/>
			</div>
			<RoutePlanUpload
				hide={uploadHide}
				onClose={() => setUploadHide(true)}
				currentRecord={currentVessel}
				onReset={() => {
					setUploadHide(true);
					routePlanRef?.current?.onReset?.();
				}}
			/>
		</div>
	);
};

export default DetailDrawer;
