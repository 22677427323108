import { ExtendsKeys, ExcludeKeys } from "@/types";
import React from "react";
import {
  MovementTableColumnsType as ColumnsType
} from "@/featrue/voyagemanage/movement/common/table"
import { FleetCustomRadio } from "@/components/Radio";
import { toThousands } from "@/tools";
import { getMonthName } from "@/tools/date";

type MultipleRouteKeys =
  "id" |
  "depPortCoor" |
  "arrPortCoor" |
  "totalDistance" |
  "ecaDistance" |
  "vesselTypeLv1" |
  "vesselTypeLv2" |
  "routeObject" |
  "totalTime" |
  "ecaTime" |
  "cpSpeed" |
  "depDateMonth";

type MultipleRouteNumKeys = ExtendsKeys<MultipleRouteKeys,
  "totalDistance" |
  "ecaDistance" |
  "totalTime" |
  "ecaTime" |
  "cpSpeed" |
  "depDateMonth"
>;

type MultipleRouteStrKeys = ExtendsKeys<MultipleRouteKeys,
  "vesselTypeLv1" |
  "vesselTypeLv2"
>;

type MultipleRouteGeoJsonKeys = ExtendsKeys<MultipleRouteKeys,
  "routeObject" |
  "depPortCoor" |
  "arrPortCoor"
>;

type MultipleRouteFormKeys = MultipleRouteNumKeys | MultipleRouteStrKeys | MultipleRouteGeoJsonKeys;

export type EsMultipleRouteFormType =
  Record<MultipleRouteNumKeys, number> &
  Record<MultipleRouteStrKeys, string> &
  Record<MultipleRouteGeoJsonKeys, any> &
  { id?: string | number };

export type EsMultipleRouteProps = {
  items: EsMultipleRouteFormType[];
  show: boolean;
  loading: boolean;
  checkbox:number[];
  onClose?: () => void;
  onConfirm?:() => void;
  actions?: {
    onSelect?: (record: EsMultipleRouteFormType, checkAll?: boolean) => void,
    onSelectAll?: (checkAll: boolean) => void
  }
}

export const columns: ColumnsType<EsMultipleRouteFormType,EsMultipleRouteProps["actions"]>[] = [
  {
    width: 32,
    label: "No.",
    dataIndex: "",
    fixed: "left",
    fixedWidth: 0,
    zIndex: 5,
    renderCheckbox: (value, record, actions, checkAll) => {
      return <FleetCustomRadio value={value} onChange={(val: boolean) => {
        if (checkAll !== undefined) {
          actions?.onSelectAll(!value)
          return
        }
        actions?.onSelect(record)
      }} />
    }
  },
  {
    width: 84,
    label: "Dep. Month",
    dataIndex: "depDateMonth",
    render: (value, record) => {
      return <span>{getMonthName(value)}</span>
    }
  },
  // {
  //   width: 64,
  //   label: "Ship Type",
  //   dataIndex: "vesselTypeLv1"
  // },
  {
    width: 84,
    label: "Dis. (nm)",
    dataIndex: "totalDistance"
  },
  // {
  //   width: 84,
  //   label: "Eca. (nm)",
  //   dataIndex: "ecaDistance"
  // },
  {
    width: 112,
    label: "Intention Speed",
    dataIndex: "cpSpeed"
  },
  {
    width: 84,
    label: "Actual spt",
    dataIndex: "cpSpeed",
    render: (value, record, index) => {
      return <span>{toThousands(record?.totalDistance / record?.totalTime)}</span>
    }
  },
  {
    width: 80,
    label: "Time (hrs)",
    dataIndex: "totalTime"
  },
]