import { ApiParameter } from "@/api";
import request from "@/service";

import {
	getMovementTaskListParams,
	getMovementVoyageTaskParams,
	getMovementTaskCommentListParams,
	deleteMovementTaskCommentParams,
	saveMovementTaskCommentParams,
	updateMovementTaskItemParams,
	saveMovementTaskConfigParams
} from "./type";
import { appVersion } from "@/api/constant";

const enum ApiMap {
	getMovementTaskListParameter,
	getMovementVoyageTaskParameter,
	updateMovementTaskParameter,
	getMovementCommentListParameter,
	deleteMovementCommentParameter,
	saveMovementCommentParameter,
	saveMovementTaskConfigParameter
}

console.log("appVersion", appVersion);

type ApiUrl = keyof typeof ApiMap;

const taskApi: ApiParameter<ApiUrl> = {
	getMovementTaskListParameter: {
		method: "get",
		url: appVersion + "/move/task/query"
	},
	getMovementVoyageTaskParameter: {
		method: "get",
		url: appVersion + "/move/task/numberList"
	},
	updateMovementTaskParameter: {
		method: "put",
		url: appVersion + "/move/task"
	},
	getMovementCommentListParameter: {
		method: "get",
		url: appVersion + "/move/comment"
	},
	deleteMovementCommentParameter: {
		method: "delete",
		url: appVersion + "/move/comment"
	},
	saveMovementCommentParameter: {
		method: "post",
		url: appVersion + "/move/comment"
	},
	saveMovementTaskConfigParameter: {
		method: "put",
		url: appVersion + "/move/task/relation"
	}
};

export const getMovementTaskListApi = (params: getMovementTaskListParams) => {
	return request.request({
		...taskApi.getMovementTaskListParameter,
		params
	});
};

export const getMovementVoyageTaskApi = (params: getMovementVoyageTaskParams) => {
	return request.request({
		...taskApi.getMovementVoyageTaskParameter,
		url: `${taskApi?.getMovementVoyageTaskParameter?.url}/${params?.voyageId}`
	});
};

export const getMovemenTaskCommentListApi = (params: getMovementTaskCommentListParams) => {
	return request.request({
		...taskApi.getMovementCommentListParameter,
		url: `${taskApi.getMovementCommentListParameter.url}/${params?.id}`
	});
};

export const deleteMovementTaskCommentApi = (params: deleteMovementTaskCommentParams) => {
	return request.request({
		...taskApi.deleteMovementCommentParameter,
		url: `${taskApi.deleteMovementCommentParameter.url}/${params?.id}`
	});
};

export const saveMovementTaskCommentApi = (params: saveMovementTaskCommentParams) => {
	return request.request({
		...taskApi.saveMovementCommentParameter,
		data: params
	});
};

export const updateMovementTaskItemApi = (params: updateMovementTaskItemParams) => {
	return request.request({
		...taskApi.updateMovementTaskParameter,
		data: params
	});
};

export const saveMovementTaskConfigApi = (params: saveMovementTaskConfigParams) => {
	return request.request({
		...taskApi.saveMovementTaskConfigParameter,
		data: params
	});
};
