import { ColumnType } from "antd/es/table";
import i18n from "@/locale";
import { DataType } from "./type";
import React from "react";
import { ArrivalSoxScrubberStatusMap, ArrivalStatusMap, ArrivalTradeTypeMap, ArrivalVesselSubTypeMap, ArrivalVesselTypeMap } from "@/pages/estimation/business/details/components/vesselArrival/source";
import { formatThousandthNumber } from "@/tools/amount";

const { t } = i18n;

export const columns: ColumnType<DataType>[] = [
  {
    title: t("vesselArrival.vesselName"),
    key: "vesselName",
    dataIndex: "vesselName",
    width: 200,
    fixed: "left",
    align: "center",
    ellipsis: true
  },
  {
    title: t("vesselArrival.vesselType"),
    key: "vesselType",
    dataIndex: "vesselType",
    width: 200,
    align: "center",
    ellipsis: true,
    render: (value, record, index) => {
      return <span>{ArrivalVesselTypeMap[value]}</span>
    }
  },
  {
    title: t("vesselArrival.vesselTypeSub"),
    key: "vesselTypeSub",
    dataIndex: "vesselTypeSub",
    width: 200,
    align: "center",
    ellipsis: true,
    render: (value, record, index) => {
      return <span>{ArrivalVesselSubTypeMap[value]}</span>
    }
  },
  {
    title: t("vesselArrival.imo"),
    key: "imo",
    dataIndex: "imo",
    width: 200,
    align: "center",
    ellipsis: true
  },
  {
    title: t("vesselArrival.mmsi"),
    key: "mmsi",
    dataIndex: "mmsi",
    width: 200,
    align: "center",
    ellipsis: true
  },
  {
    title: t("vesselArrival.callSign"),
    key: "callSign",
    dataIndex: "callSign",
    width: 200,
    align: "center",
    ellipsis: true
  },
  {
    title: t("vesselArrival.flagCountryName"),
    key: "flagCountryName",
    dataIndex: "flagCountryName",
    width: 200,
    align: "center",
    ellipsis: true
  },
  {
    title: t("vesselArrival.speedMax"),
    key: "speedMax",
    dataIndex: "speedMax",
    width: 200,
    align: "center",
    ellipsis: true
  },
  {
    title: t("vesselArrival.seviceSpeed"),
    key: "seviceSpeed",
    dataIndex: "seviceSpeed",
    width: 200,
    align: "center",
    ellipsis: true
  },
  {
    title: t("vesselArrival.classification"),
    key: "classification",
    dataIndex: "classification",
    width: 200,
    align: "center",
    ellipsis: true
  },
  {
    title: t("vesselArrival.buildYear"),
    key: "buildYear",
    dataIndex: "buildYear",
    width: 200,
    align: "center",
    ellipsis: true
  },
  {
    title: t("vesselArrival.buildMonth"),
    key: "buildMonth",
    dataIndex: "buildMonth",
    width: 200,
    align: "center",
    ellipsis: true
  },
  {
    title: t("vesselArrival.status"),
    key: "status",
    dataIndex: "status",
    width: 200,
    align: "center",
    ellipsis: true,
    render: (value, record, index) => {
      return <span>{ArrivalStatusMap[value]}</span>
    }
  },
  {
    title: t("vesselArrival.tradeType"),
    key: "tradeType",
    dataIndex: "tradeType",
    width: 200,
    align: "center",
    ellipsis: true,
    render: (value, record, index) => {
      return <span>{ArrivalTradeTypeMap[value]}</span>
    }
  },
  {
    title: t("vesselArrival.cnCode"),
    key: "cnCode",
    dataIndex: "cnCode",
    width: 200,
    align: "center",
    ellipsis: true
  },
  {
    title: t("vesselArrival.vesselNameCn"),
    key: "vesselNameCn",
    dataIndex: "vesselNameCn",
    width: 200,
    align: "center",
    ellipsis: true
  },
  {
    title: t("vesselArrival.ventureType"),
    key: "ventureType",
    dataIndex: "ventureType",
    width: 200,
    align: "center",
    ellipsis: true
  },
  {
    title: t("vesselArrival.exName"),
    key: "exName",
    dataIndex: "exName",
    width: 200,
    align: "center",
    ellipsis: true
  },
  {
    title: t("vesselArrival.pniClub"),
    key: "pniClub",
    dataIndex: "pniClub",
    width: 200,
    align: "center",
    ellipsis: true
  },
  // {
  //   title: t("vesselArrival.ownerCode"),
  //   key: "ownerCode",
  //   dataIndex: "ownerCode",
  //   width: 200,
  //   align: "center",
  //   ellipsis: true
  // },
  // {
  //   title: t("vesselArrival.operatorCode"),
  //   key: "operatorCode",
  //   dataIndex: "operatorCode",
  //   width: 200,
  //   align: "center",
  //   ellipsis: true
  // },
  // {
  //   title: t("vesselArrival.managerCode"),
  //   key: "managerCode",
  //   dataIndex: "managerCode",
  //   width: 200,
  //   align: "center",
  //   ellipsis: true
  // },
  {
    title: t("vesselArrival.countryName"),
    key: "countryName",
    dataIndex: "countryName",
    width: 200,
    align: "center",
    ellipsis: true
  },
  {
    title: t("vesselArrival.countryNameCn"),
    key: "countryNameCn",
    dataIndex: "countryNameCn",
    width: 200,
    align: "center",
    ellipsis: true
  },
  {
    title: t("vesselArrival.companyName"),
    key: "companyName",
    dataIndex: "companyName",
    width: 200,
    align: "center",
    ellipsis: true
  },
  {
    title: t("vesselArrival.companyNameCn"),
    key: "companyNameCn",
    dataIndex: "companyNameCn",
    width: 200,
    align: "center",
    ellipsis: true
  },
  {
    title: t("vesselArrival.operatorCountryName"),
    key: "operatorCountryName",
    dataIndex: "operatorCountryName",
    width: 200,
    align: "center",
    ellipsis: true
  },
  {
    title: t("vesselArrival.operatorCountryNameCn"),
    key: "operatorCountryNameCn",
    dataIndex: "operatorCountryNameCn",
    width: 200,
    align: "center",
    ellipsis: true
  },
  {
    title: t("vesselArrival.operatorCompanyName"),
    key: "operatorCompanyName",
    dataIndex: "operatorCompanyName",
    width: 200,
    align: "center",
    ellipsis: true
  },
  {
    title: t("vesselArrival.operatorCompanyNameCn"),
    key: "operatorCompanyNameCn",
    dataIndex: "operatorCompanyNameCn",
    width: 200,
    align: "center",
    ellipsis: true
  },
  {
    title: t("vesselArrival.builderCompanyName"),
    key: "builderCompanyName",
    dataIndex: "builderCompanyName",
    width: 200,
    align: "center",
    ellipsis: true
  },
  {
    title: t("vesselArrival.builderCompanyNameCn"),
    key: "builderCompanyNameCn",
    dataIndex: "builderCompanyNameCn",
    width: 200,
    align: "center",
    ellipsis: true
  },
  {
    title: t("vesselArrival.builderCountryName"),
    key: "builderCountryName",
    dataIndex: "builderCountryName",
    width: 200,
    align: "center",
    ellipsis: true
  },
  {
    title: t("vesselArrival.builderCountryNameCn"),
    key: "builderCountryNameCn",
    dataIndex: "builderCountryNameCn",
    width: 200,
    align: "center",
    ellipsis: true
  },
  {
    title: t("vesselArrival.managerCompanyName"),
    key: "managerCompanyName",
    dataIndex: "managerCompanyName",
    width: 200,
    align: "center",
    ellipsis: true
  },
  {
    title: t("vesselArrival.managerCompanyNameCn"),
    key: "managerCompanyNameCn",
    dataIndex: "managerCompanyNameCn",
    width: 200,
    align: "center",
    ellipsis: true
  },
  {
    title: t("vesselArrival.managerCountryName"),
    key: "managerCountryName",
    dataIndex: "managerCountryName",
    width: 200,
    align: "center",
    ellipsis: true
  },
  {
    title: t("vesselArrival.managerCountryNameCn"),
    key: "managerCountryNameCn",
    dataIndex: "managerCountryNameCn",
    width: 200,
    align: "center",
    ellipsis: true
  },
  {
    key: "dwt",
    dataIndex: "dwt",
    title: t(`vesselArrival.dwt`),
    width: 200,
    ellipsis: true,
    align: "center",
    render: (value, record, index) => <span>{formatThousandthNumber(value)}</span>
  },
  {
    key: "loa",
    dataIndex: "loa",
    title: t(`vesselArrival.loa`),
    width: 200,
    ellipsis: true,
    align: "center",
    render: (value, record, index) => <span>{formatThousandthNumber(value)}</span>
  },
  {
    key: "bm",
    dataIndex: "bm",
    title: t(`vesselArrival.bm`),
    width: 200,
    ellipsis: true,
    align: "center",
    render: (value, record, index) => <span>{formatThousandthNumber(value)}</span>
  },
  {
    key: "draft",
    dataIndex: "draft",
    title: t(`vesselArrival.draft`),
    width: 200,
    ellipsis: true,
    align: "center",
    render: (value, record, index) => <span>{formatThousandthNumber(value)}</span>
  },
  {
    key: "grossTonnage",
    dataIndex: "grossTonnage",
    title: t(`vesselArrival.grossTonnage`),
    width: 200,
    ellipsis: true,
    align: "center",
    render: (value, record, index) => <span>{formatThousandthNumber(value)}</span>
  },
  {
    key: "netTonnage",
    dataIndex: "netTonnage",
    title: t(`vesselArrival.netTonnage`),
    width: 200,
    ellipsis: true,
    align: "center",
    render: (value, record, index) => <span>{formatThousandthNumber(value)}</span>
  },
  {
    key: "depth",
    dataIndex: "depth",
    title: t(`vesselArrival.depth`),
    width: 200,
    ellipsis: true,
    align: "center",
    render: (value, record, index) => <span>{formatThousandthNumber(value)}</span>
  },
  {
    key: "tpc",
    dataIndex: "tpc",
    title: t(`vesselArrival.tpc`),
    width: 200,
    ellipsis: true,
    align: "center",
    render: (value, record, index) => <span>{formatThousandthNumber(value)}</span>
  },
  {
    key: "displacement",
    dataIndex: "displacement",
    title: t(`vesselArrival.displacement`),
    width: 200,
    ellipsis: true,
    align: "center",
    render: (value, record, index) => <span>{formatThousandthNumber(value)}</span>
  },
  {
    key: "soxScrubberStatus",
    dataIndex: "soxScrubberStatus",
    title: t(`vesselArrival.soxScrubberStatus`),
    width: 200,
    ellipsis: true,
    align: "center",
    render: (value, record, index) => <span>{ArrivalSoxScrubberStatusMap[value]}</span>
  },
  {
    key: "capacity",
    dataIndex: "capacity",
    title: t(`vesselArrival.capacity`),
    width: 200,
    ellipsis: true,
    align: "center",
    render: (value, record, index) => <span>{formatThousandthNumber(value)}</span>
  },
  {
    key: "lngContainmentType",
    dataIndex: "lngContainmentType",
    title: t(`vesselArrival.lngContainmentType`),
    width: 200,
    ellipsis: true,
    align: "center"
  },
  {
    key: "baleCapacity",
    dataIndex: "baleCapacity",
    title: t(`vesselArrival.baleCapacity`),
    width: 200,
    ellipsis: true,
    align: "center",
    render: (value, record, index) => <span>{formatThousandthNumber(value)}</span>
  },
  {
    key: "grainCapacity",
    dataIndex: "grainCapacity",
    title: t(`vesselArrival.grainCapacity`),
    width: 200,
    ellipsis: true,
    align: "center",
    render: (value, record, index) => <span>{formatThousandthNumber(value)}</span>
  },
  {
    key: "holdsTotalNo",
    dataIndex: "holdsTotalNo",
    title: t(`vesselArrival.holdsTotalNo`),
    width: 200,
    ellipsis: true,
    align: "center"
  },
  {
    key: "hatchesTotalNo",
    dataIndex: "hatchesTotalNo",
    title: t(`vesselArrival.hatchesTotalNo`),
    width: 200,
    ellipsis: true,
    align: "center"
  },
  {
    key: "grabsNo",
    dataIndex: "grabsNo",
    title: t(`vesselArrival.grabsNo`),
    width: 200,
    ellipsis: true,
    align: "center"
  },
  {
    key: "ecoEngine",
    dataIndex: "ecoEngine",
    title: t(`vesselArrival.ecoEngine`),
    width: 200,
    ellipsis: true,
    align: "center",
    render: (value, record, index) => <span>{formatThousandthNumber(value)}</span>
  },
  {
    key: "energySavingType",
    dataIndex: "energySavingType",
    title: t(`vesselArrival.energySavingType`),
    width: 200,
    ellipsis: true,
    align: "center",
    render: (value, record, index) => <span>{formatThousandthNumber(value)}</span>
  },
  {
    key: "lngCarrierEngineType",
    dataIndex: "lngCarrierEngineType",
    title: t(`vesselArrival.lngCarrierEngineType`),
    width: 200,
    ellipsis: true,
    align: "center",
    render: (value, record, index) => <span>{formatThousandthNumber(value)}</span>
  },
  {
    key: "lngContainmentModel",
    dataIndex: "lngContainmentModel",
    title: t(`vesselArrival.lngContainmentModel`),
    width: 200,
    ellipsis: true,
    align: "center",
    render: (value, record, index) => <span>{formatThousandthNumber(value)}</span>
  },
  {
    key: "auxiliaryEngineNumber",
    dataIndex: "auxiliaryEngineNumber",
    title: t(`vesselArrival.auxiliaryEngineNumber`),
    width: 200,
    ellipsis: true,
    align: "center",
    render: (value, record, index) => <span>{formatThousandthNumber(value)}</span>
  },
  {
    key: "mainEngineNumber",
    dataIndex: "mainEngineNumber",
    title: t(`vesselArrival.mainEngineNumber`),
    width: 200,
    ellipsis: true,
    align: "center",
    render: (value, record, index) => <span>{formatThousandthNumber(value)}</span>
  },
  {
    key: "mainEngineModel",
    dataIndex: "mainEngineModel",
    title: t(`vesselArrival.mainEngineModel`),
    width: 200,
    ellipsis: true,
    align: "center",
    render: (value, record, index) => <span>{formatThousandthNumber(value)}</span>
  },
  {
    key: "engineDesigner",
    dataIndex: "engineDesigner",
    title: t(`vesselArrival.engineDesigner`),
    width: 200,
    ellipsis: true,
    align: "center",
  },
  {
    key: "engineBuilder",
    dataIndex: "engineBuilder",
    title: t(`vesselArrival.engineBuilder`),
    width: 200,
    ellipsis: true,
    align: "center",
  },
  {
    key: "powerType",
    dataIndex: "powerType",
    title: t(`vesselArrival.powerType`),
    width: 200,
    ellipsis: true,
    align: "center",
    render: (value, record, index) => <span>{formatThousandthNumber(value)}</span>
  },
  {
    key: "mainEngineFuelType",
    dataIndex: "mainEngineFuelType",
    title: t(`vesselArrival.mainEngineFuelType`),
    width: 200,
    ellipsis: true,
    align: "center",
    render: (value, record, index) => <span>{formatThousandthNumber(value)}</span>
  },
  {
    key: "mainEngineRpm",
    dataIndex: "mainEngineRpm",
    title: t(`vesselArrival.mainEngineRpm`),
    width: 200,
    ellipsis: true,
    align: "center",
    render: (value, record, index) => <span>{formatThousandthNumber(value)}</span>
  },
  {
    key: "mainEngineMcrRpm",
    dataIndex: "mainEngineMcrRpm",
    title: t(`vesselArrival.mainEngineMcrRpm`),
    width: 200,
    ellipsis: true,
    align: "center",
    render: (value, record, index) => <span>{formatThousandthNumber(value)}</span>
  },
  {
    key: "gear",
    dataIndex: "gear",
    title: t(`vesselArrival.gear`),
    width: 200,
    ellipsis: true,
    align: "center",
    render: (value, record, index) => <span>{formatThousandthNumber(value)}</span>
  },
  {
    key: "gearSummary",
    dataIndex: "gearSummary",
    title: t(`vesselArrival.gearSummary`),
    width: 200,
    ellipsis: true,
    align: "center",
    render: (value, record, index) => <span>{formatThousandthNumber(value)}</span>
  },
  {
    key: "power",
    dataIndex: "power",
    title: t(`vesselArrival.power`),
    width: 200,
    ellipsis: true,
    align: "center",
    render: (value, record, index) => <span>{formatThousandthNumber(value)}</span>
  },
  {
    key: "minPitch",
    dataIndex: "minPitch",
    title: t(`vesselArrival.minPitch`),
    width: 200,
    ellipsis: true,
    align: "center",
    render: (value, record, index) => <span>{formatThousandthNumber(value)}</span>
  },
  {
    key: "epl",
    dataIndex: "epl",
    title: t(`vesselArrival.epl`),
    width: 200,
    ellipsis: true,
    align: "center",
    render: (value, record, index) => <span>{formatThousandthNumber(value)}</span>
  }
];
