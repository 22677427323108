import { ExcludeKeys, ExtendsKeys } from "@/types";
import { CargoBookLabelMap, CargoBookKeys } from "@/types/voyageManage/cargoBook/index";
import { CBPaymentTermsFormChangeEvent } from "../freightPayterm/type";

export type CBBasicKeys = ExtendsKeys<CargoBookKeys,
  "fixtureNo" | "chartererName" | "chartererId" | "fixtureDate" | "layCanFrom" | "layCanTo" | "id"
>;

export type CBBasicNumKeys = ExtendsKeys<CBBasicKeys,
  "chartererId" | "layCanFrom" | "layCanTo" | "id"
>;

export type CBBasicDateKeys = ExtendsKeys<CBBasicKeys,
  "layCanFrom" | "layCanTo"
>;

export type CBBasicStrKeys = ExcludeKeys<CBBasicKeys, CBBasicNumKeys>;

export type CBBasicFormType = Record<ExcludeKeys<CBBasicNumKeys,"id">, number | ""> &
  Record<CBBasicStrKeys, string> & {id?:number};
  // &
  // Record<CBBasicDateKeys, Dayjs | undefined>;

export type CBBasicFormKeys =   CBBasicStrKeys | CBBasicNumKeys;

/**
 * hooks type
 */

export type CBBasicFormChangeEvent = <K extends CBBasicFormKeys>(key: K, value: CBBasicFormType[K]) => void;

export type CBBasicFormCommitEvent = (item: Partial<CBBasicFormType>) => void;

/**
 * hfbasicprops type
 */

export type CBBasicFormProps = {
  item: CBBasicFormType;
  onChange: CBBasicFormChangeEvent;
  onCommit: CBBasicFormCommitEvent;
  onPaymentTermsChange:CBPaymentTermsFormChangeEvent;
  otherItem?:{
    paymentTerm:number | "";
  };
  type: "edit" | "newAdd" | "preOperation";
}
/**
 * init data
 */
export const initialBasicForm: CBBasicFormType = {
  fixtureNo: "",
  chartererName: "",
  chartererId: "",
  fixtureDate: "",
  layCanFrom: "",
  layCanTo: ""
}

export const checkCBBasicFormKeys:Partial<CBBasicFormKeys>[] = [
  "chartererName","fixtureDate"
]