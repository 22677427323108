import { useRef, useState } from "react";
import useReminder from "@/hook/useReminder";
import { PortCardType, PortReportsFormType, formNameType,MovementPortReportsProps } from "../type";
import {
  getMovementPortListAction,
  getMovementPortLogAction,
  saveMovementPortLogAction,
  analysisMovementPortLogAction,
  updateToMovementAction,
} from "@/action/voyageManage/portLog";
import { useAppDispatch } from "@/hook";
import { useNavigate } from "react-router-dom";

const usePortReports = (voyageId, onClose,onConfirm:MovementPortReportsProps['onConfirm']) => {
  const [loading, setLoading] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [active, setActive] = useState();
  const [portRotationId, setPortRotationId] = useState<"" | number>();
  const scrollNow = useRef<number>(0);
  const [uploadLoading, setUploadLoading] = useState(false);
  const uploadStatus = useRef<"uploading" | "static">("static");
  const [deleteIds, setDeleteIds] = useState<{
    deleteDetailRemarkIds: number[];
    deleteOtherRemarkIds: number[];
    deleteBunkerLogIds: number[];
  }>({
    deleteDetailRemarkIds: [],
    deleteOtherRemarkIds: [],
    deleteBunkerLogIds: [],
  });
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const [portList, setPortList] = useState<PortCardType[]>([]);
  const [currentPort, setCurrentPort]= useState<PortCardType|null>()
  const { reminder } = useReminder();
  const resetPortLog = () => {
    return {
      basicInfoForm: {
        id: null,
        portRotationId: portRotationId||null,
        dateTime: "",
        hoursSinceLastPort: "",
        ttlWorkDay:'',
        workingHoursLastDay: "",
        vesselName: "",
        ETCD: "",
        nameOfPort: "",
        nameOfBerth: "",
        arrivalDateTime: "",
        norTendered: "",
        vslAnchored: "",
        anchorAweigh: "",
        pob: "",
        firstLineAshore: "",
        allMadeFast: "",
        initialDraftSurveyCommenced: "",
        initialDraftSurveyCompleted: "",
        loadDishCommenced: '',
        loadDishCompleted: '',
        finalDraftSurveyCommenced: "",
        finalDraftSurveyCompleted: "",
      },
      stowagePlanForm: [],
      bunkerLogForm: [],
      detailDelaysForm: [],
      otherRemarksForm: [],
    };
  };
  const initForm = resetPortLog();
  const [portReportsForm, setPortReportsForm] =
    useState<PortReportsFormType>(initForm);

  const handleChange = (
    val,
    prop: string,
    formName: formNameType,
    index?: number
  ) => {
    const newForm = { ...portReportsForm[formName], [prop]: val,portRotationId };
    // basicForm修改
    setPortReportsForm({
      ...portReportsForm,
      [formName]: newForm,
    });
    if (index != null) {
      // 除了basicForm的其他列表修改
      const newTableData = JSON.parse(
        JSON.stringify(portReportsForm[formName])
      );
      newTableData.splice(index, 1, {
        ...portReportsForm[formName][index],
        [prop]: val,
      });
      setPortReportsForm({ ...portReportsForm, [formName]: newTableData });
    }
  };
  const handleCommit = (item,formName: formNameType)=>{
    if(formName=='basicInfoForm'){
      const newForm = {...portReportsForm[formName],...item}
      setPortReportsForm({ ...portReportsForm, [formName]: newForm });
    }
  }
  const handleAdd = (row, formName: formNameType) => {
    const newTableData = JSON.parse(JSON.stringify(portReportsForm[formName]));
    newTableData.push({...row,portRotationId:portRotationId});
    // debugger
    setPortReportsForm({ ...portReportsForm, [formName]: newTableData });
  };
  const handleDelete = (
    index,
    formName: "otherRemarksForm" | "detailDelaysForm" | "bunkerLogForm"
  ) => {
    const newTableData = JSON.parse(JSON.stringify(portReportsForm[formName]));
    // debugger
    if (portReportsForm[formName][index].id) {
      const id = portReportsForm[formName][index].id;
      if (formName == "bunkerLogForm") {
        const list =
          JSON.parse(JSON.stringify(deleteIds.deleteBunkerLogIds)) || [];
        list.push(id);
        setDeleteIds({ ...deleteIds, deleteBunkerLogIds: list });
      }
      if (formName == "detailDelaysForm") {
        const list =
          JSON.parse(JSON.stringify(deleteIds.deleteDetailRemarkIds)) || [];
        list.push(id);
        setDeleteIds({ ...deleteIds, deleteDetailRemarkIds: list });
      }
      if (formName == "otherRemarksForm") {
        const list =
          JSON.parse(JSON.stringify(deleteIds.deleteOtherRemarkIds)) || [];
        list.push(id);
        setDeleteIds({ ...deleteIds, deleteOtherRemarkIds: list });
      }
    }
    newTableData.splice(index, 1);
    setPortReportsForm({ ...portReportsForm, [formName]: newTableData });
  };
  const changeTab = (v) => {
    setActive(v);
    const table = document.getElementsByClassName("port-reports-tables"); //元素
    const scrollHeight = table[0].scrollHeight; //在没有滚动条时适配的高度（实际高度)
    const clientHeight = table[0].clientHeight; //可视区域的高度
    const distanceMax = scrollHeight - clientHeight;
    scrollNow.current =
      (+v - 1) * 440 > distanceMax ? distanceMax : (+v - 1) * 440;
    table[0].scrollTop = scrollNow.current;
  };
  const portLogApiError = (error) => {
    uploadStatus.current = "static";
    setLoading(false)
    reminder(
      "error",
      error?.msg ? error?.msg + ": " + error?.data : error?.data
    );
  };
  const setMovementPortList = (res) => {
    const list = res.data.map((item) => {
      return item;
    });
    setPortList(list);
  };
  const getMovementPortList = (action:'init'|'other' = 'other') => {
    getMovementPortListAction(
      voyageId,
      () => {
        setLoading(true)
      },
      (res)=>{
        if(action=='init'){
          setMovementPortList(res)
          handleSelectPort({id:res.data[0].id})
          setCurrentPort(res.data[0])
        }else{
          setLoading(false)
          setMovementPortList(res)
        }
      },
      portLogApiError,
      dispatch,
      navigate
    );
  };
  const setMovementPortLog = (res) => {
    if (res.data.length == 0) {
      // reminder("warning", "There is still no port log");
      const initForm = resetPortLog();
      setPortReportsForm(initForm);
      return;
    }
    const stowagePlanForm =
      res.data.stowagePlanVoList?.map((item) => {
        const daysPlan = {};
        if (item.plans && item.plans.length != 0) {
          item.plans.map((ele) => {
            daysPlan[ele.planDay] = ele.dayPlan;
          });
        }
        return {
          ...item,
          ...daysPlan,
        };
      }) || [];
    const data = {
      basicInfoForm: res.data.basic,
      stowagePlanForm,
      bunkerLogForm: res.data.bunkerLogList || [],
      detailDelaysForm: res.data.detailRemarkList || [],
      otherRemarksForm: res.data.otherRemarkList || [],
    };
    setPortReportsForm(data);
  };
  const handleSelectPort = (item: PortCardType | { id: number }) => {
    if (!item.id) return;
    setPortRotationId(item.id);
    getMovementPortLogAction(
      item.id,
      () => {
        setLoading(true)
      },
      (res)=>{        
        setLoading(false)
        setMovementPortLog(res)
      },
      portLogApiError,
      dispatch,
      navigate
    );
  };
  const editMovementPortLog = () => {
    if(!portRotationId){
      reminder("warning", "Please choose a port first");
      return;
    }
    const stowagePlanVoList = portReportsForm.stowagePlanForm.map((item) => {
      const plans = [];
      for (const [k, v] of Object.entries(item)) {
        if (k.indexOf("-") != -1) {
          plans.push({
            id: item.plans.find(ele=>ele.planDay == k).id,
            dayPlan: v,
            planDay: k,
            portRotationId: portRotationId,
            warehouse:item.warehouse
          });
          delete item[k];
        }
      }
      item.plans = plans;
      return item;
    });
    const param = {
      basic: portReportsForm.basicInfoForm,
      stowagePlanVoList,
      bunkerLogList: portReportsForm.bunkerLogForm,
      detailRemarkList: portReportsForm.detailDelaysForm,
      otherRemarkList: portReportsForm.otherRemarksForm,
      portRotationId,
      ...deleteIds,
    };
    // console.log(param)
    // return
    saveMovementPortLogAction(
      param,
      () => {        
        setLoading(true)
      },
      ()=>{
        setLoading(false)
        onClose()
      },
      portLogApiError,
      dispatch,
      navigate
    );
  };
  const onUpload = (file) => {
    if(!portRotationId) return
    uploadStatus.current = "uploading";
    const formData = new FormData();
    formData.append("file", file);
    const id = portRotationId;
    formData.append("portRotationId", id.toString());
    analysisMovementPortLogAction(
      formData,
      () => {
        setLoading(true)
      },
      () => {
        uploadStatus.current = "static";
        const idx = portList.findIndex((ele) => ele.id == portRotationId);
        const list = JSON.parse(JSON.stringify(portList));
        list[idx].status = "Not";
        setPortList(list);
        setLoading(false)
        handleSelectPort({ id: portRotationId });
      },
      portLogApiError,
      dispatch,
      navigate
    );
  };
  const updateMovement = () => {
    if (!portRotationId) return;
    updateToMovementAction(
      { voyageId, portRotationId: portRotationId },
      () => {
        setLoading(true)
      },
      (res)=>{
        setLoading(false)
        reminder("success",'Update Success');
        onConfirm('portLog',res.data)
      },
      portLogApiError,
      dispatch,
      navigate
    );
  };
  return {
    loading,
    isOpen,
    active,
    changeTab,
    portList,
    portReportsForm,
    handleChange,
    handleAdd,
    handleDelete,
    getMovementPortList,
    handleSelectPort,
    editMovementPortLog,
    uploadStatus,
    onUpload,
    updateMovement,
    portRotationId,
    setPortRotationId,
    scrollNow,
    resetPortLog,
    setPortReportsForm,
    setCurrentPort,
    currentPort,
    handleCommit
  };
};
export default usePortReports;
