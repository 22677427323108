import { useState } from "react"
import { HandoverFormTcvcOtherItemChangeEvent, HandoverFormTcvcOtherItemCommitEvent, HandoverFormTcvcOtherItemType } from "../type"
import { initialHandoverFormOtherItem } from "../source"

const checkKeys: Partial<keyof HandoverFormTcvcOtherItemType>[] = [
  "voyageNo",
  "presentVesselMovement",
  "presentPortAgentInfo",
  "shipContactDetail",
  // "physicalSupplierName",
  // "bunkerTimeBar"
]

const useIndex = () => {
  const [dataSource, setDataSource] = useState<HandoverFormTcvcOtherItemType>({ ...initialHandoverFormOtherItem() });

  const change: HandoverFormTcvcOtherItemChangeEvent = (key, value) => {
    setDataSource(prev => ({ ...prev, [key]: value }))
  }

  const commit: HandoverFormTcvcOtherItemCommitEvent = (item) => {
    setDataSource(prev => ({ ...prev, ...item }))
  }

  const init: HandoverFormTcvcOtherItemCommitEvent = (item) => {
    setDataSource(initialHandoverFormOtherItem(item))
  }

  const check = () => {
    let checkKey = "";
    let checked = true;
    for (let key of checkKeys) {
      if (dataSource[key] !== 0 && !dataSource[key]) {
        checkKey = key;
        checked = false;
        break
      }
    }

    return {
      checkKey,
      checked
    }
  }

  return {
    dataSource,
    change,
    commit,
    init,
    check
  }
}

export default useIndex