import { FleetCascaderOption } from "@/components/Cascader";
import { FleetOptionType } from "@/types/advancedType";
import { CommonContactItemType } from "./type";

export const contactBusinessOption: FleetOptionType<
	CommonContactItemType["businessType"],
	CommonContactItemType["businessType"]
>[] = [
	{
		label: "Owner",
		value: "Owner"
	},
	{
		label: "Broker",
		value: "Broker"
	},
	{
		label: "Charterer",
		value: "Charterer"
	},
	{
		label: "Port Agent",
		value: "Port Agent"
	},
	{
		label: "Shipper",
		value: "Shipper"
	},
	{
		label: "Physical Supplier",
		value: "Physical Supplier"
	},
	{
		label: "Others",
		value: "Others"
	}
];
