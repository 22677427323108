import { Key } from "react";
import { CargoBookKeys, PortRotationKeys, CargoBookLabelMap, PortLabelMap } from ".";
import { ColumnsType } from "antd/es/table/interface";
import { ExcludeKeys, ExtendsKeys, GroupOptionItemType, GroupOptionsType } from "@/types";
import { dateFormat } from "@/tools/date";
import React from "react";

/**
 * 表格列筛选分类名
 */
export type CBCategoryKeys =
  "Basic" |
  "FreightPaymentTerms" |
  "CargoInfo" |
  // "Laytime" |
  "Setup";


/**
 * 表格列
 */

export type CBGroupColumnKeys = ExtendsKeys<CargoBookKeys,
  "chartererName" |
  "fixtureDate" |
  "fixtureNo" |
  "cargoName" |
  "cargoQuantity" |
  "cargoUnit" |
  "layCanFrom" |
  "layCanTo" |
  "loadPortCode" |
  "loadPortName" |
  "discharingPortCode" |
  "discharingPortName" |
  "freightType" |
  "freightRate" |
  "commission" |
  "brokerName" |
  "brokerage" |
  "paymentRate" |
  "paymentTerm" |
  "paymentStartDay" |
  "charteredBy" |
  "createdBy" |
  "operatedBy" |
  "updatedDate" |
  "status" |
  "id" |
  "voyageId" |
  "option"
>;

export type DataType = {
  key: Key;
} & Record<CBGroupColumnKeys, any>;



export const columns: ColumnsType<DataType> = [
  {
    dataIndex: "fixtureNo",
    width: 150,
    title: CargoBookLabelMap["fixtureNo"],
    key: "fixtureNo",
    align: "center",
    fixed: "left"
  },
  {
    dataIndex: "chartererName",
    width: 150,
    title: CargoBookLabelMap["chartererName"],
    key: "chartererName",
    align: "center",
    fixed: "left"
  },
  {
    dataIndex: "fixtureDate",
    width: 150,
    title: CargoBookLabelMap["fixtureDate"],
    key: "fixtureDate",
    align: "center",
    fixed: "left"
  },
  {
    dataIndex: "cargoName",
    width: 150,
    title: CargoBookLabelMap["cargoName"],
    key: "cargoName",
    align: "center"
  },
  {
    dataIndex: "cargoQuantity",
    width: 150,
    title: CargoBookLabelMap["cargoQuantity"],
    key: "cargoQuantity",
    align: "center"
  },
  {
    dataIndex: "cargoUnit",
    width: 150,
    title: CargoBookLabelMap["cargoUnit"],
    key: "cargoUnit",
    align: "center"
  },
  {
    dataIndex: "layCanFrom",
    width: 200,
    title: CargoBookLabelMap["layCanFrom"],
    key: "layCanFrom",
    align: "center",
    render:(value,record) => {
      return <span>{dateFormat("YY/mm/dd HH:MM",value)}</span>
    }
  },
  {
    dataIndex: "layCanTo",
    width: 200,
    title: CargoBookLabelMap["layCanTo"],
    key: "layCanTo",
    align: "center",
    render:(value,record) => {
      return <span>{dateFormat("YY/mm/dd HH:MM",value)}</span>
    }
  },
  // {
  //   dataIndex: "loadPortCode",
  //   width: 150,
  //   title: CargoBookLabelMap["loadPortCode"],
  //   key: "loadPortCode",
  //   align: "center"
  // },
  {
    dataIndex: "loadPortName",
    width: 150,
    title: CargoBookLabelMap["loadPortName"],
    key: "loadPortName",
    align: "center"
  },
  // {
  //   dataIndex: "discharingPortCode",
  //   width: 150,
  //   title: CargoBookLabelMap["discharingPortCode"],
  //   key: "discharingPortCode",
  //   align: "center"
  // },
  {
    dataIndex: "discharingPortName",
    width: 200,
    title: CargoBookLabelMap["discharingPortName"],
    key: "discharingPortName",
    align: "center"
  },
  {
    dataIndex: "freightType",
    width: 150,
    title: CargoBookLabelMap["freightType"],
    key: "freightType",
    align: "center"
  },
  {
    dataIndex: "freightRate",
    width: 150,
    title: CargoBookLabelMap["freightRate"],
    key: "freightRate",
    align: "center"
  },
  {
    dataIndex: "commission",
    width: 150,
    title: CargoBookLabelMap["commission"],
    key: "commission",
    align: "center"
  },
  {
    dataIndex: "brokerName",
    width: 150,
    title: CargoBookLabelMap["brokerName"],
    key: "brokerName",
    align: "center"
  },
  {
    dataIndex: "brokerage",
    width: 150,
    title: CargoBookLabelMap["brokerage"],
    key: "brokerage",
    align: "center"
  },
  {
    dataIndex: "paymentRate",
    width: 200,
    title: CargoBookLabelMap["paymentRate"],
    key: "paymentRate",
    align: "center"
  },
  {
    dataIndex: "paymentTerm",
    width: 200,
    title: CargoBookLabelMap["paymentTerm"],
    key: "paymentTerm",
    align: "center"
  },
  {
    dataIndex: "paymentStartDay",
    width: 150,
    title: CargoBookLabelMap["paymentStartDay"],
    key: "paymentStartDay",
    align: "center"
  },
  {
    dataIndex: "charteredBy",
    width: 150,
    title: CargoBookLabelMap["charteredBy"],
    key: "charteredBy",
    align: "center"
  },
  {
    dataIndex: "createdBy",
    width: 150,
    title: CargoBookLabelMap["createdBy"],
    key: "createdBy",
    align: "center"
  },
  {
    dataIndex: "operatedBy",
    width: 150,
    title: CargoBookLabelMap["operatedBy"],
    key: "operatedBy",
    align: "center"
  },
  {
    dataIndex: "updatedDate",
    width: 150,
    title: CargoBookLabelMap["updatedDate"],
    key: "updatedDate",
    align: "center"
  },
  {
    dataIndex: "status",
    width: 150,
    title: CargoBookLabelMap["status"],
    key: "status",
    align: "center"
  }
];

/**
 * 列筛选分类
 */

export type CBGroupOptionItemKeys = ExtendsKeys<
  CBGroupColumnKeys,
  "chartererName" |
  "fixtureDate" |
  "fixtureNo" |
  "cargoName" |
  "cargoQuantity" |
  "cargoUnit" |
  "layCanFrom" |
  "layCanTo" |
  "loadPortName" |
  "discharingPortName" |
  "freightType" |
  "freightRate" |
  "commission" |
  "brokerName" |
  "brokerage" |
  "paymentRate" |
  "paymentTerm" |
  "paymentStartDay" |
  "charteredBy" |
  "createdBy" |
  "operatedBy" |
  "updatedDate" |
  "status" |
  "option"
>;

export type CBGroupOptionItemType = GroupOptionItemType<CBGroupOptionItemKeys | ExtendsKeys<CBGroupColumnKeys, "id">>;

export type CBGroupOptionsType = GroupOptionsType<CBCategoryKeys, CBGroupOptionItemKeys | ExtendsKeys<CBGroupColumnKeys, "id">>;


export const CBGroupOptions: CBGroupOptionsType[] = [
  {
    key: "Basic",
    basicDisabled: true,
    list: [
      {
        "value": "chartererName",
        "label": CargoBookLabelMap["chartererName"],
        disabled: true
      },
      {
        "value": "fixtureDate",
        "label": CargoBookLabelMap["fixtureDate"],
        disabled: true
      },
      {
        "value": "fixtureNo",
        "label": CargoBookLabelMap["fixtureNo"],
        disabled: true
      }
    ]
  },
  {
    key: "CargoInfo",
    basicDisabled: false,
    list: [
      {
        "value": "cargoName",
        "label": CargoBookLabelMap["cargoName"],
        disabled: true
      },
      {
        "value": "cargoQuantity",
        "label": CargoBookLabelMap["cargoQuantity"],
        disabled: true
      },
      {
        "value": "cargoUnit",
        "label": CargoBookLabelMap["cargoUnit"]
      },
      {
        "value": "layCanTo",
        "label": CargoBookLabelMap["layCanTo"],
        disabled: true
      },
      {
        "value": "layCanFrom",
        "label": CargoBookLabelMap["layCanFrom"],
        disabled: true
      },
      {
        "value": "loadPortName",
        "label": CargoBookLabelMap["loadPort"],
        disabled: true
      },
      {
        "value": "discharingPortName",
        "label": CargoBookLabelMap["discharingPort"],
        disabled: true
      },
      {
        "value": "freightType",
        "label": CargoBookLabelMap["freightType"],
      },
      {
        "value": "freightRate",
        "label": CargoBookLabelMap["freightRate"]
      },
      {
        "value": "commission",
        "label": CargoBookLabelMap["commission"]
      },
      {
        "value": "brokerName",
        "label": CargoBookLabelMap["brokerName"]
      },
      {
        "value": "brokerage",
        "label": CargoBookLabelMap["brokerage"]
      },
    ]
  },
  {
    key: "FreightPaymentTerms",
    basicDisabled: false,
    list: [
      {
        "value": "paymentTerm",
        "label": CargoBookLabelMap["paymentTerm"]
      },
      {
        "value": "paymentRate",
        "label": CargoBookLabelMap["paymentRate"]
      },
      {
        "value": "paymentStartDay",
        "label": CargoBookLabelMap["paymentStartDay"]
      }
    ]
  },
  {
    key: "Setup",
    basicDisabled: true,
    list: [
      {
        "value": "createdBy",
        "label": CargoBookLabelMap["createdBy"]
      },
      {
        "value": "charteredBy",
        "label": CargoBookLabelMap["charteredBy"]
      },
      {
        "value": "operatedBy",
        "label": CargoBookLabelMap["operatedBy"]
      },
      {
        "value": "updatedDate",
        "label": CargoBookLabelMap["updatedDate"]
      },
      {
        "value": "status",
        "label": CargoBookLabelMap["status"],
        disabled: true
      }
    ]
  }
];

/**
 * 分类筛选
 */

export type CBCategoryGroupType = Record<CBCategoryKeys, CBGroupColumnKeys[]>;

export const initCBCategoryGroup: CBCategoryGroupType = {
  "Basic": ["chartererName", "fixtureDate", "fixtureNo"],
  "CargoInfo": [
    "cargoName",
    "cargoQuantity",
    "cargoUnit",
    "layCanFrom",
    "layCanTo",
    "loadPortName",
    "discharingPortName"
  ],
  "FreightPaymentTerms": [],
  "Setup": ["status"]
}