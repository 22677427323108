import { CommonChangeEvent, CommonCommitEvent } from "@/types/event";
import { useState } from "react";

const useCommonIndex = <T>(initItem: (item?: Partial<T>) => T, checkKeys: (keyof T)[] = []) => {
	const [dataSource, setDataSource] = useState<T>(initItem());

	const change: CommonChangeEvent<T> = (key, value) => {
		setDataSource((prev) => ({ ...prev, [key]: value }));
	};

	const commit: CommonCommitEvent<T> = (item) => {
		setDataSource((prev) => ({ ...prev, ...item }));
	};

	const init: CommonCommitEvent<T> = (item) => {
		console.log("CommonCommitEvent", item, initItem(item));
		setDataSource(initItem(item));
	};

	const check = () => {
		let checkKey: keyof T;
		let checked = true;
		for (let key of checkKeys) {
			if (dataSource[key] !== 0 && !dataSource[key]) {
				checked = false;
				checkKey = key;
				break;
			}
		}

		return {
			checkKey,
			checked
		};
	};

	return {
		dataSource,
		setDataSource,
		change,
		commit,
		init,
		check
	};
};

export default useCommonIndex;
