import React, { FC } from "react";
import style from "./index.module.less";
import { InvoicesVoyageListProps } from "./type";
import useInfinityScroll from "@/hook/common/useInfiniteScroll";
import useIndex from "./hooks/useIndex";
import { FleetLoading } from "@/components";
import { InvoicesVoyageFilter, InvoicesVoyageItem } from "./components";

const InvoicesVoyageList: FC<InvoicesVoyageListProps> = ({ activeVoyageItem, onVoyageSelect }) => {
	const {
		loading,
		hasMore,
		dataSource,
		activeStatus,
		onSearch,
		loadVoyageGroup,
		handleStatusSelect,
		handleVoyageItemSelect
	} = useIndex(activeVoyageItem, onVoyageSelect);

	const { scrollRef } = useInfinityScroll(hasMore, loadVoyageGroup);
	return (
		<div className={style["invoicesVoyage-list"]}>
			<FleetLoading loading={loading} />
			<InvoicesVoyageFilter
				activeStatus={activeStatus}
				onSearch={onSearch}
				onStatusSelect={handleStatusSelect}
			/>
			<div className="voyageList fleet-nonScrolBar" id="voyageList" ref={scrollRef}>
				<div className="voyageList-scrollArea">
					{dataSource?.map((item, idx) => {
						return (
							<InvoicesVoyageItem
								key={idx}
								item={item}
								activeItem={activeVoyageItem}
								onSelect={handleVoyageItemSelect}
							/>
						);
					})}
				</div>
			</div>
		</div>
	);
};

export default InvoicesVoyageList;
