import { CBBasicFormChangeEvent, CBBasicFormCommitEvent, CBBasicFormType, checkCBBasicFormKeys, initialBasicForm } from "@/featrue/voyagemanage/cargoBook/basic/type";
import { useState } from "react";

const useBasicForm = () => {
  const [basicForm, setBasicForm] = useState<CBBasicFormType>({
    ...initialBasicForm
  });

  const change: CBBasicFormChangeEvent = (key, value) => {
    setBasicForm(prev => {
      return {
        ...prev,
        [key]: value
      }
    })
  }

  const commit: CBBasicFormCommitEvent = (item) => {
    setBasicForm(prev => {
      return {
        ...prev,
        ...item
      }
    })
  }

  const clear = () => {
    setBasicForm({
      ...initialBasicForm
    })
  }

  const check = () => {
    let checkKey = "";
    let checked = true;
    for(let item of checkCBBasicFormKeys) {
      if(basicForm[item] !== 0 && !basicForm[item]) {
        checkKey = item;
        checked = false;
        break;
      }
    }

    return {
      checked,
      checkKey
    }
  }

  return {
    basicForm,
    check,
    change,
    commit,
    clear
  }
}

export default useBasicForm