import {
  type ParameterType
} from "@/api";
import { loginOut, loginOutAction } from "@/store/userSlice";

import {
  getTaskApi,
  getTaskGroupApi,
  deleteTaskApi,
  addTaskApi,
  updateTaskApi,
  getTaskGroupParams,
  getTaskParams,
  deleteTaskParams,
  addTaskParams,
  updateTaskParams
} from "@/api/dataBase/task"

export const getTaskGroupAction: ParameterType<getTaskGroupParams
> = (
  params,
  frontCallback,
  successCallback,
  errorCallback,
  dispatch,
  navigate
) => {

    frontCallback?.();
    getTaskGroupApi(params).then(response => {
      successCallback?.(response)
    }).catch(err => {
      errorCallback?.(err);
      if (err?.loginStatus === 'token faild') {
        // dispatch?.(loginOut({}));
        // navigate?.('/')
        dispatch(loginOutAction(() => navigate?.("/login")))
      }
    })
  }

export const getTaskAction: ParameterType<getTaskParams
> = (
  params,
  frontCallback,
  successCallback,
  errorCallback,
  dispatch,
  navigate
) => {
    frontCallback?.();
    getTaskApi(params).then(response => {
      successCallback?.(response)
    }).catch(err => {
      errorCallback?.(err);
      if (err?.loginStatus === 'token faild') {
        // dispatch?.(loginOut({}));
        // navigate?.('/')
        dispatch(loginOutAction(() => navigate?.("/login")))
      }
    })
  }

export const deleteTaskAction: ParameterType<deleteTaskParams
> = (
  params,
  frontCallback,
  successCallback,
  errorCallback,
  dispatch,
  navigate
) => {
    frontCallback?.();
    deleteTaskApi(params).then(response => {
      successCallback?.(response)
    }).catch(err => {
      errorCallback?.(err);
      if (err?.loginStatus === 'token faild') {
        // dispatch?.(loginOut({}));
        // navigate?.('/')
        dispatch(loginOutAction(() => navigate?.("/login")))
      }
    })
  }

export const addTaskAction: ParameterType<addTaskParams
> = (
  params,
  frontCallback,
  successCallback,
  errorCallback,
  dispatch,
  navigate
) => {
    frontCallback?.();
    addTaskApi(params).then(response => {
      successCallback?.(response)
    }).catch(err => {
      errorCallback?.(err);
      if (err?.loginStatus === 'token faild') {
        // dispatch?.(loginOut({}));
        // navigate?.('/')
        dispatch(loginOutAction(() => navigate?.("/login")))
      }
    })
  }

export const updateTaskAction: ParameterType<updateTaskParams
> = (
  params,
  frontCallback,
  successCallback,
  errorCallback,
  dispatch,
  navigate
) => {
    frontCallback?.();
    updateTaskApi(params).then(response => {
      successCallback?.(response)
    }).catch(err => {
      errorCallback?.(err);
      if (err?.loginStatus === 'token faild') {
        // dispatch?.(loginOut({}));
        // navigate?.('/')
        dispatch(loginOutAction(() => navigate?.("/login")))
      }
    })
  }