import React, { useEffect, useState } from "react";
import { PromptChildrenProps } from "@/common/Prompt";
import EditContract from "../../common/editContract";
import useAddHeadFixture from "@/hook/voyageManage/headFixture/useAddHeadFixture";
import HfBasicForm from "../basic";
import HfHireAndFeesForm from "../hireAndFees";
import HfPaymentTermsForm from "../paymentTerms";
import HfBunkerClause from "../bunkerClause";
import HfPerformance from "../performance";
import { editHeadFixtureAction, getHeadFixtureAction } from "@/action/voyageManage/headFixture";
import { useNavigate } from "react-router-dom";
import { useAppDispatch } from "@/hook";
import useReminder from "@/hook/useReminder";
import { editHeadFixtureParams } from "@/api/voyageManage/headFixture";

export type EditHeadFixtureProps = {
  recordId?: number;
  onReset?: () => void
} & PromptChildrenProps;

const EditHeadFixture: React.FC<EditHeadFixtureProps> = ({
  hide,
  recordId,
  onClose,
  onReset
}) => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { reminder } = useReminder()
  const {
    loading,
    setLoading,
    basicForm,
    handleBasicFormChange,
    handleBasicFormCommit,
    hireAndFeesForm,
    handleHireAndFeesFormChange,
    handleHireAndFeesFormCommit,
    bunkerClauseForm,
    handleBunkerClauseFormChange,
    handleBunkerClauseFormCommit,
    payTermForm,
    handlePayTermFormChange,
    handlePayTermFormCommit,
    performanceForm,
    handlePortChange,
    handleWorkChange,
    handlePerformanceFormChange,
    handlePortCommit,
    check,
    clear
  } = useAddHeadFixture();
  const [voyageId, setVoyageId] = useState<string | number>("");
  const initFixture = (data: Omit<editHeadFixtureParams, "id">) => {
    handleBasicFormCommit({
      cpNo: data?.cpNo ?? "",
      minDuration: data?.minDuration ?? "",
      maxDuration: data?.maxDuration ?? "",
      bale: data?.bale ?? "",
      dwt: data?.dwt ?? "",
      imo: data?.imo ?? "",
      grain: data?.grain ?? "",
      mmsi: data?.mmsi ?? "",
      charteredBy: data?.charteredBy ?? [],
      layCanFrom: data?.layCanFrom ?? undefined,
      layCanTo: data?.layCanTo ?? undefined,
      ownerName: data?.ownerName ?? "",
      vesselName: data?.vesselName ?? "",
      cpDate: data?.cpDate ?? "",
      vesselNameCn: data?.vesselNameCn ?? "",
      vesselNameEn: data?.vesselNameEn ?? "",
      tradingArea: data?.tradingArea ?? "",
      deliveryPortCode: data?.deliveryPortCode ?? "",
      deliveryPortName: data?.deliveryPortName ?? "",
      deliveryDate: data?.deliveryDate ?? "",
      estRedeliveryDate: data?.estRedeliveryDate ?? "",
      redeliveryPortCode: data?.redeliveryPortCode ?? "",
      redeliveryPortName: data?.redeliveryPortName ?? "",
      charateList:data?.charateList ?? [],
    });
    handleHireAndFeesFormCommit({
      dailyHire: data?.dailyHire ?? "",
      commission: data?.commission ?? "",
      brokerId: data?.brokerId ?? "",
      brokerage: data?.brokerage ?? "",
      brokerName: data?.brokerName ?? "",
      ilohc: data?.ilohc ?? "",
      cve: data?.cve ?? "",
    });
    handleBunkerClauseFormCommit({
      hsFoQuantity: data?.hsFoQuantity ?? "",
      lsFoQuantity: data?.lsFoQuantity ?? "",
      mdoQuantity: data?.mdoQuantity ?? "",
      mgoQuantity: data?.mgoQuantity ?? "",
      borAbout: data?.borAbout ?? 5,
      borPrice: data?.borPrice || "same",
      bodPrice: data?.bodPrice ?? "",
      hsFoPrice: data?.hsFoPrice ?? "",
      lsFoPrice: data?.lsFoPrice ?? "",
      mdoPrice: data?.mdoPrice ?? "",
      mgoPrice: data?.mgoPrice ?? "",
      bunkerClause: data?.bunkerClause ?? "",
      goodWeatherDef: data?.goodWeatherDef ?? "",
    });
    handlePayTermFormCommit({
      // paymentRate: data?.paymentRate ?? "",
      // paymentTerm: data?.paymentTerm ?? "",
      paymentStartDay: data?.paymentStartDay ?? "",
    });
    handlePortCommit({
      work: data?.performanceList?.work ?? performanceForm?.work,
      idle: data?.performanceList?.idle ?? performanceForm?.idle,
      ballast: data?.performanceList?.ballast ?? performanceForm?.ballast,
      laden: data?.performanceList?.laden ?? performanceForm?.laden,
      performanceClause: data?.performanceClause ?? ""
    })

    setVoyageId((data?.voyageId ?? ""))
  }

  const getHeadFixture = () => {
    getHeadFixtureAction(
      {
        id: recordId
      },
      getHeadFixtureFront,
      getHeadFixtureSuccess,
      editHeadFixtureError,
      dispatch,
      navigate
    )
  }

  const getHeadFixtureSuccess = (response) => {
    initFixture(response?.data)
    reminder("success", response?.msg);
    setLoading(false);
  }

  const editHeadFixtureFront = () => {
    setLoading(true);
  }

  const getHeadFixtureFront = () => {
    clear()
    setLoading(true);
  }

  const editHeadFixtureSuccess = (response) => {
    reminder("success", response?.data);
    setLoading(false);
    onClose?.();
    onReset?.();
  }

  const editHeadFixtureError = (error) => {
    reminder("error", error?.msg ? (error?.msg + ": " + error?.data) : error?.data);
    setLoading(false);
  }

  const editHeadFixture = () => {
    if(!check()) return;
    const {
      performanceClause,
      ...restPerformanceForm
    } = performanceForm;
    editHeadFixtureAction(
      {
        ...basicForm,
        ...hireAndFeesForm,
        ...bunkerClauseForm,
        ...payTermForm,
        id: recordId,
        voyageId:voyageId,
        performanceClause: performanceClause,
        performanceList: restPerformanceForm,
      },
      editHeadFixtureFront,
      editHeadFixtureSuccess,
      editHeadFixtureError,
      dispatch,
      navigate
    )
  }

  useEffect(() => {
    if (hide) return;
    getHeadFixture()
  }, [hide, recordId])

  return <EditContract
    hide={hide}
    onClose={onClose}
    onSave={editHeadFixture}
    label="Head Fixture"
    loading={loading}
  >
    <HfBasicForm
      type="edit"
      item={basicForm}
      onChange={handleBasicFormChange}
      onCommit={handleBasicFormCommit}
    />
    <HfHireAndFeesForm
      item={hireAndFeesForm}
      onChange={handleHireAndFeesFormChange}
      onCommit={handleHireAndFeesFormCommit}
    />
    <HfPaymentTermsForm
      item={payTermForm}
      onChange={handlePayTermFormChange}
    />
    <HfBunkerClause
      item={bunkerClauseForm}
      onChange={handleBunkerClauseFormChange}
      onCommit={handleBunkerClauseFormCommit}
    />
    <HfPerformance
      item={performanceForm}
      onPortChange={handlePortChange}
      onWorkChange={handleWorkChange}
      onChange={handlePerformanceFormChange}
      onCommit={handlePortCommit}
    />
  </EditContract>
}

export default EditHeadFixture