import { FleetOptionType } from "@/types/advancedType";
import { InvoicesType } from "./type";

export const invoicesTcvcTypeOptions: FleetOptionType<
	"Freight INV" | "Statement of Hire",
	InvoicesType
>[] = [
	{ label: "Freight INV", value: "freightInv" },
	{ label: "Statement of Hire", value: "statement" }
];

export const invoicesTctcTypeOptions: FleetOptionType<"Charter In" | "Relet", InvoicesType>[] = [
	{ label: "Charter In", value: "rent" },
	{ label: "Relet", value: "relet" }
];