import { useEffect, useMemo, useRef, useState } from "react";
import {
	CommonOtherExpensesItemType,
	CommonOtherExpensesItemChangeEvnet,
	CommonOtherExpensesItemDeleteEvnet,
	CommonOtherExpensesProps,
	CommonOtherExpensesItemCommitEvnet
} from "../type";
import { initialOtherExpensesItem } from "../source";
import { useAppDispatch } from "@/hook";
import { useNavigate } from "react-router-dom";
import useReminder from "@/hook/useReminder";
import { onRequest } from "@/action";
import {
	VoyageManageContactOptionChangeEvent,
	VoyageManageContactOptionType
} from "@/pages/voyagemanage/business/preOp&movement/type";
import { initialContactOption } from "@/pages/voyagemanage/business/preOp&movement/source";
import { CommonContactItemType } from "@/common/Select";
import { addContactAction } from "@/action/dataBase/contact";

const useIndex = (
	type: CommonOtherExpensesProps["type"],
	hide: boolean,
	primaryId: CommonOtherExpensesProps["primaryId"],
	voyageType: CommonOtherExpensesProps["voyageType"],
	onConfirm: CommonOtherExpensesProps["onConfirm"],
	onClose?: () => void
) => {
	const dispatch = useAppDispatch();
	const navigate = useNavigate();
	const { reminder } = useReminder();
	const [loading, setLoading] = useState(false);
	const delIds = useRef<number[]>([]);
	const [dataSource, setDataSource] = useState<CommonOtherExpensesItemType[]>([]);

	const [contactOption, setContactOption] =
		useState<VoyageManageContactOptionType>(initialContactOption());

	const handleContactChange: VoyageManageContactOptionChangeEvent = (item) => {
		setContactOption((prev) => ({ ...prev, ...item }));
	};

	const summary = useMemo(() => {
		return dataSource?.reduce(
			(prev, curr) => {
				return {
					itemName: "Total",
					itemValue: +prev.itemValue + +curr.itemValue,
					ownerAccount: +prev.ownerAccount + +curr.ownerAccount,
					netExpense: +prev.netExpense + +curr.netExpense
				};
			},
			{
				itemName: "",
				itemValue: 0,
				ownerAccount: 0,
				netExpense: 0
			}
		);
	}, [dataSource]);

	const change: CommonOtherExpensesItemChangeEvnet = (currentIndex, key, value) => {
		setDataSource((prev) => {
			const currentItem = prev?.[currentIndex];
			prev?.splice(currentIndex, 1, {
				...currentItem,
				[key]: value
			});
			return [...prev];
		});
	};

	const commit: CommonOtherExpensesItemCommitEvnet = (currentIndex, item) => {
		debugger;
		setDataSource((prev) => {
			const currentItem = prev?.[currentIndex];
			prev?.splice(currentIndex, 1, {
				...currentItem,
				...item
			});
			return [...prev];
		});
	};

	const remove: CommonOtherExpensesItemDeleteEvnet = (currentIndex) => {
		setDataSource((prev) => {
			const currentItem = prev?.[currentIndex];
			if (currentItem?.id) {
				delIds.current.push(currentItem.id);
			}
			prev.splice(currentIndex, 1);
			return [...prev];
		});
	};

	const add = () => {
		setDataSource((prev) => {
			return [...prev, initialOtherExpensesItem({ voyageId: primaryId })];
		});
	};

	const getCommonOtherExpensesFront = () => {
		setLoading(true);
	};

	const getCommonOtherExpensesSuccess = (response) => {
		setLoading(false);
		setDataSource(response?.data);
	};

	const getCommonOtherExpensesFailed = (error) => {
		setLoading(false);
		reminder("error", error?.msg ? error?.msg + ": " + error?.data : error?.data);
	};

	const saveCommonOtherExpensesSuccess = (response) => {
		setLoading(false);
		onConfirm?.(dataSource);
		onClose?.();
	};

	const getCommonOtherExpenses = () => {
		onRequest(
			type === "est"
				? "getEstOtherExpensesApi"
				: type === "movement"
					? "getMovementOtherExpensesApi"
					: "getPreopOtherExpensesApi",
			{ id: primaryId, voyageType },
			getCommonOtherExpensesFront,
			getCommonOtherExpensesSuccess,
			getCommonOtherExpensesFailed,
			dispatch,
			navigate
		);
	};

	const saveCommonOtherExpenses = () => {
		onRequest(
			type === "est"
				? "saveEstOtherExpensesApi"
				: type === "movement"
					? "saveMovementOtherExpensesApi"
					: "savePreopOtherExpensesApi",
			{
				bindId: primaryId,
				deleteIds: delIds?.current,
				otherExpenses: dataSource,
				voyageType
			},
			getCommonOtherExpensesFront,
			saveCommonOtherExpensesSuccess,
			getCommonOtherExpensesFailed,
			dispatch,
			navigate
		);
	};

	const handleContactAddFront = () => {
		handleContactChange({ loading: true });
	};

	const handleContactAddSuccess = (response) => {
		handleContactChange({ loading: false, hide: true, type: null });
		reminder("success", response?.msg);
	};

	const handleContactAddFailed = (error) => {
		handleContactChange({ loading: true });
		reminder("error", error?.msg ? error?.msg + ": " + error?.data : error?.data);
	};

	const handleContactAdd = (item: CommonContactItemType) => {
		addContactAction(
			item,
			handleContactAddFront,
			handleContactAddSuccess,
			handleContactAddFailed,
			dispatch,
			navigate
		);
	};

		useEffect(() => {
			if (hide) {
				delIds.current = [];
				return;
			}
			getCommonOtherExpenses();
		}, [hide]);

		return {
			loading,
			dataSource,
			summary,
			change,
			commit,
			remove,
			add,
			saveCommonOtherExpenses,
			contactOption,
			handleContactChange,
			handleContactAdd
		};
};

export default useIndex;
