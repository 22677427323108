import { getRoutePlanWayPointAction } from "@/action/monitoring/routePlan"
import { MutableRefObject, useEffect, useState } from "react"
import { RoutePlanDetailsType } from "../type";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import useReminder from "@/hook/useReminder";
import { MonitoringDetailMoreTabType } from "../../../type";

const useIndex = (
  id: number,
  activeIndex: MonitoringDetailMoreTabType,
  mapRef: MutableRefObject<any>
) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { reminder } = useReminder();
  const [loading, setLoading] = useState(false);
  const [dataSource, setDataSource] = useState<RoutePlanDetailsType[]>([]);

  const getRoutePlanWayPointFront = () => {
    setLoading(true)
  }

  const getRoutePlanWayPointSuccess = (response) => {
    setLoading(false)
    setDataSource(prev => {
      return response?.data ?? []
    })
  }

  const getRoutePlanWayPointFailed = (error) => {
    setLoading(false)
    reminder("error", error?.msg ? (error?.msg + ": " + error?.data) : error?.data);
  }

  const getRoutePlanWayPoint = () => {
    getRoutePlanWayPointAction(
      { id },
      getRoutePlanWayPointFront,
      getRoutePlanWayPointSuccess,
      getRoutePlanWayPointFailed,
      dispatch,
      navigate
    )
  }

  const handleFlyTo = (record: RoutePlanDetailsType) => {
		// mapRef?.current?.handleFlyTo([record?.lon, record?.lat])
	}

  useEffect(() => {
    if (activeIndex !== "routePlanDetails") return
    if (!id) return
    getRoutePlanWayPoint()
  }, [id, activeIndex])

  return {
    loading,
    dataSource,
    handleFlyTo
  }
}

export default useIndex