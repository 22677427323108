import { useAppDispatch } from "@/hook";
import useReminder from "@/hook/useReminder";
import { useNavigate } from "react-router-dom";
import { useEffect, useRef, useState } from "react";
import { EstCompareItemSelectEvent, EstCompareItemType } from "../components";
import { EstListCompareCacheType, EstListCompareProps } from "../type";
import { getEsCompareAction, saveEsCompareAction } from "@/action/estimation";
import { useTranslation } from "react-i18next";

const useIndex = (
  ids: string,
  hide: boolean,
  onConfirm: EstListCompareProps["onConfirm"],
  onClose: () => void
) => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { reminder } = useReminder();
  const { t } = useTranslation()

  const [loading, setLoading] = useState(false);
  const [dataSource, setDataSource] = useState<EstCompareItemType[]>([]);

  const [selectList, setSelectList] = useState<number[]>([]);
  const cache = useRef<EstListCompareCacheType>({});

  const check = () => {
    for (let item of selectList) {
      const checked = cache?.current?.[item]?.check();
      if(!checked) {
        return checked
      }
    }
    return true
  }

  const getEsCompareFront = () => {
    setLoading(true)
  }

  const getEsCompareSuccess = ({ data }) => {
    setDataSource(data ?? []);
    setSelectList(data?.map(item => item?.id))
    setLoading(false);
  }

  const getEsCompareError = (error) => {
    reminder("error", error.msg);
    setLoading(false)
  }

  const saveEsCompareSuccess = (response) => {
    setLoading(false);
    reminder("success", response?.msg);
    onConfirm?.()
    onClose?.()
  }

  const getEsCompare = () => {
    getEsCompareAction(
      { ids: ids },
      getEsCompareFront,
      getEsCompareSuccess,
      getEsCompareError,
      dispatch,
      navigate
    )
  }

  const saveEsCompare = () => {
    if(!check()) return
    const delIds = ids?.split(",")?.filter(selectId => !selectList.includes(+selectId))
    const resultList = selectList?.map(selectId => {
      const resultItem = cache?.current?.[selectId]
      return {
        cargoParticularList: [resultItem?.cargoParticularList],
        vesselParticular: resultItem?.vesselParticular,
        portRotationList: resultItem?.portRotationList,
        delPortIds: resultItem?.delPortIds?.current,
        ...resultItem?.otherForm,
        ...resultItem?.calculate,
        status: resultItem?.otherForm?.status === "DFT" ? "EST" : resultItem?.otherForm?.status,
      }
    })
    saveEsCompareAction(
      {
        delIds,
        resultList
      },
      getEsCompareFront,
      saveEsCompareSuccess,
      getEsCompareError,
      dispatch,
      navigate
    )
  }

  const handleSelect: EstCompareItemSelectEvent = (id, checked) => {
    switch (checked) {
      case true:
        setSelectList(prev => ([...prev, id]))
        break
      case false:
        setSelectList(prev => {
          const index = prev?.findIndex(selectId => selectId === id);
          prev?.splice(index, 1)
          return [...prev]
        })
        break
      default:
        break
    }
  }

  useEffect(() => {
    if (hide) return
    getEsCompare()
  }, [hide])

  return {
    loading,
    dataSource,
    cache,
    selectList,
    handleSelect,
    saveEsCompare
  }

}

export default useIndex