import React from "react";
import { RoutePlanModalProps } from "./type";
import { FleetPrompt } from "@/common";
import { cn } from "@/tools";
import { Button, Icon } from "@/_components";

const RoutePlanModal: React.FC<RoutePlanModalProps> = ({
	className,
	title,
	children,
	hide,
	onClose,
	onConfirm
}) => {
	return (
		<FleetPrompt hide={hide} mode="fixed">
			<div className={cn("p-[16px] bg-white rounded-md flex flex-col gap-[16px]", className)}>
				<div className="w-full flex justify-between items-center rounded-t-md">
					<span className="text-[#484848] text-[20px] font-medium leading-4 flex items-center before:content-[''] before:w-[6px] before:h-[14px] before:rounded-full before:bg-[#355691] before:inline-block before:mr-[8px]">
						{title}
					</span>
					<Icon type="fleet-close" onClick={onClose} size={14} className=" leading-4" />
				</div>
				{children}
				<div className="w-full flex justify-end items-center gap-[10px]">
					<Button size="small" type="secondary" onClick={onClose}>
						cancel
					</Button>
					<Button size="small" onClick={onConfirm}>
						confirm
					</Button>
				</div>
			</div>
		</FleetPrompt>
	);
};

export default RoutePlanModal;
