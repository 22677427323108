import React, { useEffect } from "react";
import style from "./index.module.less";
import { DatabaseVesselAddProps, VesselType, vesselTypeMap } from "./type";
import DatabaseCommonAdd from "../../layout/common/add";
import useVessel from "@/hook/database/vessel/useVessel";
import { FleetFormItem, FleetInput, FleetSelect, FleetVerifyInput } from "@/components";

const DatabaseVesselAdd: React.FC<DatabaseVesselAddProps> = ({
  hide,
  onClose,
  onReset
}) => {
  const {
    dataSource,
    performanceSource,
    change,
    commit,
    workChange,
    portChange,
    loading,
    clear,
    addVessel
  } = useVessel(onReset);

  useEffect(() => {
    if (hide) return
    clear()
  }, [hide])

  return <DatabaseCommonAdd
    hide={hide}
    onClose={onClose}
    onAdd={addVessel}
    title="Add Vessel"
    loading={loading}
    className={style["vesselAdd-performance"]}
  >
    <div className="commonAddItem">
      <span className="commonAddItem-label">Basis</span>
      <div className="commonAddItem-container">
        <FleetVerifyInput
          value={dataSource?.vesselNameEn}
          label="Vessel Name"
          needVerity
          onChange={(val: string) => {
            commit({
              // vesselName: val,
              // vesselNameCn: val,
              vesselNameEn: val
            })
          }}
        />
        <FleetVerifyInput
          value={dataSource?.exName1}
          label="Ex. Name"
          onChange={(val: string) => {
            commit({
              exName1: val,
              // exName2: val
            })
          }}
        />
        <FleetFormItem
          label="Vessel Type"
        >
          <FleetSelect
            options={vesselTypeMap}
            value={[dataSource?.vesselTypeLv1]}
            onChange={(val: VesselType) => {
              commit({
                vesselTypeLv1: val,
                // vesselTypeLv2: val
              })
            }}
          />
        </FleetFormItem>
        <FleetVerifyInput
          value={dataSource?.imo}
          label="IMO"
          type="number"
          needVerity
          onChange={(val: number) => {
            change("imo", val)
          }}
        />
        <FleetVerifyInput
          value={dataSource?.mmsi}
          label="MMSI"
          needVerity
          type="number"
          onChange={(val: number) => {
            change("mmsi", val)
          }}
        />
        <FleetVerifyInput
          value={dataSource?.callsign}
          label="Callsign"
          onChange={(val: string) => {
            change("callsign", val)
          }}
        />
        <FleetVerifyInput
          value={dataSource?.builder}
          label="Builder"
          onChange={(val: string) => {
            change("builder", val)
          }}
        />
        <FleetVerifyInput
          value={dataSource?.buildCountry}
          label="Country of Build"
          onChange={(val: string) => {
            change("buildCountry", val)
          }}
        />
        <FleetVerifyInput
          value={dataSource?.buildDate}
          label="Date of Built"
          onChange={(val: string) => {
            change("buildDate", val)
          }}
        />
        <FleetVerifyInput
          value={dataSource?.grt}
          label="GRT"
          type="number"
          onChange={(val: number) => {
            change("grt", val)
          }}
        />
        <FleetVerifyInput
          value={dataSource?.nrt}
          label="NRT"
          type="number"
          onChange={(val: number) => {
            change("nrt", val)
          }}
        />
        <FleetVerifyInput
          value={dataSource?.loa}
          label="LOA"
          type="number"
          onChange={(val: number) => {
            change("loa", val)
          }}
        />
      </div>
    </div>
    <div className="commonAddItem">
      <span className="commonAddItem-label">Calculation</span>
      <div className="commonAddItem-container">
        <FleetVerifyInput
          value={dataSource?.dwt}
          label="DWT"
          type="number"
          onChange={(val: number) => {
            change("dwt", val)
          }}
        />
        <FleetVerifyInput
          value={dataSource?.grain}
          label="Grain"
          type="number"
          onChange={(val: number) => {
            change("grain", val)
          }}
        />
        <FleetVerifyInput
          value={dataSource?.bale}
          label="Bale"
          type="number"
          onChange={(val: number) => {
            change("bale", val)
          }}
        />
        <FleetVerifyInput
          value={dataSource?.tpc}
          label="TPC"
          type="number"
          onChange={(val: number) => {
            change("tpc", val)
          }}
        />
        <FleetVerifyInput
          value={dataSource?.summerDraft}
          label="Summer Draft"
          type="number"
          onChange={(val: number) => {
            change("summerDraft", val)
          }}
        />
      </div>
    </div>
    <div className="commonAddItem">
      <span className="commonAddItem-label">Performance</span>
      <div className="performance-area">
        <div className="performance">
          <div className="performance-common performance-header">
            <span className="performance-item">L/D</span>
            <span className="performance-item">Speed</span>
            <span className="performance-item">HSFO</span>
            <span className="performance-item">LSFO</span>
            <span className="performance-item">MDO</span>
            <span className="performance-item">MGO</span>
          </div>
          <div className="performance-container">
            {
              performanceSource?.ballast?.map((item, idx) => {
                return <div className="performance-common performance-content" key={idx}>
                  <span className="performance-item">Ballast</span>
                  <div className="performance-item">
                    <FleetInput
                      placeholder="knot"
                      type="number"
                      value={item?.speed}
                      onChange={(val: number) => {
                        portChange?.("ballast", idx, "speed", val)
                      }}
                    />
                  </div>
                  <div className="performance-item">
                    <FleetInput
                      placeholder="MT/Day"
                      type="number"
                      value={item?.hsFo}
                      onChange={(val: number) => {
                        portChange?.("ballast", idx, "hsFo", val)
                      }}
                    />
                  </div>
                  <div className="performance-item">
                    <FleetInput
                      placeholder="MT/Day"
                      type="number"
                      value={item?.lsFo}
                      onChange={(val: number) => {
                        portChange?.("ballast", idx, "lsFo", val)
                      }}
                    />
                  </div>
                  <div className="performance-item">
                    <FleetInput
                      placeholder="MT/Day"
                      type="number"
                      value={item?.mdo}
                      onChange={(val: number) => {
                        portChange?.("ballast", idx, "mdo", val)
                      }}
                    />
                  </div>
                  <div className="performance-item">
                    <FleetInput
                      placeholder="MT/Day"
                      type="number"
                      value={item?.mgo}
                      onChange={(val: number) => {
                        portChange?.("ballast", idx, "mgo", val)
                      }}
                    />
                  </div>
                </div>
              })
            }
            {
              performanceSource?.laden?.map((item, idx) => {
                return <div className="performance-common performance-content" key={idx}>
                  <span className="performance-item">Laden</span>
                  <div className="performance-item">
                    <FleetInput
                      placeholder="knot"
                      type="number"
                      value={item?.speed}
                      onChange={(val: number) => {
                        portChange?.("laden", idx, "speed", val)
                      }}
                    />
                  </div>
                  <div className="performance-item">
                    <FleetInput
                      placeholder="MT/Day"
                      type="number"
                      value={item?.hsFo}
                      onChange={(val: number) => {
                        portChange?.("laden", idx, "hsFo", val)
                      }}
                    />
                  </div>
                  <div className="performance-item">
                    <FleetInput
                      placeholder="MT/Day"
                      type="number"
                      value={item?.lsFo}
                      onChange={(val: number) => {
                        portChange?.("laden", idx, "lsFo", val)
                      }}
                    />
                  </div>
                  <div className="performance-item">
                    <FleetInput
                      placeholder="MT/Day"
                      type="number"
                      value={item?.mdo}
                      onChange={(val: number) => {
                        portChange?.("laden", idx, "mdo", val)
                      }}
                    />
                  </div>
                  <div className="performance-item">
                    <FleetInput
                      placeholder="MT/Day"
                      type="number"
                      value={item?.mgo}
                      onChange={(val: number) => {
                        portChange?.("laden", idx, "mgo", val)
                      }}
                    />
                  </div>
                </div>
              })
            }
          </div>

        </div>
        <div className="performance">
          <div className="performance-common performance-header">
            <span className="performance-item">Port</span>
            <span className="performance-item"></span>
            <span className="performance-item"></span>
            <span className="performance-item"></span>
            <span className="performance-item"></span>
            <span className="performance-item"></span>
            <span className="performance-item"></span>
          </div>
          <div className="performance-container">
            <div className="performance-common performance-content">
              <span className="performance-item">Work</span>
              <div className="performance-item">
              </div>
              <div className="performance-item">
                <FleetInput
                  placeholder="MT/Day"
                  type="number"
                  value={performanceSource?.work?.hsFo}
                  onChange={(val: number) => {
                    workChange?.("work", "hsFo", val)
                  }}
                />
              </div>
              <div className="performance-item">
                <FleetInput
                  placeholder="MT/Day"
                  type="number"
                  value={performanceSource?.work?.lsFo}
                  onChange={(val: number) => {
                    workChange?.("work", "lsFo", val)
                  }}
                />
              </div>
              <div className="performance-item">
                <FleetInput
                  placeholder="MT/Day"
                  type="number"
                  value={performanceSource?.work?.mdo}
                  onChange={(val: number) => {
                    workChange?.("work", "mdo", val)
                  }}
                />
              </div>
              <div className="performance-item">
                <FleetInput
                  placeholder="MT/Day"
                  type="number"
                  value={performanceSource?.work?.mgo}
                  onChange={(val: number) => {
                    workChange?.("work", "mgo", val)
                  }}
                />
              </div>
            </div>
            <div className="performance-common performance-content">
              <span className="performance-item">Idle</span>
              <div className="performance-item">
              </div>
              <div className="performance-item">
                <FleetInput
                  placeholder="MT/Day"
                  type="number"
                  value={performanceSource?.idle?.hsFo}
                  onChange={(val: number) => {
                    workChange?.("idle", "hsFo", val)
                  }}
                />
              </div>
              <div className="performance-item">
                <FleetInput
                  placeholder="MT/Day"
                  type="number"
                  value={performanceSource?.idle?.lsFo}
                  onChange={(val: number) => {
                    workChange?.("idle", "lsFo", val)
                  }}
                />
              </div>
              <div className="performance-item">
                <FleetInput
                  placeholder="MT/Day"
                  type="number"
                  value={performanceSource?.idle?.mdo}
                  onChange={(val: number) => {
                    workChange?.("idle", "mdo", val)
                  }}
                />
              </div>
              <div className="performance-item">
                <FleetInput
                  placeholder="MT/Day"
                  type="number"
                  value={performanceSource?.idle?.mgo}
                  onChange={(val: number) => {
                    workChange?.("idle", "mgo", val)
                  }}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </DatabaseCommonAdd>
}

export default DatabaseVesselAdd