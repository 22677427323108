import { ArrivalVesselSubTypeMap } from "@/common/Select";
import { CommonChangeEvent, CommonCommitEvent } from "@/types/event";
import {
	RouteCommonCountrySelectItemType,
	RouteCommonPortSelectItemType,
	RouteCommonAreaSelectItemType
} from "./common";
import { FleetNumType } from "@/types/common";
import { MutableRefObject } from "react";

export type AreaVesselFilterVesselTypeSubType = ArrivalVesselSubTypeMap;

export enum AreaVesselFilterHeadingMap {
	Northeast = 1,
	Northwest,
	Southeast,
	Southwest
}

export enum AreaVesselFilterPurposeMap {
	Start = 1,
	Load,
	Bunker,
	Discharge,
	Repair,
	Other
}

type AreaVesselFilterVesselTimeRange = {
	startDate: string;
	endDate: string;
};

export type AreaVesselFilterItemType = {
	ageMax: FleetNumType;
	aisSpeedMin: FleetNumType;
	aisSpeedMax: FleetNumType;
	dwtMin: FleetNumType;
	dwtMax: FleetNumType;
	vesselTypeSub: AreaVesselFilterVesselTypeSubType[];
	headingList: AreaVesselFilterHeadingMap[];
	lastPurpose: AreaVesselFilterPurposeMap[];
	lastPortCountry: Pick<RouteCommonCountrySelectItemType, "countryCode" | "countryName">[];
	desPortList: Pick<RouteCommonPortSelectItemType, "portCode" | "portName">[];
	desPortCountry: Pick<RouteCommonCountrySelectItemType, "countryCode" | "countryName">[];
	areaList: Pick<RouteCommonAreaSelectItemType, "areaCode" | "areaName">[];
	startAreaList: Pick<RouteCommonAreaSelectItemType, "areaCode" | "areaName">[];
};

export type AreaVesselFilterProps = {
	timeRange: AreaVesselFilterVesselTimeRange;
	onTimeRangeCommit: (value: AreaVesselFilterVesselTimeRange) => void;
	item: AreaVesselFilterItemType;
	onCommit: CommonCommitEvent<
		Omit<AreaVesselFilterItemType, "ageMax" | "aisSpeedMin" | "aisSpeedMax" | "dwtMin" | "dwtMax">
	>;
	onSearch: CommonChangeEvent<
		Pick<AreaVesselFilterItemType, "ageMax" | "aisSpeedMin" | "aisSpeedMax" | "dwtMin" | "dwtMax">
	>;
	agxMaxInputRef: MutableRefObject<HTMLInputElement>;
	aisSpeedMinInputRef: MutableRefObject<HTMLInputElement>;
	aisSpeedMaxInputRef: MutableRefObject<HTMLInputElement>;
	dwtMinInputRef: MutableRefObject<HTMLInputElement>;
	dwtMaxInputRef: MutableRefObject<HTMLInputElement>;
};
