import { useAppDispatch } from "@/hook";
import { IdentifierMapCommitEvent, IdentifierMapType, ShippingMapCommitEvent } from "./useDetails"
import { useNavigate } from "react-router-dom";
import { getAisAction, getThirdPartyTrackAction } from "@/action/estimation/aisData";
import { useEffect, useRef, useState } from "react";
import { FleetAisSelectTimeKeys } from "@/common/AisTimeSelect/type";
import dayjs, { ManipulateType } from "dayjs";
import { FleetTrackPointItemType } from "@/common/AisData/type";

export type EstimationAisParamsType = {
  days: FleetAisSelectTimeKeys;
  speed: number;
}

export type EstimationAisVesselType = {
  dest: string;
  imo: string | number;
  speed: string | number;
  dwt: string | number;
  lastTime: string;
  eta: string;
  lat?: number,
  lon?: number,
}

const useTrack = (
  mmsi: string | number,
  identifierMap: IdentifierMapType,
  onIdentifierCommit: IdentifierMapCommitEvent,
  onShippingCommit: ShippingMapCommitEvent
) => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const [loading, setLoading] = useState(false);
  const trackUrl = useRef("");

  const [aisParams, setAisParams] = useState<EstimationAisParamsType>({
    days: "7/day",
    speed: 0
  });

  const [aisVesselItem, setAisVesselItem] = useState<EstimationAisVesselType>({
    dest: "",
    imo: "",
    speed: "",
    dwt: "",
    lastTime: "",
    eta: "",
    lat: undefined,
    lon: undefined
  });

  const [routeData, setRouteData] = useState<{
    dates: string[];
    speeds: FleetTrackPointItemType[]
  }>({
    dates: [],
    speeds: []
  })

  const change = <T extends "days" | "speed">(key: T, val: EstimationAisParamsType[T]) => {
    setAisParams(prev => {
      return {
        ...prev,
        [key]: val
      }
    })
  }

  const getAisSuccess = (response) => {
    // const { trackPoints, vesselStateNow, path } = response?.data;
    const { current, route, dates, speeds } = response?.data
    let identifier: any = {
      vesselPosition: (current?.lat && current?.lon) ? (identifierMap?.vesselPosition === -1 ? 1 : identifierMap?.vesselPosition + 1) : 0,
      vesselTrack: route?.ais?.features?.[0] && route?.ais?.features?.[0]?.geometry?.coordinates?.length > 0 ? (identifierMap?.vesselTrack === -1 ? 1 : identifierMap.vesselTrack + 1) : 0,
      trackPoints: (!route?.trackPoints || route?.trackPoints?.length === 0) ? 0 : (identifierMap?.trackPoints === -1 ? 1 : identifierMap?.trackPoints + 1)
    }
    let shiping: any = {
			vesselPosition: {
				lat: current?.lat,
				lon: current?.lon,
				heading: current?.heading
			},
			vesselTrack: route?.ais?.features?.[0] || {
				type: "Feature",
				properties: {},
				geometry: {
					type: "LineString",
					coordinates: []
				}
			},
			trackPoints: route?.trackPoints ?? undefined
		};

    console.log('current', current)

    setAisVesselItem({
      dest: current?.dest ?? "",
      imo: current?.imo ?? "",
      speed: current?.speed ?? "",
      dwt: current?.dwt ?? "",
      lastTime: current?.lastTime ?? "",
      eta: current?.eta ?? "",
      lat: current?.lat ?? undefined,
      lon: current?.lon ?? undefined
    });

    setRouteData({
      dates: dates ?? [],
      speeds: route?.trackPoints ?? []
    });

    onShippingCommit({
      ...shiping
    })

    onIdentifierCommit({
      ...identifier
    })
    setLoading(false)
  }


  const getAisFront = () => {
    setLoading(true);
  }

  const getAisError = (error) => {
    setLoading(false)
  }

  const getThirdPartyTrackSuccess = (response) => {
    const { ais, trackPoints } = response;
    let identifier: any = {
      vesselTrack: ais?.features?.[0] && ais?.features?.[0]?.geometry?.coordinates?.length > 0 ? (identifierMap?.vesselTrack === -1 ? 1 : identifierMap.vesselTrack + 1) : 0,
      trackPoints: (!trackPoints || trackPoints?.length === 0) ? 0 : (identifierMap?.trackPoints === -1 ? 1 : identifierMap?.trackPoints + 1)
    }
    let shiping: any = {
      vesselTrack: ais?.features?.[0] || {
        "type": "Feature",
        "properties": {},
        "geometry": {
          "type": "LineString",
          "coordinates": []
        }
      },
      trackPoints: trackPoints
    }
    onShippingCommit({
      ...shiping
    })

    onIdentifierCommit({
      ...identifier
    })
    setLoading(false)
  }

  const getAis = () => {
    const [count, rule] = aisParams?.days?.split("/") as [number, ManipulateType]
    const startTime = dayjs()?.subtract(count, rule)
    const diffDay = dayjs()?.diff(startTime, "day")
    console.log("day", diffDay, aisParams)
    getAisAction(
      { mmsi, days: diffDay },
      getAisFront,
      getAisSuccess,
      getAisError,
      dispatch,
      navigate
    )
  }

  useEffect(() => {
    if (!mmsi) return;
    getAis()
  }, [mmsi, aisParams?.days])


  return {
    aisParams,
    loading,
    aisVesselItem,
    routeData: {
      ...routeData,
      loading
    },
    change
  }

}

export default useTrack