import React, { FC } from "react";
import classnames from "classnames";
import style from "./index.module.less";
import { PortCallListFilterProps } from "./type";
import { FleetInput, FleetRangePicker, FleetSelect } from "@/components";
import dayjs, { Dayjs } from "dayjs";
import { Button } from "@/_components";

const PortCallListFilter: FC<PortCallListFilterProps> = ({
	vesselName,
	imo,
	searchParam,
	onCommit,
	onDownLoad
}) => {
	return (
		<div className={classnames(style["portCall-list-filter"])}>
			<div className="portCallListFilter-header">
				<div>
					<div className="filter-headerText">
						<span>Vessel Name:</span>
						<span>{vesselName}</span>
					</div>
					<div className="filter-headerText">
						<span>IMO:</span>
						<span>{imo}</span>
					</div>
				</div>
				{/* <FleetRangePicker
					allowClear
					value={[
						searchParam?.startDate ? dayjs(searchParam.startDate) : null,
						searchParam?.endDate ? dayjs(searchParam.endDate) : null
					]}
					onChange={(vals: [Dayjs, Dayjs]) => {
						onCommit?.({
							startDate: vals?.[0].format("YYYY-MM-DD"),
							endDate: vals?.[1].format("YYYY-MM-DD")
						});
					}}
					showHour={false}
					showMinute={false}
					showTime={false}
				/> */}
				<Button type="secondary" size="small" onClick={onDownLoad} prefixIcon="fleet-download">
					Download
				</Button>
			</div>
		</div>
	);
};

export default PortCallListFilter;
