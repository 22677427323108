import React from "react";
import classnames from "classnames";
import style from "./index.module.less";
import HfPaytermStyle from "../../headFixture/paymentTerms/index.module.less";
import {
  CBPaymentTermsFormProps, CBPaymentTermsFormType
} from "./type";
import dayjs from "dayjs";
import { FleetDatePicker, FleetFormItem, FleetVerifyInput } from "@/components";
import { CargoBookLabelMap } from "@/types/voyageManage/cargoBook";

const CBPaymentTermsForm: React.FC<CBPaymentTermsFormProps> = ({
  item,
  onChange,
  onCommit,
  otherItem
  // isPreOpNewVoyage
}) => {
  return <div className={classnames(style["cargoBook-freightPayTerm"], "headFixture-form")}>
    <span className="vm-common-title">Freight Payment Terms</span>
    <div className="form-container">
      <FleetVerifyInput
        // label={CargoBookLabelMap["paymentRate"]}
        label="First Payment Ratio (%)"
        needVerity
        type="number"
        value={item?.paymentRate}
        onChange={(val: number) => {
          onChange?.("paymentRate", val)
        }}
      />
      <FleetVerifyInput
        label={CargoBookLabelMap["paymentTerm"]}
        type="number"
        value={item?.paymentTerm}
        onChange={(val: number) => {
          const paymentStartDay = dayjs(otherItem?.layCanTo)?.valueOf() + +val * 24 * 60 * 60 * 1000;
          onCommit?.({
            paymentTerm: val,
            paymentStartDay: dayjs(paymentStartDay)?.format("YYYY-MM-DD")
          })
        }}
        dimension={<div className={HfPaytermStyle["headFixture-paymentTerm-tooltip"]}>
          How many days Freight Need to Be Paid After Completition of loading
        </div>}
      />
      <FleetFormItem
        label={CargoBookLabelMap["paymentStartDay"]}
      >
        <FleetDatePicker
          // disabled
          allowClear
          value={item?.paymentStartDay ? dayjs(item?.paymentStartDay) : null}
          onChange={(val: string, timeStramp) => {
            const ranges: Pick<CBPaymentTermsFormType, "paymentTerm" | "paymentStartDay"> = {
              paymentStartDay: "",
              paymentTerm: ""
            }
            if (timeStramp) {
              const paymentTerm = (timeStramp?.valueOf() - +otherItem?.layCanTo) / 1000 / 60 / 60 / 24
              ranges.paymentTerm = +(paymentTerm.toFixed(2));
              ranges.paymentStartDay = val
            }
            onCommit?.({
              ...ranges
            })
          }}
        // disabledDate={(current) => {
        //   let prevDate = Date.now() - 24 * 60 * 60 * 1000;
        //   return current && current < dayjs(prevDate).endOf("day")
        // }}
        />
      </FleetFormItem>
    </div>
  </div>
}

export default CBPaymentTermsForm;