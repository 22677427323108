import { CommonOptionType } from "@/types/advancedType";
import { TimeAxisControlItemType } from "./type";

export const currentHoursOptions: CommonOptionType<
	`${TimeAxisControlItemType["hours"]} hours`,
	TimeAxisControlItemType["hours"]
>[] = [
	{
		label: "3 hours",
		value: 3
	},
	{
		label: "6 hours",
		value: 6
	},
	{
		label: "12 hours",
		value: 12
	},
	{
		label: "24 hours",
		value: 24
	}
];
