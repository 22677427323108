import { getMonitoringThreeAisDataAction } from "@/action/monitoring/aisGroup";
import dayjs, { ManipulateType } from "dayjs";
import { MonitoringAisDetailsProps } from "../../monitoringDetails/type";
import { useEffect, useState } from "react";
import { useAppDispatch } from "@/hook";
import { useNavigate } from "react-router-dom";
import useReminder from "@/hook/useReminder";
import { FleetAisDataProps } from "@/common/AisData/type";
import { getAisAction } from "@/action/estimation/aisData";

const useIndex = (
	hide: boolean,
	mmsi: number,
	imo: number,
	selectTime: MonitoringAisDetailsProps["activeTime"]
) => {
	const dispatch = useAppDispatch();
	const navigate = useNavigate();
	const { reminder } = useReminder();
	const [loading, setLoading] = useState(false);

	const [dataSource, setDataSource] = useState<FleetAisDataProps["dataSource"]>({
		dates: [],
		speed: 0,
		days: "3/day",
		speeds: []
	});

	const handleChange: FleetAisDataProps["onChange"] = (key, value) => {
		setDataSource((prev) => ({ ...prev, [key]: value }));
	};

	const getMonitoringAisDataFront = () => {
		setLoading(true);
	};

	const getAisCurrentStateFailed = (error) => {
		setLoading(false);
		reminder("error", error?.msg ? error?.msg + ": " + error?.data : error?.data);
	};

	const getAisCurrentStateSuccess = ({ data }) => {
		console.log("getAisCurrentStateSuccess", data);
		setDataSource((prev) => ({
			...prev,
			dates: data?.dates ?? [],
			speeds: data?.route?.trackPoints ?? []
		}));
		setLoading(false);
	};

	const getMonitoringAisData = () => {
		const [count, rule] = selectTime?.split("/") as [number, ManipulateType];
		if (selectTime === "3/day") {
			if (!imo) return;
			getMonitoringThreeAisDataAction(
				{ imo: imo },
				getMonitoringAisDataFront,
				getAisCurrentStateSuccess,
				getAisCurrentStateFailed,
				dispatch,
				navigate
			);
			return;
		}
		if (!mmsi) return;
		const startTime = dayjs()?.subtract(count, rule);
		const diffDay = dayjs()?.diff(startTime, "day");
		getAisAction(
			{ mmsi, days: diffDay },
			getMonitoringAisDataFront,
			getAisCurrentStateSuccess,
			getAisCurrentStateFailed,
			dispatch,
			navigate
		);
	};

	useEffect(() => {
		if (hide) return;
		getMonitoringAisData();
	}, [hide, mmsi, imo, selectTime]);

	return {
		loading,
		dataSource,
		handleChange
	};
};

export default useIndex;
