import { ColumnsType } from "antd/es/table/interface";
import { LabelKeys, LabelMap } from "../index"
import React, { useCallback, useState } from "react";
import { formatThousandthNumber } from "@/tools/amount";
import { FleetNumType } from "../common";
import dayjs from "dayjs";

/**
 * 表格列筛选分类名
 */
export type EsCategoryKeys = "Vessel" | "Cargo" | "Profit & Loss" | "Setup";

/**
 * 表格列
 */
export type EsGroupColumnKeys = Extract<LabelKeys,
  "ownerName" | "vesselName" | "deliveryPortName" |
  "dailyHire" | "chartererName" | "cargoName" |
  "cargoQuantity" | "cargoUnit" | "layCanFrom" |
  "layCanTo" | "loadPort" | "dischargePort" |
  "freightType" | "freightRate" | "commission" |
  "brokerage" | "loadRate" | "dischargeRate"
  | "totalRevenue" | "totalCost" | "operationCost" |
  "bunkerCost" | "hireCost" | "profitAndLoss" | "tce" |
  "totalProfit" |
  "status" | "option" | "id"
>

/**
 * 表格列操作按钮，关联列 option
 */
export enum ColumnOperation {
  passTo = "Pass To",
  saveAsDraft = "Save as Draft"
}

export type DataType = {
  key: React.Key;
} & Record<EsGroupColumnKeys, any>;

enum statusDict {
  'EST' = 'Estimation',
  'PVY' = 'PreOp',
  'DFT' = 'Draft',
}

export const columns: ColumnsType<DataType> = [
  {
    dataIndex: "ownerName",
    "width": 150,
    "title": "Owner Name",
    key: "ownerName",
    "align": "center",
    "fixed": "left"
  },
  {
    dataIndex: "status",
    "width": 100,
    "title": "Status",
    key: "status",
    "align": "center",
    "fixed": "left",
    render: (value, record, index) => {
      return <span>{statusDict[value]}</span>
    }
  },
  {
    dataIndex: "vesselName",
    "width": 150,
    "title": "Vessel Name",
    key: "vesselName",
    "align": "center",
    "fixed": "left"
  },
  {
    dataIndex: "deliveryPortName",
    "width": 150,
    "title": "Delievry Port",
    key: "deliveryPortName",
    "align": "center"
  },
  {
    dataIndex: "dailyHire",
    "width": 100,
    "title": "Daily Hire",
    key: "dailyHire",
    "align": "center",
    render: (value, record, index) => {
      return <span>{formatThousandthNumber(value)}</span>
    }
  },
  {
    dataIndex: "chartererName",
    "width": 150,
    "title": "Charterer Name",
    key: "chartererName",
    "align": "center"
  },
  {
    dataIndex: "cargoName",
    "width": 150,
    "title": "Cargo Name",
    key: "cargoName",
    "align": "center"
  },
  {
    dataIndex: "cargoQuantity",
    "width": 150,
    "title": "Cargo Quantity",
    key: "cargoQuantity",
    "align": "center",
    render: (value, record, index) => {
      return <span>{formatThousandthNumber(value)}</span>
    }
  },
  {
    dataIndex: "cargoUnit",
    "width": 130,
    "title": "Cargo Unit",
    key: "cargoUnit",
    "align": "center"
  },
  {
    dataIndex: "layCanFrom",
    "width": 180,
    "title": "Cargo Laycan From",
    key: "layCanFrom",
    "align": "center",
    render: (value,record,index) => {
      return <span>{value ? dayjs(value)?.format("YYYY-MM-DD HH:mm") : null}</span>
    }
  },
  {
    dataIndex: "layCanTo",
    "width": 180,
    "title": "Cargo Laycan To",
    key: "layCanTo",
    "align": "center",
    render: (value,record,index) => {
      return <span>{value ? dayjs(value)?.format("YYYY-MM-DD HH:mm") : null}</span>
    }
  },
  {
    dataIndex: "loadPort",
    "width": 100,
    "title": "Load Port",
    key: "loadPort",
    "align": "center"
  },
  {
    dataIndex: "dischargePort",
    "width": 150,
    "title": "Discharge Port",
    key: "dischargePort",
    "align": "center"
  },
  {
    dataIndex: "freightType",
    "width": 150,
    "title": "Freight Type",
    key: "freightType",
    "align": "center"
  },
  {
    dataIndex: "freightRate",
    "width": 150,
    "title": "Freight Rate",
    key: "freightRate",
    "align": "center",
    render: (value, record, index) => {
      return <span>{formatThousandthNumber(value)}</span>
    }
  },
  {
    dataIndex: "commission",
    "width": 200,
    "title": "Add. Commission",
    key: "commission",
    "align": "center",
    render: (value, record, index) => {
      return <span>{formatThousandthNumber(value)}</span>
    }
  },
  {
    dataIndex: "brokerage",
    "width": 100,
    "title": "Brokerage",
    key: "brokerage",
    "align": "center",
    render: (value, record, index) => {
      return <span>{formatThousandthNumber(value)}</span>
    }
  },
  {
    dataIndex: "loadRate",
    "width": 100,
    "title": "Load Rate",
    key: "loadRate",
    "align": "center"
  },
  {
    dataIndex: "dischargeRate",
    "width": 150,
    "title": "Discharge Rate",
    key: "dischargeRate",
    "align": "center"
  },
  {
    dataIndex: "totalRevenue",
    "width": 150,
    "title": "Revenue",
    key: "totalRevenue",
    "align": "center",
    ellipsis: true,
    render: (value, record, index) => {
      return <span>{formatThousandthNumber(value)}</span>
    }
  },
  {
    dataIndex: "totalCost",
    "width": 150,
    "title": "Total Cost",
    key: "totalCost",
    "align": "center",
    render: (value, record, index) => {
      return <span>{formatThousandthNumber(value)}</span>
    }
  },
  {
    dataIndex: "operationCost",
    "width": 150,
    "title": "Operation Cost",
    key: "operationCost",
    "align": "center",
    render: (value, record, index) => {
      return <span>{formatThousandthNumber(value)}</span>
    }
  },
  {
    dataIndex: "bunkerCost",
    "width": 150,
    "title": "Bunker Cost",
    key: "bunkerCost",
    "align": "center",
    render: (value, record, index) => {
      return <span>{formatThousandthNumber(value)}</span>
    }
  },
  {
    dataIndex: "hireCost",
    "width": 150,
    "title": "Hire Cost",
    key: "hireCost",
    "align": "center",
    render: (value, record, index) => {
      return <span>{formatThousandthNumber(value)}</span>
    }
  },
  {
    dataIndex: "profitAndLoss",
    "width": 150,
    "title": "Profit & Loss",
    key: "profitAndLoss",
    "align": "center",
    render: (value, record, index) => {
      return <span>{formatThousandthNumber(record?.totalProfit)}</span>
    }
  },
  {
    dataIndex: "tce",
    "width": 150,
    "title": "TCE",
    key: "tce",
    "align": "center",
    render: (value, record, index) => {
      return <span>{formatThousandthNumber(value)}</span>
    }
  }
];

/**
 * 列筛选分类
 */

export type EsGroupOptionItemType = {
  value: EsGroupColumnKeys;
  label: string;
  disabled?: boolean;
}

export type EsGroupOptionsType = {
  key: EsCategoryKeys;
  defaultValue?: string[];
  basicDisabled?: boolean;
  list: EsGroupOptionItemType[]
}

export const EsGroupOptions: EsGroupOptionsType[] = [
  {
    key: "Vessel",
    basicDisabled: true,
    list: [
      {
        "value": "ownerName",
        "label": "Owner Name"
      },
      {
        "value": "vesselName",
        "label": "Vessel Name"
      },
      {
        "value": "deliveryPortName",
        "label": "Delievry Port"
      },
      {
        "value": "dailyHire",
        "label": "Daily Hire"
      },
    ]
  },
  {
    key: "Cargo",
    list: [
      {
        "value": "chartererName",
        "label": "Charterer Name"
      },
      {
        "value": "cargoName",
        "label": "Cargo Name"
      },
      {
        "value": "cargoQuantity",
        "label": "Cargo Quantity"
      },
      {
        "value": "cargoUnit",
        "label": "Cargo Unit"
      },
      {
        "value": "layCanFrom",
        "label": "Cargo Laycan From"
      },
      {
        "value": "layCanTo",
        "label": "Cargo Laycan To"
      },
      {
        "value": "loadPort",
        "label": "Load Port"
      },
      {
        "value": "dischargePort",
        "label": "Discharge Port"
      },
      {
        "value": "freightType",
        "label": "Freight Type"
      },
      {
        "value": "freightRate",
        "label": "Freight Rate"
      },
      {
        "value": "commission",
        "label": "Add. Commission"
      },
      {
        "value": "brokerage",
        "label": "Brokerage"
      },
      {
        "value": "loadRate",
        "label": "Load Rate"
      },
      {
        "value": "dischargeRate",
        "label": "Discharge Rate"
      }
    ]
  },
  {
    key: "Profit & Loss",
    basicDisabled: true,
    list: [
      {
        "value": "totalRevenue",
        "label": "Revenue"
      },
      {
        "value": "totalCost",
        "label": "Total Cost"
      },
      {
        "value": "operationCost",
        "label": "Operation Cost"
      },
      {
        "value": "bunkerCost",
        "label": "Bunker Cost"
      },
      {
        "value": "hireCost",
        "label": "Hire Cost"
      },
      {
        "value": "profitAndLoss",
        "label": "Profit & Loss"
      },
      {
        "value": "tce",
        "label": "TCE"
      },
    ]
  },
  {
    key: "Setup",
    basicDisabled: true,
    list: [
      {
        label: "Status",
        value: "status"
      }
    ]
  }
];

/**
 * 分类筛选
 */

export type EsCategoryGroupType = Record<EsCategoryKeys, EsGroupColumnKeys[]>;

export const initEsCategoryGroup: EsCategoryGroupType = {
  "Vessel": ["ownerName", "vesselName", "deliveryPortName", "dailyHire"],
  "Cargo": [],
  "Profit & Loss": ["totalRevenue", "totalCost", "operationCost", "bunkerCost", "hireCost", "profitAndLoss", "tce"],
  "Setup": ["status"]
}

/**
 * 新增表单字段
 */

export type EsCargoRotationKeys = Extract<LabelKeys,
  "chartererName" | "cargoName" | "cargoQuantity" | "layCanFrom" | "cargoUnit"
  | "layCanTo" | "loadPort" | "loadPortExpense" | "loadRate" | "dischargeWorkDays"
  | "dischargePort" | "disPortExpenses" | "loadRate" | "loadPortDays" | "dischargeRate"
  | "disPortExpenses" | "freightRate" | "freightType" | "commission" | "brokerage" | "mollo" |
  "loadPortCode" | "loadPortName" | "loadPurpose" | "dischargePortCode" | "dischargePortName"
  | "dischargePurpose"
>;

export type EsVesselParticularKeys = Extract<LabelKeys,
  "vesselName" | "ownerName" | "dwt" | "grain" | "bale"
  | "delievryPortName" | "bunkerPriceIfo"
  | "bunkerPriceMdo" | "otherExpenses" | "dailyHire" | "commission"
  | "brokerage" | "ballastSpeed" | "ladenSpeed" | "seaFuelBallast"
  | "seaFuelLaden" | "seaFuelMdo" | "portFuelWork" | "portFuelIdle" | "portFuelMdo" |
  "deliveryPortCode" | "deliveryPortId"
>;

export type CrFormType = Record<Exclude<
  EsCargoRotationKeys, "cargoUnit" |
  "cargoQuantity" |
  "cargoQuantityDiscrepancy" |
  "freightType" |
  "freightRate" |
  "commission" |
  "brokerage"
>, string> & {
  id?: string;
  resultId?: string;
  cargoUnit: "MT" | "CBM";
  cargoQuantity: number;
  cargoQuantityDiscrepancy: number;
  freightType: "FR" | "LP";
  freightRate: number;
  commission: number;
  brokerage: number
};

export type VpFormType = Record<
  Exclude<EsVesselParticularKeys,
    "ownerName" | "vesselName" | "deliveryPortName" | "deliveryPortCode" | "deliveryPortId" | "delievryPort" | "layCanTo"
    | "layCanFrom"
  >,
  number> &
{
  id?: string | number;
  resultId?: string;
  mmsi: string | number;
  vesselName: string;
  ownerName: string;
  deliveryPortName: string;
  deliveryPortCode: string;
  deliveryPortId: string;
  layCanFrom: string | undefined;
  layCanTo: string | undefined;
  vesselNameEn: string;
  vesselNameCn: string;
  imo: string | number;
};
export type PrFormType = {
  cargoName: string;
  /**
   * 装卸货量
   */
  cargoQuantity: number;
  /**
   * 货物单位
   */
  cargoUnit: string;
  /**
   * id 修改时可能会出现新增，不判断ID 是否为空
   */
  id?: string | number;
  /**
   * 在港空闲天数
   */
  idleDays?: number;
  /**
   * 装卸货速率
   */
  loadingDischargeRate?: number;
  /**
   * 港口 code
   */
  portCode: string;
  /**
   * 港使费
   */
  portExpense?: number;
  /**
   * 港口名称
   */
  portName: string;
  /**
   * 到港目的
   */
  purpose: string;
  /**
   * 备注
   */
  remark?: string;
  /**
   * 测算结果ID
   */
  resultId?: number;
  /**
   * 序号
   */
  seq: number;
  /**
   * 在港总天数
   */
  totalDays?: number;
  /**
   * 在港工作天数
   */
  workDays?: number;
  distance?: number;
  ecaDistance?: number;
  customType?: "LD" | "DC";
  originId?: string | number;
  conFo?: FleetNumType;
  conDo?: FleetNumType
};

export type CrFromChangeEvent = (key: EsCargoRotationKeys, val: string) => void;

export type VpFromChangeEvent = (key: EsVesselParticularKeys, val: string) => void;

/**
 * 部分hooks，先放在这，后面再移到合理的目录下
 */

export type checkGroupChangeEvent = (key: EsCategoryKeys, val: EsGroupColumnKeys[]) => void;

export const useCheckGroup = (initGroup = initEsCategoryGroup) => {
  const [checkGroup, setCheckGroup] = useState<EsCategoryGroupType>(initGroup);

  const reset = useCallback(() => {
    setCheckGroup(initGroup)
  }, []);

  const change = (key: EsCategoryKeys, val: EsGroupColumnKeys[]) => {
    setCheckGroup({
      ...checkGroup,
      [key]: val
    })
  }

  return {
    checkGroup,
    reset,
    change
  }
}

