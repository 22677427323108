import React, { FC } from "react";
import style from "./index.module.less";
import { MovementTaskDetailsProps } from "./type";
import { MovementDetailTaskList, MovementTaskComment } from "./components";
import useIndex from "./hooks/useIndex";
import { FleetLoading } from "@/components";

const MovementTaskDetails: FC<MovementTaskDetailsProps> = ({
	currentItem,
	voyageId,
	onClose,
	onOpenDialog,
	open,
	activeVoyageType
}) => {
	const {
		loading,
		filterItem,
		handleFilterChange,
		dataSource,
		handleChange,
		handleSelect,
		activeTaskItem
	} = useIndex(open, voyageId, currentItem, activeVoyageType);
	return (
		<div className={style["movement-taskDetails"]}>
			<FleetLoading loading={loading} />
			<div className="taskDetails-header">
				<span className="fleet-header">Task</span>
				<span className="font_family fleet-close header-close" onClick={onClose}></span>
			</div>
			<div className="taskDetails-container">
				<MovementDetailTaskList
					activeTaskItem={activeTaskItem}
					filterItem={filterItem}
					onFilterChange={handleFilterChange}
					currentVoyageItem={currentItem}
					items={dataSource}
					onChange={handleChange}
					onSelect={handleSelect}
					onOpenDialog={onOpenDialog}
				/>
				<MovementTaskComment currentTaskItem={activeTaskItem} onTaskItemChange={handleChange} />
			</div>
		</div>
	);
};

export default MovementTaskDetails