import React from "react"
import { MovementCommonTableColumnsType } from "../charterBase/common/table/type"
import { MovementPortExpensesItemType, MovementPortExpensesPortItemChangeEvent, MovementPortExpensesPortItemCommitEvent, MovementPortExpensesPortItemType } from "./type"
import { FleetInput, FleetSelect } from "@/components";
import { CargoUnitType, PortPurposeType } from "@/types/common"
import { CargoUnitOptions } from "@/types/option"
import { CommonContactSelect } from "@/common"
import { portPurposeOptions } from "@/pages/voyagemanage/business/movement/source";

export const initialPortExpenses: MovementPortExpensesItemType = {
  vesselName: "",
  vesselType: "",
  cargoName: "",
  portExpenseVoList: []
}

export const columns: MovementCommonTableColumnsType<
  MovementPortExpensesPortItemType,
  keyof MovementPortExpensesPortItemType,
  {
    onChange: MovementPortExpensesPortItemChangeEvent,
    onCommit: MovementPortExpensesPortItemCommitEvent
  }
>[] = [
    {
      label: "Status",
      dataIndex: "purpose",
      width: 150,
      fixed: "left",
      render: (value, record, index, actions) => {
        return <FleetSelect
          value={[value]}
          disabled
          popupMatchSelectWidth={false}
          className="td-select"
          options={portPurposeOptions}
          onChange={(val: PortPurposeType) => {
            actions?.onChange(index, "purpose", val)
          }}
        />
      }
    },
    {
      label: "Port Name",
      dataIndex: "portName",
      fixed: "left",
      width: 222,
      render: (value, record, index, actions) => {
        return <FleetInput
          disabled
          className="td-input"
          value={value}
        />
      }
    },
    {
      label: "Cargo Quantity",
      dataIndex: "cargoQuantity",
      width: 196,
      render: (value, record, index, actions) => {
        return <div className="portExpenses-cargo--container">
          <FleetInput
            type="number"
            className="td-input"
            value={value}
            onChange={(val: number) => {
              actions?.onChange?.(index, "cargoQuantity", val)
            }}
          />
          <FleetSelect
            value={[record?.cargoUnit]}
            popupMatchSelectWidth={false}
            className="td-select"
            options={CargoUnitOptions}
            onChange={(val: CargoUnitType) => {
              actions?.onChange(index, "cargoUnit", val)
            }}
          />
        </div>
      }
    },
    {
      label: "Pre-Port Disb",
      dataIndex: "prePortDisb",
      width: 125,
      render: (value, record, index, actions) => {
        return <FleetInput
          type="number"
          className="td-input"
          value={value}
          onChange={(val: number) => {
            actions?.onCommit?.(index, {
              prePortDisb: val,
              portExpense: +record?.settlement + val
            })
          }
          }
        />
      }
    },
    {
      label: "Settlement",
      dataIndex: "settlement",
      width: 125,
      render: (value, record, index, actions) => {
        return <FleetInput
          type="number"
          className="td-input"
          value={value}
          onChange={(val: number) => {
            actions?.onCommit?.(index, {
              settlement: val,
              portExpense: +record?.prePortDisb + val
            })
          }
          }
        />
      }
    },
    {
      label: "Total Expense",
      dataIndex: "portExpense",
      width: 125,
      render: (value, record, index, actions) => {
        return <FleetInput
          type="number"
          className="td-input"
          disabled
          value={value}
        />
      }
    },
    {
      label: "Agent",
      dataIndex: "portAgentId",
      fixed: "right",
      width: 300,
      render: (value, record, index, actions) => {
        return <CommonContactSelect
          showKey="companyName"
          primaryKey="id"
          activeItems={{
            id: record?.portAgentId,
            companyName: record?.portAgentName
          }}
          contactParmas={{
            businessType: "Port Agent"
          }}
          className="td-select"
          onFinish={(newItem, type) => {
            actions?.onCommit?.(index, {
              portAgentId: newItem?.id ?? null,
              portAgentName: newItem?.companyName ?? ""
            })
          }}
        />
      }
    }
  ]