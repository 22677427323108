import { FleetNumType } from "@/types/common";
import { type ApiParameter } from "..";
import { appVersion } from "@/api/constant";

import request from "@/service";

const enum ApiMap {
	downloadPortCallParameter,
	getPortCallListParameter
}

type ApiUrl = keyof typeof ApiMap;

const portCallApi: ApiParameter<ApiUrl> = {
	downloadPortCallParameter: {
		method: "post",
		url: appVersion + "/moni/ship/download/history"
	},
	getPortCallListParameter: {
		method: "get",
		url: appVersion + "/moni/ship/history"
	}
};

export type getPortCallListParams = {
	pageSize: number;
	pageNum: number;
	imo: FleetNumType;
	startDate: string;
	endDate: string;
};

export type downPortCallListParams = {
	imo: FleetNumType;
	vesselTypeSub: FleetNumType;
	vesselName: string;
	portName: string;
	ata: FleetNumType;
	purpose: FleetNumType;
	atd: FleetNumType;
	portStayHour: FleetNumType;
	portStayDay: FleetNumType;
	countryName: string;
};

export const getPortCallListApi = (params: getPortCallListParams) => {
	return request.request({
		...portCallApi.getPortCallListParameter,
		params
	});
};

export const downloadPortCallApi = (data: downPortCallListParams[]) => {
	return request.request({
		...portCallApi.downloadPortCallParameter,
		data,
		responseType: "blob"
	});
};
