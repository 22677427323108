import { FleetAisData } from "@/common";
import React, { FC } from "react";
import { MonitoringAisDataProps } from "./type";
import style from "./index.module.less"
import useIndex from "./hooks/useIndex";
import classnames from "classnames";
import { FleetFormItem, FleetInput } from "@/components";


const MonitoringAisData: FC<MonitoringAisDataProps> = ({
  mmsi,
  imo,
  selectTime,
  hide,
  onClose
}) => {
  const { loading, dataSource, handleChange } = useIndex(hide, mmsi, imo, selectTime)
  return <div className={classnames(style["monitoring-aisData"], {
    [style["monitoring-aisData--active"]]: !hide
  })}>
    <div className="monitoring-aisData--header">
      <div className="header--container">
        <span className="fleet-header">AIS Data</span>
        <FleetFormItem label="CP Speed" type="row" containerClassname="aisData-search">
          <FleetInput
            value={dataSource?.speed}
            type="number"
            onChange={(val: number) => {
              handleChange?.("speed", val)
            }}
            className="aisData-input"
          />
        </FleetFormItem>
      </div>
      <span className="font_family fleet-close header-close" onClick={() => onClose?.("aisData")}></span>
    </div>
    <FleetAisData
      type="monitoring"
      loading={loading}
      dataSource={dataSource}
      onChange={handleChange}
    />
  </div>
}

export default MonitoringAisData