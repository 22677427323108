import { CommonInitItemEvent } from "@/types/event";
import { TableCellType, ArrivalReportPortItem } from "./type";
import { FleetTimePicker } from "@/components/DatePicker";
import React from "react";
import dayjs from "dayjs";
import { FleetInput } from "@/components";
import { LatLonInput } from "./components";
export type TableRowType = {
	rowIndex: number;
	row: TableCellType[];
};
export const arrivalReportConfig: TableRowType[] = [
	{
		rowIndex: 0,
		row: [
			{ colSpan: 2, rowSpan: 1, label: "ARRIVAL TIME(UTC)" },
			{ colSpan: 1, rowSpan: 1, label: "ROB IFO" },
			{ colSpan: 1, rowSpan: 1, label: "ROB MDO" },
			{ colSpan: 1, rowSpan: 1, label: "ROB FW" }
		]
	},
	{
		rowIndex: 1,
		row: [
			{
				colSpan: 2,
				rowSpan: 1,
				prop: "arrivalTime",
				render: (value, index, record, actions) => {
					return (
						<FleetTimePicker
							allowClear
							className="cell-select"
							value={value ? dayjs(value) : null}
							onChange={(val: string) => {
								actions.onCommit?.(index, {
									arrivalTime: dayjs(val).valueOf()
								});
							}}
						></FleetTimePicker>
					);
				}
			},
			{
				colSpan: 1,
				rowSpan: 1,
				prop: "brobIfo",
				render: (value, index, record, actions) => {
					return (
						<FleetInput
							value={value}
							type={"number"}
							onChange={(val) => actions?.onCommit(index, { brobIfo: +val })}
						></FleetInput>
					);
				}
			},
			{
				colSpan: 1,
				rowSpan: 1,
				prop: "brobMdo",
				render: (value, index, record, actions) => {
					return (
						<FleetInput
							value={value}
							type={"number"}
							onChange={(val) => actions?.onCommit(index, { brobMdo: +val })}
						></FleetInput>
					);
				}
			},
			{
				colSpan: 1,
				rowSpan: 1,
				prop: "brobFw",
				render: (value, index, record, actions) => {
					return (
						<FleetInput
							value={value}
							type={"number"}
							onChange={(val) => actions?.onCommit(index, { brobFw: +val })}
						></FleetInput>
					);
				}
			}
		]
	},
	{
		rowIndex: 2,
		row: [
			{ colSpan: 1, rowSpan: 1, label: "DRAFT(F)" },
			{ colSpan: 1, rowSpan: 1, label: "DRAFT(M)" },
			{ colSpan: 1, rowSpan: 1, label: "DRAFT(A)" },
			{ colSpan: 1, rowSpan: 1, label: "ANCHORAGE POSITION" },
			{ colSpan: 1, rowSpan: 1, label: "NO. OF TUG(S) USED" }
		]
	},
	{
		rowIndex: 3,
		row: [
			{
				colSpan: 1,
				rowSpan: 1,
				prop: "driftF",
				render: (value, index, record, actions) => {
					return (
						<FleetInput
							value={value}
							type={"number"}
							onChange={(val) => actions?.onCommit(index, { driftF: +val })}
						></FleetInput>
					);
				}
			},
			{
				colSpan: 1,
				rowSpan: 1,
				prop: "driftM",
				render: (value, index, record, actions) => {
					return (
						<FleetInput
							value={value}
							type={"number"}
							onChange={(val) => actions?.onCommit(index, { driftM: +val })}
						></FleetInput>
					);
				}
			},
			{
				colSpan: 1,
				rowSpan: 1,
				prop: "driftA",
				render: (value, index, record, actions) => {
					return (
						<FleetInput
							value={value}
							type={"number"}
							onChange={(val) => actions?.onCommit(index, { driftA: +val })}
						></FleetInput>
					);
				}
			},
			{
				colSpan: 1,
				rowSpan: 1,
				prop: "anchoragePositionLat",
				render: (value, index, record, actions) => {
					return (
						<LatLonInput
							latValue={+record.anchoragePositionLat || 0}
							lonValue={+record.anchoragePositionLng || 0}
							onCommit={(value) =>
								actions?.onCommit(index, {
									anchoragePositionLat: value.lat,
									anchoragePositionLng: value.lon
								})
							}
						/>
					);
				}
			},
			{
				colSpan: 1,
				rowSpan: 1,
				prop: "noOfTugUsed",
				render: (value, index, record, actions) => {
					return (
						<FleetInput
							value={value}
							type={"number"}
							onChange={(val) => actions?.onCommit(index, { noOfTugUsed: +val })}
						></FleetInput>
					);
				}
			}
		]
	}
];

export const newArrivalReportRow: CommonInitItemEvent<ArrivalReportPortItem> = (item) => {
	return {
		voyageId: item?.voyageId ?? null,
		arrivalTime: item?.arrivalTime ?? null,
		driftF: item?.driftF ?? "",
		driftM: item?.driftM ?? "",
		driftA: item?.driftA ?? "",
		brobIfo: item?.brobIfo ?? "",
		brobMdo: item?.brobMdo ?? "",
		brobFw: item?.brobFw ?? "",
		noOfTugUsed: item?.noOfTugUsed ?? "",
		anchoragePositionLat: item?.anchoragePositionLat ?? "",
		anchoragePositionLng: item?.anchoragePositionLng ?? "",
		remark: item?.remark ?? "",
		portName: item?.portName ?? "",
		portCode: item?.portCode ?? ""
	};
};
