import {
  getVesselList,
  GetVesselListParams,
  getVesselDetail,
  GetVesselDetailParams,
  getAISList,
  GetAISListParams,
  getAISState,
  GetAISStateParams,
  getDailyReportList,
  GetDailyReportParams,
  getRoutePlanList,
  GetRoutePlanParams,
  getPerformance,
  GetPerformanceParams, GetPortsParams, getPorts,
  GetDailyReportDetailParams,
  getDailyReportDetail,
  GetSegmentParams,
  getSegmentList,
  UpdateSegmentParams,
  addSegments,
  updateSegments,
  getCPTerms
} from "@/api/monitoring/vessel";

import {
  type ParameterType
} from "@/api";

import { loginOutAction } from "@/store/userSlice";


export const getVesselListAction: ParameterType<GetVesselListParams> = (
  params,
  frontCallback,
  successCallback,
  errorCallback,
  dispatch,
  navigate
) => {
  frontCallback?.();
  getVesselList(params).then(response => {
    successCallback?.(response)
  }).catch(err => {
    errorCallback?.(err)
    if (err?.loginStatus === 'token faild') {
      dispatch(loginOutAction(() => navigate?.("/login")))
    }
  })
}

export const getVesselDetailAction: ParameterType<GetVesselDetailParams> = (
  params,
  frontCallback,
  successCallback,
  errorCallback,
  dispatch,
  navigate,
  config?
) => {
  frontCallback?.();
  getVesselDetail(params, config).then(response => {
    successCallback?.(response)
  }).catch(err => {
    errorCallback?.(err)
    if (err?.loginStatus === 'token faild') {
      dispatch(loginOutAction(() => navigate?.("/login")))
    }
  })
}

export const getAISListAction: ParameterType<GetAISListParams> = (
  params,
  frontCallback,
  successCallback,
  errorCallback,
  dispatch,
  navigate
) => {
  frontCallback?.();
  getAISList(params).then(response => {
    successCallback?.(response)
  }).catch(err => {
    errorCallback?.(err)
    if (err?.loginStatus === 'token faild') {
      dispatch(loginOutAction(() => navigate?.("/login")))
    }
  })
}

export const getAISStateAction: ParameterType<GetAISStateParams> = (
  params,
  frontCallback,
  successCallback,
  errorCallback,
  dispatch,
  navigate
) => {
  frontCallback?.();
  getAISState(params).then(response => {
    successCallback?.(response)
  }).catch(err => {
    errorCallback?.(err)
    if (err?.loginStatus === 'token faild') {
      dispatch(loginOutAction(() => navigate?.("/login")))
    }
  })
}

export const getDailyReportAction: ParameterType<GetDailyReportParams> = (
  params,
  frontCallback,
  successCallback,
  errorCallback,
  dispatch,
  navigate
) => {
  frontCallback?.();
  getDailyReportList(params).then(response => {
    successCallback?.(response)
  }).catch(err => {
    errorCallback?.(err)
    if (err?.loginStatus === 'token faild') {
      dispatch(loginOutAction(() => navigate?.("/login")))
    }
  })
}

export const getRoutePlanAction: ParameterType<GetRoutePlanParams> = (
  params,
  frontCallback,
  successCallback,
  errorCallback,
  dispatch,
  navigate
) => {
  frontCallback?.();
  getRoutePlanList(params).then(response => {
    successCallback?.(response)
  }).catch(err => {
    errorCallback?.(err)
    if (err?.loginStatus === 'token faild') {
      dispatch(loginOutAction(() => navigate?.("/login")))
    }
  })
}

export const getPerformanceAction: ParameterType<GetPerformanceParams> = (
  params,
  frontCallback,
  successCallback,
  errorCallback,
  dispatch,
  navigate
) => {
  frontCallback?.();
  getPerformance(params).then(response => {
    successCallback?.(response)
  }).catch(err => {
    errorCallback?.(err)
    if (err?.loginStatus === 'token faild') {
      dispatch(loginOutAction(() => navigate?.("/login")))
    }
  })
}

export const getPortsAction: ParameterType<GetPortsParams> = (
  params,
  frontCallback,
  successCallback,
  errorCallback,
  dispatch,
  navigate
) => {
  frontCallback?.();
  getPorts(params).then(response => {
    successCallback?.(response)
  }).catch(err => {
    errorCallback?.(err)
    if (err?.loginStatus === 'token faild') {
      dispatch(loginOutAction(() => navigate?.("/login")))
    }
  })
}

export const getDailyReportDetailAction: ParameterType<GetDailyReportDetailParams> = (
  params,
  frontCallback,
  successCallback,
  errorCallback,
  dispatch,
  navigate
) => {
  frontCallback?.();
  getDailyReportDetail(params).then(response => {
    successCallback?.(response)
  }).catch(err => {
    errorCallback?.(err)
    if (err?.loginStatus === 'token faild') {
      dispatch(loginOutAction(() => navigate?.("/login")))
    }
  })
}

export const getSegmentListAction: ParameterType<GetSegmentParams> = async (
  params,
  frontCallback,
  successCallback,
  errorCallback,
  dispatch,
  navigate
) => {
  frontCallback?.();
  getSegmentList(params).then(response => {
    successCallback?.(response)
  }).catch(err => {
    errorCallback?.(err)
    if (err?.loginStatus === 'token faild') {
      dispatch(loginOutAction(() => navigate?.("/login")))
    }
  })
}

// export const getCPTermsAction: ParameterType<string> = async (
//   params,
//   frontCallback?,
//   successCallback?,
//   errorCallback?,
//   dispatch?,
//   navigate?
// ) => {
//   frontCallback?.();
//   getCPTerms(params).then(response => {
//     successCallback?.(response)
//   }).catch(err => {
//     errorCallback?.(err)
//   })
// }
export const getCPTermsAction = (voyageNo:string) => getCPTerms(voyageNo)

export const addSegmentsAction: ParameterType<UpdateSegmentParams> = (
  params,
  frontCallback,
  successCallback,
  errorCallback,
  dispatch,
  navigate
) => {
  frontCallback?.();
  addSegments(params).then(response => {
    successCallback?.(response)
  }).catch(err => {
    errorCallback?.(err)
    if (err?.loginStatus === 'token faild') {
      dispatch(loginOutAction(() => navigate?.("/login")))
    }
  })
}

export const updateSegmentsAction: ParameterType<UpdateSegmentParams> = (
  params,
  frontCallback,
  successCallback,
  errorCallback,
  dispatch,
  navigate
) => {
  frontCallback?.();
  updateSegments(params).then(response => {
    successCallback?.(response)
  }).catch(err => {
    errorCallback?.(err)
    if (err?.loginStatus === 'token faild') {
      dispatch(loginOutAction(() => navigate?.("/login")))
    }
  })
}
