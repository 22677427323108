import { ColumnsType } from "antd/es/table";
import React from "react";
import { RouteCommonAreaVesselItemType } from "./type";
import i18n from "@/locale";
import dayjs from "dayjs";
import { formatThousandthNumber } from "@/tools/amount";
import {
	ArrivalEcoEngineMap,
	ArrivalPurposeMap,
	ArrivalAisStatusMap,
	ArrivalSoxScrubberStatusMap,
	ArrivalStatusMap,
	ArrivalVesselSubTypeMap,
	ArrivalVesselTypeMap
} from "@/common/Select";

export const convertToDms = (val: number, type: "lat" | "lon"): string => {
	const degree: number = Math.floor(Math.abs(val));
	const minute: number = Math.floor((Math.abs(val) - degree) * 60);
	let direction: string;
	if (type === "lat") {
		direction = val >= 0 ? "N" : "S";
	} else {
		direction = val >= 0 ? "E" : "W";
	}
	return degree + "°" + minute.toFixed(3) + "'" + direction;
};

export const columns: ColumnsType<RouteCommonAreaVesselItemType> = [
	{
		dataIndex: "vesselName",
		key: "vesselName",
		title: i18n.t("common.vesselName"),
		width: 180,
		fixed: "left"
	},
	{
		dataIndex: "imo",
		key: "imo",
		title: i18n.t("common.imo"),
		width: 120
	},
	{
		dataIndex: "vesselType",
		key: "vesselType",
		title: i18n.t("common.vesselType"),
		width: 100,
		render: (value, record, index) => {
			return <span>{ArrivalVesselTypeMap[record?.vesselType]}</span>;
		}
	},
	{
		dataIndex: "vesselTypeSub",
		key: "vesselTypeSub",
		title: i18n.t("common.vesselTypeSub"),
		width: 150,
		render: (value, record, index) => {
			return <span>{ArrivalVesselSubTypeMap[record?.vesselTypeSub]}</span>;
		}
	},
	{
		title: i18n.t("common.dwt"),
		dataIndex: "dwt",
		width: 100,
		render: (value) => {
			return <span>{formatThousandthNumber(Number(value), 0, 0)}</span>;
		}
	},
	{
		title: "Build Year",
		dataIndex: "build",
		width: 100
	},
	{
		title: "Destination Port",
		dataIndex: "desPortName",
		width: 200
	},
	{
		title: "Destination Country",
		dataIndex: "countryName",
		width: 200
	},
	{
		title: "ETA",
		dataIndex: "eta",
		width: 150,
		render(value, record, index) {
			return <span>{value ? dayjs(value)?.format("YYYY-MM-DD HH:mm") : "-"}</span>;
		}
	},
	{
		title: "AIS Speed",
		dataIndex: "aisSpeed",
		width: 137
	},
	{
		title: "AIS Status",
		dataIndex: "aisStatus",
		width: 137,
		render: (value) => {
			return <span>{ArrivalAisStatusMap[value]}</span>;
		}
	},
	{
		title: "AIS Update",
		dataIndex: "aisUpdate",
		width: 150,
		render(value, record, index) {
			return <span>{dayjs(value)?.format("YYYY-MM-DD HH:mm")}</span>;
		}
	},
	{
		title: "Heading",
		dataIndex: "heading",
		width: 120,
		render: (value) => {
			return <span>{`${value}°`} </span>;
		}
	},
	{
		title: "Last Purpose",
		dataIndex: "lastPurpose",
		width: 130,
		render(value) {
			return <span>{ArrivalPurposeMap[value]}</span>;
		}
	},
	{
		title: "Last Port Name",
		dataIndex: "lastPortName",
		width: 200,
		ellipsis: true
	},
	{
		title: "Last Country Name",
		dataIndex: "lastCountryName",
		width: 200,
		ellipsis: true
	},
	{
		title: "Last Area Name",
		dataIndex: "startAreaName",
		width: 200,
		ellipsis: true
	},
	{
		title: "Selected Area Name",
		dataIndex: "areaName",
		width: 200,
		ellipsis: true
	},
	{
		title: "Last Port LAT",
		dataIndex: "lat",
		width: 150,
		render(value) {
			return <span>{convertToDms(value, "lat")}</span>;
		}
	},
	{
		title: "Last Port LON",
		dataIndex: "lon",
		width: 150,
		render(value) {
			return <span>{convertToDms(value, "lon")}</span>;
		}
	},
	{
		title: "Vsl AIS LAT",
		dataIndex: "aisLat",
		width: 150,
		render(value) {
			return <span>{convertToDms(value, "lat")}</span>;
		}
	},
	{
		title: "Vsl AIS LON",
		dataIndex: "aisLon",
		width: 150,
		render(value) {
			return <span>{convertToDms(value, "lon")}</span>;
		}
	},

	{
		title: "Gross Tonnage",
		dataIndex: "grossTonnage",
		width: 150
	},
	{
		title: "BM",
		dataIndex: "bm",
		width: 137
	},
	{
		title: "LOA",
		dataIndex: "loa",
		width: 137
	},
	{
		title: "Draft",
		dataIndex: "draft",
		width: 137
	},
	{
		title: "Status",
		dataIndex: "status",
		width: 137,
		render: (value) => {
			return <span>{ArrivalStatusMap[value]}</span>;
		}
	},

	{
		title: "Eco Engine",
		dataIndex: "ecoEngine",
		width: 120,
		render: (value) => {
			return <span>{ArrivalEcoEngineMap[value]}</span>;
		}
	},
	{
		title: "Sox Scrubber Status",
		dataIndex: "soxScrubberStatus",
		width: 150,
		render: (value) => {
			return <span>{ArrivalSoxScrubberStatusMap[value]}</span>;
		}
	},
	{
		title: "Owner Name",
		dataIndex: "ownerName",
		width: 200
	},
	{
		title: "Operator Name",
		dataIndex: "operatorName",
		width: 200,
		ellipsis: {
			showTitle: true
		}
	}
];
