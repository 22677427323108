import React, { FC } from "react";
import style from "./index.module.less";
import { RouteCommonPortTabbarProps } from "./type";
import { timeRangeOptions } from "./source";
import classnames from "classnames";

const RouteCommonPortTabbar: FC<RouteCommonPortTabbarProps> = ({ activeTab, onSelect }) => {
	return (
		<ul className={style["routeCommon-port-tabbar"]}>
			{timeRangeOptions?.map((item, index) => {
				return (
					<li
						key={index}
						className={classnames("port-tabbar-item", {
							active: activeTab === item?.value
						})}
						onClick={() => onSelect?.(item?.value)}
					>
						{item?.label}
					</li>
				);
			})}
		</ul>
	);
};

export default RouteCommonPortTabbar;
