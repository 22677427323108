import React, { Component, createContext } from 'react'

const { Provider, Consumer } = createContext(null);
const withScope = WrappedComponent => props => (
  <Consumer>{keep => <WrappedComponent {...props} keep={keep} />}</Consumer>
)

export class AliveScope extends Component {
  nodes = {}
  state = {}

  keep = (id, children) =>
    new Promise(resolve =>
      this.setState(
        {
          [id]: { id, children }
        },
        () => resolve(this.nodes[id])
      )
    )

  render() {
    return (
      <Provider value={this.keep}>
        {/* @ts-ignored */}
        {this.props.children}
        {/* @ts-ignored */}
        {Object.values(this.state).map(({ id, children }) => (
          <div
          style={{
            width:"inherit",
            height:"inherit"
          }}
            key={id}
            ref={node => {
              this.nodes[id] = node
            }}
          >
            {children}
          </div>
        ))}
      </Provider>
    )
  }
}

// @ts-ignored
@withScope
class KeepAlive extends Component<{
  children:React.ReactNode,
  id?:string
}> {
  placeholder;
  constructor(props) {
    super(props)
    this.init(props)
  }

  init = async ({ id, children, keep }) => {
    const realContent = await keep(id, children)
    this.placeholder.appendChild(realContent)
  }

  render() {
    return (
      <div
      style={{
        width:"100%",
        height:"100%"
      }}
        ref={node => {
          this.placeholder = node
        }}
      />
    )
  }
}

export default KeepAlive
