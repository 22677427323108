import { useCommonIndex } from "@/hook"
import { initialDialogItem } from "../source"

const useDialogIndex = () => {
  const {
    dataSource,
    change
  } = useCommonIndex(initialDialogItem)

  return {
    dataSource,
    change
  }
}

export default useDialogIndex