import { useEffect, useState } from "react";
import { initFilterData } from "../components/filter/source";
import { MonitoringAreaVesselListProps } from "../type";
import {
	downLoadTableListAction,
	getShipArgumentAction,
	getShipTableListAction,
	saveShipArgumentAction
} from "@/action/monitoring/area";
import { useAppDispatch } from "@/hook";
import { useNavigate } from "react-router-dom";
import useReminder from "@/hook/useReminder";
import {
	AreaListFilterProps,
	AreaListItemType,
	AreaListTableProps,
	FilterTimeRangeType
} from "../components";
import dayjs from "dayjs";

const useIndex = (
	activeIndex: MonitoringAreaVesselListProps["activeIndex"],
	currentTab: MonitoringAreaVesselListProps["currentTab"],
	areaItems: MonitoringAreaVesselListProps["areaItems"],
	onAisItemSelect: MonitoringAreaVesselListProps["onAisItemSelect"],
	onOpenPortCall: MonitoringAreaVesselListProps["onOpenPortCall"],
	onAreaPortVesselLoad: MonitoringAreaVesselListProps["onAreaPortVesselLoad"]
) => {
	const dispatch = useAppDispatch();
	const navigate = useNavigate();
	const { reminder } = useReminder();
	const [filter, setFilter] = useState<AreaListFilterProps["filter"]>(initFilterData());
	const [loading, setLoading] = useState(false);
	const [tableData, setTableData] = useState<AreaListItemType[]>([]);
	const [selectedRows, setSelectedRows] = useState<AreaListItemType[]>([]);
	const [timeRange, setTimeRange] = useState<FilterTimeRangeType>({
		startDate: dayjs().format("YYYY-MM-DD"),
		endDate: dayjs().format("YYYY-MM-DD")
	});
	const getShipArgumentFront = () => {
		setLoading(true);
	};
	const downLoadTableListSuccess = () => {
		reminder("success", "Download Successfully!");
		setLoading(false);
	};

	const saveShipArgumentSuccess = (response) => {
		setFilter(response?.data);
		getShipTableList(response?.data, timeRange);
	};

	const getShipTableListSuccess = (response) => {
		setTableData(response.data);
		onAreaPortVesselLoad(response.data);
		setLoading(false);
	};

	const getShipArgumentFailed = (error) => {
		setLoading(false);
		reminder("error", error?.msg ? error?.msg + ": " + error?.data : error?.data);
	};

	const handleCommit: AreaListFilterProps["onCommit"] = (item) => {
		saveShipArgument({ ...filter, ...item });
	};

	const handleTimeRange = (val: FilterTimeRangeType) => {
		setTimeRange(val);
		getShipTableList(filter, val);
	};

	const handleDownLoad = () => {
		if (selectedRows.length == 0) {
			return;
		}
		downLoadTableListAction(
			selectedRows,
			getShipArgumentFront,
			downLoadTableListSuccess,
			getShipArgumentFailed,
			dispatch,
			navigate
		);
	};

	const handleSelectAll = () => {
		if (selectedRows.length != tableData.length) {
			setSelectedRows(tableData);
		} else {
			setSelectedRows([]);
		}
	};

	const handleRowSelect: AreaListTableProps["onSelect"] = (item) => {
		const currentIndex = selectedRows?.findIndex((ele) => ele.imo == item.imo);
		if (currentIndex == -1) {
			setSelectedRows((prev) => [...prev, item]);
		} else {
			setSelectedRows((prev) => {
				prev.splice(currentIndex, 1);
				return [...prev];
			});
		}
	};
	const handleRowClick = (item: AreaListItemType, type: "detail" | "portCall") => {
		if (type === "detail") {
			onAisItemSelect("area", item, true);
			return;
		}
		onOpenPortCall?.(item, "portCallList");
	};

	const getShipArgument = () => {
		getShipArgumentAction(
			null,
			getShipArgumentFront,
			saveShipArgumentSuccess,
			getShipArgumentFailed,
			dispatch,
			navigate
		);
	};

	const saveShipArgument = (params: AreaListFilterProps["filter"]) => {
		saveShipArgumentAction(
			params,
			getShipArgumentFront,
			saveShipArgumentSuccess,
			getShipArgumentFailed,
			dispatch,
			navigate
		);
	};

	const getShipTableList = (
		filter: AreaListFilterProps["filter"],
		timeRange: FilterTimeRangeType
	) => {
		const areaCodeList = areaItems?.map((item) => {
			return item.areaCode;
		});
		getShipTableListAction(
			{
				areaCodeList: areaCodeList.join(","),
				startDate: timeRange?.startDate,
				endDate: timeRange?.endDate,
				...filter
			},
			getShipArgumentFront,
			getShipTableListSuccess,
			getShipArgumentFailed,
			dispatch,
			navigate
		);
	};

	useEffect(() => {
		if (currentTab === "area" && activeIndex === "areaVesslDetails") {
			getShipArgument();
		}
	}, [currentTab, activeIndex, areaItems]);

	return {
		filter,
		loading,
		tableData,
		selectedRows,
		handleCommit,
		handleDownLoad,
		handleRowClick,
		handleRowSelect,
		handleTimeRange,
		handleSelectAll
	};
};
export default useIndex;
