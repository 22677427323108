import { type PageParams } from "@/api/estimation";
import { useState, useRef, useMemo } from "react";
import { useNavigate } from "react-router-dom";
import { useAppDispatch } from "@/hook";
import debounce from 'lodash/debounce';
import useReminder from "@/hook/useReminder";
import { CommonTemplateItemType } from "../components/TempPopup/type";
import { deleteEstTemplateAction, queryEstTemplateGroupAction } from "@/action/estimation/template";


const useIndex = <
  T extends Partial<CommonTemplateItemType>,
>(
  activeItem: T,
  onFinish?: (newItem: T, type: "add" | "del") => void
) => {
  const { reminder } = useReminder();
  const navigate = useNavigate();
  const dispatch = useAppDispatch()
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(true);
  const [list, setList] = useState<T[]>([]);
  const [searchValue, setSearchVal] = useState("");
  const page = useRef<PageParams>({
    pageNum: 1,
    pageSize: 20
  });

  const selectValue = useMemo(() => {
    return [activeItem?.templateName]
  }, [activeItem])

  const closePopup = (open: boolean) => {
    if (!open) {
      // setSearchVal("");
      page.current.pageNum = 1;
    }
    setOpen(open);
  }

  const focusPopup = (loadMore?: boolean, searchVal?: string) => {
    queryEstTemplateGroupAction(
      {
        keyword: searchVal || searchValue,
        pageNum: page.current?.pageNum,
        pageSize: page.current?.pageSize
      },
      queryPortGroupFront,
      loadMore ? loadMoreSuccess : queryPortGroupSuccess,
      queryPortGroupError,
      dispatch,
      navigate
    )
  }

  const handleSelect = (item: T) => {
    const isExist = item?.id === activeItem?.id
    onFinish?.(item, isExist ? "del" : "add");
    setOpen(false);
  }

  const queryPortGroupFront = () => {
    setLoading(true);
  }

  const queryPortGroupSuccess = (response) => {
    setLoading(false);
    setList(response?.data?.items);
  }

  const queryPortGroupError = (error) => {
    setLoading(false)
    reminder("error", error?.msg ? (error?.msg + ": " + error?.data) : error?.data)
  }

  const loadMoreSuccess = (response) => {
    const data = response?.data?.items ?? [];
    setList(prev => [...prev, ...data]);
    setLoading(false);
  }


  const loadMore = () => {
    page.current = {
      ...page.current,
      pageNum: page.current.pageNum + 1
    };
    focusPopup(true)
  };

  const search = useMemo(() => {
    const loadOptions = (val: string) => {
      page.current.pageNum = 1;
      setList([]);
      setSearchVal(val)
      focusPopup(false, val);
    }
    return debounce(loadOptions, 800);
  }, [focusPopup])

  const deleteEstTemplate = (item: T) => {
    deleteEstTemplateAction(
      { id: item?.id },
      queryPortGroupFront,
      (response) => {
        setLoading(false);
        if (activeItem?.id === item?.id) {
          onFinish?.(null, "del")
        }
        focusPopup()
      },
      queryPortGroupError,
      dispatch,
      navigate
    )
  }

  return {
    loading,
    list,
    open,
    searchValue,
    page,
    selectValue,
    loadMore,
    closePopup,
    focusPopup,
    setSearchVal,
    handleSelect,
    search,
    deleteEstTemplate
  }
}

export default useIndex;