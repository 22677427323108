import { HandoverFormHeadFixtureType } from "./type";

export const initialHeadFixture = (item?: Partial<HandoverFormHeadFixtureType>): HandoverFormHeadFixtureType => {
	return {
		ownerName: item?.ownerId ? item?.ownerName : "",
		ownerId: item?.ownerId ?? null,
		brokerId: item?.brokerId ?? null,
		brokerName: item?.brokerName ?? "",
		cpDate: item?.cpDate ?? "",
		deliveryPortCode: item?.deliveryPortCode ?? "",
		deliveryPortName: item?.deliveryPortName ?? "",
		dailyHire: item?.dailyHire ?? "",
		commission: item?.commission ?? "",
		brokerage: item?.brokerage ?? "",
		otherExpenses: item?.otherExpenses ?? "",
		layCanFrom: item?.layCanFrom ?? null,
		layCanTo: item?.layCanTo ?? null,
		redeliveryPortCode: item?.redeliveryPortCode ?? "",
		redeliveryPortName: item?.redeliveryPortName ?? "",
		estRedeliveryDate: item?.estRedeliveryDate ?? null,
		shipownerRatingSpecialClaus: item?.shipownerRatingSpecialClaus ?? "",
		tough: item?.tough ?? false,
		vip: item?.vip ?? false,
		ownerCountry: item?.ownerCountry ?? "",
		deliveryDate: item?.deliveryDate ?? null,
		minDuration: item?.minDuration ?? "",
		maxDuration: item?.maxDuration ?? "",
		tradingArea: item?.tradingArea ?? "",
		paymentStartDay: item?.paymentStartDay ?? "",
		id: item?.id ?? null,
		voyageId: item?.voyageId ?? null,
		deliveryTime: item?.deliveryTime ?? null,
		paymentCycle: item?.paymentCycle ?? ""
	};
};

export const checkKeys: (keyof HandoverFormHeadFixtureType)[] = [
	"ownerName",
	"cpDate",
	"deliveryPortName",
	"dailyHire",
	"commission",
	"brokerage",
	"layCanFrom",
	"layCanTo",
	"shipownerRatingSpecialClaus"
];

export enum HeadFixtureLabelMap {
	"handoverForm-headFixture" = "Head Fixture",
	"handoverForm-tctc-rent" = "Charter In",
	"handoverForm-tctc-relet" = "Relet"
}