import { CommonInitItemEvent } from "@/types/event";
import { PortCallListFilterDataType } from "./type";

export const initPortCallListFilterData: CommonInitItemEvent<PortCallListFilterDataType> = (
	item: PortCallListFilterDataType
) => {
	return {
		startDate: item?.startDate ?? "",
		endDate: item?.endDate ?? "",
	};
};
