import { _range, range } from "@/tools";
import { useEffect, useRef, useState } from "react";

export interface Pos {
  x: number,
  y: number
}

interface PointItemLine {
  type: 'line'
  start: Pos
  end: Pos
}

interface PointItemCurve {
  type: 'curve'
  start: Pos
  end: Pos
  control1: Pos
  control2: Pos
}

type PointItem = Array<PointItemLine | PointItemCurve>;

export type PointList = PointItem;

type PlottingOptions = {
  layout: {
    root: string;
    m: number;
  };
  data: number[];
  axisX: {
    data: number[];
    format(param: string | number): string;
    top: number;
  };
  axisY: {
    data: number[];
    format(param: string | number): string;
    right: number;
  };
  series: {
    rgba: number[][];
    hoverRgba: number[][];
    lineColor: string;
  }[];
};

export type PlottingParams = {
  margin: number;
  width: number;
  height: number;
  firstEnding: boolean;
  animateId: number;
  isHover: boolean;
  areaId: number;
}

const usePlotting = (
  options: PlottingOptions,
  root: HTMLDivElement,
  source: {
    foList: number[],
    speedList: number[],
    maxFo: number,
    maxSpeed: number
  } = {
      foList: [],
      speedList: [],
      maxFo: 0,
      maxSpeed: 0
    },
  dot: {
    cpIfo: number,
    cpSpeed: number
  },
  activeKey?: "L" | "B",
) => {
  const {
    foList,
    speedList,
    maxFo,
    maxSpeed
  } = source;
  const speedParams = useRef({
    maxY: 0,
    minY: 0,
    rangeY: 0,
    ratioY: 0,
    count: 0,
    rangeX: 0,
    xk: 0,
    xkVal: 0,
    dataLen: 0,
    ratioX: 0,
    stepX: 0,
    startAreaX: 0,
    startAreaY: 0,
    endAreaX: 0,
    endAreaY: 0,
    prePointPosX: 0,
    prePointPosY: 0,
    newYs: [],
    newXs: [],
    axisXList: [],
    axisYList: [],
    concatData: [],
    pathPoints: [],
    pointList: [],
    data: [],
    axisX: [],
    axisY: [],
    maskHeight: 0,
    maskWidth: 0,
    animateId: 0,
    isHover: false,
    areaId: -1,
    maxX: 0,
    radioY: 0,

  });
  const foParams = useRef({
    maxY: 0,
    minY: 0,
    rangeY: 0,
    ratioY: 0,
    count: 0,
    rangeX: 0,
    xk: 0,
    xkVal: 0,
    dataLen: 0,
    ratioX: 0,
    stepX: 0,
    startAreaX: 0,
    startAreaY: 0,
    endAreaX: 0,
    endAreaY: 0,
    prePointPosX: 0,
    prePointPosY: 0,
    newYs: [],
    newXs: [],
    axisXList: [],
    axisYList: [],
    concatData: [],
    pathPoints: [],
    pointList: [],
    data: [],
    axisX: [],
    axisY: [],
    maskHeight: 0,
    maskWidth: 0,
    animateId: 0,
    isHover: false,
    areaId: -1,
    maxX: 0,
    radioY: 0
  });
  const plottingParams = useRef<PlottingParams>({
    margin: 0,
    width: 0,
    height: 0,
    firstEnding: true,
    animateId: 0,
    isHover: false,
    areaId: -1,
  });

  const plottingPrevDot = useRef({
    cpIfo: 0,
    cpSpeed: 0
  });

  const plottingCtx = useRef<{
    ctx: CanvasRenderingContext2D | null;
    dotCtx: CanvasRenderingContext2D | null;
    markCtx: CanvasRenderingContext2D | null;
  }>({
    ctx: null,
    dotCtx: null,
    markCtx: null,
  });

  function watchEvent() {
    // window.addEventListener('mousemove', mousemoveEvent)
    // window.addEventListener('resize', throttleSizeEvent)
  }

  const initBaseData = () => {
		let {
			layout: { m }
		} = options;
		plottingParams.current.margin = m;
		speedParams.current.data = speedList?.length === 1 ? [0, ...speedList] : speedList;
		foParams.current.data = foList?.length === 1 ? [0, ...foList] : foList;

		let canvas = document.createElement("canvas");
		let dotCanvas = document.createElement("canvas");
		let markCanvas = document.createElement("canvas");

		canvas.id = "myCanvas";
		dotCanvas.id = "myCanvasDot";
		markCanvas.id = "markCanvas";

		if (root.children.length <= 0) {
			root.appendChild(canvas);
			root.appendChild(dotCanvas);
			root.appendChild(markCanvas);
			canvas.width = root.clientWidth;
			canvas.height = root.clientHeight;
			dotCanvas.width = root.clientWidth;
			dotCanvas.height = root.clientHeight;
			markCanvas.width = root.clientWidth;
			markCanvas.height = root.clientHeight;

			plottingCtx.current = {
				ctx: canvas.getContext("2d"),
				dotCtx: dotCanvas.getContext("2d"),
				markCtx: markCanvas.getContext("2d")
			};
		}
		plottingCtx.current.ctx.clearRect(0, 0, root.clientWidth, root.clientHeight);
		// 路由所有点
		speedParams.current.pointList = [];
		speedParams.current.pathPoints = [];
		foParams.current.pointList = [];
		foParams.current.pathPoints = [];
		// 完整数据

		// 定义画布尺寸和边距
		plottingParams.current.width = root.clientWidth;
		plottingParams.current.height = root.clientHeight;

		// 计算 Y 轴坐标比例尺 ratioY
		// speedParams.current.maxY = Math.max.apply(null, speedParams.current.data) + 5;
		speedParams.current.maxY = 16;
		speedParams.current.minY = 0;
		// speedParams.current.minY = Math.min.apply(null, speedParams.current.data) - 5;
		speedParams.current.rangeY = speedParams.current.maxY - speedParams.current.minY;

		foParams.current.maxY =
			foParams.current.data?.length === 0 ? 4 : Math.max.apply(null, foParams.current.data) + 5;
		foParams.current.minY = 0;
		foParams.current.rangeY = foParams.current.maxY - foParams.current.minY;

		speedParams.current.axisX = range(0, speedParams.current.data.length, 1);
		// speedParams.current.axisY = _range(speedParams.current.minY, speedParams.current.maxY);
		speedParams.current.axisY = range(speedParams.current.minY, speedParams.current.maxY, 4);
		foParams.current.axisX = range(0, foParams.current.data.length, 1);
		foParams.current.axisY =
			foParams.current.data?.length === 0
				? range(foParams.current.minY, foParams.current.maxY, 1)
				: _range(foParams.current.minY, foParams.current.maxY);
		// foParams.current.axisY = range(foParams.current.minY, foParams.current.maxY, foParams.current.rangeY);;
		console.log("foParams", foParams.current.axisY);
		// 数据和坐标范围的比值
		speedParams.current.ratioY =
			(plottingParams.current.height - 2 * plottingParams.current.margin) /
			speedParams.current.rangeY;
		foParams.current.ratioY =
			(plottingParams.current.height - 2 * plottingParams.current.margin) / foParams.current.rangeY;
		// 计算 X 轴坐标比例尺和步长
		speedParams.current.count = speedParams.current.data.length - 1;
		foParams.current.count = foParams.current.data.length - 1;

		speedParams.current.rangeX = plottingParams.current.width - 2 * plottingParams.current.margin;
		foParams.current.rangeX = plottingParams.current.width - 2 * plottingParams.current.margin;

		speedParams.current.xk = 1;
		foParams.current.xk = 1;

		speedParams.current.xkVal = speedParams.current.xk * plottingParams.current.margin;
		foParams.current.xkVal = foParams.current.xk * plottingParams.current.margin;

		speedParams.current.dataLen = speedParams.current.data.length;
		foParams.current.dataLen = foParams.current.data.length;

		speedParams.current.ratioX = speedParams.current.rangeX / speedParams.current.count;
		speedParams.current.stepX = speedParams.current.ratioX;

		foParams.current.ratioX = foParams.current.rangeX / foParams.current.count;
		foParams.current.stepX = foParams.current.ratioX;

		speedParams.current = {
			...speedParams.current,
			startAreaX: 0,
			startAreaY: 0,
			endAreaX: 0,
			endAreaY: 0,
			prePointPosX: 0,
			prePointPosY: 0
		};

		foParams.current = {
			...foParams.current,
			startAreaX: 0,
			startAreaY: 0,
			endAreaX: 0,
			endAreaY: 0,
			prePointPosX: 0,
			prePointPosY: 0
		};

		console.log("speedParams", {
			foParams,
			speedParams,
			foList,
			speedList
		});
	};

	plottingPrevDot.current = {
		cpIfo: dot.cpIfo,
		cpSpeed: dot.cpSpeed
	};

	console.log("plottingPrevDot", speedParams, dot);

	const clear = () => {
		const { ctx } = plottingCtx.current;
		ctx.clearRect(0, 0, root.clientWidth, root.clientHeight);
	};

	console.log("plottingParams,plottingParams", plottingParams);

	// const drawAnimate = () => {
	//   const {
	//     markCtx
	//   } = plottingCtx.current;
	//   const {
	//     width,
	//     height,
	//     maskWidth,
	//     maskHeight
	//   } = plottingParams.current
	//   markCtx.clearRect(0, 0, width, height);

	//   markCtx.fillStyle = "rgba(255, 255, 255, 1)"
	//   markCtx.fillRect(0, 0, width, height);

	//   markCtx.clearRect(
	//     (width - maskWidth),
	//     (height - maskHeight),
	//     maskWidth,
	//     maskHeight
	//   );

	//   // 更新遮罩区域大小
	//   plottingParams.current.maskWidth += 20;
	//   plottingParams.current.maskHeight += 20;
	//   if (maskWidth < width) {
	//     plottingParams.current.animateId = requestAnimationFrame(drawAnimate);
	//   } else {
	//     cancelAnimationFrame(plottingParams.current.animateId)
	//     watchEvent()
	//   }
	// }

	const drawCoordinateAxis = () => {
		const { ctx } = plottingCtx.current;
		const { margin, height, width } = plottingParams.current;
		ctx.beginPath();
		ctx.moveTo(margin, margin);
		ctx.lineTo(margin, height - margin);
		ctx.lineTo(width - margin + 2, height - margin);
		ctx.fillText("KNOT", margin - 30, margin - 15);
		ctx.fillText("MT/day", width - 40, margin - 15);
		ctx.setLineDash([3, 3]);
		ctx.strokeStyle = "#aaa";
		ctx.stroke();
		ctx.setLineDash([1]);
	};

	const drawXaxis = () => {
		const { ctx } = plottingCtx.current;
		const { margin, height } = plottingParams.current;
		const { axisX, stepX } = speedParams.current;
		const xLen = axisX.length;

		for (let i = 0; i < xLen; i++) {
			let xPos = margin + i * stepX;
			console.log("xPos", xPos);
			if (i && i !== xLen - 1) {
				ctx.beginPath();
				ctx.moveTo(xPos, height - margin);
				ctx.lineTo(xPos, margin);
				ctx.strokeStyle = "#ddd";
				ctx.stroke();
			}
			speedParams.current.newXs = [];
			foParams.current.newXs = [];
			for (let index in axisX) {
				speedParams.current.newXs.push(`${axisX[index]}`);
				foParams.current.newXs.push(`${foParams.current.axisX?.[index]}`);
			}

			// ctx.fillText(speedParams.current.newXs[i], xPos - 1, height - margin + 10 + options.axisX.top);
			plottingParams.current.firstEnding && speedParams.current.axisXList.push(xPos - 1);
		}
	};

	const drawYaxis = () => {
		const { ctx } = plottingCtx.current;
		const { margin, height, width } = plottingParams.current;
		const { axisY, rangeY, minY, ratioY } = speedParams.current;

		const yLen = axisY.length;

		for (let i = 0; i < yLen; i++) {
			let y = (rangeY * i) / (yLen - 1) + minY;
			let foY = (foParams.current.rangeY * i) / (yLen - 1) + foParams.current.minY;
			let yPos = height - margin - (y - minY) * ratioY;

			if (i) {
				ctx.beginPath();
				ctx.moveTo(margin, yPos);
				ctx.lineTo(width - margin, yPos);
				ctx.strokeStyle = "#ddd";
				ctx.stroke();
			}

			ctx.fillText(`${+y.toFixed(2)}` + "", margin - 20 - options.axisY.right, yPos + 5);
			ctx.fillText(`${+foY.toFixed(2)}` + "", width - margin + 7, yPos + 5);

			ctx.beginPath();
			ctx.stroke();
			speedParams.current.newYs = [];
			foParams.current.newYs = [];
			for (let index in axisY) {
				speedParams.current.newYs.push(`${axisY[index]}`);
				foParams.current.newYs.push(`${foParams.current.axisY[index]}`);
			}
			// if (i === yLen - 1) {
			//   ctx.fillText(maxSpeed + '', margin - 15 - options.axisY.right, yPos + 5);
			//   ctx.fillText(maxFo + '', width - margin + 15, yPos + 5);
			// } else {
			//   ctx.fillText(speedParams.current.newYs[i] + '', margin - 15 - options.axisY.right, yPos + 5);
			//   ctx.fillText(foParams.current.newYs[i] + '', width - margin + 15, yPos + 5);
			// }
			plottingParams.current.firstEnding && speedParams.current.axisYList.push(yPos + 5);
		}
	};

	const drawLine = (type: "speed" | "fo", data: any, params: typeof speedParams) => {
		const { ctx } = plottingCtx.current;
		const { margin, height, width } = plottingParams.current;
		const { points, id, rgba, lineColor, hoverRgba } = data;

		params.current.startAreaX = params.current.endAreaX;
		params.current.startAreaY = params.current.endAreaY;
		// 分割区
		if (plottingParams.current.firstEnding) {
			// 浮动展示框
			// areaList.push({ x: startAreaX, y: startAreaY })
		}

		function darwColorOrLine(lineMode: boolean) {
			// 绘制折线
			ctx.beginPath();
			ctx.moveTo(
				id
					? margin + params.current.endAreaX - params.current.xkVal
					: margin + params.current.endAreaX,
				height - margin - (points[0] - params.current.minY) * params.current.ratioY
			);
			ctx.lineWidth = 2;
			ctx.setLineDash([0, 0]);

			let x = 0,
				y = 0,
				translateX = 0;
			if (id) {
				translateX -= margin;
			}
			for (let i = 0; i < points.length; i++) {
				x = i * params.current.stepX + margin + params.current.endAreaX + translateX;
				y = height - margin - (points[i] - params.current.minY) * params.current.ratioY;

				let x0 = (i - 1) * params.current.stepX + margin + params.current.endAreaX + translateX;
				let y0 = height - margin - (points[i - 1] - params.current.minY) * params.current.ratioY;
				let xc = x0 + params.current.stepX / 2;
				// let yc = (y0 + y) / 2;
				if (i === 0) {
					params.current.prePointPosX = x;
					params.current.prePointPosY = y;
					ctx.lineTo(x, y);
					if (!(params.current.prePointPosX === x && params.current.prePointPosY === y)) {
						params.current.pointList.push({
							type: "line",
							start: { x: params.current.prePointPosX, y: params.current.prePointPosY },
							end: { x: x, y: y }
						});
					}
				} else {
					ctx.bezierCurveTo(xc, y0, xc, y, x, y);
					params.current.pointList.push({
						type: "curve",
						start: { x: params.current.prePointPosX, y: params.current.prePointPosY },
						end: { x: x, y: y },
						control1: { x: xc, y: y0 },
						control2: { x: xc, y: y }
					});
				}
				params.current.prePointPosX = x;
				params.current.prePointPosY = y;
				if (i === points.length - 1) {
					params.current.endAreaX = x;
					params.current.endAreaY = y;

					// if (firstEnding && id === newOpt.data.length - 1) {
					//   areaList.push({ x: x, y: y })
					// }
				}
			}
			type === "speed" && (ctx.strokeStyle = "#355691");
			ctx.stroke();

			lineMode && ctx.beginPath();
			// 右侧闭合点
			ctx.lineTo(
				params.current.endAreaX,
				plottingParams.current.height - plottingParams.current.margin
			);
			// 左侧闭合点
			ctx.lineTo(
				plottingParams.current.margin + params.current.startAreaX,
				plottingParams.current.height - plottingParams.current.margin
			);
			let startClosePointX = id
				? params.current.startAreaX
				: plottingParams.current.margin + params.current.startAreaX;
			// 交接闭合点
			ctx.lineTo(startClosePointX, plottingParams.current.height - plottingParams.current.margin);
			ctx.strokeStyle = "transparent";
			lineMode && ctx.stroke();
		}
		darwColorOrLine(type === "speed" ? true : false);

		// 渐变
		const gradient = ctx.createLinearGradient(200, 110, 200, 290);

		if (plottingParams.current.isHover && plottingParams.current.areaId === id) {
			gradient.addColorStop(
				0,
				`rgba(${hoverRgba[1][0]}, ${hoverRgba[1][1]}, ${hoverRgba[1][2]}, 1)`
			);
			gradient.addColorStop(
				1,
				`rgba(${hoverRgba[0][0]}, ${hoverRgba[0][1]}, ${hoverRgba[0][2]}, 1)`
			);
		} else {
			gradient.addColorStop(0, `rgba(195,211,223, 1)`);
			gradient.addColorStop(1, `rgba(231, 245, 242, 0)`);
		}

		ctx.fillStyle = gradient;
		ctx.fill();
	};

	const startDrawLines = (type: "speed" | "fo") => {
		const params = type === "speed" ? speedParams : foParams;
		const { data } = params.current;
		const { series } = options;
		for (let i = 0; i < [data].length; i++) {
			drawLine(
				type,
				{
					points: [data][i],
					id: i,
					rgba: series[i].rgba,
					hoverRgba: series[i].hoverRgba,
					lineColor: series[i].lineColor
				},
				params
			);
		}
		plottingParams.current.firstEnding = false;
	};

	const drawSplitline = () => {
		const { ctx } = plottingCtx.current;
		const { margin, height, width } = plottingParams.current;
		const { axisY, rangeY, minY, ratioY } = speedParams.current;
		ctx.save();
		if (
			+plottingPrevDot.current.cpSpeed > speedParams.current.minY &&
			+plottingPrevDot.current.cpSpeed <= +speedParams.current.maxY
		) {
			const yPos =
				height -
				margin -
				(+plottingPrevDot.current.cpSpeed - speedParams.current.minY) * speedParams.current.ratioY;
			plottingCtx.current.ctx.strokeStyle = "#FF8632";
			ctx.beginPath();
			ctx.moveTo(margin, yPos);
			ctx.lineTo(margin + width - margin * 2, yPos);
			ctx.stroke();
		}
		if (
			+plottingPrevDot.current.cpIfo > foParams.current.minY &&
			+plottingPrevDot.current.cpIfo <= +foParams.current.maxY
		) {
			const yPos =
				height -
				margin -
				(+plottingPrevDot.current.cpIfo - foParams.current.minY) * foParams.current.ratioY;
			const yHeight = height - yPos - margin;
			ctx.fillStyle = "rgba(231, 245, 242, 0.5)";
			ctx.strokeStyle = "rgba(231, 245, 242, 0.5)";
			ctx.beginPath();
			ctx.fillRect(
				margin,
				yPos,
				plottingParams.current.width - plottingParams.current.margin * 2,
				yHeight
			);
			ctx.beginPath();
			ctx.moveTo(margin, yPos);
			ctx.lineTo(margin + width - margin * 2, yPos);
			plottingCtx.current.ctx.stroke();
		}
		ctx.restore();
	};

	function drawAxis() {
		drawCoordinateAxis();
		// 绘制 Y 轴坐标标记和标签
		drawYaxis();
		// 绘制 X 轴坐标标签
		drawXaxis();
	}

	const drwaEmpty = () => {
		const { width, margin, height } = plottingParams.current;
		// ctx.current.fillStyle = "#333"
		// ctx.current.font = "30px"
		plottingCtx.current.ctx.fillText("No Data", width / 2, height / 2);
	};

	useEffect(() => {
		if (!root) return;
		initBaseData();
		clear();
		drawCoordinateAxis();
		debugger;

		if (speedParams.current.axisX?.length <= 0 && foParams.current.axisX?.length <= 0) {
			drwaEmpty();
			return;
		}
		// 绘制 Y 轴坐标标记和标签
		drawYaxis();
		// 绘制 X 轴坐标标签
		drawXaxis();
		// plottingCtx.current.ctx.fillStyle = "rgba(231, 245, 242, 0.5)";
		// plottingCtx.current.ctx.fillRect(40, 120, plottingParams.current.width - plottingParams.current.margin * 2, 40);
		startDrawLines("fo");
		startDrawLines("speed");
		// drawSplitline(
		drawSplitline();
	}, [root, foList, speedList, activeKey, dot]);

  return {
    clear
  }

}

export default usePlotting;