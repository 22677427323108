import { ParameterType } from "@/api";
import {
	getSelectedPortListApi,
	getSelectedPortListParams,
	getPortVesselListApi,
	getPortVesselListParams,
	getPortVesselSearchDataApi,
	savePortListSearchParams,
	savePortVesselSearchDataApi,
  getPortVesselSummaryApi,
  getCountryCodeListApi,
  getCountryCodeListParams,
  getAreaCodeListApi,
  getAreaCodeListParams,
  downLoadPortVesselParams,
  downloadPortVesselApi
} from "@/api/monitoring/portList";
import { loginOutAction } from "@/store/userSlice";

export const getSelectedPortListAction: ParameterType<getSelectedPortListParams> = (
	params,
	frontCallback,
	successCallback,
	errorCallback,
	dispatch,
	navigate
) => {
	frontCallback?.();
	getSelectedPortListApi(params)
		.then((response) => {
			successCallback?.(response);
		})
		.catch((err) => {
			errorCallback?.(err);
			if (err?.loginStatus === "token faild") {
				dispatch(loginOutAction(() => navigate?.("/login")));
			}
		});
};

export const getPortVesselListAction: ParameterType<getPortVesselListParams> = (
	params,
	frontCallback,
	successCallback,
	errorCallback,
	dispatch,
	navigate
) => {
	frontCallback?.();
	getPortVesselListApi(params)
		.then((response) => {
			successCallback?.(response);
		})
		.catch((err) => {
			errorCallback?.(err);
			if (err?.loginStatus === "token faild") {
				dispatch(loginOutAction(() => navigate?.("/login")));
			}
		});
};

export const getPortVesselSearchDataAction: ParameterType<{ null }> = (
	params,
	frontCallback,
	successCallback,
	errorCallback,
	dispatch,
	navigate
) => {
	frontCallback?.();
	getPortVesselSearchDataApi()
		.then((response) => {
			successCallback?.(response);
		})
		.catch((err) => {
			errorCallback?.(err);
			if (err?.loginStatus === "token faild") {
				dispatch(loginOutAction(() => navigate?.("/login")));
			}
		});
};

export const savePortVesselSearchDataAction: ParameterType<savePortListSearchParams> = (
	params,
	frontCallback,
	successCallback,
	errorCallback,
	dispatch,
	navigate
) => {
	frontCallback?.();
	savePortVesselSearchDataApi(params)
		.then((response) => {
			successCallback?.(response);
		})
		.catch((err) => {
			errorCallback?.(err);
			if (err?.loginStatus === "token faild") {
				dispatch(loginOutAction(() => navigate?.("/login")));
			}
		});
};

export const getPortVesselSummaryAction: ParameterType<getPortVesselListParams> = (
	params,
	frontCallback,
	successCallback,
	errorCallback,
	dispatch,
	navigate
) => {
	frontCallback?.();
	getPortVesselSummaryApi(params)
		.then((response) => {
			successCallback?.(response);
		})
		.catch((err) => {
			errorCallback?.(err);
			if (err?.loginStatus === "token faild") {
				dispatch(loginOutAction(() => navigate?.("/login")));
			}
		});
};

export const getCountryCodeListAction: ParameterType<getCountryCodeListParams> = (
	params,
	frontCallback,
	successCallback,
	errorCallback,
	dispatch,
	navigate
) => {
	frontCallback?.();
	getCountryCodeListApi(params)
		.then((response) => {
			successCallback?.(response);
		})
		.catch((err) => {
			errorCallback?.(err);
			if (err?.loginStatus === "token faild") {
				dispatch(loginOutAction(() => navigate?.("/login")));
			}
		});
};

export const getAreaCodeListAction: ParameterType<getAreaCodeListParams> = (
	params,
	frontCallback,
	successCallback,
	errorCallback,
	dispatch,
	navigate
) => {
	frontCallback?.();
	getAreaCodeListApi(params)
		.then((response) => {
			successCallback?.(response);
		})
		.catch((err) => {
			errorCallback?.(err);
			if (err?.loginStatus === "token faild") {
				dispatch(loginOutAction(() => navigate?.("/login")));
			}
		});
};

export const downLoadPortVesselAction: ParameterType<downLoadPortVesselParams[]> = (
	params,
	frontCallback,
	successCallback,
	errorCallback,
	dispatch,
	navigate
) => {
	frontCallback?.();
	downloadPortVesselApi(params)
		.then((response) => {
			successCallback?.(response);
		})
		.catch((err) => {
			errorCallback?.(err);
			if (err?.loginStatus === "token faild") {
				dispatch(loginOutAction(() => navigate?.("/login")));
			}
		});
};