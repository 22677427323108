import React, { FC } from "react";
import style from "./index.module.less";
import classnames from "classnames";
import { FleetLoading, FleetTable } from "@/components";
import { RoutePlanDetailsProps } from "./type";
import useIndex from "./hooks/useIndex";
import { columns } from "./source";

const RoutePlanDetails: FC<RoutePlanDetailsProps> = ({
  id,
  className,
  activeIndex,
  onBack,
  mapRef
}) => {
  const { loading, dataSource, handleFlyTo } = useIndex(id, activeIndex, mapRef)
  console.log("activeIndex", activeIndex);
	return (
		<div
			className={classnames(
				style["routePlan-details"],
				activeIndex === "routePlan" && style["routePlan-details--active"]
			)}
		>
			<FleetLoading loading={loading} />
			<div className="routePlanDetails-back" onClick={onBack}>
				<span className="font_family fleet-back back-icon"></span>
				<span className="back-title">Back</span>
			</div>
			<FleetTable
				id="key"
				dataSource={dataSource}
				columns={columns}
				className="routePlanDetails-container"
				pagination={false}
				onRow={(record) => {
					return {
						onDoubleClick: () => handleFlyTo(record)
					};
				}}
			/>
		</div>
	);
}

export default RoutePlanDetails