import React from "react";
import { ExtendsKeys } from "@/types";
import { ColumnsType } from "antd/es/table/interface";
import { dateFormat } from "@/tools/date";

type VesselKeys = "bale" |
  "buildCountry" |
  "buildDate" |
  "builder" |
  "callsign" |
  "createTime" |
  "createUser" |
  "depth" |
  "draught" |
  "dwt" |
  "exName1" |
  "exName2" |
  "flag" |
  "grain" |
  "grt" |
  "holdNumber" |
  "imo" |
  "loa" |
  "manager" |
  "mmsi" |
  "nrt" |
  "owner" |
  "power" |
  "rpm" |
  "speed" |
  "summerDraft" |
  "updateTime" |
  "updateUser" |
  "vesselNameCn" |
  "vesselNameEn" |
  "vesselTypeLv1" |
  "vesselTypeLv2" |
  "vesselName" |
  "id";

type VesselNumKeys = ExtendsKeys<VesselKeys,
  "bale" |
  "createUser" |
  "depth" |
  "draught" |
  "dwt" |
  "grain" |
  "grt" |
  "holdNumber" |
  "imo" |
  "loa" |
  "mmsi" |
  "nrt" |
  "power" |
  "rpm" |
  "speed" |
  "summerDraft" |
  "updateUser"
>;

type VesselStrKeys = ExtendsKeys<VesselKeys,
  "buildCountry" |
  "buildDate" |
  "builder" |
  "callsign" |
  "createTime" |
  "exName1" |
  "exName2" |
  "flag" |
  "manager" |
  "owner" |
  "updateTime" |
  "vesselName" |
  "vesselNameCn" |
  "vesselNameEn" |
  "vesselTypeLv1" |
  "vesselTypeLv2"
>;

type VesselIdKeys = ExtendsKeys<VesselKeys, "id">;

type DatabaseVesselKeys = VesselNumKeys | VesselStrKeys | VesselIdKeys;

export type DatabaseVesselFormType = Record<VesselStrKeys, string> & Record<VesselNumKeys, number> & Partial<Record<VesselIdKeys, string | number>>;

export type DatabaseVesselColumnKeys = DatabaseVesselKeys | "option";

export type calcuateVesselKeyType = ExtendsKeys<DatabaseVesselKeys,
  "grt" |
  "nrt" |
  "grain" |
  "summerDraft" |
  "bale" |
  "loa" |
  "depth" |
  "draught" |
  "holdNumber" |
  "power" |
  "rpm"
>;

export type DataType = {
  key: React.Key;
} & Record<DatabaseVesselColumnKeys, any>;

export const columns: ColumnsType<DataType> = [
  {
    dataIndex: "vesselName",
    width: 200,
    title: "Vessel Name",
    key: "vesselName",
    fixed: "left",
    align: "center",
    render: (value, record, index) => {
      return <span>{record?.vesselName ?? record?.vesselNameEn ?? record?.vesselNameCn}</span>
    }
  },
  {
    dataIndex: "exName1",
    width: 200,
    title: "Ex Name",
    key: "exName1",
    align: "center",
    render: (value, record, index) => {
      return <span>{record?.exName1 ?? record?.exName2}</span>
    }
  },
  {
    dataIndex: "imo",
    width: 200,
    title: "IMO",
    key: "imo",
    align: "center"
  }, {
    dataIndex: "mmsi",
    width: 150,
    title: "MMSI",
    key: "mmsi",
    align: "center"
  },
  {
    dataIndex: "speed",
    width: 150,
    title: "Speed",
    key: "speed",
    align: "center"
  }, {
    dataIndex: "dwt",
    width: 150,
    title: "DWT",
    key: "dwt",
    align: "center"
  }, {
    dataIndex: "vesselTypeLv1",
    width: 150,
    title: "Vessel Type",
    key: "vesselTypeLv1",
    align: "center",
    render: (value, record, index) => {
      return <span>{record?.vesselTypeLv1 ?? record?.vesselTypeLv2}</span>
    }
  }, {
    dataIndex: "callsign",
    width: 150,
    title: "Call Sign",
    key: "callsign",
    align: "center"
  },
  {
    dataIndex: "owner",
    width: 150,
    title: "Owner Name",
    key: "owner",
    align: "center"
  }, {
    dataIndex: "flag",
    width: 150,
    title: "Flag",
    key: "flag",
    align: "center"
  }, {
    dataIndex: "manager",
    width: 150,
    title: "Manager",
    key: "manager",
    align: "center"
  }, {
    dataIndex: "builder",
    width: 150,
    title: "Builder",
    key: "builder",
    align: "center"
  }, {
    dataIndex: "buildCountry",
    width: 150,
    title: "Build Country",
    key: "buildCountry",
    align: "center"
  }, {
    dataIndex: "buildDate",
    width: 150,
    title: "Build Date",
    key: "buildDate",
    align: "center"
  }, {
    dataIndex: "createUser",
    width: 150,
    title: "Create User",
    key: "createUser",
    align: "center"
  }, {
    dataIndex: "updateTime",
    width: 150,
    title: "Update Time",
    key: "updateTime",
    align: "center",
    render: (value, record, index) => {
      return <span>{dateFormat("YY-mm-dd HH:MM", value)}</span>
    }
  }, {
    dataIndex: "createTime",
    width: 150,
    title: "Create Time",
    key: "createTime",
    align: "center",
    render: (value, record, index) => {
      return <span>{dateFormat("YY-mm-dd HH:MM", value)}</span>
    }
  }, {
    dataIndex: "grt",
    width: 150,
    title: "GRT",
    key: "grt",
    align: "center"
  }, {
    dataIndex: "nrt",
    width: 150,
    title: "NRT",
    key: "nrt",
    align: "center"
  }, {
    dataIndex: "grain",
    width: 150,
    title: "Grain",
    key: "grain",
    align: "center"
  }, {
    dataIndex: "summerDraft",
    width: 150,
    title: "Summer Draft",
    key: "summerDraft",
    align: "center"
  }, {
    dataIndex: "bale",
    width: 150,
    title: "Bale",
    key: "bale",
    align: "center"
  }, {
    dataIndex: "loa",
    width: 150,
    title: "Loa",
    key: "loa",
    align: "center"
  }, {
    dataIndex: "depth",
    width: 150,
    title: "Depth",
    key: "depth",
    align: "center"
  }, {
    dataIndex: "draught",
    width: 150,
    title: "Draught",
    key: "draught",
    align: "center"
  }, {
    dataIndex: "holdNumber",
    width: 150,
    title: "Hold Number",
    key: "holdNumber",
    align: "center"
  }, {
    dataIndex: "power",
    width: 150,
    title: "Power",
    key: "power",
    align: "center"
  }, {
    dataIndex: "rpm",
    width: 150,
    title: "Rpm",
    key: "rpm",
    align: "center"
  }
];

export const calcuateVesselKeys: calcuateVesselKeyType[] = [
  "grt",
  "nrt",
  "grain",
  "summerDraft",
  "bale",
  "loa",
  "depth",
  "draught",
  "holdNumber",
  "power",
  "rpm"
]