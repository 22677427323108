import { AxiosRequestConfig } from "axios";
import { type ApiParameter } from "@/api";
import { appVersion, appVersionV2 } from "@/api/constant";

import request from "@/service";
import { FleetPageParams } from "@/types/common";

const enum ApiMap {
	getVesselListParameter,
	getVesselDetailParameter,
	getAISListParameter,
	getAISStateParameter,
	getDailyReportParameter,
	getRoutePlanParameter,
	getPerformanceParameter,
	getVesselTrackFileParameter,
	getVesselTrackParameter,
	getPorts,
	getDailyReportDetailParameter,
	getSegmentParameter,
	addSegmentParameter,
	updateSegmentParameter,
	getCPTermsParameter
}

type ApiUrl = keyof typeof ApiMap;

const api: ApiParameter<ApiUrl> = {
	getVesselListParameter: {
		method: "get",
		url: appVersion + "/moni/voy/list"
	},
	getVesselDetailParameter: {
		method: "get",
		url: appVersion + "/moni/voy/detail"
	},
	getAISListParameter: {
		method: "get",
		url: appVersion + "/vessel/search"
	},
	getAISStateParameter: {
		method: "get",
		url: appVersion + "/ais/shipdt/state"
	},
	getDailyReportParameter: {
		method: "get",
		url: appVersionV2 + "/monitoring/report/daily"
	},
	getRoutePlanParameter: {
		method: "get",
		// url: appVersion + "/moni/voy/route/plan"
		url: appVersion + "/moni/route-plan/list"
	},
	getPerformanceParameter: {
		method: "get",
		url: appVersion + "/monitoring/report/performance"
	},
	getVesselTrackFileParameter: {
		method: "get",
		url: appVersion + "/ais/shipdt/current/track" //"/ais/shipdt/track"
	},
	getVesselTrackParameter: {
		method: "get",
		url: "/s3file/shiptrack"
	},
	getPorts: {
		method: "get",
		url: appVersion + "/port/search"
	},
	getDailyReportDetailParameter: {
		method: "get",
		url: appVersion + "/monitoring/report/detail"
		// url: appVersionV2 + "/monitoring/report/daily"
	},
	getSegmentParameter: {
		method: "get",
		url: "/api/monitoring/segment/list"
	},
	addSegmentParameter: {
		method: "post",
		url: "/api/monitoring/segment"
	},
	updateSegmentParameter: {
		method: "put",
		url: "/api/monitoring/segment"
	},
	getCPTermsParameter: {
		method: "get",
		url: appVersion + "/cp-terms/selection-list"
	}
};

export type GetVesselListParams = {
	statusList: string;
} & FleetPageParams;

export type GetVesselTrackParams = {
	mmsi: number;
	days: number;
};

export type GetVesselDetailParams = {
	voyageNo: string;
	type: string;
};

export type GetAISListParams = {
	keyword: string;
};

export type GetAISStateParams = {
	mmsi: number;
};

export type GetDailyReportParams = {
	voyageNo: string;
	legType: string;
};

export type GetRoutePlanParams = {
	voyageNo: string;
	legType: string;
};

export type GetPerformanceParams = {
	voyageNo: string;
	legType: string;
	startPortCode: string;
	endPortCode: string;
	voyageId: number;
};

export type GetPortsParams = {
	keyword: string;
	pageNum: string;
	pageSize: string;
	country: string;
	portType: string;
};

export type GetDailyReportDetailParams = {
	voyageNo: string;
	reportTime: number;
	reportType: string;
};

export type GetSegmentParams = {
	voyageNo: string;
	legType: string;
};

export interface SegmentInfo {
	id?: number;
	segNo?: number;
	dueDatetime?: number;
	legType?: string;
	cpTermsId?: number;
	voyageNo?: string;
}

export type UpdateSegmentParams = {
	delIds: number[];
	segmentList: SegmentInfo[];
};

export const getVesselList = (params: GetVesselListParams) => {
	return request.request({
		...api.getVesselListParameter,
		params
	});
};

export const getVesselDetail = (params: GetVesselDetailParams, config?: AxiosRequestConfig) => {
	return request.request({
		...api.getVesselDetailParameter,
		...config,
		params
	});
};

export const getAISList = (params: GetAISListParams) => {
	return request.request({
		...api.getAISListParameter,
		params
	});
};

export const getAISState = (params: GetAISStateParams) => {
	return request.request({
		...api.getAISStateParameter,
		url: api.getAISStateParameter.url + "/" + params.mmsi
	});
};

export const getDailyReportList = (params: GetDailyReportParams) => {
	return request.request({
		...api.getDailyReportParameter,
		url: `${api.getDailyReportParameter.url}/${params.voyageNo}`,
		params: { legType: params.legType }
	});
};

export const getRoutePlanList = (params: GetRoutePlanParams, config?: AxiosRequestConfig) => {
	return request.request({
		...api.getRoutePlanParameter,
		...config,
		params
	});
};

export const getPerformance = (params: GetPerformanceParams) => {
	return request.request({
		...api.getPerformanceParameter,
		params
	});
};

export const getVesselTrackFile = async (
	params: GetVesselTrackParams,
	config?: AxiosRequestConfig
) => {
	return request.request({
		...api.getVesselTrackFileParameter,
		...config,
		params
	});
};

export const getVesselTrack = async (fileName: string, config?: AxiosRequestConfig) => {
	return request.request({
		...api.getVesselTrackParameter,
		...config,
		url: `${api.getVesselTrackParameter.url}/${fileName}`
	});
};

export const getPorts = (params: GetPortsParams) => {
	return request.request({
		...api.getPorts,
		params
	});
};

export const getDailyReportDetail = (params: GetDailyReportDetailParams) => {
	return request.request({
		...api.getDailyReportDetailParameter,
		params
	});
};

export const getSegmentList = (params: GetSegmentParams) => {
	const { voyageNo, ...rest } = params;
	return request.request({
		...api.getSegmentParameter,
		url: `${api.getSegmentParameter.url}/${voyageNo}`,
		params: rest
	});
};

export const addSegments = (params: UpdateSegmentParams) => {
	return request.request({
		...api.addSegmentParameter,
		data: params
	});
};

export const updateSegments = (params: UpdateSegmentParams) => {
	return request.request({
		...api.updateSegmentParameter,
		data: params
	});
};

export const getCPTerms = (voyageNo: string) => {
	return request.request({
		...api.getCPTermsParameter,
		url: `${api.getCPTermsParameter.url}/${voyageNo}`
	});
};
