import { cacheStorage } from "@/storage";
export interface GlobalState {
	firstSelected:
		| "/layout/estimation"
		| "/layout/voyagemanage"
		| "/layout/monitoring"
		| "/layout/biInsights"
		| "/layout/database";
}

const globalInfo = cacheStorage.get("global_info");

const InitialGlobalState: GlobalState = {
	firstSelected: globalInfo?.["firstSelected"]
};

export default InitialGlobalState;
