import { CommonInitItemEvent } from "@/types/event";
import { InvStatementBasicsType } from "./type";

export const initialStatementBasicsItem: CommonInitItemEvent<InvStatementBasicsType> = (item) => {
  return {
    ownerId: item?.ownerId ?? null,
    ownerName: item?.ownerName ?? "",
    address: item?.address ?? "",
    tel: item?.tel ?? "",
    email: item?.email ?? ""
  }
}

export const checkKeys: (keyof InvStatementBasicsType)[] = [
  "ownerId",
  "ownerName"
]