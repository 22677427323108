import React from "react";
import style from "./index.module.less";
import useIndex from "./hook/useIndex";
import { Radio } from "antd";
import { FleetButton, FleetFormItem, FleetInput } from "@/components";
import { BasicProps } from "./type";

const Basic: React.FC<BasicProps> = ({ data, onReset, onChange }) => {
  const { saveBasicInfo } = useIndex(data, onReset);
  return (
    <div className={style["fleet-basic"]}>
      <FleetFormItem
        containerClassname="basic-item"
        label="Nick Name"
        type="row"
        needVerity
      >
        <FleetInput
          className="basic-item-inner"
          value={data?.nickName}
          onChange={(v) => onChange("nickName", v)}
        />
      </FleetFormItem>
      <FleetFormItem
        containerClassname="basic-item"
        label="Phone Number"
        type="row"
        needVerity
      >
        <FleetInput
          className="basic-item-inner"
          value={data?.phonenumber}
          onChange={(v) => onChange("phonenumber", v)}
        />
      </FleetFormItem>
      <FleetFormItem
        containerClassname="basic-item"
        label="Email"
        type="row"
        needVerity
      >
        <FleetInput
          className="basic-item-inner"
          value={data?.email}
          onChange={(v) => onChange("email", v)}
        />
      </FleetFormItem>
      <FleetFormItem
        containerClassname="basic-item"
        label="Sex"
        type="row"
        needVerity
      >
        <Radio.Group
          className="basic-item-inner"
          onChange={(e) => onChange("sex", e.target.value)}
          value={data?.sex}
        >
          <Radio value={"0"}>man</Radio>
          <Radio value={"1"}>female</Radio>
        </Radio.Group>
      </FleetFormItem>
      <FleetFormItem
        containerClassname="basic-item"
        label=" "
        type="row"
      >
        <div className="basic-item-inner">
          <FleetButton type="primary" onClick={saveBasicInfo}>
            Save
          </FleetButton>
        </div>        
      </FleetFormItem>
    </div>
  );
};

export default Basic;
