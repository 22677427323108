import React from "react";
import style from "./index.module.less";
import { VesselArrivalItemProps } from "./type";
import { useTranslation } from "react-i18next";

const VesselArrivalItem: React.FC<VesselArrivalItemProps> = ({
  title,
  items,
  record
}) => {
  const { t } = useTranslation()
  return <div className={style["vesselArrival-item"]}>
    <span className="vesselArrival-item--header">{title}</span>
    <div className="vesselArrival-item--container">
      {
        items?.map((item, itemIndex) => {
          return item?.render ? (
						item?.render(item?.dataIndex, t(`vesselArrival.${item?.dataIndex}`), record)
					) : (
						<div key={item?.dataIndex || itemIndex}>
							<span>{item?.dataIndex ? t(`vesselArrival.${item?.dataIndex}`) : ""}</span>
							{item?.renderValue ? (
								item?.renderValue(item?.dataIndex, record)
							) : (
								<span>{record?.[item?.dataIndex]}</span>
							)}
						</div>
					);
        })
      }
    </div>
  </div>
}

export default VesselArrivalItem