import React, { FC, useEffect, useRef } from "react";
import style from "./index.module.less";
import { MovementHandoverFormSummaryProps } from "./type";
import { VoyageManageVoyageRemark } from "@/pages/voyagemanage/business/preOp&movement";
import { VoyageManageVoyageRemarkCommonRefType } from "@/pages/voyagemanage/business/preOp&movement/business/voyageRemark/type";

const MovementHandoverFormSummary: FC<MovementHandoverFormSummaryProps> = ({
	voyageId,
	voyageType
}) => {
	const voyageRemarkRef = useRef<VoyageManageVoyageRemarkCommonRefType>();
	useEffect(() => {
		if (!voyageId) return;
		voyageRemarkRef?.current?.reset();
	}, [voyageId]);

	return (
		<div className={style["movement-handoverFormSummary"]}>
			<div className="movement-details--header">
				<span className="fleet-title">Handover Form Summary</span>
			</div>
			<VoyageManageVoyageRemark
				voyageId={voyageId}
				type="movement"
				ref={voyageRemarkRef}
				voyageType={voyageType}
			/>
		</div>
	);
};

export default MovementHandoverFormSummary;
