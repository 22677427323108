import { queryPortsRouteAction } from "@/action/estimation/route";
import { EsMultipleRouteFormType } from "@/featrue/estimation/details/multipleRoute/type";
import { useAppDispatch } from "@/hook";
import useReminder from "@/hook/useReminder";
import { PortRotationFormType, StowagePortInfoKeys } from "@/types/estimation/details/index";
import { MutableRefObject, useEffect, useMemo, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { IdentifierMapChangeEvent, ShippingMapCommitEvent, ShippingVesselHisChangeEvent, shippingMapType } from "./useDetails";
import { getRandomColor } from "@/tools";
import { EstPortRotationItemType } from "@/pages/estimation/module";

export type RouteLineProps = {
  delievryPort: {
    delievryPortName?: string;
    delievryPortId?: string | number;
    delievryPortCode?: string;
  },
  ballastPortList?: EstPortRotationItemType[];
  ladenPortList?: EstPortRotationItemType[];
  speedList?: {
    ballastSpeed: number | "";
    ladenSpeed: number | "";
  }
}

export type PortSport = {
  distance: number;
  timeConsue: number;
  endTime: string;
  portCode: string;
}

const useRouteLine = (source: RouteLineProps & {
  startVoyTime: number;
  portChange?: (id: string | number, key: Exclude<StowagePortInfoKeys, "id">, value: string | number) => void;
  onIdentifierChange?: IdentifierMapChangeEvent,
  onVesselHisCommit?: ShippingVesselHisChangeEvent,
  shippingMap?: MutableRefObject<shippingMapType>;
  onShippingCommit?: ShippingMapCommitEvent
}) => {
  const {
    ballastPortList,
    ladenPortList,
    delievryPort,
    speedList,
    startVoyTime,
    portChange,
    onIdentifierChange,
    onVesselHisCommit,
    onShippingCommit,
    shippingMap
  } = source;

  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { reminder } = useReminder()
  const [loading, setLoading] = useState(false);
  const [dataSource, setDataSource] = useState<EsMultipleRouteFormType[]>([]);
  const [checkbox, setCheckbox] = useState<number[]>([]);
  const [show, setShow] = useState(false);
  const activePort = useRef<{
    arrPort: string;
    depPort: string;
  }>({
    arrPort: "",
    depPort: ""
  });

  // const temporaryHis = useRef<{ index: number[], path: any[] }>({
  //   index: [],
  //   path: []
  // })

  const portSportGroup = useMemo<PortSport[]>(() => {
    let index = 0;
    let group: PortSport[] = [];
    let time: string | number = startVoyTime || Date.now();
    for (let i = 0; i < ballastPortList?.length; i++) {
      let distance = +ballastPortList[i].distance;
      let timeConsue = i === 0 ? (+distance / (speedList.ballastSpeed || 1)) : (+distance / (speedList.ladenSpeed || 1));
      time = +time + timeConsue * 60 * 60 * 1000;
      group.push({
        distance: distance,
        timeConsue: +(timeConsue).toFixed(2),
        endTime: new Date(time).toLocaleString().slice(0, -3),
        portCode: ballastPortList?.[i]?.portCode
      });
    }
    for (let i = 0; i < ladenPortList?.length; i++) {
      let distance = +ladenPortList[i].distance;
      let timeConsue = distance / (speedList.ladenSpeed || 1);
      time = +time + timeConsue * 60 * 60 * 1000;
      group.push({
        distance: distance,
        timeConsue: +(timeConsue).toFixed(2),
        endTime: new Date(time).toLocaleString().slice(0, -3),
        portCode: ladenPortList?.[i]?.portCode
      });
    }
    return group
  }, [ballastPortList, ladenPortList, delievryPort, speedList, startVoyTime]);

  const queryPortsRouteFront = () => {
    const vesselHisTrack = shippingMap?.current?.vesselHisTrack;
    const portIndex = activePort?.current?.depPort;
    setLoading(true);
    setCheckbox(vesselHisTrack?.[portIndex]?.index ?? []);
  }

  const queryPortsRouteSuccess = (response) => {
    const vesselHisTrack = shippingMap?.current?.vesselHisTrack;
    const portIndex = activePort?.current?.depPort;
    const data = response?.data?.sort((portA:EsMultipleRouteFormType,portB:EsMultipleRouteFormType) => portA?.depDateMonth - portB?.depDateMonth) 
    const res = [];
    const routeRes = [];
    for (let item of data) {
      res.push(item?.id as number);
      routeRes.push({
        ...item?.routeObject?.features?.[0],
        properties: {
          color: getRandomColor()
        }
      })
    }
    setDataSource(data);
    if (!vesselHisTrack?.[portIndex]) {
      setCheckbox(res)
      onVesselHisCommit(portIndex, { index: res, path: routeRes });
      onIdentifierChange("vesselHisTrack", (preVal: number) => preVal + 1);
    }
    setLoading(false);
  }

  const queryPortsRouteError = (error) => {
    reminder("error", error?.msg ? (error?.msg + ": " + error?.data) : error?.data);
    setLoading(false);
  }

  const queryPortsRoute = () => {
    queryPortsRouteAction(
      {
        arrPort: activePort.current.arrPort,
        depPort: activePort.current.depPort
      },
      queryPortsRouteFront,
      queryPortsRouteSuccess,
      queryPortsRouteError,
      dispatch,
      navigate
    )
  }

  const handleSelectPort = (item: PortSport, prevItem: Partial<PortSport>) => {
    activePort.current = {
      arrPort: prevItem?.portCode,
      depPort: item?.portCode
    };
    setShow(true);
  }

  const handleCheckAll = (checkAll: boolean) => {
    if (!checkAll) {
      setCheckbox([]);
      onVesselHisCommit(activePort.current.depPort, { index: [], path: [] });
      onIdentifierChange("vesselHisTrack", (preVal: number) => preVal + 1);
      return;
    }

    setCheckbox(prev => {
      const res = [];
      const routeRes = [];
      for (let item of dataSource) {
        res.push(item?.id as number);
        routeRes.push({
          ...item?.routeObject?.features?.[0],
          properties: {
            color: getRandomColor()
          }
        })
      }
      onVesselHisCommit(activePort.current.depPort, { index: res, path: routeRes });
      onIdentifierChange("vesselHisTrack", (preVal: number) => preVal + 1);
      return [...res]
    })
  }

  const handleCheckBox = (record: EsMultipleRouteFormType, checkAll?: boolean) => {
    const index = checkbox.findIndex(item => item === record?.id);
    const vesselHisTrack = shippingMap?.current?.vesselHisTrack;
    const portIndex = activePort?.current?.depPort;
    if (index !== -1) {
      setCheckbox(prev => {
        prev.splice(index, 1);
        return [...prev]
      });
      const indexArr = vesselHisTrack?.[portIndex]?.index;
      const pathArr = vesselHisTrack?.[portIndex]?.path;
      indexArr.splice(index, 1);
      pathArr.splice(index, 1);
      onVesselHisCommit(activePort.current.depPort, { index: indexArr, path: pathArr });
      onIdentifierChange("vesselHisTrack", (preVal: number) => preVal + 1);
    } else {
      setCheckbox(prev => {
        return [...prev, record?.id as number]
      });
      const indexArr = vesselHisTrack?.[portIndex]?.index;
      const pathArr = vesselHisTrack?.[portIndex]?.path;
      onVesselHisCommit(activePort.current.depPort, {
        index: [...indexArr, record?.id as number], path: [...pathArr, {
          ...record?.routeObject?.features?.[0],
          properties: {
            color: getRandomColor()
          }
        }]
      });
      onIdentifierChange("vesselHisTrack", (preVal: number) => preVal + 1);
    }
  }

  // const handleConfirm = () => {
  //   const portIndex = activePort?.current?.depPort;
  //   onVesselHisCommit(portIndex, {
  //     index: temporaryHis?.current?.index,
  //     path: temporaryHis?.current?.path
  //   })
  //   onIdentifierChange("vesselHisTrack", (preVal: number) => preVal + 1);
  //   setShow(false)
  // }

  useEffect(() => {
    if (!show) return;
    queryPortsRoute()
  }, [show])

  return {
    checkbox,
    dataSource,
    portSportGroup,
    show,
    loading,
    setShow,
    handleSelectPort,
    handleCheckAll,
    handleCheckBox
  }


}

export default useRouteLine;