import { FleetOptionType } from "@/types/advancedType";
import { MovementBunkerClauseType } from "./type";

export const initialBunkerClause = (item?: Partial<MovementBunkerClauseType>): MovementBunkerClauseType => {
  return {
    id: item?.id ?? null,
    borAbout: item?.borAbout ?? "",
    borPrice: item?.borPrice ?? "same"
  }
}

export const bunkerBorPriceOptions: FleetOptionType<Capitalize<"same" | "difference">, "same" | "difference">[] = [
  { label: "Same", value: "same" },
  { label: "Difference", value: "difference" }
]

export const bunkerScrubberOptions: FleetOptionType<"Yes" | "No", 0 | 1>[] = [
  { label: "Yes", value: 1 },
  { label: "No", value: 0 }
]