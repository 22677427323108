import React, { FC } from "react";
import style from "./index.module.less";
import { MovementTaskProps } from "./type";
import { MovementTaskDetails, MovementTaskList } from "./components";
import useIndex from "./hooks/useIndex";
import classnames from "classnames";

const MovementTask: FC<MovementTaskProps> = ({
	voyageId,
	refresh,
	activeVoyageType,
	onOpenDialog
}) => {
	const {
		loading,
		open,
		activeItem,
		dataSource,
		activeStageItem,
		handleTaskSelect,
		handleTaskClose,
		handleStageSelect
	} = useIndex(voyageId, refresh, activeVoyageType);

	return (
		<div
			className={classnames(style["movement-task"], {
				[style["movement-task--visible"]]: !open
			})}
		>
			<div className="movement-task-container">
				<span className="movement-task-container--mask"></span>
				<div className="movement-task-container--details">
					<MovementTaskDetails
						voyageId={voyageId}
						currentItem={activeItem}
						activeVoyageType={activeVoyageType}
						open={open}
						onOpenDialog={onOpenDialog}
						onClose={handleTaskClose}
					/>
				</div>
			</div>
			<MovementTaskList
				loading={loading}
				items={dataSource}
				activeItem={activeItem}
				currentStageItem={activeStageItem}
				onSelect={handleTaskSelect}
				onStageSelect={handleStageSelect}
				activeVoyageType={activeVoyageType}
			/>
		</div>
	);
};

export default MovementTask;
