import { RouteVoyageTabType } from "@/pages/routeCommon/type";
import { FleetOptionType } from "@/types/advancedType";

export const tabOptions: FleetOptionType<
	"Leg List" | "AIS List" | "Area" | "Port",
	RouteVoyageTabType
>[] = [
	{ label: "Leg List", value: "leg" },
	{ label: "Area", value: "area" },
	{ label: "Port", value: "port" },
	{ label: "AIS List", value: "ais" }
];
