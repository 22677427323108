import { ApiParameter, RequestApiMapType } from "@/api";
import request from "@/service";

import {
	RouteCommonAreaVesselParamsType,
	getRouteCommonAreaVesselArgumentsParams,
	updateRouteCommonAreaVesselArgumentsParams,
	getRouteCommonAreaVesselListParams,
	downloadRouteCommonAreaVesselListParams
} from "./type";
import { appVersion } from "@/api/constant";

const enum ApiMap {
	getRouteCommonAreaVesselArgumentsParameter,
	updateRouteCommonAreaVesselArgumentsParameter,
	getRouteCommonAreaVesselListParameter,
	downloadRouteCommonAreaVesselListParameter
}

export type ApiUrl = keyof typeof ApiMap;

const areaVesselApi: ApiParameter<ApiUrl> = {
	getRouteCommonAreaVesselArgumentsParameter: {
		method: "get",
		url: appVersion + "/moni/ship/area/argument/latest"
	},
	updateRouteCommonAreaVesselArgumentsParameter: {
		method: "post",
		url: appVersion + "/moni/ship/area/argument/latest"
	},
	getRouteCommonAreaVesselListParameter: {
		method: "post",
		url: appVersion + "/moni/ship/area/list/latest"
	},
	downloadRouteCommonAreaVesselListParameter: {
		method: "post",
		url: appVersion + "/moni/ship/area/download/vessel"
	}
};

export const getRouteCommonAreaVesselArgumentsApi = (
	params: getRouteCommonAreaVesselArgumentsParams
) => {
	return request.request({
		...areaVesselApi.getRouteCommonAreaVesselArgumentsParameter
	});
};

export const updateRouteCommonAreaVesselArgumentsApi = (
	params: updateRouteCommonAreaVesselArgumentsParams
) => {
	return request.request({
		...areaVesselApi.updateRouteCommonAreaVesselArgumentsParameter,
		data: params
	});
};

export const getRouteCommonAreaVesselListApi = (params: getRouteCommonAreaVesselListParams) => {
	return request.request({
		...areaVesselApi.getRouteCommonAreaVesselListParameter,
		data: params
	});
};

export const downloadRouteCommonAreaVesselListApi = (
	params: downloadRouteCommonAreaVesselListParams
) => {
	return request.request({
		...areaVesselApi.downloadRouteCommonAreaVesselListParameter,
		data: params,
		responseType: "blob"
	});
};

export const areaVesselApiMap: RequestApiMapType<ApiUrl, RouteCommonAreaVesselParamsType> = {
	getRouteCommonAreaVesselArgumentsApi,
	updateRouteCommonAreaVesselArgumentsApi,
	getRouteCommonAreaVesselListApi,
	downloadRouteCommonAreaVesselListApi
};
