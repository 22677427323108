import React, { FC, forwardRef, useImperativeHandle, useRef } from "react";
import style from "./index.module.less";
import commonStyle from "../../index.module.less";
import classnames from "classnames";
import useCommonIndex from "../../hooks/useCommonIndex";
import { CommonNewlyContact } from "@/common";
import { FleetLoading, FleetTextarea } from "@/components";
import { VoyageManageAttachAndContact } from "../../../../components";
import { VoyageManageTcvcVoyageRemarkType } from "./type";
import { initialVoyageTcvcManageVoyageRemark } from "./source";
import { VoyageManageVoyageRemarkProps, VoyageManageVoyageRemarkCommonRefType } from "../../type";

const VoyageManageTcvcVoyageRemark = forwardRef<
	VoyageManageVoyageRemarkCommonRefType,
	VoyageManageVoyageRemarkProps
>(({ voyageId, type, voyageType }, ref) => {
	const currentType = useRef<keyof VoyageManageTcvcVoyageRemarkType>("head_fixture");

	const {
		loading,
		highlightedPoint,
		dataSource,
		setHighlightedPoint,
		handleAdd,
		commit,
		remove,
		handleReset,
		handleCombine,
		contactOption,
		handleContactChange,
		handleContactConfirm,
		handleContactSelect
	} = useCommonIndex<VoyageManageTcvcVoyageRemarkType, keyof VoyageManageTcvcVoyageRemarkType>(
		voyageId,
		initialVoyageTcvcManageVoyageRemark,
		currentType
	);

	useImperativeHandle(ref, () => ({
		reset: handleReset,
		combineParams: handleCombine
	}));

	return (
		<div
			className={classnames("fleet-nonScrolBar", commonStyle["voyageManage-voyageRemark"], {
				[style["voyageManage-voyageRemark--movement"]]: type === "movement"
			})}
		>
			<FleetLoading loading={loading} />
			<div className="voyageRemark-scrollArea">
				{type === "preOp" && (
					<div className="voyageRemark--common">
						<span className="voyageRemark--common--header">Highlighted Points</span>
						<FleetTextarea
							placeholder=""
							className="voyageRemark--remarks--textarea"
							value={highlightedPoint}
							onChange={(val: string) => {
								setHighlightedPoint(val);
							}}
							disabled={!voyageId}
						/>
					</div>
				)}
				<VoyageManageAttachAndContact
					voyageId={voyageId}
					type="head_fixture"
					attachment={dataSource?.["head_fixture"]?.attachment}
					contact={dataSource?.["head_fixture"]?.contact}
					onCommit={commit}
					onDelete={remove}
					onAdd={handleAdd}
					onContactSelect={handleContactSelect}
				/>
				<VoyageManageAttachAndContact
					voyageId={voyageId}
					type="fixture_notes"
					attachment={dataSource?.["fixture_notes"]?.attachment}
					contact={dataSource?.["fixture_notes"]?.contact}
					onCommit={commit}
					onDelete={remove}
					onAdd={handleAdd}
					onContactSelect={handleContactSelect}
				/>
				<VoyageManageAttachAndContact
					voyageId={voyageId}
					type="port"
					attachment={dataSource?.["port"]?.attachment}
					contact={dataSource?.["port"]?.contact}
					onCommit={commit}
					onDelete={remove}
					onAdd={handleAdd}
					onContactSelect={handleContactSelect}
				/>
				<VoyageManageAttachAndContact
					voyageId={voyageId}
					type="other"
					attachment={dataSource?.["other"]?.attachment}
					contact={dataSource?.["other"]?.contact}
					onCommit={commit}
					onDelete={remove}
					onAdd={handleAdd}
					onContactSelect={handleContactSelect}
				/>
				<CommonNewlyContact
					hide={contactOption?.hide}
					loading={contactOption?.loading}
					onClose={() => {
						handleContactChange?.({ hide: true });
					}}
					onConfirm={handleContactConfirm}
				/>
			</div>
		</div>
	);
});

export default VoyageManageTcvcVoyageRemark;
