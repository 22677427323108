import React, { useState } from "react";
import style from "./index.module.less";
import classnames from "classnames";
import {
	FleetFormItem,
	FleetRangePicker,
	FleetSelect,
	FleetTable,
	FleetVerifyInput
} from "@/components";
import { CommonColumnCategory, CommonRoleWrap, FleetCategory, FleetToast } from "@/common";
import useGroup from "@/hook/voyageManage/movement/list/useGroup";
import { MovementGroupOptions } from "@/types/voyageManage/movement/list";
import { Button, Popover } from "@/_components";
import { voyageStatusOptions } from "./source";

const MovemoentList: React.FC<{}> = (props) => {
	const [isOpen, setIsOpen] = useState(false);

	const {
		checkGroup,
		layCan,
		search,
		ids,
		selectedRowKeys,
		pageNum,
		pageSize,
		columns,
		dataSource,
		loading,
		total,
		hide,
		editHide,
		setEditHide,
		onLayCanChange,
		handleReset,
		handleChange,
		onPageChange,
		onSelectChange,
		navigate,
		onDeleteItems,
		onSearch,
		onRow,
		onReset,
		onIdsChange,
		statusList,
		setStatusList
	} = useGroup();
	return (
		<div className={classnames(style["movement-list"], "voyagemanage-common")}>
			<div className="movementList-header">
				<span className="details-title">Voyage List</span>
			</div>
			<div className="movementList-search">
				<div className="search-container">
					<FleetVerifyInput
						label="Search"
						className="input-filter"
						value={undefined}
						placeholder="Please enter"
						prefix="LayoutSearchIcon"
						onChange={onSearch}
					/>
					<Popover
						open={isOpen}
						onOpenChange={setIsOpen}
						buttonProps={{
							children: "Filter",
							size: "small"
						}}
						// render={(setIsOpen) => {
						// 	return (
						// 		<div className={style["filter-content"]}>
						// 			<CommonColumnCategory
						// 			// options={MovementGroupOptions}
						// 			// checkGroup={checkGroup}
						// 			// onChange={handleChange}
						// 			// onReset={handleReset}
						// 			/>
						// 		</div>
						// 	);
						// }}
						content={
							<div className={style["filter-content"]}>
								<FleetCategory
									options={MovementGroupOptions}
									checkGroup={checkGroup}
									onChange={handleChange}
									onReset={handleReset}
								/>
							</div>
						}
					></Popover>
					<FleetFormItem label="Actual commence date">
						<FleetRangePicker
							className="date-filter"
							value={layCan}
							allowClear
							onChange={onLayCanChange}
							// disabledDate={(current) => {
							//   return current && current < dayjs().endOf('day');
							// }}
						/>
					</FleetFormItem>
					<FleetFormItem label="Voyage Status">
						<FleetSelect
							mode="multiple"
							value={statusList}
							options={voyageStatusOptions}
							className="voyage-select"
							onSelect={(value) => {
								console.log("value", value);
								setStatusList((prev) => {
									const index = prev?.findIndex((item) => item === value);
									if (index !== -1) {
										prev?.splice(index, 1);
									} else {
										prev?.push(value);
									}
									return [...prev];
								});
							}}
							onDeselect={(value) => {
								setStatusList((prev) => {
									const index = prev?.findIndex((item) => item === value);
									prev?.splice(index, 1);
									return [...prev];
								});
							}}
						/>
					</FleetFormItem>
				</div>
				<div className="toolbar-container">
					<CommonRoleWrap roleStr="movement:list:batchDelete">
						<Popover
							buttonProps={{
								disabled: selectedRowKeys?.length === 0,
								size: "small",
								type: "icon",
								children: <span className="font_family fleet-delete"></span>
							}}
							disabled={selectedRowKeys?.length === 0}
							render={(setIsOpen) => {
								return (
									<FleetToast
										label="Are you sure delete this item?"
										onCancel={() => {
											setIsOpen(false);
										}}
										onConfirm={() => {
											onDeleteItems?.("multiple");
											setIsOpen(false);
										}}
									/>
								);
							}}
						/>
					</CommonRoleWrap>
					{/* <CommonRoleWrap roleStr="movement:list:download">
            <Button
              size="small"
              disabled={selectedRowKeys?.length === 0}
              prefixIcon="fleet-download"
            >
              Download
            </Button>
          </CommonRoleWrap> */}
					<CommonRoleWrap roleStr="movement:list:close">
						<Popover
							buttonProps={{
								children: (
									<>
										<span className="font_family fleet-check"></span> Voyage Close
									</>
								),
								disabled: selectedRowKeys?.length === 0,
								size: "small"
							}}
							disabled={selectedRowKeys?.length === 0}
							render={(setIsOpen) => {
								return (
									<FleetToast
										label="Are you sure close this item?"
										onCancel={() => {
											setIsOpen(false);
										}}
										onConfirm={() => {
											setIsOpen(false);
										}}
									/>
								);
							}}
						/>
					</CommonRoleWrap>
					<CommonRoleWrap roleStr="movement:list:add">
						<Button
							size="small"
							// onClick={() => setHide(false)}
							onClick={() => {
								navigate("/layout/voyagemanage/preoperation");
							}}
							prefixIcon="fleet-add"
						>
							New Voyage
						</Button>
					</CommonRoleWrap>
				</div>
			</div>
			<FleetTable
				columns={columns}
				className="movementList-table"
				dataSource={dataSource}
				loading={loading}
				id="id"
				pagination={{
					pageSize: pageSize,
					current: pageNum,
					total: total,
					onChange: onPageChange
				}}
				rowSelection={{
					selectedRowKeys,
					onChange: onSelectChange
				}}
				onRow={onRow}
			/>
		</div>
	);
};

export default MovemoentList;
