import { type ApiParameter } from "@/api";
import { appVersion } from "@/api/constant";
import request from "@/service";
import { PageParams } from "../estimation";
import { CBBasicFormType } from "@/featrue/voyagemanage/cargoBook/basic/type";
import { CBCargoInfoFormType } from "@/featrue/voyagemanage/cargoBook/cargoInfo/type";
import { CBPaymentTermsFormType } from "@/featrue/voyagemanage/cargoBook/freightPayterm/type";
import { CBPortRotationFormType } from "@/featrue/voyagemanage/cargoBook/portRotation/type";

const enum ApiMap {
	getCargoBookGroupParameter,
	getCargoBookParameter,
	addCargoBookParameter,
	editCargoBookParameter,
	delCargoBookParameter
}

type ApiUrl = keyof typeof ApiMap;

const cargoBookApi: ApiParameter<ApiUrl> = {
	getCargoBookGroupParameter: {
		method: "get",
		url: appVersion + "/pre/cargo-book/query"
	},
	getCargoBookParameter: {
		method: "get",
		url: appVersion + "/pre/cargo-book"
	},
	addCargoBookParameter: {
		method: "post",
		url: appVersion + "/pre/cargo-book"
	},
	editCargoBookParameter: {
		method: "put",
		url: appVersion + "/pre/cargo-book"
	},
	delCargoBookParameter: {
		method: "delete",
		url: appVersion + "/pre/cargo-book/batch"
	}
};

export type getCargoBookGroupParams = {
	keyword: string;
	layCanFrom: number;
	layCanTo: number;
	status?: string;
} & PageParams;

export type getCargoBookParams = {
	id: number | string;
};

export type CommonCargoBookParams = CBCargoInfoFormType &
	CBPaymentTermsFormType & {
		portRotationList: CBPortRotationFormType[];
		status?: string;
		delIds?: (string | number)[];
	};

export type addCargoBookParams = Omit<CBBasicFormType, "fixtureNo"> & CommonCargoBookParams;

export type editCargoBookParams = CBBasicFormType &
	CommonCargoBookParams & {
		id?: number;
		voyageId?: string | number;
	};

export type delCargoBookParams = number[];

export const getCargoBookGroupApi = (params: getCargoBookGroupParams) => {
	return request.request({
		...cargoBookApi.getCargoBookGroupParameter,
		params
	});
};

export const getCargoBookApi = (params: getCargoBookParams) => {
	return request.request({
		...cargoBookApi.getCargoBookParameter,
		url: `${cargoBookApi.getCargoBookParameter.url}/${params?.id}`
	});
};

export const addCargoBookApi = (params: addCargoBookParams) => {
	return request.request({
		...cargoBookApi.addCargoBookParameter,
		data: params
	});
};

export const editCargoBookApi = (params: editCargoBookParams) => {
	return request.request({
		...cargoBookApi.editCargoBookParameter,
		data: params
	});
};

export const delCargoBookApi = (params: delCargoBookParams) => {
	return request.request({
		...cargoBookApi.delCargoBookParameter,
		data: params
	});
};
