import React, { FC } from "react";
import style from "./index.module.less";
import { RouteCommonTimelineLegendPanelProps } from "./type";
import { RouteCommonLegend, RouteCommonTimeAxis } from "./components";

const RouteCommonTimelineLegendPanel: FC<RouteCommonTimelineLegendPanelProps> = ({
	meteoStatus,
	visible,
	onMeteoTimeChange,
	onRun
}) => {
	return (
		<div id="routeCommon-timelineLegendPanel" className={style["routeCommon-timelineLegendPanel"]}>
			<RouteCommonLegend visible={visible} meteoStatus={meteoStatus} />
			<RouteCommonTimeAxis onMeteoTimeChange={onMeteoTimeChange} onRun={onRun} />
		</div>
	);
};

export default RouteCommonTimelineLegendPanel;
