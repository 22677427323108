import { useMemo, useRef, useState } from "react";
import { MovementPortRotationProps } from "../type";
import { initialActualItem } from "../source";
import useReminder from "@/hook/useReminder";
import { MovementBunkerPlanProps } from "../../../..";

const useIndex = () => {
  const { reminder } = useReminder();
  const delIds = useRef<(number)[]>([]);
  const [dataSource, setDataSource] = useState<MovementPortRotationProps["actualItems"]>([]);
  const [editStatus, setEditStatus] = useState(false);
  const ladenPortList = useMemo(() => dataSource.filter(item => {
    return ["DC", "DB", "DT"].includes(item.purpose)
  }), [dataSource])

  const ballastPortList = useMemo(() => dataSource.filter(item => {
    return ["LD", "LB", "LT"].includes(item.purpose)
  }), [dataSource])

  const change: MovementPortRotationProps["onActualChange"] = (currentIndex, key, value) => {
    switch (true) {
      case currentIndex === 0 && key === "purpose" && value !== "DL":
        reminder("warning", "The first port can only be the departure port");
        return;
      case key === "purpose" && value === "DL":
        reminder("warning", "The port cannot be the departure port");
        return;
      default:
        break;
    }
    setDataSource(prev => {
      const currentItem = prev?.[currentIndex];
      prev.splice(currentIndex, 1, {
        ...currentItem,
        [key]: value
      })
      return [...prev]
    })
  }

  const commit: MovementPortRotationProps["onActualCommit"] = (currentIndex, item) => {
    setDataSource(prev => {
      const currentItem = prev?.[currentIndex];
      prev.splice(currentIndex, 1, {
        ...currentItem,
        ...item
      })
      return [...prev]
    })
    !editStatus && setEditStatus(true)
  }

  const handleActualPortExpeseUpdate = (items: Pick<MovementPortRotationProps["actualItems"][number], "id" | "portExpense">[]) => {
    setDataSource(prev => {
      return prev?.map(item => {
        const currentItem = items?.find(selectItem => selectItem?.id === item?.id)
        return {
          ...item,
          portExpense: currentItem?.portExpense ?? item?.portExpense
        }
      })
    })
  }

  const init = (items: MovementPortRotationProps["actualItems"]) => {
    setDataSource(items ?? [])
    editStatus && setEditStatus(false)
  }

  const add: MovementPortRotationProps["onActualAdd"] = () => {
    if (dataSource?.at(-1)?.purpose === "RD" && dataSource?.length >= 2) {
      const currentIndex = Math.max(0, dataSource?.length - 1)
      setDataSource(prev => {
        prev?.splice(currentIndex, 0, { ...initialActualItem })
        return [...prev]
      })
      !editStatus && setEditStatus(true)
      return
    }
    setDataSource(prev => {
      return [...prev, { ...initialActualItem }]
    })
    !editStatus && setEditStatus(true)
  }

  const remove: MovementPortRotationProps["onActualDelete"] = (currentIndex) => {
    setDataSource(prev => {
      prev[currentIndex]?.id && (delIds?.current?.push(prev[currentIndex]?.id))
      prev.splice(currentIndex, 1);
      return [...prev]
    })
    !editStatus && setEditStatus(true)
  }

  const move: MovementPortRotationProps["onActualMove"] = (type, currentIndex) => {
    if (type === "up") {
      const prevIndex = currentIndex - 1;
      const prevItem = dataSource?.[prevIndex];
      const currentItem = dataSource?.[currentIndex];
      if (prevIndex === -1 || prevIndex == 0) return
      setDataSource(prev => {
        prev?.splice(prevIndex, 2, currentItem, prevItem)
        return [...prev]
      })
      return
    }

    const nextIndex = currentIndex + 1;
    const nextItem = dataSource?.[nextIndex];
    const currentItem = dataSource?.[currentIndex];
    if (currentIndex === dataSource?.length - 1) return
    setDataSource(prev => {
      prev?.splice(currentIndex, 2, nextItem, currentItem)
      return [...prev]
    })
  }

  const handleStartEndPortCommit = (portItems: Parameters<MovementBunkerPlanProps["onConfirm"]>[1]) => {
    setDataSource(prev => {
      const dlPortIndex = prev?.findIndex(item => item?.purpose === "DL")
      const rdPortIndex = prev?.findIndex(item => item?.purpose === "RD")
      if (dlPortIndex !== -1) {
        prev?.splice(dlPortIndex, 1, {
          ...prev?.[dlPortIndex],
          ...portItems["delivery"]
        })
      }
      if (rdPortIndex !== -1) {
        prev?.splice(rdPortIndex, 1, {
          ...prev?.[rdPortIndex],
          ...portItems["redilivery"]
        })
      }
      return [...prev]
    })

  }

  return {
    dataSource,
    ladenPortList,
    ballastPortList,
    delIds,
    editStatus,
    change,
    commit,
    add,
    remove,
    move,
    init,
    handleStartEndPortCommit,
    handleActualPortExpeseUpdate
  }
}

export default useIndex;