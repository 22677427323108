import { useAppDispatch } from "@/hook";
import {
	MovementOffHireItemType,
	MovementOffHireItemChangeEvent,
	MovementOffHireItemCommitEvent,
	MovementOffHireItemDeleteEvent,
	MovementOffHireItemAddEvent,
	MovementOffHireProps
} from "../type";
import { useNavigate } from "react-router-dom";
import useReminder from "@/hook/useReminder";
import { useEffect, useMemo, useRef, useState } from "react";
import { OffHireRentMap, initialOffHireItem } from "../source";
import { MovementTctcOtherItemType } from "../../../business/tctc/type";
import { ExtendsKeys } from "@/types";
import { onRequest } from "@/action";

const useIndex = (
	voyageId: MovementOffHireProps["voyageId"],
	voyageType: MovementOffHireProps["voyageType"],
	hide: boolean,
	onConfirm: MovementOffHireProps["onConfirm"],
	onClose: MovementOffHireProps["onClose"]
) => {
	const dispatch = useAppDispatch();
	const navigate = useNavigate();
	const { reminder } = useReminder();
	const [loading, setLoading] = useState(false);

	const [activeTctcType, setActiveTctcType] =
		useState<ExtendsKeys<keyof MovementTctcOtherItemType, "reletOffHire" | "rentOffHire">>(
			"rentOffHire"
		);
	const [dataSource, setDataSource] = useState<MovementOffHireItemType[]>([]);
	const delIds = useRef<string[]>([]);

	const summary = useMemo(() => {
		return dataSource?.reduce(
			(prev, curr) => {
				return {
					duration: prev?.duration + +curr?.duration,
					offHireFo: prev?.offHireFo + +curr?.offHireFo,
					offHireDo: prev?.offHireDo + +curr?.offHireDo
				};
			},
			{ duration: 0, offHireFo: 0, offHireDo: 0 }
		);
	}, [dataSource]);

	const handleTabSelect = (value: "reletOffHire" | "rentOffHire") => {
		if (activeTctcType === value) return;
		onRequest(
			"saveMevementOffHireApi",
			{
				deleteIds: delIds?.current,
				offHireVoList: dataSource,
				type: OffHireRentMap[activeTctcType]
			},
			getMovementOffHireFront,
			(response) => {
				setLoading(false);
				onConfirm?.<"TCTC">("TCTC", activeTctcType, dataSource);
				setActiveTctcType(value);
			},
			getMovementOffHireError,
			dispatch,
			navigate
		);
	};

	const add: MovementOffHireItemAddEvent = (currentIndex) => {
		setDataSource((prev) => {
			prev.splice(currentIndex + 1, 0, initialOffHireItem(null));
			return [...dataSource];
		});
	};

	const remove: MovementOffHireItemDeleteEvent = (currentIndex) => {
		if (dataSource?.length <= 1) {
			reminder("error", "Please keep at least one");
			return;
		}
		const currentItem = dataSource?.[currentIndex];
		currentItem?.id && delIds.current.push(currentItem?.id);
		setDataSource((prev) => {
			prev.splice(currentIndex, 1);
			return [...dataSource];
		});
	};

	const change: MovementOffHireItemChangeEvent = (currentIndex, key, value) => {
		setDataSource((prev) => {
			const currentItem = prev?.[currentIndex];
			prev?.splice(currentIndex, 1, {
				...currentItem,
				[key]: value
			});
			return [...prev];
		});
	};

	const commit: MovementOffHireItemCommitEvent = (currentIndex, item) => {
		setDataSource((prev) => {
			const currentItem = prev?.[currentIndex];
			prev?.splice(currentIndex, 1, {
				...currentItem,
				...item
			});
			return [...prev];
		});
	};

	const getMovementOffHireFront = () => {
		setLoading(true);
	};

	const getMovementOffHireSuccess = (response) => {
		if (response?.data?.length <= 0) {
			setDataSource([initialOffHireItem({ voyageId })]);
		} else {
			setDataSource(response?.data);
		}
		setLoading(false);
	};

	const getMovementOffHireError = (error) => {
		reminder("error", error?.msg ? error?.msg + ": " + error?.data : error?.data);
		setLoading(false);
	};

	const getMovementOffHire = () => {
		onRequest(
			"getMovementOffHireApi",
			{
				id: voyageId,
				type: voyageType === "TCVC" ? undefined : OffHireRentMap[activeTctcType]
			},
			getMovementOffHireFront,
			getMovementOffHireSuccess,
			getMovementOffHireError,
			dispatch,
			navigate
		);
	};

	const saveMevementOffHireSuccess = (response) => {
		reminder("success", response?.msg);
		setLoading(false);
		switch (true) {
			case voyageType === "TCTC":
				onConfirm?.<"TCTC">(voyageType, activeTctcType, dataSource);
				break;
			case voyageType === "TCVC":
				onConfirm?.<"TCVC">(voyageType, "offHire", dataSource);
				break;
			default:
				break;
		}
		onClose?.();
	};

	const saveMevementOffHire = () => {
		onRequest(
			"saveMevementOffHireApi",
			{
				deleteIds: delIds?.current,
				offHireVoList: dataSource,
				type: voyageType === "TCVC" ? undefined : OffHireRentMap[activeTctcType]
			},
			getMovementOffHireFront,
			saveMevementOffHireSuccess,
			getMovementOffHireError,
			dispatch,
			navigate
		);
	};

	useEffect(() => {
		if (hide) return;
		if (!voyageId) return;
		delIds.current = [];
		getMovementOffHire();
	}, [hide, voyageId, activeTctcType]);

	return {
		loading,
		dataSource,
		summary,
		change,
		commit,
		remove,
		add,
		activeTctcType,
		handleTabSelect,
		saveMevementOffHire
	};
};

export default useIndex;
