import { type ApiParameter } from "@/api";
import request from '@/service';
import {
  getPreopTcvcHandoverFormParams,
  savePreopTcvcHandoverFormParams,
  getPreopTctcHandoverFormParams,
  savePreopTctcHandoverFormParams
} from "./type";
import { appVersion } from "@/api/constant";

const enum ApiMap {
  getPreopTcvcHandoverFormParameter,
  savePreopTcvcHandoverFormParameter,
  getPreopTctcHandoverFormParameter,
  savePreopTctcHandoverFormParameter
}

type ApiUrl = keyof typeof ApiMap;

const handoverApi: ApiParameter<ApiUrl> = {
  getPreopTcvcHandoverFormParameter: {
    method: "get",
    url: appVersion + "/pre/voyage"
  },
  savePreopTcvcHandoverFormParameter: {
    method: "put",
    url: appVersion + "/pre/voyage"
  },
  getPreopTctcHandoverFormParameter: {
    method: "get",
    url: appVersion + "/pre/voyage/tctc"
  },
  savePreopTctcHandoverFormParameter: {
    method: "post",
    url: appVersion + "/pre/voyage/tctc"
  }
}

export const getPreopTcvcHandoverFormApi = (params: getPreopTcvcHandoverFormParams) => {
  return request.request({
    ...handoverApi.getPreopTcvcHandoverFormParameter,
    url: `${handoverApi.getPreopTcvcHandoverFormParameter.url}/${params?.voyageId}`
  })
}

export const savePreopTcvcHandoverFormApi = (params: savePreopTcvcHandoverFormParams) => {
  return request.request({
    ...handoverApi.savePreopTcvcHandoverFormParameter,
    data: params
  })
}

export const getPreopTctcHandoverFormApi = (params: getPreopTctcHandoverFormParams) => {
  return request.request({
		...handoverApi.getPreopTctcHandoverFormParameter,
		url: `${handoverApi.getPreopTctcHandoverFormParameter.url}/${params?.voyageId}`
	});
}

export const savePreopTctcHandoverFormApi = (params: savePreopTctcHandoverFormParams) => {
  return request.request({
    ...handoverApi.savePreopTctcHandoverFormParameter,
    data: params
  })
}