import { checkKeys, initialEstCargoItem } from "../source"
import { useCommonIndex } from "@/hook"
import { EstCargoItemType } from "../type"

const useIndex = () => {
  const {
    dataSource,
    commit,
    change,
    init,
    check
  } = useCommonIndex<EstCargoItemType>(initialEstCargoItem, checkKeys)

  return {
    dataSource,
    change,
    commit,
    init,
    check
  }


}

export default useIndex