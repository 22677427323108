import React from "react";
import classnames from "classnames";
import style from "./index.module.less";
import { FleetTable } from "@/components";
import { AparFinancial, AparListFilter } from "./components";
import useIndex from "./hooks/useIndex";
import { HistoryDuplicate } from "@/pages/voyagemanage/components";
import { Table } from "antd";
import { formatThousandthNumber } from "@/tools/amount";

const VoyageManageApar: React.FC<{}> = (props) => {
	const {
		financialHide,
		record,
		historyHide,
		filter,
		pager,
		dataSource,
		loading,
		onSearch,
		list_columns,
		onPageChange,
		selectedRowKeys,
		onSelectChange,
		handleDownload,
		handleFilterCommit,
		handleSettle,
		handleClose,
		handleFinancialConfirm,
		handleFinancialClose
	} = useIndex();

	console.log("pager", pager);

	return (
		<div className={classnames(style["voyagemanage-apAr"], "voyagemanage-common")}>
			<AparListFilter
				item={filter}
				onSearch={onSearch}
				onDownload={handleDownload}
				onCommit={handleFilterCommit}
				onSettle={handleSettle}
				disabled={selectedRowKeys?.length <= 0}
			/>
			<FleetTable
				className="apAr-table"
				columns={list_columns}
				loading={loading}
				summary={(data) => {
					return (
						<Table.Summary fixed>
							<Table.Summary.Row>
								<Table.Summary.Cell colSpan={9} index={0}>
									Summary
								</Table.Summary.Cell>
								<Table.Summary.Cell index={1} align="center" className="apAr-table-sumary--cell">
									{formatThousandthNumber(pager?.arapTotal)}
								</Table.Summary.Cell>
								<Table.Summary.Cell index={2} colSpan={3} align="center"></Table.Summary.Cell>
							</Table.Summary.Row>
						</Table.Summary>
					);
				}}
				id="key"
				dataSource={dataSource}
				pagination={{
					current: pager.pageNum,
					pageSize: pager.pageSize,
					total: pager.total,
					onChange: onPageChange,
					showSizeChanger: false
				}}
				rowSelection={{
					selectedRowKeys,
					onChange: onSelectChange
				}}
			/>
			{/* <AparDetails
      hide={hide}
      onClose={handleClose}
      voyageId={record?.current?.voyageId}
    /> */}
			<HistoryDuplicate
				hide={historyHide}
				invoicesType={record?.current?.dataType === "inv_freight" ? "freightInv" : "statement"}
				onClose={handleClose}
				invId={record?.current?.dataId}
				mode="fixed"
				period={record?.current?.period}
			/>
			<AparFinancial
				hide={financialHide}
				invoice={record?.current}
				onClose={handleFinancialClose}
				onConfirm={handleFinancialConfirm}
			/>
		</div>
	);
};

export default VoyageManageApar;
