import { type ApiParameter } from "@/api";
import request from "@/service";
import { appVersion } from "@/api/constant";

const enum ApiMap {
	getAisDataParameter,
	getAisVesselTrackParameter,
	getThirdPartyTrackParameter,
	getAisParameter,
	getVesselBaseInfoParameter
}

type ApiUrl = keyof typeof ApiMap;

const aisDataApi: ApiParameter<ApiUrl> = {
	getAisDataParameter: {
		method: "get",
		url: appVersion + "/ais/shipdt/state"
	},
	getAisVesselTrackParameter: {
		method: "get",
		url: appVersion + "/ais/shipdt/track"
	},
	getThirdPartyTrackParameter: {
		method: "get",
		url: "/s3file/shiptrack/"
	},
	getAisParameter: {
		method: "get",
		// url:appVersion + "/ais/shipdt/current/track"
		url: appVersion + "/ais/cmes/est"
	},
	getVesselBaseInfoParameter: {
		method: "get",
		url: appVersion + "/ais/cmes/basic"
	}
};

export type getAisDataParams = {
	mmsi: string | number;
};

export type getAisVesselTrackParams = {
	days: 7 | 90;
} & getAisDataParams;

export type getAisParams = {
	days: number;
	mmsi: string | number;
};

export type getVesselBaseInfoParams = {
	imo: number;
};

export const getAisDataApi = (params: getAisDataParams) => {
	return request.request({
		...aisDataApi.getAisDataParameter,
		url: `${aisDataApi.getAisDataParameter.url}/${params?.mmsi}`
	});
};

export const getAisVesselTrackApi = (params: getAisVesselTrackParams) => {
	return request.request({
		...aisDataApi.getAisVesselTrackParameter,
		params
	});
};

export const getThirdPartyTrackApi = (url: string) => {
	console.log("url", url);
	return request.request({
		url: aisDataApi.getThirdPartyTrackParameter.url + url,
		method: "get"
	});
};

export const getAisApi = (params: getAisParams) => {
	return request.request({
		...aisDataApi.getAisParameter,
		params
	});
};

export const getVesselBaseInfoApi = (params: getVesselBaseInfoParams) => {
	return request.request({
		...aisDataApi.getVesselBaseInfoParameter,
		url: `${aisDataApi.getVesselBaseInfoParameter}/${params?.imo}`
	});
};
