import { CommonInitItemEvent } from "@/types/event";
import {
	InvStatementBunkerPriceFoType,
	InvStatementBunkerPriceMoType,
	InvStatementBunkerPriceType
} from "./type";
import { FleetOptionType } from "@/types/advancedType";

export const initialStatementBunkerPriceItem: CommonInitItemEvent<InvStatementBunkerPriceType> = (
	item
) => {
	return {
		bodFoConsumption: item?.bodFoConsumption ?? "",
		bodFoType: item?.bodFoType ?? "HSFO",
		bodFoPrice: item?.bodFoPrice ?? "",
		bodFoTotalValue: item?.bodFoTotalValue ?? 0,
		bodMdoGoType: item?.bodMdoGoType ?? "MDO",
		bodMdoConsumption: item?.bodMdoConsumption ?? "",
		bodMdoPrice: item?.bodMdoPrice ?? "",
		bodMdoTotalValue: item?.bodMdoTotalValue ?? 0,
		borFoType: item?.borFoType ?? "HSFO",
		borFoConsumption: item?.borFoConsumption ?? "",
		borFoPrice: item?.borFoPrice ?? "",
		borFoTotalValue: item?.borFoTotalValue ?? 0,
		borMdoGoType: item?.borMdoGoType ?? "MDO",
		borMdoConsumption: item?.borMdoConsumption ?? "",
		borMdoPrice: item?.borMdoPrice ?? "",
		borMdoTotalValue: item?.borMdoTotalValue ?? 0,
		bohFoConsumption: item?.bohFoConsumption ?? "",
		bohFoPrice: item?.bohFoPrice ?? "",
		bohFoTotalValue: item?.bohFoTotalValue ?? 0,
		bohFoType: item?.bohFoType ?? "HSFO",
		bohMdoConsumption: item?.bohMdoConsumption ?? "",
		bohMdoGoType: item?.bohMdoGoType ?? "MDO",
		bohMdoPrice: item?.bohMdoPrice ?? "",
		bohMdoTotalValue: item?.bohMdoTotalValue ?? 0
	};
};

export const invStatementFoOptions: FleetOptionType<
	InvStatementBunkerPriceFoType,
	InvStatementBunkerPriceFoType
>[] = [
	{
		label: "HSFO",
		value: "HSFO"
	},
	{
		label: "VLSFO",
		value: "VLSFO"
	}
];

export const invStatementMoOptions: FleetOptionType<
	InvStatementBunkerPriceMoType,
	InvStatementBunkerPriceMoType
>[] = [
	{
		label: "MDO",
		value: "MDO"
	},
	{
		label: "MGO",
		value: "MGO"
	}
];

export const checkKeys: (keyof InvStatementBunkerPriceType)[] = [
	"bodFoType",
	"bodFoConsumption",
	"bodFoPrice",
	"bodFoTotalValue",
	"bodMdoGoType",
	"bodMdoConsumption",
	"bodMdoPrice",
	"bodMdoTotalValue",
	"borFoType",
	"borFoConsumption",
	"borFoPrice",
	"borFoTotalValue",
	"borMdoGoType",
	"borMdoConsumption",
	"borMdoPrice",
	"borMdoTotalValue",
	"bohFoConsumption",
	"bohFoPrice",
	"bohFoTotalValue",
	"bohFoType",
	"bohMdoConsumption",
	"bohMdoGoType",
	"bohMdoPrice",
	"bohMdoTotalValue"
];
