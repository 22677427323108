import React from "react";
import { createRoot } from "react-dom/client";
import storage from '@/storage';
import App from './App';

storage.install(window);

const Root = createRoot(
  document.getElementById('app') as HTMLElement
);
Root.render(
  <App />
);