import { ExcludeKeys } from "@/types";
import { PromptChildrenProps } from "../Prompt";

export type FleetFieldAddFormType = {
  id?: number;
  fieldName: string,
  fieldVal: string | number,
  description: string
}

export type FleetFieldsChangeEvent = <K extends ExcludeKeys<keyof FleetFieldAddFormType,"id">>(
  index: number,
  key: K,
  value: FleetFieldAddFormType[K],
) => void;

export type FleetFieldAddProps = {
  valueProp: {
    label: string;
    type: "text" | "number"
  },
  onAdd?: (fields: FleetFieldAddFormType) => void
} & PromptChildrenProps;

export const initialFleetFieldAdd: FleetFieldAddFormType = {
  fieldName: "",
  fieldVal: "",
  description: ""
}