import React, { useEffect, useRef, useState, useLayoutEffect } from "react";
import style from "./index.module.less";
import { FleetUpload } from "@/components";
import { Upload } from "antd";
import { LoadingOutlined, PlusOutlined } from "@ant-design/icons";
import useIndex from "./hook/useIndex";
import { PersonalInfoProps } from "./type";
const PersonalInfo: React.FC<PersonalInfoProps> = ({ data }) => {
  const { loading, handleChange, imageUrl, handleUpload } = useIndex();

  return (
    <div className={style["fleet-personal-information"]}>
      <span className="personal-information-header fleet-header">
        Personal Information
      </span>
      <div className="personal-information-profile">
        <div className="profile">
          <Upload
            name="avatarfile"
            listType="picture-circle"
            className="avatar-uploader"
            showUploadList={false}
            disabled
            beforeUpload={handleChange}
          >
            {imageUrl ? (
              <img src={imageUrl} alt="avatar" style={{ width: "100%" }} />
            ) : (
              <button style={{ border: 0, background: "none" }} type="button" disabled>
                {loading ? <LoadingOutlined /> : <PlusOutlined />}
              </button>
            )}
          </Upload>
          {/* <FleetUpload
            showFileList={false}
            className="documents-upload documentsUpload-dragger"
            multiple={false}
            onUpload={handleUpload}
          /> */}
        </div>
      </div>
      <ul>
        <li className="information-item">
          <span>
            <span>
              <i className={`font_family fleet-user`}></i>
            </span>
            User Name
          </span>
          <span className="information-item-value">{data?.userName}</span>
        </li>
        <li className="information-item">
          <span>
            <span>
              <i className={`font_family fleet-phone`}></i>
            </span>
            Phone Number
          </span>
          <span className="information-item-value">{data?.phonenumber}</span>
        </li>
        <li className="information-item">
          <span>
            <span>
              <i className={`font_family fleet-email`}></i>
            </span>
            Email
          </span>
          <span className="information-item-value">{data?.email}</span>
        </li>
        <li className="information-item">
          <span>
            <span>
              <i className={`font_family fleet-relational-graph`}></i>
            </span>
            Department Name
          </span>
          <span className="information-item-value">{data?.deptName}</span>
        </li>
        <li className="information-item">
          <span>
            <span>
              <i className={`font_family fleet-peoples`}></i>
            </span>
            Role Name
          </span>
          <span className="information-item-value">{data?.roleName}</span>
        </li>
        <li className="information-item">
          <span>
            <span>
              <i className={`font_family fleet-Calender`}></i>
            </span>
            Create Time
          </span>
          <span className="information-item-value">{data?.createTime}</span>
        </li>
      </ul>
    </div>
  );
};

export default PersonalInfo;
