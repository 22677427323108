import { ApiParameter, RequestApiMapType } from "@/api";
import request from "@/service";

import {
	getMovementOtherExpensesParams,
	saveMovementOtherExpensesParams,
	MovementOtherExpensesParamsType
} from "./type";
import { appVersion } from "@/api/constant";

const enum ApiMap {
	getMovementOtherExpensesParameter,
	saveMovementOtherExpensesParameter
}

export type ApiUrl = keyof typeof ApiMap;

const otherExpensesApi: ApiParameter<ApiUrl> = {
	getMovementOtherExpensesParameter: {
		method: "get",
		url: appVersion + "/move/dialog/other/voyage"
	},
	saveMovementOtherExpensesParameter: {
		method: "post",
		url: appVersion + "/move/dialog/other"
	}
};

export const getMovementOtherExpensesApi = (params: getMovementOtherExpensesParams) => {
	return request.request({
		...otherExpensesApi.getMovementOtherExpensesParameter,
		url: `${otherExpensesApi.getMovementOtherExpensesParameter.url}/${params?.id}`,
		params: {
			voyageType: params?.voyageType
		}
	});
};

export const saveMovementOtherExpensesApi = (params: saveMovementOtherExpensesParams) => {
	return request.request({
		...otherExpensesApi.saveMovementOtherExpensesParameter,
		data: params
	});
};

export const otherExpensesApiMap: RequestApiMapType<ApiUrl, MovementOtherExpensesParamsType> = {
	getMovementOtherExpensesApi,
	saveMovementOtherExpensesApi
};
